import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup, Card, CardBody, CardHeader, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import Select from 'react-select';
import moment from 'moment';
import Dropzone from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faTrash, faUpload } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-datepicker';

const anestezieData = [
  { id: '1C9B923D-0EB7-EB11-8C22-000C2901B5F1', value: ' N/A', code: 'anestezie_NA', },
  { id: '1B9B923D-0EB7-EB11-8C22-000C2901B5F1', value: 'Generală', code: 'anestezie_generala', },
  { id: '1A9B923D-0EB7-EB11-8C22-000C2901B5F1', value: 'Locală', code: 'anestezie_locala', }];

const gradAnormalitateData = [
  { id: '439B923D-0EB7-EB11-8C22-000C2901B5F1', value: 'cancer invaziv (scuamoasă / glandulară)', code: 'grad_anormalitate_invaziv', },
  { id: '469B923D-0EB7-EB11-8C22-000C2901B5F1', value: 'cancer micro-invaziv', code: 'grad_anormalitate_micro_invaziv', },
  { id: '449B923D-0EB7-EB11-8C22-000C2901B5F1', value: 'glandulară (AIS)', code: 'grad_anormalitate_ais', },
  { id: '419B923D-0EB7-EB11-8C22-000C2901B5F1', value: 'scuamoasă de grad înalt', code: 'grad_anormalitate_sc_inalt', },
  { id: '429B923D-0EB7-EB11-8C22-000C2901B5F1', value: 'scuamoasă de grad scăzut', code: 'grad_anormalitate_sc_scazut', }];

const colposcopieData = [
  { id: '089B923D-0EB7-EB11-8C22-000C2901B5F1', value: 'Nesatisfăcătoare', code: 'nesatisfacatoare', },
  { id: '099B923D-0EB7-EB11-8C22-000C2901B5F1', value: 'Satisfăcătoare şi adecvată', code: 'satisfacatoare_adecvata', },
  { id: '0A9B923D-0EB7-EB11-8C22-000C2901B5F1', value: 'Satisfăcătoare şi inadecvată', code: 'satisfacatoare_inadecvata', }];

const zonaTransformareData = [
  { id: '0F9B923D-0EB7-EB11-8C22-000C2901B5F1', value: ' N/A', code: 'transformare_NA', },
  { id: '0D9B923D-0EB7-EB11-8C22-000C2901B5F1', value: ' Nevizualizabilă', code: 'transformare_nevizualizabila', },
  { id: '0C9B923D-0EB7-EB11-8C22-000C2901B5F1', value: 'Completă', code: 'transformare_completa', },
  { id: '0E9B923D-0EB7-EB11-8C22-000C2901B5F1', value: 'Parţială', code: 'transformare_partiala', }];

const numberValues = [
  { id: '159B923D-0EB7-EB11-8C22-000C2901B5F1', value: '1', },
  { id: '169B923D-0EB7-EB11-8C22-000C2901B5F1', value: '2', },
  { id: '179B923D-0EB7-EB11-8C22-000C2901B5F1', value: '3', },
  { id: '189B923D-0EB7-EB11-8C22-000C2901B5F1', value: '4', }
];

const leziuneValues = [
  { id: '119B923D-0EB7-EB11-8C22-000C2901B5F1', value: 'NU', },
  { id: '129B923D-0EB7-EB11-8C22-000C2901B5F1', value: 'DA', },
  { id: '139B923D-0EB7-EB11-8C22-000C2901B5F1', value: 'NECONCLUDENT', }
];

const checkboxInputs = [
  'triajHPVSec2FS1',
  'constatariNormaleSec41FS1',
  'lezVizAnormalaSec41FS1',
  'limiteLezVizSec41FS1',
  'frotiuVaginalSec41FS1',
  'hrHpvTestSec41FS1',
  'biopsie2Sec41FS1',
  'recomUrmarireSec41FS1'
];

function ColposcopieEdit({ data, changeTab, saveData, }) {
  const [files, setFiles] = useState([]);
  const [centruMedicalSec4FS1, setcentruMedicalSec4FS1] = useState({});
  const [inputs, setInputs] = useState({});
  const [doctors, setDoctors] = useState({});

  useEffect(() => {
    const inputs = data.attributes;
    setInputs(inputs);
    setDoctors(data?.medicSpecialitateSec4FS1 || []);
    setcentruMedicalSec4FS1(data.centruMedicalSec4FS1);// centruMedicalSec4FS1
    if (Object.keys(inputs).length === 0) return;
    checkboxInputs.forEach(elem => {
      inputs[elem].value = inputs[elem].value.toString();
    });
  }, []);

  const {
    // centruMedicalSec4FS1,
    medicSpecialitateSec4FS1,
    triajHPVSec2FS1,
    dataInterventieSec4FS1,
    rezColpListSec41FS1,
    descriereColpSec41FS1,
    zonaTransfSec41FS1,
    leziunePrezSec41FS1,
    nrCadraneSec41FS1,
    constatariNormaleSec41FS1,
    lezVizAnormalaSec41FS1,
    limiteLezVizSec41FS1,
    gradAnormalitateSec41FS1,
    frotiuVaginalSec41FS1,
    hrHpvTestSec41FS1,
    biopsie2Sec41FS1,
    neprelevareBiopSec41FS1,
    diagramaFileSec41FS1, // file upload!!
    anestezieSev41FS1,
    recomUrmarireSec41FS1,
    urmVizitaNumericSec41FS1,
  } = inputs;

  const onDrop = uploaded => {
    setFiles([...uploaded]);
  };

  const deleteFile = () => {
    setFiles([]);
  };

  const filesDom = files.map(file => (
    <li className="d-flex" key={file.name}>
      {file.name}
      <div id={file.name} className="ml-auto" onClick={deleteFile}>
        <div style={{ pointerEvents: 'none', }}>
          <FontAwesomeIcon icon={faTrash} />
        </div>
      </div>
    </li>
  ));

  const handleChange = e => {
    const curentVal = { ...inputs[e.target.name], value: e.target.value, };
    setInputs(prevState => ({ ...prevState, [e.target.name]: curentVal, }));
  };

  const handleSelect = (selected, details) => {
    const curentVal = {
      ...inputs[details.name],
      id: selected?.id || '',
      value: selected?.value || '',
    };
    setInputs(prevState => ({ ...prevState, [details.name]: curentVal, }));
  };

  const selectDate = (val, name) => {
    const dataSubmit = moment(val).utc().format();
    const curentVal = { ...inputs[name], value: dataSubmit, };
    setInputs(prevState => ({ ...prevState, [name]: curentVal, }));
  };

  const handleCheck = e => {
    const newVal = e.target.value === 'false' ? 'true' : 'false';
    const curentVal = { ...inputs[e.target.name], value: newVal, };
    setInputs(prevState => ({ ...prevState, [e.target.name]: curentVal, }));
  };

  const save = e => {
    const saveStatus = e.target.id;
    checkboxInputs.forEach(elem => {
      inputs[elem].value = inputs[elem].value === 'true';
    });
    saveData(inputs, saveStatus);
  };

  const handleBtnNoClick = e => {
    const values = e.target.id.split('-');
    const name = values[0];
    const val = values[1];
    const selectedVal = numberValues.find(a => a.value === val);
    // const curentVal = { ...inputs[name], [name]: selectedVal, };
    setInputs(prevState => ({ ...prevState, [name]: selectedVal, }));
  };

  const handleBtnClick = e => {
    const values = e.target.id.split('-');
    const name = values[0];
    const val = values[1];
    const selectedVal = leziuneValues.find(a => a.value === val);
    // const curentVal = { ...inputs[name], [name]: selectedVal, };
    setInputs(prevState => ({ ...prevState, [name]: selectedVal, }));
  };

  const setTab = e => { changeTab(e.target.id); };

  const calcShowSaveBtn = () =>
    // if (typeof rezFinalSec44FS1 === 'undefined' || rezFinalSec44FS1?.value === '') {
    //   return false;
    // }
    // if (typeof medicAnatoPatoCertificareSec3BFS1 === 'undefined' || medicAnatoPatoCertificareSec3BFS1?.value === '') {
    //   return false;
    // }
    true
    // className={(typeof rezHpvSec34FS1 !== 'undefined' && rezHpvSec34FS1.value !== '') ? '' : 'invalid'}
  ;

  const showBtns = calcShowSaveBtn();

  return (
    <Card>
      <CardHeader>
        <h3 className="mb-0">
          <div className="d-flex">
            <div>
              <span className="mr-3">Colposcopie</span>
            </div>
            {showBtns === true && (
              <div>
                <Button id="draft" size="sm" color="primary" className="btn-rounded" onClick={save}>Draft</Button>
                <Button id="final" size="sm" color="success" className="ml-2 btn-rounded" onClick={save}>Finalizare follow-up</Button>
              </div>
            )}
            <div className="ml-auto">
              <Button id="-" size="sm" color="secondary" className="ml-auto btn-pill" onClick={setTab}>{'<'}</Button>
              <Button id="+" size="sm" color="primary" className="ml-1 btn-pill" onClick={setTab}>{'>'}</Button>
            </div>
          </div>
        </h3>
      </CardHeader>
      <CardBody className="pb-5">
        <Col
          className="underline-hover form-group dont-display ng-untouched ng-pristine"
          style={{ backgroundColor: 'rgba(233, 236, 239, 0.3)', }}
        >
          <Label>
            Date specifice rezultatelor colposcopiei
          </Label>
        </Col>

        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>Centrul de servicii medicale specializate (cabinet de ginecologie) unde s-a efectuat colposcopia</Label>
              <Input
                type="text"
                name="centruMedicalSec4FS1"
                disabled
                value={centruMedicalSec4FS1?.value || ''}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label>Medicul de specialitate obstetrică ginecologie care efectuează intervenţia </Label>
              <Select
                value={medicSpecialitateSec4FS1 || ''}
                onChange={handleSelect}
                getOptionValue={option => option.id}
                getOptionLabel={option => option.value}
                className="basic-single"
                classNamePrefix="select"
                placeholder=""
                name="medicSpecialitateSec4FS1"
                options={doctors}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col>
            <FormGroup check>
              <Input
                type="checkbox"
                checked={triajHPVSec2FS1?.value === 'true'}
                value={triajHPVSec2FS1?.value === 'true'}
                name="triajHPVSec2FS1"
                onChange={handleCheck}
              />
              <Label>Triaj HPV</Label>
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>Data efectuării colposcopiei</Label>
              <FormGroup row className="align-items-center">
                <Col sm={10} className="pr-0">
                  <Input
                    defaultValue={dataInterventieSec4FS1 ? moment(dataInterventieSec4FS1?.value).format('DD-MM-YYYY') : ''}
                    type="text"
                    className="form-control bg-white"
                    id=""
                    disabled
                    placeholder=""
                  />
                </Col>
                <Col sm={2} className="pl-0">
                  <DatePicker
                    name={dataInterventieSec4FS1}
                    locale="ro"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    selected={moment(dataInterventieSec4FS1?.value).toDate()}
                    onChange={val => selectDate(val, 'dataInterventieSec4FS1')}
                    customInput={<Button className="form-control"><FontAwesomeIcon icon={faCalendarAlt} /></Button>}
                  />
                </Col>
              </FormGroup>
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>Rezultat colposcopie</Label>
              <Select
                value={rezColpListSec41FS1 || ''}
                onChange={handleSelect}
                getOptionValue={option => option.id}
                getOptionLabel={option => option.value}
                className="basic-single"
                classNamePrefix="select"
                placeholder=""
                name="rezColpListSec41FS1"
                options={colposcopieData}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label>Descriere colposcopică</Label>
              <Input
                type="textarea"
                rows={3}
                name="descriereColpSec41FS1"
                onChange={handleChange}
                value={descriereColpSec41FS1?.value || ''}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Label>Zona de transformare vizibilă</Label>
              <Select
                value={zonaTransfSec41FS1 || ''}
                onChange={handleSelect}
                getOptionValue={option => option.id}
                getOptionLabel={option => option.value}
                className="basic-single"
                classNamePrefix="select"
                placeholder=""
                name="zonaTransfSec41FS1"
                options={zonaTransformareData}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>Leziune prezentă</Label>
              <div>
                <ButtonGroup size="">
                  <Button
                    onClick={handleBtnClick}
                    id="leziunePrezSec41FS1-DA"
                    color="primary"
                    outline={leziunePrezSec41FS1?.value !== 'DA'}
                  >
                    DA
                  </Button>
                  <Button
                    onClick={handleBtnClick}
                    id="leziunePrezSec41FS1-NU"
                    color="primary"
                    outline={leziunePrezSec41FS1?.value !== 'NU'}
                  >
                    NU
                  </Button>
                  <Button
                    onClick={handleBtnClick}
                    id="leziunePrezSec41FS1-NECONCLUDENT"
                    color="primary"
                    outline={leziunePrezSec41FS1?.value !== 'NECONCLUDENT'}
                  >
                    NECONCLUDENT
                  </Button>
                </ButtonGroup>
              </div>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label>Numărul de cadrane implicate</Label>
              <div>
                <ButtonGroup size="">
                  <Button
                    onClick={handleBtnNoClick}
                    id="nrCadraneSec41FS1-1"
                    color="primary"
                    outline={nrCadraneSec41FS1?.value !== '1'}
                  >
                    1
                  </Button>
                  <Button
                    onClick={handleBtnNoClick}
                    id="nrCadraneSec41FS1-2"
                    color="primary"
                    outline={nrCadraneSec41FS1?.value !== '2'}
                  >
                    2
                  </Button>
                  <Button
                    onClick={handleBtnNoClick}
                    id="nrCadraneSec41FS1-3"
                    color="primary"
                    outline={nrCadraneSec41FS1?.value !== '3'}
                  >
                    3
                  </Button>
                  <Button
                    onClick={handleBtnNoClick}
                    id="nrCadraneSec41FS1-4"
                    color="primary"
                    outline={nrCadraneSec41FS1?.value !== '4'}
                  >
                    4
                  </Button>
                </ButtonGroup>
              </div>
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col>
            <FormGroup check>
              <Input
                type="checkbox"
                checked={constatariNormaleSec41FS1?.value === 'true'}
                value={constatariNormaleSec41FS1?.value === 'true'}
                name="constatariNormaleSec41FS1"
                onChange={handleCheck}
              />
              <Label>Constatări normale </Label>
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col>
            <FormGroup check>
              <Input
                type="checkbox"
                checked={lezVizAnormalaSec41FS1?.value === 'true'}
                value={lezVizAnormalaSec41FS1?.value === 'true'}
                name="lezVizAnormalaSec41FS1"
                onChange={handleCheck}
              />
              <Label>Leziune vizibilă anormală </Label>
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col>
            <FormGroup check>
              <Input
                type="checkbox"
                checked={limiteLezVizSec41FS1?.value === 'true'}
                value={limiteLezVizSec41FS1?.value === 'true'}
                name="limiteLezVizSec41FS1"
                onChange={handleCheck}
              />
              <Label>Limite ale leziunii vizibile </Label>
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>Grad de anormalitate</Label>
              <Select
                value={gradAnormalitateSec41FS1 || ''}
                onChange={handleSelect}
                getOptionValue={option => option.id}
                getOptionLabel={option => option.value}
                className="basic-single"
                classNamePrefix="select"
                placeholder=""
                name="gradAnormalitateSec41FS1"
                options={gradAnormalitateData}
              />
            </FormGroup>
          </Col>
        </Row>

        <Col
          className="underline-hover form-group dont-display ng-untouched ng-pristine"
          style={{ backgroundColor: 'rgba(233, 236, 239, 0.3)', }}
        >
          <Label>
            Date specifice procedurilor realizate în cadrul colposcopiei
          </Label>
        </Col>

        <Row>
          <Col md={6}>
            <FormGroup check>
              <Input
                type="checkbox"
                checked={frotiuVaginalSec41FS1?.value === 'true'}
                value={frotiuVaginalSec41FS1?.value === 'true'}
                name="frotiuVaginalSec41FS1"
                onChange={handleCheck}
              />
              <Label>Frotiu cervical/vaginal </Label>
            </FormGroup>
          </Col>
          <Col md={6}>

            <FormGroup check>
              <Input
                type="checkbox"
                checked={hrHpvTestSec41FS1?.value === 'true'}
                value={hrHpvTestSec41FS1?.value === 'true'}
                name="hrHpvTestSec41FS1"
                onChange={handleCheck}
              />
              <Label>HrHPV test</Label>
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <FormGroup check>
              <Input
                type="checkbox"
                checked={biopsie2Sec41FS1?.value === 'true'}
                value={biopsie2Sec41FS1?.value === 'true'}
                name="biopsie2Sec41FS1"
                onChange={handleCheck}
              />
              <Label>Biopsie </Label>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label>Motive neprelevare biopsie</Label>
              <Input
                type="textarea"
                rows={3}
                name="neprelevareBiopSec41FS1"
                onChange={handleChange}
                value={neprelevareBiopSec41FS1?.value || ''}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col><Label>Diagrama/fotografia leziunii</Label></Col>

        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>Anestezie</Label>
              <Select
                value={anestezieSev41FS1 || ''}
                onChange={handleSelect}
                getOptionValue={option => option.id}
                getOptionLabel={option => option.value}
                className="basic-single"
                classNamePrefix="select"
                placeholder=""
                name="anestezieSev41FS1"
                options={anestezieData}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup check>
              <Input
                type="checkbox"
                checked={recomUrmarireSec41FS1?.value === 'true'}
                value={recomUrmarireSec41FS1?.value === 'true'}
                name="recomUrmarireSec41FS1"
                onChange={handleCheck}
              />
              <Label>Se recomandă managementul de urmărire </Label>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>Număr de luni până la următoarea vizită </Label>
              <Input
                type="number"
                min={0}
                name="urmVizitaNumericSec41FS1"
                onChange={handleChange}
                value={urmVizitaNumericSec41FS1?.value || ''}
              />
            </FormGroup>
          </Col>
        </Row>

      </CardBody>
    </Card>
  );
}

export default ColposcopieEdit;
