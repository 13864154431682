import React, { useEffect, useState } from 'react';
import { faRedoAlt, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import Select from 'react-select';
import { regionOptions, countyOptions, countyOptionsAll } from '../../Organization/organization.helper';
import IndicatorSectionFilter from './IndicatorSectionFilter';
import { cities } from '../../../helpers/orase';
import YearMonthSectionFilter from './YearMonthSectionFilter';
import IndicatorSectionFilterAdv from './IndicatorSectionFilterAdv';

const reports = [
  { value: '1', label: 'Raport numar de femei invitate/ reinvitate la screening', },
  { value: '2', label: 'Raport numar de femei programate la screening', },
  { value: '3', label: 'Raport numar de femei informate/ consiliate/ înregistrate în platforma', },
  { value: '4', label: 'Raport numar de teste pe categorii de rezultate citologice', },
  { value: '5', label: 'Raport numar femei cu teste BP negative informate in sub 30 zile de la prelevarea probelor', },
  { value: '6', label: 'Raport numar femei cu teste BP negative informate in peste 30 zile de la prelevarea probelor', },
  { value: '6L', label: 'Lista femei cu teste BP negative informate in peste 30 zile de la prelevarea probelor', },
  { value: '7', label: 'Raport numar femei cu teste BP pozitive invitate la triaj HPV in sub 21 zile de la prelevarea testelor', },
  { value: '8', label: 'Raport numar femei cu teste BP pozitive invitate la triaj HPV in peste 21 zile de la prelevarea testelor', },
  { value: '8L', label: 'Lista femei cu teste BP pozitive invitate la triaj HPV in peste 21 zile de la prelevarea testelor', },
  { value: '9', label: 'Raport numar femei cu teste BP pozitive programate la triaj HPV in sub 21 zile de la prelevarea testelor', },
  { value: '10', label: 'Raport numar femei cu teste BP pozitive programate la triaj HPV in peste 21 zile de la prelevarea testelor', },
  { value: '10L', label: 'Lista femei cu teste BP pozitive programate la triaj HPV in peste 21 zile de la prelevarea testelor', },
  { value: '11', label: 'Raport numar femei BP pozitive care s-au prezentat la testele de triaj HPV din total femei cu teste pozitive BP', },
  { value: '12', label: 'Raport numar de teste laborator HPV', },
  { value: '13', label: 'Raport numar femei cu teste HPV pozitive si BP pozitiv invitate la colposcopie in sub 30 zile de la recoltarea testului', },
  { value: '14', label: 'Raport numar femei cu teste HPV pozitive si BP pozitiv invitate la colposcopie in peste 30 zile de la recoltarea testului', },
  { value: '14L', label: 'Lista femei cu teste HPV pozitive si BP pozitiv invitate la colposcopie in peste 30 zile de la recoltarea testului', },
  { value: '15', label: 'Raport numar femei cu teste HPV pozitive si BP pozitiv programate la colposcopie in sub 30 zile de la recoltarea testului', },
  { value: '16', label: 'Raport numar femei cu teste HPV pozitive si BP pozitiv programate la colposcopie in peste 30 zile de la recoltarea testului', },
  { value: '16L', label: 'Lista femei cu teste HPV pozitive si BP pozitiv programate la colposcopie in peste 30 zile de la recoltarea testului', },
  { value: '17', label: 'Raport numar femei cu teste HPV pozitive si BP pozitiv ce s-au prezentat la colposcopie', },
  { value: '18', label: 'Raport numar femei cu teste HPV pozitive si BP pozitiv ce nu s-au prezentat la colposcopie', },
  { value: '18L', label: 'Lista femei cu teste HPV pozitive si BP pozitiv ce nu s-au prezentat la colposcopie', },
  { value: '19', label: 'Raport % teste neconforme pe categorii de teste (recoltari, colposcopii, tratament)', }
];

const stareFisa = [
  { value: 'IN_LUCRU', label: 'IN LUCRU', },
  { value: 'FINALIZATA', label: 'FINALIZATA', }
];

function IndicatorFiltersExtendedAdv({
  getDoctorsSimple,
  resetKey, organizations,
  auth, handleSearch, addFilterAndSearch, resetFilters, filters,
}) {
  const [selectedReport, setSelectedReport] = useState('');
  const [region, setRegion] = useState('');
  const [county, setCounty] = useState('');
  const [lastStatus, setLastStatus] = useState('');
  const [cityOptions, setCityOptions] = useState([]);
  const [city, setCity] = useState('');
  const [doctors, setDoctors] = useState({
    s1: [],
    s2: [],
    s3A: [],
    s3B: [],
    s4: [],
  });

  useEffect(() => {
    if (auth.region) {
      const userRegion = { value: auth.region.id, label: auth.region.value, };
      setRegion(userRegion);
    } else {
      const { region: regionFilter, } = filters;
      if (regionFilter && regionFilter !== '') {
        const findRegion = regionOptions.find(elem => elem.value === regionFilter);
        setRegion(findRegion);
      }
    }
  }, [filters.region]);

  useEffect(() => {
    const { county: countyFilter, } = filters;
    if (countyFilter && countyFilter !== '') {
      const findCounty = countyOptions.find(elem => elem.value === countyFilter);
      setCounty(findCounty);
      setCityOptions(cities[countyFilter]);
    }
  }, [filters.county]);

  useEffect(() => {
    const { city: cityFilter, county: countyFilter, } = filters;
    if ((countyFilter && countyFilter !== '') && (cityFilter && cityFilter !== '')) {
      setCityOptions(cities[countyFilter]);
      const findCity = cities[countyFilter].find(elem => elem.name === cityFilter);
      setCity({ ...findCity, value: findCity.name, label: findCity.name, });
    }
  }, [filters.city]);

  useEffect(() => {
    if (filters.lastStatus !== '') {
      const selectedStatus = stareFisa.find(a => a.value === filters.lastStatus);
      setLastStatus(selectedStatus);
    }
  }, [filters.lastStatus]);

  const handleStatusChange = selected => {
    if (selected === null) {
      setLastStatus('');
      addFilterAndSearch({ region: filters?.region || '', county: filters?.county || '', }, false, 'lastStatus');
    } else {
      setLastStatus(selected);
      addFilterAndSearch({ region: filters?.region || '', county: filters?.county || '', lastStatus: selected.value, });
    }
  };

  const handleRegionChange = selected => {
    if (selected === null) {
      setRegion('');
      setCounty('');
      setCity('');
      addFilterAndSearch({ county: '', city: '', }, false, 'region');
      addFilterAndSearch({ }, false, 'county');
      addFilterAndSearch({ }, false, 'city');
    } else {
      setRegion(selected);
      setCounty('');
      setCity('');
      addFilterAndSearch({ region: selected.value, }, false, 'county');
      addFilterAndSearch({ region: selected.value, }, false, 'city');
    }
  };

  const handleCountyChange = selected => {
    if (selected === null) {
      setCounty('');
      setCity('');
      addFilterAndSearch({ region: filters?.region || '', }, false, 'county');
      addFilterAndSearch({ region: filters?.region || '', }, false, 'city');
    } else {
      setCounty(selected);
      setCity('');
      addFilterAndSearch({ region: filters?.region || '', county: selected.value, }, false, 'city');
    }
  };

  const handleChange = (selected, details) => {
    const { name, } = details;

    if (selected === null) {
      addFilterAndSearch({ [name]: '', }, false, name);
    } else {
      const { value, } = selected;
      addFilterAndSearch({ [name]: value, });
    }
  };

  const handleDoctorChange = (selected, details) => {
    const { name, } = details;

    if (selected === null) {
      addFilterAndSearch({ [name]: '', }, false, name);
    } else {
      const { id, } = selected;
      addFilterAndSearch({ [name]: id, });
    }
  };

  const handleReportChange = (selected, details) => {
    setSelectedReport(selected);
    handleChange(selected, details);
  };

  const handleOrganizationChange = (selected, details, type) => {
    const { name, } = details;
    const section = name.substring(0, 2);

    if (selected === null) {
      addFilterAndSearch({ [name]: '', }, false, name);
      const newDoctors = { ...doctors, };
      newDoctors[type] = [];
      setDoctors(newDoctors);
      // reset doctor selection
      handleDoctorChange(null, { name: `${type}Doctor`, });
    } else {
      const { id, } = selected;
      addFilterAndSearch({ [name]: id, });
      getDoctorsSimple(id)
        .then((res => {
          const newDoctors = { ...doctors, };
          newDoctors[type] = res.data.data;
          setDoctors(newDoctors);
        }))
        .catch((err => {
          console.log('err: ', err);
        }));
    }
  };

  const handleCityChange = selected => {
    if (selected === null) {
      setCity('');
      addFilterAndSearch({ region: filters?.region || '', county: filters?.county || '', }, false, 'city');
    } else {
      setCity(selected);
      addFilterAndSearch({
        region: filters?.region || '',
        county: filters?.county || '',
        city: selected.value,
      });
    }
  };

  const handleResetFilters = () => {
    if (auth.region) {
      setCounty('');
      resetFilters();
    } else {
      setRegion('');
      setCounty('');
      resetFilters();
    }
  };
  return (
    <div className="searchFormFields mt-4">
      <Row>
        <Col className="text-right">
          <Button
            onClick={handleResetFilters}
            className="btn btn-light btn-sm btn-pill"
          >
            <FontAwesomeIcon icon={faRedoAlt} />
          </Button>
          <Button
            onClick={handleSearch}
            className="btn btn-light btn-sm btn-pill"
          >
            <FontAwesomeIcon icon={faSearch} />
          </Button>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label>
              <small className="ml-sm-2 font-weight-bold">Tip Raport *</small>
            </Label>
            <Select
              value={selectedReport}
              onChange={handleReportChange}
              className="basic-single"
              classNamePrefix="select"
              placeholder="Raport"
              name="report"
              options={reports}
              isClearable
            />
          </FormGroup>
        </Col>
        <Col md={3}>
          <YearMonthSectionFilter
            yearTitle="Data inceput (an)*"
            monthTitle="Data inceput (luna)*"
            handleChange={handleChange}
            type="dateStart"
            resetKey={resetKey}
            filters={filters}
          />
        </Col>
        <Col md={3}>
          <YearMonthSectionFilter
            yearTitle="Data sfarsit (an)*"
            monthTitle="Data sfarsit (luna)*"
            handleChange={handleChange}
            type="dateEnd"
            resetKey={resetKey}
            filters={filters}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <FormGroup>
            <Label>
              <small className="ml-sm-2 font-weight-bold">Regiune</small>
            </Label>
            {
              auth.region ? (
                <Input
                  value={region?.label || ''}
                  type="text"
                  className="form-control"
                  id="region"
                  placeholder="Regiune"
                  disabled
                />
              ) : (
                <Select
                  isClearable
                  value={region}
                  onChange={handleRegionChange}
                  className="basic-single"
                  classNamePrefix="select"
                  placeholder="Regiune"
                  name="region"
                  options={regionOptions}
                />
              )
            }

          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label>
              <small className="ml-sm-2 font-weight-bold">Judet</small>
            </Label>
            <Select
              isClearable
              value={county}
              onChange={handleCountyChange}
              className="basic-single"
              classNamePrefix="select"
              placeholder="Judet"
              name="county"
              options={auth.region ? countyOptionsAll[region.value]
                : region !== '' ? countyOptionsAll[region.value] : countyOptions}
            />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label>
              <small className="ml-sm-2 font-weight-bold">Localitate</small>
            </Label>
            <Select
              isDisabled={county === '' || county === null}
              className="basic-single"
              classNamePrefix="select"
              placeholder="Localitate"
              isClearable
              name="city"
              options={cityOptions.map(a => ({ ...a, label: a.name, value: a.name, }))}
              onChange={handleCityChange}
              value={city}
            />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label>
              <small className="ml-sm-2 font-weight-bold">Stare</small>
            </Label>
            <Select
              isClearable
              value={lastStatus}
              onChange={handleStatusChange}
              className="basic-single"
              classNamePrefix="select"
              placeholder="Stare"
              name="lastStatus"
              options={stareFisa}
            />
          </FormGroup>
        </Col>
      </Row>
      <IndicatorSectionFilterAdv
        title="Informare şi consiliere"
        handleOrganizationChange={handleOrganizationChange}
        handleDoctorChange={handleDoctorChange}
        handleChange={handleChange}
        organizations={organizations}
        type="s1"
        resetKey={resetKey}
        filters={filters}
        doctors={doctors.s1}
      />
      <IndicatorSectionFilterAdv
        title="Consultație şi recoltare"
        handleOrganizationChange={handleOrganizationChange}
        handleDoctorChange={handleDoctorChange}
        handleChange={handleChange}
        organizations={organizations}
        type="s2"
        resetKey={resetKey}
        filters={filters}
        doctors={doctors.s2}
      />
      <IndicatorSectionFilterAdv
        title="Testare HPV"
        handleOrganizationChange={handleOrganizationChange}
        handleDoctorChange={handleDoctorChange}
        handleChange={handleChange}
        organizations={organizations}
        type="s3A"
        resetKey={resetKey}
        filters={filters}
        doctors={doctors.s3A}
      />
      <IndicatorSectionFilterAdv
        title="Testare BPN"
        handleOrganizationChange={handleOrganizationChange}
        handleDoctorChange={handleDoctorChange}
        handleChange={handleChange}
        organizations={organizations}
        type="s3B"
        resetKey={resetKey}
        filters={filters}
        doctors={doctors.s3B}
      />
      <IndicatorSectionFilterAdv
        title="Colposcopie"
        handleOrganizationChange={handleOrganizationChange}
        handleDoctorChange={handleDoctorChange}
        handleChange={handleChange}
        organizations={organizations}
        type="s4"
        resetKey={resetKey}
        filters={filters}
        doctors={doctors.s4}
      />
    </div>
  );
}

export default IndicatorFiltersExtendedAdv;
