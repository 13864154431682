import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, CardHeader, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import moment from 'moment';

const checkboxInputs = [
  'biopsieCiupireSec42FS1',
  'leepSec42FS1',
  'chiuretajCervSec42FS1',
  'chiuretajMetrSec42FS1',
  'repetareColp2Sec42FS1',
  'atitudineHistoSec42FS1',
  'gradRiscHpvReintraresec42FS1'
];

function BiopsieView({ data, changeTab, }) {
  const [inputs, setInputs] = useState({});

  useEffect(() => {
    const inputs = data.attributes;
    setInputs(inputs);
    if (Object.keys(inputs).length === 0) return;
    checkboxInputs.forEach(elem => {
      inputs[elem].value = inputs[elem].value.toString();
    });
  }, []);

  const {
    biopsieCiupireSec42FS1,
    dataRecoltareBiopsieSecBiopsieFS1,
    centruRecoltareBiopsieSecBiopsieFS1,
    medicRecoltareBiopsieSecBiopsieFS1,
    leepSec42FS1,
    chiuretajCervSec42FS1,
    chiuretajMetrSec42FS1,
    repetareColp2Sec42FS1,
    atitudineHistoSec42FS1,
    gradRiscHpvReintraresec42FS1,
    labAnatoPatoSec42FS1,
    medicAnatoPatoSec42FS1,
    nrBiopsieSec42FS1,
    dataPrimireBiopsieSec42FS1,
    dataInterBiopsieSec42FS1,
    rezHistoPatoSec42FS1,
  } = inputs;

  const setTab = e => { changeTab(e.target.id); };

  return (
    <Card>
      <CardHeader>
        <h3 className="mb-0">
          <div className="d-flex">
            <div>
              <span className="mr-3">Biopsie</span>
            </div>
            <div className="ml-auto">
              <Button id="-" size="sm" color="secondary" className="ml-auto btn-pill" onClick={setTab}>{'<'}</Button>
              <Button id="+" size="sm" color="primary" className="ml-1 btn-pill" onClick={setTab}>{'>'}</Button>
            </div>
          </div>
        </h3>
      </CardHeader>
      <CardBody className="pb-5">
        <Col
          className="underline-hover form-group dont-display ng-untouched ng-pristine"
          style={{ backgroundColor: 'rgba(233, 236, 239, 0.3)', }}
        >
          <Label>
            Date specifice recomandărilor ulterior colposcopiei
          </Label>
        </Col>

        <Row>
          <Col md={6}>
            <FormGroup check>
              <Input
                type="checkbox"
                value={biopsieCiupireSec42FS1?.value || false}
                checked={biopsieCiupireSec42FS1?.value === 'true'}
                name="biopsieCiupireSec42FS1"
                onChange={() => {}}
              />
              <Label>Biopsie prin ciupire (punch)</Label>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label>
                Data recoltării biopsiei
              </Label>

              <Input
                required
                type="text"
                name="dataRecoltareBiopsieSecBiopsieFS1"
                disabled
                value={dataRecoltareBiopsieSecBiopsieFS1?.value ? moment(dataRecoltareBiopsieSecBiopsieFS1.value).format('DD-MM-YYYY') : ''}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>Centrul recoltării biopsiei</Label>
              <Input
                required
                type="text"
                name="centruRecoltareBiopsieSecBiopsieFS1"
                disabled
                value={centruRecoltareBiopsieSecBiopsieFS1?.value || ''}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label>Medicul care a realizat biopsia </Label>
              <Input
                required
                type="text"
                name="medicRecoltareBiopsieSecBiopsieFS1"
                disabled
                value={medicRecoltareBiopsieSecBiopsieFS1?.value || ''}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row className="mt-2 mb-2">
          <Col>
            <FormGroup check>
              <Input
                type="checkbox"
                value={leepSec42FS1?.value || false}
                checked={leepSec42FS1?.value === 'true'}
                name="leepSec42FS1"
                onChange={() => {}}
              />
              <Label>Procedură excizională LEEP </Label>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup check>

              <Input
                type="checkbox"
                value={chiuretajCervSec42FS1?.value || false}
                checked={chiuretajCervSec42FS1?.value === 'true'}
                name="chiuretajCervSec42FS1"
                onChange={() => {}}
              />
              <Label>Chiuretaj endocervical </Label>
            </FormGroup>
          </Col>
        </Row>

        <Row className="mt-2 mb-2">
          <Col>
            <FormGroup check>
              <Input
                type="checkbox"
                value={chiuretajMetrSec42FS1?.value || false}
                checked={chiuretajMetrSec42FS1?.value === 'true'}
                name="chiuretajMetrSec42FS1"
                onChange={() => {}}
              />
              <Label>Chiuretaj endometrial </Label>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup check>
              <Input
                type="checkbox"
                value={repetareColp2Sec42FS1?.value || false}
                checked={repetareColp2Sec42FS1?.value === 'true'}
                name="repetareColp2Sec42FS1"
                onChange={() => {}}
              />
              <Label>Repetarea colposcopiei </Label>
            </FormGroup>
          </Col>
        </Row>

        <Row className="mt-2 mb-2">
          <Col>
            <FormGroup check>
              <Input
                type="checkbox"
                value={atitudineHistoSec42FS1?.value || false}
                checked={atitudineHistoSec42FS1?.value === 'true'}
                name="atitudineHistoSec42FS1"
                onChange={() => {}}
              />
              <Label>Atitudine în funcţie de rezultatul histopatologic  </Label>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup check>
              <Input
                type="checkbox"
                value={gradRiscHpvReintraresec42FS1?.value || false}
                checked={gradRiscHpvReintraresec42FS1?.value === 'true'}
                name="gradRiscHpvReintraresec42FS1"
                onChange={() => {}}
              />
              <Label>Reintrare în program în funcţie de gradul de risc dat de tipul de HPV (exemplu: 6 luni, 12 luni, 5 ani)  </Label>
            </FormGroup>
          </Col>
        </Row>

        <Col
          className="underline-hover form-group dont-display ng-untouched ng-pristine"
          style={{ backgroundColor: 'rgba(233, 236, 239, 0.3)', }}
        >
          <Label>
            Datele specifice rezultatelor biopsiei
          </Label>
        </Col>

        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>Centrul de servicii medicale specializate (laborator de anatomie patologică) unde s-a efectuat citirea biopsiei</Label>
              <Input
                required
                type="text"
                name="labAnatoPatoSec42FS1"
                disabled
                value={labAnatoPatoSec42FS1?.value || ''}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label>Medicul de specialitate anatomo-patolog care a efectuat citirea biopsiei </Label>
              <Input
                required
                type="text"
                name="medicAnatoPatoSec42FS1"
                disabled
                value={medicAnatoPatoSec42FS1?.value || ''}
              />

            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>Numărul biopsiei (autogenerat de sistem după salvare) </Label>
              <Input
                required
                type="text"
                name="nrBiopsieSec42FS1"
                disabled
                value={nrBiopsieSec42FS1?.value || ''}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>Data primirii biopsiei</Label>
              <Input
                required
                type="text"
                name="dataPrimireBiopsieSec42FS1"
                disabled
                value={dataPrimireBiopsieSec42FS1?.value ? moment(dataPrimireBiopsieSec42FS1.value).format('DD-MM-YYYY') : ''}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label>Data interpretării</Label>
              <Input
                required
                type="text"
                name="dataInterBiopsieSec42FS1"
                disabled
                value={dataInterBiopsieSec42FS1?.value ? moment(dataInterBiopsieSec42FS1.value).format('DD-MM-YYYY') : ''}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>Rezultatul histopatologic </Label>
              <Input
                required
                type="text"
                name="rezHistoPatoSec42FS1"
                disabled
                value={rezHistoPatoSec42FS1?.value || ''}
              />
            </FormGroup>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}

export default BiopsieView;
