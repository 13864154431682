export const educationCategories = [
  {
    value: 'ISCED_0',
    label: 'Studii educație timpurie (ISCED 0)',
  }, {
    value: 'ISCED_1',
    label: 'Studii primare (ISCED 1)',
  }, {
    value: 'ISCED_2',
    label: 'Studii gimnaziale (ISCED 2)',
  }, {
    value: 'ISCED_3',
    label: 'Studii liceale (ISCED 3)',
  }, {
    value: 'ISCED_4',
    label: 'Studii postliceale (ISCED 4)',
  }, {
    value: 'ISCED_5',
    label: 'Studii superioare (ISCED 5)',
  }, {
    value: 'ISCED_6',
    label: 'Studii superioare (ISCED 6)',
  }, {
    value: 'ISCED_7',
    label: 'Studii superioare (ISCED 7)',
  }, {
    value: 'ISCED_8',
    label: 'Studii superioare (ISCED 8)',
  }, {
    value: 'fara_ISCED',
    label: 'fără ISCED',
  }];

export const vulnerableCategories = [
  { value: 'media_venit_mic_minim_economie', label: 'Femei sărace angajate, mai ales necalificate (la intrarea în intervenție media venitului pe cap de familie mai mică decât salariul minim pe economie)', },
  { value: 'evidentele_SPO', label: 'Femei sărace șomere (înregistrate în evidențele SPO)', },
  { value: 'nu_evidentele_SPO', label: 'Femei sărace inactive - nu au un loc de muncă și nu sunt înregistrate în evidențele SPO', },
  { value: 'neasigurate', label: 'Femei sărace neasigurate', },
  { value: 'VMG, ASF', label: 'Femei sărace persoane beneficiare ale venitului minim garantat (VMG), ASF (alocației de susținerea familiei)', },
  { value: 'cont_propriu_in_agricultura', label: 'Femei care lucrează pe cont propriu în agricultură', },
  { value: 'din_mediul_rural', label: 'Femei din mediul rural', },
  { value: 'centre_plasament', label: 'Femei din sau care au fost anterior în centre de plasament', },
  { value: 'protectie_copil', label: 'Femei care au părăsit sistemul de protecție a copilului', },
  { value: 'fara_adapost', label: 'Femei fără adăpost', },
  { value: 'etnie_roma', label: 'Femei de etnie romă', },
  { value: 'cu_dizabilitati', label: 'Femei cu dizabilități, inclusiv persoane invalide și cu nevoi complexe', },
  { value: 'copii_cu_dizabilitati', label: 'Femei care au copii cu dizabilități', },
  { value: 'familii_monoparentale', label: 'Femei din familii monoparentale', },
  { value: 'dependenta', label: 'Femei care suferă de dependență de alcool, droguri și alte substanțe toxice', },
  { value: 'lipsite_de_libertate', label: 'Femei lipsite de libertate sau aflate sub control judiciar', }, { value: 'probatiune', label: 'Femei aflate în evidențele serviciilor de probațiune', }, { value: 'violentei domestice', label: 'Femei victime ale violenței domestice', }, { value: 'traficului_fiinte_umane', label: 'Femei victime ale traficului de ființe umane', }];

export const populationCategories = [
  {
    value: 'Inactiv_alta_categorie',
    label: 'Altă categorie de inactivi în afară de cei din educație și formare',
  }, {
    value: 'Angajat',
    label: 'Angajat',
  }, {
    value: 'Angajat_cont_propriu',
    label: 'Angajat pe cont propriu',
  }, {
    value: 'Somer',
    label: 'Șomer',
  }, {
    value: 'Somer_de_lunga_durata',
    label: 'Șomer de lungă durată',
  }, {
    value: 'Inactiv',
    label: 'Persoană inactivă (inclusiv copii antepreșcolari, preșcolari, elevi etc.)',
  }];

export const disadvantagedCategories = [
  {
    value: 'pers_ocupate',
    label: 'Participanți care trăiesc în gospodării fără persoane ocupate',
  }, {
    value: 'pers_ocupate_copii_intretinere',
    label: 'Participanți care trăiesc în gospodării fără persoane ocupate cu copii aflați în întreținere',
  }, {
    value: 'pers_ocupate_parinte_unic_copil_intretinere',
    label: 'Participanți care trăiesc în gospodării alcătuite dintr-un părinte unic cu copil aflat în întreținere',
  }, {
    value: 'migranti',
    label: 'Migranți',
  }, {
    value: 'origine_straina',
    label: 'Participanți de origine străină',
  }, {
    value: 'minoritati',
    label: 'Minorități',
  }, {
    value: 'pers_de_etnie_roma',
    label: 'Etnie romă',
  }, {
    value: 'alta_minoritate',
    label: 'Altă minoritate decât cea de etnie romă',
  }, {
    value: 'pers_comunitati_marginalizate',
    label: 'Persoane din comunități marginalizate',
  }, {
    value: 'participanti_dizabilitati',
    label: 'Participanți cu dizabilități',
  }, {
    value: 'alte_categorii_defav',
    label: 'Alte categorii defavorizate',
  }, {
    value: 'pers_fara_adapost',
    label: 'Persoane fără adăpost sau care sunt afectate de excluziunea locativă',
  }, {
    value: 'niciuna_din_optiuni',
    label: 'Niciuna din opțiunile de mai sus',
  }];
