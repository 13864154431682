import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';

function Pagination({ filters, addFilterAndSearch, totalNo, totalPages, }) {
  const [currentPage, setCurrentPage] = useState(0);
  const [size, setSize] = useState(10);

  useEffect(() => {
    const { page, size: pageSize, } = filters;
    if (page) setCurrentPage(Number(page - 1));
    setSize(pageSize || 10);
  }, [filters]);

  const handlePageClick = data => {
    const { selected, } = data;
    addFilterAndSearch({ page: selected + 1, }, true);
  };

  const goToFirstPage = () => {
    setTimeout(() => {
      handlePageClick({ selected: 0, });
    }, 10);
  };

  const goToLastPage = () => {
    setTimeout(() => {
      handlePageClick({ selected: Math.ceil(totalNo / size) - 1, });
    }, 10);
  };
  return (
    <div className="pagination border-top pt-3 mb-5 d-flex justify-content-center">
      <div className="page-item d-flex first-page  cursor-pointer">
        <span onClick={goToFirstPage} className="page-link">««</span>
      </div>
      <ReactPaginate
        previousLabel={(
          <div className="page-item d-flex">
            <span className="page-link">«</span>
          </div>
        )}
        nextLabel={(
          <div className="page-item d-flex last-page">
            <span className="page-link">»</span>
          </div>
        )}
        breakLabel={<div className="page-item"><span className="page-link">...</span></div>}
        breakClassName="break-me"
        pageCount={totalPages}
        pageRangeDisplayed={window.innerWidth > 600 ? 5 : 3}
        marginPagesDisplayed={window.innerWidth > 600 ? 2 : 0}
        onPageChange={handlePageClick}
        pageClassName="page-item"
        pageLinkClassName="page-link"
        containerClassName="pagination mb-0 d-flex justify-content-center"
        forcePage={currentPage}
        subContainerClassName="pages pagination"
        activeClassName="active"
      />
      <div className="page-item d-flex cursor-pointer">
        <span onClick={goToLastPage} className="page-link">»»</span>
      </div>
    </div>
  );
}

export default Pagination;
