import { combineReducers } from 'redux';
import types from './types';

const initialState = {
  organizations: [],
  selectedOrganization: null,
};

const organizations = (state = initialState.organizations, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const selectedOrganization = (state = initialState.selectedOrganization, action) => {
  switch (action.type) {
    case types.SELECT_ORGANIZATION:
      return action.payload;
    default:
      return state;
  }
};

export default combineReducers({
  organizations,
  selectedOrganization,
});
