import React from 'react';
import { faCheck, faTimes, faEye, faPencilAlt, faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink, useLocation } from 'react-router-dom';
import { Button } from 'reactstrap';

function OrganizationTableRow({ data, handleActiveModal, canEdit, canActivate, }) {
  const { id, code = '', label = '', description = '', type = '', services = [], active = true, } = data;
  //
  const servicesDom = services.map((a, i) => (
    <span className="text-uppercase" key={a.value}>
      {`${a.label}${i + 1 < services.length ? ', ' : ''}`}
    </span>
  ));

  const location = useLocation();

  return (
    <tr>
      <td>{code}</td>
      <td style={{ maxWidth: 450, }}>{label}</td>
      <td>{description}</td>
      <td style={{ minWidth: 70, }}><div>{type.replace('_', ' ')}</div></td>
      <td style={{ maxWidth: 250, }}>
        <div>
          {servicesDom}
        </div>
      </td>
      <td style={{ minWidth: 80, }}>
        { active
          ? <FontAwesomeIcon fontSize={24} icon={faCheck} />
          : <FontAwesomeIcon fontSize={24} icon={faTimes} />}
      </td>
      <td className="text-right" style={{ minWidth: 180, }}>
        <div className="actions">
          <NavLink
            className="btn btn-info btn-sm btn-pill mr-1"
            to={`/organization/${id}/view`}
            state={{ from: location, }}
          >
            <FontAwesomeIcon icon={faEye} />
          </NavLink>
          {
            canEdit && (
            <NavLink
              to={`/organization/${id}/edit`}
              state={{ from: location, }}
              className="btn btn-primary btn-sm btn-pill mr-1"
            >
              <FontAwesomeIcon icon={faPencilAlt} />
            </NavLink>
            )
          }
          {
            canActivate && (
              <>
                { active ? (
                  <div
                    style={{ width: '4rem', borderRadius: '20rem', }}
                    className="btn btn-outline-success text-right p-0 row-btn-wrapper"
                  >
                    <Button
                      onClick={() => handleActiveModal(data)}
                      color="success"
                      className="btn-pill p-0"
                    >
                      <FontAwesomeIcon icon={faCheckCircle} />
                    </Button>
                  </div>
                ) : (
                  <div
                    style={{ width: '4rem', borderRadius: '20rem', }}
                    className="btn btn-outline-danger text-left p-0 row-btn-wrapper"
                  >
                    <Button
                      onClick={() => handleActiveModal(data)}
                      color="danger"
                      className="btn-pill p-0"
                    >
                      <FontAwesomeIcon icon={faTimesCircle} />
                    </Button>
                  </div>
                )}
              </>
            )
          }
        </div>
      </td>
    </tr>
  );
}

export default OrganizationTableRow;
