import React, { Component } from 'react';
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  Col,
  FormGroup, Label, Input, Row
} from 'reactstrap';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faSave } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import { regionOptions, countyOptionsAll } from '../organization.helper';
import { cities } from '../../../helpers/orase';

class OrganizationAddressModal extends Component {
  constructor() {
    super();
    this.state = {
      selectedRegion: null,
      selectedCounty: null,
      selectedCity: null,
      countyOptions: [],
      cityOptions: [],
      apartmentNo: null,
      buildingNo: null,
      floor: null,
      id: null,
      location: null,
      street: null,
      streetNo: null,
      zipCode: null,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleRegionSelect = this.handleRegionSelect.bind(this);
    this.handleCountySelect = this.handleCountySelect.bind(this);
    this.handleCitySelect = this.handleCitySelect.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.prefillFields = this.prefillFields.bind(this);
  }

  componentDidMount() {
    const { address, auth, } = this.props;
    if (address !== null) {
      this.prefillFields(address);
    } else {
      const { region = '', } = auth;
      const selectedRegion = regionOptions.find(elem => elem.value === region.id);
      this.setState({
        selectedRegion: selectedRegion || null,
        countyOptions: selectedRegion ? countyOptionsAll[region.id] : null,
      });
    }
  }

  prefillFields(data) {
    const { auth, } = this.props;
    const { region, } = auth;
    const { county, city, } = data;
    const newState = { ...this.state, ...data, };

    let selectedRegion = regionOptions.find(elem => elem.value === region?.id || data.region.value);
    if (typeof selectedRegion === 'undefined') {
      selectedRegion = data.region;
    }
    const findCounty = countyOptionsAll[region?.id || data.region.value].find(elem => elem.value === county.value);
    const cityOptions = cities[county.value].map(a => ({ value: a.zip, label: a.name, isUrban: a.isUrban, }));
    // const selectedCity = cityOptions.find(elem => elem.value === city.value);
    const selectedCity = cityOptions.find(elem => elem.label === city.label);

    this.setState({
      ...newState,
      selectedRegion,
      countyOptions: countyOptionsAll[region?.id || data.region.value],
      selectedCounty: { value: findCounty.value, label: findCounty.label, },
      cityOptions,
      selectedCity,
    });
  }

  handleSubmit() {
    const { setAddress, close, } = this.props;
    const {
      id, street, zipCode, buildingNo, floor, apartmentNo, streetNo, selectedCity,
      selectedCounty, selectedRegion,
    } = this.state;
    setAddress({
      id,
      street,
      zipCode,
      buildingNo,
      floor,
      apartmentNo,
      streetNo,
      city: selectedCity,
      county: selectedCounty,
      region: selectedRegion,
    });
    close();
  }

  handleInputChange(e) {
    const { name, value, } = e.target;
    this.setState({ [name]: value, });
  }

  handleRegionSelect(val) {
    if (val !== null) {
      this.setState({
        selectedRegion: val,
        countyOptions: countyOptionsAll[val.value],
        cityOptions: [],
        selectedCounty: null,
        selectedCity: null,
      });
    } else {
      this.setState({
        selectedRegion: null,
        selectedCounty: null,
        selectedCity: null,
        countyOptions: [],
        cityOptions: [],
      });
    }
  }

  handleCountySelect(val) {
    if (val === null) {
      this.setState({
        county: '',
        selectedCounty: null,
        city: '',
        selectedCity: null,
      });
    } else {
      this.setState({
        county: val.value,
        cityOptions: cities[val.value].map(a => ({ value: a.zip, label: a.name, isUrban: a.isUrban, })),
        city: '',
        selectedCity: null,
        selectedCounty: val,
      });
    }
  }

  handleCitySelect(val) {
    if (val !== null) {
      this.setState({
        city: val.label,
        selectedCity: val,
      });
    } else {
      this.setState({
        city: '',
        selectedCity: null,
      });
    }
  }

  render() {
    const { close, auth, } = this.props;
    const { region, } = auth;
    const {
      street,
      streetNo,
      buildingNo,
      floor,
      apartmentNo,
      zipCode,
      countyOptions,
      cityOptions,
      selectedCounty,
      selectedCity,
      selectedRegion,
    } = this.state;

    const saveDisabled = (selectedCity === null) || (selectedCounty === '') || (selectedRegion === null);
    return (
      <Modal size="xl" isOpen>
        <ModalHeader className="p-4" toggle={close}>Creare sau editare Adresă</ModalHeader>
        <ModalBody className="p-4">
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label>Județ</Label>
                <Select
                  className={(!selectedCounty || selectedCounty.length === 0) ? 'select-invalid' : 'select-valid'}
                  classNamePrefix="rolesSelect"
                  placeholder=""
                  isClearable
                  name="county"
                  options={countyOptions}
                  onChange={this.handleCountySelect}
                  value={selectedCounty}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Localitate</Label>
                <Select
                  className={(!selectedCity || selectedCity.length === 0) ? 'select-invalid' : 'select-valid'}
                  classNamePrefix="rolesSelect"
                  placeholder=""
                  isClearable
                  name="city"
                  options={cityOptions}
                  onChange={this.handleCitySelect}
                  value={selectedCity}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label>Strada</Label>
                <Input
                  type="text"
                  name="street"
                  onChange={this.handleInputChange}
                  onBlur={this.handleInputChange}
                  value={street || ''}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Numărul</Label>
                <Input
                  type="text"
                  name="streetNo"
                  onChange={this.handleInputChange}
                  onBlur={this.handleInputChange}
                  value={streetNo || ''}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label>Bloc</Label>
                <Input
                  type="text"
                  name="buildingNo"
                  onChange={this.handleInputChange}
                  onBlur={this.handleInputChange}
                  value={buildingNo || ''}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Etaj</Label>
                <Input
                  type="text"
                  name="floor"
                  onChange={this.handleInputChange}
                  onBlur={this.handleInputChange}
                  value={floor || ''}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label>Apartament</Label>
                <Input
                  type="text"
                  name="apartmentNo"
                  onChange={this.handleInputChange}
                  onBlur={this.handleInputChange}
                  value={apartmentNo || ''}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Cod Poștal</Label>
                <Input
                  type="text"
                  name="zipCode"
                  onChange={this.handleInputChange}
                  onBlur={this.handleInputChange}
                  value={zipCode || ''}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label>Regiune</Label>
                {
                  typeof region !== 'undefined' ? (
                    <Input
                      type="text"
                      name="region"
                      disabled
                      value={selectedRegion?.label || ''}
                    />
                  ) : (
                    <Select
                      className={(!selectedRegion || selectedRegion.length === 0) ? 'select-invalid' : 'select-valid'}
                      classNamePrefix="rolesSelect"
                      value={selectedRegion}
                      placeholder=""
                      isClearable
                      name="region"
                      options={regionOptions}
                      onChange={this.handleRegionSelect}
                    />
                  )
                }
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Col md={6} className="text-right">
            <Button
              onClick={close}
              id="cancel-save"
              color="secondary"
            >
              <FontAwesomeIcon icon={faBan} />
              <span className="ml-1">Anulare</span>
            </Button>
            <Button
              disabled={saveDisabled}
              onClick={saveDisabled ? () => {} : this.handleSubmit}
              id="save"
              color="primary"
              className="ml-1"
            >
              <FontAwesomeIcon icon={faSave} />
              <span className="ml-1">Salvare</span>
            </Button>
          </Col>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  patients: state.patients.patients,
  selectedPatient: state.patients.selectedPatient,
});

export default connect(mapStateToProps, null)(OrganizationAddressModal);
