/* eslint-disable no-undef */
import React from 'react';
import { Alert } from 'reactstrap';
import { Navigate } from 'react-router-dom';
import NoPermissions from './NoPermissions';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, redirect: false, };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error, });
    // setTimeout(() => {
    //   this.setState({ redirect: true, });
    // }, 5000);
    // setTimeout(() => {
    //   window.location.reload();
    // }, 5300);
  }

  render() {
    const { error, redirect, } = this.state;
    const { children, } = this.props;

    if (redirect) {
      return <Navigate to="/patient" replace />;
    }
    if (error) {
      return (
        <NoPermissions errorMessage />
      );
    }
    return children;
  }
}

export default ErrorBoundary;
