import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, CardHeader, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import moment from 'moment';

function TestareHPVView({ data, changeTab, }) {
  const [inputs, setInputs] = useState({});

  useEffect(() => {
    const inputs = data.attributes;
    setInputs(inputs);
    if (Object.keys(inputs).length === 0) return;
    inputs.triajBpnSec34FS1.value = inputs.triajBpnSec34FS1.value.toString();
    if (inputs?.rezHpvSec34FS1?.value === 'HPV-HR(16, 18, 26, 31, 33, 35, 39, 45, 51, 52, 53, 56, 58, 59, 66, 73, 82)') {
      inputs.triajBpnSec34FS1.value = 'true';
    }
    if (inputs?.rezHpvSec34FS1?.value === 'Negativ') {
      inputs.triajBpnSec34FS1.value = 'false';
    }
  }, []);

  const setTab = e => { changeTab(e.target.id); };

  const {
    labSpecFlaconSec31FS1,
    medicAnatoPatoLamaSec31FS1,
    nrFlaconSec31FS1,
    dataPrimireFlaconSec31FS1,
    dataInterSec31FS1,
    echipamentSec31FS1,
    metodaSec31FS11,
    tehnicianSec31FS1,
    rezHpvSec34FS1,
    repetareHpvList,
    triajBpnSec34FS1,
    obs2Sec34FS1,
    dataElbRezHpvSec34FS1,
  } = inputs;

  return (
    <Card>
      <CardHeader>
        <h3 className="mb-0">
          <div className="d-flex">
            <div>
              <span className="mr-3">3A. Testare HPV</span>
            </div>
            <div className="ml-auto">
              <Button id="-" size="sm" color="secondary" className="ml-auto btn-pill" onClick={setTab}>{'<'}</Button>
              <Button id="+" size="sm" color="primary" className="ml-1 btn-pill" onClick={setTab}>{'>'}</Button>
            </div>
          </div>
        </h3>

      </CardHeader>
      <CardBody className="pb-5">
        <Col
          className="underline-hover form-group dont-display ng-untouched ng-pristine"
          style={{ backgroundColor: 'rgba(233, 236, 239, 0.3)', }}
        >
          <Label>
            Datele generale ale analizei
          </Label>
        </Col>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>Laborator HPV</Label>
              <Input
                required
                type="text"
                name="labSpecFlaconSec31FS1"
                disabled
                value={labSpecFlaconSec31FS1?.value || ''}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label>Personal de specialitate care certifică rezultatele</Label>
              <Input
                required
                type="text"
                name="medicAnatoPatoLamaSec31FS1"
                disabled
                value={medicAnatoPatoLamaSec31FS1?.value || ''}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>Numărul probei HPV</Label>

              <Input
                required
                type="text"
                name="nrFlaconSec31FS1"
                disabled
                value={nrFlaconSec31FS1?.value || ''}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>Data primirii probei</Label>

              <Input
                required
                type="text"
                name="dataPrimireFlaconSec31FS1"
                disabled
                value={dataPrimireFlaconSec31FS1?.value ? moment(dataPrimireFlaconSec31FS1.value).format('DD-MM-YYYY') : ''}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label>
                Data interpretării
              </Label>
              <Input
                required
                type="text"
                name="dataInterSec31FS1"
                disabled
                value={dataInterSec31FS1?.value ? moment(dataInterSec31FS1.value).format('DD-MM-YYYY') : ''}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>Echipament</Label>
              <Input
                required
                type="text"
                name="echipamentSec31FS1"
                disabled
                value={echipamentSec31FS1?.value || ''}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label>Metodă</Label>
              <Input
                type="text"
                name="metodaSec31FS11"
                disabled
                value={metodaSec31FS11?.value || ''}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>Tehnician/asistent</Label>
              <Input
                required
                type="text"
                name="tehnicianSec31FS1"
                disabled
                value={tehnicianSec31FS1?.value || ''}
              />
            </FormGroup>
          </Col>
        </Row>

        <Col
          className="underline-hover form-group dont-display ng-untouched ng-pristine"
          style={{ backgroundColor: 'rgba(233, 236, 239, 0.3)', }}
        >
          <Label>
            Date specifice pentru examenul HPV
          </Label>
        </Col>

        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>Rezultat HPV</Label>
              <Input
                required
                type="text"
                name="rezHpvSec34FS1"
                disabled
                value={rezHpvSec34FS1?.value || ''}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label>Repetare HPV</Label>
              <Input
                required
                type="text"
                name="repetareHpvList"
                disabled
                value={repetareHpvList?.value || ''}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup check>
              <Input
                type="checkbox"
                checked={triajBpnSec34FS1?.value === 'true'}
                value={triajBpnSec34FS1?.value || false}
                name="triajBpnSec34FS1"
                onChange={() => {}}
              />
              <Label>Triaj BPN </Label>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Label>Observații</Label>
              <Input
                required
                type="textarea"
                rows={3}
                name="obs2Sec34FS1"
                disabled
                value={obs2Sec34FS1?.value || ''}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>Data eliberării rezultatului</Label>
              <Input
                required
                type="text"
                name="dataElbRezHpvSec34FS1"
                disabled
                value={dataElbRezHpvSec34FS1?.value ? moment(dataElbRezHpvSec34FS1.value).format('DD-MM-YYYY') : ''}
              />
            </FormGroup>
          </Col>
        </Row>

      </CardBody>
    </Card>
  );
}

export default TestareHPVView;
