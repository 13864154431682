import React from 'react';
import {
  faBuilding,
  faChartPie, faClinicMedical, faClipboard, faEnvelope,
  faFile,
  faFilePdf, faLock, faSignOut, faUser,
  faUsers, faUserTag
} from '@fortawesome/free-solid-svg-icons';

export const statisticsMenuData = [
  { path: '/indicator', icon: faChartPie, title: 'Indicatori', },
  // { path: '/reporting', icon: faChartPie, title: 'Raportare', },
  { path: '/reporting-oir', icon: faChartPie, title: 'Raportare furnizor', },
  { path: '/reporting-statistics', icon: faChartPie, title: 'Raportare si Statistica', }
];

export const administrationMenuData = [
  { path: '/nomen', icon: faFile, title: 'Nomen', },
  { path: '/template', icon: faFilePdf, title: 'Șablon', },
  { path: '/role', icon: faUserTag, title: 'Permisiune', },
  { path: '/group', icon: faUsers, title: 'Rol', },
  { path: '/office', icon: faClinicMedical, title: 'Birou', },
  { path: '/organization', icon: faBuilding, title: 'Furnizor', },
  { path: '/form', icon: faClipboard, title: 'Formulare', },
  { path: '/user', icon: faUser, title: 'Utilizator', }
];
export const userMenuData = [
  { path: '/logout', withCallback: true, icon: faSignOut, title: 'Ieșire', },
  { path: '/check/change-pass', icon: faLock, title: 'Resetare parolă', },
  { path: '/notifications', icon: faEnvelope, title: 'Mesaje', }
];
