import React from 'react';

function IndicatorTableRow({ data, }) {
  const { name = '', value = '', active = true, } = data;

  return (
    <tr>
      <td>{name}</td>
      <td style={{ maxWidth: 450, }}>{value}</td>
    </tr>
  );
}

export default IndicatorTableRow;
