import React, { Component } from 'react';
import { FormGroup, Input } from 'reactstrap';

class OrganizationServicePriceSection extends Component {
  constructor() {
    super();
    this.state = {
      MOBILIZATOR: '',
      RECOLTOR: '',
      LABORATOR: '',
      MANAGEMENT: '',
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.prefillPriceValues = this.prefillPriceValues.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { servicePrice, } = this.props;
    if (servicePrice !== null && prevProps.servicePrice === null) {
      this.prefillPriceValues();
    }
  }

  prefillPriceValues() {
    const { servicePrice, } = this.props;
    const newState = { ...this.state, ...servicePrice, };
    this.setState({ ...newState, });
  }

  handleInputChange(e) {
    const { name, value, } = e.target;
    const { setPriceValues, } = this.props;
    this.setState({ [name]: Number(value), }, () => {
      setPriceValues(this.state);
    });
  }

  render() {
    const { servicesSimple, } = this.props;
    const { MOBILIZATOR, RECOLTOR, LABORATOR, MANAGEMENT, } = this.state;

    return (
      <div className="p-2">
        {servicesSimple.includes('MOBILIZATOR') && (
          <FormGroup>
            <label>Tarif serviciu consiliere-informare</label>
            <div className="input-group">
              <Input
                type="number"
                min={0}
                name="MOBILIZATOR"
                className="form-control"
                onChange={this.handleInputChange}
                value={MOBILIZATOR}
              />
              <div className="input-group-append"><span className="input-group-text">RON</span></div>
            </div>
          </FormGroup>
        )}
        {servicesSimple.includes('RECOLTOR') && (
          <FormGroup>
            <label>Tarif serviciu recoltare</label>
            <div className="input-group">
              <Input
                type="number"
                min={0}
                name="RECOLTOR"
                className="form-control"
                onChange={this.handleInputChange}
                value={RECOLTOR}
              />
              <div className="input-group-append"><span className="input-group-text">RON</span></div>
            </div>
          </FormGroup>
        )}
        {(servicesSimple.includes('LABORATOR_HPV') || servicesSimple.includes('LABORATOR_PAP')) && (
          <FormGroup>
            <label>Tarif serviciu analize medicale</label>
            <div className="input-group">
              <Input
                type="number"
                min={0}
                name="LABORATOR"
                className="form-control"
                onChange={this.handleInputChange}
                value={LABORATOR}
              />
              <div className="input-group-append"><span className="input-group-text">RON</span></div>
            </div>
          </FormGroup>
        )}
        {(servicesSimple.includes('LABORATOR_HPV') || servicesSimple.includes('LABORATOR_PAP'))
      && servicesSimple.includes('MOBILIZATOR') && servicesSimple.includes('RECOLTOR')
      && (
        <FormGroup>
          <label>Tarif serviciu management</label>
          <div className="input-group">
            <Input
              type="number"
              min={0}
              name="MANAGEMENT"
              className="form-control"
              onChange={this.handleInputChange}
              value={MANAGEMENT}
            />
            <div className="input-group-append"><span className="input-group-text">RON</span></div>
          </div>
        </FormGroup>
      )}
      </div>
    );
  }
}

export default OrganizationServicePriceSection;
