import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router';
import * as yup from 'yup';
import { authOperations } from '../../../redux/ducks/auth';

import './index.scss';

const schema = yup.object().shape({ email: yup.string().email(), });

function ForgotPassword({ requestPasswordReset, }) {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [error, setError] = useState(true);
  const [success, setSuccess] = useState(false);
  const [mailError, setMailError] = useState(false);

  const handleSubmit = () => {
    schema.validate({ email, }).then(() => {
      requestPasswordReset({ email, })
        .then(res => {
          setSuccess('Vă rugăm să vă verificați e-mailul și să urmați instrucțiunile primite.');
        })
        .catch(err => {
          setMailError('E-mailul nu a putut fi trimis.');
          console.log(err);
        });
    }).catch(err => {
      console.log('err: ', err);
    });
  };

  const handleEmailChange = e => {
    setEmail(e.target.value);
    if (mailError) { setMailError(false); }
    if (success) { setSuccess(false); }
    schema.validate({ email: e.target.value, }).then(a => {
      setError(false);
    }).catch(err => {
      setError(true);
    });
  };

  const redirect = () => { navigate('/check'); };

  return (
    <div className="container d-flex justify-content-center pt-5">
      <div className="mt-5" />
      <div className="login-form mt-5">
        <div className="login-form-body">
          <div className="form-group">
            <input
              onChange={handleEmailChange}
              type="text"
              id="email"
              name="email"
              className={`form-control ${(email === '' || error) ? 'invalid' : 'valid'}`}
              placeholder="Email utilizator"
              value={email}
            />
          </div>
          <div className="form-group">
            <button
              onClick={handleSubmit}
              type="submit"
              className={`btn btn-primary ${(email === '' || error) && 'disabled-btn'}`}
            >
              Trimite mail
            </button>
          </div>
          {
            success && (
              <div className="text-success">{success}</div>
            )
          }
          {
          mailError && (
            <div className="text-error">{mailError}</div>
          )
          }
        </div>
        <div className="login-form-footer">
          <div onClick={redirect} className="login-form-footer-message">Autentificare</div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  auth: state.auth,
  users: state.users.users,
  selectedUser: state.users.selectedUser,
});

const mapDispatchToProps = dispatch => ({ requestPasswordReset: data => dispatch(authOperations.requestPasswordReset(data)), });

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
