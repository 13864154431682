import React from 'react';
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row
} from 'reactstrap';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faSave } from '@fortawesome/free-solid-svg-icons';
import * as yup from 'yup';
import { connect } from 'react-redux';
import moment from 'moment';
import DoctorAddresses from './DoctorAddresses';
import DoctorContacts from './DoctorContacts';
import { doctorsOperations } from '../../../../redux/ducks/doctors';
import { organizationsOperations } from '../../../../redux/ducks/organizations';

const specializationOptions = [
  {
    value: 'MEDICINA_DE_FAMILIE',
    label: 'MEDICINĂ DE FAMILIE',
  }, {
    value: 'ANATOMIE_PATOLOGICA',
    label: 'ANATOMIE PATOLOGICĂ',
  }, {
    value: 'BIOLOG',
    label: 'BIOLOG',
  }, {
    value: 'MEDICINA_DE_LABORATOR',
    label: 'MEDICINĂ DE LABORATOR',
  }, {
    value: 'GINECOLOGIE',
    label: 'GINECOLOGIE',
  }, {
    value: 'ASISTENT',
    label: 'ASISTENT',
  }, {
    value: 'TEHNICIAN',
    label: 'TEHNICIAN',
  }, {
    value: 'OBSTETRICA-GINECOLOGIE',
    label: 'OBSTETRICA-GINECOLOGIE',
  }];

const validations = {
  lastName: yup.object().shape({ lastName: yup.string().required(), }),
  firstName: yup.object().shape({ firstName: yup.string().required(), }),
};

class DoctorForm extends React.Component {
  constructor() {
    super();
    this.state = {
      id: null,
      lastName: '',
      lastNameError: false,
      firstName: '',
      firstNameError: false,
      cnp: '',
      cnpError: false,
      birthDate: '',
      birthDateError: false,
      organizationServices: [],
      seal: '',
      casContract: '',
      specialization: null,
      contacts: [],
      addresses: [],
      selectedSupplier: null,
      suppliers: [],
      organizationsExt: [],
      saving: false,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleBtnClick = this.handleBtnClick.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleRoleSelect = this.handleRoleSelect.bind(this);
    this.handleSupplierSelect = this.handleSupplierSelect.bind(this);
    this.handleSpecializationSelect = this.handleSpecializationSelect.bind(this);
    this.handleOrganizationServiceSelect = this.handleOrganizationServiceSelect.bind(this);
    this.handleAddressChange = this.handleAddressChange.bind(this);
    this.handleContactsChange = this.handleContactsChange.bind(this);
    this.cancel = this.cancel.bind(this);
    this.getSingleDoctor = this.getSingleDoctor.bind(this);
    this.prefillFields = this.prefillFields.bind(this);
    this.calcDisabled = this.calcDisabled.bind(this);
    this.validateCnp = this.validateCnp.bind(this);
  }

  componentDidMount() {
    const { location, auth, navigate, getOrganizationsExt, } = this.props;

    if (!auth?.create.includes('DOCTOR_CREATE')) {
      setTimeout(() => {
        navigate('/');
      }, 10);
    } else {
      getOrganizationsExt()
        .then((res => {
          this.setState({ organizationsExt: res.data.data, });
          const splitUrl = location.pathname.split('/doctor/')[1];
          if (splitUrl !== 'new') {
            this.getSingleDoctor(splitUrl);
          }
        }))
        .catch((err => {
          console.log('err: ', err);
        }));
    }
  }

  getSingleDoctor(url) {
    const { navigate, getSingleDoctor, } = this.props;
    const id = url.split('/')[0];
    getSingleDoctor(id)
      .then((res => {
        this.prefillFields(res.data.data);
      }))
      .catch((err => {
        console.log('err: ', err);
        setTimeout(() => { navigate('/doctor'); }, 100);
      }));
  }

  prefillFields(data) {
    const {
      id, active, seal, casContract, specializationType, services,
      person: { lastName, firstName, cnp, birthDate = '', gender, habitat, addresses = [], contacts = [], },
      organization: { id: orgId, label: orgLabel, services: orgServices, },
    } = data;

    const { organizationsExt, } = this.state;

    this.setState({
      active,
      id,
      lastName,
      firstName,
      cnp,
      birthDate: birthDate !== '' ? `${birthDate.split('-')[2]}-${birthDate.split('-')[1]}-${birthDate.split('-')[0]}` : '',
      gender,
      habitat,
      selectedSupplier: { id: orgId, value: orgLabel, services: orgServices, },
      organizationServices: services,
      seal,
      casContract,
      specialization: specializationType,
      addresses,
      contacts,
    });
  }

  handleSubmit() {
    this.setState({ saving: true, });
    const { createDoctor, updateDoctor, } = this.props;
    const {
      id, seal, casContract, specialization, firstName, lastName, gender, birthDate, cnp, habitat,
      addresses, contacts,
      selectedSupplier,
      organizationServices,
    } = this.state;

    const payload = {
      id,
      seal,
      casContract,
      specializationType: specialization,
      person: {
        firstName,
        lastName,
        gender,
        birthDate: birthDate !== '' ? `${birthDate.split('-')[2]}-${birthDate.split('-')[1]}-${birthDate.split('-')[0]}` : '',
        cnp,
        habitat,
        addresses,
        contacts,
      },
      organization: { id: selectedSupplier.id, },
      services: organizationServices,
    };
    if (id === null) {
      // console.log('CREATE PAYLOAD: ', payload);
      delete payload.id;
      createDoctor(payload).then(res => {
        const { navigate, } = this.props;
        setTimeout(() => {
          navigate(`/doctor/${res.data?.data?.id}/view`);
        }, 100);
      }).catch(error => {
        console.log('err: ', error.data.message);
        if (error && error.data && error.data.message) {
          if (error.data.message.indexOf('CNP invalid') !== -1) {
            this.setState({ cnpError: ['CNP-ul nu este valid'], });
          }
        }
        this.setState({ saving: false, });
      });
    } else {
      // console.log('UPDATE PAYLOAD: ', payload);
      updateDoctor(payload).then(() => {
        const { navigate, } = this.props;
        setTimeout(() => {
          navigate(`/doctor/${payload.id}/view`);
        }, 100);
      }).catch(error => {
        console.log('err: ', error);
        this.setState({ saving: false, });
      });
    }
  }

  handleAddressChange(value, type) {
    const { addresses, } = this.state;
    if (type === 'remove') {
      const newAddresses = addresses.filter(elem => elem.id !== value.id);
      this.setState({ addresses: newAddresses, });
    } else if (value.id === null) {
      value.id = `${value?.city?.value || ''}${value?.streetNo || ''}${value?.apartmentNo || ''}`;
      const newAddresses = [...addresses, value];
      this.setState({ addresses: newAddresses, });
    } else {
      const { id, } = value;
      const newAddresses = addresses.map(el => {
        if (el.id === id) {
          return { ...el, ...value, };
        }
        return el;
      });
      this.setState({ addresses: newAddresses, });
    }
  }

  handleContactsChange(value, type) {
    const { contacts, } = this.state;
    if (type === 'remove') {
      const newContacts = contacts.filter(elem => elem.id !== value.id);
      this.setState({ contacts: newContacts, });
    } else if (typeof value.id === 'undefined' || value.id === null) {
      value.id = `${value?.label || ''}`;
      const newContacts = [...contacts, value];
      this.setState({ contacts: newContacts, });
    } else {
      const { id, } = value;

      const newContacts = contacts.map(el => {
        if (el.id === id) {
          return { ...el, ...value, };
        }
        return el;
      });
      this.setState({ contacts: newContacts, });
    }
  }

  validateCnp(cnpValue) {
    if (cnpValue === '') {
      this.setState({ cnpError: false, });
    } else {
      const regExp = /[a-zA-Z]/g;
      const cnpErrors = [];

      if (cnpValue.length > 0 && cnpValue.length !== 13) {
        cnpErrors.push('CNP-ul este format din 13 cifre!');
      }
      if (regExp.test(cnpValue)) {
        cnpErrors.push('Sunt permise doar cifre!');
      }
      const mask = [2, 7, 9, 1, 4, 6, 3, 5, 8, 2, 7, 9];
      let sum = 0;
      for (let i = 0; i < cnpValue.length - 1; i++) {
        sum += parseInt(cnpValue[i]) * mask[i];
      }
      const mod = (sum % 11 == 10) ? 1 : sum % 11;
      const isValid = (mod == cnpValue[mask.length]);
      // console.log('isValid: ', isValid);
      if (!isValid) {
        cnpErrors.push('CNP-ul nu este valid!');
      } else if (cnpValue[0] == 1 || cnpValue[0] == 3 || cnpValue[0] == 5 || cnpValue[0] == 7) {
        this.setState({ gender: 'M', });
      } else if (cnpValue[0] == 2 || cnpValue[0] == 4 || cnpValue[0] == 6 || cnpValue[0] == 8) {
        this.setState({ gender: 'F', }, () => {
          // calc varsta intre 24 si 65
          // console.log('calc varsta');
          let century = null; const year = 0; const month = 0; const
            day = 0;
          let dataNasterii;
          if (cnpValue[0] == 1 || cnpValue[0] == 2) {
            century = '19';
          }
          if (cnpValue[0] == 3 || cnpValue[0] == 4) {
            century = '18';
          }
          if (cnpValue[0] == 5 || cnpValue[0] == 6) {
            century = '20';
          }
          if (century != null) {
            // dataNasterii = (`${cnpValue[5]}${cnpValue[6]}/${cnpValue[3]}${cnpValue[4]}/${century}${cnpValue[1]}${cnpValue[2]}`);
            dataNasterii = (`${century}${cnpValue[1]}${cnpValue[2]}-${cnpValue[3]}${cnpValue[4]}-${cnpValue[5]}${cnpValue[6]}`);
          }
          const ppp = moment(dataNasterii, 'YYYY-MM-DD').toDate();
          this.setState({ birthDate: ppp, });
        });
      }
      this.setState({ cnpError: cnpErrors, });
    }
  }

  handleInputChange(e) {
    const { name, value, required, } = e.target;
    this.setState({ [name]: value, }, () => {
      if (required) {
        validations[name].validate({ [name]: value, }).then(() => {
          this.setState({ [`${name}Error`]: false, });
        }).catch(() => {
          this.setState({ [`${name}Error`]: true, });
        });
      }
      if (name === 'cnp') {
        this.validateCnp(value);
      }
    });
  }

  handleBtnClick(e) {
    const { name, } = e.target;
    const split = name.split('-');
    this.setState({ [split[0]]: split[1], });
  }

  handleRoleSelect(val) {
    this.setState({
      roles: [...val],
      rolesSimple: [...val].map(a => a.value),
    });
  }

  handleSupplierSelect(val) {
    this.setState({ selectedSupplier: val, organizationServices: [], });
  }

  handleSpecializationSelect(val) {
    this.setState({ specialization: val, });
  }

  handleOrganizationServiceSelect(val) {
    this.setState({ organizationServices: [...val], });
  }

  cancel() {
    const { navigate, } = this.props;
    navigate(-1);
  }

  calcDisabled() {
    const {
      lastName, lastNameError, cnpError,
      firstName, firstNameError, selectedSupplier, organizationServices,
    } = this.state;
    let disabled = false;
    if (cnpError.length > 0) disabled = true;
    if (lastName === '' || lastNameError === true) disabled = true;
    if (firstName === '' || firstNameError === true) disabled = true;
    if (selectedSupplier === '' || selectedSupplier === null) disabled = true;
    if (organizationServices.length === 0) disabled = true;

    return disabled;
  }

  render() {
    const {
      lastName, lastNameError,
      firstName, firstNameError,
      cnp, cnpError,
      birthDate, birthDateError,
      gender,
      habitat,
      seal,
      casContract,
      organizationServices,
      specialization,
      suppliers,
      selectedSupplier,
      contacts = [], addresses = [], organizationsExt = [],
      saving,
    } = this.state;

    const saveDisabled = this.calcDisabled();
    return (
      <Container fluid className={`d-flex ${saving ? 'no-events' : ''}`}>
        <Row className="justify-content-center w-100">
          <Col sm={8}>
            <Card className="mt-3">
              <CardHeader>
                <h3 className="mb-0">Creare sau editare Persoană</h3>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col className="pr-4">
                    <FormGroup row>
                      <Label sm={3}>Nume</Label>
                      <Col sm={9}>
                        <Input
                          required
                          type="text"
                          name="lastName"
                          className={`${(lastName === '' || lastNameError) && 'invalid'} `}
                          onChange={this.handleInputChange}
                          onBlur={this.handleInputChange}
                          value={lastName}
                        />
                        { lastName === '' && lastNameError && <p className="error text-danger mt-1 mb-1">Acest câmp este necesar.</p>}
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label sm={3}>Prenume</Label>
                      <Col sm={9}>
                        <Input
                          required
                          type="text"
                          name="firstName"
                          className={`${(firstName === '' || firstNameError) && 'invalid'} `}
                          onChange={this.handleInputChange}
                          onBlur={this.handleInputChange}
                          value={firstName}
                        />
                        { firstName === '' && firstNameError && <p className="error text-danger mt-1 mb-1">Acest câmp este necesar.</p>}
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label sm={3}>CNP</Label>
                      <Col sm={9}>
                        <Input
                          type="text"
                          name="cnp"
                          className={`${cnp !== '' && cnpError && 'invalid'} `}
                          onChange={this.handleInputChange}
                          onBlur={this.handleInputChange}
                          value={cnp}
                        />
                        <div className="d-flex flex-column">
                          { cnp !== '' && cnpError && (
                            cnpError.map(a => <p key={a} className="error text-danger mt-1 mb-1">{a}</p>)
                          )}
                        </div>
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label sm={3}>Data nașterii</Label>
                      <Col sm={9}>
                        <Input
                          disabled
                          type="text"
                          name="birthDate"
                          className={`${birthDateError && 'invalid'} `}
                          onChange={this.handleInputChange}
                          onBlur={this.handleInputChange}
                          value={birthDate !== '' ? birthDate : ''}
                        />
                        { birthDateError && (<p className="error text-danger mt-1 mb-1">Error</p>)}
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label sm={3}>Gen</Label>
                      <Col sm={9}>
                        <ButtonGroup size="sm">
                          <Button name="gender-M" color="primary" outline={gender !== 'M'}>M</Button>
                          <Button name="gender-F" color="primary" outline={gender !== 'F'}>F</Button>
                        </ButtonGroup>
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label sm={3}>Mediu</Label>
                      <Col sm={9}>
                        <ButtonGroup size="sm">
                          <Button onClick={this.handleBtnClick} name="habitat-Rural" color="primary" outline={habitat !== 'Rural'}>Rural</Button>
                          <Button onClick={this.handleBtnClick} name="habitat-Urban" color="primary" outline={habitat !== 'Urban'}>Urban</Button>
                        </ButtonGroup>
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label sm={3}>Furnizor</Label>
                      <Col sm={9}>
                        <FormGroup>
                          <Select
                            getOptionValue={option => option.id}
                            getOptionLabel={option => option.value || option.label}
                            value={selectedSupplier}
                            className={selectedSupplier ? 'select-valid' : 'select-invalid'}
                            classNamePrefix="rolesSelect"
                            placeholder=""
                            isClearable
                            name="suppliers"
                            options={organizationsExt}
                            onChange={this.handleSupplierSelect}
                          />
                        </FormGroup>
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label sm={3}>Servicii</Label>
                      <Col sm={9}>
                        <Select
                          value={organizationServices}
                          isMulti
                          className={organizationServices.length === 0 ? 'select-invalid' : 'select-valid'}
                          classNamePrefix="rolesSelect"
                          placeholder=""
                          isClearable
                          name="organizationServices"
                          options={selectedSupplier?.services || []}
                          onChange={this.handleOrganizationServiceSelect}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label sm={3}>Parafă</Label>
                      <Col sm={9}>
                        <Input
                          type="text"
                          name="seal"
                          onChange={this.handleInputChange}
                          value={seal}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label sm={3}>CAS Contract</Label>
                      <Col sm={9}>
                        <Input
                          type="text"
                          name="casContract"
                          onChange={this.handleInputChange}
                          value={casContract}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label sm={3}>Tip</Label>
                      <Col sm={9}>
                        <FormGroup>
                          <Select
                            value={specialization}
                            classNamePrefix="rolesSelect"
                            className="select-valid"
                            placeholder=""
                            isClearable
                            name="specialization"
                            options={specializationOptions}
                            onChange={this.handleSpecializationSelect}
                          />
                        </FormGroup>
                      </Col>
                    </FormGroup>
                  </Col>
                  <Col className="pl-4">
                    <DoctorContacts
                      contacts={contacts}
                      handleContactsChange={this.handleContactsChange}
                    />
                    <DoctorAddresses
                      isRequired={false}
                      addresses={addresses}
                      handleAddressChange={this.handleAddressChange}
                    />
                  </Col>
                </Row>

                <Row className="mt-3">
                  <Col md={6} />
                  <Col md={6} className="text-right">
                    <Button
                      onClick={this.cancel}
                      id="cancel-save"
                      color="secondary"
                    >
                      <FontAwesomeIcon icon={faBan} />
                      <span className="ml-1">Anulare</span>
                    </Button>
                    <Button
                      disabled={saveDisabled}
                      onClick={saveDisabled ? () => {} : this.handleSubmit}
                      id="save"
                      color="primary"
                      className="ml-1"
                    >
                      <FontAwesomeIcon icon={faSave} />
                      <span className="ml-1">Salvare</span>
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}
const mapStateToProps = state => ({
  auth: state.auth,
  users: state.users.users,
  selectedUser: state.users.selectedUser,
});

const mapDispatchToProps = dispatch => ({
  createDoctor: data => dispatch(doctorsOperations.createDoctor(data)),
  updateDoctor: data => dispatch(doctorsOperations.updateDoctor(data)),
  getSingleDoctor: id => dispatch(doctorsOperations.getSingleDoctor(id)),
  getOrganizationsExt: () => dispatch(organizationsOperations.getOrganizationsExt()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DoctorForm);
