/* eslint-disable no-undef */
import actions from './actions';

const getPatients = url => dispatch => new Promise(((resolve, reject) => {
  dispatch(actions.getPatients(url))
    .then(res => {
      resolve(res);
    })
    .catch(err => {
      reject(err);
    });
}));

const getSinglePatient = id => dispatch => new Promise(((resolve, reject) => {
  dispatch(actions.getSinglePatient(id))
    .then(res => {
      resolve(res);
    })
    .catch(err => {
      reject(err);
    });
}));

const downloadPatient = id => dispatch => new Promise(((resolve, reject) => {
  dispatch(actions.downloadPatient(id))
    .then(res => {
      resolve(res);
    })
    .catch(err => {
      reject(err);
    });
}));

const createPatient = data => dispatch => new Promise(((resolve, reject) => {
  dispatch(actions.createPatient(data))
    .then(res => {
      resolve(res);
    })
    .catch(err => {
      reject(err);
    });
}));

const updatePatient = data => dispatch => new Promise(((resolve, reject) => {
  dispatch(actions.updatePatient(data))
    .then(res => {
      resolve(res);
    })
    .catch(err => {
      reject(err);
    });
}));

export default {
  createPatient,
  updatePatient,
  getPatients,
  getSinglePatient,
  downloadPatient,
};
