import React, { useEffect, useState } from 'react';
import { Button, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faRedoAlt, faSearch } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';

const stareOptions = [
  { value: 'activ', label: 'activ', },
  { value: 'inactiv', label: 'inactiv', }
];

function PatientFilterExtended({ type, setFilterType, addFilterAndSearch, resetFilters, filters, }) {
  const [inputs, setInputs] = useState({});
  const [active, setActive] = useState('');

  useEffect(
    () => {
      const { firstName = '', lastName = '', cnp = '', id = '', doctor = '', city = '', county = '', } = filters;
      setInputs({
        firstName,
        lastName,
        cnp,
        id,
        city,
        doctor,
        county,
      });
    },
    [filters.firstName, filters.lastName, filters.cnp, filters.id, filters.city, filters.doctor, filters.county]
  );

  useEffect(() => {
    const { active: activeFilter, } = filters;
    if (activeFilter && activeFilter !== '') {
      setActive({ value: filters.active, label: filters.active, });
    }
  }, [filters.active]);

  const handleChange = e => {
    setInputs(prevState => ({ ...prevState, [e.target.name]: e.target.value, }));
  };

  const handleFilterType = () => {
    setFilterType(type);
  };

  const handleSearch = () => {
    addFilterAndSearch({ firstName, lastName, cnp, id, city, doctor, county, active, });
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const handleActiveChange = selected => {
    if (selected === null) {
      setActive('');
      addFilterAndSearch({ ...inputs, active: '', }, false, 'active');
    } else {
      setActive(selected);
      addFilterAndSearch({ ...inputs, active: selected.value, });
    }
  };

  const handleResetFilters = () => {
    setActive('');
    resetFilters();
  };

  const { firstName, lastName, cnp, id, city, doctor, county, } = inputs;

  return (
    <div className="searchFormFields mt-4">
      <Row>
        <Col className="text-right">
          <Button
            onClick={handleResetFilters}
            className="btn btn-light btn-sm btn-pill"
          >
            <FontAwesomeIcon icon={faRedoAlt} />
          </Button>
          <Button
            onClick={handleSearch}
            className="btn btn-light btn-sm btn-pill"
          >
            <FontAwesomeIcon icon={faSearch} />
          </Button>
          <Button
            onClick={handleFilterType}
            className="btn btn-light btn-sm btn-pill"
          >
            <FontAwesomeIcon icon={faChevronUp} />
          </Button>
        </Col>
      </Row>

      <Row>
        <Col sm={4} lg={3}>
          <FormGroup>
            <Label>
              <small className="ml-sm-2 font-weight-bold">Numele beneficiarului</small>
            </Label>
            <Input
              name="lastName"
              value={lastName || ''}
              onKeyDown={handleKeyDown}
              onChange={handleChange}
              type="text"
              className="form-control"
              placeholder="Nume"
            />
          </FormGroup>
        </Col>
        <Col sm={4} lg={3}>
          <FormGroup>
            <Label>
              <small className="ml-sm-2 font-weight-bold">Prenumele beneficiarului</small>
            </Label>
            <Input
              name="firstName"
              value={firstName || ''}
              onKeyDown={handleKeyDown}
              onChange={handleChange}
              type="text"
              className="form-control"
              placeholder="Prenume"
            />
          </FormGroup>
        </Col>
        <Col sm={4} lg={3}>
          <FormGroup>
            <Label>
              <small className="ml-sm-2 font-weight-bold">CNP beneficiar</small>
            </Label>
            <Input
              name="cnp"
              value={cnp || ''}
              onKeyDown={handleKeyDown}
              onChange={handleChange}
              type="text"
              className="form-control"
              placeholder="CNP beneficiar"
            />
          </FormGroup>
        </Col>
        <Col sm={4} lg={3}>
          <FormGroup>
            <Label>
              <small className="ml-sm-2 font-weight-bold">ID beneficiar</small>
            </Label>
            <Input
              name="id"
              value={id || ''}
              onKeyDown={handleKeyDown}
              onChange={handleChange}
              type="text"
              className="form-control"
              placeholder="ID beneficiar"
            />
          </FormGroup>
        </Col>
        <Col sm={4} lg={3}>
          <FormGroup>
            <Label>
              <small className="ml-sm-2 font-weight-bold">Personal furnizor</small>
            </Label>
            <Input
              name="doctor"
              value={doctor || ''}
              onKeyDown={handleKeyDown}
              onChange={handleChange}
              type="text"
              className="form-control"
              placeholder="Personal furnizor"
            />
          </FormGroup>
        </Col>
        <Col sm={4} lg={3}>
          <FormGroup>
            <Label>
              <small className="ml-sm-2 font-weight-bold">Localitate</small>
            </Label>
            <Input
              name="city"
              value={city || ''}
              onKeyDown={handleKeyDown}
              onChange={handleChange}
              type="text"
              className="form-control"
              placeholder="Localitate"
            />
          </FormGroup>
        </Col>
        <Col sm={4} lg={3}>
          <FormGroup>
            <Label>
              <small className="ml-sm-2 font-weight-bold">Județ</small>
            </Label>
            <Input
              name="county"
              value={county || ''}
              onKeyDown={handleKeyDown}
              onChange={handleChange}
              type="text"
              className="form-control"
              placeholder="Județ"
            />
          </FormGroup>
        </Col>
        <Col sm={4} lg={3}>
          <FormGroup>
            <Label>
              <small className="ml-sm-2 font-weight-bold">Stare</small>
            </Label>
            <Select
              value={active}
              onChange={handleActiveChange}
              className="basic-single"
              classNamePrefix="select"
              placeholder="Stare"
              isClearable
              name="color"
              options={stareOptions}
            />
          </FormGroup>
        </Col>
      </Row>
    </div>

  );
}

export default PatientFilterExtended;
