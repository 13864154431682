import React, { useState, useEffect } from 'react';
import Webcam from 'react-webcam';
import Tesseract from 'tesseract.js';
import { Button, Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { faBan, faCheck, faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const series = ['AX', 'TR', 'AR', 'ZR', 'XC', 'ZX',
  'MM', 'XM', 'XB', 'XT', 'BV', 'ZV', 'XR',
  'DP', 'DR', 'DT', 'DX', 'RD', 'RR', 'RT', 'RX', 'RK', 'RZ',
  'IF', 'XZ', 'ZB', 'KL', 'KX', 'CJ', 'KT', 'KZ', 'DX', 'DZ', 'HD', 'XD', 'VN',
  'GL', 'ZL', 'GG', 'MX', 'MZ', 'IZ', 'MH', 'HR', 'XH', 'ZH', 'NT', 'NZ',
  'AS', 'AZ', 'PH', 'PX', 'KS', 'VX', 'SM', 'KV', 'SB', 'SR', 'OT', 'SZ',
  'SV', 'XV', 'TM', 'TZ', 'DD', 'ZD', 'GZ', 'ZS', 'MS', 'TC', 'VS', 'XS', 'SX'
];

let finalValues = [];

function IDCardScanner({ close, useData, }) {
  const [imageSrc, setImageSrc] = useState(null);
  const [scannedText, setScannedText] = useState('');
  // const [finalValues, setFinalValues] = useState([]);
  const [loading, setLoading] = useState(false);
  const [resetKey, setResetKey] = useState(0);

  const [inputs, setInputs] = useState({
    cnp: '',
    nrCI: '',
    seriaCI: '',
    firstName: '',
    lastName: '',
  });

  useEffect(() => {
    finalValues.length = 0;
  }, []);

  const webcamRef = React.useRef(null);

  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImageSrc(imageSrc);
    parseText(imageSrc);
  }, []);

  const findCnp = text => {
    if (finalValues.includes('cnp')) return;
    // look for a substring that matches 13 consecutive numbers
    const regex = /\b\d{13}\b/g;
    const extractedNumbers = [];
    let match;
    while ((match = regex.exec(text)) !== null) {
      extractedNumbers.push(match[0]);
    }

    if (extractedNumbers.length > 0) {
      setInputs(prevState => ({
        ...prevState,
        cnp: extractedNumbers[0],
      }));
    }
  };

  const findNrCI = text => {
    if (finalValues.includes('nrCI')) return;

    const regex = /\b\d{6}\b/g;
    const extractedNumbers = [];
    let match;
    while ((match = regex.exec(text)) !== null) {
      extractedNumbers.push(match[0]);
    }

    if (extractedNumbers.length > 0) {
      setInputs(prevState => ({
        ...prevState,
        nrCI: extractedNumbers[0],
      }));
    }
  };

  const findSeriaCI = text => {
    if (finalValues.includes('seriaCI')) return;
    const regex = /\b[A-Z]{2}\b/g;
    const extractedNumbers = [];
    let match;
    while ((match = regex.exec(text)) !== null) {
      extractedNumbers.push(match[0]);
    }
    const matchedSeries = [];

    if (extractedNumbers.length > 0) {
      extractedNumbers.forEach(elem => {
        if (series.includes(elem)) {
          matchedSeries.push(elem);
        }
        if (matchedSeries.length > 0) {
          setInputs(prevState => ({
            ...prevState,
            seriaCI: matchedSeries[0],
          }));
        }
      });
    }
  };

  const findNames = text => {
    if (finalValues.includes('firstName') && finalValues.includes('lastName')) return;
    if (text.includes('IDROU')) {
      let nameRow = '';
      const split = text.split('IDROU')[1];
      if (split) {
        nameRow = split.split('\n')[0];
        const caca = nameRow.replace(/</g, ' ').trim();
        if (caca) {
          const lastName = caca.split('  ')[0];
          if (lastName) {
            if (finalValues.includes('lastName')) return;
            setInputs(prevState => ({
              ...prevState,
              lastName,
            }));
          }
          const firstName = caca.split('  ')[1];
          if (firstName) {
            if (finalValues.includes('firstName')) return;
            setInputs(prevState => ({
              ...prevState,
              firstName,
            }));
          }
        }
      }
    }
  };

  const handleConfirm = e => {
    const name = e.target.id.split('btn-')[1];
    if (typeof name === 'undefined') return;
    if (finalValues.includes(name)) return;
    // setFinalValues(prevState => ([...prevState, name]));
    // setFinalValues(prevState => ([...prevState, name]));
    finalValues = [...finalValues, name];
    setResetKey(Math.random());
    // set the value for the field and makes it uneditable
  };

  const handleChange = e => {
    setInputs(prevState => ({ ...prevState, [e.target.name]: e.target.value, }));
  };

  const parseText = async imageSrc => {
    setLoading(true);
    Tesseract.recognize(
      imageSrc,
      'ron'
    ).then(({ data: { text, }, }) => {
      try {
        setScannedText(text);
        findCnp(text);
        findNrCI(text);
        findSeriaCI(text);
        findNames(text);
        setLoading(false);
      } catch (error) {
        console.error('Error parsing text to JSON:', error);
      }
    });
  };

  const useCIData = () => {
    useData(inputs);
    finalValues.length = 0;
    close();
  };

  const closeModal = () => {
    finalValues.length = 0;
    close();
  };

  const { cnp, nrCI, seriaCI, firstName, lastName, } = inputs;

  return (
    <Modal size="xl" isOpen>
      <ModalHeader className="p-4" toggle={closeModal}>Preluare Date</ModalHeader>
      <ModalBody className="p-4">
        <div style={loading ? { pointerEvents: 'none', opacity: 0.8, } : {}}>
          <Row>
            <Col sm={7}>
              <Webcam
                style={{
                  width: 550,
                  height: 'auto',
                }}
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
              />
              <p>Incadreaza carte de identitate si apasa butonul de mai jos</p>
              <Button onClick={capture}>Citeste CI</Button>
              {/* {imageSrc && <img width="300" height="200" src={imageSrc} alt="Captured ID Card" />} */}
              {/* <div>{scannedText}</div> */}
            </Col>
            <Col sm={1} />
            <Col sm={4}>
              <div>
                <strong>CNP</strong>
                <div className="input-group flex-nowrap pt-2 pb-2">
                  <Input
                    disabled={finalValues.includes('cnp')}
                    name="cnp"
                    value={cnp}
                    onChange={handleChange}
                  />
                  <Button
                    id="btn-cnp"
                    color="primary"
                    disabled={cnp === ''}
                    onClick={handleConfirm}
                  >
                    {finalValues.includes('cnp') ? <FontAwesomeIcon icon={faCheck} /> : 'Confirma'}
                  </Button>
                </div>
              </div>

              <div>
                <strong>SERIA CARTE IDENTITATE</strong>
                <div className="input-group flex-nowrap pt-2 pb-2">
                  <Input
                    disabled={finalValues.includes('seriaCI')}
                    name="seriaCI"
                    value={seriaCI}
                    onChange={handleChange}
                  />
                  <Button
                    id="btn-seriaCI"
                    color="primary"
                    disabled={seriaCI === ''}
                    onClick={handleConfirm}
                  >
                    {finalValues.includes('seriaCI') ? <FontAwesomeIcon icon={faCheck} /> : 'Confirma'}
                  </Button>
                </div>
              </div>

              <div>
                <strong>NUMAR CARTE IDENTITATE</strong>
                <div className="input-group flex-nowrap pt-2 pb-2">
                  <Input
                    disabled={finalValues.includes('nrCI')}
                    name="nrCI"
                    value={nrCI}
                    onChange={handleChange}
                  />
                  <Button
                    id="btn-nrCI"
                    color="primary"
                    disabled={nrCI === ''}
                    onClick={handleConfirm}
                  >
                    {finalValues.includes('nrCI') ? <FontAwesomeIcon icon={faCheck} /> : 'Confirma'}
                  </Button>
                </div>
              </div>

              {/* <div> */}
              {/*  <strong>DATA NASTERE</strong> */}
              {/*  <div className="input-group flex-nowrap pt-2 pb-2"> */}
              {/*    <Input */}
              {/*      disabled={finalValues.includes('cnp')} */}
              {/*      name="cnp" */}
              {/*      value={cnp} */}
              {/*      onChange={handleChange} */}
              {/*    /> */}
              {/*    <Button */}
              {/*      id="btn-cnp" */}
              {/*      color="primary" */}
              {/*      disabled={cnp === ''} */}
              {/*      onClick={handleConfirm} */}
              {/*    > */}
              {/*      {finalValues.includes('cnp') ? <FontAwesomeIcon icon={faCheck} /> : 'Confirma'} */}
              {/*    </Button> */}
              {/*  </div> */}
              {/* </div> */}

              <div>
                <strong>NUME</strong>
                <div className="input-group flex-nowrap pt-2 pb-2">
                  <Input
                    disabled={finalValues.includes('lastName')}
                    name="lastName"
                    value={lastName}
                    onChange={handleChange}
                  />
                  <Button
                    id="btn-lastName"
                    color="primary"
                    disabled={lastName === ''}
                    onClick={handleConfirm}
                  >
                    {finalValues.includes('lastName') ? <FontAwesomeIcon icon={faCheck} /> : 'Confirma'}
                  </Button>
                </div>
              </div>
              <div>
                <strong>PRENUME</strong>
                <div className="input-group flex-nowrap pt-2 pb-2">
                  <Input
                    disabled={finalValues.includes('firstName')}
                    name="firstName"
                    value={firstName}
                    onChange={handleChange}
                  />
                  <Button
                    id="btn-firstName"
                    color="primary"
                    disabled={firstName === ''}
                    onClick={handleConfirm}
                  >
                    {finalValues.includes('firstName') ? <FontAwesomeIcon icon={faCheck} /> : 'Confirma'}
                  </Button>
                </div>
              </div>

            </Col>
          </Row>
        </div>
      </ModalBody>
      <ModalFooter>
        <Col md={6} className="text-right">
          <Button
            onClick={closeModal}
            id="cancel-save"
            color="secondary"
          >
            <FontAwesomeIcon icon={faBan} />
            <span className="ml-1">Anulare</span>
          </Button>
          <Button
            // disabled={saveDisabled}
            onClick={useCIData}
            id="save"
            color="primary"
            className="ml-1"
          >
            <FontAwesomeIcon icon={faSave} />
            <span className="ml-1">Utilizeaza datele</span>
          </Button>
        </Col>
      </ModalFooter>
    </Modal>

  );
}

export default IDCardScanner;
