import React, { useEffect, useState } from 'react';
import { faChevronUp, faRedoAlt, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import Select from 'react-select';
import { regionOptions, countyOptions, countyOptionsAll } from '../../Organization/organization.helper';

const years = [
  { value: 2021, label: 2021, },
  { value: 2022, label: 2022, },
  { value: 2023, label: 2023, }
];

const reports = [
  { value: '1', label: 'Borderou centralizator servicii de informare - consiliere', },
  { value: '2', label: 'Borderou centralizator - Testare HPV - 30-64, negativ', },
  { value: '3', label: 'Borderou centralizator - Testare Babeş Papanicolau - 24-29, negativ', },
  { value: '4', label: 'Borderou centralizator - Testare HPV si Babes Papanicolau - pozitiv', },
  { value: '5', label: 'Borderou centralizator - Biopsie - femei neasigurate', },
  { value: '6', label: 'Borderou centralizator - Colposcopie - femei neasigurate', },
  { value: '7', label: 'Borderou centralizator - Tratamentul excizional sau ablativ al leziunilor - femei neasigurate', }
];

const months = [
  { value: '01', label: 'Ianuarie', },
  { value: '02', label: 'Februarie', },
  { value: '03', label: 'Martie', },
  { value: '04', label: 'Aprilie', },
  { value: '05', label: 'Mai', },
  { value: '06', label: 'Iunie', },
  { value: '07', label: 'Iulie', },
  { value: '08', label: 'August', },
  { value: '09', label: 'Septembrie', },
  { value: '10', label: 'Octombrie', },
  { value: '11', label: 'Noiembrie', },
  { value: '12', label: 'Decembrie', }

];

function RaportareOirFiltersExtended({ organizationsExt, handleChange, auth, addFilterAndSearch, resetFilters, filters, }) {
  const [region, setRegion] = useState('');
  const [county, setCounty] = useState('');
  const [label, setLabel] = useState('');
  const [selectedYear, setSelectedYear] = useState('');
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedReport, setSelectedReport] = useState('');

  useEffect(() => {
    if (auth.region) {
      const userRegion = { value: auth.region.id, label: auth.region.value, };
      setRegion(userRegion);
    } else {
      const { region: regionFilter, } = filters;
      if (regionFilter && regionFilter !== '') {
        const findRegion = regionOptions.find(elem => elem.value === regionFilter);
        setRegion(findRegion);
      }
    }
  }, [filters.region]);

  useEffect(() => {
    const { county: countyFilter, } = filters;
    if (countyFilter && countyFilter !== '') {
      const findCounty = countyOptions.find(elem => elem.value === countyFilter);
      setCounty(findCounty);
    }
  }, [filters.county]);

  const handleYearChange = (selected, details) => {
    setSelectedYear(selected);
    handleChange(selected, details);
  };

  const handleMonthChange = (selected, details) => {
    setSelectedMonth(selected);
    handleChange(selected, details);
  };

  const handleReportChange = (selected, details) => {
    setSelectedReport(selected);
    handleChange(selected, details);
  };

  const handleRegionChange = selected => {
    if (selected === null) {
      setRegion('');
      addFilterAndSearch({ county: filters?.county || '', label: filters?.label || '', }, false, 'region');
    } else {
      setRegion(selected);
      addFilterAndSearch({ county: filters?.county || '', label: filters?.label || '', region: selected.value, });
    }
  };

  const handleCountyChange = selected => {
    if (selected === null) {
      setCounty('');
      addFilterAndSearch({ region: filters?.region || '', label: filters?.label || '', }, false, 'county');
    } else {
      setCounty(selected);
      addFilterAndSearch({ region: filters?.region || '', label: filters?.label || '', county: selected.value, });
    }
  };

  const handleLabelChange = selected => {
    if (selected === null) {
      setLabel('');
      addFilterAndSearch({ region: filters?.region || '', county: filters?.county || '', }, false, 'label');
    } else {
      setLabel(selected);
      addFilterAndSearch({ region: filters?.region || '', county: filters?.county || '', label: selected.value, });
    }
  };

  const handleResetFilters = () => {
    if (auth.region) {
      setCounty('');
      resetFilters();
    } else {
      setRegion('');
      setCounty('');
      resetFilters();
    }
  };

  const calcOrgOptions = () => {
    let org = organizationsExt;
    if (region) {
      org = org.filter(a => a.region === region.value);
    }
    if (county) {
      org = org.filter(a => a.county === county.value);
    }
    return org;
  };

  const organizationOptions = calcOrgOptions();

  return (
    <div className="searchFormFields mt-4">
      <Row>
        <Col>
          <FormGroup>
            <Label>
              <small className="ml-sm-2 font-weight-bold">Regiune *</small>
            </Label>
            {
              auth.region ? (
                <Input
                  value={region?.label || ''}
                  type="text"
                  className="form-control"
                  id="region"
                  placeholder="Regiune"
                  disabled
                />
              ) : (
                <Select
                  isClearable
                  value={region}
                  onChange={handleRegionChange}
                  className="basic-single"
                  classNamePrefix="select"
                  placeholder="Regiune"
                  name="region"
                  options={regionOptions}
                />
              )
            }

          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label>
              <small className="ml-sm-2 font-weight-bold">Furnizor *</small>
            </Label>
            <Select
              isClearable
              value={label}
              onChange={handleLabelChange}
              className="basic-single"
              classNamePrefix="select"
              placeholder="Furnizor"
              name="label"
              options={organizationOptions}
              getOptionValue={option => option.id}
              getOptionLabel={option => option.value}
            />
          </FormGroup>
        </Col>

      </Row>
      <Row>
        <Col md={2}>
          <FormGroup>
            <Label>
              <small className="ml-sm-2 font-weight-bold">Judet</small>
            </Label>
            <Select
              isClearable
              value={county}
              onChange={handleCountyChange}
              className="basic-single"
              classNamePrefix="select"
              placeholder="Judet"
              name="county"
              options={auth.region ? countyOptionsAll[region.value]
                : region !== '' ? countyOptionsAll[region.value] : countyOptions}
            />
          </FormGroup>
        </Col>
        <Col md={2}>
          <FormGroup>
            <Label>
              <small className="ml-sm-2 font-weight-bold">An *</small>
            </Label>
            <Select
              value={selectedYear}
              onChange={handleYearChange}
              className="basic-single"
              classNamePrefix="select"
              placeholder="An"
              name="year"
              options={years}
              isClearable
            />
          </FormGroup>
        </Col>
        <Col md={2}>
          <FormGroup>
            <Label>
              <small className="ml-sm-2 font-weight-bold">Luna *</small>
            </Label>
            <Select
              value={selectedMonth}
              onChange={handleMonthChange}
              className="basic-single"
              classNamePrefix="select"
              placeholder="Luna"
              name="month"
              options={months}
              isClearable
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label>
              <small className="ml-sm-2 font-weight-bold">Tip Raport *</small>
            </Label>
            <Select
              value={selectedReport}
              onChange={handleReportChange}
              className="basic-single"
              classNamePrefix="select"
              placeholder="Raport"
              name="report"
              options={reports}
              isClearable
            />
          </FormGroup>
        </Col>
      </Row>
    </div>
  );
}

export default RaportareOirFiltersExtended;
