import React, { Component } from 'react';
import { Button, FormGroup, Label, Col, Card, CardHeader, CardBody, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faPlus, faRemove } from '@fortawesome/free-solid-svg-icons';
import OrganizationAddressModal from './OrganizationAddressModal';
import DeleteAddressModal from './DeleteAddressModal';

class OrganizationAddressInput extends Component {
  constructor() {
    super();
    this.state = {
      showBody: false,
      addressModal: false,
      deleteModal: false,
    };

    this.toggleBody = this.toggleBody.bind(this);
    this.showAddressModal = this.showAddressModal.bind(this);
    this.closeAddressModal = this.closeAddressModal.bind(this);
    this.showDeleteModal = this.showDeleteModal.bind(this);
    this.closeDeleteModal = this.closeDeleteModal.bind(this);
    this.calcAddress = this.calcAddress.bind(this);
  }

  toggleBody() {
    const { showBody, } = this.state;
    this.setState({ showBody: !showBody, });
  }

  showAddressModal() {
    this.setState({ addressModal: true, });
  }

  closeAddressModal() {
    this.setState({ addressModal: false, });
  }

  showDeleteModal() {
    this.setState({ deleteModal: true, });
  }

  closeDeleteModal() {
    this.setState({ deleteModal: false, });
  }

  calcAddress() {
    const { address, } = this.props;
    if (address === null) return '';
    const { city, county, region, } = address;
    return `${city ? city.label : ''}${county ? `, ${county.label}` : ''}${region ? `, ${region.label}` : ''}`;
  }

  render() {
    const { address, setAddress, } = this.props;
    const { showBody, addressModal, deleteModal, } = this.state;
    const addressDom = this.calcAddress();
    return (
      <>
        { addressModal && (
        <OrganizationAddressModal
          close={this.closeAddressModal}
          address={address}
          setAddress={setAddress}
        />
        )}
        { deleteModal && (
          <DeleteAddressModal
            setAddress={setAddress}
            address={addressDom}
            close={this.closeDeleteModal}
          />
        )}

        {address === null ? (
          <FormGroup>
            <Col>
              <Button
                onClick={this.showAddressModal}
                id="field_address"
                className="btn btn-pill p-0"
                color="danger"
              >
                <FontAwesomeIcon icon={faPlus} />
              </Button>
              <Label className="ml-3">Adresă</Label>
              <small className="form-text text-danger">
                Acest câmp este necesar.
              </small>
            </Col>
          </FormGroup>
        ) : (
          <Card className="mb-2">
            <CardHeader className={showBody ? '' : 'border-0'}>
              <div className="d-flex align-items-center justify-content-between">
                <Button
                  onClick={this.toggleBody}
                  color="link"
                  className="w-100 text-left pl-0 collapsed"
                >
                  {addressDom}
                </Button>

                <Button
                  onClick={this.showAddressModal}
                  color="primary"
                  size="sm"
                  className="btn-pill"
                >
                  <FontAwesomeIcon icon={faPencilAlt} />
                </Button>
                <Button
                  onClick={this.showDeleteModal}
                  color="danger"
                  size="sm"
                  className="btn-pill ml-1"
                >
                  <FontAwesomeIcon icon={faRemove} />
                </Button>
              </div>
            </CardHeader>
            { showBody && (
              <CardBody>
                <Row>
                  <Col sm={6}>
                    <Label className="font-weight-bold mb-0">
                      Localitate
                    </Label>
                    <div className="mb-3">
                      {address && address.city && address.city.label}
                    </div>
                  </Col>
                  <Col sm={6}>
                    <Label className="font-weight-bold mb-0">
                      Județ
                    </Label>
                    <div className="mb-3">
                      {address && address.county && address.county.label}
                    </div>
                  </Col>
                  <Col sm={6}>
                    <Label className="font-weight-bold mb-0">
                      Regiune
                    </Label>
                    <div className="mb-3">
                      {address && address.region && address.region.label}
                    </div>
                  </Col>
                </Row>
              </CardBody>
            )}
          </Card>
        )}
      </>
    );
  }
}

export default OrganizationAddressInput;
