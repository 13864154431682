import React, { Component } from 'react';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, CardBody, CardHeader, Spinner } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { DoctorsTable, DoctorFilterSimple, DoctorFilterExtended } from './components/index';
import Pagination from '../../Pagination';

import './index.scss';
import { doctorsOperations } from '../../../redux/ducks/doctors';
import { organizationsOperations } from '../../../redux/ducks/organizations';

// const firstElem = (filters.page - 1) * filters.size + 1;
// const lastElem = filters.page * filters.size > totalNo ? totalNo : filters.page * filters.size;

const defaultFilters = {
  page: 1,
  size: 10,
  sortBy: 'createTs',
  sortDir: 'desc',
};
class Doctors extends Component {
  constructor() {
    super();

    this.setFilters = this.setFilters.bind(this);
    this.addFilterAndSearch = this.addFilterAndSearch.bind(this);
    this.selectFilterType = this.selectFilterType.bind(this);
    this.setSortFilter = this.setSortFilter.bind(this);
    this.resetFilters = this.resetFilters.bind(this);
    this.parseUrlAndSetFilters = this.parseUrlAndSetFilters.bind(this);
    this.setActiveStatus = this.setActiveStatus.bind(this);
    this.getDoctors = this.getDoctors.bind(this);
    this.onBrowserNav = this.onBrowserNav.bind(this);
    this.getDoctorsData = this.getDoctorsData.bind(this);

    this.state = {
      filters: defaultFilters,
      filterType: 'simple',
      doctors: [],
      firstElem: 0,
      lastElem: 10,
      totalNo: 999,
      key: 0,
      totalPages: 0,
      loading: false,
      organizationsExt: [],
    };
  }

  componentDidMount() {
    const { auth, navigate, getOrganizationsExt, } = this.props;
    if (auth) {
      const { view, } = auth;
      if (view && !view.includes('DOCTOR_LIST')) {
        setTimeout(() => {
          navigate('/');
        }, 10);
      }
    }
    window.addEventListener('popstate', this.onBrowserNav);
    setTimeout(() => {
      this.parseUrlAndSetFilters();
    }, 50);
    getOrganizationsExt()
      .then((res => {
        this.setState({ organizationsExt: res.data.data, });
      }))
      .catch((err => {
        console.log('err: ', err);
      }));
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.onBrowserNav);
  }

  onBrowserNav() {
    this.setState({ filters: defaultFilters, key: Math.random(), }, () => {
      this.parseUrlAndSetFilters();
    });
  }

  parseUrlAndSetFilters() {
    const { searchParams, } = this.props;
    const parsedFilters = Object.fromEntries([...searchParams]);
    if (Object.keys(parsedFilters).length > 0) {
      this.setFilters(parsedFilters);
    } else {
      this.resetFilters();
    }
  }

  getDoctors() {
    const { location: { pathname, }, navigate, } = this.props;
    const { filters, } = this.state;
    const formatFilters = Object.entries(filters).filter(elem => elem[1] !== '').map(elem => elem.join('=')).join('&');
    navigate(`${pathname}?${formatFilters}`);
    this.getDoctorsData(`${pathname}s?${formatFilters}`);
  }

  getDoctorsData(url) {
    const { getDoctors, } = this.props;
    this.setState({ loading: true, });

    getDoctors(url)
      .then((res => {
        const { pageSize, totalElements, totalPages, pageNumber, data, } = res.data;
        const { filters, } = this.state;
        this.setState({
          doctors: data,
          totalNo: totalElements,
          firstElem: (filters.page - 1) * filters.size + 1,
          lastElem: filters.page * filters.size > totalElements ? totalElements : filters.page * filters.size,
          totalPages,
          loading: false,
        });
      }))
      .catch((err => {
        console.log('err: ', err);
        this.setState({ loading: false, });
      }));
  }

  setFilters(data) {
    this.setState({ filters: data, }, () => {
      this.getDoctors();
    });
  }

  addFilterAndSearch(data, pageSelect, removeKey) {
    const { filters, } = this.state;
    if (removeKey) delete filters[removeKey];
    const newFilters = {
      ...filters,
      ...data,
      ...(!pageSelect && { page: 1, }),
    };
    this.setFilters(newFilters);
  }

  setSortFilter(e) {
    let newSortDir;
    const newSortBy = e.target.id;
    const { filters: { sortBy, sortDir, }, } = this.state;
    if (e.target.id === sortBy) {
      newSortDir = sortDir === 'asc' ? 'desc' : 'asc';
    } else {
      newSortDir = 'asc';
    }
    // console.log(newSortBy);
    // console.log(newSortDir);
    this.addFilterAndSearch({ sortBy: newSortBy, sortDir: newSortDir, }, true);
  }

  selectFilterType() {
    const { filterType, } = this.state;
    const newType = filterType === 'simple' ? 'extended' : 'simple';
    this.setState({ filterType: newType, });
  }

  resetFilters() {
    this.setState({ filters: defaultFilters, }, () => {
      this.getDoctors();
    });
  }

  setActiveStatus(data) {
    const { active, id, } = data;
    const { updateDoctor, } = this.props;
    updateDoctor({ active, id, })
      .then(() => {
        this.getDoctors();
      })
      .catch(err => {
        console.log('err: ', err);
      });
  }

  render() {
    const { doctors, firstElem, lastElem, totalNo, filters, filterType, key, totalPages, loading, organizationsExt, } = this.state;
    const { location, auth, } = this.props;
    return (
      <>
        {
        loading && (
        <div
          className="loading-container"
        >
          <Spinner />
        </div>
        )
        }
        <div className={`container-fluid position-relative ${loading ? 'no-events loading-data' : ''}`}>
          <Card className="mt-4">
            <CardHeader className="position-relative">
              <div className="d-inline-block">
                <h3><span>Personal Furnizori</span></h3>
                <div className="info">
                  {`Se afiseaza ${firstElem} - ${lastElem} din ${totalNo} elemente.`}
                </div>
              </div>
              {
                auth?.create.includes('DOCTOR_CREATE') && (
                  <div className="position-absolute create-organization-btn-wrapper">
                    <NavLink
                      to="/doctor/new"
                      state={{ from: location, }}
                    >
                      <Button
                        color="success"
                        className="btn btn-pill p-0"
                      >
                        <FontAwesomeIcon icon={faPlus} />
                      </Button>
                    </NavLink>
                  </div>
                )
              }
            </CardHeader>
            <CardBody>
              {
              filterType === 'simple'
                ? (
                  <DoctorFilterSimple
                    key={key}
                    addFilterAndSearch={this.addFilterAndSearch}
                    setFilterType={this.selectFilterType}
                    resetFilters={this.resetFilters}
                    filters={filters}
                  />
                )
                : (
                  <DoctorFilterExtended
                    organizationsExt={organizationsExt}
                    auth={auth}
                    key={key}
                    addFilterAndSearch={this.addFilterAndSearch}
                    setFilterType={this.selectFilterType}
                    resetFilters={this.resetFilters}
                    filters={filters}
                  />
                )
            }
              <DoctorsTable
                auth={auth}
                setActiveStatus={this.setActiveStatus}
                setSortFilter={this.setSortFilter}
                tableData={doctors}
                filters={filters}
              />
              <Pagination
                totalPages={totalPages}
                totalNo={totalNo}
                filters={filters}
                addFilterAndSearch={this.addFilterAndSearch}
              />
            </CardBody>
          </Card>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  doctors: state.doctors.doctors,
  selectedDoctor: state.doctors.selectedDoctor,
});

const mapDispatchToProps = dispatch => ({
  getDoctors: url => dispatch(doctorsOperations.getDoctors(url)),
  updateDoctor: data => dispatch(doctorsOperations.updateDoctor(data)),
  getOrganizationsExt: () => dispatch(organizationsOperations.getOrganizationsExt()),
  // selectOrganization: data => dispatch(organizationsOperations.selectOrganization(data)),
  // updateOrganization: data => dispatch(organizationsOperations.updateOrganization(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Doctors);
