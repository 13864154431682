import React, { Component } from 'react';

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Label,
  Row
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import { organizationsOperations } from '../../../../redux/ducks/organizations';

class OrganizationView extends Component {
  constructor() {
    super();
    this.state = {
      id: null,
      code: '',
      codeError: false,
      label: '',
      labelError: false,
      description: '',
      representative: '',
      type: '',
      services: [],
      servicesSimple: [],
      servicePrice: null,
      servicesDom: '',
      address: null,
      active: false,
    };

    this.getSingleOrganization = this.getSingleOrganization.bind(this);
    this.prefillFields = this.prefillFields.bind(this);
    this.goBack = this.goBack.bind(this);
    this.goEdit = this.goEdit.bind(this);
    this.calcAddress = this.calcAddress.bind(this);
    this.calcServices = this.calcServices.bind(this);
  }

  componentDidMount() {
    const { location, auth, navigate, } = this.props;

    if (!auth?.view.includes('ORGANIZATION_LIST')) {
      setTimeout(() => { navigate('/'); }, 10);
    }

    const splitUrl = location.pathname.split('/organization/')[1];

    if (splitUrl !== 'new') {
      this.getSingleOrganization(splitUrl);
    }
  }

  getSingleOrganization(url) {
    const { getSingleOrganization, } = this.props;
    const id = url.split('/')[0];
    getSingleOrganization(id)
      .then((res => {
        this.prefillFields(res.data.data);
      }))
      .catch((err => {
        console.log('err: ', err);
      }));
  }

  prefillFields(selected) {
    const newState = {
      ...this.state,
      ...selected,
      servicePrice: typeof selected.servicePrice !== 'undefined' ? JSON.parse(selected.servicePrice) : null,
      servicesDom: typeof selected.servicePrice !== 'undefined' ? this.calcServices(selected.services, JSON.parse(selected.servicePrice)) : [],
    };
    this.setState({ ...newState, });
  }

  calcServices(services = [], servicePrice = {}) {
    const arr = [];
    services.map(a => a.label).forEach(elem => {
      if (elem.split(' ')[0] === 'Laborator') {
        if (servicePrice.LABORATOR) {
          arr.push(`${elem} (${servicePrice.LABORATOR} RON)`);
        } else {
          arr.push(elem);
        }
      } else if (servicePrice[elem.toUpperCase()]) {
        arr.push(`${elem} (${servicePrice[elem.toUpperCase()]} RON)`);
      } else {
        arr.push(elem);
      }
    });
    return arr.join(', ');
  }

  goBack() {
    const { navigate, } = this.props;
    navigate(-1);
  }

  goEdit() {
    const { navigate, } = this.props;
    const { id, } = this.state;
    navigate(`/organization/${id}/edit`);
  }

  calcAddress() {
    const { address, } = this.state;
    if (address === null) return '';
    const { city, county, region, } = address;
    return `${city ? city.label : ''}${county ? `, ${county.label}` : ''}${region ? `, ${region.label}` : ''}`;
  }

  render() {
    const { code, label, description, type, servicesDom = '', active, representative, } = this.state;
    const { auth, } = this.props;
    const addressDom = this.calcAddress();

    return (
      <Container fluid className="d-flex">
        <Row className="justify-content-center w-100">
          <Col sm={8}>
            <Card className="mt-3">
              <CardHeader>
                <h3 className="mb-0">Furnizor</h3>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col>
                    <Label className="font-weight-bold mb-0">Cod</Label>
                    <p>{code || ''}</p>
                  </Col>
                  <Col>
                    <Label className="font-weight-bold mb-0">Denumire / Nume furnizor</Label>
                    <p>{label || ''}</p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Label className="font-weight-bold mb-0">Descriere</Label>
                    <p>{description || ''}</p>
                  </Col>
                  <Col>
                    <Label className="font-weight-bold mb-0">Tip furnizor</Label>
                    <p>{type || ''}</p>
                  </Col>
                </Row>
                <Row>
                  { representative
                    && (
                    <Col>
                      <Label className="font-weight-bold mb-0">Reprezentant legal</Label>
                      <p>{ representative || ''}</p>
                    </Col>
                    )}
                  <Col>
                    <Label className="font-weight-bold mb-0">Servicii</Label>
                    <p>{ servicesDom }</p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Label className="font-weight-bold mb-0">Stare</Label>
                    <p>{ active ? 'Activ' : 'Inactiv'}</p>
                  </Col>
                  <Col>
                    <Label className="font-weight-bold mb-0">Adresă</Label>
                    <p>{addressDom}</p>
                  </Col>
                </Row>
                <Row>
                  <Col md={12} className="text-right">
                    <Button
                      onClick={this.goBack}
                      color="secondary"
                    >
                      <FontAwesomeIcon icon={faArrowLeft} />
                      <span className="ml-1">Înapoi</span>
                    </Button>
                    {
                      auth?.update.includes('ORGANIZATION_UPDATE') && (
                        <Button
                          onClick={this.goEdit}
                          color="primary"
                          className="ml-1"
                        >
                          <FontAwesomeIcon icon={faPencilAlt} />
                          <span className="ml-1">Editare</span>
                        </Button>
                      )
}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  organizations: state.organizations.organizations,
  selectedOrganization: state.organizations.selectedOrganization,
});

const mapDispatchToProps = dispatch => ({
  getSingleOrganization: id => dispatch(organizationsOperations.getSingleOrganization(id)),
  selectOrganization: data => dispatch(organizationsOperations.selectOrganization(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationView);
