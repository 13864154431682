import React from 'react';
import {
  Button,
  Modal, ModalBody, ModalFooter,
  ModalHeader
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faTimes } from '@fortawesome/free-solid-svg-icons';

function DeleteDoctorAddressModal({ close, confirm, data, }) {
  const { label, description, contactType: { label: contactLabel, }, } = data;
  const displayContact = `${label || ''}${description ? `, ${description}` : ''}${contactLabel ? `, ${contactLabel}` : ''}`;
  return (
    <Modal size="xl" isOpen>
      <ModalHeader className="p-4" toggle={close}>Confirmați operația de ștergere</ModalHeader>
      <ModalBody className="p-4">
        <p>
          {`Sunteți sigur că doriți să inactivați contactul ${displayContact}?`}
        </p>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={close}>
          <FontAwesomeIcon icon={faBan} />
          <span className="ml-2">Anulare</span>
        </Button>
        <Button color="danger" onClick={confirm}>
          <FontAwesomeIcon icon={faTimes} />
          <span className="ml-2">Ștergere</span>
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default DeleteDoctorAddressModal;
