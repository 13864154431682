import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCheckCircle, faEye, faPencilAlt, faTimes, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { Button } from 'reactstrap';
import { userRolesOptions } from '../users.helper';

const dummyPerson = { person: { firstName: '', lastName: '', }, };

function UsersTableRow({ data, handleActiveModal, canEdit, }) {
  const { email, username, auxiliarPerson, doctor, profiles, active, id, organization, } = data;
  const location = useLocation();
  const personData = auxiliarPerson || (doctor || dummyPerson);

  const rolesDom = [];
  profiles.forEach(a => {
    const selectedRole = userRolesOptions.find(elem => elem.value === a.value);
    if (selectedRole) {
      rolesDom.push(selectedRole.label);
    }
  });
  return (
    <tr>
      <td>{email}</td>
      <td>{username}</td>
      <td>{personData.person.lastName || ''}</td>
      <td>{personData.person.firstName || ''}</td>
      <td style={{ maxWidth: 250, }}>
        {rolesDom.join(', ')}
      </td>
      <td style={{ minWidth: 70, }}>
        <div>
          { organization ? organization.label : personData.organization ? personData.organization.label : ''}
        </div>
      </td>
      <td style={{ minWidth: 80, }}>
        { active
          ? <FontAwesomeIcon fontSize={24} icon={faCheck} />
          : <FontAwesomeIcon fontSize={24} icon={faTimes} />}
      </td>
      <td className="text-right" style={{ minWidth: 180, }}>
        <div className="actions">
          <NavLink
            className="btn btn-info btn-sm btn-pill mr-1"
            to={`/user/${id}/view`}
            state={{ from: location, }}
          >
            <FontAwesomeIcon icon={faEye} />
          </NavLink>
          {canEdit && (
            <>
              <NavLink
                to={`/user/${id}/edit`}
                state={{ from: location, }}
                className="btn btn-primary btn-sm btn-pill mr-1"
              >
                <FontAwesomeIcon icon={faPencilAlt} />
              </NavLink>
              { active ? (
                <div
                  style={{ width: '4rem', borderRadius: '20rem', }}
                  className="btn btn-outline-success text-right p-0 row-btn-wrapper"
                >
                  <Button
                    onClick={() => handleActiveModal(data)}
                    color="success"
                    className="btn-pill p-0"
                  >
                    <FontAwesomeIcon icon={faCheckCircle} />
                  </Button>
                </div>
              ) : (
                <div
                  style={{ width: '4rem', borderRadius: '20rem', }}
                  className="btn btn-outline-danger text-left p-0 row-btn-wrapper"
                >
                  <Button
                    onClick={() => handleActiveModal(data)}
                    color="danger"
                    className="btn-pill p-0"
                  >
                    <FontAwesomeIcon icon={faTimesCircle} />
                  </Button>
                </div>
              )}
            </>
          )}
        </div>
      </td>
    </tr>
  );
}

export default UsersTableRow;
