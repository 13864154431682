import moment from 'moment';

export const calcCnpRelatedValues = (cnpValue, that) => {
  const regExp = /[a-zA-Z]/g;
  const cnpErrors = [];

  if (cnpValue.length > 0 && cnpValue.length !== 13) {
    cnpErrors.push('CNP-ul este format din 13 cifre!');
  }
  if (regExp.test(cnpValue)) {
    cnpErrors.push('Sunt permise doar cifre!');
  }

  if (cnpErrors.length > 0) {
    that.setState({ cnpError: cnpErrors, });
    return;
  }
  const day = cnpValue.substring(5, 7);
  const month = cnpValue.substring(3, 5);
  const yearSimple = cnpValue.substring(1, 3);
  let yearComplete = '';
  if (cnpValue[0] === '1' || cnpValue[0] === '2') {
    yearComplete = `19${yearSimple}`;
  } else if (cnpValue[0] === '5' || cnpValue[0] === '6') {
    yearComplete = `20${yearSimple}`;
  } else { yearComplete = ''; }
  that.setState({
    cnp: cnpValue,
    cnpError: false,
    gender: (cnpValue[0] == 1 || cnpValue[0] == 5) ? 'M' : (cnpValue[0] == 2 || cnpValue[0] == 6) ? 'F' : null,
    birthDate: `${day}-${month}-${yearComplete}`,
  });
};
