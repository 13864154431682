import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup, Card, CardBody, CardHeader, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import moment from 'moment';
import Dropzone from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faUpload } from '@fortawesome/free-solid-svg-icons';

const checkboxInputs = [
  'triajHPVSec2FS1',
  'constatariNormaleSec41FS1',
  'lezVizAnormalaSec41FS1',
  'limiteLezVizSec41FS1',
  'frotiuVaginalSec41FS1',
  'hrHpvTestSec41FS1',
  'biopsie2Sec41FS1',
  'recomUrmarireSec41FS1'
];

function ColposcopieView({ data, changeTab, }) {
  const [files, setFiles] = useState([]);
  const [inputs, setInputs] = useState({});
  const [centruMedicalSec4FS1, setcentruMedicalSec4FS1] = useState({});

  useEffect(() => {
    const inputs = data.attributes;
    setInputs(inputs);
    setcentruMedicalSec4FS1(data.centruMedicalSec4FS1);// centruMedicalSec4FS1
    if (Object.keys(inputs).length === 0) return;
    checkboxInputs.forEach(elem => {
      inputs[elem].value = inputs[elem].value.toString();
    });
  }, []);

  const {
    // centruMedicalSec4FS1,
    medicSpecialitateSec4FS1,
    triajHPVSec2FS1,
    dataInterventieSec4FS1,
    rezColpListSec41FS1,
    descriereColpSec41FS1,
    zonaTransfSec41FS1,
    leziunePrezSec41FS1,
    nrCadraneSec41FS1,
    constatariNormaleSec41FS1,
    lezVizAnormalaSec41FS1,
    limiteLezVizSec41FS1,
    gradAnormalitateSec41FS1,
    frotiuVaginalSec41FS1,
    hrHpvTestSec41FS1,
    biopsie2Sec41FS1,
    neprelevareBiopSec41FS1,
    diagramaFileSec41FS1, // file upload!!
    anestezieSev41FS1,
    recomUrmarireSec41FS1,
    urmVizitaNumericSec41FS1,
  } = inputs;

  const onDrop = uploaded => {
    setFiles([...uploaded]);
  };

  const deleteFile = () => {
    setFiles([]);
  };

  const setTab = e => { changeTab(e.target.id); };

  const filesDom = files.map(file => (
    <li className="d-flex" key={file.name}>
      {file.name}
      <div id={file.name} className="ml-auto" onClick={deleteFile}>
        <div style={{ pointerEvents: 'none', }}>
          <FontAwesomeIcon icon={faTrash} />
        </div>
      </div>
    </li>
  ));

  const handleBtnClick = () => {};

  return (
    <Card>
      <CardHeader>
        <h3 className="mb-0">
          <div className="d-flex">
            <div>
              <span className="mr-3">Colposcopie</span>
            </div>
            <div className="ml-auto">
              <Button id="-" size="sm" color="secondary" className="ml-auto btn-pill" onClick={setTab}>{'<'}</Button>
              <Button id="+" size="sm" color="primary" className="ml-1 btn-pill" onClick={setTab}>{'>'}</Button>
            </div>
          </div>
        </h3>
      </CardHeader>
      <CardBody className="pb-5">
        <Col
          className="underline-hover form-group dont-display ng-untouched ng-pristine"
          style={{ backgroundColor: 'rgba(233, 236, 239, 0.3)', }}
        >
          <Label>
            Date specifice rezultatelor colposcopiei
          </Label>
        </Col>

        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>Centrul de servicii medicale specializate (cabinet de ginecologie) unde s-a efectuat colposcopia</Label>
              <Input
                type="text"
                name="centruMedicalSec4FS1"
                disabled
                value={centruMedicalSec4FS1?.value || ''}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label>Medicul de specialitate obstetrică ginecologie care efectuează intervenţia </Label>

              <Input
                required
                type="text"
                name="medicSpecialitateSec4FS1"
                disabled
                value={medicSpecialitateSec4FS1?.value || ''}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col>
            <FormGroup check>
              <Input
                type="checkbox"
                value={triajHPVSec2FS1?.value || false}
                checked={triajHPVSec2FS1?.value === 'true'}
                name="triajHPVSec2FS1"
                onChange={() => {}}
              />
              <Label>Triaj HPV</Label>
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>Data efectuării colposcopiei</Label>
              <Input
                required
                type="text"
                name="dataInterventieSec4FS1"
                disabled
                value={dataInterventieSec4FS1?.value ? moment(dataInterventieSec4FS1.value).format('DD-MM-YYYY') : ''}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>Rezultat colposcopie</Label>

              <Input
                required
                type="text"
                name="rezColpListSec41FS1"
                disabled
                value={rezColpListSec41FS1?.value || ''}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label>Descriere colposcopică</Label>

              <Input
                type="textarea"
                rows={3}
                name="descriereColpSec41FS1"
                disabled
                value={descriereColpSec41FS1?.value || ''}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Label>Zona de transformare vizibilă</Label>

              <Input
                required
                type="text"
                name="zonaTransfSec41FS1"
                disabled
                value={zonaTransfSec41FS1?.value || ''}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>Leziune prezentă</Label>
              <div>
                <ButtonGroup size="">
                  <Button
                    onClick={handleBtnClick}
                    id="leziunePrezSec41FS1-DA"
                    color="primary"
                    outline={leziunePrezSec41FS1?.value !== 'DA'}
                  >
                    DA
                  </Button>
                  <Button
                    onClick={handleBtnClick}
                    id="leziunePrezSec41FS1-NU"
                    color="primary"
                    outline={leziunePrezSec41FS1?.value !== 'NU'}
                  >
                    NU
                  </Button>
                  <Button
                    onClick={handleBtnClick}
                    id="leziunePrezSec41FS1-NECONCLUDENT"
                    color="primary"
                    outline={leziunePrezSec41FS1?.value !== 'NECONCLUDENT'}
                  >
                    NECONCLUDENT
                  </Button>
                </ButtonGroup>
              </div>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label>Numărul de cadrane implicate</Label>
              <div>
                <ButtonGroup size="">
                  <Button
                    onClick={handleBtnClick}
                    id="nrCadraneSec41FS1-1"
                    color="primary"
                    outline={nrCadraneSec41FS1?.value !== '1'}
                  >
                    1
                  </Button>
                  <Button
                    onClick={handleBtnClick}
                    id="nrCadraneSec41FS1-2"
                    color="primary"
                    outline={nrCadraneSec41FS1?.value !== '2'}
                  >
                    2
                  </Button>
                  <Button
                    onClick={handleBtnClick}
                    id="nrCadraneSec41FS1-3"
                    color="primary"
                    outline={nrCadraneSec41FS1?.value !== '3'}
                  >
                    3
                  </Button>
                  <Button
                    onClick={handleBtnClick}
                    id="nrCadraneSec41FS1-4"
                    color="primary"
                    outline={nrCadraneSec41FS1?.value !== '4'}
                  >
                    4
                  </Button>
                </ButtonGroup>
              </div>
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col>
            <FormGroup check>

              <Input
                type="checkbox"
                value={constatariNormaleSec41FS1?.value || false}
                checked={constatariNormaleSec41FS1?.value === 'true'}
                name="constatariNormaleSec41FS1"
                onChange={() => {}}
              />
              <Label>Constatări normale </Label>
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col>
            <FormGroup check>
              <Input
                type="checkbox"
                value={lezVizAnormalaSec41FS1?.value || false}
                checked={lezVizAnormalaSec41FS1?.value === 'true'}
                name="lezVizAnormalaSec41FS1"
                onChange={() => {}}
              />
              <Label>Leziune vizibilă anormală </Label>
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col>
            <FormGroup check>

              <Input
                type="checkbox"
                value={limiteLezVizSec41FS1?.value || false}
                checked={limiteLezVizSec41FS1?.value === 'true'}
                name="limiteLezVizSec41FS1"
                onChange={() => {}}
              />
              <Label>Limite ale leziunii vizibile </Label>
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>Grad de anormalitate</Label>
              <Input
                required
                type="text"
                name="gradAnormalitateSec41FS1"
                disabled
                value={gradAnormalitateSec41FS1?.value || ''}
              />
            </FormGroup>
          </Col>
        </Row>

        <Col
          className="underline-hover form-group dont-display ng-untouched ng-pristine"
          style={{ backgroundColor: 'rgba(233, 236, 239, 0.3)', }}
        >
          <Label>
            Date specifice procedurilor realizate în cadrul colposcopiei
          </Label>
        </Col>

        <Row>
          <Col md={6}>
            <FormGroup check>
              <Input
                type="checkbox"
                value={frotiuVaginalSec41FS1?.value || false}
                checked={frotiuVaginalSec41FS1?.value === 'true'}
                name="frotiuVaginalSec41FS1"
                onChange={() => {}}
              />
              <Label>Frotiu cervical/vaginal </Label>
            </FormGroup>
          </Col>
          <Col md={6}>

            <FormGroup check>
              <Input
                type="checkbox"
                value={hrHpvTestSec41FS1?.value || false}
                checked={hrHpvTestSec41FS1?.value === 'true'}
                name="hrHpvTestSec41FS1"
                onChange={() => {}}
              />
              <Label>HrHPV test</Label>
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <FormGroup check>
              <Input
                type="checkbox"
                value={biopsie2Sec41FS1?.value || false}
                checked={biopsie2Sec41FS1?.value === 'true'}
                name="biopsie2Sec41FS1"
                onChange={() => {}}
              />
              <Label>Biopsie </Label>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label>Motive neprelevare biopsie</Label>

              <Input
                type="textarea"
                rows={3}
                name="neprelevareBiopSec41FS1"
                disabled
                value={neprelevareBiopSec41FS1?.value || ''}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col><Label>Diagrama/fotografia leziunii</Label></Col>

        </Row>
        {
          1 === 2 & (
            <Row className="file-upload">
              <Col>
                <FormGroup>
                  <Dropzone
                    maxFiles={1}
                    onDrop={onDrop}
                  >
                    {({ getRootProps, getInputProps, }) => (
                      <>
                        {
                          files.length === 0 && (
                            <div {...getRootProps({ className: 'dropzone', })}>
                              <div className="d-flex justify-content-center align-items-center h-100">
                                <div className="border p-2 d-inline-flex">
                                  <FontAwesomeIcon icon={faUpload} />
                                </div>
                                <input {...getInputProps()} />
                                <p className="mb-0 ml-2">Drag and drop files or click here</p>
                              </div>
                            </div>
                          )
                        }
                        {
                          files.length > 0 && (
                            <div className="d-flex w-100">
                              <div className="col w-100 pr-5">{filesDom}</div>
                              <div {...getRootProps({ className: 'dropzone', })} className="border p-2 d-inline-flex">
                                <FontAwesomeIcon icon={faUpload} />
                                <input {...getInputProps()} />
                              </div>
                            </div>
                          )
                        }
                      </>
                    )}
                  </Dropzone>
                </FormGroup>
              </Col>
            </Row>
          )
        }

        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>Anestezie</Label>
              <Input
                required
                type="text"
                name="anestezieSev41FS1"
                disabled
                value={anestezieSev41FS1?.value || ''}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup check>
              <Input
                type="checkbox"
                value={recomUrmarireSec41FS1?.value || false}
                checked={recomUrmarireSec41FS1?.value === 'true'}
                name="recomUrmarireSec41FS1"
                onChange={() => {}}
              />
              <Label>Se recomandă managementul de urmărire </Label>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>Număr de luni până la următoarea vizită </Label>
              <Input
                type="number"
                min={0}
                name="urmVizitaNumericSec41FS1"
                disabled
                value={urmVizitaNumericSec41FS1?.value || ''}
              />
            </FormGroup>
          </Col>
        </Row>

      </CardBody>
    </Card>
  );
}

export default ColposcopieView;
