import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, CardHeader, Spinner } from 'reactstrap';
import { IndicatorTable, IndicatorFiltersExtended } from './components/index';

import { organizationsOperations } from '../../../redux/ducks/organizations';

import './index.scss';

const defaultFilters = {
  page: 1,
  size: 10,
};

const compareByOrder = (a, b) => {
  if (a.order < b.order) return -1;
  if (a.order > b.order) return 1;
  return 0;
};

const mapNames = {
  'Total femei mobilizate': 0,
  'Total femei recoltate': 1,
  'Total femei testate HPV': 2,
  'Total femei testate HPV pozitiv': 3,
  'Total femei testate HPV pozitiv si PAP negativ': 4,
  'Total femei testate HPV pozitiv si PAP pozitiv': 5,
  'Total femei testate PAP': 6,
  'Total femei testate PAP negativ': 7,
  'Total femei testate PAP pozitiv si HPV negativ': 8,
  'Total femei testate PAP pozitiv si HPV pozitiv': 9,
  'Total femei testate PAP nesatisfacator': 10,
  'Total femei colposcopie': 11,
  'Total femei biopsie': 12,
  'Total femei tratament': 13,
};

class Indicators extends Component {
  constructor() {
    super();

    this.setFilters = this.setFilters.bind(this);
    this.addFilterAndSearch = this.addFilterAndSearch.bind(this);
    this.selectFilterType = this.selectFilterType.bind(this);
    this.setSortFilter = this.setSortFilter.bind(this);
    this.resetFilters = this.resetFilters.bind(this);
    this.parseUrlAndSetFilters = this.parseUrlAndSetFilters.bind(this);
    this.setActiveStatus = this.setActiveStatus.bind(this);
    this.getOrganizations = this.getOrganizations.bind(this);
    this.handleSearch = this.handleSearch.bind(this);

    this.state = {
      filters: defaultFilters,
      filterType: 'simple',
      indicators: [],
      firstElem: 0,
      lastElem: 0,
      totalNo: 0,
      totalPages: 0,
      loading: false,
      organizations: [],
      resetKey: 0,
    };
  }

  componentDidMount() {
    const { location: { search, pathname, }, auth, navigate, getOrganizationsSimple, } = this.props;
    if (auth) {
      const { view, } = auth;
      if (view && !view.includes('ORGANIZATION_LIST')) {
        setTimeout(() => {
          navigate('/patient');
        }, 10);
      }
    }
    // if (search !== '') this.getOrganizations(`statistics${search}`);

    setTimeout(() => {
      this.parseUrlAndSetFilters();
    }, 50);

    setTimeout(() => {
      const { searchParams, } = this.props;
      if (this.props.location.search !== '') {
        this.getOrganizations(`statistics?${searchParams.toString()}`);
      }
    }, 100);

    getOrganizationsSimple()
      .then((res => {
        const { organizations, regions, } = res.data.data;
        this.setState({
          organizations,
          regions,
        });
      }))
      .catch((err => {
        console.log('err: ', err);
      }));
  }

  // componentDidUpdate(prevProps, prevState, snapshot) {
  //   if (prevProps.location.search !== this.props.location.search) {
  //     const { searchParams, } = this.props;
  //     if (this.props.location.search !== '') {
  //       this.getOrganizations(`statistics?${searchParams.toString()}`);
  //     }
  //   }
  // }

  parseUrlAndSetFilters() {
    const { searchParams, } = this.props;
    const parsedFilters = Object.fromEntries([...searchParams]);
    if (Object.keys(parsedFilters).length > 0) {
      this.setFilters(parsedFilters);
    } else {
      this.resetFilters();
    }
  }

  getOrganizations(url) {
    const { getOrganizations, } = this.props;
    this.setState({ loading: true, });
    getOrganizations(url)
      .then((res => {
        const arr = [];
        Object.keys(res.data.data).forEach(key => {
          arr.push({ name: key, value: res.data.data[key], order: mapNames[key], });
        });

        const { totalElements, totalPages, data, } = res.data;
        const { filters, } = this.state;
        this.setState({
          indicators: arr.sort(compareByOrder),
          totalNo: totalElements,
          firstElem: (filters.page - 1) * filters.size + 1,
          lastElem: filters.page * filters.size > totalElements ? totalElements : filters.page * filters.size,
          totalPages,
          loading: false,
        });
      }))
      .catch((err => {
        console.log('err: ', err);
        this.setState({ loading: false, });
      }));
  }

  setFilters(data) {
    this.setState({ filters: data, }, () => {
      const { setSearchParams, } = this.props;
      const { filters, } = this.state;
      setSearchParams(filters);
    });
  }

  addFilterAndSearch(data, pageSelect, removeKey) {
    Object.keys(data).forEach(k => data[k] === '' && delete data[k]);

    const { filters, } = this.state;
    if (removeKey) delete filters[removeKey];
    const newFilters = {
      ...filters,
      ...data,
      ...(!pageSelect && { page: 1, }),
    };
    this.setFilters(newFilters);
  }

  setSortFilter(e) {
    let newSortDir;
    const newSortBy = e.target.id;
    const { filters: { sortBy, sortDir, }, } = this.state;
    if (e.target.id === sortBy) {
      newSortDir = sortDir === 'asc' ? 'desc' : 'asc';
    } else {
      newSortDir = 'asc';
    }
    this.addFilterAndSearch({ sortBy: newSortBy, sortDir: newSortDir, }, true);
  }

  selectFilterType() {
    const { filterType, } = this.state;
    const newType = filterType === 'simple' ? 'extended' : 'simple';
    this.setState({ filterType: newType, });
  }

  resetFilters() {
    const { searchParams, setSearchParams, } = this.props;
    this.setState({ filters: defaultFilters, resetKey: Math.random(), }, () => {
      const { filters, } = this.state;
      setSearchParams(filters);
    });
  }

  handleSearch() {
    const { searchParams, } = this.props;
    if (this.props.location.search !== '') {
      this.getOrganizations(`statistics?${searchParams.toString()}`);
    }
  }

  setActiveStatus(data) {
    const { active, id, } = data;
    const { updateOrganization, } = this.props;
    updateOrganization({ active, id, })
      .then(() => {
        const { location: { search, pathname, }, } = this.props;
        this.getOrganizations(`${pathname}s${search}`);
      })
      .catch(err => {
        console.log('err: ', err);
      });
  }

  render() {
    const { resetKey, indicators = [], firstElem, lastElem, totalNo, filters, loading, organizations = [], } = this.state;
    const { location, auth, } = this.props;
    return (
      <>
        {
          loading && (
            <div
              className="loading-container"
            >
              <Spinner />
            </div>
          )
        }
        <div className={`container-fluid position-relative ${loading ? 'no-events loading-data' : ''}`}>
          <Card className="mt-4">
            <CardHeader className="position-relative">
              <div className="d-inline-block">
                <h3><span>Indicatori</span></h3>
              </div>
            </CardHeader>
            <CardBody>
              <IndicatorFiltersExtended
                organizations={organizations}
                auth={auth}
                handleSearch={this.handleSearch}
                addFilterAndSearch={this.addFilterAndSearch}
                setFilterType={this.selectFilterType}
                resetFilters={this.resetFilters}
                filters={filters}
                resetKey={resetKey}
              />
              <IndicatorTable
                key={indicators.length}
                auth={auth}
                setActiveStatus={this.setActiveStatus}
                setSortFilter={this.setSortFilter}
                tableData={indicators}
                filters={filters}
              />
            </CardBody>
          </Card>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  organizations: state.organizations.organizations,
  selectedOrganization: state.organizations.selectedOrganization,
});

const mapDispatchToProps = dispatch => ({
  getOrganizationsSimple: () => dispatch(organizationsOperations.getOrganizationsSimple()),
  getOrganizations: url => dispatch(organizationsOperations.getOrganizations(url)),
  selectOrganization: data => dispatch(organizationsOperations.selectOrganization(data)),
  updateOrganization: data => dispatch(organizationsOperations.updateOrganization(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Indicators);
