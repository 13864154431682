import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Card, CardBody, CardHeader, Col, Row, Spinner } from 'reactstrap';
import { IndicatorFiltersExtendedAdv } from './components/index';
import StatisticsTable from './StatisticsTable';
import { doctorsOperations } from '../../../redux/ducks/doctors';
import { organizationsOperations } from '../../../redux/ducks/organizations';

import './index.scss';

const defaultFilters = {
  page: 1,
  size: 10,
};

class RaportareStatistici extends Component {
  constructor() {
    super();

    this.setFilters = this.setFilters.bind(this);
    this.addFilterAndSearch = this.addFilterAndSearch.bind(this);
    this.selectFilterType = this.selectFilterType.bind(this);
    this.setSortFilter = this.setSortFilter.bind(this);
    this.resetFilters = this.resetFilters.bind(this);
    this.parseUrlAndSetFilters = this.parseUrlAndSetFilters.bind(this);
    this.getOrganizations = this.getOrganizations.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.calcDisabled = this.calcDisabled.bind(this);
    this.downloadReport = this.downloadReport.bind(this);

    this.state = {
      filters: defaultFilters,
      filterType: 'simple',
      indicators: [],
      firstElem: 0,
      lastElem: 0,
      totalNo: 0,
      totalPages: 0,
      loading: false,
      organizations: [],
      resetKey: 0,
      tableData: null,
      downloadUrl: null,
      tableTitle: '',
      tableSubtitle: '',
      tableKey: 0,
      isList: null,
    };
  }

  componentDidMount() {
    const { location: { search, pathname, }, auth, navigate, getOrganizationsSimple, setSearchParams, } = this.props;
    if (auth) {
      const { view, } = auth;
      if (view && !view.includes('ORGANIZATION_LIST')) {
        setTimeout(() => {
          navigate('/patient');
        }, 10);
      }
    }

    setTimeout(() => {
      // this.parseUrlAndSetFilters();
      setSearchParams({});
    }, 50);

    getOrganizationsSimple()
      .then((res => {
        const { organizations, regions, } = res.data.data;
        this.setState({
          organizations,
          regions,
        });
      }))
      .catch((err => {
        console.log('err: ', err);
      }));
  }

  parseUrlAndSetFilters() {
    const { searchParams, } = this.props;
    const parsedFilters = Object.fromEntries([...searchParams]);
    if (Object.keys(parsedFilters).length > 0) {
      this.setFilters(parsedFilters);
    } else {
      this.resetFilters();
    }
  }

  getOrganizations(url) {
    const { getOrganizations, } = this.props;
    this.setState({ loading: true, tableData: null, });
    getOrganizations(url)
      .then((res => {
        const { totalElements, totalPages, data, } = res.data;
        const { filters, } = this.state;
        this.setState({
          tableData: data,
          tableTitle: data.title,
          tableSubtitle: data.subtitle,
          tableKey: Math.random(),
          isList: data.isList || false,
          totalNo: totalElements,
          firstElem: (filters.page - 1) * filters.size + 1,
          lastElem: filters.page * filters.size > totalElements ? totalElements : filters.page * filters.size,
          totalPages,
          loading: false,
        });
      }))
      .catch((err => {
        console.log('err: ', err);
        this.setState({ loading: false, });
      }));
  }

  setFilters(data) {
    this.setState({ filters: data, }, () => {
      const { setSearchParams, } = this.props;
      const { filters, } = this.state;
      setSearchParams(filters);
    });
  }

  addFilterAndSearch(data, pageSelect, removeKey) {
    Object.keys(data).forEach(k => data[k] === '' && delete data[k]);

    const { filters, } = this.state;
    if (removeKey) delete filters[removeKey];
    const newFilters = {
      ...filters,
      ...data,
      ...(!pageSelect && { page: 1, }),
    };
    this.setFilters(newFilters);
  }

  setSortFilter(e) {
    let newSortDir;
    const newSortBy = e.target.id;
    const { filters: { sortBy, sortDir, }, } = this.state;
    if (e.target.id === sortBy) {
      newSortDir = sortDir === 'asc' ? 'desc' : 'asc';
    } else {
      newSortDir = 'asc';
    }
    this.addFilterAndSearch({ sortBy: newSortBy, sortDir: newSortDir, }, true);
  }

  selectFilterType() {
    const { filterType, } = this.state;
    const newType = filterType === 'simple' ? 'extended' : 'simple';
    this.setState({ filterType: newType, });
  }

  resetFilters() {
    const { searchParams, setSearchParams, } = this.props;
    this.setState({ filters: defaultFilters, resetKey: Math.random(), }, () => {
      const { filters, } = this.state;
      setSearchParams(filters);
    });
  }

  handleSearch() {
    const { searchParams, } = this.props;
    if (this.props.location.search !== '') {
      this.setState({ downloadUrl: searchParams.toString(), }, () => {
        this.getOrganizations(`statisticreports?${searchParams.toString()}`);
      });
    }
  }

  handleSubmit() {
    const { setSearchParams, } = this.props;
    const { filters, } = this.state;
    setSearchParams(filters);
  }

  calcDisabled() {
    const { report, dateStartYear, dateEndYear, dateStartMonth, dateEndMonth, } = this.state.filters;
    let disabled = false;
    if (!report || report === '') disabled = true;
    if (!dateStartYear || dateStartYear === '') disabled = true;
    if (!dateStartMonth || dateStartMonth === '') disabled = true;
    if (!dateEndYear || dateEndYear === '') disabled = true;
    if (!dateEndMonth || dateEndMonth === '') disabled = true;
    return disabled;
  }

  downloadReport(e) {
    const type = e.target.id;
    const { downloadUrl, } = this.state;
    const fileType = type === 'pdf' ? 'pdf' : 'xlsx';
    const reqUrl = type === 'pdf'
      ? `/statisticdownload?type=PDF&${downloadUrl}`
      : `/statisticdownload?type=XLS&${downloadUrl}`;

    const { downloadStatistic, } = this.props;
    downloadStatistic(reqUrl)
      .then(response => {
        // this.setState({ loading: false, });
        const timestamp = new Date().valueOf();
        const fileName = `Export-${timestamp}.${fileType}`;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
      })
      .catch(err => {
        console.log('err: ', err);
      });
  }

  render() {
    const { auth, getDoctorsSimple, } = this.props;

    const {
      resetKey, tableTitle, tableSubtitle, tableKey, isList,
      tableData, filters, loading, organizations = [],
    } = this.state;

    console.log(this.state);

    const isDisabled = this.calcDisabled();
    return (
      <>
        {
          loading && (
            <div
              className="loading-container"
            >
              <Spinner />
            </div>
          )
        }
        <div className={`container-fluid position-relative ${loading ? 'no-events loading-data' : ''}`}>
          <Card className="mt-4">
            <CardHeader className="position-relative">
              <div className="d-inline-block">
                <h3><span>Raportare si Statistica</span></h3>
              </div>
            </CardHeader>
            <CardBody>
              <IndicatorFiltersExtendedAdv
                organizations={organizations}
                auth={auth}
                handleSearch={this.handleSearch}
                addFilterAndSearch={this.addFilterAndSearch}
                setFilterType={this.selectFilterType}
                resetFilters={this.resetFilters}
                getDoctorsSimple={getDoctorsSimple}
                filters={filters}
                resetKey={resetKey}
              />
              <Row>
                <Col className="text-center">
                  <Button
                    onClick={isDisabled ? () => {} : this.handleSearch}
                    className="btn-primary w-25 mb-4"
                    style={{ marginTop: 32, }}
                    disabled={isDisabled}
                  >
                    Trimite
                  </Button>
                </Col>
              </Row>

              <Row>
                <Col>
                  {
                    tableData && (
                      <StatisticsTable
                        isList={isList}
                        tableKey={tableKey}
                        tableTitle={tableTitle}
                        tableSubitle={tableSubtitle}
                        downloadReport={this.downloadReport}
                        filters={filters}
                        tableData={tableData}
                      />
                    )
                  }
                </Col>
              </Row>
            </CardBody>
          </Card>

        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  organizations: state.organizations.organizations,
  selectedOrganization: state.organizations.selectedOrganization,
});

const mapDispatchToProps = dispatch => ({
  getOrganizationsSimple: () => dispatch(organizationsOperations.getOrganizationsSimple()),
  getOrganizations: url => dispatch(organizationsOperations.getOrganizations(url)),
  selectOrganization: data => dispatch(organizationsOperations.selectOrganization(data)),
  updateOrganization: data => dispatch(organizationsOperations.updateOrganization(data)),
  getDoctorsSimple: orgId => dispatch(doctorsOperations.getDoctorsSimple(orgId)),
  downloadStatistic: url => dispatch(organizationsOperations.downloadStatistic(url)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RaportareStatistici);
