const GET_PATIENTS = 'GET_PATIENTS';
const GET_PATIENTS_COMPLETED = 'GET_PATIENTS_COMPLETED';
const GET_PATIENTS_FAILED = 'GET_PATIENTS_FAILED';
const GET_SINGLE_PATIENT = 'GET_SINGLE_PATIENT';
const GET_SINGLE_PATIENT_COMPLETED = 'GET_SINGLE_PATIENT_COMPLETED';
const GET_SINGLE_PATIENT_FAILED = 'GET_SINGLE_PATIENT_FAILED';
const CREATE_PATIENT = 'CREATE_PATIENT';
const CREATE_PATIENT_COMPLETED = 'CREATE_PATIENT_COMPLETED';
const CREATE_PATIENT_FAILED = 'CREATE_PATIENT_FAILED';
const UPDATE_PATIENT = 'UPDATE_PATIENT';
const UPDATE_PATIENT_COMPLETED = 'UPDATE_PATIENT_COMPLETED';
const UPDATE_PATIENT_FAILED = 'UPDATE_PATIENT_FAILED';
const DOWNLOAD_PATIENT = 'DOWNLOAD_PATIENT';
const DOWNLOAD_PATIENT_COMPLETED = 'DOWNLOAD_PATIENT_COMPLETED';
const DOWNLOAD_PATIENT_FAILED = 'DOWNLOAD_PATIENT_FAILED';

export default {
  GET_PATIENTS,
  GET_PATIENTS_COMPLETED,
  GET_PATIENTS_FAILED,
  GET_SINGLE_PATIENT,
  GET_SINGLE_PATIENT_COMPLETED,
  GET_SINGLE_PATIENT_FAILED,
  CREATE_PATIENT,
  CREATE_PATIENT_COMPLETED,
  CREATE_PATIENT_FAILED,
  UPDATE_PATIENT,
  UPDATE_PATIENT_COMPLETED,
  UPDATE_PATIENT_FAILED,
  DOWNLOAD_PATIENT,
  DOWNLOAD_PATIENT_COMPLETED,
  DOWNLOAD_PATIENT_FAILED,
};
