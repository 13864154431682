import React, { useEffect, useState } from 'react';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import Select from 'react-select';
import 'react-datepicker/dist/react-datepicker.css';

const years = [
  { value: 2021, label: 2021, },
  { value: 2022, label: 2022, },
  { value: 2023, label: 2023, },
  { value: 2024, label: 2024, }
];

const months = [
  { value: '01', label: 'Ianuarie', },
  { value: '02', label: 'Februarie', },
  { value: '03', label: 'Martie', },
  { value: '04', label: 'Aprilie', },
  { value: '05', label: 'Mai', },
  { value: '06', label: 'Iunie', },
  { value: '07', label: 'Iulie', },
  { value: '08', label: 'August', },
  { value: '09', label: 'Septembrie', },
  { value: '10', label: 'Octombrie', },
  { value: '11', label: 'Noiembrie', },
  { value: '12', label: 'Decembrie', }

];

function YearMonthSectionFilter({ resetKey, type, yearTitle, monthTitle, handleChange, filters, }) {
  const [selectedYear, setSelectedYear] = useState('');
  const [selectedMonth, setSelectedMonth] = useState('');

  const handleYearChange = (selected, details) => {
    setSelectedYear(selected);
    handleChange(selected, details);
  };

  const handleMonthChange = (selected, details) => {
    setSelectedMonth(selected);
    handleChange(selected, details);
  };

  useEffect(() => {
    handleResetFilters();
  }, [resetKey]);

  useEffect(() => {
    if (filters[`${type}Year`] !== '') {
      const id = filters[`${type}Year`] || '';
      if (id !== '') {
        const selected = years.find(elem => elem.value == id);
        if (selected) {
          setSelectedYear(selected);
        }
      }
    }
  }, [filters[`${type}Year`]]);
  useEffect(() => {
    if (filters[`${type}Month`] !== '') {
      const id = filters[`${type}Month`] || '';
      if (id !== '') {
        const selected = months.find(elem => elem.value == id);
        if (selected) {
          setSelectedMonth(selected);
        }
      }
    }
  }, [filters[`${type}Month`]]);

  const handleResetFilters = () => {
    setSelectedYear('');
    setSelectedMonth('');
  };

  return (
    <Row>
      <Col>
        <FormGroup>
          <Label>
            <small className="ml-sm-2 font-weight-bold">{yearTitle}</small>
          </Label>
          <Select
            value={selectedYear}
            onChange={handleYearChange}
            className="basic-single"
            classNamePrefix="select"
            placeholder=""
            name={`${type}Year`}
            options={years}
            isClearable
          />
        </FormGroup>
      </Col>
      <Col>
        <FormGroup>
          <Label>
            <small className="ml-sm-2 font-weight-bold">{monthTitle}</small>
          </Label>
          <Select
            value={selectedMonth}
            onChange={handleMonthChange}
            className="basic-single"
            classNamePrefix="select"
            placeholder=""
            name={`${type}Month`}
            options={months}
            isClearable
          />
        </FormGroup>
      </Col>
    </Row>
  );
}

export default YearMonthSectionFilter;
