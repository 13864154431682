import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCheckCircle, faEye, faPencilAlt, faTimes, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { NavLink, useLocation } from 'react-router-dom';
import { Button } from 'reactstrap';

function PatientsTableRow({ data, handleActiveModal, canEdit, canActivate, }) {
  const { id, active, createdBy = '', person: { firstName, lastName, cnp, }, } = data;

  const location = useLocation();

  return (
    <tr>
      <td>{lastName || ''}</td>
      <td>{firstName || ''}</td>
      <td>{cnp || ''}</td>
      <td>{createdBy || ''}</td>
      <td style={{ minWidth: 80, }}>
        { active
          ? <FontAwesomeIcon fontSize={24} icon={faCheck} />
          : <FontAwesomeIcon fontSize={24} icon={faTimes} />}
      </td>
      <td className="text-right" style={{ minWidth: 180, }}>
        <div className="actions">
          <NavLink
            className="btn btn-info btn-sm btn-pill mr-1"
            to={`/patient/${id}/view`}
            state={{ from: location, }}
          >
            <FontAwesomeIcon icon={faEye} />
          </NavLink>
          {canEdit && active && (
            <NavLink
              to={`/patient/${id}/edit`}
              state={{ from: location, }}
              className="btn btn-primary btn-sm btn-pill mr-1"
            >
              <FontAwesomeIcon icon={faPencilAlt} />
            </NavLink>
          )}
          {canActivate && active && (
            <div
              style={{ width: '4rem', borderRadius: '20rem', }}
              className="btn btn-outline-success text-right p-0 row-btn-wrapper"
            >
              <Button
                onClick={() => handleActiveModal(data)}
                color="success"
                className="btn-pill p-0"
              >
                <FontAwesomeIcon icon={faCheckCircle} />
              </Button>
            </div>
          )}
        </div>
      </td>
    </tr>
  );
}

export default PatientsTableRow;
