import React, { Component } from 'react';
import * as yup from 'yup';

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row
} from 'reactstrap';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faSave } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import OrganizationAddressInput from './OrganizationAddressInput';
import { organizationTypeOptions, organizationServicesOptions } from '../organization.helper';
import OrganizationServicePriceSection from './OrganizationServicePriceSection';
import { organizationsOperations } from '../../../../redux/ducks/organizations';

const schema = yup.object().shape({
  code: yup.string().required(),
  email: yup.string().email(),
});

class OrganizationForm extends Component {
  constructor() {
    super();
    this.state = {
      id: null,
      code: '',
      codeError: false,
      label: '',
      labelError: false,
      description: '',
      representative: '',
      organizationType: '',
      services: [],
      servicesSimple: [],
      servicePrice: null,
      address: null,
      saving: false,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleSelectInputChange = this.handleSelectInputChange.bind(this);
    this.checkValidNewOption = this.checkValidNewOption.bind(this);
    this.createOrganizationTypeOption = this.createOrganizationTypeOption.bind(this);
    this.formatCreateLabel = this.formatCreateLabel.bind(this);
    this.handleOrganizationServiceSelect = this.handleOrganizationServiceSelect.bind(this);
    this.cancel = this.cancel.bind(this);
    this.getSingleOrganization = this.getSingleOrganization.bind(this);
    this.prefillFields = this.prefillFields.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.setPriceValues = this.setPriceValues.bind(this);
    this.setAddress = this.setAddress.bind(this);
    this.calcServices = this.calcServices.bind(this);
    this.calcServicesSimple = this.calcServicesSimple.bind(this);
  }

  componentDidMount() {
    const { location, auth, } = this.props;

    if (!auth?.create.includes('ORGANIZATION_CREATE')) {
      setTimeout(() => { navigate('/'); }, 10);
    }

    const splitUrl = location.pathname.split('/organization/')[1];

    if (splitUrl !== 'new') {
      this.getSingleOrganization(splitUrl);
    }
  }

  getSingleOrganization(url) {
    const { getSingleOrganization, } = this.props;
    const id = url.split('/')[0];
    getSingleOrganization(id)
      .then((res => {
        this.prefillFields(res.data.data);
      }))
      .catch((err => {
        console.log('err: ', err);
      }));
  }

  setPriceValues(data) {
    this.setState({ servicePrice: data, });
  }

  setAddress(newAddress) {
    this.setState({ address: newAddress, });
  }

  prefillFields(selected) {
    const newState = {
      ...this.state,
      ...selected,
      organizationType: selected.type ? { label: selected.type, value: selected.type, } : null,
      servicesSimple: selected.services.map(a => a.value),
      servicePrice: selected.servicePrice ? JSON.parse(selected.servicePrice) : null,
    };
    this.setState({ ...newState, });
  }

  calcServices(data = []) {
    const arr = [];
    data.forEach(elem => {
      const service = organizationServicesOptions.find(a => a.value === elem);
      arr.push(service);
    });
    return arr;
  }

  calcServicesSimple(data = []) {
    const arr = [];
    data.forEach(elem => {
      const service = organizationServicesOptions.find(a => a.value === elem);
      arr.push(service.value);
    });
    return arr;
  }

  handleSubmit() {
    this.setState({ saving: true, });
    const { createOrganization, updateOrganization, } = this.props;
    const {
      address,
      code,
      description,
      id,
      label,
      organizationType,
      representative,
      servicePrice,
      services,
    } = this.state;

    const createPayload = {
      address,
      code,
      description,
      id,
      label,
      representative,
      organizationType: organizationType.value,
      servicePrice: JSON.stringify(servicePrice),
      services,
    };

    if (id === null) {
      createOrganization(createPayload).then(res => {
        const { navigate, } = this.props;
        setTimeout(() => {
          navigate(`/organization/${res.data?.data?.id}/view`);
        }, 100);
      }).catch(err => {
        console.log('err: ', err);
        this.setState({ saving: false, });
      });
    } else {
      updateOrganization(createPayload).then(() => {
        const { navigate, } = this.props;
        setTimeout(() => {
          navigate(`/organization/${createPayload.id}/view`);
        }, 100);
      }).catch(err => {
        console.log('err: ', err);
        this.setState({ saving: false, });
      });
    }
  }

  handleInputChange(e) {
    const { name, value, required, } = e.target;
    this.setState({ [name]: value, }, () => {
      if (required) {
        schema.validate({ code: value, }).then(() => {
          this.setState({ [`${name}Error`]: false, });
        }).catch(() => {
          this.setState({ [`${name}Error`]: true, });
        });
      }
    });
  }

  handleSelectChange(selected) {
    this.setState({ organizationType: selected, });
  }

  handleSelectInputChange(value) {
    // console.group('Input Changed');
  }

  checkValidNewOption(val) {
    return val.length > 2;
  }

  createOrganizationTypeOption(val) {
    organizationTypeOptions.push({ value: val, label: val, });
    this.setState({ organizationType: val, });
  }

  formatCreateLabel(val) {
    return `Adauga '${val}'`;
  }

  noOptionMessage(val) {
    return 'Nu exista optiuni';
  }

  handleOrganizationServiceSelect(val) {
    this.setState({
      services: [...val],
      servicesSimple: [...val].map(a => a.value),
    });
  }

  cancel() {
    const { navigate, } = this.props;
    navigate(-1);
  }

  render() {
    const {
      code, codeError,
      label, labelError, id,
      description, representative,
      organizationType, services, servicesSimple, address, servicePrice,
      saving,
    } = this.state;
    const saveDisabled = (code === '') || (label === '') || address === null;
    return (
      <Container fluid className={`d-flex ${saving ? 'no-events' : ''}`}>
        <Row className="justify-content-center w-100">
          <Col sm={8}>
            <Card className="mt-3">
              <CardHeader>
                <h3 className="font-weight-light mb-0">Creare sau editare Furnizor</h3>
              </CardHeader>
              <CardBody>
                <FormGroup>
                  <Label>Cod</Label>
                  <Input
                    required
                    type="text"
                    name="code"
                    className={`${(code === '' || codeError) && 'invalid'} `}
                    onChange={this.handleInputChange}
                    onBlur={this.handleInputChange}
                    value={code}
                  />
                  { codeError && <p className="error text-danger mt-1 mb-1">Acest câmp este necesar.</p>}
                </FormGroup>
                <FormGroup>
                  <Label>Denumire / Nume furnizor</Label>
                  <Input
                    required
                    type="text"
                    name="label"
                    className={`${(label === '' || labelError) && 'invalid'} `}
                    onChange={this.handleInputChange}
                    onBlur={this.handleInputChange}
                    value={label}
                  />
                  { labelError && <p className="error text-danger mt-1 mb-1">Acest câmp este necesar.</p>}
                </FormGroup>
                <FormGroup>
                  <Label>Descriere</Label>
                  <Input
                    type="text"
                    name="description"
                    onChange={this.handleInputChange}
                    value={description}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Tip furnizor</Label>
                  <Select
                    defaultValue={organizationType}
                    value={organizationType}
                    isClearable
                    placeholder=""
                    onChange={this.handleSelectChange}
                    onInputChange={this.handleSelectInputChange}
                    options={organizationTypeOptions}
                    isValidNewOption={this.checkValidNewOption}
                    formatCreateLabel={this.formatCreateLabel}
                    noOptionsMessage={this.noOptionMessage}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Reprezentant legal</Label>
                  <Input
                    type="text"
                    name="representative"
                    onChange={this.handleInputChange}
                    value={representative || ''}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Servicii</Label>
                  <Select
                    isMulti
                    value={services}
                    className="select-invalid"
                    classNamePrefix="servicesSelect"
                    placeholder=""
                    isClearable
                    name="organizationServices"
                    options={organizationServicesOptions}
                    onChange={this.handleOrganizationServiceSelect}
                  />
                </FormGroup>

                <OrganizationServicePriceSection
                  servicesSimple={servicesSimple}
                  servicePrice={servicePrice}
                  setPriceValues={this.setPriceValues}
                />

                <OrganizationAddressInput
                  address={address}
                  setAddress={this.setAddress}
                />
                <Row className="mt-3">
                  <Col md={12} className="text-right">
                    <Button
                      onClick={this.cancel}
                      id="cancel-save"
                      color="secondary"
                    >
                      <FontAwesomeIcon icon={faBan} />
                      <span className="ml-1">Anulare</span>
                    </Button>
                    <Button
                      disabled={saveDisabled}
                      onClick={saveDisabled ? () => {} : this.handleSubmit}
                      id="save"
                      color="primary"
                      className="ml-1"
                    >
                      <FontAwesomeIcon icon={faSave} />
                      <span className="ml-1">Salvare</span>
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}
const mapStateToProps = state => ({
  auth: state.auth,
  users: state.users.users,
  selectedUser: state.users.selectedUser,
});

const mapDispatchToProps = dispatch => ({
  createOrganization: data => dispatch(organizationsOperations.createOrganization(data)),
  updateOrganization: data => dispatch(organizationsOperations.updateOrganization(data)),
  getSingleOrganization: id => dispatch(organizationsOperations.getSingleOrganization(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationForm);
