/* eslint-disable no-undef */
import actions from './actions';

const getDoctors = url => dispatch => new Promise(((resolve, reject) => {
  dispatch(actions.getDoctors(url))
    .then(res => {
      resolve(res);
    })
    .catch(err => {
      reject(err);
    });
}));

const getDoctorsSimple = orgId => dispatch => new Promise(((resolve, reject) => {
  dispatch(actions.getDoctorsSimple(orgId))
    .then(res => {
      resolve(res);
    })
    .catch(err => {
      reject(err);
    });
}));

const getSingleDoctor = id => dispatch => new Promise(((resolve, reject) => {
  dispatch(actions.getSingleDoctor(id))
    .then(res => {
      resolve(res);
    })
    .catch(err => {
      reject(err);
    });
}));

const createDoctor = data => dispatch => new Promise(((resolve, reject) => {
  dispatch(actions.createDoctor(data))
    .then(res => {
      resolve(res);
    })
    .catch(err => {
      reject(err);
    });
}));

const updateDoctor = data => dispatch => new Promise(((resolve, reject) => {
  dispatch(actions.updateDoctor(data))
    .then(res => {
      resolve(res);
    })
    .catch(err => {
      reject(err);
    });
}));

export default {
  createDoctor,
  updateDoctor,
  getDoctors,
  getSingleDoctor,
  getDoctorsSimple,
};
