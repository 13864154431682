import React from 'react';
import moment from 'moment';

const sectionsMapping = {
  s1: { name: 'Informare şi consiliere', },
  s2: { name: 'Consultație şi recoltare', },
  s3A: { name: 'Testare HPV', },
  s3B: { name: 'Testare BPN', },
};

const calcColor = status => {
  if (status) {
    return status === 'FINAL' ? 'text-success' : status === 'EDIT' ? 'text-warning' : 'text-info';
  }
  return '';
};

const calcLabel = status => {
  if (status) {
    return status === 'FINAL' ? 'Finalizat' : status === 'EDIT' ? 'In lucru' : 'Inactiv';
  }
  return '';
};

const calcOrgLabel = label => {
  if (label) {
    if (label.length > 50) {
      return `${label.substring(0, 50)}...`;
    }
    return label;
  }
  return '';
};

function PatientCasefileTable({ casefile, }) {
  const { sections, } = casefile;

  if (typeof sections === 'undefined') {
    return <div className="text-primary">Nu a fost găsită nicio fișă.</div>;
  }

  const domSections = Object.keys(sectionsMapping).map((elem => {
    const colorClassName = calcColor(sections[elem]?.status);
    const statusLabel = calcLabel(sections[elem]?.status);
    const orgLabel = calcOrgLabel(sections[elem]?.organization?.label);

    if (sections[elem]) {
      return (
        <div key={`id-${elem}`} className="mb-1">
          <div className="d-flex">
            <div>
              <small className="text-uppercase">
                <strong>
                  {sectionsMapping[elem].name}
                  :
                </strong>
              </small>
              <span>
                {' '}
                {orgLabel}
              </span>
              <span>
                {' / '}
                {sections[elem]?.doctor?.person?.fullName || ''}
                {' / '}
                { sections[elem]?.sectionDate ? moment(sections[elem]?.sectionDate).format('DD-MM-YYYY') : '' }
                {' / '}
                <span className={`${colorClassName} text-uppercase`}>{statusLabel}</span>
                {
                  sections[elem]?.result && (
                    <span className="font-weight-bold">
                      /
                      <strong>
                        {' '}
                        {sections[elem]?.result}
                      </strong>
                    </span>
                  )
                }
              </span>
            </div>
          </div>
        </div>
      );
    }
    return '';
  }));

  return (
    <div>
      {domSections}
    </div>
  );
}

export default PatientCasefileTable;
