import { combineReducers } from 'redux';
import types from './types';

const initialState = {
  casefiles: [],
  selectedCasefile: null,
};

const casefiles = (state = initialState.casefiles, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const selectedCasefile = (state = initialState.selectedCasefile, action) => {
  switch (action.type) {
    case types.SELECT_CASEFILE:
      return action.payload;
    default:
      return state;
  }
};

export default combineReducers({
  casefiles,
  selectedCasefile,
});
