import React, { useState } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Label, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faRemove } from '@fortawesome/free-solid-svg-icons';

function DoctorContactsEntry({ showAddressModal, showDeleteModal, contact, readOnly, }) {
  const { id, label, description, contactType: { label: contactLabel, }, } = contact;

  const [showBody, setShowBody] = useState(false);
  const toggleBody = () => {
    setShowBody(!showBody);
  };

  const editAddress = () => {
    showAddressModal();
  };

  const deleteAddress = contact => {
    showDeleteModal(contact);
  };

  return (
    <Card className="mb-2">
      <CardHeader className="border-bottom-0">
        <div className="d-flex align-items-center justify-content-between">
          <Button
            onClick={toggleBody}
            color="link"
            className="w-100 text-left pl-0 collapsed"
          >
            <div>{`${label ? `${label}, ` : ''}${description ? `${description}, ` : ''}${contactLabel ? `${contactLabel}` : ''}`}</div>
          </Button>
          { readOnly ? null : (
            <>
              <Button
                onClick={() => { showAddressModal(contact); }}
                color="primary"
                className="btn-pill p-0"
              >
                <FontAwesomeIcon icon={faPencilAlt} />
              </Button>
              <Button
                onClick={() => { showDeleteModal(contact); }}
                color="danger"
                className="btn-pill ml-1 p-0"
              >
                <FontAwesomeIcon icon={faRemove} />
              </Button>
            </>
          )}

        </div>
      </CardHeader>
      { showBody && (
        <CardBody className="border-top">
          <Row>
            <Col sm={6}>
              <Label className="font-weight-bold mb-0">
                Etichetă
              </Label>
              <div className="mb-3">
                {label || ''}
              </div>
            </Col>
            <Col sm={6}>
              <Label className="font-weight-bold mb-0">
                Descriere
              </Label>
              <div className="mb-3">
                {description || ''}
              </div>
            </Col>
            <Col sm={6}>
              <Label className="font-weight-bold mb-0">
                Tip contact
              </Label>
              <div className="mb-3">
                {contactLabel || ''}
              </div>
            </Col>
          </Row>
        </CardBody>
      )}
    </Card>

  );
}

export default DoctorContactsEntry;
