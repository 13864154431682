import types from './types';

const getCasefiles = url => ({
  type: types.GET_CASEFILES,
  async: true,
  generalFetching: true,
  payload: {
    path: url,
    method: 'GET',
  },
});

const getSingleCasefile = id => ({
  type: types.GET_SINGLE_CASEFILE,
  async: true,
  generalFetching: true,
  payload: {
    path: `/casefiles/${id}`,
    method: 'GET',
  },
});

const getSingleFile = id => ({
  type: types.GET_SINGLE_FILE,
  async: true,
  generalFetching: true,
  payload: {
    path: `/tsfentities/${id}`,
    method: 'GET',
  },
});

const downloadFile = id => ({
  type: types.DOWNLOAD_FILE,
  async: true,
  generalFetching: true,
  payload: {
    path: `/tsfentitiesreport/${id}`,
    method: 'GET',
    responseType: 'blob',
  },
});

const createCasefile = data => ({
  type: types.CREATE_CASEFILE,
  async: true,
  generalFetching: true,
  payload: {
    path: '/casefiles',
    method: 'POST',
    body: data,
  },
});

const createFile = id => ({
  type: types.CREATE_FILE,
  async: true,
  generalFetching: true,
  payload: {
    path: '/tsfentities',
    method: 'POST',
    body: { patient: { id, }, },
  },
});

const updateFile = data => ({
  type: types.UPDATE_FILE,
  async: true,
  generalFetching: true,
  payload: {
    path: `/tsfentities/${data.id}`,
    method: 'PUT',
    body: data.body,
  },
});

const updateCasefile = data => ({
  type: types.UPDATE_CASEFILE,
  async: true,
  generalFetching: true,
  payload: {
    path: `/casefiles/${data.id}`,
    method: 'PUT',
    body: data,
  },
});

const updateTsfEntityDates = data => ({
  type: types.UPDATE_TSF_ENTITY_DATES,
  async: true,
  generalFetching: true,
  payload: {
    path: `/tsfentities/dates/${data.id}`,
    method: 'PUT',
    body: data.body,
  },
});

export default {
  getCasefiles,
  createCasefile,
  getSingleCasefile,
  updateCasefile,
  updateTsfEntityDates,
  getSingleFile,
  createFile,
  updateFile,
  downloadFile,
};
