import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, CardHeader, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import moment from 'moment';

function InformareView({ data, changeTab, }) {
  const [inputs, setInputs] = useState({});

  useEffect(() => {
    const inputs = data.attributes;
    setInputs(inputs);
    if (Object.keys(inputs).length === 0) return;
    inputs.absentaCongenitalaSec1FS1.value = inputs.absentaCongenitalaSec1FS1.value.toString();
    inputs.histerectomieSec1FS1.value = inputs.histerectomieSec1FS1.value.toString();
  }, []);

  const setTab = e => { changeTab(e.target.id); };

  const {
    serieFormular,
    beneficiarSec1FS1,
    dataAnamnezeiSec1FS1,
    cabinetMedicalSec1FS1,
    medicDeFamilieSec1FS1,
    consilierSec1FS1,
    absentaCongenitalaSec1FS1,
    histerectomieSec1FS1,
    tipAfectiuneHisterectomieTotala,
  } = inputs;

  return (
    <Card>
      <CardHeader>
        <h3 className="mb-0">
          <div className="d-flex">
            <div>
              <span className="mr-3">1. Informare şi consiliere</span>
            </div>
            <div className="ml-auto">
              {/* <Button id="-" size="sm" color="secondary" className="ml-auto btn-pill" onClick={setTab}>{'<'}</Button> */}
              <Button id="+" size="sm" color="primary" className="ml-1 btn-pill" onClick={setTab}>{'>'}</Button>
            </div>
          </div>
        </h3>
      </CardHeader>
      <CardBody>

        <Row>
          <Col md={12}>
            <FormGroup>
              <Label>Număr/Serie formular </Label>
              <Input
                type="text"
                name="serieFormular"
                disabled
                value={serieFormular?.value || ''}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>Beneficiar</Label>
              <Input
                type="text"
                name="beneficiarSec1FS1"
                disabled
                value={beneficiarSec1FS1?.value || ''}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label>
                Data informare-consiliere *
              </Label>
              <Input
                required
                type="text"
                name="dataAnamnezeiSec1FS1"
                disabled
                value={dataAnamnezeiSec1FS1?.value ? moment(dataAnamnezeiSec1FS1.value).format('DD-MM-YYYY') : ''}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>Centru de informare-consiliere</Label>
              <Input
                type="text"
                name="cabinetMedicalSec1FS1"
                disabled
                value={cabinetMedicalSec1FS1?.value || ''}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label>
                Medic de familie
              </Label>
              <Input
                type="text"
                name="medicDeFamilieSec1FS1"
                disabled
                value={medicDeFamilieSec1FS1?.value || ''}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>Personal informare-consiliere</Label>
              <Input
                required
                type="text"
                name="consilierSec1FS1"
                disabled
                value={consilierSec1FS1?.value || ''}
              />
            </FormGroup>
          </Col>
          <Col md={6} />
        </Row>
        <Row>
          <Col>
            <FormGroup check>
              <Input
                type="checkbox"
                value={absentaCongenitalaSec1FS1?.value || false}
                checked={absentaCongenitalaSec1FS1?.value === 'true'}
                name="absentaCongenitalaSec1FS1"
                onChange={() => {}}
              />
              <Label>Absența congenitală a colului uterin </Label>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup check>
              <Input
                type="checkbox"
                value={histerectomieSec1FS1?.value || false}
                checked={histerectomieSec1FS1?.value === 'true'}
                name="histerectomieSec1FS1"
                onChange={() => {}}
              />
              <Label>Histerectomie totală </Label>
            </FormGroup>
          </Col>
          {
            histerectomieSec1FS1?.value === 'true' && (
              <Col md={6}>
                <FormGroup>
                  <Label>Tip afecțiune histerectomie totală</Label>
                  <Input
                    required
                    type="text"
                    name="tipAfectiuneHisterectomieTotala"
                    disabled
                    value={tipAfectiuneHisterectomieTotala?.value || ''}
                  />
                </FormGroup>
              </Col>
            )
          }
        </Row>
      </CardBody>
    </Card>
  );
}

export default InformareView;
