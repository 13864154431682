import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup, Card, CardBody, CardHeader, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import moment from 'moment';
import InfectiiCheckboxes from '../Checkboxes/InfectiiCheckboxes';
import AnomaliiCelScuamoaseCheckboxes from '../Checkboxes/AnomaliiCelScuamoaseCheckboxes';
import ModifNonNeoplaziceCheckboxes from '../Checkboxes/ModifNonNeoplaziceCheckboxes';

function TestareBabesView({ data, changeTab, readOnly, }) {
  const [inputs, setInputs] = useState({});
  const [flacon, setFlacon] = useState({});

  const checkboxInputs = ['alteNeoplaziiSec33FS1', 'repetareSec34FS1', 'biopsieSec34FS1',
    'colposcopieSec34FS1', 'chiuretajCervSec34FS1', 'chiuretajMetrSec34FS1',
    'testHpvSec34FS1', 'repRutinaNegSec34FS1'
  ];

  useEffect(() => {
    const inputs = data.attributes;
    setInputs(inputs);
    setFlacon(data.flaconNumberAuto);
    if (Object.keys(inputs).length === 0) return;
    checkboxInputs.forEach(elem => {
      inputs[elem].value = inputs[elem].value.toString();
    });
  }, []);

  const {
    laboratorSec3BFS1,
    medicAnatoPatoSec3BFS1,
    medicMedicinaLab3BFS1,
    medicBiolog3BFS1,
    medicAnatoPatoCertificareSec3BFS1,
    numarFlaconSauLamaClone,
    nrFlaconSec3BFS1,
    dataPrimireFlaconSec3BFS1,
    dataInterSec3BFS1,
    calitateFrotiuSec32FS1,
    microorganismeSec33FS1,
    modificariCelSec33FS1,
    anomaliiScSec33FS1,
    anomaliiCelGlandSec33FS1,
    alteNeoplaziiSec33FS1,
    rezFinalSec44FS1,
    repetareSec34FS1,
    biopsieSec34FS1,
    colposcopieSec34FS1,
    chiuretajCervSec34FS1,
    chiuretajMetrSec34FS1,
    testHpvSec34FS1,
    repRutinaNegSec34FS1,
    obs1Sec34FS1,
    dataElibRezSec34FS1,
    lamaSec32FS1 = [],
    frotiuSec32FS1 = [],
    alteleLamaSec32FS1,
    alteleFrotiu32FS1,
    celuleCervSec32FS1,
    descriereAlteNeoplazii,
    tipLeziuneSec44FS1,
  } = inputs;

  const handleCheck = () => {};

  const setTab = e => { changeTab(e.target.id); };

  return (
    <Card>
      <CardHeader>
        <h3 className="mb-0">
          <div className="d-flex">
            <div>
              <span className="mr-3">3B. Testare Babeş-Papanicolau</span>
            </div>
            <div className="ml-auto">
              <Button id="-" size="sm" color="secondary" className="ml-auto btn-pill" onClick={setTab}>{'<'}</Button>
              <Button id="+" size="sm" color="primary" className="ml-1 btn-pill" onClick={setTab}>{'>'}</Button>
            </div>
          </div>
        </h3>
      </CardHeader>
      <CardBody className="pb-5">
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>Laborator BPN</Label>
              <Input
                required
                type="text"
                name="laboratorSec3BFS1"
                disabled
                value={laboratorSec3BFS1?.value || ''}
              />
            </FormGroup>
          </Col>
        </Row>
        <Col
          className="underline-hover form-group dont-display ng-untouched ng-pristine"
          style={{ backgroundColor: 'rgba(233, 236, 239, 0.3)', }}
        >
          <Label>
            Personalul medical care efectuează citirea
          </Label>
        </Col>

        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>Medicul de specialitate anatomie-patologică</Label>
              <Input
                required
                type="text"
                name="medicAnatoPatoSec3BFS1"
                disabled
                value={medicAnatoPatoSec3BFS1?.value || ''}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label>Medicul de specialitate medicina de laborator</Label>

              <Input
                type="text"
                name="medicMedicinaLab3BFS1"
                disabled
                value={medicMedicinaLab3BFS1?.value || ''}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>Medicul de specialitate/Biolog</Label>

              <Input
                type="text"
                name="medicBiolog3BFS1"
                disabled
                value={medicBiolog3BFS1?.value || ''}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label>Personal de specialitate care certifică rezultatele *</Label>
              <Input
                required
                type="text"
                name="medicAnatoPatoCertificareSec3BFS1"
                disabled
                value={medicAnatoPatoCertificareSec3BFS1?.value || ''}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>Număr flacon/lamă </Label>
              <Input
                type="text"
                name="flacon"
                disabled
                value={flacon || ''}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label>Număr flacon/lamă intern</Label>
              <Input
                required
                type="text"
                name="nrFlaconSec3BFS1"
                disabled
                value={nrFlaconSec3BFS1?.value || ''}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>Data primirii flaconului/lamei </Label>

              <Input
                type="text"
                name="dataPrimireFlaconSec3BFS1"
                disabled
                value={dataPrimireFlaconSec3BFS1?.value ? moment(dataPrimireFlaconSec3BFS1.value).format('DD-MM-YYYY') : ''}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label>Data interpretării</Label>

              <Input
                required
                type="text"
                name="dataInterSec3BFS1"
                disabled
                value={dataInterSec3BFS1?.value ? moment(dataInterSec3BFS1.value).format('DD-MM-YYYY') : ''}
              />
            </FormGroup>
          </Col>
        </Row>

        <Col
          className="underline-hover form-group dont-display ng-untouched ng-pristine"
          style={{ backgroundColor: 'rgba(233, 236, 239, 0.3)', }}
        >
          <Label>
            Datele specifice calității frotiului
          </Label>
        </Col>

        <Row>
          <Col>
            <FormGroup>
              <Label>Calitate frotiu pentru evaluarea celulelor endocervicale/metaplaziate </Label>

              <Input
                required
                type="text"
                name="calitateFrotiuSec32FS1"
                disabled
                value={calitateFrotiuSec32FS1?.value || ''}
              />
            </FormGroup>
          </Col>
        </Row>

        { calitateFrotiuSec32FS1?.value === 'Satisfăcător' && (
          <Row>
            <Col>
              <FormGroup>
                <Label>Celule endocervicale</Label>
                <Input
                  required
                  type="text"
                  name="celuleCervSec32FS1"
                  disabled
                  value={celuleCervSec32FS1?.value || ''}
                />
              </FormGroup>
            </Col>
          </Row>
        )}

        { calitateFrotiuSec32FS1?.value === 'Nesatisfăcător' && (
          <Row>
            <Col sm={12}>
              <FormGroup>
                <Label>Lamă</Label>
                <Input
                  required
                  type="text"
                  name="lamaSec32FS1"
                  disabled
                  value={lamaSec32FS1?.value ? lamaSec32FS1.value.map(a => a.value).join(', ') : []}
                />
              </FormGroup>
            </Col>
            <Col sm={12}>
              <FormGroup>
                <Label>Altele</Label>
                <Input
                  required
                  type="textarea"
                  rows={3}
                  name="obs2Sec34FS1"
                  disabled
                  value={alteleLamaSec32FS1?.value || ''}
                />
              </FormGroup>
            </Col>
            <Col sm={12}>
              <FormGroup>
                <Label>Calitate frotiu pentru evaluarea celulelor endocervicale/metaplaziate </Label>
                <Input
                  required
                  type="text"
                  name="frotiuSec32FS1"
                  disabled
                  value={frotiuSec32FS1?.value ? frotiuSec32FS1.value.map(a => a.value).join(', ') : []}
                />
              </FormGroup>
            </Col>
            <Col sm={12}>
              <FormGroup>
                <Label>Altele</Label>
                <Input
                  required
                  type="textarea"
                  rows={3}
                  name="alteleFrotiu32FS1"
                  disabled
                  value={alteleFrotiu32FS1?.value || ''}
                />
              </FormGroup>

            </Col>
          </Row>
        )}

        <Col
          className="underline-hover form-group dont-display ng-untouched ng-pristine"
          style={{ backgroundColor: 'rgba(233, 236, 239, 0.3)', }}
        >
          <Label>
            Descrierea frotiului
          </Label>
        </Col>

        <Row>
          <Col md={12}>
            <FormGroup>
              <Label> Infecții </Label>
              <div>
                <ButtonGroup size="">
                  <Button name="DA" color="primary" outline={microorganismeSec33FS1?.value !== 'DA'}>DA</Button>
                  <Button name="NU" color="primary" outline={microorganismeSec33FS1?.value !== 'NU'}>NU</Button>
                </ButtonGroup>
                {
                  microorganismeSec33FS1?.value === 'DA' && (
                    <InfectiiCheckboxes
                      readOnly={readOnly}
                      setCheckboxValue={handleCheck}
                      data={inputs}
                      editable={false}
                    />
                  )
                }
              </div>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <FormGroup>
              <Label> Modificări celulare non-neoplazice </Label>
              <div>
                <ButtonGroup size="">
                  <Button name="DA" color="primary" outline={modificariCelSec33FS1?.value !== 'DA'}>DA</Button>
                  <Button name="NU" color="primary" outline={modificariCelSec33FS1?.value !== 'NU'}>NU</Button>
                </ButtonGroup>
                {
                  modificariCelSec33FS1?.value === 'DA' && (
                    <ModifNonNeoplaziceCheckboxes
                      readOnly={readOnly}
                      setCheckboxValue={handleCheck}
                      data={inputs}
                      editable={false}
                    />
                  )
                }
              </div>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <FormGroup>
              <Label> Anomalii ale celulelor epiteliale scuamoase</Label>
              <div>
                <ButtonGroup size="">
                  <Button name="DA" color="primary" outline={anomaliiScSec33FS1?.value !== 'DA'}>DA</Button>
                  <Button name="NU" color="primary" outline={anomaliiScSec33FS1?.value !== 'NU'}>NU</Button>
                </ButtonGroup>
                {
                  anomaliiScSec33FS1?.value === 'DA' && (
                    <AnomaliiCelScuamoaseCheckboxes
                      setCheckboxValue={handleCheck}
                      data={inputs}
                      editable={false}
                    />
                  )
                }
              </div>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label> Anomalii ale celulelor epiteliale glandulare</Label>
              <div>
                <ButtonGroup size="">
                  <Button name="DA" color="primary" outline={anomaliiCelGlandSec33FS1?.value !== 'DA'}>DA</Button>
                  <Button name="NU" color="primary" outline={anomaliiCelGlandSec33FS1?.value !== 'NU'}>NU</Button>
                </ButtonGroup>
              </div>
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col>
            <FormGroup check>
              <Input
                type="checkbox"
                value={alteNeoplaziiSec33FS1?.value || false}
                checked={alteNeoplaziiSec33FS1?.value === 'true'}
                name="alteNeoplaziiSec33FS1"
                onChange={() => {}}
              />
              <Label>Alte neoplazii </Label>
            </FormGroup>
          </Col>
        </Row>

        {
          alteNeoplaziiSec33FS1?.value === 'true' && (
            <Row>
              <Col>
                <FormGroup>
                  <Label>Descriere</Label>
                  <Input
                    type="textarea"
                    rows={3}
                    name="descriereAlteNeoplazii"
                    disabled
                    value={descriereAlteNeoplazii?.value || ''}
                  />
                </FormGroup>
              </Col>
            </Row>
          )
        }

        <Col
          className="underline-hover form-group dont-display ng-untouched ng-pristine"
          style={{ backgroundColor: 'rgba(233, 236, 239, 0.3)', }}
        >
          <Label>
            Date specifice rezultatului final
          </Label>
        </Col>

        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>Rezultat final *</Label>
              <Input
                required
                type="text"
                name="rezFinalSec44FS1"
                disabled
                value={rezFinalSec44FS1?.value || ''}
              />
            </FormGroup>
          </Col>
          { rezFinalSec44FS1?.value === 'Pozitiv' && (
            <Col>
              <FormGroup>
                <Label>Tipul leziunii</Label>
                <Input
                  type="textarea"
                  rows={3}
                  name="tipLeziuneSec44FS1"
                  disabled
                  value={tipLeziuneSec44FS1?.value || ''}
                />
              </FormGroup>
            </Col>
          )}
        </Row>

        <Row>
          <Col>
            <FormGroup check>
              <Input
                type="checkbox"
                value={repetareSec34FS1?.value || false}
                checked={repetareSec34FS1?.value === 'true'}
                name="repetareSec34FS1"
                onChange={() => {}}
              />
              <Label>Repetare </Label>
            </FormGroup>
          </Col>
        </Row>
        <Row className="mt-2 mb-2">
          <Col>
            <FormGroup check>
              <Input
                type="checkbox"
                value={biopsieSec34FS1?.value || false}
                checked={biopsieSec34FS1?.value === 'true'}
                name="biopsieSec34FS1"
                onChange={() => {}}
              />
              <Label>Biopsie </Label>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup check>
              <Input
                type="checkbox"
                value={colposcopieSec34FS1?.value || false}
                checked={colposcopieSec34FS1?.value === 'true'}
                name="colposcopieSec34FS1"
                onChange={() => {}}
              />
              <Label>Colposcopie </Label>
            </FormGroup>
          </Col>
        </Row>
        <Row className="mt-2 mb-2">
          <Col>
            <FormGroup check>
              <Input
                type="checkbox"
                value={chiuretajCervSec34FS1?.value || false}
                checked={chiuretajCervSec34FS1?.value === 'true'}
                name="chiuretajCervSec34FS1"
                onChange={() => {}}
              />
              <Label>Chiuretaj endocervical  </Label>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup check>
              <Input
                type="checkbox"
                value={chiuretajMetrSec34FS1?.value || false}
                checked={chiuretajMetrSec34FS1?.value === 'true'}
                name="chiuretajMetrSec34FS1"
                onChange={() => {}}
              />
              <Label>Chiuretaj endometrial  </Label>
            </FormGroup>
          </Col>
        </Row>

        <Row className="mt-2 mb-2">
          <Col>
            <FormGroup check>
              <Input
                type="checkbox"
                value={testHpvSec34FS1?.value || false}
                checked={testHpvSec34FS1?.value === 'true'}
                name="testHpvSec34FS1"
                onChange={() => {}}
              />
              <Label>Testare HPV</Label>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup check>
              <Input
                type="checkbox"
                value={repRutinaNegSec34FS1?.value || false}
                checked={repRutinaNegSec34FS1?.value === 'true'}
                name="repRutinaNegSec34FS1"
                onChange={() => {}}
              />
              <Label>Repetare de rutină dacă leziunea este negativă  </Label>
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>Observații</Label>
              <Input
                type="textarea"
                rows={3}
                name="obs1Sec34FS1"
                disabled
                value={obs1Sec34FS1?.value || ''}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label>Data eliberării rezultatului</Label>
              <Input
                required
                type="text"
                name="dataAnamnezeiSec1FS1"
                disabled
                value={dataElibRezSec34FS1?.value ? moment(dataElibRezSec34FS1.value).format('DD-MM-YYYY') : ''}
              />
            </FormGroup>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}

export default TestareBabesView;
