import React, { Component } from 'react';
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  Col,
  FormGroup, Label, Input, Row
} from 'reactstrap';
import Select from 'react-select';
import * as yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faSave } from '@fortawesome/free-solid-svg-icons';

const phoneRegExp = /^\d{10}$/;

const validations = {
  email: yup.object().shape({ email: yup.string().email().required(), }),
  username: yup.object().shape({ username: yup.string().required(), }),
  phone: yup.object().shape({ phone: yup.string().matches(phoneRegExp, ''), }),
};

class DoctorContactsModal extends Component {
  constructor() {
    super();
    this.state = {
      id: null,
      selectedType: null,
      description: '',
      active: true,
      email: '',
      emailError: '',
      phone: '',
      phoneError: '',
    };

    this.saveContact = this.saveContact.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleTypeSelect = this.handleTypeSelect.bind(this);
    this.prefillFileds = this.prefillFileds.bind(this);
  }

  componentDidMount() {
    const { data, } = this.props;
    if (data) {
      this.prefillFileds(data);
    }
  }

  prefillFileds(data) {
    const { id, label, description, active, contactType: { value, }, } = data;
    const fieldName = value === 'EMAIL' ? 'email' : 'phone';
    const newState = {
      ...this.state,
      [fieldName]: label,
      description,
      active,
      selectedType: { value, label: value, },
      id,
    };
    this.setState({ ...newState, });
  }

  handleInputChange(e) {
    const { name, value, required, } = e.target;
    this.setState({ [name]: value, }, () => {
      if (required) {
        validations[name].validate({ [name]: value, }).then(() => {
          this.setState({ [`${name}Error`]: false, });
        }).catch(() => {
          this.setState({ [`${name}Error`]: true, });
        });
      }
    });
  }

  handleTypeSelect(val) {
    this.setState({ selectedType: val, });
  }

  calcDisabled() {
    const {
      selectedType,
      email, emailError,
      phone, phoneError,
    } = this.state;
    let disabled = false;
    if (selectedType === null || selectedType === '') disabled = true;
    if ((selectedType?.label === 'EMAIL') && (email === '' || emailError === true)) disabled = true;
    if ((selectedType?.label === 'TELEFON') && (phone === '' || phoneError === true)) disabled = true;

    return disabled;
  }

  saveContact() {
    const { setContact, } = this.props;
    // const { id, label, description, contactType: { label: contactLabel, }, } = contact;
    let newContact;
    const {
      selectedType: { value, },
      description,
      email,
      phone,
      id,
    } = this.state;
    if (value === 'EMAIL') {
      newContact = {
        id,
        contactType: { label: 'EMAIL', value: 'EMAIL', description, },
        description,
        label: email,
      };
    }
    if (value === 'TELEFON') {
      newContact = {
        id,
        contactType: { label: 'TELEFON', value: 'TELEFON', description, },
        description,
        label: phone,
      };
    }
    setContact(newContact);
  }

  render() {
    const { close, } = this.props;
    const {
      selectedType, description,
      email, emailError,
      phone, phoneError,
    } = this.state;
    const saveDisabled = this.calcDisabled();
    return (
      <Modal size="xl" isOpen>
        <ModalHeader className="" toggle={close}>Creare sau editare Contact</ModalHeader>
        <ModalBody className="">
          <Row>
            <Col md={12}>
              <FormGroup>
                <Label>Tip Contact</Label>
                <Select
                  className="select-invalid"
                  classNamePrefix="servicesSelect"
                  placeholder=""
                  isClearable
                  name="county"
                  options={['TELEFON', 'EMAIL'].map(a => ({ value: a, label: a, }))}
                  onChange={this.handleTypeSelect}
                  value={selectedType}
                />
              </FormGroup>
            </Col>
            {
              selectedType?.value === 'EMAIL' && (
                <Col md={12}>
                  <FormGroup>
                    <Label>Email</Label>
                    <Input
                      required
                      type="text"
                      name="email"
                      className={`${(email === '' || emailError) && 'invalid'} `}
                      onChange={this.handleInputChange}
                      onBlur={this.handleInputChange}
                      value={email}
                    />
                    { email === '' && emailError && <p className="error text-danger mt-1 mb-1">Acest câmp este necesar.</p>}
                  </FormGroup>
                </Col>
              )
            }
            {
              selectedType?.value === 'TELEFON' && (
                <Col md={12}>
                  <FormGroup>
                    <Label>Telefon</Label>
                    <Input
                      required
                      type="text"
                      name="phone"
                      className={`${(phone === '' || phoneError) && 'invalid'} `}
                      onChange={this.handleInputChange}
                      onBlur={this.handleInputChange}
                      value={phone}
                    />
                    { phone === '' && phoneError && <p className="error text-danger mt-1 mb-1">Acest câmp este necesar.</p>}
                  </FormGroup>
                </Col>
              )
            }
            <Col md={12}>
              <FormGroup>
                <Label>Descriere</Label>
                <Input
                  type="text"
                  name="description"
                  onChange={this.handleInputChange}
                  onBlur={this.handleInputChange}
                  value={description}
                />
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={close}>
            <FontAwesomeIcon icon={faBan} />
            <span className="ml-1">Anulare</span>
          </Button>
          {' '}
          <Button
            disabled={saveDisabled}
            onClick={saveDisabled ? () => {} : this.saveContact}
            color="primary"
          >
            <FontAwesomeIcon icon={faSave} />
            <span className="ml-1">Salvare</span>
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default DoctorContactsModal;
