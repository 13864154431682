import React, { useEffect, useState } from 'react';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';

const checkboxInputs = [
  'ascUsSec33FS1', // ASC - US
  'ascHSec33FS1', // ASC - H
  'lsilSec33FS1', // LSIL
  'lsilHpvSec33FS1', // LSIL cu atipii HPV
  'hsilSec33FS1', // HSIL
  'hsilIInvazieSec33FS1', // HSIL cu suspiciune de invazie
  'carcinomScSec33FS1' // carcinom scuamos
];

function AnomaliiCelScuamoaseCheckboxes({ data, editable, setCheckboxValue, }) {
  const [inputs, setInputs] = useState({});

  useEffect(() => {
    const dataProps = {
      ascUsSec33FS1: { value: data?.ascUsSec33FS1?.value.toString() || 'false', },
      ascHSec33FS1: { value: data?.ascHSec33FS1?.value.toString() || 'false', },
      lsilSec33FS1: { value: data?.lsilSec33FS1?.value.toString() || 'false', },
      lsilHpvSec33FS1: { value: data?.lsilHpvSec33FS1?.value.toString() || 'false', },
      hsilSec33FS1: { value: data?.hsilSec33FS1?.value.toString() || 'false', },
      hsilIInvazieSec33FS1: { value: data?.hsilIInvazieSec33FS1?.value.toString() || 'false', },
      carcinomScSec33FS1: { value: data?.carcinomScSec33FS1?.value.toString() || 'false', },
    };
    setInputs(dataProps);
    if (Object.keys(inputs).length === 0) return;
    checkboxInputs.forEach(elem => {
      inputs[elem].value = inputs[elem].value.toString();
    });
  }, [data]);

  const {
    ascUsSec33FS1,
    ascHSec33FS1,
    lsilSec33FS1,
    lsilHpvSec33FS1,
    hsilSec33FS1,
    hsilIInvazieSec33FS1,
    carcinomScSec33FS1,
  } = inputs;

  const handleCheck = e => {
    // const newVal = e.target.value === 'false' ? 'true' : 'false';
    // const curentVal = { ...inputs[e.target.name], value: newVal, };
    // setInputs(prevState => ({ ...prevState, [e.target.name]: curentVal, }));
    setCheckboxValue(e);
    // pass to parent
  };

  //   'hsilIInvazieSec33FS1', // HSIL cu suspiciune de invazie
  //   'carcinomScSec33FS1' // carcinom scuamos

  return (
    <Row className="mt-3">
      <Col md={6}>
        <FormGroup check>
          {
            editable ? (
              <Input
                type="checkbox"
                checked={ascUsSec33FS1?.value === 'true'}
                value={ascUsSec33FS1?.value === 'true'}
                name="ascUsSec33FS1"
                onChange={handleCheck}
              />
            ) : (
              <Input
                type="checkbox"
                value={ascUsSec33FS1?.value || false}
                checked={ascUsSec33FS1?.value === 'true'}
                name="ascUsSec33FS1"
                onChange={() => {}}
              />
            )
          }
          <Label>ASC - US</Label>
        </FormGroup>
      </Col>
      <Col md={6}>
        <FormGroup check>
          {
            editable ? (
              <Input
                type="checkbox"
                checked={ascHSec33FS1?.value === 'true'}
                value={ascHSec33FS1?.value === 'true'}
                name="ascHSec33FS1"
                onChange={handleCheck}
              />
            ) : (
              <Input
                type="checkbox"
                value={ascHSec33FS1?.value || false}
                checked={ascHSec33FS1?.value === 'true'}
                name="ascHSec33FS1"
                onChange={() => {}}
              />
            )
          }
          <Label>ASC - H</Label>
        </FormGroup>
      </Col>
      <Col md={6}>
        <FormGroup check>
          {
            editable ? (
              <Input
                type="checkbox"
                checked={lsilSec33FS1?.value === 'true'}
                value={lsilSec33FS1?.value === 'true'}
                name="lsilSec33FS1"
                onChange={handleCheck}
              />
            ) : (
              <Input
                type="checkbox"
                value={lsilSec33FS1?.value || false}
                checked={lsilSec33FS1?.value === 'true'}
                name="lsilSec33FS1"
                onChange={() => {}}
              />
            )
          }
          <Label>LSIL</Label>
        </FormGroup>
      </Col>
      <Col md={6}>
        <FormGroup check>
          {
            editable ? (
              <Input
                type="checkbox"
                checked={lsilHpvSec33FS1?.value === 'true'}
                value={lsilHpvSec33FS1?.value === 'true'}
                name="lsilHpvSec33FS1"
                onChange={handleCheck}
              />
            ) : (
              <Input
                type="checkbox"
                value={lsilHpvSec33FS1?.value || false}
                checked={lsilHpvSec33FS1?.value === 'true'}
                name="lsilHpvSec33FS1"
                onChange={() => {}}
              />
            )
          }
          <Label>LSIL cu atipii HPV</Label>
        </FormGroup>
      </Col>
      <Col md={6}>
        <FormGroup check>
          {
            editable ? (
              <Input
                type="checkbox"
                checked={hsilSec33FS1?.value === 'true'}
                value={hsilSec33FS1?.value === 'true'}
                name="hsilSec33FS1"
                onChange={handleCheck}
              />
            ) : (
              <Input
                type="checkbox"
                value={hsilSec33FS1?.value || false}
                checked={hsilSec33FS1?.value === 'true'}
                name="hsilSec33FS1"
                onChange={() => {}}
              />
            )
          }
          <Label>HSIL</Label>
        </FormGroup>
      </Col>
      <Col md={6}>
        <FormGroup check>
          {
            editable ? (
              <Input
                type="checkbox"
                checked={hsilIInvazieSec33FS1?.value === 'true'}
                value={hsilIInvazieSec33FS1?.value === 'true'}
                name="hsilIInvazieSec33FS1"
                onChange={handleCheck}
              />
            ) : (
              <Input
                type="checkbox"
                value={hsilIInvazieSec33FS1?.value || false}
                checked={hsilIInvazieSec33FS1?.value === 'true'}
                name="hsilIInvazieSec33FS1"
                onChange={() => {}}
              />
            )
          }
          <Label>HSIL cu suspiciune de invazie</Label>
        </FormGroup>
      </Col>
      <Col md={6}>
        <FormGroup check>
          {
            editable ? (
              <Input
                type="checkbox"
                checked={carcinomScSec33FS1?.value === 'true'}
                value={carcinomScSec33FS1?.value === 'true'}
                name="carcinomScSec33FS1"
                onChange={handleCheck}
              />
            ) : (
              <Input
                type="checkbox"
                value={carcinomScSec33FS1?.value || false}
                checked={carcinomScSec33FS1?.value === 'true'}
                name="carcinomScSec33FS1"
                onChange={() => {}}
              />
            )
          }
          <Label>Carcinom scuamos</Label>
        </FormGroup>
      </Col>
    </Row>
  );
}

export default AnomaliiCelScuamoaseCheckboxes;
