export const rolesOptionsExtended = [
  {
    id: 'DB0B443E-F36B-1410-8D2C-00DFB91F5CDC',
    code: 'ROLE_SUPER_ADMIN',
    label: 'Rol super administrator',
    description: 'Rol super administrator',
    permissions: [
      {
        id: '4409443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'ADM_FORMS',
        label: 'Administrare formulare',
        description: 'Administrare formulare',
        active: true,
      }, {
        id: '4B09443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'ADM_NOMEN',
        label: 'Administrare Nomenclatoare',
        description: 'Administrare Nomenclatoare',
        active: true,
      }, {
        id: '5209443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'ADM_OFFICE',
        label: 'Administrare birouri/cladiri',
        description: 'Administrare birouri/cladiri',
        active: true,
      }, {
        id: '5909443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'ADM_ORGANIZATION',
        label: 'Administrare organizatii',
        description: 'Administrare organizatii',
        active: true,
      }, {
        id: '6009443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'ADM_PERMISSION',
        label: 'Administrare Permisiuni',
        description: 'Administrare Permisiuni',
        active: true,
      }, {
        id: '6709443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'ADM_ROLE',
        label: 'Administrare Roluri',
        description: 'Administrare Roluri',
        active: true,
      }, {
        id: '6E09443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'ADM_TEMPLATE',
        label: 'Administrare Sabloane',
        description: 'Administrare Sabloane',
        active: true,
      }, {
        id: '7509443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'ADM_USERS',
        label: 'Administrare Utilizatori',
        description: 'Administrare Utilizatori',
        active: true,
      }, {
        id: '8309443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'CASEFILE_DELETE',
        label: 'Sterge dosar',
        description: 'Sterge dosar',
        active: true,
      }, {
        id: '8A09443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'CASEFILE_EDIT',
        label: 'Editeaza dosar',
        description: 'Editeaza dosar',
        active: true,
      }, {
        id: '9109443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'CASEFILE_LIST',
        label: 'Lista dosare',
        description: 'Lista dosare',
        active: true,
      }, {
        id: '9809443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'CASEFILE_SEARCH',
        label: 'Cauta dosar',
        description: 'Cauta dosar',
        active: true,
      }, {
        id: '9F09443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'CASEFILE_VIEW',
        label: 'Vizualizeaza dosar',
        description: 'Vizualizeaza dosar',
        active: true,
      }, {
        id: 'AD09443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'CASEFILE_VIEW_FILE_EDIT',
        label: 'Editeaza fisa in dosar',
        description: 'Editeaza fisa in dosar',
        active: true,
      }, {
        id: 'B409443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'CASEFILE_VIEW_FILE_LIST',
        label: 'Lista fise in dosar',
        description: 'Lista fise in dosar',
        active: true,
      }, {
        id: 'BB09443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'CASEFILE_VIEW_FILE_VIEW',
        label: 'Vizualizeaza fisa in dosar',
        description: 'Vizualizeaza fisa in dosar',
        active: true,
      }, {
        id: 'C209443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'DOCTOR_ADD',
        label: 'Adauga medic',
        description: 'Adauga medic',
        active: true,
      }, {
        id: 'C909443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'DOCTOR_DELETE',
        label: 'Sterge medic',
        description: 'Sterge medic',
        active: true,
      }, {
        id: 'D009443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'DOCTOR_EDIT',
        label: 'Editeaza medic',
        description: 'Editeaza medic',
        active: true,
      }, {
        id: 'D709443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'DOCTOR_LIST',
        label: 'Lista medici',
        description: 'Lista medici',
        active: true,
      }, {
        id: 'DE09443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'DOCTOR_SEARCH',
        label: 'Cauta medic',
        description: 'Cauta medic',
        active: true,
      }, {
        id: 'E509443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'DOCTOR_VIEW',
        label: 'Vizualizeaza medic',
        description: 'Vizualizeaza medic',
        active: true,
      }, {
        id: 'EC09443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'FORM_ADD',
        label: 'Adauga formular',
        description: 'Adauga formular',
        active: true,
      }, {
        id: 'F309443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'FORM_DELETE',
        label: 'Sterge formular',
        description: 'Sterge formular',
        active: true,
      }, {
        id: 'FA09443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'FORM_EDIT',
        label: 'Editeaza formular',
        description: 'Editeaza formular',
        active: true,
      }, {
        id: '010A443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'FORM_LIST',
        label: 'Lista formulare',
        description: 'Lista formulare',
        active: true,
      }, {
        id: '080A443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'FORM_SEARCH',
        label: 'Cauta formular',
        description: 'Cauta formular',
        active: true,
      }, {
        id: '0F0A443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'FORM_VIEW',
        label: 'Vizualizeaza formular',
        description: 'Vizualizeaza formular',
        active: true,
      }, {
        id: '160A443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'NAVBAR_ADM',
        label: 'Sectiune Administrare',
        description: 'Sectiune Administrare',
        active: true,
      }, {
        id: '1D0A443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'NAVBAR_CASEFILE',
        label: 'Sectiune Dosar',
        description: 'Sectiune Dosar',
        active: true,
      }, {
        id: '240A443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'NAVBAR_DOCTOR',
        label: 'Sectiune Doctor',
        description: 'Sectiune Doctor',
        active: true,
      }, {
        id: '2B0A443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'NAVBAR_NOTIFICATIONS',
        label: 'Sectiune Mesaje',
        description: 'Sectiune Mesaje',
        active: true,
      }, {
        id: '320A443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'NAVBAR_PATIENT',
        label: 'Sectiune Pacient',
        description: 'Sectiune Pacient',
        active: true,
      }, {
        id: '400A443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'NOMEN_ADD',
        label: 'Adauga nomeclator',
        description: 'Adauga nomeclator',
        active: true,
      }, {
        id: '470A443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'NOMEN_DELETE',
        label: 'Sterge nomenclator',
        description: 'Sterge nomenclator',
        active: true,
      }, {
        id: '4E0A443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'NOMEN_EDIT',
        label: 'Editare nomenclator',
        description: 'Editare nomenclator',
        active: true,
      }, {
        id: '550A443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'NOMEN_LIST',
        label: 'Lista nomenclator',
        description: 'Lista nomenclator',
        active: true,
      }, {
        id: '5C0A443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'NOMEN_SEARCH',
        label: 'Cauta nomenclator',
        description: 'Cauta nomenclator',
        active: true,
      }, {
        id: '630A443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'NOMEN_VIEW',
        label: 'Vizualizare nomenclator',
        description: 'Vizualizare nomenclator',
        active: true,
      }, {
        id: '6A0A443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'NOTIFICATION_ADD',
        label: 'Adauga mesaj',
        description: 'Adauga mesaj',
        active: true,
      }, {
        id: '710A443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'NOTIFICATION_RECEIVED_LIST',
        label: 'Lista mesaje primite',
        description: 'Lista mesaje primite',
        active: true,
      }, {
        id: '780A443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'NOTIFICATION_RECEIVED_SEARCH',
        label: 'Cauta mesaj primit',
        description: 'Cauta mesaj primit',
        active: true,
      }, {
        id: '7F0A443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'NOTIFICATION_SENT_LIST',
        label: 'Lista mesaje trimise',
        description: 'Lista mesaje trimise',
        active: true,
      }, {
        id: '860A443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'NOTIFICATION_VIEW',
        label: 'Vizualizeaza mesaj',
        description: 'Vizualizeaza mesaj',
        active: true,
      }, {
        id: '8D0A443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'NOTIFICATION_SENT_SEARCH',
        label: 'Cauta mesaj trimis',
        description: 'Cauta mesaj trimis',
        active: true,
      }, {
        id: '940A443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'OFFICE_ADD',
        label: 'Adauga birou/cladire',
        description: 'Adauga birou/cladire',
        active: true,
      }, {
        id: '9B0A443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'OFFICE_DELETE',
        label: 'Sterge birou/cladire',
        description: 'Sterge birou/cladire',
        active: true,
      }, {
        id: 'A20A443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'OFFICE_EDIT',
        label: 'Editeaza birou/cladire',
        description: 'Editeaza birou/cladire',
        active: true,
      }, {
        id: 'A90A443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'OFFICE_LIST',
        label: 'Lista birouri/cladiri',
        description: 'Lista birouri/cladiri',
        active: true,
      }, {
        id: 'B00A443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'OFFICE_SEARCH',
        label: 'Cauta birou/cladire',
        description: 'Cauta birou/cladire',
        active: true,
      }, {
        id: 'B70A443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'OFFICE_VIEW',
        label: 'Vizualizeza birou/cladire',
        description: 'Vizualizeza birou/cladire',
        active: true,
      }, {
        id: 'BE0A443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'ORGANIZATION_ADD',
        label: 'Adauga organizatie',
        description: 'Adauga organizatie',
        active: true,
      }, {
        id: 'C50A443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'ORGANIZATION_DELETE',
        label: 'Sterge organizatie',
        description: 'Sterge organizatie',
        active: true,
      }, {
        id: 'CC0A443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'ORGANIZATION_EDIT',
        label: 'Editeaza organizatie',
        description: 'Editeaza organizatie',
        active: true,
      }, {
        id: 'D30A443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'ORGANIZATION_LIST',
        label: 'Lista organizatii',
        description: 'Lista organizatii',
        active: true,
      }, {
        id: 'DA0A443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'ORGANIZATION_SEARCH',
        label: 'Cauta organizatie',
        description: 'Cauta organizatie',
        active: true,
      }, {
        id: 'E10A443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'ORGANIZATION_VIEW',
        label: 'Vizualizeaza organizatie',
        description: 'Vizualizeaza organizatie',
        active: true,
      }, {
        id: 'EF0A443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'PATIENT_DELETE',
        label: 'Sterge pacient',
        description: 'Sterge pacient',
        active: true,
      }, {
        id: 'F60A443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'PATIENT_EDIT',
        label: 'Editeaza pacient',
        description: 'Editeaza pacient',
        active: true,
      }, {
        id: 'FD0A443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'PATIENT_LIST',
        label: 'Lista pacienti',
        description: 'Lista pacienti',
        active: true,
      }, {
        id: '040B443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'PATIENT_SEARCH',
        label: 'Cauta pacient',
        description: 'Cauta pacient',
        active: true,
      }, {
        id: '0B0B443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'PATIENT_VIEW',
        label: 'Vizualizeaza pacient',
        description: 'Vizualizeaza pacient',
        active: true,
      }, {
        id: '120B443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'PERMISSION_ADD',
        label: 'Adauga permisiune',
        description: 'Adauga permisiune',
        active: true,
      }, {
        id: '190B443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'PERMISSION_DELETE',
        label: 'Sterge permisiune',
        description: 'Sterge permisiune',
        active: true,
      }, {
        id: '200B443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'PERMISSION_EDIT',
        label: 'Editeaza permisiune',
        description: 'Editeaza permisiune',
        active: true,
      }, {
        id: '270B443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'PERMISSION_LIST',
        label: 'Lista permisiuni',
        description: 'Lista permisiuni',
        active: true,
      }, {
        id: '2E0B443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'PERMISSION_SEARCH',
        label: 'Cauta permisiune',
        description: 'Cauta permisiune',
        active: true,
      }, {
        id: '350B443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'PERMISSION_VIEW',
        label: 'Vizualizeaza permisiune',
        description: 'Vizualizeaza permisiune',
        active: true,
      }, {
        id: '3C0B443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'REPORT_ADD',
        label: 'Adauga raport',
        description: 'Adauga raport',
        active: true,
      }, {
        id: '430B443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'REPORT_EDIT',
        label: 'Editeaza raport',
        description: 'Editeaza raport',
        active: true,
      }, {
        id: '4A0B443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'REPORT_LIST',
        label: 'Lista rapoarte',
        description: 'Lista rapoarte',
        active: true,
      }, {
        id: '510B443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'REPORT_VIEW',
        label: 'Vizualizeaza raport',
        description: 'Vizualizeaza raport',
        active: true,
      }, {
        id: '580B443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'ROLE_ADD',
        label: 'Adauga rol',
        description: 'Adauga rol',
        active: true,
      }, {
        id: '5F0B443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'ROLE_ADMIN',
        label: 'Permisiune Administrator',
        description: 'Permisiune Administrator',
        active: true,
      }, {
        id: '660B443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'ROLE_DELETE',
        label: 'Sterge rol',
        description: 'Sterge rol',
        active: true,
      }, {
        id: '6D0B443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'ROLE_EDIT',
        label: 'Editeaza rol',
        description: 'Editeaza rol',
        active: true,
      }, {
        id: '740B443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'ROLE_LIST',
        label: 'Lista roluri',
        description: 'Lista roluri',
        active: true,
      }, {
        id: '7B0B443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'ROLE_SEARCH',
        label: 'Cauta rol',
        description: 'Cauta rol',
        active: true,
      }, {
        id: '820B443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'ROLE_USER',
        label: 'Permisiune Utilizator',
        description: 'Permisiune Utilizator',
        active: true,
      }, {
        id: '890B443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'ROLE_VIEW',
        label: 'Vizualizeaza rol',
        description: 'Vizualizeaza rol',
        active: true,
      }, {
        id: '900B443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'TEMPLATE_ADD',
        label: 'Adauga sablon',
        description: 'Adauga sablon',
        active: true,
      }, {
        id: '970B443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'TEMPLATE_DOWNLOAD',
        label: 'Descarca sablon',
        description: 'Descarca sablon',
        active: true,
      }, {
        id: '9E0B443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'TEMPLATE_EDIT',
        label: 'Editeaza sablon',
        description: 'Editeaza sablon',
        active: true,
      }, {
        id: 'A50B443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'TEMPLATE_LIST',
        label: 'Lista sabloane',
        description: 'Lista sabloane',
        active: true,
      }, {
        id: 'AC0B443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'TEMPLATE_VIEW',
        label: 'Vizualizare sablon',
        description: 'Vizualizare sablon',
        active: true,
      }, {
        id: 'B30B443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'USER_ADD',
        label: 'Adauga utilizator',
        description: 'Adauga utilizator',
        active: true,
      }, {
        id: 'BA0B443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'USER_DELETE',
        label: 'Sterge utilizator',
        description: 'Sterge utilizator',
        active: true,
      }, {
        id: 'C10B443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'USER_EDIT',
        label: 'Editeaza utilizator',
        description: 'Editeaza utilizator',
        active: true,
      }, {
        id: 'C80B443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'USER_LIST',
        label: 'Lista utilizatori',
        description: 'Lista utilizatori',
        active: true,
      }, {
        id: 'CF0B443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'USER_SEARCH',
        label: 'Cauta utilizator',
        description: 'Cauta utilizator',
        active: true,
      }, {
        id: 'D60B443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'USER_VIEW',
        label: 'Vizualizare utilizator',
        description: 'Vizualizare utilizator',
        active: true,
      }, {
        id: 'E5BF443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'ALL_FORM_SECTIONS_VIEW',
        label: 'Vizualizeaza toate sectiunile formularului',
        description: 'Vizualizeaza toate sectiunile formularului',
        active: true,
      }, {
        id: 'E8BF443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'ALL_FORM_SECTIONS_EDIT',
        label: 'Editeaza toate sectiunile formularului',
        description: 'Editeaza toate sectiunile formularului',
        active: true,
      }, {
        id: '37C1443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'CASEFILE_VIEW_FILE_DELETE',
        label: 'Sterge fisa din dosar',
        description: 'Sterge fisa din dosar',
        active: true,
      }, {
        id: '39C1443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'CASEFILE_VIEW_FILE_DOWNLOAD',
        label: 'Descarca fisa',
        description: 'Descarca fisa',
        active: true,
      }, {
        id: 'B7C5443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'NAVBAR_BORDEROU_EXPORT',
        label: 'Sectiune Raportare',
        description: 'Sectiune Raportare',
        active: true,
      }, {
        id: 'BEC5443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'BORDEROU_EXPORT',
        label: 'Export borderouri',
        description: 'Export borderouri',
        active: true,
      }, {
        id: '0531433E-F36B-1410-8D3A-00DFB91F5CDC',
        code: 'NAVBAR_INDICATORS',
        label: 'Sectiune Indicatori',
        description: 'Sectiune Indicatori',
        active: true,
      }, {
        id: '0C31433E-F36B-1410-8D3A-00DFB91F5CDC',
        code: 'VIEW_INDICATORS',
        label: 'Vizualizare indicatori',
        description: 'Vizualizare indicatori',
        active: true,
      }, {
        id: '4F31433E-F36B-1410-8D3A-00DFB91F5CDC',
        code: 'PSYCHOLOGICAL_SUPPORT',
        label: 'Suport psiho-oncologic',
        description: 'Suport psiho-oncologic',
        active: true,
      }, {
        id: 'BB3A433E-F36B-1410-8D3A-00DFB91F5CDC',
        code: 'NAVBAR_USER_MANUAL',
        label: 'Sectiune Manual de utilizare',
        description: 'Sectiune Manual de utilizare',
        active: true,
      }, {
        id: '13EA433E-F36B-1410-8D3C-00DFB91F5CDC',
        code: 'NAVBAR_STATISTICS',
        label: 'Sectiune Statistici',
        description: 'Sectiune Statistici',
        active: true,
      }, {
        id: '1AEA433E-F36B-1410-8D3C-00DFB91F5CDC',
        code: 'OIR_BORDEROU_EXPORT',
        label: 'Export borderouri OIR',
        description: 'Export borderouri OIR',
        active: true,
      }, {
        id: '08F8433E-F36B-1410-8D3C-00DFB91F5CDC',
        code: 'PATIENT_SERVICES_REPORT',
        label: 'Raport servicii beneficiar',
        description: 'Raport servicii beneficiar',
        active: true,
      }],
  },
  {
    id: 'E00B443E-F36B-1410-8D2C-00DFB91F5CDC',
    code: 'ROLE_ADMIN',
    label: 'Rol administrator',
    description: 'Rol administrator',
    permissions: [
      {
        id: '5909443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'ADM_ORGANIZATION',
        label: 'Administrare organizatii',
        description: 'Administrare organizatii',
        active: true,
      },
      {
        id: '7509443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'ADM_USERS',
        label: 'Administrare Utilizatori',
        description: 'Administrare Utilizatori',
        active: true,
      }, {
        id: '8309443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'CASEFILE_DELETE',
        label: 'Sterge dosar',
        description: 'Sterge dosar',
        active: true,
      }, {
        id: '8A09443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'CASEFILE_EDIT',
        label: 'Editeaza dosar',
        description: 'Editeaza dosar',
        active: true,
      }, {
        id: '9109443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'CASEFILE_LIST',
        label: 'Lista dosare',
        description: 'Lista dosare',
        active: true,
      }, {
        id: '9809443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'CASEFILE_SEARCH',
        label: 'Cauta dosar',
        description: 'Cauta dosar',
        active: true,
      }, {
        id: '9F09443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'CASEFILE_VIEW',
        label: 'Vizualizeaza dosar',
        description: 'Vizualizeaza dosar',
        active: true,
      }, {
        id: 'B409443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'CASEFILE_VIEW_FILE_LIST',
        label: 'Lista fise in dosar',
        description: 'Lista fise in dosar',
        active: true,
      }, {
        id: 'BB09443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'CASEFILE_VIEW_FILE_VIEW',
        label: 'Vizualizeaza fisa in dosar',
        description: 'Vizualizeaza fisa in dosar',
        active: true,
      }, {
        id: 'C209443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'DOCTOR_ADD',
        label: 'Adauga medic',
        description: 'Adauga medic',
        active: true,
      }, {
        id: 'C909443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'DOCTOR_DELETE',
        label: 'Sterge medic',
        description: 'Sterge medic',
        active: true,
      }, {
        id: 'D009443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'DOCTOR_EDIT',
        label: 'Editeaza medic',
        description: 'Editeaza medic',
        active: true,
      }, {
        id: 'D709443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'DOCTOR_LIST',
        label: 'Lista medici',
        description: 'Lista medici',
        active: true,
      }, {
        id: 'DE09443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'DOCTOR_SEARCH',
        label: 'Cauta medic',
        description: 'Cauta medic',
        active: true,
      }, {
        id: 'E509443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'DOCTOR_VIEW',
        label: 'Vizualizeaza medic',
        description: 'Vizualizeaza medic',
        active: true,
      }, {
        id: '0F0A443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'FORM_VIEW',
        label: 'Vizualizeaza formular',
        description: 'Vizualizeaza formular',
        active: true,
      }, {
        id: '160A443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'NAVBAR_ADM',
        label: 'Sectiune Administrare',
        description: 'Sectiune Administrare',
        active: true,
      }, {
        id: '1D0A443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'NAVBAR_CASEFILE',
        label: 'Sectiune Dosar',
        description: 'Sectiune Dosar',
        active: true,
      }, {
        id: '240A443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'NAVBAR_DOCTOR',
        label: 'Sectiune Doctor',
        description: 'Sectiune Doctor',
        active: true,
      }, {
        id: '2B0A443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'NAVBAR_NOTIFICATIONS',
        label: 'Sectiune Mesaje',
        description: 'Sectiune Mesaje',
        active: true,
      }, {
        id: '320A443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'NAVBAR_PATIENT',
        label: 'Sectiune Pacient',
        description: 'Sectiune Pacient',
        active: true,
      }, {
        id: '6A0A443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'NOTIFICATION_ADD',
        label: 'Adauga mesaj',
        description: 'Adauga mesaj',
        active: true,
      }, {
        id: '710A443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'NOTIFICATION_RECEIVED_LIST',
        label: 'Lista mesaje primite',
        description: 'Lista mesaje primite',
        active: true,
      }, {
        id: '780A443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'NOTIFICATION_RECEIVED_SEARCH',
        label: 'Cauta mesaj primit',
        description: 'Cauta mesaj primit',
        active: true,
      }, {
        id: '7F0A443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'NOTIFICATION_SENT_LIST',
        label: 'Lista mesaje trimise',
        description: 'Lista mesaje trimise',
        active: true,
      }, {
        id: '860A443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'NOTIFICATION_VIEW',
        label: 'Vizualizeaza mesaj',
        description: 'Vizualizeaza mesaj',
        active: true,
      }, {
        id: '8D0A443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'NOTIFICATION_SENT_SEARCH',
        label: 'Cauta mesaj trimis',
        description: 'Cauta mesaj trimis',
        active: true,
      }, {
        id: 'BE0A443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'ORGANIZATION_ADD',
        label: 'Adauga organizatie',
        description: 'Adauga organizatie',
        active: true,
      }, {
        id: 'C50A443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'ORGANIZATION_DELETE',
        label: 'Sterge organizatie',
        description: 'Sterge organizatie',
        active: true,
      }, {
        id: 'CC0A443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'ORGANIZATION_EDIT',
        label: 'Editeaza organizatie',
        description: 'Editeaza organizatie',
        active: true,
      }, {
        id: 'D30A443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'ORGANIZATION_LIST',
        label: 'Lista organizatii',
        description: 'Lista organizatii',
        active: true,
      }, {
        id: 'DA0A443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'ORGANIZATION_SEARCH',
        label: 'Cauta organizatie',
        description: 'Cauta organizatie',
        active: true,
      }, {
        id: 'E10A443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'ORGANIZATION_VIEW',
        label: 'Vizualizeaza organizatie',
        description: 'Vizualizeaza organizatie',
        active: true,
      }, {
        id: 'EF0A443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'PATIENT_DELETE',
        label: 'Sterge pacient',
        description: 'Sterge pacient',
        active: true,
      }, {
        id: 'F60A443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'PATIENT_EDIT',
        label: 'Editeaza pacient',
        description: 'Editeaza pacient',
        active: true,
      }, {
        id: 'FD0A443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'PATIENT_LIST',
        label: 'Lista pacienti',
        description: 'Lista pacienti',
        active: true,
      }, {
        id: '040B443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'PATIENT_SEARCH',
        label: 'Cauta pacient',
        description: 'Cauta pacient',
        active: true,
      }, {
        id: '0B0B443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'PATIENT_VIEW',
        label: 'Vizualizeaza pacient',
        description: 'Vizualizeaza pacient',
        active: true,
      }, {
        id: '3C0B443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'REPORT_ADD',
        label: 'Adauga raport',
        description: 'Adauga raport',
        active: true,
      }, {
        id: '4A0B443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'REPORT_LIST',
        label: 'Lista rapoarte',
        description: 'Lista rapoarte',
        active: true,
      }, {
        id: '510B443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'REPORT_VIEW',
        label: 'Vizualizeaza raport',
        description: 'Vizualizeaza raport',
        active: true,
      }, {
        id: '5F0B443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'ROLE_ADMIN',
        label: 'Permisiune Administrator',
        description: 'Permisiune Administrator',
        active: true,
      }, {
        id: 'B30B443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'USER_ADD',
        label: 'Adauga utilizator',
        description: 'Adauga utilizator',
        active: true,
      }, {
        id: 'BA0B443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'USER_DELETE',
        label: 'Sterge utilizator',
        description: 'Sterge utilizator',
        active: true,
      }, {
        id: 'C10B443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'USER_EDIT',
        label: 'Editeaza utilizator',
        description: 'Editeaza utilizator',
        active: true,
      }, {
        id: 'C80B443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'USER_LIST',
        label: 'Lista utilizatori',
        description: 'Lista utilizatori',
        active: true,
      }, {
        id: 'CF0B443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'USER_SEARCH',
        label: 'Cauta utilizator',
        description: 'Cauta utilizator',
        active: true,
      }, {
        id: 'D60B443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'USER_VIEW',
        label: 'Vizualizare utilizator',
        description: 'Vizualizare utilizator',
        active: true,
      }, {
        id: 'E5BF443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'ALL_FORM_SECTIONS_VIEW',
        label: 'Vizualizeaza toate sectiunile formularului',
        description: 'Vizualizeaza toate sectiunile formularului',
        active: true,
      }, {
        id: 'E8BF443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'ALL_FORM_SECTIONS_EDIT',
        label: 'Editeaza toate sectiunile formularului',
        description: 'Editeaza toate sectiunile formularului',
        active: true,
      }, {
        id: 'B7C5443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'NAVBAR_BORDEROU_EXPORT',
        label: 'Sectiune Raportare',
        description: 'Sectiune Raportare',
        active: true,
      }, {
        id: 'BEC5443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'BORDEROU_EXPORT',
        label: 'Export borderouri',
        description: 'Export borderouri',
        active: true,
      }, {
        id: '0531433E-F36B-1410-8D3A-00DFB91F5CDC',
        code: 'NAVBAR_INDICATORS',
        label: 'Sectiune Indicatori',
        description: 'Sectiune Indicatori',
        active: true,
      }, {
        id: '0C31433E-F36B-1410-8D3A-00DFB91F5CDC',
        code: 'VIEW_INDICATORS',
        label: 'Vizualizare indicatori',
        description: 'Vizualizare indicatori',
        active: true,
      }, {
        id: 'BB3A433E-F36B-1410-8D3A-00DFB91F5CDC',
        code: 'NAVBAR_USER_MANUAL',
        label: 'Sectiune Manual de utilizare',
        description: 'Sectiune Manual de utilizare',
        active: true,
      }, {
        id: '13EA433E-F36B-1410-8D3C-00DFB91F5CDC',
        code: 'NAVBAR_STATISTICS',
        label: 'Sectiune Statistici',
        description: 'Sectiune Statistici',
        active: true,
      }],
  },
  {
    id: 'E50B443E-F36B-1410-8D2C-00DFB91F5CDC',
    code: 'ROLE_DOCTOR',
    label: 'Rol personal furnizor',
    description: 'Rol personal furnizor',
    permissions: [{
      id: '8309443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'CASEFILE_DELETE',
      label: 'Sterge dosar',
      description: 'Sterge dosar',
      active: true,
    }, {
      id: '9109443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'CASEFILE_LIST',
      label: 'Lista dosare',
      description: 'Lista dosare',
      active: true,
    }, {
      id: '9809443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'CASEFILE_SEARCH',
      label: 'Cauta dosar',
      description: 'Cauta dosar',
      active: true,
    }, {
      id: '9F09443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'CASEFILE_VIEW',
      label: 'Vizualizeaza dosar',
      description: 'Vizualizeaza dosar',
      active: true,
    }, {
      id: 'A609443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'CASEFILE_VIEW_FILE_ADD',
      label: 'Adauga fisa in dosar',
      description: 'Adauga fisa in dosar',
      active: true,
    }, {
      id: 'AD09443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'CASEFILE_VIEW_FILE_EDIT',
      label: 'Editeaza fisa in dosar',
      description: 'Editeaza fisa in dosar',
      active: true,
    }, {
      id: 'B409443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'CASEFILE_VIEW_FILE_LIST',
      label: 'Lista fise in dosar',
      description: 'Lista fise in dosar',
      active: true,
    }, {
      id: 'BB09443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'CASEFILE_VIEW_FILE_VIEW',
      label: 'Vizualizeaza fisa in dosar',
      description: 'Vizualizeaza fisa in dosar',
      active: true,
    }, {
      id: 'E509443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'DOCTOR_VIEW',
      label: 'Vizualizeaza medic',
      description: 'Vizualizeaza medic',
      active: true,
    }, {
      id: 'EC09443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'FORM_ADD',
      label: 'Adauga formular',
      description: 'Adauga formular',
      active: true,
    }, {
      id: 'F309443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'FORM_DELETE',
      label: 'Sterge formular',
      description: 'Sterge formular',
      active: true,
    }, {
      id: 'FA09443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'FORM_EDIT',
      label: 'Editeaza formular',
      description: 'Editeaza formular',
      active: true,
    }, {
      id: '010A443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'FORM_LIST',
      label: 'Lista formulare',
      description: 'Lista formulare',
      active: true,
    }, {
      id: '080A443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'FORM_SEARCH',
      label: 'Cauta formular',
      description: 'Cauta formular',
      active: true,
    }, {
      id: '0F0A443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'FORM_VIEW',
      label: 'Vizualizeaza formular',
      description: 'Vizualizeaza formular',
      active: true,
    }, {
      id: '1D0A443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'NAVBAR_CASEFILE',
      label: 'Sectiune Dosar',
      description: 'Sectiune Dosar',
      active: true,
    }, {
      id: '2B0A443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'NAVBAR_NOTIFICATIONS',
      label: 'Sectiune Mesaje',
      description: 'Sectiune Mesaje',
      active: true,
    }, {
      id: '320A443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'NAVBAR_PATIENT',
      label: 'Sectiune Pacient',
      description: 'Sectiune Pacient',
      active: true,
    }, {
      id: '630A443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'NOMEN_VIEW',
      label: 'Vizualizare nomenclator',
      description: 'Vizualizare nomenclator',
      active: true,
    }, {
      id: '710A443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'NOTIFICATION_RECEIVED_LIST',
      label: 'Lista mesaje primite',
      description: 'Lista mesaje primite',
      active: true,
    }, {
      id: '780A443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'NOTIFICATION_RECEIVED_SEARCH',
      label: 'Cauta mesaj primit',
      description: 'Cauta mesaj primit',
      active: true,
    }, {
      id: '860A443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'NOTIFICATION_VIEW',
      label: 'Vizualizeaza mesaj',
      description: 'Vizualizeaza mesaj',
      active: true,
    }, {
      id: 'E80A443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'PATIENT_ADD',
      label: 'Adauga pacient',
      description: 'Adauga pacient',
      active: true,
    }, {
      id: 'EF0A443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'PATIENT_DELETE',
      label: 'Sterge pacient',
      description: 'Sterge pacient',
      active: true,
    }, {
      id: 'F60A443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'PATIENT_EDIT',
      label: 'Editeaza pacient',
      description: 'Editeaza pacient',
      active: true,
    }, {
      id: 'FD0A443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'PATIENT_LIST',
      label: 'Lista pacienti',
      description: 'Lista pacienti',
      active: true,
    }, {
      id: '040B443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'PATIENT_SEARCH',
      label: 'Cauta pacient',
      description: 'Cauta pacient',
      active: true,
    }, {
      id: '0B0B443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'PATIENT_VIEW',
      label: 'Vizualizeaza pacient',
      description: 'Vizualizeaza pacient',
      active: true,
    }, {
      id: '3C0B443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'REPORT_ADD',
      label: 'Adauga raport',
      description: 'Adauga raport',
      active: true,
    }, {
      id: '4A0B443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'REPORT_LIST',
      label: 'Lista rapoarte',
      description: 'Lista rapoarte',
      active: true,
    }, {
      id: '510B443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'REPORT_VIEW',
      label: 'Vizualizeaza raport',
      description: 'Vizualizeaza raport',
      active: true,
    }, {
      id: 'E5BF443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'ALL_FORM_SECTIONS_VIEW',
      label: 'Vizualizeaza toate sectiunile formularului',
      description: 'Vizualizeaza toate sectiunile formularului',
      active: true,
    }, {
      id: 'E8BF443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'ALL_FORM_SECTIONS_EDIT',
      label: 'Editeaza toate sectiunile formularului',
      description: 'Editeaza toate sectiunile formularului',
      active: true,
    }, {
      id: '37C1443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'CASEFILE_VIEW_FILE_DELETE',
      label: 'Sterge fisa din dosar',
      description: 'Sterge fisa din dosar',
      active: true,
    }, {
      id: '39C1443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'CASEFILE_VIEW_FILE_DOWNLOAD',
      label: 'Descarca fisa',
      description: 'Descarca fisa',
      active: true,
    }, {
      id: 'BB3A433E-F36B-1410-8D3A-00DFB91F5CDC',
      code: 'NAVBAR_USER_MANUAL',
      label: 'Sectiune Manual de utilizare',
      description: 'Sectiune Manual de utilizare',
      active: true,
    }, {
      id: '08F8433E-F36B-1410-8D3C-00DFB91F5CDC',
      code: 'PATIENT_SERVICES_REPORT',
      label: 'Raport servicii beneficiar',
      description: 'Raport servicii beneficiar',
      active: true,
    }],
  }, {
    id: 'EA0B443E-F36B-1410-8D2C-00DFB91F5CDC',
    code: 'ROLE_PATIENT',
    label: 'Rol pacient',
    description: 'Rol pacient',
    permissions: [{
      id: 'BB3A433E-F36B-1410-8D3A-00DFB91F5CDC',
      code: 'NAVBAR_USER_MANUAL',
      label: 'Sectiune Manual de utilizare',
      description: 'Sectiune Manual de utilizare',
      active: true,
    }],
  }, {
    id: '7CBF443E-F36B-1410-8D2C-00DFB91F5CDC',
    code: 'ROLE_AUXILIARY_STAFF',
    label: 'Personal auxiliar',
    description: 'Rol personal auxiliar',
    permissions: [{
      id: '0F0A443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'FORM_VIEW',
      label: 'Vizualizeaza formular',
      description: 'Vizualizeaza formular',
      active: true,
    }, {
      id: '2B0A443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'NAVBAR_NOTIFICATIONS',
      label: 'Sectiune Mesaje',
      description: 'Sectiune Mesaje',
      active: true,
    }, {
      id: '320A443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'NAVBAR_PATIENT',
      label: 'Sectiune Pacient',
      description: 'Sectiune Pacient',
      active: true,
    }, {
      id: '630A443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'NOMEN_VIEW',
      label: 'Vizualizare nomenclator',
      description: 'Vizualizare nomenclator',
      active: true,
    }, {
      id: '710A443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'NOTIFICATION_RECEIVED_LIST',
      label: 'Lista mesaje primite',
      description: 'Lista mesaje primite',
      active: true,
    }, {
      id: '780A443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'NOTIFICATION_RECEIVED_SEARCH',
      label: 'Cauta mesaj primit',
      description: 'Cauta mesaj primit',
      active: true,
    }, {
      id: '860A443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'NOTIFICATION_VIEW',
      label: 'Vizualizeaza mesaj',
      description: 'Vizualizeaza mesaj',
      active: true,
    }, {
      id: 'E80A443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'PATIENT_ADD',
      label: 'Adauga pacient',
      description: 'Adauga pacient',
      active: true,
    }, {
      id: 'EF0A443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'PATIENT_DELETE',
      label: 'Sterge pacient',
      description: 'Sterge pacient',
      active: true,
    }, {
      id: 'F60A443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'PATIENT_EDIT',
      label: 'Editeaza pacient',
      description: 'Editeaza pacient',
      active: true,
    }, {
      id: 'FD0A443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'PATIENT_LIST',
      label: 'Lista pacienti',
      description: 'Lista pacienti',
      active: true,
    }, {
      id: '040B443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'PATIENT_SEARCH',
      label: 'Cauta pacient',
      description: 'Cauta pacient',
      active: true,
    }, {
      id: '0B0B443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'PATIENT_VIEW',
      label: 'Vizualizeaza pacient',
      description: 'Vizualizeaza pacient',
      active: true,
    }, {
      id: 'BB3A433E-F36B-1410-8D3A-00DFB91F5CDC',
      code: 'NAVBAR_USER_MANUAL',
      label: 'Sectiune Manual de utilizare',
      description: 'Sectiune Manual de utilizare',
      active: true,
    }],
  }, {
    id: 'C247433E-F36B-1410-8D33-00DFB91F5CDC',
    code: 'ROLE_FINANCIAR',
    label: 'Financiar',
    description: 'Financiar',
    permissions: [{
      id: '2B0A443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'NAVBAR_NOTIFICATIONS',
      label: 'Sectiune Mesaje',
      description: 'Sectiune Mesaje',
      active: true,
    }, {
      id: '710A443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'NOTIFICATION_RECEIVED_LIST',
      label: 'Lista mesaje primite',
      description: 'Lista mesaje primite',
      active: true,
    }, {
      id: '780A443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'NOTIFICATION_RECEIVED_SEARCH',
      label: 'Cauta mesaj primit',
      description: 'Cauta mesaj primit',
      active: true,
    }, {
      id: '860A443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'NOTIFICATION_VIEW',
      label: 'Vizualizeaza mesaj',
      description: 'Vizualizeaza mesaj',
      active: true,
    }, {
      id: 'B7C5443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'NAVBAR_BORDEROU_EXPORT',
      label: 'Sectiune Raportare',
      description: 'Sectiune Raportare',
      active: true,
    }, {
      id: 'BEC5443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'BORDEROU_EXPORT',
      label: 'Export borderouri',
      description: 'Export borderouri',
      active: true,
    }, {
      id: 'BB3A433E-F36B-1410-8D3A-00DFB91F5CDC',
      code: 'NAVBAR_USER_MANUAL',
      label: 'Sectiune Manual de utilizare',
      description: 'Sectiune Manual de utilizare',
      active: true,
    }, {
      id: '13EA433E-F36B-1410-8D3C-00DFB91F5CDC',
      code: 'NAVBAR_STATISTICS',
      label: 'Sectiune Statistici',
      description: 'Sectiune Statistici',
      active: true,
    }],
  }, {
    id: '4448433E-F36B-1410-8D33-00DFB91F5CDC',
    code: 'ROLE_ORG_ADMIN',
    label: 'Rol administrator organizational',
    description: 'Rol administrator organizational',
    permissions: [{
      id: '7509443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'ADM_USERS',
      label: 'Administrare Utilizatori',
      description: 'Administrare Utilizatori',
      active: true,
    }, {
      id: 'C209443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'DOCTOR_ADD',
      label: 'Adauga medic',
      description: 'Adauga medic',
      active: true,
    }, {
      id: 'C909443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'DOCTOR_DELETE',
      label: 'Sterge medic',
      description: 'Sterge medic',
      active: true,
    }, {
      id: 'D009443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'DOCTOR_EDIT',
      label: 'Editeaza medic',
      description: 'Editeaza medic',
      active: true,
    }, {
      id: 'D709443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'DOCTOR_LIST',
      label: 'Lista medici',
      description: 'Lista medici',
      active: true,
    }, {
      id: 'DE09443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'DOCTOR_SEARCH',
      label: 'Cauta medic',
      description: 'Cauta medic',
      active: true,
    }, {
      id: 'E509443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'DOCTOR_VIEW',
      label: 'Vizualizeaza medic',
      description: 'Vizualizeaza medic',
      active: true,
    }, {
      id: '160A443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'NAVBAR_ADM',
      label: 'Sectiune Administrare',
      description: 'Sectiune Administrare',
      active: true,
    }, {
      id: '240A443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'NAVBAR_DOCTOR',
      label: 'Sectiune Doctor',
      description: 'Sectiune Doctor',
      active: true,
    }, {
      id: '2B0A443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'NAVBAR_NOTIFICATIONS',
      label: 'Sectiune Mesaje',
      description: 'Sectiune Mesaje',
      active: true,
    }, {
      id: '6A0A443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'NOTIFICATION_ADD',
      label: 'Adauga mesaj',
      description: 'Adauga mesaj',
      active: true,
    }, {
      id: '710A443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'NOTIFICATION_RECEIVED_LIST',
      label: 'Lista mesaje primite',
      description: 'Lista mesaje primite',
      active: true,
    }, {
      id: '780A443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'NOTIFICATION_RECEIVED_SEARCH',
      label: 'Cauta mesaj primit',
      description: 'Cauta mesaj primit',
      active: true,
    }, {
      id: '7F0A443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'NOTIFICATION_SENT_LIST',
      label: 'Lista mesaje trimise',
      description: 'Lista mesaje trimise',
      active: true,
    }, {
      id: '860A443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'NOTIFICATION_VIEW',
      label: 'Vizualizeaza mesaj',
      description: 'Vizualizeaza mesaj',
      active: true,
    }, {
      id: '8D0A443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'NOTIFICATION_SENT_SEARCH',
      label: 'Cauta mesaj trimis',
      description: 'Cauta mesaj trimis',
      active: true,
    }, {
      id: 'B30B443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'USER_ADD',
      label: 'Adauga utilizator',
      description: 'Adauga utilizator',
      active: true,
    }, {
      id: 'BA0B443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'USER_DELETE',
      label: 'Sterge utilizator',
      description: 'Sterge utilizator',
      active: true,
    }, {
      id: 'C10B443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'USER_EDIT',
      label: 'Editeaza utilizator',
      description: 'Editeaza utilizator',
      active: true,
    }, {
      id: 'C80B443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'USER_LIST',
      label: 'Lista utilizatori',
      description: 'Lista utilizatori',
      active: true,
    }, {
      id: 'CF0B443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'USER_SEARCH',
      label: 'Cauta utilizator',
      description: 'Cauta utilizator',
      active: true,
    }, {
      id: 'D60B443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'USER_VIEW',
      label: 'Vizualizare utilizator',
      description: 'Vizualizare utilizator',
      active: true,
    }, {
      id: '39C1443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'CASEFILE_VIEW_FILE_DOWNLOAD',
      label: 'Descarca fisa',
      description: 'Descarca fisa',
      active: true,
    }, {
      id: 'BB3A433E-F36B-1410-8D3A-00DFB91F5CDC',
      code: 'NAVBAR_USER_MANUAL',
      label: 'Sectiune Manual de utilizare',
      description: 'Sectiune Manual de utilizare',
      active: true,
    }],
  }, {
    id: '4B48433E-F36B-1410-8D33-00DFB91F5CDC',
    code: 'ROLE_REG_ADMIN',
    label: 'Rol administrator regional',
    description: 'Rol administrator regional',
    permissions: [{
      id: '5909443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'ADM_ORGANIZATION',
      label: 'Administrare organizatii',
      description: 'Administrare organizatii',
      active: true,
    }, {
      id: '7509443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'ADM_USERS',
      label: 'Administrare Utilizatori',
      description: 'Administrare Utilizatori',
      active: true,
    }, {
      id: '9109443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'CASEFILE_LIST',
      label: 'Lista dosare',
      description: 'Lista dosare',
      active: true,
    }, {
      id: '9809443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'CASEFILE_SEARCH',
      label: 'Cauta dosar',
      description: 'Cauta dosar',
      active: true,
    }, {
      id: '9F09443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'CASEFILE_VIEW',
      label: 'Vizualizeaza dosar',
      description: 'Vizualizeaza dosar',
      active: true,
    }, {
      id: 'B409443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'CASEFILE_VIEW_FILE_LIST',
      label: 'Lista fise in dosar',
      description: 'Lista fise in dosar',
      active: true,
    }, {
      id: 'BB09443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'CASEFILE_VIEW_FILE_VIEW',
      label: 'Vizualizeaza fisa in dosar',
      description: 'Vizualizeaza fisa in dosar',
      active: true,
    }, {
      id: 'E509443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'DOCTOR_VIEW',
      label: 'Vizualizeaza medic',
      description: 'Vizualizeaza medic',
      active: true,
    }, {
      id: '0F0A443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'FORM_VIEW',
      label: 'Vizualizeaza formular',
      description: 'Vizualizeaza formular',
      active: true,
    }, {
      id: '160A443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'NAVBAR_ADM',
      label: 'Sectiune Administrare',
      description: 'Sectiune Administrare',
      active: true,
    }, {
      id: '1D0A443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'NAVBAR_CASEFILE',
      label: 'Sectiune Dosar',
      description: 'Sectiune Dosar',
      active: true,
    }, {
      id: '2B0A443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'NAVBAR_NOTIFICATIONS',
      label: 'Sectiune Mesaje',
      description: 'Sectiune Mesaje',
      active: true,
    }, {
      id: '6A0A443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'NOTIFICATION_ADD',
      label: 'Adauga mesaj',
      description: 'Adauga mesaj',
      active: true,
    }, {
      id: '710A443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'NOTIFICATION_RECEIVED_LIST',
      label: 'Lista mesaje primite',
      description: 'Lista mesaje primite',
      active: true,
    }, {
      id: '780A443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'NOTIFICATION_RECEIVED_SEARCH',
      label: 'Cauta mesaj primit',
      description: 'Cauta mesaj primit',
      active: true,
    }, {
      id: '7F0A443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'NOTIFICATION_SENT_LIST',
      label: 'Lista mesaje trimise',
      description: 'Lista mesaje trimise',
      active: true,
    }, {
      id: '860A443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'NOTIFICATION_VIEW',
      label: 'Vizualizeaza mesaj',
      description: 'Vizualizeaza mesaj',
      active: true,
    }, {
      id: '8D0A443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'NOTIFICATION_SENT_SEARCH',
      label: 'Cauta mesaj trimis',
      description: 'Cauta mesaj trimis',
      active: true,
    }, {
      id: 'BE0A443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'ORGANIZATION_ADD',
      label: 'Adauga organizatie',
      description: 'Adauga organizatie',
      active: true,
    }, {
      id: 'C50A443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'ORGANIZATION_DELETE',
      label: 'Sterge organizatie',
      description: 'Sterge organizatie',
      active: true,
    }, {
      id: 'CC0A443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'ORGANIZATION_EDIT',
      label: 'Editeaza organizatie',
      description: 'Editeaza organizatie',
      active: true,
    }, {
      id: 'D30A443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'ORGANIZATION_LIST',
      label: 'Lista organizatii',
      description: 'Lista organizatii',
      active: true,
    }, {
      id: 'DA0A443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'ORGANIZATION_SEARCH',
      label: 'Cauta organizatie',
      description: 'Cauta organizatie',
      active: true,
    }, {
      id: 'E10A443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'ORGANIZATION_VIEW',
      label: 'Vizualizeaza organizatie',
      description: 'Vizualizeaza organizatie',
      active: true,
    }, {
      id: '0B0B443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'PATIENT_VIEW',
      label: 'Vizualizeaza pacient',
      description: 'Vizualizeaza pacient',
      active: true,
    }, {
      id: 'B30B443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'USER_ADD',
      label: 'Adauga utilizator',
      description: 'Adauga utilizator',
      active: true,
    }, {
      id: 'BA0B443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'USER_DELETE',
      label: 'Sterge utilizator',
      description: 'Sterge utilizator',
      active: true,
    }, {
      id: 'C10B443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'USER_EDIT',
      label: 'Editeaza utilizator',
      description: 'Editeaza utilizator',
      active: true,
    }, {
      id: 'C80B443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'USER_LIST',
      label: 'Lista utilizatori',
      description: 'Lista utilizatori',
      active: true,
    }, {
      id: 'CF0B443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'USER_SEARCH',
      label: 'Cauta utilizator',
      description: 'Cauta utilizator',
      active: true,
    }, {
      id: 'D60B443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'USER_VIEW',
      label: 'Vizualizare utilizator',
      description: 'Vizualizare utilizator',
      active: true,
    }, {
      id: 'E5BF443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'ALL_FORM_SECTIONS_VIEW',
      label: 'Vizualizeaza toate sectiunile formularului',
      description: 'Vizualizeaza toate sectiunile formularului',
      active: true,
    }, {
      id: 'E8BF443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'ALL_FORM_SECTIONS_EDIT',
      label: 'Editeaza toate sectiunile formularului',
      description: 'Editeaza toate sectiunile formularului',
      active: true,
    }, {
      id: '39C1443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'CASEFILE_VIEW_FILE_DOWNLOAD',
      label: 'Descarca fisa',
      description: 'Descarca fisa',
      active: true,
    }, {
      id: 'B7C5443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'NAVBAR_BORDEROU_EXPORT',
      label: 'Sectiune Raportare',
      description: 'Sectiune Raportare',
      active: true,
    }, {
      id: 'BEC5443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'BORDEROU_EXPORT',
      label: 'Export borderouri',
      description: 'Export borderouri',
      active: true,
    }, {
      id: '0531433E-F36B-1410-8D3A-00DFB91F5CDC',
      code: 'NAVBAR_INDICATORS',
      label: 'Sectiune Indicatori',
      description: 'Sectiune Indicatori',
      active: true,
    }, {
      id: '0C31433E-F36B-1410-8D3A-00DFB91F5CDC',
      code: 'VIEW_INDICATORS',
      label: 'Vizualizare indicatori',
      description: 'Vizualizare indicatori',
      active: true,
    }, {
      id: 'BB3A433E-F36B-1410-8D3A-00DFB91F5CDC',
      code: 'NAVBAR_USER_MANUAL',
      label: 'Sectiune Manual de utilizare',
      description: 'Sectiune Manual de utilizare',
      active: true,
    }, {
      id: '13EA433E-F36B-1410-8D3C-00DFB91F5CDC',
      code: 'NAVBAR_STATISTICS',
      label: 'Sectiune Statistici',
      description: 'Sectiune Statistici',
      active: true,
    }, {
      id: '1AEA433E-F36B-1410-8D3C-00DFB91F5CDC',
      code: 'OIR_BORDEROU_EXPORT',
      label: 'Export borderouri OIR',
      description: 'Export borderouri OIR',
      active: true,
    }],
  }, {
    id: '5248433E-F36B-1410-8D33-00DFB91F5CDC',
    code: 'ROLE_INTRODUCERE_DATE',
    label: 'Rol introducere date',
    description: 'Rol introducere date',
    permissions: [{
      id: '9109443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'CASEFILE_LIST',
      label: 'Lista dosare',
      description: 'Lista dosare',
      active: true,
    }, {
      id: '9809443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'CASEFILE_SEARCH',
      label: 'Cauta dosar',
      description: 'Cauta dosar',
      active: true,
    }, {
      id: '9F09443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'CASEFILE_VIEW',
      label: 'Vizualizeaza dosar',
      description: 'Vizualizeaza dosar',
      active: true,
    }, {
      id: 'A609443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'CASEFILE_VIEW_FILE_ADD',
      label: 'Adauga fisa in dosar',
      description: 'Adauga fisa in dosar',
      active: true,
    }, {
      id: 'AD09443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'CASEFILE_VIEW_FILE_EDIT',
      label: 'Editeaza fisa in dosar',
      description: 'Editeaza fisa in dosar',
      active: true,
    }, {
      id: 'B409443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'CASEFILE_VIEW_FILE_LIST',
      label: 'Lista fise in dosar',
      description: 'Lista fise in dosar',
      active: true,
    }, {
      id: 'BB09443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'CASEFILE_VIEW_FILE_VIEW',
      label: 'Vizualizeaza fisa in dosar',
      description: 'Vizualizeaza fisa in dosar',
      active: true,
    }, {
      id: 'E509443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'DOCTOR_VIEW',
      label: 'Vizualizeaza medic',
      description: 'Vizualizeaza medic',
      active: true,
    }, {
      id: '0F0A443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'FORM_VIEW',
      label: 'Vizualizeaza formular',
      description: 'Vizualizeaza formular',
      active: true,
    }, {
      id: '1D0A443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'NAVBAR_CASEFILE',
      label: 'Sectiune Dosar',
      description: 'Sectiune Dosar',
      active: true,
    }, {
      id: '2B0A443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'NAVBAR_NOTIFICATIONS',
      label: 'Sectiune Mesaje',
      description: 'Sectiune Mesaje',
      active: true,
    }, {
      id: '320A443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'NAVBAR_PATIENT',
      label: 'Sectiune Pacient',
      description: 'Sectiune Pacient',
      active: true,
    }, {
      id: '710A443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'NOTIFICATION_RECEIVED_LIST',
      label: 'Lista mesaje primite',
      description: 'Lista mesaje primite',
      active: true,
    }, {
      id: '780A443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'NOTIFICATION_RECEIVED_SEARCH',
      label: 'Cauta mesaj primit',
      description: 'Cauta mesaj primit',
      active: true,
    }, {
      id: '860A443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'NOTIFICATION_VIEW',
      label: 'Vizualizeaza mesaj',
      description: 'Vizualizeaza mesaj',
      active: true,
    }, {
      id: 'E80A443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'PATIENT_ADD',
      label: 'Adauga pacient',
      description: 'Adauga pacient',
      active: true,
    }, {
      id: 'EF0A443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'PATIENT_DELETE',
      label: 'Sterge pacient',
      description: 'Sterge pacient',
      active: true,
    }, {
      id: 'F60A443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'PATIENT_EDIT',
      label: 'Editeaza pacient',
      description: 'Editeaza pacient',
      active: true,
    }, {
      id: 'FD0A443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'PATIENT_LIST',
      label: 'Lista pacienti',
      description: 'Lista pacienti',
      active: true,
    }, {
      id: '040B443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'PATIENT_SEARCH',
      label: 'Cauta pacient',
      description: 'Cauta pacient',
      active: true,
    }, {
      id: '0B0B443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'PATIENT_VIEW',
      label: 'Vizualizeaza pacient',
      description: 'Vizualizeaza pacient',
      active: true,
    }, {
      id: 'E5BF443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'ALL_FORM_SECTIONS_VIEW',
      label: 'Vizualizeaza toate sectiunile formularului',
      description: 'Vizualizeaza toate sectiunile formularului',
      active: true,
    }, {
      id: 'E8BF443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'ALL_FORM_SECTIONS_EDIT',
      label: 'Editeaza toate sectiunile formularului',
      description: 'Editeaza toate sectiunile formularului',
      active: true,
    }, {
      id: 'BB3A433E-F36B-1410-8D3A-00DFB91F5CDC',
      code: 'NAVBAR_USER_MANUAL',
      label: 'Sectiune Manual de utilizare',
      description: 'Sectiune Manual de utilizare',
      active: true,
    }, {
      id: '08F8433E-F36B-1410-8D3C-00DFB91F5CDC',
      code: 'PATIENT_SERVICES_REPORT',
      label: 'Raport servicii beneficiar',
      description: 'Raport servicii beneficiar',
      active: true,
    }],
  }];

export const userRolesOptions = [
  { value: 'ROLE_SUPER_ADMIN', label: 'Rol super administrator', },
  { value: 'ROLE_DOCTOR', label: 'Rol personal furnizor', },
  { value: 'ROLE_AUXILIARY_STAFF', label: 'Personal auxiliar', },
  { value: 'ROLE_FINANCIAR', label: 'Rol financiar', },
  { value: 'ROLE_ORG_ADMIN', label: 'Rol administrator organizational', },
  { value: 'ROLE_REG_ADMIN', label: 'Rol administrator regional', },
  { value: 'ROLE_INTRODUCERE_DATE', label: 'Rol introducere date', },
  { value: 'ROLE_ADMIN', label: 'Rol administrator', }
];

export const userRolesAdminOptions = [
  { value: 'ROLE_SUPER_ADMIN', label: 'Rol super administrator', },
  { value: 'ROLE_REG_ADMIN', label: 'Rol administrator regional', }
];

export const userPermissionOptions = [
  { value: 'ROLE_FINANCIAR', label: 'Rol financiar', },
  { value: 'ROLE_ORG_ADMIN', label: 'Rol administrator organizational', },
  { value: 'ROLE_INTRODUCERE_DATE', label: 'Rol introducere date', },
  { value: 'ROLE_DOCTOR', label: 'Rol personal furnizor', }
];
