/* eslint-disable no-undef */
import Cookies from 'universal-cookie';
import actions from './actions';

const login = data => dispatch => new Promise(((resolve, reject) => {
  dispatch(actions.login(data))
    .then(res => {
      resolve(res);
    })
    .catch(err => {
      reject(err);
    });
}));

const logout = () => dispatch => new Promise(((resolve, reject) => {
  dispatch(actions.logout())
    .then(res => {
      resolve(res);
      window.location.reload();
    })
    .catch(err => {
      reject(err);
      window.location.reload();
    });
}));

const register = (data, lang) => dispatch => new Promise(((resolve, reject) => {
  dispatch(actions.register(data, lang))
    .then(res => {
      resolve(res);
    })
    .catch(err => {
      reject(err);
    });
}));

const registerTech = (data, lang) => dispatch => new Promise(((resolve, reject) => {
  dispatch(actions.registerTech(data, lang))
    .then(res => {
      resolve(res);
    })
    .catch(err => {
      reject(err);
    });
}));

const requestPasswordReset = data => dispatch => new Promise(((resolve, reject) => {
  dispatch(actions.requestPasswordReset(data))
    .then(res => {
      resolve(res);
    })
    .catch(err => {
      reject(err);
    });
}));

const activateAccount = data => dispatch => new Promise(((resolve, reject) => {
  dispatch(actions.activateAccount(data))
    .then(res => {
      resolve(res);
    })
    .catch(err => {
      reject(err);
    });
}));

const verifyCompleteAccount = token => dispatch => new Promise(((resolve, reject) => {
  dispatch(actions.verifyCompleteAccount(token))
    .then(res => {
      resolve(res);
    })
    .catch(err => {
      reject(err);
    });
}));

const completeAccount = (data, token) => dispatch => new Promise(((resolve, reject) => {
  dispatch(actions.completeAccount(data, token))
    .then(res => {
      resolve(res);
    })
    .catch(err => {
      reject(err);
    });
}));

const checkResetPasswordToken = data => dispatch => new Promise(((resolve, reject) => {
  dispatch(actions.checkResetPasswordToken(data))
    .then(res => {
      resolve(res);
    })
    .catch(err => {
      reject(err);
    });
}));

const setNewPassword = (data, token) => dispatch => new Promise(((resolve, reject) => {
  dispatch(actions.setNewPassword(data, token))
    .then(res => {
      resolve(res);
    })
    .catch(err => {
      reject(err);
    });
}));

const changePassword = data => dispatch => new Promise(((resolve, reject) => {
  dispatch(actions.changePassword(data))
    .then(res => {
      resolve(res);
    })
    .catch(err => {
      reject(err);
    });
}));

const changeUserProfile = data => dispatch => new Promise(((resolve, reject) => {
  dispatch(actions.changeUserProfile(data))
    .then(res => {
      resolve(res);
    })
    .catch(err => {
      reject(err);
    });
}));

let checkingToken;

const checkToken = () => dispatch => {
  const token = localStorage.getItem('token');
  const expiry = localStorage.getItem('expiry');
  const refresh = localStorage.getItem('refresh');
  const now = Math.floor(new Date().getTime());
  if (typeof token === 'undefined' || token === null || Number(expiry) - now < 0) {
    dispatch(actions.logout());
    clearTimeout(checkingToken);
  } else {
    if (Number(expiry) - now < 60000) {
      dispatch(actions.refreshUserToken({ refreshToken: refresh, }))
        .then((res => {
          localStorage.setItem('token', res.data.accessToken);
          localStorage.setItem('expiry', Date.now() + Number(res.data.expiryDuration));
          localStorage.setItem('refresh', res.data.refreshToken);
        }))
        .catch((err => {
          console.log(err);
          clearTimeout(checkingToken);
          dispatch(actions.logout());
        }));
    }
    checkingToken = setTimeout(() => {
      dispatch(checkToken());
    }, 5000);
  }
};

const refreshUserToken = data => dispatch => new Promise(((resolve, reject) => {
  dispatch(actions.refreshUserToken(data))
    .then(
      res => {
        resolve(res);
      },
      err => {
        reject(err);
      }
    );
}));

const stopChecking = () => dispatch => {
  clearTimeout(checkingToken);
};

const switchCompany = uuid => dispatch => new Promise(((resolve, reject) => {
  dispatch(actions.switchCompany(uuid))
    .then(res => {
      resolve(res);
    })
    .catch(err => {
      reject(err);
    });
}));

const getUserSettings = () => dispatch => new Promise(((resolve, reject) => {
  dispatch(actions.getUserSettings())
    .then(res => {
      resolve(res);
    })
    .catch(err => {
      reject(err);
    });
}));

export default {
  login,
  logout,
  checkToken,
  stopChecking,
  register,
  registerTech,
  requestPasswordReset,
  activateAccount,
  verifyCompleteAccount,
  completeAccount,
  checkResetPasswordToken,
  setNewPassword,
  changePassword,
  changeUserProfile,
  getUserSettings,
  refreshUserToken,
  switchCompany,
};
