import React, { useEffect, useState } from 'react';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import Select from 'react-select';
import 'react-datepicker/dist/react-datepicker.css';

const stareFisa = [
  { id: 'IN_LUCRU', value: 'IN LUCRU', },
  { id: 'FINALIZATA', value: 'FINALIZATA', }
];

const rezultatOptions = [
  { id: 'POZITIV', value: 'POZITIV', },
  { id: 'NEGATIV', value: 'NEGATIV', }
];

function CasefileFilterExtendedSection({ withResult, setDoctors, type, title, handleOrganizationChange, organizations, doctors, handleChange, }) {
  const handleOrgChange = (selected, details) => {
    handleOrganizationChange(selected, details, setDoctors);
  };

  return (
    <>
      <p className="mb-0"><strong>{title}</strong></p>
      <Row>
        <Col sm={withResult ? 3 : 4}>
          <FormGroup>
            <Label>
              <small className="font-weight-bold">Centru</small>
            </Label>
            <Select
              getOptionValue={option => option.id}
              getOptionLabel={option => option.value}
              onChange={handleOrgChange}
              className="basic-single"
              classNamePrefix="select"
              placeholder=""
              isClearable
              name={`${type}Organization`}
              options={organizations}
            />
          </FormGroup>
        </Col>
        <Col sm={withResult ? 3 : 4}>
          <FormGroup>
            <Label>
              <small className="ml-sm-2 font-weight-bold">Medic</small>
            </Label>
            <Select
              getOptionValue={option => option.id}
              getOptionLabel={option => option.value}
              onChange={handleChange}
              className="basic-single"
              classNamePrefix="select"
              placeholder=""
              isClearable
              name={`${type}Doctor`}
              options={doctors}
            />
          </FormGroup>
        </Col>
        <Col sm={withResult ? 3 : 4}>
          <FormGroup>
            <Label>
              <small className="ml-sm-2 font-weight-bold">Stare</small>
            </Label>
            <Select
              getOptionValue={option => option.id}
              getOptionLabel={option => option.value}
              onChange={handleChange}
              className="basic-single"
              classNamePrefix="select"
              placeholder=""
              isClearable
              name={`${type}Status`}
              options={stareFisa}
            />
          </FormGroup>
        </Col>
        {
          withResult && (
            <Col sm={3}>
              <FormGroup>
                <Label>
                  <small className="ml-sm-2 font-weight-bold">Rezultat</small>
                </Label>
                <Select
                  getOptionValue={option => option.id}
                  getOptionLabel={option => option.value}
                  onChange={handleChange}
                  className="basic-single"
                  classNamePrefix="select"
                  placeholder=""
                  isClearable
                  name={`${type}Result`}
                  options={rezultatOptions}
                />
              </FormGroup>
            </Col>
          )
        }
      </Row>
    </>
  );
}

export default CasefileFilterExtendedSection;
