import React, { Component } from 'react';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, CardBody, CardHeader, Spinner } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { PatientsTable, PatientFilterSimple, PatientFilterExtended } from './components/index';
import Pagination from '../../Pagination';

import './index.scss';
import { patientsOperations } from '../../../redux/ducks/patients';

// const firstElem = (filters.page - 1) * filters.size + 1;
// const lastElem = filters.page * filters.size > totalNo ? totalNo : filters.page * filters.size;

const defaultFilters = {
  page: 1,
  size: 10,
  sortBy: 'createTs',
  sortDir: 'desc',
};
class Patients extends Component {
  constructor() {
    super();

    this.setFilters = this.setFilters.bind(this);
    this.addFilterAndSearch = this.addFilterAndSearch.bind(this);
    this.selectFilterType = this.selectFilterType.bind(this);
    this.setSortFilter = this.setSortFilter.bind(this);
    this.resetFilters = this.resetFilters.bind(this);
    this.parseUrlAndSetFilters = this.parseUrlAndSetFilters.bind(this);
    this.setActiveStatus = this.setActiveStatus.bind(this);
    this.getPatients = this.getPatients.bind(this);
    this.onBrowserNav = this.onBrowserNav.bind(this);
    this.getPatientsData = this.getPatientsData.bind(this);

    this.state = {
      filters: defaultFilters,
      filterType: 'simple',
      patients: [],
      firstElem: 0,
      lastElem: 10,
      totalNo: 999,
      key: 0,
      totalPages: 0,
      loading: false,
    };
  }

  componentDidMount() {
    const { auth, navigate, } = this.props;
    if (auth) {
      const { view, } = auth;
      if (view && !view.includes('PATIENT_LIST')) {
        if (view && view.includes('ORGANIZATION_LIST')) {
          setTimeout(() => {
            navigate('/organization');
          }, 10);
        } else if (view && view.includes('CASEFILE_LIST')) {
          setTimeout(() => {
            navigate('/casefile');
          }, 10);
        } else if (view && view.includes('DOCTOR_LIST')) {
          setTimeout(() => {
            navigate('/doctor');
          }, 10);
        }
      }
    }

    window.addEventListener('popstate', this.onBrowserNav);
    setTimeout(() => {
      this.parseUrlAndSetFilters();
    }, 50);
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.onBrowserNav);
  }

  onBrowserNav() {
    this.setState({ filters: defaultFilters, key: Math.random(), }, () => {
      this.parseUrlAndSetFilters();
    });
  }

  parseUrlAndSetFilters() {
    const { searchParams, } = this.props;
    const parsedFilters = Object.fromEntries([...searchParams]);
    if (Object.keys(parsedFilters).length > 0) {
      this.setFilters(parsedFilters);
    } else {
      this.resetFilters();
    }
  }

  getPatients() {
    const { location: { pathname, }, navigate, } = this.props;
    const { filters, } = this.state;
    const formatFilters = Object.entries(filters).filter(elem => elem[1] !== '').map(elem => elem.join('=')).join('&');
    // console.log('GET PatientS: ', `${pathname}s?${formatFilters}`);
    navigate(`${pathname}?${formatFilters}`);
    this.getPatientsData(`${pathname}s?${formatFilters}`);
  }

  getPatientsData(url) {
    // console.log(url);
    const { getPatients, } = this.props;
    this.setState({ loading: true, });
    getPatients(url)
      .then((res => {
        const { totalElements, totalPages, data, } = res.data;
        const { filters, } = this.state;
        this.setState({
          patients: data,
          totalNo: totalElements,
          firstElem: (filters.page - 1) * filters.size + 1,
          lastElem: filters.page * filters.size > totalElements ? totalElements : filters.page * filters.size,
          totalPages,
          loading: false,
        });
      }))
      .catch((err => {
        console.log('err: ', err);
        this.setState({ loading: false, });
      }));
  }

  setFilters(data) {
    this.setState({ filters: data, }, () => {
      this.getPatients();
    });
  }

  addFilterAndSearch(data, pageSelect, removeKey) {
    const { filters, } = this.state;
    if (removeKey) delete filters[removeKey];
    const newFilters = {
      ...filters,
      ...data,
      ...(!pageSelect && { page: 1, }),
    };
    this.setFilters(newFilters);
  }

  setSortFilter(e) {
    let newSortDir;
    const newSortBy = e.target.id;
    const { filters: { sortBy, sortDir, }, } = this.state;
    if (e.target.id === sortBy) {
      newSortDir = sortDir === 'asc' ? 'desc' : 'asc';
    } else {
      newSortDir = 'asc';
    }
    this.addFilterAndSearch({ sortBy: newSortBy, sortDir: newSortDir, }, true);
  }

  selectFilterType() {
    const { filterType, } = this.state;
    const newType = filterType === 'simple' ? 'extended' : 'simple';
    this.setState({ filterType: newType, });
  }

  resetFilters() {
    this.setState({ filters: defaultFilters, }, () => {
      this.getPatients();
    });
  }

  setActiveStatus(data) {
    const { id, active, } = data;
    const { updatePatient, } = this.props;
    updatePatient({ id, active, }).then(res => {
      this.getPatients();
    }).catch(error => {
      console.log('err: ', error);
    });
  }

  render() {
    const { patients, firstElem, lastElem, totalNo, filters, filterType, key, totalPages, loading, } = this.state;
    const { location, auth, } = this.props;
    return (
      <>
        {
          loading && (
            <div
              className="loading-container"
            >
              <Spinner />
            </div>
          )
        }
        <div className={`container-fluid position-relative ${loading ? 'no-events loading-data' : ''}`}>
          <Card className="mt-4">
            <CardHeader className="position-relative">
              <div className="d-inline-block">
                <h3><span>Beneficiari</span></h3>
                <div className="info">
                  {`Se afiseaza ${firstElem} - ${lastElem} din ${totalNo} elemente.`}
                </div>
              </div>
              {
                auth?.create.includes('PATIENT_CREATE') && (
                  <div className="position-absolute create-organization-btn-wrapper">
                    <NavLink
                      to="/patient/new"
                      state={{ from: location, }}
                    >
                      <Button
                        color="success"
                        className="btn btn-pill p-0"
                      >
                        <FontAwesomeIcon icon={faPlus} />
                      </Button>
                    </NavLink>
                  </div>
                )
              }
            </CardHeader>
            <CardBody>
              {
                filterType === 'simple'
                  ? (
                    <PatientFilterSimple
                      key={key}
                      addFilterAndSearch={this.addFilterAndSearch}
                      setFilterType={this.selectFilterType}
                      resetFilters={this.resetFilters}
                      filters={filters}
                    />
                  )
                  : (
                    <PatientFilterExtended
                      key={key}
                      addFilterAndSearch={this.addFilterAndSearch}
                      setFilterType={this.selectFilterType}
                      resetFilters={this.resetFilters}
                      filters={filters}
                    />
                  )
              }
              <PatientsTable
                auth={auth}
                setActiveStatus={this.setActiveStatus}
                setSortFilter={this.setSortFilter}
                tableData={patients}
                filters={filters}
              />
              <Pagination
                totalPages={totalPages}
                totalNo={totalNo}
                filters={filters}
                addFilterAndSearch={this.addFilterAndSearch}
              />
            </CardBody>
          </Card>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  doctors: state.doctors.doctors,
  selectedDoctor: state.doctors.selectedDoctor,
});

const mapDispatchToProps = dispatch => ({
  getPatients: url => dispatch(patientsOperations.getPatients(url)),
  updatePatient: data => dispatch(patientsOperations.updatePatient(data)),
  // selectOrganization: data => dispatch(organizationsOperations.selectOrganization(data)),
  // updateOrganization: data => dispatch(organizationsOperations.updateOrganization(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Patients);
