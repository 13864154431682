import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import React from 'react';
import PropTypes from 'prop-types';

function NavbarItem({ entry, isActive, icon, }) {
  const className = entry.path === isActive ? 'is-active' : '';

  return (
    <NavItem className={`d-flex align-items-center navbar-item-iocn ${className}`}>
      <NavLink className="pl-1 pr-3" to={entry.path} tag={Link}>
        { icon }
        <span className="pl-1">{ entry.name }</span>
      </NavLink>
    </NavItem>

  );
}

NavbarItem.propTypes = {
  entry: PropTypes.instanceOf(Object).isRequired,
  isActive: PropTypes.string.isRequired,
};

export default NavbarItem;
