import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Button,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row, Spinner
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router';

import { connect } from 'react-redux';
import ConsultatieSiRecoltareView from './CasefileSections/ConsultatieSiRecoltareView';
import ConsultatieSiRecoltareEdit from './CasefileSections/ConsultatieSiRecoltareEdit';
import FileHeader from './CasefileSections/FileHeader';
import { casefilesOperations } from '../../../../redux/ducks/casefiles';
import InformareEdit from './CasefileSections/InformareEdit';
import InformareView from './CasefileSections/InformareView';
import TestareHPVEdit from './CasefileSections/TestareHPVEdit';
import TestareHPVView from './CasefileSections/TestareHPVView';
import TestareBabesEdit from './CasefileSections/TestareBabesEdit';
import TestareBabesView from './CasefileSections/TestareBabesView';
import ColposcopieEdit from './CasefileSections/ColposcopieEdit';
import ColposcopieView from './CasefileSections/ColposcopieView';
import BiopsieEdit from './CasefileSections/BiopsieEdit';
import BiopsieView from './CasefileSections/BiopsieView';
import TratamentEdit from './CasefileSections/TratamentEdit';
import TratamentView from './CasefileSections/TratamentView';

const fileSections = ['s1', 's2', 's3A', 's3B', 's4', 's42', 's5'];

function SingleFileEdit({ getSingleFile, updateFile, auth, }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [sections, setSections] = useState({});
  const [saving, setSaving] = useState(false);
  const [activeTab, setActiveTab] = useState('');
  const [file, setFile] = useState(null);
  const [key, setKey] = useState(0);
  const [error, setError] = useState(false);

  useEffect(() => {
    const splitUrl = location.pathname.split('/file/')[1];
    if (splitUrl !== 'new') {
      getFileData(splitUrl);
    }
  }, []);

  const calcInitialTabsState = sections => {
    setSections(sections);
    const active = [];
    Object.keys(sections).forEach(k => {
      if (sections[k] === 'EDIT') {
        active.push(k);
      }
    });
    if (active.length > 0) {
      setActiveTab(active.sort()[0]);
    } else {
      setActiveTab('s1');
    }
  };

  const getFileData = url => {
    const id = url.split('/')[0];
    getSingleFile(id)
      .then((res => {
        const { sections, } = res.data.data;
        setFile(res.data.data);
        if (res.data.data.active === false) {
          navigate(`/file/${id}/view`);
        }
        calcInitialTabsState(sections);
        setKey(Math.random());
      }))
      .catch((err => {
        console.log('err: ', err);
      }));
  };

  const selectActiveTab = e => {
    setActiveTab(e.target.id);
  };

  const goBack = () => {
    const { state, } = location;
    if (state) {
      const { state: { from: { search, pathname, }, }, } = location;
      navigate(`${pathname}${search}`);
    } else {
      navigate('/casefile');
    }
  };

  const saveFileData = (attributes, savedState) => {
    setSaving(true);
    const payload = {
      id: file.id,
      body: {
        attributes,
        ...(savedState === 'draft' && { draftSection: activeTab, }),
        ...(savedState === 'final' && { savedSection: activeTab, }),
      },
    };
    updateFile(payload)
      .then((res => {
        getFileData(file.id);
        setTimeout(() => {
          setSaving(false);
        }, 1000);
      }))
      .catch((err => {
        console.log('err: ', err);
        handleErrors(err);
        setTimeout(() => {
          setSaving(false);
        }, 1000);
      }));
  };

  const handleErrors = errors => {
    const errorMessage = errors?.data?.message || '';
    const split = errorMessage.split(':');
    const field = split[1];
    const message = split[2];
    if (field && message) {
      setError({ field, message, });
    } else {
      const split = errorMessage.split('EROARE ');
      const field2 = split[1];
      setError({ field: field2, message: 'Vă rugăm să verificați valorile introduse', });
    }
  };

  const changeTab = val => {
    const index = fileSections.indexOf(activeTab);
    const newIndex = val === '+' ? index + 1 : index - 1;
    const newActiveTab = fileSections[newIndex];
    setActiveTab(newActiveTab);
  };

  if (file == null) return <Spinner />;

  const { s1, s2, s3A, s3B, s4, s42, s5, } = sections;

  return (
    <>
      {
        saving && (
          <div
            className="loading-container"
          >
            <Spinner />
          </div>
        )
      }
      <Container id="casefiles" className={saving ? 'no-events loading-data' : ''} fluid>
        <Row className="mt-4 mb-4">
          <Col sm={2}>
            <Button onClick={goBack} color="secondary">
              <FontAwesomeIcon icon={faArrowLeft} />
              <span className="ml-2">Înapoi</span>
            </Button>
          </Col>
          <Col sm={10}>
            <FileHeader data={file} />
          </Col>
        </Row>
        <Row>
          <Col sm={2}>
            <Nav vertical>
              <NavItem className={activeTab === 's1' ? 'active' : ''}>
                <NavLink id="s1" onClick={selectActiveTab} href="#">
                  1. Informare şi consiliere
                  { s1 === 'EDIT' && <span className="ml-1 badge badge-pill badge-light">1</span>}
                </NavLink>
              </NavItem>
              <NavItem className={activeTab === 's2' ? 'active' : ''}>
                <NavLink id="s2" onClick={selectActiveTab} href="#">
                  2. Consultație şi recoltare
                  { sections.s2 === 'EDIT' && <span className="ml-1 badge badge-pill badge-light">2</span>}
                </NavLink>
              </NavItem>
              <NavItem className={activeTab === 's3A' ? 'active' : ''}>
                <NavLink id="s3A" onClick={selectActiveTab} href="#">
                  3A. Testare HPV
                  { sections.s3A === 'EDIT' && <span className="ml-1 badge badge-pill badge-light">3</span>}
                </NavLink>
              </NavItem>
              <NavItem className={activeTab === 's3B' ? 'active' : ''}>
                <NavLink id="s3B" onClick={selectActiveTab} href="#">
                  3B. Testare Babeş-Papanicolau
                  { sections.s3B === 'EDIT' && <span className="ml-1 badge badge-pill badge-light">3</span>}
                </NavLink>
              </NavItem>
              <div className="nav-separator" />
              <NavItem className={activeTab === 's4' ? 'active' : ''}>
                <NavLink id="s4" onClick={selectActiveTab}>
                  Colposcopie
                </NavLink>
              </NavItem>
              <NavItem className={activeTab === 's42' ? 'active' : ''}>
                <NavLink id="s42" onClick={selectActiveTab}> Biopsie </NavLink>
              </NavItem>
              <NavItem className={activeTab === 's5' ? 'active' : ''}>
                <NavLink id="s5" onClick={selectActiveTab}> Tratament </NavLink>
              </NavItem>
            </Nav>
          </Col>
          <Col sm={10}>
            {
              error && error.field === '_' && (
                <div className="text-danger">{error?.message || 'Vă rugăm să verificați valorile introduse'}</div>
              )
            }
            { activeTab === 's1' && (
              s1 === 'EDIT'
                ? (
                  <InformareEdit
                    error={error}
                    auth={auth}
                    key={key}
                    changeTab={changeTab}
                    saveData={saveFileData}
                    data={file}
                    activeTab={activeTab}
                  />
                )
                : (
                  <InformareView
                    changeTab={changeTab}
                    data={file}
                    activeTab={activeTab}
                  />
                )
            ) }
            { activeTab === 's2' && (
              s2 === 'EDIT'
                ? (
                  <ConsultatieSiRecoltareEdit
                    error={error}
                    auth={auth}
                    key={key}
                    changeTab={changeTab}
                    saveData={saveFileData}
                    data={file}
                    activeTab={activeTab}
                  />
                )
                : (
                  <ConsultatieSiRecoltareView
                    changeTab={changeTab}
                    data={file}
                    activeTab={activeTab}
                  />
                )
            ) }
            { activeTab === 's3A' && (
              s3A === 'EDIT'
                ? (
                  <TestareHPVEdit
                    error={error}
                    auth={auth}
                    key={key}
                    changeTab={changeTab}
                    saveData={saveFileData}
                    data={file}
                    activeTab={activeTab}
                  />
                )
                : (
                  <TestareHPVView
                    changeTab={changeTab}
                    data={file}
                    activeTab={activeTab}
                  />
                )
            ) }
            { activeTab === 's3B' && (
              s3B === 'EDIT'
                ? (
                  <TestareBabesEdit
                    error={error}
                    auth={auth}
                    key={key}
                    changeTab={changeTab}
                    saveData={saveFileData}
                    data={file}
                    activeTab={activeTab}
                  />
                )
                : (
                  <TestareBabesView
                    changeTab={changeTab}
                    data={file}
                    activeTab={activeTab}
                  />
                )
            ) }
            { activeTab === 's4' && (
              s4 === 'EDIT'
                ? (
                  <ColposcopieEdit
                    error={error}
                    auth={auth}
                    key={key}
                    changeTab={changeTab}
                    saveData={saveFileData}
                    data={file}
                    activeTab={activeTab}
                  />
                )
                : (
                  <ColposcopieView
                    changeTab={changeTab}
                    data={file}
                    activeTab={activeTab}
                  />
                )
            ) }
            { activeTab === 's42' && (
              s42 === 'EDIT'
                ? (
                  <BiopsieEdit
                    error={error}
                    key={key}
                    changeTab={changeTab}
                    saveData={saveFileData}
                    data={file}
                    activeTab={activeTab}
                  />
                )
                : (
                  <BiopsieView
                    error={error}
                    changeTab={changeTab}
                    data={file}
                    activeTab={activeTab}
                  />
                )
            ) }
            { activeTab === 's5' && (
              s5 === 'EDIT'
                ? (
                  <TratamentEdit
                    key={key}
                    changeTab={changeTab}
                    saveData={saveFileData}
                    data={file}
                    activeTab={activeTab}
                  />
                )
                : (
                  <TratamentView
                    changeTab={changeTab}
                    data={file}
                    activeTab={activeTab}
                  />
                )
            ) }
          </Col>
        </Row>
      </Container>
    </>
  );
}

const mapStateToProps = state => ({
  auth: state.auth,
  casefiles: state.casefiles.casefiles,
  selectedCasefile: state.casefiles.selectedCasefile,
});

const mapDispatchToProps = dispatch => ({
  getSingleFile: id => dispatch(casefilesOperations.getSingleFile(id)),
  selectCasefile: data => dispatch(casefilesOperations.selectCasefile(data)),
  updateFile: data => dispatch(casefilesOperations.updateFile(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SingleFileEdit);
