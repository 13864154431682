import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortAsc, faSortDesc } from '@fortawesome/free-solid-svg-icons';
import OrganizationTableRow from './OrganizationTableRow';
import OrganizationActiveModal from './OrganizationActiveModal';

function OrganizationTable({ tableData = [], setSortFilter, filters, setActiveStatus, auth, }) {
  const [modal, setModal] = useState(false);
  const [selected, setSelected] = useState(null);

  const handleActiveModal = data => {
    setModal(modal !== true);
    setSelected(data);
  };

  const domOrganizations = tableData.map(elem => (
    <OrganizationTableRow
      canActivate={auth?.activate.includes('ORGANIZATION_ACTIVATE') || false}
      canEdit={auth?.update.includes('ORGANIZATION_UPDATE') || false}
      handleActiveModal={handleActiveModal}
      key={elem.id}
      data={elem}
    />
  ));

  const { sortBy, sortDir, } = filters;

  const calcIcon = field => {
    if (field !== sortBy) {
      return faSort;
    }
    return sortDir === 'asc' ? faSortAsc : faSortDesc;
  };

  return (
    <>
      { modal && (
        <OrganizationActiveModal
          selected={selected}
          close={handleActiveModal}
          setActive={setActiveStatus}
        />
      )}
      <div id="entities" className="table-responsive-xl mt-5">
        <table aria-describedby="page-heading" className="table">
          <thead>
            <tr>
              <th onClick={setSortFilter} role="button" id="code" scope="col">
                <span className="events-none">Cod</span>
                <FontAwesomeIcon
                  className="ml-1 events-none"
                  icon={calcIcon('code')}
                />
              </th>
              <th onClick={setSortFilter} role="button" id="label" scope="col">
                <span className="events-none">Denumire / Nume furnizor</span>
                <FontAwesomeIcon
                  className="ml-1"
                  icon={calcIcon('label')}
                />
              </th>
              <th onClick={setSortFilter} role="button" id="description" scope="col">
                <span className="events-none">Descriere</span>
                <FontAwesomeIcon
                  className="ml-1"
                  icon={calcIcon('description')}
                />
              </th>
              <th onClick={setSortFilter} role="button" id="orgTypeLabel" scope="col">
                <span className="events-none">Tip furnizor</span>
                <FontAwesomeIcon
                  className="ml-1"
                  icon={calcIcon('orgTypeLabel')}
                />
              </th>
              <th onClick={setSortFilter} role="button" id="services" scope="col">
                <span className="events-none">Servicii</span>
                <FontAwesomeIcon
                  className="ml-1"
                  icon={calcIcon('services')}
                />
              </th>
              <th onClick={setSortFilter} role="button" id="active" scope="col">
                <span className="events-none">Stare</span>
                <FontAwesomeIcon
                  className="ml-1"
                  icon={calcIcon('active')}
                />
              </th>
              <th scope="col" />
            </tr>
          </thead>
          <tbody>
            { domOrganizations}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default OrganizationTable;
