const LOGIN = 'LOGIN';
const LOGIN_COMPLETED = 'LOGIN_COMPLETED';
const LOGIN_FAILED = 'LOGIN_FAILED';
const LOGOUT = 'LOGOUT';
const LOGOUT_COMPLETED = 'LOGOUT_COMPLETED';
const LOGOUT_FAILED = 'LOGOUT_FAILED';
const REGISTER = 'REGISTER';
const REGISTER_COMPLETED = 'REGISTER_COMPLETED';
const REGISTER_FAILED = 'REGISTER_FAILED';
const REGISTER_TECH = 'REGISTER_TECH';
const REGISTER_TECH_COMPLETED = 'REGISTER_TECH_COMPLETED';
const REGISTER_TECH_FAILED = 'REGISTER_TECH_FAILED';
const REQUEST_PASSWORD_RESET = 'REQUEST_PASSWORD_RESET';
const REQUEST_PASSWORD_RESET_COMPLETED = 'REQUEST_PASSWORD_RESET_COMPLETED';
const REQUEST_PASSWORD_RESET_FAILED = 'REQUEST_PASSWORD_RESET_FAILED';
const ACTIVATE_ACCOUNT = 'ACTIVATE_ACCOUNT';
const ACTIVATE_ACCOUNT_COMPLETED = 'ACTIVATE_ACCOUNT_COMPLETED';
const ACTIVATE_ACCOUNT_FAILED = 'ACTIVATE_ACCOUNT_FAILED';

const SWITCH_COMPANY = 'SWITCH_COMPANY';
const SWITCH_COMPANY_COMPLETED = 'SWITCH_COMPANY_COMPLETED';
const SWITCH_COMPANY_FAILED = 'SWITCH_COMPANY_FAILED';

const VERIFY_COMPLETE_ACCOUNT = 'VERIFY_COMPLETE_ACCOUNT';
const VERIFY_COMPLETE_ACCOUNT_COMPLETED = 'VERIFY_COMPLETE_ACCOUNT_COMPLETED';
const VERIFY_COMPLETE_ACCOUNT_FAILED = 'VERIFY_COMPLETE_ACCOUNT_FAILED';

const COMPLETE_ACCOUNT = 'COMPLETE_ACCOUNT';
const COMPLETE_ACCOUNT_COMPLETED = 'COMPLETE_ACCOUNT_COMPLETED';
const COMPLETE_ACCOUNT_FAILED = 'COMPLETE_ACCOUNT_FAILED';

const CHECK_RESET_PASSWORD_TOKEN = 'CHECK_RESET_PASSWORD_TOKEN';
const CHECK_RESET_PASSWORD_TOKEN_COMPLETED = 'CHECK_RESET_PASSWORD_TOKEN_COMPLETED';
const CHECK_RESET_PASSWORD_TOKEN_FAILED = 'CHECK_RESET_PASSWORD_TOKEN_FAILED';
const SET_NEW_PASSWORD = 'SET_NEW_PASSWORD';
const SET_NEW_PASSWORD_COMPLETED = 'SET_NEW_PASSWORD_COMPLETED';
const SET_NEW_PASSWORD_FAILED = 'SET_NEW_PASSWORD_FAILED';
const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
const CHANGE_PASSWORD_COMPLETED = 'CHANGE_PASSWORD_COMPLETED';
const CHANGE_PASSWORD_FAILED = 'CHANGE_PASSWORD_FAILED';
const CHANGE_USER_PROFILE = 'CHANGE_USER_PROFILE';
const CHANGE_USER_PROFILE_COMPLETED = 'CHANGE_USER_PROFILE_COMPLETED';
const CHANGE_USER_PROFILE_FAILED = 'CHANGE_USER_PROFILE_FAILED';
const GET_USER_SETTINGS = 'GET_USER_SETTINGS';
const GET_USER_SETTINGS_COMPLETED = 'GET_USER_SETTINGS_COMPLETED';
const GET_USER_SETTINGS_FAILED = 'GET_USER_SETTINGS_FAILED';
const CHECK_TOKEN = 'CHECK_TOKEN';
const REFRESH_USER_TOKEN = 'REFRESH_USER_TOKEN';
const REFRESH_USER_TOKEN_COMPLETED = 'REFRESH_USER_TOKEN_COMPLETED';
const REFRESH_USER_TOKEN_FAILED = 'REFRESH_USER_TOKEN_FAILED';

export default {
  LOGIN,
  LOGIN_COMPLETED,
  LOGIN_FAILED,
  LOGOUT,
  LOGOUT_COMPLETED,
  LOGOUT_FAILED,
  REGISTER,
  REGISTER_COMPLETED,
  REGISTER_FAILED,
  REGISTER_TECH,
  REGISTER_TECH_COMPLETED,
  REGISTER_TECH_FAILED,
  REQUEST_PASSWORD_RESET,
  REQUEST_PASSWORD_RESET_COMPLETED,
  REQUEST_PASSWORD_RESET_FAILED,
  ACTIVATE_ACCOUNT,
  ACTIVATE_ACCOUNT_COMPLETED,
  ACTIVATE_ACCOUNT_FAILED,

  SWITCH_COMPANY,
  SWITCH_COMPANY_COMPLETED,
  SWITCH_COMPANY_FAILED,

  VERIFY_COMPLETE_ACCOUNT,
  VERIFY_COMPLETE_ACCOUNT_COMPLETED,
  VERIFY_COMPLETE_ACCOUNT_FAILED,
  COMPLETE_ACCOUNT,
  COMPLETE_ACCOUNT_COMPLETED,
  COMPLETE_ACCOUNT_FAILED,

  CHECK_RESET_PASSWORD_TOKEN,
  CHECK_RESET_PASSWORD_TOKEN_COMPLETED,
  CHECK_RESET_PASSWORD_TOKEN_FAILED,
  SET_NEW_PASSWORD,
  SET_NEW_PASSWORD_COMPLETED,
  SET_NEW_PASSWORD_FAILED,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_COMPLETED,
  CHANGE_PASSWORD_FAILED,
  CHANGE_USER_PROFILE,
  CHANGE_USER_PROFILE_COMPLETED,
  CHANGE_USER_PROFILE_FAILED,
  GET_USER_SETTINGS,
  GET_USER_SETTINGS_COMPLETED,
  GET_USER_SETTINGS_FAILED,
  CHECK_TOKEN,
  REFRESH_USER_TOKEN,
  REFRESH_USER_TOKEN_COMPLETED,
  REFRESH_USER_TOKEN_FAILED,
};
