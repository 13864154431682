import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup, Card, CardBody, CardHeader, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import Select from 'react-select';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import InfectiiCheckboxes from '../Checkboxes/InfectiiCheckboxes';
import AnomaliiCelScuamoaseCheckboxes from '../Checkboxes/AnomaliiCelScuamoaseCheckboxes';
import ModifNonNeoplaziceCheckboxes from '../Checkboxes/ModifNonNeoplaziceCheckboxes';

const tipLeziuneOptions = [
  { id: '4B9B923D-0EB7-EB11-8C22-000C2901B5F1', value: 'Deficiență de recoltare', },
  { id: '4D9B923D-0EB7-EB11-8C22-000C2901B5F1', value: 'Conform protocolului în cazul rezultatului pozitiv', },
  { id: '4C9B923D-0EB7-EB11-8C22-000C2901B5F1', value: 'După tratament antiinfecțios', }
];

const optiuniAGC = [
  { id: '349B923D-0EB7-EB11-8C22-000C2901B5F1', value: 'endocervicale în favoarea neoplaziei', },
  { id: '359B923D-0EB7-EB11-8C22-000C2901B5F1', value: 'endocervicale NOS', },
  { id: '399B923D-0EB7-EB11-8C22-000C2901B5F1', value: 'endometriale NOS', },
  { id: '369B923D-0EB7-EB11-8C22-000C2901B5F1', value: 'glandulare în favoarea neoplaziei', },
  { id: '379B923D-0EB7-EB11-8C22-000C2901B5F1', value: 'NOS', }
];

const optiuniAdenocarcinom = [
  { id: '3D9B923D-0EB7-EB11-8C22-000C2901B5F1', value: 'adenocarcinom', },
  { id: '3A9B923D-0EB7-EB11-8C22-000C2901B5F1', value: 'endocervical', },
  { id: '3C9B923D-0EB7-EB11-8C22-000C2901B5F1', value: 'endometrial', },
  { id: '3F9B923D-0EB7-EB11-8C22-000C2901B5F1', value: 'extrauterin', },
  { id: '409B923D-0EB7-EB11-8C22-000C2901B5F1', value: 'in situ', },
  { id: '3B9B923D-0EB7-EB11-8C22-000C2901B5F1', value: 'NOS', }
];

const rezultatFinalOptions = [
  { id: '299B923D-0EB7-EB11-8C22-000C2901B5F1', value: 'Negativ pentru leziuni intraepiteliale sau maligne', code: 'rez_final_negativ', },
  { id: '5189496D-FA15-EC11-8C2A-000C2901B5F1', value: 'Nesatisfăcător', code: 'rez_final_nesatisfacator', },
  { id: '2A9B923D-0EB7-EB11-8C22-000C2901B5F1', value: 'Pozitiv', code: 'rez_final_pozitiv', }];

const celuleEndoData = [
  { id: '279B923D-0EB7-EB11-8C22-000C2901B5F1', value: 'Absente', code: 'celule_endocervicale_absente', },
  { id: '269B923D-0EB7-EB11-8C22-000C2901B5F1', value: 'Prezente', code: 'celule_endocervicale_prezente', }];

const frotiuData = [
  { id: '249B923D-0EB7-EB11-8C22-000C2901B5F1', value: 'Nesatisfăcător', code: 'frotiu_nesatisfacator', },
  { id: '239B923D-0EB7-EB11-8C22-000C2901B5F1', value: 'Satisfăcător', code: 'frotiu_satisfacator', }];

const optiuniFrotiu = [
  { id: '329B923D-0EB7-EB11-8C22-000C2901B5F1', value: 'alte substanțe străine', code: 'frotiu_alte_substante', },
  { id: '309B923D-0EB7-EB11-8C22-000C2901B5F1', value: 'exces de hematii', code: 'frotiu_exces_hematii', },
  { id: '319B923D-0EB7-EB11-8C22-000C2901B5F1', value: 'exces de leucocite', code: 'frotiu_exces_leucocite', }];

const lamaData = [
  { id: '2D9B923D-0EB7-EB11-8C22-000C2901B5F1', value: 'cu etalare defectuoasă', code: 'lama_etalare_defectuoasa', },
  { id: '2E9B923D-0EB7-EB11-8C22-000C2901B5F1', value: 'cu fixare deficitară', code: 'lama_fixare_deficitara', },
  { id: '2C9B923D-0EB7-EB11-8C22-000C2901B5F1', value: 'neidentificată', code: 'lama_neidentificata', }];

const daNu = [
  { id: '9A6F282D-2968-45DB-84CE-3DC15A1389A7', value: 'DA', },
  { id: '016F2E5F-88DC-4636-B60F-6D946D829A46', value: 'NU', }
];

const checkboxInputsInfectii = [
  'trichomonasSec32FS1',
  'candidaSec32FS1',
  'vaginozaSec33FS1',
  'actinomycesSec33FS1',
  'herpesSec33FS1',
  'alteleMicroSec33FS1'
];

const checkboxInputsAnomaliiScuamoase = [
  'ascUsSec33FS1', // ASC - US
  'ascHSec33FS1', // ASC - H
  'lsilSec33FS1', // LSIL
  'lsilHpvSec33FS1', // LSIL cu atipii HPV
  'hsilSec33FS1', // HSIL
  'hsilIInvazieSec33FS1', // HSIL cu suspiciune de invazie
  'carcinomScSec33FS1' // carcinom scuamos
];

const checkboxInputsNonNeoplazice = [
  'inflamatoriiSec33FS1',
  'radioChimioSec33FS1',
  'purtatoareSteriletSec33FS1',
  'celEndomFemSec33FS1',
  'ceGlandHisteroSec33FS1',
  'atrofieSec33FS1',
  'alteleModifNonSec33FS1'
];

function TestareBabesEdit({ data, changeTab, saveData, auth, error, }) {
  const [inputs, setInputs] = useState({});
  const [laboratorSec3BFS1, setLaboratorSec3BFS1] = useState('');
  const [mediciAnatomoPato, setMediciAnatomoPato] = useState([]);
  const [mediciAnatomoPatoCertif, setMediciAnatomoPatoCertif] = useState([]);
  const [biologi, setBiolog] = useState([]);
  const [mediciLab, setMediciLab] = useState([]);
  const [flacon, setFlacon] = useState([]);

  const checkboxInputs = ['alteNeoplaziiSec33FS1', 'repetareSec34FS1', 'biopsieSec34FS1',
    'colposcopieSec34FS1', 'chiuretajCervSec34FS1', 'chiuretajMetrSec34FS1',
    'testHpvSec34FS1', 'repRutinaNegSec34FS1'
  ];

  useEffect(() => {
    const inputs = data.attributes;
    setInputs(inputs);
    setLaboratorSec3BFS1(data.laboratorSec3BFS1);
    setMediciAnatomoPatoCertif(data.medicAnatoPatoCertificareSec3BFS1);
    setMediciAnatomoPato(data.medicAnatoPatoSec3BFS1);
    setBiolog(data.medicBiolog3BFS1);
    setMediciLab(data.medicMedicinaLab3BFS1);
    setFlacon(data.flaconNumberAuto);

    if (Object.keys(inputs).length === 0) return;
    checkboxInputs.forEach(elem => {
      inputs[elem] = { value: inputs?.[elem].value.toString() || 'false', };
    });

    const selectedMedic1 = (data.medicAnatoPatoCertificareSec3BFS1 || []).find(elem => auth.doctorId === elem.id);
    if (selectedMedic1) {
      inputs.medicAnatoPatoCertificareSec3BFS1 = selectedMedic1;
    }
  }, []);

  const {
    agcListSec33FS1,
    medicAnatoPatoSec3BFS1,
    medicMedicinaLab3BFS1,
    medicBiolog3BFS1,
    medicAnatoPatoCertificareSec3BFS1,
    // numarFlaconSauLamaClone,
    nrFlaconSec3BFS1,
    dataPrimireFlaconSec3BFS1,
    dataInterSec3BFS1,
    calitateFrotiuSec32FS1,
    microorganismeSec33FS1,
    modificariCelSec33FS1,
    anomaliiScSec33FS1,
    anomaliiCelGlandSec33FS1,
    alteNeoplaziiSec33FS1,
    rezFinalSec44FS1,
    repetareSec34FS1,
    biopsieSec34FS1,
    colposcopieSec34FS1,
    chiuretajCervSec34FS1,
    chiuretajMetrSec34FS1,
    testHpvSec34FS1,
    repRutinaNegSec34FS1,
    obs1Sec34FS1,
    dataElibRezSec34FS1,
    lamaSec32FS1 = [],
    frotiuSec32FS1 = [],
    alteleLamaSec32FS1,
    alteleFrotiu32FS1,
    celuleCervSec32FS1,
    descriereAlteNeoplazii,
    tipLeziuneSec44FS1,
    adenocarcinomListSec33FS1,
    repetareListSec34FS1,
  } = inputs;

  const handleChange = e => {
    const curentVal = { ...inputs[e.target.name], value: e.target.value, };
    setInputs(prevState => ({ ...prevState, [e.target.name]: curentVal, }));
  };

  const handleSelect = (selected, details) => {
    const curentVal = {
      ...inputs[details.name],
      id: selected?.id || '',
      value: selected?.value || '',
    };
    setInputs(prevState => ({ ...prevState, [details.name]: curentVal, }));
    if (details.name === 'rezFinalSec44FS1') {
      if (selected !== null && selected.value === 'Pozitiv') {
        setInputs(prevState => ({ ...prevState, testHpvSec34FS1: { value: 'true', }, }));
      } else {
        setInputs(prevState => ({ ...prevState, testHpvSec34FS1: { value: 'false', }, }));
      }
    }
  };

  const selectDate = (val, name) => {
    const dataSubmit = moment(val).utc().format();
    const curentVal = { ...inputs[name], value: dataSubmit, };
    setInputs(prevState => ({ ...prevState, [name]: curentVal, }));
  };

  const handleCheck = e => {
    const newVal = e.target.value === 'false' ? 'true' : 'false';
    const curentVal = { ...inputs[e.target.name], value: newVal, };
    setInputs(prevState => ({ ...prevState, [e.target.name]: curentVal, }));
  };

  const save = e => {
    const saveStatus = e.target.id;
    checkboxInputs.forEach(elem => {
      inputs[elem].value = inputs[elem].value === 'true';
    });

    // infectii
    if (microorganismeSec33FS1?.value === 'DA') {
      checkboxInputsInfectii.forEach(elem => {
        if (typeof inputs[elem] !== 'undefined') {
          inputs[elem].value = inputs[elem].value.toString() === 'true';
        }
      });
    }

    // Modificări celulare non-neoplazice
    if (modificariCelSec33FS1?.value === 'DA') {
      checkboxInputsNonNeoplazice.forEach(elem => {
        if (typeof inputs[elem] !== 'undefined') {
          inputs[elem].value = inputs[elem].value.toString() === 'true';
        }
      });
    }

    // Anomalii ale celulelor epiteliale scuamoase
    if (anomaliiScSec33FS1?.value === 'DA') {
      checkboxInputsAnomaliiScuamoase.forEach(elem => {
        if (typeof inputs[elem] !== 'undefined') {
          inputs[elem].value = inputs[elem].value.toString() === 'true';
        }
      });
    }

    // console.log('payload: ', inputs);
    saveData(inputs, saveStatus);
  };

  const handleBtnClick = e => {
    const values = e.target.id.split('-');
    const name = values[0];
    const val = values[1];
    const selectedVal = daNu.find(a => a.value === val);
    // const curentVal = { ...inputs[name], [name]: selectedVal, };
    setInputs(prevState => ({ ...prevState, [name]: selectedVal, }));
  };

  const handleMultipleSelect = (value, name) => {
    setInputs(prevState => ({ ...prevState, [name]: { value, }, }));
  };

  const setTab = e => { changeTab(e.target.id); };

  const calcShowSaveBtn = () => {
    if (typeof rezFinalSec44FS1 === 'undefined'
      || rezFinalSec44FS1?.value === ''
      || rezFinalSec44FS1?.value === 'Nesatisfăcător') {
      return false;
    }
    if (typeof medicAnatoPatoCertificareSec3BFS1 === 'undefined' || medicAnatoPatoCertificareSec3BFS1?.value === '') {
      return false;
    }
    if (typeof dataElibRezSec34FS1 === 'undefined' || dataElibRezSec34FS1?.value === '') {
      return false;
    }
    if (typeof dataInterSec3BFS1 === 'undefined' || dataInterSec3BFS1?.value === '') {
      return false;
    }
    return true;
  };

  const showBtns = calcShowSaveBtn();

  return (
    <Card>
      <CardHeader>
        <h3 className="mb-0">
          <div className="d-flex">
            <div>
              <span className="mr-3">3B. Testare Babeş-Papanicolau</span>
            </div>
            {showBtns === true && (
              <div>
                <Button id="draft" size="sm" color="primary" className="btn-rounded" onClick={save}>Draft</Button>
                {
                  rezFinalSec44FS1?.value !== 'Nesatisfăcător' && (
                    <Button id="final" size="sm" color="success" className="ml-2 btn-rounded" onClick={save}>Finalizeaza</Button>
                  )
                }
              </div>
            )}
            <div className="ml-auto">
              <Button id="-" size="sm" color="secondary" className="ml-auto btn-pill" onClick={setTab}>{'<'}</Button>
              <Button id="+" size="sm" color="primary" className="ml-1 btn-pill" onClick={setTab}>{'>'}</Button>
            </div>
          </div>
        </h3>
      </CardHeader>
      <CardBody className="pb-5">
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>Laborator BPN</Label>
              <Input
                required
                type="text"
                name="laboratorSec3BFS1"
                disabled
                value={laboratorSec3BFS1?.value || ''}
              />
            </FormGroup>
          </Col>
        </Row>
        <Col
          className="underline-hover form-group dont-display ng-untouched ng-pristine"
          style={{ backgroundColor: 'rgba(233, 236, 239, 0.3)', }}
        >
          <Label>
            Personalul medical care efectuează citirea
          </Label>
        </Col>

        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>Medicul de specialitate anatomie-patologică</Label>
              <Select
                value={medicAnatoPatoSec3BFS1 || ''}
                onChange={handleSelect}
                getOptionValue={option => option.id}
                getOptionLabel={option => option.value}
                isClearable
                className="basic-single"
                classNamePrefix="select"
                placeholder=""
                name="medicAnatoPatoSec3BFS1"
                options={mediciAnatomoPato}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label>Medicul de specialitate medicina de laborator</Label>
              <Select
                value={medicMedicinaLab3BFS1 || ''}
                onChange={handleSelect}
                getOptionValue={option => option.id}
                getOptionLabel={option => option.value}
                isClearable
                className="basic-single"
                classNamePrefix="select"
                placeholder=""
                name="medicMedicinaLab3BFS1"
                options={mediciLab}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>Medicul de specialitate/Biolog</Label>
              <Select
                value={medicBiolog3BFS1 || ''}
                onChange={handleSelect}
                getOptionValue={option => option.id}
                getOptionLabel={option => option.value}
                className="basic-single"
                classNamePrefix="select"
                placeholder=""
                name="medicBiolog3BFS1"
                options={biologi}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label>Personal de specialitate care certifică rezultatele *</Label>
              <Select
                value={medicAnatoPatoCertificareSec3BFS1 || ''}
                onChange={handleSelect}
                getOptionValue={option => option.id}
                getOptionLabel={option => option.value}
                isClearable
                className={(typeof medicAnatoPatoCertificareSec3BFS1 !== 'undefined' && medicAnatoPatoCertificareSec3BFS1.value !== '') ? '' : 'invalid'}
                classNamePrefix="select"
                placeholder=""
                name="medicAnatoPatoCertificareSec3BFS1"
                options={mediciAnatomoPatoCertif}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>Număr flacon/lamă </Label>
              <Input
                type="text"
                name="flacon"
                disabled
                value={flacon || ''}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label>Număr flacon/lamă intern</Label>
              <Input
                type="text"
                name="nrFlaconSec3BFS1"
                onChange={handleChange}
                value={nrFlaconSec3BFS1?.value || ''}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>Data primirii flaconului/lamei </Label>
              <FormGroup row className="align-items-center">
                <Col sm={10} className="pr-0">
                  <Input
                    value={dataPrimireFlaconSec3BFS1 ? moment(dataPrimireFlaconSec3BFS1?.value).format('DD-MM-YYYY') : ''}
                    type="text"
                    className={`form-control bg-white ${error && error.field === 'dataPrimireFlaconSec3BFS1' ? 'invalid' : ''}`}
                    id=""
                    placeholder=""
                    onChange={() => {}}
                  />
                </Col>
                <Col sm={2} className="pl-0">
                  <DatePicker
                    minDate={new Date('01-01-2021')}
                    maxDate={new Date()}
                    name={dataPrimireFlaconSec3BFS1}
                    locale="ro"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    selected={moment(dataPrimireFlaconSec3BFS1?.value).toDate()}
                    onChange={val => selectDate(val, 'dataPrimireFlaconSec3BFS1')}
                    customInput={<Button className="form-control"><FontAwesomeIcon icon={faCalendarAlt} /></Button>}
                  />
                </Col>
                { error && error.field === 'dataPrimireFlaconSec3BFS1' && <p className="error text-danger mt-1 mb-1 pl-3">{error.message}</p>}
              </FormGroup>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label>Data interpretării *</Label>
              <FormGroup row className="align-items-center">
                <Col sm={10} className="pr-0">
                  <Input
                    value={dataInterSec3BFS1 ? moment(dataInterSec3BFS1?.value).format('DD-MM-YYYY') : ''}
                    type="text"
                    className={`form-control bg-white ${error && error.field === 'dataInterSec3BFS1' ? 'invalid' : ''} ${dataInterSec3BFS1?.value === '' ? 'invalid' : ''} ${typeof dataInterSec3BFS1 === 'undefined' ? 'invalid' : ''}`}
                    onChange={() => {}}
                  />
                </Col>
                <Col sm={2} className="pl-0">
                  <DatePicker
                    minDate={new Date('01-01-2021')}
                    maxDate={new Date()}
                    name={dataInterSec3BFS1}
                    locale="ro"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    selected={moment(dataInterSec3BFS1?.value).toDate()}
                    onChange={val => selectDate(val, 'dataInterSec3BFS1')}
                    customInput={<Button className="form-control"><FontAwesomeIcon icon={faCalendarAlt} /></Button>}
                  />
                </Col>
                { error && error.field === 'dataInterSec3BFS1' && <p className="error text-danger mt-1 mb-1 pl-3">{error.message}</p>}
              </FormGroup>
            </FormGroup>
          </Col>
        </Row>

        <Col
          className="underline-hover form-group dont-display ng-untouched ng-pristine"
          style={{ backgroundColor: 'rgba(233, 236, 239, 0.3)', }}
        >
          <Label>
            Datele specifice calității frotiului
          </Label>
        </Col>

        <Row>
          <Col>
            <FormGroup>
              <Label>Calitate frotiu pentru evaluarea celulelor endocervicale/metaplaziate </Label>
              <Select
                value={calitateFrotiuSec32FS1 || ''}
                onChange={handleSelect}
                getOptionValue={option => option.id}
                getOptionLabel={option => option.value}
                isClearable
                className="basic-single"
                classNamePrefix="select"
                placeholder=""
                name="calitateFrotiuSec32FS1"
                options={frotiuData}
              />
            </FormGroup>
          </Col>
        </Row>

        { calitateFrotiuSec32FS1?.value === 'Satisfăcător' && (
          <Row>
            <Col>
              <FormGroup>
                <Label>Celule endocervicale</Label>
                <Select
                  value={celuleCervSec32FS1 || ''}
                  onChange={handleSelect}
                  getOptionValue={option => option.id}
                  getOptionLabel={option => option.value}
                  isClearable
                  className="basic-single"
                  classNamePrefix="select"
                  placeholder=""
                  name="celuleCervSec32FS1"
                  options={celuleEndoData}
                />
              </FormGroup>
            </Col>
          </Row>
        )}

        { calitateFrotiuSec32FS1?.value === 'Nesatisfăcător' && (
          <Row>
            <Col sm={12}>
              <FormGroup>
                <Label>Lamă</Label>
                <Select
                  isMulti
                  isClearable
                  getOptionValue={option => option.id}
                  getOptionLabel={option => option.value}
                  value={lamaSec32FS1?.value || []}
                  onChange={val => { handleMultipleSelect(val, 'lamaSec32FS1'); }}
                  className="basic-single"
                  classNamePrefix="select"
                  placeholder=""
                  name="lamaSec32FS1"
                  options={lamaData}
                />

              </FormGroup>
            </Col>
            <Col sm={12}>
              <FormGroup>
                <Label>Altele</Label>

                <Input
                  type="textarea"
                  rows={3}
                  name="alteleLamaSec32FS1"
                  onChange={handleChange}
                  value={alteleLamaSec32FS1?.value || ''}
                />

              </FormGroup>
            </Col>
            <Col sm={12}>
              <FormGroup>
                <Label>Calitate frotiu pentru evaluarea celulelor endocervicale/metaplaziate </Label>
                <Select
                  isMulti
                  isClearable
                  getOptionValue={option => option.id}
                  getOptionLabel={option => option.value}
                  value={frotiuSec32FS1?.value || []}
                  onChange={val => { handleMultipleSelect(val, 'frotiuSec32FS1'); }}
                  className="basic-single"
                  classNamePrefix="select"
                  placeholder=""
                  name="frotiuSec32FS1"
                  options={optiuniFrotiu}
                />
              </FormGroup>
            </Col>
            <Col sm={12}>
              <FormGroup>
                <Label>Altele</Label>
                <Input
                  type="textarea"
                  rows={3}
                  name="alteleFrotiu32FS1"
                  onChange={handleChange}
                  value={alteleFrotiu32FS1?.value || ''}
                />
              </FormGroup>

            </Col>
          </Row>
        )}

        <Col
          className="underline-hover form-group dont-display ng-untouched ng-pristine"
          style={{ backgroundColor: 'rgba(233, 236, 239, 0.3)', }}
        >
          <Label>
            Descrierea frotiului
          </Label>
        </Col>

        <Row>
          <Col md={12}>
            <FormGroup>
              <Label> Infecții </Label>
              <div>
                <ButtonGroup size="">
                  <Button
                    onClick={handleBtnClick}
                    id="microorganismeSec33FS1-DA"
                    color="primary"
                    outline={microorganismeSec33FS1?.value !== 'DA'}
                  >
                    DA
                  </Button>
                  <Button
                    id="microorganismeSec33FS1-NU"
                    onClick={handleBtnClick}
                    color="primary"
                    outline={microorganismeSec33FS1?.value !== 'NU'}
                  >
                    NU
                  </Button>
                </ButtonGroup>
                {
                  microorganismeSec33FS1?.value === 'DA' && (
                    <InfectiiCheckboxes
                      handleChange={handleChange}
                      setCheckboxValue={handleCheck}
                      data={inputs}
                      editable
                    />
                  )
                }
              </div>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <FormGroup>
              <Label> Modificări celulare non-neoplazice </Label>
              <div>
                <ButtonGroup size="">
                  <Button
                    onClick={handleBtnClick}
                    id="modificariCelSec33FS1-DA"
                    color="primary"
                    outline={modificariCelSec33FS1?.value !== 'DA'}
                  >
                    DA
                  </Button>
                  <Button
                    id="modificariCelSec33FS1-NU"
                    onClick={handleBtnClick}
                    color="primary"
                    outline={modificariCelSec33FS1?.value !== 'NU'}
                  >
                    NU
                  </Button>
                </ButtonGroup>

                {
                  modificariCelSec33FS1?.value === 'DA' && (
                    <ModifNonNeoplaziceCheckboxes
                      handleChange={handleChange}
                      setCheckboxValue={handleCheck}
                      data={inputs}
                      editable
                    />
                  )
                }
              </div>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <FormGroup>
              <Label> Anomalii ale celulelor epiteliale scuamoase</Label>
              <div>
                <ButtonGroup size="">
                  <Button
                    onClick={handleBtnClick}
                    id="anomaliiScSec33FS1-DA"
                    color="primary"
                    outline={anomaliiScSec33FS1?.value !== 'DA'}
                  >
                    DA
                  </Button>
                  <Button
                    id="anomaliiScSec33FS1-NU"
                    onClick={handleBtnClick}
                    color="primary"
                    outline={anomaliiScSec33FS1?.value !== 'NU'}
                  >
                    NU
                  </Button>
                </ButtonGroup>
                {
                  anomaliiScSec33FS1?.value === 'DA' && (
                    <AnomaliiCelScuamoaseCheckboxes
                      setCheckboxValue={handleCheck}
                      data={inputs}
                      editable
                    />
                  )
                }
              </div>
            </FormGroup>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col md={12}>
            <FormGroup>
              <Label> Anomalii ale celulelor epiteliale glandulare</Label>
              <div>
                <ButtonGroup size="">
                  <Button
                    onClick={handleBtnClick}
                    id="anomaliiCelGlandSec33FS1-DA"
                    color="primary"
                    outline={anomaliiCelGlandSec33FS1?.value !== 'DA'}
                  >
                    DA
                  </Button>
                  <Button
                    id="anomaliiCelGlandSec33FS1-NU"
                    onClick={handleBtnClick}
                    color="primary"
                    outline={anomaliiCelGlandSec33FS1?.value !== 'NU'}
                  >
                    NU
                  </Button>
                </ButtonGroup>
                {
                  anomaliiCelGlandSec33FS1?.value === 'DA' && (
                    <Row className="mt-2">
                      <Col md={6}>
                        <FormGroup>
                          <Label>
                            AGC (Atypical glandular cells)
                          </Label>
                          <Select
                            isMulti
                            isClearable
                            getOptionValue={option => option.id}
                            getOptionLabel={option => option.value}
                            value={agcListSec33FS1?.value || []}
                            onChange={val => { handleMultipleSelect(val, 'agcListSec33FS1'); }}
                            className="basic-single"
                            classNamePrefix="select"
                            placeholder=""
                            name="agcListSec33FS1"
                            options={optiuniAGC}

                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label>
                            Adenocarcinom
                          </Label>
                          <Select
                            isMulti
                            getOptionValue={option => option.id}
                            getOptionLabel={option => option.value}
                            isClearable
                            value={adenocarcinomListSec33FS1?.value || []}
                            onChange={val => { handleMultipleSelect(val, 'adenocarcinomListSec33FS1'); }}
                            className="basic-single"
                            classNamePrefix="select"
                            placeholder=""
                            name="adenocarcinomListSec33FS1"
                            options={optiuniAdenocarcinom}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  )
                }
              </div>
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col>
            <FormGroup check>
              <Input
                type="checkbox"
                checked={alteNeoplaziiSec33FS1?.value === 'true'}
                value={alteNeoplaziiSec33FS1?.value === 'true'}
                name="alteNeoplaziiSec33FS1"
                onChange={handleCheck}
              />
              <Label>Alte neoplazii </Label>
            </FormGroup>
          </Col>
        </Row>

        {
          alteNeoplaziiSec33FS1?.value === 'true' && (
            <Row>
              <Col>
                <FormGroup>
                  <Label>Descriere</Label>
                  <Input
                    type="textarea"
                    rows={3}
                    name="descriereAlteNeoplazii"
                    onChange={handleChange}
                    value={descriereAlteNeoplazii?.value || ''}
                  />
                </FormGroup>
              </Col>
            </Row>
          )
        }

        <Col
          className="underline-hover form-group dont-display ng-untouched ng-pristine"
          style={{ backgroundColor: 'rgba(233, 236, 239, 0.3)', }}
        >
          <Label>
            Date specifice rezultatului final
          </Label>
        </Col>

        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>Rezultat final *</Label>
              <Select
                value={rezFinalSec44FS1 || ''}
                onChange={handleSelect}
                getOptionValue={option => option.id}
                getOptionLabel={option => option.value}
                className={(typeof rezFinalSec44FS1 !== 'undefined' && rezFinalSec44FS1.value !== '') ? '' : 'invalid'}
                classNamePrefix="select"
                placeholder=""
                name="rezFinalSec44FS1"
                options={rezultatFinalOptions}
              />
            </FormGroup>
          </Col>
          { rezFinalSec44FS1?.value === 'Pozitiv' && (
            <Col>
              <FormGroup>
                <Label>Tipul leziunii</Label>
                <Input
                  type="textarea"
                  rows={3}
                  name="tipLeziuneSec44FS1"
                  onChange={handleChange}
                  value={tipLeziuneSec44FS1?.value || ''}
                />
              </FormGroup>
            </Col>
          )}
        </Row>

        <Row>
          <Col md={6}>
            <FormGroup check>
              <Input
                type="checkbox"
                checked={repetareSec34FS1?.value === 'true'}
                value={repetareSec34FS1?.value === 'true'}
                name="repetareSec34FS1"
                onChange={handleCheck}
              />
              <Label>Repetare </Label>
            </FormGroup>
          </Col>
          {
            repetareSec34FS1?.value === 'true' && (
              <Col md={6}>
                <FormGroup>
                  <Label>
                    Tip repetare
                  </Label>
                  <Select
                    value={repetareListSec34FS1 || ''}
                    onChange={handleSelect}
                    getOptionValue={option => option.id}
                    getOptionLabel={option => option.value}
                    className="basic-single"
                    classNamePrefix="select"
                    placeholder=""
                    name="repetareListSec34FS1"
                    options={tipLeziuneOptions}
                  />
                </FormGroup>
              </Col>
            )
          }
        </Row>
        <Row className="mt-2 mb-2">
          <Col>
            <FormGroup check>
              <Input
                type="checkbox"
                checked={biopsieSec34FS1?.value === 'true'}
                value={biopsieSec34FS1?.value === 'true'}
                name="biopsieSec34FS1"
                onChange={handleCheck}
              />
              <Label>Biopsie </Label>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup check>
              <Input
                type="checkbox"
                checked={colposcopieSec34FS1?.value === 'true'}
                value={colposcopieSec34FS1?.value === 'true'}
                name="colposcopieSec34FS1"
                onChange={handleCheck}
              />
              <Label>Colposcopie </Label>
            </FormGroup>
          </Col>
        </Row>
        <Row className="mt-2 mb-2">
          <Col>
            <FormGroup check>
              <Input
                type="checkbox"
                checked={chiuretajCervSec34FS1?.value === 'true'}
                value={chiuretajCervSec34FS1?.value === 'true'}
                name="chiuretajCervSec34FS1"
                onChange={handleCheck}
              />
              <Label>Chiuretaj endocervical  </Label>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup check>
              <Input
                type="checkbox"
                checked={chiuretajMetrSec34FS1?.value === 'true'}
                value={chiuretajMetrSec34FS1?.value === 'true'}
                name="chiuretajMetrSec34FS1"
                onChange={handleCheck}
              />
              <Label>Chiuretaj endometrial  </Label>
            </FormGroup>
          </Col>
        </Row>

        <Row className="mt-2 mb-2">
          <Col>
            <FormGroup check>
              <Input
                type="checkbox"
                checked={testHpvSec34FS1?.value === 'true'}
                value={testHpvSec34FS1?.value === 'true'}
                name="testHpvSec34FS1"
                onChange={rezFinalSec44FS1?.value !== 'Nesatisfăcător' ? () => {} : handleCheck}
              />
              <Label>Testare HPV</Label>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup check>
              <Input
                type="checkbox"
                checked={repRutinaNegSec34FS1?.value === 'true'}
                value={repRutinaNegSec34FS1?.value === 'true'}
                name="repRutinaNegSec34FS1"
                onChange={handleCheck}
              />
              <Label>Repetare de rutină dacă leziunea este negativă  </Label>
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>Observații</Label>
              <Input
                type="textarea"
                rows={3}
                name="obs1Sec34FS1"
                onChange={handleChange}
                value={obs1Sec34FS1?.value || ''}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label>Data eliberării rezultatului *</Label>
              <FormGroup row className="align-items-center">
                <Col sm={10} className="pr-0">
                  <Input
                    defaultValue={dataElibRezSec34FS1 ? moment(dataElibRezSec34FS1?.value).format('DD-MM-YYYY') : ''}
                    type="text"
                    className={`form-control bg-white ${error && error.field === 'dataElibRezSec34FS1' ? 'invalid' : ''} ${dataElibRezSec34FS1?.value === '' ? 'invalid' : ''} ${typeof dataElibRezSec34FS1 === 'undefined' ? 'invalid' : ''}`}
                    id=""
                    disabled
                    placeholder=""
                  />
                </Col>
                <Col sm={2} className="pl-0">
                  <DatePicker
                    minDate={new Date('01-01-2021')}
                    maxDate={new Date()}
                    name={dataElibRezSec34FS1}
                    locale="ro"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    selected={moment(dataElibRezSec34FS1?.value).toDate()}
                    onChange={val => selectDate(val, 'dataElibRezSec34FS1')}
                    customInput={<Button className="form-control"><FontAwesomeIcon icon={faCalendarAlt} /></Button>}
                  />
                </Col>
                { error && error.field === 'dataElibRezSec34FS1' && <p className="error text-danger mt-1 mb-1 pl-3">{error.message}</p>}
              </FormGroup>
            </FormGroup>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}

export default TestareBabesEdit;
