import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, CardHeader, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import Select from 'react-select';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';

const rezultatHistoData = [
  { id: '529B923D-0EB7-EB11-8C22-000C2901B5F1', value: 'Adenocarcinom', code: 'histopatologic_adenocarcinom', },
  { id: '549B923D-0EB7-EB11-8C22-000C2901B5F1', value: 'Adenocarcinom in situ (AIS)', code: 'histopatologic_AIS', },
  { id: '589B923D-0EB7-EB11-8C22-000C2901B5F1', value: 'Alte tumori epiteliale', code: 'histopatologic_alte_tumori', },
  { id: '579B923D-0EB7-EB11-8C22-000C2901B5F1', value: 'Carcinom scuamos', code: 'histopatologic_carcinom', },
  { id: '599B923D-0EB7-EB11-8C22-000C2901B5F1', value: 'HSIL', code: 'histopatologic_HSIL', },
  { id: '569B923D-0EB7-EB11-8C22-000C2901B5F1', value: 'LSIL', code: 'histopatologic_LSIL', },
  { id: '539B923D-0EB7-EB11-8C22-000C2901B5F1', value: 'Negativ (normal)', code: 'histopatologic_negativ', },
  { id: '5A9B923D-0EB7-EB11-8C22-000C2901B5F1', value: 'Nereprezentativ / neconcludent', code: 'histopatologic_neconcludent', }];

const checkboxInputs = [
  'leepSec5FS1',
  'ablatieLaserSec5FS1',
  'ablatieAlteMijSec5FS1',
  'conizatieSec5FS1',
  'thermocoagulareSec5FS1',
  'crioterapieSec5FS1',
  'histerectomieSec5FS1'
];

function TratamentEdit({ data, changeTab, saveData, editable, }) {
  const [inputs, setInputs] = useState({});
  const [cabinetInterventiiSec5FS1, setCabinetInterventiiSec5FS1] = useState('');
  const [nediciInterventie, setMediciInterventie] = useState({});
  const [laboratorSec5FS1, setLaboratorSec5FS1] = useState('');
  const [patologi, setPatologi] = useState('');

  useEffect(() => {
    const inputs = data.attributes;
    setInputs(inputs);
    setCabinetInterventiiSec5FS1(data.cabinetInterventiiSec5FS1);
    setLaboratorSec5FS1(data.laboratorSec5FS1);
    setMediciInterventie(data.medicInterventieSec5FS1);
    setPatologi(data.personalAnatomoPatoMedicalSec5FS1);
    if (Object.keys(inputs).length === 0) return;
    checkboxInputs.forEach(elem => {
      inputs[elem].value = inputs[elem].value.toString();
    });
  }, []);

  const {
    // cabinetInterventiiSec5FS1,
    medicInterventieSec5FS1,
    dataInterventieSec5FS1,
    leepSec5FS1,
    ablatieLaserSec5FS1,
    ablatieAlteMijSec5FS1,
    conizatieSec5FS1,
    crioterapieSec5FS1,
    thermocoagulareSec5FS1,
    histerectomieSec5FS1,
    alteleSec5FS1,
    // laboratorSec5FS1,
    personalAnatomoPatoMedicalSec5FS1,
    numarProbaSec5FS1,
    dataPimireProbaSec5FS1,
    dataInterpretareSec5FS1,
    rezultatHistoSec5FS1,
  } = inputs;

  const handleSelect = (selected, details) => {
    const curentVal = {
      ...inputs[details.name],
      id: selected?.id || '',
      value: selected?.value || '',
    };
    setInputs(prevState => ({ ...prevState, [details.name]: curentVal, }));
  };

  const selectDate = (val, name) => {
    const dataSubmit = moment(val).utc().format();
    const curentVal = { ...inputs[name], value: dataSubmit, };
    setInputs(prevState => ({ ...prevState, [name]: curentVal, }));
  };

  const handleCheck = e => {
    const newVal = e.target.value === 'false' ? 'true' : 'false';
    const curentVal = { ...inputs[e.target.name], value: newVal, };
    setInputs(prevState => ({ ...prevState, [e.target.name]: curentVal, }));
  };

  const save = e => {
    const saveStatus = e.target.id;
    checkboxInputs.forEach(elem => {
      inputs[elem].value = inputs[elem].value === 'true';
    });
    saveData(inputs, saveStatus);
  };

  const setTab = e => { changeTab(e.target.id); };

  const calcShowSaveBtn = () =>
  // if (typeof rezFinalSec44FS1 === 'undefined' || rezFinalSec44FS1?.value === '') {
  //   return false;
  // }
  // if (typeof medicAnatoPatoCertificareSec3BFS1 === 'undefined' || medicAnatoPatoCertificareSec3BFS1?.value === '') {
  //   return false;
  // }
    true
    // className={(typeof rezHpvSec34FS1 !== 'undefined' && rezHpvSec34FS1.value !== '') ? '' : 'invalid'}
  ;

  const showBtns = calcShowSaveBtn();

  return (
    <Card>
      <CardHeader>
        <h3 className="mb-0">
          <div className="d-flex">
            <div>
              <span className="mr-3">Tratament</span>
            </div>
            {showBtns === true && (
              <div>
                <Button id="draft" size="sm" color="primary" className="btn-rounded" onClick={save}>Draft</Button>
                <Button id="final" size="sm" color="success" className="ml-2 btn-rounded" onClick={save}>Finalizare follow-up</Button>
              </div>
            )}
            <div className="ml-auto">
              <Button id="-" size="sm" color="secondary" className="ml-auto btn-pill" onClick={setTab}>{'<'}</Button>
            </div>
          </div>
        </h3>
      </CardHeader>
      <CardBody className="pb-5">
        <Col
          className="underline-hover form-group dont-display ng-untouched ng-pristine"
          style={{ backgroundColor: 'rgba(233, 236, 239, 0.3)', }}
        >
          <Label>
            Datele specifice tratamentului
          </Label>
        </Col>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>Centrul de servicii medicale specializate unde se efectuează intervenţiile</Label>
              <Input
                required
                type="text"
                name="cabinetInterventiiSec5FS1"
                disabled
                value={cabinetInterventiiSec5FS1?.value || ''}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label>Medicul de specialitate obstetrică ginecologie care efectuează intervenţia</Label>
              <Select
                value={medicInterventieSec5FS1 || ''}
                onChange={handleSelect}
                getOptionValue={option => option.id}
                getOptionLabel={option => option.value}
                className="basic-single"
                classNamePrefix="select"
                placeholder=""
                name="medicInterventieSec5FS1"
                options={nediciInterventie}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>Data intervenţiei</Label>
              <FormGroup row className="align-items-center">
                <Col sm={10} className="pr-0">
                  <Input
                    defaultValue={dataInterventieSec5FS1 ? moment(dataInterventieSec5FS1?.value).format('DD-MM-YYYY') : ''}
                    type="text"
                    className="form-control bg-white"
                    id=""
                    disabled
                    placeholder=""
                  />
                </Col>
                <Col sm={2} className="pl-0">
                  <DatePicker
                    name={dataInterventieSec5FS1}
                    locale="ro"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    selected={moment(dataInterventieSec5FS1?.value).toDate()}
                    onChange={val => selectDate(val, 'dataInterventieSec5FS1')}
                    customInput={<Button className="form-control"><FontAwesomeIcon icon={faCalendarAlt} /></Button>}
                  />
                </Col>
              </FormGroup>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup check>
              <Input
                type="checkbox"
                checked={leepSec5FS1?.value === 'true'}
                value={leepSec5FS1?.value === 'true'}
                name="leepSec5FS1"
                onChange={handleCheck}
              />
              <Label>Procedură excizională LEEP</Label>
            </FormGroup>
          </Col>
        </Row>

        <Row className="mt-2 mb-2">
          <Col>
            <FormGroup check>
              <Input
                type="checkbox"
                checked={ablatieLaserSec5FS1?.value === 'true'}
                value={ablatieLaserSec5FS1?.value === 'true'}
                name="ablatieLaserSec5FS1"
                onChange={handleCheck}
              />
              <Label>Ablaţie laser </Label>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup check>
              <Input
                type="checkbox"
                checked={ablatieAlteMijSec5FS1?.value === 'true'}
                value={ablatieAlteMijSec5FS1?.value === 'true'}
                name="ablatieAlteMijSec5FS1"
                onChange={handleCheck}
              />
              <Label>Ablaţie cu alte mijloace în afară de laser  </Label>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup check>
              <Input
                type="checkbox"
                checked={conizatieSec5FS1?.value === 'true'}
                value={conizatieSec5FS1?.value === 'true'}
                name="conizatieSec5FS1"
                onChange={handleCheck}
              />

              <Label>Conizaţie cu bisturiul rece  </Label>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup check>
              <Input
                type="checkbox"
                checked={crioterapieSec5FS1?.value === 'true'}
                value={crioterapieSec5FS1?.value === 'true'}
                name="crioterapieSec5FS1"
                onChange={handleCheck}
              />

              <Label>Crioterapie</Label>
            </FormGroup>
          </Col>
        </Row>

        <Row className="mt-2 mb-2">
          <Col>
            <FormGroup check>
              <Input
                type="checkbox"
                checked={thermocoagulareSec5FS1?.value === 'true'}
                value={thermocoagulareSec5FS1?.value === 'true'}
                name="thermocoagulareSec5FS1"
                onChange={handleCheck}
              />

              <Label>Thermocoagulare</Label>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup check>
              <Input
                type="checkbox"
                checked={histerectomieSec5FS1?.value === 'true'}
                value={histerectomieSec5FS1?.value === 'true'}
                name="histerectomieSec5FS1"
                onChange={handleCheck}
              />

              <Label>Histerectomie totală </Label>
            </FormGroup>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            <FormGroup check>
              <Input
                type="checkbox"
                checked={alteleSec5FS1?.value === 'true'}
                value={alteleSec5FS1?.value === 'true'}
                name="alteleSec5FS1"
                onChange={handleCheck}
              />
              <Label>Altele</Label>
            </FormGroup>
          </Col>
        </Row>

        <Col
          className="underline-hover form-group dont-display ng-untouched ng-pristine"
          style={{ backgroundColor: 'rgba(233, 236, 239, 0.3)', }}
        >
          <Label>
            Examen histopatologic – (post tratament)
          </Label>
        </Col>

        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>Laborator</Label>
              <Input
                required
                type="text"
                name="laboratorSec5FS1"
                disabled
                value={laboratorSec5FS1?.value || ''}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label>Personalul medical care realizează diagnosticul histologic</Label>
              <Select
                value={personalAnatomoPatoMedicalSec5FS1 || ''}
                onChange={handleSelect}
                getOptionValue={option => option.id}
                getOptionLabel={option => option.value}
                className="basic-single"
                classNamePrefix="select"
                placeholder=""
                name="personalAnatomoPatoMedicalSec5FS1"
                options={patologi}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>Numărul probei</Label>
              <Input
                required
                type="text"
                name="numarProbaSec5FS1"
                disabled
                value={numarProbaSec5FS1?.value || ''}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>Data primirii probei</Label>
              <FormGroup row className="align-items-center">
                <Col sm={10} className="pr-0">
                  <Input
                    defaultValue={dataPimireProbaSec5FS1 ? moment(dataPimireProbaSec5FS1?.value).format('DD-MM-YYYY') : ''}
                    type="text"
                    className="form-control bg-white"
                    id=""
                    disabled
                    placeholder=""
                  />
                </Col>
                <Col sm={2} className="pl-0">
                  <DatePicker
                    name={dataPimireProbaSec5FS1}
                    locale="ro"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    selected={moment(dataPimireProbaSec5FS1?.value).toDate()}
                    onChange={val => selectDate(val, 'dataPimireProbaSec5FS1')}
                    customInput={<Button className="form-control"><FontAwesomeIcon icon={faCalendarAlt} /></Button>}
                  />
                </Col>
              </FormGroup>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label>
                Data interpretării
              </Label>
              <FormGroup row className="align-items-center">
                <Col sm={10} className="pr-0">
                  <Input
                    defaultValue={dataInterpretareSec5FS1 ? moment(dataInterpretareSec5FS1?.value).format('DD-MM-YYYY') : ''}
                    type="text"
                    className="form-control bg-white"
                    id=""
                    disabled
                    placeholder=""
                  />
                </Col>
                <Col sm={2} className="pl-0">
                  <DatePicker
                    name={dataInterpretareSec5FS1}
                    locale="ro"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    selected={moment(dataInterpretareSec5FS1?.value).toDate()}
                    onChange={val => selectDate(val, 'dataInterpretareSec5FS1')}
                    customInput={<Button className="form-control"><FontAwesomeIcon icon={faCalendarAlt} /></Button>}
                  />
                </Col>
              </FormGroup>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>Rezultatul histopatologic</Label>
              <Select
                value={rezultatHistoSec5FS1 || ''}
                onChange={handleSelect}
                getOptionValue={option => option.id}
                getOptionLabel={option => option.value}
                className="basic-single"
                classNamePrefix="select"
                placeholder=""
                name="rezultatHistoSec5FS1"
                menuPlacement="top"
                options={rezultatHistoData}
              />
            </FormGroup>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}

export default TratamentEdit;
