import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faRedoAlt, faSearch } from '@fortawesome/free-solid-svg-icons';
import { Input } from 'reactstrap';

function OrganizationFiltersSimple({ type, setFilterType, addFilterAndSearch, resetFilters, filters, }) {
  const [label, setLabel] = useState('');

  useEffect(() => {
    const { label: propsLabel, } = filters;
    if (typeof propsLabel !== 'undefined') setLabel(propsLabel);
  }, [filters.label]);

  const handleFilterType = () => {
    setFilterType(type);
  };

  const handleSearch = () => {
    addFilterAndSearch({ label, });
  };

  const handleLabelChange = e => {
    setLabel(e.target.value);
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const handleResetFilters = () => {
    setLabel('');
    resetFilters();
  };

  return (
    <div className="row mt-4">
      <div className="col-sm-12">
        <div className="input-group flex-nowrap">
          <div className="input-group-prepend">
            <span id="addon-wrapping" className="input-group-text">Denumire / Nume furnizor:</span>
            <Input
              value={label}
              onKeyDown={handleKeyDown}
              onChange={handleLabelChange}
              id="search-default"
              type="text"
              className="form-control principalSearchField"
              placeholder="Căutare"
            />
          </div>
          <div className="input-group-append">
            <div
              onClick={handleResetFilters}
              className="input-group-text bg-transparent border-left-0 border cursor-pointer"
            >
              <FontAwesomeIcon icon={faRedoAlt} />
            </div>
            <div
              onClick={handleSearch}
              className="input-group-text bg-transparent border-left-0 border cursor-pointer"
            >
              <FontAwesomeIcon icon={faSearch} />
            </div>
            <div
              onClick={handleFilterType}
              id="search-expanded"
              className="input-group-text bg-transparent border-left-0 border cursor-pointer"
            >
              <FontAwesomeIcon icon={faChevronDown} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrganizationFiltersSimple;
