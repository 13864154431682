import React, { useState } from 'react';
import {
  Button,
  Col,
  FormGroup,
  Label
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import DeleteDoctorContactsModal from './DeleteDoctorContactsModal';
import DoctorContactsModal from './DoctorContactsModal';
import DoctorContactsEntry from './DoctorContactsEntry';

function DoctorContacts({ contacts = [], readOnly, handleContactsChange, }) {
  const [showModal, setShowModal] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [selected, setSelected] = useState(null);

  const showAddressModal = data => {
    setSelected(data);
    setShowModal(true);
  };

  const createNewContact = () => {
    // console.log('val: ', val);
    // handleContactsChange(val);
    setSelected(null);
    setShowModal(true);
  };

  const setNewContact = val => {
    handleContactsChange(val);
    setSelected(null);
    setShowModal(false);
  };

  const showDeleteModal = data => {
    setShowDelete(data);
  };

  const closeAddressModal = () => { setShowModal(false); };

  const closeDeleteModal = () => { setShowDelete(false); };
  const confirmDelete = () => {
    handleContactsChange(showDelete, 'remove');
    closeDeleteModal();
  };

  const domContacts = contacts.map(c => {
    if (typeof c.id === 'undefined' || c.id === null) {
      c.id = c?.label || '';
    }
    return (
      <DoctorContactsEntry
        readOnly={readOnly}
        key={c.id}
        contact={c}
        showAddressModal={showAddressModal}
        showDeleteModal={showDeleteModal}
      />
    );
  });

  return (
    <>
      { showModal && (
        <DoctorContactsModal
          close={closeAddressModal}
          data={selected}
          setContact={setNewContact}
        />
      )}
      { showDelete && (
        <DeleteDoctorContactsModal
          data={showDelete}
          close={closeDeleteModal}
          confirm={confirmDelete}
        />
      )}
      { readOnly ? (
        <FormGroup row>
          <Label sm={3}>Contacte</Label>
          <Col sm={9}>
            {domContacts}
          </Col>
        </FormGroup>
      ) : (
        <>
          <FormGroup className="mt-0 mb-1" row>
            <Col>
              <Button
                onClick={createNewContact}
                color="secondary"
                className="btn btn-pill p-0"
              >
                <FontAwesomeIcon icon={faPlus} />
              </Button>
              <Label className="ml-3">Contacte</Label>
            </Col>
          </FormGroup>
          <div>
            { domContacts}
          </div>
        </>
      )}
    </>
  );
}

export default DoctorContacts;
