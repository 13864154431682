import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCheckCircle, faEye, faPencilAlt, faTimes, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { Link, NavLink, useLocation } from 'react-router-dom';
import moment from 'moment';

const sectionsMapping = {
  s1: { name: 'Informare şi consiliere', },
  s2: { name: 'Consultație şi recoltare', },
  s3A: { name: 'Testare HPV', },
  s3B: { name: 'Testare BPN', },
};

const calcColor = status => {
  if (status) {
    return status === 'FINAL' ? 'text-success' : status === 'EDIT' ? 'text-warning' : 'text-info';
  }
  return '';
};

const calcLabel = status => {
  if (status) {
    return status === 'FINAL' ? 'Finalizata' : status === 'EDIT' ? 'In lucru' : 'Inactiva';
  }
  return '';
};

const calcFileStatus = data => {
  const { tsfEntityActive = false, tsfEntityIsFinalized = false, tsfEntityStatus, } = data;
  if (tsfEntityIsFinalized === true && tsfEntityActive === true) { return 'Finalizata'; }
  if (tsfEntityActive === true && tsfEntityIsFinalized === false) { return 'In lucru'; }
  if (tsfEntityStatus === 'DISABLED') return 'Inactiva';
  if (tsfEntityActive === false) return 'Inactiva';
  return '';
};

const calcOrgLabel = label => {
  if (label) {
    if (label.length > 50) {
      return `${label.substring(0, 50)}...`;
    }
    return label;
  }
  return '';
};

function CasefilesTableRow({ data, canEdit, }) {
  const { id, patient: { person, customId, }, sections = {}, active, } = data;
  const location = useLocation();
  const tsfEntityStatusDom = calcFileStatus(data);

  const domSections = Object.keys(sectionsMapping).map((elem => {
    const colorClassName = calcColor(sections[elem]?.status);
    const statusLabel = calcLabel(sections[elem]?.status);
    const orgLabel = calcOrgLabel(sections[elem]?.organization?.label);

    if (sections[elem]) {
      return (
        <div key={`id-${elem}`} className="mb-1">
          <div className="d-flex">
            <div>
              <small className="text-uppercase">
                <strong>
                  {sectionsMapping[elem].name}
                  :
                </strong>
              </small>
              <span>
                {' '}
                {orgLabel}
              </span>
              <span>
                {' / '}
                {sections[elem]?.doctor?.person?.fullName || ''}
                {' / '}
                { sections[elem]?.sectionDate ? moment(sections[elem]?.sectionDate).format('DD-MM-YYYY') : '' }
                {' / '}
                <span className={`${colorClassName} text-uppercase`}>{statusLabel}</span>
              </span>
            </div>
          </div>
        </div>
      );
    }
    return '';
  }));

  return (
    <tr>
      <td>
        {person?.lastName || '' }
        {' '}
        {person?.firstName || '' }
        <br />
        <small><strong>CNP: </strong></small>
        <span>{person?.cnp || '' }</span>
        <br />
        <small><strong>ID: </strong></small>
        <span>{customId || '' }</span>
      </td>

      <td>
        {domSections}
      </td>
      <td className={`text-uppercase ${tsfEntityStatusDom === 'In lucru' ? 'text-warning' : tsfEntityStatusDom === 'Finalizata' ? 'text-success' : ''}`}>{tsfEntityStatusDom}</td>

      <td style={{ minWidth: 80, }}>
        { active
          ? <FontAwesomeIcon fontSize={24} icon={faCheck} />
          : <FontAwesomeIcon fontSize={24} icon={faTimes} />}
      </td>

      <td className="text-right" style={{ minWidth: 150, }}>
        <div className="actions">
          <NavLink
            to={`/casefile/${id}/view`}
            state={{ from: location, }}
            className="btn btn-info btn-sm btn-pill mr-1"
          >
            <FontAwesomeIcon icon={faEye} />
          </NavLink>
          {
            canEdit && active && (
              <NavLink
                to={`/casefile/${id}/edit`}
                state={{ from: location, }}
                className="btn btn-primary btn-sm btn-pill mr-1"
              >
                <FontAwesomeIcon icon={faPencilAlt} />
              </NavLink>
            )
          }
        </div>
      </td>
    </tr>
  );
}

export default CasefilesTableRow;
