import React, { useEffect, useState } from 'react';
import { Button, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faRedoAlt, faSearch } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';
import 'react-datepicker/dist/react-datepicker.css';
import CasefileFilterExtendedSection from './CasefileFilterExtendedSection';

const stareOptions = [
  { id: 'IN_LUCRU', value: 'IN LUCRU', },
  { id: 'INACTIVA', value: 'INACTIVA', },
  { id: 'FINALIZATA', value: 'FINALIZATA', }
];

const stareFisa = [
  { id: 'IN_LUCRU', value: 'IN LUCRU', },
  { id: 'FINALIZATA', value: 'FINALIZATA', }
];

function CasefileFilterExtended({
  handleSearch,
  handleReset,
  organizations,
  regions,
  getDoctorsSimple,
  type, setFilterType, addFilterAndSearch, resetFilters, filters,
}) {
  const [inputs, setInputs] = useState({});
  const [doctorsS1, setDoctorsS1] = useState([]);
  const [doctorsS2, setDoctorsS2] = useState([]);
  const [doctorsS3A, setDoctorsS3A] = useState([]);
  const [doctorsS3B, setDoctorsS3B] = useState([]);
  const [active, setActive] = useState('');

  useEffect(() => {
    const { lastStatus, } = filters;
    if (lastStatus && lastStatus !== '') {
      const selected = stareOptions.find(a => a.id === lastStatus);
      setActive(selected);
    }
  }, [filters.lastStatus]);

  useEffect(
    () => {
      const { id = '', } = filters;
      setInputs({ id, });
    },
    [filters.id]
  );

  const handleChange = (selected, details) => {
    const { name, } = details;
    if (selected === null) {
      addFilterAndSearch({ ...inputs, [name]: '', }, false, 'active');
    } else {
      const { id, } = selected;
      addFilterAndSearch({ ...inputs, [name]: id, });
    }
  };

  const handleInputChange = e => {
    setInputs(prevState => ({ ...prevState, [e.target.name]: e.target.value, }));
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      // console.log(inputs);
      addFilterAndSearch({ ...inputs, });
    }
  };

  const handleOrganizationChange = (selected, details, callback) => {
    const { name, } = details;
    const section = name.substring(0, 2);
    // handleChange(null, { name: `${section}Doctor`, });
    if (selected === null) {
      addFilterAndSearch({ ...inputs, [name]: '', }, false, 'active');
    } else {
      const { id, } = selected;
      addFilterAndSearch({ ...inputs, [name]: id, });
      getDoctorsSimple(id)
        .then((res => {
          // setDoctorsS1(res.data.data);
          callback(res.data.data);
        }))
        .catch((err => {
          console.log('err: ', err);
        }));
    }
  };
  const { id, } = inputs;

  return (
    <div className="searchFormFields mt-4">
      <Row>
        <Col className="text-right">
          <Button
            onClick={resetFilters}
            className="btn btn-light btn-sm btn-pill"
          >
            <FontAwesomeIcon icon={faRedoAlt} />
          </Button>
          <Button
            onClick={handleSearch}
            className="btn btn-light btn-sm btn-pill"
          >
            <FontAwesomeIcon icon={faSearch} />
          </Button>
          <Button
            onClick={setFilterType}
            className="btn btn-light btn-sm btn-pill"
          >
            <FontAwesomeIcon icon={faChevronUp} />
          </Button>
        </Col>
      </Row>

      <Row>
        <Col sm={4} lg={3}>
          <FormGroup>
            <Label>
              <small className="font-weight-bold">Stare ultima fisa</small>
            </Label>
            <Select
              getOptionValue={option => option.id}
              getOptionLabel={option => option.value}
              onChange={handleChange}
              className="basic-single"
              classNamePrefix="select"
              placeholder=""
              isClearable
              name="lastStatus"
              options={stareOptions}
              value={active}
            />
          </FormGroup>
        </Col>
        <Col sm={4} lg={3}>
          <FormGroup>
            <Label>
              <small className="ml-sm-2 font-weight-bold">ID beneficiar</small>
            </Label>
            <Input
              name="id"
              value={id || ''}
              onKeyDown={handleKeyDown}
              onChange={handleInputChange}
              type="text"
              className="form-control"
              placeholder=""
            />
          </FormGroup>
        </Col>
      </Row>
      <CasefileFilterExtendedSection
        title="Informare şi consiliere"
        handleOrganizationChange={handleOrganizationChange}
        handleChange={handleChange}
        organizations={organizations}
        doctors={doctorsS1 || []}
        setDoctors={setDoctorsS1}
        type="s1"
      />
      <CasefileFilterExtendedSection
        title="Consultație şi recoltare"
        handleOrganizationChange={handleOrganizationChange}
        handleChange={handleChange}
        organizations={organizations}
        doctors={doctorsS2 || []}
        setDoctors={setDoctorsS2}
        type="s2"
      />
      <CasefileFilterExtendedSection
        title="Testare HPV"
        handleOrganizationChange={handleOrganizationChange}
        handleChange={handleChange}
        organizations={organizations}
        doctors={doctorsS3A || []}
        setDoctors={setDoctorsS3A}
        type="s3A"
        // withResult
      />
      <CasefileFilterExtendedSection
        title="Testare BPN"
        handleOrganizationChange={handleOrganizationChange}
        handleChange={handleChange}
        organizations={organizations}
        doctors={doctorsS3B || []}
        setDoctors={setDoctorsS3B}
        type="s3B"
        // withResult
      />
    </div>
  );
}

export default CasefileFilterExtended;
