import React, { Component } from 'react';
import { connect } from 'react-redux';
import usersOperations from '../../../redux/ducks/users/operations';

class Mesaje extends Component {
  render() {
    return (
      <div className="container-fluid">
        <h1>Mesaje</h1>
        <h1>Mesaje</h1>
        <h1>Mesaje</h1>
        <h1>Mesaje</h1>
        <h1>Mesaje</h1>
        <h1>Mesaje</h1>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  users: state.users.users,
  selectedUser: state.users.selectedUser,
});

const mapDispatchToProps = dispatch => ({ getUsers: () => dispatch(usersOperations.getUsers()), });

export default connect(mapStateToProps, mapDispatchToProps)(Mesaje);
