import types from './types';

const getUsers = url => ({
  type: types.GET_USERS,
  async: true,
  generalFetching: true,
  payload: {
    path: url,
    method: 'GET',
  },
});

const getProfiles = () => ({
  type: types.GET_PROFILES,
  async: true,
  generalFetching: true,
  payload: {
    path: '/profiles',
    method: 'GET',
  },
});

const getSingleUser = id => ({
  type: types.GET_SINGLE_USER,
  async: true,
  generalFetching: true,
  payload: {
    path: `/users/${id}`,
    method: 'GET',
  },
});

const createUser = data => ({
  type: types.CREATE_USER,
  async: true,
  generalFetching: true,
  payload: {
    path: '/users',
    method: 'POST',
    body: data,
  },
});

const updateUser = data => ({
  type: types.UPDATE_USER,
  async: true,
  generalFetching: true,
  payload: {
    path: `/users/${data.id}`,
    method: 'PUT',
    body: data,
  },
});

export default {
  getUsers,
  createUser,
  getSingleUser,
  updateUser,
  getProfiles,
};
