import React, { Component } from 'react';
import './index.scss';
import { Button, Card } from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

const formatTable = (data, isList) => {
  const arr = [];
  const headerArr = data?.headers || [];
  const contentArr = data?.content || [];
  const { title, subtitle, } = data;

  const columns = isList ? [] : [{
    dataField: 'id',
    text: 'Tip',
    sort: true,
    footer: 'Total',
  }];

  if (isList) {
    headerArr.forEach(name => columns.push({
      dataField: name,
      text: name,
      sort: true,
    }));
  } else {
    headerArr.forEach(name => columns.push({
      dataField: name,
      text: name,
      sort: true,
      footer: columnData => columnData.reduce((acc, item) => acc + item, 0),
    }));
  }

  Object.keys(contentArr).forEach(key => {
    if (key === 'Total') return;
    const obj = {};
    const values = contentArr[key];
    values.forEach((elem, index) => obj[headerArr[index]] = elem);
    arr.push({ id: key, ...obj, });
  });

  return {
    data: arr,
    columns,
    title,
    subtitle,
  };
};

function StatisticsTable(props) {
  const { downloadReport, tableTitle, tableSubitle, tableData, isList, } = props;
  let finalTableData = null;
  if (tableData) {
    finalTableData = formatTable(tableData || {}, isList);
  }

  return (
    <>
      {tableTitle && (
      <h5 className="mt-3">
        {tableTitle}
        <Button
          id="pdf"
          onClick={downloadReport}
          size="sm"
          color="info"
          className="ml-3"
        >
          Descarca Pdf
        </Button>
        <Button
          id="xls"
          onClick={downloadReport}
          size="sm"
          color="info"
          className="ml-3"
        >
          Descarca Excel
        </Button>
      </h5>
      )}
      {tableSubitle && (
      <h6 className="mt-3">
        {tableSubitle}
      </h6>
      )}
      {
          finalTableData && (
            <BootstrapTable keyField="id" data={finalTableData.data} columns={finalTableData.columns} />
          )
        }
    </>
  );
}

export default StatisticsTable;
