import React, { useState, useEffect } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Container, FormGroup, Input, Label, Row, Spinner } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faPencilAlt, faPlus } from '@fortawesome/free-solid-svg-icons';
import { NavLink, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { connect } from 'react-redux';
import moment from 'moment';
import CasefilesForPatientTable from './CasefilesForPatientTable';
import { casefilesOperations } from '../../../../redux/ducks/casefiles';
import DeactivateFileModal from './DeactivateFileModal';

function CasefileView({ getSingleCasefile, createFile, updateFile, auth, }) {
  const location = useLocation();
  const navigate = useNavigate();

  const [casefile, setCasefile] = useState(null);
  const [modal, setModal] = useState(null);
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    const splitUrl = location.pathname.split('/casefile/')[1];
    if (splitUrl !== 'new') {
      getCasefileData(splitUrl);
    }
  }, []);

  const closeModalAndRefresh = () => {
    const splitUrl = location.pathname.split('/casefile/')[1];
    getCasefileData(splitUrl);
  };

  const getCasefileData = url => {
    const id = url.split('/')[0];
    getSingleCasefile(id)
      .then((res => {
        setCasefile(res.data.data);
      }))
      .catch((err => {
        console.log('err: ', err);
      }));
  };

  const goBack = () => {
    const { state, } = location;
    if (state) {
      const { state: { from: { search, pathname, }, }, } = location;
      navigate(`${pathname}${search}`);
    } else {
      navigate('/casefile');
    }
  };

  const createNewFile = () => {
    const { patient: { id, }, } = casefile;
    createFile(id)
      .then((res => {
        navigate(`/file/${res.data.data.id}/edit`);
      }))
      .catch((err => {
        console.log('err: ', err);
      }));
  };

  const openModal = fileId => {
    setModal(true);
    setSelected(fileId);
  };

  const closeModal = () => {
    setModal(false);
    setSelected(null);
  };

  const confirmInactivate = () => {
    updateFile({ id: selected, body: { active: false, }, })
      .then((res => {
        const splitUrl = location.pathname.split('/casefile/')[1];
        getCasefileData(splitUrl);
        closeModal();
      }))
      .catch((err => {
        console.log('err: ', err);
        getCasefileData(splitUrl);
        closeModal();
      }));
  };

  if (casefile === null) return <Spinner />;

  const {
    allowNewTsfEntity = false,
    allowNewTsfEntityMessage = null,
    id = '',
    createTs = '',
    active = false,
    doctor: {
      person: {
        firstName: firstNameDoctor = '',
        lastName: lastNameDoctor = '',
      },
    },
    isFirstInvite = true,
    d0InviteDate,
    d0InviteDoctor,
    d2InformDate,
    d1ScheduleDate = '',
    d3InviteDate = '',
    d4ScheduleDate = '',
    d5InviteDate = '',
    d6ScheduleDate = '',
    d2InformDoctor,
    patient: {
      id: patientId = '',
      person: {
        firstName: firstNamePatient = '',
        lastName: lastNamePatient = '',
      },
    },
    disease: { label = '', },
    tsfEntities: patientFiles = [],
  } = casefile;

  return (
    <Container fluid className="d-flex ">
      { modal && (
        <DeactivateFileModal
          selected={selected}
          close={closeModal}
          confirm={confirmInactivate}
        />
      )}
      <Row className="w-100 m-0 justify-content-center">
        <Col lg={10}>
          <Card className="mt-3">
            <CardHeader>
              <h2>Dosar</h2>
            </CardHeader>
            <CardBody className="p-5">
              <Row className="">
                <Col md={6}>
                  <FormGroup row className="mb-0">
                    <Label className="font-weight-bold" sm={3}>Beneficiar:</Label>
                    <Label className="font-weight-bold" sm={9}>
                      <NavLink to={`/patient/${patientId}/view`}>
                        <span className="text-dark">{`${lastNamePatient} ${firstNamePatient}`}</span>
                      </NavLink>
                    </Label>
                  </FormGroup>
                  <FormGroup row className="mb-0">
                    <Label className="font-weight-bold" sm={3}>Stare:</Label>
                    <Label sm={9}>{active ? 'Activ' : 'Inactiv'}</Label>
                  </FormGroup>
                  <FormGroup row className="mb-0">
                    <Label className="font-weight-bold" sm={3}>Creat la data:</Label>
                    <Label sm={9}>{moment(createTs).format('DD-MM-YYYY')}</Label>
                  </FormGroup>
                  <FormGroup row className="mb-0">
                    <Label className="font-weight-bold" sm={3}>Personal furnizor:</Label>
                    <Label sm={9}>
                      {lastNameDoctor}
                      {' '}
                      {firstNameDoctor}
                    </Label>
                  </FormGroup>
                  <FormGroup row className="mb-0">
                    <Label className="font-weight-bold" sm={3}>Program:</Label>
                    <Label sm={9}>{label}</Label>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  {
                    d0InviteDate && d0InviteDate !== '' && (
                      <FormGroup row className="mb-0">
                        <Label className="font-weight-bold" sm={3}>Data Invitare Screening:</Label>
                        <Label sm={9}>{moment(d0InviteDate).format('DD-MM-YYYY')}</Label>
                      </FormGroup>
                    )
                  }
                  {
                    d0InviteDoctor && (
                      <FormGroup row className="mb-0">
                        <Label className="font-weight-bold" sm={3}>Personal Invitare Screening:</Label>
                        <Label sm={9}>
                          { d0InviteDoctor?.person?.fullName || '' }
                        </Label>
                      </FormGroup>
                    )
                  }

                  <FormGroup row className="mb-0">
                    <Label className="font-weight-bold" sm={3}>Prima Invitatie:</Label>
                    <Label sm={9}>
                      { isFirstInvite ? 'Da' : 'Nu' }
                    </Label>
                  </FormGroup>

                  <FormGroup row className="mb-0 invisible">
                    <Label className="font-weight-bold" sm={3}>Creat la data:</Label>
                    <Label sm={9}>{moment(createTs).format('DD-MM-YYYY')}</Label>
                  </FormGroup>
                  {
                    d1ScheduleDate && d1ScheduleDate !== '' && (
                      <FormGroup row className="mb-0">
                        <Label className="font-weight-bold" sm={3}>Data Programare Screening:</Label>
                        <Label sm={9}>{moment(d1ScheduleDate).format('DD-MM-YYYY')}</Label>
                      </FormGroup>
                    )
                  }

                  {
                    d2InformDate && d2InformDate !== '' && (
                      <FormGroup row className="mb-0">
                        <Label className="font-weight-bold" sm={3}>Data Instiintare Rezultat:</Label>
                        <Label sm={9}>{moment(d2InformDate).format('DD-MM-YYYY')}</Label>
                      </FormGroup>
                    )
                  }
                  {d2InformDoctor && (
                    <FormGroup row className="mb-0">
                      <Label className="font-weight-bold" sm={3}>Personal Instiintare Rezultat:</Label>
                      <Label sm={9}>
                        { d2InformDoctor?.person?.fullName || '' }
                      </Label>
                    </FormGroup>
                  )}

                </Col>
              </Row>
              <Row className="mt-4">
                <Col md={6}>
                  {
                    d3InviteDate && d3InviteDate !== '' && (
                      <FormGroup row className="mb-0">
                        <Label className="font-weight-bold" sm={3}>Data Invitare Triaj:</Label>
                        <Label sm={9}>{moment(d3InviteDate).format('DD-MM-YYYY')}</Label>
                      </FormGroup>
                    )
                  }

                  {
                    d4ScheduleDate && d4ScheduleDate !== '' && (
                      <FormGroup row className="mb-0">
                        <Label className="font-weight-bold" sm={3}>Data Programare Triaj:</Label>
                        <Label sm={9}>{moment(d4ScheduleDate).format('DD-MM-YYYY')}</Label>
                      </FormGroup>
                    )
                  }

                </Col>
                <Col md={6}>

                  {
                    d5InviteDate && d5InviteDate !== '' && (
                      <FormGroup row className="mb-0">
                        <Label className="font-weight-bold" sm={3}>Data Invitare Colposcopie/Biopsie:</Label>
                        <Label sm={9}>{moment(d5InviteDate).format('DD-MM-YYYY')}</Label>
                      </FormGroup>
                    )
                  }
                  {
                    d6ScheduleDate && d6ScheduleDate !== '' && (
                      <FormGroup row className="mb-0 d-flex">
                        <Label className="font-weight-bold" sm={3}>Data Programare Colposcopie/Biopsie:</Label>
                        <Label sm={9}>{moment(d6ScheduleDate).format('DD-MM-YYYY')}</Label>
                      </FormGroup>
                    )
                  }

                </Col>

              </Row>
              <Row className="mt-3">
                <Col md={6} />
                <Col md={6} className="text-right">
                  <Button
                    onClick={goBack}
                    color="secondary"
                  >
                    <FontAwesomeIcon icon={faArrowLeft} />
                    <span className="ml-1">Înapoi</span>
                  </Button>
                  {
                    auth?.update.includes('CASEFILE_UPDATE') && active && (
                      <NavLink to={`/casefile/${id}/edit`}>
                        <Button
                          color="primary"
                          className="ml-1"
                        >
                          <FontAwesomeIcon icon={faPencilAlt} />
                          <span className="ml-1">Editare</span>
                        </Button>
                      </NavLink>
                    )
                  }
                </Col>
              </Row>
            </CardBody>
          </Card>

          {
            auth?.view.includes('TSFENTITY_LIST') && (
              <Card className="mt-3">
                <CardHeader>
                  <h2>Fișe</h2>
                  {
                    allowNewTsfEntityMessage !== null && (
                      <div className="text-warning">{allowNewTsfEntityMessage}</div>
                    )
                  }
                  {
                    auth?.create.includes('TSFENTITY_CREATE') && allowNewTsfEntity && (
                      <div className="position-absolute create-organization-btn-wrapper">
                        <Button
                          onClick={createNewFile}
                          color="success"
                          className="btn btn-pill p-0"
                        >
                          <FontAwesomeIcon icon={faPlus} />
                        </Button>
                      </div>
                    )
                  }
                </CardHeader>
                <CardBody className="p-5">
                  <CasefilesForPatientTable
                    closeModalAndRefresh={closeModalAndRefresh}
                    casefile={casefile}
                    auth={auth}
                    openModal={openModal}
                    tableData={patientFiles}
                  />
                </CardBody>
              </Card>
            )
          }
        </Col>
      </Row>
    </Container>
  );
}
const mapStateToProps = state => ({
  auth: state.auth,
  casefiles: state.casefiles.casefiles,
  selectedCasefile: state.casefiles.selectedCasefile,
});

const mapDispatchToProps = dispatch => ({
  getSingleCasefile: id => dispatch(casefilesOperations.getSingleCasefile(id)),
  selectCasefile: data => dispatch(casefilesOperations.selectCasefile(data)),
  createFile: data => dispatch(casefilesOperations.createFile(data)),
  updateFile: data => dispatch(casefilesOperations.updateFile(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CasefileView);
