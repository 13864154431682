import React, { useState, useEffect } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Container, NavItem, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faEye, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import PatientFormView from './PatientFormView';
import { patientsOperations } from '../../../../redux/ducks/patients';
import PatientCasefileTable from '../../Casefiles/components/PatientCasefileTable';

function PatientView({ location, navigate, getSinglePatient, auth, downloadPatient, }) {
  const [patient, setPatient] = useState(null);

  useEffect(() => {
    if (!auth.view.includes('PATIENT_LIST')) {
      setTimeout(() => { navigate('/'); }, 10);
    } else {
      const splitUrl = location.pathname.split('/patient/')[1];
      getPatientData(splitUrl);
    }
  }, []);

  const getPatientData = url => {
    const id = url.split('/')[0];
    getSinglePatient(id)
      .then((res => {
        setPatient(res.data.data);
      }))
      .catch((err => {
        console.log('err: ', err.data.message);
      }));
  };

  const downloadPatientData = () => {
    downloadPatient(patient.id)
      .then(response => {
        // this.setState({ loading: false, });
        const timestamp = new Date().valueOf();
        const fileName = `${timestamp}.pdf`;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
      })
      .catch(err => {
        console.log('err: ', err);
      });
  };

  const goBack = () => {
    const { state, } = location;
    if (state) {
      const { state: { from: { search, pathname, }, }, } = location;
      navigate(`${pathname}${search}`);
    } else {
      navigate('/casefile');
    }
  };

  return (
    <Container fluid className="d-flex ">
      <Row className="w-100 m-0 justify-content-center pb-5">
        <Col lg={10}>
          {auth?.update.includes('PATIENT_UPDATE') ? (
            <Card className="mt-3">
              <CardHeader>
                <h3 className="mb-0">Beneficiar</h3>
                <small>
                  ID:
                  {patient && patient.customId}
                </small>
                {auth?.view.includes('PATIENT_LIST') && (
                  <div className="text-primary cursor-pointer" onClick={downloadPatientData}>Descarca Anexa 8</div>
                )}
              </CardHeader>
              {
                patient && (
                  <PatientFormView data={patient} location={location} navigate={navigate} />
                )
              }
            </Card>
          ) : (
            <Card className="mt-3">
              <CardHeader>
                <h3 className="mb-0">Beneficiar</h3>
                {auth?.view.includes('PATIENT_LIST') && (
                  <div className="text-primary cursor-pointer" onClick={downloadPatientData}>Descarca Anexa 8</div>
                )}
              </CardHeader>
              {
                patient && (
                  <PatientFormView simple data={patient} location={location} navigate={navigate} />
                )
              }
            </Card>
          )}
          {
            patient?.casefile && (
              <Card className="mt-3">
                <CardHeader>
                  <h3 className="mb-0">
                    <span>Dosar</span>
                    <NavLink
                      to={`/casefile/${patient.casefile.id}/view`}
                      state={{ from: location, }}
                      className="btn btn-info btn-sm btn-pill ml-2"
                    >
                      <FontAwesomeIcon icon={faEye} />
                    </NavLink>
                  </h3>
                </CardHeader>
                <CardBody className="p-5">
                  <PatientCasefileTable
                    casefile={patient.casefile}
                  />
                </CardBody>
              </Card>
            )
          }
          <Row className="mt-3">
            <Col className="text-right">
              <Button
                className="mr-1"
                onClick={goBack}
                color="secondary"
              >
                <FontAwesomeIcon icon={faArrowLeft} />
                <span className="ml-1">Înapoi</span>
              </Button>
              {/* <Button */}
              {/*  className="mr-1" */}
              {/*  color="info" */}
              {/* > */}
              {/*  <span className="ml-1">PDF/DOCX</span> */}
              {/* </Button> */}
              {/* <Button */}
              {/*  className="mr-1" */}
              {/*  color="primary" */}
              {/* > */}
              {/*  <FontAwesomeIcon icon={faDownload} /> */}
              {/*  <span className="ml-1">Salvare raport</span> */}
              {/* </Button> */}
              {/* <Button */}
              {/*  className="mr-1" */}
              {/*  color="primary" */}
              {/* > */}
              {/*  <FontAwesomeIcon icon={faUpload} /> */}
              {/*  <span className="ml-1">Suport psiho-oncologic</span> */}
              {/* </Button> */}
              {
                auth?.update.includes('PATIENT_UPDATE') && patient?.active && (
                  <NavLink to={`/patient/${patient?.id || ''}/edit`}>
                    <Button
                      color="primary"
                    >
                      <FontAwesomeIcon icon={faPencilAlt} />
                      <span className="ml-1">Editare</span>
                    </Button>
                  </NavLink>
                )
              }
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

const mapStateToProps = state => ({
  auth: state.auth,
  patients: state.patients.patients,
  selectedPatient: state.patients.selectedPatient,
});

const mapDispatchToProps = dispatch => ({
  getSinglePatient: id => dispatch(patientsOperations.getSinglePatient(id)),
  downloadPatient: id => dispatch(patientsOperations.downloadPatient(id)),
  selectPatient: data => dispatch(patientsOperations.selectPatient(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PatientView);
