import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, CardHeader, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import moment from 'moment';

function ConsultatieSiRecoltareView({ data, changeTab, }) {
  const [inputs, setInputs] = useState({});

  const checkboxInputs = [
    'indrumarePrelevareSec2FS1',
    'statusPostvaccinareHPV',
    'purtatoareIUDSec2FS1',
    'leucoreeSec2FS1',
    'cauterizariColSec2FS1',
    'biopsiiDeColSec2FS1',
    'cervicalaSec2FS1'
  ];

  useEffect(() => {
    const inputs = data.attributes;
    setInputs(inputs);
    if (Object.keys(inputs).length === 0) return;
    checkboxInputs.forEach(elem => {
      inputs[elem].value = inputs[elem].value.toString();
    });
  }, []);

  const setTab = e => { changeTab(e.target.id); };

  const {
    recoltareFlaconLabelSec2FS1,
    centruRecoltareSec2FS1,
    indrumarePrelevareSec2FS1,
    medicRecoltorSec2FS1,
    numarFlaconSauLama,
    dataRecoltareSec2FS1,
    dataUltimeiMenstruatiiSec2FS1,
    statusPostvaccinareHPV,
    statusHormonalSec2FS1,
    aspectColOptiuniSec2FS1,
    statusPostTerapeuticListSec2FS1,
    antecedenteColSec2FS1,
    optiuniSangerariSec2FS1 = [],
    purtatoareIUDSec2FS1,
    leucoreeSec2FS1,
    cauterizariColSec2FS1,
    biopsiiDeColSec2FS1,
    cervicalaSec2FS1,
    HPVSec2FS1,
    citologieSec2FS1,
    diagnosticBiopsieColSec2FS1,
  } = inputs;

  return (
    <Card>
      <CardHeader>
        <h3 className="mb-0">
          <div className="d-flex">
            <div>
              <span className="mr-3">2. Consultație şi recoltare</span>
            </div>
            <div className="ml-auto">
              <Button id="-" size="sm" color="secondary" className="ml-auto btn-pill" onClick={setTab}>{'<'}</Button>
              <Button id="+" size="sm" color="primary" className="ml-1 btn-pill" onClick={setTab}>{'>'}</Button>
            </div>
          </div>
        </h3>
      </CardHeader>
      <CardBody className="pb-5">
        <Col
          className="underline-hover form-group dont-display ng-untouched ng-pristine"
          style={{ backgroundColor: 'rgba(233, 236, 239, 0.3)', }}
        >
          <Label>
            Recoltarea flaconului/lamei
            {' '}
            <span>{recoltareFlaconLabelSec2FS1?.value || ''}</span>
          </Label>
        </Col>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>Centru recoltare</Label>
              <Input
                required
                type="text"
                name="centruRecoltareSec2FS1"
                disabled
                value={centruRecoltareSec2FS1?.value || ''}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup check>
              <Input
                type="checkbox"
                value={indrumarePrelevareSec2FS1?.value || false}
                checked={indrumarePrelevareSec2FS1?.value === 'true'}
                name="indrumarePrelevareSec2FS1"
                onChange={() => {}}
              />
              <Label>Auto-prelevare sub îndrumare medic </Label>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>Medic/AMC/Recoltor</Label>
              <Input
                required
                type="text"
                name="medicRecoltorSec2FS1"
                disabled
                value={medicRecoltorSec2FS1?.value || ''}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label>
                Numărul flaconului/lamei (autogenerat de sistem după salvare)
              </Label>
              <Input
                required
                type="text"
                name="numarFlaconSauLama"
                disabled
                value={numarFlaconSauLama?.value || ''}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>Data recoltării flaconului/lamei *</Label>
              <Input
                required
                type="text"
                name="dataRecoltareSec2FS1"
                disabled
                value={dataRecoltareSec2FS1?.value ? moment(dataRecoltareSec2FS1.value).format('DD-MM-YYYY') : ''}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label>
                Data ultimei menstruații
              </Label>
              <Input
                required
                type="text"
                name="dataUltimeiMenstruatiiSec2FS1"
                disabled
                value={dataUltimeiMenstruatiiSec2FS1?.value ? moment(dataUltimeiMenstruatiiSec2FS1.value).format('DD-MM-YYYY') : ''}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <FormGroup check>
              <Input
                type="checkbox"
                value={statusPostvaccinareHPV?.value || false}
                checked={statusPostvaccinareHPV?.value === 'true'}
                name="statusPostvaccinareHPV"
                onChange={() => {}}
              />
              <Label>Status postvaccinare HPV </Label>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label>
                Status hormonal
              </Label>
              <Input
                required
                type="text"
                name="statusHormonalSec2FS1"
                disabled
                value={statusHormonalSec2FS1?.value || ''}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>
                Status post-terapeutic
              </Label>
              <Input
                required
                type="text"
                name="statusPostTerapeuticListSec2FS1"
                disabled
                value={statusPostTerapeuticListSec2FS1?.value ? statusPostTerapeuticListSec2FS1.value.map(a => a.value).join(', ') : []}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label>
                Aspectul colului
              </Label>
              <Input
                required
                type="text"
                name="aspectColOptiuniSec2FS1"
                disabled
                value={aspectColOptiuniSec2FS1?.value || ''}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>
                Sângerari
              </Label>
              <Input
                required
                type="text"
                name="optiuniSangerariSec2FS1"
                disabled
                value={optiuniSangerariSec2FS1?.value ? optiuniSangerariSec2FS1.value.map(a => a.value).join(', ') : []}
              />
            </FormGroup>
          </Col>
        </Row>
        <Col
          className="underline-hover form-group dont-display ng-untouched ng-pristine"
          style={{ backgroundColor: 'rgba(233, 236, 239, 0.3)', }}
        >
          <Label>În antecedente</Label>
        </Col>

        <Row>
          <Col md={6}>
            <FormGroup check>
              <Input
                type="checkbox"
                value={purtatoareIUDSec2FS1?.value || false}
                checked={purtatoareIUDSec2FS1?.value === 'true'}
                name="purtatoareIUDSec2FS1"
                onChange={() => {}}
              />
              <Label>Purtătoare de IUD </Label>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup check>
              <Input
                type="checkbox"
                value={leucoreeSec2FS1?.value || false}
                checked={leucoreeSec2FS1?.value === 'true'}
                name="leucoreeSec2FS1"
                onChange={() => {}}
              />
              <Label>Leucoree </Label>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup check>
              <Input
                type="checkbox"
                value={cauterizariColSec2FS1?.value || false}
                checked={cauterizariColSec2FS1?.value === 'true'}
                name="cauterizariColSec2FS1"
                onChange={() => {}}
              />
              <Label> Cauterizări ale colului </Label>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup check>
              <Input
                type="checkbox"
                value={biopsiiDeColSec2FS1?.value || false}
                checked={biopsiiDeColSec2FS1?.value === 'true'}
                name="biopsiiDeColSec2FS1"
                onChange={() => {}}
              />
              <Label>Biopsii de col  </Label>
            </FormGroup>
          </Col>
          {
            biopsiiDeColSec2FS1?.value === 'true' && (
              <Col>
                <FormGroup>
                  <Label>Diagnostic/Descriere</Label>
                  <Input
                    type="textarea"
                    rows={3}
                    name="diagnosticBiopsieColSec2FS1"
                    disabled
                    value={diagnosticBiopsieColSec2FS1?.value || ''}
                  />
                </FormGroup>
              </Col>
            )
          }

        </Row>
        <Col
          className="underline-hover form-group dont-display ng-untouched ng-pristine"
          style={{ backgroundColor: 'rgba(233, 236, 239, 0.3)', }}
        >
          <Label>Origine eșantion </Label>
        </Col>
        <Row>
          <Col md={12}>
            <FormGroup check>
              <Input
                type="checkbox"
                value={cervicalaSec2FS1?.value || false}
                checked={cervicalaSec2FS1?.value === 'true'}
                name="cervicalaSec2FS1"
                onChange={() => {}}
              />
              <Label> Cervicala </Label>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>Citologie</Label>
              <Input
                required
                type="text"
                name="citologieSec2FS1"
                disabled
                value={citologieSec2FS1?.value || ''}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup check>
              <Label>HPV</Label>
              <Input
                required
                type="text"
                name="HPVSec2FS1"
                disabled
                value={HPVSec2FS1?.value || ''}
              />
            </FormGroup>
          </Col>
        </Row>

      </CardBody>
    </Card>
  );
}

export default ConsultatieSiRecoltareView;
