import React, { useEffect, useState } from 'react';
import { faRedoAlt, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import Select from 'react-select';
import { regionOptions, countyOptions, countyOptionsAll } from '../../Organization/organization.helper';
import IndicatorSectionFilter from './IndicatorSectionFilter';

const stareFisa = [
  { value: 'IN_LUCRU', label: 'IN LUCRU', },
  { value: 'FINALIZATA', label: 'FINALIZATA', }
];

const ageCategories = [
  { value: '24-29', label: '24-29', },
  { value: '30-64', label: '30-64', }
];

function IndicatorFiltersExtended({ resetKey, organizations, auth, handleSearch, addFilterAndSearch, resetFilters, filters, }) {
  const [region, setRegion] = useState('');
  const [county, setCounty] = useState('');
  const [ageGroup, setAgeGroup] = useState('');
  const [lastStatus, setLastStatus] = useState('');

  useEffect(() => {
    if (auth.region) {
      const userRegion = { value: auth.region.id, label: auth.region.value, };
      setRegion(userRegion);
    } else {
      const { region: regionFilter, } = filters;
      if (regionFilter && regionFilter !== '') {
        const findRegion = regionOptions.find(elem => elem.value === regionFilter);
        setRegion(findRegion);
      }
    }
  }, [filters.region]);

  useEffect(() => {
    const { county: countyFilter, } = filters;
    if (countyFilter && countyFilter !== '') {
      const findCounty = countyOptions.find(elem => elem.value === countyFilter);
      setCounty(findCounty);
    }
  }, [filters.county]);

  useEffect(() => {
    if (filters.lastStatus !== '') {
      const selectedStatus = stareFisa.find(a => a.value === filters.lastStatus);
      setLastStatus(selectedStatus);
    }
  }, [filters.lastStatus]);

  useEffect(() => {
    if (filters.ageGroup !== '') {
      const selectedAgeGroup = ageCategories.find(a => a.value === filters.ageGroup);
      setAgeGroup(selectedAgeGroup);
    }
  }, [filters.ageGroup]);

  const handleAgeChange = selected => {
    if (selected === null) {
      setAgeGroup('');
      addFilterAndSearch({ region: filters?.region || '', county: filters?.county || '', }, false, 'ageGroup');
    } else {
      setAgeGroup(selected);
      addFilterAndSearch({ region: filters?.region || '', county: filters?.county || '', ageGroup: selected.value, });
    }
  };

  const handleStatusChange = selected => {
    if (selected === null) {
      setLastStatus('');
      addFilterAndSearch({ region: filters?.region || '', county: filters?.county || '', }, false, 'lastStatus');
    } else {
      setLastStatus(selected);
      addFilterAndSearch({ region: filters?.region || '', county: filters?.county || '', lastStatus: selected.value, });
    }
  };

  const handleRegionChange = selected => {
    if (selected === null) {
      setRegion('');
      setCounty('');
      addFilterAndSearch({ county: '', }, false, 'region');
      addFilterAndSearch({ county: '', }, false, 'county');
    } else {
      setRegion(selected);
      setCounty('');
      addFilterAndSearch({ county: '', region: selected.value, }, false, 'county');
    }
  };

  const handleCountyChange = selected => {
    if (selected === null) {
      setCounty('');
      addFilterAndSearch({ region: filters?.region || '', }, false, 'county');
    } else {
      setCounty(selected);
      addFilterAndSearch({ region: filters?.region || '', county: selected.value, });
    }
  };

  const handleChange = (selected, details) => {
    const { name, } = details;

    if (selected === null) {
      addFilterAndSearch({ [name]: '', }, false, name);
    } else {
      const { value, } = selected;
      addFilterAndSearch({ [name]: value, });
    }
  };

  const handleOrganizationChange = (selected, details) => {
    const { name, } = details;
    const section = name.substring(0, 2);

    if (selected === null) {
      addFilterAndSearch({ [name]: '', }, false, 'active');
    } else {
      const { id, } = selected;
      addFilterAndSearch({ [name]: id, });
    }
  };

  const handleResetFilters = () => {
    if (auth.region) {
      setCounty('');
      resetFilters();
    } else {
      setRegion('');
      setCounty('');
      resetFilters();
    }
  };
  return (
    <div className="searchFormFields mt-4">
      <Row>
        <Col className="text-right">
          <Button
            onClick={handleResetFilters}
            className="btn btn-light btn-sm btn-pill"
          >
            <FontAwesomeIcon icon={faRedoAlt} />
          </Button>
          <Button
            onClick={handleSearch}
            className="btn btn-light btn-sm btn-pill"
          >
            <FontAwesomeIcon icon={faSearch} />
          </Button>
        </Col>
      </Row>

      <Row>
        <Col>
          <FormGroup>
            <Label>
              <small className="ml-sm-2 font-weight-bold">Regiune</small>
            </Label>
            {
              auth.region ? (
                <Input
                  value={region?.label || ''}
                  type="text"
                  className="form-control"
                  id="region"
                  placeholder="Regiune"
                  disabled
                />
              ) : (
                <Select
                  isClearable
                  value={region}
                  onChange={handleRegionChange}
                  className="basic-single"
                  classNamePrefix="select"
                  placeholder="Regiune"
                  name="region"
                  options={regionOptions}
                />
              )
            }

          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label>
              <small className="ml-sm-2 font-weight-bold">Judet</small>
            </Label>
            <Select
              isClearable
              value={county}
              onChange={handleCountyChange}
              className="basic-single"
              classNamePrefix="select"
              placeholder="Judet"
              name="county"
              options={auth.region ? countyOptionsAll[region.value]
                : region !== '' ? countyOptionsAll[region.value] : countyOptions}
            />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label>
              <small className="ml-sm-2 font-weight-bold">Varsta</small>
            </Label>
            <Select
              isClearable
              value={ageGroup}
              onChange={handleAgeChange}
              className="basic-single"
              classNamePrefix="select"
              placeholder="Varsta"
              name="ageGroup"
              options={ageCategories}
            />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label>
              <small className="ml-sm-2 font-weight-bold">Stare</small>
            </Label>
            <Select
              isClearable
              value={lastStatus}
              onChange={handleStatusChange}
              className="basic-single"
              classNamePrefix="select"
              placeholder="Stare"
              name="lastStatus"
              options={stareFisa}
            />
          </FormGroup>
        </Col>
      </Row>
      <IndicatorSectionFilter
        title="Informare şi consiliere"
        handleOrganizationChange={handleOrganizationChange}
        handleChange={handleChange}
        organizations={organizations}
        type="s1"
        resetKey={resetKey}
        filters={filters}
      />
      <IndicatorSectionFilter
        title="Consultație şi recoltare"
        handleOrganizationChange={handleOrganizationChange}
        handleChange={handleChange}
        organizations={organizations}
        type="s2"
        resetKey={resetKey}
        filters={filters}
      />
      <IndicatorSectionFilter
        title="Testare HPV"
        handleOrganizationChange={handleOrganizationChange}
        handleChange={handleChange}
        organizations={organizations}
        type="s3A"
        resetKey={resetKey}
        filters={filters}
      />
      <IndicatorSectionFilter
        title="Testare BPN"
        handleOrganizationChange={handleOrganizationChange}
        handleChange={handleChange}
        organizations={organizations}
        type="s3B"
        resetKey={resetKey}
        filters={filters}
      />
    </div>
  );
}

export default IndicatorFiltersExtended;
