import React, { useState } from 'react';
import {
  Button,
  Col,
  FormGroup, Input,
  Label
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import DoctorAddressEntry from './DoctorAddressEntry';
// import DoctorAddressModal from './DoctorAddressModal';
import DeleteDoctorAddressModal from './DeleteDoctorAddressModal';
import OrganizationAddressModal from '../../Organization/components/OrganizationAddressModal';

function DoctorAddresses({ addresses = [], handleAddressChange, readOnly, isRequired, }) {
  const [showModal, setShowModal] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [selected, setSelected] = useState(null);

  const showAddressModal = data => {
    setSelected(data);
    setShowModal(true);
  };

  const showDeleteModal = data => {
    setShowDelete(data);
  };

  const setAddress = data => {
    handleAddressChange(data, 'add');
  };

  const closeAddressModal = () => { setShowModal(false); };

  const closeDeleteModal = () => { setShowDelete(false); };
  const confirmDelete = data => {
    handleAddressChange(data, 'remove');
    closeDeleteModal();
  };

  const domAddresses = addresses.map((a, i) => {
    if (typeof a.id === 'undefined' || a.id === null) {
      a.id = `${a?.city?.value || ''}${a?.streetNo || ''}${a?.apartmentNo || ''}`;
    }
    return (
      <DoctorAddressEntry
        key={i}
        address={a}
        showAddressModal={showAddressModal}
        showDeleteModal={showDeleteModal}
        readOnly={readOnly}
      />
    );
  });

  return (
    <>
      { showModal && (
      <OrganizationAddressModal
        close={closeAddressModal}
        address={selected}
        setAddress={setAddress}
      />
      )}
      { showDelete && (
      <DeleteDoctorAddressModal
        address={showDelete}
        close={closeDeleteModal}
        confirm={confirmDelete}
      />
      )}
      { readOnly ? (
        <FormGroup row>
          <Label sm={3}>Adrese</Label>
          <Col sm={9}>
            {domAddresses}
          </Col>
        </FormGroup>
      ) : (
        <>
          <FormGroup className="mt-3 mb-1" row>
            <Col>
              <Button
                onClick={() => showAddressModal(null)}
                color={isRequired && domAddresses.length === 0 ? 'danger' : 'secondary'}
                className="btn btn-pill p-0"
              >
                <FontAwesomeIcon icon={faPlus} />
              </Button>
              <Label className="ml-3">Adrese</Label>
              { isRequired && domAddresses.length === 0 && (
                <small className="form-text text-danger">
                  Acest câmp este necesar.
                </small>
              )}
            </Col>
          </FormGroup>
          <div>
            { domAddresses }
          </div>
        </>
      )}
    </>
  );
}

export default DoctorAddresses;
