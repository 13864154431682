import types from './types';

const getPatients = url => ({
  type: types.GET_PATIENTS,
  async: true,
  generalFetching: true,
  payload: {
    path: url,
    method: 'GET',
  },
});

const getSinglePatient = id => ({
  type: types.GET_SINGLE_PATIENT,
  async: true,
  generalFetching: true,
  payload: {
    path: `/patients/${id}`,
    method: 'GET',
  },
});

const downloadPatient = id => ({
  type: types.DOWNLOAD_PATIENT,
  async: true,
  generalFetching: true,
  payload: {
    path: `/patientsreport/${id}`,
    method: 'GET',
    responseType: 'blob',
  },
});

const createPatient = data => ({
  type: types.CREATE_PATIENT,
  async: true,
  generalFetching: true,
  payload: {
    path: '/patients',
    method: 'POST',
    body: data,
  },
});

const updatePatient = data => ({
  type: types.UPDATE_PATIENT,
  async: true,
  generalFetching: true,
  payload: {
    path: `/patients/${data.id}`,
    method: 'PUT',
    body: data,
  },
});

export default {
  getPatients,
  createPatient,
  getSinglePatient,
  downloadPatient,
  updatePatient,
};
