import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortAsc, faSortDesc } from '@fortawesome/free-solid-svg-icons';
import DoctorsTableRow from './DoctorsTableRow';
import DoctorActiveModal from './DoctorActiveModal';

function DoctorsTable({ tableData, setSortFilter, filters, setActiveStatus, auth, }) {
  const [modal, setModal] = useState(false);
  const [selected, setSelected] = useState(null);
  const handleActiveModal = data => {
    setModal(modal !== true);
    setSelected(data);
  };

  const domDoctors = tableData.map(elem => (
    <DoctorsTableRow
      canActivate={auth?.activate.includes('DOCTOR_ACTIVATE') || false}
      canEdit={auth?.update.includes('DOCTOR_UPDATE') || false}
      handleActiveModal={handleActiveModal}
      key={elem.id}
      data={elem}
    />
  ));

  const { sortBy, sortDir, } = filters;

  const calcIcon = field => {
    if (field !== sortBy) {
      return faSort;
    }
    return sortDir === 'asc' ? faSortAsc : faSortDesc;
  };

  return (
    <>
      { modal && (
        <DoctorActiveModal
          selected={selected}
          close={handleActiveModal}
          setActive={setActiveStatus}
        />
      )}
      <div id="entities" className="table-responsive-xl mt-5">
        <table aria-describedby="page-heading" className="table">
          <thead>
            <tr>
              <th onClick={setSortFilter} role="button" id="seal" scope="col">
                <span className="events-none">Parafă</span>
                <FontAwesomeIcon
                  className="ml-1 events-none"
                  icon={calcIcon('seal')}
                />
              </th>
              <th onClick={setSortFilter} role="button" id="casContract" scope="col">
                <span className="events-none">CAS Contract</span>
                <FontAwesomeIcon
                  className="ml-1 events-none"
                  icon={calcIcon('casContract')}
                />
              </th>
              <th onClick={setSortFilter} role="button" id="specializationType" scope="col">
                <span className="events-none">Tip</span>
                <FontAwesomeIcon
                  className="ml-1 events-none"
                  icon={calcIcon('specializationType')}
                />
              </th>
              <th onClick={setSortFilter} role="button" id="lastName" scope="col">
                <span className="events-none">Persoană Furnizor</span>
                <FontAwesomeIcon
                  className="ml-1 events-none"
                  icon={calcIcon('lastName')}
                />
              </th>
              <th onClick={setSortFilter} role="button" id="label" scope="col">
                <span className="events-none">Furnizor</span>
                <FontAwesomeIcon
                  className="ml-1 events-none"
                  icon={calcIcon('label')}
                />
              </th>
              <th onClick={setSortFilter} role="button" id="active" scope="col">
                <span className="events-none">Stare</span>
                <FontAwesomeIcon
                  className="ml-1 events-none"
                  icon={calcIcon('active')}
                />
              </th>
              <th scope="col" />
            </tr>
          </thead>
          <tbody>
            { domDoctors}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default DoctorsTable;
