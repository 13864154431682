export const cities = {
  AB: [
    {
      name: 'ALBA IULIA',
      zip: '1026',
      isUrban: true,
    },
    {
      name: 'BARABANT',
      zip: '1035',
      isUrban: true,
    },
    {
      name: 'MICESTI',
      zip: '1044',
      isUrban: true,
    },
    {
      name: 'OARDA',
      zip: '1053',
      isUrban: true,
    },
    {
      name: 'PACLISA',
      zip: '1062',
      isUrban: true,
    },
    {
      name: 'AIUD',
      zip: '1222',
      isUrban: true,
    },
    {
      name: 'AIUDUL DE SUS',
      zip: '1231',
      isUrban: true,
    },
    {
      name: 'CIUMBRUD',
      zip: '1277',
      isUrban: true,
    },
    {
      name: 'GAMBAS',
      zip: '1240',
      isUrban: true,
    },
    {
      name: 'GARBOVA DE JOS',
      zip: '1286',
      isUrban: true,
    },
    {
      name: 'GARBOVA DE SUS',
      zip: '1295',
      isUrban: true,
    },
    {
      name: 'GARBOVITA',
      zip: '1302',
      isUrban: true,
    },
    {
      name: 'MAGINA',
      zip: '1259',
      isUrban: true,
    },
    {
      name: 'PAGIDA',
      zip: '1268',
      isUrban: true,
    },
    {
      name: 'SANCRAI',
      zip: '1311',
      isUrban: true,
    },
    {
      name: 'TIFRA',
      zip: '1320',
      isUrban: true,
    },
    {
      name: 'BLAJ',
      zip: '1357',
      isUrban: true,
    },
    {
      name: 'DELENI-OBIRSIE',
      zip: '1366',
      isUrban: true,
    },
    {
      name: 'FLITESTI',
      zip: '1375',
      isUrban: true,
    },
    {
      name: 'IZVOARELE',
      zip: '1384',
      isUrban: true,
    },
    {
      name: 'MANARADE',
      zip: '1428',
      isUrban: true,
    },
    {
      name: 'PETRISAT',
      zip: '1393',
      isUrban: true,
    },
    {
      name: 'SPATAC',
      zip: '1437',
      isUrban: true,
    },
    {
      name: 'TIUR',
      zip: '1400',
      isUrban: true,
    },
    {
      name: 'VEZA',
      zip: '1419',
      isUrban: true,
    },
    {
      name: 'ABRUD',
      zip: '1160',
      isUrban: true,
    },
    {
      name: 'ABRUD-SAT',
      zip: '1179',
      isUrban: true,
    },
    {
      name: 'GURA CORNEI',
      zip: '1188',
      isUrban: true,
    },
    {
      name: 'SOHARU',
      zip: '1197',
      isUrban: true,
    },
    {
      name: 'CAMPENI',
      zip: '1464',
      isUrban: true,
    },
    {
      name: 'BONCESTI',
      zip: '1473',
      isUrban: true,
    },
    {
      name: 'BORLESTI',
      zip: '1482',
      isUrban: true,
    },
    {
      name: 'BOTESTI',
      zip: '1491',
      isUrban: true,
    },
    {
      name: 'CERTEGE',
      zip: '1507',
      isUrban: true,
    },
    {
      name: 'COASTA VASCULUI',
      zip: '1516',
      isUrban: true,
    },
    {
      name: 'DANDUT',
      zip: '1525',
      isUrban: true,
    },
    {
      name: 'DEALU BISTRII',
      zip: '1534',
      isUrban: true,
    },
    {
      name: 'DEALU CAPSEI',
      zip: '1543',
      isUrban: true,
    },
    {
      name: 'DRIC',
      zip: '1552',
      isUrban: true,
    },
    {
      name: 'FATA ABRUDULUI',
      zip: '1561',
      isUrban: true,
    },
    {
      name: 'FLORESTI',
      zip: '1570',
      isUrban: true,
    },
    {
      name: 'FURDUIESTI',
      zip: '1589',
      isUrban: true,
    },
    {
      name: 'MIHOESTI',
      zip: '1598',
      isUrban: true,
    },
    {
      name: 'MOTORASTI',
      zip: '1605',
      isUrban: true,
    },
    {
      name: 'PESTE VALEA BISTRII',
      zip: '1614',
      isUrban: true,
    },
    {
      name: 'PODURI',
      zip: '1623',
      isUrban: true,
    },
    {
      name: 'SORLITA',
      zip: '1632',
      isUrban: true,
    },
    {
      name: 'TOMUSESTI',
      zip: '1641',
      isUrban: true,
    },
    {
      name: 'VALEA BISTRII',
      zip: '1650',
      isUrban: true,
    },
    {
      name: 'VALEA CASELOR',
      zip: '1669',
      isUrban: true,
    },
    {
      name: 'VARSI',
      zip: '1678',
      isUrban: true,
    },
    {
      name: 'CUGIR',
      zip: '1703',
      isUrban: true,
    },
    {
      name: 'BOCSITURA',
      zip: '1712',
      isUrban: true,
    },
    {
      name: 'BUCURU',
      zip: '1721',
      isUrban: true,
    },
    {
      name: 'CALENE',
      zip: '1730',
      isUrban: true,
    },
    {
      name: 'FETENI',
      zip: '1749',
      isUrban: true,
    },
    {
      name: 'GOASELE',
      zip: '1758',
      isUrban: true,
    },
    {
      name: 'MUGESTI',
      zip: '1767',
      isUrban: true,
    },
    {
      name: 'VINEREA',
      zip: '1776',
      isUrban: true,
    },
    {
      name: 'OCNA MURES',
      zip: '1801',
      isUrban: true,
    },
    {
      name: 'CISTEIU DE MURES',
      zip: '1838',
      isUrban: true,
    },
    {
      name: 'MICOSLACA',
      zip: '1847',
      isUrban: true,
    },
    {
      name: 'RAZBOIENI-CETATE',
      zip: '1856',
      isUrban: true,
    },
    {
      name: 'UIOARA DE JOS',
      zip: '1810',
      isUrban: true,
    },
    {
      name: 'UIOARA DE SUS',
      zip: '1829',
      isUrban: true,
    },
    {
      name: 'SEBES',
      zip: '1883',
      isUrban: true,
    },
    {
      name: 'LANCRAM',
      zip: '1892',
      isUrban: true,
    },
    {
      name: 'PETRESTI',
      zip: '1909',
      isUrban: true,
    },
    {
      name: 'RAHAU',
      zip: '1918',
      isUrban: true,
    },
    {
      name: 'TEIUS',
      zip: '8103',
      isUrban: true,
    },
    {
      name: 'BELDIU',
      zip: '8112',
      isUrban: true,
    },
    {
      name: 'CAPUD',
      zip: '8121',
      isUrban: true,
    },
    {
      name: 'COSLARIU NOU',
      zip: '8130',
      isUrban: true,
    },
    {
      name: 'PETELCA',
      zip: '8149',
      isUrban: true,
    },
    {
      name: 'ZLATNA',
      zip: '1945',
      isUrban: true,
    },
    {
      name: 'BOTESTI',
      zip: '1954',
      isUrban: true,
    },
    {
      name: 'BUDENI',
      zip: '1963',
      isUrban: true,
    },
    {
      name: 'DEALU ROATEI',
      zip: '1972',
      isUrban: true,
    },
    {
      name: 'DOBROT',
      zip: '1981',
      isUrban: true,
    },
    {
      name: 'DUMBRAVA',
      zip: '1990',
      isUrban: true,
    },
    {
      name: 'FENES',
      zip: '2005',
      isUrban: true,
    },
    {
      name: 'GALATI',
      zip: '2014',
      isUrban: true,
    },
    {
      name: 'IZVORU AMPOIULUI',
      zip: '2023',
      isUrban: true,
    },
    {
      name: 'PARAU GRUIULUI',
      zip: '2050',
      isUrban: true,
    },
    {
      name: 'PATRANGENI',
      zip: '2032',
      isUrban: true,
    },
    {
      name: 'PIRITA',
      zip: '2041',
      isUrban: true,
    },
    {
      name: 'PODU LUI PAUL',
      zip: '2069',
      isUrban: true,
    },
    {
      name: 'RUNC',
      zip: '2078',
      isUrban: true,
    },
    {
      name: 'RUSI',
      zip: '2087',
      isUrban: true,
    },
    {
      name: 'SUSENI',
      zip: '2096',
      isUrban: true,
    },
    {
      name: 'TRAMPOIELE',
      zip: '2103',
      isUrban: true,
    },
    {
      name: 'VALEA MICA',
      zip: '2112',
      isUrban: true,
    },
    {
      name: 'VALTORI',
      zip: '2121',
      isUrban: true,
    },
    {
      name: 'ALBAC',
      zip: '2149',
      isUrban: false,
    },
    {
      name: 'BARASTI',
      zip: '2158',
      isUrban: false,
    },
    {
      name: 'BUDAIESTI',
      zip: '2167',
      isUrban: false,
    },
    {
      name: 'CIONESTI',
      zip: '2176',
      isUrban: false,
    },
    {
      name: 'COSTESTI',
      zip: '2185',
      isUrban: false,
    },
    {
      name: 'DEALU LAMASOI',
      zip: '2194',
      isUrban: false,
    },
    {
      name: 'DEVE',
      zip: '2201',
      isUrban: false,
    },
    {
      name: 'DUPA PLESE',
      zip: '2210',
      isUrban: false,
    },
    {
      name: 'FATA',
      zip: '2229',
      isUrban: false,
    },
    {
      name: 'PLESESTI',
      zip: '2238',
      isUrban: false,
    },
    {
      name: 'POTIONCI',
      zip: '2247',
      isUrban: false,
    },
    {
      name: 'ROGOZ',
      zip: '2256',
      isUrban: false,
    },
    {
      name: 'ROSESTI',
      zip: '2265',
      isUrban: false,
    },
    {
      name: 'RUSESTI',
      zip: '2274',
      isUrban: false,
    },
    {
      name: 'SOHODOL',
      zip: '2283',
      isUrban: false,
    },
    {
      name: 'TAMBORESTI',
      zip: '2292',
      isUrban: false,
    },
    {
      name: 'ALMASU MARE',
      zip: '2318',
      isUrban: false,
    },
    {
      name: 'ALMASU DE MIJLOC',
      zip: '2327',
      isUrban: false,
    },
    {
      name: 'BRADET',
      zip: '2336',
      isUrban: false,
    },
    {
      name: 'CHEILE CIBULUI',
      zip: '2345',
      isUrban: false,
    },
    {
      name: 'GLOD',
      zip: '2363',
      isUrban: false,
    },
    {
      name: 'NADASTIA',
      zip: '2372',
      isUrban: false,
    },
    {
      name: 'ARIESENI',
      zip: '2390',
      isUrban: false,
    },
    {
      name: 'AVRAMESTI',
      zip: '2407',
      isUrban: false,
    },
    {
      name: 'BUBESTI',
      zip: '2416',
      isUrban: false,
    },
    {
      name: 'CASA DE PIATRA',
      zip: '2425',
      isUrban: false,
    },
    {
      name: 'COBLES',
      zip: '2434',
      isUrban: false,
    },
    {
      name: 'DEALU BAJULUI',
      zip: '2443',
      isUrban: false,
    },
    {
      name: 'FATA CRISTESEI',
      zip: '2452',
      isUrban: false,
    },
    {
      name: 'FATA LAPUSULUI',
      zip: '2461',
      isUrban: false,
    },
    {
      name: 'GALBENA',
      zip: '2470',
      isUrban: false,
    },
    {
      name: 'HODOBANA',
      zip: '2489',
      isUrban: false,
    },
    {
      name: 'IZLAZ',
      zip: '2498',
      isUrban: false,
    },
    {
      name: 'PANTESTI',
      zip: '2504',
      isUrban: false,
    },
    {
      name: 'PATRAHAITESTI',
      zip: '2513',
      isUrban: false,
    },
    {
      name: 'POIENITA',
      zip: '2522',
      isUrban: false,
    },
    {
      name: 'RAVICESTI',
      zip: '2531',
      isUrban: false,
    },
    {
      name: 'STEI-ARIESENI',
      zip: '2559',
      isUrban: false,
    },
    {
      name: 'STURU',
      zip: '2540',
      isUrban: false,
    },
    {
      name: 'VANVUCESTI',
      zip: '2568',
      isUrban: false,
    },
    {
      name: 'AVRAM IANCU',
      zip: '2586',
      isUrban: false,
    },
    {
      name: 'ACHIMETESTI',
      zip: '2595',
      isUrban: false,
    },
    {
      name: 'AVRAMESTI',
      zip: '2602',
      isUrban: false,
    },
    {
      name: 'BADAI',
      zip: '2611',
      isUrban: false,
    },
    {
      name: 'BOLDESTI',
      zip: '2620',
      isUrban: false,
    },
    {
      name: 'CALUGARESTI',
      zip: '2639',
      isUrban: false,
    },
    {
      name: 'CARASTI',
      zip: '2666',
      isUrban: false,
    },
    {
      name: 'CARTULESTI',
      zip: '2675',
      isUrban: false,
    },
    {
      name: 'CASOAIA',
      zip: '2648',
      isUrban: false,
    },
    {
      name: 'CINDESTI',
      zip: '2657',
      isUrban: false,
    },
    {
      name: 'COCESTI',
      zip: '2684',
      isUrban: false,
    },
    {
      name: 'COCOSESTI',
      zip: '2693',
      isUrban: false,
    },
    {
      name: 'COROIESTI',
      zip: '2700',
      isUrban: false,
    },
    {
      name: 'DEALU CRISULUI',
      zip: '2719',
      isUrban: false,
    },
    {
      name: 'DOLESTI',
      zip: '2728',
      isUrban: false,
    },
    {
      name: 'DUMACESTI',
      zip: '2737',
      isUrban: false,
    },
    {
      name: 'GOJEIESTI',
      zip: '2746',
      isUrban: false,
    },
    {
      name: 'HELERESTI',
      zip: '2755',
      isUrban: false,
    },
    {
      name: 'INCESTI',
      zip: '2764',
      isUrban: false,
    },
    {
      name: 'JOJEI',
      zip: '2773',
      isUrban: false,
    },
    {
      name: 'MARTESTI',
      zip: '2782',
      isUrban: false,
    },
    {
      name: 'ORGESTI',
      zip: '2791',
      isUrban: false,
    },
    {
      name: 'PATRUTESTI',
      zip: '2808',
      isUrban: false,
    },
    {
      name: 'PLAI',
      zip: '2817',
      isUrban: false,
    },
    {
      name: 'PUSELESTI',
      zip: '2826',
      isUrban: false,
    },
    {
      name: 'SOICESTI',
      zip: '2835',
      isUrban: false,
    },
    {
      name: 'STERTESTI',
      zip: '2844',
      isUrban: false,
    },
    {
      name: 'TARSA',
      zip: '2853',
      isUrban: false,
    },
    {
      name: 'TARSA-PLAI',
      zip: '2862',
      isUrban: false,
    },
    {
      name: 'VALEA MACIULUI',
      zip: '2871',
      isUrban: false,
    },
    {
      name: 'VALEA UTULUI',
      zip: '2880',
      isUrban: false,
    },
    {
      name: 'VERDESTI',
      zip: '2899',
      isUrban: false,
    },
    {
      name: 'VIDRISOARA',
      zip: '2906',
      isUrban: false,
    },
    {
      name: 'BAIA DE ARIES',
      zip: '2924',
      isUrban: true,
    },
    {
      name: 'BRAZESTI',
      zip: '2933',
      isUrban: true,
    },
    {
      name: 'CIOARA DE SUS',
      zip: '2942',
      isUrban: true,
    },
    {
      name: 'MUNCELU',
      zip: '2951',
      isUrban: true,
    },
    {
      name: 'SARTAS',
      zip: '2960',
      isUrban: true,
    },
    {
      name: 'SIMULESTI',
      zip: '2979',
      isUrban: true,
    },
    {
      name: 'BERGHIN',
      zip: '2997',
      isUrban: false,
    },
    {
      name: 'GHIRBOM',
      zip: '3002',
      isUrban: false,
    },
    {
      name: 'HENIG',
      zip: '3011',
      isUrban: false,
    },
    {
      name: 'STRAJA',
      zip: '3020',
      isUrban: false,
    },
    {
      name: 'BISTRA',
      zip: '3048',
      isUrban: false,
    },
    {
      name: 'ARONESTI',
      zip: '3057',
      isUrban: false,
    },
    {
      name: 'BALESTI',
      zip: '3066',
      isUrban: false,
    },
    {
      name: 'BALESTI-CATUN',
      zip: '3075',
      isUrban: false,
    },
    {
      name: 'BARLESTI',
      zip: '3084',
      isUrban: false,
    },
    {
      name: 'CHELETENI',
      zip: '3093',
      isUrban: false,
    },
    {
      name: 'CIULDESTI',
      zip: '3100',
      isUrban: false,
    },
    {
      name: 'CRETESTI',
      zip: '3119',
      isUrban: false,
    },
    {
      name: 'DAMBURENI',
      zip: '3137',
      isUrban: false,
    },
    {
      name: 'DEALU MUNTELUI',
      zip: '3128',
      isUrban: false,
    },
    {
      name: 'DURASTI',
      zip: '3146',
      isUrban: false,
    },
    {
      name: 'GANESTI',
      zip: '3155',
      isUrban: false,
    },
    {
      name: 'GARDE',
      zip: '3164',
      isUrban: false,
    },
    {
      name: 'HODISESTI',
      zip: '3173',
      isUrban: false,
    },
    {
      name: 'HUDRICESTI',
      zip: '3182',
      isUrban: false,
    },
    {
      name: 'LIPAIA',
      zip: '3191',
      isUrban: false,
    },
    {
      name: 'LUNCA LARGA',
      zip: '3208',
      isUrban: false,
    },
    {
      name: 'LUNCA MERILOR',
      zip: '3217',
      isUrban: false,
    },
    {
      name: 'MIHAIESTI',
      zip: '3226',
      isUrban: false,
    },
    {
      name: 'NAMAS',
      zip: '3235',
      isUrban: false,
    },
    {
      name: 'NOVACESTI',
      zip: '3244',
      isUrban: false,
    },
    {
      name: 'PERJESTI',
      zip: '3253',
      isUrban: false,
    },
    {
      name: 'POIANA',
      zip: '3262',
      isUrban: false,
    },
    {
      name: 'POIU',
      zip: '3271',
      isUrban: false,
    },
    {
      name: 'RATITIS',
      zip: '3280',
      isUrban: false,
    },
    {
      name: 'RUNCURI',
      zip: '3299',
      isUrban: false,
    },
    {
      name: 'SALAGESTI',
      zip: '3306',
      isUrban: false,
    },
    {
      name: 'STEFANCA',
      zip: '3315',
      isUrban: false,
    },
    {
      name: 'TARANESTI',
      zip: '3351',
      isUrban: false,
    },
    {
      name: 'TOLACESTI',
      zip: '3324',
      isUrban: false,
    },
    {
      name: 'TOMNATEC',
      zip: '3333',
      isUrban: false,
    },
    {
      name: 'TRISORESTI',
      zip: '3342',
      isUrban: false,
    },
    {
      name: 'VARSI-RONTU',
      zip: '3360',
      isUrban: false,
    },
    {
      name: 'VARSII MARI',
      zip: '3379',
      isUrban: false,
    },
    {
      name: 'VARSII MICI',
      zip: '3388',
      isUrban: false,
    },
    {
      name: 'BLANDIANA',
      zip: '3404',
      isUrban: false,
    },
    {
      name: 'ACMARIU',
      zip: '3413',
      isUrban: false,
    },
    {
      name: 'IBRU',
      zip: '3422',
      isUrban: false,
    },
    {
      name: 'POIENI',
      zip: '3431',
      isUrban: false,
    },
    {
      name: 'RACATAU',
      zip: '3440',
      isUrban: false,
    },
    {
      name: 'BUCIUM',
      zip: '3468',
      isUrban: false,
    },
    {
      name: 'ANGHELESTI',
      zip: '3477',
      isUrban: false,
    },
    {
      name: 'BISERICANI',
      zip: '3486',
      isUrban: false,
    },
    {
      name: 'BUCIUM-SAT',
      zip: '3495',
      isUrban: false,
    },
    {
      name: 'VALENI',
      zip: '3743',
      isUrban: false,
    },
    {
      name: 'CALNIC',
      zip: '4115',
      isUrban: false,
    },
    {
      name: 'CUT',
      zip: '4124',
      isUrban: false,
    },
    {
      name: 'DEAL',
      zip: '4133',
      isUrban: false,
    },
    {
      name: 'CENADE',
      zip: '3770',
      isUrban: false,
    },
    {
      name: 'CAPU DEALULUI',
      zip: '3789',
      isUrban: false,
    },
    {
      name: 'GORGAN',
      zip: '3798',
      isUrban: false,
    },
    {
      name: 'CERGAU MARE',
      zip: '3814',
      isUrban: false,
    },
    {
      name: 'CERGAU MIC',
      zip: '3823',
      isUrban: false,
    },
    {
      name: 'LUPU',
      zip: '3832',
      isUrban: false,
    },
    {
      name: 'CERU-BACAINTI',
      zip: '3850',
      isUrban: false,
    },
    {
      name: 'BOLOVANESTI',
      zip: '3869',
      isUrban: false,
    },
    {
      name: 'BULBUC',
      zip: '3878',
      isUrban: false,
    },
    {
      name: 'CUCUTA',
      zip: '3887',
      isUrban: false,
    },
    {
      name: 'CURPENI',
      zip: '3896',
      isUrban: false,
    },
    {
      name: 'DUMBRAVITA',
      zip: '3903',
      isUrban: false,
    },
    {
      name: 'FANTINELE',
      zip: '3912',
      isUrban: false,
    },
    {
      name: 'GROSI',
      zip: '3921',
      isUrban: false,
    },
    {
      name: 'VALEA MARE',
      zip: '3930',
      isUrban: false,
    },
    {
      name: 'VIEZURI',
      zip: '3949',
      isUrban: false,
    },
    {
      name: 'CETATEA DE BALTA',
      zip: '3967',
      isUrban: false,
    },
    {
      name: 'CRACIUNELU DE SUS',
      zip: '3976',
      isUrban: false,
    },
    {
      name: 'SANTAMARIE',
      zip: '3985',
      isUrban: false,
    },
    {
      name: 'TATARLAUA',
      zip: '3994',
      isUrban: false,
    },
    {
      name: 'CIUGUD',
      zip: '1080',
      isUrban: false,
    },
    {
      name: 'DRAMBAR',
      zip: '1099',
      isUrban: false,
    },
    {
      name: 'LIMBA',
      zip: '1106',
      isUrban: false,
    },
    {
      name: 'HAPRIA',
      zip: '1115',
      isUrban: false,
    },
    {
      name: 'SEUSA',
      zip: '1124',
      isUrban: false,
    },
    {
      name: 'TELEAC',
      zip: '1133',
      isUrban: false,
    },
    {
      name: 'CIURULEASA',
      zip: '4017',
      isUrban: false,
    },
    {
      name: 'BIDIGESTI',
      zip: '4026',
      isUrban: false,
    },
    {
      name: 'BODRESTI',
      zip: '4035',
      isUrban: false,
    },
    {
      name: 'BOGLESTI',
      zip: '4044',
      isUrban: false,
    },
    {
      name: 'BUNINGINEA',
      zip: '4053',
      isUrban: false,
    },
    {
      name: 'GHEDULESTI',
      zip: '4062',
      isUrban: false,
    },
    {
      name: 'MATISESTI',
      zip: '4071',
      isUrban: false,
    },
    {
      name: 'MORARESTI',
      zip: '4080',
      isUrban: false,
    },
    {
      name: 'VULCAN',
      zip: '4099',
      isUrban: false,
    },
    {
      name: 'CRACIUNELU DE JOS',
      zip: '4197',
      isUrban: false,
    },
    {
      name: 'BUCERDEA GRANOASA',
      zip: '4204',
      isUrban: false,
    },
    {
      name: 'CORNU',
      zip: '4213',
      isUrban: false,
    },
    {
      name: 'PADURE',
      zip: '4222',
      isUrban: false,
    },
    {
      name: 'PANCA',
      zip: '4231',
      isUrban: false,
    },
    {
      name: 'CRICAU',
      zip: '4151',
      isUrban: false,
    },
    {
      name: 'CRAIVA',
      zip: '4160',
      isUrban: false,
    },
    {
      name: 'TIBRU',
      zip: '4179',
      isUrban: false,
    },
    {
      name: 'DAIA ROMANA',
      zip: '4259',
      isUrban: false,
    },
    {
      name: 'DOSTAT',
      zip: '4277',
      isUrban: false,
    },
    {
      name: 'BOZ',
      zip: '4286',
      isUrban: false,
    },
    {
      name: 'DEALU DOSTATULUI',
      zip: '4295',
      isUrban: false,
    },
    {
      name: 'FARAU',
      zip: '4311',
      isUrban: false,
    },
    {
      name: 'HERIA',
      zip: '4320',
      isUrban: false,
    },
    {
      name: 'MEDVES',
      zip: '4339',
      isUrban: false,
    },
    {
      name: 'SANBENEDIC',
      zip: '4348',
      isUrban: false,
    },
    {
      name: 'SILEA',
      zip: '4357',
      isUrban: false,
    },
    {
      name: 'GALDA DE JOS',
      zip: '4375',
      isUrban: false,
    },
    {
      name: 'BENIC',
      zip: '4384',
      isUrban: false,
    },
    {
      name: 'CETEA',
      zip: '4393',
      isUrban: false,
    },
    {
      name: 'GALDA DE SUS',
      zip: '4400',
      isUrban: false,
    },
    {
      name: 'LUPSENI',
      zip: '4419',
      isUrban: false,
    },
    {
      name: 'MAGURA',
      zip: '4428',
      isUrban: false,
    },
    {
      name: 'MESENTEA',
      zip: '4437',
      isUrban: false,
    },
    {
      name: 'OIEJDEA',
      zip: '4446',
      isUrban: false,
    },
    {
      name: 'POIANA GALDEI',
      zip: '4455',
      isUrban: false,
    },
    {
      name: 'RAICANI',
      zip: '4464',
      isUrban: false,
    },
    {
      name: 'ZAGRIS',
      zip: '4473',
      isUrban: false,
    },
    {
      name: 'GARBOVA',
      zip: '4491',
      isUrban: false,
    },
    {
      name: 'CARPINIS',
      zip: '4507',
      isUrban: false,
    },
    {
      name: 'RECIU',
      zip: '4516',
      isUrban: false,
    },
    {
      name: 'GARDA DE SUS',
      zip: '4534',
      isUrban: false,
    },
    {
      name: 'BIHARIA',
      zip: '4543',
      isUrban: false,
    },
    {
      name: 'DEALU FRUMOS',
      zip: '4552',
      isUrban: false,
    },
    {
      name: 'DEALU ORDINCUSII',
      zip: '4561',
      isUrban: false,
    },
    {
      name: 'DOBRESTI',
      zip: '4570',
      isUrban: false,
    },
    {
      name: 'GARDA SEACA',
      zip: '4598',
      isUrban: false,
    },
    {
      name: 'GHETARI',
      zip: '4589',
      isUrban: false,
    },
    {
      name: 'HANASESTI',
      zip: '4605',
      isUrban: false,
    },
    {
      name: 'HUZARESTI',
      zip: '4614',
      isUrban: false,
    },
    {
      name: 'IZVOARELE',
      zip: '4623',
      isUrban: false,
    },
    {
      name: 'MUNUNA',
      zip: '4632',
      isUrban: false,
    },
    {
      name: 'OCOALE',
      zip: '4641',
      isUrban: false,
    },
    {
      name: 'PLAI',
      zip: '4650',
      isUrban: false,
    },
    {
      name: 'PLISTI',
      zip: '4669',
      isUrban: false,
    },
    {
      name: 'SCOARTA',
      zip: '4678',
      isUrban: false,
    },
    {
      name: 'SNIDE',
      zip: '4696',
      isUrban: false,
    },
    {
      name: 'SUCESTI',
      zip: '4687',
      isUrban: false,
    },
    {
      name: 'HOPARTA',
      zip: '4712',
      isUrban: false,
    },
    {
      name: 'SILIVAS',
      zip: '4721',
      isUrban: false,
    },
    {
      name: 'SPALNACA',
      zip: '4730',
      isUrban: false,
    },
    {
      name: 'TURDAS',
      zip: '4749',
      isUrban: false,
    },
    {
      name: 'VAMA SEACA',
      zip: '4758',
      isUrban: false,
    },
    {
      name: 'HOREA',
      zip: '4776',
      isUrban: false,
    },
    {
      name: 'BABA',
      zip: '4785',
      isUrban: false,
    },
    {
      name: 'BUTESTI',
      zip: '4794',
      isUrban: false,
    },
    {
      name: 'DARLESTI',
      zip: '4801',
      isUrban: false,
    },
    {
      name: 'FERICET',
      zip: '4810',
      isUrban: false,
    },
    {
      name: 'GIURGIUT',
      zip: '4829',
      isUrban: false,
    },
    {
      name: 'MANCESTI',
      zip: '4838',
      isUrban: false,
    },
    {
      name: 'MATISESTI',
      zip: '4847',
      isUrban: false,
    },
    {
      name: 'NICULESTI',
      zip: '4856',
      isUrban: false,
    },
    {
      name: 'PATRUSESTI',
      zip: '4865',
      isUrban: false,
    },
    {
      name: 'PETREASA',
      zip: '4874',
      isUrban: false,
    },
    {
      name: 'PRELUCA',
      zip: '4883',
      isUrban: false,
    },
    {
      name: 'TEIU',
      zip: '4892',
      isUrban: false,
    },
    {
      name: 'TRIFESTI',
      zip: '4909',
      isUrban: false,
    },
    {
      name: 'ZANZESTI',
      zip: '4918',
      isUrban: false,
    },
    {
      name: 'IGHIU',
      zip: '4936',
      isUrban: false,
    },
    {
      name: 'BUCERDEA VANOASA',
      zip: '4945',
      isUrban: false,
    },
    {
      name: 'IGHIEL',
      zip: '4954',
      isUrban: false,
    },
    {
      name: 'SARD',
      zip: '4963',
      isUrban: false,
    },
    {
      name: 'TELNA',
      zip: '4972',
      isUrban: false,
    },
    {
      name: 'INTREGALDE',
      zip: '4990',
      isUrban: false,
    },
    {
      name: 'DEALU GEOAGIULUI',
      zip: '5005',
      isUrban: false,
    },
    {
      name: 'GHIONCANI',
      zip: '5014',
      isUrban: false,
    },
    {
      name: 'ILIESTI',
      zip: '5023',
      isUrban: false,
    },
    {
      name: 'IVANIS',
      zip: '5032',
      isUrban: false,
    },
    {
      name: 'MARINESTI',
      zip: '5041',
      isUrban: false,
    },
    {
      name: 'MODOLESTI',
      zip: '5050',
      isUrban: false,
    },
    {
      name: 'NECRILESTI',
      zip: '5069',
      isUrban: false,
    },
    {
      name: 'POPESTI',
      zip: '5078',
      isUrban: false,
    },
    {
      name: 'SFARCEA',
      zip: '5087',
      isUrban: false,
    },
    {
      name: 'TECSESTI',
      zip: '5096',
      isUrban: false,
    },
    {
      name: 'JIDVEI',
      zip: '5112',
      isUrban: false,
    },
    {
      name: 'BALCACIU',
      zip: '5121',
      isUrban: false,
    },
    {
      name: 'CAPALNA DE JOS',
      zip: '5130',
      isUrban: false,
    },
    {
      name: 'FEISA',
      zip: '5149',
      isUrban: false,
    },
    {
      name: 'VESEUS',
      zip: '5158',
      isUrban: false,
    },
    {
      name: 'LIVEZILE',
      zip: '5176',
      isUrban: false,
    },
    {
      name: 'IZVOARELE',
      zip: '5185',
      isUrban: false,
    },
    {
      name: 'POIANA AIUDULUI',
      zip: '5194',
      isUrban: false,
    },
    {
      name: 'VALISOARA',
      zip: '5201',
      isUrban: false,
    },
    {
      name: 'LOPADEA NOUA',
      zip: '5229',
      isUrban: false,
    },
    {
      name: 'ASINIP',
      zip: '5238',
      isUrban: false,
    },
    {
      name: 'BAGAU',
      zip: '5256',
      isUrban: false,
    },
    {
      name: 'BETA',
      zip: '5247',
      isUrban: false,
    },
    {
      name: 'CICARD',
      zip: '5265',
      isUrban: false,
    },
    {
      name: 'CIUGUZEL',
      zip: '5274',
      isUrban: false,
    },
    {
      name: 'OCNISOARA',
      zip: '5283',
      isUrban: false,
    },
    {
      name: 'ODVEREM',
      zip: '5292',
      isUrban: false,
    },
    {
      name: 'LUNCA MURESULUI',
      zip: '5318',
      isUrban: false,
    },
    {
      name: 'GURA ARIESULUI',
      zip: '5327',
      isUrban: false,
    },
    {
      name: 'LUPSA',
      zip: '5345',
      isUrban: false,
    },
    {
      name: 'BARDESTI',
      zip: '5354',
      isUrban: false,
    },
    {
      name: 'BARZAN',
      zip: '5363',
      isUrban: false,
    },
    {
      name: 'CURMATURA',
      zip: '5372',
      isUrban: false,
    },
    {
      name: 'DUPA DEAL',
      zip: '5381',
      isUrban: false,
    },
    {
      name: 'GEAMANA',
      zip: '5390',
      isUrban: false,
    },
    {
      name: 'HADARAU',
      zip: '5407',
      isUrban: false,
    },
    {
      name: 'HOLOBANI',
      zip: '5416',
      isUrban: false,
    },
    {
      name: 'LAZURI',
      zip: '5425',
      isUrban: false,
    },
    {
      name: 'LUNCA',
      zip: '5434',
      isUrban: false,
    },
    {
      name: 'MANASTIRE',
      zip: '5443',
      isUrban: false,
    },
    {
      name: 'MARGAIA',
      zip: '5452',
      isUrban: false,
    },
    {
      name: 'MUSCA',
      zip: '5461',
      isUrban: false,
    },
    {
      name: 'PARAU-CARBUNARI',
      zip: '5489',
      isUrban: false,
    },
    {
      name: 'PITIGA',
      zip: '5470',
      isUrban: false,
    },
    {
      name: 'POSOGANI',
      zip: '5498',
      isUrban: false,
    },
    {
      name: 'SASA',
      zip: '5504',
      isUrban: false,
    },
    {
      name: 'TRIFESTI',
      zip: '5513',
      isUrban: false,
    },
    {
      name: 'VAI',
      zip: '5559',
      isUrban: false,
    },
    {
      name: 'VALEA HOLHORII',
      zip: '5522',
      isUrban: false,
    },
    {
      name: 'VALEA LUPSII',
      zip: '5531',
      isUrban: false,
    },
    {
      name: 'VALEA SESII',
      zip: '5540',
      isUrban: false,
    },
    {
      name: 'VINTA',
      zip: '5568',
      isUrban: false,
    },
    {
      name: 'METES',
      zip: '5586',
      isUrban: false,
    },
    {
      name: 'AMPOITA',
      zip: '5595',
      isUrban: false,
    },
    {
      name: 'ISCA',
      zip: '5602',
      isUrban: false,
    },
    {
      name: 'LUNCA AMPOITEI',
      zip: '5611',
      isUrban: false,
    },
    {
      name: 'LUNCA METESULUI',
      zip: '5620',
      isUrban: false,
    },
    {
      name: 'PADUREA',
      zip: '5639',
      isUrban: false,
    },
    {
      name: 'POIANA AMPOIULUI',
      zip: '5648',
      isUrban: false,
    },
    {
      name: 'POIANA URSULUI',
      zip: '5657',
      isUrban: false,
    },
    {
      name: 'PRESACA AMPOIULUI',
      zip: '5666',
      isUrban: false,
    },
    {
      name: 'REMETEA',
      zip: '5675',
      isUrban: false,
    },
    {
      name: 'TAUTI',
      zip: '5684',
      isUrban: false,
    },
    {
      name: 'VALENI',
      zip: '5693',
      isUrban: false,
    },
    {
      name: 'MIHALT',
      zip: '5719',
      isUrban: false,
    },
    {
      name: 'CISTEI',
      zip: '5728',
      isUrban: false,
    },
    {
      name: 'OBREJA',
      zip: '5737',
      isUrban: false,
    },
    {
      name: 'ZARIES',
      zip: '5746',
      isUrban: false,
    },
    {
      name: 'MIRASLAU',
      zip: '5764',
      isUrban: false,
    },
    {
      name: 'CICAU',
      zip: '5773',
      isUrban: false,
    },
    {
      name: 'DECEA',
      zip: '5782',
      isUrban: false,
    },
    {
      name: 'LOPADEA VECHE',
      zip: '5791',
      isUrban: false,
    },
    {
      name: 'ORMENIS',
      zip: '5808',
      isUrban: false,
    },
    {
      name: 'RACHIS',
      zip: '5817',
      isUrban: false,
    },
    {
      name: 'MOGOS',
      zip: '5835',
      isUrban: false,
    },
    {
      name: 'BARBESTI',
      zip: '5844',
      isUrban: false,
    },
    {
      name: 'BARLESTI',
      zip: '5853',
      isUrban: false,
    },
    {
      name: 'BARLESTI-CATUN',
      zip: '5862',
      isUrban: false,
    },
    {
      name: 'BARZOGANI',
      zip: '5871',
      isUrban: false,
    },
    {
      name: 'BOCESTI',
      zip: '5880',
      isUrban: false,
    },
    {
      name: 'BOGDANESTI',
      zip: '5899',
      isUrban: false,
    },
    {
      name: 'BUTESTI',
      zip: '5906',
      isUrban: false,
    },
    {
      name: 'COJOCANI',
      zip: '5915',
      isUrban: false,
    },
    {
      name: 'CRISTESTI',
      zip: '5924',
      isUrban: false,
    },
    {
      name: 'MAMALIGANI',
      zip: '5933',
      isUrban: false,
    },
    {
      name: 'NEGRESTI',
      zip: '5942',
      isUrban: false,
    },
    {
      name: 'ONCESTI',
      zip: '5951',
      isUrban: false,
    },
    {
      name: 'POIENILE-MOGOS',
      zip: '5960',
      isUrban: false,
    },
    {
      name: 'TOMESTI',
      zip: '5979',
      isUrban: false,
    },
    {
      name: 'VALEA BARLUTESTI',
      zip: '5988',
      isUrban: false,
    },
    {
      name: 'VALEA BARNII',
      zip: '5997',
      isUrban: false,
    },
    {
      name: 'VALEA COCESTI',
      zip: '6002',
      isUrban: false,
    },
    {
      name: 'VALEA GIOGESTI',
      zip: '6011',
      isUrban: false,
    },
    {
      name: 'VALEA MLACII',
      zip: '6020',
      isUrban: false,
    },
    {
      name: 'VALEA TUPILOR',
      zip: '6039',
      isUrban: false,
    },
    {
      name: 'NOSLAC',
      zip: '6057',
      isUrban: false,
    },
    {
      name: 'CAPTALAN',
      zip: '6066',
      isUrban: false,
    },
    {
      name: 'COPAND',
      zip: '6075',
      isUrban: false,
    },
    {
      name: 'GABUD',
      zip: '6084',
      isUrban: false,
    },
    {
      name: 'STANA DE MURES',
      zip: '6093',
      isUrban: false,
    },
    {
      name: 'VALEA CIUCIULUI',
      zip: '6100',
      isUrban: false,
    },
    {
      name: 'OCOLIS',
      zip: '6128',
      isUrban: false,
    },
    {
      name: 'LUNCA LARGA',
      zip: '6137',
      isUrban: false,
    },
    {
      name: 'RUNC',
      zip: '6146',
      isUrban: false,
    },
    {
      name: 'VIDOLM',
      zip: '6155',
      isUrban: false,
    },
    {
      name: 'OHABA',
      zip: '6173',
      isUrban: false,
    },
    {
      name: 'COLIBI',
      zip: '6182',
      isUrban: false,
    },
    {
      name: 'MAGHIERAT',
      zip: '6191',
      isUrban: false,
    },
    {
      name: 'SECASEL',
      zip: '6208',
      isUrban: false,
    },
    {
      name: 'PIANU DE SUS',
      zip: '6226',
      isUrban: false,
    },
    {
      name: 'PIANU DE JOS',
      zip: '6235',
      isUrban: false,
    },
    {
      name: 'PLAIURI',
      zip: '6244',
      isUrban: false,
    },
    {
      name: 'PURCARETI',
      zip: '6253',
      isUrban: false,
    },
    {
      name: 'STRUNGARI',
      zip: '6262',
      isUrban: false,
    },
    {
      name: 'POIANA VADULUI',
      zip: '6280',
      isUrban: false,
    },
    {
      name: 'COSTESTI',
      zip: '6299',
      isUrban: false,
    },
    {
      name: 'DUDUIENI',
      zip: '6306',
      isUrban: false,
    },
    {
      name: 'FAGETU DE JOS',
      zip: '6315',
      isUrban: false,
    },
    {
      name: 'RADESTI',
      zip: '6556',
      isUrban: false,
    },
    {
      name: 'LEORINT',
      zip: '6565',
      isUrban: false,
    },
    {
      name: 'MESCREAC',
      zip: '6574',
      isUrban: false,
    },
    {
      name: 'SOIMUS',
      zip: '6583',
      isUrban: false,
    },
    {
      name: 'RAMET',
      zip: '6636',
      isUrban: false,
    },
    {
      name: 'BOTANI',
      zip: '6645',
      isUrban: false,
    },
    {
      name: 'BRADESTI',
      zip: '6654',
      isUrban: false,
    },
    {
      name: 'CHEIA',
      zip: '6663',
      isUrban: false,
    },
    {
      name: 'COTORASTI',
      zip: '6672',
      isUrban: false,
    },
    {
      name: 'FLORESTI',
      zip: '6681',
      isUrban: false,
    },
    {
      name: 'OLTENI',
      zip: '6690',
      isUrban: false,
    },
    {
      name: 'VALEA FAGETULUI',
      zip: '6707',
      isUrban: false,
    },
    {
      name: 'VALEA INZELULUI',
      zip: '6716',
      isUrban: false,
    },
    {
      name: 'VALEA MANASTIRII',
      zip: '6725',
      isUrban: false,
    },
    {
      name: 'VALEA POIENII',
      zip: '6734',
      isUrban: false,
    },
    {
      name: 'VALEA UZEI',
      zip: '6743',
      isUrban: false,
    },
    {
      name: 'VLADESTI',
      zip: '6752',
      isUrban: false,
    },
    {
      name: 'RIMETEA',
      zip: '6609',
      isUrban: false,
    },
    {
      name: 'COLTESTI',
      zip: '6618',
      isUrban: false,
    },
    {
      name: 'ROSIA DE SECAS',
      zip: '6949',
      isUrban: false,
    },
    {
      name: 'UNGUREI',
      zip: '6967',
      isUrban: false,
    },
    {
      name: 'ROSIA MONTANA',
      zip: '6770',
      isUrban: false,
    },
    {
      name: 'BALMOSESTI',
      zip: '6789',
      isUrban: false,
    },
    {
      name: 'BLIDESTI',
      zip: '6798',
      isUrban: false,
    },
    {
      name: 'BUNTA',
      zip: '6805',
      isUrban: false,
    },
    {
      name: 'CARPINIS',
      zip: '6814',
      isUrban: false,
    },
    {
      name: 'COASTA HENTII',
      zip: '6823',
      isUrban: false,
    },
    {
      name: 'CORNA',
      zip: '6832',
      isUrban: false,
    },
    {
      name: 'CURATURI',
      zip: '6841',
      isUrban: false,
    },
    {
      name: 'DAROAIA',
      zip: '6850',
      isUrban: false,
    },
    {
      name: 'GARDA-BARBULESTI',
      zip: '6869',
      isUrban: false,
    },
    {
      name: 'GURA ROSIEI',
      zip: '6878',
      isUrban: false,
    },
    {
      name: 'IACOBESTI',
      zip: '6887',
      isUrban: false,
    },
    {
      name: 'IGNATESTI',
      zip: '6896',
      isUrban: false,
    },
    {
      name: 'SOAL',
      zip: '6903',
      isUrban: false,
    },
    {
      name: 'TARINA',
      zip: '6912',
      isUrban: false,
    },
    {
      name: 'VARTOP',
      zip: '6921',
      isUrban: false,
    },
    {
      name: 'SALCIUA DE JOS',
      zip: '6985',
      isUrban: false,
    },
    {
      name: 'DEALU CASELOR',
      zip: '6994',
      isUrban: false,
    },
    {
      name: 'DUMESTI',
      zip: '7008',
      isUrban: false,
    },
    {
      name: 'SALCIUA DE SUS',
      zip: '7017',
      isUrban: false,
    },
    {
      name: 'SUB PIATRA',
      zip: '7026',
      isUrban: false,
    },
    {
      name: 'VALEA LARGA',
      zip: '7035',
      isUrban: false,
    },
    {
      name: 'SALISTEA',
      zip: '7053',
      isUrban: false,
    },
    {
      name: 'MARGINENI',
      zip: '7062',
      isUrban: false,
    },
    {
      name: 'SALISTEA-DEAL',
      zip: '7071',
      isUrban: false,
    },
    {
      name: 'TARTARIA',
      zip: '7080',
      isUrban: false,
    },
    {
      name: 'SANCEL',
      zip: '7357',
      isUrban: false,
    },
    {
      name: 'ICLOD',
      zip: '7366',
      isUrban: false,
    },
    {
      name: 'PANADE',
      zip: '7375',
      isUrban: false,
    },
    {
      name: 'SANTIMBRU',
      zip: '7393',
      isUrban: false,
    },
    {
      name: 'COSLARIU',
      zip: '7400',
      isUrban: false,
    },
    {
      name: 'DUMITRA',
      zip: '7419',
      isUrban: false,
    },
    {
      name: 'GALTIU',
      zip: '7428',
      isUrban: false,
    },
    {
      name: 'TOTOI',
      zip: '7437',
      isUrban: false,
    },
    {
      name: 'SASCIORI',
      zip: '7106',
      isUrban: false,
    },
    {
      name: 'CAPALNA',
      zip: '7115',
      isUrban: false,
    },
    {
      name: 'DUMBRAVA',
      zip: '7124',
      isUrban: false,
    },
    {
      name: 'LAZ',
      zip: '7133',
      isUrban: false,
    },
    {
      name: 'LOMAN',
      zip: '7142',
      isUrban: false,
    },
    {
      name: 'PLESI',
      zip: '7151',
      isUrban: false,
    },
    {
      name: 'RACHITA',
      zip: '7160',
      isUrban: false,
    },
    {
      name: 'SEBESEL',
      zip: '7179',
      isUrban: false,
    },
    {
      name: 'TONEA',
      zip: '7188',
      isUrban: false,
    },
    {
      name: 'SCARISOARA',
      zip: '7204',
      isUrban: false,
    },
    {
      name: 'BARLESTI',
      zip: '7213',
      isUrban: false,
    },
    {
      name: 'BOTESTI',
      zip: '7222',
      isUrban: false,
    },
    {
      name: 'FATA-LAZESTI',
      zip: '7231',
      isUrban: false,
    },
    {
      name: 'FLORESTI',
      zip: '7240',
      isUrban: false,
    },
    {
      name: 'LAZESTI',
      zip: '7259',
      isUrban: false,
    },
    {
      name: 'LESPEZEA',
      zip: '7268',
      isUrban: false,
    },
    {
      name: 'MATEI',
      zip: '7277',
      isUrban: false,
    },
    {
      name: 'NEGESTI',
      zip: '7286',
      isUrban: false,
    },
    {
      name: 'PRELUCA',
      zip: '7295',
      isUrban: false,
    },
    {
      name: 'RUNC',
      zip: '7302',
      isUrban: false,
    },
    {
      name: 'SFOARTEA',
      zip: '7311',
      isUrban: false,
    },
    {
      name: 'STIULETI',
      zip: '7320',
      isUrban: false,
    },
    {
      name: 'TRANCESTI',
      zip: '7339',
      isUrban: false,
    },
    {
      name: 'SIBOT',
      zip: '7829',
      isUrban: false,
    },
    {
      name: 'BACAINTI',
      zip: '7847',
      isUrban: false,
    },
    {
      name: 'BALOMIRU DE CAMP',
      zip: '7838',
      isUrban: false,
    },
    {
      name: 'SARACSAU',
      zip: '7856',
      isUrban: false,
    },
    {
      name: 'SOHODOL',
      zip: '7455',
      isUrban: false,
    },
    {
      name: 'BAZESTI',
      zip: '7464',
      isUrban: false,
    },
    {
      name: 'BILANESTI',
      zip: '7473',
      isUrban: false,
    },
    {
      name: 'BOBARESTI',
      zip: '7482',
      isUrban: false,
    },
    {
      name: 'BRADEANA',
      zip: '7491',
      isUrban: false,
    },
    {
      name: 'BURZONESTI',
      zip: '7507',
      isUrban: false,
    },
    {
      name: 'DEONCESTI',
      zip: '7516',
      isUrban: false,
    },
    {
      name: 'DILIMANI',
      zip: '7525',
      isUrban: false,
    },
    {
      name: 'FURDUIESTI',
      zip: '7534',
      isUrban: false,
    },
    {
      name: 'GURA SOHODOL',
      zip: '7543',
      isUrban: false,
    },
    {
      name: 'HOANCA',
      zip: '7552',
      isUrban: false,
    },
    {
      name: 'JOLDISESTI',
      zip: '7561',
      isUrban: false,
    },
    {
      name: 'LAZURI',
      zip: '7570',
      isUrban: false,
    },
    {
      name: 'LEHESTI',
      zip: '7589',
      isUrban: false,
    },
    {
      name: 'LUMINESTI',
      zip: '7598',
      isUrban: false,
    },
    {
      name: 'MEDRESTI',
      zip: '7605',
      isUrban: false,
    },
    {
      name: 'MORARESTI',
      zip: '7614',
      isUrban: false,
    },
    {
      name: 'MUNESTI',
      zip: '7623',
      isUrban: false,
    },
    {
      name: 'NAPAIESTI',
      zip: '7632',
      isUrban: false,
    },
    {
      name: 'NELEGESTI',
      zip: '7641',
      isUrban: false,
    },
    {
      name: 'NICORESTI',
      zip: '7650',
      isUrban: false,
    },
    {
      name: 'PELES',
      zip: '7669',
      isUrban: false,
    },
    {
      name: 'POIANA',
      zip: '7678',
      isUrban: false,
    },
    {
      name: 'ROBESTI',
      zip: '7687',
      isUrban: false,
    },
    {
      name: 'SEBISESTI',
      zip: '7712',
      isUrban: false,
    },
    {
      name: 'SICOIESTI',
      zip: '7696',
      isUrban: false,
    },
    {
      name: 'SIMOCESTI',
      zip: '7721',
      isUrban: false,
    },
    {
      name: 'SURDESTI',
      zip: '7703',
      isUrban: false,
    },
    {
      name: 'TOCI',
      zip: '7730',
      isUrban: false,
    },
    {
      name: 'VALEA VERDE',
      zip: '7749',
      isUrban: false,
    },
    {
      name: 'VLADOSESTI',
      zip: '7758',
      isUrban: false,
    },
    {
      name: 'SONA',
      zip: '7874',
      isUrban: false,
    },
    {
      name: 'ALECUS',
      zip: '7883',
      isUrban: false,
    },
    {
      name: 'BIIA',
      zip: '7892',
      isUrban: false,
    },
    {
      name: 'DOPTAU',
      zip: '7909',
      isUrban: false,
    },
    {
      name: 'LUNCA TARNAVEI',
      zip: '7918',
      isUrban: false,
    },
    {
      name: 'SANMICLAUS',
      zip: '7927',
      isUrban: false,
    },
    {
      name: 'VALEA SASULUI',
      zip: '7936',
      isUrban: false,
    },
    {
      name: 'SPRING',
      zip: '7954',
      isUrban: false,
    },
    {
      name: 'CARPEN',
      zip: '7963',
      isUrban: false,
    },
    {
      name: 'CARPENII DE SUS',
      zip: '7972',
      isUrban: false,
    },
    {
      name: 'CUNTA',
      zip: '7981',
      isUrban: false,
    },
    {
      name: 'DRASOV',
      zip: '7990',
      isUrban: false,
    },
    {
      name: 'VINGARD',
      zip: '8005',
      isUrban: false,
    },
    {
      name: 'STREMT',
      zip: '7776',
      isUrban: false,
    },
    {
      name: 'FATA PIETRII',
      zip: '7785',
      isUrban: false,
    },
    {
      name: 'GEOAGIU DE SUS',
      zip: '7794',
      isUrban: false,
    },
    {
      name: 'GEOMAL',
      zip: '7801',
      isUrban: false,
    },
    {
      name: 'SUGAG',
      zip: '8023',
      isUrban: false,
    },
    {
      name: 'ARTI',
      zip: '8032',
      isUrban: false,
    },
    {
      name: 'BARSANA',
      zip: '8041',
      isUrban: false,
    },
    {
      name: 'DOBRA',
      zip: '8050',
      isUrban: false,
    },
    {
      name: 'JIDOSTINA',
      zip: '8069',
      isUrban: false,
    },
    {
      name: 'MARTINIE',
      zip: '8078',
      isUrban: false,
    },
    {
      name: 'TAU BISTRA',
      zip: '8087',
      isUrban: false,
    },
    {
      name: 'UNIREA',
      zip: '8167',
      isUrban: false,
    },
    {
      name: 'CIUGUDU DE JOS',
      zip: '8176',
      isUrban: false,
    },
    {
      name: 'CIUGUDU DE SUS',
      zip: '8185',
      isUrban: false,
    },
    {
      name: 'DUMBRAVA',
      zip: '8194',
      isUrban: false,
    },
    {
      name: 'INOC',
      zip: '8201',
      isUrban: false,
    },
    {
      name: 'MAHACENI',
      zip: '8210',
      isUrban: false,
    },
    {
      name: 'VADU MOTILOR',
      zip: '8238',
      isUrban: false,
    },
    {
      name: 'BODESTI',
      zip: '8247',
      isUrban: false,
    },
    {
      name: 'BURZESTI',
      zip: '8256',
      isUrban: false,
    },
    {
      name: 'DEALU FRUMOS',
      zip: '8265',
      isUrban: false,
    },
    {
      name: 'LAZESTI',
      zip: '8274',
      isUrban: false,
    },
    {
      name: 'NECSESTI',
      zip: '8283',
      isUrban: false,
    },
    {
      name: 'PODURI-BRICESTI',
      zip: '8292',
      isUrban: false,
    },
    {
      name: 'POPESTII DE JOS',
      zip: '8309',
      isUrban: false,
    },
    {
      name: 'POPESTII DE SUS',
      zip: '8318',
      isUrban: false,
    },
    {
      name: 'TOMUTESTI',
      zip: '8327',
      isUrban: false,
    },
    {
      name: 'TOTESTI',
      zip: '8336',
      isUrban: false,
    },
    {
      name: 'VALTORI',
      zip: '8345',
      isUrban: false,
    },
    {
      name: 'VALEA LUNGA',
      zip: '8363',
      isUrban: false,
    },
    {
      name: 'FAGET',
      zip: '8372',
      isUrban: false,
    },
    {
      name: 'GLOGOVET',
      zip: '8381',
      isUrban: false,
    },
    {
      name: 'LODROMAN',
      zip: '8390',
      isUrban: false,
    },
    {
      name: 'LUNCA',
      zip: '8407',
      isUrban: false,
    },
    {
      name: 'TAUNI',
      zip: '8416',
      isUrban: false,
    },
    {
      name: 'VIDRA',
      zip: '8434',
      isUrban: false,
    },
    {
      name: 'BAI',
      zip: '8443',
      isUrban: false,
    },
    {
      name: 'BOBARESTI',
      zip: '8452',
      isUrban: false,
    },
    {
      name: 'BOGDANESTI',
      zip: '8461',
      isUrban: false,
    },
    {
      name: 'BORDESTII POIENI',
      zip: '8470',
      isUrban: false,
    },
    {
      name: 'CULDESTI',
      zip: '8489',
      isUrban: false,
    },
    {
      name: 'DEALU GOIESTI',
      zip: '8498',
      isUrban: false,
    },
    {
      name: 'DOS',
      zip: '8504',
      isUrban: false,
    },
    {
      name: 'DOSU LUNCII',
      zip: '8513',
      isUrban: false,
    },
    {
      name: 'DOSU VASESTI',
      zip: '8522',
      isUrban: false,
    },
    {
      name: 'DRAGOIESTI-LUNCA',
      zip: '8531',
      isUrban: false,
    },
    {
      name: 'FICARESTI',
      zip: '8540',
      isUrban: false,
    },
    {
      name: 'GLIGORESTI',
      zip: '8559',
      isUrban: false,
    },
    {
      name: 'GOIESTI',
      zip: '8568',
      isUrban: false,
    },
    {
      name: 'HAIDUCESTI',
      zip: '8577',
      isUrban: false,
    },
    {
      name: 'HARASTI',
      zip: '8586',
      isUrban: false,
    },
    {
      name: 'HOANCA',
      zip: '8595',
      isUrban: false,
    },
    {
      name: 'JEFLESTI',
      zip: '8602',
      isUrban: false,
    },
    {
      name: 'LUNCA',
      zip: '8611',
      isUrban: false,
    },
    {
      name: 'LUNCA BISERICII',
      zip: '8620',
      isUrban: false,
    },
    {
      name: 'LUNCA DE JOS',
      zip: '8639',
      isUrban: false,
    },
    {
      name: 'LUNCA GOIESTI',
      zip: '8648',
      isUrban: false,
    },
    {
      name: 'LUNCA VESESTI',
      zip: '8657',
      isUrban: false,
    },
    {
      name: 'MODOLESTI',
      zip: '8666',
      isUrban: false,
    },
    {
      name: 'NEMESI',
      zip: '8675',
      isUrban: false,
    },
    {
      name: 'OIDESTI',
      zip: '8684',
      isUrban: false,
    },
    {
      name: 'PITARCESTI',
      zip: '8693',
      isUrban: false,
    },
    {
      name: 'PLESCUTA',
      zip: '8700',
      isUrban: false,
    },
    {
      name: 'POIENI',
      zip: '8719',
      isUrban: false,
    },
    {
      name: 'PONOREL',
      zip: '8728',
      isUrban: false,
    },
    {
      name: 'PUIULETESTI',
      zip: '8737',
      isUrban: false,
    },
    {
      name: 'RUNC',
      zip: '8746',
      isUrban: false,
    },
    {
      name: 'SEGAJ',
      zip: '8755',
      isUrban: false,
    },
    {
      name: 'URDES',
      zip: '8764',
      isUrban: false,
    },
    {
      name: 'VALCANEASA',
      zip: '8791',
      isUrban: false,
    },
    {
      name: 'VALCESTI',
      zip: '8808',
      isUrban: false,
    },
    {
      name: 'VALEA MORII',
      zip: '8773',
      isUrban: false,
    },
    {
      name: 'VARTANESTI',
      zip: '8817',
      isUrban: false,
    },
    {
      name: 'VASESTI',
      zip: '8782',
      isUrban: false,
    },
    {
      name: 'VINTU DE JOS',
      zip: '8835',
      isUrban: false,
    },
    {
      name: 'CAMPU GOBLII',
      zip: '8853',
      isUrban: false,
    },
    {
      name: 'CIOCASU',
      zip: '8844',
      isUrban: false,
    },
    {
      name: 'CRISENI',
      zip: '8862',
      isUrban: false,
    },
    {
      name: 'DEALU FERULUI',
      zip: '8871',
      isUrban: false,
    },
    {
      name: 'GURA CUTULUI',
      zip: '8880',
      isUrban: false,
    },
    {
      name: 'HATEGANA',
      zip: '8899',
      isUrban: false,
    },
    {
      name: 'INURI',
      zip: '8906',
      isUrban: false,
    },
    {
      name: 'LAZ',
      zip: '8915',
      isUrban: false,
    },
    {
      name: 'MATACINA',
      zip: '8924',
      isUrban: false,
    },
    {
      name: 'MERETEU',
      zip: '8933',
      isUrban: false,
    },
    {
      name: 'PARAU LUI MIHAI',
      zip: '8942',
      isUrban: false,
    },
    {
      name: 'POIENITA',
      zip: '8951',
      isUrban: false,
    },
    {
      name: 'STAUINI',
      zip: '8960',
      isUrban: false,
    },
    {
      name: 'VALEA GOBLII',
      zip: '8979',
      isUrban: false,
    },
    {
      name: 'VALEA LUI MIHAI',
      zip: '8988',
      isUrban: false,
    },
    {
      name: 'VALEA VINTULUI',
      zip: '8997',
      isUrban: false,
    },
    {
      name: 'VURPAR',
      zip: '9002',
      isUrban: false,
    },
    {
      name: 'CIB',
      zip: '2354',
      isUrban: false,
    },
    {
      name: 'CERBU',
      zip: '3501',
      isUrban: false,
    },
    {
      name: 'CIUCULESTI',
      zip: '3510',
      isUrban: false,
    },
    {
      name: 'COLESENI',
      zip: '3529',
      isUrban: false,
    },
    {
      name: 'DOGARESTI',
      zip: '3538',
      isUrban: false,
    },
    {
      name: 'FERESTI',
      zip: '3547',
      isUrban: false,
    },
    {
      name: 'FLORESTI',
      zip: '3556',
      isUrban: false,
    },
    {
      name: 'GURA IZBITEI',
      zip: '3565',
      isUrban: false,
    },
    {
      name: 'HELESTI',
      zip: '3574',
      isUrban: false,
    },
    {
      name: 'IZBICIOARA',
      zip: '3583',
      isUrban: false,
    },
    {
      name: 'IZBITA',
      zip: '3592',
      isUrban: false,
    },
    {
      name: 'JURCUIESTI',
      zip: '3609',
      isUrban: false,
    },
    {
      name: 'LUPULESTI',
      zip: '3618',
      isUrban: false,
    },
    {
      name: 'MAGURA',
      zip: '3627',
      isUrban: false,
    },
    {
      name: 'MUNTARI',
      zip: '3636',
      isUrban: false,
    },
    {
      name: 'PETRENI',
      zip: '3645',
      isUrban: false,
    },
    {
      name: 'POIANA',
      zip: '3654',
      isUrban: false,
    },
    {
      name: 'POIENI',
      zip: '3663',
      isUrban: false,
    },
    {
      name: 'STALNISOARA',
      zip: '3672',
      isUrban: false,
    },
    {
      name: 'VALCEA',
      zip: '3752',
      isUrban: false,
    },
    {
      name: 'VALEA ABRUZEL',
      zip: '3681',
      isUrban: false,
    },
    {
      name: 'VALEA ALBA',
      zip: '3690',
      isUrban: false,
    },
    {
      name: 'VALEA CERBULUI',
      zip: '3707',
      isUrban: false,
    },
    {
      name: 'VALEA NEGRILESII',
      zip: '3716',
      isUrban: false,
    },
    {
      name: 'VALEA POIENII',
      zip: '3725',
      isUrban: false,
    },
    {
      name: 'VALEA SESII',
      zip: '3734',
      isUrban: false,
    },
    {
      name: 'FAGETU DE SUS',
      zip: '6324',
      isUrban: false,
    },
    {
      name: 'HANASESTI',
      zip: '6333',
      isUrban: false,
    },
    {
      name: 'LUPAIESTI',
      zip: '6342',
      isUrban: false,
    },
    {
      name: 'MORCANESTI',
      zip: '6351',
      isUrban: false,
    },
    {
      name: 'PASTESTI',
      zip: '6360',
      isUrban: false,
    },
    {
      name: 'PETELEI',
      zip: '6379',
      isUrban: false,
    },
    {
      name: 'STANESTI',
      zip: '6388',
      isUrban: false,
    },
    {
      name: 'PONOR',
      zip: '6404',
      isUrban: false,
    },
    {
      name: 'DUPA DEAL',
      zip: '6413',
      isUrban: false,
    },
    {
      name: 'GEOGEL',
      zip: '6422',
      isUrban: false,
    },
    {
      name: 'MACARESTI',
      zip: '6431',
      isUrban: false,
    },
    {
      name: 'VALE IN JOS',
      zip: '6459',
      isUrban: false,
    },
    {
      name: 'VALEA BUCURULUI',
      zip: '6440',
      isUrban: false,
    },
    {
      name: 'POSAGA DE JOS',
      zip: '6477',
      isUrban: false,
    },
    {
      name: 'CORTESTI',
      zip: '6486',
      isUrban: false,
    },
    {
      name: 'INCESTI',
      zip: '6495',
      isUrban: false,
    },
    {
      name: 'LUNCA',
      zip: '6501',
      isUrban: false,
    },
    {
      name: 'ORASTI',
      zip: '6510',
      isUrban: false,
    },
    {
      name: 'POSAGA DE SUS',
      zip: '6529',
      isUrban: false,
    },
    {
      name: 'SAGAGEA',
      zip: '6538',
      isUrban: false,
    },
    {
      name: 'TAU',
      zip: '6958',
      isUrban: false,
    }
  ],
  AR: [
    {
      name: 'ALMAS',
      zip: '9752',
      isUrban: false,
    },
    {
      name: 'CIL',
      zip: '9761',
      isUrban: false,
    },
    {
      name: 'JOIA MARE',
      zip: '9770',
      isUrban: false,
    },
    {
      name: 'RADESTI',
      zip: '9789',
      isUrban: false,
    },
    {
      name: 'APATEU',
      zip: '9805',
      isUrban: false,
    },
    {
      name: 'BERECHIU',
      zip: '9814',
      isUrban: false,
    },
    {
      name: 'MOTIORI',
      zip: '9823',
      isUrban: false,
    },
    {
      name: 'ARCHIS',
      zip: '9841',
      isUrban: false,
    },
    {
      name: 'BARZESTI',
      zip: '9850',
      isUrban: false,
    },
    {
      name: 'GROSENI',
      zip: '9869',
      isUrban: false,
    },
    {
      name: 'NERMIS',
      zip: '9878',
      isUrban: false,
    },
    {
      name: 'BARSA',
      zip: '10060',
      isUrban: false,
    },
    {
      name: 'ALDESTI',
      zip: '10079',
      isUrban: false,
    },
    {
      name: 'HODIS',
      zip: '10088',
      isUrban: false,
    },
    {
      name: 'MORODA',
      zip: '12028',
      isUrban: false,
    },
    {
      name: 'ZABRANI',
      zip: '12787',
      isUrban: false,
    },
    {
      name: 'CHESINT',
      zip: '12796',
      isUrban: false,
    },
    {
      name: 'NEUDORF',
      zip: '12803',
      isUrban: false,
    },
    {
      name: 'ZARAND',
      zip: '12821',
      isUrban: false,
    },
    {
      name: 'ARAD',
      zip: '9271',
      isUrban: true,
    },
    {
      name: 'CHISINEU-CRIS',
      zip: '9468',
      isUrban: true,
    },
    {
      name: 'NADAB',
      zip: '9477',
      isUrban: true,
    },
    {
      name: 'CURTICI',
      zip: '9501',
      isUrban: true,
    },
    {
      name: 'DOROBANTI',
      zip: '9510',
      isUrban: false,
    },
    {
      name: 'INEU',
      zip: '9547',
      isUrban: true,
    },
    {
      name: 'MOCREA',
      zip: '9556',
      isUrban: true,
    },
    {
      name: 'LIPOVA',
      zip: '9583',
      isUrban: true,
    },
    {
      name: 'RADNA',
      zip: '9592',
      isUrban: true,
    },
    {
      name: 'SOIMOS',
      zip: '9609',
      isUrban: true,
    },
    {
      name: 'NADLAC',
      zip: '9636',
      isUrban: true,
    },
    {
      name: 'PANCOTA',
      zip: '9663',
      isUrban: true,
    },
    {
      name: 'MADERAT',
      zip: '9672',
      isUrban: true,
    },
    {
      name: 'SEBIS',
      zip: '9707',
      isUrban: true,
    },
    {
      name: 'DONCENI',
      zip: '9716',
      isUrban: true,
    },
    {
      name: 'PRUNISOR',
      zip: '9725',
      isUrban: true,
    },
    {
      name: 'SALAJENI',
      zip: '9734',
      isUrban: true,
    },
    {
      name: 'VOIVODENI',
      zip: '10097',
      isUrban: false,
    },
    {
      name: 'BARZAVA',
      zip: '10113',
      isUrban: false,
    },
    {
      name: 'BATUTA',
      zip: '10122',
      isUrban: false,
    },
    {
      name: 'CAPRUTA',
      zip: '10131',
      isUrban: false,
    },
    {
      name: 'DUMBRAVITA',
      zip: '10140',
      isUrban: false,
    },
    {
      name: 'GROSII NOI',
      zip: '10159',
      isUrban: false,
    },
    {
      name: 'LALASINT',
      zip: '10168',
      isUrban: false,
    },
    {
      name: 'MONOROSTIA',
      zip: '10177',
      isUrban: false,
    },
    {
      name: 'SLATINA DE MURES',
      zip: '10186',
      isUrban: false,
    },
    {
      name: 'BATA',
      zip: '9896',
      isUrban: false,
    },
    {
      name: 'BACAU DE MIJLOC',
      zip: '9903',
      isUrban: false,
    },
    {
      name: 'BULCI',
      zip: '9912',
      isUrban: false,
    },
    {
      name: 'TELA',
      zip: '9921',
      isUrban: false,
    },
    {
      name: 'BELIU',
      zip: '9949',
      isUrban: false,
    },
    {
      name: 'BENESTI',
      zip: '9958',
      isUrban: false,
    },
    {
      name: 'BOCHIA',
      zip: '9967',
      isUrban: false,
    },
    {
      name: 'VASILE GOLDIS',
      zip: '9976',
      isUrban: false,
    },
    {
      name: 'SECACI',
      zip: '9985',
      isUrban: false,
    },
    {
      name: 'TAGADAU',
      zip: '9994',
      isUrban: false,
    },
    {
      name: 'BIRCHIS',
      zip: '10015',
      isUrban: false,
    },
    {
      name: 'CAPALNAS',
      zip: '10024',
      isUrban: false,
    },
    {
      name: 'OSTROV',
      zip: '10033',
      isUrban: false,
    },
    {
      name: 'VIRISMORT',
      zip: '10042',
      isUrban: false,
    },
    {
      name: 'BOCSIG',
      zip: '10202',
      isUrban: false,
    },
    {
      name: 'MANERAU',
      zip: '10211',
      isUrban: false,
    },
    {
      name: 'RAPSIG',
      zip: '10220',
      isUrban: false,
    },
    {
      name: 'BRAZII',
      zip: '10248',
      isUrban: false,
    },
    {
      name: 'BUCEAVA-SOIMUS',
      zip: '10257',
      isUrban: false,
    },
    {
      name: 'IACOBINI',
      zip: '10266',
      isUrban: false,
    },
    {
      name: 'MADRIGESTI',
      zip: '10275',
      isUrban: false,
    },
    {
      name: 'SECAS',
      zip: '10284',
      isUrban: false,
    },
    {
      name: 'BUTENI',
      zip: '10300',
      isUrban: false,
    },
    {
      name: 'BERINDIA',
      zip: '10319',
      isUrban: false,
    },
    {
      name: 'CUIED',
      zip: '10328',
      isUrban: false,
    },
    {
      name: 'PAULIN',
      zip: '10337',
      isUrban: false,
    },
    {
      name: 'CARAND',
      zip: '10355',
      isUrban: false,
    },
    {
      name: 'SELISTEA',
      zip: '10364',
      isUrban: false,
    },
    {
      name: 'CERMEI',
      zip: '10382',
      isUrban: false,
    },
    {
      name: 'AVRAM IANCU',
      zip: '10391',
      isUrban: false,
    },
    {
      name: 'SOMOSCHES',
      zip: '10408',
      isUrban: false,
    },
    {
      name: 'CHISINDIA',
      zip: '10426',
      isUrban: false,
    },
    {
      name: 'PAIUSENI',
      zip: '10435',
      isUrban: false,
    },
    {
      name: 'VASOAIA',
      zip: '10444',
      isUrban: false,
    },
    {
      name: 'CONOP',
      zip: '10462',
      isUrban: false,
    },
    {
      name: 'BELOTINT',
      zip: '10471',
      isUrban: false,
    },
    {
      name: 'CHELMAC',
      zip: '10480',
      isUrban: false,
    },
    {
      name: 'MILOVA',
      zip: '10499',
      isUrban: false,
    },
    {
      name: 'ODVOS',
      zip: '10505',
      isUrban: false,
    },
    {
      name: 'COVASINT',
      zip: '10523',
      isUrban: false,
    },
    {
      name: 'CRAIVA',
      zip: '10541',
      isUrban: false,
    },
    {
      name: 'CHISLACA',
      zip: '10550',
      isUrban: false,
    },
    {
      name: 'CIUNTESTI',
      zip: '10569',
      isUrban: false,
    },
    {
      name: 'COROI',
      zip: '10578',
      isUrban: false,
    },
    {
      name: 'MARAUS',
      zip: '10587',
      isUrban: false,
    },
    {
      name: 'ROGOZ DE BELIU',
      zip: '10596',
      isUrban: false,
    },
    {
      name: 'SIAD',
      zip: '10621',
      isUrban: false,
    },
    {
      name: 'STOINESTI',
      zip: '10603',
      isUrban: false,
    },
    {
      name: 'SUSAG',
      zip: '10612',
      isUrban: false,
    },
    {
      name: 'TALMACI',
      zip: '10630',
      isUrban: false,
    },
    {
      name: 'DEZNA',
      zip: '10658',
      isUrban: false,
    },
    {
      name: 'BUHANI',
      zip: '10667',
      isUrban: false,
    },
    {
      name: 'LAZ',
      zip: '10676',
      isUrban: false,
    },
    {
      name: 'NEAGRA',
      zip: '10685',
      isUrban: false,
    },
    {
      name: 'SLATINA DE CRIS',
      zip: '10694',
      isUrban: false,
    },
    {
      name: 'DIECI',
      zip: '10710',
      isUrban: false,
    },
    {
      name: 'COCIUBA',
      zip: '10729',
      isUrban: false,
    },
    {
      name: 'CROCNA',
      zip: '10738',
      isUrban: false,
    },
    {
      name: 'REVETIS',
      zip: '10747',
      isUrban: false,
    },
    {
      name: 'ROSIA',
      zip: '10756',
      isUrban: false,
    },
    {
      name: 'DORGOS',
      zip: '10774',
      isUrban: false,
    },
    {
      name: 'BRUZNIC',
      zip: '10783',
      isUrban: false,
    },
    {
      name: 'PATARS',
      zip: '10792',
      isUrban: false,
    },
    {
      name: 'USUSAU',
      zip: '10809',
      isUrban: false,
    },
    {
      name: 'ZABALT',
      zip: '10818',
      isUrban: false,
    },
    {
      name: 'FANTANELE',
      zip: '9299',
      isUrban: false,
    },
    {
      name: 'ALUNIS',
      zip: '9306',
      isUrban: false,
    },
    {
      name: 'FRUMUSENI',
      zip: '9315',
      isUrban: false,
    },
    {
      name: 'TISA NOUA',
      zip: '9324',
      isUrban: false,
    },
    {
      name: 'FELNAC',
      zip: '10836',
      isUrban: false,
    },
    {
      name: 'BODROGU NOU',
      zip: '10845',
      isUrban: false,
    },
    {
      name: 'CALUGARENI',
      zip: '10854',
      isUrban: false,
    },
    {
      name: 'ZADARENI',
      zip: '10863',
      isUrban: false,
    },
    {
      name: 'GHIOROC',
      zip: '10881',
      isUrban: false,
    },
    {
      name: 'CUVIN',
      zip: '10890',
      isUrban: false,
    },
    {
      name: 'MINIS',
      zip: '10907',
      isUrban: false,
    },
    {
      name: 'GRANICERI',
      zip: '10925',
      isUrban: false,
    },
    {
      name: 'SICLAU',
      zip: '10934',
      isUrban: false,
    },
    {
      name: 'GURAHONT',
      zip: '10952',
      isUrban: false,
    },
    {
      name: 'BONTESTI',
      zip: '10961',
      isUrban: false,
    },
    {
      name: 'DULCELE',
      zip: '10970',
      isUrban: false,
    },
    {
      name: 'FENIS',
      zip: '10989',
      isUrban: false,
    },
    {
      name: 'HONTISOR',
      zip: '10998',
      isUrban: false,
    },
    {
      name: 'IOSAS',
      zip: '11003',
      isUrban: false,
    },
    {
      name: 'MUSTESTI',
      zip: '11012',
      isUrban: false,
    },
    {
      name: 'PESCARI',
      zip: '11021',
      isUrban: false,
    },
    {
      name: 'VALEA MARE',
      zip: '11030',
      isUrban: false,
    },
    {
      name: 'ZIMBRU',
      zip: '11049',
      isUrban: false,
    },
    {
      name: 'HALMAGEL',
      zip: '11183',
      isUrban: false,
    },
    {
      name: 'LUNCSOARA',
      zip: '11192',
      isUrban: false,
    },
    {
      name: 'SARBI',
      zip: '11209',
      isUrban: false,
    },
    {
      name: 'TARNAVITA',
      zip: '11218',
      isUrban: false,
    },
    {
      name: 'TOHESTI',
      zip: '11227',
      isUrban: false,
    },
    {
      name: 'HALMAGIU',
      zip: '11067',
      isUrban: false,
    },
    {
      name: 'BANESTI',
      zip: '11076',
      isUrban: false,
    },
    {
      name: 'BODESTI',
      zip: '11085',
      isUrban: false,
    },
    {
      name: 'BRUSTURI',
      zip: '11094',
      isUrban: false,
    },
    {
      name: 'CRISTESTI',
      zip: '11101',
      isUrban: false,
    },
    {
      name: 'IONESTI',
      zip: '11110',
      isUrban: false,
    },
    {
      name: 'LEASA',
      zip: '11129',
      isUrban: false,
    },
    {
      name: 'LESTIOARA',
      zip: '11138',
      isUrban: false,
    },
    {
      name: 'POIENARI',
      zip: '11147',
      isUrban: false,
    },
    {
      name: 'TARMURE',
      zip: '11165',
      isUrban: false,
    },
    {
      name: 'TISA',
      zip: '11156',
      isUrban: false,
    },
    {
      name: 'HASMAS',
      zip: '11245',
      isUrban: false,
    },
    {
      name: 'AGRISU MIC',
      zip: '11254',
      isUrban: false,
    },
    {
      name: 'BOTFEI',
      zip: '11263',
      isUrban: false,
    },
    {
      name: 'CLIT',
      zip: '11272',
      isUrban: false,
    },
    {
      name: 'COMANESTI',
      zip: '11281',
      isUrban: false,
    },
    {
      name: 'URVISU DE BELIU',
      zip: '11290',
      isUrban: false,
    },
    {
      name: 'IGNESTI',
      zip: '11316',
      isUrban: false,
    },
    {
      name: 'MINEAD',
      zip: '11325',
      isUrban: false,
    },
    {
      name: 'NADALBESTI',
      zip: '11334',
      isUrban: false,
    },
    {
      name: 'SUSANI',
      zip: '11343',
      isUrban: false,
    },
    {
      name: 'IRATOSU',
      zip: '11361',
      isUrban: false,
    },
    {
      name: 'VARIASU MARE',
      zip: '11370',
      isUrban: false,
    },
    {
      name: 'VARIASU MIC',
      zip: '11389',
      isUrban: false,
    },
    {
      name: 'LIVADA',
      zip: '9342',
      isUrban: false,
    },
    {
      name: 'SANLEANI',
      zip: '9351',
      isUrban: false,
    },
    {
      name: 'MACEA',
      zip: '11405',
      isUrban: false,
    },
    {
      name: 'SANMARTIN',
      zip: '11414',
      isUrban: false,
    },
    {
      name: 'MISCA',
      zip: '11432',
      isUrban: false,
    },
    {
      name: 'SATU NOU',
      zip: '11441',
      isUrban: false,
    },
    {
      name: 'VANATORI',
      zip: '11450',
      isUrban: false,
    },
    {
      name: 'ZERINDU MIC',
      zip: '11469',
      isUrban: false,
    },
    {
      name: 'MONEASA',
      zip: '11487',
      isUrban: false,
    },
    {
      name: 'RANUSA',
      zip: '11496',
      isUrban: false,
    },
    {
      name: 'OLARI',
      zip: '11511',
      isUrban: false,
    },
    {
      name: 'SINTEA MICA',
      zip: '11520',
      isUrban: false,
    },
    {
      name: 'PAULIS',
      zip: '11548',
      isUrban: false,
    },
    {
      name: 'BARATCA',
      zip: '11557',
      isUrban: false,
    },
    {
      name: 'CLADOVA',
      zip: '11566',
      isUrban: false,
    },
    {
      name: 'SAMBATENI',
      zip: '11575',
      isUrban: false,
    },
    {
      name: 'PECICA',
      zip: '11593',
      isUrban: true,
    },
    {
      name: 'BODROGU VECHI',
      zip: '11600',
      isUrban: true,
    },
    {
      name: 'SEDERHAT',
      zip: '11619',
      isUrban: true,
    },
    {
      name: 'TURNU',
      zip: '11628',
      isUrban: true,
    },
    {
      name: 'PEREGU MARE',
      zip: '11646',
      isUrban: false,
    },
    {
      name: 'PEREGU MIC',
      zip: '11655',
      isUrban: false,
    },
    {
      name: 'PETRIS',
      zip: '11673',
      isUrban: false,
    },
    {
      name: 'CORBESTI',
      zip: '11682',
      isUrban: false,
    },
    {
      name: 'ILTEU',
      zip: '11691',
      isUrban: false,
    },
    {
      name: 'OBARSIA',
      zip: '11708',
      isUrban: false,
    },
    {
      name: 'ROSIA NOUA',
      zip: '11717',
      isUrban: false,
    },
    {
      name: 'SELISTE',
      zip: '11726',
      isUrban: false,
    },
    {
      name: 'PILU',
      zip: '11744',
      isUrban: false,
    },
    {
      name: 'VARSAND',
      zip: '11753',
      isUrban: false,
    },
    {
      name: 'PLESCUTA',
      zip: '11771',
      isUrban: false,
    },
    {
      name: 'ACIUTA',
      zip: '11780',
      isUrban: false,
    },
    {
      name: 'BUDESTI',
      zip: '11799',
      isUrban: false,
    },
    {
      name: 'DUMBRAVA',
      zip: '11806',
      isUrban: false,
    },
    {
      name: 'GURA VAII',
      zip: '11815',
      isUrban: false,
    },
    {
      name: 'ROSTOCI',
      zip: '11824',
      isUrban: false,
    },
    {
      name: 'TALAGIU',
      zip: '11833',
      isUrban: false,
    },
    {
      name: 'SAGU',
      zip: '12153',
      isUrban: false,
    },
    {
      name: 'CRUCENI',
      zip: '12162',
      isUrban: false,
    },
    {
      name: 'FIRITEAZ',
      zip: '12171',
      isUrban: false,
    },
    {
      name: 'FISCUT',
      zip: '12180',
      isUrban: false,
    },
    {
      name: 'HUNEDOARA TIMISANA',
      zip: '12199',
      isUrban: false,
    },
    {
      name: 'SANTANA',
      zip: '12108',
      isUrban: true,
    },
    {
      name: 'CAPORAL ALEXA',
      zip: '12117',
      isUrban: true,
    },
    {
      name: 'SAVARSIN',
      zip: '11851',
      isUrban: false,
    },
    {
      name: 'CAPRIOARA',
      zip: '11860',
      isUrban: false,
    },
    {
      name: 'CUIAS',
      zip: '11879',
      isUrban: false,
    },
    {
      name: 'HALALIS',
      zip: '11888',
      isUrban: false,
    },
    {
      name: 'PARNESTI',
      zip: '11897',
      isUrban: false,
    },
    {
      name: 'TEMESESTI',
      zip: '11904',
      isUrban: false,
    },
    {
      name: 'TOC',
      zip: '11913',
      isUrban: false,
    },
    {
      name: 'TROAS',
      zip: '11922',
      isUrban: false,
    },
    {
      name: 'VALEA MARE',
      zip: '11931',
      isUrban: false,
    },
    {
      name: 'SECUSIGIU',
      zip: '11959',
      isUrban: false,
    },
    {
      name: 'MUNAR',
      zip: '11968',
      isUrban: false,
    },
    {
      name: 'SANPETRU GERMAN',
      zip: '11986',
      isUrban: false,
    },
    {
      name: 'SATU MARE',
      zip: '11977',
      isUrban: false,
    },
    {
      name: 'SEITIN',
      zip: '12215',
      isUrban: false,
    },
    {
      name: 'SELEUS',
      zip: '12000',
      isUrban: false,
    },
    {
      name: 'IERMATA',
      zip: '12019',
      isUrban: false,
    },
    {
      name: 'SEMLAC',
      zip: '12046',
      isUrban: false,
    },
    {
      name: 'SEPREUS',
      zip: '12233',
      isUrban: false,
    },
    {
      name: 'SICULA',
      zip: '12251',
      isUrban: false,
    },
    {
      name: 'CHERELUS',
      zip: '12260',
      isUrban: false,
    },
    {
      name: 'GURBA',
      zip: '12279',
      isUrban: false,
    },
    {
      name: 'SILINDIA',
      zip: '12297',
      isUrban: false,
    },
    {
      name: 'CAMNA',
      zip: '12304',
      isUrban: false,
    },
    {
      name: 'IERCOSENI',
      zip: '12313',
      isUrban: false,
    },
    {
      name: 'LUGUZAU',
      zip: '12322',
      isUrban: false,
    },
    {
      name: 'SATU MIC',
      zip: '12331',
      isUrban: false,
    },
    {
      name: 'SIMAND',
      zip: '12359',
      isUrban: false,
    },
    {
      name: 'SINTEA MARE',
      zip: '12064',
      isUrban: false,
    },
    {
      name: 'ADEA',
      zip: '12073',
      isUrban: false,
    },
    {
      name: 'TIPAR',
      zip: '12082',
      isUrban: false,
    },
    {
      name: 'SIRIA',
      zip: '12377',
      isUrban: false,
    },
    {
      name: 'GALSA',
      zip: '12386',
      isUrban: false,
    },
    {
      name: 'MASCA',
      zip: '12395',
      isUrban: false,
    },
    {
      name: 'SISTAROVAT',
      zip: '12411',
      isUrban: false,
    },
    {
      name: 'CUVESDIA',
      zip: '12420',
      isUrban: false,
    },
    {
      name: 'LABASINT',
      zip: '12439',
      isUrban: false,
    },
    {
      name: 'VARNITA',
      zip: '12448',
      isUrban: false,
    },
    {
      name: 'SOCODOR',
      zip: '12135',
      isUrban: false,
    },
    {
      name: 'SOFRONEA',
      zip: '9379',
      isUrban: false,
    },
    {
      name: 'SANPAUL',
      zip: '9388',
      isUrban: false,
    },
    {
      name: 'TARNOVA',
      zip: '12518',
      isUrban: false,
    },
    {
      name: 'AGRISU MARE',
      zip: '12527',
      isUrban: false,
    },
    {
      name: 'ARANEAG',
      zip: '12536',
      isUrban: false,
    },
    {
      name: 'CHIER',
      zip: '12545',
      isUrban: false,
    },
    {
      name: 'DRAUT',
      zip: '12554',
      isUrban: false,
    },
    {
      name: 'DUD',
      zip: '12563',
      isUrban: false,
    },
    {
      name: 'TAUT',
      zip: '12466',
      isUrban: false,
    },
    {
      name: 'MINISEL',
      zip: '12475',
      isUrban: false,
    },
    {
      name: 'MINISU DE SUS',
      zip: '12484',
      isUrban: false,
    },
    {
      name: 'NADAS',
      zip: '12493',
      isUrban: false,
    },
    {
      name: 'VARADIA DE MURES',
      zip: '12581',
      isUrban: false,
    },
    {
      name: 'BAIA',
      zip: '12590',
      isUrban: false,
    },
    {
      name: 'JULITA',
      zip: '12607',
      isUrban: false,
    },
    {
      name: 'LUPESTI',
      zip: '12616',
      isUrban: false,
    },
    {
      name: 'NICOLAE BALCESCU',
      zip: '12625',
      isUrban: false,
    },
    {
      name: 'STEJAR',
      zip: '12634',
      isUrban: false,
    },
    {
      name: 'VARFURILE',
      zip: '12698',
      isUrban: false,
    },
    {
      name: 'AVRAM IANCU',
      zip: '12705',
      isUrban: false,
    },
    {
      name: 'GROSI',
      zip: '12714',
      isUrban: false,
    },
    {
      name: 'LAZURI',
      zip: '12723',
      isUrban: false,
    },
    {
      name: 'MAGULICEA',
      zip: '12732',
      isUrban: false,
    },
    {
      name: 'MERMESTI',
      zip: '12741',
      isUrban: false,
    },
    {
      name: 'POIANA',
      zip: '12750',
      isUrban: false,
    },
    {
      name: 'VIDRA',
      zip: '12769',
      isUrban: false,
    },
    {
      name: 'VINGA',
      zip: '12652',
      isUrban: false,
    },
    {
      name: 'MAILAT',
      zip: '12661',
      isUrban: false,
    },
    {
      name: 'MANASTUR',
      zip: '12670',
      isUrban: false,
    },
    {
      name: 'VLADIMIRESCU',
      zip: '9404',
      isUrban: false,
    },
    {
      name: 'CICIR',
      zip: '9413',
      isUrban: false,
    },
    {
      name: 'HORIA',
      zip: '9422',
      isUrban: false,
    },
    {
      name: 'MANDRULOC',
      zip: '9431',
      isUrban: false,
    },
    {
      name: 'CINTEI',
      zip: '12830',
      isUrban: false,
    },
    {
      name: 'ZERIND',
      zip: '12858',
      isUrban: false,
    },
    {
      name: 'IERMATA NEAGRA',
      zip: '12867',
      isUrban: false,
    },
    {
      name: 'ZIMANDU NOU',
      zip: '12885',
      isUrban: false,
    },
    {
      name: 'ANDREI SAGUNA',
      zip: '12894',
      isUrban: false,
    },
    {
      name: 'ZIMANDCUZ',
      zip: '12901',
      isUrban: false,
    }
  ],
  AG: [
    {
      name: 'PITESTI',
      zip: '13178',
      isUrban: true,
    },
    {
      name: 'CAMPULUNG',
      zip: '13506',
      isUrban: true,
    },
    {
      name: 'VALEA RUMANESTILOR',
      zip: '13515',
      isUrban: true,
    },
    {
      name: 'CURTEA DE ARGES',
      zip: '13631',
      isUrban: true,
    },
    {
      name: 'NOAPTES',
      zip: '13640',
      isUrban: true,
    },
    {
      name: 'COSTESTI',
      zip: '13677',
      isUrban: true,
    },
    {
      name: 'BROSTENI',
      zip: '13686',
      isUrban: true,
    },
    {
      name: 'LACENI',
      zip: '13695',
      isUrban: true,
    },
    {
      name: 'PARVU ROSU',
      zip: '13702',
      isUrban: true,
    },
    {
      name: 'PODU BROSTENI',
      zip: '13711',
      isUrban: true,
    },
    {
      name: 'SMEI',
      zip: '13720',
      isUrban: true,
    },
    {
      name: 'STARCI',
      zip: '13739',
      isUrban: true,
    },
    {
      name: 'MIOVENI',
      zip: '13310',
      isUrban: true,
    },
    {
      name: 'CLUCEREASA',
      zip: '13329',
      isUrban: true,
    },
    {
      name: 'COLIBASI',
      zip: '13338',
      isUrban: true,
    },
    {
      name: 'FAGETU',
      zip: '13347',
      isUrban: true,
    },
    {
      name: 'RACOVITA',
      zip: '13356',
      isUrban: true,
    },
    {
      name: 'TOPOLOVENI',
      zip: '13766',
      isUrban: true,
    },
    {
      name: 'BOTARCANI',
      zip: '13775',
      isUrban: true,
    },
    {
      name: 'CRINTESTI',
      zip: '13784',
      isUrban: true,
    },
    {
      name: 'GORANESTI',
      zip: '13793',
      isUrban: true,
    },
    {
      name: 'TIGANESTI',
      zip: '13800',
      isUrban: true,
    },
    {
      name: 'ALBESTII PAMANTENI',
      zip: '13828',
      isUrban: false,
    },
    {
      name: 'ALBESTII UNGURENI',
      zip: '13837',
      isUrban: false,
    },
    {
      name: 'BRATESTI',
      zip: '13846',
      isUrban: false,
    },
    {
      name: 'DOBLEA',
      zip: '13855',
      isUrban: false,
    },
    {
      name: 'DOBROTU',
      zip: '13864',
      isUrban: false,
    },
    {
      name: 'DUMIRESTI',
      zip: '13873',
      isUrban: false,
    },
    {
      name: 'FLORIENI',
      zip: '13882',
      isUrban: false,
    },
    {
      name: 'BUGHEA DE SUS',
      zip: '13908',
      isUrban: false,
    },
    {
      name: 'ALBESTI',
      zip: '13917',
      isUrban: false,
    },
    {
      name: 'CANDESTI',
      zip: '13926',
      isUrban: false,
    },
    {
      name: 'ALBOTA',
      zip: '13944',
      isUrban: false,
    },
    {
      name: 'CERBU',
      zip: '13953',
      isUrban: false,
    },
    {
      name: 'FRATESTI',
      zip: '13962',
      isUrban: false,
    },
    {
      name: 'GURA VAII',
      zip: '13971',
      isUrban: false,
    },
    {
      name: 'MARES',
      zip: '13980',
      isUrban: false,
    },
    {
      name: 'ANINOASA',
      zip: '14003',
      isUrban: false,
    },
    {
      name: 'BROSTENI',
      zip: '14012',
      isUrban: false,
    },
    {
      name: 'SLANIC',
      zip: '14021',
      isUrban: false,
    },
    {
      name: 'VALEA SILISTII',
      zip: '14030',
      isUrban: false,
    },
    {
      name: 'CAPATANENII PAMANTENI',
      zip: '14058',
      isUrban: false,
    },
    {
      name: 'AREFU',
      zip: '14067',
      isUrban: false,
    },
    {
      name: 'CAPATANENII UNGURENI',
      zip: '14076',
      isUrban: false,
    },
    {
      name: 'BABANA',
      zip: '14094',
      isUrban: false,
    },
    {
      name: 'BAJANESTI',
      zip: '14101',
      isUrban: false,
    },
    {
      name: 'CIOBANESTI',
      zip: '14110',
      isUrban: false,
    },
    {
      name: 'COTMENITA',
      zip: '14129',
      isUrban: false,
    },
    {
      name: 'GROSI',
      zip: '14138',
      isUrban: false,
    },
    {
      name: 'LUPUENI',
      zip: '14147',
      isUrban: false,
    },
    {
      name: 'SLATIOARELE',
      zip: '14156',
      isUrban: false,
    },
    {
      name: 'BAICULESTI',
      zip: '14174',
      isUrban: false,
    },
    {
      name: 'ALUNISU',
      zip: '14183',
      isUrban: false,
    },
    {
      name: 'ANGHINESTI',
      zip: '14192',
      isUrban: false,
    },
    {
      name: 'ARGESANI',
      zip: '14209',
      isUrban: false,
    },
    {
      name: 'MANICESTI',
      zip: '14218',
      isUrban: false,
    },
    {
      name: 'STEJARI',
      zip: '14227',
      isUrban: false,
    },
    {
      name: 'TUTANA',
      zip: '14236',
      isUrban: false,
    },
    {
      name: 'VALEA BRAZILOR',
      zip: '14245',
      isUrban: false,
    },
    {
      name: 'VALEA LUI ENACHE',
      zip: '14254',
      isUrban: false,
    },
    {
      name: 'ZIGONENI',
      zip: '14263',
      isUrban: false,
    },
    {
      name: 'BALILESTI',
      zip: '14281',
      isUrban: false,
    },
    {
      name: 'BAJESTI',
      zip: '14290',
      isUrban: false,
    },
    {
      name: 'GOLESTI',
      zip: '14307',
      isUrban: false,
    },
    {
      name: 'POIENITA',
      zip: '14316',
      isUrban: false,
    },
    {
      name: 'PRIBOAIA',
      zip: '14325',
      isUrban: false,
    },
    {
      name: 'ULITA',
      zip: '14334',
      isUrban: false,
    },
    {
      name: 'VALEA MARE-BRATIA',
      zip: '14343',
      isUrban: false,
    },
    {
      name: 'BARLA',
      zip: '14469',
      isUrban: false,
    },
    {
      name: 'AFRIMESTI',
      zip: '14478',
      isUrban: false,
    },
    {
      name: 'BADESTI',
      zip: '14487',
      isUrban: false,
    },
    {
      name: 'BRABETI',
      zip: '14496',
      isUrban: false,
    },
    {
      name: 'CIOCESTI',
      zip: '14502',
      isUrban: false,
    },
    {
      name: 'MALU',
      zip: '14511',
      isUrban: false,
    },
    {
      name: 'MANDRA',
      zip: '14520',
      isUrban: false,
    },
    {
      name: 'MOZACENII-VALE',
      zip: '14539',
      isUrban: false,
    },
    {
      name: 'PODISORU',
      zip: '14548',
      isUrban: false,
    },
    {
      name: 'SELAREASCA',
      zip: '14557',
      isUrban: false,
    },
    {
      name: 'URLUENI',
      zip: '14566',
      isUrban: false,
    },
    {
      name: 'ZUVELCATI',
      zip: '14575',
      isUrban: false,
    },
    {
      name: 'BASCOV',
      zip: '13196',
      isUrban: false,
    },
    {
      name: 'BRAILENI',
      zip: '13203',
      isUrban: false,
    },
    {
      name: 'GLIMBOCU',
      zip: '13212',
      isUrban: false,
    },
    {
      name: 'MICA',
      zip: '13221',
      isUrban: false,
    },
    {
      name: 'PRISLOPU MIC',
      zip: '13230',
      isUrban: false,
    },
    {
      name: 'SCHIAU',
      zip: '13249',
      isUrban: false,
    },
    {
      name: 'UIASCA',
      zip: '13258',
      isUrban: false,
    },
    {
      name: 'VALEA URSULUI',
      zip: '13267',
      isUrban: false,
    },
    {
      name: 'ZGRIPCESTI',
      zip: '14361',
      isUrban: false,
    },
    {
      name: 'BELETI',
      zip: '14370',
      isUrban: false,
    },
    {
      name: 'LENTEA',
      zip: '14389',
      isUrban: false,
    },
    {
      name: 'NEGRESTI',
      zip: '14398',
      isUrban: false,
    },
    {
      name: 'BEREVOESTI',
      zip: '14414',
      isUrban: false,
    },
    {
      name: 'BRATIA',
      zip: '14423',
      isUrban: false,
    },
    {
      name: 'GAMACESTI',
      zip: '14432',
      isUrban: false,
    },
    {
      name: 'OTELU',
      zip: '14441',
      isUrban: false,
    },
    {
      name: 'BOGATI',
      zip: '14593',
      isUrban: false,
    },
    {
      name: 'BARLOI',
      zip: '14600',
      isUrban: false,
    },
    {
      name: 'BUJOI',
      zip: '14619',
      isUrban: false,
    },
    {
      name: 'CHITESTI',
      zip: '14628',
      isUrban: false,
    },
    {
      name: 'DUMBRAVA',
      zip: '14637',
      isUrban: false,
    },
    {
      name: 'GLAMBOCEL',
      zip: '14646',
      isUrban: false,
    },
    {
      name: 'GLAMBOCELU',
      zip: '14655',
      isUrban: false,
    },
    {
      name: 'SUSENI',
      zip: '14664',
      isUrban: false,
    },
    {
      name: 'BOTENI',
      zip: '14682',
      isUrban: false,
    },
    {
      name: 'BALABANI',
      zip: '14691',
      isUrban: false,
    },
    {
      name: 'LUNCA',
      zip: '14708',
      isUrban: false,
    },
    {
      name: 'MUSCEL',
      zip: '14717',
      isUrban: false,
    },
    {
      name: 'BOTESTI',
      zip: '14735',
      isUrban: false,
    },
    {
      name: 'MOSTENI-GRECI',
      zip: '14744',
      isUrban: false,
    },
    {
      name: 'BRADU',
      zip: '13285',
      isUrban: false,
    },
    {
      name: 'GEAMANA',
      zip: '13294',
      isUrban: false,
    },
    {
      name: 'BRADULET',
      zip: '14762',
      isUrban: false,
    },
    {
      name: 'ALUNISU',
      zip: '14771',
      isUrban: false,
    },
    {
      name: 'BRADETU',
      zip: '14780',
      isUrban: false,
    },
    {
      name: 'COSACI',
      zip: '14799',
      isUrban: false,
    },
    {
      name: 'GALESU',
      zip: '14806',
      isUrban: false,
    },
    {
      name: 'PIATRA',
      zip: '14815',
      isUrban: false,
    },
    {
      name: 'SLAMNESTI',
      zip: '14824',
      isUrban: false,
    },
    {
      name: 'ULENI',
      zip: '14833',
      isUrban: false,
    },
    {
      name: 'UNGURENI',
      zip: '14842',
      isUrban: false,
    },
    {
      name: 'BUDEASA MARE',
      zip: '14860',
      isUrban: false,
    },
    {
      name: 'BUDEASA MICA',
      zip: '14879',
      isUrban: false,
    },
    {
      name: 'CALOTESTI',
      zip: '14888',
      isUrban: false,
    },
    {
      name: 'GALASESTI',
      zip: '14897',
      isUrban: false,
    },
    {
      name: 'ROGOJINA',
      zip: '14904',
      isUrban: false,
    },
    {
      name: 'VALEA MARULUI',
      zip: '14913',
      isUrban: false,
    },
    {
      name: 'BUGHEA DE JOS',
      zip: '14931',
      isUrban: false,
    },
    {
      name: 'VULPESTI',
      zip: '14959',
      isUrban: false,
    },
    {
      name: 'BUJORENI',
      zip: '14968',
      isUrban: false,
    },
    {
      name: 'BUZOESTI',
      zip: '14977',
      isUrban: false,
    },
    {
      name: 'CORNATEL',
      zip: '14986',
      isUrban: false,
    },
    {
      name: 'CURTEANCA',
      zip: '14995',
      isUrban: false,
    },
    {
      name: 'IONESTI',
      zip: '15000',
      isUrban: false,
    },
    {
      name: 'PODENI',
      zip: '15019',
      isUrban: false,
    },
    {
      name: 'REDEA',
      zip: '15028',
      isUrban: false,
    },
    {
      name: 'SERBOENI',
      zip: '15037',
      isUrban: false,
    },
    {
      name: 'TOMSANCA',
      zip: '15046',
      isUrban: false,
    },
    {
      name: 'VLADUTA',
      zip: '15055',
      isUrban: false,
    },
    {
      name: 'CALDARARU',
      zip: '15073',
      isUrban: false,
    },
    {
      name: 'BURDEA',
      zip: '15082',
      isUrban: false,
    },
    {
      name: 'STRAMBENI',
      zip: '15091',
      isUrban: false,
    },
    {
      name: 'VRANESTI',
      zip: '15117',
      isUrban: false,
    },
    {
      name: 'CALINESTI',
      zip: '15126',
      isUrban: false,
    },
    {
      name: 'CARSTIENI',
      zip: '15144',
      isUrban: false,
    },
    {
      name: 'CIOCANESTI',
      zip: '15135',
      isUrban: false,
    },
    {
      name: 'GLODU',
      zip: '15153',
      isUrban: false,
    },
    {
      name: 'GORGANU',
      zip: '15162',
      isUrban: false,
    },
    {
      name: 'RADU NEGRU',
      zip: '15171',
      isUrban: false,
    },
    {
      name: 'RANCACIOV',
      zip: '15180',
      isUrban: false,
    },
    {
      name: 'UDENI-ZAVOI',
      zip: '15199',
      isUrban: false,
    },
    {
      name: 'URLUCEA',
      zip: '15206',
      isUrban: false,
    },
    {
      name: 'VALEA CORBULUI',
      zip: '15215',
      isUrban: false,
    },
    {
      name: 'VALENI-PODGORIA',
      zip: '15224',
      isUrban: false,
    },
    {
      name: 'CATEASCA',
      zip: '15242',
      isUrban: false,
    },
    {
      name: 'CATANELE',
      zip: '15251',
      isUrban: false,
    },
    {
      name: 'CIRESU',
      zip: '15260',
      isUrban: false,
    },
    {
      name: 'COSERI',
      zip: '15279',
      isUrban: false,
    },
    {
      name: 'GRUIU',
      zip: '15288',
      isUrban: false,
    },
    {
      name: 'RECEA',
      zip: '15297',
      isUrban: false,
    },
    {
      name: 'SILISTEA',
      zip: '15304',
      isUrban: false,
    },
    {
      name: 'CEPARII PAMANTENI',
      zip: '15322',
      isUrban: false,
    },
    {
      name: 'CARPENIS',
      zip: '15331',
      isUrban: false,
    },
    {
      name: 'CEPARII UNGURENI',
      zip: '15340',
      isUrban: false,
    },
    {
      name: 'MORASTI',
      zip: '15359',
      isUrban: false,
    },
    {
      name: 'SENDRULESTI',
      zip: '15368',
      isUrban: false,
    },
    {
      name: 'URLUIESTI',
      zip: '15377',
      isUrban: false,
    },
    {
      name: 'VALEA MAGUREI',
      zip: '15386',
      isUrban: false,
    },
    {
      name: 'ZAMFIRESTI',
      zip: '15395',
      isUrban: false,
    },
    {
      name: 'CETATENI',
      zip: '15411',
      isUrban: false,
    },
    {
      name: 'LAICAI',
      zip: '15420',
      isUrban: false,
    },
    {
      name: 'VALEA CETATUIA',
      zip: '15439',
      isUrban: false,
    },
    {
      name: 'CICANESTI',
      zip: '15457',
      isUrban: false,
    },
    {
      name: 'BARASTI',
      zip: '15466',
      isUrban: false,
    },
    {
      name: 'MIOARELE',
      zip: '15475',
      isUrban: false,
    },
    {
      name: 'URECHESTI',
      zip: '15484',
      isUrban: false,
    },
    {
      name: 'CIOFRANGENI',
      zip: '15509',
      isUrban: false,
    },
    {
      name: 'BURLUSI',
      zip: '15518',
      isUrban: false,
    },
    {
      name: 'LACURILE',
      zip: '15527',
      isUrban: false,
    },
    {
      name: 'PIATRA',
      zip: '15536',
      isUrban: false,
    },
    {
      name: 'SCHITU-MATEI',
      zip: '15545',
      isUrban: false,
    },
    {
      name: 'RADUTESTI',
      zip: '15563',
      isUrban: false,
    },
    {
      name: 'BECULESTI',
      zip: '15572',
      isUrban: false,
    },
    {
      name: 'BRATIA',
      zip: '15581',
      isUrban: false,
    },
    {
      name: 'CIOMAGESTI',
      zip: '15590',
      isUrban: false,
    },
    {
      name: 'CUNGREA',
      zip: '15607',
      isUrban: false,
    },
    {
      name: 'DOGARI',
      zip: '15616',
      isUrban: false,
    },
    {
      name: 'FEDELESOIU',
      zip: '15625',
      isUrban: false,
    },
    {
      name: 'GIUCLANI',
      zip: '15634',
      isUrban: false,
    },
    {
      name: 'PAUNESTI',
      zip: '15643',
      isUrban: false,
    },
    {
      name: 'RACHITELE DE JOS',
      zip: '15661',
      isUrban: false,
    },
    {
      name: 'BARBATESTI',
      zip: '15670',
      isUrban: false,
    },
    {
      name: 'COCU',
      zip: '15689',
      isUrban: false,
    },
    {
      name: 'CRUCISOARA',
      zip: '15698',
      isUrban: false,
    },
    {
      name: 'FACALETESTI',
      zip: '15705',
      isUrban: false,
    },
    {
      name: 'GREABANU',
      zip: '15714',
      isUrban: false,
    },
    {
      name: 'POPESTI',
      zip: '15723',
      isUrban: false,
    },
    {
      name: 'RACHITELE DE SUS',
      zip: '15732',
      isUrban: false,
    },
    {
      name: 'CORBENI',
      zip: '15750',
      isUrban: false,
    },
    {
      name: 'BERINDESTI',
      zip: '15769',
      isUrban: false,
    },
    {
      name: 'BUCSENESTI',
      zip: '15778',
      isUrban: false,
    },
    {
      name: 'OESTII PAMANTENI',
      zip: '15787',
      isUrban: false,
    },
    {
      name: 'OESTII UNGURENI',
      zip: '15796',
      isUrban: false,
    },
    {
      name: 'POIENARI',
      zip: '15803',
      isUrban: false,
    },
    {
      name: 'ROTUNDA',
      zip: '15812',
      isUrban: false,
    },
    {
      name: 'TURBUREA',
      zip: '15821',
      isUrban: false,
    },
    {
      name: 'CORBI',
      zip: '15849',
      isUrban: false,
    },
    {
      name: 'CORBSORI',
      zip: '15858',
      isUrban: false,
    },
    {
      name: 'JGHEABURI',
      zip: '15867',
      isUrban: false,
    },
    {
      name: 'PODURI',
      zip: '15876',
      isUrban: false,
    },
    {
      name: 'POIENAREI',
      zip: '15885',
      isUrban: false,
    },
    {
      name: 'STANESTI',
      zip: '15894',
      isUrban: false,
    },
    {
      name: 'COSESTI',
      zip: '15910',
      isUrban: false,
    },
    {
      name: 'JUPANESTI',
      zip: '15929',
      isUrban: false,
    },
    {
      name: 'LAPUSANI',
      zip: '15938',
      isUrban: false,
    },
    {
      name: 'LEICESTI',
      zip: '15947',
      isUrban: false,
    },
    {
      name: 'PACIOIU',
      zip: '15956',
      isUrban: false,
    },
    {
      name: 'PETRESTI',
      zip: '15965',
      isUrban: false,
    },
    {
      name: 'PRISEACA',
      zip: '15974',
      isUrban: false,
    },
    {
      name: 'COTMEANA',
      zip: '15992',
      isUrban: false,
    },
    {
      name: 'BASCOVELE',
      zip: '16007',
      isUrban: false,
    },
    {
      name: 'BUNESTI',
      zip: '16016',
      isUrban: false,
    },
    {
      name: 'COSTESTI',
      zip: '16025',
      isUrban: false,
    },
    {
      name: 'DEALU PADURII',
      zip: '16034',
      isUrban: false,
    },
    {
      name: 'DRAGOLESTI',
      zip: '16043',
      isUrban: false,
    },
    {
      name: 'LINTESTI',
      zip: '16052',
      isUrban: false,
    },
    {
      name: 'NEGESTI',
      zip: '16061',
      isUrban: false,
    },
    {
      name: 'PIELESTI',
      zip: '16070',
      isUrban: false,
    },
    {
      name: 'SANDULESTI',
      zip: '16089',
      isUrban: false,
    },
    {
      name: 'SPIRIDONI',
      zip: '16098',
      isUrban: false,
    },
    {
      name: 'URSOAIA',
      zip: '16105',
      isUrban: false,
    },
    {
      name: 'VARLOVENI',
      zip: '16114',
      isUrban: false,
    },
    {
      name: 'ZAMFIRESTI',
      zip: '16123',
      isUrban: false,
    },
    {
      name: 'CUCA',
      zip: '16141',
      isUrban: false,
    },
    {
      name: 'BALTATA',
      zip: '16150',
      isUrban: false,
    },
    {
      name: 'BARBALANI',
      zip: '16169',
      isUrban: false,
    },
    {
      name: 'CARCESTI',
      zip: '16178',
      isUrban: false,
    },
    {
      name: 'COTU',
      zip: '16187',
      isUrban: false,
    },
    {
      name: 'CRIVATU',
      zip: '16196',
      isUrban: false,
    },
    {
      name: 'LAUNELE DE SUS',
      zip: '16203',
      isUrban: false,
    },
    {
      name: 'MACAI',
      zip: '16212',
      isUrban: false,
    },
    {
      name: 'MANESTI',
      zip: '16221',
      isUrban: false,
    },
    {
      name: 'SINESTI',
      zip: '16230',
      isUrban: false,
    },
    {
      name: 'STANICEI',
      zip: '16249',
      isUrban: false,
    },
    {
      name: 'TEODORESTI',
      zip: '16258',
      isUrban: false,
    },
    {
      name: 'VALEA CUCII',
      zip: '16267',
      isUrban: false,
    },
    {
      name: 'DAMBOVICIOARA',
      zip: '16338',
      isUrban: false,
    },
    {
      name: 'CIOCANU',
      zip: '16347',
      isUrban: false,
    },
    {
      name: 'PODU DAMBOVITEI',
      zip: '16356',
      isUrban: false,
    },
    {
      name: 'DARMANESTI',
      zip: '16374',
      isUrban: false,
    },
    {
      name: 'NEGRENI',
      zip: '16383',
      isUrban: false,
    },
    {
      name: 'PISCANI',
      zip: '16392',
      isUrban: false,
    },
    {
      name: 'VALEA NANDRII',
      zip: '16409',
      isUrban: false,
    },
    {
      name: 'VALEA RIZII',
      zip: '16418',
      isUrban: false,
    },
    {
      name: 'DAVIDESTI',
      zip: '16294',
      isUrban: false,
    },
    {
      name: 'CONTESTI',
      zip: '16301',
      isUrban: false,
    },
    {
      name: 'VOROVENI',
      zip: '16310',
      isUrban: false,
    },
    {
      name: 'DOBRESTI',
      zip: '16436',
      isUrban: false,
    },
    {
      name: 'FURESTI',
      zip: '16445',
      isUrban: false,
    },
    {
      name: 'DOMNESTI',
      zip: '16463',
      isUrban: false,
    },
    {
      name: 'DRAGANU-OLTENI',
      zip: '16515',
      isUrban: false,
    },
    {
      name: 'BACESTI',
      zip: '16524',
      isUrban: false,
    },
    {
      name: 'DUMBRAVESTI',
      zip: '16533',
      isUrban: false,
    },
    {
      name: 'PRISLOPU MARE',
      zip: '16542',
      isUrban: false,
    },
    {
      name: 'DRAGOSLAVELE',
      zip: '16481',
      isUrban: false,
    },
    {
      name: 'VALEA HOTARULUI',
      zip: '16490',
      isUrban: false,
    },
    {
      name: 'GODENI',
      zip: '16560',
      isUrban: false,
    },
    {
      name: 'BORDEIENI',
      zip: '16579',
      isUrban: false,
    },
    {
      name: 'CAPU PISCULUI',
      zip: '16588',
      isUrban: false,
    },
    {
      name: 'COTESTI',
      zip: '16597',
      isUrban: false,
    },
    {
      name: 'MALU',
      zip: '16604',
      isUrban: false,
    },
    {
      name: 'HARSESTI',
      zip: '16622',
      isUrban: false,
    },
    {
      name: 'CIOBANI',
      zip: '16631',
      isUrban: false,
    },
    {
      name: 'MARTALOGI',
      zip: '16640',
      isUrban: false,
    },
    {
      name: 'HARTIESTI',
      zip: '16668',
      isUrban: false,
    },
    {
      name: 'BARZESTI',
      zip: '16677',
      isUrban: false,
    },
    {
      name: 'DEALU',
      zip: '16686',
      isUrban: false,
    },
    {
      name: 'HULUBA',
      zip: '16695',
      isUrban: false,
    },
    {
      name: 'LESPEZI',
      zip: '16702',
      isUrban: false,
    },
    {
      name: 'LUCIENI',
      zip: '16711',
      isUrban: false,
    },
    {
      name: 'VULTURESTI',
      zip: '16720',
      isUrban: false,
    },
    {
      name: 'IZVORU',
      zip: '16748',
      isUrban: false,
    },
    {
      name: 'LEORDENI',
      zip: '16766',
      isUrban: false,
    },
    {
      name: 'BAILA',
      zip: '16784',
      isUrban: false,
    },
    {
      name: 'BALOTEASCA',
      zip: '16775',
      isUrban: false,
    },
    {
      name: 'BANTAU',
      zip: '16793',
      isUrban: false,
    },
    {
      name: 'BUDISTENI',
      zip: '16800',
      isUrban: false,
    },
    {
      name: 'CARCIUMARESTI',
      zip: '16837',
      isUrban: false,
    },
    {
      name: 'CIOLCESTI',
      zip: '16819',
      isUrban: false,
    },
    {
      name: 'CIULNITA',
      zip: '16828',
      isUrban: false,
    },
    {
      name: 'COTU MALULUI',
      zip: '16846',
      isUrban: false,
    },
    {
      name: 'GLAMBOCATA',
      zip: '16855',
      isUrban: false,
    },
    {
      name: 'GLAMBOCATA-DEAL',
      zip: '16864',
      isUrban: false,
    },
    {
      name: 'GLODU',
      zip: '16873',
      isUrban: false,
    },
    {
      name: 'MOARA MOCANULUI',
      zip: '16882',
      isUrban: false,
    },
    {
      name: 'SCHITU SCOICESTI',
      zip: '16891',
      isUrban: false,
    },
    {
      name: 'LERESTI',
      zip: '16917',
      isUrban: false,
    },
    {
      name: 'POJORATA',
      zip: '16926',
      isUrban: false,
    },
    {
      name: 'VOINESTI',
      zip: '16935',
      isUrban: false,
    },
    {
      name: 'LUNCA CORBULUI',
      zip: '16953',
      isUrban: false,
    },
    {
      name: 'BUMBUENI',
      zip: '16962',
      isUrban: false,
    },
    {
      name: 'CATANE',
      zip: '16971',
      isUrban: false,
    },
    {
      name: 'CIESTI',
      zip: '16980',
      isUrban: false,
    },
    {
      name: 'LANGESTI',
      zip: '16999',
      isUrban: false,
    },
    {
      name: 'MARGHIA DE JOS',
      zip: '17003',
      isUrban: false,
    },
    {
      name: 'MARGHIA DE SUS',
      zip: '17012',
      isUrban: false,
    },
    {
      name: 'PADURETI',
      zip: '17021',
      isUrban: false,
    },
    {
      name: 'SILISTENI',
      zip: '17030',
      isUrban: false,
    },
    {
      name: 'MALURENI',
      zip: '17058',
      isUrban: false,
    },
    {
      name: 'BUNESTI',
      zip: '17067',
      isUrban: false,
    },
    {
      name: 'PAULEASCA',
      zip: '17076',
      isUrban: false,
    },
    {
      name: 'TOPLITA',
      zip: '17085',
      isUrban: false,
    },
    {
      name: 'ZARNESTI',
      zip: '17094',
      isUrban: false,
    },
    {
      name: 'MARACINENI',
      zip: '13374',
      isUrban: false,
    },
    {
      name: 'ARGESELU',
      zip: '13383',
      isUrban: false,
    },
    {
      name: 'MERISANI',
      zip: '17110',
      isUrban: false,
    },
    {
      name: 'BORLESTI',
      zip: '17129',
      isUrban: false,
    },
    {
      name: 'BRATEASCA',
      zip: '17138',
      isUrban: false,
    },
    {
      name: 'CAPU PISCULUI',
      zip: '17147',
      isUrban: false,
    },
    {
      name: 'CRAMPOTANI',
      zip: '17156',
      isUrban: false,
    },
    {
      name: 'DOBROGOSTEA',
      zip: '17165',
      isUrban: false,
    },
    {
      name: 'MALU VINAT',
      zip: '17174',
      isUrban: false,
    },
    {
      name: 'VALCELELE',
      zip: '17192',
      isUrban: false,
    },
    {
      name: 'VARZARU',
      zip: '17183',
      isUrban: false,
    },
    {
      name: 'MICESTI',
      zip: '17218',
      isUrban: false,
    },
    {
      name: 'BRANZARI',
      zip: '17227',
      isUrban: false,
    },
    {
      name: 'PAULEASCA',
      zip: '17236',
      isUrban: false,
    },
    {
      name: 'PURCARENI',
      zip: '17245',
      isUrban: false,
    },
    {
      name: 'MIHAESTI',
      zip: '17263',
      isUrban: false,
    },
    {
      name: 'DRAGHICI',
      zip: '17272',
      isUrban: false,
    },
    {
      name: 'FURNICOSI',
      zip: '17281',
      isUrban: false,
    },
    {
      name: 'RUDENI',
      zip: '17290',
      isUrban: false,
    },
    {
      name: 'VACAREA',
      zip: '17325',
      isUrban: false,
    },
    {
      name: 'VALEA BRADULUI',
      zip: '17307',
      isUrban: false,
    },
    {
      name: 'VALEA POPII',
      zip: '17316',
      isUrban: false,
    },
    {
      name: 'MATAU',
      zip: '17343',
      isUrban: false,
    },
    {
      name: 'ALUNIS',
      zip: '17352',
      isUrban: false,
    },
    {
      name: 'CHILII',
      zip: '17361',
      isUrban: false,
    },
    {
      name: 'COCENESTI',
      zip: '17370',
      isUrban: false,
    },
    {
      name: 'SUSLANESTI',
      zip: '17389',
      isUrban: false,
    },
    {
      name: 'MIROSI',
      zip: '17405',
      isUrban: false,
    },
    {
      name: 'SURDULESTI',
      zip: '17414',
      isUrban: false,
    },
    {
      name: 'MORARESTI',
      zip: '17432',
      isUrban: false,
    },
    {
      name: 'DEALU OBEJDEANULUI',
      zip: '17450',
      isUrban: false,
    },
    {
      name: 'DEDULESTI',
      zip: '17441',
      isUrban: false,
    },
    {
      name: 'LUMINILE',
      zip: '17469',
      isUrban: false,
    },
    {
      name: 'MANCIOIU',
      zip: '17478',
      isUrban: false,
    },
    {
      name: 'SAPUNARI',
      zip: '17487',
      isUrban: false,
    },
    {
      name: 'MOSOAIA',
      zip: '17502',
      isUrban: false,
    },
    {
      name: 'BATRANI',
      zip: '17511',
      isUrban: false,
    },
    {
      name: 'CIOCANAI',
      zip: '17520',
      isUrban: false,
    },
    {
      name: 'DEALU VIILOR',
      zip: '17539',
      isUrban: false,
    },
    {
      name: 'HINTESTI',
      zip: '17548',
      isUrban: false,
    },
    {
      name: 'LAZARESTI',
      zip: '17557',
      isUrban: false,
    },
    {
      name: 'SMEURA',
      zip: '17566',
      isUrban: false,
    },
    {
      name: 'MOZACENI',
      zip: '17584',
      isUrban: false,
    },
    {
      name: 'BABAROAGA',
      zip: '17593',
      isUrban: false,
    },
    {
      name: 'ZIDURILE',
      zip: '17600',
      isUrban: false,
    },
    {
      name: 'VILSANESTI',
      zip: '17628',
      isUrban: false,
    },
    {
      name: 'BOLOVANESTI',
      zip: '17637',
      isUrban: false,
    },
    {
      name: 'COSTESTI-VISLAN',
      zip: '17646',
      isUrban: false,
    },
    {
      name: 'MUSATESTI',
      zip: '17655',
      isUrban: false,
    },
    {
      name: 'PROSIA',
      zip: '17664',
      isUrban: false,
    },
    {
      name: 'ROBAIA',
      zip: '17673',
      isUrban: false,
    },
    {
      name: 'STROESTI',
      zip: '17682',
      isUrban: false,
    },
    {
      name: 'VALEA FAURULUI',
      zip: '17691',
      isUrban: false,
    },
    {
      name: 'VALEA LUI MAS',
      zip: '17708',
      isUrban: false,
    },
    {
      name: 'VALEA MUSCELULUI',
      zip: '17717',
      isUrban: false,
    },
    {
      name: 'NEGRASI',
      zip: '17735',
      isUrban: false,
    },
    {
      name: 'BARLOGU',
      zip: '17744',
      isUrban: false,
    },
    {
      name: 'BUTA',
      zip: '17753',
      isUrban: false,
    },
    {
      name: 'MOZACU',
      zip: '17762',
      isUrban: false,
    },
    {
      name: 'NUCSOARA',
      zip: '17780',
      isUrban: false,
    },
    {
      name: 'GRUIU',
      zip: '17799',
      isUrban: false,
    },
    {
      name: 'SBOGHITESTI',
      zip: '17806',
      isUrban: false,
    },
    {
      name: 'SLATINA',
      zip: '17815',
      isUrban: false,
    },
    {
      name: 'OARJA',
      zip: '17833',
      isUrban: false,
    },
    {
      name: 'CEAUSESTI',
      zip: '17842',
      isUrban: false,
    },
    {
      name: 'PIETROSANI',
      zip: '17860',
      isUrban: false,
    },
    {
      name: 'BADESTI',
      zip: '17879',
      isUrban: false,
    },
    {
      name: 'GANESTI',
      zip: '17888',
      isUrban: false,
    },
    {
      name: 'RETEVOIESTI',
      zip: '17897',
      isUrban: false,
    },
    {
      name: 'VARZAROAIA',
      zip: '17904',
      isUrban: false,
    },
    {
      name: 'POIANA LACULUI',
      zip: '18037',
      isUrban: false,
    },
    {
      name: 'CATUNASI',
      zip: '18046',
      isUrban: false,
    },
    {
      name: 'CEPARI',
      zip: '18055',
      isUrban: false,
    },
    {
      name: 'DEALU ORASULUI',
      zip: '18064',
      isUrban: false,
    },
    {
      name: 'DEALU VIILOR',
      zip: '18073',
      isUrban: false,
    },
    {
      name: 'DINCULESTI',
      zip: '18082',
      isUrban: false,
    },
    {
      name: 'GALCESTI',
      zip: '18117',
      isUrban: false,
    },
    {
      name: 'GALETEANU',
      zip: '18091',
      isUrban: false,
    },
    {
      name: 'GARDINESTI',
      zip: '18108',
      isUrban: false,
    },
    {
      name: 'METOFU',
      zip: '18126',
      isUrban: false,
    },
    {
      name: 'PADUROIU DIN DEAL',
      zip: '18135',
      isUrban: false,
    },
    {
      name: 'PADUROIU DIN VALE',
      zip: '18144',
      isUrban: false,
    },
    {
      name: 'SAMARA',
      zip: '18153',
      isUrban: false,
    },
    {
      name: 'POIENARI',
      zip: '17922',
      isUrban: false,
    },
    {
      name: 'CEAURESTI',
      zip: '17931',
      isUrban: false,
    },
    {
      name: 'IOANICESTI',
      zip: '17940',
      isUrban: false,
    },
    {
      name: 'TOMULESTI',
      zip: '17959',
      isUrban: false,
    },
    {
      name: 'POIENARI',
      zip: '17977',
      isUrban: false,
    },
    {
      name: 'GROSANI',
      zip: '17986',
      isUrban: false,
    },
    {
      name: 'JUGUR',
      zip: '17995',
      isUrban: false,
    },
    {
      name: 'SERBANESTI',
      zip: '18000',
      isUrban: false,
    },
    {
      name: 'VALEA INDARAT',
      zip: '18019',
      isUrban: false,
    },
    {
      name: 'POPESTI',
      zip: '18171',
      isUrban: false,
    },
    {
      name: 'ADUNATI',
      zip: '18180',
      isUrban: false,
    },
    {
      name: 'BUCOV',
      zip: '18199',
      isUrban: false,
    },
    {
      name: 'PALANGA',
      zip: '18206',
      isUrban: false,
    },
    {
      name: 'PURCARENI',
      zip: '18215',
      isUrban: false,
    },
    {
      name: 'RACA',
      zip: '18224',
      isUrban: false,
    },
    {
      name: 'SLOBOZIA',
      zip: '18233',
      isUrban: false,
    },
    {
      name: 'PRIBOIENI',
      zip: '18251',
      isUrban: false,
    },
    {
      name: 'ALBOTELE',
      zip: '18260',
      isUrban: false,
    },
    {
      name: 'PARASCHIVESTI',
      zip: '18279',
      isUrban: false,
    },
    {
      name: 'PITOI',
      zip: '18297',
      isUrban: false,
    },
    {
      name: 'SAMAILA',
      zip: '18288',
      isUrban: false,
    },
    {
      name: 'VALEA MARE',
      zip: '18304',
      isUrban: false,
    },
    {
      name: 'VALEA NENII',
      zip: '18313',
      isUrban: false,
    },
    {
      name: 'VALEA POPII',
      zip: '18322',
      isUrban: false,
    },
    {
      name: 'RATESTI',
      zip: '18340',
      isUrban: false,
    },
    {
      name: 'CIUPA-MANCIULESCU',
      zip: '18359',
      isUrban: false,
    },
    {
      name: 'FURDUESTI',
      zip: '18368',
      isUrban: false,
    },
    {
      name: 'MAVRODOLU',
      zip: '18377',
      isUrban: false,
    },
    {
      name: 'NEJLOVELU',
      zip: '18386',
      isUrban: false,
    },
    {
      name: 'PATULENI',
      zip: '18395',
      isUrban: false,
    },
    {
      name: 'TIGVENI',
      zip: '18402',
      isUrban: false,
    },
    {
      name: 'RECEA',
      zip: '18420',
      isUrban: false,
    },
    {
      name: 'DEAGU DE JOS',
      zip: '18439',
      isUrban: false,
    },
    {
      name: 'DEAGU DE SUS',
      zip: '18448',
      isUrban: false,
    },
    {
      name: 'GOLEASCA',
      zip: '18457',
      isUrban: false,
    },
    {
      name: 'ORODEL',
      zip: '18466',
      isUrban: false,
    },
    {
      name: 'ROCIU',
      zip: '18484',
      isUrban: false,
    },
    {
      name: 'GLIGANU DE JOS',
      zip: '18493',
      isUrban: false,
    },
    {
      name: 'GLIGANU DE SUS',
      zip: '18509',
      isUrban: false,
    },
    {
      name: 'SERBANESTI',
      zip: '18518',
      isUrban: false,
    },
    {
      name: 'RUCAR',
      zip: '18536',
      isUrban: false,
    },
    {
      name: 'SATIC',
      zip: '18545',
      isUrban: false,
    },
    {
      name: 'SALATRUCU',
      zip: '18563',
      isUrban: false,
    },
    {
      name: 'VALENI',
      zip: '18572',
      isUrban: false,
    },
    {
      name: 'MARTESTI',
      zip: '18590',
      isUrban: false,
    },
    {
      name: 'BANARESTI',
      zip: '18607',
      isUrban: false,
    },
    {
      name: 'DEALU BRADULUI',
      zip: '18616',
      isUrban: false,
    },
    {
      name: 'DRAGHICESTI',
      zip: '18625',
      isUrban: false,
    },
    {
      name: 'GAINUSA',
      zip: '18634',
      isUrban: false,
    },
    {
      name: 'LIPIA',
      zip: '18643',
      isUrban: false,
    },
    {
      name: 'POPESTI',
      zip: '18652',
      isUrban: false,
    },
    {
      name: 'TURCESTI',
      zip: '18661',
      isUrban: false,
    },
    {
      name: 'SCHITU GOLESTI',
      zip: '18689',
      isUrban: false,
    },
    {
      name: 'BURNESTI',
      zip: '18698',
      isUrban: false,
    },
    {
      name: 'COSTITA',
      zip: '18705',
      isUrban: false,
    },
    {
      name: 'LAZARESTI',
      zip: '18714',
      isUrban: false,
    },
    {
      name: 'LOTURI',
      zip: '18723',
      isUrban: false,
    },
    {
      name: 'VALEA PECHII',
      zip: '18732',
      isUrban: false,
    },
    {
      name: 'SLOBOZIA',
      zip: '18750',
      isUrban: false,
    },
    {
      name: 'NIGRISOARA',
      zip: '18769',
      isUrban: false,
    },
    {
      name: 'STALPENI',
      zip: '18787',
      isUrban: false,
    },
    {
      name: 'DEALU FRUMOS',
      zip: '18796',
      isUrban: false,
    },
    {
      name: 'LIVEZENI',
      zip: '18803',
      isUrban: false,
    },
    {
      name: 'OGREZEA',
      zip: '18812',
      isUrban: false,
    },
    {
      name: 'OPRESTI',
      zip: '18821',
      isUrban: false,
    },
    {
      name: 'PITIGAIA',
      zip: '18830',
      isUrban: false,
    },
    {
      name: 'RADESTI',
      zip: '18849',
      isUrban: false,
    },
    {
      name: 'STEFAN CEL MARE',
      zip: '19123',
      isUrban: false,
    },
    {
      name: 'GLAVACIOC',
      zip: '19132',
      isUrban: false,
    },
    {
      name: 'STEFANESTI',
      zip: '13409',
      isUrban: true,
    },
    {
      name: 'ENCULESTI',
      zip: '13418',
      isUrban: true,
    },
    {
      name: 'GOLESTI',
      zip: '13427',
      isUrban: true,
    },
    {
      name: 'IZVORANI',
      zip: '13436',
      isUrban: true,
    },
    {
      name: 'STEFANESTII NOI',
      zip: '13445',
      isUrban: true,
    },
    {
      name: 'VALEA MARE-PODGORIA',
      zip: '13454',
      isUrban: true,
    },
    {
      name: 'VIISOARA',
      zip: '13463',
      isUrban: true,
    },
    {
      name: 'ZAVOI',
      zip: '13472',
      isUrban: true,
    },
    {
      name: 'STOENESTI',
      zip: '18867',
      isUrban: false,
    },
    {
      name: 'BADENI',
      zip: '18876',
      isUrban: false,
    },
    {
      name: 'COTENESTI',
      zip: '18885',
      isUrban: false,
    },
    {
      name: 'LUNCA GIRTII',
      zip: '18894',
      isUrban: false,
    },
    {
      name: 'PIATRA',
      zip: '18901',
      isUrban: false,
    },
    {
      name: 'SLOBOZIA',
      zip: '18910',
      isUrban: false,
    },
    {
      name: 'VALEA BADENILOR',
      zip: '18929',
      isUrban: false,
    },
    {
      name: 'STOLNICI',
      zip: '18947',
      isUrban: false,
    },
    {
      name: 'COCHINESTI',
      zip: '18956',
      isUrban: false,
    },
    {
      name: 'COTMEANA',
      zip: '18965',
      isUrban: false,
    },
    {
      name: 'FALFANI',
      zip: '18974',
      isUrban: false,
    },
    {
      name: 'IZBASESTI',
      zip: '18983',
      isUrban: false,
    },
    {
      name: 'VLASCUTA',
      zip: '18992',
      isUrban: false,
    },
    {
      name: 'SUICI',
      zip: '19150',
      isUrban: false,
    },
    {
      name: 'IANCULESTI',
      zip: '19169',
      isUrban: false,
    },
    {
      name: 'PALTENU',
      zip: '19178',
      isUrban: false,
    },
    {
      name: 'PAULENI',
      zip: '19187',
      isUrban: false,
    },
    {
      name: 'RUDENI',
      zip: '19196',
      isUrban: false,
    },
    {
      name: 'VALEA CALULUI',
      zip: '19203',
      isUrban: false,
    },
    {
      name: 'SUSENI',
      zip: '19016',
      isUrban: false,
    },
    {
      name: 'BURDESTI',
      zip: '19025',
      isUrban: false,
    },
    {
      name: 'CERSANI',
      zip: '19034',
      isUrban: false,
    },
    {
      name: 'CHIRITESTI',
      zip: '19043',
      isUrban: false,
    },
    {
      name: 'GALESESTI',
      zip: '19052',
      isUrban: false,
    },
    {
      name: 'ODAENI',
      zip: '19061',
      isUrban: false,
    },
    {
      name: 'PADURENI',
      zip: '19070',
      isUrban: false,
    },
    {
      name: 'STEFANESTI',
      zip: '19098',
      isUrban: false,
    },
    {
      name: 'STRAMBENI',
      zip: '19089',
      isUrban: false,
    },
    {
      name: 'TIGVENI',
      zip: '19258',
      isUrban: false,
    },
    {
      name: 'BADISLAVA',
      zip: '19267',
      isUrban: false,
    },
    {
      name: 'BALILESTI',
      zip: '19276',
      isUrban: false,
    },
    {
      name: 'BALTENI',
      zip: '19285',
      isUrban: false,
    },
    {
      name: 'BARSESTII DE JOS',
      zip: '19294',
      isUrban: false,
    },
    {
      name: 'BARSESTII DE SUS',
      zip: '19301',
      isUrban: false,
    },
    {
      name: 'BLAJU',
      zip: '19310',
      isUrban: false,
    },
    {
      name: 'VLADESTI',
      zip: '19329',
      isUrban: false,
    },
    {
      name: 'TITESTI',
      zip: '19347',
      isUrban: false,
    },
    {
      name: 'BUCSENESTI-LOTASI',
      zip: '19356',
      isUrban: false,
    },
    {
      name: 'CISMEA',
      zip: '19365',
      isUrban: false,
    },
    {
      name: 'VALEA MANASTIRII',
      zip: '19374',
      isUrban: false,
    },
    {
      name: 'VALEA STANII',
      zip: '19383',
      isUrban: false,
    },
    {
      name: 'UDA',
      zip: '19409',
      isUrban: false,
    },
    {
      name: 'BADULESTI',
      zip: '19418',
      isUrban: false,
    },
    {
      name: 'BARANESTI',
      zip: '19427',
      isUrban: false,
    },
    {
      name: 'BRANISTEA',
      zip: '19436',
      isUrban: false,
    },
    {
      name: 'CHIRITESTI',
      zip: '19445',
      isUrban: false,
    },
    {
      name: 'COTU',
      zip: '19454',
      isUrban: false,
    },
    {
      name: 'DEALU BISERICII',
      zip: '19463',
      isUrban: false,
    },
    {
      name: 'DEALU TOLCESII',
      zip: '19472',
      isUrban: false,
    },
    {
      name: 'DICONESTI',
      zip: '19481',
      isUrban: false,
    },
    {
      name: 'GORANI',
      zip: '19490',
      isUrban: false,
    },
    {
      name: 'GREABAN',
      zip: '19506',
      isUrban: false,
    },
    {
      name: 'LUNGULESTI',
      zip: '19515',
      isUrban: false,
    },
    {
      name: 'MIERCANI',
      zip: '19524',
      isUrban: false,
    },
    {
      name: 'RAJLETU-GOVORA',
      zip: '19533',
      isUrban: false,
    },
    {
      name: 'ROMANA',
      zip: '19542',
      isUrban: false,
    },
    {
      name: 'SALISTEA',
      zip: '19551',
      isUrban: false,
    },
    {
      name: 'UNGHENI',
      zip: '19579',
      isUrban: false,
    },
    {
      name: 'COLTU',
      zip: '19588',
      isUrban: false,
    },
    {
      name: 'GAUJANI',
      zip: '19597',
      isUrban: false,
    },
    {
      name: 'GOIA',
      zip: '19604',
      isUrban: false,
    },
    {
      name: 'HUMELE',
      zip: '19613',
      isUrban: false,
    },
    {
      name: 'SATU NOU',
      zip: '19622',
      isUrban: false,
    },
    {
      name: 'VALEA DANULUI',
      zip: '19640',
      isUrban: false,
    },
    {
      name: 'BANICESTI',
      zip: '19659',
      isUrban: false,
    },
    {
      name: 'BOLCULESTI',
      zip: '19668',
      isUrban: false,
    },
    {
      name: 'BOROBANESTI',
      zip: '19677',
      isUrban: false,
    },
    {
      name: 'VERNESTI',
      zip: '19686',
      isUrban: false,
    },
    {
      name: 'VALEA IASULUI',
      zip: '19702',
      isUrban: false,
    },
    {
      name: 'BADILA',
      zip: '19711',
      isUrban: false,
    },
    {
      name: 'BARBALATESTI',
      zip: '19720',
      isUrban: false,
    },
    {
      name: 'BOROVINESTI',
      zip: '19739',
      isUrban: false,
    },
    {
      name: 'CERBURENI',
      zip: '19748',
      isUrban: false,
    },
    {
      name: 'MUSTATESTI',
      zip: '19757',
      isUrban: false,
    },
    {
      name: 'RUGINOASA',
      zip: '19766',
      isUrban: false,
    },
    {
      name: 'UNGURENI',
      zip: '19775',
      isUrban: false,
    },
    {
      name: 'VALEA ULEIULUI',
      zip: '19784',
      isUrban: false,
    },
    {
      name: 'VALEA MARE PRAVAT',
      zip: '13533',
      isUrban: false,
    },
    {
      name: 'BILCESTI',
      zip: '13542',
      isUrban: false,
    },
    {
      name: 'COLNIC',
      zip: '13551',
      isUrban: false,
    },
    {
      name: 'FINTINEA',
      zip: '13560',
      isUrban: false,
    },
    {
      name: 'GURA PRAVAT',
      zip: '13579',
      isUrban: false,
    },
    {
      name: 'NAMAESTI',
      zip: '13588',
      isUrban: false,
    },
    {
      name: 'PIETROASA',
      zip: '13597',
      isUrban: false,
    },
    {
      name: 'SELARI',
      zip: '13604',
      isUrban: false,
    },
    {
      name: 'VEDEA',
      zip: '19800',
      isUrban: false,
    },
    {
      name: 'BADICEA',
      zip: '19819',
      isUrban: false,
    },
    {
      name: 'BLEJANI',
      zip: '19828',
      isUrban: false,
    },
    {
      name: 'BURETESTI',
      zip: '19837',
      isUrban: false,
    },
    {
      name: 'CHIRITESTI',
      zip: '19846',
      isUrban: false,
    },
    {
      name: 'CHITANI',
      zip: '19855',
      isUrban: false,
    },
    {
      name: 'CIURESTI',
      zip: '19864',
      isUrban: false,
    },
    {
      name: 'DINCANI',
      zip: '19873',
      isUrban: false,
    },
    {
      name: 'FATA',
      zip: '19882',
      isUrban: false,
    },
    {
      name: 'FRATICI',
      zip: '19891',
      isUrban: false,
    },
    {
      name: 'IZVORU DE JOS',
      zip: '19908',
      isUrban: false,
    },
    {
      name: 'IZVORU DE SUS',
      zip: '19917',
      isUrban: false,
    },
    {
      name: 'LUNGANI',
      zip: '19926',
      isUrban: false,
    },
    {
      name: 'MOGOSESTI',
      zip: '19935',
      isUrban: false,
    },
    {
      name: 'PRODANI',
      zip: '19944',
      isUrban: false,
    },
    {
      name: 'RATOI',
      zip: '19953',
      isUrban: false,
    },
    {
      name: 'VARSESTI',
      zip: '19980',
      isUrban: false,
    },
    {
      name: 'VATA',
      zip: '19962',
      isUrban: false,
    },
    {
      name: 'VETISOARA',
      zip: '19971',
      isUrban: false,
    },
    {
      name: 'VLADESTI',
      zip: '20000',
      isUrban: false,
    },
    {
      name: 'COTEASCA',
      zip: '20019',
      isUrban: false,
    },
    {
      name: 'DRAGHESCU',
      zip: '20028',
      isUrban: false,
    },
    {
      name: 'PUTINA',
      zip: '20037',
      isUrban: false,
    },
    {
      name: 'VONIGEASA',
      zip: '16276',
      isUrban: false,
    },
    {
      name: 'TUTULESTI',
      zip: '19105',
      isUrban: false,
    },
    {
      name: 'TEIU',
      zip: '19221',
      isUrban: false,
    },
    {
      name: 'LESILE',
      zip: '19230',
      isUrban: false,
    }
  ],
  BC: [
    {
      name: 'BACAU',
      zip: '20304',
      isUrban: true,
    },
    {
      name: 'ONESTI',
      zip: '20572',
      isUrban: true,
    },
    {
      name: 'BORZESTI',
      zip: '20581',
      isUrban: true,
    },
    {
      name: 'SLOBOZIA',
      zip: '20590',
      isUrban: true,
    },
    {
      name: 'BUHUSI',
      zip: '20787',
      isUrban: true,
    },
    {
      name: 'MARGINEA',
      zip: '20796',
      isUrban: true,
    },
    {
      name: 'RUNCU',
      zip: '20803',
      isUrban: true,
    },
    {
      name: 'COMANESTI',
      zip: '20830',
      isUrban: true,
    },
    {
      name: 'PODEI',
      zip: '20849',
      isUrban: true,
    },
    {
      name: 'VERMESTI',
      zip: '20858',
      isUrban: true,
    },
    {
      name: 'DARMANESTI',
      zip: '22175',
      isUrban: true,
    },
    {
      name: 'DARMANEASCA',
      zip: '22184',
      isUrban: true,
    },
    {
      name: 'LAPOS',
      zip: '22193',
      isUrban: true,
    },
    {
      name: 'PAGUBENI',
      zip: '22200',
      isUrban: true,
    },
    {
      name: 'PLOPU',
      zip: '22219',
      isUrban: true,
    },
    {
      name: 'SALATRUC',
      zip: '22228',
      isUrban: true,
    },
    {
      name: 'MOINESTI',
      zip: '20885',
      isUrban: true,
    },
    {
      name: 'GAZARIE',
      zip: '20894',
      isUrban: true,
    },
    {
      name: 'SLANIC MOLDOVA',
      zip: '20929',
      isUrban: true,
    },
    {
      name: 'CERDAC',
      zip: '20938',
      isUrban: true,
    },
    {
      name: 'CIRESOAIA',
      zip: '20947',
      isUrban: true,
    },
    {
      name: 'TARGU OCNA',
      zip: '20974',
      isUrban: true,
    },
    {
      name: 'POIENI',
      zip: '20983',
      isUrban: true,
    },
    {
      name: 'VALCELE',
      zip: '20992',
      isUrban: true,
    },
    {
      name: 'AGAS',
      zip: '21016',
      isUrban: false,
    },
    {
      name: 'BELEGHET',
      zip: '21025',
      isUrban: false,
    },
    {
      name: 'COSNEA',
      zip: '21034',
      isUrban: false,
    },
    {
      name: 'COTUMBA',
      zip: '21043',
      isUrban: false,
    },
    {
      name: 'DIACONESTI',
      zip: '21052',
      isUrban: false,
    },
    {
      name: 'GOIOASA',
      zip: '21061',
      isUrban: false,
    },
    {
      name: 'PRELUCI',
      zip: '21070',
      isUrban: false,
    },
    {
      name: 'SULTA',
      zip: '21089',
      isUrban: false,
    },
    {
      name: 'ARDEOANI',
      zip: '21105',
      isUrban: false,
    },
    {
      name: 'LEONTINESTI',
      zip: '21114',
      isUrban: false,
    },
    {
      name: 'ASAU',
      zip: '21132',
      isUrban: false,
    },
    {
      name: 'APA ASAU',
      zip: '21141',
      isUrban: false,
    },
    {
      name: 'CIOBANUS',
      zip: '21150',
      isUrban: false,
    },
    {
      name: 'LUNCA ASAU',
      zip: '21169',
      isUrban: false,
    },
    {
      name: 'PALTINIS',
      zip: '21178',
      isUrban: false,
    },
    {
      name: 'STRAJA',
      zip: '21187',
      isUrban: false,
    },
    {
      name: 'BALCANI',
      zip: '21203',
      isUrban: false,
    },
    {
      name: 'FRUMOASA',
      zip: '21212',
      isUrban: false,
    },
    {
      name: 'LUDASI',
      zip: '21221',
      isUrban: false,
    },
    {
      name: 'SCHITU FRUMOASA',
      zip: '21230',
      isUrban: false,
    },
    {
      name: 'BARSANESTI',
      zip: '21463',
      isUrban: false,
    },
    {
      name: 'ALBELE',
      zip: '21472',
      isUrban: false,
    },
    {
      name: 'BRATESTI',
      zip: '21481',
      isUrban: false,
    },
    {
      name: 'CARACLAU',
      zip: '21490',
      isUrban: false,
    },
    {
      name: 'BERESTI-BISTRITA',
      zip: '21258',
      isUrban: false,
    },
    {
      name: 'BRAD',
      zip: '21267',
      isUrban: false,
    },
    {
      name: 'CIUMASI',
      zip: '21276',
      isUrban: false,
    },
    {
      name: 'CLIMESTI',
      zip: '21285',
      isUrban: false,
    },
    {
      name: 'DUMBRAVA',
      zip: '21294',
      isUrban: false,
    },
    {
      name: 'FAGETEL',
      zip: '21301',
      isUrban: false,
    },
    {
      name: 'ITESTI',
      zip: '21310',
      isUrban: false,
    },
    {
      name: 'PADURENI',
      zip: '21329',
      isUrban: false,
    },
    {
      name: 'BERESTI-TAZLAU',
      zip: '21347',
      isUrban: false,
    },
    {
      name: 'BOSOTENI',
      zip: '21356',
      isUrban: false,
    },
    {
      name: 'ENACHESTI',
      zip: '21365',
      isUrban: false,
    },
    {
      name: 'PRISACA',
      zip: '21374',
      isUrban: false,
    },
    {
      name: 'ROMANESTI',
      zip: '21383',
      isUrban: false,
    },
    {
      name: 'TESCANI',
      zip: '21392',
      isUrban: false,
    },
    {
      name: 'TURLUIANU',
      zip: '21409',
      isUrban: false,
    },
    {
      name: 'BERZUNTI',
      zip: '21427',
      isUrban: false,
    },
    {
      name: 'BUDA',
      zip: '21436',
      isUrban: false,
    },
    {
      name: 'DRAGOMIR',
      zip: '21445',
      isUrban: false,
    },
    {
      name: 'BLAGESTI',
      zip: '21515',
      isUrban: false,
    },
    {
      name: 'BUDA',
      zip: '21524',
      isUrban: false,
    },
    {
      name: 'POIANA NEGUSTORULUI',
      zip: '21533',
      isUrban: false,
    },
    {
      name: 'TARDENII MARI',
      zip: '21542',
      isUrban: false,
    },
    {
      name: 'VALEA LUI ION',
      zip: '21551',
      isUrban: false,
    },
    {
      name: 'BOGDANESTI',
      zip: '21579',
      isUrban: false,
    },
    {
      name: 'FILIPESTI',
      zip: '21588',
      isUrban: false,
    },
    {
      name: 'BRUSTUROASA',
      zip: '21604',
      isUrban: false,
    },
    {
      name: 'BURUIENIS',
      zip: '21613',
      isUrban: false,
    },
    {
      name: 'BURUIENISU DE SUS',
      zip: '21622',
      isUrban: false,
    },
    {
      name: 'CAMENCA',
      zip: '21631',
      isUrban: false,
    },
    {
      name: 'CUCHINIS',
      zip: '21640',
      isUrban: false,
    },
    {
      name: 'HANGANESTI',
      zip: '21659',
      isUrban: false,
    },
    {
      name: 'BUHOCI',
      zip: '21677',
      isUrban: false,
    },
    {
      name: 'BIJGHIR',
      zip: '21686',
      isUrban: false,
    },
    {
      name: 'BUHOCEL',
      zip: '21695',
      isUrban: false,
    },
    {
      name: 'COTENI',
      zip: '21702',
      isUrban: false,
    },
    {
      name: 'DOSPINESTI',
      zip: '21711',
      isUrban: false,
    },
    {
      name: 'CAIUTI',
      zip: '21766',
      isUrban: false,
    },
    {
      name: 'BLIDARI',
      zip: '21775',
      isUrban: false,
    },
    {
      name: 'BOISTEA',
      zip: '21784',
      isUrban: false,
    },
    {
      name: 'FLORESTI',
      zip: '21793',
      isUrban: false,
    },
    {
      name: 'HELTIU',
      zip: '21800',
      isUrban: false,
    },
    {
      name: 'MARCESTI',
      zip: '21819',
      isUrban: false,
    },
    {
      name: 'POPENI',
      zip: '21828',
      isUrban: false,
    },
    {
      name: 'PRALEA',
      zip: '21837',
      isUrban: false,
    },
    {
      name: 'VRANCENI',
      zip: '21846',
      isUrban: false,
    },
    {
      name: 'CASIN',
      zip: '21739',
      isUrban: false,
    },
    {
      name: 'CURITA',
      zip: '21748',
      isUrban: false,
    },
    {
      name: 'CLEJA',
      zip: '21864',
      isUrban: false,
    },
    {
      name: 'SOMUSCA',
      zip: '21873',
      isUrban: false,
    },
    {
      name: 'VALEA MICA',
      zip: '21882',
      isUrban: false,
    },
    {
      name: 'COLONESTI',
      zip: '21908',
      isUrban: false,
    },
    {
      name: 'CALINI',
      zip: '21917',
      isUrban: false,
    },
    {
      name: 'POIANA',
      zip: '21926',
      isUrban: false,
    },
    {
      name: 'SATU NOU',
      zip: '21935',
      isUrban: false,
    },
    {
      name: 'SPRIA',
      zip: '21944',
      isUrban: false,
    },
    {
      name: 'VALEA MARE',
      zip: '21953',
      isUrban: false,
    },
    {
      name: 'ZAPODIA',
      zip: '21962',
      isUrban: false,
    },
    {
      name: 'CORBASCA',
      zip: '21980',
      isUrban: false,
    },
    {
      name: 'BACIOIU',
      zip: '21999',
      isUrban: false,
    },
    {
      name: 'MARVILA',
      zip: '22004',
      isUrban: false,
    },
    {
      name: 'POGLET',
      zip: '22013',
      isUrban: false,
    },
    {
      name: 'ROGOAZA',
      zip: '22022',
      isUrban: false,
    },
    {
      name: 'SCARISOARA',
      zip: '22031',
      isUrban: false,
    },
    {
      name: 'VALCELE',
      zip: '22040',
      isUrban: false,
    },
    {
      name: 'COTOFANESTI',
      zip: '22068',
      isUrban: false,
    },
    {
      name: 'BALCA',
      zip: '22077',
      isUrban: false,
    },
    {
      name: 'BOISTEA DE JOS',
      zip: '22086',
      isUrban: false,
    },
    {
      name: 'BORSANI',
      zip: '22095',
      isUrban: false,
    },
    {
      name: 'TAMASOAIA',
      zip: '22102',
      isUrban: false,
    },
    {
      name: 'DAMIENESTI',
      zip: '22120',
      isUrban: false,
    },
    {
      name: 'CALUGARENI',
      zip: '22139',
      isUrban: false,
    },
    {
      name: 'DRAGESTI',
      zip: '22148',
      isUrban: false,
    },
    {
      name: 'PADURENI',
      zip: '22157',
      isUrban: false,
    },
    {
      name: 'DEALU MORII',
      zip: '22246',
      isUrban: false,
    },
    {
      name: 'BALANESTI',
      zip: '22264',
      isUrban: false,
    },
    {
      name: 'BANCA',
      zip: '22255',
      isUrban: false,
    },
    {
      name: 'BLAGA',
      zip: '22273',
      isUrban: false,
    },
    {
      name: 'BOBOS',
      zip: '22282',
      isUrban: false,
    },
    {
      name: 'BODEASA',
      zip: '22291',
      isUrban: false,
    },
    {
      name: 'BOSTANESTI',
      zip: '22308',
      isUrban: false,
    },
    {
      name: 'CALAPODESTI',
      zip: '22317',
      isUrban: false,
    },
    {
      name: 'CAUIA',
      zip: '22326',
      isUrban: false,
    },
    {
      name: 'DOROFEI',
      zip: '22335',
      isUrban: false,
    },
    {
      name: 'GHIONOAIA',
      zip: '22344',
      isUrban: false,
    },
    {
      name: 'GRADESTI',
      zip: '22353',
      isUrban: false,
    },
    {
      name: 'NEGULESTI',
      zip: '22362',
      isUrban: false,
    },
    {
      name: 'TAVADARESTI',
      zip: '22371',
      isUrban: false,
    },
    {
      name: 'DOFTEANA',
      zip: '22399',
      isUrban: false,
    },
    {
      name: 'BOGATA',
      zip: '22406',
      isUrban: false,
    },
    {
      name: 'CUCUIETI',
      zip: '22415',
      isUrban: false,
    },
    {
      name: 'HAGHIAC',
      zip: '22424',
      isUrban: false,
    },
    {
      name: 'LARGA',
      zip: '22433',
      isUrban: false,
    },
    {
      name: 'SEACA',
      zip: '22442',
      isUrban: false,
    },
    {
      name: 'STEFAN VODA',
      zip: '22451',
      isUrban: false,
    },
    {
      name: 'FARAOANI',
      zip: '22479',
      isUrban: false,
    },
    {
      name: 'FILIPENI',
      zip: '22497',
      isUrban: false,
    },
    {
      name: 'BALAIA',
      zip: '22503',
      isUrban: false,
    },
    {
      name: 'BRAD',
      zip: '22512',
      isUrban: false,
    },
    {
      name: 'FRUNTESTI',
      zip: '22521',
      isUrban: false,
    },
    {
      name: 'MARASTI',
      zip: '22530',
      isUrban: false,
    },
    {
      name: 'PADURENI',
      zip: '22549',
      isUrban: false,
    },
    {
      name: 'SLOBOZIA',
      zip: '22558',
      isUrban: false,
    },
    {
      name: 'VALEA BOTULUI',
      zip: '22567',
      isUrban: false,
    },
    {
      name: 'FILIPESTI',
      zip: '22585',
      isUrban: false,
    },
    {
      name: 'BOANTA',
      zip: '22594',
      isUrban: false,
    },
    {
      name: 'CARLIGI',
      zip: '22601',
      isUrban: false,
    },
    {
      name: 'CORNESTI',
      zip: '22610',
      isUrban: false,
    },
    {
      name: 'COTU GROSULUI',
      zip: '22629',
      isUrban: false,
    },
    {
      name: 'GALBENI',
      zip: '22638',
      isUrban: false,
    },
    {
      name: 'HARLESTI',
      zip: '22647',
      isUrban: false,
    },
    {
      name: 'ONISCANI',
      zip: '22656',
      isUrban: false,
    },
    {
      name: 'GAICEANA',
      zip: '22674',
      isUrban: false,
    },
    {
      name: 'ARINI',
      zip: '22683',
      isUrban: false,
    },
    {
      name: 'HUTU',
      zip: '22692',
      isUrban: false,
    },
    {
      name: 'POPESTI',
      zip: '22709',
      isUrban: false,
    },
    {
      name: 'GARLENII DE SUS',
      zip: '22790',
      isUrban: false,
    },
    {
      name: 'GARLENI',
      zip: '22807',
      isUrban: false,
    },
    {
      name: 'LESPEZI',
      zip: '22816',
      isUrban: false,
    },
    {
      name: 'SURINA',
      zip: '22825',
      isUrban: false,
    },
    {
      name: 'FAGET',
      zip: '22727',
      isUrban: false,
    },
    {
      name: 'BOLOVANIS',
      zip: '22736',
      isUrban: false,
    },
    {
      name: 'FAGETU DE SUS',
      zip: '22745',
      isUrban: false,
    },
    {
      name: 'GHIMES',
      zip: '22754',
      isUrban: false,
    },
    {
      name: 'RACHITIS',
      zip: '22763',
      isUrban: false,
    },
    {
      name: 'TARHAUSI',
      zip: '22772',
      isUrban: false,
    },
    {
      name: 'TEMELIA',
      zip: '20661',
      isUrban: false,
    },
    {
      name: 'HELEGIU',
      zip: '22905',
      isUrban: false,
    },
    {
      name: 'BRATILA',
      zip: '22914',
      isUrban: false,
    },
    {
      name: 'DELENI',
      zip: '22923',
      isUrban: false,
    },
    {
      name: 'DRAGUGESTI',
      zip: '22932',
      isUrban: false,
    },
    {
      name: 'HEMEIUS',
      zip: '20322',
      isUrban: false,
    },
    {
      name: 'FANTANELE',
      zip: '20331',
      isUrban: false,
    },
    {
      name: 'LILIECI',
      zip: '20340',
      isUrban: false,
    },
    {
      name: 'HORGESTI',
      zip: '22950',
      isUrban: false,
    },
    {
      name: 'BAZGA',
      zip: '22969',
      isUrban: false,
    },
    {
      name: 'GALERI',
      zip: '22978',
      isUrban: false,
    },
    {
      name: 'MARASCU',
      zip: '22987',
      isUrban: false,
    },
    {
      name: 'RACATAU DE JOS',
      zip: '23001',
      isUrban: false,
    },
    {
      name: 'RACATAU-RAZESI',
      zip: '22996',
      isUrban: false,
    },
    {
      name: 'RECEA',
      zip: '23010',
      isUrban: false,
    },
    {
      name: 'SOHODOR',
      zip: '23029',
      isUrban: false,
    },
    {
      name: 'HURUIESTI',
      zip: '23056',
      isUrban: false,
    },
    {
      name: 'CAPOTESTI',
      zip: '23065',
      isUrban: false,
    },
    {
      name: 'FLORESTI',
      zip: '23074',
      isUrban: false,
    },
    {
      name: 'FUNDOAIA',
      zip: '23083',
      isUrban: false,
    },
    {
      name: 'OCHENI',
      zip: '23092',
      isUrban: false,
    },
    {
      name: 'PERCHIU',
      zip: '23109',
      isUrban: false,
    },
    {
      name: 'PRADAIS',
      zip: '23118',
      isUrban: false,
    },
    {
      name: 'IZVORU BERHECIULUI',
      zip: '23136',
      isUrban: false,
    },
    {
      name: 'ANTOHESTI',
      zip: '23145',
      isUrban: false,
    },
    {
      name: 'BAIMAC',
      zip: '23154',
      isUrban: false,
    },
    {
      name: 'FAGHIENI',
      zip: '23163',
      isUrban: false,
    },
    {
      name: 'OBARSIA',
      zip: '23172',
      isUrban: false,
    },
    {
      name: 'OTELESTI',
      zip: '23181',
      isUrban: false,
    },
    {
      name: 'PADURENI',
      zip: '23190',
      isUrban: false,
    },
    {
      name: 'LETEA VECHE',
      zip: '20368',
      isUrban: false,
    },
    {
      name: 'HOLT',
      zip: '20377',
      isUrban: false,
    },
    {
      name: 'RADOMIRESTI',
      zip: '20386',
      isUrban: false,
    },
    {
      name: 'RUSI-CIUTEA',
      zip: '20395',
      isUrban: false,
    },
    {
      name: 'SIRETU',
      zip: '20402',
      isUrban: false,
    },
    {
      name: 'LIPOVA',
      zip: '23216',
      isUrban: false,
    },
    {
      name: 'MILOSU',
      zip: '23225',
      isUrban: false,
    },
    {
      name: 'SATU NOU',
      zip: '23234',
      isUrban: false,
    },
    {
      name: 'VALEA CASELOR',
      zip: '23243',
      isUrban: false,
    },
    {
      name: 'VALEA HOGEI',
      zip: '23252',
      isUrban: false,
    },
    {
      name: 'VALEA MARULUI',
      zip: '23261',
      isUrban: false,
    },
    {
      name: 'VALEA MOSNEAGULUI',
      zip: '23270',
      isUrban: false,
    },
    {
      name: 'LIVEZI',
      zip: '23298',
      isUrban: false,
    },
    {
      name: 'BALANEASA',
      zip: '23305',
      isUrban: false,
    },
    {
      name: 'ORASA',
      zip: '23314',
      isUrban: false,
    },
    {
      name: 'POIANA',
      zip: '23323',
      isUrban: false,
    },
    {
      name: 'PRAJOAIA',
      zip: '23332',
      isUrban: false,
    },
    {
      name: 'SCARIGA',
      zip: '23341',
      isUrban: false,
    },
    {
      name: 'LUIZI-CALUGARA',
      zip: '23369',
      isUrban: false,
    },
    {
      name: 'OSEBITI',
      zip: '23378',
      isUrban: false,
    },
    {
      name: 'MAGIRESTI',
      zip: '23396',
      isUrban: false,
    },
    {
      name: 'PRAJESTI',
      zip: '23403',
      isUrban: false,
    },
    {
      name: 'SESURI',
      zip: '23421',
      isUrban: false,
    },
    {
      name: 'STANESTI',
      zip: '23412',
      isUrban: false,
    },
    {
      name: 'VALEA ARINILOR',
      zip: '23430',
      isUrban: false,
    },
    {
      name: 'MAGURA',
      zip: '20420',
      isUrban: false,
    },
    {
      name: 'CRIHAN',
      zip: '20439',
      isUrban: false,
    },
    {
      name: 'DEALU-MARE',
      zip: '20448',
      isUrban: false,
    },
    {
      name: 'SOHODOL',
      zip: '20457',
      isUrban: false,
    },
    {
      name: 'MANASTIREA CASIN',
      zip: '23458',
      isUrban: false,
    },
    {
      name: 'LUPESTI',
      zip: '23467',
      isUrban: false,
    },
    {
      name: 'PIRVULESTI',
      zip: '23476',
      isUrban: false,
    },
    {
      name: 'SCUTARU',
      zip: '23485',
      isUrban: false,
    },
    {
      name: 'MARGINENI',
      zip: '20475',
      isUrban: false,
    },
    {
      name: 'BARATI',
      zip: '20484',
      isUrban: false,
    },
    {
      name: 'LUNCANI',
      zip: '20493',
      isUrban: false,
    },
    {
      name: 'PADURENI',
      zip: '20509',
      isUrban: false,
    },
    {
      name: 'PODIS',
      zip: '20518',
      isUrban: false,
    },
    {
      name: 'POIANA',
      zip: '20527',
      isUrban: false,
    },
    {
      name: 'TREBES',
      zip: '20536',
      isUrban: false,
    },
    {
      name: 'VALEA BUDULUI',
      zip: '20545',
      isUrban: false,
    },
    {
      name: 'MOTOSENI',
      zip: '23500',
      isUrban: false,
    },
    {
      name: 'BACLESTI',
      zip: '23519',
      isUrban: false,
    },
    {
      name: 'CHETRENI',
      zip: '23528',
      isUrban: false,
    },
    {
      name: 'CHICEREA',
      zip: '23537',
      isUrban: false,
    },
    {
      name: 'COCIU',
      zip: '23546',
      isUrban: false,
    },
    {
      name: 'CORNATELU',
      zip: '23555',
      isUrban: false,
    },
    {
      name: 'FANTANELE',
      zip: '23564',
      isUrban: false,
    },
    {
      name: 'FUNDATURA',
      zip: '23573',
      isUrban: false,
    },
    {
      name: 'GURA CRAIESTI',
      zip: '23582',
      isUrban: false,
    },
    {
      name: 'POIANA',
      zip: '23591',
      isUrban: false,
    },
    {
      name: 'PRAJA',
      zip: '23608',
      isUrban: false,
    },
    {
      name: 'ROTARIA',
      zip: '23617',
      isUrban: false,
    },
    {
      name: 'SENDRESTI',
      zip: '23626',
      isUrban: false,
    },
    {
      name: 'TEPOAIA',
      zip: '23635',
      isUrban: false,
    },
    {
      name: 'NEGRI',
      zip: '23653',
      isUrban: false,
    },
    {
      name: 'BRAD',
      zip: '23662',
      isUrban: false,
    },
    {
      name: 'CALINESTI',
      zip: '23671',
      isUrban: false,
    },
    {
      name: 'MAGLA',
      zip: '23680',
      isUrban: false,
    },
    {
      name: 'POIANA',
      zip: '23699',
      isUrban: false,
    },
    {
      name: 'URSOAIA',
      zip: '23706',
      isUrban: false,
    },
    {
      name: 'NICOLAE BALCESCU',
      zip: '23724',
      isUrban: false,
    },
    {
      name: 'BALTATA',
      zip: '23733',
      isUrban: false,
    },
    {
      name: 'BUCHILA',
      zip: '23742',
      isUrban: false,
    },
    {
      name: 'GALBENI',
      zip: '23751',
      isUrban: false,
    },
    {
      name: 'LARGUTA',
      zip: '23760',
      isUrban: false,
    },
    {
      name: 'SARATA',
      zip: '23779',
      isUrban: false,
    },
    {
      name: 'VALEA SEACA',
      zip: '23788',
      isUrban: false,
    },
    {
      name: 'OITUZ',
      zip: '23804',
      isUrban: false,
    },
    {
      name: 'CALCII',
      zip: '23813',
      isUrban: false,
    },
    {
      name: 'FERESTRAU-OITUZ',
      zip: '23822',
      isUrban: false,
    },
    {
      name: 'HIRJA',
      zip: '23831',
      isUrban: false,
    },
    {
      name: 'MARGINEA',
      zip: '23840',
      isUrban: false,
    },
    {
      name: 'POIANA SARATA',
      zip: '23859',
      isUrban: false,
    },
    {
      name: 'ONCESTI',
      zip: '23877',
      isUrban: false,
    },
    {
      name: 'BARBOASA',
      zip: '23886',
      isUrban: false,
    },
    {
      name: 'DEALU PERJULUI',
      zip: '23895',
      isUrban: false,
    },
    {
      name: 'ONCESTII VECHI',
      zip: '23902',
      isUrban: false,
    },
    {
      name: 'SATU NOU',
      zip: '23911',
      isUrban: false,
    },
    {
      name: 'TARNITA',
      zip: '23920',
      isUrban: false,
    },
    {
      name: 'TAULA',
      zip: '23939',
      isUrban: false,
    },
    {
      name: 'ORBENI',
      zip: '23957',
      isUrban: false,
    },
    {
      name: 'SCURTA',
      zip: '23966',
      isUrban: false,
    },
    {
      name: 'PALANCA',
      zip: '23984',
      isUrban: false,
    },
    {
      name: 'CADARESTI',
      zip: '23993',
      isUrban: false,
    },
    {
      name: 'CIUGHES',
      zip: '24007',
      isUrban: false,
    },
    {
      name: 'PAJISTEA',
      zip: '24016',
      isUrban: false,
    },
    {
      name: 'POPOIU',
      zip: '24025',
      isUrban: false,
    },
    {
      name: 'PANCESTI',
      zip: '24196',
      isUrban: false,
    },
    {
      name: 'CHILIA BENEI',
      zip: '24203',
      isUrban: false,
    },
    {
      name: 'DIENET',
      zip: '24212',
      isUrban: false,
    },
    {
      name: 'FULGERIS',
      zip: '24221',
      isUrban: false,
    },
    {
      name: 'FUNDU VAII',
      zip: '24230',
      isUrban: false,
    },
    {
      name: 'MOTOC',
      zip: '24249',
      isUrban: false,
    },
    {
      name: 'PETRESTI',
      zip: '24258',
      isUrban: false,
    },
    {
      name: 'SOCI',
      zip: '24267',
      isUrban: false,
    },
    {
      name: 'PARAVA',
      zip: '24043',
      isUrban: false,
    },
    {
      name: 'DRAGUSANI',
      zip: '24052',
      isUrban: false,
    },
    {
      name: 'RADOAIA',
      zip: '24061',
      isUrban: false,
    },
    {
      name: 'TEIUS',
      zip: '24070',
      isUrban: false,
    },
    {
      name: 'PARGARESTI',
      zip: '24285',
      isUrban: false,
    },
    {
      name: 'BAHNA',
      zip: '24294',
      isUrban: false,
    },
    {
      name: 'NICORESTI',
      zip: '24301',
      isUrban: false,
    },
    {
      name: 'PARAU BOGHII',
      zip: '24310',
      isUrban: false,
    },
    {
      name: 'SATU NOU',
      zip: '24329',
      isUrban: false,
    },
    {
      name: 'PARINCEA',
      zip: '24098',
      isUrban: false,
    },
    {
      name: 'BARNA',
      zip: '24105',
      isUrban: false,
    },
    {
      name: 'MILESTII DE JOS',
      zip: '24114',
      isUrban: false,
    },
    {
      name: 'MILESTII DE SUS',
      zip: '24123',
      isUrban: false,
    },
    {
      name: 'NANESTI',
      zip: '24132',
      isUrban: false,
    },
    {
      name: 'NASTASENI',
      zip: '24141',
      isUrban: false,
    },
    {
      name: 'POIENI',
      zip: '24150',
      isUrban: false,
    },
    {
      name: 'SATU NOU',
      zip: '24169',
      isUrban: false,
    },
    {
      name: 'VALENI',
      zip: '23038',
      isUrban: false,
    },
    {
      name: 'VLADNIC',
      zip: '24178',
      isUrban: false,
    },
    {
      name: 'PARJOL',
      zip: '24347',
      isUrban: false,
    },
    {
      name: 'BAHNASENI',
      zip: '24356',
      isUrban: false,
    },
    {
      name: 'BARNESTI',
      zip: '24365',
      isUrban: false,
    },
    {
      name: 'BASASTI',
      zip: '24374',
      isUrban: false,
    },
    {
      name: 'CAMPENI',
      zip: '24383',
      isUrban: false,
    },
    {
      name: 'HAINEALA',
      zip: '24392',
      isUrban: false,
    },
    {
      name: 'PUSTIANA',
      zip: '24409',
      isUrban: false,
    },
    {
      name: 'TARITA',
      zip: '24418',
      isUrban: false,
    },
    {
      name: 'PLOPANA',
      zip: '24436',
      isUrban: false,
    },
    {
      name: 'BUDESTI',
      zip: '24445',
      isUrban: false,
    },
    {
      name: 'DORNENI',
      zip: '24454',
      isUrban: false,
    },
    {
      name: 'FUNDU TUTOVEI',
      zip: '24463',
      isUrban: false,
    },
    {
      name: 'ITCANI',
      zip: '24472',
      isUrban: false,
    },
    {
      name: 'RUSENII DE SUS',
      zip: '24490',
      isUrban: false,
    },
    {
      name: 'RUSENII RAZESI',
      zip: '24481',
      isUrban: false,
    },
    {
      name: 'STRAMINOASA',
      zip: '24506',
      isUrban: false,
    },
    {
      name: 'TIGIRA',
      zip: '24515',
      isUrban: false,
    },
    {
      name: 'PODU TURCULUI',
      zip: '24533',
      isUrban: false,
    },
    {
      name: 'BALANESTI',
      zip: '24542',
      isUrban: false,
    },
    {
      name: 'CABESTI',
      zip: '24551',
      isUrban: false,
    },
    {
      name: 'FICHITESTI',
      zip: '24560',
      isUrban: false,
    },
    {
      name: 'GIURGIOANA',
      zip: '24579',
      isUrban: false,
    },
    {
      name: 'HANTA',
      zip: '24588',
      isUrban: false,
    },
    {
      name: 'LEHANCEA',
      zip: '24597',
      isUrban: false,
    },
    {
      name: 'PLOPU',
      zip: '24604',
      isUrban: false,
    },
    {
      name: 'RACUSANA',
      zip: '24613',
      isUrban: false,
    },
    {
      name: 'SIRBI',
      zip: '24622',
      isUrban: false,
    },
    {
      name: 'PODURI',
      zip: '24640',
      isUrban: false,
    },
    {
      name: 'BUCSESTI',
      zip: '24659',
      isUrban: false,
    },
    {
      name: 'CERNU',
      zip: '24668',
      isUrban: false,
    },
    {
      name: 'CORNET',
      zip: '24677',
      isUrban: false,
    },
    {
      name: 'NEGRENI',
      zip: '24686',
      isUrban: false,
    },
    {
      name: 'PROHOZESTI',
      zip: '24695',
      isUrban: false,
    },
    {
      name: 'VALEA SOSII',
      zip: '24702',
      isUrban: false,
    },
    {
      name: 'RACACIUNI',
      zip: '24775',
      isUrban: false,
    },
    {
      name: 'CIUCANI',
      zip: '24784',
      isUrban: false,
    },
    {
      name: 'FUNDU RACACIUNI',
      zip: '24793',
      isUrban: false,
    },
    {
      name: 'GHEORGHE DOJA',
      zip: '24800',
      isUrban: false,
    },
    {
      name: 'GISTENI',
      zip: '24819',
      isUrban: false,
    },
    {
      name: 'RASTOACA',
      zip: '24828',
      isUrban: false,
    },
    {
      name: 'RACHITOASA',
      zip: '24846',
      isUrban: false,
    },
    {
      name: 'BARCANA',
      zip: '24855',
      isUrban: false,
    },
    {
      name: 'BUCSA',
      zip: '24864',
      isUrban: false,
    },
    {
      name: 'BUDA',
      zip: '24873',
      isUrban: false,
    },
    {
      name: 'BURDUSACI',
      zip: '24882',
      isUrban: false,
    },
    {
      name: 'DANAILA',
      zip: '24891',
      isUrban: false,
    },
    {
      name: 'DUMBRAVA',
      zip: '24908',
      isUrban: false,
    },
    {
      name: 'FARCASA',
      zip: '24917',
      isUrban: false,
    },
    {
      name: 'FUNDATURA RACHITOASA',
      zip: '24926',
      isUrban: false,
    },
    {
      name: 'HAGHIAC',
      zip: '24935',
      isUrban: false,
    },
    {
      name: 'MAGAZIA',
      zip: '24944',
      isUrban: false,
    },
    {
      name: 'MOVILITA',
      zip: '24953',
      isUrban: false,
    },
    {
      name: 'OPRISESTI',
      zip: '24962',
      isUrban: false,
    },
    {
      name: 'PUTINI',
      zip: '24971',
      isUrban: false,
    },
    {
      name: 'TOCHILEA',
      zip: '24980',
      isUrban: false,
    },
    {
      name: 'RACOVA',
      zip: '24720',
      isUrban: false,
    },
    {
      name: 'GURA VAII',
      zip: '24739',
      isUrban: false,
    },
    {
      name: 'HALMACIOAIA',
      zip: '24748',
      isUrban: false,
    },
    {
      name: 'ILIESI',
      zip: '24757',
      isUrban: false,
    },
    {
      name: 'ROSIORI',
      zip: '25004',
      isUrban: false,
    },
    {
      name: 'MISIHANESTI',
      zip: '25013',
      isUrban: false,
    },
    {
      name: 'NEGUSENI',
      zip: '25022',
      isUrban: false,
    },
    {
      name: 'POIENI',
      zip: '25031',
      isUrban: false,
    },
    {
      name: 'VALEA MARE',
      zip: '25040',
      isUrban: false,
    },
    {
      name: 'VALEA MICA',
      zip: '25059',
      isUrban: false,
    },
    {
      name: 'SANDULENI',
      zip: '25157',
      isUrban: false,
    },
    {
      name: 'BARZULESTI',
      zip: '25166',
      isUrban: false,
    },
    {
      name: 'COMAN',
      zip: '25175',
      isUrban: false,
    },
    {
      name: 'MATEIESTI',
      zip: '25184',
      isUrban: false,
    },
    {
      name: 'STUFU',
      zip: '25193',
      isUrban: false,
    },
    {
      name: 'TISA',
      zip: '25200',
      isUrban: false,
    },
    {
      name: 'VERSESTI',
      zip: '25219',
      isUrban: false,
    },
    {
      name: 'SASCUT',
      zip: '25077',
      isUrban: false,
    },
    {
      name: 'BERESTI',
      zip: '25086',
      isUrban: false,
    },
    {
      name: 'CONTESTI',
      zip: '25095',
      isUrban: false,
    },
    {
      name: 'PANCESTI',
      zip: '25102',
      isUrban: false,
    },
    {
      name: 'SASCUT-SAT',
      zip: '25111',
      isUrban: false,
    },
    {
      name: 'SCHINENI',
      zip: '25120',
      isUrban: false,
    },
    {
      name: 'VALEA NACULUI',
      zip: '25139',
      isUrban: false,
    },
    {
      name: 'SAUCESTI',
      zip: '25237',
      isUrban: false,
    },
    {
      name: 'BOGDAN VODA',
      zip: '25246',
      isUrban: false,
    },
    {
      name: 'COSTEI',
      zip: '25255',
      isUrban: false,
    },
    {
      name: 'SCHINENI',
      zip: '25264',
      isUrban: false,
    },
    {
      name: 'SERBESTI',
      zip: '25282',
      isUrban: false,
    },
    {
      name: 'SIRETU',
      zip: '25273',
      isUrban: false,
    },
    {
      name: 'SCORTENI',
      zip: '25308',
      isUrban: false,
    },
    {
      name: 'BOGDANESTI',
      zip: '25317',
      isUrban: false,
    },
    {
      name: 'FLORESTI',
      zip: '25326',
      isUrban: false,
    },
    {
      name: 'GRIGORENI',
      zip: '25335',
      isUrban: false,
    },
    {
      name: 'SERPENI',
      zip: '25353',
      isUrban: false,
    },
    {
      name: 'STEJARU',
      zip: '25344',
      isUrban: false,
    },
    {
      name: 'SECUIENI',
      zip: '25371',
      isUrban: false,
    },
    {
      name: 'BALUSA',
      zip: '25380',
      isUrban: false,
    },
    {
      name: 'BERBINCENI',
      zip: '25399',
      isUrban: false,
    },
    {
      name: 'CHITICENI',
      zip: '25406',
      isUrban: false,
    },
    {
      name: 'CIUTURESTI',
      zip: '25415',
      isUrban: false,
    },
    {
      name: 'FUNDENI',
      zip: '25424',
      isUrban: false,
    },
    {
      name: 'GLODISOARELE',
      zip: '25433',
      isUrban: false,
    },
    {
      name: 'ODOBESTI',
      zip: '25442',
      isUrban: false,
    },
    {
      name: 'TISA-SILVESTRI',
      zip: '25451',
      isUrban: false,
    },
    {
      name: 'VALEA FINATULUI',
      zip: '25460',
      isUrban: false,
    },
    {
      name: 'RADEANA',
      zip: '20741',
      isUrban: false,
    },
    {
      name: 'VIISOARA',
      zip: '20750',
      isUrban: false,
    },
    {
      name: 'STRUGARI',
      zip: '25638',
      isUrban: false,
    },
    {
      name: 'CETATUIA',
      zip: '25647',
      isUrban: false,
    },
    {
      name: 'IAZ',
      zip: '25656',
      isUrban: false,
    },
    {
      name: 'NADISA',
      zip: '25665',
      isUrban: false,
    },
    {
      name: 'PETRICICA',
      zip: '25674',
      isUrban: false,
    },
    {
      name: 'RACHITISU',
      zip: '25683',
      isUrban: false,
    },
    {
      name: 'TAMASI',
      zip: '25709',
      isUrban: false,
    },
    {
      name: 'CHETRIS',
      zip: '25718',
      isUrban: false,
    },
    {
      name: 'FURNICARI',
      zip: '25727',
      isUrban: false,
    },
    {
      name: 'GIOSENI',
      zip: '25736',
      isUrban: false,
    },
    {
      name: 'TARGU TROTUS',
      zip: '25834',
      isUrban: false,
    },
    {
      name: 'TUTA',
      zip: '25843',
      isUrban: false,
    },
    {
      name: 'VIISOARA',
      zip: '25852',
      isUrban: false,
    },
    {
      name: 'TATARASTI',
      zip: '25754',
      isUrban: false,
    },
    {
      name: 'CORNII DE JOS',
      zip: '25763',
      isUrban: false,
    },
    {
      name: 'CORNII DE SUS',
      zip: '25772',
      isUrban: false,
    },
    {
      name: 'DRAGESTI',
      zip: '25781',
      isUrban: false,
    },
    {
      name: 'GHERDANA',
      zip: '25790',
      isUrban: false,
    },
    {
      name: 'GIURGENI',
      zip: '25807',
      isUrban: false,
    },
    {
      name: 'UNGURENI',
      zip: '25816',
      isUrban: false,
    },
    {
      name: 'TRAIAN',
      zip: '25870',
      isUrban: false,
    },
    {
      name: 'BOGDANESTI',
      zip: '25889',
      isUrban: false,
    },
    {
      name: 'HERTIOANA DE JOS',
      zip: '25898',
      isUrban: false,
    },
    {
      name: 'HERTIOANA-RAZESI',
      zip: '25905',
      isUrban: false,
    },
    {
      name: 'PRAJESTI',
      zip: '25914',
      isUrban: false,
    },
    {
      name: 'ZAPODIA',
      zip: '25923',
      isUrban: false,
    },
    {
      name: 'UNGURENI',
      zip: '25941',
      isUrban: false,
    },
    {
      name: 'BARTASESTI',
      zip: '25950',
      isUrban: false,
    },
    {
      name: 'BIBIRESTI',
      zip: '25969',
      isUrban: false,
    },
    {
      name: 'BOTA',
      zip: '25978',
      isUrban: false,
    },
    {
      name: 'BOTESTI',
      zip: '25987',
      isUrban: false,
    },
    {
      name: 'GARLA ANEI',
      zip: '25996',
      isUrban: false,
    },
    {
      name: 'VIFORENI',
      zip: '26001',
      isUrban: false,
    },
    {
      name: 'ZLATARI',
      zip: '26010',
      isUrban: false,
    },
    {
      name: 'URECHESTI',
      zip: '26038',
      isUrban: false,
    },
    {
      name: 'CORNATEL',
      zip: '26047',
      isUrban: false,
    },
    {
      name: 'LUNCA DOCHIEI',
      zip: '26056',
      isUrban: false,
    },
    {
      name: 'SATU NOU',
      zip: '26065',
      isUrban: false,
    },
    {
      name: 'SLOBOZIA',
      zip: '26074',
      isUrban: false,
    },
    {
      name: 'VALEA SEACA',
      zip: '26092',
      isUrban: false,
    },
    {
      name: 'CUCOVA',
      zip: '26109',
      isUrban: false,
    },
    {
      name: 'LICHITISENI',
      zip: '26127',
      isUrban: false,
    },
    {
      name: 'BOSIA',
      zip: '26136',
      isUrban: false,
    },
    {
      name: 'DADESTI',
      zip: '26145',
      isUrban: false,
    },
    {
      name: 'DORNENI',
      zip: '26154',
      isUrban: false,
    },
    {
      name: 'GHILAVESTI',
      zip: '26163',
      isUrban: false,
    },
    {
      name: 'GODINESTII DE JOS',
      zip: '26172',
      isUrban: false,
    },
    {
      name: 'GODINESTII DE SUS',
      zip: '26181',
      isUrban: false,
    },
    {
      name: 'MEDELENI',
      zip: '26190',
      isUrban: false,
    },
    {
      name: 'NAZARIOAIA',
      zip: '26207',
      isUrban: false,
    },
    {
      name: 'REPRIVAT',
      zip: '26216',
      isUrban: false,
    },
    {
      name: 'TIGANESTI',
      zip: '26234',
      isUrban: false,
    },
    {
      name: 'TOMOZIA',
      zip: '26225',
      isUrban: false,
    },
    {
      name: 'VALEA LUPULUI',
      zip: '26243',
      isUrban: false,
    },
    {
      name: 'VALEA MERILOR',
      zip: '26252',
      isUrban: false,
    },
    {
      name: 'VALEA SALCIEI',
      zip: '26261',
      isUrban: false,
    },
    {
      name: 'VULTURENI',
      zip: '26270',
      isUrban: false,
    },
    {
      name: 'ZEMES',
      zip: '26298',
      isUrban: false,
    },
    {
      name: 'BOLATAU',
      zip: '26305',
      isUrban: false,
    },
    {
      name: 'GLAVANESTI',
      zip: '22843',
      isUrban: false,
    },
    {
      name: 'FRUMUSELU',
      zip: '22852',
      isUrban: false,
    },
    {
      name: 'MUNCELU',
      zip: '22861',
      isUrban: false,
    },
    {
      name: 'PUTREDENI',
      zip: '22870',
      isUrban: false,
    },
    {
      name: 'RAZESU',
      zip: '22889',
      isUrban: false,
    },
    {
      name: 'GURA VAII',
      zip: '20616',
      isUrban: false,
    },
    {
      name: 'CAPATA',
      zip: '20625',
      isUrban: false,
    },
    {
      name: 'DUMBRAVA',
      zip: '20634',
      isUrban: false,
    },
    {
      name: 'MOTOCESTI',
      zip: '20643',
      isUrban: false,
    },
    {
      name: 'PALTINATA',
      zip: '20652',
      isUrban: false,
    },
    {
      name: 'VALENI',
      zip: '25479',
      isUrban: false,
    },
    {
      name: 'SOLONT',
      zip: '25497',
      isUrban: false,
    },
    {
      name: 'CUCUIETI',
      zip: '25503',
      isUrban: false,
    },
    {
      name: 'SARATA',
      zip: '25512',
      isUrban: false,
    },
    {
      name: 'STANISESTI',
      zip: '25530',
      isUrban: false,
    },
    {
      name: 'BALOTESTI',
      zip: '25549',
      isUrban: false,
    },
    {
      name: 'BELCIUNEASA',
      zip: '25558',
      isUrban: false,
    },
    {
      name: 'BENESTI',
      zip: '25567',
      isUrban: false,
    },
    {
      name: 'CRAIESTI',
      zip: '25576',
      isUrban: false,
    },
    {
      name: 'GORGHESTI',
      zip: '25585',
      isUrban: false,
    },
    {
      name: 'SLOBOZIA',
      zip: '25594',
      isUrban: false,
    },
    {
      name: 'SLOBOZIA NOUA',
      zip: '25601',
      isUrban: false,
    },
    {
      name: 'VALENI',
      zip: '25610',
      isUrban: false,
    },
    {
      name: 'STEFAN CEL MARE',
      zip: '20689',
      isUrban: false,
    },
    {
      name: 'BOGDANA',
      zip: '20698',
      isUrban: false,
    },
    {
      name: 'BUCIUMI',
      zip: '20705',
      isUrban: false,
    },
    {
      name: 'GUTINAS',
      zip: '20714',
      isUrban: false,
    },
    {
      name: 'NEGOIESTI',
      zip: '20723',
      isUrban: false,
    },
    {
      name: 'RACAUTI',
      zip: '20732',
      isUrban: false,
    },
    {
      name: 'HEMIENI',
      zip: '26312',
      isUrban: false,
    }
  ],
  BH: [
    {
      name: 'ORADEA',
      zip: '26573',
      isUrban: true,
    },
    {
      name: 'ALESD',
      zip: '26706',
      isUrban: true,
    },
    {
      name: 'PADUREA NEAGRA',
      zip: '26715',
      isUrban: true,
    },
    {
      name: 'PESTIS',
      zip: '26724',
      isUrban: true,
    },
    {
      name: 'TINAUD',
      zip: '26733',
      isUrban: true,
    },
    {
      name: 'BEIUS',
      zip: '26813',
      isUrban: true,
    },
    {
      name: 'DELANI',
      zip: '26822',
      isUrban: true,
    },
    {
      name: 'MARGHITA',
      zip: '26886',
      isUrban: true,
    },
    {
      name: 'CHET',
      zip: '26895',
      isUrban: true,
    },
    {
      name: 'GHENETEA',
      zip: '26902',
      isUrban: true,
    },
    {
      name: 'NUCET',
      zip: '26939',
      isUrban: true,
    },
    {
      name: 'BAITA',
      zip: '26948',
      isUrban: true,
    },
    {
      name: 'BAITA-PLAI',
      zip: '26957',
      isUrban: true,
    },
    {
      name: 'SALONTA',
      zip: '26984',
      isUrban: true,
    },
    {
      name: 'STEI',
      zip: '26859',
      isUrban: true,
    },
    {
      name: 'VALEA LUI MIHAI',
      zip: '32036',
      isUrban: true,
    },
    {
      name: 'VASCAU',
      zip: '27016',
      isUrban: true,
    },
    {
      name: 'CAMP',
      zip: '27025',
      isUrban: true,
    },
    {
      name: 'CAMP-MOTI',
      zip: '27034',
      isUrban: true,
    },
    {
      name: 'COLESTI',
      zip: '27043',
      isUrban: true,
    },
    {
      name: 'VARZARII DE JOS',
      zip: '27052',
      isUrban: true,
    },
    {
      name: 'VARZARII DE SUS',
      zip: '27061',
      isUrban: true,
    },
    {
      name: 'ABRAM',
      zip: '27089',
      isUrban: false,
    },
    {
      name: 'COHANI',
      zip: '27098',
      isUrban: false,
    },
    {
      name: 'DIJIR',
      zip: '27105',
      isUrban: false,
    },
    {
      name: 'ITEU',
      zip: '27114',
      isUrban: false,
    },
    {
      name: 'ITEU NOU',
      zip: '27123',
      isUrban: false,
    },
    {
      name: 'MARGINE',
      zip: '27132',
      isUrban: false,
    },
    {
      name: 'SATU BARBA',
      zip: '27141',
      isUrban: false,
    },
    {
      name: 'SUIUG',
      zip: '27150',
      isUrban: false,
    },
    {
      name: 'ABRAMUT',
      zip: '27178',
      isUrban: false,
    },
    {
      name: 'CRESTUR',
      zip: '27187',
      isUrban: false,
    },
    {
      name: 'FANCICA',
      zip: '27196',
      isUrban: false,
    },
    {
      name: 'PETREU',
      zip: '27203',
      isUrban: false,
    },
    {
      name: 'ASTILEU',
      zip: '26751',
      isUrban: false,
    },
    {
      name: 'CALATEA',
      zip: '26760',
      isUrban: false,
    },
    {
      name: 'CHISTAG',
      zip: '26779',
      isUrban: false,
    },
    {
      name: 'PESTERE',
      zip: '26788',
      isUrban: false,
    },
    {
      name: 'AUSEU',
      zip: '27221',
      isUrban: false,
    },
    {
      name: 'CACUCIU VECHI',
      zip: '27230',
      isUrban: false,
    },
    {
      name: 'CODRISORU',
      zip: '27249',
      isUrban: false,
    },
    {
      name: 'GHEGHIE',
      zip: '27258',
      isUrban: false,
    },
    {
      name: 'GROSI',
      zip: '27267',
      isUrban: false,
    },
    {
      name: 'LUNCSOARA',
      zip: '27276',
      isUrban: false,
    },
    {
      name: 'AVRAM IANCU',
      zip: '27294',
      isUrban: false,
    },
    {
      name: 'ANT',
      zip: '27301',
      isUrban: false,
    },
    {
      name: 'TAMASDA',
      zip: '27310',
      isUrban: false,
    },
    {
      name: 'BALC',
      zip: '27338',
      isUrban: false,
    },
    {
      name: 'ALMASU MARE',
      zip: '27347',
      isUrban: false,
    },
    {
      name: 'ALMASU MIC',
      zip: '27356',
      isUrban: false,
    },
    {
      name: 'GHIDA',
      zip: '27365',
      isUrban: false,
    },
    {
      name: 'SALDABAGIU DE BARCAU',
      zip: '27374',
      isUrban: false,
    },
    {
      name: 'BATAR',
      zip: '27392',
      isUrban: false,
    },
    {
      name: 'ARPASEL',
      zip: '27409',
      isUrban: false,
    },
    {
      name: 'TALPOS',
      zip: '27418',
      isUrban: false,
    },
    {
      name: 'TAUT',
      zip: '27427',
      isUrban: false,
    },
    {
      name: 'BIHARIA',
      zip: '27445',
      isUrban: false,
    },
    {
      name: 'CAUACEU',
      zip: '27454',
      isUrban: false,
    },
    {
      name: 'NIUVED',
      zip: '27463',
      isUrban: false,
    },
    {
      name: 'PARHIDA',
      zip: '27472',
      isUrban: false,
    },
    {
      name: 'SATU NOU',
      zip: '27481',
      isUrban: false,
    },
    {
      name: 'TAMASEU',
      zip: '27490',
      isUrban: false,
    },
    {
      name: 'BOIANU MARE',
      zip: '27515',
      isUrban: false,
    },
    {
      name: 'CORBOAIA',
      zip: '27524',
      isUrban: false,
    },
    {
      name: 'HUTA',
      zip: '27533',
      isUrban: false,
    },
    {
      name: 'PAGAIA',
      zip: '27542',
      isUrban: false,
    },
    {
      name: 'RUGEA',
      zip: '27551',
      isUrban: false,
    },
    {
      name: 'BOROD',
      zip: '27579',
      isUrban: false,
    },
    {
      name: 'BOROZEL',
      zip: '27588',
      isUrban: false,
    },
    {
      name: 'CETEA',
      zip: '27597',
      isUrban: false,
    },
    {
      name: 'CORNITEL',
      zip: '27604',
      isUrban: false,
    },
    {
      name: 'SERANI',
      zip: '27613',
      isUrban: false,
    },
    {
      name: 'VALEA MARE DE CRIS',
      zip: '27622',
      isUrban: false,
    },
    {
      name: 'BORS',
      zip: '27640',
      isUrban: false,
    },
    {
      name: 'SANTAUL MARE',
      zip: '27659',
      isUrban: false,
    },
    {
      name: 'SANTAUL MIC',
      zip: '27668',
      isUrban: false,
    },
    {
      name: 'SANTION',
      zip: '27677',
      isUrban: false,
    },
    {
      name: 'BRATCA',
      zip: '27695',
      isUrban: false,
    },
    {
      name: 'BEZNEA',
      zip: '27711',
      isUrban: false,
    },
    {
      name: 'DAMIS',
      zip: '27702',
      isUrban: false,
    },
    {
      name: 'LORAU',
      zip: '27720',
      isUrban: false,
    },
    {
      name: 'PONOARA',
      zip: '27739',
      isUrban: false,
    },
    {
      name: 'VALEA CRISULUI',
      zip: '27748',
      isUrban: false,
    },
    {
      name: 'BRUSTURI',
      zip: '27766',
      isUrban: false,
    },
    {
      name: 'CUIESD',
      zip: '27775',
      isUrban: false,
    },
    {
      name: 'LORANTA',
      zip: '27784',
      isUrban: false,
    },
    {
      name: 'ORVISELE',
      zip: '27793',
      isUrban: false,
    },
    {
      name: 'PAULESTI',
      zip: '27800',
      isUrban: false,
    },
    {
      name: 'PICLEU',
      zip: '27819',
      isUrban: false,
    },
    {
      name: 'TIGANESTII DE CRIS',
      zip: '27828',
      isUrban: false,
    },
    {
      name: 'VARASAU',
      zip: '27837',
      isUrban: false,
    },
    {
      name: 'BUDUREASA',
      zip: '27855',
      isUrban: false,
    },
    {
      name: 'BURDA',
      zip: '27864',
      isUrban: false,
    },
    {
      name: 'SACA',
      zip: '27873',
      isUrban: false,
    },
    {
      name: 'SALISTE DE BEIUS',
      zip: '27882',
      isUrban: false,
    },
    {
      name: 'TELEAC',
      zip: '27891',
      isUrban: false,
    },
    {
      name: 'BUDUSLAU',
      zip: '27917',
      isUrban: false,
    },
    {
      name: 'MUNTENI',
      zip: '27953',
      isUrban: false,
    },
    {
      name: 'REMETI',
      zip: '27962',
      isUrban: false,
    },
    {
      name: 'BUNTESTI',
      zip: '27980',
      isUrban: false,
    },
    {
      name: 'BRADET',
      zip: '27999',
      isUrban: false,
    },
    {
      name: 'DUMBRAVANI',
      zip: '28004',
      isUrban: false,
    },
    {
      name: 'FERICE',
      zip: '28013',
      isUrban: false,
    },
    {
      name: 'LELESTI',
      zip: '28022',
      isUrban: false,
    },
    {
      name: 'POIENII DE JOS',
      zip: '28031',
      isUrban: false,
    },
    {
      name: 'POIENII DE SUS',
      zip: '28040',
      isUrban: false,
    },
    {
      name: 'SAUD',
      zip: '28059',
      isUrban: false,
    },
    {
      name: 'STANCESTI',
      zip: '28068',
      isUrban: false,
    },
    {
      name: 'CABESTI',
      zip: '28086',
      isUrban: false,
    },
    {
      name: 'GOILA',
      zip: '28095',
      isUrban: false,
    },
    {
      name: 'GURBESTI',
      zip: '28102',
      isUrban: false,
    },
    {
      name: 'JOSANI',
      zip: '28111',
      isUrban: false,
    },
    {
      name: 'SOHODOL',
      zip: '28120',
      isUrban: false,
    },
    {
      name: 'CAMPANI',
      zip: '28718',
      isUrban: false,
    },
    {
      name: 'FANATE',
      zip: '28727',
      isUrban: false,
    },
    {
      name: 'HARSESTI',
      zip: '28736',
      isUrban: false,
    },
    {
      name: 'SIGHISTEL',
      zip: '28745',
      isUrban: false,
    },
    {
      name: 'VALEA DE SUS',
      zip: '28754',
      isUrban: false,
    },
    {
      name: 'CAPALNA',
      zip: '28148',
      isUrban: false,
    },
    {
      name: 'GINTA',
      zip: '28157',
      isUrban: false,
    },
    {
      name: 'ROHANI',
      zip: '28166',
      isUrban: false,
    },
    {
      name: 'SALDABAGIU MIC',
      zip: '28175',
      isUrban: false,
    },
    {
      name: 'SUPLACU DE TINCA',
      zip: '28184',
      isUrban: false,
    },
    {
      name: 'CARPINET',
      zip: '28200',
      isUrban: false,
    },
    {
      name: 'CALUGARI',
      zip: '28237',
      isUrban: false,
    },
    {
      name: 'IZBUC',
      zip: '28219',
      isUrban: false,
    },
    {
      name: 'LEHECENI',
      zip: '28228',
      isUrban: false,
    },
    {
      name: 'CEFA',
      zip: '28255',
      isUrban: false,
    },
    {
      name: 'ATEAS',
      zip: '28264',
      isUrban: false,
    },
    {
      name: 'BERECHIU',
      zip: '28273',
      isUrban: false,
    },
    {
      name: 'BICACI',
      zip: '28282',
      isUrban: false,
    },
    {
      name: 'GEPIU',
      zip: '28291',
      isUrban: false,
    },
    {
      name: 'INAND',
      zip: '28308',
      isUrban: false,
    },
    {
      name: 'ROIT',
      zip: '28317',
      isUrban: false,
    },
    {
      name: 'SANNICOLAU ROMIN',
      zip: '28326',
      isUrban: false,
    },
    {
      name: 'CEICA',
      zip: '28344',
      isUrban: false,
    },
    {
      name: 'BUCIUM',
      zip: '28353',
      isUrban: false,
    },
    {
      name: 'CEISOARA',
      zip: '28362',
      isUrban: false,
    },
    {
      name: 'CORBESTI',
      zip: '28371',
      isUrban: false,
    },
    {
      name: 'COTIGLET',
      zip: '28380',
      isUrban: false,
    },
    {
      name: 'DUSESTI',
      zip: '28399',
      isUrban: false,
    },
    {
      name: 'INCESTI',
      zip: '28406',
      isUrban: false,
    },
    {
      name: 'CETARIU',
      zip: '28424',
      isUrban: false,
    },
    {
      name: 'PALEU',
      zip: '28433',
      isUrban: false,
    },
    {
      name: 'SALDABAGIU DE MUNTE',
      zip: '28442',
      isUrban: false,
    },
    {
      name: 'SISTEREA',
      zip: '28451',
      isUrban: false,
    },
    {
      name: 'SUSTUROGI',
      zip: '28460',
      isUrban: false,
    },
    {
      name: 'TAUTELEC',
      zip: '28479',
      isUrban: false,
    },
    {
      name: 'UILEACU DE MUNTE',
      zip: '28488',
      isUrban: false,
    },
    {
      name: 'CHERECHIU',
      zip: '28503',
      isUrban: false,
    },
    {
      name: 'CHESEREU',
      zip: '28512',
      isUrban: false,
    },
    {
      name: 'TARGUSOR',
      zip: '28521',
      isUrban: false,
    },
    {
      name: 'CHISLAZ',
      zip: '28549',
      isUrban: false,
    },
    {
      name: 'CHIRALEU',
      zip: '28558',
      isUrban: false,
    },
    {
      name: 'HAUCESTI',
      zip: '28567',
      isUrban: false,
    },
    {
      name: 'MISCA',
      zip: '28576',
      isUrban: false,
    },
    {
      name: 'POCLUSA DE BARCAU',
      zip: '28585',
      isUrban: false,
    },
    {
      name: 'SANLAZAR',
      zip: '28601',
      isUrban: false,
    },
    {
      name: 'SARSIG',
      zip: '28594',
      isUrban: false,
    },
    {
      name: 'CIUHOI',
      zip: '28629',
      isUrban: false,
    },
    {
      name: 'CENALOS',
      zip: '28638',
      isUrban: false,
    },
    {
      name: 'SANIOB',
      zip: '28647',
      isUrban: false,
    },
    {
      name: 'SFARNAS',
      zip: '28656',
      isUrban: false,
    },
    {
      name: 'CIUMEGHIU',
      zip: '28674',
      isUrban: false,
    },
    {
      name: 'BOIU',
      zip: '28683',
      isUrban: false,
    },
    {
      name: 'GHIORAC',
      zip: '28692',
      isUrban: false,
    },
    {
      name: 'COCIUBA MARE',
      zip: '28772',
      isUrban: false,
    },
    {
      name: 'CARASAU',
      zip: '28781',
      isUrban: false,
    },
    {
      name: 'CHESA',
      zip: '28790',
      isUrban: false,
    },
    {
      name: 'PETID',
      zip: '28807',
      isUrban: false,
    },
    {
      name: 'COPACEL',
      zip: '28825',
      isUrban: false,
    },
    {
      name: 'BUCUROAIA',
      zip: '28834',
      isUrban: false,
    },
    {
      name: 'CHIJIC',
      zip: '28843',
      isUrban: false,
    },
    {
      name: 'POIANA TASAD',
      zip: '28852',
      isUrban: false,
    },
    {
      name: 'DOBRESTI',
      zip: '29163',
      isUrban: false,
    },
    {
      name: 'CORNISESTI',
      zip: '29172',
      isUrban: false,
    },
    {
      name: 'CRANCESTI',
      zip: '29181',
      isUrban: false,
    },
    {
      name: 'HIDISEL',
      zip: '29190',
      isUrban: false,
    },
    {
      name: 'LUNCASPRIE',
      zip: '29207',
      isUrban: false,
    },
    {
      name: 'RACAS',
      zip: '29216',
      isUrban: false,
    },
    {
      name: 'TOPA DE JOS',
      zip: '29225',
      isUrban: false,
    },
    {
      name: 'TOPA DE SUS',
      zip: '29234',
      isUrban: false,
    },
    {
      name: 'DRAGANESTI',
      zip: '29252',
      isUrban: false,
    },
    {
      name: 'BELEJENI',
      zip: '29261',
      isUrban: false,
    },
    {
      name: 'GRADINARI',
      zip: '29270',
      isUrban: false,
    },
    {
      name: 'LIVADA BEIUSULUI',
      zip: '29289',
      isUrban: false,
    },
    {
      name: 'MIZIES',
      zip: '29298',
      isUrban: false,
    },
    {
      name: 'PANTASESTI',
      zip: '29305',
      isUrban: false,
    },
    {
      name: 'SEBIS',
      zip: '29314',
      isUrban: false,
    },
    {
      name: 'TALPE',
      zip: '29323',
      isUrban: false,
    },
    {
      name: 'TIGANESTII DE BEIUS',
      zip: '29332',
      isUrban: false,
    },
    {
      name: 'DRAGESTI',
      zip: '29350',
      isUrban: false,
    },
    {
      name: 'DICANESTI',
      zip: '29369',
      isUrban: false,
    },
    {
      name: 'STRACOS',
      zip: '29378',
      isUrban: false,
    },
    {
      name: 'TASAD',
      zip: '29387',
      isUrban: false,
    },
    {
      name: 'TOPESTI',
      zip: '29396',
      isUrban: false,
    },
    {
      name: 'FINIS',
      zip: '29412',
      isUrban: false,
    },
    {
      name: 'BRUSTURI',
      zip: '29421',
      isUrban: false,
    },
    {
      name: 'FIZIS',
      zip: '29430',
      isUrban: false,
    },
    {
      name: 'IOANIS',
      zip: '29449',
      isUrban: false,
    },
    {
      name: 'SUNCUIS',
      zip: '29458',
      isUrban: false,
    },
    {
      name: 'GIRISU DE CRIS',
      zip: '29476',
      isUrban: false,
    },
    {
      name: 'CHERESIG',
      zip: '29485',
      isUrban: false,
    },
    {
      name: 'TARIAN',
      zip: '29494',
      isUrban: false,
    },
    {
      name: 'TOBOLIU',
      zip: '29500',
      isUrban: false,
    },
    {
      name: 'HIDISELU DE SUS',
      zip: '29528',
      isUrban: false,
    },
    {
      name: 'HIDISELU DE JOS',
      zip: '29537',
      isUrban: false,
    },
    {
      name: 'MIERLAU',
      zip: '29546',
      isUrban: false,
    },
    {
      name: 'SANTELEC',
      zip: '29555',
      isUrban: false,
    },
    {
      name: 'SUMUGIU',
      zip: '29564',
      isUrban: false,
    },
    {
      name: 'HOLOD',
      zip: '29582',
      isUrban: false,
    },
    {
      name: 'DUMBRAVA',
      zip: '29591',
      isUrban: false,
    },
    {
      name: 'DUMBRAVITA',
      zip: '29608',
      isUrban: false,
    },
    {
      name: 'FOROSIG',
      zip: '29617',
      isUrban: false,
    },
    {
      name: 'HODIS',
      zip: '29626',
      isUrban: false,
    },
    {
      name: 'LUPOAIA',
      zip: '29635',
      isUrban: false,
    },
    {
      name: 'VALEA MARE DE CODRU',
      zip: '29644',
      isUrban: false,
    },
    {
      name: 'VINTERE',
      zip: '29653',
      isUrban: false,
    },
    {
      name: 'HUSASAU DE TINCA',
      zip: '29671',
      isUrban: false,
    },
    {
      name: 'FONAU',
      zip: '29680',
      isUrban: false,
    },
    {
      name: 'MIERSIG',
      zip: '29699',
      isUrban: false,
    },
    {
      name: 'OSAND',
      zip: '29706',
      isUrban: false,
    },
    {
      name: 'SITITELEC',
      zip: '29715',
      isUrban: false,
    },
    {
      name: 'INEU',
      zip: '29733',
      isUrban: false,
    },
    {
      name: 'BOTEAN',
      zip: '29742',
      isUrban: false,
    },
    {
      name: 'HUSASAU DE CRIS',
      zip: '29751',
      isUrban: false,
    },
    {
      name: 'LAZARENI',
      zip: '29822',
      isUrban: false,
    },
    {
      name: 'BICACEL',
      zip: '29831',
      isUrban: false,
    },
    {
      name: 'CALEA MARE',
      zip: '29840',
      isUrban: false,
    },
    {
      name: 'CARANDENI',
      zip: '29859',
      isUrban: false,
    },
    {
      name: 'CARANZEL',
      zip: '29868',
      isUrban: false,
    },
    {
      name: 'GEPIS',
      zip: '29877',
      isUrban: false,
    },
    {
      name: 'GRUILUNG',
      zip: '29886',
      isUrban: false,
    },
    {
      name: 'MIHELEU',
      zip: '29895',
      isUrban: false,
    },
    {
      name: 'LAZURI DE BEIUS',
      zip: '29779',
      isUrban: false,
    },
    {
      name: 'BALENI',
      zip: '29788',
      isUrban: false,
    },
    {
      name: 'CUSUIUS',
      zip: '29797',
      isUrban: false,
    },
    {
      name: 'HINCHIRIS',
      zip: '29804',
      isUrban: false,
    },
    {
      name: 'LUGASU DE JOS',
      zip: '29911',
      isUrban: false,
    },
    {
      name: 'LUGASU DE SUS',
      zip: '29920',
      isUrban: false,
    },
    {
      name: 'URVIND',
      zip: '29939',
      isUrban: false,
    },
    {
      name: 'LUNCA',
      zip: '29957',
      isUrban: false,
    },
    {
      name: 'BRIHENI',
      zip: '29966',
      isUrban: false,
    },
    {
      name: 'HOTAREL',
      zip: '29975',
      isUrban: false,
    },
    {
      name: 'SARBESTI',
      zip: '29993',
      isUrban: false,
    },
    {
      name: 'SEGHISTE',
      zip: '29984',
      isUrban: false,
    },
    {
      name: 'SUSTIU',
      zip: '30005',
      isUrban: false,
    },
    {
      name: 'MADARAS',
      zip: '30023',
      isUrban: false,
    },
    {
      name: 'HOMOROG',
      zip: '30032',
      isUrban: false,
    },
    {
      name: 'IANOSDA',
      zip: '30041',
      isUrban: false,
    },
    {
      name: 'MARTIHAZ',
      zip: '30050',
      isUrban: false,
    },
    {
      name: 'MAGESTI',
      zip: '30078',
      isUrban: false,
    },
    {
      name: 'BUTANI',
      zip: '30087',
      isUrban: false,
    },
    {
      name: 'CACUCIU NOU',
      zip: '30096',
      isUrban: false,
    },
    {
      name: 'DOBRICIONESTI',
      zip: '30103',
      isUrban: false,
    },
    {
      name: 'GALASENI',
      zip: '30112',
      isUrban: false,
    },
    {
      name: 'JOSANI',
      zip: '30121',
      isUrban: false,
    },
    {
      name: 'ORTITEAG',
      zip: '30130',
      isUrban: false,
    },
    {
      name: 'NOJORID',
      zip: '30158',
      isUrban: false,
    },
    {
      name: 'APATEU',
      zip: '30167',
      isUrban: false,
    },
    {
      name: 'CHISIRID',
      zip: '30176',
      isUrban: false,
    },
    {
      name: 'LES',
      zip: '30185',
      isUrban: false,
    },
    {
      name: 'LIVADA DE BIHOR',
      zip: '30194',
      isUrban: false,
    },
    {
      name: 'PAUSA',
      zip: '30201',
      isUrban: false,
    },
    {
      name: 'SAUAIEU',
      zip: '30210',
      isUrban: false,
    },
    {
      name: 'OLCEA',
      zip: '30238',
      isUrban: false,
    },
    {
      name: 'CALACEA',
      zip: '30247',
      isUrban: false,
    },
    {
      name: 'HODISEL',
      zip: '30256',
      isUrban: false,
    },
    {
      name: 'UCURIS',
      zip: '30265',
      isUrban: false,
    },
    {
      name: 'OSORHEI',
      zip: '30283',
      isUrban: false,
    },
    {
      name: 'ALPAREA',
      zip: '30292',
      isUrban: false,
    },
    {
      name: 'CHERIU',
      zip: '30309',
      isUrban: false,
    },
    {
      name: 'FELCHERIU',
      zip: '30318',
      isUrban: false,
    },
    {
      name: 'FUGHIU',
      zip: '30327',
      isUrban: false,
    },
    {
      name: 'PIETROASA',
      zip: '30345',
      isUrban: false,
    },
    {
      name: 'CHISCAU',
      zip: '30354',
      isUrban: false,
    },
    {
      name: 'COCIUBA MICA',
      zip: '30363',
      isUrban: false,
    },
    {
      name: 'GIULESTI',
      zip: '30372',
      isUrban: false,
    },
    {
      name: 'GURANI',
      zip: '30381',
      isUrban: false,
    },
    {
      name: 'MAGURA',
      zip: '30390',
      isUrban: false,
    },
    {
      name: 'MOTESTI',
      zip: '30407',
      isUrban: false,
    },
    {
      name: 'POCOLA',
      zip: '30425',
      isUrban: false,
    },
    {
      name: 'FENERIS',
      zip: '30434',
      isUrban: false,
    },
    {
      name: 'PETRANI',
      zip: '30443',
      isUrban: false,
    },
    {
      name: 'POIETARI',
      zip: '30452',
      isUrban: false,
    },
    {
      name: 'SANMARTIN DE BEIUS',
      zip: '30461',
      isUrban: false,
    },
    {
      name: 'POMEZEU',
      zip: '30489',
      isUrban: false,
    },
    {
      name: 'CAMPANI DE POMEZEU',
      zip: '30498',
      isUrban: false,
    },
    {
      name: 'COSDENI',
      zip: '30504',
      isUrban: false,
    },
    {
      name: 'HIDIS',
      zip: '30513',
      isUrban: false,
    },
    {
      name: 'LACU SARAT',
      zip: '30522',
      isUrban: false,
    },
    {
      name: 'SITANI',
      zip: '30531',
      isUrban: false,
    },
    {
      name: 'SPINUS DE POMEZEU',
      zip: '30540',
      isUrban: false,
    },
    {
      name: 'VALANI DE POMEZEU',
      zip: '30559',
      isUrban: false,
    },
    {
      name: 'POPESTI',
      zip: '30577',
      isUrban: false,
    },
    {
      name: 'BISTRA',
      zip: '30586',
      isUrban: false,
    },
    {
      name: 'BUDOI',
      zip: '30595',
      isUrban: false,
    },
    {
      name: 'CUZAP',
      zip: '30602',
      isUrban: false,
    },
    {
      name: 'VARVIZ',
      zip: '30611',
      isUrban: false,
    },
    {
      name: 'VARZARI',
      zip: '30620',
      isUrban: false,
    },
    {
      name: 'VOIVOZI',
      zip: '30639',
      isUrban: false,
    },
    {
      name: 'RABAGANI',
      zip: '30657',
      isUrban: false,
    },
    {
      name: 'ALBESTI',
      zip: '30666',
      isUrban: false,
    },
    {
      name: 'BRATESTI',
      zip: '30675',
      isUrban: false,
    },
    {
      name: 'SALISTE DE POMEZEU',
      zip: '30693',
      isUrban: false,
    },
    {
      name: 'SAUCANI',
      zip: '30684',
      isUrban: false,
    },
    {
      name: 'VARASENI',
      zip: '30700',
      isUrban: false,
    },
    {
      name: 'REMETEA',
      zip: '30728',
      isUrban: false,
    },
    {
      name: 'DRAGOTENI',
      zip: '30737',
      isUrban: false,
    },
    {
      name: 'MEZIAD',
      zip: '30746',
      isUrban: false,
    },
    {
      name: 'PETREASA',
      zip: '30755',
      isUrban: false,
    },
    {
      name: 'SOIMUS',
      zip: '30764',
      isUrban: false,
    },
    {
      name: 'RIENI',
      zip: '30782',
      isUrban: false,
    },
    {
      name: 'CUCUCENI',
      zip: '30791',
      isUrban: false,
    },
    {
      name: 'GHIGHISENI',
      zip: '30808',
      isUrban: false,
    },
    {
      name: 'PETRILENI',
      zip: '30817',
      isUrban: false,
    },
    {
      name: 'SUDRIGIU',
      zip: '30826',
      isUrban: false,
    },
    {
      name: 'VALEA DE JOS',
      zip: '30835',
      isUrban: false,
    },
    {
      name: 'ROSIA',
      zip: '30853',
      isUrban: false,
    },
    {
      name: 'LAZURI',
      zip: '30862',
      isUrban: false,
    },
    {
      name: 'SACADAT',
      zip: '30880',
      isUrban: false,
    },
    {
      name: 'BORSA',
      zip: '30899',
      isUrban: false,
    },
    {
      name: 'SABOLCIU',
      zip: '30906',
      isUrban: false,
    },
    {
      name: 'SACUENI',
      zip: '30924',
      isUrban: true,
    },
    {
      name: 'CADEA',
      zip: '30933',
      isUrban: true,
    },
    {
      name: 'CIOCAIA',
      zip: '30942',
      isUrban: true,
    },
    {
      name: 'CUBULCUT',
      zip: '30951',
      isUrban: true,
    },
    {
      name: 'OLOSIG',
      zip: '30960',
      isUrban: true,
    },
    {
      name: 'SANNICOLAU DE MUNTE',
      zip: '30979',
      isUrban: true,
    },
    {
      name: 'SALACEA',
      zip: '30997',
      isUrban: false,
    },
    {
      name: 'OTOMANI',
      zip: '31002',
      isUrban: false,
    },
    {
      name: 'SALARD',
      zip: '31020',
      isUrban: false,
    },
    {
      name: 'HODOS',
      zip: '31039',
      isUrban: false,
    },
    {
      name: 'SANTIMREU',
      zip: '31048',
      isUrban: false,
    },
    {
      name: 'SAMBATA',
      zip: '31066',
      isUrban: false,
    },
    {
      name: 'COPACENI',
      zip: '31075',
      isUrban: false,
    },
    {
      name: 'OGESTI',
      zip: '31084',
      isUrban: false,
    },
    {
      name: 'ROGOZ',
      zip: '31093',
      isUrban: false,
    },
    {
      name: 'ROTARESTI',
      zip: '31100',
      isUrban: false,
    },
    {
      name: 'ZAVOIU',
      zip: '31119',
      isUrban: false,
    },
    {
      name: 'SANMARTIN',
      zip: '26591',
      isUrban: false,
    },
    {
      name: 'BAILE FELIX',
      zip: '32143',
      isUrban: false,
    },
    {
      name: 'BETFIA',
      zip: '26608',
      isUrban: false,
    },
    {
      name: 'CIHEI',
      zip: '26617',
      isUrban: false,
    },
    {
      name: 'CORDAU',
      zip: '26626',
      isUrban: false,
    },
    {
      name: 'HAIEU',
      zip: '26635',
      isUrban: false,
    },
    {
      name: 'RONTAU',
      zip: '26644',
      isUrban: false,
    },
    {
      name: 'SANTANDREI',
      zip: '26662',
      isUrban: false,
    },
    {
      name: 'PALOTA',
      zip: '26671',
      isUrban: false,
    },
    {
      name: 'SARBI',
      zip: '31137',
      isUrban: false,
    },
    {
      name: 'ALMASU MIC',
      zip: '31146',
      isUrban: false,
    },
    {
      name: 'BURZUC',
      zip: '31155',
      isUrban: false,
    },
    {
      name: 'CHIOAG',
      zip: '31164',
      isUrban: false,
    },
    {
      name: 'FEGERNIC',
      zip: '31173',
      isUrban: false,
    },
    {
      name: 'FEGERNICU NOU',
      zip: '31182',
      isUrban: false,
    },
    {
      name: 'SARCAU',
      zip: '31191',
      isUrban: false,
    },
    {
      name: 'SIMIAN',
      zip: '31342',
      isUrban: false,
    },
    {
      name: 'SILINDRU',
      zip: '31351',
      isUrban: false,
    },
    {
      name: 'VOIVOZI',
      zip: '31360',
      isUrban: false,
    },
    {
      name: 'SINTEU',
      zip: '31388',
      isUrban: false,
    },
    {
      name: 'HUTA VOIVOZI',
      zip: '31397',
      isUrban: false,
    },
    {
      name: 'SOCET',
      zip: '31404',
      isUrban: false,
    },
    {
      name: 'VALEA TIRNEI',
      zip: '31413',
      isUrban: false,
    },
    {
      name: 'SOIMI',
      zip: '31431',
      isUrban: false,
    },
    {
      name: 'BORZ',
      zip: '31440',
      isUrban: false,
    },
    {
      name: 'CODRU',
      zip: '31459',
      isUrban: false,
    },
    {
      name: 'DUMBRAVITA DE CODRU',
      zip: '31468',
      isUrban: false,
    },
    {
      name: 'POCLUSA DE BEIUS',
      zip: '31477',
      isUrban: false,
    },
    {
      name: 'SANNICOLAU DE BEIUS',
      zip: '31486',
      isUrban: false,
    },
    {
      name: 'URSAD',
      zip: '31495',
      isUrban: false,
    },
    {
      name: 'URVIS DE BEIUS',
      zip: '31501',
      isUrban: false,
    },
    {
      name: 'SPINUS',
      zip: '31217',
      isUrban: false,
    },
    {
      name: 'CIULESTI',
      zip: '31226',
      isUrban: false,
    },
    {
      name: 'GURBESTI',
      zip: '31235',
      isUrban: false,
    },
    {
      name: 'NADAR',
      zip: '31244',
      isUrban: false,
    },
    {
      name: 'SALISTE',
      zip: '31253',
      isUrban: false,
    },
    {
      name: 'SUNCUIUS',
      zip: '31529',
      isUrban: false,
    },
    {
      name: 'BALNACA',
      zip: '31538',
      isUrban: false,
    },
    {
      name: 'BALNACA-GROSI',
      zip: '31547',
      isUrban: false,
    },
    {
      name: 'ZECE HOTARE',
      zip: '31556',
      isUrban: false,
    },
    {
      name: 'SUPLACU DE BARCAU',
      zip: '31271',
      isUrban: false,
    },
    {
      name: 'BORUMLACA',
      zip: '31280',
      isUrban: false,
    },
    {
      name: 'DOLEA',
      zip: '31299',
      isUrban: false,
    },
    {
      name: 'FOGLAS',
      zip: '31306',
      isUrban: false,
    },
    {
      name: 'VALCELELE',
      zip: '31324',
      isUrban: false,
    },
    {
      name: 'VALEA CERULUI',
      zip: '31315',
      isUrban: false,
    },
    {
      name: 'TARCAIA',
      zip: '31618',
      isUrban: false,
    },
    {
      name: 'MIERAG',
      zip: '31627',
      isUrban: false,
    },
    {
      name: 'TARCAITA',
      zip: '31636',
      isUrban: false,
    },
    {
      name: 'TOTORENI',
      zip: '31645',
      isUrban: false,
    },
    {
      name: 'TARCEA',
      zip: '31574',
      isUrban: false,
    },
    {
      name: 'ADONI',
      zip: '31583',
      isUrban: false,
    },
    {
      name: 'GALOSPETRU',
      zip: '31592',
      isUrban: false,
    },
    {
      name: 'TAUTEU',
      zip: '31663',
      isUrban: false,
    },
    {
      name: 'BOGEI',
      zip: '31672',
      isUrban: false,
    },
    {
      name: 'CHIRIBIS',
      zip: '31681',
      isUrban: false,
    },
    {
      name: 'CIUTELEC',
      zip: '31690',
      isUrban: false,
    },
    {
      name: 'POIANA',
      zip: '31707',
      isUrban: false,
    },
    {
      name: 'TETCHEA',
      zip: '31887',
      isUrban: false,
    },
    {
      name: 'HOTAR',
      zip: '31896',
      isUrban: false,
    },
    {
      name: 'SUBPIATRA',
      zip: '31903',
      isUrban: false,
    },
    {
      name: 'TELECHIU',
      zip: '31912',
      isUrban: false,
    },
    {
      name: 'TILEAGD',
      zip: '31725',
      isUrban: false,
    },
    {
      name: 'BALAIA',
      zip: '31734',
      isUrban: false,
    },
    {
      name: 'CALATANI',
      zip: '31743',
      isUrban: false,
    },
    {
      name: 'POSOLOACA',
      zip: '31752',
      isUrban: false,
    },
    {
      name: 'TOMNATIC',
      zip: '32009',
      isUrban: false,
    },
    {
      name: 'TOPA DE CRIS',
      zip: '32018',
      isUrban: false,
    },
    {
      name: 'VARCIOROG',
      zip: '32107',
      isUrban: false,
    },
    {
      name: 'FASCA',
      zip: '32116',
      isUrban: false,
    },
    {
      name: 'SERGHIS',
      zip: '32134',
      isUrban: false,
    },
    {
      name: 'SURDUCEL',
      zip: '32125',
      isUrban: false,
    },
    {
      name: 'VIISOARA',
      zip: '32054',
      isUrban: false,
    },
    {
      name: 'IZVOARELE',
      zip: '32063',
      isUrban: false,
    },
    {
      name: 'PADURENI',
      zip: '32072',
      isUrban: false,
    },
    {
      name: 'REGHEA',
      zip: '32081',
      isUrban: false,
    },
    {
      name: 'ALBIS',
      zip: '27926',
      isUrban: false,
    },
    {
      name: 'BULZ',
      zip: '27944',
      isUrban: false,
    },
    {
      name: 'SARAND',
      zip: '28861',
      isUrban: false,
    },
    {
      name: 'SURDUC',
      zip: '28870',
      isUrban: false,
    },
    {
      name: 'CRISTIORU DE JOS',
      zip: '28898',
      isUrban: false,
    },
    {
      name: 'BALC',
      zip: '28905',
      isUrban: false,
    },
    {
      name: 'CRISTIORU DE SUS',
      zip: '28914',
      isUrban: false,
    },
    {
      name: 'POIANA',
      zip: '28923',
      isUrban: false,
    },
    {
      name: 'SALISTE DE VASCAU',
      zip: '28932',
      isUrban: false,
    },
    {
      name: 'CURATELE',
      zip: '28950',
      isUrban: false,
    },
    {
      name: 'BEIUSELE',
      zip: '28969',
      isUrban: false,
    },
    {
      name: 'CRESUIA',
      zip: '28978',
      isUrban: false,
    },
    {
      name: 'NIMAIESTI',
      zip: '28987',
      isUrban: false,
    },
    {
      name: 'POCIOVELISTE',
      zip: '28996',
      isUrban: false,
    },
    {
      name: 'CURTUISENI',
      zip: '29010',
      isUrban: false,
    },
    {
      name: 'VASAD',
      zip: '29029',
      isUrban: false,
    },
    {
      name: 'DERNA',
      zip: '29047',
      isUrban: false,
    },
    {
      name: 'DERNISOARA',
      zip: '29056',
      isUrban: false,
    },
    {
      name: 'SACALASAU',
      zip: '29065',
      isUrban: false,
    },
    {
      name: 'SACALASAU NOU',
      zip: '29074',
      isUrban: false,
    },
    {
      name: 'TRIA',
      zip: '29083',
      isUrban: false,
    },
    {
      name: 'DIOSIG',
      zip: '29109',
      isUrban: false,
    },
    {
      name: 'IANCA',
      zip: '29118',
      isUrban: false,
    },
    {
      name: 'MIHAI BRAVU',
      zip: '29127',
      isUrban: false,
    },
    {
      name: 'ROSIORI',
      zip: '29136',
      isUrban: false,
    },
    {
      name: 'VAIDA',
      zip: '29145',
      isUrban: false,
    },
    {
      name: 'TILECUS',
      zip: '31761',
      isUrban: false,
    },
    {
      name: 'UILEACU DE CRIS',
      zip: '31770',
      isUrban: false,
    },
    {
      name: 'TINCA',
      zip: '31798',
      isUrban: false,
    },
    {
      name: 'BELFIR',
      zip: '31805',
      isUrban: false,
    },
    {
      name: 'GIRISU NEGRU',
      zip: '31814',
      isUrban: false,
    },
    {
      name: 'GURBEDIU',
      zip: '31823',
      isUrban: false,
    },
    {
      name: 'RAPA',
      zip: '31832',
      isUrban: false,
    },
    {
      name: 'TULCA',
      zip: '31850',
      isUrban: false,
    },
    {
      name: 'CAUASD',
      zip: '31869',
      isUrban: false,
    },
    {
      name: 'UILEACU DE BEIUS',
      zip: '31930',
      isUrban: false,
    },
    {
      name: 'FORAU',
      zip: '31949',
      isUrban: false,
    },
    {
      name: 'PRISACA',
      zip: '31958',
      isUrban: false,
    },
    {
      name: 'VALANII DE BEIUS',
      zip: '31967',
      isUrban: false,
    },
    {
      name: 'VADU CRISULUI',
      zip: '31985',
      isUrban: false,
    },
    {
      name: 'BIRTIN',
      zip: '31994',
      isUrban: false,
    }
  ],
  BR: [
    {
      name: 'BRAILA',
      zip: '42691',
      isUrban: true,
    },
    {
      name: 'FAUREI',
      zip: '42762',
      isUrban: true,
    },
    {
      name: 'IANCA',
      zip: '43340',
      isUrban: true,
    },
    {
      name: 'BERLESTI',
      zip: '43359',
      isUrban: true,
    },
    {
      name: 'GARA IANCA',
      zip: '43368',
      isUrban: true,
    },
    {
      name: 'OPRISENESTI',
      zip: '43377',
      isUrban: true,
    },
    {
      name: 'PERISORU',
      zip: '43386',
      isUrban: true,
    },
    {
      name: 'PLOPU',
      zip: '43395',
      isUrban: true,
    },
    {
      name: 'TARLELE FILIU',
      zip: '43402',
      isUrban: true,
    },
    {
      name: 'INSURATEI',
      zip: '43420',
      isUrban: true,
    },
    {
      name: 'LACU REZII',
      zip: '43439',
      isUrban: true,
    },
    {
      name: 'MARU ROSU',
      zip: '43448',
      isUrban: true,
    },
    {
      name: 'VALEA CALMATUIULUI',
      zip: '43457',
      isUrban: true,
    },
    {
      name: 'BARAGANUL',
      zip: '42833',
      isUrban: false,
    },
    {
      name: 'BERTESTII DE JOS',
      zip: '42851',
      isUrban: false,
    },
    {
      name: 'BERTESTII DE SUS',
      zip: '42860',
      isUrban: false,
    },
    {
      name: 'GURA CALMATUI',
      zip: '42879',
      isUrban: false,
    },
    {
      name: 'GURA GIRLUTEI',
      zip: '42888',
      isUrban: false,
    },
    {
      name: 'NICOLESTI',
      zip: '42897',
      isUrban: false,
    },
    {
      name: 'SPIRU HARET',
      zip: '42904',
      isUrban: false,
    },
    {
      name: 'BORDEI VERDE',
      zip: '42922',
      isUrban: false,
    },
    {
      name: 'CONSTANTIN GABRIELESCU',
      zip: '42931',
      isUrban: false,
    },
    {
      name: 'FILIU',
      zip: '42940',
      isUrban: false,
    },
    {
      name: 'LISCOTEANCA',
      zip: '42959',
      isUrban: false,
    },
    {
      name: 'CHISCANI',
      zip: '42717',
      isUrban: false,
    },
    {
      name: 'LACU SARAT',
      zip: '42726',
      isUrban: false,
    },
    {
      name: 'VARSATURA',
      zip: '42735',
      isUrban: false,
    },
    {
      name: 'CIOCILE',
      zip: '42977',
      isUrban: false,
    },
    {
      name: 'CHICHINETU',
      zip: '43000',
      isUrban: false,
    },
    {
      name: 'CHIOIBASESTI',
      zip: '42986',
      isUrban: false,
    },
    {
      name: 'ODAIENI',
      zip: '42995',
      isUrban: false,
    },
    {
      name: 'CIRESU',
      zip: '43028',
      isUrban: false,
    },
    {
      name: 'BATOGU',
      zip: '43037',
      isUrban: false,
    },
    {
      name: 'IONESTI',
      zip: '43046',
      isUrban: false,
    },
    {
      name: 'SCARLATESTI',
      zip: '43055',
      isUrban: false,
    },
    {
      name: 'VULTURENI',
      zip: '43064',
      isUrban: false,
    },
    {
      name: 'DUDESTI',
      zip: '43082',
      isUrban: false,
    },
    {
      name: 'BUMBACARI',
      zip: '43091',
      isUrban: false,
    },
    {
      name: 'TATARU',
      zip: '43108',
      isUrban: false,
    },
    {
      name: 'FRECATEI',
      zip: '43126',
      isUrban: false,
    },
    {
      name: 'AGAUA',
      zip: '43135',
      isUrban: false,
    },
    {
      name: 'CISTIA',
      zip: '43144',
      isUrban: false,
    },
    {
      name: 'SALCIA',
      zip: '43153',
      isUrban: false,
    },
    {
      name: 'STOIENESTI',
      zip: '43162',
      isUrban: false,
    },
    {
      name: 'TITCOV',
      zip: '43171',
      isUrban: false,
    },
    {
      name: 'GALBENU',
      zip: '43199',
      isUrban: false,
    },
    {
      name: 'DROGU',
      zip: '43206',
      isUrban: false,
    },
    {
      name: 'PANTECANI',
      zip: '43215',
      isUrban: false,
    },
    {
      name: 'SATUC',
      zip: '43224',
      isUrban: false,
    },
    {
      name: 'ZAMFIRESTI',
      zip: '43233',
      isUrban: false,
    },
    {
      name: 'GEMENELE',
      zip: '43251',
      isUrban: false,
    },
    {
      name: 'GAVANI',
      zip: '43260',
      isUrban: false,
    },
    {
      name: 'GRADISTEA',
      zip: '43288',
      isUrban: false,
    },
    {
      name: 'IBRIANU',
      zip: '43297',
      isUrban: false,
    },
    {
      name: 'MARALOIU',
      zip: '43304',
      isUrban: false,
    },
    {
      name: 'GROPENI',
      zip: '43322',
      isUrban: false,
    },
    {
      name: 'JIRLAU',
      zip: '43475',
      isUrban: false,
    },
    {
      name: 'BRADEANCA',
      zip: '43484',
      isUrban: false,
    },
    {
      name: 'MARASU',
      zip: '43509',
      isUrban: false,
    },
    {
      name: 'BANDOIU',
      zip: '43518',
      isUrban: false,
    },
    {
      name: 'MAGURENI',
      zip: '43527',
      isUrban: false,
    },
    {
      name: 'NEDEICU',
      zip: '43536',
      isUrban: false,
    },
    {
      name: 'PLOPI',
      zip: '43545',
      isUrban: false,
    },
    {
      name: 'TACAU',
      zip: '43554',
      isUrban: false,
    },
    {
      name: 'MAXINENI',
      zip: '43572',
      isUrban: false,
    },
    {
      name: 'CORBU NOU',
      zip: '43581',
      isUrban: false,
    },
    {
      name: 'CORBU VECHI',
      zip: '43590',
      isUrban: false,
    },
    {
      name: 'LATINU',
      zip: '43607',
      isUrban: false,
    },
    {
      name: 'VOINESTI',
      zip: '43616',
      isUrban: false,
    },
    {
      name: 'MIRCEA VODA',
      zip: '43634',
      isUrban: false,
    },
    {
      name: 'DEDULESTI',
      zip: '43643',
      isUrban: false,
    },
    {
      name: 'MOVILA MIRESII',
      zip: '43661',
      isUrban: false,
    },
    {
      name: 'ESNA',
      zip: '43670',
      isUrban: false,
    },
    {
      name: 'TEPES VODA',
      zip: '43689',
      isUrban: false,
    },
    {
      name: 'RACOVITA',
      zip: '43705',
      isUrban: false,
    },
    {
      name: 'CORBENI',
      zip: '43714',
      isUrban: false,
    },
    {
      name: 'CUSTURA',
      zip: '43723',
      isUrban: false,
    },
    {
      name: 'RAMNICELU',
      zip: '43741',
      isUrban: false,
    },
    {
      name: 'BOARCA',
      zip: '43750',
      isUrban: false,
    },
    {
      name: 'CONSTANTINESTI',
      zip: '43769',
      isUrban: false,
    },
    {
      name: 'MIHAIL KOGALNICEANU',
      zip: '43778',
      isUrban: false,
    },
    {
      name: 'ROMANU',
      zip: '43796',
      isUrban: false,
    },
    {
      name: 'OANCEA',
      zip: '43803',
      isUrban: false,
    },
    {
      name: 'ROSIORI',
      zip: '43821',
      isUrban: false,
    },
    {
      name: 'COLTEA',
      zip: '43830',
      isUrban: false,
    },
    {
      name: 'FLORICA',
      zip: '43849',
      isUrban: false,
    },
    {
      name: 'PRIBEAGU',
      zip: '43858',
      isUrban: false,
    },
    {
      name: 'SALCIA TUDOR',
      zip: '43876',
      isUrban: false,
    },
    {
      name: 'ARICIU',
      zip: '43885',
      isUrban: false,
    },
    {
      name: 'CUZA VODA',
      zip: '43894',
      isUrban: false,
    },
    {
      name: 'GULIANCA',
      zip: '43901',
      isUrban: false,
    },
    {
      name: 'OLANEASCA',
      zip: '43910',
      isUrban: false,
    },
    {
      name: 'SCORTARU NOU',
      zip: '43938',
      isUrban: false,
    },
    {
      name: 'DESIRATI',
      zip: '43947',
      isUrban: false,
    },
    {
      name: 'GURGUIETI',
      zip: '43956',
      isUrban: false,
    },
    {
      name: 'NICOLAE BALCESCU',
      zip: '43965',
      isUrban: false,
    },
    {
      name: 'PITULATI',
      zip: '43974',
      isUrban: false,
    },
    {
      name: 'SIHLEANU',
      zip: '43983',
      isUrban: false,
    },
    {
      name: 'SILISTEA',
      zip: '44006',
      isUrban: false,
    },
    {
      name: 'COTU LUNG',
      zip: '44015',
      isUrban: false,
    },
    {
      name: 'COTU MIHALEA',
      zip: '44024',
      isUrban: false,
    },
    {
      name: 'MARTACESTI',
      zip: '44033',
      isUrban: false,
    },
    {
      name: 'MUCHEA',
      zip: '44042',
      isUrban: false,
    },
    {
      name: 'VAMESU',
      zip: '44051',
      isUrban: false,
    },
    {
      name: 'STANCUTA',
      zip: '44079',
      isUrban: false,
    },
    {
      name: 'CUZA VODA',
      zip: '44088',
      isUrban: false,
    },
    {
      name: 'POLIZESTI',
      zip: '44097',
      isUrban: false,
    },
    {
      name: 'STANCA',
      zip: '44104',
      isUrban: false,
    },
    {
      name: 'SURDILA-GAISEANCA',
      zip: '44122',
      isUrban: false,
    },
    {
      name: 'FILIPESTI',
      zip: '44131',
      isUrban: false,
    },
    {
      name: 'SURDILA-GRECI',
      zip: '42780',
      isUrban: false,
    },
    {
      name: 'BRATESU VECHI',
      zip: '42799',
      isUrban: false,
    },
    {
      name: 'FAUREI SAT',
      zip: '42806',
      isUrban: false,
    },
    {
      name: 'HORIA',
      zip: '42815',
      isUrban: false,
    },
    {
      name: 'SUTESTI',
      zip: '44159',
      isUrban: false,
    },
    {
      name: 'MIHAIL KOGALNICEANU',
      zip: '44168',
      isUrban: false,
    },
    {
      name: 'TICHILESTI',
      zip: '44186',
      isUrban: false,
    },
    {
      name: 'ALBINA',
      zip: '44195',
      isUrban: false,
    },
    {
      name: 'TRAIAN',
      zip: '44211',
      isUrban: false,
    },
    {
      name: 'CALDARUSA',
      zip: '44220',
      isUrban: false,
    },
    {
      name: 'SILISTRARU',
      zip: '44239',
      isUrban: false,
    },
    {
      name: 'URLEASCA',
      zip: '44248',
      isUrban: false,
    },
    {
      name: 'TUDOR VLADIMIRESCU',
      zip: '44266',
      isUrban: false,
    },
    {
      name: 'CAZASU',
      zip: '44275',
      isUrban: false,
    },
    {
      name: 'COMANEASCA',
      zip: '44284',
      isUrban: false,
    },
    {
      name: 'SCORTARU VECHI',
      zip: '44293',
      isUrban: false,
    },
    {
      name: 'TUFESTI',
      zip: '44319',
      isUrban: false,
    },
    {
      name: 'ULMU',
      zip: '44337',
      isUrban: false,
    },
    {
      name: 'JUGUREANU',
      zip: '44346',
      isUrban: false,
    },
    {
      name: 'UNIREA',
      zip: '44364',
      isUrban: false,
    },
    {
      name: 'MOROTESTI',
      zip: '44373',
      isUrban: false,
    },
    {
      name: 'VALEA CANEPII',
      zip: '44382',
      isUrban: false,
    },
    {
      name: 'VADENI',
      zip: '44408',
      isUrban: false,
    },
    {
      name: 'BALDOVINESTI',
      zip: '44417',
      isUrban: false,
    },
    {
      name: 'PIETROIU',
      zip: '44426',
      isUrban: false,
    },
    {
      name: 'VICTORIA',
      zip: '44444',
      isUrban: false,
    },
    {
      name: 'MIHAI BRAVU',
      zip: '44453',
      isUrban: false,
    },
    {
      name: 'VISANI',
      zip: '44471',
      isUrban: false,
    },
    {
      name: 'CAINENI-BAI',
      zip: '44480',
      isUrban: false,
    },
    {
      name: 'PLASOIU',
      zip: '44499',
      isUrban: false,
    },
    {
      name: 'VIZIRU',
      zip: '44514',
      isUrban: false,
    },
    {
      name: 'LANURILE',
      zip: '44523',
      isUrban: false,
    },
    {
      name: 'ZAVOAIA',
      zip: '44541',
      isUrban: false,
    },
    {
      name: 'DUDESCU',
      zip: '44550',
      isUrban: false,
    }
  ],
  BZ: [
    {
      name: 'BUZAU',
      zip: '44827',
      isUrban: true,
    },
    {
      name: 'RAMNICU SARAT',
      zip: '44854',
      isUrban: true,
    },
    {
      name: 'NEHOIU',
      zip: '47925',
      isUrban: true,
    },
    {
      name: 'BASCA ROZILEI',
      zip: '47934',
      isUrban: true,
    },
    {
      name: 'CHIRLESTI',
      zip: '47943',
      isUrban: true,
    },
    {
      name: 'CURMATURA',
      zip: '47952',
      isUrban: true,
    },
    {
      name: 'LUNCA PRIPORULUI',
      zip: '47961',
      isUrban: true,
    },
    {
      name: 'MLAJET',
      zip: '47970',
      isUrban: true,
    },
    {
      name: 'NEHOIASU',
      zip: '47989',
      isUrban: true,
    },
    {
      name: 'PALTINENI',
      zip: '47998',
      isUrban: true,
    },
    {
      name: 'STANILA',
      zip: '48003',
      isUrban: true,
    },
    {
      name: 'VINETISU',
      zip: '48012',
      isUrban: true,
    },
    {
      name: 'POGOANELE',
      zip: '48753',
      isUrban: true,
    },
    {
      name: 'CALDARASTI',
      zip: '48762',
      isUrban: true,
    },
    {
      name: 'AMARU',
      zip: '44872',
      isUrban: false,
    },
    {
      name: 'CAMPENI',
      zip: '44881',
      isUrban: false,
    },
    {
      name: 'DULBANU',
      zip: '44890',
      isUrban: false,
    },
    {
      name: 'LACU SINAIA',
      zip: '44907',
      isUrban: false,
    },
    {
      name: 'LUNCA',
      zip: '44916',
      isUrban: false,
    },
    {
      name: 'SCORTEANCA',
      zip: '44925',
      isUrban: false,
    },
    {
      name: 'BALACEANU',
      zip: '44998',
      isUrban: false,
    },
    {
      name: 'BALTA ALBA',
      zip: '44943',
      isUrban: false,
    },
    {
      name: 'AMARA',
      zip: '44952',
      isUrban: false,
    },
    {
      name: 'BAILE',
      zip: '44961',
      isUrban: false,
    },
    {
      name: 'STAVARASTI',
      zip: '44970',
      isUrban: false,
    },
    {
      name: 'BECENI',
      zip: '45012',
      isUrban: false,
    },
    {
      name: 'ARBANASI',
      zip: '45021',
      isUrban: false,
    },
    {
      name: 'CARPINISTEA',
      zip: '45030',
      isUrban: false,
    },
    {
      name: 'DOGARI',
      zip: '45049',
      isUrban: false,
    },
    {
      name: 'FLORESTI',
      zip: '45058',
      isUrban: false,
    },
    {
      name: 'GURA DIMIENII',
      zip: '45067',
      isUrban: false,
    },
    {
      name: 'IZVORU DULCE',
      zip: '45076',
      isUrban: false,
    },
    {
      name: 'MARGARITI',
      zip: '45085',
      isUrban: false,
    },
    {
      name: 'VALEA PARULUI',
      zip: '45094',
      isUrban: false,
    },
    {
      name: 'BERCA',
      zip: '45110',
      isUrban: false,
    },
    {
      name: 'BACENI',
      zip: '45129',
      isUrban: false,
    },
    {
      name: 'COJANU',
      zip: '45138',
      isUrban: false,
    },
    {
      name: 'JOSENI',
      zip: '45147',
      isUrban: false,
    },
    {
      name: 'MANASTIREA RATESTI',
      zip: '45156',
      isUrban: false,
    },
    {
      name: 'PACLELE',
      zip: '45165',
      isUrban: false,
    },
    {
      name: 'PLESCOI',
      zip: '45174',
      isUrban: false,
    },
    {
      name: 'PLESESTI',
      zip: '45183',
      isUrban: false,
    },
    {
      name: 'RATESTI',
      zip: '45192',
      isUrban: false,
    },
    {
      name: 'SATUC',
      zip: '45209',
      isUrban: false,
    },
    {
      name: 'TATARLIGU',
      zip: '45218',
      isUrban: false,
    },
    {
      name: 'VALEA NUCULUI',
      zip: '45227',
      isUrban: false,
    },
    {
      name: 'VIFORATA',
      zip: '45236',
      isUrban: false,
    },
    {
      name: 'BISOCA',
      zip: '45254',
      isUrban: false,
    },
    {
      name: 'BALTAGARI',
      zip: '45263',
      isUrban: false,
    },
    {
      name: 'LACURILE',
      zip: '45272',
      isUrban: false,
    },
    {
      name: 'LOPATAREASA',
      zip: '45281',
      isUrban: false,
    },
    {
      name: 'PLESI',
      zip: '45290',
      isUrban: false,
    },
    {
      name: 'RECEA',
      zip: '45307',
      isUrban: false,
    },
    {
      name: 'SARILE',
      zip: '45316',
      isUrban: false,
    },
    {
      name: 'SINDRILA',
      zip: '45325',
      isUrban: false,
    },
    {
      name: 'BLAJANI',
      zip: '45343',
      isUrban: false,
    },
    {
      name: 'SORESTI',
      zip: '45352',
      isUrban: false,
    },
    {
      name: 'BOLDU',
      zip: '45370',
      isUrban: false,
    },
    {
      name: 'BOZIORU',
      zip: '45398',
      isUrban: false,
    },
    {
      name: 'BUDUILE',
      zip: '45405',
      isUrban: false,
    },
    {
      name: 'FISICI',
      zip: '45414',
      isUrban: false,
    },
    {
      name: 'GAVANELE',
      zip: '45423',
      isUrban: false,
    },
    {
      name: 'GRESIA',
      zip: '45432',
      isUrban: false,
    },
    {
      name: 'IZVOARELE',
      zip: '45441',
      isUrban: false,
    },
    {
      name: 'NUCU',
      zip: '45450',
      isUrban: false,
    },
    {
      name: 'SCAENI',
      zip: '45469',
      isUrban: false,
    },
    {
      name: 'ULMET',
      zip: '45478',
      isUrban: false,
    },
    {
      name: 'VAVALUCILE',
      zip: '45487',
      isUrban: false,
    },
    {
      name: 'BRADEANU',
      zip: '45502',
      isUrban: false,
    },
    {
      name: 'MITROPOLIA',
      zip: '45511',
      isUrban: false,
    },
    {
      name: 'SMARDAN',
      zip: '45520',
      isUrban: false,
    },
    {
      name: 'BRAESTI',
      zip: '45548',
      isUrban: false,
    },
    {
      name: 'BRATILESTI',
      zip: '45557',
      isUrban: false,
    },
    {
      name: 'GOIDESTI',
      zip: '45566',
      isUrban: false,
    },
    {
      name: 'IVANETU',
      zip: '45575',
      isUrban: false,
    },
    {
      name: 'PARSCOVELU',
      zip: '45593',
      isUrban: false,
    },
    {
      name: 'PINU',
      zip: '45584',
      isUrban: false,
    },
    {
      name: 'RUGINOASA',
      zip: '45600',
      isUrban: false,
    },
    {
      name: 'BREAZA',
      zip: '45628',
      isUrban: false,
    },
    {
      name: 'BADENI',
      zip: '45637',
      isUrban: false,
    },
    {
      name: 'GRECEANCA',
      zip: '45646',
      isUrban: false,
    },
    {
      name: 'VALEANCA-VILANESTI',
      zip: '45655',
      isUrban: false,
    },
    {
      name: 'VISPESTI',
      zip: '45664',
      isUrban: false,
    },
    {
      name: 'BUDA',
      zip: '45682',
      isUrban: false,
    },
    {
      name: 'ALEXANDRU ODOBESCU',
      zip: '45691',
      isUrban: false,
    },
    {
      name: 'DANULESTI',
      zip: '45708',
      isUrban: false,
    },
    {
      name: 'MUCESTI-DANULESTI',
      zip: '45717',
      isUrban: false,
    },
    {
      name: 'SPIDELE',
      zip: '45726',
      isUrban: false,
    },
    {
      name: 'TOROPALESTI',
      zip: '45735',
      isUrban: false,
    },
    {
      name: 'VALEA LARGA',
      zip: '45744',
      isUrban: false,
    },
    {
      name: 'C.A. ROSETTI',
      zip: '45824',
      isUrban: false,
    },
    {
      name: 'BALHACU',
      zip: '45842',
      isUrban: false,
    },
    {
      name: 'BALTENI',
      zip: '45833',
      isUrban: false,
    },
    {
      name: 'COTU CIORII',
      zip: '45851',
      isUrban: false,
    },
    {
      name: 'LUNCA',
      zip: '45860',
      isUrban: false,
    },
    {
      name: 'VIZIRENI',
      zip: '45879',
      isUrban: false,
    },
    {
      name: 'CALVINI',
      zip: '45762',
      isUrban: false,
    },
    {
      name: 'BASCENII DE JOS',
      zip: '45771',
      isUrban: false,
    },
    {
      name: 'GONTESTI',
      zip: '45904',
      isUrban: false,
    },
    {
      name: 'NEGOSINA',
      zip: '45913',
      isUrban: false,
    },
    {
      name: 'PACURILE',
      zip: '45922',
      isUrban: false,
    },
    {
      name: 'SUCHEA',
      zip: '45931',
      isUrban: false,
    },
    {
      name: 'VALEA VERZEI',
      zip: '45940',
      isUrban: false,
    },
    {
      name: 'CATINA',
      zip: '45968',
      isUrban: false,
    },
    {
      name: 'CORBU',
      zip: '45977',
      isUrban: false,
    },
    {
      name: 'SLOBOZIA',
      zip: '45986',
      isUrban: false,
    },
    {
      name: 'VALEA CATINEI',
      zip: '45995',
      isUrban: false,
    },
    {
      name: 'ZELETIN',
      zip: '46000',
      isUrban: false,
    },
    {
      name: 'CERNATESTI',
      zip: '46028',
      isUrban: false,
    },
    {
      name: 'ALDENI',
      zip: '46037',
      isUrban: false,
    },
    {
      name: 'BAESTI',
      zip: '46046',
      isUrban: false,
    },
    {
      name: 'CALDARUSA',
      zip: '46055',
      isUrban: false,
    },
    {
      name: 'FULGA',
      zip: '46064',
      isUrban: false,
    },
    {
      name: 'MANASIA',
      zip: '46073',
      isUrban: false,
    },
    {
      name: 'VLADENI',
      zip: '46082',
      isUrban: false,
    },
    {
      name: 'ZARNESTII DE SLANIC',
      zip: '46091',
      isUrban: false,
    },
    {
      name: 'CHILIILE',
      zip: '46117',
      isUrban: false,
    },
    {
      name: 'BUDESTI',
      zip: '46126',
      isUrban: false,
    },
    {
      name: 'CREVELESTI',
      zip: '46135',
      isUrban: false,
    },
    {
      name: 'GHIOCARI',
      zip: '46144',
      isUrban: false,
    },
    {
      name: 'GLODU-PETCARI',
      zip: '46153',
      isUrban: false,
    },
    {
      name: 'POIANA PLETARI',
      zip: '46162',
      isUrban: false,
    },
    {
      name: 'TRESTIOARA',
      zip: '46171',
      isUrban: false,
    },
    {
      name: 'CHIOJDU',
      zip: '46199',
      isUrban: false,
    },
    {
      name: 'BASCA CHIOJDULUI',
      zip: '46206',
      isUrban: false,
    },
    {
      name: 'CATIASU',
      zip: '46215',
      isUrban: false,
    },
    {
      name: 'LERA',
      zip: '46224',
      isUrban: false,
    },
    {
      name: 'PLESCIOARA',
      zip: '46233',
      isUrban: false,
    },
    {
      name: 'POENITELE',
      zip: '46242',
      isUrban: false,
    },
    {
      name: 'CILIBIA',
      zip: '46260',
      isUrban: false,
    },
    {
      name: 'GARA CILIBIA',
      zip: '46279',
      isUrban: false,
    },
    {
      name: 'MANZU',
      zip: '46288',
      isUrban: false,
    },
    {
      name: 'MOVILA OII',
      zip: '46297',
      isUrban: false,
    },
    {
      name: 'POSTA',
      zip: '46304',
      isUrban: false,
    },
    {
      name: 'CISLAU',
      zip: '46322',
      isUrban: false,
    },
    {
      name: 'BARASTI',
      zip: '46331',
      isUrban: false,
    },
    {
      name: 'BUDA CRACIUNESTI',
      zip: '46340',
      isUrban: false,
    },
    {
      name: 'GURA BASCEI',
      zip: '46359',
      isUrban: false,
    },
    {
      name: 'SCARISOARA',
      zip: '46368',
      isUrban: false,
    },
    {
      name: 'COCHIRLEANCA',
      zip: '46386',
      isUrban: false,
    },
    {
      name: 'BOBOC',
      zip: '46395',
      isUrban: false,
    },
    {
      name: 'GARA BOBOCU',
      zip: '46402',
      isUrban: false,
    },
    {
      name: 'ROSIORU',
      zip: '46411',
      isUrban: false,
    },
    {
      name: 'TARLELE',
      zip: '46420',
      isUrban: false,
    },
    {
      name: 'COLTI',
      zip: '46448',
      isUrban: false,
    },
    {
      name: 'ALUNIS',
      zip: '46457',
      isUrban: false,
    },
    {
      name: 'COLTII DE JOS',
      zip: '46466',
      isUrban: false,
    },
    {
      name: 'MUSCELU CARAMANESTI',
      zip: '46475',
      isUrban: false,
    },
    {
      name: 'COSTESTI',
      zip: '46493',
      isUrban: false,
    },
    {
      name: 'BUDISTENI',
      zip: '46509',
      isUrban: false,
    },
    {
      name: 'GOMOESTI',
      zip: '46518',
      isUrban: false,
    },
    {
      name: 'GROSANI',
      zip: '46527',
      isUrban: false,
    },
    {
      name: 'PIETROSU',
      zip: '46536',
      isUrban: false,
    },
    {
      name: 'SPATARU',
      zip: '46545',
      isUrban: false,
    },
    {
      name: 'COZIENI',
      zip: '46563',
      isUrban: false,
    },
    {
      name: 'ANINI',
      zip: '46572',
      isUrban: false,
    },
    {
      name: 'BALANESTI',
      zip: '46581',
      isUrban: false,
    },
    {
      name: 'BERCESTI',
      zip: '46590',
      isUrban: false,
    },
    {
      name: 'CIOCANESTI',
      zip: '46607',
      isUrban: false,
    },
    {
      name: 'COCIRCENI',
      zip: '46616',
      isUrban: false,
    },
    {
      name: 'COLTENI',
      zip: '46625',
      isUrban: false,
    },
    {
      name: 'FATA LUI NAN',
      zip: '46634',
      isUrban: false,
    },
    {
      name: 'GLODURILE',
      zip: '46643',
      isUrban: false,
    },
    {
      name: 'IZVORU',
      zip: '46652',
      isUrban: false,
    },
    {
      name: 'LUNGESTI',
      zip: '46661',
      isUrban: false,
    },
    {
      name: 'NISTORESTI',
      zip: '46670',
      isUrban: false,
    },
    {
      name: 'PIETRARU',
      zip: '46689',
      isUrban: false,
    },
    {
      name: 'PUNGA',
      zip: '46698',
      isUrban: false,
    },
    {
      name: 'TEISU',
      zip: '46705',
      isUrban: false,
    },
    {
      name: 'TRESTIA',
      zip: '46714',
      isUrban: false,
    },
    {
      name: 'TULBUREA',
      zip: '46723',
      isUrban: false,
    },
    {
      name: 'VALEA BANULUI',
      zip: '46732',
      isUrban: false,
    },
    {
      name: 'VALEA ROATEI',
      zip: '46741',
      isUrban: false,
    },
    {
      name: 'ZAPODIA',
      zip: '46750',
      isUrban: false,
    },
    {
      name: 'GALBINASI',
      zip: '46778',
      isUrban: false,
    },
    {
      name: 'BENTU',
      zip: '46787',
      isUrban: false,
    },
    {
      name: 'TABARASTI',
      zip: '46796',
      isUrban: false,
    },
    {
      name: 'GHERASENI',
      zip: '46812',
      isUrban: false,
    },
    {
      name: 'SUDITI',
      zip: '46821',
      isUrban: false,
    },
    {
      name: 'GHERGHEASA',
      zip: '46849',
      isUrban: false,
    },
    {
      name: 'SALCIOARA',
      zip: '46858',
      isUrban: false,
    },
    {
      name: 'GLODEANU SARAT',
      zip: '46876',
      isUrban: false,
    },
    {
      name: 'CALDARUSEANCA',
      zip: '46885',
      isUrban: false,
    },
    {
      name: 'ILEANA',
      zip: '46894',
      isUrban: false,
    },
    {
      name: 'PITULICEA',
      zip: '46901',
      isUrban: false,
    },
    {
      name: 'GLODEANU-SILISTEA',
      zip: '46929',
      isUrban: false,
    },
    {
      name: 'CARLIGU MARE',
      zip: '46947',
      isUrban: false,
    },
    {
      name: 'CARLIGU MIC',
      zip: '46956',
      isUrban: false,
    },
    {
      name: 'CASOTA',
      zip: '46938',
      isUrban: false,
    },
    {
      name: 'CORBU',
      zip: '46965',
      isUrban: false,
    },
    {
      name: 'COTORCA',
      zip: '46974',
      isUrban: false,
    },
    {
      name: 'SATU NOU',
      zip: '46983',
      isUrban: false,
    },
    {
      name: 'VACAREASCA',
      zip: '46992',
      isUrban: false,
    },
    {
      name: 'GREBANU',
      zip: '47015',
      isUrban: false,
    },
    {
      name: 'HOMESTI',
      zip: '47024',
      isUrban: false,
    },
    {
      name: 'LIVADA',
      zip: '47033',
      isUrban: false,
    },
    {
      name: 'LIVADA MICA',
      zip: '47042',
      isUrban: false,
    },
    {
      name: 'PLEVNA',
      zip: '47051',
      isUrban: false,
    },
    {
      name: 'ZAPLAZI',
      zip: '47060',
      isUrban: false,
    },
    {
      name: 'GURA TEGHII',
      zip: '47088',
      isUrban: false,
    },
    {
      name: 'FURTUNESTI',
      zip: '47097',
      isUrban: false,
    },
    {
      name: 'NEMERTEA',
      zip: '47104',
      isUrban: false,
    },
    {
      name: 'PALTINIS',
      zip: '47113',
      isUrban: false,
    },
    {
      name: 'SECUIU',
      zip: '47122',
      isUrban: false,
    },
    {
      name: 'VADU OII',
      zip: '47131',
      isUrban: false,
    },
    {
      name: 'VARLAAM',
      zip: '47140',
      isUrban: false,
    },
    {
      name: 'LARGU',
      zip: '47168',
      isUrban: false,
    },
    {
      name: 'SCARLATESTI',
      zip: '47177',
      isUrban: false,
    },
    {
      name: 'LOPATARI',
      zip: '47195',
      isUrban: false,
    },
    {
      name: 'BREBU',
      zip: '47202',
      isUrban: false,
    },
    {
      name: 'FUNDATA',
      zip: '47211',
      isUrban: false,
    },
    {
      name: 'LUNCILE',
      zip: '47220',
      isUrban: false,
    },
    {
      name: 'PESTRITU',
      zip: '47239',
      isUrban: false,
    },
    {
      name: 'PLAIU NUCULUI',
      zip: '47248',
      isUrban: false,
    },
    {
      name: 'PLOSTINA',
      zip: '47257',
      isUrban: false,
    },
    {
      name: 'POTECU',
      zip: '47266',
      isUrban: false,
    },
    {
      name: 'SARENI',
      zip: '47275',
      isUrban: false,
    },
    {
      name: 'TERCA',
      zip: '47284',
      isUrban: false,
    },
    {
      name: 'VARTEJU',
      zip: '47293',
      isUrban: false,
    },
    {
      name: 'LUCIU',
      zip: '47319',
      isUrban: false,
    },
    {
      name: 'CARAGELE',
      zip: '47328',
      isUrban: false,
    },
    {
      name: 'MAGURA',
      zip: '47346',
      isUrban: false,
    },
    {
      name: 'CIUTA',
      zip: '47355',
      isUrban: false,
    },
    {
      name: 'UNGURIU',
      zip: '47364',
      isUrban: false,
    },
    {
      name: 'MANZALESTI',
      zip: '47649',
      isUrban: false,
    },
    {
      name: 'BESLII',
      zip: '47658',
      isUrban: false,
    },
    {
      name: 'BUSTEA',
      zip: '47667',
      isUrban: false,
    },
    {
      name: 'CIRESU',
      zip: '47676',
      isUrban: false,
    },
    {
      name: 'GHIZDITA',
      zip: '47685',
      isUrban: false,
    },
    {
      name: 'GURA BADICULUI',
      zip: '47694',
      isUrban: false,
    },
    {
      name: 'JGHIAB',
      zip: '47701',
      isUrban: false,
    },
    {
      name: 'PLAVATU',
      zip: '47710',
      isUrban: false,
    },
    {
      name: 'POIANA VILCULUI',
      zip: '47729',
      isUrban: false,
    },
    {
      name: 'SATU VECHI',
      zip: '47738',
      isUrban: false,
    },
    {
      name: 'TRESTIOARA',
      zip: '47747',
      isUrban: false,
    },
    {
      name: 'VALEA COTOAREI',
      zip: '47756',
      isUrban: false,
    },
    {
      name: 'VALEA URSULUI',
      zip: '47765',
      isUrban: false,
    },
    {
      name: 'MARACINENI',
      zip: '47382',
      isUrban: false,
    },
    {
      name: 'CAPATANESTI',
      zip: '47391',
      isUrban: false,
    },
    {
      name: 'POTOCENI',
      zip: '47408',
      isUrban: false,
    },
    {
      name: 'MARGARITESTI',
      zip: '47426',
      isUrban: false,
    },
    {
      name: 'CAMPULUNGEANCA',
      zip: '47435',
      isUrban: false,
    },
    {
      name: 'FANTANELE',
      zip: '47444',
      isUrban: false,
    },
    {
      name: 'MEREI',
      zip: '47462',
      isUrban: false,
    },
    {
      name: 'CIOBANOAIA',
      zip: '47471',
      isUrban: false,
    },
    {
      name: 'DEALUL VIEI',
      zip: '47480',
      isUrban: false,
    },
    {
      name: 'DOBRILESTI',
      zip: '47499',
      isUrban: false,
    },
    {
      name: 'GURA SARATII',
      zip: '47505',
      isUrban: false,
    },
    {
      name: 'IZVORU DULCE',
      zip: '47514',
      isUrban: false,
    },
    {
      name: 'LIPIA',
      zip: '47523',
      isUrban: false,
    },
    {
      name: 'NENCIULESTI',
      zip: '47532',
      isUrban: false,
    },
    {
      name: 'OGRAZILE',
      zip: '47541',
      isUrban: false,
    },
    {
      name: 'SARATA-MONTEORU',
      zip: '47550',
      isUrban: false,
    },
    {
      name: 'VALEA PUTULUI MEREI',
      zip: '47569',
      isUrban: false,
    },
    {
      name: 'MIHAILESTI',
      zip: '47587',
      isUrban: false,
    },
    {
      name: 'COLTANENI',
      zip: '47596',
      isUrban: false,
    },
    {
      name: 'FLORICA',
      zip: '47603',
      isUrban: false,
    },
    {
      name: 'MARGINEANU',
      zip: '47612',
      isUrban: false,
    },
    {
      name: 'SATU NOU',
      zip: '47621',
      isUrban: false,
    },
    {
      name: 'MOVILA BANULUI',
      zip: '47783',
      isUrban: false,
    },
    {
      name: 'CIORANCA',
      zip: '47792',
      isUrban: false,
    },
    {
      name: 'LIMPEZIS',
      zip: '47809',
      isUrban: false,
    },
    {
      name: 'MURGESTI',
      zip: '47827',
      isUrban: false,
    },
    {
      name: 'BATOGU',
      zip: '47836',
      isUrban: false,
    },
    {
      name: 'VALEA RATEI',
      zip: '47845',
      isUrban: false,
    },
    {
      name: 'NAENI',
      zip: '47863',
      isUrban: false,
    },
    {
      name: 'FANTINELE',
      zip: '47881',
      isUrban: false,
    },
    {
      name: 'FINTESTI',
      zip: '47872',
      isUrban: false,
    },
    {
      name: 'PROSCA',
      zip: '47890',
      isUrban: false,
    },
    {
      name: 'VARF',
      zip: '47907',
      isUrban: false,
    },
    {
      name: 'ODAILE',
      zip: '48030',
      isUrban: false,
    },
    {
      name: 'CAPU SATULUI',
      zip: '48049',
      isUrban: false,
    },
    {
      name: 'CORNEANU',
      zip: '48058',
      isUrban: false,
    },
    {
      name: 'GORANI',
      zip: '48067',
      isUrban: false,
    },
    {
      name: 'LACU',
      zip: '48076',
      isUrban: false,
    },
    {
      name: 'PIATRA ALBA',
      zip: '48085',
      isUrban: false,
    },
    {
      name: 'POSOBESTI',
      zip: '48094',
      isUrban: false,
    },
    {
      name: 'SCOROSESTI',
      zip: '48101',
      isUrban: false,
    },
    {
      name: 'VALEA FANTANEI',
      zip: '48110',
      isUrban: false,
    },
    {
      name: 'VALEA STEFANULUI',
      zip: '48129',
      isUrban: false,
    },
    {
      name: 'PADINA',
      zip: '48147',
      isUrban: false,
    },
    {
      name: 'TATULESTI',
      zip: '48156',
      isUrban: false,
    },
    {
      name: 'PANATAU',
      zip: '48236',
      isUrban: false,
    },
    {
      name: 'BEGU',
      zip: '48245',
      isUrban: false,
    },
    {
      name: 'LACU CU ANINI',
      zip: '48254',
      isUrban: false,
    },
    {
      name: 'MAGURICEA',
      zip: '48263',
      isUrban: false,
    },
    {
      name: 'PLAISOR',
      zip: '48272',
      isUrban: false,
    },
    {
      name: 'RIPILE',
      zip: '48281',
      isUrban: false,
    },
    {
      name: 'SIBICIU DE JOS',
      zip: '48290',
      isUrban: false,
    },
    {
      name: 'TEGA',
      zip: '48307',
      isUrban: false,
    },
    {
      name: 'ZAHARESTI',
      zip: '48316',
      isUrban: false,
    },
    {
      name: 'PARDOSI',
      zip: '48174',
      isUrban: false,
    },
    {
      name: 'CHIPERU',
      zip: '48183',
      isUrban: false,
    },
    {
      name: 'COSTOMIRU',
      zip: '48192',
      isUrban: false,
    },
    {
      name: 'VALEA LUI LALU',
      zip: '48209',
      isUrban: false,
    },
    {
      name: 'VALEA SCHIOPULUI',
      zip: '48218',
      isUrban: false,
    },
    {
      name: 'PARSCOV',
      zip: '48566',
      isUrban: false,
    },
    {
      name: 'BADILA',
      zip: '48575',
      isUrban: false,
    },
    {
      name: 'CURCANESTI',
      zip: '48584',
      isUrban: false,
    },
    {
      name: 'LUNCA FRUMOASA',
      zip: '48593',
      isUrban: false,
    },
    {
      name: 'OLESESTI',
      zip: '48600',
      isUrban: false,
    },
    {
      name: 'PARJOLESTI',
      zip: '48619',
      isUrban: false,
    },
    {
      name: 'ROBESTI',
      zip: '48628',
      isUrban: false,
    },
    {
      name: 'RUNCU',
      zip: '48637',
      isUrban: false,
    },
    {
      name: 'TARCOV',
      zip: '48646',
      isUrban: false,
    },
    {
      name: 'TOCILENI',
      zip: '48655',
      isUrban: false,
    },
    {
      name: 'TRESTIENI',
      zip: '48664',
      isUrban: false,
    },
    {
      name: 'VALEA PURCARULUI',
      zip: '48673',
      isUrban: false,
    },
    {
      name: 'PATARLAGELE',
      zip: '48334',
      isUrban: true,
    },
    {
      name: 'CALEA CHIOJDULUI',
      zip: '48343',
      isUrban: true,
    },
    {
      name: 'CRANG',
      zip: '48352',
      isUrban: true,
    },
    {
      name: 'FUNDATURILE',
      zip: '48361',
      isUrban: true,
    },
    {
      name: 'GORNET',
      zip: '48370',
      isUrban: true,
    },
    {
      name: 'LUNCA',
      zip: '48389',
      isUrban: true,
    },
    {
      name: 'MANASTIREA',
      zip: '48405',
      isUrban: true,
    },
    {
      name: 'MARUNTISU',
      zip: '48398',
      isUrban: true,
    },
    {
      name: 'MUSCEL',
      zip: '48414',
      isUrban: true,
    },
    {
      name: 'POIENILE',
      zip: '48423',
      isUrban: true,
    },
    {
      name: 'SIBICIU DE SUS',
      zip: '48432',
      isUrban: true,
    },
    {
      name: 'STROESTI',
      zip: '48441',
      isUrban: true,
    },
    {
      name: 'VALEA LUPULUI',
      zip: '48450',
      isUrban: true,
    },
    {
      name: 'VALEA SIBICIULUI',
      zip: '48469',
      isUrban: true,
    },
    {
      name: 'VALEA VIEI',
      zip: '48478',
      isUrban: true,
    },
    {
      name: 'PIETROASELE',
      zip: '48496',
      isUrban: false,
    },
    {
      name: 'CALTESTI',
      zip: '48502',
      isUrban: false,
    },
    {
      name: 'CLONDIRU DE SUS',
      zip: '48511',
      isUrban: false,
    },
    {
      name: 'DARA',
      zip: '48520',
      isUrban: false,
    },
    {
      name: 'PIETROASA MICA',
      zip: '48539',
      isUrban: false,
    },
    {
      name: 'SARANGA',
      zip: '48548',
      isUrban: false,
    },
    {
      name: 'PODGORIA',
      zip: '48691',
      isUrban: false,
    },
    {
      name: 'COTATCU',
      zip: '48708',
      isUrban: false,
    },
    {
      name: 'ORATIA',
      zip: '48717',
      isUrban: false,
    },
    {
      name: 'PLESESTI',
      zip: '48726',
      isUrban: false,
    },
    {
      name: 'TABACARI',
      zip: '48735',
      isUrban: false,
    },
    {
      name: 'POSTA CALNAU',
      zip: '48780',
      isUrban: false,
    },
    {
      name: 'ALICENI',
      zip: '48799',
      isUrban: false,
    },
    {
      name: 'COCONARI',
      zip: '48806',
      isUrban: false,
    },
    {
      name: 'POTARNICHESTI',
      zip: '48815',
      isUrban: false,
    },
    {
      name: 'SUDITI',
      zip: '48824',
      isUrban: false,
    },
    {
      name: 'ZILISTEANCA',
      zip: '48833',
      isUrban: false,
    },
    {
      name: 'PUIESTII DE JOS',
      zip: '48851',
      isUrban: false,
    },
    {
      name: 'DASCALESTI',
      zip: '48860',
      isUrban: false,
    },
    {
      name: 'LUNCA',
      zip: '48879',
      isUrban: false,
    },
    {
      name: 'MACRINA',
      zip: '48888',
      isUrban: false,
    },
    {
      name: 'NICOLESTI',
      zip: '48897',
      isUrban: false,
    },
    {
      name: 'PLOPI',
      zip: '48904',
      isUrban: false,
    },
    {
      name: 'PUIESTII DE SUS',
      zip: '48913',
      isUrban: false,
    },
    {
      name: 'RACOVITENI',
      zip: '48931',
      isUrban: false,
    },
    {
      name: 'BUDREA',
      zip: '48940',
      isUrban: false,
    },
    {
      name: 'PETRISORU',
      zip: '48959',
      isUrban: false,
    },
    {
      name: 'COLIBASI',
      zip: '48986',
      isUrban: false,
    },
    {
      name: 'FOTIN',
      zip: '48995',
      isUrban: false,
    },
    {
      name: 'STIUBEI',
      zip: '49000',
      isUrban: false,
    },
    {
      name: 'ROBEASCA',
      zip: '49028',
      isUrban: false,
    },
    {
      name: 'MOSESTI',
      zip: '49037',
      isUrban: false,
    },
    {
      name: 'RUSETU',
      zip: '49055',
      isUrban: false,
    },
    {
      name: 'SERGENT IONEL STEFAN',
      zip: '49064',
      isUrban: false,
    },
    {
      name: 'SAGEATA',
      zip: '49082',
      isUrban: false,
    },
    {
      name: 'BANITA',
      zip: '49091',
      isUrban: false,
    },
    {
      name: 'BEILIC',
      zip: '49108',
      isUrban: false,
    },
    {
      name: 'BORDUSANI',
      zip: '49117',
      isUrban: false,
    },
    {
      name: 'DAMBROCA',
      zip: '49126',
      isUrban: false,
    },
    {
      name: 'GAVANESTI',
      zip: '49135',
      isUrban: false,
    },
    {
      name: 'MOVILITA',
      zip: '49144',
      isUrban: false,
    },
    {
      name: 'SAHATENI',
      zip: '49162',
      isUrban: false,
    },
    {
      name: 'GAGENI',
      zip: '49171',
      isUrban: false,
    },
    {
      name: 'ISTRITA DE JOS',
      zip: '49180',
      isUrban: false,
    },
    {
      name: 'VINTILEANCA',
      zip: '49199',
      isUrban: false,
    },
    {
      name: 'SAPOCA',
      zip: '49215',
      isUrban: false,
    },
    {
      name: 'MATESTI',
      zip: '49224',
      isUrban: false,
    },
    {
      name: 'SARULESTI',
      zip: '49242',
      isUrban: false,
    },
    {
      name: 'CARATNAU DE JOS',
      zip: '49251',
      isUrban: false,
    },
    {
      name: 'CARATNAU DE SUS',
      zip: '49260',
      isUrban: false,
    },
    {
      name: 'GOICELU',
      zip: '49279',
      isUrban: false,
    },
    {
      name: 'SARILE-CATUN',
      zip: '49288',
      isUrban: false,
    },
    {
      name: 'VALEA LARGA-SARULESTI',
      zip: '49297',
      isUrban: false,
    },
    {
      name: 'VALEA STINEI',
      zip: '49304',
      isUrban: false,
    },
    {
      name: 'SCORTOASA',
      zip: '49322',
      isUrban: false,
    },
    {
      name: 'BALTA TOCILA',
      zip: '49331',
      isUrban: false,
    },
    {
      name: 'BECIU',
      zip: '49340',
      isUrban: false,
    },
    {
      name: 'DELENI',
      zip: '49359',
      isUrban: false,
    },
    {
      name: 'DALMA',
      zip: '49368',
      isUrban: false,
    },
    {
      name: 'GOLU GRABICINA',
      zip: '49377',
      isUrban: false,
    },
    {
      name: 'GRABICINA DE JOS',
      zip: '49386',
      isUrban: false,
    },
    {
      name: 'GRABICINA DE SUS',
      zip: '49395',
      isUrban: false,
    },
    {
      name: 'GURA VAII',
      zip: '49402',
      isUrban: false,
    },
    {
      name: 'PLOPEASA',
      zip: '49411',
      isUrban: false,
    },
    {
      name: 'POLICIORI',
      zip: '49420',
      isUrban: false,
    },
    {
      name: 'SCUTELNICI',
      zip: '49448',
      isUrban: false,
    },
    {
      name: 'ARCANU',
      zip: '49457',
      isUrban: false,
    },
    {
      name: 'BRAGAREASA',
      zip: '49466',
      isUrban: false,
    },
    {
      name: 'LIPANESCU',
      zip: '49475',
      isUrban: false,
    },
    {
      name: 'LUNCA JARISTEI',
      zip: '49493',
      isUrban: false,
    },
    {
      name: 'CASOCA',
      zip: '49518',
      isUrban: false,
    },
    {
      name: 'COLTU PIETRII',
      zip: '49509',
      isUrban: false,
    },
    {
      name: 'GURA SIRIULUI',
      zip: '49527',
      isUrban: false,
    },
    {
      name: 'MUSCELUSA',
      zip: '49536',
      isUrban: false,
    },
    {
      name: 'SMEENI',
      zip: '49554',
      isUrban: false,
    },
    {
      name: 'ALBESTI',
      zip: '49563',
      isUrban: false,
    },
    {
      name: 'BALAIA',
      zip: '49572',
      isUrban: false,
    },
    {
      name: 'CALTUNA',
      zip: '49581',
      isUrban: false,
    },
    {
      name: 'MOISICA',
      zip: '49590',
      isUrban: false,
    },
    {
      name: 'UDATI-LUCIENI',
      zip: '49607',
      isUrban: false,
    },
    {
      name: 'UDATI-MANZU',
      zip: '49616',
      isUrban: false,
    },
    {
      name: 'STALPU',
      zip: '49634',
      isUrban: false,
    },
    {
      name: 'TINTESTI',
      zip: '49858',
      isUrban: false,
    },
    {
      name: 'MAXENU',
      zip: '49867',
      isUrban: false,
    },
    {
      name: 'ODAIA BANULUI',
      zip: '49876',
      isUrban: false,
    },
    {
      name: 'POGONELE',
      zip: '49885',
      isUrban: false,
    },
    {
      name: 'IZVORU',
      zip: '49652',
      isUrban: false,
    },
    {
      name: 'BARBUNCESTI',
      zip: '49661',
      isUrban: false,
    },
    {
      name: 'GRAJDANA',
      zip: '49670',
      isUrban: false,
    },
    {
      name: 'HALES',
      zip: '49689',
      isUrban: false,
    },
    {
      name: 'IZVORANU',
      zip: '49698',
      isUrban: false,
    },
    {
      name: 'LEICULESTI',
      zip: '49705',
      isUrban: false,
    },
    {
      name: 'PADURENII',
      zip: '49714',
      isUrban: false,
    },
    {
      name: 'SALCIA',
      zip: '49723',
      isUrban: false,
    },
    {
      name: 'STREZENI',
      zip: '49732',
      isUrban: false,
    },
    {
      name: 'TISAU',
      zip: '49741',
      isUrban: false,
    },
    {
      name: 'VALEA SALCIILOR',
      zip: '49750',
      isUrban: false,
    },
    {
      name: 'TOPLICENI',
      zip: '49778',
      isUrban: false,
    },
    {
      name: 'BABENI',
      zip: '49787',
      isUrban: false,
    },
    {
      name: 'CEAIRU',
      zip: '49796',
      isUrban: false,
    },
    {
      name: 'DEDULESTI',
      zip: '49803',
      isUrban: false,
    },
    {
      name: 'GURA FAGETULUI',
      zip: '49812',
      isUrban: false,
    },
    {
      name: 'POSTA',
      zip: '49821',
      isUrban: false,
    },
    {
      name: 'RADUCESTI',
      zip: '49830',
      isUrban: false,
    },
    {
      name: 'ULMENI',
      zip: '49901',
      isUrban: false,
    },
    {
      name: 'BALTARETI',
      zip: '49910',
      isUrban: false,
    },
    {
      name: 'CLONDIRU',
      zip: '49929',
      isUrban: false,
    },
    {
      name: 'SARATA',
      zip: '49938',
      isUrban: false,
    },
    {
      name: 'VALCELE',
      zip: '49947',
      isUrban: false,
    },
    {
      name: 'VADU PASII',
      zip: '49965',
      isUrban: false,
    },
    {
      name: 'BAJANI',
      zip: '49974',
      isUrban: false,
    },
    {
      name: 'FOCSANEI',
      zip: '49983',
      isUrban: false,
    },
    {
      name: 'GURA CALNAULUI',
      zip: '49992',
      isUrban: false,
    },
    {
      name: 'SCURTESTI',
      zip: '50004',
      isUrban: false,
    },
    {
      name: 'STANCESTI',
      zip: '50013',
      isUrban: false,
    },
    {
      name: 'VALCELELE',
      zip: '50406',
      isUrban: false,
    },
    {
      name: 'VALEA RAMNICULUI',
      zip: '50031',
      isUrban: false,
    },
    {
      name: 'OREAVUL',
      zip: '50040',
      isUrban: false,
    },
    {
      name: 'RUBLA',
      zip: '50059',
      isUrban: false,
    },
    {
      name: 'VALEA SALCIEI',
      zip: '50077',
      isUrban: false,
    },
    {
      name: 'MODRENI',
      zip: '50086',
      isUrban: false,
    },
    {
      name: 'VALEA SALCIEI-CATUN',
      zip: '50095',
      isUrban: false,
    },
    {
      name: 'VERNESTI',
      zip: '50111',
      isUrban: false,
    },
    {
      name: 'BRADEANCA',
      zip: '50120',
      isUrban: false,
    },
    {
      name: 'CANDESTI',
      zip: '50139',
      isUrban: false,
    },
    {
      name: 'CARLOMANESTI',
      zip: '50148',
      isUrban: false,
    },
    {
      name: 'MIEREA',
      zip: '50157',
      isUrban: false,
    },
    {
      name: 'NENCIU',
      zip: '50166',
      isUrban: false,
    },
    {
      name: 'NISCOV',
      zip: '50175',
      isUrban: false,
    },
    {
      name: 'SASENII NOI',
      zip: '50184',
      isUrban: false,
    },
    {
      name: 'SASENII PE VALE',
      zip: '50193',
      isUrban: false,
    },
    {
      name: 'SASENII VECHI',
      zip: '50200',
      isUrban: false,
    },
    {
      name: 'ZORESTI',
      zip: '50219',
      isUrban: false,
    },
    {
      name: 'VINTILA VODA',
      zip: '50237',
      isUrban: false,
    },
    {
      name: 'BODINESTI',
      zip: '50246',
      isUrban: false,
    },
    {
      name: 'COCA-ANTIMIRESTI',
      zip: '50255',
      isUrban: false,
    },
    {
      name: 'COCA-NICULESTI',
      zip: '50264',
      isUrban: false,
    },
    {
      name: 'NICULESTI',
      zip: '50273',
      isUrban: false,
    },
    {
      name: 'PETRACHESTI',
      zip: '50282',
      isUrban: false,
    },
    {
      name: 'PODU MUNCII',
      zip: '50291',
      isUrban: false,
    },
    {
      name: 'SARBESTI',
      zip: '50308',
      isUrban: false,
    },
    {
      name: 'SMEESTI',
      zip: '50317',
      isUrban: false,
    },
    {
      name: 'VIPERESTI',
      zip: '50335',
      isUrban: false,
    },
    {
      name: 'MUSCEL',
      zip: '50344',
      isUrban: false,
    },
    {
      name: 'PALICI',
      zip: '50353',
      isUrban: false,
    },
    {
      name: 'RUSAVAT',
      zip: '50362',
      isUrban: false,
    },
    {
      name: 'TRONARI',
      zip: '50371',
      isUrban: false,
    },
    {
      name: 'URSOAIA',
      zip: '50380',
      isUrban: false,
    },
    {
      name: 'FUNDENI',
      zip: '50424',
      isUrban: false,
    },
    {
      name: 'COMISOAIA',
      zip: '50433',
      isUrban: false,
    },
    {
      name: 'PRUNENI',
      zip: '50442',
      isUrban: false,
    },
    {
      name: 'VADU SORESTI',
      zip: '50451',
      isUrban: false,
    },
    {
      name: 'ZARNESTI',
      zip: '50460',
      isUrban: false,
    },
    {
      name: 'ZIDURI',
      zip: '50488',
      isUrban: false,
    },
    {
      name: 'COSTIENI',
      zip: '50497',
      isUrban: false,
    },
    {
      name: 'CUCULEASA',
      zip: '50503',
      isUrban: false,
    },
    {
      name: 'HELIADE RADULESCU',
      zip: '50512',
      isUrban: false,
    },
    {
      name: 'LANURILE',
      zip: '50521',
      isUrban: false,
    },
    {
      name: 'ZOITA',
      zip: '50530',
      isUrban: false,
    },
    {
      name: 'BASCENII DE SUS',
      zip: '45780',
      isUrban: false,
    },
    {
      name: 'FRASINET',
      zip: '45799',
      isUrban: false,
    },
    {
      name: 'OLARI',
      zip: '45806',
      isUrban: false,
    },
    {
      name: 'CANESTI',
      zip: '45897',
      isUrban: false,
    },
    {
      name: 'RAMNICELU',
      zip: '48977',
      isUrban: false,
    },
    {
      name: 'OJASCA',
      zip: '50556',
      isUrban: false,
    }
  ],
  BN: [
    {
      name: 'BISTRITA',
      zip: '32401',
      isUrban: true,
    },
    {
      name: 'GHINDA',
      zip: '32410',
      isUrban: true,
    },
    {
      name: 'SARATA',
      zip: '32429',
      isUrban: true,
    },
    {
      name: 'SIGMIR',
      zip: '32438',
      isUrban: true,
    },
    {
      name: 'SLATINITA',
      zip: '32447',
      isUrban: true,
    },
    {
      name: 'UNIREA',
      zip: '32456',
      isUrban: true,
    },
    {
      name: 'VIISOARA',
      zip: '32465',
      isUrban: true,
    },
    {
      name: 'BECLEAN',
      zip: '32492',
      isUrban: true,
    },
    {
      name: 'COLDAU',
      zip: '32508',
      isUrban: true,
    },
    {
      name: 'FIGA',
      zip: '32517',
      isUrban: true,
    },
    {
      name: 'RUSU DE JOS',
      zip: '32526',
      isUrban: true,
    },
    {
      name: 'NASAUD',
      zip: '32553',
      isUrban: true,
    },
    {
      name: 'LIVIU REBREANU',
      zip: '32562',
      isUrban: true,
    },
    {
      name: 'LUSCA',
      zip: '32571',
      isUrban: true,
    },
    {
      name: 'SANGEORZ-BAI',
      zip: '32606',
      isUrban: true,
    },
    {
      name: 'CORMAIA',
      zip: '32615',
      isUrban: true,
    },
    {
      name: 'VALEA BORCUTULUI',
      zip: '32624',
      isUrban: true,
    },
    {
      name: 'BISTRITA BARGAULUI',
      zip: '32642',
      isUrban: false,
    },
    {
      name: 'COLIBITA',
      zip: '32651',
      isUrban: false,
    },
    {
      name: 'BRANISTEA',
      zip: '32679',
      isUrban: false,
    },
    {
      name: 'CIRESOAIA',
      zip: '32688',
      isUrban: false,
    },
    {
      name: 'MALUT',
      zip: '32697',
      isUrban: false,
    },
    {
      name: 'BUDACU DE JOS',
      zip: '32713',
      isUrban: false,
    },
    {
      name: 'BUDUS',
      zip: '32722',
      isUrban: false,
    },
    {
      name: 'JELNA',
      zip: '32731',
      isUrban: false,
    },
    {
      name: 'MONARIU',
      zip: '32740',
      isUrban: false,
    },
    {
      name: 'SIMIONESTI',
      zip: '32759',
      isUrban: false,
    },
    {
      name: 'BUDESTI',
      zip: '32777',
      isUrban: false,
    },
    {
      name: 'BUDESTI-FINATE',
      zip: '32786',
      isUrban: false,
    },
    {
      name: 'TAGSORU',
      zip: '32802',
      isUrban: false,
    },
    {
      name: 'TAGU',
      zip: '32795',
      isUrban: false,
    },
    {
      name: 'CAIANU MIC',
      zip: '32820',
      isUrban: false,
    },
    {
      name: 'CAIANU MARE',
      zip: '32839',
      isUrban: false,
    },
    {
      name: 'CICEU-POIENI',
      zip: '32848',
      isUrban: false,
    },
    {
      name: 'DOBRIC',
      zip: '32857',
      isUrban: false,
    },
    {
      name: 'DOBRICEL',
      zip: '32866',
      isUrban: false,
    },
    {
      name: 'DUMBRAVITA',
      zip: '32875',
      isUrban: false,
    },
    {
      name: 'SATU NOU',
      zip: '32893',
      isUrban: false,
    },
    {
      name: 'BUDACU DE SUS',
      zip: '32900',
      isUrban: false,
    },
    {
      name: 'DUMITRITA',
      zip: '32919',
      isUrban: false,
    },
    {
      name: 'ORHEIU BISTRITEI',
      zip: '32928',
      isUrban: false,
    },
    {
      name: 'PETRIS',
      zip: '32937',
      isUrban: false,
    },
    {
      name: 'RAGLA',
      zip: '32946',
      isUrban: false,
    },
    {
      name: 'CHIOCHIS',
      zip: '33024',
      isUrban: false,
    },
    {
      name: 'APATIU',
      zip: '33033',
      isUrban: false,
    },
    {
      name: 'BOZIES',
      zip: '33042',
      isUrban: false,
    },
    {
      name: 'BUZA CATUN',
      zip: '33051',
      isUrban: false,
    },
    {
      name: 'CHETIU',
      zip: '33060',
      isUrban: false,
    },
    {
      name: 'JIMBOR',
      zip: '33079',
      isUrban: false,
    },
    {
      name: 'MANIC',
      zip: '33088',
      isUrban: false,
    },
    {
      name: 'SANNICOARA',
      zip: '33097',
      isUrban: false,
    },
    {
      name: 'STRUGURENI',
      zip: '33104',
      isUrban: false,
    },
    {
      name: 'TENTEA',
      zip: '33113',
      isUrban: false,
    },
    {
      name: 'CHIUZA',
      zip: '33131',
      isUrban: false,
    },
    {
      name: 'MIRES',
      zip: '33140',
      isUrban: false,
    },
    {
      name: 'PIATRA',
      zip: '33159',
      isUrban: false,
    },
    {
      name: 'SASARM',
      zip: '33168',
      isUrban: false,
    },
    {
      name: 'CICEU-GIURGESTI',
      zip: '32964',
      isUrban: false,
    },
    {
      name: 'BREAZA',
      zip: '32973',
      isUrban: false,
    },
    {
      name: 'DUMBRAVENI',
      zip: '32982',
      isUrban: false,
    },
    {
      name: 'NEGRILESTI',
      zip: '32991',
      isUrban: false,
    },
    {
      name: 'PURCARETE',
      zip: '33006',
      isUrban: false,
    },
    {
      name: 'COSBUC',
      zip: '33186',
      isUrban: false,
    },
    {
      name: 'BICHIGIU',
      zip: '33195',
      isUrban: false,
    },
    {
      name: 'DUMITRA',
      zip: '33211',
      isUrban: false,
    },
    {
      name: 'CEPARI',
      zip: '33220',
      isUrban: false,
    },
    {
      name: 'TARPIU',
      zip: '33239',
      isUrban: false,
    },
    {
      name: 'FELDRU',
      zip: '33257',
      isUrban: false,
    },
    {
      name: 'NEPOS',
      zip: '33266',
      isUrban: false,
    },
    {
      name: 'GALATII BISTRITEI',
      zip: '33284',
      isUrban: false,
    },
    {
      name: 'ALBESTII BISTRITEI',
      zip: '33293',
      isUrban: false,
    },
    {
      name: 'DIPSA',
      zip: '33300',
      isUrban: false,
    },
    {
      name: 'HERINA',
      zip: '33319',
      isUrban: false,
    },
    {
      name: 'TONCIU',
      zip: '33328',
      isUrban: false,
    },
    {
      name: 'ILVA MARE',
      zip: '33346',
      isUrban: false,
    },
    {
      name: 'IVANEASA',
      zip: '33355',
      isUrban: false,
    },
    {
      name: 'ILVA MICA',
      zip: '33373',
      isUrban: false,
    },
    {
      name: 'JOSENII BARGAULUI',
      zip: '33391',
      isUrban: false,
    },
    {
      name: 'MIJLOCENII BARGAULUI',
      zip: '33408',
      isUrban: false,
    },
    {
      name: 'RUSU BARGAULUI',
      zip: '33417',
      isUrban: false,
    },
    {
      name: 'STRAMBA',
      zip: '33426',
      isUrban: false,
    },
    {
      name: 'LECHINTA',
      zip: '33444',
      isUrban: false,
    },
    {
      name: 'BUNGARD',
      zip: '33453',
      isUrban: false,
    },
    {
      name: 'CHIRALES',
      zip: '33462',
      isUrban: false,
    },
    {
      name: 'SANGEORZU NOU',
      zip: '33471',
      isUrban: false,
    },
    {
      name: 'SANIACOB',
      zip: '33480',
      isUrban: false,
    },
    {
      name: 'TIGAU',
      zip: '33499',
      isUrban: false,
    },
    {
      name: 'VERMES',
      zip: '33505',
      isUrban: false,
    },
    {
      name: 'LESU',
      zip: '33523',
      isUrban: false,
    },
    {
      name: 'LUNCA LESULUI',
      zip: '33532',
      isUrban: false,
    },
    {
      name: 'LIVEZILE',
      zip: '33550',
      isUrban: false,
    },
    {
      name: 'CUSMA',
      zip: '33569',
      isUrban: false,
    },
    {
      name: 'DOROLEA',
      zip: '33578',
      isUrban: false,
    },
    {
      name: 'DUMBRAVA',
      zip: '33587',
      isUrban: false,
    },
    {
      name: 'VALEA POENII',
      zip: '33596',
      isUrban: false,
    },
    {
      name: 'LUNCA ILVEI',
      zip: '33612',
      isUrban: false,
    },
    {
      name: 'MAGURA ILVEI',
      zip: '33738',
      isUrban: false,
    },
    {
      name: 'ARSITA',
      zip: '33747',
      isUrban: false,
    },
    {
      name: 'POIANA ILVEI',
      zip: '33756',
      isUrban: false,
    },
    {
      name: 'MAIERU',
      zip: '33630',
      isUrban: false,
    },
    {
      name: 'ANIES',
      zip: '33649',
      isUrban: false,
    },
    {
      name: 'MARISELU',
      zip: '33774',
      isUrban: false,
    },
    {
      name: 'BARLA',
      zip: '33783',
      isUrban: false,
    },
    {
      name: 'DOMNESTI',
      zip: '33792',
      isUrban: false,
    },
    {
      name: 'JEICA',
      zip: '33809',
      isUrban: false,
    },
    {
      name: 'MAGURELE',
      zip: '33818',
      isUrban: false,
    },
    {
      name: 'NETENI',
      zip: '33827',
      isUrban: false,
    },
    {
      name: 'SANTIOANA',
      zip: '33836',
      isUrban: false,
    },
    {
      name: 'MATEI',
      zip: '33667',
      isUrban: false,
    },
    {
      name: 'BIDIU',
      zip: '33676',
      isUrban: false,
    },
    {
      name: 'CORVINESTI',
      zip: '33685',
      isUrban: false,
    },
    {
      name: 'ENCIU',
      zip: '33694',
      isUrban: false,
    },
    {
      name: 'FANTANELE',
      zip: '33701',
      isUrban: false,
    },
    {
      name: 'MORUT',
      zip: '33710',
      isUrban: false,
    },
    {
      name: 'MICESTII DE CAMPIE',
      zip: '33854',
      isUrban: false,
    },
    {
      name: 'FANTANITA',
      zip: '33863',
      isUrban: false,
    },
    {
      name: 'VISUIA',
      zip: '33872',
      isUrban: false,
    },
    {
      name: 'MILAS',
      zip: '33890',
      isUrban: false,
    },
    {
      name: 'COMLOD',
      zip: '33907',
      isUrban: false,
    },
    {
      name: 'DUPA DEAL',
      zip: '33916',
      isUrban: false,
    },
    {
      name: 'GHEMES',
      zip: '33925',
      isUrban: false,
    },
    {
      name: 'HIREAN',
      zip: '33934',
      isUrban: false,
    },
    {
      name: 'OROSFAIA',
      zip: '33943',
      isUrban: false,
    },
    {
      name: 'MONOR',
      zip: '33961',
      isUrban: false,
    },
    {
      name: 'GLEDIN',
      zip: '33970',
      isUrban: false,
    },
    {
      name: 'NIMIGEA DE JOS',
      zip: '33998',
      isUrban: false,
    },
    {
      name: 'FLORESTI',
      zip: '34002',
      isUrban: false,
    },
    {
      name: 'MINTIU',
      zip: '34011',
      isUrban: false,
    },
    {
      name: 'MITITEI',
      zip: '34020',
      isUrban: false,
    },
    {
      name: 'MOCOD',
      zip: '34039',
      isUrban: false,
    },
    {
      name: 'MOGOSENI',
      zip: '34048',
      isUrban: false,
    },
    {
      name: 'NIMIGEA DE SUS',
      zip: '34057',
      isUrban: false,
    },
    {
      name: 'TAURE',
      zip: '34066',
      isUrban: false,
    },
    {
      name: 'NUSENI',
      zip: '34084',
      isUrban: false,
    },
    {
      name: 'BEUDIU',
      zip: '34093',
      isUrban: false,
    },
    {
      name: 'DUMBRAVA',
      zip: '34100',
      isUrban: false,
    },
    {
      name: 'FELEAC',
      zip: '34119',
      isUrban: false,
    },
    {
      name: 'MALIN',
      zip: '34128',
      isUrban: false,
    },
    {
      name: 'RUSU DE SUS',
      zip: '34137',
      isUrban: false,
    },
    {
      name: 'VITA',
      zip: '34146',
      isUrban: false,
    },
    {
      name: 'PARVA',
      zip: '34164',
      isUrban: false,
    },
    {
      name: 'RETEAG',
      zip: '34182',
      isUrban: false,
    },
    {
      name: 'BATA',
      zip: '34191',
      isUrban: false,
    },
    {
      name: 'CICEU-CORABIA',
      zip: '34208',
      isUrban: false,
    },
    {
      name: 'CICEU-MIHAIESTI',
      zip: '34217',
      isUrban: false,
    },
    {
      name: 'LELESTI',
      zip: '34226',
      isUrban: false,
    },
    {
      name: 'PRUNDU BARGAULUI',
      zip: '34244',
      isUrban: false,
    },
    {
      name: 'SUSENII BARGAULUI',
      zip: '34253',
      isUrban: false,
    },
    {
      name: 'REBRA',
      zip: '34271',
      isUrban: false,
    },
    {
      name: 'REBRISOARA',
      zip: '34299',
      isUrban: false,
    },
    {
      name: 'GERSA I',
      zip: '34306',
      isUrban: false,
    },
    {
      name: 'GERSA II',
      zip: '34315',
      isUrban: false,
    },
    {
      name: 'PODEREI',
      zip: '34324',
      isUrban: false,
    },
    {
      name: 'RODNA',
      zip: '34342',
      isUrban: false,
    },
    {
      name: 'VALEA VINULUI',
      zip: '34351',
      isUrban: false,
    },
    {
      name: 'ROMULI',
      zip: '34379',
      isUrban: false,
    },
    {
      name: 'DEALU STEFANITEI',
      zip: '34388',
      isUrban: false,
    },
    {
      name: 'SALVA',
      zip: '34404',
      isUrban: false,
    },
    {
      name: 'RUNCU SALVEI',
      zip: '34413',
      isUrban: false,
    },
    {
      name: 'SANMIHAIU DE CAMPIE',
      zip: '34486',
      isUrban: false,
    },
    {
      name: 'BRATENI',
      zip: '34495',
      isUrban: false,
    },
    {
      name: 'LA CURTE',
      zip: '34501',
      isUrban: false,
    },
    {
      name: 'SALCUTA',
      zip: '34510',
      isUrban: false,
    },
    {
      name: 'STUPINI',
      zip: '34529',
      isUrban: false,
    },
    {
      name: 'ZORENI',
      zip: '34538',
      isUrban: false,
    },
    {
      name: 'SANT',
      zip: '34627',
      isUrban: false,
    },
    {
      name: 'VALEA MARE',
      zip: '34636',
      isUrban: false,
    },
    {
      name: 'SIEU',
      zip: '34654',
      isUrban: false,
    },
    {
      name: 'ARDAN',
      zip: '34663',
      isUrban: false,
    },
    {
      name: 'POSMUS',
      zip: '34672',
      isUrban: false,
    },
    {
      name: 'SOIMUS',
      zip: '34681',
      isUrban: false,
    },
    {
      name: 'SIEU-MAGHERUS',
      zip: '34707',
      isUrban: false,
    },
    {
      name: 'ARCALIA',
      zip: '34716',
      isUrban: false,
    },
    {
      name: 'CHINTELNIC',
      zip: '34725',
      isUrban: false,
    },
    {
      name: 'CRAINIMAT',
      zip: '34734',
      isUrban: false,
    },
    {
      name: 'PODIREI',
      zip: '34743',
      isUrban: false,
    },
    {
      name: 'SARATEL',
      zip: '34752',
      isUrban: false,
    },
    {
      name: 'VALEA MAGHERUSULUI',
      zip: '34761',
      isUrban: false,
    },
    {
      name: 'SIEU-ODORHEI',
      zip: '34789',
      isUrban: false,
    },
    {
      name: 'AGRISU DE JOS',
      zip: '34798',
      isUrban: false,
    },
    {
      name: 'AGRISU DE SUS',
      zip: '34805',
      isUrban: false,
    },
    {
      name: 'BRETEA',
      zip: '34814',
      isUrban: false,
    },
    {
      name: 'COASTA',
      zip: '34823',
      isUrban: false,
    },
    {
      name: 'CRISTUR-SIEU',
      zip: '34832',
      isUrban: false,
    },
    {
      name: 'SIRIOARA',
      zip: '34841',
      isUrban: false,
    },
    {
      name: 'SIEUT',
      zip: '34869',
      isUrban: false,
    },
    {
      name: 'LUNCA',
      zip: '34878',
      isUrban: false,
    },
    {
      name: 'RUSTIOR',
      zip: '34887',
      isUrban: false,
    },
    {
      name: 'SEBIS',
      zip: '34896',
      isUrban: false,
    },
    {
      name: 'SILIVASU DE CAMPIE',
      zip: '34431',
      isUrban: false,
    },
    {
      name: 'DRAGA',
      zip: '34440',
      isUrban: false,
    },
    {
      name: 'FANATELE SILIVASULUI',
      zip: '34459',
      isUrban: false,
    },
    {
      name: 'PORUMBENII',
      zip: '34468',
      isUrban: false,
    },
    {
      name: 'SINTEREAG',
      zip: '34912',
      isUrban: false,
    },
    {
      name: 'BLAJENII DE JOS',
      zip: '34921',
      isUrban: false,
    },
    {
      name: 'BLAJENII DE SUS',
      zip: '34930',
      isUrban: false,
    },
    {
      name: 'CAILA',
      zip: '34949',
      isUrban: false,
    },
    {
      name: 'COCIU',
      zip: '34958',
      isUrban: false,
    },
    {
      name: 'SIEU-SFANTU',
      zip: '34967',
      isUrban: false,
    },
    {
      name: 'SINTEREAG-GARA',
      zip: '34976',
      isUrban: false,
    },
    {
      name: 'SPERMEZEU',
      zip: '34556',
      isUrban: false,
    },
    {
      name: 'HALMASAU',
      zip: '34565',
      isUrban: false,
    },
    {
      name: 'LUNCA BORLESEI',
      zip: '34574',
      isUrban: false,
    },
    {
      name: 'PALTINEASA',
      zip: '34583',
      isUrban: false,
    },
    {
      name: 'SESURI SPERMEZEU-VALE',
      zip: '34609',
      isUrban: false,
    },
    {
      name: 'SITA',
      zip: '34592',
      isUrban: false,
    },
    {
      name: 'TARLISUA',
      zip: '35161',
      isUrban: false,
    },
    {
      name: 'AGRIES',
      zip: '35170',
      isUrban: false,
    },
    {
      name: 'AGRIESEL',
      zip: '35189',
      isUrban: false,
    },
    {
      name: 'BORLEASA',
      zip: '35198',
      isUrban: false,
    },
    {
      name: 'CIREASI',
      zip: '35205',
      isUrban: false,
    },
    {
      name: 'LUNCA SATEASCA',
      zip: '35214',
      isUrban: false,
    },
    {
      name: 'MOLISET',
      zip: '35223',
      isUrban: false,
    },
    {
      name: 'OARZINA',
      zip: '35232',
      isUrban: false,
    },
    {
      name: 'RACATESU',
      zip: '35241',
      isUrban: false,
    },
    {
      name: 'SENDROAIA',
      zip: '35250',
      isUrban: false,
    },
    {
      name: 'TEACA',
      zip: '34994',
      isUrban: false,
    },
    {
      name: 'ARCHIUD',
      zip: '35009',
      isUrban: false,
    },
    {
      name: 'BUDURLENI',
      zip: '35018',
      isUrban: false,
    },
    {
      name: 'OCNITA',
      zip: '35027',
      isUrban: false,
    },
    {
      name: 'PINTICU',
      zip: '35036',
      isUrban: false,
    },
    {
      name: 'VIILE TECII',
      zip: '35045',
      isUrban: false,
    },
    {
      name: 'FIAD',
      zip: '35072',
      isUrban: false,
    },
    {
      name: 'TELCISOR',
      zip: '35081',
      isUrban: false,
    },
    {
      name: 'TIHA BARGAULUI',
      zip: '35107',
      isUrban: false,
    },
    {
      name: 'CIOSA',
      zip: '35116',
      isUrban: false,
    },
    {
      name: 'MURESENII BARGAULUI',
      zip: '35125',
      isUrban: false,
    },
    {
      name: 'PIATRA FANTANELE',
      zip: '35134',
      isUrban: false,
    },
    {
      name: 'TUREAC',
      zip: '35143',
      isUrban: false,
    },
    {
      name: 'URIU',
      zip: '35278',
      isUrban: false,
    },
    {
      name: 'SCOABE',
      zip: '35385',
      isUrban: false,
    },
    {
      name: 'SOPTERIU',
      zip: '35394',
      isUrban: false,
    },
    {
      name: 'VALEA',
      zip: '35401',
      isUrban: false,
    },
    {
      name: 'VALEA MARE',
      zip: '35410',
      isUrban: false,
    },
    {
      name: 'ZAGRA',
      zip: '35438',
      isUrban: false,
    },
    {
      name: 'ALUNISUL',
      zip: '35447',
      isUrban: false,
    },
    {
      name: 'PERISOR',
      zip: '35456',
      isUrban: false,
    },
    {
      name: 'POIENILE ZAGREI',
      zip: '35465',
      isUrban: false,
    },
    {
      name: 'SUPLAI',
      zip: '35474',
      isUrban: false,
    },
    {
      name: 'TELCIU',
      zip: '35063',
      isUrban: false,
    },
    {
      name: 'CRISTESTII CICEULUI',
      zip: '35287',
      isUrban: false,
    },
    {
      name: 'HASMASU CICEULUI',
      zip: '35296',
      isUrban: false,
    },
    {
      name: 'ILISUA',
      zip: '35303',
      isUrban: false,
    },
    {
      name: 'URMENIS',
      zip: '35321',
      isUrban: false,
    },
    {
      name: 'CAMP',
      zip: '35330',
      isUrban: false,
    },
    {
      name: 'COSERIU',
      zip: '35349',
      isUrban: false,
    },
    {
      name: 'DELURENI',
      zip: '35358',
      isUrban: false,
    },
    {
      name: 'FANATE',
      zip: '35367',
      isUrban: false,
    },
    {
      name: 'PODENII',
      zip: '35376',
      isUrban: false,
    }
  ],
  BT: [
    {
      name: 'BOTOSANI',
      zip: '35740',
      isUrban: true,
    },
    {
      name: 'DOROHOI',
      zip: '36015',
      isUrban: true,
    },
    {
      name: 'DEALU MARE',
      zip: '36024',
      isUrban: true,
    },
    {
      name: 'LOTURI ENESCU',
      zip: '36033',
      isUrban: true,
    },
    {
      name: 'PROGRESUL',
      zip: '36042',
      isUrban: true,
    },
    {
      name: 'DARABANI',
      zip: '35955',
      isUrban: true,
    },
    {
      name: 'BAJURA',
      zip: '35964',
      isUrban: true,
    },
    {
      name: 'ESANCA',
      zip: '35973',
      isUrban: true,
    },
    {
      name: 'LISMANITA',
      zip: '35982',
      isUrban: true,
    },
    {
      name: 'SAVENI',
      zip: '36079',
      isUrban: true,
    },
    {
      name: 'BODEASA',
      zip: '36088',
      isUrban: true,
    },
    {
      name: 'BOZIENI',
      zip: '36097',
      isUrban: true,
    },
    {
      name: 'CHISCARENI',
      zip: '36104',
      isUrban: true,
    },
    {
      name: 'PETRICANI',
      zip: '36113',
      isUrban: true,
    },
    {
      name: 'SAT NOU',
      zip: '36122',
      isUrban: true,
    },
    {
      name: 'ALBESTI',
      zip: '36140',
      isUrban: false,
    },
    {
      name: 'BUIMACENI',
      zip: '36159',
      isUrban: false,
    },
    {
      name: 'COSTIUGENI',
      zip: '36168',
      isUrban: false,
    },
    {
      name: 'JIJIA',
      zip: '36177',
      isUrban: false,
    },
    {
      name: 'MASCATENI',
      zip: '36186',
      isUrban: false,
    },
    {
      name: 'TUDOR VLADIMIRESCU',
      zip: '36195',
      isUrban: false,
    },
    {
      name: 'AVRAMENI',
      zip: '36211',
      isUrban: false,
    },
    {
      name: 'ADASENI',
      zip: '36220',
      isUrban: false,
    },
    {
      name: 'AUREL VLAICU',
      zip: '36239',
      isUrban: false,
    },
    {
      name: 'DIMITRIE CANTEMIR',
      zip: '36248',
      isUrban: false,
    },
    {
      name: 'ICHIMENI',
      zip: '36257',
      isUrban: false,
    },
    {
      name: 'PANAITOAIA',
      zip: '36266',
      isUrban: false,
    },
    {
      name: 'TIMUS',
      zip: '36275',
      isUrban: false,
    },
    {
      name: 'TUDOR VLADIMIRESCU',
      zip: '36284',
      isUrban: false,
    },
    {
      name: 'ZOITANI',
      zip: '36293',
      isUrban: false,
    },
    {
      name: 'BALUSENI',
      zip: '36319',
      isUrban: false,
    },
    {
      name: 'BALUSENII NOI',
      zip: '36328',
      isUrban: false,
    },
    {
      name: 'BUZENI',
      zip: '36337',
      isUrban: false,
    },
    {
      name: 'COSULENI',
      zip: '36346',
      isUrban: false,
    },
    {
      name: 'DRAXINI',
      zip: '36355',
      isUrban: false,
    },
    {
      name: 'ZAICESTI',
      zip: '36364',
      isUrban: false,
    },
    {
      name: 'BRAESTI',
      zip: '36382',
      isUrban: false,
    },
    {
      name: 'POIANA',
      zip: '36391',
      isUrban: false,
    },
    {
      name: 'POPENI',
      zip: '36408',
      isUrban: false,
    },
    {
      name: 'VILCELELE',
      zip: '36417',
      isUrban: false,
    },
    {
      name: 'BROSCAUTI',
      zip: '36435',
      isUrban: false,
    },
    {
      name: 'SLOBOZIA',
      zip: '36444',
      isUrban: false,
    },
    {
      name: 'BUCECEA',
      zip: '36462',
      isUrban: true,
    },
    {
      name: 'BOHOGHINA',
      zip: '36471',
      isUrban: true,
    },
    {
      name: 'CALINESTI',
      zip: '36480',
      isUrban: true,
    },
    {
      name: 'CALARASI',
      zip: '36505',
      isUrban: false,
    },
    {
      name: 'LIBERTATEA',
      zip: '36514',
      isUrban: false,
    },
    {
      name: 'PLESANI',
      zip: '36523',
      isUrban: false,
    },
    {
      name: 'CONCESTI',
      zip: '36541',
      isUrban: false,
    },
    {
      name: 'MOVILENI',
      zip: '36550',
      isUrban: false,
    },
    {
      name: 'COPALAU',
      zip: '36578',
      isUrban: false,
    },
    {
      name: 'BUDA',
      zip: '36587',
      isUrban: false,
    },
    {
      name: 'CERBU',
      zip: '36596',
      isUrban: false,
    },
    {
      name: 'COSULA',
      zip: '36603',
      isUrban: false,
    },
    {
      name: 'COTU',
      zip: '36612',
      isUrban: false,
    },
    {
      name: 'PADURENI',
      zip: '36621',
      isUrban: false,
    },
    {
      name: 'SUPITCA',
      zip: '36630',
      isUrban: false,
    },
    {
      name: 'CORDARENI',
      zip: '36658',
      isUrban: false,
    },
    {
      name: 'GRIVITA',
      zip: '36667',
      isUrban: false,
    },
    {
      name: 'CORLATENI',
      zip: '36685',
      isUrban: false,
    },
    {
      name: 'CARASA',
      zip: '36694',
      isUrban: false,
    },
    {
      name: 'DIMACHENI',
      zip: '36701',
      isUrban: false,
    },
    {
      name: 'MATEIENI',
      zip: '36710',
      isUrban: false,
    },
    {
      name: 'PODENI',
      zip: '36729',
      isUrban: false,
    },
    {
      name: 'RECIA-VERBIA',
      zip: '36738',
      isUrban: false,
    },
    {
      name: 'VLADENI',
      zip: '36747',
      isUrban: false,
    },
    {
      name: 'CORNI',
      zip: '36765',
      isUrban: false,
    },
    {
      name: 'BALTA ARSA',
      zip: '36774',
      isUrban: false,
    },
    {
      name: 'MESTEACAN',
      zip: '36783',
      isUrban: false,
    },
    {
      name: 'SARAFINESTI',
      zip: '36792',
      isUrban: false,
    },
    {
      name: 'COTUSCA',
      zip: '36818',
      isUrban: false,
    },
    {
      name: 'AVRAM IANCU',
      zip: '36827',
      isUrban: false,
    },
    {
      name: 'COTU MICULINTI',
      zip: '36836',
      isUrban: false,
    },
    {
      name: 'CRASNALEUCA',
      zip: '36845',
      isUrban: false,
    },
    {
      name: 'GHIRENI',
      zip: '36854',
      isUrban: false,
    },
    {
      name: 'MIHAIL KOGALNICEANU',
      zip: '36863',
      isUrban: false,
    },
    {
      name: 'NICHITENI',
      zip: '36872',
      isUrban: false,
    },
    {
      name: 'NICOLAE BALCESCU',
      zip: '36881',
      isUrban: false,
    },
    {
      name: 'PUTURENI',
      zip: '36890',
      isUrban: false,
    },
    {
      name: 'CRISTESTI',
      zip: '36916',
      isUrban: false,
    },
    {
      name: 'ONEAGA',
      zip: '36925',
      isUrban: false,
    },
    {
      name: 'SCHIT-ORASENI',
      zip: '36934',
      isUrban: false,
    },
    {
      name: 'UNGUROAIA',
      zip: '36943',
      isUrban: false,
    },
    {
      name: 'CRISTINESTI',
      zip: '36961',
      isUrban: false,
    },
    {
      name: 'BARANCA',
      zip: '36970',
      isUrban: false,
    },
    {
      name: 'DRAGALINA',
      zip: '36989',
      isUrban: false,
    },
    {
      name: 'FUNDU HERTII',
      zip: '36998',
      isUrban: false,
    },
    {
      name: 'POIANA',
      zip: '37002',
      isUrban: false,
    },
    {
      name: 'CURTESTI',
      zip: '35768',
      isUrban: false,
    },
    {
      name: 'AGAFTON',
      zip: '35777',
      isUrban: false,
    },
    {
      name: 'BAICENI',
      zip: '35786',
      isUrban: false,
    },
    {
      name: 'HUDUM',
      zip: '35795',
      isUrban: false,
    },
    {
      name: 'MANASTIREA DOAMNEI',
      zip: '35802',
      isUrban: false,
    },
    {
      name: 'ORASENI-DEAL',
      zip: '35811',
      isUrban: false,
    },
    {
      name: 'ORASENI-VALE',
      zip: '35820',
      isUrban: false,
    },
    {
      name: 'DANGENI',
      zip: '37066',
      isUrban: false,
    },
    {
      name: 'HULUB',
      zip: '37075',
      isUrban: false,
    },
    {
      name: 'IACOBENI',
      zip: '37084',
      isUrban: false,
    },
    {
      name: 'STRAHOTIN',
      zip: '37093',
      isUrban: false,
    },
    {
      name: 'DERSCA',
      zip: '37020',
      isUrban: false,
    },
    {
      name: 'LOZNA',
      zip: '37039',
      isUrban: false,
    },
    {
      name: 'STRATENI',
      zip: '37048',
      isUrban: false,
    },
    {
      name: 'DOBARCENI',
      zip: '37119',
      isUrban: false,
    },
    {
      name: 'BIVOLARI',
      zip: '37128',
      isUrban: false,
    },
    {
      name: 'BRATENI',
      zip: '37164',
      isUrban: false,
    },
    {
      name: 'CISMANESTI',
      zip: '37137',
      isUrban: false,
    },
    {
      name: 'LIVADA',
      zip: '37146',
      isUrban: false,
    },
    {
      name: 'MURGUTA',
      zip: '37155',
      isUrban: false,
    },
    {
      name: 'DRAGUSENI',
      zip: '37182',
      isUrban: false,
    },
    {
      name: 'PODRIGA',
      zip: '37191',
      isUrban: false,
    },
    {
      name: 'SARATA-DRAGUSENI',
      zip: '37208',
      isUrban: false,
    },
    {
      name: 'DURNESTI',
      zip: '37226',
      isUrban: false,
    },
    {
      name: 'BABICENI',
      zip: '37235',
      isUrban: false,
    },
    {
      name: 'BARSANESTI',
      zip: '37244',
      isUrban: false,
    },
    {
      name: 'BROSTENI',
      zip: '37253',
      isUrban: false,
    },
    {
      name: 'CUCUTENI',
      zip: '37262',
      isUrban: false,
    },
    {
      name: 'GURANDA',
      zip: '37271',
      isUrban: false,
    },
    {
      name: 'FLAMANZI',
      zip: '37299',
      isUrban: true,
    },
    {
      name: 'CHITOVENI',
      zip: '38438',
      isUrban: true,
    },
    {
      name: 'NICOLAE BALCESCU',
      zip: '38410',
      isUrban: true,
    },
    {
      name: 'POIANA',
      zip: '37315',
      isUrban: true,
    },
    {
      name: 'PRISACANI',
      zip: '38447',
      isUrban: true,
    },
    {
      name: 'FRUMUSICA',
      zip: '37333',
      isUrban: false,
    },
    {
      name: 'BOSCOTENI',
      zip: '37342',
      isUrban: false,
    },
    {
      name: 'RADENI',
      zip: '37351',
      isUrban: false,
    },
    {
      name: 'SENDRENI',
      zip: '37379',
      isUrban: false,
    },
    {
      name: 'STORESTI',
      zip: '37360',
      isUrban: false,
    },
    {
      name: 'VLADENI-DEAL',
      zip: '37388',
      isUrban: false,
    },
    {
      name: 'DUMENI',
      zip: '37404',
      isUrban: false,
    },
    {
      name: 'ARBOREA',
      zip: '37413',
      isUrban: false,
    },
    {
      name: 'GEORGE ENESCU',
      zip: '37422',
      isUrban: false,
    },
    {
      name: 'STANCA',
      zip: '37440',
      isUrban: false,
    },
    {
      name: 'GORBANESTI',
      zip: '37468',
      isUrban: false,
    },
    {
      name: 'BATRANESTI',
      zip: '37477',
      isUrban: false,
    },
    {
      name: 'GEORGE COSBUC',
      zip: '37486',
      isUrban: false,
    },
    {
      name: 'MIHAI EMINESCU',
      zip: '37495',
      isUrban: false,
    },
    {
      name: 'SILISCANI',
      zip: '37501',
      isUrban: false,
    },
    {
      name: 'SOCRUJENI',
      zip: '37510',
      isUrban: false,
    },
    {
      name: 'VANATORI',
      zip: '37538',
      isUrban: false,
    },
    {
      name: 'VIFORENI',
      zip: '37529',
      isUrban: false,
    },
    {
      name: 'HANESTI',
      zip: '37627',
      isUrban: false,
    },
    {
      name: 'BOROLEA',
      zip: '37636',
      isUrban: false,
    },
    {
      name: 'MOARA JORII',
      zip: '37645',
      isUrban: false,
    },
    {
      name: 'SARATA-BASARAB',
      zip: '37654',
      isUrban: false,
    },
    {
      name: 'SLOBOZIA HANESTI',
      zip: '37663',
      isUrban: false,
    },
    {
      name: 'HAVARNA',
      zip: '37556',
      isUrban: false,
    },
    {
      name: 'BALINTI',
      zip: '37565',
      isUrban: false,
    },
    {
      name: 'GALBENI',
      zip: '37574',
      isUrban: false,
    },
    {
      name: 'GARBENI',
      zip: '37583',
      isUrban: false,
    },
    {
      name: 'NICULCEA',
      zip: '37592',
      isUrban: false,
    },
    {
      name: 'TATARASENI',
      zip: '37609',
      isUrban: false,
    },
    {
      name: 'HILISEU-HORIA',
      zip: '37681',
      isUrban: false,
    },
    {
      name: 'CORJAUTI',
      zip: '37690',
      isUrban: false,
    },
    {
      name: 'HILISEU-CLOSCA',
      zip: '37707',
      isUrban: false,
    },
    {
      name: 'HILISEU-CRISAN',
      zip: '37716',
      isUrban: false,
    },
    {
      name: 'IEZER',
      zip: '37725',
      isUrban: false,
    },
    {
      name: 'HLIPICENI',
      zip: '37743',
      isUrban: false,
    },
    {
      name: 'DRAGALINA',
      zip: '37752',
      isUrban: false,
    },
    {
      name: 'VICTORIA',
      zip: '37761',
      isUrban: false,
    },
    {
      name: 'HUDESTI',
      zip: '37789',
      isUrban: false,
    },
    {
      name: 'ALBA',
      zip: '37798',
      isUrban: false,
    },
    {
      name: 'BARANCA',
      zip: '37805',
      isUrban: false,
    },
    {
      name: 'MLENAUTI',
      zip: '37814',
      isUrban: false,
    },
    {
      name: 'IBANESTI',
      zip: '37832',
      isUrban: false,
    },
    {
      name: 'DUMBRAVITA',
      zip: '37841',
      isUrban: false,
    },
    {
      name: 'LEORDA',
      zip: '37869',
      isUrban: false,
    },
    {
      name: 'BELCEA',
      zip: '37878',
      isUrban: false,
    },
    {
      name: 'COSTINESTI',
      zip: '37887',
      isUrban: false,
    },
    {
      name: 'DOLINA',
      zip: '37896',
      isUrban: false,
    },
    {
      name: 'MITOC',
      zip: '37903',
      isUrban: false,
    },
    {
      name: 'LUNCA',
      zip: '37921',
      isUrban: false,
    },
    {
      name: 'STROIESTI',
      zip: '37930',
      isUrban: false,
    },
    {
      name: 'ZLATUNOAIA',
      zip: '37949',
      isUrban: false,
    },
    {
      name: 'MANOLEASA',
      zip: '37967',
      isUrban: false,
    },
    {
      name: 'BOLD',
      zip: '37976',
      isUrban: false,
    },
    {
      name: 'FLONDORA',
      zip: '37985',
      isUrban: false,
    },
    {
      name: 'IORGA',
      zip: '37994',
      isUrban: false,
    },
    {
      name: 'LIVENI',
      zip: '38009',
      isUrban: false,
    },
    {
      name: 'LOTURI',
      zip: '38018',
      isUrban: false,
    },
    {
      name: 'MANOLEASA-PRUT',
      zip: '38027',
      isUrban: false,
    },
    {
      name: 'SADOVENI',
      zip: '38036',
      isUrban: false,
    },
    {
      name: 'SERPENITA',
      zip: '38045',
      isUrban: false,
    },
    {
      name: 'ZAHORENI',
      zip: '38054',
      isUrban: false,
    },
    {
      name: 'IPOTESTI',
      zip: '38072',
      isUrban: false,
    },
    {
      name: 'BAISA',
      zip: '38081',
      isUrban: false,
    },
    {
      name: 'CATAMARESTI',
      zip: '38090',
      isUrban: false,
    },
    {
      name: 'CATAMARESTI-DEAL',
      zip: '38107',
      isUrban: false,
    },
    {
      name: 'CERVICESTI',
      zip: '38116',
      isUrban: false,
    },
    {
      name: 'CERVICESTI-DEAL',
      zip: '38125',
      isUrban: false,
    },
    {
      name: 'CUCORANI',
      zip: '38134',
      isUrban: false,
    },
    {
      name: 'MANOLESTI',
      zip: '38143',
      isUrban: false,
    },
    {
      name: 'STANCESTI',
      zip: '38152',
      isUrban: false,
    },
    {
      name: 'MIHAILENI',
      zip: '38170',
      isUrban: false,
    },
    {
      name: 'CALINESTI',
      zip: '38189',
      isUrban: false,
    },
    {
      name: 'CANDESTI',
      zip: '38198',
      isUrban: false,
    },
    {
      name: 'PARAU NEGRU',
      zip: '38205',
      isUrban: false,
    },
    {
      name: 'ROGOJESTI',
      zip: '38214',
      isUrban: false,
    },
    {
      name: 'TALPA',
      zip: '38223',
      isUrban: false,
    },
    {
      name: 'VITCANI',
      zip: '38232',
      isUrban: false,
    },
    {
      name: 'MIHALASENI',
      zip: '38250',
      isUrban: false,
    },
    {
      name: 'CARAIMAN',
      zip: '38269',
      isUrban: false,
    },
    {
      name: 'NASTASE',
      zip: '38278',
      isUrban: false,
    },
    {
      name: 'NEGRESTI',
      zip: '38287',
      isUrban: false,
    },
    {
      name: 'PAUN',
      zip: '38296',
      isUrban: false,
    },
    {
      name: 'SARATA',
      zip: '38303',
      isUrban: false,
    },
    {
      name: 'SLOBOZIA SILISCANI',
      zip: '38312',
      isUrban: false,
    },
    {
      name: 'MILEANCA',
      zip: '38330',
      isUrban: false,
    },
    {
      name: 'CODRENI',
      zip: '38349',
      isUrban: false,
    },
    {
      name: 'SCUTARI',
      zip: '38358',
      isUrban: false,
    },
    {
      name: 'SELISTEA',
      zip: '38367',
      isUrban: false,
    },
    {
      name: 'MITOC',
      zip: '38385',
      isUrban: false,
    },
    {
      name: 'HORIA',
      zip: '38394',
      isUrban: false,
    },
    {
      name: 'NICSENI',
      zip: '38465',
      isUrban: false,
    },
    {
      name: 'DACIA',
      zip: '38474',
      isUrban: false,
    },
    {
      name: 'DOROBANTI',
      zip: '38483',
      isUrban: false,
    },
    {
      name: 'PALTINIS',
      zip: '38508',
      isUrban: false,
    },
    {
      name: 'CUZLAU',
      zip: '38517',
      isUrban: false,
    },
    {
      name: 'HORODISTEA',
      zip: '38526',
      isUrban: false,
    },
    {
      name: 'SLOBOZIA',
      zip: '38535',
      isUrban: false,
    },
    {
      name: 'POMARLA',
      zip: '38553',
      isUrban: false,
    },
    {
      name: 'HULUBESTI',
      zip: '38562',
      isUrban: false,
    },
    {
      name: 'RACOVAT',
      zip: '38571',
      isUrban: false,
    },
    {
      name: 'PRAJENI',
      zip: '38599',
      isUrban: false,
    },
    {
      name: 'CAMPENI',
      zip: '38606',
      isUrban: false,
    },
    {
      name: 'LUPARIA',
      zip: '38615',
      isUrban: false,
    },
    {
      name: 'MILETIN',
      zip: '38624',
      isUrban: false,
    },
    {
      name: 'RACHITI',
      zip: '35848',
      isUrban: false,
    },
    {
      name: 'CISMEA',
      zip: '35857',
      isUrban: false,
    },
    {
      name: 'COSTESTI',
      zip: '35866',
      isUrban: false,
    },
    {
      name: 'ROSIORI',
      zip: '35875',
      isUrban: false,
    },
    {
      name: 'RADAUTI-PRUT',
      zip: '38642',
      isUrban: false,
    },
    {
      name: 'MIORCANI',
      zip: '38651',
      isUrban: false,
    },
    {
      name: 'REDIU',
      zip: '38660',
      isUrban: false,
    },
    {
      name: 'RAUSENI',
      zip: '38688',
      isUrban: false,
    },
    {
      name: 'DOINA',
      zip: '38697',
      isUrban: false,
    },
    {
      name: 'POGORASTI',
      zip: '38704',
      isUrban: false,
    },
    {
      name: 'REDIU',
      zip: '38713',
      isUrban: false,
    },
    {
      name: 'LEHNESTI',
      zip: '38768',
      isUrban: false,
    },
    {
      name: 'MOVILA RUPTA',
      zip: '38777',
      isUrban: false,
    },
    {
      name: 'POPOAIA',
      zip: '38786',
      isUrban: false,
    },
    {
      name: 'RASCA',
      zip: '38802',
      isUrban: false,
    },
    {
      name: 'RIPICENII VECHI',
      zip: '38795',
      isUrban: false,
    },
    {
      name: 'ROMA',
      zip: '38820',
      isUrban: false,
    },
    {
      name: 'COTARGACI',
      zip: '38839',
      isUrban: false,
    },
    {
      name: 'ROMANESTI',
      zip: '38857',
      isUrban: false,
    },
    {
      name: 'DAMIDENI',
      zip: '38866',
      isUrban: false,
    },
    {
      name: 'ROMANESTI-VALE',
      zip: '38875',
      isUrban: false,
    },
    {
      name: 'SARATA',
      zip: '38884',
      isUrban: false,
    },
    {
      name: 'SANTA MARE',
      zip: '38900',
      isUrban: false,
    },
    {
      name: 'BADARAI',
      zip: '38919',
      isUrban: false,
    },
    {
      name: 'BERZA',
      zip: '38928',
      isUrban: false,
    },
    {
      name: 'BOGDANESTI',
      zip: '38937',
      isUrban: false,
    },
    {
      name: 'DURNESTI',
      zip: '38946',
      isUrban: false,
    },
    {
      name: 'ILISENI',
      zip: '38955',
      isUrban: false,
    },
    {
      name: 'RANGHILESTI',
      zip: '38964',
      isUrban: false,
    },
    {
      name: 'RANGHILESTI-DEAL',
      zip: '38973',
      isUrban: false,
    },
    {
      name: 'SENDRICENI',
      zip: '39131',
      isUrban: false,
    },
    {
      name: 'HORLACENI',
      zip: '39140',
      isUrban: false,
    },
    {
      name: 'PADURENI',
      zip: '39159',
      isUrban: false,
    },
    {
      name: 'STAUCENI',
      zip: '35893',
      isUrban: false,
    },
    {
      name: 'SILISTEA',
      zip: '35900',
      isUrban: false,
    },
    {
      name: 'TOCILENI',
      zip: '35919',
      isUrban: false,
    },
    {
      name: 'VICTORIA',
      zip: '35928',
      isUrban: false,
    },
    {
      name: 'STEFANESTI',
      zip: '39177',
      isUrban: true,
    },
    {
      name: 'BADIUTI',
      zip: '39186',
      isUrban: true,
    },
    {
      name: 'BOBULESTI',
      zip: '39195',
      isUrban: true,
    },
    {
      name: 'STANCA',
      zip: '39202',
      isUrban: true,
    },
    {
      name: 'STEFANESTI-SAT',
      zip: '39211',
      isUrban: true,
    },
    {
      name: 'STIUBIENI',
      zip: '39239',
      isUrban: false,
    },
    {
      name: 'IBANEASA',
      zip: '39248',
      isUrban: false,
    },
    {
      name: 'NEGRENI',
      zip: '39257',
      isUrban: false,
    },
    {
      name: 'SUHARAU',
      zip: '38991',
      isUrban: false,
    },
    {
      name: 'IZVOARE',
      zip: '39006',
      isUrban: false,
    },
    {
      name: 'LISNA',
      zip: '39015',
      isUrban: false,
    },
    {
      name: 'OROFTIANA',
      zip: '39024',
      isUrban: false,
    },
    {
      name: 'PLEVNA',
      zip: '39033',
      isUrban: false,
    },
    {
      name: 'SMARDAN',
      zip: '39042',
      isUrban: false,
    },
    {
      name: 'SULITA',
      zip: '39060',
      isUrban: false,
    },
    {
      name: 'BLANDESTI',
      zip: '39079',
      isUrban: false,
    },
    {
      name: 'CERCHEJENI',
      zip: '39088',
      isUrban: false,
    },
    {
      name: 'CHELIS',
      zip: '39097',
      isUrban: false,
    },
    {
      name: 'DRACSANI',
      zip: '39104',
      isUrban: false,
    },
    {
      name: 'SOLDANESTI',
      zip: '39113',
      isUrban: false,
    },
    {
      name: 'TODIRENI',
      zip: '39275',
      isUrban: false,
    },
    {
      name: 'CERNESTI',
      zip: '39284',
      isUrban: false,
    },
    {
      name: 'FLORESTI',
      zip: '39293',
      isUrban: false,
    },
    {
      name: 'GARBESTI',
      zip: '39300',
      isUrban: false,
    },
    {
      name: 'IURESTI',
      zip: '39319',
      isUrban: false,
    },
    {
      name: 'TRUSESTI',
      zip: '39337',
      isUrban: false,
    },
    {
      name: 'BUHACENI',
      zip: '39346',
      isUrban: false,
    },
    {
      name: 'CIRITEI',
      zip: '39355',
      isUrban: false,
    },
    {
      name: 'DRISLEA',
      zip: '39364',
      isUrban: false,
    },
    {
      name: 'IONASENI',
      zip: '39373',
      isUrban: false,
    },
    {
      name: 'PASATENI',
      zip: '39382',
      isUrban: false,
    },
    {
      name: 'TUDORA',
      zip: '39408',
      isUrban: false,
    },
    {
      name: 'UNGURENI',
      zip: '39426',
      isUrban: false,
    },
    {
      name: 'BORZESTI',
      zip: '39435',
      isUrban: false,
    },
    {
      name: 'CALUGARENI',
      zip: '39444',
      isUrban: false,
    },
    {
      name: 'CALUGARENII NOI',
      zip: '39453',
      isUrban: false,
    },
    {
      name: 'DURNESTI',
      zip: '39462',
      isUrban: false,
    },
    {
      name: 'MANDRESTI',
      zip: '39480',
      isUrban: false,
    },
    {
      name: 'MIHAI VITEAZU',
      zip: '39471',
      isUrban: false,
    },
    {
      name: 'PLOPENII MARI',
      zip: '39499',
      isUrban: false,
    },
    {
      name: 'PLOPENII MICI',
      zip: '39505',
      isUrban: false,
    },
    {
      name: 'TAUTESTI',
      zip: '39514',
      isUrban: false,
    },
    {
      name: 'VICOLENI',
      zip: '39523',
      isUrban: false,
    },
    {
      name: 'UNTENI',
      zip: '39541',
      isUrban: false,
    },
    {
      name: 'BURLA',
      zip: '39550',
      isUrban: false,
    },
    {
      name: 'BURLESTI',
      zip: '39569',
      isUrban: false,
    },
    {
      name: 'MANASTIRENI',
      zip: '39578',
      isUrban: false,
    },
    {
      name: 'SOROCENI',
      zip: '39587',
      isUrban: false,
    },
    {
      name: 'VALEA GRAJDULUI',
      zip: '39596',
      isUrban: false,
    },
    {
      name: 'VULTURENI',
      zip: '39603',
      isUrban: false,
    },
    {
      name: 'VACULESTI',
      zip: '39621',
      isUrban: false,
    },
    {
      name: 'GOROVEI',
      zip: '39630',
      isUrban: false,
    },
    {
      name: 'SAUCENITA',
      zip: '39649',
      isUrban: false,
    },
    {
      name: 'VARFU CAMPULUI',
      zip: '39701',
      isUrban: false,
    },
    {
      name: 'IONASENI',
      zip: '39710',
      isUrban: false,
    },
    {
      name: 'LUNCA',
      zip: '39729',
      isUrban: false,
    },
    {
      name: 'VIISOARA',
      zip: '39667',
      isUrban: false,
    },
    {
      name: 'CUZA VODA',
      zip: '39676',
      isUrban: false,
    },
    {
      name: 'VIISOARA MICA',
      zip: '39685',
      isUrban: false,
    },
    {
      name: 'VLADENI',
      zip: '39747',
      isUrban: false,
    },
    {
      name: 'BREHUIESTI',
      zip: '39756',
      isUrban: false,
    },
    {
      name: 'HRISCANI',
      zip: '39765',
      isUrban: false,
    },
    {
      name: 'HUTANI',
      zip: '39774',
      isUrban: false,
    },
    {
      name: 'MANDRESTI',
      zip: '39783',
      isUrban: false,
    },
    {
      name: 'VLASINESTI',
      zip: '39809',
      isUrban: false,
    },
    {
      name: 'MIRON COSTIN',
      zip: '39818',
      isUrban: false,
    },
    {
      name: 'SARBI',
      zip: '39827',
      isUrban: false,
    },
    {
      name: 'VORNICENI',
      zip: '39845',
      isUrban: false,
    },
    {
      name: 'DAVIDOAIA',
      zip: '39854',
      isUrban: false,
    },
    {
      name: 'DEALU CRUCII',
      zip: '39863',
      isUrban: false,
    },
    {
      name: 'VORONA',
      zip: '39881',
      isUrban: false,
    },
    {
      name: 'ICUSENI',
      zip: '39890',
      isUrban: false,
    },
    {
      name: 'JOLDESTI',
      zip: '39907',
      isUrban: false,
    },
    {
      name: 'POIANA',
      zip: '39916',
      isUrban: false,
    },
    {
      name: 'VORONA MARE',
      zip: '39925',
      isUrban: false,
    },
    {
      name: 'VORONA-TEODORU',
      zip: '39934',
      isUrban: false,
    },
    {
      name: 'POPENI',
      zip: '37431',
      isUrban: false,
    },
    {
      name: 'STOLNICENI',
      zip: '38722',
      isUrban: false,
    },
    {
      name: 'RIPICENI',
      zip: '38740',
      isUrban: false,
    },
    {
      name: 'CINGHINIIA',
      zip: '38759',
      isUrban: false,
    },
    {
      name: 'DAMILENI',
      zip: '39991',
      isUrban: false,
    },
    {
      name: 'SLOBOZIA',
      zip: '40001',
      isUrban: false,
    },
    {
      name: 'BASEU',
      zip: '40019',
      isUrban: false,
    },
    {
      name: 'VATRA',
      zip: '40027',
      isUrban: false,
    },
    {
      name: 'DOBRINAUTI-HAPAI',
      zip: '40043',
      isUrban: false,
    },
    {
      name: 'MAGHERA',
      zip: '40050',
      isUrban: false,
    },
    {
      name: 'PUSTOAIA',
      zip: '40068',
      isUrban: false,
    }
  ],
  BV: [
    {
      name: 'BRASOV',
      zip: '40205',
      isUrban: true,
    },
    {
      name: 'POIANA BRASOV',
      zip: '42432',
      isUrban: true,
    },
    {
      name: 'FAGARAS',
      zip: '40287',
      isUrban: true,
    },
    {
      name: 'CODLEA',
      zip: '40250',
      isUrban: true,
    },
    {
      name: 'PREDEAL',
      zip: '40312',
      isUrban: true,
    },
    {
      name: 'PARIUL RECE',
      zip: '40321',
      isUrban: true,
    },
    {
      name: 'TIMISU DE JOS',
      zip: '40330',
      isUrban: true,
    },
    {
      name: 'TIMISU DE SUS',
      zip: '40349',
      isUrban: true,
    },
    {
      name: 'RASNOV',
      zip: '40376',
      isUrban: true,
    },
    {
      name: 'RUPEA',
      zip: '40401',
      isUrban: true,
    },
    {
      name: 'FISER',
      zip: '40410',
      isUrban: true,
    },
    {
      name: 'SACELE',
      zip: '40447',
      isUrban: true,
    },
    {
      name: 'VICTORIA',
      zip: '40474',
      isUrban: true,
    },
    {
      name: 'ZARNESTI',
      zip: '40508',
      isUrban: true,
    },
    {
      name: 'TOHANU NOU',
      zip: '40517',
      isUrban: true,
    },
    {
      name: 'APATA',
      zip: '40535',
      isUrban: false,
    },
    {
      name: 'BECLEAN',
      zip: '40553',
      isUrban: false,
    },
    {
      name: 'BOHOLT',
      zip: '40562',
      isUrban: false,
    },
    {
      name: 'CALBOR',
      zip: '40571',
      isUrban: false,
    },
    {
      name: 'HUREZ',
      zip: '40580',
      isUrban: false,
    },
    {
      name: 'LUTA',
      zip: '40599',
      isUrban: false,
    },
    {
      name: 'BOD',
      zip: '40615',
      isUrban: false,
    },
    {
      name: 'COLONIA BOD',
      zip: '40624',
      isUrban: false,
    },
    {
      name: 'BRAN',
      zip: '40642',
      isUrban: false,
    },
    {
      name: 'PREDELUT',
      zip: '40651',
      isUrban: false,
    },
    {
      name: 'SIMON',
      zip: '40679',
      isUrban: false,
    },
    {
      name: 'SOHODOL',
      zip: '40660',
      isUrban: false,
    },
    {
      name: 'BUDILA',
      zip: '40697',
      isUrban: false,
    },
    {
      name: 'BUNESTI',
      zip: '40713',
      isUrban: false,
    },
    {
      name: 'CRIT',
      zip: '40722',
      isUrban: false,
    },
    {
      name: 'MESENDORF',
      zip: '40731',
      isUrban: false,
    },
    {
      name: 'ROADES',
      zip: '40740',
      isUrban: false,
    },
    {
      name: 'VISCRI',
      zip: '40759',
      isUrban: false,
    },
    {
      name: 'CATA',
      zip: '40777',
      isUrban: false,
    },
    {
      name: 'BEIA',
      zip: '40786',
      isUrban: false,
    },
    {
      name: 'DRAUSENI',
      zip: '40795',
      isUrban: false,
    },
    {
      name: 'IONESTI',
      zip: '40802',
      isUrban: false,
    },
    {
      name: 'PALOS',
      zip: '40811',
      isUrban: false,
    },
    {
      name: 'CINCU',
      zip: '40839',
      isUrban: false,
    },
    {
      name: 'TOARCLA',
      zip: '40848',
      isUrban: false,
    },
    {
      name: 'COMANA DE JOS',
      zip: '40866',
      isUrban: false,
    },
    {
      name: 'COMANA DE SUS',
      zip: '40875',
      isUrban: false,
    },
    {
      name: 'CRIHALMA',
      zip: '40884',
      isUrban: false,
    },
    {
      name: 'TICUSU NOU',
      zip: '40893',
      isUrban: false,
    },
    {
      name: 'CRISTIAN',
      zip: '40919',
      isUrban: false,
    },
    {
      name: 'DUMBRAVITA',
      zip: '40937',
      isUrban: false,
    },
    {
      name: 'VLADENI',
      zip: '40946',
      isUrban: false,
    },
    {
      name: 'FELDIOARA',
      zip: '40964',
      isUrban: false,
    },
    {
      name: 'COLONIA RECONSTRUCTIA',
      zip: '40973',
      isUrban: false,
    },
    {
      name: 'ROTBAV',
      zip: '40982',
      isUrban: false,
    },
    {
      name: 'FUNDATA',
      zip: '41006',
      isUrban: false,
    },
    {
      name: 'FUNDATICA',
      zip: '41015',
      isUrban: false,
    },
    {
      name: 'SIRNEA',
      zip: '41024',
      isUrban: false,
    },
    {
      name: 'GHIMBAV',
      zip: '40223',
      isUrban: true,
    },
    {
      name: 'HALCHIU',
      zip: '41042',
      isUrban: false,
    },
    {
      name: 'CRIZBAV',
      zip: '41051',
      isUrban: false,
    },
    {
      name: 'CUTUS',
      zip: '41060',
      isUrban: false,
    },
    {
      name: 'SATU NOU',
      zip: '41079',
      isUrban: false,
    },
    {
      name: 'HARMAN',
      zip: '41097',
      isUrban: false,
    },
    {
      name: 'PODU OLTULUI',
      zip: '41104',
      isUrban: false,
    },
    {
      name: 'HARSENI',
      zip: '41122',
      isUrban: false,
    },
    {
      name: 'COPACEL',
      zip: '41131',
      isUrban: false,
    },
    {
      name: 'MALINIS',
      zip: '41140',
      isUrban: false,
    },
    {
      name: 'MARGINENI',
      zip: '41159',
      isUrban: false,
    },
    {
      name: 'SEBES',
      zip: '41168',
      isUrban: false,
    },
    {
      name: 'HOGHIZ',
      zip: '41186',
      isUrban: false,
    },
    {
      name: 'BOGATA OLTEANA',
      zip: '41195',
      isUrban: false,
    },
    {
      name: 'CUCIULATA',
      zip: '41202',
      isUrban: false,
    },
    {
      name: 'DOPCA',
      zip: '41211',
      isUrban: false,
    },
    {
      name: 'FANTANA',
      zip: '41220',
      isUrban: false,
    },
    {
      name: 'LUPSA',
      zip: '41239',
      isUrban: false,
    },
    {
      name: 'HOMOROD',
      zip: '41257',
      isUrban: false,
    },
    {
      name: 'JIMBOR',
      zip: '41266',
      isUrban: false,
    },
    {
      name: 'MERCHEASA',
      zip: '41275',
      isUrban: false,
    },
    {
      name: 'JIBERT',
      zip: '41293',
      isUrban: false,
    },
    {
      name: 'DACIA',
      zip: '41300',
      isUrban: false,
    },
    {
      name: 'GRANARI',
      zip: '41319',
      isUrban: false,
    },
    {
      name: 'LOVNIC',
      zip: '41328',
      isUrban: false,
    },
    {
      name: 'VALENI',
      zip: '41337',
      isUrban: false,
    },
    {
      name: 'LISA',
      zip: '41355',
      isUrban: false,
    },
    {
      name: 'BREAZA',
      zip: '41364',
      isUrban: false,
    },
    {
      name: 'POJORTA',
      zip: '41373',
      isUrban: false,
    },
    {
      name: 'MAIERUS',
      zip: '41391',
      isUrban: false,
    },
    {
      name: 'ARINI',
      zip: '41408',
      isUrban: false,
    },
    {
      name: 'MANDRA',
      zip: '41426',
      isUrban: false,
    },
    {
      name: 'ILENI',
      zip: '41435',
      isUrban: false,
    },
    {
      name: 'RAUSOR',
      zip: '41444',
      isUrban: false,
    },
    {
      name: 'SONA',
      zip: '41453',
      isUrban: false,
    },
    {
      name: 'TODERITA',
      zip: '41462',
      isUrban: false,
    },
    {
      name: 'MOIECIU DE JOS',
      zip: '41480',
      isUrban: false,
    },
    {
      name: 'CHEIA',
      zip: '41499',
      isUrban: false,
    },
    {
      name: 'DRUMUL CARULUI',
      zip: '41505',
      isUrban: false,
    },
    {
      name: 'MAGURA',
      zip: '41514',
      isUrban: false,
    },
    {
      name: 'MOIECIU DE SUS',
      zip: '41523',
      isUrban: false,
    },
    {
      name: 'PESTERA',
      zip: '41532',
      isUrban: false,
    },
    {
      name: 'ORMENIS',
      zip: '41550',
      isUrban: false,
    },
    {
      name: 'AUGUSTIN',
      zip: '41569',
      isUrban: false,
    },
    {
      name: 'PARAU',
      zip: '41587',
      isUrban: false,
    },
    {
      name: 'GRID',
      zip: '41596',
      isUrban: false,
    },
    {
      name: 'VENETIA DE JOS',
      zip: '41603',
      isUrban: false,
    },
    {
      name: 'VENETIA DE SUS',
      zip: '41612',
      isUrban: false,
    },
    {
      name: 'POIANA MARULUI',
      zip: '41630',
      isUrban: false,
    },
    {
      name: 'PALTIN',
      zip: '41649',
      isUrban: false,
    },
    {
      name: 'SINCA NOUA',
      zip: '41658',
      isUrban: false,
    },
    {
      name: 'PREJMER',
      zip: '41676',
      isUrban: false,
    },
    {
      name: 'LUNCA CALNICULUI',
      zip: '41685',
      isUrban: false,
    },
    {
      name: 'STUPINII PREJMERULUI',
      zip: '41694',
      isUrban: false,
    },
    {
      name: 'RACOS',
      zip: '41710',
      isUrban: false,
    },
    {
      name: 'MATEIAS',
      zip: '41729',
      isUrban: false,
    },
    {
      name: 'RECEA',
      zip: '41747',
      isUrban: false,
    },
    {
      name: 'BERIVOI',
      zip: '41756',
      isUrban: false,
    },
    {
      name: 'DEJANI',
      zip: '41765',
      isUrban: false,
    },
    {
      name: 'GURA VAII',
      zip: '41774',
      isUrban: false,
    },
    {
      name: 'IASI',
      zip: '41783',
      isUrban: false,
    },
    {
      name: 'SASCIORI',
      zip: '41792',
      isUrban: false,
    },
    {
      name: 'SAVASTRENI',
      zip: '41809',
      isUrban: false,
    },
    {
      name: 'SANPETRU',
      zip: '41934',
      isUrban: false,
    },
    {
      name: 'SERCAIA',
      zip: '41827',
      isUrban: false,
    },
    {
      name: 'HALMEAG',
      zip: '41836',
      isUrban: false,
    },
    {
      name: 'VAD',
      zip: '41845',
      isUrban: false,
    },
    {
      name: 'SINCA VECHE',
      zip: '41863',
      isUrban: false,
    },
    {
      name: 'BUCIUM',
      zip: '41872',
      isUrban: false,
    },
    {
      name: 'OHABA',
      zip: '41881',
      isUrban: false,
    },
    {
      name: 'PERSANI',
      zip: '41890',
      isUrban: false,
    },
    {
      name: 'SERCAITA',
      zip: '41907',
      isUrban: false,
    },
    {
      name: 'VALCEA',
      zip: '41916',
      isUrban: false,
    },
    {
      name: 'SOARS',
      zip: '41952',
      isUrban: false,
    },
    {
      name: 'BARCUT',
      zip: '41961',
      isUrban: false,
    },
    {
      name: 'FELMER',
      zip: '41970',
      isUrban: false,
    },
    {
      name: 'RODBAV',
      zip: '41989',
      isUrban: false,
    },
    {
      name: 'SELISTAT',
      zip: '41998',
      isUrban: false,
    },
    {
      name: 'TARLUNGENI',
      zip: '42012',
      isUrban: false,
    },
    {
      name: 'CARPINIS',
      zip: '42021',
      isUrban: false,
    },
    {
      name: 'PURCARENI',
      zip: '42030',
      isUrban: false,
    },
    {
      name: 'ZIZIN',
      zip: '42049',
      isUrban: false,
    },
    {
      name: 'TELIU',
      zip: '42067',
      isUrban: false,
    },
    {
      name: 'TICUSU VECHI',
      zip: '42085',
      isUrban: false,
    },
    {
      name: 'COBOR',
      zip: '42094',
      isUrban: false,
    },
    {
      name: 'UCEA DE JOS',
      zip: '42110',
      isUrban: false,
    },
    {
      name: 'CORBI',
      zip: '42129',
      isUrban: false,
    },
    {
      name: 'FELDIOARA',
      zip: '42138',
      isUrban: false,
    },
    {
      name: 'UCEA DE SUS',
      zip: '42147',
      isUrban: false,
    },
    {
      name: 'UNGRA',
      zip: '42165',
      isUrban: false,
    },
    {
      name: 'DAISOARA',
      zip: '42174',
      isUrban: false,
    },
    {
      name: 'VAMA BUZAULUI',
      zip: '42192',
      isUrban: false,
    },
    {
      name: 'ACRIS',
      zip: '42209',
      isUrban: false,
    },
    {
      name: 'BUZAIEL',
      zip: '42218',
      isUrban: false,
    },
    {
      name: 'DALGHIU',
      zip: '42227',
      isUrban: false,
    },
    {
      name: 'VISTEA DE JOS',
      zip: '42245',
      isUrban: false,
    },
    {
      name: 'DRAGUS',
      zip: '42254',
      isUrban: false,
    },
    {
      name: 'OLTET',
      zip: '42263',
      isUrban: false,
    },
    {
      name: 'VISTISOARA',
      zip: '42290',
      isUrban: false,
    },
    {
      name: 'CINCSOR',
      zip: '42325',
      isUrban: false,
    },
    {
      name: 'DRIDIF',
      zip: '42334',
      isUrban: false,
    },
    {
      name: 'LUDISOR',
      zip: '42343',
      isUrban: false,
    },
    {
      name: 'SIMBATA DE JOS',
      zip: '42352',
      isUrban: false,
    },
    {
      name: 'SIMBATA DE SUS',
      zip: '42361',
      isUrban: false,
    },
    {
      name: 'STATIUNEA CLIMATERICA SIMBATA',
      zip: '42370',
      isUrban: false,
    },
    {
      name: 'VOIVODENI',
      zip: '42389',
      isUrban: false,
    },
    {
      name: 'VULCAN',
      zip: '42405',
      isUrban: false,
    },
    {
      name: 'COLONIA 1 MAI',
      zip: '42414',
      isUrban: false,
    },
    {
      name: 'HOLBAV',
      zip: '42423',
      isUrban: false,
    },
    {
      name: 'RUCAR',
      zip: '42272',
      isUrban: false,
    },
    {
      name: 'VISTEA DE SUS',
      zip: '42281',
      isUrban: false,
    },
    {
      name: 'VOILA',
      zip: '42316',
      isUrban: false,
    }
  ],
  B: [
    {
      name: 'BUCURESTI SECTORUL 1',
      zip: '179141',
      isUrban: true,
    },
    {
      name: 'BUCURESTI SECTORUL 2',
      zip: '179150',
      isUrban: true,
    },
    {
      name: 'BUCURESTI SECTORUL 3',
      zip: '179169',
      isUrban: true,
    },
    {
      name: 'BUCURESTI SECTORUL 4',
      zip: '179178',
      isUrban: true,
    },
    {
      name: 'BUCURESTI SECTORUL 5',
      zip: '179187',
      isUrban: true,
    },
    {
      name: 'BUCURESTI SECTORUL 6',
      zip: '179196',
      isUrban: true,
    }
  ],
  CL: [
    {
      name: 'CALARASI',
      zip: '92578',
      isUrban: true,
    },
    {
      name: 'BUDESTI',
      zip: '101467',
      isUrban: true,
    },
    {
      name: 'APROZI',
      zip: '101476',
      isUrban: true,
    },
    {
      name: 'BUCIUMENI',
      zip: '101485',
      isUrban: true,
    },
    {
      name: 'CRIVAT',
      zip: '101494',
      isUrban: false,
    },
    {
      name: 'GRUIU',
      zip: '101500',
      isUrban: true,
    },
    {
      name: 'FUNDULEA',
      zip: '103041',
      isUrban: true,
    },
    {
      name: 'ALEXANDRU I. CUZA',
      zip: '103050',
      isUrban: true,
    },
    {
      name: 'GOSTILELE',
      zip: '103078',
      isUrban: true,
    },
    {
      name: 'LEHLIU-GARA',
      zip: '93897',
      isUrban: true,
    },
    {
      name: 'BUZOENI',
      zip: '93904',
      isUrban: true,
    },
    {
      name: 'RAZVANI',
      zip: '93913',
      isUrban: true,
    },
    {
      name: 'VALEA SEACA',
      zip: '93922',
      isUrban: true,
    },
    {
      name: 'OLTENITA',
      zip: '100629',
      isUrban: true,
    },
    {
      name: 'NICOLAE BALCESCU',
      zip: '94134',
      isUrban: false,
    },
    {
      name: 'ALEXANDRU ODOBESCU',
      zip: '94143',
      isUrban: false,
    },
    {
      name: 'GALATUI',
      zip: '94152',
      isUrban: false,
    },
    {
      name: 'BELCIUGATELE',
      zip: '101092',
      isUrban: false,
    },
    {
      name: 'CANDEASCA',
      zip: '101109',
      isUrban: false,
    },
    {
      name: 'COJESTI',
      zip: '101118',
      isUrban: false,
    },
    {
      name: 'MARIUTA',
      zip: '101136',
      isUrban: false,
    },
    {
      name: 'MATARAUA',
      zip: '101127',
      isUrban: false,
    },
    {
      name: 'BORCEA',
      zip: '92970',
      isUrban: false,
    },
    {
      name: 'CASCIOARELE',
      zip: '101733',
      isUrban: false,
    },
    {
      name: 'CHIRNOGI',
      zip: '101813',
      isUrban: false,
    },
    {
      name: 'CHISELET',
      zip: '101831',
      isUrban: false,
    },
    {
      name: 'CIOCANESTI',
      zip: '93094',
      isUrban: false,
    },
    {
      name: 'CURCANI',
      zip: '102428',
      isUrban: false,
    },
    {
      name: 'SALCIOARA',
      zip: '102437',
      isUrban: false,
    },
    {
      name: 'CEACU',
      zip: '93290',
      isUrban: false,
    },
    {
      name: 'CALARASII VECHI',
      zip: '93307',
      isUrban: false,
    },
    {
      name: 'CUZA VODA',
      zip: '93316',
      isUrban: false,
    },
    {
      name: 'DICHISENI',
      zip: '93334',
      isUrban: false,
    },
    {
      name: 'COSLOGENI',
      zip: '93343',
      isUrban: false,
    },
    {
      name: 'LIBERTATEA',
      zip: '93352',
      isUrban: false,
    },
    {
      name: 'SATNOENI',
      zip: '93361',
      isUrban: false,
    },
    {
      name: 'DOR MARUNT',
      zip: '93389',
      isUrban: false,
    },
    {
      name: 'DALGA',
      zip: '93398',
      isUrban: false,
    },
    {
      name: 'DALGA-GARA',
      zip: '93405',
      isUrban: false,
    },
    {
      name: 'INFRATIREA',
      zip: '93414',
      isUrban: false,
    },
    {
      name: 'OGORU',
      zip: '93423',
      isUrban: false,
    },
    {
      name: 'PELINU',
      zip: '93432',
      isUrban: false,
    },
    {
      name: 'DOROBANTU',
      zip: '93450',
      isUrban: false,
    },
    {
      name: 'BOSNEAGU',
      zip: '93469',
      isUrban: false,
    },
    {
      name: 'VARASTI',
      zip: '93478',
      isUrban: false,
    },
    {
      name: 'DRAGALINA',
      zip: '93496',
      isUrban: false,
    },
    {
      name: 'CONSTANTIN BRANCOVEANU',
      zip: '93502',
      isUrban: false,
    },
    {
      name: 'DRAJNA NOUA',
      zip: '93511',
      isUrban: false,
    },
    {
      name: 'DRAGOS VODA',
      zip: '93548',
      isUrban: false,
    },
    {
      name: 'BOGDANA',
      zip: '93557',
      isUrban: false,
    },
    {
      name: 'SOCOALELE',
      zip: '93566',
      isUrban: false,
    },
    {
      name: 'FRASINET',
      zip: '102847',
      isUrban: false,
    },
    {
      name: 'CURATESTI',
      zip: '102856',
      isUrban: false,
    },
    {
      name: 'DANESTI',
      zip: '102865',
      isUrban: false,
    },
    {
      name: 'FRASINETU DE JOS',
      zip: '102874',
      isUrban: false,
    },
    {
      name: 'LUPTATORI',
      zip: '102883',
      isUrban: false,
    },
    {
      name: 'TARICENI',
      zip: '102892',
      isUrban: false,
    },
    {
      name: 'FRUMUSANI',
      zip: '102954',
      isUrban: false,
    },
    {
      name: 'ORASTI',
      zip: '102963',
      isUrban: false,
    },
    {
      name: 'PADURISU',
      zip: '102981',
      isUrban: false,
    },
    {
      name: 'PASAREA',
      zip: '102972',
      isUrban: false,
    },
    {
      name: 'PITIGAIA',
      zip: '102990',
      isUrban: false,
    },
    {
      name: 'POSTAVARI',
      zip: '103005',
      isUrban: false,
    },
    {
      name: 'FUNDENI',
      zip: '103023',
      isUrban: false,
    },
    {
      name: 'GRADISTEA',
      zip: '93673',
      isUrban: false,
    },
    {
      name: 'BOGATA',
      zip: '93682',
      isUrban: false,
    },
    {
      name: 'CUNESTI',
      zip: '93691',
      isUrban: false,
    },
    {
      name: 'RASA',
      zip: '93708',
      isUrban: false,
    },
    {
      name: 'GURBANESTI',
      zip: '103577',
      isUrban: false,
    },
    {
      name: 'CODRENI',
      zip: '103586',
      isUrban: false,
    },
    {
      name: 'VLAICULESTI',
      zip: '103853',
      isUrban: false,
    },
    {
      name: 'INDEPENDENTA',
      zip: '93780',
      isUrban: false,
    },
    {
      name: 'POTCOAVA',
      zip: '93799',
      isUrban: false,
    },
    {
      name: 'VISINII',
      zip: '93806',
      isUrban: false,
    },
    {
      name: 'JEGALIA',
      zip: '93824',
      isUrban: false,
    },
    {
      name: 'GALDAU',
      zip: '93833',
      isUrban: false,
    },
    {
      name: 'IEZERU',
      zip: '93842',
      isUrban: false,
    },
    {
      name: 'LEHLIU',
      zip: '93860',
      isUrban: false,
    },
    {
      name: 'SAPUNARI',
      zip: '93879',
      isUrban: false,
    },
    {
      name: 'LUICA',
      zip: '104092',
      isUrban: false,
    },
    {
      name: 'VALEA STANII',
      zip: '104109',
      isUrban: false,
    },
    {
      name: 'LUPSANU',
      zip: '93940',
      isUrban: false,
    },
    {
      name: 'NUCETU',
      zip: '93959',
      isUrban: false,
    },
    {
      name: 'PLEVNA',
      zip: '93968',
      isUrban: false,
    },
    {
      name: 'RADU VODA',
      zip: '93977',
      isUrban: false,
    },
    {
      name: 'VALEA RUSULUI',
      zip: '93986',
      isUrban: false,
    },
    {
      name: 'MANASTIREA',
      zip: '104190',
      isUrban: false,
    },
    {
      name: 'COCONI',
      zip: '104207',
      isUrban: false,
    },
    {
      name: 'SULTANA',
      zip: '104216',
      isUrban: false,
    },
    {
      name: 'MITRENI',
      zip: '100647',
      isUrban: false,
    },
    {
      name: 'CLATESTI',
      zip: '100656',
      isUrban: false,
    },
    {
      name: 'VALEA ROSIE',
      zip: '100665',
      isUrban: false,
    },
    {
      name: 'MODELU',
      zip: '92596',
      isUrban: false,
    },
    {
      name: 'RADU NEGRU',
      zip: '92612',
      isUrban: false,
    },
    {
      name: 'STOENESTI',
      zip: '92621',
      isUrban: false,
    },
    {
      name: 'TONEA',
      zip: '92630',
      isUrban: false,
    },
    {
      name: 'NANA',
      zip: '104332',
      isUrban: false,
    },
    {
      name: 'NICOLAE BALCESCU',
      zip: '104350',
      isUrban: false,
    },
    {
      name: 'FANTANA DOAMNEI',
      zip: '104369',
      isUrban: false,
    },
    {
      name: 'PAICU',
      zip: '104378',
      isUrban: false,
    },
    {
      name: 'PERISORU',
      zip: '94232',
      isUrban: false,
    },
    {
      name: 'MARCULESTI-GARA',
      zip: '94241',
      isUrban: false,
    },
    {
      name: 'TUDOR VLADIMIRESCU',
      zip: '94250',
      isUrban: false,
    },
    {
      name: 'PLATARESTI',
      zip: '104644',
      isUrban: false,
    },
    {
      name: 'CUCUIETI',
      zip: '104653',
      isUrban: false,
    },
    {
      name: 'DOROBANTU',
      zip: '104662',
      isUrban: false,
    },
    {
      name: 'PODU PITARULUI',
      zip: '104671',
      isUrban: false,
    },
    {
      name: 'RADOVANU',
      zip: '104760',
      isUrban: false,
    },
    {
      name: 'VALEA POPII',
      zip: '104779',
      isUrban: false,
    },
    {
      name: 'ROSETI',
      zip: '94321',
      isUrban: false,
    },
    {
      name: 'SARULESTI',
      zip: '104895',
      isUrban: false,
    },
    {
      name: 'MAGURENI',
      zip: '104902',
      isUrban: false,
    },
    {
      name: 'POLCESTI',
      zip: '104911',
      isUrban: false,
    },
    {
      name: 'SANDULITA',
      zip: '104920',
      isUrban: false,
    },
    {
      name: 'SARULESTI-GARA',
      zip: '104939',
      isUrban: false,
    },
    {
      name: 'SATUCU',
      zip: '104957',
      isUrban: false,
    },
    {
      name: 'SOLACOLU',
      zip: '104966',
      isUrban: false,
    },
    {
      name: 'SOHATU',
      zip: '105231',
      isUrban: false,
    },
    {
      name: 'PROGRESU',
      zip: '105240',
      isUrban: false,
    },
    {
      name: 'SOLDANU',
      zip: '105393',
      isUrban: false,
    },
    {
      name: 'NEGOESTI',
      zip: '105400',
      isUrban: false,
    },
    {
      name: 'SPANTOV',
      zip: '105268',
      isUrban: false,
    },
    {
      name: 'CETATEA VECHE',
      zip: '105277',
      isUrban: false,
    },
    {
      name: 'STANCEA',
      zip: '105286',
      isUrban: false,
    },
    {
      name: 'STEFAN CEL MARE',
      zip: '94571',
      isUrban: false,
    },
    {
      name: 'STEFAN VODA',
      zip: '94599',
      isUrban: false,
    },
    {
      name: 'TAMADAU MARE',
      zip: '105464',
      isUrban: false,
    },
    {
      name: 'CALARETI',
      zip: '105473',
      isUrban: false,
    },
    {
      name: 'DARVARI',
      zip: '105482',
      isUrban: false,
    },
    {
      name: 'PLUMBUITA',
      zip: '105491',
      isUrban: false,
    },
    {
      name: 'SACELE',
      zip: '105507',
      isUrban: false,
    },
    {
      name: 'SEINOIU',
      zip: '105516',
      isUrban: false,
    },
    {
      name: 'TAMADAU MIC',
      zip: '105525',
      isUrban: false,
    },
    {
      name: 'ULMENI',
      zip: '105614',
      isUrban: false,
    },
    {
      name: 'ULMU',
      zip: '94615',
      isUrban: false,
    },
    {
      name: 'CHIRNOGI',
      zip: '94624',
      isUrban: false,
    },
    {
      name: 'FAUREI',
      zip: '94633',
      isUrban: false,
    },
    {
      name: 'ZIMBRU',
      zip: '94642',
      isUrban: false,
    },
    {
      name: 'UNIREA',
      zip: '94660',
      isUrban: false,
    },
    {
      name: 'OLTINA',
      zip: '94679',
      isUrban: false,
    },
    {
      name: 'VALCELELE',
      zip: '94740',
      isUrban: false,
    },
    {
      name: 'FLOROAICA',
      zip: '94759',
      isUrban: false,
    },
    {
      name: 'VALEA ARGOVEI',
      zip: '105721',
      isUrban: false,
    },
    {
      name: 'LUNCA',
      zip: '105730',
      isUrban: false,
    },
    {
      name: 'OSTROVU',
      zip: '105749',
      isUrban: false,
    },
    {
      name: 'SILISTEA',
      zip: '105758',
      isUrban: false,
    },
    {
      name: 'VLADICEASCA',
      zip: '105767',
      isUrban: false,
    },
    {
      name: 'VASILATI',
      zip: '105838',
      isUrban: false,
    },
    {
      name: 'GALBINASI',
      zip: '105847',
      isUrban: false,
    },
    {
      name: 'NUCI',
      zip: '105856',
      isUrban: false,
    },
    {
      name: 'POPESTI',
      zip: '105865',
      isUrban: false,
    },
    {
      name: 'VLAD TEPES',
      zip: '94777',
      isUrban: false,
    },
    {
      name: 'MIHAI VITEAZU',
      zip: '94786',
      isUrban: false,
    },
    {
      name: 'COTOFANCA',
      zip: '103595',
      isUrban: false,
    },
    {
      name: 'PREASNA',
      zip: '103602',
      isUrban: false,
    },
    {
      name: 'PREASNA VECHE',
      zip: '103611',
      isUrban: false,
    },
    {
      name: 'VALEA PRESNEI',
      zip: '103620',
      isUrban: false,
    },
    {
      name: 'ILEANA',
      zip: '103773',
      isUrban: false,
    },
    {
      name: 'ARTARI',
      zip: '103782',
      isUrban: false,
    },
    {
      name: 'FLORICA',
      zip: '103791',
      isUrban: false,
    },
    {
      name: 'PODARI',
      zip: '103808',
      isUrban: false,
    },
    {
      name: 'RASURILE',
      zip: '103817',
      isUrban: false,
    },
    {
      name: 'RAZOARELE',
      zip: '103826',
      isUrban: false,
    },
    {
      name: 'SATU NOU',
      zip: '103835',
      isUrban: false,
    },
    {
      name: 'STEFANESTI',
      zip: '103844',
      isUrban: false,
    }
  ],
  CS: [
    {
      name: 'RESITA',
      zip: '50807',
      isUrban: true,
    },
    {
      name: 'CALNIC',
      zip: '50816',
      isUrban: true,
    },
    {
      name: 'CUPTOARE',
      zip: '50825',
      isUrban: true,
    },
    {
      name: 'DOMAN',
      zip: '50834',
      isUrban: true,
    },
    {
      name: 'MONIOM',
      zip: '50861',
      isUrban: true,
    },
    {
      name: 'SECU',
      zip: '50843',
      isUrban: true,
    },
    {
      name: 'TEROVA',
      zip: '50852',
      isUrban: true,
    },
    {
      name: 'CARANSEBES',
      zip: '51029',
      isUrban: true,
    },
    {
      name: 'JUPA',
      zip: '51038',
      isUrban: true,
    },
    {
      name: 'ANINA',
      zip: '50898',
      isUrban: true,
    },
    {
      name: 'STEIERDORF',
      zip: '50905',
      isUrban: true,
    },
    {
      name: 'BAILE HERCULANE',
      zip: '50932',
      isUrban: true,
    },
    {
      name: 'PECINISCA',
      zip: '50941',
      isUrban: true,
    },
    {
      name: 'BOCSA',
      zip: '50978',
      isUrban: true,
    },
    {
      name: 'MOLDOVA NOUA',
      zip: '51065',
      isUrban: true,
    },
    {
      name: 'MACESTI',
      zip: '51074',
      isUrban: true,
    },
    {
      name: 'MOLDOVA VECHE',
      zip: '51083',
      isUrban: true,
    },
    {
      name: 'MOLDOVITA',
      zip: '51092',
      isUrban: true,
    },
    {
      name: 'ORAVITA',
      zip: '51127',
      isUrban: true,
    },
    {
      name: 'AGADICI',
      zip: '51154',
      isUrban: true,
    },
    {
      name: 'BRADISORU DE JOS',
      zip: '51163',
      isUrban: true,
    },
    {
      name: 'BROSTENI',
      zip: '51172',
      isUrban: true,
    },
    {
      name: 'CICLOVA MONTANA',
      zip: '51136',
      isUrban: true,
    },
    {
      name: 'MARILA',
      zip: '51145',
      isUrban: true,
    },
    {
      name: 'RACHITOVA',
      zip: '51181',
      isUrban: true,
    },
    {
      name: 'OTELU ROSU',
      zip: '51216',
      isUrban: true,
    },
    {
      name: 'CIRESA',
      zip: '51225',
      isUrban: true,
    },
    {
      name: 'MAL',
      zip: '51234',
      isUrban: true,
    },
    {
      name: 'ARMENIS',
      zip: '51252',
      isUrban: false,
    },
    {
      name: 'FENES',
      zip: '51261',
      isUrban: false,
    },
    {
      name: 'PLOPU',
      zip: '51270',
      isUrban: false,
    },
    {
      name: 'SAT BATRAN',
      zip: '51289',
      isUrban: false,
    },
    {
      name: 'SUB MARGINE',
      zip: '51298',
      isUrban: false,
    },
    {
      name: 'BANIA',
      zip: '51314',
      isUrban: false,
    },
    {
      name: 'GARBOVAT',
      zip: '51323',
      isUrban: false,
    },
    {
      name: 'BAUTAR',
      zip: '51341',
      isUrban: false,
    },
    {
      name: 'BUCOVA',
      zip: '51350',
      isUrban: false,
    },
    {
      name: 'CORNISORU',
      zip: '51369',
      isUrban: false,
    },
    {
      name: 'PREVECIORI',
      zip: '51378',
      isUrban: false,
    },
    {
      name: 'BERLISTE',
      zip: '51396',
      isUrban: false,
    },
    {
      name: 'IAM',
      zip: '51403',
      isUrban: false,
    },
    {
      name: 'MILCOVENI',
      zip: '51412',
      isUrban: false,
    },
    {
      name: 'RUSOVA NOUA',
      zip: '51421',
      isUrban: false,
    },
    {
      name: 'RUSOVA VECHE',
      zip: '51430',
      isUrban: false,
    },
    {
      name: 'BERZASCA',
      zip: '51458',
      isUrban: false,
    },
    {
      name: 'BIGAR',
      zip: '51467',
      isUrban: false,
    },
    {
      name: 'COZLA',
      zip: '51476',
      isUrban: false,
    },
    {
      name: 'DRENCOVA',
      zip: '51485',
      isUrban: false,
    },
    {
      name: 'LIUBCOVA',
      zip: '51494',
      isUrban: false,
    },
    {
      name: 'BERZOVIA',
      zip: '51519',
      isUrban: false,
    },
    {
      name: 'FIZES',
      zip: '51528',
      isUrban: false,
    },
    {
      name: 'GHERTENIS',
      zip: '51537',
      isUrban: false,
    },
    {
      name: 'BOLVASNITA',
      zip: '51555',
      isUrban: false,
    },
    {
      name: 'VARCIOROVA',
      zip: '51564',
      isUrban: false,
    },
    {
      name: 'BOZOVICI',
      zip: '51582',
      isUrban: false,
    },
    {
      name: 'PONEASCA',
      zip: '51591',
      isUrban: false,
    },
    {
      name: 'PRILIPET',
      zip: '51608',
      isUrban: false,
    },
    {
      name: 'VALEA MINISULUI',
      zip: '51617',
      isUrban: false,
    },
    {
      name: 'BREBU',
      zip: '51635',
      isUrban: false,
    },
    {
      name: 'APADIA',
      zip: '51644',
      isUrban: false,
    },
    {
      name: 'VALEADENI',
      zip: '51653',
      isUrban: false,
    },
    {
      name: 'BREBU NOU',
      zip: '51671',
      isUrban: false,
    },
    {
      name: 'GARANA',
      zip: '51680',
      isUrban: false,
    },
    {
      name: 'BUCHIN',
      zip: '51706',
      isUrban: false,
    },
    {
      name: 'LINDENFELD',
      zip: '51715',
      isUrban: false,
    },
    {
      name: 'POIANA',
      zip: '51724',
      isUrban: false,
    },
    {
      name: 'PRISIAN',
      zip: '51733',
      isUrban: false,
    },
    {
      name: 'VALEA TIMISULUI',
      zip: '51742',
      isUrban: false,
    },
    {
      name: 'BUCOSNITA',
      zip: '51760',
      isUrban: false,
    },
    {
      name: 'GOLET',
      zip: '51779',
      isUrban: false,
    },
    {
      name: 'PETROSNITA',
      zip: '51788',
      isUrban: false,
    },
    {
      name: 'VALISOARA',
      zip: '51797',
      isUrban: false,
    },
    {
      name: 'CARASOVA',
      zip: '51813',
      isUrban: false,
    },
    {
      name: 'IABALCEA',
      zip: '51822',
      isUrban: false,
    },
    {
      name: 'NERMED',
      zip: '51831',
      isUrban: false,
    },
    {
      name: 'CARBUNARI',
      zip: '51859',
      isUrban: false,
    },
    {
      name: 'STINAPARI',
      zip: '51868',
      isUrban: false,
    },
    {
      name: 'CICLOVA ROMANA',
      zip: '51957',
      isUrban: false,
    },
    {
      name: 'ILIDIA',
      zip: '51966',
      isUrban: false,
    },
    {
      name: 'SOCOLARI',
      zip: '51975',
      isUrban: false,
    },
    {
      name: 'CIUCHICI',
      zip: '51993',
      isUrban: false,
    },
    {
      name: 'MACOVISTE',
      zip: '52008',
      isUrban: false,
    },
    {
      name: 'NICOLINT',
      zip: '52017',
      isUrban: false,
    },
    {
      name: 'PETRILOVA',
      zip: '52026',
      isUrban: false,
    },
    {
      name: 'CIUDANOVITA',
      zip: '52044',
      isUrban: false,
    },
    {
      name: 'JITIN',
      zip: '52053',
      isUrban: false,
    },
    {
      name: 'CAVARAN',
      zip: '51886',
      isUrban: false,
    },
    {
      name: 'MACIOVA',
      zip: '51895',
      isUrban: false,
    },
    {
      name: 'MATNICU MARE',
      zip: '51902',
      isUrban: false,
    },
    {
      name: 'PESTERE',
      zip: '51911',
      isUrban: false,
    },
    {
      name: 'PRISACA',
      zip: '51920',
      isUrban: false,
    },
    {
      name: 'ZAGUJENI',
      zip: '51939',
      isUrban: false,
    },
    {
      name: 'COPACELE',
      zip: '52071',
      isUrban: false,
    },
    {
      name: 'OHABA-MATNIC',
      zip: '52080',
      isUrban: false,
    },
    {
      name: 'RUGINOSU',
      zip: '52099',
      isUrban: false,
    },
    {
      name: 'ZORILE',
      zip: '52106',
      isUrban: false,
    },
    {
      name: 'CORNEA',
      zip: '52124',
      isUrban: false,
    },
    {
      name: 'CRUSOVAT',
      zip: '52133',
      isUrban: false,
    },
    {
      name: 'CUPTOARE',
      zip: '52142',
      isUrban: false,
    },
    {
      name: 'MACOVISTE',
      zip: '52151',
      isUrban: false,
    },
    {
      name: 'CORNEREVA',
      zip: '52179',
      isUrban: false,
    },
    {
      name: 'ARSURI',
      zip: '52188',
      isUrban: false,
    },
    {
      name: 'BOGALTIN',
      zip: '52197',
      isUrban: false,
    },
    {
      name: 'BOJIA',
      zip: '52204',
      isUrban: false,
    },
    {
      name: 'BORUGI',
      zip: '52213',
      isUrban: false,
    },
    {
      name: 'CAMENA',
      zip: '52222',
      isUrban: false,
    },
    {
      name: 'CIRESEL',
      zip: '52231',
      isUrban: false,
    },
    {
      name: 'COSTIS',
      zip: '52240',
      isUrban: false,
    },
    {
      name: 'COZIA',
      zip: '52259',
      isUrban: false,
    },
    {
      name: 'CRACU MARE',
      zip: '52268',
      isUrban: false,
    },
    {
      name: 'CRACU TEIULUI',
      zip: '52277',
      isUrban: false,
    },
    {
      name: 'DOBRAIA',
      zip: '52286',
      isUrban: false,
    },
    {
      name: 'DOLINA',
      zip: '52295',
      isUrban: false,
    },
    {
      name: 'GRUNI',
      zip: '52302',
      isUrban: false,
    },
    {
      name: 'HORA MARE',
      zip: '52311',
      isUrban: false,
    },
    {
      name: 'HORA MICA',
      zip: '52320',
      isUrban: false,
    },
    {
      name: 'INELET',
      zip: '52339',
      isUrban: false,
    },
    {
      name: 'IZVOR',
      zip: '52348',
      isUrban: false,
    },
    {
      name: 'LUNCA FLORII',
      zip: '52357',
      isUrban: false,
    },
    {
      name: 'LUNCA ZAICII',
      zip: '52366',
      isUrban: false,
    },
    {
      name: 'MESTEACAN',
      zip: '52375',
      isUrban: false,
    },
    {
      name: 'NEGIUDIN',
      zip: '52384',
      isUrban: false,
    },
    {
      name: 'OBITA',
      zip: '52393',
      isUrban: false,
    },
    {
      name: 'RUSTIN',
      zip: '52455',
      isUrban: false,
    },
    {
      name: 'SCARISOARA',
      zip: '52464',
      isUrban: false,
    },
    {
      name: 'STRUGASCA',
      zip: '52473',
      isUrban: false,
    },
    {
      name: 'STUDENA',
      zip: '52482',
      isUrban: false,
    },
    {
      name: 'SUB CRANG',
      zip: '52491',
      isUrban: false,
    },
    {
      name: 'SUB PLAI',
      zip: '52507',
      isUrban: false,
    },
    {
      name: 'TATU',
      zip: '52525',
      isUrban: false,
    },
    {
      name: 'TOPLA',
      zip: '52516',
      isUrban: false,
    },
    {
      name: 'ZANOGI',
      zip: '52534',
      isUrban: false,
    },
    {
      name: 'ZBEGU',
      zip: '52543',
      isUrban: false,
    },
    {
      name: 'ZMOGOTIN',
      zip: '52552',
      isUrban: false,
    },
    {
      name: 'ZOINA',
      zip: '52561',
      isUrban: false,
    },
    {
      name: 'CORONINI',
      zip: '53498',
      isUrban: false,
    },
    {
      name: 'SFANTA ELENA',
      zip: '53504',
      isUrban: false,
    },
    {
      name: 'DALBOSET',
      zip: '52589',
      isUrban: false,
    },
    {
      name: 'BARZ',
      zip: '52598',
      isUrban: false,
    },
    {
      name: 'BOINA',
      zip: '52605',
      isUrban: false,
    },
    {
      name: 'BOINITA',
      zip: '52614',
      isUrban: false,
    },
    {
      name: 'PRISLOP',
      zip: '52623',
      isUrban: false,
    },
    {
      name: 'RESITA MICA',
      zip: '52632',
      isUrban: false,
    },
    {
      name: 'SOPOTU VECHI',
      zip: '52641',
      isUrban: false,
    },
    {
      name: 'DOCLIN',
      zip: '52669',
      isUrban: false,
    },
    {
      name: 'BINIS',
      zip: '52678',
      isUrban: false,
    },
    {
      name: 'TIROL',
      zip: '52687',
      isUrban: false,
    },
    {
      name: 'DOGNECEA',
      zip: '52703',
      isUrban: false,
    },
    {
      name: 'CALINA',
      zip: '52712',
      isUrban: false,
    },
    {
      name: 'DOMASNEA',
      zip: '52730',
      isUrban: false,
    },
    {
      name: 'CANICEA',
      zip: '52749',
      isUrban: false,
    },
    {
      name: 'EFTIMIE MURGU',
      zip: '53719',
      isUrban: false,
    },
    {
      name: 'EZERIS',
      zip: '52767',
      isUrban: false,
    },
    {
      name: 'SOCENI',
      zip: '52776',
      isUrban: false,
    },
    {
      name: 'FARLIUG',
      zip: '52794',
      isUrban: false,
    },
    {
      name: 'DEZESTI',
      zip: '52801',
      isUrban: false,
    },
    {
      name: 'DULEU',
      zip: '52810',
      isUrban: false,
    },
    {
      name: 'REMETEA-POGANICI',
      zip: '52829',
      isUrban: false,
    },
    {
      name: 'SCAIUS',
      zip: '52838',
      isUrban: false,
    },
    {
      name: 'VALEA MARE',
      zip: '52847',
      isUrban: false,
    },
    {
      name: 'FOROTIC',
      zip: '52865',
      isUrban: false,
    },
    {
      name: 'BREZON',
      zip: '52874',
      isUrban: false,
    },
    {
      name: 'COMORASTE',
      zip: '52883',
      isUrban: false,
    },
    {
      name: 'SURDUCU MARE',
      zip: '52892',
      isUrban: false,
    },
    {
      name: 'GARNIC',
      zip: '52918',
      isUrban: false,
    },
    {
      name: 'PADINA MATEI',
      zip: '52927',
      isUrban: false,
    },
    {
      name: 'GLIMBOCA',
      zip: '52945',
      isUrban: false,
    },
    {
      name: 'GORUIA',
      zip: '52963',
      isUrban: false,
    },
    {
      name: 'GARLISTE',
      zip: '52981',
      isUrban: false,
    },
    {
      name: 'GIURGIOVA',
      zip: '52972',
      isUrban: false,
    },
    {
      name: 'GRADINARI',
      zip: '53005',
      isUrban: false,
    },
    {
      name: 'GREONI',
      zip: '53014',
      isUrban: false,
    },
    {
      name: 'IABLANITA',
      zip: '53032',
      isUrban: false,
    },
    {
      name: 'GLOBU CRAIOVEI',
      zip: '53041',
      isUrban: false,
    },
    {
      name: 'PETNIC',
      zip: '53050',
      isUrban: false,
    },
    {
      name: 'LAPUSNICEL',
      zip: '53078',
      isUrban: false,
    },
    {
      name: 'PARVOVA',
      zip: '53087',
      isUrban: false,
    },
    {
      name: 'SUMITA',
      zip: '53096',
      isUrban: false,
    },
    {
      name: 'LAPUSNICU MARE',
      zip: '53112',
      isUrban: false,
    },
    {
      name: 'MOCERIS',
      zip: '53121',
      isUrban: false,
    },
    {
      name: 'LUNCAVITA',
      zip: '53149',
      isUrban: false,
    },
    {
      name: 'VERENDIN',
      zip: '53158',
      isUrban: false,
    },
    {
      name: 'LUPAC',
      zip: '53176',
      isUrban: false,
    },
    {
      name: 'CLOCOTICI',
      zip: '53185',
      isUrban: false,
    },
    {
      name: 'RAFNIC',
      zip: '53194',
      isUrban: false,
    },
    {
      name: 'VODNIC',
      zip: '53201',
      isUrban: false,
    },
    {
      name: 'MARGA',
      zip: '53229',
      isUrban: false,
    },
    {
      name: 'VAMA MARGA',
      zip: '53238',
      isUrban: false,
    },
    {
      name: 'MAURENI',
      zip: '53256',
      isUrban: false,
    },
    {
      name: 'SOSDEA',
      zip: '53265',
      isUrban: false,
    },
    {
      name: 'MEHADIA',
      zip: '53283',
      isUrban: false,
    },
    {
      name: 'GLOBURAU',
      zip: '53292',
      isUrban: false,
    },
    {
      name: 'PLUGOVA',
      zip: '53309',
      isUrban: false,
    },
    {
      name: 'VALEA BOLVASNITA',
      zip: '53318',
      isUrban: false,
    },
    {
      name: 'MEHADICA',
      zip: '53336',
      isUrban: false,
    },
    {
      name: 'NAIDAS',
      zip: '53354',
      isUrban: false,
    },
    {
      name: 'LESCOVITA',
      zip: '53363',
      isUrban: false,
    },
    {
      name: 'OBREJA',
      zip: '53381',
      isUrban: false,
    },
    {
      name: 'CIUTA',
      zip: '53390',
      isUrban: false,
    },
    {
      name: 'IAZ',
      zip: '53407',
      isUrban: false,
    },
    {
      name: 'VAR',
      zip: '53416',
      isUrban: false,
    },
    {
      name: 'OCNA DE FIER',
      zip: '50996',
      isUrban: false,
    },
    {
      name: 'PALTINIS',
      zip: '53434',
      isUrban: false,
    },
    {
      name: 'CORNUTEL',
      zip: '53443',
      isUrban: false,
    },
    {
      name: 'DELINESTI',
      zip: '53452',
      isUrban: false,
    },
    {
      name: 'OHABITA',
      zip: '53461',
      isUrban: false,
    },
    {
      name: 'RUGI',
      zip: '53470',
      isUrban: false,
    },
    {
      name: 'POJEJENA',
      zip: '53522',
      isUrban: false,
    },
    {
      name: 'BELOBRESCA',
      zip: '53531',
      isUrban: false,
    },
    {
      name: 'DIVICI',
      zip: '53540',
      isUrban: false,
    },
    {
      name: 'RADIMNA',
      zip: '53559',
      isUrban: false,
    },
    {
      name: 'SUSCA',
      zip: '53568',
      isUrban: false,
    },
    {
      name: 'PRIGOR',
      zip: '53586',
      isUrban: false,
    },
    {
      name: 'BORLOVENII NOI',
      zip: '53595',
      isUrban: false,
    },
    {
      name: 'BORLOVENII VECHI',
      zip: '53602',
      isUrban: false,
    },
    {
      name: 'PATAS',
      zip: '53611',
      isUrban: false,
    },
    {
      name: 'PUTNA',
      zip: '53620',
      isUrban: false,
    },
    {
      name: 'RACASDIA',
      zip: '53684',
      isUrban: false,
    },
    {
      name: 'VRANIUT',
      zip: '53693',
      isUrban: false,
    },
    {
      name: 'RAMNA',
      zip: '53648',
      isUrban: false,
    },
    {
      name: 'BARBOSU',
      zip: '53657',
      isUrban: false,
    },
    {
      name: 'VALEAPAI',
      zip: '53666',
      isUrban: false,
    },
    {
      name: 'RUSCA MONTANA',
      zip: '53737',
      isUrban: false,
    },
    {
      name: 'RUSCHITA',
      zip: '53746',
      isUrban: false,
    },
    {
      name: 'SACU',
      zip: '53764',
      isUrban: false,
    },
    {
      name: 'SALBAGELU NOU',
      zip: '53773',
      isUrban: false,
    },
    {
      name: 'TINCOVA',
      zip: '53782',
      isUrban: false,
    },
    {
      name: 'SASCA MONTANA',
      zip: '53808',
      isUrban: false,
    },
    {
      name: 'BOGODINT',
      zip: '53817',
      isUrban: false,
    },
    {
      name: 'POTOC',
      zip: '53826',
      isUrban: false,
    },
    {
      name: 'SASCA ROMANA',
      zip: '53835',
      isUrban: false,
    },
    {
      name: 'SLATINA-NERA',
      zip: '53844',
      isUrban: false,
    },
    {
      name: 'SICHEVITA',
      zip: '53862',
      isUrban: false,
    },
    {
      name: 'BRESTELNIC',
      zip: '53871',
      isUrban: false,
    },
    {
      name: 'CAMENITA',
      zip: '53880',
      isUrban: false,
    },
    {
      name: 'CARSIE',
      zip: '53899',
      isUrban: false,
    },
    {
      name: 'CRACU ALMAJ',
      zip: '53906',
      isUrban: false,
    },
    {
      name: 'CRUSOVITA',
      zip: '53915',
      isUrban: false,
    },
    {
      name: 'CURMATURA',
      zip: '53924',
      isUrban: false,
    },
    {
      name: 'FRASINIS',
      zip: '53933',
      isUrban: false,
    },
    {
      name: 'GORNEA',
      zip: '53942',
      isUrban: false,
    },
    {
      name: 'LIBORAJDEA',
      zip: '53951',
      isUrban: false,
    },
    {
      name: 'LUCACEVAT',
      zip: '53960',
      isUrban: false,
    },
    {
      name: 'MARTINOVAT',
      zip: '53979',
      isUrban: false,
    },
    {
      name: 'OGASU PODULUI',
      zip: '53988',
      isUrban: false,
    },
    {
      name: 'STRENEAC',
      zip: '53997',
      isUrban: false,
    },
    {
      name: 'VALEA OREVITA',
      zip: '54001',
      isUrban: false,
    },
    {
      name: 'VALEA RAVENSCA',
      zip: '54010',
      isUrban: false,
    },
    {
      name: 'VALEA SICHEVITEI',
      zip: '54029',
      isUrban: false,
    },
    {
      name: 'ZANOU',
      zip: '54038',
      isUrban: false,
    },
    {
      name: 'ZASLOANE',
      zip: '54047',
      isUrban: false,
    },
    {
      name: 'SLATINA-TIMIS',
      zip: '54065',
      isUrban: false,
    },
    {
      name: 'ILOVA',
      zip: '54074',
      isUrban: false,
    },
    {
      name: 'SADOVA NOUA',
      zip: '54083',
      isUrban: false,
    },
    {
      name: 'SADOVA VECHE',
      zip: '54092',
      isUrban: false,
    },
    {
      name: 'SOCOL',
      zip: '54118',
      isUrban: false,
    },
    {
      name: 'BAZIAS',
      zip: '54127',
      isUrban: false,
    },
    {
      name: 'CAMPIA',
      zip: '54136',
      isUrban: false,
    },
    {
      name: 'PARNEAURA',
      zip: '54145',
      isUrban: false,
    },
    {
      name: 'ZLATITA',
      zip: '54154',
      isUrban: false,
    },
    {
      name: 'SOPOTU NOU',
      zip: '54172',
      isUrban: false,
    },
    {
      name: 'CARSA ROSIE',
      zip: '54181',
      isUrban: false,
    },
    {
      name: 'DRISTIE',
      zip: '54190',
      isUrban: false,
    },
    {
      name: 'POIENILE BOINEI',
      zip: '54207',
      isUrban: false,
    },
    {
      name: 'RACHITA',
      zip: '54225',
      isUrban: false,
    },
    {
      name: 'RAVENSCA',
      zip: '54216',
      isUrban: false,
    },
    {
      name: 'STANCILOVA',
      zip: '54234',
      isUrban: false,
    },
    {
      name: 'URCU',
      zip: '54243',
      isUrban: false,
    },
    {
      name: 'VALEA RACHITEI',
      zip: '54252',
      isUrban: false,
    },
    {
      name: 'VALEA ROSIE',
      zip: '54261',
      isUrban: false,
    },
    {
      name: 'TARNOVA',
      zip: '54369',
      isUrban: false,
    },
    {
      name: 'BRATOVA',
      zip: '54378',
      isUrban: false,
    },
    {
      name: 'TEREGOVA',
      zip: '54289',
      isUrban: false,
    },
    {
      name: 'RUSCA',
      zip: '54298',
      isUrban: false,
    },
    {
      name: 'TICVANIU MARE',
      zip: '54314',
      isUrban: false,
    },
    {
      name: 'CARNECEA',
      zip: '54323',
      isUrban: false,
    },
    {
      name: 'SECASENI',
      zip: '54332',
      isUrban: false,
    },
    {
      name: 'TICVANIU MIC',
      zip: '54341',
      isUrban: false,
    },
    {
      name: 'TOPLET',
      zip: '54396',
      isUrban: false,
    },
    {
      name: 'BARZA',
      zip: '54403',
      isUrban: false,
    },
    {
      name: 'TURNU RUIENI',
      zip: '54421',
      isUrban: false,
    },
    {
      name: 'BORLOVA',
      zip: '54430',
      isUrban: false,
    },
    {
      name: 'CICLENI',
      zip: '54449',
      isUrban: false,
    },
    {
      name: 'DALCI',
      zip: '54458',
      isUrban: false,
    },
    {
      name: 'ZERVESTI',
      zip: '54467',
      isUrban: false,
    },
    {
      name: 'ZLAGNA',
      zip: '54476',
      isUrban: false,
    },
    {
      name: 'VALIUG',
      zip: '54494',
      isUrban: false,
    },
    {
      name: 'VARADIA',
      zip: '54519',
      isUrban: false,
    },
    {
      name: 'MERCINA',
      zip: '54528',
      isUrban: false,
    },
    {
      name: 'VERMES',
      zip: '54546',
      isUrban: false,
    },
    {
      name: 'ERSIG',
      zip: '54555',
      isUrban: false,
    },
    {
      name: 'IZGAR',
      zip: '54564',
      isUrban: false,
    },
    {
      name: 'VRANI',
      zip: '54582',
      isUrban: false,
    },
    {
      name: 'CIORTEA',
      zip: '54591',
      isUrban: false,
    },
    {
      name: 'IERTOF',
      zip: '54608',
      isUrban: false,
    },
    {
      name: 'ZAVOI',
      zip: '54626',
      isUrban: false,
    },
    {
      name: '',
      zip: '54680',
      isUrban: false,
    },
    {
      name: 'MAGURA',
      zip: '54635',
      isUrban: false,
    },
    {
      name: 'MARU',
      zip: '54644',
      isUrban: false,
    },
    {
      name: 'POIANA MARULUI',
      zip: '54653',
      isUrban: false,
    },
    {
      name: 'VALEA BISTREI',
      zip: '54662',
      isUrban: false,
    },
    {
      name: 'VOISLOVA',
      zip: '54671',
      isUrban: false,
    },
    {
      name: 'ZORLENTU MARE',
      zip: '54706',
      isUrban: false,
    },
    {
      name: 'ZORLENCIOR',
      zip: '54715',
      isUrban: false,
    },
    {
      name: 'POGARA',
      zip: '52419',
      isUrban: false,
    },
    {
      name: 'POGARA DE SUS',
      zip: '52400',
      isUrban: false,
    },
    {
      name: 'POIANA LUNGA',
      zip: '52428',
      isUrban: false,
    },
    {
      name: 'PRISACINA',
      zip: '52437',
      isUrban: false,
    },
    {
      name: 'PRISLOP',
      zip: '52446',
      isUrban: false,
    }
  ],
  CJ: [
    {
      name: 'CLUJ-NAPOCA',
      zip: '54984',
      isUrban: true,
    },
    {
      name: 'DEJ',
      zip: '55017',
      isUrban: true,
    },
    {
      name: 'OCNA DEJULUI',
      zip: '55026',
      isUrban: true,
    },
    {
      name: 'PESTERA',
      zip: '55035',
      isUrban: true,
    },
    {
      name: 'PINTIC',
      zip: '55044',
      isUrban: true,
    },
    {
      name: 'SOMCUTU MIC',
      zip: '55053',
      isUrban: true,
    },
    {
      name: 'TURDA',
      zip: '55268',
      isUrban: true,
    },
    {
      name: 'CAMPIA TURZII',
      zip: '55366',
      isUrban: true,
    },
    {
      name: 'GHERLA',
      zip: '55393',
      isUrban: true,
    },
    {
      name: 'BAITA',
      zip: '55400',
      isUrban: true,
    },
    {
      name: 'HASDATE',
      zip: '55419',
      isUrban: true,
    },
    {
      name: 'SILIVAS',
      zip: '55428',
      isUrban: true,
    },
    {
      name: 'HUEDIN',
      zip: '55455',
      isUrban: true,
    },
    {
      name: 'BICALATU',
      zip: '55464',
      isUrban: true,
    },
    {
      name: 'AGHIRESU',
      zip: '55482',
      isUrban: false,
    },
    {
      name: 'AGHIRESU-FABRICI',
      zip: '55491',
      isUrban: false,
    },
    {
      name: 'ARGHISU',
      zip: '55507',
      isUrban: false,
    },
    {
      name: 'BAGARA',
      zip: '55516',
      isUrban: false,
    },
    {
      name: 'DANCU',
      zip: '55525',
      isUrban: false,
    },
    {
      name: 'DOROLTU',
      zip: '55534',
      isUrban: false,
    },
    {
      name: 'INUCU',
      zip: '55543',
      isUrban: false,
    },
    {
      name: 'LEGHIA',
      zip: '55552',
      isUrban: false,
    },
    {
      name: 'MACAU',
      zip: '55561',
      isUrban: false,
    },
    {
      name: 'TICU',
      zip: '55570',
      isUrban: false,
    },
    {
      name: 'TICU-COLONIE',
      zip: '55589',
      isUrban: false,
    },
    {
      name: 'AITON',
      zip: '55605',
      isUrban: false,
    },
    {
      name: 'REDIU',
      zip: '55614',
      isUrban: false,
    },
    {
      name: 'ALUNIS',
      zip: '55632',
      isUrban: false,
    },
    {
      name: 'CORNENI',
      zip: '55641',
      isUrban: false,
    },
    {
      name: 'GHIROLT',
      zip: '55650',
      isUrban: false,
    },
    {
      name: 'PRUNENI',
      zip: '55669',
      isUrban: false,
    },
    {
      name: 'VALE',
      zip: '55678',
      isUrban: false,
    },
    {
      name: 'APAHIDA',
      zip: '55696',
      isUrban: false,
    },
    {
      name: 'BODROG',
      zip: '55703',
      isUrban: false,
    },
    {
      name: 'CAMPENESTI',
      zip: '55712',
      isUrban: false,
    },
    {
      name: 'CORPADEA',
      zip: '55721',
      isUrban: false,
    },
    {
      name: 'DEZMIR',
      zip: '55730',
      isUrban: false,
    },
    {
      name: 'PATA',
      zip: '55749',
      isUrban: false,
    },
    {
      name: 'SANNICOARA',
      zip: '55758',
      isUrban: false,
    },
    {
      name: 'SUB COASTA',
      zip: '55767',
      isUrban: false,
    },
    {
      name: 'ASCHILEU MARE',
      zip: '55785',
      isUrban: false,
    },
    {
      name: 'ASCHILEU MIC',
      zip: '55794',
      isUrban: false,
    },
    {
      name: 'CRISTOREL',
      zip: '55801',
      isUrban: false,
    },
    {
      name: 'DORNA',
      zip: '55810',
      isUrban: false,
    },
    {
      name: 'FODORA',
      zip: '55829',
      isUrban: false,
    },
    {
      name: 'BACIU',
      zip: '55847',
      isUrban: false,
    },
    {
      name: 'CORUSU',
      zip: '55856',
      isUrban: false,
    },
    {
      name: 'POPESTI',
      zip: '55874',
      isUrban: false,
    },
    {
      name: 'RADAIA',
      zip: '55883',
      isUrban: false,
    },
    {
      name: 'SALISTEA NOUA',
      zip: '55892',
      isUrban: false,
    },
    {
      name: 'SUCEAGU',
      zip: '55909',
      isUrban: false,
    },
    {
      name: 'BAISOARA',
      zip: '55927',
      isUrban: false,
    },
    {
      name: 'FRASINET',
      zip: '55936',
      isUrban: false,
    },
    {
      name: 'MOARA DE PADURE',
      zip: '55945',
      isUrban: false,
    },
    {
      name: 'MUNTELE BAISORII',
      zip: '55954',
      isUrban: false,
    },
    {
      name: 'MUNTELE BOCULUI',
      zip: '55963',
      isUrban: false,
    },
    {
      name: 'MUNTELE CACOVEI',
      zip: '55972',
      isUrban: false,
    },
    {
      name: 'MUNTELE FILII',
      zip: '55981',
      isUrban: false,
    },
    {
      name: 'MUNTELE SACELULUI',
      zip: '55990',
      isUrban: false,
    },
    {
      name: 'SACEL',
      zip: '56005',
      isUrban: false,
    },
    {
      name: 'BELIS',
      zip: '56023',
      isUrban: false,
    },
    {
      name: 'BALCESTI',
      zip: '56032',
      isUrban: false,
    },
    {
      name: 'DEALU BOTII',
      zip: '56041',
      isUrban: false,
    },
    {
      name: 'GIURCUTA DE JOS',
      zip: '56050',
      isUrban: false,
    },
    {
      name: 'GIURCUTA DE SUS',
      zip: '56069',
      isUrban: false,
    },
    {
      name: 'POIANA HOREA',
      zip: '56078',
      isUrban: false,
    },
    {
      name: 'SMIDA',
      zip: '56087',
      isUrban: false,
    },
    {
      name: 'BOBALNA',
      zip: '56103',
      isUrban: false,
    },
    {
      name: 'ANTAS',
      zip: '56112',
      isUrban: false,
    },
    {
      name: 'BABDIU',
      zip: '56121',
      isUrban: false,
    },
    {
      name: 'BLIDARESTI',
      zip: '56130',
      isUrban: false,
    },
    {
      name: 'CREMENEA',
      zip: '56149',
      isUrban: false,
    },
    {
      name: 'MAIA',
      zip: '56158',
      isUrban: false,
    },
    {
      name: 'OSORHEL',
      zip: '56167',
      isUrban: false,
    },
    {
      name: 'PRUNI',
      zip: '56176',
      isUrban: false,
    },
    {
      name: 'RAZBUNENI',
      zip: '56185',
      isUrban: false,
    },
    {
      name: 'SUARAS',
      zip: '56194',
      isUrban: false,
    },
    {
      name: 'VALCELELE',
      zip: '56201',
      isUrban: false,
    },
    {
      name: 'BONTIDA',
      zip: '56229',
      isUrban: false,
    },
    {
      name: 'COASTA',
      zip: '56238',
      isUrban: false,
    },
    {
      name: 'RASCRUCI',
      zip: '56247',
      isUrban: false,
    },
    {
      name: 'TAUSENI',
      zip: '56256',
      isUrban: false,
    },
    {
      name: 'BORSA',
      zip: '56274',
      isUrban: false,
    },
    {
      name: 'BORSA-CATUN',
      zip: '56283',
      isUrban: false,
    },
    {
      name: 'BORSA-CRESTAIA',
      zip: '56292',
      isUrban: false,
    },
    {
      name: 'CIUMAFAIA',
      zip: '56309',
      isUrban: false,
    },
    {
      name: 'GIULA',
      zip: '56318',
      isUrban: false,
    },
    {
      name: 'BUZA',
      zip: '56336',
      isUrban: false,
    },
    {
      name: 'ROTUNDA',
      zip: '56345',
      isUrban: false,
    },
    {
      name: 'CAIANU',
      zip: '56363',
      isUrban: false,
    },
    {
      name: 'BARAI',
      zip: '56372',
      isUrban: false,
    },
    {
      name: 'CAIANU MIC',
      zip: '56381',
      isUrban: false,
    },
    {
      name: 'CAIANU-VAMA',
      zip: '56390',
      isUrban: false,
    },
    {
      name: 'VAIDA-CAMARAS',
      zip: '56407',
      isUrban: false,
    },
    {
      name: 'VALENI',
      zip: '56416',
      isUrban: false,
    },
    {
      name: 'CALARASI',
      zip: '56434',
      isUrban: false,
    },
    {
      name: 'BOGATA',
      zip: '56443',
      isUrban: false,
    },
    {
      name: 'CALARASI-GARA',
      zip: '56452',
      isUrban: false,
    },
    {
      name: 'CALATELE',
      zip: '56470',
      isUrban: false,
    },
    {
      name: 'CALATA',
      zip: '56489',
      isUrban: false,
    },
    {
      name: 'DEALU NEGRU',
      zip: '56498',
      isUrban: false,
    },
    {
      name: 'FINCIU',
      zip: '56504',
      isUrban: false,
    },
    {
      name: 'VALENI',
      zip: '56513',
      isUrban: false,
    },
    {
      name: 'CAMARASU',
      zip: '56531',
      isUrban: false,
    },
    {
      name: 'SAMBOLENI',
      zip: '56559',
      isUrban: false,
    },
    {
      name: 'CAPUSU MARE',
      zip: '56577',
      isUrban: false,
    },
    {
      name: 'AGARBICIU',
      zip: '56586',
      isUrban: false,
    },
    {
      name: 'BALCESTI',
      zip: '56595',
      isUrban: false,
    },
    {
      name: 'CAPUSU MIC',
      zip: '56602',
      isUrban: false,
    },
    {
      name: 'DANGAU MARE',
      zip: '56611',
      isUrban: false,
    },
    {
      name: 'DANGAU MIC',
      zip: '56620',
      isUrban: false,
    },
    {
      name: 'DUMBRAVA',
      zip: '56639',
      isUrban: false,
    },
    {
      name: 'PANICENI',
      zip: '56648',
      isUrban: false,
    },
    {
      name: 'STRAJA',
      zip: '56657',
      isUrban: false,
    },
    {
      name: 'CASEIU',
      zip: '56675',
      isUrban: false,
    },
    {
      name: 'COMORITA',
      zip: '56684',
      isUrban: false,
    },
    {
      name: 'COPLEAN',
      zip: '56693',
      isUrban: false,
    },
    {
      name: 'CUSTURA',
      zip: '56700',
      isUrban: false,
    },
    {
      name: 'GARBAU DEJULUI',
      zip: '56719',
      isUrban: false,
    },
    {
      name: 'GUGA',
      zip: '56728',
      isUrban: false,
    },
    {
      name: 'LEURDA',
      zip: '56737',
      isUrban: false,
    },
    {
      name: 'RUGASESTI',
      zip: '56746',
      isUrban: false,
    },
    {
      name: 'SALATRUC',
      zip: '56755',
      isUrban: false,
    },
    {
      name: 'URISOR',
      zip: '56764',
      isUrban: false,
    },
    {
      name: 'CATCAU',
      zip: '57323',
      isUrban: false,
    },
    {
      name: 'MUNCEL',
      zip: '57332',
      isUrban: false,
    },
    {
      name: 'SALISCA',
      zip: '57341',
      isUrban: false,
    },
    {
      name: 'CATINA',
      zip: '56782',
      isUrban: false,
    },
    {
      name: 'COPRU',
      zip: '56791',
      isUrban: false,
    },
    {
      name: 'FELDIOARA',
      zip: '56808',
      isUrban: false,
    },
    {
      name: 'HAGAU',
      zip: '56817',
      isUrban: false,
    },
    {
      name: 'HODAIE',
      zip: '56826',
      isUrban: false,
    },
    {
      name: 'VALEA CALDA',
      zip: '56835',
      isUrban: false,
    },
    {
      name: 'CEANU MARE',
      zip: '56853',
      isUrban: false,
    },
    {
      name: 'ANDICI',
      zip: '56862',
      isUrban: false,
    },
    {
      name: 'BOIAN',
      zip: '56871',
      isUrban: false,
    },
    {
      name: 'BOLDUT',
      zip: '56880',
      isUrban: false,
    },
    {
      name: 'CIURGAU',
      zip: '56899',
      isUrban: false,
    },
    {
      name: 'DOSU NAPULUI',
      zip: '56906',
      isUrban: false,
    },
    {
      name: 'FANATE',
      zip: '56915',
      isUrban: false,
    },
    {
      name: 'HODAI BOIAN',
      zip: '56924',
      isUrban: false,
    },
    {
      name: 'IACOBENI',
      zip: '56933',
      isUrban: false,
    },
    {
      name: 'MORTESTI',
      zip: '56942',
      isUrban: false,
    },
    {
      name: 'STARCU',
      zip: '56951',
      isUrban: false,
    },
    {
      name: 'STRUCUT',
      zip: '56960',
      isUrban: false,
    },
    {
      name: 'VALEA LUI CATI',
      zip: '56979',
      isUrban: false,
    },
    {
      name: 'CHINTENI',
      zip: '56997',
      isUrban: false,
    },
    {
      name: 'DEUSU',
      zip: '57001',
      isUrban: false,
    },
    {
      name: 'FEIURDENI',
      zip: '57010',
      isUrban: false,
    },
    {
      name: 'MACICASU',
      zip: '57029',
      isUrban: false,
    },
    {
      name: 'PADURENI',
      zip: '57038',
      isUrban: false,
    },
    {
      name: 'SALISTEA VECHE',
      zip: '57056',
      isUrban: false,
    },
    {
      name: 'SANMARTIN',
      zip: '57065',
      isUrban: false,
    },
    {
      name: 'SATU LUNG',
      zip: '57047',
      isUrban: false,
    },
    {
      name: 'VECHEA',
      zip: '57074',
      isUrban: false,
    },
    {
      name: 'CHIUIESTI',
      zip: '57092',
      isUrban: false,
    },
    {
      name: 'DOSU BRICII',
      zip: '57109',
      isUrban: false,
    },
    {
      name: 'HUTA',
      zip: '57118',
      isUrban: false,
    },
    {
      name: 'MAGOAJA',
      zip: '57127',
      isUrban: false,
    },
    {
      name: 'IURIU DE CAMPIE',
      zip: '57412',
      isUrban: false,
    },
    {
      name: 'MORISTI',
      zip: '57421',
      isUrban: false,
    },
    {
      name: 'STRAJA',
      zip: '57430',
      isUrban: false,
    },
    {
      name: 'CORNESTI',
      zip: '57458',
      isUrban: false,
    },
    {
      name: 'BARLEA',
      zip: '57467',
      isUrban: false,
    },
    {
      name: 'IGRITIA',
      zip: '57476',
      isUrban: false,
    },
    {
      name: 'LUJERDIU',
      zip: '57485',
      isUrban: false,
    },
    {
      name: 'MORAU',
      zip: '57494',
      isUrban: false,
    },
    {
      name: 'STOIANA',
      zip: '57500',
      isUrban: false,
    },
    {
      name: 'TIOCU DE JOS',
      zip: '57519',
      isUrban: false,
    },
    {
      name: 'TIOCU DE SUS',
      zip: '57528',
      isUrban: false,
    },
    {
      name: 'TIOLTIUR',
      zip: '57537',
      isUrban: false,
    },
    {
      name: 'CUZDRIOARA',
      zip: '55071',
      isUrban: false,
    },
    {
      name: 'MANASTUREL',
      zip: '55080',
      isUrban: false,
    },
    {
      name: 'VALEA GARBOULUI',
      zip: '55099',
      isUrban: false,
    },
    {
      name: 'DABACA',
      zip: '57555',
      isUrban: false,
    },
    {
      name: 'LUNA DE JOS',
      zip: '57564',
      isUrban: false,
    },
    {
      name: 'PAGLISA',
      zip: '57573',
      isUrban: false,
    },
    {
      name: 'FELEACU',
      zip: '57591',
      isUrban: false,
    },
    {
      name: 'CASELE MICESTI',
      zip: '57608',
      isUrban: false,
    },
    {
      name: 'GHEORGHIENI',
      zip: '57617',
      isUrban: false,
    },
    {
      name: 'SARADIS',
      zip: '57626',
      isUrban: false,
    },
    {
      name: 'VALCELE',
      zip: '57635',
      isUrban: false,
    },
    {
      name: 'FIZESU GHERLII',
      zip: '57653',
      isUrban: false,
    },
    {
      name: 'BONT',
      zip: '57662',
      isUrban: false,
    },
    {
      name: 'LUNCA BONTULUI',
      zip: '57671',
      isUrban: false,
    },
    {
      name: 'NICULA',
      zip: '57680',
      isUrban: false,
    },
    {
      name: 'SACALAIA',
      zip: '57699',
      isUrban: false,
    },
    {
      name: 'FLORESTI',
      zip: '57715',
      isUrban: false,
    },
    {
      name: 'LUNA DE SUS',
      zip: '57724',
      isUrban: false,
    },
    {
      name: 'TAUTI',
      zip: '57733',
      isUrban: false,
    },
    {
      name: 'FRATA',
      zip: '57751',
      isUrban: false,
    },
    {
      name: 'BERCHIESU',
      zip: '57760',
      isUrban: false,
    },
    {
      name: 'OAS',
      zip: '57779',
      isUrban: false,
    },
    {
      name: 'OLARIU',
      zip: '57788',
      isUrban: false,
    },
    {
      name: 'PADUREA IACOBENI',
      zip: '57797',
      isUrban: false,
    },
    {
      name: 'POIANA FRATII',
      zip: '57804',
      isUrban: false,
    },
    {
      name: 'RAZOARE',
      zip: '57813',
      isUrban: false,
    },
    {
      name: 'SOPORU DE CAMPIE',
      zip: '57822',
      isUrban: false,
    },
    {
      name: 'GARBAU',
      zip: '57957',
      isUrban: false,
    },
    {
      name: 'CORNESTI',
      zip: '57966',
      isUrban: false,
    },
    {
      name: 'NADASELU',
      zip: '57975',
      isUrban: false,
    },
    {
      name: 'TUREA',
      zip: '57993',
      isUrban: false,
    },
    {
      name: 'VISTEA',
      zip: '57984',
      isUrban: false,
    },
    {
      name: 'GEACA',
      zip: '57840',
      isUrban: false,
    },
    {
      name: 'CHIRIS',
      zip: '57859',
      isUrban: false,
    },
    {
      name: 'LACU',
      zip: '57868',
      isUrban: false,
    },
    {
      name: 'LEGII',
      zip: '57877',
      isUrban: false,
    },
    {
      name: 'PUINI',
      zip: '57886',
      isUrban: false,
    },
    {
      name: 'SUCUTARD',
      zip: '57895',
      isUrban: false,
    },
    {
      name: 'GILAU',
      zip: '57911',
      isUrban: false,
    },
    {
      name: 'SOMESU CALD',
      zip: '57920',
      isUrban: false,
    },
    {
      name: 'SOMESU RECE',
      zip: '57939',
      isUrban: false,
    },
    {
      name: 'IARA',
      zip: '58017',
      isUrban: false,
    },
    {
      name: 'AGRIS',
      zip: '58026',
      isUrban: false,
    },
    {
      name: 'BORZESTI',
      zip: '58035',
      isUrban: false,
    },
    {
      name: 'BURU',
      zip: '58044',
      isUrban: false,
    },
    {
      name: 'CACOVA IERII',
      zip: '58053',
      isUrban: false,
    },
    {
      name: 'FAGETU IERII',
      zip: '58062',
      isUrban: false,
    },
    {
      name: 'LUNGESTI',
      zip: '58071',
      isUrban: false,
    },
    {
      name: 'MAGURA IERII',
      zip: '58099',
      isUrban: false,
    },
    {
      name: 'MASCA',
      zip: '58080',
      isUrban: false,
    },
    {
      name: 'OCOLISEL',
      zip: '58106',
      isUrban: false,
    },
    {
      name: 'SURDUC',
      zip: '58115',
      isUrban: false,
    },
    {
      name: 'VALEA AGRISULUI',
      zip: '58124',
      isUrban: false,
    },
    {
      name: 'VALEA VADULUI',
      zip: '58133',
      isUrban: false,
    },
    {
      name: 'ICLOD',
      zip: '58151',
      isUrban: false,
    },
    {
      name: 'FUNDATURA',
      zip: '58160',
      isUrban: false,
    },
    {
      name: 'ICLOZEL',
      zip: '58179',
      isUrban: false,
    },
    {
      name: 'LIVADA',
      zip: '58188',
      isUrban: false,
    },
    {
      name: 'ORMAN',
      zip: '58197',
      isUrban: false,
    },
    {
      name: 'IZVORU CRISULUI',
      zip: '58213',
      isUrban: false,
    },
    {
      name: 'NADASU',
      zip: '58222',
      isUrban: false,
    },
    {
      name: 'NEARSOVA',
      zip: '58231',
      isUrban: false,
    },
    {
      name: 'SAULA',
      zip: '58240',
      isUrban: false,
    },
    {
      name: 'JICHISU DE JOS',
      zip: '55115',
      isUrban: false,
    },
    {
      name: 'CODOR',
      zip: '55124',
      isUrban: false,
    },
    {
      name: 'JICHISU DE SUS',
      zip: '55133',
      isUrban: false,
    },
    {
      name: 'SIGAU',
      zip: '55142',
      isUrban: false,
    },
    {
      name: 'TARPIU',
      zip: '55151',
      isUrban: false,
    },
    {
      name: 'JUCU DE SUS',
      zip: '58268',
      isUrban: false,
    },
    {
      name: 'GADALIN',
      zip: '58277',
      isUrban: false,
    },
    {
      name: 'JUC-HERGHELIE',
      zip: '58286',
      isUrban: false,
    },
    {
      name: 'JUCU DE MIJLOC',
      zip: '58295',
      isUrban: false,
    },
    {
      name: 'VISEA',
      zip: '58302',
      isUrban: false,
    },
    {
      name: 'LUNA',
      zip: '58320',
      isUrban: false,
    },
    {
      name: 'GLIGORESTI',
      zip: '58339',
      isUrban: false,
    },
    {
      name: 'LUNCANI',
      zip: '58348',
      isUrban: false,
    },
    {
      name: 'MAGURI-RACATAU',
      zip: '58366',
      isUrban: false,
    },
    {
      name: 'MAGURI',
      zip: '58375',
      isUrban: false,
    },
    {
      name: 'MUNTELE RECE',
      zip: '58384',
      isUrban: false,
    },
    {
      name: 'MANASTIRENI',
      zip: '58400',
      isUrban: false,
    },
    {
      name: 'ARDEOVA',
      zip: '58419',
      isUrban: false,
    },
    {
      name: 'BEDECIU',
      zip: '58428',
      isUrban: false,
    },
    {
      name: 'BICA',
      zip: '58437',
      isUrban: false,
    },
    {
      name: 'DRETEA',
      zip: '58446',
      isUrban: false,
    },
    {
      name: 'MANASTURU ROMANESC',
      zip: '58455',
      isUrban: false,
    },
    {
      name: 'MARGAU',
      zip: '58473',
      isUrban: false,
    },
    {
      name: 'BOCIU',
      zip: '58482',
      isUrban: false,
    },
    {
      name: 'BUTENI',
      zip: '58491',
      isUrban: false,
    },
    {
      name: 'CIULENI',
      zip: '58507',
      isUrban: false,
    },
    {
      name: 'RACHITELE',
      zip: '58516',
      isUrban: false,
    },
    {
      name: 'SCRIND-FRASINET',
      zip: '58525',
      isUrban: false,
    },
    {
      name: 'MARISEL',
      zip: '58543',
      isUrban: false,
    },
    {
      name: 'MICA',
      zip: '55179',
      isUrban: false,
    },
    {
      name: 'DAMBU MARE',
      zip: '55188',
      isUrban: false,
    },
    {
      name: 'MANASTIREA',
      zip: '55197',
      isUrban: false,
    },
    {
      name: 'NIRES',
      zip: '55204',
      isUrban: false,
    },
    {
      name: 'SANMARGHITA',
      zip: '55213',
      isUrban: false,
    },
    {
      name: 'VALEA CIRESOII',
      zip: '55222',
      isUrban: false,
    },
    {
      name: 'VALEA LUNCII',
      zip: '55231',
      isUrban: false,
    },
    {
      name: 'MIHAI VITEAZU',
      zip: '55286',
      isUrban: false,
    },
    {
      name: 'CHEIA',
      zip: '55295',
      isUrban: false,
    },
    {
      name: 'CORNESTI',
      zip: '55302',
      isUrban: false,
    },
    {
      name: 'MINTIU GHERLII',
      zip: '58561',
      isUrban: false,
    },
    {
      name: 'BUNESTI',
      zip: '58570',
      isUrban: false,
    },
    {
      name: 'NIMA',
      zip: '58589',
      isUrban: false,
    },
    {
      name: 'PADURENII',
      zip: '58598',
      isUrban: false,
    },
    {
      name: 'PETRESTI',
      zip: '58605',
      isUrban: false,
    },
    {
      name: 'SALATIU',
      zip: '58614',
      isUrban: false,
    },
    {
      name: 'MOCIU',
      zip: '58632',
      isUrban: false,
    },
    {
      name: 'BOTENI',
      zip: '58641',
      isUrban: false,
    },
    {
      name: 'CHESAU',
      zip: '58650',
      isUrban: false,
    },
    {
      name: 'CRISENI',
      zip: '58669',
      isUrban: false,
    },
    {
      name: 'FALCA',
      zip: '58678',
      isUrban: false,
    },
    {
      name: 'GHIRISU ROMAN',
      zip: '58687',
      isUrban: false,
    },
    {
      name: 'ROSIENI',
      zip: '58696',
      isUrban: false,
    },
    {
      name: 'TURMASI',
      zip: '58703',
      isUrban: false,
    },
    {
      name: 'ZORENII DE VALE',
      zip: '58712',
      isUrban: false,
    },
    {
      name: 'MOLDOVENESTI',
      zip: '58730',
      isUrban: false,
    },
    {
      name: 'BADENI',
      zip: '58749',
      isUrban: false,
    },
    {
      name: 'PIETROASA',
      zip: '58758',
      isUrban: false,
    },
    {
      name: 'PLAIESTI',
      zip: '58767',
      isUrban: false,
    },
    {
      name: 'PODENI',
      zip: '58776',
      isUrban: false,
    },
    {
      name: 'STEJERIS',
      zip: '58785',
      isUrban: false,
    },
    {
      name: 'PALATCA',
      zip: '58801',
      isUrban: false,
    },
    {
      name: 'BAGACIU',
      zip: '58810',
      isUrban: false,
    },
    {
      name: 'MURESENII DE CAMPIE',
      zip: '58829',
      isUrban: false,
    },
    {
      name: 'PETEA',
      zip: '58838',
      isUrban: false,
    },
    {
      name: 'SAVA',
      zip: '58847',
      isUrban: false,
    },
    {
      name: 'PANTICEU',
      zip: '58865',
      isUrban: false,
    },
    {
      name: 'CATALINA',
      zip: '58874',
      isUrban: false,
    },
    {
      name: 'CUBLESU SOMESAN',
      zip: '58883',
      isUrban: false,
    },
    {
      name: 'DARJA',
      zip: '58892',
      isUrban: false,
    },
    {
      name: 'SARATA',
      zip: '58909',
      isUrban: false,
    },
    {
      name: 'PETRESTII DE JOS',
      zip: '58927',
      isUrban: false,
    },
    {
      name: 'CRAESTI',
      zip: '58936',
      isUrban: false,
    },
    {
      name: 'DELENI',
      zip: '58945',
      isUrban: false,
    },
    {
      name: 'LIVADA',
      zip: '58954',
      isUrban: false,
    },
    {
      name: 'PETRESTII DE MIJLOC',
      zip: '58963',
      isUrban: false,
    },
    {
      name: 'PETRESTII DE SUS',
      zip: '58972',
      isUrban: false,
    },
    {
      name: 'PLAIURI',
      zip: '58981',
      isUrban: false,
    },
    {
      name: 'PLOSCOS',
      zip: '59005',
      isUrban: false,
    },
    {
      name: 'CRAIRAT',
      zip: '59014',
      isUrban: false,
    },
    {
      name: 'LOBODAS',
      zip: '59023',
      isUrban: false,
    },
    {
      name: 'VALEA FLORILOR',
      zip: '59032',
      isUrban: false,
    },
    {
      name: 'POIENI',
      zip: '59050',
      isUrban: false,
    },
    {
      name: 'BOLOGA',
      zip: '59069',
      isUrban: false,
    },
    {
      name: 'CERBESTI',
      zip: '59078',
      isUrban: false,
    },
    {
      name: 'HODISU',
      zip: '59087',
      isUrban: false,
    },
    {
      name: 'LUNCA VISAGULUI',
      zip: '59096',
      isUrban: false,
    },
    {
      name: 'MORLACA',
      zip: '59103',
      isUrban: false,
    },
    {
      name: 'TRANISU',
      zip: '59112',
      isUrban: false,
    },
    {
      name: 'VALEA DRAGANULUI',
      zip: '59121',
      isUrban: false,
    },
    {
      name: 'RECEA-CRISTUR',
      zip: '59149',
      isUrban: false,
    },
    {
      name: 'CAPRIOARA',
      zip: '59158',
      isUrban: false,
    },
    {
      name: 'CIUBANCA',
      zip: '59167',
      isUrban: false,
    },
    {
      name: 'CIUBANCUTA',
      zip: '59176',
      isUrban: false,
    },
    {
      name: 'ELCIU',
      zip: '59185',
      isUrban: false,
    },
    {
      name: 'ESCU',
      zip: '59194',
      isUrban: false,
    },
    {
      name: 'JURCA',
      zip: '59201',
      isUrban: false,
    },
    {
      name: 'OSOI',
      zip: '59210',
      isUrban: false,
    },
    {
      name: 'PUSTUTA',
      zip: '59229',
      isUrban: false,
    },
    {
      name: 'RISCA',
      zip: '59247',
      isUrban: false,
    },
    {
      name: 'DEALU MARE',
      zip: '59256',
      isUrban: false,
    },
    {
      name: 'LAPUSTESTI',
      zip: '59265',
      isUrban: false,
    },
    {
      name: 'MARCESTI',
      zip: '59274',
      isUrban: false,
    },
    {
      name: 'SACUIEU',
      zip: '59292',
      isUrban: false,
    },
    {
      name: 'ROGOJEL',
      zip: '59309',
      isUrban: false,
    },
    {
      name: 'VISAGU',
      zip: '59318',
      isUrban: false,
    },
    {
      name: 'SANCRAIU',
      zip: '59443',
      isUrban: false,
    },
    {
      name: 'ALUNISU',
      zip: '59452',
      isUrban: false,
    },
    {
      name: 'BRAISORU',
      zip: '59461',
      isUrban: false,
    },
    {
      name: 'DOMOSU',
      zip: '59470',
      isUrban: false,
    },
    {
      name: 'HORLACEA',
      zip: '59489',
      isUrban: false,
    },
    {
      name: 'SANDULESTI',
      zip: '55320',
      isUrban: false,
    },
    {
      name: 'COPACENI',
      zip: '55339',
      isUrban: false,
    },
    {
      name: 'SANMARTIN',
      zip: '59504',
      isUrban: false,
    },
    {
      name: 'CEABA',
      zip: '59513',
      isUrban: false,
    },
    {
      name: 'CUTCA',
      zip: '59522',
      isUrban: false,
    },
    {
      name: 'DIVICIORII MARI',
      zip: '59531',
      isUrban: false,
    },
    {
      name: 'DIVICIORII MICI',
      zip: '59540',
      isUrban: false,
    },
    {
      name: 'MAHAL',
      zip: '59559',
      isUrban: false,
    },
    {
      name: 'SAMBOIENI',
      zip: '59568',
      isUrban: false,
    },
    {
      name: 'TARGUSOR',
      zip: '59577',
      isUrban: false,
    },
    {
      name: 'SANPAUL',
      zip: '59595',
      isUrban: false,
    },
    {
      name: 'BERINDU',
      zip: '59602',
      isUrban: false,
    },
    {
      name: 'MIHAIESTI',
      zip: '59611',
      isUrban: false,
    },
    {
      name: 'SARDU',
      zip: '59639',
      isUrban: false,
    },
    {
      name: 'SUMURDUCU',
      zip: '59620',
      isUrban: false,
    },
    {
      name: 'TOPA MICA',
      zip: '59648',
      isUrban: false,
    },
    {
      name: 'SAVADISLA',
      zip: '59336',
      isUrban: false,
    },
    {
      name: 'FINISEL',
      zip: '59345',
      isUrban: false,
    },
    {
      name: 'HASDATE',
      zip: '59354',
      isUrban: false,
    },
    {
      name: 'LITA',
      zip: '59363',
      isUrban: false,
    },
    {
      name: 'LITENI',
      zip: '59372',
      isUrban: false,
    },
    {
      name: 'STOLNA',
      zip: '59381',
      isUrban: false,
    },
    {
      name: 'VALISOARA',
      zip: '59390',
      isUrban: false,
    },
    {
      name: 'VLAHA',
      zip: '59407',
      isUrban: false,
    },
    {
      name: 'SIC',
      zip: '59425',
      isUrban: false,
    },
    {
      name: 'ARUNCUTA',
      zip: '59675',
      isUrban: false,
    },
    {
      name: 'DAMBURILE',
      zip: '59684',
      isUrban: false,
    },
    {
      name: 'TAGA',
      zip: '59835',
      isUrban: false,
    },
    {
      name: 'NASAL',
      zip: '59844',
      isUrban: false,
    },
    {
      name: 'SANTEJUDE',
      zip: '59853',
      isUrban: false,
    },
    {
      name: 'SANTEJUDE VALE',
      zip: '59862',
      isUrban: false,
    },
    {
      name: 'SANTIOANA',
      zip: '59871',
      isUrban: false,
    },
    {
      name: 'TRITENII DE JOS',
      zip: '59700',
      isUrban: false,
    },
    {
      name: 'CLAPA',
      zip: '59719',
      isUrban: false,
    },
    {
      name: 'COLONIA',
      zip: '59728',
      isUrban: false,
    },
    {
      name: 'PADURENI',
      zip: '59737',
      isUrban: false,
    },
    {
      name: 'TRITENII DE SUS',
      zip: '59746',
      isUrban: false,
    },
    {
      name: 'TRITENII-HOTAR',
      zip: '59755',
      isUrban: false,
    },
    {
      name: 'TURENI',
      zip: '59773',
      isUrban: false,
    },
    {
      name: 'CEANU MIC',
      zip: '59782',
      isUrban: false,
    },
    {
      name: 'COMSESTI',
      zip: '59791',
      isUrban: false,
    },
    {
      name: 'MARTINESTI',
      zip: '59808',
      isUrban: false,
    },
    {
      name: 'MICESTI',
      zip: '59817',
      isUrban: false,
    },
    {
      name: 'UNGURAS',
      zip: '59899',
      isUrban: false,
    },
    {
      name: 'BATIN',
      zip: '59906',
      isUrban: false,
    },
    {
      name: 'DAROT',
      zip: '59915',
      isUrban: false,
    },
    {
      name: 'SICFA',
      zip: '59924',
      isUrban: false,
    },
    {
      name: 'VALEA UNGURASULUI',
      zip: '59933',
      isUrban: false,
    },
    {
      name: 'VAD',
      zip: '59951',
      isUrban: false,
    },
    {
      name: 'NAOIU',
      zip: '56540',
      isUrban: false,
    },
    {
      name: 'MERA',
      zip: '55865',
      isUrban: false,
    },
    {
      name: 'SUATU',
      zip: '59666',
      isUrban: false,
    },
    {
      name: 'STRAMBU',
      zip: '57136',
      isUrban: false,
    },
    {
      name: 'VALEA CASEIELULUI',
      zip: '57145',
      isUrban: false,
    },
    {
      name: 'VALEA LUI OPRIS',
      zip: '57154',
      isUrban: false,
    },
    {
      name: 'CIUCEA',
      zip: '57172',
      isUrban: false,
    },
    {
      name: 'BUCEA',
      zip: '57181',
      isUrban: false,
    },
    {
      name: 'NEGRENI',
      zip: '57190',
      isUrban: false,
    },
    {
      name: 'PRELUCELE',
      zip: '57207',
      isUrban: false,
    },
    {
      name: 'VANATORI',
      zip: '57216',
      isUrban: false,
    },
    {
      name: 'CIURILA',
      zip: '57234',
      isUrban: false,
    },
    {
      name: 'FILEA DE JOS',
      zip: '57243',
      isUrban: false,
    },
    {
      name: 'FILEA DE SUS',
      zip: '57252',
      isUrban: false,
    },
    {
      name: 'PADURENI',
      zip: '57261',
      isUrban: false,
    },
    {
      name: 'PRUNIS',
      zip: '57270',
      isUrban: false,
    },
    {
      name: 'SALICEA',
      zip: '57289',
      isUrban: false,
    },
    {
      name: 'SALISTE',
      zip: '57298',
      isUrban: false,
    },
    {
      name: 'SUTU',
      zip: '57305',
      isUrban: false,
    },
    {
      name: 'COJOCNA',
      zip: '57369',
      isUrban: false,
    },
    {
      name: 'BOJ-CATUN',
      zip: '57378',
      isUrban: false,
    },
    {
      name: 'BOJU',
      zip: '57387',
      isUrban: false,
    },
    {
      name: 'CARA',
      zip: '57396',
      isUrban: false,
    },
    {
      name: 'HUCI',
      zip: '57403',
      isUrban: false,
    },
    {
      name: 'BOGATA DE JOS',
      zip: '59960',
      isUrban: false,
    },
    {
      name: 'BOGATA DE SUS',
      zip: '59979',
      isUrban: false,
    },
    {
      name: 'CALNA',
      zip: '59988',
      isUrban: false,
    },
    {
      name: 'CETAN',
      zip: '59997',
      isUrban: false,
    },
    {
      name: 'CURTUIUSU DEJULUI',
      zip: '60008',
      isUrban: false,
    },
    {
      name: 'VALEA GROSILOR',
      zip: '60017',
      isUrban: false,
    },
    {
      name: 'VALEA IERII',
      zip: '60035',
      isUrban: false,
    },
    {
      name: 'CERC',
      zip: '60044',
      isUrban: false,
    },
    {
      name: 'PLOPI',
      zip: '60053',
      isUrban: false,
    },
    {
      name: 'VIISOARA',
      zip: '60071',
      isUrban: false,
    },
    {
      name: 'URCA',
      zip: '60080',
      isUrban: false,
    },
    {
      name: 'VULTURENI',
      zip: '60106',
      isUrban: false,
    },
    {
      name: 'BABUTIU',
      zip: '60115',
      isUrban: false,
    },
    {
      name: 'BADESTI',
      zip: '60124',
      isUrban: false,
    },
    {
      name: 'CHIDEA',
      zip: '60133',
      isUrban: false,
    },
    {
      name: 'FAURENI',
      zip: '60142',
      isUrban: false,
    },
    {
      name: 'SOIMENI',
      zip: '60151',
      isUrban: false,
    }
  ],
  CT: [
    {
      name: 'CONSTANTA',
      zip: '60428',
      isUrban: true,
    },
    {
      name: 'CAP AURORA',
      zip: '63223',
      isUrban: true,
    },
    {
      name: 'JUPITER',
      zip: '63214',
      isUrban: true,
    },
    {
      name: 'MAMAIA',
      zip: '60437',
      isUrban: true,
    },
    {
      name: 'NEPTUN',
      zip: '63205',
      isUrban: true,
    },
    {
      name: 'OLIMP',
      zip: '63143',
      isUrban: true,
    },
    {
      name: 'PALAZU MARE',
      zip: '60446',
      isUrban: true,
    },
    {
      name: 'SATURN',
      zip: '63241',
      isUrban: true,
    },
    {
      name: 'VENUS',
      zip: '63232',
      isUrban: true,
    },
    {
      name: 'MANGALIA',
      zip: '60491',
      isUrban: true,
    },
    {
      name: 'MEDGIDIA',
      zip: '60856',
      isUrban: true,
    },
    {
      name: 'REMUS OPREANU',
      zip: '60865',
      isUrban: true,
    },
    {
      name: 'VALEA DACILOR',
      zip: '60874',
      isUrban: true,
    },
    {
      name: 'BASARABI',
      zip: '62379',
      isUrban: true,
    },
    {
      name: 'SIMINOC',
      zip: '62388',
      isUrban: true,
    },
    {
      name: 'CERNAVODA',
      zip: '60785',
      isUrban: true,
    },
    {
      name: 'EFORIE NORD',
      zip: '60473',
      isUrban: true,
    },
    {
      name: 'EFORIE SUD',
      zip: '60464',
      isUrban: true,
    },
    {
      name: 'HARSOVA',
      zip: '60810',
      isUrban: true,
    },
    {
      name: 'VADU OII',
      zip: '60829',
      isUrban: true,
    },
    {
      name: 'NAVODARI',
      zip: '60516',
      isUrban: true,
    },
    {
      name: 'MAMAIA-SAT',
      zip: '60525',
      isUrban: true,
    },
    {
      name: 'NEGRU VODA',
      zip: '62404',
      isUrban: true,
    },
    {
      name: 'DARABANI',
      zip: '62413',
      isUrban: true,
    },
    {
      name: 'GRANICERU',
      zip: '62422',
      isUrban: true,
    },
    {
      name: 'VALCELELE',
      zip: '62431',
      isUrban: true,
    },
    {
      name: 'OVIDIU',
      zip: '60696',
      isUrban: true,
    },
    {
      name: 'POIANA',
      zip: '60712',
      isUrban: true,
    },
    {
      name: 'TECHIRGHIOL',
      zip: '60543',
      isUrban: true,
    },
    {
      name: '',
      zip: '60605',
      isUrban: false,
    },
    {
      name: 'DULCESTI',
      zip: '60614',
      isUrban: false,
    },
    {
      name: 'MOSNENI',
      zip: '60623',
      isUrban: false,
    },
    {
      name: 'ADAMCLISI',
      zip: '60892',
      isUrban: false,
    },
    {
      name: 'ABRUD',
      zip: '60909',
      isUrban: false,
    },
    {
      name: 'HATEG',
      zip: '60918',
      isUrban: false,
    },
    {
      name: 'URLUIA',
      zip: '60927',
      isUrban: false,
    },
    {
      name: 'ZORILE',
      zip: '60936',
      isUrban: false,
    },
    {
      name: 'AGIGEA',
      zip: '63269',
      isUrban: false,
    },
    {
      name: 'LAZU',
      zip: '60589',
      isUrban: false,
    },
    {
      name: 'SANATORIUL AGIGEA',
      zip: '60552',
      isUrban: false,
    },
    {
      name: 'STATIUNEA ZOOLOGICA MARINA AGIGEA',
      zip: '60561',
      isUrban: false,
    },
    {
      name: 'ALBESTI',
      zip: '60954',
      isUrban: false,
    },
    {
      name: 'ARSA',
      zip: '60963',
      isUrban: false,
    },
    {
      name: 'COROANA',
      zip: '60972',
      isUrban: false,
    },
    {
      name: 'COTU VAII',
      zip: '60981',
      isUrban: false,
    },
    {
      name: 'VARTOP',
      zip: '60990',
      isUrban: false,
    },
    {
      name: 'ALIMAN',
      zip: '61014',
      isUrban: false,
    },
    {
      name: 'DUNARENI',
      zip: '61032',
      isUrban: false,
    },
    {
      name: 'FLORIILE',
      zip: '61041',
      isUrban: false,
    },
    {
      name: 'VLAHII',
      zip: '61050',
      isUrban: false,
    },
    {
      name: 'AMZACEA',
      zip: '63090',
      isUrban: false,
    },
    {
      name: 'CASICEA',
      zip: '61470',
      isUrban: false,
    },
    {
      name: 'GENERAL SCARISOREANU',
      zip: '61489',
      isUrban: false,
    },
    {
      name: 'BANEASA',
      zip: '61078',
      isUrban: true,
    },
    {
      name: 'FAUREI',
      zip: '61087',
      isUrban: true,
    },
    {
      name: 'NEGURENI',
      zip: '61096',
      isUrban: true,
    },
    {
      name: 'TUDOR VLADIMIRESCU',
      zip: '61103',
      isUrban: true,
    },
    {
      name: 'CASTELU',
      zip: '61130',
      isUrban: false,
    },
    {
      name: 'CUZA VODA',
      zip: '61149',
      isUrban: false,
    },
    {
      name: 'NISIPARI',
      zip: '61158',
      isUrban: false,
    },
    {
      name: 'CERCHEZU',
      zip: '61176',
      isUrban: false,
    },
    {
      name: 'CASCIOARELE',
      zip: '61185',
      isUrban: false,
    },
    {
      name: 'MAGURA',
      zip: '61194',
      isUrban: false,
    },
    {
      name: 'VIROAGA',
      zip: '61201',
      isUrban: false,
    },
    {
      name: 'CHIRNOGENI',
      zip: '61229',
      isUrban: false,
    },
    {
      name: 'CREDINTA',
      zip: '61238',
      isUrban: false,
    },
    {
      name: 'PLOPENI',
      zip: '61247',
      isUrban: false,
    },
    {
      name: 'CIOBANU',
      zip: '61265',
      isUrban: false,
    },
    {
      name: 'MIORITA',
      zip: '61274',
      isUrban: false,
    },
    {
      name: 'CIOCARLIA',
      zip: '61292',
      isUrban: false,
    },
    {
      name: 'CIOCARLIA DE SUS',
      zip: '61309',
      isUrban: false,
    },
    {
      name: 'COBADIN',
      zip: '61327',
      isUrban: false,
    },
    {
      name: 'CONACU',
      zip: '61336',
      isUrban: false,
    },
    {
      name: 'CURCANI',
      zip: '61345',
      isUrban: false,
    },
    {
      name: 'NEGRESTI',
      zip: '61354',
      isUrban: false,
    },
    {
      name: 'VIISOARA',
      zip: '61363',
      isUrban: false,
    },
    {
      name: 'COGEALAC',
      zip: '61381',
      isUrban: false,
    },
    {
      name: 'FANTANELE',
      zip: '61407',
      isUrban: false,
    },
    {
      name: 'GURA DOBROGEI',
      zip: '61416',
      isUrban: false,
    },
    {
      name: 'RAMNICU DE JOS',
      zip: '61425',
      isUrban: false,
    },
    {
      name: 'RAMNICU DE SUS',
      zip: '61434',
      isUrban: false,
    },
    {
      name: 'TARIVERDE',
      zip: '61443',
      isUrban: false,
    },
    {
      name: 'COMANA',
      zip: '61461',
      isUrban: false,
    },
    {
      name: 'PELINU',
      zip: '61498',
      isUrban: false,
    },
    {
      name: 'TATARU',
      zip: '61504',
      isUrban: false,
    },
    {
      name: 'CORBU',
      zip: '61522',
      isUrban: false,
    },
    {
      name: 'LUMINITA',
      zip: '61531',
      isUrban: false,
    },
    {
      name: 'VADU',
      zip: '61540',
      isUrban: false,
    },
    {
      name: 'CRUCEA',
      zip: '61568',
      isUrban: false,
    },
    {
      name: 'BALTAGESTI',
      zip: '61577',
      isUrban: false,
    },
    {
      name: 'CRISAN',
      zip: '61586',
      isUrban: false,
    },
    {
      name: 'GALBIORI',
      zip: '61595',
      isUrban: false,
    },
    {
      name: 'SIRIU',
      zip: '61611',
      isUrban: false,
    },
    {
      name: 'STUPINA',
      zip: '61602',
      isUrban: false,
    },
    {
      name: 'CUMPANA',
      zip: '61639',
      isUrban: false,
    },
    {
      name: 'STRAJA',
      zip: '61666',
      isUrban: false,
    },
    {
      name: 'DELENI',
      zip: '61684',
      isUrban: false,
    },
    {
      name: 'PETROSANI',
      zip: '61693',
      isUrban: false,
    },
    {
      name: 'PIETRENI',
      zip: '61700',
      isUrban: false,
    },
    {
      name: 'SIPOTELE',
      zip: '61728',
      isUrban: false,
    },
    {
      name: 'DOBROMIR',
      zip: '61746',
      isUrban: false,
    },
    {
      name: 'CETATEA',
      zip: '61755',
      isUrban: false,
    },
    {
      name: 'DOBROMIRU DIN DEAL',
      zip: '61764',
      isUrban: false,
    },
    {
      name: 'LESPEZI',
      zip: '61773',
      isUrban: false,
    },
    {
      name: 'PADURENI',
      zip: '61782',
      isUrban: false,
    },
    {
      name: 'VALENI',
      zip: '61791',
      isUrban: false,
    },
    {
      name: 'DUMBRAVENI',
      zip: '61899',
      isUrban: false,
    },
    {
      name: 'FURNICA',
      zip: '61915',
      isUrban: false,
    },
    {
      name: 'GARLICIU',
      zip: '61817',
      isUrban: false,
    },
    {
      name: 'HORIA',
      zip: '61835',
      isUrban: false,
    },
    {
      name: 'CLOSCA',
      zip: '61844',
      isUrban: false,
    },
    {
      name: 'GHINDARESTI',
      zip: '61853',
      isUrban: false,
    },
    {
      name: 'TICHILESTI',
      zip: '61862',
      isUrban: false,
    },
    {
      name: 'INDEPENDENTA',
      zip: '61880',
      isUrban: false,
    },
    {
      name: 'FANTANA MARE',
      zip: '61906',
      isUrban: false,
    },
    {
      name: 'MOVILA VERDE',
      zip: '61924',
      isUrban: false,
    },
    {
      name: 'OLTENI',
      zip: '61933',
      isUrban: false,
    },
    {
      name: 'TUFANI',
      zip: '61942',
      isUrban: false,
    },
    {
      name: 'ION CORVIN',
      zip: '61960',
      isUrban: false,
    },
    {
      name: 'BREBENI',
      zip: '61979',
      isUrban: false,
    },
    {
      name: 'CRANGU',
      zip: '61988',
      isUrban: false,
    },
    {
      name: 'RARISTEA',
      zip: '62002',
      isUrban: false,
    },
    {
      name: 'VIILE',
      zip: '62011',
      isUrban: false,
    },
    {
      name: 'ISTRIA',
      zip: '62039',
      isUrban: false,
    },
    {
      name: 'NUNTASI',
      zip: '62048',
      isUrban: false,
    },
    {
      name: 'LIMANU',
      zip: '60641',
      isUrban: false,
    },
    {
      name: '2 MAI',
      zip: '60669',
      isUrban: false,
    },
    {
      name: 'HAGIENI',
      zip: '60650',
      isUrban: false,
    },
    {
      name: 'VAMA VECHE',
      zip: '60678',
      isUrban: false,
    },
    {
      name: 'LIPNITA',
      zip: '62066',
      isUrban: false,
    },
    {
      name: 'CANLIA',
      zip: '62075',
      isUrban: false,
    },
    {
      name: 'CARVAN',
      zip: '62084',
      isUrban: false,
    },
    {
      name: 'COSLUGEA',
      zip: '62093',
      isUrban: false,
    },
    {
      name: 'CUIUGIUC',
      zip: '62100',
      isUrban: false,
    },
    {
      name: 'GORUNI',
      zip: '62119',
      isUrban: false,
    },
    {
      name: 'IZVOARELE',
      zip: '62128',
      isUrban: false,
    },
    {
      name: 'LUMINA',
      zip: '60703',
      isUrban: false,
    },
    {
      name: 'OITUZ',
      zip: '62217',
      isUrban: false,
    },
    {
      name: 'SIBIOARA',
      zip: '62244',
      isUrban: false,
    },
    {
      name: 'MERENI',
      zip: '62146',
      isUrban: false,
    },
    {
      name: 'BARAGANU',
      zip: '61648',
      isUrban: false,
    },
    {
      name: 'CIOBANITA',
      zip: '62155',
      isUrban: false,
    },
    {
      name: 'LANURILE',
      zip: '62164',
      isUrban: false,
    },
    {
      name: 'MIRISTEA',
      zip: '62173',
      isUrban: false,
    },
    {
      name: 'OSMANCEA',
      zip: '62182',
      isUrban: false,
    },
    {
      name: 'MIHAI VITEAZU',
      zip: '62262',
      isUrban: false,
    },
    {
      name: 'SINOIE',
      zip: '62271',
      isUrban: false,
    },
    {
      name: 'MIHAIL KOGALNICEANU',
      zip: '62208',
      isUrban: false,
    },
    {
      name: 'PALAZU MIC',
      zip: '62226',
      isUrban: false,
    },
    {
      name: 'PIATRA',
      zip: '62235',
      isUrban: false,
    },
    {
      name: 'MIRCEA VODA',
      zip: '62299',
      isUrban: false,
    },
    {
      name: 'FACLIA',
      zip: '62306',
      isUrban: false,
    },
    {
      name: 'GHERGHINA',
      zip: '62315',
      isUrban: false,
    },
    {
      name: 'SALIGNY',
      zip: '62324',
      isUrban: false,
    },
    {
      name: 'SATU NOU',
      zip: '62333',
      isUrban: false,
    },
    {
      name: 'STEFAN CEL MARE',
      zip: '62342',
      isUrban: false,
    },
    {
      name: 'TIBRINU',
      zip: '62351',
      isUrban: false,
    },
    {
      name: 'NICOLAE BALCESCU',
      zip: '62459',
      isUrban: false,
    },
    {
      name: 'DOROBANTU',
      zip: '62468',
      isUrban: false,
    },
    {
      name: 'OLTINA',
      zip: '62495',
      isUrban: false,
    },
    {
      name: 'RAZOARELE',
      zip: '62501',
      isUrban: false,
    },
    {
      name: 'SATU NOU',
      zip: '62510',
      isUrban: false,
    },
    {
      name: 'STRUNGA',
      zip: '62529',
      isUrban: false,
    },
    {
      name: 'OSTROV',
      zip: '62547',
      isUrban: false,
    },
    {
      name: 'ALMALAU',
      zip: '62556',
      isUrban: false,
    },
    {
      name: 'BUGEAC',
      zip: '62565',
      isUrban: false,
    },
    {
      name: 'ESECHIOI',
      zip: '62574',
      isUrban: false,
    },
    {
      name: 'GALITA',
      zip: '62583',
      isUrban: false,
    },
    {
      name: 'GARLITA',
      zip: '62592',
      isUrban: false,
    },
    {
      name: 'PANTELIMON',
      zip: '62618',
      isUrban: false,
    },
    {
      name: 'CALUGARENI',
      zip: '62627',
      isUrban: false,
    },
    {
      name: 'NISTORESTI',
      zip: '62636',
      isUrban: false,
    },
    {
      name: 'PANTELIMON DE JOS',
      zip: '62645',
      isUrban: false,
    },
    {
      name: 'RUNCU',
      zip: '62654',
      isUrban: false,
    },
    {
      name: 'PECINEAGA',
      zip: '62681',
      isUrban: false,
    },
    {
      name: 'VANATORI',
      zip: '62690',
      isUrban: false,
    },
    {
      name: 'PESTERA',
      zip: '62716',
      isUrban: false,
    },
    {
      name: 'IVRINEZU MARE',
      zip: '62725',
      isUrban: false,
    },
    {
      name: 'IVRINEZU MIC',
      zip: '62734',
      isUrban: false,
    },
    {
      name: 'IZVORU MARE',
      zip: '62743',
      isUrban: false,
    },
    {
      name: 'VETERANU',
      zip: '62752',
      isUrban: false,
    },
    {
      name: 'POARTA ALBA',
      zip: '62770',
      isUrban: false,
    },
    {
      name: 'NAZARCEA',
      zip: '62789',
      isUrban: false,
    },
    {
      name: 'RASOVA',
      zip: '62805',
      isUrban: false,
    },
    {
      name: 'COCHIRLENI',
      zip: '62814',
      isUrban: false,
    },
    {
      name: 'SACELE',
      zip: '62887',
      isUrban: false,
    },
    {
      name: 'TRAIAN',
      zip: '62896',
      isUrban: false,
    },
    {
      name: 'SARAIU',
      zip: '62832',
      isUrban: false,
    },
    {
      name: 'DULGHERU',
      zip: '62850',
      isUrban: false,
    },
    {
      name: 'STEJARU',
      zip: '62869',
      isUrban: false,
    },
    {
      name: 'SEIMENI',
      zip: '62912',
      isUrban: false,
    },
    {
      name: 'DUNAREA',
      zip: '62921',
      isUrban: false,
    },
    {
      name: 'SEIMENII MICI',
      zip: '62930',
      isUrban: false,
    },
    {
      name: 'SILISTEA',
      zip: '62958',
      isUrban: false,
    },
    {
      name: 'TEPES VODA',
      zip: '62976',
      isUrban: false,
    },
    {
      name: 'TARGUSOR',
      zip: '62994',
      isUrban: false,
    },
    {
      name: 'CASIAN',
      zip: '63009',
      isUrban: false,
    },
    {
      name: 'CHEIA',
      zip: '63018',
      isUrban: false,
    },
    {
      name: 'GRADINA',
      zip: '63027',
      isUrban: false,
    },
    {
      name: 'MIREASA',
      zip: '63036',
      isUrban: false,
    },
    {
      name: 'TOPALU',
      zip: '63054',
      isUrban: false,
    },
    {
      name: 'CAPIDAVA',
      zip: '63063',
      isUrban: false,
    },
    {
      name: 'TOPRAISAR',
      zip: '63081',
      isUrban: false,
    },
    {
      name: 'BIRUINTA',
      zip: '63107',
      isUrban: false,
    },
    {
      name: 'MOVILITA',
      zip: '63116',
      isUrban: false,
    },
    {
      name: 'POTARNICHEA',
      zip: '61657',
      isUrban: false,
    },
    {
      name: 'TORTOMAN',
      zip: '62967',
      isUrban: false,
    },
    {
      name: 'DROPIA',
      zip: '62477',
      isUrban: false,
    },
    {
      name: 'TUZLA',
      zip: '60730',
      isUrban: false,
    },
    {
      name: 'COSTINESTI',
      zip: '60749',
      isUrban: false,
    },
    {
      name: 'SCHITU',
      zip: '60758',
      isUrban: false,
    },
    {
      name: 'VALU LUI TRAIAN',
      zip: '63134',
      isUrban: false,
    },
    {
      name: 'VULTURU',
      zip: '62663',
      isUrban: false,
    }
  ],
  CV: [
    {
      name: 'SFANTU GHEORGHE',
      zip: '63401',
      isUrban: true,
    },
    {
      name: 'CHILIENI',
      zip: '63410',
      isUrban: true,
    },
    {
      name: 'COSENI',
      zip: '63429',
      isUrban: true,
    },
    {
      name: 'BARAOLT',
      zip: '63456',
      isUrban: true,
    },
    {
      name: 'BIBORTENI',
      zip: '63465',
      isUrban: true,
    },
    {
      name: 'BODOS',
      zip: '63474',
      isUrban: true,
    },
    {
      name: 'CAPENI',
      zip: '63483',
      isUrban: true,
    },
    {
      name: 'MICLOSOARA',
      zip: '63492',
      isUrban: true,
    },
    {
      name: 'RACOSUL DE SUS',
      zip: '63508',
      isUrban: true,
    },
    {
      name: 'COVASNA',
      zip: '63535',
      isUrban: true,
    },
    {
      name: 'CHIURUS',
      zip: '63544',
      isUrban: true,
    },
    {
      name: 'INTORSURA BUZAULUI',
      zip: '63599',
      isUrban: true,
    },
    {
      name: 'BRADET',
      zip: '63606',
      isUrban: true,
    },
    {
      name: 'FLOROAIA',
      zip: '63615',
      isUrban: true,
    },
    {
      name: 'SCRADOASA',
      zip: '63624',
      isUrban: true,
    },
    {
      name: 'TARGU SECUIESC',
      zip: '63759',
      isUrban: true,
    },
    {
      name: 'LUNGA',
      zip: '63768',
      isUrban: true,
    },
    {
      name: 'AITA MARE',
      zip: '63786',
      isUrban: false,
    },
    {
      name: 'AITA MEDIE',
      zip: '63795',
      isUrban: false,
    },
    {
      name: 'BARCANI',
      zip: '63642',
      isUrban: false,
    },
    {
      name: 'LADAUTI',
      zip: '63651',
      isUrban: false,
    },
    {
      name: 'SARAMAS',
      zip: '63660',
      isUrban: false,
    },
    {
      name: 'VALEA MARE',
      zip: '63679',
      isUrban: false,
    },
    {
      name: 'BATANII MARI',
      zip: '63811',
      isUrban: false,
    },
    {
      name: 'AITA SEACA',
      zip: '63820',
      isUrban: false,
    },
    {
      name: 'BATANII MICI',
      zip: '63839',
      isUrban: false,
    },
    {
      name: 'DOBOSENI',
      zip: '64069',
      isUrban: false,
    },
    {
      name: 'FILIA',
      zip: '64078',
      isUrban: false,
    },
    {
      name: 'TALISOARA',
      zip: '64087',
      isUrban: false,
    },
    {
      name: 'BRATES',
      zip: '64014',
      isUrban: false,
    },
    {
      name: 'PACHIA',
      zip: '64023',
      isUrban: false,
    },
    {
      name: 'TELECHIA',
      zip: '64032',
      isUrban: false,
    },
    {
      name: 'BRETCU',
      zip: '64103',
      isUrban: false,
    },
    {
      name: 'MARTANUS',
      zip: '64112',
      isUrban: false,
    },
    {
      name: 'OITUZ',
      zip: '64121',
      isUrban: false,
    },
    {
      name: 'CATALINA',
      zip: '64149',
      isUrban: false,
    },
    {
      name: 'HATUICA',
      zip: '64158',
      isUrban: false,
    },
    {
      name: 'IMENI',
      zip: '64167',
      isUrban: false,
    },
    {
      name: 'MARCUSA',
      zip: '64185',
      isUrban: false,
    },
    {
      name: 'MARTINENI',
      zip: '64176',
      isUrban: false,
    },
    {
      name: 'CERNAT',
      zip: '64201',
      isUrban: false,
    },
    {
      name: 'ALBIS',
      zip: '64210',
      isUrban: false,
    },
    {
      name: 'ICAFALAU',
      zip: '64229',
      isUrban: false,
    },
    {
      name: 'CHICHIS',
      zip: '64247',
      isUrban: false,
    },
    {
      name: 'BACEL',
      zip: '64256',
      isUrban: false,
    },
    {
      name: 'COMANDAU',
      zip: '63562',
      isUrban: false,
    },
    {
      name: 'DOBARLAU',
      zip: '64274',
      isUrban: false,
    },
    {
      name: 'LUNCA MARCUSULUI',
      zip: '64283',
      isUrban: false,
    },
    {
      name: 'MARCUS',
      zip: '64292',
      isUrban: false,
    },
    {
      name: 'VALEA DOBARLAULUI',
      zip: '64309',
      isUrban: false,
    },
    {
      name: 'GHELINTA',
      zip: '64327',
      isUrban: false,
    },
    {
      name: 'HARALE',
      zip: '64336',
      isUrban: false,
    },
    {
      name: 'GHIDFALAU',
      zip: '64354',
      isUrban: false,
    },
    {
      name: 'ANGHELUS',
      zip: '64363',
      isUrban: false,
    },
    {
      name: 'FOTOS',
      zip: '64372',
      isUrban: false,
    },
    {
      name: 'ZOLTAN',
      zip: '64381',
      isUrban: false,
    },
    {
      name: 'HAGHIG',
      zip: '64407',
      isUrban: false,
    },
    {
      name: 'IARAS',
      zip: '64416',
      isUrban: false,
    },
    {
      name: 'ILIENI',
      zip: '64434',
      isUrban: false,
    },
    {
      name: 'DOBOLII DE JOS',
      zip: '64443',
      isUrban: false,
    },
    {
      name: 'SANCRAIU',
      zip: '64452',
      isUrban: false,
    },
    {
      name: 'LEMNIA',
      zip: '64470',
      isUrban: false,
    },
    {
      name: 'LUTOASA',
      zip: '64489',
      isUrban: false,
    },
    {
      name: 'MERENI',
      zip: '64498',
      isUrban: false,
    },
    {
      name: 'MICFALAU',
      zip: '64513',
      isUrban: false,
    },
    {
      name: 'BIXAD',
      zip: '64522',
      isUrban: false,
    },
    {
      name: 'MALNAS',
      zip: '64531',
      isUrban: false,
    },
    {
      name: 'MALNAS-BAI',
      zip: '64540',
      isUrban: false,
    },
    {
      name: 'VALEA ZALANULUI',
      zip: '64559',
      isUrban: false,
    },
    {
      name: 'MOACSA',
      zip: '64577',
      isUrban: false,
    },
    {
      name: 'DALNIC',
      zip: '64586',
      isUrban: false,
    },
    {
      name: 'PADURENI',
      zip: '64595',
      isUrban: false,
    },
    {
      name: 'OJDULA',
      zip: '64611',
      isUrban: false,
    },
    {
      name: 'HILIB',
      zip: '64620',
      isUrban: false,
    },
    {
      name: 'OZUN',
      zip: '64648',
      isUrban: false,
    },
    {
      name: 'BICFALAU',
      zip: '64657',
      isUrban: false,
    },
    {
      name: 'LISNAU',
      zip: '64666',
      isUrban: false,
    },
    {
      name: 'LISNAU-VALE',
      zip: '64675',
      isUrban: false,
    },
    {
      name: 'LUNCA OZUNULUI',
      zip: '64684',
      isUrban: false,
    },
    {
      name: 'MAGHERUS',
      zip: '64693',
      isUrban: false,
    },
    {
      name: 'SANTIONLUNCA',
      zip: '64700',
      isUrban: false,
    },
    {
      name: 'POIAN',
      zip: '64728',
      isUrban: false,
    },
    {
      name: 'BELANI',
      zip: '64737',
      isUrban: false,
    },
    {
      name: 'CARPINENII',
      zip: '64746',
      isUrban: false,
    },
    {
      name: 'ESTELNIC',
      zip: '64755',
      isUrban: false,
    },
    {
      name: 'VALEA SCURTA',
      zip: '64764',
      isUrban: false,
    },
    {
      name: 'RECI',
      zip: '64782',
      isUrban: false,
    },
    {
      name: 'ANINOASA',
      zip: '64791',
      isUrban: false,
    },
    {
      name: 'BITA',
      zip: '64808',
      isUrban: false,
    },
    {
      name: 'SACIOVA',
      zip: '64817',
      isUrban: false,
    },
    {
      name: 'SANZIENI',
      zip: '64835',
      isUrban: false,
    },
    {
      name: 'CASINU MIC',
      zip: '64844',
      isUrban: false,
    },
    {
      name: 'PETRICENI',
      zip: '64853',
      isUrban: false,
    },
    {
      name: 'VALEA SEACA',
      zip: '64862',
      isUrban: false,
    },
    {
      name: 'SITA BUZAULUI',
      zip: '63697',
      isUrban: false,
    },
    {
      name: 'CRASNA',
      zip: '63704',
      isUrban: false,
    },
    {
      name: 'MERISOR',
      zip: '63713',
      isUrban: false,
    },
    {
      name: 'ZABRATAU',
      zip: '63722',
      isUrban: false,
    },
    {
      name: 'TURIA',
      zip: '64880',
      isUrban: false,
    },
    {
      name: 'ALUNGENI',
      zip: '64899',
      isUrban: false,
    },
    {
      name: 'ARACI',
      zip: '64951',
      isUrban: false,
    },
    {
      name: 'ARIUSD',
      zip: '64960',
      isUrban: false,
    },
    {
      name: 'HETEA',
      zip: '64979',
      isUrban: false,
    },
    {
      name: 'VALCELE',
      zip: '64988',
      isUrban: false,
    },
    {
      name: 'VALEA CRISULUI',
      zip: '64915',
      isUrban: false,
    },
    {
      name: 'ARCUS',
      zip: '64924',
      isUrban: false,
    },
    {
      name: 'CALNIC',
      zip: '64933',
      isUrban: false,
    },
    {
      name: 'VARGHIS',
      zip: '65002',
      isUrban: false,
    },
    {
      name: 'ZABALA',
      zip: '65057',
      isUrban: false,
    },
    {
      name: 'PETENI',
      zip: '65066',
      isUrban: false,
    },
    {
      name: 'SURCEA',
      zip: '65075',
      isUrban: false,
    },
    {
      name: 'TAMASFALAU',
      zip: '65084',
      isUrban: false,
    },
    {
      name: 'ZAGON',
      zip: '65020',
      isUrban: false,
    },
    {
      name: 'PAPAUTI',
      zip: '65039',
      isUrban: false,
    },
    {
      name: 'HERCULIAN',
      zip: '63848',
      isUrban: false,
    },
    {
      name: 'OZUNCA-BAI',
      zip: '63857',
      isUrban: false,
    },
    {
      name: 'BELIN',
      zip: '63875',
      isUrban: false,
    },
    {
      name: 'BELIN-VALE',
      zip: '63884',
      isUrban: false,
    },
    {
      name: 'BODOC',
      zip: '63900',
      isUrban: false,
    },
    {
      name: 'OLTENI',
      zip: '63919',
      isUrban: false,
    },
    {
      name: 'ZALAN',
      zip: '63928',
      isUrban: false,
    },
    {
      name: 'BOROSNEU MARE',
      zip: '63946',
      isUrban: false,
    },
    {
      name: 'BOROSNEU MIC',
      zip: '63955',
      isUrban: false,
    },
    {
      name: 'DOBOLII DE SUS',
      zip: '63964',
      isUrban: false,
    },
    {
      name: 'LET',
      zip: '63973',
      isUrban: false,
    },
    {
      name: 'TUFALAU',
      zip: '63982',
      isUrban: false,
    },
    {
      name: 'VALEA MICA',
      zip: '63991',
      isUrban: false,
    },
    {
      name: 'BRADUT',
      zip: '64050',
      isUrban: false,
    }
  ],
  DB: [
    {
      name: 'TARGOVISTE',
      zip: '65351',
      isUrban: true,
    },
    {
      name: 'PRISEACA',
      zip: '65360',
      isUrban: true,
    },
    {
      name: 'FIENI',
      zip: '65618',
      isUrban: true,
    },
    {
      name: 'BEREVOESTI',
      zip: '65627',
      isUrban: true,
    },
    {
      name: 'COSTESTI',
      zip: '65636',
      isUrban: true,
    },
    {
      name: 'GAESTI',
      zip: '65690',
      isUrban: true,
    },
    {
      name: 'MORENI',
      zip: '65850',
      isUrban: true,
    },
    {
      name: 'PUCIOASA',
      zip: '65930',
      isUrban: true,
    },
    {
      name: 'BELA',
      zip: '65949',
      isUrban: true,
    },
    {
      name: 'DIACONESTI',
      zip: '65958',
      isUrban: true,
    },
    {
      name: 'GLODENI',
      zip: '65967',
      isUrban: true,
    },
    {
      name: 'MALURILE',
      zip: '65976',
      isUrban: true,
    },
    {
      name: 'MICULESTI',
      zip: '65985',
      isUrban: true,
    },
    {
      name: 'PUCIOASA-SAT',
      zip: '65994',
      isUrban: true,
    },
    {
      name: 'TITU',
      zip: '66090',
      isUrban: true,
    },
    {
      name: 'FUSEA',
      zip: '66107',
      isUrban: true,
    },
    {
      name: 'HAGIOAICA',
      zip: '66116',
      isUrban: true,
    },
    {
      name: 'MERENI',
      zip: '66125',
      isUrban: true,
    },
    {
      name: 'PLOPU',
      zip: '66134',
      isUrban: true,
    },
    {
      name: 'SALCUTA',
      zip: '66143',
      isUrban: true,
    },
    {
      name: 'ANINOASA',
      zip: '65388',
      isUrban: false,
    },
    {
      name: 'SATENI',
      zip: '65397',
      isUrban: false,
    },
    {
      name: 'VIFORATA',
      zip: '65404',
      isUrban: false,
    },
    {
      name: 'BALENI-ROMANI',
      zip: '66205',
      isUrban: false,
    },
    {
      name: 'BALENI-SARBI',
      zip: '66214',
      isUrban: false,
    },
    {
      name: 'GURA BARBULETULUI',
      zip: '66232',
      isUrban: false,
    },
    {
      name: 'ALUNIS',
      zip: '66241',
      isUrban: false,
    },
    {
      name: 'BARBULETU',
      zip: '66250',
      isUrban: false,
    },
    {
      name: 'CETATUIA',
      zip: '66269',
      isUrban: false,
    },
    {
      name: 'DUPA DEAL',
      zip: '66278',
      isUrban: false,
    },
    {
      name: 'PIETRARI',
      zip: '66287',
      isUrban: false,
    },
    {
      name: 'RAU ALB DE JOS',
      zip: '66296',
      isUrban: false,
    },
    {
      name: 'RAU ALB DE SUS',
      zip: '66303',
      isUrban: false,
    },
    {
      name: 'SIPOT',
      zip: '66312',
      isUrban: false,
    },
    {
      name: 'VALEA',
      zip: '66321',
      isUrban: false,
    },
    {
      name: 'BEZDEAD',
      zip: '66349',
      isUrban: false,
    },
    {
      name: 'BROSTENI',
      zip: '66358',
      isUrban: false,
    },
    {
      name: 'COSTISATA',
      zip: '66367',
      isUrban: false,
    },
    {
      name: 'MAGURA',
      zip: '66376',
      isUrban: false,
    },
    {
      name: 'TUNARI',
      zip: '66385',
      isUrban: false,
    },
    {
      name: 'VALEA MORII',
      zip: '66394',
      isUrban: false,
    },
    {
      name: 'BILCIURESTI',
      zip: '66410',
      isUrban: false,
    },
    {
      name: 'SUSENI SOCETU',
      zip: '66429',
      isUrban: false,
    },
    {
      name: 'BRANESTI',
      zip: '66018',
      isUrban: false,
    },
    {
      name: 'GURA VULCANEI',
      zip: '66027',
      isUrban: false,
    },
    {
      name: 'LACULETE-GARA',
      zip: '66036',
      isUrban: false,
    },
    {
      name: 'PRIBOIU',
      zip: '66045',
      isUrban: false,
    },
    {
      name: 'TOCULESTI',
      zip: '66054',
      isUrban: false,
    },
    {
      name: 'VULCANA-PANDELE',
      zip: '66063',
      isUrban: false,
    },
    {
      name: 'BRANISTEA',
      zip: '66161',
      isUrban: false,
    },
    {
      name: 'DAMBOVICIOARA',
      zip: '66170',
      isUrban: false,
    },
    {
      name: 'SAVESTI',
      zip: '66189',
      isUrban: false,
    },
    {
      name: 'BREZOAELE',
      zip: '101350',
      isUrban: false,
    },
    {
      name: 'BREZOAIA',
      zip: '101369',
      isUrban: false,
    },
    {
      name: 'BUCIUMENI',
      zip: '66447',
      isUrban: false,
    },
    {
      name: 'DEALU MARE',
      zip: '66456',
      isUrban: false,
    },
    {
      name: 'VALEA LEURZII',
      zip: '66465',
      isUrban: false,
    },
    {
      name: 'BUCSANI',
      zip: '66483',
      isUrban: false,
    },
    {
      name: 'HABENI',
      zip: '66492',
      isUrban: false,
    },
    {
      name: 'RACOVITA',
      zip: '66508',
      isUrban: false,
    },
    {
      name: 'RATOAIA',
      zip: '66517',
      isUrban: false,
    },
    {
      name: 'BUTIMANU',
      zip: '101573',
      isUrban: false,
    },
    {
      name: 'BARBUCEANU',
      zip: '101582',
      isUrban: false,
    },
    {
      name: 'LUCIANCA',
      zip: '101591',
      isUrban: false,
    },
    {
      name: 'UNGURENI',
      zip: '101608',
      isUrban: false,
    },
    {
      name: 'CANDESTI-VALE',
      zip: '66535',
      isUrban: false,
    },
    {
      name: 'ANINOSANI',
      zip: '66544',
      isUrban: false,
    },
    {
      name: 'CANDESTI DEAL',
      zip: '66553',
      isUrban: false,
    },
    {
      name: 'DRAGODANESTI',
      zip: '66562',
      isUrban: false,
    },
    {
      name: 'VALEA MARE',
      zip: '66571',
      isUrban: false,
    },
    {
      name: 'CIOCANESTI',
      zip: '101859',
      isUrban: false,
    },
    {
      name: 'CRETU',
      zip: '101868',
      isUrban: false,
    },
    {
      name: 'DECINDEA',
      zip: '101877',
      isUrban: false,
    },
    {
      name: 'URZICEANCA',
      zip: '101886',
      isUrban: false,
    },
    {
      name: 'VIZURESTI',
      zip: '101895',
      isUrban: false,
    },
    {
      name: 'GHERGHITESTI',
      zip: '66599',
      isUrban: false,
    },
    {
      name: 'BLIDARI',
      zip: '66606',
      isUrban: false,
    },
    {
      name: 'CALUGARENI',
      zip: '66615',
      isUrban: false,
    },
    {
      name: 'CAPSUNA',
      zip: '66624',
      isUrban: false,
    },
    {
      name: 'COBIUTA',
      zip: '66633',
      isUrban: false,
    },
    {
      name: 'CRACIUNESTI',
      zip: '66642',
      isUrban: false,
    },
    {
      name: 'FRASIN DEAL',
      zip: '66651',
      isUrban: false,
    },
    {
      name: 'FRASIN VALE',
      zip: '66660',
      isUrban: false,
    },
    {
      name: 'MANASTIREA',
      zip: '66688',
      isUrban: false,
    },
    {
      name: 'MISLEA',
      zip: '66679',
      isUrban: false,
    },
    {
      name: 'COJASCA',
      zip: '66704',
      isUrban: false,
    },
    {
      name: 'FANTANELE',
      zip: '66713',
      isUrban: false,
    },
    {
      name: 'IAZU',
      zip: '66722',
      isUrban: false,
    },
    {
      name: 'COMISANI',
      zip: '66740',
      isUrban: false,
    },
    {
      name: 'LAZURI',
      zip: '66759',
      isUrban: false,
    },
    {
      name: 'CONTESTI',
      zip: '66777',
      isUrban: false,
    },
    {
      name: 'BALTENI',
      zip: '66786',
      isUrban: false,
    },
    {
      name: 'BOTENI',
      zip: '66795',
      isUrban: false,
    },
    {
      name: 'CALUGARENI',
      zip: '66802',
      isUrban: false,
    },
    {
      name: 'CRANGASI',
      zip: '66811',
      isUrban: false,
    },
    {
      name: 'GAMANESTI',
      zip: '66820',
      isUrban: false,
    },
    {
      name: 'HELESTEU',
      zip: '66839',
      isUrban: false,
    },
    {
      name: 'MERENI',
      zip: '66848',
      isUrban: false,
    },
    {
      name: 'CORBII MARI',
      zip: '66866',
      isUrban: false,
    },
    {
      name: 'BARACENI',
      zip: '66875',
      isUrban: false,
    },
    {
      name: 'GROZAVESTI',
      zip: '66884',
      isUrban: false,
    },
    {
      name: 'MOARA DIN GROAPA',
      zip: '66893',
      isUrban: false,
    },
    {
      name: 'PETRESTI',
      zip: '66900',
      isUrban: false,
    },
    {
      name: 'PODU CORBENCII',
      zip: '66919',
      isUrban: false,
    },
    {
      name: 'SATU NOU',
      zip: '66928',
      isUrban: false,
    },
    {
      name: 'UNGURENI',
      zip: '66937',
      isUrban: false,
    },
    {
      name: 'VADU STANCHII',
      zip: '66946',
      isUrban: false,
    },
    {
      name: 'CORNATELU',
      zip: '66964',
      isUrban: false,
    },
    {
      name: 'ALUNISU',
      zip: '66973',
      isUrban: false,
    },
    {
      name: 'BOLOVANI',
      zip: '66982',
      isUrban: false,
    },
    {
      name: 'CORNI',
      zip: '66991',
      isUrban: false,
    },
    {
      name: 'SLOBOZIA',
      zip: '67005',
      isUrban: false,
    },
    {
      name: 'CORNESTI',
      zip: '67023',
      isUrban: false,
    },
    {
      name: 'BUJOREANCA',
      zip: '67032',
      isUrban: false,
    },
    {
      name: 'CATUNU',
      zip: '67041',
      isUrban: false,
    },
    {
      name: 'CRISTEASCA',
      zip: '67050',
      isUrban: false,
    },
    {
      name: 'CRIVATU',
      zip: '67069',
      isUrban: false,
    },
    {
      name: 'FRASINU',
      zip: '67078',
      isUrban: false,
    },
    {
      name: 'HODARASTI',
      zip: '67087',
      isUrban: false,
    },
    {
      name: 'IBRIANU',
      zip: '67096',
      isUrban: false,
    },
    {
      name: 'POSTARNACU',
      zip: '67103',
      isUrban: false,
    },
    {
      name: 'UNGURENI',
      zip: '67112',
      isUrban: false,
    },
    {
      name: 'COSTESTII DIN VALE',
      zip: '67130',
      isUrban: false,
    },
    {
      name: 'MARUNTISU',
      zip: '67149',
      isUrban: false,
    },
    {
      name: 'TOMSANI',
      zip: '67158',
      isUrban: false,
    },
    {
      name: 'BADULESTI',
      zip: '67176',
      isUrban: false,
    },
    {
      name: 'CRANGURILE DE JOS',
      zip: '67185',
      isUrban: false,
    },
    {
      name: 'CRANGURILE DE SUS',
      zip: '67194',
      isUrban: false,
    },
    {
      name: 'PATROAIA DEAL',
      zip: '67201',
      isUrban: false,
    },
    {
      name: 'PATROAIA VALE',
      zip: '67210',
      isUrban: false,
    },
    {
      name: 'POTLOGENI VALE',
      zip: '67229',
      isUrban: false,
    },
    {
      name: 'RATESTI',
      zip: '67238',
      isUrban: false,
    },
    {
      name: 'VOIA',
      zip: '67247',
      isUrban: false,
    },
    {
      name: 'CREVEDIA',
      zip: '102295',
      isUrban: false,
    },
    {
      name: 'COCANI',
      zip: '102302',
      isUrban: false,
    },
    {
      name: 'DARZA',
      zip: '102311',
      isUrban: false,
    },
    {
      name: 'MANASTIREA',
      zip: '102320',
      isUrban: false,
    },
    {
      name: 'SAMURCASI',
      zip: '102339',
      isUrban: false,
    },
    {
      name: 'DARMANESTI',
      zip: '67265',
      isUrban: false,
    },
    {
      name: 'MARGINENII DE SUS',
      zip: '67274',
      isUrban: false,
    },
    {
      name: 'VLADENI',
      zip: '67283',
      isUrban: false,
    },
    {
      name: 'DOBRA',
      zip: '67309',
      isUrban: false,
    },
    {
      name: 'MARCESTI',
      zip: '67318',
      isUrban: false,
    },
    {
      name: 'DOICESTI',
      zip: '65422',
      isUrban: false,
    },
    {
      name: 'DRAGODANA',
      zip: '67336',
      isUrban: false,
    },
    {
      name: 'BOBOCI',
      zip: '67345',
      isUrban: false,
    },
    {
      name: 'BURDUCA',
      zip: '67354',
      isUrban: false,
    },
    {
      name: 'CUPARU',
      zip: '67363',
      isUrban: false,
    },
    {
      name: 'PADURENI',
      zip: '67372',
      isUrban: false,
    },
    {
      name: 'PICIOR DE MUNTE',
      zip: '67381',
      isUrban: false,
    },
    {
      name: 'STRAOSTI',
      zip: '67390',
      isUrban: false,
    },
    {
      name: 'DRAGOMIRESTI',
      zip: '67416',
      isUrban: false,
    },
    {
      name: 'DECINDENI',
      zip: '67425',
      isUrban: false,
    },
    {
      name: 'GEANGOESTI',
      zip: '67434',
      isUrban: false,
    },
    {
      name: 'GUSOIU',
      zip: '67540',
      isUrban: false,
    },
    {
      name: 'LACULETE',
      zip: '67559',
      isUrban: false,
    },
    {
      name: 'LIVEZILE',
      zip: '67568',
      isUrban: false,
    },
    {
      name: 'MALU MIERII',
      zip: '67577',
      isUrban: false,
    },
    {
      name: 'SCHELA',
      zip: '67586',
      isUrban: false,
    },
    {
      name: 'GURA FOII',
      zip: '65716',
      isUrban: false,
    },
    {
      name: 'BUMBUIA',
      zip: '65725',
      isUrban: false,
    },
    {
      name: 'CATANELE',
      zip: '65734',
      isUrban: false,
    },
    {
      name: 'FAGETU',
      zip: '65743',
      isUrban: false,
    },
    {
      name: 'GURA OCNITEI',
      zip: '67602',
      isUrban: false,
    },
    {
      name: 'ADANCA',
      zip: '67611',
      isUrban: false,
    },
    {
      name: 'OCHIURI',
      zip: '67620',
      isUrban: false,
    },
    {
      name: 'SACUENI',
      zip: '67639',
      isUrban: false,
    },
    {
      name: 'GURA SUTII',
      zip: '67657',
      isUrban: false,
    },
    {
      name: 'SPERIETENI',
      zip: '67666',
      isUrban: false,
    },
    {
      name: 'HULUBESTI',
      zip: '67684',
      isUrban: false,
    },
    {
      name: 'BUTOIU DE JOS',
      zip: '67693',
      isUrban: false,
    },
    {
      name: 'BUTOIU DE SUS',
      zip: '67700',
      isUrban: false,
    },
    {
      name: 'MAGURA',
      zip: '67719',
      isUrban: false,
    },
    {
      name: 'VALEA DADEI',
      zip: '67728',
      isUrban: false,
    },
    {
      name: 'GHIRDOVENI',
      zip: '67746',
      isUrban: false,
    },
    {
      name: 'I. L. CARAGIALE',
      zip: '67755',
      isUrban: false,
    },
    {
      name: 'MIJA',
      zip: '67764',
      isUrban: false,
    },
    {
      name: 'IEDERA DE JOS',
      zip: '65878',
      isUrban: false,
    },
    {
      name: 'COLIBASI',
      zip: '65887',
      isUrban: false,
    },
    {
      name: 'CRICOVU DULCE',
      zip: '65896',
      isUrban: false,
    },
    {
      name: 'IEDERA DE SUS',
      zip: '65903',
      isUrban: false,
    },
    {
      name: 'LUCIENI',
      zip: '67782',
      isUrban: false,
    },
    {
      name: 'OLTENI',
      zip: '67791',
      isUrban: false,
    },
    {
      name: 'RACIU',
      zip: '67808',
      isUrban: false,
    },
    {
      name: 'SILISTEA',
      zip: '67817',
      isUrban: false,
    },
    {
      name: 'SUTA SEACA',
      zip: '67826',
      isUrban: false,
    },
    {
      name: 'LUDESTI',
      zip: '67844',
      isUrban: false,
    },
    {
      name: 'MILOSARI',
      zip: '67853',
      isUrban: false,
    },
    {
      name: 'POTOCELU',
      zip: '67862',
      isUrban: false,
    },
    {
      name: 'SCHEIU DE JOS',
      zip: '67871',
      isUrban: false,
    },
    {
      name: 'SCHEIU DE SUS',
      zip: '67880',
      isUrban: false,
    },
    {
      name: 'TELESTI',
      zip: '67899',
      isUrban: false,
    },
    {
      name: 'LUNGULETU',
      zip: '67915',
      isUrban: false,
    },
    {
      name: 'OREASCA',
      zip: '67924',
      isUrban: false,
    },
    {
      name: 'SERDANU',
      zip: '67933',
      isUrban: false,
    },
    {
      name: 'MALU CU FLORI',
      zip: '67951',
      isUrban: false,
    },
    {
      name: 'CAPU COASTEI',
      zip: '67960',
      isUrban: false,
    },
    {
      name: 'COPACENI',
      zip: '67979',
      isUrban: false,
    },
    {
      name: 'MICLOSANII MARI',
      zip: '67988',
      isUrban: false,
    },
    {
      name: 'MICLOSANII MICI',
      zip: '67997',
      isUrban: false,
    },
    {
      name: 'MANESTI',
      zip: '68011',
      isUrban: false,
    },
    {
      name: 'DRAGAESTI PAMANTENI',
      zip: '68020',
      isUrban: false,
    },
    {
      name: 'DRAGAESTI UNGURENI',
      zip: '68039',
      isUrban: false,
    },
    {
      name: 'TETCOIU',
      zip: '68057',
      isUrban: false,
    },
    {
      name: 'CRETULESTI',
      zip: '68066',
      isUrban: false,
    },
    {
      name: 'MATASARU',
      zip: '68075',
      isUrban: false,
    },
    {
      name: 'ODAIA TURCULUI',
      zip: '68084',
      isUrban: false,
    },
    {
      name: 'POROINICA',
      zip: '68093',
      isUrban: false,
    },
    {
      name: 'PUTU CU SALCIE',
      zip: '68100',
      isUrban: false,
    },
    {
      name: 'SALCIOARA',
      zip: '68119',
      isUrban: false,
    },
    {
      name: 'MOGOSANI',
      zip: '68137',
      isUrban: false,
    },
    {
      name: 'CHIRCA',
      zip: '68146',
      isUrban: false,
    },
    {
      name: 'COJOCARU',
      zip: '68155',
      isUrban: false,
    },
    {
      name: 'MERII',
      zip: '68164',
      isUrban: false,
    },
    {
      name: 'ZAVOIU',
      zip: '68173',
      isUrban: false,
    },
    {
      name: 'MOROENI',
      zip: '68191',
      isUrban: false,
    },
    {
      name: 'DOBRESTI',
      zip: '68208',
      isUrban: false,
    },
    {
      name: 'GLOD',
      zip: '68217',
      isUrban: false,
    },
    {
      name: 'LUNCA',
      zip: '68226',
      isUrban: false,
    },
    {
      name: 'MUSCEL',
      zip: '68235',
      isUrban: false,
    },
    {
      name: 'PUCHENI',
      zip: '68244',
      isUrban: false,
    },
    {
      name: 'MORTENI',
      zip: '68262',
      isUrban: false,
    },
    {
      name: 'NEAJLOVU',
      zip: '68271',
      isUrban: false,
    },
    {
      name: 'MOTAIENI',
      zip: '65654',
      isUrban: false,
    },
    {
      name: 'CUCUTENI',
      zip: '65663',
      isUrban: false,
    },
    {
      name: 'NICULESTI',
      zip: '104396',
      isUrban: false,
    },
    {
      name: 'CIOCANARI',
      zip: '104403',
      isUrban: false,
    },
    {
      name: 'MOVILA',
      zip: '104412',
      isUrban: false,
    },
    {
      name: 'NUCET',
      zip: '68299',
      isUrban: false,
    },
    {
      name: 'CAZACI',
      zip: '68306',
      isUrban: false,
    },
    {
      name: 'ILFOVENI',
      zip: '68315',
      isUrban: false,
    },
    {
      name: 'OCNITA',
      zip: '68333',
      isUrban: false,
    },
    {
      name: 'ODOBESTI',
      zip: '68351',
      isUrban: false,
    },
    {
      name: 'BRANCOVEANU',
      zip: '68360',
      isUrban: false,
    },
    {
      name: 'CROVU',
      zip: '68379',
      isUrban: false,
    },
    {
      name: 'MIULESTI',
      zip: '68388',
      isUrban: false,
    },
    {
      name: 'ZIDURILE',
      zip: '68397',
      isUrban: false,
    },
    {
      name: 'PETRESTI',
      zip: '65761',
      isUrban: false,
    },
    {
      name: 'COADA IZVORULUI',
      zip: '65770',
      isUrban: false,
    },
    {
      name: 'GHERGHESTI',
      zip: '65789',
      isUrban: false,
    },
    {
      name: 'GRECI',
      zip: '65798',
      isUrban: false,
    },
    {
      name: 'IONESTI',
      zip: '65805',
      isUrban: false,
    },
    {
      name: 'POTLOGENI-DEAL',
      zip: '65814',
      isUrban: false,
    },
    {
      name: 'PUNTEA DE GRECI',
      zip: '65823',
      isUrban: false,
    },
    {
      name: 'PIETROSITA',
      zip: '68413',
      isUrban: false,
    },
    {
      name: 'DEALU FRUMOS',
      zip: '68422',
      isUrban: false,
    },
    {
      name: 'POIANA',
      zip: '68440',
      isUrban: false,
    },
    {
      name: 'POIENITA',
      zip: '68459',
      isUrban: false,
    },
    {
      name: 'POTLOGI',
      zip: '68477',
      isUrban: false,
    },
    {
      name: 'PITARU',
      zip: '68486',
      isUrban: false,
    },
    {
      name: 'PODU CRISTINII',
      zip: '68495',
      isUrban: false,
    },
    {
      name: 'ROMANESTI',
      zip: '68501',
      isUrban: false,
    },
    {
      name: 'VLASCENI',
      zip: '68510',
      isUrban: false,
    },
    {
      name: 'PRODULESTI',
      zip: '68538',
      isUrban: false,
    },
    {
      name: 'BROSTENI',
      zip: '68547',
      isUrban: false,
    },
    {
      name: 'COSTESTII DIN DEAL',
      zip: '68556',
      isUrban: false,
    },
    {
      name: 'PUCHENI',
      zip: '68574',
      isUrban: false,
    },
    {
      name: 'BRADATEL',
      zip: '68583',
      isUrban: false,
    },
    {
      name: 'MEISOARE',
      zip: '68592',
      isUrban: false,
    },
    {
      name: 'VALEA LARGA',
      zip: '68609',
      isUrban: false,
    },
    {
      name: 'VARFURENI',
      zip: '68618',
      isUrban: false,
    },
    {
      name: 'RACARI',
      zip: '68636',
      isUrban: true,
    },
    {
      name: 'BALANESTI',
      zip: '68645',
      isUrban: true,
    },
    {
      name: 'COLACU',
      zip: '68654',
      isUrban: true,
    },
    {
      name: 'GHERGANI',
      zip: '68663',
      isUrban: true,
    },
    {
      name: 'GHIMPATI',
      zip: '68672',
      isUrban: true,
    },
    {
      name: 'MAVRODIN',
      zip: '68681',
      isUrban: true,
    },
    {
      name: 'SABIESTI',
      zip: '68690',
      isUrban: true,
    },
    {
      name: 'STANESTI',
      zip: '68707',
      isUrban: true,
    },
    {
      name: 'RAZVAD',
      zip: '65440',
      isUrban: false,
    },
    {
      name: 'GORGOTA',
      zip: '65459',
      isUrban: false,
    },
    {
      name: 'VALEA VOIVOZILOR',
      zip: '65468',
      isUrban: false,
    },
    {
      name: 'RUNCU',
      zip: '68725',
      isUrban: false,
    },
    {
      name: 'BADENI',
      zip: '68734',
      isUrban: false,
    },
    {
      name: 'BREBU',
      zip: '68743',
      isUrban: false,
    },
    {
      name: 'FERESTRE',
      zip: '68752',
      isUrban: false,
    },
    {
      name: 'PIATRA',
      zip: '68761',
      isUrban: false,
    },
    {
      name: 'SILISTEA',
      zip: '68770',
      isUrban: false,
    },
    {
      name: 'BANESTI',
      zip: '68798',
      isUrban: false,
    },
    {
      name: 'CATUNU',
      zip: '68805',
      isUrban: false,
    },
    {
      name: 'CUZA VODA',
      zip: '68814',
      isUrban: false,
    },
    {
      name: 'GHINESTI',
      zip: '68823',
      isUrban: false,
    },
    {
      name: 'MIRCEA VODA',
      zip: '68832',
      isUrban: false,
    },
    {
      name: 'MOARA NOUA',
      zip: '68841',
      isUrban: false,
    },
    {
      name: 'MOVILA',
      zip: '68850',
      isUrban: false,
    },
    {
      name: 'PODU RIZII',
      zip: '68869',
      isUrban: false,
    },
    {
      name: 'SALCIOARA',
      zip: '68878',
      isUrban: false,
    },
    {
      name: 'SELARU',
      zip: '68896',
      isUrban: false,
    },
    {
      name: 'FIERBINTI',
      zip: '68903',
      isUrban: false,
    },
    {
      name: 'GLOGOVEANU',
      zip: '68912',
      isUrban: false,
    },
    {
      name: 'SLOBOZIA MOARA',
      zip: '105151',
      isUrban: false,
    },
    {
      name: 'SOTANGA',
      zip: '65486',
      isUrban: false,
    },
    {
      name: 'TEIS',
      zip: '65495',
      isUrban: false,
    },
    {
      name: 'TARTASESTI',
      zip: '105543',
      isUrban: false,
    },
    {
      name: 'BALDANA',
      zip: '105552',
      isUrban: false,
    },
    {
      name: 'GULIA',
      zip: '105561',
      isUrban: false,
    },
    {
      name: 'TATARANI',
      zip: '68930',
      isUrban: false,
    },
    {
      name: 'CAPRIORU',
      zip: '68949',
      isUrban: false,
    },
    {
      name: 'GHEBOIENI',
      zip: '68958',
      isUrban: false,
    },
    {
      name: 'PRIBOIU',
      zip: '68967',
      isUrban: false,
    },
    {
      name: 'ULIESTI',
      zip: '68985',
      isUrban: false,
    },
    {
      name: 'CROITORI',
      zip: '68994',
      isUrban: false,
    },
    {
      name: 'HANU LUI PALA',
      zip: '69009',
      isUrban: false,
    },
    {
      name: 'JUGURENI',
      zip: '69018',
      isUrban: false,
    },
    {
      name: 'MANASTIOARA',
      zip: '69027',
      isUrban: false,
    },
    {
      name: 'OLTENI',
      zip: '69036',
      isUrban: false,
    },
    {
      name: 'RAGU',
      zip: '69045',
      isUrban: false,
    },
    {
      name: 'STAVROPOLIA',
      zip: '69054',
      isUrban: false,
    },
    {
      name: 'ULMI',
      zip: '65510',
      isUrban: false,
    },
    {
      name: 'COLANU',
      zip: '65529',
      isUrban: false,
    },
    {
      name: 'DIMOIU',
      zip: '65538',
      isUrban: false,
    },
    {
      name: 'DUMBRAVA',
      zip: '65547',
      isUrban: false,
    },
    {
      name: 'MATRACA',
      zip: '65556',
      isUrban: false,
    },
    {
      name: 'NISIPURILE',
      zip: '65565',
      isUrban: false,
    },
    {
      name: 'UDRESTI',
      zip: '65574',
      isUrban: false,
    },
    {
      name: 'VIISOARA',
      zip: '65583',
      isUrban: false,
    },
    {
      name: 'VACARESTI',
      zip: '69269',
      isUrban: false,
    },
    {
      name: 'BRATESTII DE JOS',
      zip: '69278',
      isUrban: false,
    },
    {
      name: 'BUNGETU',
      zip: '69287',
      isUrban: false,
    },
    {
      name: 'PERSINARI',
      zip: '69296',
      isUrban: false,
    },
    {
      name: 'VALEA LUNGA-CRICOV',
      zip: '69072',
      isUrban: false,
    },
    {
      name: 'BACESTI',
      zip: '69081',
      isUrban: false,
    },
    {
      name: 'IZVORU',
      zip: '69090',
      isUrban: false,
    },
    {
      name: 'MOSIA MICA',
      zip: '69107',
      isUrban: false,
    },
    {
      name: 'SERBANEASA',
      zip: '69116',
      isUrban: false,
    },
    {
      name: 'STUBEIE TISA',
      zip: '69125',
      isUrban: false,
    },
    {
      name: 'VALEA LUI DAN',
      zip: '69134',
      isUrban: false,
    },
    {
      name: 'VALEA LUNGA GORGOTA',
      zip: '69143',
      isUrban: false,
    },
    {
      name: 'VALEA LUNGA OGREA',
      zip: '69152',
      isUrban: false,
    },
    {
      name: 'VALEA MARE',
      zip: '69161',
      isUrban: false,
    },
    {
      name: 'VALEA MARE',
      zip: '69189',
      isUrban: false,
    },
    {
      name: 'FETENI',
      zip: '69198',
      isUrban: false,
    },
    {
      name: 'GARLENI',
      zip: '69205',
      isUrban: false,
    },
    {
      name: 'LIVEZILE',
      zip: '69214',
      isUrban: false,
    },
    {
      name: 'SARU',
      zip: '69223',
      isUrban: false,
    },
    {
      name: 'STRATONESTI',
      zip: '69232',
      isUrban: false,
    },
    {
      name: 'VALEA CASELOR',
      zip: '69241',
      isUrban: false,
    },
    {
      name: 'VALENI-DAMBOVITA',
      zip: '69312',
      isUrban: false,
    },
    {
      name: 'MESTEACAN',
      zip: '69321',
      isUrban: false,
    },
    {
      name: 'VIRFURI',
      zip: '69456',
      isUrban: false,
    },
    {
      name: 'CARLANESTI',
      zip: '69465',
      isUrban: false,
    },
    {
      name: 'COJOIU',
      zip: '69474',
      isUrban: false,
    },
    {
      name: 'MERISORU',
      zip: '69483',
      isUrban: false,
    },
    {
      name: 'STATESTI',
      zip: '69492',
      isUrban: false,
    },
    {
      name: 'SUVITA',
      zip: '69508',
      isUrban: false,
    },
    {
      name: 'ULMETU',
      zip: '69517',
      isUrban: false,
    },
    {
      name: 'VISINA',
      zip: '69349',
      isUrban: false,
    },
    {
      name: 'BROSTENI',
      zip: '69358',
      isUrban: false,
    },
    {
      name: 'IZVORU',
      zip: '69367',
      isUrban: false,
    },
    {
      name: 'RASCAETI',
      zip: '69376',
      isUrban: false,
    },
    {
      name: 'VULTUREANCA',
      zip: '69385',
      isUrban: false,
    },
    {
      name: 'VISINESTI',
      zip: '69401',
      isUrban: false,
    },
    {
      name: 'DOSPINESTI',
      zip: '69410',
      isUrban: false,
    },
    {
      name: 'SULTANU',
      zip: '69429',
      isUrban: false,
    },
    {
      name: 'URSEIU',
      zip: '69438',
      isUrban: false,
    },
    {
      name: 'VOINESTI',
      zip: '69535',
      isUrban: false,
    },
    {
      name: 'GEMENEA BRATULESTI',
      zip: '69544',
      isUrban: false,
    },
    {
      name: 'IZVOARELE',
      zip: '69553',
      isUrban: false,
    },
    {
      name: 'LUNCA',
      zip: '69562',
      isUrban: false,
    },
    {
      name: 'MANGA',
      zip: '69571',
      isUrban: false,
    },
    {
      name: 'MINJINA',
      zip: '69580',
      isUrban: false,
    },
    {
      name: 'ONCESTI',
      zip: '69599',
      isUrban: false,
    },
    {
      name: 'SUDULENI',
      zip: '69606',
      isUrban: false,
    },
    {
      name: 'VULCANA-BAI',
      zip: '69624',
      isUrban: false,
    },
    {
      name: 'NICOLAESTI',
      zip: '69633',
      isUrban: false,
    },
    {
      name: 'VULCANA DE SUS',
      zip: '69642',
      isUrban: false,
    },
    {
      name: 'MOGOSESTI',
      zip: '67443',
      isUrban: false,
    },
    {
      name: 'RANCACIOV',
      zip: '67452',
      isUrban: false,
    },
    {
      name: 'UNGURENI',
      zip: '67461',
      isUrban: false,
    },
    {
      name: 'FINTA MARE',
      zip: '67489',
      isUrban: false,
    },
    {
      name: 'BECHINESTI',
      zip: '67498',
      isUrban: false,
    },
    {
      name: 'FINTA VECHE',
      zip: '67504',
      isUrban: false,
    },
    {
      name: 'GHEBOAIA',
      zip: '67513',
      isUrban: false,
    },
    {
      name: 'GLODENI',
      zip: '67531',
      isUrban: false,
    }
  ],
  DJ: [
    {
      name: 'CRAIOVA',
      zip: '69919',
      isUrban: true,
    },
    {
      name: 'CERNELE',
      zip: '70058',
      isUrban: true,
    },
    {
      name: 'CERNELE DE SUS',
      zip: '70067',
      isUrban: true,
    },
    {
      name: 'FACAI',
      zip: '69928',
      isUrban: true,
    },
    {
      name: 'IZVORU RECE',
      zip: '70076',
      isUrban: true,
    },
    {
      name: 'MOFLENI',
      zip: '69937',
      isUrban: true,
    },
    {
      name: 'POPOVENI',
      zip: '69946',
      isUrban: true,
    },
    {
      name: 'ROVINE',
      zip: '70085',
      isUrban: true,
    },
    {
      name: 'SIMNICU DE JOS',
      zip: '69955',
      isUrban: true,
    },
    {
      name: 'BAILESTI',
      zip: '70325',
      isUrban: true,
    },
    {
      name: 'BALASAN',
      zip: '70334',
      isUrban: true,
    },
    {
      name: 'CALAFAT',
      zip: '70361',
      isUrban: true,
    },
    {
      name: 'BASARABI',
      zip: '70370',
      isUrban: true,
    },
    {
      name: 'CIUPERCENII VECHI',
      zip: '70389',
      isUrban: true,
    },
    {
      name: 'GOLENTI',
      zip: '70398',
      isUrban: true,
    },
    {
      name: 'FILIASI',
      zip: '70423',
      isUrban: true,
    },
    {
      name: 'ALMAJEL',
      zip: '70432',
      isUrban: true,
    },
    {
      name: 'BALTA',
      zip: '70441',
      isUrban: true,
    },
    {
      name: 'BRANISTE',
      zip: '70450',
      isUrban: true,
    },
    {
      name: 'FRATOSTITA',
      zip: '70469',
      isUrban: true,
    },
    {
      name: 'RACARII DE SUS',
      zip: '70478',
      isUrban: true,
    },
    {
      name: 'USCACI',
      zip: '70487',
      isUrban: true,
    },
    {
      name: 'SEGARCEA',
      zip: '70511',
      isUrban: true,
    },
    {
      name: 'BOURENI',
      zip: '70548',
      isUrban: false,
    },
    {
      name: 'COVEI',
      zip: '70557',
      isUrban: false,
    },
    {
      name: 'ALMAJ',
      zip: '70575',
      isUrban: false,
    },
    {
      name: 'BEHARCA',
      zip: '70584',
      isUrban: false,
    },
    {
      name: 'BOGEA',
      zip: '70593',
      isUrban: false,
    },
    {
      name: 'COTOFENII DIN FATA',
      zip: '70600',
      isUrban: false,
    },
    {
      name: 'MOSNENI',
      zip: '70619',
      isUrban: false,
    },
    {
      name: 'SITOAIA',
      zip: '70628',
      isUrban: false,
    },
    {
      name: 'AMARASTII DE JOS',
      zip: '70646',
      isUrban: false,
    },
    {
      name: 'OCOLNA',
      zip: '70655',
      isUrban: false,
    },
    {
      name: 'PRAPOR',
      zip: '70664',
      isUrban: false,
    },
    {
      name: 'AMARASTII DE SUS',
      zip: '70682',
      isUrban: false,
    },
    {
      name: 'ZVORSCA',
      zip: '70717',
      isUrban: false,
    },
    {
      name: 'APELE VII',
      zip: '70735',
      isUrban: false,
    },
    {
      name: 'ARGETOAIA',
      zip: '70753',
      isUrban: false,
    },
    {
      name: 'BERBESU',
      zip: '70771',
      isUrban: false,
    },
    {
      name: 'IORDACHESTI',
      zip: '70780',
      isUrban: false,
    },
    {
      name: 'LEORDOASA',
      zip: '70799',
      isUrban: false,
    },
    {
      name: 'MALUMNIC',
      zip: '70806',
      isUrban: false,
    },
    {
      name: 'NOVAC',
      zip: '70815',
      isUrban: false,
    },
    {
      name: 'PIRIA',
      zip: '70824',
      isUrban: false,
    },
    {
      name: 'POIANA FANTINII',
      zip: '70833',
      isUrban: false,
    },
    {
      name: 'SALCIA',
      zip: '70842',
      isUrban: false,
    },
    {
      name: 'TEASCU DIN DEAL',
      zip: '70851',
      isUrban: false,
    },
    {
      name: 'URSOAIA',
      zip: '70860',
      isUrban: false,
    },
    {
      name: 'BECHET',
      zip: '70888',
      isUrban: true,
    },
    {
      name: 'BARCA',
      zip: '70959',
      isUrban: false,
    },
    {
      name: 'BISTRET',
      zip: '70904',
      isUrban: false,
    },
    {
      name: 'BISTRETU NOU',
      zip: '70913',
      isUrban: false,
    },
    {
      name: 'BRANDUSA',
      zip: '70922',
      isUrban: false,
    },
    {
      name: 'PLOSCA',
      zip: '70931',
      isUrban: false,
    },
    {
      name: 'BOTOSESTI-PAIA',
      zip: '70977',
      isUrban: false,
    },
    {
      name: 'BRABOVA',
      zip: '70995',
      isUrban: false,
    },
    {
      name: 'CARAIMAN',
      zip: '71000',
      isUrban: false,
    },
    {
      name: 'MOSNA',
      zip: '71019',
      isUrban: false,
    },
    {
      name: 'RACHITA DE JOS',
      zip: '71028',
      isUrban: false,
    },
    {
      name: 'URDINITA',
      zip: '71037',
      isUrban: false,
    },
    {
      name: 'VOITA',
      zip: '71046',
      isUrban: false,
    },
    {
      name: 'BRADESTI',
      zip: '71064',
      isUrban: false,
    },
    {
      name: 'BRADESTII BATRINI',
      zip: '71073',
      isUrban: false,
    },
    {
      name: 'METEU',
      zip: '71082',
      isUrban: false,
    },
    {
      name: 'PISCANI',
      zip: '71091',
      isUrban: false,
    },
    {
      name: 'RACARII DE JOS',
      zip: '71108',
      isUrban: false,
    },
    {
      name: 'TATOMIRESTI',
      zip: '71117',
      isUrban: false,
    },
    {
      name: 'BRALOSTITA',
      zip: '71135',
      isUrban: false,
    },
    {
      name: 'CIOCANELE',
      zip: '71144',
      isUrban: false,
    },
    {
      name: 'RACOVITA',
      zip: '71153',
      isUrban: false,
    },
    {
      name: 'SCHITU',
      zip: '71162',
      isUrban: false,
    },
    {
      name: 'SFARCEA',
      zip: '71171',
      isUrban: false,
    },
    {
      name: 'VALEA FINTINILOR',
      zip: '71180',
      isUrban: false,
    },
    {
      name: 'BRATOVOESTI',
      zip: '71206',
      isUrban: false,
    },
    {
      name: 'BADOSI',
      zip: '71215',
      isUrban: false,
    },
    {
      name: 'GEOROCU MARE',
      zip: '71224',
      isUrban: false,
    },
    {
      name: 'PRUNET',
      zip: '71233',
      isUrban: false,
    },
    {
      name: 'ROJISTE',
      zip: '71242',
      isUrban: false,
    },
    {
      name: 'TAMBURESTI',
      zip: '71251',
      isUrban: false,
    },
    {
      name: 'BREASTA',
      zip: '71279',
      isUrban: false,
    },
    {
      name: 'COTU',
      zip: '71288',
      isUrban: false,
    },
    {
      name: 'CROVNA',
      zip: '71297',
      isUrban: false,
    },
    {
      name: 'FAGET',
      zip: '71304',
      isUrban: false,
    },
    {
      name: 'OBEDIN',
      zip: '71313',
      isUrban: false,
    },
    {
      name: 'ROSIENI',
      zip: '71322',
      isUrban: false,
    },
    {
      name: 'VALEA LUNGULUI',
      zip: '71331',
      isUrban: false,
    },
    {
      name: 'BUCOVAT',
      zip: '69973',
      isUrban: false,
    },
    {
      name: 'CARLIGEI',
      zip: '69982',
      isUrban: false,
    },
    {
      name: 'ITALIENI',
      zip: '69991',
      isUrban: false,
    },
    {
      name: 'LEAMNA DE JOS',
      zip: '70003',
      isUrban: false,
    },
    {
      name: 'LEAMNA DE SUS',
      zip: '70012',
      isUrban: false,
    },
    {
      name: 'PALILULA',
      zip: '70021',
      isUrban: false,
    },
    {
      name: 'SARBATOAREA',
      zip: '70030',
      isUrban: false,
    },
    {
      name: 'PREJOI',
      zip: '71359',
      isUrban: false,
    },
    {
      name: 'BULZESTI',
      zip: '71368',
      isUrban: false,
    },
    {
      name: 'FRATILA',
      zip: '71377',
      isUrban: false,
    },
    {
      name: 'GURA RACULUI',
      zip: '71386',
      isUrban: false,
    },
    {
      name: 'INFRATIREA',
      zip: '71395',
      isUrban: false,
    },
    {
      name: 'PISCU LUNG',
      zip: '71402',
      isUrban: false,
    },
    {
      name: 'POIENILE',
      zip: '71411',
      isUrban: false,
    },
    {
      name: 'SALISTE',
      zip: '71420',
      isUrban: false,
    },
    {
      name: 'SECULESTI',
      zip: '71439',
      isUrban: false,
    },
    {
      name: 'STOICESTI',
      zip: '71448',
      isUrban: false,
    },
    {
      name: 'CALARASI',
      zip: '71616',
      isUrban: false,
    },
    {
      name: 'SARATA',
      zip: '71625',
      isUrban: false,
    },
    {
      name: 'CALOPAR',
      zip: '71466',
      isUrban: false,
    },
    {
      name: 'BAZDANA',
      zip: '71484',
      isUrban: false,
    },
    {
      name: 'BELCINU',
      zip: '71475',
      isUrban: false,
    },
    {
      name: 'PANAGHIA',
      zip: '71493',
      isUrban: false,
    },
    {
      name: 'SALCUTA',
      zip: '71509',
      isUrban: false,
    },
    {
      name: 'CARAULA',
      zip: '71527',
      isUrban: false,
    },
    {
      name: 'CARPEN',
      zip: '71545',
      isUrban: false,
    },
    {
      name: 'CLEANOV',
      zip: '71554',
      isUrban: false,
    },
    {
      name: 'GEBLESTI',
      zip: '71563',
      isUrban: false,
    },
    {
      name: 'CASTRANOVA',
      zip: '71581',
      isUrban: false,
    },
    {
      name: 'PUTURI',
      zip: '71590',
      isUrban: false,
    },
    {
      name: 'CELARU',
      zip: '71643',
      isUrban: false,
    },
    {
      name: 'GHIZDAVESTI',
      zip: '71652',
      isUrban: false,
    },
    {
      name: 'MAROTINU DE JOS',
      zip: '71661',
      isUrban: false,
    },
    {
      name: 'MAROTINU DE SUS',
      zip: '71670',
      isUrban: false,
    },
    {
      name: 'SORENI',
      zip: '71689',
      isUrban: false,
    },
    {
      name: 'CERAT',
      zip: '71705',
      isUrban: false,
    },
    {
      name: 'MALAICA',
      zip: '71714',
      isUrban: false,
    },
    {
      name: 'CERNATESTI',
      zip: '71732',
      isUrban: false,
    },
    {
      name: 'CORNITA',
      zip: '71741',
      isUrban: false,
    },
    {
      name: 'RASNICU BATRIN',
      zip: '71750',
      isUrban: false,
    },
    {
      name: 'RASNICU OGHIAN',
      zip: '71769',
      isUrban: false,
    },
    {
      name: 'TIU',
      zip: '71778',
      isUrban: false,
    },
    {
      name: 'CETATE',
      zip: '71796',
      isUrban: false,
    },
    {
      name: 'MORENI',
      zip: '71803',
      isUrban: false,
    },
    {
      name: 'CIOROIASI',
      zip: '71821',
      isUrban: false,
    },
    {
      name: 'CETATUIA',
      zip: '71830',
      isUrban: false,
    },
    {
      name: 'CIOROIU NOU',
      zip: '71849',
      isUrban: false,
    },
    {
      name: 'CIUPERCENII NOI',
      zip: '71867',
      isUrban: false,
    },
    {
      name: 'SMARDAN',
      zip: '71876',
      isUrban: false,
    },
    {
      name: 'COSOVENI',
      zip: '71894',
      isUrban: false,
    },
    {
      name: 'CARCEA',
      zip: '71901',
      isUrban: false,
    },
    {
      name: 'COTOFENII DIN DOS',
      zip: '71929',
      isUrban: false,
    },
    {
      name: 'MIHAITA',
      zip: '71938',
      isUrban: false,
    },
    {
      name: 'POTMELTU',
      zip: '71947',
      isUrban: false,
    },
    {
      name: 'DABULENI',
      zip: '72016',
      isUrban: true,
    },
    {
      name: 'CHIASU',
      zip: '72025',
      isUrban: true,
    },
    {
      name: 'DANETI',
      zip: '71965',
      isUrban: false,
    },
    {
      name: 'BRABETI',
      zip: '71974',
      isUrban: false,
    },
    {
      name: 'BRANISTE',
      zip: '71983',
      isUrban: false,
    },
    {
      name: 'LOCUSTENI',
      zip: '71992',
      isUrban: false,
    },
    {
      name: 'DESA',
      zip: '72043',
      isUrban: false,
    },
    {
      name: 'DIOSTI',
      zip: '72061',
      isUrban: false,
    },
    {
      name: 'CIOCANESTI',
      zip: '72070',
      isUrban: false,
    },
    {
      name: 'RADOMIR',
      zip: '72089',
      isUrban: false,
    },
    {
      name: 'DOBRESTI',
      zip: '72105',
      isUrban: false,
    },
    {
      name: 'CACIULATESTI',
      zip: '72114',
      isUrban: false,
    },
    {
      name: 'GEOROCEL',
      zip: '72123',
      isUrban: false,
    },
    {
      name: 'MURTA',
      zip: '72132',
      isUrban: false,
    },
    {
      name: 'TOCENI',
      zip: '72141',
      isUrban: false,
    },
    {
      name: 'DRAGOTESTI',
      zip: '72169',
      isUrban: false,
    },
    {
      name: 'BENESTI',
      zip: '72178',
      isUrban: false,
    },
    {
      name: 'BOBEANU',
      zip: '72187',
      isUrban: false,
    },
    {
      name: 'BUZDUC',
      zip: '72196',
      isUrban: false,
    },
    {
      name: 'POPANZALESTI',
      zip: '72203',
      isUrban: false,
    },
    {
      name: 'VIISOARA',
      zip: '72212',
      isUrban: false,
    },
    {
      name: 'DRANIC',
      zip: '72230',
      isUrban: false,
    },
    {
      name: 'BOOVENI',
      zip: '72249',
      isUrban: false,
    },
    {
      name: 'FOISOR',
      zip: '72258',
      isUrban: false,
    },
    {
      name: 'PADEA',
      zip: '72267',
      isUrban: false,
    },
    {
      name: 'FARCAS',
      zip: '72285',
      isUrban: false,
    },
    {
      name: 'AMARASTI',
      zip: '72294',
      isUrban: false,
    },
    {
      name: 'GOLUMBELU',
      zip: '72301',
      isUrban: false,
    },
    {
      name: 'GOLUMBU',
      zip: '72310',
      isUrban: false,
    },
    {
      name: 'MOFLESTI',
      zip: '72329',
      isUrban: false,
    },
    {
      name: 'NISTOI',
      zip: '72338',
      isUrban: false,
    },
    {
      name: 'PLOPU AMARASTI',
      zip: '72347',
      isUrban: false,
    },
    {
      name: 'PUTINEI',
      zip: '72356',
      isUrban: false,
    },
    {
      name: 'SOCENI',
      zip: '72365',
      isUrban: false,
    },
    {
      name: 'TALPAS',
      zip: '72374',
      isUrban: false,
    },
    {
      name: 'GALICEA MARE',
      zip: '72392',
      isUrban: false,
    },
    {
      name: 'GHERCESTI',
      zip: '72418',
      isUrban: false,
    },
    {
      name: 'GARLESTI',
      zip: '72427',
      isUrban: false,
    },
    {
      name: 'LUNCSORU',
      zip: '72436',
      isUrban: false,
    },
    {
      name: 'UNGURENI',
      zip: '72445',
      isUrban: false,
    },
    {
      name: 'UNGURENII MICI',
      zip: '72454',
      isUrban: false,
    },
    {
      name: 'GIGHERA',
      zip: '72472',
      isUrban: false,
    },
    {
      name: 'NEDEIA',
      zip: '72481',
      isUrban: false,
    },
    {
      name: 'ZAVAL',
      zip: '72490',
      isUrban: false,
    },
    {
      name: 'GANGIOVA',
      zip: '72588',
      isUrban: false,
    },
    {
      name: 'COMOSTENI',
      zip: '72597',
      isUrban: false,
    },
    {
      name: 'GIUBEGA',
      zip: '72515',
      isUrban: false,
    },
    {
      name: 'GALICIUICA',
      zip: '72524',
      isUrban: false,
    },
    {
      name: 'GIURGITA',
      zip: '72542',
      isUrban: false,
    },
    {
      name: 'CURMATURA',
      zip: '72551',
      isUrban: false,
    },
    {
      name: 'FILARET',
      zip: '72560',
      isUrban: false,
    },
    {
      name: 'GOGOSU',
      zip: '72613',
      isUrban: false,
    },
    {
      name: 'GOGOSITA',
      zip: '72622',
      isUrban: false,
    },
    {
      name: 'STEFANEL',
      zip: '72631',
      isUrban: false,
    },
    {
      name: 'GOICEA',
      zip: '72659',
      isUrban: false,
    },
    {
      name: 'CARNA',
      zip: '72668',
      isUrban: false,
    },
    {
      name: 'GOIESTI',
      zip: '72686',
      isUrban: false,
    },
    {
      name: 'ADANCATA',
      zip: '72695',
      isUrban: false,
    },
    {
      name: 'FANTANI',
      zip: '72702',
      isUrban: false,
    },
    {
      name: 'GRUITA',
      zip: '72711',
      isUrban: false,
    },
    {
      name: 'MALAESTI',
      zip: '72720',
      isUrban: false,
    },
    {
      name: 'MOGOSESTI',
      zip: '72739',
      isUrban: false,
    },
    {
      name: 'MUERENI',
      zip: '72748',
      isUrban: false,
    },
    {
      name: 'PIORESTI',
      zip: '72757',
      isUrban: false,
    },
    {
      name: 'POMETESTI',
      zip: '72766',
      isUrban: false,
    },
    {
      name: 'POPEASA',
      zip: '72775',
      isUrban: false,
    },
    {
      name: 'TANDARA',
      zip: '72784',
      isUrban: false,
    },
    {
      name: 'VLADIMIR',
      zip: '72793',
      isUrban: false,
    },
    {
      name: 'ZLATARI',
      zip: '72800',
      isUrban: false,
    },
    {
      name: 'GRECESTI',
      zip: '72828',
      isUrban: false,
    },
    {
      name: 'BARBOI',
      zip: '72837',
      isUrban: false,
    },
    {
      name: 'BUSU',
      zip: '72846',
      isUrban: false,
    },
    {
      name: 'BUSULETU',
      zip: '72855',
      isUrban: false,
    },
    {
      name: 'GRADISTEA',
      zip: '72864',
      isUrban: false,
    },
    {
      name: 'GROPANELE',
      zip: '72873',
      isUrban: false,
    },
    {
      name: 'ISALNITA',
      zip: '70101',
      isUrban: false,
    },
    {
      name: 'IZVOARE',
      zip: '72891',
      isUrban: false,
    },
    {
      name: 'CORLATE',
      zip: '72908',
      isUrban: false,
    },
    {
      name: 'DOMNU TUDOR',
      zip: '72917',
      isUrban: false,
    },
    {
      name: 'LEU',
      zip: '72935',
      isUrban: false,
    },
    {
      name: 'ZANOAGA',
      zip: '72944',
      isUrban: false,
    },
    {
      name: 'LIPOVU',
      zip: '72962',
      isUrban: false,
    },
    {
      name: 'LIPOVU DE SUS',
      zip: '72971',
      isUrban: false,
    },
    {
      name: 'MACESU DE JOS',
      zip: '72999',
      isUrban: false,
    },
    {
      name: 'SAPATA',
      zip: '73004',
      isUrban: false,
    },
    {
      name: 'MACESU DE SUS',
      zip: '73022',
      isUrban: false,
    },
    {
      name: 'MAGLAVIT',
      zip: '73040',
      isUrban: false,
    },
    {
      name: 'HUNIA',
      zip: '73059',
      isUrban: false,
    },
    {
      name: 'MALU MARE',
      zip: '73077',
      isUrban: false,
    },
    {
      name: 'GHINDENI',
      zip: '73086',
      isUrban: false,
    },
    {
      name: 'PREAJBA',
      zip: '73095',
      isUrban: false,
    },
    {
      name: 'MARSANI',
      zip: '73326',
      isUrban: false,
    },
    {
      name: 'MELINESTI',
      zip: '73111',
      isUrban: false,
    },
    {
      name: 'BODAIESTI',
      zip: '73120',
      isUrban: false,
    },
    {
      name: 'BODAIESTII DE SUS',
      zip: '73139',
      isUrban: false,
    },
    {
      name: 'GODENI',
      zip: '73148',
      isUrban: false,
    },
    {
      name: 'MUIERUSU',
      zip: '73157',
      isUrban: false,
    },
    {
      name: 'NEGOIESTI',
      zip: '73166',
      isUrban: false,
    },
    {
      name: 'ODOLENI',
      zip: '73175',
      isUrban: false,
    },
    {
      name: 'OHABA',
      zip: '73184',
      isUrban: false,
    },
    {
      name: 'PLOSTINA',
      zip: '73193',
      isUrban: false,
    },
    {
      name: 'POPESTI',
      zip: '73200',
      isUrban: false,
    },
    {
      name: 'SPINENI',
      zip: '73219',
      isUrban: false,
    },
    {
      name: 'VALEA MARE',
      zip: '73228',
      isUrban: false,
    },
    {
      name: 'VALEA MUIERII DE JOS',
      zip: '73237',
      isUrban: false,
    },
    {
      name: 'MISCHII',
      zip: '73255',
      isUrban: false,
    },
    {
      name: 'CALINESTI',
      zip: '73264',
      isUrban: false,
    },
    {
      name: 'GOGOSESTI',
      zip: '73273',
      isUrban: false,
    },
    {
      name: 'MLECANESTI',
      zip: '73282',
      isUrban: false,
    },
    {
      name: 'MOTOCI',
      zip: '73291',
      isUrban: false,
    },
    {
      name: 'URECHESTI',
      zip: '73308',
      isUrban: false,
    },
    {
      name: 'MOTATEI',
      zip: '73344',
      isUrban: false,
    },
    {
      name: 'DOBRIDOR',
      zip: '73353',
      isUrban: false,
    },
    {
      name: 'MOTATEI GARA',
      zip: '73362',
      isUrban: false,
    },
    {
      name: 'BALOTA DE JOS',
      zip: '73380',
      isUrban: false,
    },
    {
      name: 'BALOTA DE SUS',
      zip: '73399',
      isUrban: false,
    },
    {
      name: 'BUSTENI',
      zip: '73406',
      isUrban: false,
    },
    {
      name: 'GAIA',
      zip: '73415',
      isUrban: false,
    },
    {
      name: 'MURGASI',
      zip: '73424',
      isUrban: false,
    },
    {
      name: 'PICATURILE',
      zip: '73433',
      isUrban: false,
    },
    {
      name: 'RUPTURILE',
      zip: '73442',
      isUrban: false,
    },
    {
      name: 'VELESTI',
      zip: '73451',
      isUrban: false,
    },
    {
      name: 'NEGOI',
      zip: '73479',
      isUrban: false,
    },
    {
      name: 'CATANE',
      zip: '73488',
      isUrban: false,
    },
    {
      name: 'CATANELE NOI',
      zip: '73497',
      isUrban: false,
    },
    {
      name: 'ORODEL',
      zip: '73512',
      isUrban: false,
    },
    {
      name: 'BECHET',
      zip: '73521',
      isUrban: false,
    },
    {
      name: 'CALUGAREI',
      zip: '73530',
      isUrban: false,
    },
    {
      name: 'CORNU',
      zip: '73549',
      isUrban: false,
    },
    {
      name: 'TEIU',
      zip: '73558',
      isUrban: false,
    },
    {
      name: 'OSTROVENI',
      zip: '73576',
      isUrban: false,
    },
    {
      name: 'LISTEAVA',
      zip: '73585',
      isUrban: false,
    },
    {
      name: 'PERISOR',
      zip: '73601',
      isUrban: false,
    },
    {
      name: 'MARACINELE',
      zip: '73610',
      isUrban: false,
    },
    {
      name: 'PIELESTI',
      zip: '73638',
      isUrban: false,
    },
    {
      name: 'CAMPENI',
      zip: '73647',
      isUrban: false,
    },
    {
      name: 'LANGA',
      zip: '73656',
      isUrban: false,
    },
    {
      name: 'PISCU VECHI',
      zip: '73674',
      isUrban: false,
    },
    {
      name: 'GHIDICI',
      zip: '73683',
      isUrban: false,
    },
    {
      name: 'PISCULET',
      zip: '73692',
      isUrban: false,
    },
    {
      name: 'PLENITA',
      zip: '73718',
      isUrban: false,
    },
    {
      name: 'CASTRELE TRAIANE',
      zip: '73727',
      isUrban: false,
    },
    {
      name: 'PODARI',
      zip: '70129',
      isUrban: false,
    },
    {
      name: 'BALTA VERDE',
      zip: '70138',
      isUrban: false,
    },
    {
      name: 'BRANISTE',
      zip: '70147',
      isUrban: false,
    },
    {
      name: 'GURA VAII',
      zip: '70156',
      isUrban: false,
    },
    {
      name: 'LIVEZI',
      zip: '70165',
      isUrban: false,
    },
    {
      name: 'POIANA MARE',
      zip: '73745',
      isUrban: false,
    },
    {
      name: 'MILOVAN',
      zip: '73825',
      isUrban: false,
    },
    {
      name: 'PLESOI',
      zip: '73834',
      isUrban: false,
    },
    {
      name: 'PREDESTII MICI',
      zip: '73843',
      isUrban: false,
    },
    {
      name: 'RADOVAN',
      zip: '73861',
      isUrban: false,
    },
    {
      name: 'FANTINELE',
      zip: '73870',
      isUrban: false,
    },
    {
      name: 'INTORSURA',
      zip: '73889',
      isUrban: false,
    },
    {
      name: 'TIRNAVA',
      zip: '73898',
      isUrban: false,
    },
    {
      name: 'RAST',
      zip: '73914',
      isUrban: false,
    },
    {
      name: 'ROBANESTII DE JOS',
      zip: '73932',
      isUrban: false,
    },
    {
      name: 'BOJOIU',
      zip: '73941',
      isUrban: false,
    },
    {
      name: 'GOLFIN',
      zip: '73950',
      isUrban: false,
    },
    {
      name: 'LACRITA MARE',
      zip: '73969',
      isUrban: false,
    },
    {
      name: 'LACRITA MICA',
      zip: '73978',
      isUrban: false,
    },
    {
      name: 'ROBANESTII DE SUS',
      zip: '73987',
      isUrban: false,
    },
    {
      name: 'SADOVA',
      zip: '74000',
      isUrban: false,
    },
    {
      name: 'PISCU SADOVEI',
      zip: '74019',
      isUrban: false,
    },
    {
      name: 'SALCUTA',
      zip: '74037',
      isUrban: false,
    },
    {
      name: 'MARZA',
      zip: '74046',
      isUrban: false,
    },
    {
      name: 'PLOPSOR',
      zip: '74055',
      isUrban: false,
    },
    {
      name: 'TENCANAU',
      zip: '74064',
      isUrban: false,
    },
    {
      name: 'SCAESTI',
      zip: '74082',
      isUrban: false,
    },
    {
      name: 'VALEA LUI PATRU',
      zip: '74091',
      isUrban: false,
    },
    {
      name: 'SEACA DE CAMP',
      zip: '74117',
      isUrban: false,
    },
    {
      name: 'PISCU NOU',
      zip: '74126',
      isUrban: false,
    },
    {
      name: 'SEACA DE PADURE',
      zip: '74144',
      isUrban: false,
    },
    {
      name: 'RACHITA DE SUS',
      zip: '74153',
      isUrban: false,
    },
    {
      name: 'VELENI',
      zip: '74162',
      isUrban: false,
    },
    {
      name: 'SECU',
      zip: '74180',
      isUrban: false,
    },
    {
      name: 'COMANICEA',
      zip: '74199',
      isUrban: false,
    },
    {
      name: 'SMADOVICIOARA DE SECU',
      zip: '74206',
      isUrban: false,
    },
    {
      name: 'SUMANDRA',
      zip: '74215',
      isUrban: false,
    },
    {
      name: 'SILISTEA CRUCII',
      zip: '74233',
      isUrban: false,
    },
    {
      name: 'SIMNICU DE SUS',
      zip: '70183',
      isUrban: false,
    },
    {
      name: 'ALBESTI',
      zip: '70192',
      isUrban: false,
    },
    {
      name: 'CORNETU',
      zip: '70209',
      isUrban: false,
    },
    {
      name: 'DELENI',
      zip: '70218',
      isUrban: false,
    },
    {
      name: 'DUDOVICESTI',
      zip: '70227',
      isUrban: false,
    },
    {
      name: 'DUTULESTI',
      zip: '70236',
      isUrban: false,
    },
    {
      name: 'FLORESTI',
      zip: '70245',
      isUrban: false,
    },
    {
      name: 'IZVOR',
      zip: '70254',
      isUrban: false,
    },
    {
      name: 'JIENI',
      zip: '70263',
      isUrban: false,
    },
    {
      name: 'LESILE',
      zip: '70272',
      isUrban: false,
    },
    {
      name: 'MILESTI',
      zip: '70281',
      isUrban: false,
    },
    {
      name: 'ROMANESTI',
      zip: '70290',
      isUrban: false,
    },
    {
      name: 'SOPOT',
      zip: '74251',
      isUrban: false,
    },
    {
      name: 'BASCOV',
      zip: '74260',
      isUrban: false,
    },
    {
      name: 'BELOT',
      zip: '74279',
      isUrban: false,
    },
    {
      name: 'CERNAT',
      zip: '74288',
      isUrban: false,
    },
    {
      name: 'PERENI',
      zip: '74297',
      isUrban: false,
    },
    {
      name: 'PIETROAIA',
      zip: '74304',
      isUrban: false,
    },
    {
      name: 'SARSCA',
      zip: '74313',
      isUrban: false,
    },
    {
      name: 'TEASC',
      zip: '74331',
      isUrban: false,
    },
    {
      name: 'SECUI',
      zip: '74340',
      isUrban: false,
    },
    {
      name: 'TERPEZITA',
      zip: '74368',
      isUrban: false,
    },
    {
      name: 'CACIULATU',
      zip: '74386',
      isUrban: false,
    },
    {
      name: 'CARUIA',
      zip: '74377',
      isUrban: false,
    },
    {
      name: 'FLORAN',
      zip: '74395',
      isUrban: false,
    },
    {
      name: 'LAZU',
      zip: '74402',
      isUrban: false,
    },
    {
      name: 'TESLUI',
      zip: '74420',
      isUrban: false,
    },
    {
      name: 'COSERENI',
      zip: '74439',
      isUrban: false,
    },
    {
      name: 'FANTINELE',
      zip: '74448',
      isUrban: false,
    },
    {
      name: 'PREAJBA DE JOS',
      zip: '74457',
      isUrban: false,
    },
    {
      name: 'PREAJBA DE PADURE',
      zip: '74466',
      isUrban: false,
    },
    {
      name: 'TARTAL',
      zip: '74475',
      isUrban: false,
    },
    {
      name: 'URIENI',
      zip: '74484',
      isUrban: false,
    },
    {
      name: 'VIISOARA MOSNENI',
      zip: '74493',
      isUrban: false,
    },
    {
      name: 'TUGLUI',
      zip: '74518',
      isUrban: false,
    },
    {
      name: 'JIUL',
      zip: '74527',
      isUrban: false,
    },
    {
      name: 'UNIREA',
      zip: '74545',
      isUrban: false,
    },
    {
      name: 'URZICUTA',
      zip: '74563',
      isUrban: false,
    },
    {
      name: 'URZICA MARE',
      zip: '74572',
      isUrban: false,
    },
    {
      name: 'VALEA STANCIULUI',
      zip: '74590',
      isUrban: false,
    },
    {
      name: 'HOREZU POENARI',
      zip: '74607',
      isUrban: false,
    },
    {
      name: 'VARTOP',
      zip: '74741',
      isUrban: false,
    },
    {
      name: 'VARVORU DE JOS',
      zip: '74769',
      isUrban: false,
    },
    {
      name: 'BUJOR',
      zip: '74778',
      isUrban: false,
    },
    {
      name: 'CIUTURA',
      zip: '74787',
      isUrban: false,
    },
    {
      name: 'CRIVA',
      zip: '74796',
      isUrban: false,
    },
    {
      name: 'DOBROMIRA',
      zip: '74803',
      isUrban: false,
    },
    {
      name: 'DRAGOAIA',
      zip: '74812',
      isUrban: false,
    },
    {
      name: 'GABRU',
      zip: '74821',
      isUrban: false,
    },
    {
      name: 'VARVOR',
      zip: '74830',
      isUrban: false,
    },
    {
      name: 'VELA',
      zip: '74625',
      isUrban: false,
    },
    {
      name: 'BUCOVICIOR',
      zip: '74634',
      isUrban: false,
    },
    {
      name: 'CETATUIA',
      zip: '74643',
      isUrban: false,
    },
    {
      name: 'DESNATUI',
      zip: '74652',
      isUrban: false,
    },
    {
      name: 'GUBAUCEA',
      zip: '74661',
      isUrban: false,
    },
    {
      name: 'SEGLET',
      zip: '74670',
      isUrban: false,
    },
    {
      name: 'STIUBEI',
      zip: '74698',
      isUrban: false,
    },
    {
      name: 'SUHARU',
      zip: '74689',
      isUrban: false,
    },
    {
      name: 'VERBITA',
      zip: '74714',
      isUrban: false,
    },
    {
      name: 'VERBICIOARA',
      zip: '74723',
      isUrban: false,
    },
    {
      name: 'DOBROTESTI',
      zip: '70691',
      isUrban: false,
    },
    {
      name: 'NISIPURI',
      zip: '70708',
      isUrban: false,
    },
    {
      name: 'BARANU',
      zip: '70762',
      isUrban: false,
    },
    {
      name: 'TUNARII NOI',
      zip: '73754',
      isUrban: false,
    },
    {
      name: 'TUNARII VECHI',
      zip: '73763',
      isUrban: false,
    },
    {
      name: 'PREDESTI',
      zip: '73781',
      isUrban: false,
    },
    {
      name: 'BUCICANI',
      zip: '73790',
      isUrban: false,
    },
    {
      name: 'CARSTOVANI',
      zip: '73807',
      isUrban: false,
    },
    {
      name: 'FRASIN',
      zip: '73816',
      isUrban: false,
    },
    {
      name: 'AFUMATI',
      zip: '70539',
      isUrban: false,
    }
  ],
  GL: [
    {
      name: 'GALATI',
      zip: '75105',
      isUrban: true,
    },
    {
      name: 'TECUCI',
      zip: '75212',
      isUrban: true,
    },
    {
      name: 'BERESTI',
      zip: '75347',
      isUrban: true,
    },
    {
      name: 'TARGU BUJOR',
      zip: '75481',
      isUrban: true,
    },
    {
      name: 'MOSCU',
      zip: '75490',
      isUrban: true,
    },
    {
      name: 'UMBRARESTI',
      zip: '75506',
      isUrban: true,
    },
    {
      name: 'BALABANESTI',
      zip: '75551',
      isUrban: false,
    },
    {
      name: 'BURSUCANI',
      zip: '75560',
      isUrban: false,
    },
    {
      name: 'CRUCEANU',
      zip: '75579',
      isUrban: false,
    },
    {
      name: 'LUNGESTI',
      zip: '75588',
      isUrban: false,
    },
    {
      name: 'RADESTI',
      zip: '75597',
      isUrban: false,
    },
    {
      name: 'ZIMBRU',
      zip: '75604',
      isUrban: false,
    },
    {
      name: 'BALASESTI',
      zip: '75622',
      isUrban: false,
    },
    {
      name: 'CIURESTI',
      zip: '75631',
      isUrban: false,
    },
    {
      name: 'CIURESTII NOI',
      zip: '75640',
      isUrban: false,
    },
    {
      name: 'PUPEZENI',
      zip: '75659',
      isUrban: false,
    },
    {
      name: 'BALENI',
      zip: '75677',
      isUrban: false,
    },
    {
      name: 'BANEASA',
      zip: '75695',
      isUrban: false,
    },
    {
      name: 'ROSCANI',
      zip: '75702',
      isUrban: false,
    },
    {
      name: 'BARCEA',
      zip: '75524',
      isUrban: false,
    },
    {
      name: 'PODOLENI',
      zip: '75533',
      isUrban: false,
    },
    {
      name: 'BERESTI-MERIA',
      zip: '75365',
      isUrban: false,
    },
    {
      name: 'ALDESTI',
      zip: '75374',
      isUrban: false,
    },
    {
      name: 'BALINTESTI',
      zip: '75383',
      isUrban: false,
    },
    {
      name: 'ONCIU',
      zip: '75392',
      isUrban: false,
    },
    {
      name: 'PLESA',
      zip: '75409',
      isUrban: false,
    },
    {
      name: 'PRODANESTI',
      zip: '75418',
      isUrban: false,
    },
    {
      name: 'PURICANI',
      zip: '75427',
      isUrban: false,
    },
    {
      name: 'SASENI',
      zip: '75436',
      isUrban: false,
    },
    {
      name: 'SIPOTE',
      zip: '75454',
      isUrban: false,
    },
    {
      name: 'SLIVNA',
      zip: '75445',
      isUrban: false,
    },
    {
      name: 'BRAHASESTI',
      zip: '75775',
      isUrban: false,
    },
    {
      name: 'CORCIOVENI',
      zip: '75784',
      isUrban: false,
    },
    {
      name: 'COSITENI',
      zip: '75793',
      isUrban: false,
    },
    {
      name: 'TOFLEA',
      zip: '75800',
      isUrban: false,
    },
    {
      name: 'BRANISTEA',
      zip: '75720',
      isUrban: false,
    },
    {
      name: 'LOZOVA',
      zip: '75739',
      isUrban: false,
    },
    {
      name: 'TRAIAN',
      zip: '75748',
      isUrban: false,
    },
    {
      name: 'VASILE ALECSANDRI',
      zip: '75757',
      isUrban: false,
    },
    {
      name: 'BUCIUMENI',
      zip: '75828',
      isUrban: false,
    },
    {
      name: 'HANTESTI',
      zip: '75837',
      isUrban: false,
    },
    {
      name: 'TECUCELU SEC',
      zip: '75846',
      isUrban: false,
    },
    {
      name: 'VIZURESTI',
      zip: '75855',
      isUrban: false,
    },
    {
      name: 'CAVADINESTI',
      zip: '75873',
      isUrban: false,
    },
    {
      name: 'COMANESTI',
      zip: '75882',
      isUrban: false,
    },
    {
      name: 'GANESTI',
      zip: '75891',
      isUrban: false,
    },
    {
      name: 'VADENI',
      zip: '75908',
      isUrban: false,
    },
    {
      name: 'CERTESTI',
      zip: '75926',
      isUrban: false,
    },
    {
      name: 'CARLOMANESTI',
      zip: '75935',
      isUrban: false,
    },
    {
      name: 'COTOROAIA',
      zip: '75944',
      isUrban: false,
    },
    {
      name: 'CORNI',
      zip: '76013',
      isUrban: false,
    },
    {
      name: 'MACISENI',
      zip: '76022',
      isUrban: false,
    },
    {
      name: 'URLESTI',
      zip: '76031',
      isUrban: false,
    },
    {
      name: 'COROD',
      zip: '75962',
      isUrban: false,
    },
    {
      name: 'BLANZI',
      zip: '75971',
      isUrban: false,
    },
    {
      name: 'BRATULESTI',
      zip: '75980',
      isUrban: false,
    },
    {
      name: 'CARAPCESTI',
      zip: '75999',
      isUrban: false,
    },
    {
      name: 'COSMESTI',
      zip: '76059',
      isUrban: false,
    },
    {
      name: 'BALTARETI',
      zip: '76068',
      isUrban: false,
    },
    {
      name: 'COSMESTII-VALE',
      zip: '76077',
      isUrban: false,
    },
    {
      name: 'FURCENII NOI',
      zip: '76086',
      isUrban: false,
    },
    {
      name: 'FURCENII VECHI',
      zip: '76095',
      isUrban: false,
    },
    {
      name: 'SATU NOU',
      zip: '76102',
      isUrban: false,
    },
    {
      name: 'COSTACHE NEGRI',
      zip: '76120',
      isUrban: false,
    },
    {
      name: 'CUCA',
      zip: '76148',
      isUrban: false,
    },
    {
      name: 'CUDALBI',
      zip: '76166',
      isUrban: false,
    },
    {
      name: 'DRAGANESTI',
      zip: '75230',
      isUrban: false,
    },
    {
      name: 'MALU ALB',
      zip: '75249',
      isUrban: false,
    },
    {
      name: 'DRAGUSENI',
      zip: '76184',
      isUrban: false,
    },
    {
      name: 'ADAM',
      zip: '76193',
      isUrban: false,
    },
    {
      name: 'CAUIESTI',
      zip: '76200',
      isUrban: false,
    },
    {
      name: 'FUNDEANU',
      zip: '76219',
      isUrban: false,
    },
    {
      name: 'GHINGHESTI',
      zip: '76228',
      isUrban: false,
    },
    {
      name: 'NICOPOLE',
      zip: '76237',
      isUrban: false,
    },
    {
      name: 'STIETESTI',
      zip: '76246',
      isUrban: false,
    },
    {
      name: 'FARTANESTI',
      zip: '76264',
      isUrban: false,
    },
    {
      name: 'VIILE',
      zip: '76273',
      isUrban: false,
    },
    {
      name: 'FOLTESTI',
      zip: '76291',
      isUrban: false,
    },
    {
      name: 'STOICANI',
      zip: '76308',
      isUrban: false,
    },
    {
      name: 'FRUMUSITA',
      zip: '76326',
      isUrban: false,
    },
    {
      name: 'IJDILENI',
      zip: '76335',
      isUrban: false,
    },
    {
      name: 'TAMAOANI',
      zip: '76344',
      isUrban: false,
    },
    {
      name: 'FUNDENII NOI',
      zip: '76362',
      isUrban: false,
    },
    {
      name: 'FUNDENI',
      zip: '76371',
      isUrban: false,
    },
    {
      name: 'HANU CONACHI',
      zip: '76380',
      isUrban: false,
    },
    {
      name: 'LUNGOCI',
      zip: '76399',
      isUrban: false,
    },
    {
      name: 'GHIDIGENI',
      zip: '76415',
      isUrban: false,
    },
    {
      name: 'GARA GHIDIGENI',
      zip: '76424',
      isUrban: false,
    },
    {
      name: 'GARBOVAT',
      zip: '76442',
      isUrban: false,
    },
    {
      name: 'GEFU',
      zip: '76433',
      isUrban: false,
    },
    {
      name: 'GURA GARBOVATULUI',
      zip: '76451',
      isUrban: false,
    },
    {
      name: 'SLOBOZIA CORNI',
      zip: '76460',
      isUrban: false,
    },
    {
      name: 'TALPIGI',
      zip: '76479',
      isUrban: false,
    },
    {
      name: 'TAPLAU',
      zip: '76488',
      isUrban: false,
    },
    {
      name: 'GOHOR',
      zip: '76503',
      isUrban: false,
    },
    {
      name: 'GARA BERHECI',
      zip: '76512',
      isUrban: false,
    },
    {
      name: 'IREASCA',
      zip: '76521',
      isUrban: false,
    },
    {
      name: 'NARTESTI',
      zip: '76530',
      isUrban: false,
    },
    {
      name: 'POSTA',
      zip: '76549',
      isUrban: false,
    },
    {
      name: 'GRIVITA',
      zip: '76567',
      isUrban: false,
    },
    {
      name: 'CALMATUI',
      zip: '76576',
      isUrban: false,
    },
    {
      name: 'INDEPENDENTA',
      zip: '76594',
      isUrban: false,
    },
    {
      name: 'IVESTI',
      zip: '76610',
      isUrban: false,
    },
    {
      name: 'BUCESTI',
      zip: '76629',
      isUrban: false,
    },
    {
      name: 'JORASTI',
      zip: '76647',
      isUrban: false,
    },
    {
      name: 'LUNCA',
      zip: '76656',
      isUrban: false,
    },
    {
      name: 'ZARNESTI',
      zip: '76665',
      isUrban: false,
    },
    {
      name: 'LIESTI',
      zip: '76683',
      isUrban: false,
    },
    {
      name: 'MASTACANI',
      zip: '76727',
      isUrban: false,
    },
    {
      name: 'CHIRAFTEI',
      zip: '76736',
      isUrban: false,
    },
    {
      name: 'MATCA',
      zip: '76709',
      isUrban: false,
    },
    {
      name: 'MOVILENI',
      zip: '76754',
      isUrban: false,
    },
    {
      name: 'MUNTENI',
      zip: '75267',
      isUrban: false,
    },
    {
      name: 'FRUNZEASCA',
      zip: '75276',
      isUrban: false,
    },
    {
      name: 'NEGRILESTI',
      zip: '75285',
      isUrban: false,
    },
    {
      name: 'SLOBOZIA BLANEASA',
      zip: '75294',
      isUrban: false,
    },
    {
      name: 'TIGANESTI',
      zip: '75301',
      isUrban: false,
    },
    {
      name: 'UNGURENI',
      zip: '75310',
      isUrban: false,
    },
    {
      name: 'NAMOLOASA',
      zip: '76772',
      isUrban: false,
    },
    {
      name: 'CRANGENI',
      zip: '76781',
      isUrban: false,
    },
    {
      name: 'NAMOLOASA SAT',
      zip: '76790',
      isUrban: false,
    },
    {
      name: 'NICORESTI',
      zip: '76816',
      isUrban: false,
    },
    {
      name: 'BRANISTEA',
      zip: '76825',
      isUrban: false,
    },
    {
      name: 'COASTA LUPEI',
      zip: '76834',
      isUrban: false,
    },
    {
      name: 'DOBRINESTI',
      zip: '76843',
      isUrban: false,
    },
    {
      name: 'FANTANI',
      zip: '76852',
      isUrban: false,
    },
    {
      name: 'GROZAVESTI',
      zip: '76861',
      isUrban: false,
    },
    {
      name: 'IONASESTI',
      zip: '76870',
      isUrban: false,
    },
    {
      name: 'MALURENI',
      zip: '76889',
      isUrban: false,
    },
    {
      name: 'PISCU CORBULUI',
      zip: '76898',
      isUrban: false,
    },
    {
      name: 'POIANA',
      zip: '76905',
      isUrban: false,
    },
    {
      name: 'SARBI',
      zip: '76914',
      isUrban: false,
    },
    {
      name: 'VISINA',
      zip: '76923',
      isUrban: false,
    },
    {
      name: 'OANCEA',
      zip: '76941',
      isUrban: false,
    },
    {
      name: 'SLOBOZIA OANCEA',
      zip: '76950',
      isUrban: false,
    },
    {
      name: 'PECHEA',
      zip: '76978',
      isUrban: false,
    },
    {
      name: 'LUPELE',
      zip: '76987',
      isUrban: false,
    },
    {
      name: 'PISCU',
      zip: '77000',
      isUrban: false,
    },
    {
      name: 'VAMES',
      zip: '77019',
      isUrban: false,
    },
    {
      name: 'PRIPONESTI',
      zip: '77037',
      isUrban: false,
    },
    {
      name: 'CIORASTI',
      zip: '77046',
      isUrban: false,
    },
    {
      name: 'HUSTIU',
      zip: '77055',
      isUrban: false,
    },
    {
      name: 'LIESTI',
      zip: '77064',
      isUrban: false,
    },
    {
      name: 'PRIPONESTII DE JOS',
      zip: '77073',
      isUrban: false,
    },
    {
      name: 'REDIU',
      zip: '77091',
      isUrban: false,
    },
    {
      name: 'PLEVNA',
      zip: '77108',
      isUrban: false,
    },
    {
      name: 'SUHURLUI',
      zip: '77117',
      isUrban: false,
    },
    {
      name: 'SCANTEIESTI',
      zip: '77135',
      isUrban: false,
    },
    {
      name: 'FANTANELE',
      zip: '77144',
      isUrban: false,
    },
    {
      name: 'SCHELA',
      zip: '77162',
      isUrban: false,
    },
    {
      name: 'NEGREA',
      zip: '77171',
      isUrban: false,
    },
    {
      name: 'SENDRENI',
      zip: '75123',
      isUrban: false,
    },
    {
      name: 'MOVILENI',
      zip: '75132',
      isUrban: false,
    },
    {
      name: 'SERBESTII VECHI',
      zip: '75141',
      isUrban: false,
    },
    {
      name: 'SLOBOZIA CONACHI',
      zip: '77199',
      isUrban: false,
    },
    {
      name: 'CUZA VODA',
      zip: '77206',
      isUrban: false,
    },
    {
      name: 'IZVOARELE',
      zip: '77215',
      isUrban: false,
    },
    {
      name: 'SMARDAN',
      zip: '77233',
      isUrban: false,
    },
    {
      name: 'TATARCA',
      zip: '77368',
      isUrban: false,
    },
    {
      name: 'UMBRARESTI',
      zip: '77411',
      isUrban: false,
    },
    {
      name: 'CONDREA',
      zip: '77420',
      isUrban: false,
    },
    {
      name: 'SALCIA',
      zip: '77439',
      isUrban: false,
    },
    {
      name: 'SILISTEA',
      zip: '77448',
      isUrban: false,
    },
    {
      name: 'TORCESTI',
      zip: '77457',
      isUrban: false,
    },
    {
      name: 'UMBRARESTI-DEAL',
      zip: '77466',
      isUrban: false,
    },
    {
      name: 'VALEA MARULUI',
      zip: '77484',
      isUrban: false,
    },
    {
      name: 'MANDRESTI',
      zip: '77493',
      isUrban: false,
    },
    {
      name: 'VANATORI',
      zip: '75169',
      isUrban: false,
    },
    {
      name: 'COSTI',
      zip: '75178',
      isUrban: false,
    },
    {
      name: 'ODAIA MANOLACHE',
      zip: '75187',
      isUrban: false,
    },
    {
      name: 'VARLEZI',
      zip: '77518',
      isUrban: false,
    },
    {
      name: 'CRAIESTI',
      zip: '77527',
      isUrban: false,
    },
    {
      name: 'VLADESTI',
      zip: '77545',
      isUrban: false,
    },
    {
      name: 'BRANESTI',
      zip: '77554',
      isUrban: false,
    },
    {
      name: 'CISMELE',
      zip: '77242',
      isUrban: false,
    },
    {
      name: 'MIHAIL KOGALNICEANU',
      zip: '77251',
      isUrban: false,
    },
    {
      name: 'SMULTI',
      zip: '77279',
      isUrban: false,
    },
    {
      name: 'SUCEVENI',
      zip: '77297',
      isUrban: false,
    },
    {
      name: 'ROGOJENI',
      zip: '77304',
      isUrban: false,
    },
    {
      name: 'TEPU',
      zip: '77386',
      isUrban: false,
    },
    {
      name: 'TEPU DE SUS',
      zip: '77395',
      isUrban: false,
    },
    {
      name: 'TUDOR VLADIMIRESCU',
      zip: '77322',
      isUrban: false,
    },
    {
      name: 'TULUCESTI',
      zip: '77340',
      isUrban: false,
    },
    {
      name: 'SIVITA',
      zip: '77359',
      isUrban: false,
    }
  ],
  GR: [
    {
      name: 'GIURGIU',
      zip: '100530',
      isUrban: true,
    },
    {
      name: 'BOLINTIN-VALE',
      zip: '101207',
      isUrban: true,
    },
    {
      name: 'CRIVINA',
      zip: '101216',
      isUrban: true,
    },
    {
      name: 'MALU SPART',
      zip: '101225',
      isUrban: true,
    },
    {
      name: 'SUSENI',
      zip: '101234',
      isUrban: true,
    },
    {
      name: 'MIHAILESTI',
      zip: '104145',
      isUrban: true,
    },
    {
      name: 'DRAGANESCU',
      zip: '104154',
      isUrban: true,
    },
    {
      name: 'NOVACI',
      zip: '104163',
      isUrban: true,
    },
    {
      name: 'POPESTI',
      zip: '104172',
      isUrban: true,
    },
    {
      name: 'ADUNATII-COPACENI',
      zip: '100790',
      isUrban: false,
    },
    {
      name: 'DARASTI-VLASCA',
      zip: '100807',
      isUrban: false,
    },
    {
      name: 'MOGOSESTI',
      zip: '100816',
      isUrban: false,
    },
    {
      name: 'VARLAAM',
      zip: '100825',
      isUrban: false,
    },
    {
      name: 'BANEASA',
      zip: '101010',
      isUrban: false,
    },
    {
      name: 'FRASINU',
      zip: '101029',
      isUrban: false,
    },
    {
      name: 'PIETRELE',
      zip: '101038',
      isUrban: false,
    },
    {
      name: 'SFANTU GHEORGHE',
      zip: '101047',
      isUrban: false,
    },
    {
      name: 'BOLINTIN-DEAL',
      zip: '101172',
      isUrban: false,
    },
    {
      name: 'MIHAI VODA',
      zip: '101181',
      isUrban: false,
    },
    {
      name: 'BUCSANI',
      zip: '101387',
      isUrban: false,
    },
    {
      name: 'ANGHELESTI',
      zip: '101396',
      isUrban: false,
    },
    {
      name: 'GOLEASCA',
      zip: '101403',
      isUrban: false,
    },
    {
      name: 'OBEDENI',
      zip: '101412',
      isUrban: false,
    },
    {
      name: 'PODISOR',
      zip: '101421',
      isUrban: false,
    },
    {
      name: 'UIESTI',
      zip: '101449',
      isUrban: false,
    },
    {
      name: 'VADU LAT',
      zip: '101430',
      isUrban: false,
    },
    {
      name: 'BULBUCATA',
      zip: '101528',
      isUrban: false,
    },
    {
      name: 'COTENI',
      zip: '101537',
      isUrban: false,
    },
    {
      name: 'FACAU',
      zip: '101546',
      isUrban: false,
    },
    {
      name: 'TEISORI',
      zip: '101555',
      isUrban: false,
    },
    {
      name: 'BUTURUGENI',
      zip: '101626',
      isUrban: false,
    },
    {
      name: 'PADURENI',
      zip: '101635',
      isUrban: false,
    },
    {
      name: 'PODU ILFOVATULUI',
      zip: '101644',
      isUrban: false,
    },
    {
      name: 'POSTA',
      zip: '101653',
      isUrban: false,
    },
    {
      name: 'CALUGARENI',
      zip: '101671',
      isUrban: false,
    },
    {
      name: 'BRANISTARI',
      zip: '101680',
      isUrban: false,
    },
    {
      name: 'CRUCEA DE PIATRA',
      zip: '101699',
      isUrban: false,
    },
    {
      name: 'HULUBESTI',
      zip: '101706',
      isUrban: false,
    },
    {
      name: 'UZUNU',
      zip: '101715',
      isUrban: false,
    },
    {
      name: 'CLEJANI',
      zip: '101993',
      isUrban: false,
    },
    {
      name: 'NEAJLOVU',
      zip: '102008',
      isUrban: false,
    },
    {
      name: 'PODU DOAMNEI',
      zip: '102017',
      isUrban: false,
    },
    {
      name: 'STEREA',
      zip: '102026',
      isUrban: false,
    },
    {
      name: 'COLIBASI',
      zip: '102080',
      isUrban: false,
    },
    {
      name: 'CAMPURELU',
      zip: '102099',
      isUrban: false,
    },
    {
      name: 'COMANA',
      zip: '102115',
      isUrban: false,
    },
    {
      name: 'BUDENI',
      zip: '102124',
      isUrban: false,
    },
    {
      name: 'FALASTOACA',
      zip: '102133',
      isUrban: false,
    },
    {
      name: 'GRADISTEA',
      zip: '102142',
      isUrban: false,
    },
    {
      name: 'VLAD TEPES',
      zip: '102151',
      isUrban: false,
    },
    {
      name: 'CREVEDIA MARE',
      zip: '102357',
      isUrban: false,
    },
    {
      name: 'CREVEDIA MICA',
      zip: '102366',
      isUrban: false,
    },
    {
      name: 'DEALU',
      zip: '102375',
      isUrban: false,
    },
    {
      name: 'GAISEANCA',
      zip: '102384',
      isUrban: false,
    },
    {
      name: 'PRIBOIU',
      zip: '102393',
      isUrban: false,
    },
    {
      name: 'SFANTU GHEORGHE',
      zip: '102400',
      isUrban: false,
    },
    {
      name: 'DAIA',
      zip: '102455',
      isUrban: false,
    },
    {
      name: 'PLOPSORU',
      zip: '102464',
      isUrban: false,
    },
    {
      name: 'STOENESTI',
      zip: '102801',
      isUrban: false,
    },
    {
      name: 'FLORESTI',
      zip: '102810',
      isUrban: false,
    },
    {
      name: 'PALANCA',
      zip: '102829',
      isUrban: false,
    },
    {
      name: 'FRATESTI',
      zip: '102918',
      isUrban: false,
    },
    {
      name: 'CETATEA',
      zip: '102927',
      isUrban: false,
    },
    {
      name: 'REMUS',
      zip: '102936',
      isUrban: false,
    },
    {
      name: 'GAISENI',
      zip: '103096',
      isUrban: false,
    },
    {
      name: 'CARPENISU',
      zip: '103103',
      isUrban: false,
    },
    {
      name: 'CASCIOARELE',
      zip: '103112',
      isUrban: false,
    },
    {
      name: 'PODU POPA NAE',
      zip: '103121',
      isUrban: false,
    },
    {
      name: 'GAUJANI',
      zip: '103201',
      isUrban: false,
    },
    {
      name: 'CETATUIA',
      zip: '103210',
      isUrban: false,
    },
    {
      name: 'PIETRISU',
      zip: '103229',
      isUrban: false,
    },
    {
      name: 'GHIMPATI',
      zip: '103247',
      isUrban: false,
    },
    {
      name: 'COPACIU',
      zip: '103256',
      isUrban: false,
    },
    {
      name: 'NAIPU',
      zip: '103265',
      isUrban: false,
    },
    {
      name: 'VALEA PLOPILOR',
      zip: '103274',
      isUrban: false,
    },
    {
      name: 'GOGOSARI',
      zip: '103318',
      isUrban: false,
    },
    {
      name: 'DRAGHICEANU',
      zip: '103327',
      isUrban: false,
    },
    {
      name: 'IZVORU',
      zip: '103336',
      isUrban: false,
    },
    {
      name: 'RALESTI',
      zip: '103345',
      isUrban: false,
    },
    {
      name: 'GOSTINARI',
      zip: '103381',
      isUrban: false,
    },
    {
      name: 'MIRONESTI',
      zip: '103390',
      isUrban: false,
    },
    {
      name: 'GOSTINU',
      zip: '103363',
      isUrban: false,
    },
    {
      name: 'GRADINARI',
      zip: '103416',
      isUrban: false,
    },
    {
      name: 'TANTAVA',
      zip: '103425',
      isUrban: false,
    },
    {
      name: 'ZORILE',
      zip: '103434',
      isUrban: false,
    },
    {
      name: 'GREACA',
      zip: '103489',
      isUrban: false,
    },
    {
      name: 'PUTU GRECI',
      zip: '103498',
      isUrban: false,
    },
    {
      name: 'ZBOIU',
      zip: '103504',
      isUrban: false,
    },
    {
      name: 'HOTARELE',
      zip: '103648',
      isUrban: false,
    },
    {
      name: 'HERASTI',
      zip: '103657',
      isUrban: false,
    },
    {
      name: 'IZVOARELE',
      zip: '103666',
      isUrban: false,
    },
    {
      name: 'MILOSESTI',
      zip: '103675',
      isUrban: false,
    },
    {
      name: 'TEIUSU',
      zip: '103684',
      isUrban: false,
    },
    {
      name: 'IEPURESTI',
      zip: '103700',
      isUrban: false,
    },
    {
      name: 'BANESTI',
      zip: '103719',
      isUrban: false,
    },
    {
      name: 'CHIRCULESTI',
      zip: '103728',
      isUrban: false,
    },
    {
      name: 'GORNENI',
      zip: '103737',
      isUrban: false,
    },
    {
      name: 'STALPU',
      zip: '103746',
      isUrban: false,
    },
    {
      name: 'VALTER MARACINEANU',
      zip: '103755',
      isUrban: false,
    },
    {
      name: 'IZVOARELE',
      zip: '103906',
      isUrban: false,
    },
    {
      name: 'CHIRIACU',
      zip: '103915',
      isUrban: false,
    },
    {
      name: 'DIMITRIE CANTEMIR',
      zip: '103924',
      isUrban: false,
    },
    {
      name: 'PETRU RARES',
      zip: '103933',
      isUrban: false,
    },
    {
      name: 'RADU VODA',
      zip: '103942',
      isUrban: false,
    },
    {
      name: 'VALEA BUJORULUI',
      zip: '103951',
      isUrban: false,
    },
    {
      name: 'JOITA',
      zip: '104001',
      isUrban: false,
    },
    {
      name: 'BACU',
      zip: '104010',
      isUrban: false,
    },
    {
      name: 'COSOBA',
      zip: '104029',
      isUrban: false,
    },
    {
      name: 'SABARENI',
      zip: '104038',
      isUrban: false,
    },
    {
      name: 'LETCA NOUA',
      zip: '104056',
      isUrban: false,
    },
    {
      name: 'LETCA VECHE',
      zip: '104065',
      isUrban: false,
    },
    {
      name: 'MILCOVATU',
      zip: '104074',
      isUrban: false,
    },
    {
      name: 'MARSA',
      zip: '104234',
      isUrban: false,
    },
    {
      name: 'MIHAI BRAVU',
      zip: '104127',
      isUrban: false,
    },
    {
      name: 'OGREZENI',
      zip: '104494',
      isUrban: false,
    },
    {
      name: 'HOBAIA',
      zip: '104500',
      isUrban: false,
    },
    {
      name: 'OINACU',
      zip: '104528',
      isUrban: false,
    },
    {
      name: 'BRANISTEA',
      zip: '104537',
      isUrban: false,
    },
    {
      name: 'PRUNDU',
      zip: '104699',
      isUrban: false,
    },
    {
      name: 'PUIENI',
      zip: '104706',
      isUrban: false,
    },
    {
      name: 'PUTINEIU',
      zip: '104724',
      isUrban: false,
    },
    {
      name: 'HODIVOAIA',
      zip: '104733',
      isUrban: false,
    },
    {
      name: 'VIERU',
      zip: '104742',
      isUrban: false,
    },
    {
      name: 'RASUCENI',
      zip: '104797',
      isUrban: false,
    },
    {
      name: 'CARAPANCEA',
      zip: '104804',
      isUrban: false,
    },
    {
      name: 'CUCURUZU',
      zip: '104813',
      isUrban: false,
    },
    {
      name: 'SATU NOU',
      zip: '104822',
      isUrban: false,
    },
    {
      name: 'ROATA DE JOS',
      zip: '104840',
      isUrban: false,
    },
    {
      name: 'CARTOJANI',
      zip: '104859',
      isUrban: false,
    },
    {
      name: 'ROATA MICA',
      zip: '104868',
      isUrban: false,
    },
    {
      name: 'SADINA',
      zip: '104877',
      isUrban: false,
    },
    {
      name: 'SCHITU',
      zip: '104984',
      isUrban: false,
    },
    {
      name: 'BILA',
      zip: '104993',
      isUrban: false,
    },
    {
      name: 'CAMINEASCA',
      zip: '105008',
      isUrban: false,
    },
    {
      name: 'VLASIN',
      zip: '105017',
      isUrban: false,
    },
    {
      name: 'SINGURENI',
      zip: '105115',
      isUrban: false,
    },
    {
      name: 'CRANGURI',
      zip: '105124',
      isUrban: false,
    },
    {
      name: 'STEJARU',
      zip: '105133',
      isUrban: false,
    },
    {
      name: 'SLOBOZIA',
      zip: '100558',
      isUrban: false,
    },
    {
      name: 'STANESTI',
      zip: '105302',
      isUrban: false,
    },
    {
      name: 'BALANU',
      zip: '105311',
      isUrban: false,
    },
    {
      name: 'GHIZDARU',
      zip: '105320',
      isUrban: false,
    },
    {
      name: 'ONCESTI',
      zip: '105339',
      isUrban: false,
    },
    {
      name: 'STOENESTI',
      zip: '105357',
      isUrban: false,
    },
    {
      name: 'IANCULESTI',
      zip: '105366',
      isUrban: false,
    },
    {
      name: 'MIRAU',
      zip: '105375',
      isUrban: false,
    },
    {
      name: 'TOPORU',
      zip: '154718',
      isUrban: false,
    },
    {
      name: 'TOMULESTI',
      zip: '154727',
      isUrban: false,
    },
    {
      name: 'ULMI',
      zip: '105632',
      isUrban: false,
    },
    {
      name: 'CASCIOARELE',
      zip: '105641',
      isUrban: false,
    },
    {
      name: 'DRAGANEASCA',
      zip: '105650',
      isUrban: false,
    },
    {
      name: 'GHIONEA',
      zip: '105669',
      isUrban: false,
    },
    {
      name: 'ICOANA',
      zip: '105678',
      isUrban: false,
    },
    {
      name: 'MOSTENI',
      zip: '105687',
      isUrban: false,
    },
    {
      name: 'POENARI',
      zip: '105696',
      isUrban: false,
    },
    {
      name: 'TRESTIENI',
      zip: '105703',
      isUrban: false,
    },
    {
      name: 'VALEA DRAGULUI',
      zip: '105785',
      isUrban: false,
    },
    {
      name: 'VANATORII MICI',
      zip: '105981',
      isUrban: false,
    },
    {
      name: 'CORBEANCA',
      zip: '105990',
      isUrban: false,
    },
    {
      name: 'CUPELE',
      zip: '106005',
      isUrban: false,
    },
    {
      name: 'IZVORU',
      zip: '106014',
      isUrban: false,
    },
    {
      name: 'POIANA LUI STANGA',
      zip: '106023',
      isUrban: false,
    },
    {
      name: 'VALCELELE',
      zip: '106032',
      isUrban: false,
    },
    {
      name: 'VANATORII MARI',
      zip: '106041',
      isUrban: false,
    },
    {
      name: 'ZADARICIU',
      zip: '106050',
      isUrban: false,
    },
    {
      name: 'VARASTI',
      zip: '105883',
      isUrban: false,
    },
    {
      name: 'DOBRENI',
      zip: '105892',
      isUrban: false,
    },
    {
      name: 'VEDEA',
      zip: '105918',
      isUrban: false,
    },
    {
      name: 'MALU',
      zip: '105927',
      isUrban: false,
    }
  ],
  GJ: [
    {
      name: 'TARGU JIU',
      zip: '77821',
      isUrban: true,
    },
    {
      name: 'BARSESTI',
      zip: '77830',
      isUrban: true,
    },
    {
      name: 'DRAGOENI',
      zip: '77849',
      isUrban: true,
    },
    {
      name: 'IEZURENI',
      zip: '77858',
      isUrban: true,
    },
    {
      name: 'POLATA',
      zip: '77867',
      isUrban: true,
    },
    {
      name: 'PREAJBA MARE',
      zip: '77876',
      isUrban: true,
    },
    {
      name: 'ROMANESTI',
      zip: '77885',
      isUrban: true,
    },
    {
      name: 'SLOBOZIA',
      zip: '77894',
      isUrban: true,
    },
    {
      name: 'URSATI',
      zip: '77901',
      isUrban: true,
    },
    {
      name: 'BUMBESTI-JIU',
      zip: '79317',
      isUrban: true,
    },
    {
      name: 'CURTISOARA',
      zip: '79326',
      isUrban: true,
    },
    {
      name: 'LAZARESTI',
      zip: '79335',
      isUrban: true,
    },
    {
      name: 'PLESA',
      zip: '79344',
      isUrban: true,
    },
    {
      name: 'TETILA',
      zip: '79353',
      isUrban: true,
    },
    {
      name: 'MOTRU',
      zip: '78150',
      isUrban: true,
    },
    {
      name: 'DEALU POMILOR',
      zip: '78169',
      isUrban: true,
    },
    {
      name: 'HORASTI',
      zip: '78178',
      isUrban: true,
    },
    {
      name: 'INSURATEI',
      zip: '78187',
      isUrban: true,
    },
    {
      name: 'LEURDA',
      zip: '78196',
      isUrban: true,
    },
    {
      name: 'LUPOITA',
      zip: '78212',
      isUrban: true,
    },
    {
      name: 'PLOSTINA',
      zip: '78203',
      isUrban: true,
    },
    {
      name: 'RAPA',
      zip: '78230',
      isUrban: true,
    },
    {
      name: 'ROSIUTA',
      zip: '78221',
      isUrban: true,
    },
    {
      name: 'NOVACI',
      zip: '78267',
      isUrban: true,
    },
    {
      name: 'BERCESTI',
      zip: '78276',
      isUrban: true,
    },
    {
      name: 'HIRISESTI',
      zip: '78285',
      isUrban: true,
    },
    {
      name: 'POCIOVALISTEA',
      zip: '78294',
      isUrban: true,
    },
    {
      name: 'SITESTI',
      zip: '78301',
      isUrban: true,
    },
    {
      name: 'ROVINARI',
      zip: '79059',
      isUrban: true,
    },
    {
      name: 'VIRT',
      zip: '82902',
      isUrban: true,
    },
    {
      name: 'TICLENI',
      zip: '78463',
      isUrban: true,
    },
    {
      name: 'TARGU CARBUNESTI',
      zip: '78338',
      isUrban: true,
    },
    {
      name: 'BLAHNITA DE JOS',
      zip: '78347',
      isUrban: true,
    },
    {
      name: 'CARBUNESTI-SAT',
      zip: '78356',
      isUrban: true,
    },
    {
      name: 'COJANI',
      zip: '78365',
      isUrban: true,
    },
    {
      name: 'CRETESTI',
      zip: '78374',
      isUrban: true,
    },
    {
      name: 'CURTEANA',
      zip: '78383',
      isUrban: true,
    },
    {
      name: 'FLORESTENI',
      zip: '78392',
      isUrban: true,
    },
    {
      name: 'MACESU',
      zip: '78409',
      isUrban: true,
    },
    {
      name: 'POJOGENI',
      zip: '78418',
      isUrban: true,
    },
    {
      name: 'ROGOJENI',
      zip: '78427',
      isUrban: true,
    },
    {
      name: 'STEFANESTI',
      zip: '78436',
      isUrban: true,
    },
    {
      name: 'ALBENI',
      zip: '78481',
      isUrban: false,
    },
    {
      name: 'BARZEIU DE GILORT',
      zip: '78490',
      isUrban: false,
    },
    {
      name: 'BOLBOCESTI',
      zip: '78506',
      isUrban: false,
    },
    {
      name: 'DOSENI',
      zip: '78515',
      isUrban: false,
    },
    {
      name: 'MIROSLOVENI',
      zip: '78524',
      isUrban: false,
    },
    {
      name: 'PRUNESTI',
      zip: '78533',
      isUrban: false,
    },
    {
      name: 'ALIMPESTI',
      zip: '78551',
      isUrban: false,
    },
    {
      name: 'CIUPERCENII DE OLTET',
      zip: '78560',
      isUrban: false,
    },
    {
      name: 'CORSORU',
      zip: '78579',
      isUrban: false,
    },
    {
      name: 'NISTORESTI',
      zip: '78588',
      isUrban: false,
    },
    {
      name: 'SARBESTI',
      zip: '78597',
      isUrban: false,
    },
    {
      name: 'ANINOASA',
      zip: '78613',
      isUrban: false,
    },
    {
      name: 'BOBAIA',
      zip: '78622',
      isUrban: false,
    },
    {
      name: 'COSTESTI',
      zip: '78631',
      isUrban: false,
    },
    {
      name: 'GROSEREA',
      zip: '78640',
      isUrban: false,
    },
    {
      name: 'STERPOAIA',
      zip: '78659',
      isUrban: false,
    },
    {
      name: 'ARCANI',
      zip: '78677',
      isUrban: false,
    },
    {
      name: 'CAMPOFENI',
      zip: '78686',
      isUrban: false,
    },
    {
      name: 'SANATESTI',
      zip: '78695',
      isUrban: false,
    },
    {
      name: 'RASOVA',
      zip: '77965',
      isUrban: false,
    },
    {
      name: 'STOLOJANI',
      zip: '77974',
      isUrban: false,
    },
    {
      name: 'TALPASESTI',
      zip: '77983',
      isUrban: false,
    },
    {
      name: 'TAMASESTI',
      zip: '77992',
      isUrban: false,
    },
    {
      name: 'VOINIGESTI',
      zip: '78007',
      isUrban: false,
    },
    {
      name: 'BALTENI',
      zip: '79013',
      isUrban: false,
    },
    {
      name: 'COCORENI',
      zip: '79022',
      isUrban: false,
    },
    {
      name: 'MOI',
      zip: '79031',
      isUrban: false,
    },
    {
      name: 'PESTEANA JIU',
      zip: '79040',
      isUrban: false,
    },
    {
      name: 'VLADULENI',
      zip: '79068',
      isUrban: false,
    },
    {
      name: 'BARBATESTI',
      zip: '78837',
      isUrban: false,
    },
    {
      name: 'MUSCULESTI',
      zip: '78846',
      isUrban: false,
    },
    {
      name: 'PETRESTI',
      zip: '78855',
      isUrban: false,
    },
    {
      name: 'SOCU',
      zip: '78864',
      isUrban: false,
    },
    {
      name: 'BENGESTI',
      zip: '78882',
      isUrban: false,
    },
    {
      name: 'BALCESTI',
      zip: '78891',
      isUrban: false,
    },
    {
      name: 'BIRCII',
      zip: '78908',
      isUrban: false,
    },
    {
      name: 'CIOCADIA',
      zip: '78917',
      isUrban: false,
    },
    {
      name: 'BERLESTI',
      zip: '78935',
      isUrban: false,
    },
    {
      name: 'BARZEIU',
      zip: '78944',
      isUrban: false,
    },
    {
      name: 'GALCESTI',
      zip: '78953',
      isUrban: false,
    },
    {
      name: 'LIHULESTI',
      zip: '78962',
      isUrban: false,
    },
    {
      name: 'PARIU VIU',
      zip: '78971',
      isUrban: false,
    },
    {
      name: 'SCRADA',
      zip: '78980',
      isUrban: false,
    },
    {
      name: 'SCURTU',
      zip: '78999',
      isUrban: false,
    },
    {
      name: 'BOLBOSI',
      zip: '79086',
      isUrban: false,
    },
    {
      name: 'BALACESTI',
      zip: '79095',
      isUrban: false,
    },
    {
      name: 'BOLBOASA',
      zip: '79102',
      isUrban: false,
    },
    {
      name: 'IGIROSU',
      zip: '79111',
      isUrban: false,
    },
    {
      name: 'MICLOSU',
      zip: '79120',
      isUrban: false,
    },
    {
      name: 'OHABA JIU',
      zip: '79139',
      isUrban: false,
    },
    {
      name: 'VALEA',
      zip: '79148',
      isUrban: false,
    },
    {
      name: 'BORASCU',
      zip: '79166',
      isUrban: false,
    },
    {
      name: 'BANIU',
      zip: '79175',
      isUrban: false,
    },
    {
      name: 'CALAPARU',
      zip: '79184',
      isUrban: false,
    },
    {
      name: 'GURA MENTI',
      zip: '79193',
      isUrban: false,
    },
    {
      name: 'MENTII DIN DOS',
      zip: '79200',
      isUrban: false,
    },
    {
      name: 'MILUTA',
      zip: '79219',
      isUrban: false,
    },
    {
      name: 'SCORUSU',
      zip: '79228',
      isUrban: false,
    },
    {
      name: 'BRANESTI',
      zip: '79246',
      isUrban: false,
    },
    {
      name: 'BADESTI',
      zip: '79255',
      isUrban: false,
    },
    {
      name: 'BREBENEI',
      zip: '79264',
      isUrban: false,
    },
    {
      name: 'CAPU DEALULUI',
      zip: '79273',
      isUrban: false,
    },
    {
      name: 'GILORTU',
      zip: '79282',
      isUrban: false,
    },
    {
      name: 'PARIU',
      zip: '79291',
      isUrban: false,
    },
    {
      name: 'BUMBESTI-PITIC',
      zip: '79371',
      isUrban: false,
    },
    {
      name: 'CARLIGEI',
      zip: '79380',
      isUrban: false,
    },
    {
      name: 'POIENARI',
      zip: '79399',
      isUrban: false,
    },
    {
      name: 'BUSTUCHIN',
      zip: '79415',
      isUrban: false,
    },
    {
      name: 'CIONTI',
      zip: '79424',
      isUrban: false,
    },
    {
      name: 'MOTORGI',
      zip: '79433',
      isUrban: false,
    },
    {
      name: 'NAMETE',
      zip: '79442',
      isUrban: false,
    },
    {
      name: 'POIANA SECIURI',
      zip: '79460',
      isUrban: false,
    },
    {
      name: 'POIENITA',
      zip: '79451',
      isUrban: false,
    },
    {
      name: 'POJARU',
      zip: '79479',
      isUrban: false,
    },
    {
      name: 'VALEA POJARULUI',
      zip: '79488',
      isUrban: false,
    },
    {
      name: 'CALNIC',
      zip: '79745',
      isUrban: false,
    },
    {
      name: 'CILNICU DE SUS',
      zip: '79754',
      isUrban: false,
    },
    {
      name: 'DIDILESTI',
      zip: '79763',
      isUrban: false,
    },
    {
      name: 'GALESOAIA',
      zip: '79772',
      isUrban: false,
    },
    {
      name: 'HODOREASCA',
      zip: '79781',
      isUrban: false,
    },
    {
      name: 'PIEPTANI',
      zip: '79790',
      isUrban: false,
    },
    {
      name: 'PINOASA',
      zip: '79807',
      isUrban: false,
    },
    {
      name: 'STEJEREI',
      zip: '79816',
      isUrban: false,
    },
    {
      name: 'VALCEAUA',
      zip: '79825',
      isUrban: false,
    },
    {
      name: 'CAPRENI',
      zip: '79503',
      isUrban: false,
    },
    {
      name: 'ALUNIS',
      zip: '79512',
      isUrban: false,
    },
    {
      name: 'BRATESTI',
      zip: '79521',
      isUrban: false,
    },
    {
      name: 'BULBUCENI',
      zip: '79530',
      isUrban: false,
    },
    {
      name: 'CETATEA',
      zip: '79549',
      isUrban: false,
    },
    {
      name: 'CORNETU',
      zip: '79558',
      isUrban: false,
    },
    {
      name: 'DEALU SPIREI',
      zip: '79567',
      isUrban: false,
    },
    {
      name: 'SATU NOU',
      zip: '79576',
      isUrban: false,
    },
    {
      name: 'CATUNELE',
      zip: '79594',
      isUrban: false,
    },
    {
      name: 'DEALU VIILOR',
      zip: '79601',
      isUrban: false,
    },
    {
      name: 'LUPOAIA',
      zip: '79610',
      isUrban: false,
    },
    {
      name: 'STEIC',
      zip: '79629',
      isUrban: false,
    },
    {
      name: 'VALEA MANASTIRII',
      zip: '79638',
      isUrban: false,
    },
    {
      name: 'VALEA PERILOR',
      zip: '79647',
      isUrban: false,
    },
    {
      name: 'CIUPERCENI',
      zip: '79665',
      isUrban: false,
    },
    {
      name: 'BOBOIESTI',
      zip: '79674',
      isUrban: false,
    },
    {
      name: 'PESTEANA VULCAN',
      zip: '79683',
      isUrban: false,
    },
    {
      name: 'PRIPORU',
      zip: '79692',
      isUrban: false,
    },
    {
      name: 'STRAMBA VULCAN',
      zip: '79709',
      isUrban: false,
    },
    {
      name: 'VARTOPU',
      zip: '79718',
      isUrban: false,
    },
    {
      name: 'ZORZILA',
      zip: '79727',
      isUrban: false,
    },
    {
      name: 'CRASNA',
      zip: '79843',
      isUrban: false,
    },
    {
      name: 'ANINISU DIN DEAL',
      zip: '79852',
      isUrban: false,
    },
    {
      name: 'ANINISU DIN VALE',
      zip: '79861',
      isUrban: false,
    },
    {
      name: 'BUZESTI',
      zip: '79870',
      isUrban: false,
    },
    {
      name: 'CARPINIS',
      zip: '79889',
      isUrban: false,
    },
    {
      name: 'CRASNA DIN DEAL',
      zip: '79898',
      isUrban: false,
    },
    {
      name: 'DRAGOIESTI',
      zip: '79905',
      isUrban: false,
    },
    {
      name: 'DUMBRAVENI',
      zip: '79914',
      isUrban: false,
    },
    {
      name: 'RADOSI',
      zip: '79923',
      isUrban: false,
    },
    {
      name: 'CRUSET',
      zip: '79941',
      isUrban: false,
    },
    {
      name: 'BOJINU',
      zip: '79950',
      isUrban: false,
    },
    {
      name: 'MAIAG',
      zip: '79978',
      isUrban: false,
    },
    {
      name: 'MARINESTI',
      zip: '79969',
      isUrban: false,
    },
    {
      name: 'MIEREA',
      zip: '79987',
      isUrban: false,
    },
    {
      name: 'MIERICEAUA',
      zip: '79996',
      isUrban: false,
    },
    {
      name: 'SLAMNESTI',
      zip: '80007',
      isUrban: false,
    },
    {
      name: 'SLAVUTA',
      zip: '80016',
      isUrban: false,
    },
    {
      name: 'URDA DE JOS',
      zip: '80025',
      isUrban: false,
    },
    {
      name: 'VALUTA',
      zip: '80034',
      isUrban: false,
    },
    {
      name: 'DANCIULESTI',
      zip: '80052',
      isUrban: false,
    },
    {
      name: 'BIBULESTI',
      zip: '80061',
      isUrban: false,
    },
    {
      name: 'HALANGESTI',
      zip: '80070',
      isUrban: false,
    },
    {
      name: 'OBARSIA',
      zip: '80089',
      isUrban: false,
    },
    {
      name: 'PETRACHEI',
      zip: '80098',
      isUrban: false,
    },
    {
      name: 'RADINESTI',
      zip: '80105',
      isUrban: false,
    },
    {
      name: 'ZAICOIU',
      zip: '80114',
      isUrban: false,
    },
    {
      name: 'DANESTI',
      zip: '80132',
      isUrban: false,
    },
    {
      name: 'BARZA',
      zip: '80141',
      isUrban: false,
    },
    {
      name: 'BOTOROGI',
      zip: '80150',
      isUrban: false,
    },
    {
      name: 'BRATUIA',
      zip: '80169',
      isUrban: false,
    },
    {
      name: 'BUCUREASA',
      zip: '80178',
      isUrban: false,
    },
    {
      name: 'MERFULESTI',
      zip: '80187',
      isUrban: false,
    },
    {
      name: 'SASA',
      zip: '80196',
      isUrban: false,
    },
    {
      name: 'TARCULESTI',
      zip: '80212',
      isUrban: false,
    },
    {
      name: 'TROCANI',
      zip: '80203',
      isUrban: false,
    },
    {
      name: 'UNGURENI',
      zip: '80221',
      isUrban: false,
    },
    {
      name: 'VACAREA',
      zip: '80230',
      isUrban: false,
    },
    {
      name: 'DRAGOTESTI',
      zip: '80258',
      isUrban: false,
    },
    {
      name: 'COROBAI',
      zip: '80267',
      isUrban: false,
    },
    {
      name: 'TRESTIOARA',
      zip: '80276',
      isUrban: false,
    },
    {
      name: 'DRAGUTESTI',
      zip: '78025',
      isUrban: false,
    },
    {
      name: 'CARBESTI',
      zip: '78034',
      isUrban: false,
    },
    {
      name: 'DAMBOVA',
      zip: '78043',
      isUrban: false,
    },
    {
      name: 'IASI-GORJ',
      zip: '78052',
      isUrban: false,
    },
    {
      name: 'TALVESTI',
      zip: '78061',
      isUrban: false,
    },
    {
      name: 'URECHESTI',
      zip: '78070',
      isUrban: false,
    },
    {
      name: 'FARCASESTI',
      zip: '80294',
      isUrban: false,
    },
    {
      name: 'FARCASESTI MOSNENI',
      zip: '80301',
      isUrban: false,
    },
    {
      name: 'PESTEANA DE JOS',
      zip: '80310',
      isUrban: false,
    },
    {
      name: 'ROGOJEL',
      zip: '80329',
      isUrban: false,
    },
    {
      name: 'ROSIA JIU',
      zip: '80338',
      isUrban: false,
    },
    {
      name: 'TIMISENI',
      zip: '80347',
      isUrban: false,
    },
    {
      name: 'VALEA CU APA',
      zip: '80356',
      isUrban: false,
    },
    {
      name: 'IORMANESTI',
      zip: '80374',
      isUrban: false,
    },
    {
      name: 'CAMUIESTI',
      zip: '80383',
      isUrban: false,
    },
    {
      name: 'CLESNESTI',
      zip: '80392',
      isUrban: false,
    },
    {
      name: 'GLOGOVA',
      zip: '80409',
      isUrban: false,
    },
    {
      name: 'OLTEANU',
      zip: '80418',
      isUrban: false,
    },
    {
      name: 'GODINESTI',
      zip: '80436',
      isUrban: false,
    },
    {
      name: 'ARJOCI',
      zip: '80445',
      isUrban: false,
    },
    {
      name: 'CALCESTI',
      zip: '80463',
      isUrban: false,
    },
    {
      name: 'CHILIU',
      zip: '80454',
      isUrban: false,
    },
    {
      name: 'PARAU DE PRIPOR',
      zip: '80472',
      isUrban: false,
    },
    {
      name: 'PARAU DE VALE',
      zip: '80481',
      isUrban: false,
    },
    {
      name: 'RATEZ',
      zip: '80490',
      isUrban: false,
    },
    {
      name: 'HUREZANI',
      zip: '80515',
      isUrban: false,
    },
    {
      name: 'BUSUIOCI',
      zip: '80524',
      isUrban: false,
    },
    {
      name: 'PEGENI',
      zip: '80533',
      isUrban: false,
    },
    {
      name: 'PLOPU',
      zip: '80542',
      isUrban: false,
    },
    {
      name: 'TOTEA DE HUREZANI',
      zip: '80551',
      isUrban: false,
    },
    {
      name: 'IONESTI',
      zip: '80579',
      isUrban: false,
    },
    {
      name: 'GURA SUSITEI',
      zip: '80588',
      isUrban: false,
    },
    {
      name: 'ILIESTI',
      zip: '80597',
      isUrban: false,
    },
    {
      name: 'PICU',
      zip: '80604',
      isUrban: false,
    },
    {
      name: 'JUPANESTI',
      zip: '80622',
      isUrban: false,
    },
    {
      name: 'BOIA',
      zip: '80631',
      isUrban: false,
    },
    {
      name: 'PARIU BOIA',
      zip: '80640',
      isUrban: false,
    },
    {
      name: 'VIDIN',
      zip: '80659',
      isUrban: false,
    },
    {
      name: 'VIERSANI',
      zip: '80668',
      isUrban: false,
    },
    {
      name: 'LELESTI',
      zip: '80686',
      isUrban: false,
    },
    {
      name: 'FRATESTI',
      zip: '80695',
      isUrban: false,
    },
    {
      name: 'RASOVITA',
      zip: '80702',
      isUrban: false,
    },
    {
      name: 'LICURICI',
      zip: '80720',
      isUrban: false,
    },
    {
      name: 'FRUMUSEI',
      zip: '80739',
      isUrban: false,
    },
    {
      name: 'NEGRENI',
      zip: '80748',
      isUrban: false,
    },
    {
      name: 'TOTEA',
      zip: '80757',
      isUrban: false,
    },
    {
      name: 'TARGU LOGRESTI',
      zip: '80775',
      isUrban: false,
    },
    {
      name: 'COLTESTI',
      zip: '80784',
      isUrban: false,
    },
    {
      name: 'FRUNZA',
      zip: '80793',
      isUrban: false,
    },
    {
      name: 'LOGRESTI MOSTENI',
      zip: '80800',
      isUrban: false,
    },
    {
      name: 'MARU',
      zip: '80819',
      isUrban: false,
    },
    {
      name: 'POPESTI',
      zip: '80828',
      isUrban: false,
    },
    {
      name: 'SEACA',
      zip: '80837',
      isUrban: false,
    },
    {
      name: 'MATASARI',
      zip: '80855',
      isUrban: false,
    },
    {
      name: 'BRADET',
      zip: '80864',
      isUrban: false,
    },
    {
      name: 'BRADETEL',
      zip: '80873',
      isUrban: false,
    },
    {
      name: 'CROICI',
      zip: '80882',
      isUrban: false,
    },
    {
      name: 'RUNCUREL',
      zip: '80891',
      isUrban: false,
    },
    {
      name: 'MUSETESTI',
      zip: '80917',
      isUrban: false,
    },
    {
      name: 'ARSENI',
      zip: '80926',
      isUrban: false,
    },
    {
      name: 'BARCACIU',
      zip: '80935',
      isUrban: false,
    },
    {
      name: 'GAMANI',
      zip: '80944',
      isUrban: false,
    },
    {
      name: 'GRUI',
      zip: '80953',
      isUrban: false,
    },
    {
      name: 'STANCESTI',
      zip: '80962',
      isUrban: false,
    },
    {
      name: 'STANCESTI LARGA',
      zip: '80971',
      isUrban: false,
    },
    {
      name: 'NEGOMIR',
      zip: '80999',
      isUrban: false,
    },
    {
      name: 'ARTANU',
      zip: '81004',
      isUrban: false,
    },
    {
      name: 'BOHOREL',
      zip: '81013',
      isUrban: false,
    },
    {
      name: 'CONDEIESTI',
      zip: '81022',
      isUrban: false,
    },
    {
      name: 'NUCETU',
      zip: '81031',
      isUrban: false,
    },
    {
      name: 'ORZU',
      zip: '81040',
      isUrban: false,
    },
    {
      name: 'PALTINU',
      zip: '81059',
      isUrban: false,
    },
    {
      name: 'RACI',
      zip: '81068',
      isUrban: false,
    },
    {
      name: 'URSOAIA',
      zip: '81077',
      isUrban: false,
    },
    {
      name: 'VALEA RACILOR',
      zip: '81086',
      isUrban: false,
    },
    {
      name: 'CALUGARENI',
      zip: '81102',
      isUrban: false,
    },
    {
      name: 'APA NEAGRA',
      zip: '81111',
      isUrban: false,
    },
    {
      name: 'CERNA SAT',
      zip: '81120',
      isUrban: false,
    },
    {
      name: 'CLOSANI',
      zip: '81139',
      isUrban: false,
    },
    {
      name: 'MOTRU SEC',
      zip: '81148',
      isUrban: false,
    },
    {
      name: 'ORZESTI',
      zip: '81157',
      isUrban: false,
    },
    {
      name: 'PADES',
      zip: '81166',
      isUrban: false,
    },
    {
      name: 'VAIENI',
      zip: '81175',
      isUrban: false,
    },
    {
      name: 'PESTISANI',
      zip: '81193',
      isUrban: false,
    },
    {
      name: 'BOROSTENI',
      zip: '81200',
      isUrban: false,
    },
    {
      name: 'BRADICENI',
      zip: '81219',
      isUrban: false,
    },
    {
      name: 'FRANCESTI',
      zip: '81228',
      isUrban: false,
    },
    {
      name: 'GURENI',
      zip: '81237',
      isUrban: false,
    },
    {
      name: 'HOBITA',
      zip: '81246',
      isUrban: false,
    },
    {
      name: 'SEUCA',
      zip: '81255',
      isUrban: false,
    },
    {
      name: 'PLOPSORU',
      zip: '81273',
      isUrban: false,
    },
    {
      name: 'BROSTENI',
      zip: '81282',
      isUrban: false,
    },
    {
      name: 'BROSTENII DE SUS',
      zip: '81291',
      isUrban: false,
    },
    {
      name: 'CEPLEA',
      zip: '81308',
      isUrban: false,
    },
    {
      name: 'CURSARU',
      zip: '81317',
      isUrban: false,
    },
    {
      name: 'DELENI',
      zip: '81326',
      isUrban: false,
    },
    {
      name: 'IZVOARELE',
      zip: '81335',
      isUrban: false,
    },
    {
      name: 'OLARI',
      zip: '81344',
      isUrban: false,
    },
    {
      name: 'PISCURI',
      zip: '81353',
      isUrban: false,
    },
    {
      name: 'SARDANESTI',
      zip: '81362',
      isUrban: false,
    },
    {
      name: 'VALENI',
      zip: '81371',
      isUrban: false,
    },
    {
      name: 'POLOVRAGI',
      zip: '81399',
      isUrban: false,
    },
    {
      name: 'RACOVITA',
      zip: '81406',
      isUrban: false,
    },
    {
      name: 'PRIGORIA',
      zip: '81424',
      isUrban: false,
    },
    {
      name: 'BUCSANA',
      zip: '81433',
      isUrban: false,
    },
    {
      name: 'BURLANI',
      zip: '81442',
      isUrban: false,
    },
    {
      name: 'CALUGAREASA',
      zip: '81451',
      isUrban: false,
    },
    {
      name: 'DOBRANA',
      zip: '81460',
      isUrban: false,
    },
    {
      name: 'NEGOIESTI',
      zip: '81479',
      isUrban: false,
    },
    {
      name: 'ZORLESTI',
      zip: '81488',
      isUrban: false,
    },
    {
      name: 'ROSIA DE AMARADIA',
      zip: '81503',
      isUrban: false,
    },
    {
      name: 'BECHENI',
      zip: '81512',
      isUrban: false,
    },
    {
      name: 'DEALU VIEI',
      zip: '81521',
      isUrban: false,
    },
    {
      name: 'RUGET',
      zip: '81530',
      isUrban: false,
    },
    {
      name: 'SECIURILE',
      zip: '81549',
      isUrban: false,
    },
    {
      name: 'SITOAIA',
      zip: '81567',
      isUrban: false,
    },
    {
      name: 'VALEA MICA',
      zip: '81736',
      isUrban: false,
    },
    {
      name: 'VALEA POIENII',
      zip: '81745',
      isUrban: false,
    },
    {
      name: 'SAULESTI',
      zip: '81825',
      isUrban: false,
    },
    {
      name: 'BIBESTI',
      zip: '81834',
      isUrban: false,
    },
    {
      name: 'DOLCESTI',
      zip: '81843',
      isUrban: false,
    },
    {
      name: 'PURCARU',
      zip: '81852',
      isUrban: false,
    },
    {
      name: 'SAMBOTIN',
      zip: '81996',
      isUrban: false,
    },
    {
      name: 'ARSURI',
      zip: '82001',
      isUrban: false,
    },
    {
      name: 'GORNACEL',
      zip: '82010',
      isUrban: false,
    },
    {
      name: 'PAJISTELE',
      zip: '82029',
      isUrban: false,
    },
    {
      name: 'SCHELA',
      zip: '82038',
      isUrban: false,
    },
    {
      name: 'SCOARTA',
      zip: '81870',
      isUrban: false,
    },
    {
      name: 'BOBU',
      zip: '81889',
      isUrban: false,
    },
    {
      name: 'BUDIENI',
      zip: '81898',
      isUrban: false,
    },
    {
      name: 'CAMPU MARE',
      zip: '81914',
      isUrban: false,
    },
    {
      name: 'CERATU DE COPACIOASA',
      zip: '81905',
      isUrban: false,
    },
    {
      name: 'COLIBASI',
      zip: '81923',
      isUrban: false,
    },
    {
      name: 'COPACIOASA',
      zip: '81932',
      isUrban: false,
    },
    {
      name: 'LAZURI',
      zip: '81941',
      isUrban: false,
    },
    {
      name: 'LINTEA',
      zip: '81950',
      isUrban: false,
    },
    {
      name: 'MOGOSANI',
      zip: '81969',
      isUrban: false,
    },
    {
      name: 'PISTESTII DIN DEAL',
      zip: '81978',
      isUrban: false,
    },
    {
      name: 'SLIVILESTI',
      zip: '82056',
      isUrban: false,
    },
    {
      name: 'COJMANESTI',
      zip: '82065',
      isUrban: false,
    },
    {
      name: 'MICULESTI',
      zip: '82074',
      isUrban: false,
    },
    {
      name: 'SIACU',
      zip: '82118',
      isUrban: false,
    },
    {
      name: 'STIUCANI',
      zip: '82083',
      isUrban: false,
    },
    {
      name: 'STRAMTU',
      zip: '82092',
      isUrban: false,
    },
    {
      name: 'SURA',
      zip: '82109',
      isUrban: false,
    },
    {
      name: 'TEHOMIR',
      zip: '82127',
      isUrban: false,
    },
    {
      name: 'STANESTI',
      zip: '82145',
      isUrban: false,
    },
    {
      name: 'ALEXENI',
      zip: '82154',
      isUrban: false,
    },
    {
      name: 'BALANI',
      zip: '82163',
      isUrban: false,
    },
    {
      name: 'CALESTI',
      zip: '82172',
      isUrban: false,
    },
    {
      name: 'CURPEN',
      zip: '82181',
      isUrban: false,
    },
    {
      name: 'MAZAROI',
      zip: '82190',
      isUrban: false,
    },
    {
      name: 'OBREJA',
      zip: '82207',
      isUrban: false,
    },
    {
      name: 'PARVULESTI',
      zip: '82216',
      isUrban: false,
    },
    {
      name: 'VAIDEI',
      zip: '82225',
      isUrban: false,
    },
    {
      name: 'VALARI',
      zip: '82234',
      isUrban: false,
    },
    {
      name: 'STEJARI',
      zip: '82252',
      isUrban: false,
    },
    {
      name: 'BACESTI',
      zip: '82270',
      isUrban: false,
    },
    {
      name: 'BALOSANI',
      zip: '82261',
      isUrban: false,
    },
    {
      name: 'DEALU LEULUI',
      zip: '82289',
      isUrban: false,
    },
    {
      name: 'PISCOIU',
      zip: '82298',
      isUrban: false,
    },
    {
      name: 'POPESTI STEJARI',
      zip: '82305',
      isUrban: false,
    },
    {
      name: 'STOINA',
      zip: '82323',
      isUrban: false,
    },
    {
      name: 'CIORARI',
      zip: '82332',
      isUrban: false,
    },
    {
      name: 'MIELUSEI',
      zip: '82341',
      isUrban: false,
    },
    {
      name: 'PAISANI',
      zip: '82350',
      isUrban: false,
    },
    {
      name: 'TOIAGA',
      zip: '82369',
      isUrban: false,
    },
    {
      name: 'ULMET',
      zip: '82378',
      isUrban: false,
    },
    {
      name: 'URDA DE SUS',
      zip: '82387',
      isUrban: false,
    },
    {
      name: 'TANTARENI',
      zip: '82699',
      isUrban: false,
    },
    {
      name: 'ARPADIA',
      zip: '82706',
      isUrban: false,
    },
    {
      name: 'CHICIORA',
      zip: '82715',
      isUrban: false,
    },
    {
      name: 'FLORESTI',
      zip: '82724',
      isUrban: false,
    },
    {
      name: 'TELESTI',
      zip: '82403',
      isUrban: false,
    },
    {
      name: 'BUDUHALA',
      zip: '82412',
      isUrban: false,
    },
    {
      name: 'SOMANESTI',
      zip: '82421',
      isUrban: false,
    },
    {
      name: 'TISMANA',
      zip: '82449',
      isUrban: true,
    },
    {
      name: 'CELEI',
      zip: '82458',
      isUrban: true,
    },
    {
      name: 'COSTENI',
      zip: '82467',
      isUrban: true,
    },
    {
      name: 'GORNOVITA',
      zip: '82476',
      isUrban: true,
    },
    {
      name: 'ISVARNA',
      zip: '82485',
      isUrban: true,
    },
    {
      name: 'POCRUIA',
      zip: '82494',
      isUrban: true,
    },
    {
      name: 'RACOTI',
      zip: '82500',
      isUrban: true,
    },
    {
      name: 'SOHODOL',
      zip: '82519',
      isUrban: true,
    },
    {
      name: 'TOPESTI',
      zip: '82528',
      isUrban: true,
    },
    {
      name: 'VALCELE',
      zip: '82537',
      isUrban: true,
    },
    {
      name: 'VANATA',
      zip: '82546',
      isUrban: true,
    },
    {
      name: 'TURBUREA',
      zip: '82564',
      isUrban: false,
    },
    {
      name: 'COCOROVA',
      zip: '82573',
      isUrban: false,
    },
    {
      name: 'POIANA',
      zip: '82582',
      isUrban: false,
    },
    {
      name: 'SIPOTU',
      zip: '82608',
      isUrban: false,
    },
    {
      name: 'SPAHII',
      zip: '82591',
      isUrban: false,
    },
    {
      name: 'TURCENI',
      zip: '82626',
      isUrban: true,
    },
    {
      name: 'GARBOVU',
      zip: '82635',
      isUrban: true,
    },
    {
      name: 'JILTU',
      zip: '82644',
      isUrban: true,
    },
    {
      name: 'MURGESTI',
      zip: '82653',
      isUrban: true,
    },
    {
      name: 'STRAMBA JIU',
      zip: '82662',
      isUrban: true,
    },
    {
      name: 'VALEA VIEI',
      zip: '82671',
      isUrban: true,
    },
    {
      name: 'TURCINESTI',
      zip: '78098',
      isUrban: false,
    },
    {
      name: 'CARTIU',
      zip: '78105',
      isUrban: false,
    },
    {
      name: 'HOREZU',
      zip: '78114',
      isUrban: false,
    },
    {
      name: 'RUGI',
      zip: '78123',
      isUrban: false,
    },
    {
      name: 'URDARI',
      zip: '82742',
      isUrban: false,
    },
    {
      name: 'FANTANELE',
      zip: '82751',
      isUrban: false,
    },
    {
      name: 'HOTAROASA',
      zip: '82760',
      isUrban: false,
    },
    {
      name: 'VAGIULESTI',
      zip: '82788',
      isUrban: false,
    },
    {
      name: 'CARCIU',
      zip: '82797',
      isUrban: false,
    },
    {
      name: 'COVRIGI',
      zip: '82804',
      isUrban: false,
    },
    {
      name: 'MURGILESTI',
      zip: '82813',
      isUrban: false,
    },
    {
      name: 'VALEA MOTRULUI',
      zip: '82822',
      isUrban: false,
    },
    {
      name: 'ANDREESTI',
      zip: '82840',
      isUrban: false,
    },
    {
      name: 'FRASIN',
      zip: '82859',
      isUrban: false,
    },
    {
      name: 'VALEA DESULUI',
      zip: '82868',
      isUrban: false,
    },
    {
      name: 'VLADIMIR',
      zip: '82877',
      isUrban: false,
    },
    {
      name: 'STROIESTI',
      zip: '78702',
      isUrban: false,
    },
    {
      name: 'BAIA DE FIER',
      zip: '78720',
      isUrban: false,
    },
    {
      name: 'CERNADIA',
      zip: '78739',
      isUrban: false,
    },
    {
      name: 'VOITESTII DE VALE',
      zip: '78757',
      isUrban: false,
    },
    {
      name: 'BALANESTI',
      zip: '78766',
      isUrban: false,
    },
    {
      name: 'BLIDARI',
      zip: '78775',
      isUrban: false,
    },
    {
      name: 'CANEPESTI',
      zip: '78784',
      isUrban: false,
    },
    {
      name: 'GLODENI',
      zip: '78793',
      isUrban: false,
    },
    {
      name: 'OHABA',
      zip: '78800',
      isUrban: false,
    },
    {
      name: 'VOITESTII DIN DEAL',
      zip: '78819',
      isUrban: false,
    },
    {
      name: 'BALESTI',
      zip: '77929',
      isUrban: false,
    },
    {
      name: 'CEAURU',
      zip: '77938',
      isUrban: false,
    },
    {
      name: 'CORNESTI',
      zip: '77947',
      isUrban: false,
    },
    {
      name: 'GAVANESTI',
      zip: '77956',
      isUrban: false,
    },
    {
      name: 'STEJARU',
      zip: '81558',
      isUrban: false,
    },
    {
      name: 'RUNCU',
      zip: '81585',
      isUrban: false,
    },
    {
      name: 'BALTA',
      zip: '81594',
      isUrban: false,
    },
    {
      name: 'BALTISOARA',
      zip: '81601',
      isUrban: false,
    },
    {
      name: 'DOBRITA',
      zip: '81610',
      isUrban: false,
    },
    {
      name: 'RACHITI',
      zip: '81629',
      isUrban: false,
    },
    {
      name: 'SUSENI',
      zip: '81638',
      isUrban: false,
    },
    {
      name: 'VALEA MARE',
      zip: '81647',
      isUrban: false,
    },
    {
      name: 'SACELU',
      zip: '81763',
      isUrban: false,
    },
    {
      name: 'BLAHNITA DE SUS',
      zip: '81772',
      isUrban: false,
    },
    {
      name: 'HAIESTI',
      zip: '81781',
      isUrban: false,
    },
    {
      name: 'JERISTEA',
      zip: '81790',
      isUrban: false,
    },
    {
      name: 'MAGHERESTI',
      zip: '81807',
      isUrban: false,
    },
    {
      name: 'SAMARINESTI',
      zip: '81665',
      isUrban: false,
    },
    {
      name: 'BAZAVANI',
      zip: '81674',
      isUrban: false,
    },
    {
      name: 'BOCA',
      zip: '81683',
      isUrban: false,
    },
    {
      name: 'DUCULESTI',
      zip: '81692',
      isUrban: false,
    },
    {
      name: 'LARGA',
      zip: '81709',
      isUrban: false,
    },
    {
      name: 'TIRIOI',
      zip: '81718',
      isUrban: false,
    },
    {
      name: 'VALEA BISERICII',
      zip: '81727',
      isUrban: false,
    }
  ],
  HR: [
    {
      name: 'MIERCUREA CIUC',
      zip: '83339',
      isUrban: true,
    },
    {
      name: 'CIBA',
      zip: '83348',
      isUrban: true,
    },
    {
      name: 'HARGHITA-BAI',
      zip: '83357',
      isUrban: true,
    },
    {
      name: 'JIGODIN-BAI',
      zip: '83366',
      isUrban: true,
    },
    {
      name: 'ODORHEIU SECUIESC',
      zip: '83142',
      isUrban: true,
    },
    {
      name: 'BAILE TUSNAD',
      zip: '83437',
      isUrban: true,
    },
    {
      name: 'CARPITUS',
      zip: '83446',
      isUrban: true,
    },
    {
      name: 'BALAN',
      zip: '83473',
      isUrban: true,
    },
    {
      name: 'BORSEC',
      zip: '83507',
      isUrban: true,
    },
    {
      name: 'CRISTURU SECUIESC',
      zip: '83534',
      isUrban: true,
    },
    {
      name: 'FILIAS',
      zip: '83543',
      isUrban: true,
    },
    {
      name: 'GHEORGHENI',
      zip: '83570',
      isUrban: true,
    },
    {
      name: 'COVACIPETER',
      zip: '83589',
      isUrban: true,
    },
    {
      name: 'LACU ROSU',
      zip: '83598',
      isUrban: true,
    },
    {
      name: 'VARGATAC',
      zip: '83605',
      isUrban: true,
    },
    {
      name: 'VISAFOLIO',
      zip: '83614',
      isUrban: true,
    },
    {
      name: 'TOPLITA',
      zip: '83641',
      isUrban: true,
    },
    {
      name: 'CALIMANEL',
      zip: '83650',
      isUrban: true,
    },
    {
      name: 'LUNCANI',
      zip: '83669',
      isUrban: true,
    },
    {
      name: 'MAGHERUS',
      zip: '83678',
      isUrban: true,
    },
    {
      name: 'MOGLANESTI',
      zip: '83687',
      isUrban: true,
    },
    {
      name: 'SECU',
      zip: '83696',
      isUrban: true,
    },
    {
      name: 'VAGANI',
      zip: '83712',
      isUrban: true,
    },
    {
      name: 'VALE',
      zip: '83703',
      isUrban: true,
    },
    {
      name: 'ZENCANI',
      zip: '83721',
      isUrban: true,
    },
    {
      name: 'VLAHITA',
      zip: '83758',
      isUrban: true,
    },
    {
      name: 'BAILE HOMOROD',
      zip: '83767',
      isUrban: true,
    },
    {
      name: 'MINELE LUETA',
      zip: '83776',
      isUrban: true,
    },
    {
      name: 'ATID',
      zip: '83794',
      isUrban: false,
    },
    {
      name: 'CRISENI',
      zip: '83801',
      isUrban: false,
    },
    {
      name: 'CUSMED',
      zip: '83810',
      isUrban: false,
    },
    {
      name: 'INLACENI',
      zip: '83829',
      isUrban: false,
    },
    {
      name: 'SICLOD',
      zip: '83838',
      isUrban: false,
    },
    {
      name: 'AVRAMESTI',
      zip: '83856',
      isUrban: false,
    },
    {
      name: 'ANDREENI',
      zip: '83865',
      isUrban: false,
    },
    {
      name: 'CECHESTI',
      zip: '83874',
      isUrban: false,
    },
    {
      name: 'FIRTANUS',
      zip: '83892',
      isUrban: false,
    },
    {
      name: 'GOAGIU',
      zip: '83883',
      isUrban: false,
    },
    {
      name: 'LAZ-FIRTANUS',
      zip: '83909',
      isUrban: false,
    },
    {
      name: 'LAZ-SOIMUS',
      zip: '83918',
      isUrban: false,
    },
    {
      name: 'MEDISORU MIC',
      zip: '83927',
      isUrban: false,
    },
    {
      name: 'BILBOR',
      zip: '83945',
      isUrban: false,
    },
    {
      name: 'RACHITIS',
      zip: '83954',
      isUrban: false,
    },
    {
      name: 'BRADESTI',
      zip: '83160',
      isUrban: false,
    },
    {
      name: 'SATU MARE',
      zip: '83188',
      isUrban: false,
    },
    {
      name: 'TARNOVITA',
      zip: '83179',
      isUrban: false,
    },
    {
      name: 'CAPALNITA',
      zip: '83972',
      isUrban: false,
    },
    {
      name: 'CARTA',
      zip: '84111',
      isUrban: false,
    },
    {
      name: 'INEU',
      zip: '84120',
      isUrban: false,
    },
    {
      name: 'TOMESTI',
      zip: '84139',
      isUrban: false,
    },
    {
      name: 'CIUCSANGEORGIU',
      zip: '83990',
      isUrban: false,
    },
    {
      name: 'ARMASENI',
      zip: '84004',
      isUrban: false,
    },
    {
      name: 'ARMASENII NOI',
      zip: '84013',
      isUrban: false,
    },
    {
      name: 'BANCU',
      zip: '84022',
      isUrban: false,
    },
    {
      name: 'CIOBANIS',
      zip: '84031',
      isUrban: false,
    },
    {
      name: 'COTORMANI',
      zip: '84040',
      isUrban: false,
    },
    {
      name: 'EGHERSEC',
      zip: '84059',
      isUrban: false,
    },
    {
      name: 'GHIURCHE',
      zip: '84068',
      isUrban: false,
    },
    {
      name: 'POTIOND',
      zip: '84077',
      isUrban: false,
    },
    {
      name: 'CIUMANI',
      zip: '84095',
      isUrban: false,
    },
    {
      name: 'CORBU',
      zip: '84157',
      isUrban: false,
    },
    {
      name: 'CAPU CORBULUI',
      zip: '84166',
      isUrban: false,
    },
    {
      name: 'CORUND',
      zip: '84184',
      isUrban: false,
    },
    {
      name: 'ATIA',
      zip: '84193',
      isUrban: false,
    },
    {
      name: 'CALONDA',
      zip: '84200',
      isUrban: false,
    },
    {
      name: 'FANTANA BRAZILOR',
      zip: '84219',
      isUrban: false,
    },
    {
      name: 'VALEA LUI PAVEL',
      zip: '84228',
      isUrban: false,
    },
    {
      name: 'DANESTI',
      zip: '84246',
      isUrban: false,
    },
    {
      name: 'MADARAS',
      zip: '84255',
      isUrban: false,
    },
    {
      name: 'DARJIU',
      zip: '84399',
      isUrban: false,
    },
    {
      name: 'MUJNA',
      zip: '84406',
      isUrban: false,
    },
    {
      name: 'DEALU',
      zip: '84273',
      isUrban: false,
    },
    {
      name: 'FANCEL',
      zip: '84282',
      isUrban: false,
    },
    {
      name: 'SANCRAI',
      zip: '84291',
      isUrban: false,
    },
    {
      name: 'TAMASU',
      zip: '84308',
      isUrban: false,
    },
    {
      name: 'TIBOD',
      zip: '84317',
      isUrban: false,
    },
    {
      name: 'ULCANI',
      zip: '84326',
      isUrban: false,
    },
    {
      name: 'VALEA ROTUNDA',
      zip: '84335',
      isUrban: false,
    },
    {
      name: 'DITRAU',
      zip: '84353',
      isUrban: false,
    },
    {
      name: 'JOLOTCA',
      zip: '84362',
      isUrban: false,
    },
    {
      name: 'TENGHELER',
      zip: '84371',
      isUrban: false,
    },
    {
      name: 'FELICENI',
      zip: '83204',
      isUrban: false,
    },
    {
      name: 'ALEXANDRITA',
      zip: '83213',
      isUrban: false,
    },
    {
      name: 'ARVATENI',
      zip: '83222',
      isUrban: false,
    },
    {
      name: 'CIRESENI',
      zip: '83231',
      isUrban: false,
    },
    {
      name: 'FORTENI',
      zip: '83240',
      isUrban: false,
    },
    {
      name: 'HOGHIA',
      zip: '83259',
      isUrban: false,
    },
    {
      name: 'OTENI',
      zip: '83268',
      isUrban: false,
    },
    {
      name: 'POLONITA',
      zip: '83277',
      isUrban: false,
    },
    {
      name: 'TAURENI',
      zip: '83286',
      isUrban: false,
    },
    {
      name: 'TELEAC',
      zip: '83295',
      isUrban: false,
    },
    {
      name: 'VALENI',
      zip: '83302',
      isUrban: false,
    },
    {
      name: 'FRUMOASA',
      zip: '84424',
      isUrban: false,
    },
    {
      name: 'BARZAVA',
      zip: '84433',
      isUrban: false,
    },
    {
      name: 'FAGETEL',
      zip: '84442',
      isUrban: false,
    },
    {
      name: 'NICOLESTI',
      zip: '84451',
      isUrban: false,
    },
    {
      name: 'GALAUTAS',
      zip: '84479',
      isUrban: false,
    },
    {
      name: 'DEALU ARMANULUI',
      zip: '84488',
      isUrban: false,
    },
    {
      name: 'GALAUTAS-PIRAU',
      zip: '84497',
      isUrban: false,
    },
    {
      name: 'NUTENI',
      zip: '84503',
      isUrban: false,
    },
    {
      name: 'PLOPIS',
      zip: '84512',
      isUrban: false,
    },
    {
      name: 'PRELUCA',
      zip: '84521',
      isUrban: false,
    },
    {
      name: 'TOLESENI',
      zip: '84530',
      isUrban: false,
    },
    {
      name: 'ZAPODEA',
      zip: '84549',
      isUrban: false,
    },
    {
      name: 'JOSENI',
      zip: '84567',
      isUrban: false,
    },
    {
      name: 'BORZONT',
      zip: '84576',
      isUrban: false,
    },
    {
      name: 'BUCIN',
      zip: '84585',
      isUrban: false,
    },
    {
      name: 'LAZAREA',
      zip: '84601',
      isUrban: false,
    },
    {
      name: 'GHIDUT',
      zip: '84610',
      isUrban: false,
    },
    {
      name: 'LUETA',
      zip: '84638',
      isUrban: false,
    },
    {
      name: 'BAILE CHIRUI',
      zip: '84647',
      isUrban: false,
    },
    {
      name: 'LUNCA DE JOS',
      zip: '84665',
      isUrban: false,
    },
    {
      name: 'BARATCOS',
      zip: '84674',
      isUrban: false,
    },
    {
      name: 'POIANA FAGULUI',
      zip: '84683',
      isUrban: false,
    },
    {
      name: 'PUNTEA LUPULUI',
      zip: '84692',
      isUrban: false,
    },
    {
      name: 'VALEA BOROS',
      zip: '84718',
      isUrban: false,
    },
    {
      name: 'VALEA CAPELEI',
      zip: '84727',
      isUrban: false,
    },
    {
      name: 'VALEA INTUNECOASA',
      zip: '84736',
      isUrban: false,
    },
    {
      name: 'VALEA LUI ANTALOC',
      zip: '84709',
      isUrban: false,
    },
    {
      name: 'VALEA RECE',
      zip: '84745',
      isUrban: false,
    },
    {
      name: 'LUNCA DE SUS',
      zip: '84763',
      isUrban: false,
    },
    {
      name: 'COMIAT',
      zip: '84772',
      isUrban: false,
    },
    {
      name: 'IZVORUL TROTUSULUI',
      zip: '84781',
      isUrban: false,
    },
    {
      name: 'PALTINIS-CIUC',
      zip: '84790',
      isUrban: false,
    },
    {
      name: 'VALEA GARBEA',
      zip: '84807',
      isUrban: false,
    },
    {
      name: 'VALEA UGRA',
      zip: '84816',
      isUrban: false,
    },
    {
      name: 'LUPENI',
      zip: '84834',
      isUrban: false,
    },
    {
      name: 'BISERICANI',
      zip: '84843',
      isUrban: false,
    },
    {
      name: 'BULGARENI',
      zip: '84852',
      isUrban: false,
    },
    {
      name: 'FIRTUSU',
      zip: '84861',
      isUrban: false,
    },
    {
      name: 'MORARENI',
      zip: '84870',
      isUrban: false,
    },
    {
      name: 'PALTINIS',
      zip: '84889',
      isUrban: false,
    },
    {
      name: 'PAULENI',
      zip: '84898',
      isUrban: false,
    },
    {
      name: 'SANCEL',
      zip: '84914',
      isUrban: false,
    },
    {
      name: 'SATU MIC',
      zip: '84905',
      isUrban: false,
    },
    {
      name: 'MARTINIS',
      zip: '84932',
      isUrban: false,
    },
    {
      name: 'ALDEA',
      zip: '84941',
      isUrban: false,
    },
    {
      name: 'BADENI',
      zip: '84950',
      isUrban: false,
    },
    {
      name: 'TAIETURA',
      zip: '85234',
      isUrban: false,
    },
    {
      name: 'OCLAND',
      zip: '85252',
      isUrban: false,
    },
    {
      name: 'CRACIUNEL',
      zip: '85261',
      isUrban: false,
    },
    {
      name: 'SATU NOU',
      zip: '85270',
      isUrban: false,
    },
    {
      name: 'PAULENI-CIUC',
      zip: '83384',
      isUrban: false,
    },
    {
      name: 'DELNITA',
      zip: '83393',
      isUrban: false,
    },
    {
      name: 'SOIMENI',
      zip: '83400',
      isUrban: false,
    },
    {
      name: 'PLAIESII DE JOS',
      zip: '85298',
      isUrban: false,
    },
    {
      name: 'CASINU NOU',
      zip: '85305',
      isUrban: false,
    },
    {
      name: 'IACOBENI',
      zip: '85314',
      isUrban: false,
    },
    {
      name: 'IMPER',
      zip: '85323',
      isUrban: false,
    },
    {
      name: 'PLAIESII DE SUS',
      zip: '85332',
      isUrban: false,
    },
    {
      name: 'PRAID',
      zip: '85350',
      isUrban: false,
    },
    {
      name: 'BECAS',
      zip: '85369',
      isUrban: false,
    },
    {
      name: 'BUCIN',
      zip: '85378',
      isUrban: false,
    },
    {
      name: 'OCNA DE JOS',
      zip: '85387',
      isUrban: false,
    },
    {
      name: 'OCNA DE SUS',
      zip: '85396',
      isUrban: false,
    },
    {
      name: 'SASVERES',
      zip: '85403',
      isUrban: false,
    },
    {
      name: 'REMETEA',
      zip: '85421',
      isUrban: false,
    },
    {
      name: 'FAGETEL',
      zip: '85430',
      isUrban: false,
    },
    {
      name: 'MARTONCA',
      zip: '85449',
      isUrban: false,
    },
    {
      name: 'SINEU',
      zip: '85458',
      isUrban: false,
    },
    {
      name: 'SACEL',
      zip: '85476',
      isUrban: false,
    },
    {
      name: 'SOIMUSU MARE',
      zip: '85485',
      isUrban: false,
    },
    {
      name: 'SOIMUSU MIC',
      zip: '85494',
      isUrban: false,
    },
    {
      name: 'UILAC',
      zip: '85500',
      isUrban: false,
    },
    {
      name: 'VIDACUT',
      zip: '85519',
      isUrban: false,
    },
    {
      name: 'SANCRAIENI',
      zip: '85699',
      isUrban: false,
    },
    {
      name: 'FITOD',
      zip: '85706',
      isUrban: false,
    },
    {
      name: 'HOSASAU',
      zip: '85715',
      isUrban: false,
    },
    {
      name: 'LELICENI',
      zip: '85724',
      isUrban: false,
    },
    {
      name: 'MISENTEA',
      zip: '85733',
      isUrban: false,
    },
    {
      name: 'SANTIMBRU',
      zip: '85742',
      isUrban: false,
    },
    {
      name: 'SANTIMBRU-BAI',
      zip: '85751',
      isUrban: false,
    },
    {
      name: 'SANDOMINIC',
      zip: '85779',
      isUrban: false,
    },
    {
      name: 'SANMARTIN',
      zip: '85797',
      isUrban: false,
    },
    {
      name: 'CIUCANI',
      zip: '85804',
      isUrban: false,
    },
    {
      name: 'COZMENI',
      zip: '85813',
      isUrban: false,
    },
    {
      name: 'LAZARESTI',
      zip: '85822',
      isUrban: false,
    },
    {
      name: 'VALEA UZULUI',
      zip: '85831',
      isUrban: false,
    },
    {
      name: 'SANSIMION',
      zip: '85859',
      isUrban: false,
    },
    {
      name: 'CETATUIA',
      zip: '85868',
      isUrban: false,
    },
    {
      name: 'SARMAS',
      zip: '85537',
      isUrban: false,
    },
    {
      name: 'FUNDOAIA',
      zip: '85546',
      isUrban: false,
    },
    {
      name: 'HODOSA',
      zip: '85555',
      isUrban: false,
    },
    {
      name: 'PLATONESTI',
      zip: '85564',
      isUrban: false,
    },
    {
      name: 'RUNC',
      zip: '85573',
      isUrban: false,
    },
    {
      name: 'SECUIENI',
      zip: '85591',
      isUrban: false,
    },
    {
      name: 'BODOGAIA',
      zip: '85608',
      isUrban: false,
    },
    {
      name: 'ELISENI',
      zip: '85617',
      isUrban: false,
    },
    {
      name: 'SICULENI',
      zip: '85635',
      isUrban: false,
    },
    {
      name: 'CIARACIO',
      zip: '85644',
      isUrban: false,
    },
    {
      name: 'CICEU',
      zip: '85653',
      isUrban: false,
    },
    {
      name: 'RACU',
      zip: '85662',
      isUrban: false,
    },
    {
      name: 'SATU NOU',
      zip: '85671',
      isUrban: false,
    },
    {
      name: 'SIMONESTI',
      zip: '85993',
      isUrban: false,
    },
    {
      name: 'BENTID',
      zip: '86008',
      isUrban: false,
    },
    {
      name: 'CADACIU MARE',
      zip: '86017',
      isUrban: false,
    },
    {
      name: 'CADACIU MIC',
      zip: '86026',
      isUrban: false,
    },
    {
      name: 'CEHETEL',
      zip: '86035',
      isUrban: false,
    },
    {
      name: 'CHEDIA MARE',
      zip: '86044',
      isUrban: false,
    },
    {
      name: 'CHEDIA MICA',
      zip: '86053',
      isUrban: false,
    },
    {
      name: 'COBATESTI',
      zip: '86062',
      isUrban: false,
    },
    {
      name: 'MEDISORU MARE',
      zip: '86071',
      isUrban: false,
    },
    {
      name: 'MIHAILENI',
      zip: '86080',
      isUrban: false,
    },
    {
      name: 'NICOLENI',
      zip: '86099',
      isUrban: false,
    },
    {
      name: 'RUGANESTI',
      zip: '86106',
      isUrban: false,
    },
    {
      name: 'TARCESTI',
      zip: '86115',
      isUrban: false,
    },
    {
      name: 'TURDENI',
      zip: '86124',
      isUrban: false,
    },
    {
      name: 'SUBCETATE',
      zip: '85886',
      isUrban: false,
    },
    {
      name: 'CALNACI',
      zip: '85895',
      isUrban: false,
    },
    {
      name: 'DUDA',
      zip: '85902',
      isUrban: false,
    },
    {
      name: 'FILPEA',
      zip: '85911',
      isUrban: false,
    },
    {
      name: 'SUSENI',
      zip: '85939',
      isUrban: false,
    },
    {
      name: 'CHILENI',
      zip: '85948',
      isUrban: false,
    },
    {
      name: 'LIBAN',
      zip: '85957',
      isUrban: false,
    },
    {
      name: 'SENETEA',
      zip: '85966',
      isUrban: false,
    },
    {
      name: 'VALEA STRAMBA',
      zip: '85975',
      isUrban: false,
    },
    {
      name: 'TULGHES',
      zip: '86142',
      isUrban: false,
    },
    {
      name: 'HAGOTA',
      zip: '86151',
      isUrban: false,
    },
    {
      name: 'PINTIC',
      zip: '86160',
      isUrban: false,
    },
    {
      name: 'RECEA',
      zip: '86179',
      isUrban: false,
    },
    {
      name: 'TUSNAD',
      zip: '86197',
      isUrban: false,
    },
    {
      name: 'TUSNADU NOU',
      zip: '86204',
      isUrban: false,
    },
    {
      name: 'VRABIA',
      zip: '86213',
      isUrban: false,
    },
    {
      name: 'ULIES',
      zip: '86231',
      isUrban: false,
    },
    {
      name: 'DAIA',
      zip: '86240',
      isUrban: false,
    },
    {
      name: 'IASU',
      zip: '86259',
      isUrban: false,
    },
    {
      name: 'IGHIU',
      zip: '86268',
      isUrban: false,
    },
    {
      name: 'NICOLESTI',
      zip: '86277',
      isUrban: false,
    },
    {
      name: 'OBRANESTI',
      zip: '86286',
      isUrban: false,
    },
    {
      name: 'PETECU',
      zip: '86295',
      isUrban: false,
    },
    {
      name: 'VASILENI',
      zip: '86302',
      isUrban: false,
    },
    {
      name: 'VARSAG',
      zip: '86320',
      isUrban: false,
    },
    {
      name: 'VOSLABENI',
      zip: '86348',
      isUrban: false,
    },
    {
      name: 'IZVORU MURESULUI',
      zip: '86357',
      isUrban: false,
    },
    {
      name: 'ZETEA',
      zip: '86375',
      isUrban: false,
    },
    {
      name: 'DESAG',
      zip: '86384',
      isUrban: false,
    },
    {
      name: 'IZVOARE',
      zip: '86393',
      isUrban: false,
    },
    {
      name: 'POIANA TARNAVEI',
      zip: '86400',
      isUrban: false,
    },
    {
      name: 'SICASAU',
      zip: '86419',
      isUrban: false,
    },
    {
      name: 'SUB CETATE',
      zip: '86428',
      isUrban: false,
    },
    {
      name: 'CALUGARENI',
      zip: '84969',
      isUrban: false,
    },
    {
      name: 'CHINUSU',
      zip: '84978',
      isUrban: false,
    },
    {
      name: 'COMANESTI',
      zip: '84987',
      isUrban: false,
    },
    {
      name: 'GHIPES',
      zip: '84996',
      isUrban: false,
    },
    {
      name: 'LOCODENI',
      zip: '85001',
      isUrban: false,
    },
    {
      name: 'ORASENI',
      zip: '85010',
      isUrban: false,
    },
    {
      name: 'PETRENI',
      zip: '85029',
      isUrban: false,
    },
    {
      name: 'RARES',
      zip: '85038',
      isUrban: false,
    },
    {
      name: 'SANPAUL',
      zip: '85047',
      isUrban: false,
    },
    {
      name: 'MERESTI',
      zip: '85065',
      isUrban: false,
    },
    {
      name: 'MIHAILENI',
      zip: '85083',
      isUrban: false,
    },
    {
      name: 'LIVEZI',
      zip: '85092',
      isUrban: false,
    },
    {
      name: 'NADEJDEA',
      zip: '85109',
      isUrban: false,
    },
    {
      name: 'VACARESTI',
      zip: '85118',
      isUrban: false,
    },
    {
      name: 'MUGENI',
      zip: '85136',
      isUrban: false,
    },
    {
      name: 'ALUNIS',
      zip: '85145',
      isUrban: false,
    },
    {
      name: 'BETA',
      zip: '85154',
      isUrban: false,
    },
    {
      name: 'BETESTI',
      zip: '85163',
      isUrban: true,
    },
    {
      name: 'DEJUTIU',
      zip: '85172',
      isUrban: false,
    },
    {
      name: 'DOBENI',
      zip: '85181',
      isUrban: false,
    },
    {
      name: 'LUTITA',
      zip: '85190',
      isUrban: false,
    },
    {
      name: 'MATISENI',
      zip: '85207',
      isUrban: false,
    },
    {
      name: 'PORUMBENII MARI',
      zip: '85216',
      isUrban: false,
    },
    {
      name: 'PORUMBENII MICI',
      zip: '85225',
      isUrban: false,
    }
  ],
  HD: [
    {
      name: 'DEVA',
      zip: '86696',
      isUrban: true,
    },
    {
      name: 'ARCHIA',
      zip: '86712',
      isUrban: true,
    },
    {
      name: 'BARCEA MICA',
      zip: '86721',
      isUrban: true,
    },
    {
      name: 'CRISTUR',
      zip: '86730',
      isUrban: true,
    },
    {
      name: 'SANTUHALM',
      zip: '86703',
      isUrban: true,
    },
    {
      name: 'BRAD',
      zip: '87308',
      isUrban: true,
    },
    {
      name: 'MESTEACAN',
      zip: '87317',
      isUrban: true,
    },
    {
      name: 'POTINGANI',
      zip: '87326',
      isUrban: true,
    },
    {
      name: 'RUDA-BRAD',
      zip: '87335',
      isUrban: true,
    },
    {
      name: 'TARATEL',
      zip: '87344',
      isUrban: true,
    },
    {
      name: 'VALEA BRADULUI',
      zip: '87353',
      isUrban: true,
    },
    {
      name: 'HUNEDOARA',
      zip: '86829',
      isUrban: true,
    },
    {
      name: 'BOS',
      zip: '86847',
      isUrban: true,
    },
    {
      name: 'GROS',
      zip: '86856',
      isUrban: true,
    },
    {
      name: 'HASDAT',
      zip: '86865',
      isUrban: true,
    },
    {
      name: 'PESTISU MARE',
      zip: '86874',
      isUrban: true,
    },
    {
      name: 'RACASTIA',
      zip: '86838',
      isUrban: true,
    },
    {
      name: 'ORASTIE',
      zip: '87647',
      isUrban: true,
    },
    {
      name: 'PETROSANI',
      zip: '87004',
      isUrban: true,
    },
    {
      name: 'DALJA MARE',
      zip: '87013',
      isUrban: true,
    },
    {
      name: 'DALJA MICA',
      zip: '87022',
      isUrban: true,
    },
    {
      name: 'PESTERA',
      zip: '87031',
      isUrban: true,
    },
    {
      name: 'SLATINIOARA',
      zip: '87040',
      isUrban: true,
    },
    {
      name: 'ANINOASA',
      zip: '87228',
      isUrban: true,
    },
    {
      name: 'ISCRONI',
      zip: '87237',
      isUrban: true,
    },
    {
      name: 'CALANU MIC',
      zip: '87460',
      isUrban: true,
    },
    {
      name: 'GRID',
      zip: '87479',
      isUrban: true,
    },
    {
      name: 'NADASTIA DE JOS',
      zip: '87488',
      isUrban: true,
    },
    {
      name: 'NADASTIA DE SUS',
      zip: '87497',
      isUrban: true,
    },
    {
      name: 'OHABA STRAIULUI',
      zip: '87503',
      isUrban: true,
    },
    {
      name: 'SANCRAI',
      zip: '87512',
      isUrban: true,
    },
    {
      name: 'SANTAMARIA DE PIATRA',
      zip: '87521',
      isUrban: true,
    },
    {
      name: 'STREI',
      zip: '87530',
      isUrban: true,
    },
    {
      name: 'STREI-SACEL',
      zip: '87549',
      isUrban: true,
    },
    {
      name: 'STREISANGEORGIU',
      zip: '87442',
      isUrban: true,
    },
    {
      name: 'VALEA SANGEORGIULUI',
      zip: '87558',
      isUrban: true,
    },
    {
      name: 'HATEG',
      zip: '87585',
      isUrban: true,
    },
    {
      name: 'NALATVAD',
      zip: '87594',
      isUrban: true,
    },
    {
      name: 'SILVASU DE JOS',
      zip: '87601',
      isUrban: true,
    },
    {
      name: 'SILVASU DE SUS',
      zip: '87610',
      isUrban: true,
    },
    {
      name: 'LUPENI',
      zip: '87068',
      isUrban: true,
    },
    {
      name: 'PETRILA',
      zip: '87086',
      isUrban: true,
    },
    {
      name: 'CAMPA',
      zip: '87095',
      isUrban: true,
    },
    {
      name: 'JIET',
      zip: '87102',
      isUrban: true,
    },
    {
      name: 'RASCOALA',
      zip: '87111',
      isUrban: true,
    },
    {
      name: 'TIRICI',
      zip: '87120',
      isUrban: true,
    },
    {
      name: 'SIMERIA',
      zip: '87674',
      isUrban: true,
    },
    {
      name: 'BIRCEA MARE',
      zip: '87683',
      isUrban: true,
    },
    {
      name: 'CARPINIS',
      zip: '87692',
      isUrban: true,
    },
    {
      name: 'SANTANDREI',
      zip: '87718',
      isUrban: true,
    },
    {
      name: 'SAULESTI',
      zip: '87727',
      isUrban: true,
    },
    {
      name: 'SIMERIA VECHE',
      zip: '87709',
      isUrban: true,
    },
    {
      name: 'UROI',
      zip: '87736',
      isUrban: true,
    },
    {
      name: 'URICANI',
      zip: '87148',
      isUrban: true,
    },
    {
      name: 'CAMPU LUI NEAG',
      zip: '87157',
      isUrban: true,
    },
    {
      name: 'VALEA DE BRAZI',
      zip: '87166',
      isUrban: true,
    },
    {
      name: 'VULCAN',
      zip: '87184',
      isUrban: true,
    },
    {
      name: 'DEALU BABII',
      zip: '87193',
      isUrban: true,
    },
    {
      name: 'JIU-PAROSENI',
      zip: '87200',
      isUrban: true,
    },
    {
      name: 'BACIA',
      zip: '88056',
      isUrban: false,
    },
    {
      name: 'PETRENI',
      zip: '88065',
      isUrban: false,
    },
    {
      name: 'TAMPA',
      zip: '88074',
      isUrban: false,
    },
    {
      name: 'TOTIA',
      zip: '88083',
      isUrban: false,
    },
    {
      name: 'BAIA DE CRIS',
      zip: '87754',
      isUrban: false,
    },
    {
      name: 'BALDOVIN',
      zip: '87763',
      isUrban: false,
    },
    {
      name: 'CARACI',
      zip: '87772',
      isUrban: false,
    },
    {
      name: 'CARASTAU',
      zip: '87781',
      isUrban: false,
    },
    {
      name: 'LUNCA',
      zip: '87790',
      isUrban: false,
    },
    {
      name: 'RISCA',
      zip: '87807',
      isUrban: false,
    },
    {
      name: 'RISCULITA',
      zip: '87816',
      isUrban: false,
    },
    {
      name: 'TEBEA',
      zip: '87825',
      isUrban: false,
    },
    {
      name: 'VALENI',
      zip: '87834',
      isUrban: false,
    },
    {
      name: 'BAITA',
      zip: '88109',
      isUrban: false,
    },
    {
      name: 'BARBURA',
      zip: '88118',
      isUrban: false,
    },
    {
      name: 'CAINELU DE SUS',
      zip: '88127',
      isUrban: false,
    },
    {
      name: 'CRACIUNESTI',
      zip: '88136',
      isUrban: false,
    },
    {
      name: 'FIZES',
      zip: '88145',
      isUrban: false,
    },
    {
      name: 'HARTAGANI',
      zip: '88154',
      isUrban: false,
    },
    {
      name: 'LUNCA',
      zip: '88163',
      isUrban: false,
    },
    {
      name: 'ORMINDEA',
      zip: '88172',
      isUrban: false,
    },
    {
      name: 'PESTERA',
      zip: '88181',
      isUrban: false,
    },
    {
      name: 'SALISTE',
      zip: '88190',
      isUrban: false,
    },
    {
      name: 'TRESTIA',
      zip: '88207',
      isUrban: false,
    },
    {
      name: 'BALSA',
      zip: '87852',
      isUrban: false,
    },
    {
      name: 'ALMASU MIC DE MUNTE',
      zip: '87861',
      isUrban: false,
    },
    {
      name: 'ARDEU',
      zip: '87870',
      isUrban: false,
    },
    {
      name: 'BUNESTI',
      zip: '87889',
      isUrban: false,
    },
    {
      name: 'GALBINA',
      zip: '87898',
      isUrban: false,
    },
    {
      name: 'MADA',
      zip: '87905',
      isUrban: false,
    },
    {
      name: 'OPRISESTI',
      zip: '87914',
      isUrban: false,
    },
    {
      name: 'POIANA',
      zip: '87923',
      isUrban: false,
    },
    {
      name: 'POIENITA',
      zip: '87932',
      isUrban: false,
    },
    {
      name: 'ROSIA',
      zip: '87941',
      isUrban: false,
    },
    {
      name: 'STAUINI',
      zip: '87950',
      isUrban: false,
    },
    {
      name: 'TECHEREU',
      zip: '87969',
      isUrban: false,
    },
    {
      name: 'VALISOARA',
      zip: '87978',
      isUrban: false,
    },
    {
      name: 'VOIA',
      zip: '87987',
      isUrban: false,
    },
    {
      name: 'BANITA',
      zip: '87255',
      isUrban: false,
    },
    {
      name: 'CRIVADIA',
      zip: '87264',
      isUrban: false,
    },
    {
      name: 'MERISOR',
      zip: '87273',
      isUrban: false,
    },
    {
      name: 'BARU',
      zip: '88001',
      isUrban: false,
    },
    {
      name: 'LIVADIA',
      zip: '88010',
      isUrban: false,
    },
    {
      name: 'PETROS',
      zip: '88029',
      isUrban: false,
    },
    {
      name: 'VALEA LUPULUI',
      zip: '88038',
      isUrban: false,
    },
    {
      name: 'BATRANA',
      zip: '88225',
      isUrban: false,
    },
    {
      name: 'FATA ROSIE',
      zip: '88234',
      isUrban: false,
    },
    {
      name: 'PIATRA',
      zip: '88243',
      isUrban: false,
    },
    {
      name: 'RACHITAUA',
      zip: '88252',
      isUrban: false,
    },
    {
      name: 'BERIU',
      zip: '88270',
      isUrban: false,
    },
    {
      name: 'CASTAU',
      zip: '88289',
      isUrban: false,
    },
    {
      name: 'CUCUIS',
      zip: '88298',
      isUrban: false,
    },
    {
      name: 'MAGURENI',
      zip: '88305',
      isUrban: false,
    },
    {
      name: 'ORASTIOARA DE JOS',
      zip: '88314',
      isUrban: false,
    },
    {
      name: 'POIENI',
      zip: '88323',
      isUrban: false,
    },
    {
      name: 'SERECA',
      zip: '88332',
      isUrban: false,
    },
    {
      name: 'SIBISEL',
      zip: '88341',
      isUrban: false,
    },
    {
      name: 'BLAJENI',
      zip: '88369',
      isUrban: false,
    },
    {
      name: 'BLAJENI-VULCAN',
      zip: '88378',
      isUrban: false,
    },
    {
      name: 'CRIS',
      zip: '88387',
      isUrban: false,
    },
    {
      name: 'DRAGU-BRAD',
      zip: '88396',
      isUrban: false,
    },
    {
      name: 'GROSURI',
      zip: '88403',
      isUrban: false,
    },
    {
      name: 'PLAI',
      zip: '88412',
      isUrban: false,
    },
    {
      name: 'RET',
      zip: '88421',
      isUrban: false,
    },
    {
      name: 'SALATRUC',
      zip: '88430',
      isUrban: false,
    },
    {
      name: 'BOSOROD',
      zip: '88458',
      isUrban: false,
    },
    {
      name: 'ALUN',
      zip: '88467',
      isUrban: false,
    },
    {
      name: 'BOBAIA',
      zip: '88476',
      isUrban: false,
    },
    {
      name: 'CHITID',
      zip: '88485',
      isUrban: false,
    },
    {
      name: 'BRETEA ROMANA',
      zip: '88653',
      isUrban: false,
    },
    {
      name: 'BATALAR',
      zip: '88662',
      isUrban: false,
    },
    {
      name: 'BERCU',
      zip: '88671',
      isUrban: false,
    },
    {
      name: 'BRETEA STREIULUI',
      zip: '88680',
      isUrban: false,
    },
    {
      name: 'COVRAGIU',
      zip: '88699',
      isUrban: false,
    },
    {
      name: 'GANTAGA',
      zip: '88706',
      isUrban: false,
    },
    {
      name: 'MACEU',
      zip: '88715',
      isUrban: false,
    },
    {
      name: 'OCOLISU MARE',
      zip: '88724',
      isUrban: false,
    },
    {
      name: 'PLOPI',
      zip: '88733',
      isUrban: false,
    },
    {
      name: 'RUSI',
      zip: '88742',
      isUrban: false,
    },
    {
      name: 'VALCELE',
      zip: '88751',
      isUrban: false,
    },
    {
      name: 'VALCELELE BUNE',
      zip: '88760',
      isUrban: false,
    },
    {
      name: 'VALCELUTA',
      zip: '88779',
      isUrban: false,
    },
    {
      name: 'BUCES',
      zip: '88797',
      isUrban: false,
    },
    {
      name: 'BUCES-VULCAN',
      zip: '88804',
      isUrban: false,
    },
    {
      name: 'DUPAPIATRA',
      zip: '88813',
      isUrban: false,
    },
    {
      name: 'GROHOTELE',
      zip: '88822',
      isUrban: false,
    },
    {
      name: 'MIHAILENI',
      zip: '88831',
      isUrban: false,
    },
    {
      name: 'STANIJA',
      zip: '88840',
      isUrban: false,
    },
    {
      name: 'TARNITA',
      zip: '88859',
      isUrban: false,
    },
    {
      name: 'BUCURESCI',
      zip: '88877',
      isUrban: false,
    },
    {
      name: 'CURECHIU',
      zip: '88886',
      isUrban: false,
    },
    {
      name: 'MERISOR',
      zip: '88895',
      isUrban: false,
    },
    {
      name: 'ROVINA',
      zip: '88902',
      isUrban: false,
    },
    {
      name: 'SESURI',
      zip: '88911',
      isUrban: false,
    },
    {
      name: 'BULZESTII DE SUS',
      zip: '88939',
      isUrban: false,
    },
    {
      name: 'BULZESTII DE JOS',
      zip: '88948',
      isUrban: false,
    },
    {
      name: 'GIURGESTI',
      zip: '88957',
      isUrban: false,
    },
    {
      name: 'GROHOT',
      zip: '88966',
      isUrban: false,
    },
    {
      name: 'PAULESTI',
      zip: '88975',
      isUrban: false,
    },
    {
      name: 'RUSESTI',
      zip: '88984',
      isUrban: false,
    },
    {
      name: 'STANCULESTI',
      zip: '88993',
      isUrban: false,
    },
    {
      name: 'TICERA',
      zip: '89008',
      isUrban: false,
    },
    {
      name: 'TOMNATEC',
      zip: '89017',
      isUrban: false,
    },
    {
      name: 'BUNILA',
      zip: '89035',
      isUrban: false,
    },
    {
      name: 'ALUN',
      zip: '89044',
      isUrban: false,
    },
    {
      name: 'CERNISOARA FLORESE',
      zip: '89053',
      isUrban: false,
    },
    {
      name: 'POIENITA VOINII',
      zip: '89062',
      isUrban: false,
    },
    {
      name: 'VADU DOBRII',
      zip: '89071',
      isUrban: false,
    },
    {
      name: 'BURJUC',
      zip: '89099',
      isUrban: false,
    },
    {
      name: 'BRADATEL',
      zip: '89106',
      isUrban: false,
    },
    {
      name: 'GLODGHILESTI',
      zip: '89115',
      isUrban: false,
    },
    {
      name: 'PETRESTI',
      zip: '89124',
      isUrban: false,
    },
    {
      name: 'TATARASTI',
      zip: '89133',
      isUrban: false,
    },
    {
      name: 'TISA',
      zip: '89142',
      isUrban: false,
    },
    {
      name: 'CARJITI',
      zip: '86758',
      isUrban: false,
    },
    {
      name: 'ALMASU SEC',
      zip: '86767',
      isUrban: false,
    },
    {
      name: 'CHERGHES',
      zip: '86776',
      isUrban: false,
    },
    {
      name: 'COZIA',
      zip: '86785',
      isUrban: false,
    },
    {
      name: 'POPESTI',
      zip: '86794',
      isUrban: false,
    },
    {
      name: 'CERBAL',
      zip: '89160',
      isUrban: false,
    },
    {
      name: 'ARANIES',
      zip: '89179',
      isUrban: false,
    },
    {
      name: 'FEREGI',
      zip: '89188',
      isUrban: false,
    },
    {
      name: 'MERISORU DE MUNTE',
      zip: '89197',
      isUrban: false,
    },
    {
      name: 'POIANA RACHITELII',
      zip: '89213',
      isUrban: false,
    },
    {
      name: 'POIENITA TOMII',
      zip: '89204',
      isUrban: false,
    },
    {
      name: 'SOCET',
      zip: '89222',
      isUrban: false,
    },
    {
      name: 'ULM',
      zip: '89231',
      isUrban: false,
    },
    {
      name: 'CERTEJU DE SUS',
      zip: '89259',
      isUrban: false,
    },
    {
      name: 'BOCSA MARE',
      zip: '89268',
      isUrban: false,
    },
    {
      name: 'BOCSA MICA',
      zip: '89277',
      isUrban: false,
    },
    {
      name: 'HONDOL',
      zip: '89286',
      isUrban: false,
    },
    {
      name: 'MAGURA-TOPLITA',
      zip: '89295',
      isUrban: false,
    },
    {
      name: 'NOJAG',
      zip: '89302',
      isUrban: false,
    },
    {
      name: 'SACARAMB',
      zip: '89311',
      isUrban: false,
    },
    {
      name: 'TOPLITA MURESULUI',
      zip: '89320',
      isUrban: false,
    },
    {
      name: 'VARMAGA',
      zip: '89339',
      isUrban: false,
    },
    {
      name: 'CRISCIOR',
      zip: '87371',
      isUrban: false,
    },
    {
      name: 'BARZA',
      zip: '87380',
      isUrban: false,
    },
    {
      name: 'VALEA ARSULUI',
      zip: '87399',
      isUrban: false,
    },
    {
      name: 'ZDRAPTI',
      zip: '87406',
      isUrban: false,
    },
    {
      name: 'DENSUS',
      zip: '89357',
      isUrban: false,
    },
    {
      name: 'CRIVA',
      zip: '89366',
      isUrban: false,
    },
    {
      name: 'HATAGEL',
      zip: '89375',
      isUrban: false,
    },
    {
      name: 'PESTEANA',
      zip: '89384',
      isUrban: false,
    },
    {
      name: 'PESTENITA',
      zip: '89393',
      isUrban: false,
    },
    {
      name: 'POIENI',
      zip: '89400',
      isUrban: false,
    },
    {
      name: 'STEI',
      zip: '89419',
      isUrban: false,
    },
    {
      name: 'DOBRA',
      zip: '89437',
      isUrban: false,
    },
    {
      name: 'ABUCEA',
      zip: '89446',
      isUrban: false,
    },
    {
      name: 'BUJORU',
      zip: '89455',
      isUrban: false,
    },
    {
      name: 'FAGETEL',
      zip: '89464',
      isUrban: false,
    },
    {
      name: 'LAPUSNIC',
      zip: '89473',
      isUrban: false,
    },
    {
      name: 'MIHAIESTI',
      zip: '89482',
      isUrban: false,
    },
    {
      name: 'PANC',
      zip: '89491',
      isUrban: false,
    },
    {
      name: 'PANC-SALISTE',
      zip: '89507',
      isUrban: false,
    },
    {
      name: 'RADULESTI',
      zip: '89516',
      isUrban: false,
    },
    {
      name: 'ROSCANI',
      zip: '89525',
      isUrban: false,
    },
    {
      name: 'STANCESTI',
      zip: '89534',
      isUrban: false,
    },
    {
      name: 'STANCESTI-OHABA',
      zip: '89543',
      isUrban: false,
    },
    {
      name: 'STRETEA',
      zip: '89552',
      isUrban: false,
    },
    {
      name: 'GEOAGIU',
      zip: '89570',
      isUrban: true,
    },
    {
      name: 'AUREL VLAICU',
      zip: '89589',
      isUrban: true,
    },
    {
      name: 'BACIIA',
      zip: '89598',
      isUrban: true,
    },
    {
      name: 'BOZES',
      zip: '89605',
      isUrban: true,
    },
    {
      name: 'CIGMAU',
      zip: '89614',
      isUrban: true,
    },
    {
      name: 'GELMAR',
      zip: '89623',
      isUrban: true,
    },
    {
      name: 'GEOAGIU-BAI',
      zip: '89632',
      isUrban: true,
    },
    {
      name: 'HOMOROD',
      zip: '89641',
      isUrban: true,
    },
    {
      name: 'MERMEZEU-VALENI',
      zip: '89650',
      isUrban: true,
    },
    {
      name: 'RENGHET',
      zip: '89669',
      isUrban: true,
    },
    {
      name: 'VALENI',
      zip: '89678',
      isUrban: true,
    },
    {
      name: 'GHELARI',
      zip: '86892',
      isUrban: false,
    },
    {
      name: 'GOVAJDIA',
      zip: '86909',
      isUrban: false,
    },
    {
      name: 'PLOP',
      zip: '86918',
      isUrban: false,
    },
    {
      name: 'RUDA',
      zip: '86927',
      isUrban: false,
    },
    {
      name: 'GURASADA',
      zip: '89696',
      isUrban: false,
    },
    {
      name: 'BOIU DE JOS',
      zip: '89703',
      isUrban: false,
    },
    {
      name: 'BOIU DE SUS',
      zip: '89712',
      isUrban: false,
    },
    {
      name: 'CAMPURI DE SUS',
      zip: '89730',
      isUrban: false,
    },
    {
      name: 'CAMPURI-SURDUC',
      zip: '89749',
      isUrban: false,
    },
    {
      name: 'CARMAZANESTI',
      zip: '89721',
      isUrban: false,
    },
    {
      name: 'DANULESTI',
      zip: '89758',
      isUrban: false,
    },
    {
      name: 'GOTHATEA',
      zip: '89767',
      isUrban: false,
    },
    {
      name: 'RUNCSOR',
      zip: '89776',
      isUrban: false,
    },
    {
      name: 'ULIES',
      zip: '89785',
      isUrban: false,
    },
    {
      name: 'VICA',
      zip: '89794',
      isUrban: false,
    },
    {
      name: 'HARAU',
      zip: '89810',
      isUrban: false,
    },
    {
      name: 'BANPOTOC',
      zip: '89829',
      isUrban: false,
    },
    {
      name: 'BARSAU',
      zip: '89838',
      isUrban: false,
    },
    {
      name: 'CHIMINDIA',
      zip: '89847',
      isUrban: false,
    },
    {
      name: 'ILIA',
      zip: '89865',
      isUrban: false,
    },
    {
      name: 'BACEA',
      zip: '89874',
      isUrban: false,
    },
    {
      name: 'BRAZNIC',
      zip: '89892',
      isUrban: false,
    },
    {
      name: 'BRETEA MURESANA',
      zip: '89883',
      isUrban: false,
    },
    {
      name: 'CUIES',
      zip: '89909',
      isUrban: false,
    },
    {
      name: 'DUMBRAVITA',
      zip: '89918',
      isUrban: false,
    },
    {
      name: 'SACAMAS',
      zip: '89927',
      isUrban: false,
    },
    {
      name: 'SARBI',
      zip: '89936',
      isUrban: false,
    },
    {
      name: 'VALEA LUNGA',
      zip: '89945',
      isUrban: false,
    },
    {
      name: 'LAPUGIU DE JOS',
      zip: '89963',
      isUrban: false,
    },
    {
      name: 'BASTEA',
      zip: '89972',
      isUrban: false,
    },
    {
      name: 'COSESTI',
      zip: '89981',
      isUrban: false,
    },
    {
      name: 'FINTOAG',
      zip: '89990',
      isUrban: false,
    },
    {
      name: 'GRIND',
      zip: '90002',
      isUrban: false,
    },
    {
      name: 'HOLDEA',
      zip: '90011',
      isUrban: false,
    },
    {
      name: 'LAPUGIU DE SUS',
      zip: '90020',
      isUrban: false,
    },
    {
      name: 'LASAU',
      zip: '90039',
      isUrban: false,
    },
    {
      name: 'OHABA',
      zip: '90048',
      isUrban: false,
    },
    {
      name: 'TEIU',
      zip: '90057',
      isUrban: false,
    },
    {
      name: 'LELESE',
      zip: '90075',
      isUrban: false,
    },
    {
      name: 'CERISOR',
      zip: '90084',
      isUrban: false,
    },
    {
      name: 'RUNCU MARE',
      zip: '90093',
      isUrban: false,
    },
    {
      name: 'SOHODOL',
      zip: '90100',
      isUrban: false,
    },
    {
      name: 'LUNCA CERNII DE JOS',
      zip: '90128',
      isUrban: false,
    },
    {
      name: 'CIUMITA',
      zip: '90137',
      isUrban: false,
    },
    {
      name: 'FANTANA',
      zip: '90146',
      isUrban: false,
    },
    {
      name: 'GURA BORDULUI',
      zip: '90155',
      isUrban: false,
    },
    {
      name: 'LUNCA CERNII DE SUS',
      zip: '90164',
      isUrban: false,
    },
    {
      name: 'MERIA',
      zip: '90173',
      isUrban: false,
    },
    {
      name: 'NEGOIU',
      zip: '90182',
      isUrban: false,
    },
    {
      name: 'VALEA BABII',
      zip: '90191',
      isUrban: false,
    },
    {
      name: 'LUNCOIU DE JOS',
      zip: '90217',
      isUrban: false,
    },
    {
      name: 'DUDESTI',
      zip: '90226',
      isUrban: false,
    },
    {
      name: 'LUNCOIU DE SUS',
      zip: '90235',
      isUrban: false,
    },
    {
      name: 'PODELE',
      zip: '90244',
      isUrban: false,
    },
    {
      name: 'STEJAREL',
      zip: '90253',
      isUrban: false,
    },
    {
      name: 'MARTINESTI',
      zip: '90271',
      isUrban: false,
    },
    {
      name: 'DANCU MARE',
      zip: '90280',
      isUrban: false,
    },
    {
      name: 'DANCU MIC',
      zip: '90299',
      isUrban: false,
    },
    {
      name: 'JELEDINTI',
      zip: '90306',
      isUrban: false,
    },
    {
      name: 'MAGURA',
      zip: '90315',
      isUrban: false,
    },
    {
      name: 'TAMASASA',
      zip: '90324',
      isUrban: false,
    },
    {
      name: 'TURMAS',
      zip: '90333',
      isUrban: false,
    },
    {
      name: 'ORASTIOARA DE SUS',
      zip: '90351',
      isUrban: false,
    },
    {
      name: 'BUCIUM',
      zip: '90360',
      isUrban: false,
    },
    {
      name: 'COSTESTI',
      zip: '90379',
      isUrban: false,
    },
    {
      name: 'COSTESTI-DEAL',
      zip: '90388',
      isUrban: false,
    },
    {
      name: 'GRADISTEA DE MUNTE',
      zip: '90397',
      isUrban: false,
    },
    {
      name: 'LUDESTII DE JOS',
      zip: '90404',
      isUrban: false,
    },
    {
      name: 'LUDESTII DE SUS',
      zip: '90413',
      isUrban: false,
    },
    {
      name: 'OCOLISU MIC',
      zip: '90422',
      isUrban: false,
    },
    {
      name: 'PESTISU MIC',
      zip: '90440',
      isUrban: false,
    },
    {
      name: 'ALMASU MIC',
      zip: '90459',
      isUrban: false,
    },
    {
      name: 'CIULPAZ',
      zip: '90468',
      isUrban: false,
    },
    {
      name: 'CUTIN',
      zip: '90477',
      isUrban: false,
    },
    {
      name: 'DUMBRAVA',
      zip: '90486',
      isUrban: false,
    },
    {
      name: 'JOSANI',
      zip: '90495',
      isUrban: false,
    },
    {
      name: 'MANERAU',
      zip: '90501',
      isUrban: false,
    },
    {
      name: 'NANDRU',
      zip: '90510',
      isUrban: false,
    },
    {
      name: 'VALEA NANDRULUI',
      zip: '90529',
      isUrban: false,
    },
    {
      name: 'PUI',
      zip: '90547',
      isUrban: false,
    },
    {
      name: 'BAIESTI',
      zip: '90556',
      isUrban: false,
    },
    {
      name: 'FEDERI',
      zip: '90565',
      isUrban: false,
    },
    {
      name: 'FIZESTI',
      zip: '90574',
      isUrban: false,
    },
    {
      name: 'GALATI',
      zip: '90583',
      isUrban: false,
    },
    {
      name: 'HOBITA',
      zip: '90592',
      isUrban: false,
    },
    {
      name: 'OHABA-PONOR',
      zip: '90609',
      isUrban: false,
    },
    {
      name: 'PONOR',
      zip: '90618',
      isUrban: false,
    },
    {
      name: 'RAU BARBAT',
      zip: '90627',
      isUrban: false,
    },
    {
      name: 'RUSOR',
      zip: '90636',
      isUrban: false,
    },
    {
      name: 'SEREL',
      zip: '90645',
      isUrban: false,
    },
    {
      name: 'URIC',
      zip: '90654',
      isUrban: false,
    },
    {
      name: 'RACHITOVA',
      zip: '90734',
      isUrban: false,
    },
    {
      name: 'BOITA',
      zip: '90743',
      isUrban: false,
    },
    {
      name: 'CIULA MARE',
      zip: '90752',
      isUrban: false,
    },
    {
      name: 'CIULA MICA',
      zip: '90761',
      isUrban: false,
    },
    {
      name: 'GOTESTI',
      zip: '90770',
      isUrban: false,
    },
    {
      name: 'MESTEACAN',
      zip: '90789',
      isUrban: false,
    },
    {
      name: 'VALIOARA',
      zip: '90798',
      isUrban: false,
    },
    {
      name: 'RAPOLTU MARE',
      zip: '90672',
      isUrban: false,
    },
    {
      name: 'BOBILNA',
      zip: '90681',
      isUrban: false,
    },
    {
      name: 'BOIU',
      zip: '90690',
      isUrban: false,
    },
    {
      name: 'FOLT',
      zip: '90707',
      isUrban: false,
    },
    {
      name: 'RAPOLTEL',
      zip: '90716',
      isUrban: false,
    },
    {
      name: 'RAU DE MORI',
      zip: '90887',
      isUrban: false,
    },
    {
      name: 'BRAZI',
      zip: '90896',
      isUrban: false,
    },
    {
      name: 'CLOPOTIVA',
      zip: '90903',
      isUrban: false,
    },
    {
      name: 'OHABA-SIBISEL',
      zip: '90912',
      isUrban: false,
    },
    {
      name: 'OSTROV',
      zip: '90921',
      isUrban: false,
    },
    {
      name: 'OSTROVEL',
      zip: '90930',
      isUrban: false,
    },
    {
      name: 'OSTROVU MIC',
      zip: '90949',
      isUrban: false,
    },
    {
      name: 'SIBISEL',
      zip: '90958',
      isUrban: false,
    },
    {
      name: 'SUSENI',
      zip: '90967',
      isUrban: false,
    },
    {
      name: 'UNCIUC',
      zip: '90976',
      isUrban: false,
    },
    {
      name: 'VALEA DALJII',
      zip: '90985',
      isUrban: false,
    },
    {
      name: 'RIBITA',
      zip: '90814',
      isUrban: false,
    },
    {
      name: 'CRISAN',
      zip: '90823',
      isUrban: false,
    },
    {
      name: 'DUMBRAVA DE JOS',
      zip: '90832',
      isUrban: false,
    },
    {
      name: 'DUMBRAVA DE SUS',
      zip: '90841',
      isUrban: false,
    },
    {
      name: 'RIBICIOARA',
      zip: '90850',
      isUrban: false,
    },
    {
      name: 'UIBARESTI',
      zip: '90869',
      isUrban: false,
    },
    {
      name: 'ROMOS',
      zip: '91009',
      isUrban: false,
    },
    {
      name: 'CIUNGU MARE',
      zip: '91018',
      isUrban: false,
    },
    {
      name: 'PISCHINTI',
      zip: '91027',
      isUrban: false,
    },
    {
      name: 'ROMOSEL',
      zip: '91036',
      isUrban: false,
    },
    {
      name: 'VAIDEI',
      zip: '91045',
      isUrban: false,
    },
    {
      name: 'SALASU DE SUS',
      zip: '91125',
      isUrban: false,
    },
    {
      name: 'COROIESTI',
      zip: '91134',
      isUrban: false,
    },
    {
      name: 'MALAIESTI',
      zip: '91143',
      isUrban: false,
    },
    {
      name: 'NUCSOARA',
      zip: '91152',
      isUrban: false,
    },
    {
      name: 'OHABA DE SUB PIATRA',
      zip: '91161',
      isUrban: false,
    },
    {
      name: 'PAROS',
      zip: '91170',
      isUrban: false,
    },
    {
      name: 'PESTERA',
      zip: '91189',
      isUrban: false,
    },
    {
      name: 'RAU ALB',
      zip: '91198',
      isUrban: false,
    },
    {
      name: 'RAU MIC',
      zip: '91205',
      isUrban: false,
    },
    {
      name: 'SALASU DE JOS',
      zip: '91214',
      isUrban: false,
    },
    {
      name: 'ZAVOI',
      zip: '91223',
      isUrban: false,
    },
    {
      name: 'SANTAMARIA-ORLEA',
      zip: '91241',
      isUrban: false,
    },
    {
      name: 'BALOMIR',
      zip: '91250',
      isUrban: false,
    },
    {
      name: 'BARASTII HATEGULUI',
      zip: '91269',
      isUrban: false,
    },
    {
      name: 'BUCIUM-ORLEA',
      zip: '91278',
      isUrban: false,
    },
    {
      name: 'PAUCINESTI',
      zip: '91090',
      isUrban: false,
    },
    {
      name: 'ZEICANI',
      zip: '91107',
      isUrban: false,
    },
    {
      name: 'SOIMUS',
      zip: '91349',
      isUrban: false,
    },
    {
      name: 'BALATA',
      zip: '91358',
      isUrban: false,
    },
    {
      name: 'BEJAN',
      zip: '91376',
      isUrban: false,
    },
    {
      name: 'BEJAN-TARNAVITA',
      zip: '91385',
      isUrban: false,
    },
    {
      name: 'BOHOLT',
      zip: '91367',
      isUrban: false,
    },
    {
      name: 'CAINELU DE JOS',
      zip: '91394',
      isUrban: false,
    },
    {
      name: 'CHISCADAGA',
      zip: '91401',
      isUrban: false,
    },
    {
      name: 'FORNADIA',
      zip: '91410',
      isUrban: false,
    },
    {
      name: 'PAULIS',
      zip: '91429',
      isUrban: false,
    },
    {
      name: 'SULIGHETE',
      zip: '91438',
      isUrban: false,
    },
    {
      name: 'TELIUCU INFERIOR',
      zip: '86945',
      isUrban: false,
    },
    {
      name: 'CINCIS-CERNA',
      zip: '86954',
      isUrban: false,
    },
    {
      name: 'IZVOARELE',
      zip: '86963',
      isUrban: false,
    },
    {
      name: 'TELIUCU SUPERIOR',
      zip: '86972',
      isUrban: false,
    },
    {
      name: 'TOMESTI',
      zip: '91456',
      isUrban: false,
    },
    {
      name: 'DOBROT',
      zip: '91465',
      isUrban: false,
    },
    {
      name: 'LEAUT',
      zip: '91474',
      isUrban: false,
    },
    {
      name: 'LIVADA',
      zip: '91483',
      isUrban: false,
    },
    {
      name: 'OBARSA',
      zip: '91492',
      isUrban: false,
    },
    {
      name: 'STEIA',
      zip: '91508',
      isUrban: false,
    },
    {
      name: 'TIULESTI',
      zip: '91517',
      isUrban: false,
    },
    {
      name: 'VALEA MARE DE CRIS',
      zip: '91526',
      isUrban: false,
    },
    {
      name: 'TOPLITA',
      zip: '91544',
      isUrban: false,
    },
    {
      name: 'CURPENII SILVASULUI',
      zip: '91553',
      isUrban: false,
    },
    {
      name: 'DABACA',
      zip: '91571',
      isUrban: false,
    },
    {
      name: 'DEALU MIC',
      zip: '91562',
      isUrban: false,
    },
    {
      name: 'GOLES',
      zip: '91580',
      isUrban: false,
    },
    {
      name: 'HASDAU',
      zip: '91599',
      isUrban: false,
    },
    {
      name: 'MOSORU',
      zip: '91606',
      isUrban: false,
    },
    {
      name: 'VALARI',
      zip: '91615',
      isUrban: false,
    },
    {
      name: 'TOTESTI',
      zip: '91633',
      isUrban: false,
    },
    {
      name: 'CARNESTI',
      zip: '91642',
      isUrban: false,
    },
    {
      name: 'COPACI',
      zip: '91651',
      isUrban: false,
    },
    {
      name: 'PACLISA',
      zip: '91660',
      isUrban: false,
    },
    {
      name: 'REEA',
      zip: '91679',
      isUrban: false,
    },
    {
      name: 'TURDAS',
      zip: '91697',
      isUrban: false,
    },
    {
      name: 'PRICAZ',
      zip: '91704',
      isUrban: false,
    },
    {
      name: 'RAPAS',
      zip: '91713',
      isUrban: false,
    },
    {
      name: 'SPINI',
      zip: '91722',
      isUrban: false,
    },
    {
      name: 'GENERAL BERTHELOT',
      zip: '91740',
      isUrban: false,
    },
    {
      name: 'CRAGUIS',
      zip: '91759',
      isUrban: false,
    },
    {
      name: 'FARCADIN',
      zip: '91768',
      isUrban: false,
    },
    {
      name: 'LIVEZI',
      zip: '91777',
      isUrban: false,
    },
    {
      name: 'TUSTEA',
      zip: '91786',
      isUrban: false,
    },
    {
      name: 'VALISOARA',
      zip: '91946',
      isUrban: false,
    },
    {
      name: 'DEALU MARE',
      zip: '91955',
      isUrban: false,
    },
    {
      name: 'SALISTIOARA',
      zip: '91964',
      isUrban: false,
    },
    {
      name: 'STOIENEASA',
      zip: '91973',
      isUrban: false,
    },
    {
      name: 'VATA DE JOS',
      zip: '91802',
      isUrban: false,
    },
    {
      name: 'BASARABASA',
      zip: '91811',
      isUrban: false,
    },
    {
      name: 'BIRTIN',
      zip: '91820',
      isUrban: false,
    },
    {
      name: 'BROTUNA',
      zip: '91839',
      isUrban: false,
    },
    {
      name: 'CAZANESTI',
      zip: '91848',
      isUrban: false,
    },
    {
      name: 'CIUNGANI',
      zip: '91857',
      isUrban: false,
    },
    {
      name: 'OCISOR',
      zip: '91875',
      isUrban: false,
    },
    {
      name: 'OCIU',
      zip: '91866',
      isUrban: false,
    },
    {
      name: 'PRAVALENI',
      zip: '91884',
      isUrban: false,
    },
    {
      name: 'PRIHODISTE',
      zip: '91893',
      isUrban: false,
    },
    {
      name: 'TARNAVA DE CRIS',
      zip: '91919',
      isUrban: false,
    },
    {
      name: 'TATARASTII DE CRIS',
      zip: '91900',
      isUrban: false,
    },
    {
      name: 'VATA DE SUS',
      zip: '91928',
      isUrban: false,
    },
    {
      name: 'VETEL',
      zip: '91991',
      isUrban: false,
    },
    {
      name: 'BOIA BARZII',
      zip: '92006',
      isUrban: false,
    },
    {
      name: 'BRETELIN',
      zip: '92015',
      isUrban: false,
    },
    {
      name: 'CAOI',
      zip: '92024',
      isUrban: false,
    },
    {
      name: 'HEREPEIA',
      zip: '92033',
      isUrban: false,
    },
    {
      name: 'LESNIC',
      zip: '92042',
      isUrban: false,
    },
    {
      name: 'MINTIA',
      zip: '92051',
      isUrban: false,
    },
    {
      name: 'MUNCELU MARE',
      zip: '92060',
      isUrban: false,
    },
    {
      name: 'MUNCELU MIC',
      zip: '92079',
      isUrban: false,
    },
    {
      name: 'RUNCU MIC',
      zip: '92088',
      isUrban: false,
    },
    {
      name: 'VORTA',
      zip: '92104',
      isUrban: false,
    },
    {
      name: 'CERTEJU DE JOS',
      zip: '92113',
      isUrban: false,
    },
    {
      name: 'COAJA',
      zip: '92122',
      isUrban: false,
    },
    {
      name: 'DUMESTI',
      zip: '92131',
      isUrban: false,
    },
    {
      name: 'LUNCSOARA',
      zip: '92140',
      isUrban: false,
    },
    {
      name: 'VALEA POIENII',
      zip: '92159',
      isUrban: false,
    },
    {
      name: 'VISCA',
      zip: '92168',
      isUrban: false,
    },
    {
      name: 'ZAM',
      zip: '92186',
      isUrban: false,
    },
    {
      name: 'ALMAS-SALISTE',
      zip: '92202',
      isUrban: false,
    },
    {
      name: 'ALMASEL',
      zip: '92195',
      isUrban: false,
    },
    {
      name: 'BRASEU',
      zip: '92211',
      isUrban: false,
    },
    {
      name: 'CERBIA',
      zip: '92220',
      isUrban: false,
    },
    {
      name: 'DELENI',
      zip: '92239',
      isUrban: false,
    },
    {
      name: 'GODINESTI',
      zip: '92248',
      isUrban: false,
    },
    {
      name: 'MICANESTI',
      zip: '92257',
      isUrban: false,
    },
    {
      name: 'POGANESTI',
      zip: '92266',
      isUrban: false,
    },
    {
      name: 'POJOGA',
      zip: '92275',
      isUrban: false,
    },
    {
      name: 'SALCIVA',
      zip: '92284',
      isUrban: false,
    },
    {
      name: 'TAMASESTI',
      zip: '92293',
      isUrban: false,
    },
    {
      name: 'VALEA',
      zip: '92300',
      isUrban: false,
    },
    {
      name: 'CIOCLOVINA',
      zip: '88494',
      isUrban: false,
    },
    {
      name: 'LUNCANI',
      zip: '88500',
      isUrban: false,
    },
    {
      name: 'PRIHODISTE',
      zip: '88519',
      isUrban: false,
    },
    {
      name: 'TARSA',
      zip: '88537',
      isUrban: false,
    },
    {
      name: 'URSICI',
      zip: '88528',
      isUrban: false,
    },
    {
      name: 'BRANISCA',
      zip: '88555',
      isUrban: false,
    },
    {
      name: 'BARASTII ILIEI',
      zip: '88573',
      isUrban: false,
    },
    {
      name: 'BOZ',
      zip: '88564',
      isUrban: false,
    },
    {
      name: 'CABESTI',
      zip: '88582',
      isUrban: false,
    },
    {
      name: 'FURCSOARA',
      zip: '88608',
      isUrban: false,
    },
    {
      name: 'GIALACUTA',
      zip: '88591',
      isUrban: false,
    },
    {
      name: 'ROVINA',
      zip: '88617',
      isUrban: false,
    },
    {
      name: 'TARNAVA',
      zip: '88626',
      isUrban: false,
    },
    {
      name: 'TARNAVITA',
      zip: '88635',
      isUrban: false,
    },
    {
      name: 'CIOPEIA',
      zip: '91287',
      isUrban: false,
    },
    {
      name: 'SACEL',
      zip: '91296',
      isUrban: false,
    },
    {
      name: 'SANPETRU',
      zip: '91303',
      isUrban: false,
    },
    {
      name: 'SUBCETATE',
      zip: '91312',
      isUrban: false,
    },
    {
      name: 'VADU',
      zip: '91321',
      isUrban: false,
    },
    {
      name: 'SARMIZEGETUSA',
      zip: '91063',
      isUrban: false,
    },
    {
      name: 'BREAZOVA',
      zip: '91072',
      isUrban: false,
    },
    {
      name: 'HOBITA-GRADISTE',
      zip: '91081',
      isUrban: false,
    },
    {
      name: 'CALAN',
      zip: '87433',
      isUrban: true,
    },
    {
      name: 'BATIZ',
      zip: '87451',
      isUrban: true,
    }
  ],
  IL: [
    {
      name: 'SLOBOZIA',
      zip: '92667',
      isUrban: true,
    },
    {
      name: 'BORA',
      zip: '92676',
      isUrban: true,
    },
    {
      name: 'SLOBOZIA NOUA',
      zip: '92685',
      isUrban: true,
    },
    {
      name: 'FETESTI',
      zip: '92710',
      isUrban: true,
    },
    {
      name: 'BULIGA',
      zip: '92729',
      isUrban: true,
    },
    {
      name: 'FETESTI-GARA',
      zip: '92738',
      isUrban: true,
    },
    {
      name: 'VLASCA',
      zip: '92747',
      isUrban: true,
    },
    {
      name: 'URZICENI',
      zip: '100692',
      isUrban: true,
    },
    {
      name: 'TANDAREI',
      zip: '92774',
      isUrban: true,
    },
    {
      name: 'ADANCATA',
      zip: '100763',
      isUrban: false,
    },
    {
      name: 'PATRU FRATI',
      zip: '100772',
      isUrban: false,
    },
    {
      name: 'ALBESTI',
      zip: '92792',
      isUrban: false,
    },
    {
      name: 'BATALURI',
      zip: '92809',
      isUrban: false,
    },
    {
      name: 'BUESTI',
      zip: '92818',
      isUrban: false,
    },
    {
      name: 'MARSILIENI',
      zip: '92827',
      isUrban: false,
    },
    {
      name: 'ALEXENI',
      zip: '100861',
      isUrban: false,
    },
    {
      name: 'AMARA',
      zip: '92845',
      isUrban: true,
    },
    {
      name: 'AMARA NOUA',
      zip: '92854',
      isUrban: true,
    },
    {
      name: 'ANDRASESTI',
      zip: '92881',
      isUrban: false,
    },
    {
      name: 'ORBOESTI',
      zip: '92890',
      isUrban: false,
    },
    {
      name: 'ARMASESTI',
      zip: '100889',
      isUrban: false,
    },
    {
      name: 'BARBULESTI',
      zip: '100898',
      isUrban: false,
    },
    {
      name: 'MALU ROSU',
      zip: '100905',
      isUrban: false,
    },
    {
      name: 'NENISORI',
      zip: '100914',
      isUrban: false,
    },
    {
      name: 'AXINTELE',
      zip: '100932',
      isUrban: false,
    },
    {
      name: 'BARBATESCU',
      zip: '100941',
      isUrban: false,
    },
    {
      name: 'HORIA',
      zip: '100950',
      isUrban: false,
    },
    {
      name: 'BALACIU',
      zip: '92916',
      isUrban: false,
    },
    {
      name: 'COPUZU',
      zip: '92925',
      isUrban: false,
    },
    {
      name: 'CRASANII DE JOS',
      zip: '92934',
      isUrban: false,
    },
    {
      name: 'CRASANII DE SUS',
      zip: '92943',
      isUrban: false,
    },
    {
      name: 'SARATENI',
      zip: '92952',
      isUrban: false,
    },
    {
      name: 'BARCANESTI',
      zip: '101065',
      isUrban: false,
    },
    {
      name: 'CONDEESTI',
      zip: '101074',
      isUrban: false,
    },
    {
      name: 'BORDUSANI',
      zip: '92998',
      isUrban: false,
    },
    {
      name: 'CEGANI',
      zip: '93003',
      isUrban: false,
    },
    {
      name: 'BRAZII',
      zip: '101252',
      isUrban: false,
    },
    {
      name: 'MAIA',
      zip: '101261',
      isUrban: false,
    },
    {
      name: 'MOVILEANCA',
      zip: '101270',
      isUrban: false,
    },
    {
      name: 'RASIMNICEA',
      zip: '101289',
      isUrban: false,
    },
    {
      name: 'BUCU',
      zip: '93030',
      isUrban: false,
    },
    {
      name: 'OGRADA',
      zip: '93058',
      isUrban: false,
    },
    {
      name: 'CAZANESTI',
      zip: '93076',
      isUrban: true,
    },
    {
      name: 'CIOCARLIA',
      zip: '100718',
      isUrban: false,
    },
    {
      name: 'COTORCA',
      zip: '100727',
      isUrban: false,
    },
    {
      name: 'CIOCHINA',
      zip: '93110',
      isUrban: false,
    },
    {
      name: 'BORDUSELU',
      zip: '93129',
      isUrban: false,
    },
    {
      name: 'OREZU',
      zip: '93138',
      isUrban: false,
    },
    {
      name: 'PIERSICA',
      zip: '93147',
      isUrban: false,
    },
    {
      name: 'CIULNITA',
      zip: '93165',
      isUrban: false,
    },
    {
      name: 'ION GHICA',
      zip: '93174',
      isUrban: false,
    },
    {
      name: 'IVANESTI',
      zip: '93183',
      isUrban: false,
    },
    {
      name: 'POIANA',
      zip: '93192',
      isUrban: false,
    },
    {
      name: 'COCORA',
      zip: '93218',
      isUrban: false,
    },
    {
      name: 'COLELIA',
      zip: '93227',
      isUrban: false,
    },
    {
      name: 'COSAMBESTI',
      zip: '93245',
      isUrban: false,
    },
    {
      name: 'GIMBASANI',
      zip: '93254',
      isUrban: false,
    },
    {
      name: 'MARCULESTI',
      zip: '93263',
      isUrban: false,
    },
    {
      name: 'COSERENI',
      zip: '102259',
      isUrban: false,
    },
    {
      name: 'BORANESTI',
      zip: '102268',
      isUrban: false,
    },
    {
      name: 'SINTESTI',
      zip: '102277',
      isUrban: false,
    },
    {
      name: 'DRAGOESTI',
      zip: '102650',
      isUrban: false,
    },
    {
      name: 'CHIROIU-PAMANTENI',
      zip: '102669',
      isUrban: false,
    },
    {
      name: 'CHIROIU-SATU NOU',
      zip: '102678',
      isUrban: false,
    },
    {
      name: 'CHIROIU-UNGURENI',
      zip: '102687',
      isUrban: false,
    },
    {
      name: 'VALEA BISERICII',
      zip: '102696',
      isUrban: false,
    },
    {
      name: 'DRIDU',
      zip: '102712',
      isUrban: false,
    },
    {
      name: 'DRIDU-SNAGOV',
      zip: '102721',
      isUrban: false,
    },
    {
      name: 'MOLDOVENI',
      zip: '102730',
      isUrban: false,
    },
    {
      name: 'FACAENI',
      zip: '93584',
      isUrban: false,
    },
    {
      name: 'PROGRESU',
      zip: '93593',
      isUrban: false,
    },
    {
      name: 'FIERBINTI-TARG',
      zip: '102758',
      isUrban: true,
    },
    {
      name: 'FIERBINTII DE JOS',
      zip: '102767',
      isUrban: true,
    },
    {
      name: 'FIERBINTII DE SUS',
      zip: '102776',
      isUrban: true,
    },
    {
      name: 'GRECII DE JOS',
      zip: '102785',
      isUrban: true,
    },
    {
      name: 'GARBOVI',
      zip: '103292',
      isUrban: false,
    },
    {
      name: 'GHEORGHE DOJA',
      zip: '93619',
      isUrban: false,
    },
    {
      name: 'GHEORGHE LAZAR',
      zip: '93637',
      isUrban: false,
    },
    {
      name: 'GIURGENI',
      zip: '93655',
      isUrban: false,
    },
    {
      name: 'GRINDU',
      zip: '93726',
      isUrban: false,
    },
    {
      name: 'GRIVITA',
      zip: '93744',
      isUrban: false,
    },
    {
      name: 'SMIRNA',
      zip: '93753',
      isUrban: false,
    },
    {
      name: 'TRAIAN',
      zip: '93762',
      isUrban: false,
    },
    {
      name: 'ION ROATA',
      zip: '103871',
      isUrban: false,
    },
    {
      name: 'BROSTENI',
      zip: '103880',
      isUrban: false,
    },
    {
      name: 'JILAVELE',
      zip: '103979',
      isUrban: false,
    },
    {
      name: 'SLATIOARELE',
      zip: '103988',
      isUrban: false,
    },
    {
      name: 'MANASIA',
      zip: '100745',
      isUrban: false,
    },
    {
      name: 'MIHAIL KOGALNICEANU',
      zip: '94009',
      isUrban: false,
    },
    {
      name: 'GURA IALOMITEI',
      zip: '94018',
      isUrban: false,
    },
    {
      name: 'HAGIENI',
      zip: '94027',
      isUrban: false,
    },
    {
      name: 'LUCIU',
      zip: '94036',
      isUrban: false,
    },
    {
      name: 'MILOSESTI',
      zip: '94054',
      isUrban: false,
    },
    {
      name: 'NICOLESTI',
      zip: '94063',
      isUrban: false,
    },
    {
      name: 'TOVARASIA',
      zip: '94072',
      isUrban: false,
    },
    {
      name: 'MOVILA',
      zip: '94090',
      isUrban: false,
    },
    {
      name: 'MOVILITA',
      zip: '104289',
      isUrban: false,
    },
    {
      name: 'BITINA-PAMANTENI',
      zip: '104298',
      isUrban: false,
    },
    {
      name: 'BITINA-UNGURENI',
      zip: '104305',
      isUrban: false,
    },
    {
      name: 'ROSIORI',
      zip: '104314',
      isUrban: false,
    },
    {
      name: 'MUNTENI-BUZAU',
      zip: '94116',
      isUrban: false,
    },
    {
      name: 'PERIETI',
      zip: '94170',
      isUrban: false,
    },
    {
      name: 'FUNDATA',
      zip: '94189',
      isUrban: false,
    },
    {
      name: 'MISLEANU',
      zip: '94198',
      isUrban: false,
    },
    {
      name: 'PALTINISU',
      zip: '94205',
      isUrban: false,
    },
    {
      name: 'STEJARU',
      zip: '94214',
      isUrban: false,
    },
    {
      name: 'CRUNTI',
      zip: '94287',
      isUrban: false,
    },
    {
      name: 'MIRCEA CEL BATRAN',
      zip: '94296',
      isUrban: false,
    },
    {
      name: 'ROVINE',
      zip: '94303',
      isUrban: false,
    },
    {
      name: 'SALCIOARA',
      zip: '94349',
      isUrban: false,
    },
    {
      name: 'RASI',
      zip: '94367',
      isUrban: false,
    },
    {
      name: 'SAVENI',
      zip: '94385',
      isUrban: false,
    },
    {
      name: 'FRATILESTI',
      zip: '94394',
      isUrban: false,
    },
    {
      name: 'LACUSTENI',
      zip: '94401',
      isUrban: false,
    },
    {
      name: 'PLATONESTI',
      zip: '94410',
      isUrban: false,
    },
    {
      name: 'SCANTEIA',
      zip: '94438',
      isUrban: false,
    },
    {
      name: 'IAZU',
      zip: '94447',
      isUrban: false,
    },
    {
      name: 'SFANTU GHEORGHE',
      zip: '94465',
      isUrban: false,
    },
    {
      name: 'BUTOIU',
      zip: '94474',
      isUrban: false,
    },
    {
      name: 'MALU',
      zip: '94483',
      isUrban: false,
    },
    {
      name: 'SINESTI',
      zip: '105035',
      isUrban: false,
    },
    {
      name: 'BOTENI',
      zip: '105044',
      isUrban: false,
    },
    {
      name: 'CATRUNESTI',
      zip: '105053',
      isUrban: false,
    },
    {
      name: 'HAGIESTI',
      zip: '105062',
      isUrban: false,
    },
    {
      name: 'LILIECI',
      zip: '105071',
      isUrban: false,
    },
    {
      name: 'LIVEDEA',
      zip: '105080',
      isUrban: false,
    },
    {
      name: 'STELNICA',
      zip: '94508',
      isUrban: false,
    },
    {
      name: 'MALTEZI',
      zip: '94517',
      isUrban: false,
    },
    {
      name: 'RETEZATU',
      zip: '94526',
      isUrban: false,
    },
    {
      name: 'SUDITI',
      zip: '94544',
      isUrban: false,
    },
    {
      name: 'GURA VAII',
      zip: '94553',
      isUrban: false,
    },
    {
      name: 'VALEA CIORII',
      zip: '94697',
      isUrban: false,
    },
    {
      name: 'BUCSA',
      zip: '94704',
      isUrban: false,
    },
    {
      name: 'DUMITRESTI',
      zip: '94713',
      isUrban: false,
    },
    {
      name: 'MURGEANCA',
      zip: '94722',
      isUrban: false,
    },
    {
      name: 'VALEA MACRISULUI',
      zip: '105801',
      isUrban: false,
    },
    {
      name: 'GRINDASI',
      zip: '105810',
      isUrban: false,
    },
    {
      name: 'VLADENI',
      zip: '94802',
      isUrban: false,
    },
    {
      name: 'REVIGA',
      zip: '94278',
      isUrban: false,
    }
  ],
  IS: [
    {
      name: 'IASI',
      zip: '95079',
      isUrban: true,
    },
    {
      name: 'PASCANI',
      zip: '95408',
      isUrban: true,
    },
    {
      name: 'BLAGESTI',
      zip: '95417',
      isUrban: true,
    },
    {
      name: 'BOSTENI',
      zip: '95426',
      isUrban: true,
    },
    {
      name: 'GASTESTI',
      zip: '95435',
      isUrban: true,
    },
    {
      name: 'LUNCA',
      zip: '95444',
      isUrban: true,
    },
    {
      name: 'SODOMENI',
      zip: '95453',
      isUrban: true,
    },
    {
      name: 'HARLAU',
      zip: '95364',
      isUrban: true,
    },
    {
      name: 'PARCOVACI',
      zip: '95373',
      isUrban: true,
    },
    {
      name: 'TARGU FRUMOS',
      zip: '95480',
      isUrban: true,
    },
    {
      name: 'ALEXANDRU I. CUZA',
      zip: '95621',
      isUrban: false,
    },
    {
      name: 'KOGALNICENI',
      zip: '95630',
      isUrban: false,
    },
    {
      name: 'SCHEIA',
      zip: '95649',
      isUrban: false,
    },
    {
      name: 'VOLINTIRESTI',
      zip: '95658',
      isUrban: false,
    },
    {
      name: 'ANDRIESENI',
      zip: '95676',
      isUrban: false,
    },
    {
      name: 'BUHAENI',
      zip: '95685',
      isUrban: false,
    },
    {
      name: 'IEPURENI',
      zip: '95729',
      isUrban: false,
    },
    {
      name: 'SPINENI',
      zip: '95738',
      isUrban: false,
    },
    {
      name: 'ARONEANU',
      zip: '95756',
      isUrban: false,
    },
    {
      name: 'DOROBANT',
      zip: '95765',
      isUrban: false,
    },
    {
      name: 'REDIU ALDEI',
      zip: '95774',
      isUrban: false,
    },
    {
      name: 'SOROGARI',
      zip: '95783',
      isUrban: false,
    },
    {
      name: 'BALTATI',
      zip: '95809',
      isUrban: false,
    },
    {
      name: 'COTARGACI',
      zip: '95818',
      isUrban: false,
    },
    {
      name: 'FILIASI',
      zip: '95827',
      isUrban: false,
    },
    {
      name: 'MADARJESTI',
      zip: '95836',
      isUrban: false,
    },
    {
      name: 'PODISU',
      zip: '95845',
      isUrban: false,
    },
    {
      name: 'SARCA',
      zip: '95854',
      isUrban: false,
    },
    {
      name: 'VALEA OILOR',
      zip: '95863',
      isUrban: false,
    },
    {
      name: 'BARNOVA',
      zip: '95097',
      isUrban: false,
    },
    {
      name: 'CERCU',
      zip: '95104',
      isUrban: false,
    },
    {
      name: 'PAUN',
      zip: '95113',
      isUrban: false,
    },
    {
      name: 'PIETRARIA',
      zip: '95122',
      isUrban: false,
    },
    {
      name: 'TODIREL',
      zip: '95131',
      isUrban: false,
    },
    {
      name: 'DRAGANESTI',
      zip: '95694',
      isUrban: false,
    },
    {
      name: 'FANTANELE',
      zip: '95701',
      isUrban: false,
    },
    {
      name: 'GLAVANESTI',
      zip: '95710',
      isUrban: false,
    },
    {
      name: 'BOHOTIN',
      zip: '98701',
      isUrban: false,
    },
    {
      name: 'ISAIIA',
      zip: '98710',
      isUrban: false,
    },
    {
      name: 'ROSU',
      zip: '98729',
      isUrban: false,
    },
    {
      name: 'REDIU',
      zip: '95248',
      isUrban: false,
    },
    {
      name: 'BREAZU',
      zip: '95257',
      isUrban: false,
    },
    {
      name: 'VISAN',
      zip: '95140',
      isUrban: false,
    },
    {
      name: 'BELCESTI',
      zip: '95881',
      isUrban: false,
    },
    {
      name: 'LITENI',
      zip: '95890',
      isUrban: false,
    },
    {
      name: 'MUNTENI',
      zip: '95907',
      isUrban: false,
    },
    {
      name: 'SATU NOU',
      zip: '95916',
      isUrban: false,
    },
    {
      name: 'TANSA',
      zip: '95925',
      isUrban: false,
    },
    {
      name: 'ULMI',
      zip: '95934',
      isUrban: false,
    },
    {
      name: 'BIVOLARI',
      zip: '95952',
      isUrban: false,
    },
    {
      name: 'BURUIENESTI',
      zip: '95961',
      isUrban: false,
    },
    {
      name: 'SOLONET',
      zip: '95970',
      isUrban: false,
    },
    {
      name: 'TABARA',
      zip: '95989',
      isUrban: false,
    },
    {
      name: 'TRAIAN',
      zip: '95998',
      isUrban: false,
    },
    {
      name: 'BRAESTI',
      zip: '96067',
      isUrban: false,
    },
    {
      name: 'ALBESTI',
      zip: '96076',
      isUrban: false,
    },
    {
      name: 'BUDA',
      zip: '96085',
      isUrban: false,
    },
    {
      name: 'CRISTESTI',
      zip: '96094',
      isUrban: false,
    },
    {
      name: 'REDIU',
      zip: '96101',
      isUrban: false,
    },
    {
      name: 'BUTEA',
      zip: '96129',
      isUrban: false,
    },
    {
      name: 'MICLAUSENI',
      zip: '96138',
      isUrban: false,
    },
    {
      name: 'CEPLENITA',
      zip: '96156',
      isUrban: false,
    },
    {
      name: 'BUHALNITA',
      zip: '96165',
      isUrban: false,
    },
    {
      name: 'POIANA MARULUI',
      zip: '96174',
      isUrban: false,
    },
    {
      name: 'ZLODICA',
      zip: '96183',
      isUrban: false,
    },
    {
      name: 'CIORTESTI',
      zip: '96209',
      isUrban: false,
    },
    {
      name: 'COROPCENI',
      zip: '96218',
      isUrban: false,
    },
    {
      name: 'DELENI',
      zip: '96227',
      isUrban: false,
    },
    {
      name: 'ROTARIA',
      zip: '96236',
      isUrban: false,
    },
    {
      name: 'SERBESTI',
      zip: '96245',
      isUrban: false,
    },
    {
      name: 'CIUREA',
      zip: '96263',
      isUrban: false,
    },
    {
      name: 'CURATURI',
      zip: '96272',
      isUrban: false,
    },
    {
      name: 'DUMBRAVA',
      zip: '96281',
      isUrban: false,
    },
    {
      name: 'HLINCEA',
      zip: '96290',
      isUrban: false,
    },
    {
      name: 'LUNCA CETATUII',
      zip: '96307',
      isUrban: false,
    },
    {
      name: 'PICIORU LUPULUI',
      zip: '96316',
      isUrban: false,
    },
    {
      name: 'SLOBOZIA',
      zip: '96325',
      isUrban: false,
    },
    {
      name: 'COARNELE CAPREI',
      zip: '96343',
      isUrban: false,
    },
    {
      name: 'ARAMA',
      zip: '96352',
      isUrban: false,
    },
    {
      name: 'PETROSICA',
      zip: '96361',
      isUrban: false,
    },
    {
      name: 'OSOI',
      zip: '96389',
      isUrban: false,
    },
    {
      name: 'COMARNA',
      zip: '96398',
      isUrban: false,
    },
    {
      name: 'CURAGAU',
      zip: '96405',
      isUrban: false,
    },
    {
      name: 'STANCA',
      zip: '96414',
      isUrban: false,
    },
    {
      name: 'COSTULENI',
      zip: '96432',
      isUrban: false,
    },
    {
      name: 'COVASNA',
      zip: '96441',
      isUrban: false,
    },
    {
      name: 'COZIA',
      zip: '96450',
      isUrban: false,
    },
    {
      name: 'HILITA',
      zip: '96469',
      isUrban: false,
    },
    {
      name: 'COTNARI',
      zip: '96487',
      isUrban: false,
    },
    {
      name: 'BAHLUIU',
      zip: '96496',
      isUrban: false,
    },
    {
      name: 'CARJOAIA',
      zip: '96511',
      isUrban: false,
    },
    {
      name: 'CIRESENI',
      zip: '96502',
      isUrban: false,
    },
    {
      name: 'FAGAT',
      zip: '96520',
      isUrban: false,
    },
    {
      name: 'HODORA',
      zip: '96539',
      isUrban: false,
    },
    {
      name: 'HORODISTEA',
      zip: '96548',
      isUrban: false,
    },
    {
      name: 'IOSUPENI',
      zip: '96557',
      isUrban: false,
    },
    {
      name: 'LUPARIA',
      zip: '96566',
      isUrban: false,
    },
    {
      name: 'VALEA RACULUI',
      zip: '96575',
      isUrban: false,
    },
    {
      name: 'ZBERENI',
      zip: '96584',
      isUrban: false,
    },
    {
      name: 'COZMESTI',
      zip: '96600',
      isUrban: false,
    },
    {
      name: 'PODOLENII DE JOS',
      zip: '96619',
      isUrban: false,
    },
    {
      name: 'PODOLENII DE SUS',
      zip: '96628',
      isUrban: false,
    },
    {
      name: 'CRISTESTI',
      zip: '96646',
      isUrban: false,
    },
    {
      name: 'HOMITA',
      zip: '96655',
      isUrban: false,
    },
    {
      name: 'CUCUTENI',
      zip: '96673',
      isUrban: false,
    },
    {
      name: 'BAICENI',
      zip: '96682',
      isUrban: false,
    },
    {
      name: 'BARBATESTI',
      zip: '96691',
      isUrban: false,
    },
    {
      name: 'SACARESTI',
      zip: '96708',
      isUrban: false,
    },
    {
      name: 'DAGATA',
      zip: '96726',
      isUrban: false,
    },
    {
      name: 'BALUSESTI',
      zip: '96735',
      isUrban: false,
    },
    {
      name: 'BOATCA',
      zip: '96744',
      isUrban: false,
    },
    {
      name: 'BUZDUG',
      zip: '96753',
      isUrban: false,
    },
    {
      name: 'MANASTIREA',
      zip: '96762',
      isUrban: false,
    },
    {
      name: 'PISCU RUSULUI',
      zip: '96771',
      isUrban: false,
    },
    {
      name: 'POIENILE',
      zip: '96780',
      isUrban: false,
    },
    {
      name: 'TARNITA',
      zip: '96799',
      isUrban: false,
    },
    {
      name: 'ZECE PRAJINI',
      zip: '96806',
      isUrban: false,
    },
    {
      name: 'DELENI',
      zip: '96824',
      isUrban: false,
    },
    {
      name: 'FEREDENI',
      zip: '96833',
      isUrban: false,
    },
    {
      name: 'LEAHU-NACU',
      zip: '96842',
      isUrban: false,
    },
    {
      name: 'MAXUT',
      zip: '96851',
      isUrban: false,
    },
    {
      name: 'POIANA',
      zip: '96860',
      isUrban: false,
    },
    {
      name: 'SLOBOZIA',
      zip: '96879',
      isUrban: false,
    },
    {
      name: 'DOBROVAT',
      zip: '96897',
      isUrban: false,
    },
    {
      name: 'DOLHESTI',
      zip: '96913',
      isUrban: false,
    },
    {
      name: 'BRADICESTI',
      zip: '96922',
      isUrban: false,
    },
    {
      name: 'PIETRIS',
      zip: '96931',
      isUrban: false,
    },
    {
      name: 'DUMESTI',
      zip: '96959',
      isUrban: false,
    },
    {
      name: 'BANU',
      zip: '96968',
      isUrban: false,
    },
    {
      name: 'CHILISOAIA',
      zip: '96977',
      isUrban: false,
    },
    {
      name: 'HOISESTI',
      zip: '96986',
      isUrban: false,
    },
    {
      name: 'PAUSESTI',
      zip: '96995',
      isUrban: false,
    },
    {
      name: 'ERBICENI',
      zip: '97018',
      isUrban: false,
    },
    {
      name: 'BARLESTI',
      zip: '97027',
      isUrban: false,
    },
    {
      name: 'SPINOASA',
      zip: '97036',
      isUrban: false,
    },
    {
      name: 'SPRANCEANA',
      zip: '97045',
      isUrban: false,
    },
    {
      name: 'TOTOESTI',
      zip: '97054',
      isUrban: false,
    },
    {
      name: 'FOCURI',
      zip: '97072',
      isUrban: false,
    },
    {
      name: 'FANTANELE',
      zip: '97081',
      isUrban: false,
    },
    {
      name: 'GOLAIESTI',
      zip: '97107',
      isUrban: false,
    },
    {
      name: 'BRAN',
      zip: '97116',
      isUrban: false,
    },
    {
      name: 'CILIBIU',
      zip: '97125',
      isUrban: false,
    },
    {
      name: 'COTU LUI IVAN',
      zip: '97134',
      isUrban: false,
    },
    {
      name: 'GRADINARI',
      zip: '97143',
      isUrban: false,
    },
    {
      name: 'MEDELENI',
      zip: '97152',
      isUrban: false,
    },
    {
      name: 'PETRESTI',
      zip: '97161',
      isUrban: false,
    },
    {
      name: 'PODU JIJIEI',
      zip: '97170',
      isUrban: false,
    },
    {
      name: 'GORBAN',
      zip: '97198',
      isUrban: false,
    },
    {
      name: 'GURA BOHOTIN',
      zip: '97205',
      isUrban: false,
    },
    {
      name: 'PODU HAGIULUI',
      zip: '97214',
      isUrban: false,
    },
    {
      name: 'SCOPOSENI',
      zip: '97223',
      isUrban: false,
    },
    {
      name: 'ZBEROAIA',
      zip: '97232',
      isUrban: false,
    },
    {
      name: 'GRAJDURI',
      zip: '97250',
      isUrban: false,
    },
    {
      name: 'CARBUNARI',
      zip: '97269',
      isUrban: false,
    },
    {
      name: 'CORCODEL',
      zip: '97278',
      isUrban: false,
    },
    {
      name: 'LUNCA',
      zip: '97287',
      isUrban: false,
    },
    {
      name: 'PADURENI',
      zip: '97296',
      isUrban: false,
    },
    {
      name: 'POIANA CU CETATE',
      zip: '97303',
      isUrban: false,
    },
    {
      name: 'VALEA SATULUI',
      zip: '97312',
      isUrban: false,
    },
    {
      name: 'GROPNITA',
      zip: '97330',
      isUrban: false,
    },
    {
      name: 'BULBUCANI',
      zip: '97349',
      isUrban: false,
    },
    {
      name: 'FORASTI',
      zip: '97358',
      isUrban: false,
    },
    {
      name: 'MALAESTI',
      zip: '97367',
      isUrban: false,
    },
    {
      name: 'SANGERI',
      zip: '97385',
      isUrban: false,
    },
    {
      name: 'SAVENI',
      zip: '97376',
      isUrban: false,
    },
    {
      name: 'GROZESTI',
      zip: '97401',
      isUrban: false,
    },
    {
      name: 'COLTU CORNII',
      zip: '97410',
      isUrban: false,
    },
    {
      name: 'SALAGENI',
      zip: '97429',
      isUrban: false,
    },
    {
      name: 'HALAUCESTI',
      zip: '97447',
      isUrban: false,
    },
    {
      name: 'LUNCASI',
      zip: '97456',
      isUrban: false,
    },
    {
      name: 'HELESTENI',
      zip: '97474',
      isUrban: false,
    },
    {
      name: 'HARMANEASA',
      zip: '97483',
      isUrban: false,
    },
    {
      name: 'MOVILENI',
      zip: '97492',
      isUrban: false,
    },
    {
      name: 'OBOROCENI',
      zip: '97508',
      isUrban: false,
    },
    {
      name: 'HOLBOCA',
      zip: '95168',
      isUrban: false,
    },
    {
      name: 'CRISTESTI',
      zip: '95177',
      isUrban: false,
    },
    {
      name: 'DANCU',
      zip: '95186',
      isUrban: false,
    },
    {
      name: 'ORZENI',
      zip: '95195',
      isUrban: false,
    },
    {
      name: 'RUSENII NOI',
      zip: '95202',
      isUrban: false,
    },
    {
      name: 'RUSENII VECHI',
      zip: '95211',
      isUrban: false,
    },
    {
      name: 'VALEA LUNGA',
      zip: '95220',
      isUrban: false,
    },
    {
      name: 'HORLESTI',
      zip: '97526',
      isUrban: false,
    },
    {
      name: 'BOGDANESTI',
      zip: '97535',
      isUrban: false,
    },
    {
      name: 'SCOPOSENI',
      zip: '97544',
      isUrban: false,
    },
    {
      name: 'IPATELE',
      zip: '97562',
      isUrban: false,
    },
    {
      name: 'ALEXESTI',
      zip: '97571',
      isUrban: false,
    },
    {
      name: 'BACU',
      zip: '97580',
      isUrban: false,
    },
    {
      name: 'CUZA VODA',
      zip: '97599',
      isUrban: false,
    },
    {
      name: 'LESPEZI',
      zip: '97615',
      isUrban: false,
    },
    {
      name: 'BUDA',
      zip: '97624',
      isUrban: false,
    },
    {
      name: 'BURSUC-DEAL',
      zip: '97633',
      isUrban: false,
    },
    {
      name: 'BURSUC-VALE',
      zip: '97642',
      isUrban: false,
    },
    {
      name: 'DUMBRAVA',
      zip: '97651',
      isUrban: false,
    },
    {
      name: 'HECI',
      zip: '97660',
      isUrban: false,
    },
    {
      name: 'LETCANI',
      zip: '97688',
      isUrban: false,
    },
    {
      name: 'BOGONOS',
      zip: '97697',
      isUrban: false,
    },
    {
      name: 'COGEASCA',
      zip: '97704',
      isUrban: false,
    },
    {
      name: 'CUCUTENI',
      zip: '97713',
      isUrban: false,
    },
    {
      name: 'LUNGANI',
      zip: '97731',
      isUrban: false,
    },
    {
      name: 'CRUCEA',
      zip: '97740',
      isUrban: false,
    },
    {
      name: 'GOESTI',
      zip: '97759',
      isUrban: false,
    },
    {
      name: 'ZMEU',
      zip: '97768',
      isUrban: false,
    },
    {
      name: 'MADARJAC',
      zip: '97786',
      isUrban: false,
    },
    {
      name: 'BOJILA',
      zip: '97795',
      isUrban: false,
    },
    {
      name: 'FRUMUSICA',
      zip: '97802',
      isUrban: false,
    },
    {
      name: 'MIRCESTI',
      zip: '97820',
      isUrban: false,
    },
    {
      name: 'IUGANI',
      zip: '97839',
      isUrban: false,
    },
    {
      name: 'IZVOARELE',
      zip: '97848',
      isUrban: false,
    },
    {
      name: 'RACHITENI',
      zip: '97857',
      isUrban: false,
    },
    {
      name: 'URSARESTI',
      zip: '97866',
      isUrban: false,
    },
    {
      name: 'MIRONEASA',
      zip: '97884',
      isUrban: false,
    },
    {
      name: 'SCHITU HADAMBULUI',
      zip: '97893',
      isUrban: false,
    },
    {
      name: 'URSITA',
      zip: '97900',
      isUrban: false,
    },
    {
      name: 'MIROSLAVA',
      zip: '97928',
      isUrban: false,
    },
    {
      name: 'BALCIU',
      zip: '97937',
      isUrban: false,
    },
    {
      name: 'BRATULENI',
      zip: '97946',
      isUrban: false,
    },
    {
      name: 'CIURBESTI',
      zip: '97955',
      isUrban: false,
    },
    {
      name: 'CORNESTI',
      zip: '97964',
      isUrban: false,
    },
    {
      name: 'DANCAS',
      zip: '97973',
      isUrban: false,
    },
    {
      name: 'GAURENI',
      zip: '97982',
      isUrban: false,
    },
    {
      name: 'HORPAZ',
      zip: '97991',
      isUrban: false,
    },
    {
      name: 'PROSELNICI',
      zip: '98006',
      isUrban: false,
    },
    {
      name: 'URICANI',
      zip: '98015',
      isUrban: false,
    },
    {
      name: 'VALEA ADANCA',
      zip: '98024',
      isUrban: false,
    },
    {
      name: 'VALEA URSULUI',
      zip: '98033',
      isUrban: false,
    },
    {
      name: 'VOROVESTI',
      zip: '98042',
      isUrban: false,
    },
    {
      name: 'MIROSLOVESTI',
      zip: '98060',
      isUrban: false,
    },
    {
      name: 'CIOHORANI',
      zip: '98079',
      isUrban: false,
    },
    {
      name: 'MITESTI',
      zip: '98088',
      isUrban: false,
    },
    {
      name: 'SOCI',
      zip: '98097',
      isUrban: false,
    },
    {
      name: 'VERSENI',
      zip: '98104',
      isUrban: false,
    },
    {
      name: 'MOGOSESTI',
      zip: '98122',
      isUrban: false,
    },
    {
      name: 'BUDESTI',
      zip: '98131',
      isUrban: false,
    },
    {
      name: 'HADAMBU',
      zip: '98140',
      isUrban: false,
    },
    {
      name: 'MANJESTI',
      zip: '98159',
      isUrban: false,
    },
    {
      name: 'MOGOSESTI-SIRET',
      zip: '98177',
      isUrban: false,
    },
    {
      name: 'MUNCELU DE SUS',
      zip: '98186',
      isUrban: false,
    },
    {
      name: 'TUDOR VLADIMIRESCU',
      zip: '98195',
      isUrban: false,
    },
    {
      name: 'MOSNA',
      zip: '98211',
      isUrban: false,
    },
    {
      name: 'MOTCA',
      zip: '98239',
      isUrban: false,
    },
    {
      name: 'BOURENI',
      zip: '98248',
      isUrban: false,
    },
    {
      name: 'MOVILENI',
      zip: '98266',
      isUrban: false,
    },
    {
      name: 'IEPURENI',
      zip: '98275',
      isUrban: false,
    },
    {
      name: 'LARGA-JIJIA',
      zip: '98284',
      isUrban: false,
    },
    {
      name: 'POTANGENI',
      zip: '98293',
      isUrban: false,
    },
    {
      name: 'OTELENI',
      zip: '98319',
      isUrban: false,
    },
    {
      name: 'HANDRESTI',
      zip: '98328',
      isUrban: false,
    },
    {
      name: 'PLUGARI',
      zip: '98346',
      isUrban: false,
    },
    {
      name: 'BOROSOAIA',
      zip: '98355',
      isUrban: false,
    },
    {
      name: 'ONESTI',
      zip: '98364',
      isUrban: false,
    },
    {
      name: 'PODU ILOAIEI',
      zip: '98382',
      isUrban: true,
    },
    {
      name: 'BUDAI',
      zip: '98391',
      isUrban: true,
    },
    {
      name: 'COSITENI',
      zip: '98408',
      isUrban: true,
    },
    {
      name: 'HOLM',
      zip: '98417',
      isUrban: true,
    },
    {
      name: 'SCOBALTENI',
      zip: '98426',
      isUrban: true,
    },
    {
      name: 'POPESTI',
      zip: '98444',
      isUrban: false,
    },
    {
      name: 'DOROSCANI',
      zip: '98453',
      isUrban: false,
    },
    {
      name: 'HARPASESTI',
      zip: '98462',
      isUrban: false,
    },
    {
      name: 'OBRIJENI',
      zip: '98471',
      isUrban: false,
    },
    {
      name: 'PADURENI',
      zip: '98480',
      isUrban: false,
    },
    {
      name: 'VAMA',
      zip: '98499',
      isUrban: false,
    },
    {
      name: 'POPRICANI',
      zip: '98514',
      isUrban: false,
    },
    {
      name: 'CARLIG',
      zip: '98523',
      isUrban: false,
    },
    {
      name: 'COTU MORII',
      zip: '98532',
      isUrban: false,
    },
    {
      name: 'CUZA VODA',
      zip: '98541',
      isUrban: false,
    },
    {
      name: 'MOIMESTI',
      zip: '98550',
      isUrban: false,
    },
    {
      name: 'REDIU MITROPOLIEI',
      zip: '98569',
      isUrban: false,
    },
    {
      name: 'TIPILESTI',
      zip: '98578',
      isUrban: false,
    },
    {
      name: 'VANATORI',
      zip: '98587',
      isUrban: false,
    },
    {
      name: 'VULTURI',
      zip: '98596',
      isUrban: false,
    },
    {
      name: 'PRISACANI',
      zip: '98612',
      isUrban: false,
    },
    {
      name: 'MACARESTI',
      zip: '98621',
      isUrban: false,
    },
    {
      name: 'MORENI',
      zip: '98630',
      isUrban: false,
    },
    {
      name: 'PROBOTA',
      zip: '98658',
      isUrban: false,
    },
    {
      name: 'BALTENI',
      zip: '98667',
      isUrban: false,
    },
    {
      name: 'PERIENI',
      zip: '98676',
      isUrban: false,
    },
    {
      name: 'RADUCANENI',
      zip: '98694',
      isUrban: false,
    },
    {
      name: 'HORLESTI',
      zip: '95266',
      isUrban: false,
    },
    {
      name: 'TAUTESTI',
      zip: '95275',
      isUrban: false,
    },
    {
      name: 'VALEA LUPULUI',
      zip: '95284',
      isUrban: false,
    },
    {
      name: 'ROMANESTI',
      zip: '98747',
      isUrban: false,
    },
    {
      name: 'AVANTU',
      zip: '98756',
      isUrban: false,
    },
    {
      name: 'URSOAIA',
      zip: '98765',
      isUrban: false,
    },
    {
      name: 'RUGINOASA',
      zip: '98783',
      isUrban: false,
    },
    {
      name: 'DUMBRAVITA',
      zip: '98792',
      isUrban: false,
    },
    {
      name: 'REDIU',
      zip: '98809',
      isUrban: false,
    },
    {
      name: 'VASCANI',
      zip: '98818',
      isUrban: false,
    },
    {
      name: 'SCANTEIA',
      zip: '98925',
      isUrban: false,
    },
    {
      name: 'BODESTI',
      zip: '98934',
      isUrban: false,
    },
    {
      name: 'BOROSESTI',
      zip: '98943',
      isUrban: false,
    },
    {
      name: 'CIOCARLESTI',
      zip: '98952',
      isUrban: false,
    },
    {
      name: 'LUNCA RATES',
      zip: '98961',
      isUrban: false,
    },
    {
      name: 'REDIU',
      zip: '98970',
      isUrban: false,
    },
    {
      name: 'TUFESTII DE SUS',
      zip: '98989',
      isUrban: false,
    },
    {
      name: 'SCHEIA',
      zip: '99307',
      isUrban: false,
    },
    {
      name: 'CAUESTI',
      zip: '99316',
      isUrban: false,
    },
    {
      name: 'CIOCA-BOCA',
      zip: '99325',
      isUrban: false,
    },
    {
      name: 'DRAGUSENI',
      zip: '99334',
      isUrban: false,
    },
    {
      name: 'FRENCIUGI',
      zip: '99343',
      isUrban: false,
    },
    {
      name: 'POIANA SCHEII',
      zip: '99352',
      isUrban: false,
    },
    {
      name: 'SATU NOU',
      zip: '99361',
      isUrban: false,
    },
    {
      name: 'SCHITU DUCA',
      zip: '98836',
      isUrban: false,
    },
    {
      name: 'BLAGA',
      zip: '98845',
      isUrban: false,
    },
    {
      name: 'DUMITRESTII GALATII',
      zip: '98854',
      isUrban: false,
    },
    {
      name: 'POCREACA',
      zip: '98863',
      isUrban: false,
    },
    {
      name: 'POIANA',
      zip: '98881',
      isUrban: false,
    },
    {
      name: 'POIENI',
      zip: '98872',
      isUrban: false,
    },
    {
      name: 'SATU NOU',
      zip: '98890',
      isUrban: false,
    },
    {
      name: 'SLOBOZIA',
      zip: '98907',
      isUrban: false,
    },
    {
      name: 'SCOBINTI',
      zip: '99003',
      isUrban: false,
    },
    {
      name: 'BADENI',
      zip: '99012',
      isUrban: false,
    },
    {
      name: 'FETESTI',
      zip: '99021',
      isUrban: false,
    },
    {
      name: 'STICLARIA',
      zip: '99030',
      isUrban: false,
    },
    {
      name: 'ZAGAVIA',
      zip: '99049',
      isUrban: false,
    },
    {
      name: 'SINESTI',
      zip: '99067',
      isUrban: false,
    },
    {
      name: 'BOCNITA',
      zip: '99076',
      isUrban: false,
    },
    {
      name: 'OSOI',
      zip: '99085',
      isUrban: false,
    },
    {
      name: 'STORNESTI',
      zip: '99094',
      isUrban: false,
    },
    {
      name: 'SIPOTE',
      zip: '99389',
      isUrban: false,
    },
    {
      name: 'CHISCARENI',
      zip: '99398',
      isUrban: false,
    },
    {
      name: 'HALCENI',
      zip: '99405',
      isUrban: false,
    },
    {
      name: 'IAZU NOU',
      zip: '99414',
      isUrban: false,
    },
    {
      name: 'IAZU VECHI',
      zip: '99423',
      isUrban: false,
    },
    {
      name: 'MITOC',
      zip: '99432',
      isUrban: false,
    },
    {
      name: 'SIRETEL',
      zip: '99110',
      isUrban: false,
    },
    {
      name: 'BEREZLOGI',
      zip: '99129',
      isUrban: false,
    },
    {
      name: 'HUMOSU',
      zip: '99138',
      isUrban: false,
    },
    {
      name: 'SATU NOU',
      zip: '99147',
      isUrban: false,
    },
    {
      name: 'SLOBOZIA',
      zip: '99156',
      isUrban: false,
    },
    {
      name: 'STOLNICENI-PRAJESCU',
      zip: '99174',
      isUrban: false,
    },
    {
      name: 'BRATESTI',
      zip: '99183',
      isUrban: false,
    },
    {
      name: 'COZMESTI',
      zip: '99192',
      isUrban: false,
    },
    {
      name: 'STRUNGA',
      zip: '99218',
      isUrban: false,
    },
    {
      name: 'BRATULESTI',
      zip: '99227',
      isUrban: false,
    },
    {
      name: 'CRIVESTI',
      zip: '99236',
      isUrban: false,
    },
    {
      name: 'CUCOVA',
      zip: '99245',
      isUrban: false,
    },
    {
      name: 'FARCASENI',
      zip: '99254',
      isUrban: false,
    },
    {
      name: 'FEDELESENI',
      zip: '99263',
      isUrban: false,
    },
    {
      name: 'GURA VAII',
      zip: '99272',
      isUrban: false,
    },
    {
      name: 'HABASESTI',
      zip: '99281',
      isUrban: false,
    },
    {
      name: 'TANSA',
      zip: '99450',
      isUrban: false,
    },
    {
      name: 'SUHULET',
      zip: '99469',
      isUrban: false,
    },
    {
      name: 'BALS',
      zip: '95505',
      isUrban: false,
    },
    {
      name: 'BOURENI',
      zip: '95514',
      isUrban: false,
    },
    {
      name: 'BUZNEA',
      zip: '95523',
      isUrban: false,
    },
    {
      name: 'COASTA MAGURII',
      zip: '95532',
      isUrban: false,
    },
    {
      name: 'COSTESTI',
      zip: '95541',
      isUrban: false,
    },
    {
      name: 'DADESTI',
      zip: '95550',
      isUrban: false,
    },
    {
      name: 'GANESTI',
      zip: '95569',
      isUrban: false,
    },
    {
      name: 'GIURGESTI',
      zip: '95578',
      isUrban: false,
    },
    {
      name: 'ION NECULCE',
      zip: '95587',
      isUrban: false,
    },
    {
      name: 'PRIGORENI',
      zip: '95596',
      isUrban: false,
    },
    {
      name: 'RAZBOIENI',
      zip: '95603',
      isUrban: false,
    },
    {
      name: 'TATARUSI',
      zip: '99487',
      isUrban: false,
    },
    {
      name: 'IORCANI',
      zip: '99496',
      isUrban: false,
    },
    {
      name: 'PIETROSU',
      zip: '99502',
      isUrban: false,
    },
    {
      name: 'UDA',
      zip: '99511',
      isUrban: false,
    },
    {
      name: 'VALCICA',
      zip: '99520',
      isUrban: false,
    },
    {
      name: 'TIBANA',
      zip: '99682',
      isUrban: false,
    },
    {
      name: 'ALEXENI',
      zip: '99691',
      isUrban: false,
    },
    {
      name: 'DOMNITA',
      zip: '99708',
      isUrban: false,
    },
    {
      name: 'GARBESTI',
      zip: '99717',
      isUrban: false,
    },
    {
      name: 'MOARA CIORNEI',
      zip: '99726',
      isUrban: false,
    },
    {
      name: 'OPROAIA',
      zip: '99735',
      isUrban: false,
    },
    {
      name: 'POIANA DE SUS',
      zip: '99744',
      isUrban: false,
    },
    {
      name: 'POIANA MANASTIRII',
      zip: '99753',
      isUrban: false,
    },
    {
      name: 'RUNCU',
      zip: '99762',
      isUrban: false,
    },
    {
      name: 'VADU VEJEI',
      zip: '99771',
      isUrban: false,
    },
    {
      name: 'TIBANESTI',
      zip: '99799',
      isUrban: false,
    },
    {
      name: 'GLODENII GINDULUI',
      zip: '99806',
      isUrban: false,
    },
    {
      name: 'GRIESTI',
      zip: '99815',
      isUrban: false,
    },
    {
      name: 'JIGORENI',
      zip: '99824',
      isUrban: false,
    },
    {
      name: 'RASBOIENI',
      zip: '99833',
      isUrban: false,
    },
    {
      name: 'RECEA',
      zip: '99842',
      isUrban: false,
    },
    {
      name: 'TUNGUJEI',
      zip: '99851',
      isUrban: false,
    },
    {
      name: 'VALENII',
      zip: '99860',
      isUrban: false,
    },
    {
      name: 'TIGANASI',
      zip: '99888',
      isUrban: false,
    },
    {
      name: 'CARNICENI',
      zip: '99897',
      isUrban: false,
    },
    {
      name: 'MIHAIL KOGALNICEANU',
      zip: '99904',
      isUrban: false,
    },
    {
      name: 'STEJARII',
      zip: '99913',
      isUrban: false,
    },
    {
      name: 'TODIRESTI',
      zip: '99548',
      isUrban: false,
    },
    {
      name: 'BAICENI',
      zip: '99557',
      isUrban: false,
    },
    {
      name: 'BOLDESTI',
      zip: '99566',
      isUrban: false,
    },
    {
      name: 'HARMANESTII NOI',
      zip: '99575',
      isUrban: false,
    },
    {
      name: 'HARMANESTII VECHI',
      zip: '99584',
      isUrban: false,
    },
    {
      name: 'STROESTI',
      zip: '99593',
      isUrban: false,
    },
    {
      name: 'TOMESTI',
      zip: '95300',
      isUrban: false,
    },
    {
      name: 'CHICEREA',
      zip: '95319',
      isUrban: false,
    },
    {
      name: 'GORUNI',
      zip: '95328',
      isUrban: false,
    },
    {
      name: 'VLADICENI',
      zip: '95337',
      isUrban: false,
    },
    {
      name: 'TRIFESTI',
      zip: '99619',
      isUrban: false,
    },
    {
      name: 'HERMEZIU',
      zip: '99628',
      isUrban: false,
    },
    {
      name: 'RADENI',
      zip: '99637',
      isUrban: false,
    },
    {
      name: 'ROSCANI',
      zip: '99646',
      isUrban: false,
    },
    {
      name: 'VLADOMIRA',
      zip: '99655',
      isUrban: false,
    },
    {
      name: 'ZABOLOTENI',
      zip: '99664',
      isUrban: false,
    },
    {
      name: 'TUTORA',
      zip: '99931',
      isUrban: false,
    },
    {
      name: 'CHIPERESTI',
      zip: '99940',
      isUrban: false,
    },
    {
      name: 'OPRISENI',
      zip: '99959',
      isUrban: false,
    },
    {
      name: 'BOSIA',
      zip: '96012',
      isUrban: false,
    },
    {
      name: 'COADA STANCII',
      zip: '96021',
      isUrban: false,
    },
    {
      name: 'MANZATESTI',
      zip: '96030',
      isUrban: false,
    },
    {
      name: 'UNGHENI',
      zip: '96049',
      isUrban: false,
    },
    {
      name: 'VALEA SEACA',
      zip: '99977',
      isUrban: false,
    },
    {
      name: 'CONTESTI',
      zip: '99986',
      isUrban: false,
    },
    {
      name: 'TOPILE',
      zip: '99995',
      isUrban: false,
    },
    {
      name: 'VANATORI',
      zip: '100095',
      isUrban: false,
    },
    {
      name: 'CRIVESTI',
      zip: '100102',
      isUrban: false,
    },
    {
      name: 'GURA BADILITEI',
      zip: '100111',
      isUrban: false,
    },
    {
      name: 'HARTOAPE',
      zip: '100120',
      isUrban: false,
    },
    {
      name: 'VLADNICUT',
      zip: '100139',
      isUrban: false,
    },
    {
      name: 'VICTORIA',
      zip: '100013',
      isUrban: false,
    },
    {
      name: 'FRASULENI',
      zip: '100022',
      isUrban: false,
    },
    {
      name: 'ICUSENI',
      zip: '100031',
      isUrban: false,
    },
    {
      name: 'LUCENI',
      zip: '100040',
      isUrban: false,
    },
    {
      name: 'SCULENI',
      zip: '100059',
      isUrban: false,
    },
    {
      name: 'SENDRENI',
      zip: '100077',
      isUrban: false,
    },
    {
      name: 'STANCA',
      zip: '100068',
      isUrban: false,
    },
    {
      name: 'VLADENI',
      zip: '100157',
      isUrban: false,
    },
    {
      name: 'ALEXANDRU CEL BUN',
      zip: '100166',
      isUrban: false,
    },
    {
      name: 'BORSA',
      zip: '100175',
      isUrban: false,
    },
    {
      name: 'BROSTENI',
      zip: '100184',
      isUrban: false,
    },
    {
      name: 'IACOBENI',
      zip: '100193',
      isUrban: false,
    },
    {
      name: 'VALCELELE',
      zip: '100200',
      isUrban: false,
    },
    {
      name: 'VOINESTI',
      zip: '100228',
      isUrban: false,
    },
    {
      name: 'LUNGANI',
      zip: '100237',
      isUrban: false,
    },
    {
      name: 'SCHITU STAVNIC',
      zip: '100246',
      isUrban: false,
    },
    {
      name: 'SLOBOZIA',
      zip: '100255',
      isUrban: false,
    },
    {
      name: 'VOCOTESTI',
      zip: '100264',
      isUrban: false,
    }
  ],
  IF: [
    {
      name: 'BUFTEA',
      zip: '100585',
      isUrban: true,
    },
    {
      name: 'BUCIUMENI',
      zip: '100594',
      isUrban: true,
    },
    {
      name: '1 DECEMBRIE',
      zip: '102552',
      isUrban: false,
    },
    {
      name: 'COPACENI',
      zip: '102561',
      isUrban: false,
    },
    {
      name: 'AFUMATI',
      zip: '100843',
      isUrban: false,
    },
    {
      name: 'BALOTESTI',
      zip: '100978',
      isUrban: false,
    },
    {
      name: 'DUMBRAVENI',
      zip: '100987',
      isUrban: false,
    },
    {
      name: 'SAFTICA',
      zip: '100996',
      isUrban: false,
    },
    {
      name: 'BERCENI',
      zip: '101154',
      isUrban: false,
    },
    {
      name: 'BRAGADIRU',
      zip: '179230',
      isUrban: true,
    },
    {
      name: 'BRANESTI',
      zip: '101305',
      isUrban: false,
    },
    {
      name: 'ISLAZ',
      zip: '101314',
      isUrban: false,
    },
    {
      name: 'PASAREA',
      zip: '101323',
      isUrban: false,
    },
    {
      name: 'VADU ANEI',
      zip: '101332',
      isUrban: false,
    },
    {
      name: 'CERNICA',
      zip: '101751',
      isUrban: false,
    },
    {
      name: 'BALACEANCA',
      zip: '101760',
      isUrban: false,
    },
    {
      name: 'CALDARARU',
      zip: '101779',
      isUrban: false,
    },
    {
      name: 'POSTA',
      zip: '101788',
      isUrban: false,
    },
    {
      name: 'TANGANU',
      zip: '101797',
      isUrban: false,
    },
    {
      name: 'CHIAJNA',
      zip: '179258',
      isUrban: false,
    },
    {
      name: 'DUDU',
      zip: '179267',
      isUrban: false,
    },
    {
      name: 'ROSU',
      zip: '179276',
      isUrban: false,
    },
    {
      name: 'CHITILA',
      zip: '179294',
      isUrban: true,
    },
    {
      name: 'RUDENI',
      zip: '179301',
      isUrban: true,
    },
    {
      name: 'CIOLPANI',
      zip: '101911',
      isUrban: false,
    },
    {
      name: 'IZVORANI',
      zip: '101920',
      isUrban: false,
    },
    {
      name: 'LUPARIA',
      zip: '101939',
      isUrban: false,
    },
    {
      name: 'PISCU',
      zip: '101948',
      isUrban: false,
    },
    {
      name: 'CIOROGARLA',
      zip: '101966',
      isUrban: false,
    },
    {
      name: 'DARVARI',
      zip: '101975',
      isUrban: false,
    },
    {
      name: 'CLINCENI',
      zip: '102044',
      isUrban: false,
    },
    {
      name: 'OLTENI',
      zip: '102053',
      isUrban: false,
    },
    {
      name: 'ORDOREANU',
      zip: '102062',
      isUrban: false,
    },
    {
      name: 'CORBEANCA',
      zip: '102179',
      isUrban: false,
    },
    {
      name: 'OSTRATU',
      zip: '102188',
      isUrban: false,
    },
    {
      name: 'PETRESTI',
      zip: '102197',
      isUrban: false,
    },
    {
      name: 'TAMASI',
      zip: '102204',
      isUrban: false,
    },
    {
      name: 'CORNETU',
      zip: '102222',
      isUrban: false,
    },
    {
      name: 'BUDA',
      zip: '102231',
      isUrban: false,
    },
    {
      name: 'DARASTI-ILFOV',
      zip: '102534',
      isUrban: false,
    },
    {
      name: 'DASCALU',
      zip: '102482',
      isUrban: false,
    },
    {
      name: 'CREATA',
      zip: '102491',
      isUrban: false,
    },
    {
      name: 'GAGU',
      zip: '102507',
      isUrban: false,
    },
    {
      name: 'RUNCU',
      zip: '102516',
      isUrban: false,
    },
    {
      name: 'DOBROESTI',
      zip: '179329',
      isUrban: false,
    },
    {
      name: 'FUNDENI',
      zip: '179338',
      isUrban: false,
    },
    {
      name: 'DOMNESTI',
      zip: '102589',
      isUrban: false,
    },
    {
      name: 'TEGHES',
      zip: '102598',
      isUrban: false,
    },
    {
      name: 'DRAGOMIRESTI-VALE',
      zip: '102614',
      isUrban: false,
    },
    {
      name: 'DRAGOMIRESTI-DEAL',
      zip: '102623',
      isUrban: false,
    },
    {
      name: 'ZURBAUA',
      zip: '102632',
      isUrban: false,
    },
    {
      name: 'GANEASA',
      zip: '103149',
      isUrban: false,
    },
    {
      name: 'COZIENI',
      zip: '103158',
      isUrban: false,
    },
    {
      name: 'MOARA DOMNEASCA',
      zip: '103167',
      isUrban: false,
    },
    {
      name: 'PITEASCA',
      zip: '103176',
      isUrban: false,
    },
    {
      name: 'SINDRILITA',
      zip: '103185',
      isUrban: false,
    },
    {
      name: 'GLINA',
      zip: '179356',
      isUrban: false,
    },
    {
      name: 'CATELU',
      zip: '179365',
      isUrban: false,
    },
    {
      name: 'MANOLACHE',
      zip: '179374',
      isUrban: false,
    },
    {
      name: 'GRADISTEA',
      zip: '103452',
      isUrban: false,
    },
    {
      name: 'SITARU',
      zip: '103461',
      isUrban: false,
    },
    {
      name: 'GRUIU',
      zip: '103522',
      isUrban: false,
    },
    {
      name: 'LIPIA',
      zip: '103531',
      isUrban: false,
    },
    {
      name: 'SANTU FLORESTI',
      zip: '103540',
      isUrban: false,
    },
    {
      name: 'SILISTEA SNAGOVULUI',
      zip: '103559',
      isUrban: false,
    },
    {
      name: 'JILAVA',
      zip: '179392',
      isUrban: false,
    },
    {
      name: 'MAGURELE',
      zip: '179418',
      isUrban: true,
    },
    {
      name: 'ALUNISU',
      zip: '179427',
      isUrban: true,
    },
    {
      name: 'DUMITRANA',
      zip: '179436',
      isUrban: true,
    },
    {
      name: 'PRUNI',
      zip: '179445',
      isUrban: true,
    },
    {
      name: 'VARTEJU',
      zip: '179454',
      isUrban: true,
    },
    {
      name: 'MOARA VLASIEI',
      zip: '104252',
      isUrban: false,
    },
    {
      name: 'CACIULATI',
      zip: '104261',
      isUrban: false,
    },
    {
      name: 'MOGOSOAIA',
      zip: '179472',
      isUrban: false,
    },
    {
      name: 'NUCI',
      zip: '104430',
      isUrban: false,
    },
    {
      name: 'BALTA NEAGRA',
      zip: '104449',
      isUrban: false,
    },
    {
      name: 'MERII PETCHII',
      zip: '104458',
      isUrban: false,
    },
    {
      name: 'MICSUNESTII MARI',
      zip: '104467',
      isUrban: false,
    },
    {
      name: 'MICSUNESTII-MOARA',
      zip: '104476',
      isUrban: false,
    },
    {
      name: 'OTOPENI',
      zip: '179490',
      isUrban: true,
    },
    {
      name: 'ODAILE',
      zip: '179506',
      isUrban: true,
    },
    {
      name: 'PANTELIMON',
      zip: '179524',
      isUrban: true,
    },
    {
      name: 'PERIS',
      zip: '104555',
      isUrban: false,
    },
    {
      name: 'BALTENI',
      zip: '104564',
      isUrban: false,
    },
    {
      name: 'BURIAS',
      zip: '104573',
      isUrban: false,
    },
    {
      name: 'PETRACHIOAIA',
      zip: '104591',
      isUrban: false,
    },
    {
      name: 'MAINEASCA',
      zip: '104608',
      isUrban: false,
    },
    {
      name: 'SURLARI',
      zip: '104617',
      isUrban: false,
    },
    {
      name: 'VANATORI',
      zip: '104626',
      isUrban: false,
    },
    {
      name: 'POPESTI LEORDENI',
      zip: '179542',
      isUrban: true,
    },
    {
      name: 'SNAGOV',
      zip: '105179',
      isUrban: false,
    },
    {
      name: 'CIOFLICENI',
      zip: '105188',
      isUrban: false,
    },
    {
      name: 'GHERMANESTI',
      zip: '105197',
      isUrban: false,
    },
    {
      name: 'TANCABESTI',
      zip: '105204',
      isUrban: false,
    },
    {
      name: 'VLADICEASCA',
      zip: '105213',
      isUrban: false,
    },
    {
      name: 'STEFANESTII DE JOS',
      zip: '105428',
      isUrban: false,
    },
    {
      name: 'CRETULEASCA',
      zip: '105437',
      isUrban: false,
    },
    {
      name: 'STEFANESTII DE SUS',
      zip: '105446',
      isUrban: false,
    },
    {
      name: 'TUNARI',
      zip: '105589',
      isUrban: false,
    },
    {
      name: 'DIMIENI',
      zip: '105598',
      isUrban: false,
    },
    {
      name: 'VIDRA',
      zip: '105945',
      isUrban: false,
    },
    {
      name: 'CRETESTI',
      zip: '105954',
      isUrban: false,
    },
    {
      name: 'SINTESTI',
      zip: '105963',
      isUrban: false,
    },
    {
      name: 'VOLUNTARI',
      zip: '179560',
      isUrban: true,
    }
  ],
  MH: [
    {
      name: 'DROBETA-TURNU SEVERIN',
      zip: '109782',
      isUrban: true,
    },
    {
      name: 'DUDASU SCHELEI',
      zip: '109808',
      isUrban: true,
    },
    {
      name: 'GURA VAII',
      zip: '109791',
      isUrban: true,
    },
    {
      name: 'SCHELA CLADOVEI',
      zip: '109817',
      isUrban: true,
    },
    {
      name: 'BAIA DE ARAMA',
      zip: '109933',
      isUrban: true,
    },
    {
      name: 'MENTI',
      zip: '110198',
      isUrban: true,
    },
    {
      name: 'MOTRULENI',
      zip: '110205',
      isUrban: true,
    },
    {
      name: 'SLATINICU MARE',
      zip: '110170',
      isUrban: true,
    },
    {
      name: 'SLATINICU MIC',
      zip: '110189',
      isUrban: true,
    },
    {
      name: 'STANCESTI',
      zip: '110214',
      isUrban: true,
    },
    {
      name: 'VANJU MARE',
      zip: '110241',
      isUrban: true,
    },
    {
      name: 'BUCURA',
      zip: '110250',
      isUrban: true,
    },
    {
      name: 'NICOLAE BALCESCU',
      zip: '110269',
      isUrban: true,
    },
    {
      name: 'OREVITA MARE',
      zip: '110278',
      isUrban: true,
    },
    {
      name: 'TRAIAN',
      zip: '110287',
      isUrban: true,
    },
    {
      name: 'BACLES',
      zip: '110580',
      isUrban: false,
    },
    {
      name: 'CORZU',
      zip: '110615',
      isUrban: false,
    },
    {
      name: 'GIURA',
      zip: '110624',
      isUrban: false,
    },
    {
      name: 'PETRA',
      zip: '110633',
      isUrban: false,
    },
    {
      name: 'PODU GROSULUI',
      zip: '110642',
      isUrban: false,
    },
    {
      name: 'SELISTIUTA',
      zip: '110651',
      isUrban: false,
    },
    {
      name: 'SMADOVITA',
      zip: '110660',
      isUrban: false,
    },
    {
      name: 'BALA',
      zip: '110303',
      isUrban: false,
    },
    {
      name: 'BALA DE SUS',
      zip: '110312',
      isUrban: false,
    },
    {
      name: 'BRATESUL',
      zip: '110321',
      isUrban: false,
    },
    {
      name: 'BRATIVOESTI',
      zip: '110330',
      isUrban: false,
    },
    {
      name: 'CAMPU MARE',
      zip: '110349',
      isUrban: false,
    },
    {
      name: 'CARSU',
      zip: '110358',
      isUrban: false,
    },
    {
      name: 'COMANESTI',
      zip: '110367',
      isUrban: false,
    },
    {
      name: 'CRAINICI',
      zip: '110376',
      isUrban: false,
    },
    {
      name: 'DALMA',
      zip: '110385',
      isUrban: false,
    },
    {
      name: 'IUPCA',
      zip: '110394',
      isUrban: false,
    },
    {
      name: 'MOLANI',
      zip: '110401',
      isUrban: false,
    },
    {
      name: 'RUDINA',
      zip: '110410',
      isUrban: false,
    },
    {
      name: 'RUNCUSORU',
      zip: '110429',
      isUrban: false,
    },
    {
      name: 'SARDANESTI',
      zip: '110438',
      isUrban: false,
    },
    {
      name: 'VIDIMIRESTI',
      zip: '110447',
      isUrban: false,
    },
    {
      name: 'BALACITA',
      zip: '110544',
      isUrban: false,
    },
    {
      name: 'DOBRA',
      zip: '110553',
      isUrban: false,
    },
    {
      name: 'GVARDINITA',
      zip: '110562',
      isUrban: false,
    },
    {
      name: 'BALTA',
      zip: '110465',
      isUrban: false,
    },
    {
      name: 'COADA CORNETULUI',
      zip: '110474',
      isUrban: false,
    },
    {
      name: 'COSTESTI',
      zip: '110483',
      isUrban: false,
    },
    {
      name: 'GORNOVITA',
      zip: '110492',
      isUrban: false,
    },
    {
      name: 'NEVATU',
      zip: '110508',
      isUrban: false,
    },
    {
      name: 'PREJNA',
      zip: '110517',
      isUrban: false,
    },
    {
      name: 'SFODEA',
      zip: '110526',
      isUrban: false,
    },
    {
      name: 'BALVANESTI',
      zip: '110697',
      isUrban: false,
    },
    {
      name: 'BALVANESTII DE JOS',
      zip: '110704',
      isUrban: false,
    },
    {
      name: 'CALINESTII DE JOS',
      zip: '110713',
      isUrban: false,
    },
    {
      name: 'CALINESTII DE SUS',
      zip: '110722',
      isUrban: false,
    },
    {
      name: 'PARLAGELE',
      zip: '110731',
      isUrban: false,
    },
    {
      name: 'BREZNITA-MOTRU',
      zip: '110759',
      isUrban: false,
    },
    {
      name: 'COSOVAT',
      zip: '110768',
      isUrban: false,
    },
    {
      name: 'DELENI',
      zip: '110777',
      isUrban: false,
    },
    {
      name: 'FAUROAIA',
      zip: '110786',
      isUrban: false,
    },
    {
      name: 'PLAI',
      zip: '110795',
      isUrban: false,
    },
    {
      name: 'TALAPANU',
      zip: '110802',
      isUrban: false,
    },
    {
      name: 'VALEA TEIULUI',
      zip: '110811',
      isUrban: false,
    },
    {
      name: 'BREZNITA-OCOL',
      zip: '110839',
      isUrban: false,
    },
    {
      name: 'JIDOSTITA',
      zip: '110848',
      isUrban: false,
    },
    {
      name: 'MAGHERU',
      zip: '110857',
      isUrban: false,
    },
    {
      name: 'SUSITA',
      zip: '110866',
      isUrban: false,
    },
    {
      name: 'BROSTENI',
      zip: '110884',
      isUrban: false,
    },
    {
      name: 'CAPATANESTI',
      zip: '110893',
      isUrban: false,
    },
    {
      name: 'LUNCSOARA',
      zip: '110900',
      isUrban: false,
    },
    {
      name: 'LUPSA DE JOS',
      zip: '110919',
      isUrban: false,
    },
    {
      name: 'LUPSA DE SUS',
      zip: '110928',
      isUrban: false,
    },
    {
      name: 'MERIS',
      zip: '110937',
      isUrban: false,
    },
    {
      name: 'BURILA MARE',
      zip: '110955',
      isUrban: false,
    },
    {
      name: 'CRIVINA',
      zip: '110964',
      isUrban: false,
    },
    {
      name: 'IZVORU FRUMOS',
      zip: '110973',
      isUrban: false,
    },
    {
      name: 'TIGANASI',
      zip: '110982',
      isUrban: false,
    },
    {
      name: 'VRANCEA',
      zip: '110991',
      isUrban: false,
    },
    {
      name: 'BUTOIESTI',
      zip: '111015',
      isUrban: false,
    },
    {
      name: 'ARGINESTI',
      zip: '111024',
      isUrban: false,
    },
    {
      name: 'BUICESTI',
      zip: '111033',
      isUrban: false,
    },
    {
      name: 'GURA MOTRULUI',
      zip: '111042',
      isUrban: false,
    },
    {
      name: 'JUGASTRU',
      zip: '111051',
      isUrban: false,
    },
    {
      name: 'PLUTA',
      zip: '111060',
      isUrban: false,
    },
    {
      name: 'RADUTESTI',
      zip: '111079',
      isUrban: false,
    },
    {
      name: 'TANTARU',
      zip: '111088',
      isUrban: false,
    },
    {
      name: 'CAZANESTI',
      zip: '111104',
      isUrban: false,
    },
    {
      name: 'ERCEA',
      zip: '111113',
      isUrban: false,
    },
    {
      name: 'GARBOVATU DE SUS',
      zip: '111122',
      isUrban: false,
    },
    {
      name: 'GOVODARVA',
      zip: '111131',
      isUrban: false,
    },
    {
      name: 'ILOVU',
      zip: '111140',
      isUrban: false,
    },
    {
      name: 'JIGNITA',
      zip: '111159',
      isUrban: false,
    },
    {
      name: 'PALTINISU',
      zip: '111168',
      isUrban: false,
    },
    {
      name: 'POIANA',
      zip: '111177',
      isUrban: false,
    },
    {
      name: 'ROSIA',
      zip: '111186',
      isUrban: false,
    },
    {
      name: 'SEVERINESTI',
      zip: '111195',
      isUrban: false,
    },
    {
      name: 'SUHARU',
      zip: '111202',
      isUrban: false,
    },
    {
      name: 'VALEA COSUSTEI',
      zip: '111211',
      isUrban: false,
    },
    {
      name: 'CIRESU',
      zip: '111239',
      isUrban: false,
    },
    {
      name: 'BUNOAICA',
      zip: '111248',
      isUrban: false,
    },
    {
      name: 'JUPANESTI',
      zip: '111257',
      isUrban: false,
    },
    {
      name: 'NEGRUSA',
      zip: '111266',
      isUrban: false,
    },
    {
      name: 'CORCOVA',
      zip: '111284',
      isUrban: false,
    },
    {
      name: 'BRETA',
      zip: '111293',
      isUrban: false,
    },
    {
      name: 'CERNAIA',
      zip: '111300',
      isUrban: false,
    },
    {
      name: 'CORDUN',
      zip: '111319',
      isUrban: false,
    },
    {
      name: 'CROICA',
      zip: '111328',
      isUrban: false,
    },
    {
      name: 'GARBOVATU DE JOS',
      zip: '111337',
      isUrban: false,
    },
    {
      name: 'IMOASA',
      zip: '111346',
      isUrban: false,
    },
    {
      name: 'JIROV',
      zip: '111355',
      isUrban: false,
    },
    {
      name: 'MARU ROSU',
      zip: '111364',
      isUrban: false,
    },
    {
      name: 'PARVULESTI',
      zip: '111373',
      isUrban: false,
    },
    {
      name: 'PUSCASU',
      zip: '111382',
      isUrban: false,
    },
    {
      name: 'STEJARU',
      zip: '111391',
      isUrban: false,
    },
    {
      name: 'VLADASESTI',
      zip: '111408',
      isUrban: false,
    },
    {
      name: 'CORLATEL',
      zip: '111426',
      isUrban: false,
    },
    {
      name: 'VALEA ANILOR',
      zip: '111435',
      isUrban: false,
    },
    {
      name: 'CUJMIR',
      zip: '111453',
      isUrban: false,
    },
    {
      name: 'AURORA',
      zip: '111462',
      isUrban: false,
    },
    {
      name: 'CUJMIRU MIC',
      zip: '111471',
      isUrban: false,
    },
    {
      name: 'DARVARI',
      zip: '111569',
      isUrban: false,
    },
    {
      name: 'GEMENI',
      zip: '111578',
      isUrban: false,
    },
    {
      name: 'DEVESEL',
      zip: '111499',
      isUrban: false,
    },
    {
      name: 'BATOTI',
      zip: '111505',
      isUrban: false,
    },
    {
      name: 'BISTRETU',
      zip: '111514',
      isUrban: false,
    },
    {
      name: 'DUNAREA MICA',
      zip: '111523',
      isUrban: false,
    },
    {
      name: 'SCAPAU',
      zip: '111532',
      isUrban: false,
    },
    {
      name: 'TISMANA',
      zip: '111541',
      isUrban: false,
    },
    {
      name: 'DUBOVA',
      zip: '112931',
      isUrban: false,
    },
    {
      name: 'BAIA NOUA',
      zip: '112922',
      isUrban: false,
    },
    {
      name: 'EIBENTHAL',
      zip: '112940',
      isUrban: false,
    },
    {
      name: 'DUMBRAVA DE JOS',
      zip: '111596',
      isUrban: false,
    },
    {
      name: 'ALBULESTI',
      zip: '111603',
      isUrban: false,
    },
    {
      name: 'BRAGLEASA',
      zip: '110606',
      isUrban: false,
    },
    {
      name: 'DUMBRAVA DE MIJLOC',
      zip: '111612',
      isUrban: false,
    },
    {
      name: 'DUMBRAVA DE SUS',
      zip: '111621',
      isUrban: false,
    },
    {
      name: 'GOLINEASA',
      zip: '111630',
      isUrban: false,
    },
    {
      name: 'HIGIU',
      zip: '111649',
      isUrban: false,
    },
    {
      name: 'ROCSORENI',
      zip: '111658',
      isUrban: false,
    },
    {
      name: 'VALEA MARCULUI',
      zip: '111667',
      isUrban: false,
    },
    {
      name: 'VARODIA',
      zip: '111676',
      isUrban: false,
    },
    {
      name: 'VLADICA',
      zip: '110679',
      isUrban: false,
    },
    {
      name: 'ESELNITA',
      zip: '112254',
      isUrban: false,
    },
    {
      name: 'FLORESTI',
      zip: '111694',
      isUrban: false,
    },
    {
      name: 'COPACIOASA',
      zip: '111701',
      isUrban: false,
    },
    {
      name: 'GARDOAIA',
      zip: '111710',
      isUrban: false,
    },
    {
      name: 'LIVEZI',
      zip: '111729',
      isUrban: false,
    },
    {
      name: 'MOSNENI',
      zip: '111738',
      isUrban: false,
    },
    {
      name: 'PESTEANA',
      zip: '111747',
      isUrban: false,
    },
    {
      name: 'PESTENUTA',
      zip: '111756',
      isUrban: false,
    },
    {
      name: 'STROESTI',
      zip: '111765',
      isUrban: false,
    },
    {
      name: 'ZEGUJANI',
      zip: '111774',
      isUrban: false,
    },
    {
      name: 'GARLA MARE',
      zip: '111792',
      isUrban: false,
    },
    {
      name: 'VRATA',
      zip: '111809',
      isUrban: false,
    },
    {
      name: 'GODEANU',
      zip: '111827',
      isUrban: false,
    },
    {
      name: 'MARGA',
      zip: '111836',
      isUrban: false,
    },
    {
      name: 'PAUNESTI',
      zip: '111845',
      isUrban: false,
    },
    {
      name: 'SIROCA',
      zip: '111854',
      isUrban: false,
    },
    {
      name: 'GOGOSU',
      zip: '111872',
      isUrban: false,
    },
    {
      name: 'BALTA VERDE',
      zip: '111881',
      isUrban: false,
    },
    {
      name: 'BURILA MICA',
      zip: '111890',
      isUrban: false,
    },
    {
      name: 'OSTROVU MARE',
      zip: '111907',
      isUrban: false,
    },
    {
      name: 'GRECI',
      zip: '111925',
      isUrban: false,
    },
    {
      name: 'BALTANELE',
      zip: '111934',
      isUrban: false,
    },
    {
      name: 'BLIDARU',
      zip: '111943',
      isUrban: false,
    },
    {
      name: 'SALATRUC',
      zip: '111952',
      isUrban: false,
    },
    {
      name: 'VALEA PETRII',
      zip: '111961',
      isUrban: false,
    },
    {
      name: 'VISINA',
      zip: '111970',
      isUrban: false,
    },
    {
      name: 'GROZESTI',
      zip: '111998',
      isUrban: false,
    },
    {
      name: 'CARCENI',
      zip: '112003',
      isUrban: false,
    },
    {
      name: 'PASARANI',
      zip: '112012',
      isUrban: false,
    },
    {
      name: 'SUSITA',
      zip: '112021',
      isUrban: false,
    },
    {
      name: 'GRUIA',
      zip: '112049',
      isUrban: false,
    },
    {
      name: 'IZVOARELE',
      zip: '112058',
      isUrban: false,
    },
    {
      name: 'POIANA GRUII',
      zip: '112067',
      isUrban: false,
    },
    {
      name: 'HINOVA',
      zip: '112085',
      isUrban: false,
    },
    {
      name: 'BISTRITA',
      zip: '112094',
      isUrban: false,
    },
    {
      name: 'CARJEI',
      zip: '112101',
      isUrban: false,
    },
    {
      name: 'OSTROVU CORBULUI',
      zip: '112110',
      isUrban: false,
    },
    {
      name: 'HUSNICIOARA',
      zip: '112138',
      isUrban: false,
    },
    {
      name: 'ALUNISUL',
      zip: '112147',
      isUrban: false,
    },
    {
      name: 'BADITESTI',
      zip: '112156',
      isUrban: false,
    },
    {
      name: 'BOROGEA',
      zip: '112165',
      isUrban: false,
    },
    {
      name: 'CELNATA',
      zip: '112174',
      isUrban: false,
    },
    {
      name: 'DUMBRAVITA',
      zip: '112183',
      isUrban: false,
    },
    {
      name: 'MARMANU',
      zip: '112192',
      isUrban: false,
    },
    {
      name: 'OPRANESTI',
      zip: '112209',
      isUrban: false,
    },
    {
      name: 'PERI',
      zip: '112218',
      isUrban: false,
    },
    {
      name: 'PRIBOIESTI',
      zip: '112227',
      isUrban: false,
    },
    {
      name: 'SELISTENI',
      zip: '112236',
      isUrban: false,
    },
    {
      name: 'ILOVAT',
      zip: '112272',
      isUrban: false,
    },
    {
      name: 'BUDANESTI',
      zip: '112281',
      isUrban: false,
    },
    {
      name: 'CRACU LUNG',
      zip: '112290',
      isUrban: false,
    },
    {
      name: 'DALBOCITA',
      zip: '112307',
      isUrban: false,
    },
    {
      name: 'FIRIZU',
      zip: '112316',
      isUrban: false,
    },
    {
      name: 'RACOVA',
      zip: '112325',
      isUrban: false,
    },
    {
      name: 'ILOVITA',
      zip: '112343',
      isUrban: false,
    },
    {
      name: 'BAHNA',
      zip: '112352',
      isUrban: false,
    },
    {
      name: 'MOISESTI',
      zip: '112361',
      isUrban: false,
    },
    {
      name: 'ISVERNA',
      zip: '112389',
      isUrban: false,
    },
    {
      name: 'BUSESTI',
      zip: '112398',
      isUrban: false,
    },
    {
      name: 'CERNA-VIRF',
      zip: '112405',
      isUrban: false,
    },
    {
      name: 'DRAGHESTI',
      zip: '112414',
      isUrban: false,
    },
    {
      name: 'GIURGIANI',
      zip: '112423',
      isUrban: false,
    },
    {
      name: 'NADANOVA',
      zip: '112432',
      isUrban: false,
    },
    {
      name: 'SELISTEA',
      zip: '112441',
      isUrban: false,
    },
    {
      name: 'TURTABA',
      zip: '112450',
      isUrban: false,
    },
    {
      name: 'IZVORU BARZII',
      zip: '112478',
      isUrban: false,
    },
    {
      name: 'BALOTESTI',
      zip: '112487',
      isUrban: false,
    },
    {
      name: 'HALANGA',
      zip: '112496',
      isUrban: false,
    },
    {
      name: 'PUTINEI',
      zip: '112502',
      isUrban: false,
    },
    {
      name: 'RASCOLESTI',
      zip: '112511',
      isUrban: false,
    },
    {
      name: 'SCHINTEIESTI',
      zip: '112520',
      isUrban: false,
    },
    {
      name: 'SCHITU TOPOLNITEI',
      zip: '112539',
      isUrban: false,
    },
    {
      name: 'JIANA',
      zip: '112557',
      isUrban: false,
    },
    {
      name: 'CIOROBORENI',
      zip: '112566',
      isUrban: false,
    },
    {
      name: 'DANCEU',
      zip: '112575',
      isUrban: false,
    },
    {
      name: 'JIANA MARE',
      zip: '112584',
      isUrban: false,
    },
    {
      name: 'JIANA VECHE',
      zip: '112593',
      isUrban: false,
    },
    {
      name: 'LIVEZILE',
      zip: '112619',
      isUrban: false,
    },
    {
      name: 'IZVORALU DE JOS',
      zip: '112628',
      isUrban: false,
    },
    {
      name: 'IZVORU ANESTILOR',
      zip: '112637',
      isUrban: false,
    },
    {
      name: 'PETRIS',
      zip: '112646',
      isUrban: false,
    },
    {
      name: 'STEFAN ODOBLEJA',
      zip: '112655',
      isUrban: false,
    },
    {
      name: 'MALOVAT',
      zip: '112673',
      isUrban: false,
    },
    {
      name: '',
      zip: '112735',
      isUrban: false,
    },
    {
      name: 'BARDA',
      zip: '112726',
      isUrban: false,
    },
    {
      name: 'BOBAITA',
      zip: '112682',
      isUrban: false,
    },
    {
      name: 'COLIBASI',
      zip: '112691',
      isUrban: false,
    },
    {
      name: 'LAZU',
      zip: '112708',
      isUrban: false,
    },
    {
      name: 'NEGRESTI',
      zip: '112717',
      isUrban: false,
    },
    {
      name: 'OBARSIA DE CAMP',
      zip: '112753',
      isUrban: false,
    },
    {
      name: 'IZIMSA',
      zip: '112762',
      isUrban: false,
    },
    {
      name: 'OBARSIA-CLOSANI',
      zip: '110036',
      isUrban: false,
    },
    {
      name: 'GODEANU',
      zip: '110045',
      isUrban: false,
    },
    {
      name: 'OPRISOR',
      zip: '112780',
      isUrban: false,
    },
    {
      name: 'PRISACEAUA',
      zip: '112799',
      isUrban: false,
    },
    {
      name: 'PADINA MARE',
      zip: '112815',
      isUrban: false,
    },
    {
      name: 'BIBAN',
      zip: '112824',
      isUrban: false,
    },
    {
      name: 'IABLANITA',
      zip: '112833',
      isUrban: false,
    },
    {
      name: 'OLTEANCA',
      zip: '112842',
      isUrban: false,
    },
    {
      name: 'PADINA MICA',
      zip: '112851',
      isUrban: false,
    },
    {
      name: 'SLASOMA',
      zip: '112860',
      isUrban: false,
    },
    {
      name: 'PATULELE',
      zip: '112888',
      isUrban: false,
    },
    {
      name: 'VIASU',
      zip: '112897',
      isUrban: false,
    },
    {
      name: 'PODENI',
      zip: '112968',
      isUrban: false,
    },
    {
      name: 'GORNENTI',
      zip: '112977',
      isUrban: false,
    },
    {
      name: 'MALARISCA',
      zip: '112986',
      isUrban: false,
    },
    {
      name: 'PONOARELE',
      zip: '113000',
      isUrban: false,
    },
    {
      name: 'BALUTA',
      zip: '113019',
      isUrban: false,
    },
    {
      name: 'BARAIACU',
      zip: '113028',
      isUrban: false,
    },
    {
      name: 'PRUNISOR',
      zip: '113242',
      isUrban: false,
    },
    {
      name: 'ARVATESTI',
      zip: '113251',
      isUrban: false,
    },
    {
      name: 'BALOTA',
      zip: '113260',
      isUrban: false,
    },
    {
      name: 'BALTANELE',
      zip: '113279',
      isUrban: false,
    },
    {
      name: 'CERVENITA',
      zip: '113288',
      isUrban: false,
    },
    {
      name: 'DRAGOTESTI',
      zip: '113297',
      isUrban: false,
    },
    {
      name: 'FANTANA DOMNEASCA',
      zip: '113304',
      isUrban: false,
    },
    {
      name: 'GARNITA',
      zip: '113322',
      isUrban: false,
    },
    {
      name: 'GHELMEGIOAIA',
      zip: '113313',
      isUrban: false,
    },
    {
      name: 'GUTU',
      zip: '113331',
      isUrban: false,
    },
    {
      name: 'IGIROASA',
      zip: '113340',
      isUrban: false,
    },
    {
      name: 'LUMNIC',
      zip: '113359',
      isUrban: false,
    },
    {
      name: 'MIJARCA',
      zip: '113368',
      isUrban: false,
    },
    {
      name: 'PRUNARU',
      zip: '113377',
      isUrban: false,
    },
    {
      name: 'ZEGAIA',
      zip: '113386',
      isUrban: false,
    },
    {
      name: 'PUNGHINA',
      zip: '113402',
      isUrban: false,
    },
    {
      name: 'CEARANGU',
      zip: '113411',
      isUrban: false,
    },
    {
      name: 'DRINCEA',
      zip: '113420',
      isUrban: false,
    },
    {
      name: 'MAGURELE',
      zip: '113439',
      isUrban: false,
    },
    {
      name: 'RECEA',
      zip: '113448',
      isUrban: false,
    },
    {
      name: 'SATU NOU',
      zip: '113457',
      isUrban: false,
    },
    {
      name: 'ROGOVA',
      zip: '113475',
      isUrban: false,
    },
    {
      name: 'POROINITA',
      zip: '113484',
      isUrban: false,
    },
    {
      name: 'SALCIA',
      zip: '113509',
      isUrban: false,
    },
    {
      name: 'SIMIAN',
      zip: '109835',
      isUrban: false,
    },
    {
      name: 'CERNETI',
      zip: '109844',
      isUrban: false,
    },
    {
      name: 'DEDOVITA NOUA',
      zip: '109853',
      isUrban: false,
    },
    {
      name: 'DEDOVITA VECHE',
      zip: '109862',
      isUrban: false,
    },
    {
      name: 'DUDASU',
      zip: '109871',
      isUrban: false,
    },
    {
      name: 'ERGHEVITA',
      zip: '109880',
      isUrban: false,
    },
    {
      name: 'POROINA',
      zip: '109899',
      isUrban: false,
    },
    {
      name: 'VALEA COPCII',
      zip: '109906',
      isUrban: false,
    },
    {
      name: 'SISESTI',
      zip: '113634',
      isUrban: false,
    },
    {
      name: 'CARAMIDARU',
      zip: '113643',
      isUrban: false,
    },
    {
      name: 'CIOVARNASANI',
      zip: '113652',
      isUrban: false,
    },
    {
      name: 'COCOROVA',
      zip: '113661',
      isUrban: false,
    },
    {
      name: 'CRAGUESTI',
      zip: '113670',
      isUrban: false,
    },
    {
      name: 'NOAPTESA',
      zip: '113689',
      isUrban: false,
    },
    {
      name: 'SOVARNA',
      zip: '113705',
      isUrban: false,
    },
    {
      name: 'OHABA',
      zip: '113714',
      isUrban: false,
    },
    {
      name: 'STUDINA',
      zip: '113723',
      isUrban: false,
    },
    {
      name: 'STANGACEAUA',
      zip: '113527',
      isUrban: false,
    },
    {
      name: 'BARLOGENI',
      zip: '113536',
      isUrban: false,
    },
    {
      name: 'BREZNICIOARA',
      zip: '113545',
      isUrban: false,
    },
    {
      name: 'CERANGANUL',
      zip: '113554',
      isUrban: false,
    },
    {
      name: 'FATA MOTRULUI',
      zip: '113563',
      isUrban: false,
    },
    {
      name: 'POSTA VECHE',
      zip: '113572',
      isUrban: false,
    },
    {
      name: 'SATU MARE',
      zip: '113581',
      isUrban: false,
    },
    {
      name: 'TARSA',
      zip: '113590',
      isUrban: false,
    },
    {
      name: 'SVINITA',
      zip: '113616',
      isUrban: false,
    },
    {
      name: 'TAMNA',
      zip: '113741',
      isUrban: false,
    },
    {
      name: 'ADUNATII TEIULUI',
      zip: '110599',
      isUrban: false,
    },
    {
      name: 'BOCENI',
      zip: '113750',
      isUrban: false,
    },
    {
      name: 'COLARET',
      zip: '113769',
      isUrban: false,
    },
    {
      name: 'CREMENEA',
      zip: '113778',
      isUrban: false,
    },
    {
      name: 'FATA CREMENII',
      zip: '113787',
      isUrban: false,
    },
    {
      name: 'IZVORALU',
      zip: '113796',
      isUrban: false,
    },
    {
      name: 'MANU',
      zip: '113803',
      isUrban: false,
    },
    {
      name: 'PAVAT',
      zip: '113812',
      isUrban: false,
    },
    {
      name: 'PLOPI',
      zip: '113821',
      isUrban: false,
    },
    {
      name: 'VALEA URSULUI',
      zip: '113830',
      isUrban: false,
    },
    {
      name: 'VANATORI',
      zip: '113858',
      isUrban: false,
    },
    {
      name: 'BRANISTEA',
      zip: '113867',
      isUrban: false,
    },
    {
      name: 'GOANTA',
      zip: '113876',
      isUrban: false,
    },
    {
      name: 'ROSIORI',
      zip: '113885',
      isUrban: false,
    },
    {
      name: 'VANJULET',
      zip: '113901',
      isUrban: false,
    },
    {
      name: 'HOTARANI',
      zip: '113910',
      isUrban: false,
    },
    {
      name: 'VLADAIA',
      zip: '113938',
      isUrban: false,
    },
    {
      name: 'ALMAJEL',
      zip: '113947',
      isUrban: false,
    },
    {
      name: 'SCORILA',
      zip: '113956',
      isUrban: false,
    },
    {
      name: 'STIRCOVITA',
      zip: '113965',
      isUrban: false,
    },
    {
      name: 'VOLOIAC',
      zip: '113983',
      isUrban: false,
    },
    {
      name: 'COTOROAIA',
      zip: '113992',
      isUrban: false,
    },
    {
      name: 'LAC',
      zip: '114006',
      isUrban: false,
    },
    {
      name: 'RUPTURA',
      zip: '114015',
      isUrban: false,
    },
    {
      name: 'SPERLESTI',
      zip: '114024',
      isUrban: false,
    },
    {
      name: 'TITIRIGI',
      zip: '114033',
      isUrban: false,
    },
    {
      name: 'VALEA BUNA',
      zip: '114042',
      isUrban: false,
    },
    {
      name: 'VOLOICEL',
      zip: '114051',
      isUrban: false,
    },
    {
      name: 'BRATILOVU',
      zip: '109942',
      isUrban: true,
    },
    {
      name: 'BREBINA',
      zip: '109951',
      isUrban: true,
    },
    {
      name: 'DEALU MARE',
      zip: '109960',
      isUrban: true,
    },
    {
      name: 'MARASESTI',
      zip: '109979',
      isUrban: true,
    },
    {
      name: 'NEGOESTI',
      zip: '109988',
      isUrban: true,
    },
    {
      name: 'PISTRITA',
      zip: '109997',
      isUrban: true,
    },
    {
      name: 'STANESTI',
      zip: '110009',
      isUrban: true,
    },
    {
      name: 'TITERLESTI',
      zip: '110018',
      isUrban: true,
    },
    {
      name: 'ORSOVA',
      zip: '110072',
      isUrban: true,
    },
    {
      name: 'STREHAIA',
      zip: '110125',
      isUrban: true,
    },
    {
      name: 'CIOCHIUTA',
      zip: '110134',
      isUrban: true,
    },
    {
      name: 'COMANDA',
      zip: '110143',
      isUrban: true,
    },
    {
      name: 'HURDUCESTI',
      zip: '110152',
      isUrban: true,
    },
    {
      name: 'LUNCA BANULUI',
      zip: '110161',
      isUrban: true,
    },
    {
      name: 'BRANZENI',
      zip: '113037',
      isUrban: false,
    },
    {
      name: 'BUICANI',
      zip: '113046',
      isUrban: false,
    },
    {
      name: 'CEPTURENI',
      zip: '113055',
      isUrban: false,
    },
    {
      name: 'CRACU MUNTELUI',
      zip: '113064',
      isUrban: false,
    },
    {
      name: 'DELURENI',
      zip: '113073',
      isUrban: false,
    },
    {
      name: 'GARDANEASA',
      zip: '113082',
      isUrban: false,
    },
    {
      name: 'GHEORGHESTI',
      zip: '113091',
      isUrban: false,
    },
    {
      name: 'LUDU',
      zip: '113108',
      isUrban: false,
    },
    {
      name: 'PROITESTI',
      zip: '113117',
      isUrban: false,
    },
    {
      name: 'RAICULESTI',
      zip: '113126',
      isUrban: false,
    },
    {
      name: 'SIPOTU',
      zip: '113135',
      isUrban: false,
    },
    {
      name: 'VALEA URSULUI',
      zip: '113144',
      isUrban: false,
    },
    {
      name: 'POROINA MARE',
      zip: '113162',
      isUrban: false,
    },
    {
      name: 'FANTANILE NEGRE',
      zip: '113171',
      isUrban: false,
    },
    {
      name: 'SIPOTU',
      zip: '113199',
      isUrban: false,
    },
    {
      name: 'STIGNITA',
      zip: '113180',
      isUrban: false,
    },
    {
      name: 'PRISTOL',
      zip: '113215',
      isUrban: false,
    },
    {
      name: 'COZIA',
      zip: '113224',
      isUrban: false,
    }
  ],
  MM: [
    {
      name: 'BAIA MARE',
      zip: '106327',
      isUrban: true,
    },
    {
      name: 'BLIDARI',
      zip: '106336',
      isUrban: true,
    },
    {
      name: 'FIRIZA',
      zip: '106345',
      isUrban: true,
    },
    {
      name: 'VALEA NEAGRA',
      zip: '106354',
      isUrban: true,
    },
    {
      name: 'SIGHETU MARMATIEI',
      zip: '106568',
      isUrban: true,
    },
    {
      name: 'IAPA',
      zip: '106577',
      isUrban: true,
    },
    {
      name: 'LAZU BACIULUI',
      zip: '106586',
      isUrban: true,
    },
    {
      name: 'SUGAU',
      zip: '106595',
      isUrban: true,
    },
    {
      name: 'VALEA CUFUNDOASA',
      zip: '106602',
      isUrban: true,
    },
    {
      name: 'VALEA HOTARULUI',
      zip: '106611',
      isUrban: true,
    },
    {
      name: 'BAIA SPRIE',
      zip: '106693',
      isUrban: true,
    },
    {
      name: 'CHIUZBAIA',
      zip: '106700',
      isUrban: true,
    },
    {
      name: 'SATU NOU DE SUS',
      zip: '106728',
      isUrban: true,
    },
    {
      name: 'TAUTII DE SUS',
      zip: '106719',
      isUrban: true,
    },
    {
      name: 'BORSA',
      zip: '106755',
      isUrban: true,
    },
    {
      name: 'BAILE BORSA',
      zip: '106764',
      isUrban: true,
    },
    {
      name: 'CAVNIC',
      zip: '106791',
      isUrban: true,
    },
    {
      name: 'SEINI',
      zip: '108972',
      isUrban: true,
    },
    {
      name: 'SABISA',
      zip: '108981',
      isUrban: true,
    },
    {
      name: 'VIILE APEI',
      zip: '108990',
      isUrban: true,
    },
    {
      name: 'TARGU LAPUS',
      zip: '106826',
      isUrban: true,
    },
    {
      name: 'BOIERENI',
      zip: '106835',
      isUrban: true,
    },
    {
      name: 'BORCUT',
      zip: '106844',
      isUrban: true,
    },
    {
      name: 'CUFOAIA',
      zip: '106853',
      isUrban: true,
    },
    {
      name: 'DAMACUSENI',
      zip: '106862',
      isUrban: true,
    },
    {
      name: 'DOBRICU LAPUSULUI',
      zip: '106871',
      isUrban: true,
    },
    {
      name: 'DUMBRAVA',
      zip: '106880',
      isUrban: true,
    },
    {
      name: 'FANTANELE',
      zip: '106899',
      isUrban: true,
    },
    {
      name: 'GROAPE',
      zip: '106906',
      isUrban: true,
    },
    {
      name: 'INAU',
      zip: '106915',
      isUrban: true,
    },
    {
      name: 'RAZOARE',
      zip: '106924',
      isUrban: true,
    },
    {
      name: 'ROGOZ',
      zip: '106933',
      isUrban: true,
    },
    {
      name: 'ROHIA',
      zip: '106942',
      isUrban: true,
    },
    {
      name: 'STOICENI',
      zip: '106951',
      isUrban: true,
    },
    {
      name: 'VISEU DE SUS',
      zip: '106988',
      isUrban: true,
    },
    {
      name: 'VISEU DE MIJLOC',
      zip: '106997',
      isUrban: true,
    },
    {
      name: 'ARDUSAT',
      zip: '107010',
      isUrban: false,
    },
    {
      name: 'ARIESU DE CAMP',
      zip: '107029',
      isUrban: false,
    },
    {
      name: 'COLTIREA',
      zip: '107038',
      isUrban: false,
    },
    {
      name: 'ARINIS',
      zip: '107056',
      isUrban: false,
    },
    {
      name: 'RODINA',
      zip: '107065',
      isUrban: false,
    },
    {
      name: 'TAMASESTI',
      zip: '107074',
      isUrban: false,
    },
    {
      name: 'ASUAJU DE SUS',
      zip: '107092',
      isUrban: false,
    },
    {
      name: 'ASUAJU DE JOS',
      zip: '107109',
      isUrban: false,
    },
    {
      name: 'BAITA DE SUB CODRU',
      zip: '107127',
      isUrban: false,
    },
    {
      name: 'ODESTI',
      zip: '107136',
      isUrban: false,
    },
    {
      name: 'URMENIS',
      zip: '107145',
      isUrban: false,
    },
    {
      name: 'BAIUT',
      zip: '107163',
      isUrban: false,
    },
    {
      name: 'POIANA BOTIZII',
      zip: '107172',
      isUrban: false,
    },
    {
      name: 'STRAMBU-BAIUT',
      zip: '107181',
      isUrban: false,
    },
    {
      name: 'BARSANA',
      zip: '107323',
      isUrban: false,
    },
    {
      name: 'NANESTI',
      zip: '107332',
      isUrban: false,
    },
    {
      name: 'ONCESTI',
      zip: '107341',
      isUrban: false,
    },
    {
      name: 'BASESTI',
      zip: '107207',
      isUrban: false,
    },
    {
      name: 'SALISTE',
      zip: '107216',
      isUrban: false,
    },
    {
      name: 'STREMT',
      zip: '107225',
      isUrban: false,
    },
    {
      name: 'BICAZ',
      zip: '107243',
      isUrban: false,
    },
    {
      name: 'CIUTA',
      zip: '107252',
      isUrban: false,
    },
    {
      name: 'CORNI',
      zip: '107261',
      isUrban: false,
    },
    {
      name: 'BISTRA',
      zip: '107289',
      isUrban: false,
    },
    {
      name: 'CRASNA VISEULUI',
      zip: '107298',
      isUrban: false,
    },
    {
      name: 'VALEA VISEULUI',
      zip: '107305',
      isUrban: false,
    },
    {
      name: 'BOCICOIU MARE',
      zip: '107369',
      isUrban: false,
    },
    {
      name: 'CRACIUNESTI',
      zip: '107378',
      isUrban: false,
    },
    {
      name: 'LUNCA LA TISA',
      zip: '107387',
      isUrban: false,
    },
    {
      name: 'TISA',
      zip: '107396',
      isUrban: false,
    },
    {
      name: 'BOGDAN VODA',
      zip: '107412',
      isUrban: false,
    },
    {
      name: 'BOCICOEL',
      zip: '107421',
      isUrban: false,
    },
    {
      name: 'BOIU MARE',
      zip: '107449',
      isUrban: false,
    },
    {
      name: 'FRANCENII BOIULUI',
      zip: '107458',
      isUrban: false,
    },
    {
      name: 'PRISLOP',
      zip: '107467',
      isUrban: false,
    },
    {
      name: 'ROMANESTI',
      zip: '107476',
      isUrban: false,
    },
    {
      name: 'BOTIZA',
      zip: '107494',
      isUrban: false,
    },
    {
      name: 'BUDESTI',
      zip: '107528',
      isUrban: false,
    },
    {
      name: 'SARBI',
      zip: '107537',
      isUrban: false,
    },
    {
      name: 'CALINESTI',
      zip: '107555',
      isUrban: false,
    },
    {
      name: 'CORNESTI',
      zip: '107564',
      isUrban: false,
    },
    {
      name: 'VALENI',
      zip: '107573',
      isUrban: false,
    },
    {
      name: 'CAMPULUNG LA TISA',
      zip: '107724',
      isUrban: false,
    },
    {
      name: 'CERNESTI',
      zip: '107591',
      isUrban: false,
    },
    {
      name: 'BREBENI',
      zip: '107608',
      isUrban: false,
    },
    {
      name: 'CIOCOTIS',
      zip: '107617',
      isUrban: false,
    },
    {
      name: 'FANATE',
      zip: '107626',
      isUrban: false,
    },
    {
      name: 'IZVOARELE',
      zip: '107635',
      isUrban: false,
    },
    {
      name: 'MAGURENI',
      zip: '107644',
      isUrban: false,
    },
    {
      name: 'TRESTIA',
      zip: '107653',
      isUrban: false,
    },
    {
      name: 'CICARLAU',
      zip: '107671',
      isUrban: false,
    },
    {
      name: 'BARGAU',
      zip: '107680',
      isUrban: false,
    },
    {
      name: 'HANDALU ILBEI',
      zip: '107699',
      isUrban: false,
    },
    {
      name: 'ILBA',
      zip: '107706',
      isUrban: false,
    },
    {
      name: 'COPALNIC-MANASTUR',
      zip: '107742',
      isUrban: false,
    },
    {
      name: 'BERINTA',
      zip: '107751',
      isUrban: false,
    },
    {
      name: 'CARPINIS',
      zip: '107760',
      isUrban: false,
    },
    {
      name: 'COPALNIC',
      zip: '107779',
      isUrban: false,
    },
    {
      name: 'COPALNIC-DEAL',
      zip: '107788',
      isUrban: false,
    },
    {
      name: 'CURTUIUSU MIC',
      zip: '107797',
      isUrban: false,
    },
    {
      name: 'FAURESTI',
      zip: '107804',
      isUrban: false,
    },
    {
      name: 'LASCHIA',
      zip: '107813',
      isUrban: false,
    },
    {
      name: 'PRELUCA NOUA',
      zip: '107822',
      isUrban: false,
    },
    {
      name: 'PRELUCA VECHE',
      zip: '107831',
      isUrban: false,
    },
    {
      name: 'RUSOR',
      zip: '107840',
      isUrban: false,
    },
    {
      name: 'VAD',
      zip: '107859',
      isUrban: false,
    },
    {
      name: 'COROIENI',
      zip: '107877',
      isUrban: false,
    },
    {
      name: 'BABA',
      zip: '107886',
      isUrban: false,
    },
    {
      name: 'DEALU MARE',
      zip: '107895',
      isUrban: false,
    },
    {
      name: 'DRAGHIA',
      zip: '107902',
      isUrban: false,
    },
    {
      name: 'VALENII LAPUSULUI',
      zip: '107911',
      isUrban: false,
    },
    {
      name: 'CUPSENI',
      zip: '107939',
      isUrban: false,
    },
    {
      name: 'COSTENI',
      zip: '107948',
      isUrban: false,
    },
    {
      name: 'LIBOTIN',
      zip: '107957',
      isUrban: false,
    },
    {
      name: 'UNGURENI',
      zip: '107966',
      isUrban: false,
    },
    {
      name: 'DESESTI',
      zip: '107984',
      isUrban: false,
    },
    {
      name: 'HARNICESTI',
      zip: '107993',
      isUrban: false,
    },
    {
      name: 'MARA',
      zip: '108008',
      isUrban: false,
    },
    {
      name: 'DRAGOMIRESTI',
      zip: '108026',
      isUrban: true,
    },
    {
      name: 'DUMBRAVITA',
      zip: '108044',
      isUrban: false,
    },
    {
      name: 'CARBUNARI',
      zip: '108053',
      isUrban: false,
    },
    {
      name: 'CHECHIS',
      zip: '108062',
      isUrban: false,
    },
    {
      name: 'RUS',
      zip: '108071',
      isUrban: false,
    },
    {
      name: 'SANDRESTI',
      zip: '108080',
      isUrban: false,
    },
    {
      name: 'UNGURAS',
      zip: '108099',
      isUrban: false,
    },
    {
      name: 'FARCASA',
      zip: '108115',
      isUrban: false,
    },
    {
      name: 'BUZESTI',
      zip: '108124',
      isUrban: false,
    },
    {
      name: 'SARBI',
      zip: '108133',
      isUrban: false,
    },
    {
      name: 'TAMAIA',
      zip: '108142',
      isUrban: false,
    },
    {
      name: 'GIULESTI',
      zip: '108160',
      isUrban: false,
    },
    {
      name: 'BERBESTI',
      zip: '108179',
      isUrban: false,
    },
    {
      name: 'FERESTI',
      zip: '108188',
      isUrban: false,
    },
    {
      name: 'MANASTIREA',
      zip: '108197',
      isUrban: false,
    },
    {
      name: 'GROSI',
      zip: '106372',
      isUrban: false,
    },
    {
      name: 'OCOLIS',
      zip: '106381',
      isUrban: false,
    },
    {
      name: 'SATU NOU DE JOS',
      zip: '106390',
      isUrban: false,
    },
    {
      name: 'IEUD',
      zip: '108213',
      isUrban: false,
    },
    {
      name: 'LAPUS',
      zip: '108231',
      isUrban: false,
    },
    {
      name: 'LEORDINA',
      zip: '108259',
      isUrban: false,
    },
    {
      name: 'MIRESU MARE',
      zip: '108277',
      isUrban: false,
    },
    {
      name: 'DANESTII CHIOARULUI',
      zip: '108286',
      isUrban: false,
    },
    {
      name: 'IADARA',
      zip: '108295',
      isUrban: false,
    },
    {
      name: 'LUCACESTI',
      zip: '108302',
      isUrban: false,
    },
    {
      name: 'REMETI PE SOMES',
      zip: '108311',
      isUrban: false,
    },
    {
      name: 'STEJERA',
      zip: '108320',
      isUrban: false,
    },
    {
      name: 'TULGHIES',
      zip: '108339',
      isUrban: false,
    },
    {
      name: 'MOISEI',
      zip: '108357',
      isUrban: false,
    },
    {
      name: 'OARTA DE JOS',
      zip: '108375',
      isUrban: false,
    },
    {
      name: 'OARTA DE SUS',
      zip: '108384',
      isUrban: false,
    },
    {
      name: 'ORTITA',
      zip: '108393',
      isUrban: false,
    },
    {
      name: 'OCNA SUGATAG',
      zip: '108419',
      isUrban: false,
    },
    {
      name: 'BREB',
      zip: '108428',
      isUrban: false,
    },
    {
      name: 'HOTENI',
      zip: '108437',
      isUrban: false,
    },
    {
      name: 'SAT-SUGATAG',
      zip: '108446',
      isUrban: false,
    },
    {
      name: 'PETROVA',
      zip: '108464',
      isUrban: false,
    },
    {
      name: 'POIENILE DE SUB MUNTE',
      zip: '108482',
      isUrban: false,
    },
    {
      name: 'POIENILE IZEI',
      zip: '107500',
      isUrban: false,
    },
    {
      name: 'RECEA',
      zip: '106416',
      isUrban: false,
    },
    {
      name: 'BOZANTA MICA',
      zip: '106425',
      isUrban: false,
    },
    {
      name: 'LAPUSEL',
      zip: '106434',
      isUrban: false,
    },
    {
      name: 'MOCIRA',
      zip: '106443',
      isUrban: false,
    },
    {
      name: 'SASAR',
      zip: '106452',
      isUrban: false,
    },
    {
      name: 'REMETEA CHIOARULUI',
      zip: '108507',
      isUrban: false,
    },
    {
      name: 'BERCHEZ',
      zip: '108516',
      isUrban: false,
    },
    {
      name: 'BERCHEZOAIA',
      zip: '108525',
      isUrban: false,
    },
    {
      name: 'POSTA',
      zip: '108534',
      isUrban: false,
    },
    {
      name: 'REMECIOARA',
      zip: '108543',
      isUrban: false,
    },
    {
      name: 'REMETI',
      zip: '108561',
      isUrban: false,
    },
    {
      name: 'PIATRA',
      zip: '108570',
      isUrban: false,
    },
    {
      name: 'TECEU MIC',
      zip: '108589',
      isUrban: false,
    },
    {
      name: 'REPEDEA',
      zip: '108605',
      isUrban: false,
    },
    {
      name: 'RONA DE JOS',
      zip: '108623',
      isUrban: false,
    },
    {
      name: 'RONA DE SUS',
      zip: '108641',
      isUrban: false,
    },
    {
      name: 'COSTIUI',
      zip: '108650',
      isUrban: false,
    },
    {
      name: 'ROZAVLEA',
      zip: '108678',
      isUrban: false,
    },
    {
      name: 'SIEU',
      zip: '108687',
      isUrban: false,
    },
    {
      name: 'RUSCOVA',
      zip: '108703',
      isUrban: false,
    },
    {
      name: 'SACALASENI',
      zip: '108801',
      isUrban: false,
    },
    {
      name: 'CATALINA',
      zip: '108810',
      isUrban: false,
    },
    {
      name: 'COLTAU',
      zip: '108838',
      isUrban: false,
    },
    {
      name: 'CORUIA',
      zip: '108847',
      isUrban: false,
    },
    {
      name: 'CULCEA',
      zip: '108856',
      isUrban: false,
    },
    {
      name: 'INTRERAURI',
      zip: '108865',
      isUrban: false,
    },
    {
      name: 'SACEL',
      zip: '108883',
      isUrban: false,
    },
    {
      name: 'SALISTEA DE SUS',
      zip: '108909',
      isUrban: true,
    },
    {
      name: 'SALSIG',
      zip: '108927',
      isUrban: false,
    },
    {
      name: 'GARDANI',
      zip: '108936',
      isUrban: false,
    },
    {
      name: 'SAPANTA',
      zip: '108954',
      isUrban: false,
    },
    {
      name: 'SARASAU',
      zip: '106639',
      isUrban: false,
    },
    {
      name: 'SATULUNG',
      zip: '108721',
      isUrban: false,
    },
    {
      name: 'ARIESU DE PADURE',
      zip: '108730',
      isUrban: false,
    },
    {
      name: 'FERSIG',
      zip: '108749',
      isUrban: false,
    },
    {
      name: 'FINTEUSU MIC',
      zip: '108758',
      isUrban: false,
    },
    {
      name: 'HIDEAGA',
      zip: '108767',
      isUrban: false,
    },
    {
      name: 'MOGOSESTI',
      zip: '108776',
      isUrban: false,
    },
    {
      name: 'PRIBILESTI',
      zip: '108785',
      isUrban: false,
    },
    {
      name: 'SISESTI',
      zip: '109103',
      isUrban: false,
    },
    {
      name: 'BONTAIENI',
      zip: '109112',
      isUrban: false,
    },
    {
      name: 'CETATELE',
      zip: '109121',
      isUrban: false,
    },
    {
      name: 'DANESTI',
      zip: '109130',
      isUrban: false,
    },
    {
      name: 'NEGREIA',
      zip: '109149',
      isUrban: false,
    },
    {
      name: 'PLOPIS',
      zip: '109158',
      isUrban: false,
    },
    {
      name: 'SURDESTI',
      zip: '109167',
      isUrban: false,
    },
    {
      name: 'SOMCUTA MARE',
      zip: '109185',
      isUrban: true,
    },
    {
      name: 'BUCIUMI',
      zip: '109194',
      isUrban: true,
    },
    {
      name: 'BUTEASA',
      zip: '109201',
      isUrban: true,
    },
    {
      name: 'CIOLT',
      zip: '109210',
      isUrban: true,
    },
    {
      name: 'CODRU BUTESII',
      zip: '109229',
      isUrban: true,
    },
    {
      name: 'FINTEUSU MARE',
      zip: '109238',
      isUrban: true,
    },
    {
      name: 'HOVRILA',
      zip: '109247',
      isUrban: true,
    },
    {
      name: 'VALENII SOMCUTEI',
      zip: '109256',
      isUrban: true,
    },
    {
      name: 'STRAMTURA',
      zip: '109014',
      isUrban: false,
    },
    {
      name: 'GLOD',
      zip: '109023',
      isUrban: false,
    },
    {
      name: 'SLATIOARA',
      zip: '109032',
      isUrban: false,
    },
    {
      name: 'SUCIU DE SUS',
      zip: '109050',
      isUrban: false,
    },
    {
      name: 'GROSII TIBLESULUI',
      zip: '109069',
      isUrban: false,
    },
    {
      name: 'LARGA',
      zip: '109078',
      isUrban: false,
    },
    {
      name: 'SUCIU DE JOS',
      zip: '109087',
      isUrban: false,
    },
    {
      name: 'TAUTII-MAGHERAUS',
      zip: '106470',
      isUrban: true,
    },
    {
      name: 'BAITA',
      zip: '106489',
      isUrban: true,
    },
    {
      name: 'BOZANTA MARE',
      zip: '106498',
      isUrban: true,
    },
    {
      name: 'BUSAG',
      zip: '106504',
      isUrban: true,
    },
    {
      name: 'MERISOR',
      zip: '106513',
      isUrban: true,
    },
    {
      name: 'NISTRU',
      zip: '106522',
      isUrban: true,
    },
    {
      name: 'ULMOASA',
      zip: '106531',
      isUrban: true,
    },
    {
      name: 'ULMENI',
      zip: '109274',
      isUrban: true,
    },
    {
      name: 'ARDUZEL',
      zip: '109283',
      isUrban: true,
    },
    {
      name: 'CHELINTA',
      zip: '109292',
      isUrban: true,
    },
    {
      name: 'MANAU',
      zip: '109309',
      isUrban: true,
    },
    {
      name: 'SOMES-UILEAC',
      zip: '109318',
      isUrban: true,
    },
    {
      name: 'TICAU',
      zip: '109336',
      isUrban: true,
    },
    {
      name: 'TOHAT',
      zip: '109327',
      isUrban: true,
    },
    {
      name: 'VICEA',
      zip: '109345',
      isUrban: true,
    },
    {
      name: 'VADU IZEI',
      zip: '106657',
      isUrban: false,
    },
    {
      name: 'VALEA STEJARULUI',
      zip: '106666',
      isUrban: false,
    },
    {
      name: 'VALEA CHIOARULUI',
      zip: '109363',
      isUrban: false,
    },
    {
      name: 'CURTUIUSU MARE',
      zip: '109372',
      isUrban: false,
    },
    {
      name: 'DURUSA',
      zip: '109381',
      isUrban: false,
    },
    {
      name: 'FERICEA',
      zip: '109390',
      isUrban: false,
    },
    {
      name: 'MESTEACAN',
      zip: '109407',
      isUrban: false,
    },
    {
      name: 'VARAI',
      zip: '109416',
      isUrban: false,
    },
    {
      name: 'VIMA MICA',
      zip: '109434',
      isUrban: false,
    },
    {
      name: 'ASPRA',
      zip: '109443',
      isUrban: false,
    },
    {
      name: 'DEALU CORBULUI',
      zip: '109452',
      isUrban: false,
    },
    {
      name: 'JUGASTRENI',
      zip: '109461',
      isUrban: false,
    },
    {
      name: 'PETERITEA',
      zip: '109470',
      isUrban: false,
    },
    {
      name: 'SALNITA',
      zip: '109489',
      isUrban: false,
    },
    {
      name: 'VIMA MARE',
      zip: '109498',
      isUrban: false,
    },
    {
      name: 'VISEU DE JOS',
      zip: '109513',
      isUrban: false,
    },
    {
      name: 'COAS',
      zip: '108829',
      isUrban: false,
    }
  ],
  MS: [
    {
      name: 'TARGU MURES',
      zip: '114328',
      isUrban: true,
    },
    {
      name: 'MURESENI',
      zip: '114337',
      isUrban: true,
    },
    {
      name: 'REMETEA',
      zip: '114346',
      isUrban: true,
    },
    {
      name: 'REGHIN',
      zip: '114818',
      isUrban: true,
    },
    {
      name: 'APALINA',
      zip: '114827',
      isUrban: true,
    },
    {
      name: 'IERNUTENI',
      zip: '114836',
      isUrban: true,
    },
    {
      name: 'SIGHISOARA',
      zip: '114523',
      isUrban: true,
    },
    {
      name: 'ANGOFA',
      zip: '114532',
      isUrban: true,
    },
    {
      name: 'AUREL VLAICU',
      zip: '114541',
      isUrban: true,
    },
    {
      name: 'HETIUR',
      zip: '114596',
      isUrban: true,
    },
    {
      name: 'RORA',
      zip: '114550',
      isUrban: true,
    },
    {
      name: 'SOROMICLEA',
      zip: '114569',
      isUrban: true,
    },
    {
      name: 'VENCHI',
      zip: '114578',
      isUrban: true,
    },
    {
      name: 'VIILOR',
      zip: '114587',
      isUrban: true,
    },
    {
      name: 'IERNUT',
      zip: '117836',
      isUrban: true,
    },
    {
      name: 'CIPAU',
      zip: '117845',
      isUrban: true,
    },
    {
      name: 'DEAG',
      zip: '117854',
      isUrban: true,
    },
    {
      name: 'LECHINTA',
      zip: '117863',
      isUrban: true,
    },
    {
      name: 'OARBA DE MURES',
      zip: '117872',
      isUrban: true,
    },
    {
      name: 'PORUMBAC',
      zip: '117881',
      isUrban: true,
    },
    {
      name: 'RACAMET',
      zip: '117890',
      isUrban: true,
    },
    {
      name: 'SALCUD',
      zip: '117907',
      isUrban: true,
    },
    {
      name: 'SFANTU GHEORGHE',
      zip: '117916',
      isUrban: true,
    },
    {
      name: 'LUDUS',
      zip: '114729',
      isUrban: true,
    },
    {
      name: 'AVRAMESTI',
      zip: '114738',
      isUrban: true,
    },
    {
      name: 'CIOARGA',
      zip: '114747',
      isUrban: true,
    },
    {
      name: 'CIURGAU',
      zip: '114756',
      isUrban: true,
    },
    {
      name: 'FUNDATURA',
      zip: '114765',
      isUrban: true,
    },
    {
      name: 'GHEJA',
      zip: '114774',
      isUrban: true,
    },
    {
      name: 'ROSIORI',
      zip: '114783',
      isUrban: true,
    },
    {
      name: 'SOVATA',
      zip: '114863',
      isUrban: true,
    },
    {
      name: 'CAPETI',
      zip: '114872',
      isUrban: true,
    },
    {
      name: 'ILIESI',
      zip: '114881',
      isUrban: true,
    },
    {
      name: 'SACADAT',
      zip: '114890',
      isUrban: true,
    },
    {
      name: 'SARATENI',
      zip: '114907',
      isUrban: false,
    },
    {
      name: 'TARNAVENI',
      zip: '114934',
      isUrban: true,
    },
    {
      name: 'BOBOHALMA',
      zip: '114961',
      isUrban: true,
    },
    {
      name: 'BOTORCA',
      zip: '114943',
      isUrban: true,
    },
    {
      name: 'ACATARI',
      zip: '114989',
      isUrban: false,
    },
    {
      name: 'CORBESTI',
      zip: '114998',
      isUrban: false,
    },
    {
      name: 'GAIESTI',
      zip: '115003',
      isUrban: false,
    },
    {
      name: 'GRUISOR',
      zip: '115012',
      isUrban: false,
    },
    {
      name: 'MURGESTI',
      zip: '115021',
      isUrban: false,
    },
    {
      name: 'ROTENI',
      zip: '115030',
      isUrban: false,
    },
    {
      name: 'STEJERIS',
      zip: '115049',
      isUrban: false,
    },
    {
      name: 'SUVEICA',
      zip: '115058',
      isUrban: false,
    },
    {
      name: 'VALENII',
      zip: '115067',
      isUrban: false,
    },
    {
      name: 'ADAMUS',
      zip: '115085',
      isUrban: false,
    },
    {
      name: 'CHINCIUS',
      zip: '115094',
      isUrban: false,
    },
    {
      name: 'CORNESTI',
      zip: '115101',
      isUrban: false,
    },
    {
      name: 'CRAIESTI',
      zip: '115110',
      isUrban: false,
    },
    {
      name: 'DAMBAU',
      zip: '115129',
      isUrban: false,
    },
    {
      name: 'HEREPEA',
      zip: '115138',
      isUrban: false,
    },
    {
      name: 'ALBESTI',
      zip: '114612',
      isUrban: false,
    },
    {
      name: 'BARLIBASOAIA',
      zip: '114621',
      isUrban: false,
    },
    {
      name: 'BOIU',
      zip: '114630',
      isUrban: false,
    },
    {
      name: 'JACU',
      zip: '114649',
      isUrban: false,
    },
    {
      name: 'SAPARTOC',
      zip: '114658',
      isUrban: false,
    },
    {
      name: 'TOPA',
      zip: '114667',
      isUrban: false,
    },
    {
      name: 'VALEA ALBESTIULUI',
      zip: '114676',
      isUrban: false,
    },
    {
      name: 'VALEA DAII',
      zip: '114685',
      isUrban: false,
    },
    {
      name: 'VALEA SAPARTOCULUI',
      zip: '114694',
      isUrban: false,
    },
    {
      name: 'ALUNIS',
      zip: '115156',
      isUrban: false,
    },
    {
      name: 'FITCAU',
      zip: '115165',
      isUrban: false,
    },
    {
      name: 'LUNCA MURESULUI',
      zip: '115174',
      isUrban: false,
    },
    {
      name: 'APOLD',
      zip: '115192',
      isUrban: false,
    },
    {
      name: 'DAIA',
      zip: '115209',
      isUrban: false,
    },
    {
      name: 'SAES',
      zip: '115218',
      isUrban: false,
    },
    {
      name: 'VULCAN',
      zip: '115227',
      isUrban: false,
    },
    {
      name: 'ATINTIS',
      zip: '115245',
      isUrban: false,
    },
    {
      name: 'BOTEZ',
      zip: '115254',
      isUrban: false,
    },
    {
      name: 'CECALACA',
      zip: '115263',
      isUrban: false,
    },
    {
      name: 'ISTIHAZA',
      zip: '115272',
      isUrban: false,
    },
    {
      name: 'MALDAOCI',
      zip: '115281',
      isUrban: false,
    },
    {
      name: 'SANIACOB',
      zip: '115290',
      isUrban: false,
    },
    {
      name: 'BAGACIU',
      zip: '115584',
      isUrban: false,
    },
    {
      name: 'DELENII',
      zip: '115593',
      isUrban: false,
    },
    {
      name: 'BAHNEA',
      zip: '115316',
      isUrban: false,
    },
    {
      name: 'BERNADEA',
      zip: '115325',
      isUrban: false,
    },
    {
      name: 'CUND',
      zip: '115334',
      isUrban: false,
    },
    {
      name: 'DAIA',
      zip: '115343',
      isUrban: false,
    },
    {
      name: 'GOGAN',
      zip: '115352',
      isUrban: false,
    },
    {
      name: 'IDICIU',
      zip: '115361',
      isUrban: false,
    },
    {
      name: 'LEPINDEA',
      zip: '115370',
      isUrban: false,
    },
    {
      name: 'BALA',
      zip: '115619',
      isUrban: false,
    },
    {
      name: 'ERCEA',
      zip: '115628',
      isUrban: false,
    },
    {
      name: 'BALAUSERI',
      zip: '115646',
      isUrban: false,
    },
    {
      name: 'AGRISTEU',
      zip: '115655',
      isUrban: false,
    },
    {
      name: 'CHENDU',
      zip: '115664',
      isUrban: false,
    },
    {
      name: 'DUMITRENI',
      zip: '115673',
      isUrban: false,
    },
    {
      name: 'FILITELNIC',
      zip: '115682',
      isUrban: false,
    },
    {
      name: 'SENEREUS',
      zip: '115691',
      isUrban: false,
    },
    {
      name: 'BAND',
      zip: '115398',
      isUrban: false,
    },
    {
      name: 'DRACULEA BANDULUI',
      zip: '115405',
      isUrban: false,
    },
    {
      name: 'FANATE',
      zip: '115414',
      isUrban: false,
    },
    {
      name: 'FANATELE MADARASULUI',
      zip: '115423',
      isUrban: false,
    },
    {
      name: 'ISTAN-TAU',
      zip: '115432',
      isUrban: false,
    },
    {
      name: 'MADARAS',
      zip: '115441',
      isUrban: false,
    },
    {
      name: 'MARASESTI',
      zip: '115450',
      isUrban: false,
    },
    {
      name: 'NEGRENII DE CAMPIE',
      zip: '115469',
      isUrban: false,
    },
    {
      name: 'OROIU',
      zip: '115478',
      isUrban: false,
    },
    {
      name: 'PETEA',
      zip: '115487',
      isUrban: false,
    },
    {
      name: 'TIPTELNIC',
      zip: '115496',
      isUrban: false,
    },
    {
      name: 'VALEA MARE',
      zip: '115502',
      isUrban: false,
    },
    {
      name: 'VALEA RECE',
      zip: '115511',
      isUrban: false,
    },
    {
      name: 'BATOS',
      zip: '115539',
      isUrban: false,
    },
    {
      name: 'DEDRAD',
      zip: '115548',
      isUrban: false,
    },
    {
      name: 'GORENI',
      zip: '115557',
      isUrban: false,
    },
    {
      name: 'UILA',
      zip: '115566',
      isUrban: false,
    },
    {
      name: 'BEICA DE JOS',
      zip: '115717',
      isUrban: false,
    },
    {
      name: 'BEICA DE SUS',
      zip: '115726',
      isUrban: false,
    },
    {
      name: 'CACUCIU',
      zip: '115735',
      isUrban: false,
    },
    {
      name: 'NADASA',
      zip: '115744',
      isUrban: false,
    },
    {
      name: 'SANMIHAI DE PADURE',
      zip: '115753',
      isUrban: false,
    },
    {
      name: 'SERBENI',
      zip: '115762',
      isUrban: false,
    },
    {
      name: 'BICHIS',
      zip: '115780',
      isUrban: false,
    },
    {
      name: 'GAMBUT',
      zip: '115799',
      isUrban: false,
    },
    {
      name: 'NANDRA',
      zip: '115806',
      isUrban: false,
    },
    {
      name: 'OZD',
      zip: '115815',
      isUrban: false,
    },
    {
      name: 'BOGATA',
      zip: '115833',
      isUrban: false,
    },
    {
      name: 'RANTA',
      zip: '115842',
      isUrban: false,
    },
    {
      name: 'BRANCOVENESTI',
      zip: '115904',
      isUrban: false,
    },
    {
      name: 'IDICEL',
      zip: '115913',
      isUrban: false,
    },
    {
      name: 'IDICEL-PADURE',
      zip: '115922',
      isUrban: false,
    },
    {
      name: 'SACALU DE PADURE',
      zip: '115931',
      isUrban: false,
    },
    {
      name: 'VALENII DE MURES',
      zip: '115940',
      isUrban: false,
    },
    {
      name: 'BREAZA',
      zip: '115860',
      isUrban: false,
    },
    {
      name: 'FILPISU MARE',
      zip: '115879',
      isUrban: false,
    },
    {
      name: 'FILPISU MIC',
      zip: '115888',
      isUrban: false,
    },
    {
      name: 'CEUASU DE CAMPIE',
      zip: '115968',
      isUrban: false,
    },
    {
      name: 'BOZED',
      zip: '115977',
      isUrban: false,
    },
    {
      name: 'CAMPENITA',
      zip: '115986',
      isUrban: false,
    },
    {
      name: 'CULPIU',
      zip: '115995',
      isUrban: false,
    },
    {
      name: 'HERGHELIA',
      zip: '116000',
      isUrban: false,
    },
    {
      name: 'PORUMBENI',
      zip: '116019',
      isUrban: false,
    },
    {
      name: 'SABED',
      zip: '116028',
      isUrban: false,
    },
    {
      name: 'VOINICENI',
      zip: '116037',
      isUrban: false,
    },
    {
      name: 'CHETANI',
      zip: '116055',
      isUrban: false,
    },
    {
      name: 'COASTA GRINDULUI',
      zip: '116064',
      isUrban: false,
    },
    {
      name: 'CORDOS',
      zip: '116073',
      isUrban: false,
    },
    {
      name: 'GIURGIS',
      zip: '116082',
      isUrban: false,
    },
    {
      name: 'GRINDENI',
      zip: '116091',
      isUrban: false,
    },
    {
      name: 'HADARENI',
      zip: '116108',
      isUrban: false,
    },
    {
      name: 'LINT',
      zip: '116117',
      isUrban: false,
    },
    {
      name: 'CHIHERU DE JOS',
      zip: '116135',
      isUrban: false,
    },
    {
      name: 'CHIHERU DE SUS',
      zip: '116144',
      isUrban: false,
    },
    {
      name: 'URISIU DE JOS',
      zip: '116153',
      isUrban: false,
    },
    {
      name: 'URISIU DE SUS',
      zip: '116162',
      isUrban: false,
    },
    {
      name: 'COROISANMARTIN',
      zip: '116180',
      isUrban: false,
    },
    {
      name: 'COROI',
      zip: '116199',
      isUrban: false,
    },
    {
      name: 'ODRIHEI',
      zip: '116206',
      isUrban: false,
    },
    {
      name: 'SOIMUS',
      zip: '116215',
      isUrban: false,
    },
    {
      name: 'COZMA',
      zip: '116233',
      isUrban: false,
    },
    {
      name: 'FANATELE SOCOLULUI',
      zip: '116242',
      isUrban: false,
    },
    {
      name: 'SOCOLU DE CAMPIE',
      zip: '116251',
      isUrban: false,
    },
    {
      name: 'VALEA SASULUI',
      zip: '116260',
      isUrban: false,
    },
    {
      name: 'VALEA UNGURULUI',
      zip: '116279',
      isUrban: false,
    },
    {
      name: 'CRACIUNESTI',
      zip: '116297',
      isUrban: false,
    },
    {
      name: 'BUDIU MIC',
      zip: '116304',
      isUrban: false,
    },
    {
      name: 'BISTRA MURESULUI',
      zip: '116563',
      isUrban: false,
    },
    {
      name: 'FILEA',
      zip: '116572',
      isUrban: false,
    },
    {
      name: 'PIETRIS',
      zip: '116581',
      isUrban: false,
    },
    {
      name: 'EREMITU',
      zip: '116607',
      isUrban: false,
    },
    {
      name: 'CALUGARENI',
      zip: '116616',
      isUrban: false,
    },
    {
      name: 'CAMPU CETATII',
      zip: '116625',
      isUrban: false,
    },
    {
      name: 'DAMIENI',
      zip: '116634',
      isUrban: false,
    },
    {
      name: 'MATRICI',
      zip: '116643',
      isUrban: false,
    },
    {
      name: 'ERNEI',
      zip: '116661',
      isUrban: false,
    },
    {
      name: 'CALUSERI',
      zip: '116670',
      isUrban: false,
    },
    {
      name: 'DUMBRAVIOARA',
      zip: '116689',
      isUrban: false,
    },
    {
      name: 'ICLAND',
      zip: '116698',
      isUrban: false,
    },
    {
      name: 'SACARENI',
      zip: '116705',
      isUrban: false,
    },
    {
      name: 'SANGERU DE PADURE',
      zip: '116714',
      isUrban: false,
    },
    {
      name: 'FANTANELE',
      zip: '116803',
      isUrban: false,
    },
    {
      name: 'BORDOSIU',
      zip: '116812',
      isUrban: false,
    },
    {
      name: 'CALIMANESTI',
      zip: '116821',
      isUrban: false,
    },
    {
      name: 'CIBU',
      zip: '116830',
      isUrban: false,
    },
    {
      name: 'ROUA',
      zip: '116849',
      isUrban: false,
    },
    {
      name: 'VIFOROASA',
      zip: '116858',
      isUrban: false,
    },
    {
      name: 'FARAGAU',
      zip: '116732',
      isUrban: false,
    },
    {
      name: 'FANATE',
      zip: '116741',
      isUrban: false,
    },
    {
      name: 'HODAIA',
      zip: '116750',
      isUrban: false,
    },
    {
      name: 'ONUCA',
      zip: '116769',
      isUrban: false,
    },
    {
      name: 'POARTA',
      zip: '116778',
      isUrban: false,
    },
    {
      name: 'TONCIU',
      zip: '116787',
      isUrban: false,
    },
    {
      name: 'GALESTI',
      zip: '116876',
      isUrban: false,
    },
    {
      name: 'ADRIANU MARE',
      zip: '116885',
      isUrban: false,
    },
    {
      name: 'ADRIANU MIC',
      zip: '116894',
      isUrban: false,
    },
    {
      name: 'BEDENI',
      zip: '116901',
      isUrban: false,
    },
    {
      name: 'MAIAD',
      zip: '116910',
      isUrban: false,
    },
    {
      name: 'TROITA',
      zip: '116929',
      isUrban: false,
    },
    {
      name: 'GANESTI',
      zip: '116947',
      isUrban: false,
    },
    {
      name: 'CUSTELNIC',
      zip: '116956',
      isUrban: true,
    },
    {
      name: 'PAUCISOARA',
      zip: '116965',
      isUrban: false,
    },
    {
      name: 'SUB PADURE',
      zip: '116974',
      isUrban: false,
    },
    {
      name: 'GHEORGHE DOJA',
      zip: '116992',
      isUrban: false,
    },
    {
      name: 'ILIENI',
      zip: '117006',
      isUrban: false,
    },
    {
      name: 'LEORDENI',
      zip: '117015',
      isUrban: false,
    },
    {
      name: 'SATU NOU',
      zip: '117024',
      isUrban: false,
    },
    {
      name: 'TIRIMIA',
      zip: '117033',
      isUrban: false,
    },
    {
      name: 'GHINDARI',
      zip: '117051',
      isUrban: false,
    },
    {
      name: 'ABUD',
      zip: '117060',
      isUrban: false,
    },
    {
      name: 'CEIE',
      zip: '117079',
      isUrban: false,
    },
    {
      name: 'CHIBED',
      zip: '117088',
      isUrban: false,
    },
    {
      name: 'SOLOCMA',
      zip: '117097',
      isUrban: false,
    },
    {
      name: 'TREI SATE',
      zip: '117104',
      isUrban: false,
    },
    {
      name: 'GLODENI',
      zip: '117122',
      isUrban: false,
    },
    {
      name: 'MERISOR',
      zip: '117131',
      isUrban: false,
    },
    {
      name: 'MOISA',
      zip: '117140',
      isUrban: false,
    },
    {
      name: 'PACURENI',
      zip: '117159',
      isUrban: false,
    },
    {
      name: 'PAINGENI',
      zip: '117168',
      isUrban: false,
    },
    {
      name: 'GORNESTI',
      zip: '117186',
      isUrban: false,
    },
    {
      name: 'IARA DE MURES',
      zip: '117195',
      isUrban: false,
    },
    {
      name: 'ILIOARA',
      zip: '117202',
      isUrban: false,
    },
    {
      name: 'MURA MARE',
      zip: '117211',
      isUrban: false,
    },
    {
      name: 'MURA MICA',
      zip: '117220',
      isUrban: false,
    },
    {
      name: 'PADURENI',
      zip: '117239',
      isUrban: false,
    },
    {
      name: 'PERIS',
      zip: '117248',
      isUrban: false,
    },
    {
      name: 'PETRILACA DE MURES',
      zip: '117257',
      isUrban: false,
    },
    {
      name: 'TELEAC',
      zip: '117266',
      isUrban: false,
    },
    {
      name: 'GREBENISU DE CAMPIE',
      zip: '117284',
      isUrban: false,
    },
    {
      name: 'LEORINTA',
      zip: '117293',
      isUrban: false,
    },
    {
      name: 'VALEA SANPETRULUI',
      zip: '117300',
      isUrban: false,
    },
    {
      name: 'GURGHIU',
      zip: '117328',
      isUrban: false,
    },
    {
      name: 'ADRIAN',
      zip: '117337',
      isUrban: false,
    },
    {
      name: 'CASVA',
      zip: '117346',
      isUrban: false,
    },
    {
      name: 'COMORI',
      zip: '117355',
      isUrban: false,
    },
    {
      name: 'FUNDOAIA',
      zip: '117364',
      isUrban: false,
    },
    {
      name: 'GLAJARIE',
      zip: '117373',
      isUrban: false,
    },
    {
      name: 'LARGA',
      zip: '117382',
      isUrban: false,
    },
    {
      name: 'ORSOVA',
      zip: '117391',
      isUrban: false,
    },
    {
      name: 'ORSOVA-PADURE',
      zip: '117408',
      isUrban: false,
    },
    {
      name: 'PAULOAIA',
      zip: '117417',
      isUrban: false,
    },
    {
      name: 'HODAC',
      zip: '117435',
      isUrban: false,
    },
    {
      name: 'ARSITA',
      zip: '117444',
      isUrban: false,
    },
    {
      name: 'BICASU',
      zip: '117453',
      isUrban: false,
    },
    {
      name: 'DUBISTEA DE PADURE',
      zip: '117462',
      isUrban: false,
    },
    {
      name: 'MIRIGIOAIA',
      zip: '117471',
      isUrban: false,
    },
    {
      name: 'TOACA',
      zip: '117480',
      isUrban: false,
    },
    {
      name: 'URICEA',
      zip: '117499',
      isUrban: false,
    },
    {
      name: 'HODOSA',
      zip: '117514',
      isUrban: false,
    },
    {
      name: 'IHOD',
      zip: '117523',
      isUrban: false,
    },
    {
      name: 'ISLA',
      zip: '117532',
      isUrban: false,
    },
    {
      name: 'SAMBRIAS',
      zip: '117541',
      isUrban: false,
    },
    {
      name: 'IBANESTI',
      zip: '117569',
      isUrban: false,
    },
    {
      name: 'BLIDIREASA',
      zip: '117578',
      isUrban: false,
    },
    {
      name: 'BRADETELU',
      zip: '117587',
      isUrban: false,
    },
    {
      name: 'DULCEA',
      zip: '117596',
      isUrban: false,
    },
    {
      name: 'IBANESTI-PADURE',
      zip: '117603',
      isUrban: false,
    },
    {
      name: 'LAPUSNA',
      zip: '117612',
      isUrban: false,
    },
    {
      name: 'PARAU MARE',
      zip: '117621',
      isUrban: false,
    },
    {
      name: 'TIREU',
      zip: '117630',
      isUrban: false,
    },
    {
      name: 'TISIEU',
      zip: '117649',
      isUrban: false,
    },
    {
      name: 'ZIMTI',
      zip: '117658',
      isUrban: false,
    },
    {
      name: 'ICLANZEL',
      zip: '117676',
      isUrban: false,
    },
    {
      name: 'CAPUSU DE CAMPIE',
      zip: '117685',
      isUrban: false,
    },
    {
      name: 'CHISALITA',
      zip: '117694',
      isUrban: false,
    },
    {
      name: 'DUPA DEAL',
      zip: '117701',
      isUrban: false,
    },
    {
      name: 'FANATE',
      zip: '117710',
      isUrban: false,
    },
    {
      name: 'FANATELE CAPUSULUI',
      zip: '117729',
      isUrban: false,
    },
    {
      name: 'GHIDASTEU',
      zip: '117738',
      isUrban: false,
    },
    {
      name: 'ICLANDU MARE',
      zip: '117747',
      isUrban: false,
    },
    {
      name: 'MADARASENI',
      zip: '117756',
      isUrban: false,
    },
    {
      name: 'TABLASENI',
      zip: '117765',
      isUrban: false,
    },
    {
      name: 'VALEA ICLANDULUI',
      zip: '117774',
      isUrban: false,
    },
    {
      name: 'IDECIU DE JOS',
      zip: '117792',
      isUrban: false,
    },
    {
      name: 'DELENI',
      zip: '117809',
      isUrban: false,
    },
    {
      name: 'IDECIU DE SUS',
      zip: '117818',
      isUrban: false,
    },
    {
      name: 'LIVEZENI',
      zip: '117934',
      isUrban: false,
    },
    {
      name: 'BOZENI',
      zip: '117943',
      isUrban: false,
    },
    {
      name: 'CORUNCA',
      zip: '117952',
      isUrban: false,
    },
    {
      name: 'IVANESTI',
      zip: '117961',
      isUrban: false,
    },
    {
      name: 'POIENITA',
      zip: '117970',
      isUrban: false,
    },
    {
      name: 'SANISOR',
      zip: '117989',
      isUrban: false,
    },
    {
      name: 'LUNCA',
      zip: '118003',
      isUrban: false,
    },
    {
      name: 'BAITA',
      zip: '118012',
      isUrban: false,
    },
    {
      name: 'FRUNZENI',
      zip: '118021',
      isUrban: false,
    },
    {
      name: 'LOGIG',
      zip: '118030',
      isUrban: false,
    },
    {
      name: 'SANTU',
      zip: '118049',
      isUrban: false,
    },
    {
      name: 'LUNCA BRADULUI',
      zip: '118067',
      isUrban: false,
    },
    {
      name: 'NEAGRA',
      zip: '118076',
      isUrban: false,
    },
    {
      name: 'SALARD',
      zip: '118085',
      isUrban: false,
    },
    {
      name: 'MAGHERANI',
      zip: '118101',
      isUrban: false,
    },
    {
      name: 'BARA',
      zip: '118129',
      isUrban: false,
    },
    {
      name: 'BERENI',
      zip: '118110',
      isUrban: false,
    },
    {
      name: 'CANDU',
      zip: '118138',
      isUrban: false,
    },
    {
      name: 'DROJDII',
      zip: '118147',
      isUrban: false,
    },
    {
      name: 'EREMIENI',
      zip: '118156',
      isUrban: false,
    },
    {
      name: 'MAIA',
      zip: '118165',
      isUrban: false,
    },
    {
      name: 'MARCULENI',
      zip: '118174',
      isUrban: false,
    },
    {
      name: 'SILEA NIRAJULUI',
      zip: '118183',
      isUrban: false,
    },
    {
      name: 'TORBA',
      zip: '118192',
      isUrban: false,
    },
    {
      name: 'MICA',
      zip: '118218',
      isUrban: false,
    },
    {
      name: 'ABUS',
      zip: '118227',
      isUrban: false,
    },
    {
      name: 'CAPALNA DE SUS',
      zip: '118236',
      isUrban: false,
    },
    {
      name: 'CEUAS',
      zip: '118245',
      isUrban: false,
    },
    {
      name: 'DEAJ',
      zip: '118254',
      isUrban: false,
    },
    {
      name: 'HARANGLAB',
      zip: '118263',
      isUrban: false,
    },
    {
      name: 'SOMOSTELNIC',
      zip: '118272',
      isUrban: false,
    },
    {
      name: 'MIERCUREA NIRAJULUI',
      zip: '118290',
      isUrban: true,
    },
    {
      name: 'BEU',
      zip: '118307',
      isUrban: true,
    },
    {
      name: 'DUMITRESTII',
      zip: '118316',
      isUrban: true,
    },
    {
      name: 'LAURENI',
      zip: '118325',
      isUrban: true,
    },
    {
      name: 'MOSUNI',
      zip: '118334',
      isUrban: true,
    },
    {
      name: 'SARDU NIRAJULUI',
      zip: '118343',
      isUrban: true,
    },
    {
      name: 'TAMPA',
      zip: '118352',
      isUrban: true,
    },
    {
      name: 'VETA',
      zip: '118361',
      isUrban: true,
    },
    {
      name: 'MIHESU DE CAMPIE',
      zip: '118389',
      isUrban: false,
    },
    {
      name: 'BUJOR',
      zip: '118398',
      isUrban: false,
    },
    {
      name: 'CIRHAGAU',
      zip: '118405',
      isUrban: false,
    },
    {
      name: 'GROAPA RADAII',
      zip: '118414',
      isUrban: false,
    },
    {
      name: 'MOGOAIA',
      zip: '118423',
      isUrban: false,
    },
    {
      name: 'RAZOARE',
      zip: '118432',
      isUrban: false,
    },
    {
      name: 'SAULITA',
      zip: '118441',
      isUrban: false,
    },
    {
      name: 'STEFANCA',
      zip: '118450',
      isUrban: false,
    },
    {
      name: 'NADES',
      zip: '118478',
      isUrban: false,
    },
    {
      name: 'MAGHERUS',
      zip: '118487',
      isUrban: false,
    },
    {
      name: 'PIPEA',
      zip: '118496',
      isUrban: false,
    },
    {
      name: 'TIGMANDRU',
      zip: '118502',
      isUrban: false,
    },
    {
      name: 'NEAUA',
      zip: '118520',
      isUrban: false,
    },
    {
      name: 'GHINESTI',
      zip: '118539',
      isUrban: false,
    },
    {
      name: 'RIGMANI',
      zip: '118548',
      isUrban: false,
    },
    {
      name: 'SANSIMION',
      zip: '118557',
      isUrban: false,
    },
    {
      name: 'VADAS',
      zip: '118566',
      isUrban: false,
    },
    {
      name: 'OGRA',
      zip: '118584',
      isUrban: false,
    },
    {
      name: 'DILEU VECHI',
      zip: '118593',
      isUrban: false,
    },
    {
      name: 'GIULUS',
      zip: '118600',
      isUrban: false,
    },
    {
      name: 'LASCUD',
      zip: '118619',
      isUrban: false,
    },
    {
      name: 'VAIDEIU',
      zip: '118628',
      isUrban: false,
    },
    {
      name: 'PANET',
      zip: '118708',
      isUrban: false,
    },
    {
      name: 'BERGHIA',
      zip: '118717',
      isUrban: false,
    },
    {
      name: 'CUIESD',
      zip: '118726',
      isUrban: false,
    },
    {
      name: 'HARTAU',
      zip: '118735',
      isUrban: false,
    },
    {
      name: 'SANTIOANA DE MURES',
      zip: '118744',
      isUrban: false,
    },
    {
      name: 'PAPIU ILARIAN',
      zip: '118646',
      isUrban: false,
    },
    {
      name: 'DOBRA',
      zip: '118655',
      isUrban: false,
    },
    {
      name: 'MERISORU',
      zip: '118664',
      isUrban: false,
    },
    {
      name: 'SANDRU',
      zip: '118673',
      isUrban: false,
    },
    {
      name: 'URSOAIA',
      zip: '118682',
      isUrban: false,
    },
    {
      name: 'PASARENI',
      zip: '118762',
      isUrban: false,
    },
    {
      name: 'BOLINTINENI',
      zip: '118771',
      isUrban: false,
    },
    {
      name: 'GALATENI',
      zip: '118780',
      isUrban: false,
    },
    {
      name: 'PETELEA',
      zip: '118806',
      isUrban: false,
    },
    {
      name: 'HABIC',
      zip: '118815',
      isUrban: false,
    },
    {
      name: 'POGACEAUA',
      zip: '118833',
      isUrban: false,
    },
    {
      name: 'BOLOGAIA',
      zip: '118842',
      isUrban: false,
    },
    {
      name: 'CIULEA',
      zip: '118851',
      isUrban: false,
    },
    {
      name: 'DELENI',
      zip: '118860',
      isUrban: false,
    },
    {
      name: 'FANTANA BABII',
      zip: '118879',
      isUrban: false,
    },
    {
      name: 'PARAU CRUCII',
      zip: '118888',
      isUrban: false,
    },
    {
      name: 'SCURTA',
      zip: '118897',
      isUrban: false,
    },
    {
      name: 'SICELE',
      zip: '118904',
      isUrban: false,
    },
    {
      name: 'VALEA SANPETRULUI',
      zip: '118913',
      isUrban: false,
    },
    {
      name: 'VALENI',
      zip: '118922',
      isUrban: false,
    },
    {
      name: 'RASTOLITA',
      zip: '118940',
      isUrban: false,
    },
    {
      name: 'ANDRENEASA',
      zip: '118959',
      isUrban: false,
    },
    {
      name: 'BORZIA',
      zip: '118968',
      isUrban: false,
    },
    {
      name: 'GALAOAIA',
      zip: '118977',
      isUrban: false,
    },
    {
      name: 'IOD',
      zip: '118986',
      isUrban: false,
    },
    {
      name: 'RACIU',
      zip: '119000',
      isUrban: false,
    },
    {
      name: 'CACIULATA',
      zip: '119019',
      isUrban: false,
    },
    {
      name: 'COASTA MARE',
      zip: '119028',
      isUrban: false,
    },
    {
      name: 'COTORINAU',
      zip: '119037',
      isUrban: false,
    },
    {
      name: 'CURETE',
      zip: '119046',
      isUrban: false,
    },
    {
      name: 'HAGAU',
      zip: '119055',
      isUrban: false,
    },
    {
      name: 'LENIS',
      zip: '119064',
      isUrban: false,
    },
    {
      name: 'NIMA RACIULUI',
      zip: '119073',
      isUrban: false,
    },
    {
      name: 'OBARSIE',
      zip: '119082',
      isUrban: false,
    },
    {
      name: 'PARAU CRUCII',
      zip: '119091',
      isUrban: false,
    },
    {
      name: 'SANMARTINU DE CAMPIE',
      zip: '119108',
      isUrban: false,
    },
    {
      name: 'ULIES',
      zip: '119117',
      isUrban: false,
    },
    {
      name: 'VALEA SANMARTINULUI',
      zip: '119135',
      isUrban: false,
    },
    {
      name: 'VALEA SEACA',
      zip: '119126',
      isUrban: false,
    },
    {
      name: 'VALEA ULIESULUI',
      zip: '119144',
      isUrban: false,
    },
    {
      name: 'RUSII-MUNTI',
      zip: '119162',
      isUrban: false,
    },
    {
      name: 'MAIORESTI',
      zip: '119171',
      isUrban: false,
    },
    {
      name: 'MORARENI',
      zip: '119180',
      isUrban: false,
    },
    {
      name: 'SEBES',
      zip: '119199',
      isUrban: false,
    },
    {
      name: 'SANCRAIU DE MURES',
      zip: '114391',
      isUrban: false,
    },
    {
      name: 'NAZNA',
      zip: '114408',
      isUrban: false,
    },
    {
      name: 'SANGEORGIU DE MURES',
      zip: '114426',
      isUrban: false,
    },
    {
      name: 'COTUS',
      zip: '114435',
      isUrban: false,
    },
    {
      name: 'TOFALAU',
      zip: '114444',
      isUrban: false,
    },
    {
      name: 'SANGEORGIU DE PADURE',
      zip: '119340',
      isUrban: true,
    },
    {
      name: 'BEZID',
      zip: '119359',
      isUrban: true,
    },
    {
      name: 'BEZIDU NOU',
      zip: '119368',
      isUrban: true,
    },
    {
      name: 'LOTU',
      zip: '119377',
      isUrban: true,
    },
    {
      name: 'SANGER',
      zip: '119395',
      isUrban: false,
    },
    {
      name: 'BARZA',
      zip: '119402',
      isUrban: false,
    },
    {
      name: 'CIPAIENI',
      zip: '119411',
      isUrban: false,
    },
    {
      name: 'DALU',
      zip: '119420',
      isUrban: false,
    },
    {
      name: 'PRIPOARE',
      zip: '119439',
      isUrban: false,
    },
    {
      name: 'VALISOARA',
      zip: '119448',
      isUrban: false,
    },
    {
      name: 'CLOASTERF',
      zip: '119224',
      isUrban: false,
    },
    {
      name: 'MIHAI VITEAZU',
      zip: '119233',
      isUrban: false,
    },
    {
      name: 'SAULIA',
      zip: '119769',
      isUrban: false,
    },
    {
      name: 'LEORINTA-SAULIA',
      zip: '119778',
      isUrban: false,
    },
    {
      name: 'MACICASESTI',
      zip: '119787',
      isUrban: false,
    },
    {
      name: 'PADUREA',
      zip: '119796',
      isUrban: false,
    },
    {
      name: 'SINCAI',
      zip: '119812',
      isUrban: false,
    },
    {
      name: 'LECHINCIOARA',
      zip: '119821',
      isUrban: false,
    },
    {
      name: 'PUSTA',
      zip: '119830',
      isUrban: false,
    },
    {
      name: 'SINCAI-FANATE',
      zip: '119849',
      isUrban: false,
    },
    {
      name: 'SOLOVASTRU',
      zip: '119607',
      isUrban: false,
    },
    {
      name: 'JABENITA',
      zip: '119616',
      isUrban: false,
    },
    {
      name: 'STANCENI',
      zip: '119634',
      isUrban: false,
    },
    {
      name: 'CIOBOTANI',
      zip: '119643',
      isUrban: false,
    },
    {
      name: 'MESTERA',
      zip: '119652',
      isUrban: false,
    },
    {
      name: 'SUPLAC',
      zip: '119670',
      isUrban: false,
    },
    {
      name: 'IDRIFAIA',
      zip: '119689',
      isUrban: false,
    },
    {
      name: 'LASLAU MARE',
      zip: '119698',
      isUrban: false,
    },
    {
      name: 'LASLAU MIC',
      zip: '119705',
      isUrban: false,
    },
    {
      name: 'VAIDACUTA',
      zip: '119714',
      isUrban: false,
    },
    {
      name: 'SUSENI',
      zip: '119732',
      isUrban: false,
    },
    {
      name: 'LUIERIU',
      zip: '119741',
      isUrban: false,
    },
    {
      name: 'TAURENI',
      zip: '119867',
      isUrban: false,
    },
    {
      name: 'FANATE',
      zip: '119876',
      isUrban: false,
    },
    {
      name: 'MOARA DE JOS',
      zip: '119885',
      isUrban: false,
    },
    {
      name: 'UNGHENI',
      zip: '119901',
      isUrban: true,
    },
    {
      name: 'CERGHID',
      zip: '119910',
      isUrban: true,
    },
    {
      name: 'CERGHIZEL',
      zip: '119929',
      isUrban: true,
    },
    {
      name: 'MORESTI',
      zip: '119938',
      isUrban: true,
    },
    {
      name: 'RECEA',
      zip: '119947',
      isUrban: true,
    },
    {
      name: 'SAUSA',
      zip: '119956',
      isUrban: true,
    },
    {
      name: 'VIDRASAU',
      zip: '119965',
      isUrban: true,
    },
    {
      name: 'VALEA LARGA',
      zip: '119983',
      isUrban: false,
    },
    {
      name: 'GRADINI',
      zip: '119992',
      isUrban: false,
    },
    {
      name: 'MALAESTI',
      zip: '120003',
      isUrban: false,
    },
    {
      name: 'PODURI',
      zip: '120012',
      isUrban: false,
    },
    {
      name: 'VALEA FRATIEI',
      zip: '120021',
      isUrban: false,
    },
    {
      name: 'VALEA GLODULUI',
      zip: '120030',
      isUrban: false,
    },
    {
      name: 'VALEA PADURII',
      zip: '120049',
      isUrban: false,
    },
    {
      name: 'VALEA SURII',
      zip: '120058',
      isUrban: false,
    },
    {
      name: 'VALEA URIESULUI',
      zip: '120067',
      isUrban: false,
    },
    {
      name: 'VANATORI',
      zip: '120263',
      isUrban: false,
    },
    {
      name: 'ARCHITA',
      zip: '120272',
      isUrban: false,
    },
    {
      name: 'FELEAG',
      zip: '120281',
      isUrban: false,
    },
    {
      name: 'MURENI',
      zip: '120290',
      isUrban: false,
    },
    {
      name: 'SOARD',
      zip: '120307',
      isUrban: false,
    },
    {
      name: 'VARGATA',
      zip: '120085',
      isUrban: false,
    },
    {
      name: 'GRAUSORUL',
      zip: '120094',
      isUrban: false,
    },
    {
      name: 'MITRESTI',
      zip: '120101',
      isUrban: false,
    },
    {
      name: 'VADU',
      zip: '120110',
      isUrban: false,
    },
    {
      name: 'VALEA',
      zip: '120129',
      isUrban: false,
    },
    {
      name: 'VATAVA',
      zip: '120147',
      isUrban: false,
    },
    {
      name: 'DUMBRAVA',
      zip: '120156',
      isUrban: false,
    },
    {
      name: 'RAPA DE JOS',
      zip: '120165',
      isUrban: false,
    },
    {
      name: 'VETCA',
      zip: '120183',
      isUrban: false,
    },
    {
      name: 'JACODU',
      zip: '120192',
      isUrban: false,
    },
    {
      name: 'SALASURI',
      zip: '120209',
      isUrban: false,
    },
    {
      name: 'VIISOARA',
      zip: '120227',
      isUrban: false,
    },
    {
      name: 'ORMENIS',
      zip: '120236',
      isUrban: false,
    },
    {
      name: 'SANTIOANA',
      zip: '120245',
      isUrban: false,
    },
    {
      name: 'VOIVODENI',
      zip: '120325',
      isUrban: false,
    },
    {
      name: 'TOLDAL',
      zip: '120334',
      isUrban: false,
    },
    {
      name: 'ZAGAR',
      zip: '120352',
      isUrban: false,
    },
    {
      name: 'SELEUS',
      zip: '120361',
      isUrban: false,
    },
    {
      name: 'ZAU DE CAMPIE',
      zip: '120389',
      isUrban: false,
    },
    {
      name: 'BARBOSI',
      zip: '120398',
      isUrban: false,
    },
    {
      name: 'BOTEI',
      zip: '120405',
      isUrban: false,
    },
    {
      name: 'BUJOR-HODAIE',
      zip: '120414',
      isUrban: false,
    },
    {
      name: 'CIRETEA',
      zip: '120423',
      isUrban: false,
    },
    {
      name: 'GAURA SANGERULUI',
      zip: '120432',
      isUrban: false,
    },
    {
      name: 'MALEA',
      zip: '120441',
      isUrban: false,
    },
    {
      name: 'STEFANEACA',
      zip: '120450',
      isUrban: false,
    },
    {
      name: 'TAU',
      zip: '120469',
      isUrban: false,
    },
    {
      name: 'CINTA',
      zip: '116313',
      isUrban: false,
    },
    {
      name: 'CORNESTI',
      zip: '116322',
      isUrban: false,
    },
    {
      name: 'TIRIMIOARA',
      zip: '116331',
      isUrban: false,
    },
    {
      name: 'CRAIESTI',
      zip: '116359',
      isUrban: false,
    },
    {
      name: 'LEFAIA',
      zip: '116368',
      isUrban: false,
    },
    {
      name: 'MILASEL',
      zip: '116377',
      isUrban: false,
    },
    {
      name: 'NIMA MILASELULUI',
      zip: '116386',
      isUrban: false,
    },
    {
      name: 'CRISTESTI',
      zip: '114364',
      isUrban: false,
    },
    {
      name: 'VALURENI',
      zip: '114373',
      isUrban: false,
    },
    {
      name: 'CUCERDEA',
      zip: '116402',
      isUrban: false,
    },
    {
      name: 'BORD',
      zip: '116411',
      isUrban: false,
    },
    {
      name: 'SEULIA DE MURES',
      zip: '116420',
      isUrban: false,
    },
    {
      name: 'CUCI',
      zip: '116448',
      isUrban: false,
    },
    {
      name: 'DATASENI',
      zip: '116457',
      isUrban: false,
    },
    {
      name: 'DUPA DEAL',
      zip: '116466',
      isUrban: false,
    },
    {
      name: 'OROSIA',
      zip: '116475',
      isUrban: false,
    },
    {
      name: 'PETRILACA',
      zip: '116484',
      isUrban: false,
    },
    {
      name: 'DANES',
      zip: '116509',
      isUrban: false,
    },
    {
      name: 'CRIS',
      zip: '116518',
      isUrban: false,
    },
    {
      name: 'SELEUS',
      zip: '116527',
      isUrban: false,
    },
    {
      name: 'STEJARENII',
      zip: '116536',
      isUrban: false,
    },
    {
      name: 'DEDA',
      zip: '116554',
      isUrban: false,
    },
    {
      name: 'ZAPODEA',
      zip: '119457',
      isUrban: false,
    },
    {
      name: 'SANPAUL',
      zip: '119475',
      isUrban: false,
    },
    {
      name: 'CHIRILEU',
      zip: '119484',
      isUrban: false,
    },
    {
      name: 'DILEU NOU',
      zip: '119493',
      isUrban: false,
    },
    {
      name: 'SANMARGHITA',
      zip: '119509',
      isUrban: false,
    },
    {
      name: 'VALEA IZVOARELOR',
      zip: '119518',
      isUrban: false,
    },
    {
      name: 'SANPETRU DE CAMPIE',
      zip: '119536',
      isUrban: false,
    },
    {
      name: 'BARLIBAS',
      zip: '119545',
      isUrban: false,
    },
    {
      name: 'DAMBU',
      zip: '119554',
      isUrban: false,
    },
    {
      name: 'SANGEORGIU DE CAMPIE',
      zip: '119572',
      isUrban: false,
    },
    {
      name: 'SATU NOU',
      zip: '119563',
      isUrban: false,
    },
    {
      name: 'TUSINU',
      zip: '119581',
      isUrban: false,
    },
    {
      name: 'SANTANA DE MURES',
      zip: '114462',
      isUrban: false,
    },
    {
      name: 'BARDESTI',
      zip: '114471',
      isUrban: false,
    },
    {
      name: 'CHINARI',
      zip: '114480',
      isUrban: false,
    },
    {
      name: 'CURTENI',
      zip: '114499',
      isUrban: false,
    },
    {
      name: 'SARMASU',
      zip: '119251',
      isUrban: true,
    },
    {
      name: 'BALDA',
      zip: '119260',
      isUrban: true,
    },
    {
      name: 'LARGA',
      zip: '119279',
      isUrban: true,
    },
    {
      name: 'MORUT',
      zip: '119288',
      isUrban: true,
    },
    {
      name: 'SARMASEL',
      zip: '119297',
      isUrban: true,
    },
    {
      name: 'SARMASEL-GARA',
      zip: '119304',
      isUrban: true,
    },
    {
      name: 'TITIANA',
      zip: '119313',
      isUrban: true,
    },
    {
      name: 'VISINELU',
      zip: '119322',
      isUrban: true,
    },
    {
      name: 'SASCHIZ',
      zip: '119215',
      isUrban: false,
    },
    {
      name: 'SANVASII',
      zip: '120520',
      isUrban: false,
    }
  ],
  OT: [
    {
      name: 'SLATINA',
      zip: '125356',
      isUrban: true,
    },
    {
      name: 'CIREASOV',
      zip: '125365',
      isUrban: true,
    },
    {
      name: 'CARACAL',
      zip: '125481',
      isUrban: true,
    },
    {
      name: 'BALS',
      zip: '125427',
      isUrban: true,
    },
    {
      name: 'CORBENI',
      zip: '125436',
      isUrban: true,
    },
    {
      name: 'ROMANA',
      zip: '125445',
      isUrban: true,
    },
    {
      name: 'TEIS',
      zip: '125454',
      isUrban: true,
    },
    {
      name: 'CORABIA',
      zip: '125551',
      isUrban: true,
    },
    {
      name: 'TUDOR VLADIMIRESCU',
      zip: '125560',
      isUrban: true,
    },
    {
      name: 'VARTOPU',
      zip: '125579',
      isUrban: true,
    },
    {
      name: 'DRAGANESTI-OLT',
      zip: '125631',
      isUrban: true,
    },
    {
      name: 'COMANI',
      zip: '125640',
      isUrban: true,
    },
    {
      name: 'PIATRA-OLT',
      zip: '128114',
      isUrban: true,
    },
    {
      name: 'BISTRITA NOUA',
      zip: '128123',
      isUrban: true,
    },
    {
      name: 'CRIVA DE JOS',
      zip: '128132',
      isUrban: true,
    },
    {
      name: 'CRIVA DE SUS',
      zip: '128141',
      isUrban: true,
    },
    {
      name: 'ENOSESTI',
      zip: '128150',
      isUrban: true,
    },
    {
      name: 'PIATRA',
      zip: '128169',
      isUrban: true,
    },
    {
      name: 'SCORNICESTI',
      zip: '128720',
      isUrban: true,
    },
    {
      name: 'BALTATI',
      zip: '128739',
      isUrban: true,
    },
    {
      name: 'BIRCII',
      zip: '128748',
      isUrban: true,
    },
    {
      name: 'CHITEASCA',
      zip: '128757',
      isUrban: true,
    },
    {
      name: 'CONSTANTINESTI',
      zip: '128766',
      isUrban: true,
    },
    {
      name: 'JITARU',
      zip: '128775',
      isUrban: true,
    },
    {
      name: 'MARGINENI SLOBOZIA',
      zip: '128784',
      isUrban: true,
    },
    {
      name: 'MIHAILESTI POPESTI',
      zip: '128793',
      isUrban: true,
    },
    {
      name: 'MOGOSESTI',
      zip: '128800',
      isUrban: true,
    },
    {
      name: 'NEGRENI',
      zip: '128819',
      isUrban: true,
    },
    {
      name: 'PISCANI',
      zip: '128828',
      isUrban: true,
    },
    {
      name: 'RUSCIORI',
      zip: '128837',
      isUrban: true,
    },
    {
      name: 'SUICA',
      zip: '128846',
      isUrban: true,
    },
    {
      name: 'TEIUS',
      zip: '128855',
      isUrban: true,
    },
    {
      name: 'BABICIU',
      zip: '125668',
      isUrban: false,
    },
    {
      name: 'BALEASA',
      zip: '125686',
      isUrban: false,
    },
    {
      name: 'BALDOVINESTI',
      zip: '125695',
      isUrban: false,
    },
    {
      name: 'BROSTENI',
      zip: '125702',
      isUrban: false,
    },
    {
      name: 'DAMBURILE',
      zip: '125711',
      isUrban: false,
    },
    {
      name: 'GAVANESTI',
      zip: '125720',
      isUrban: false,
    },
    {
      name: 'GUBANDRU',
      zip: '125739',
      isUrban: false,
    },
    {
      name: 'PIETRIS',
      zip: '125748',
      isUrban: false,
    },
    {
      name: 'BARASTII DE VEDE',
      zip: '125766',
      isUrban: false,
    },
    {
      name: 'BARASTII DE CEPTURI',
      zip: '125775',
      isUrban: false,
    },
    {
      name: 'BOROESTI',
      zip: '125784',
      isUrban: false,
    },
    {
      name: 'CIOCANESTI',
      zip: '125793',
      isUrban: false,
    },
    {
      name: 'LAZARESTI',
      zip: '125800',
      isUrban: false,
    },
    {
      name: 'MERENI',
      zip: '125819',
      isUrban: false,
    },
    {
      name: 'MOTOESTI',
      zip: '125828',
      isUrban: false,
    },
    {
      name: 'POPESTI',
      zip: '125837',
      isUrban: false,
    },
    {
      name: 'BARZA',
      zip: '125855',
      isUrban: false,
    },
    {
      name: 'BRANET',
      zip: '125864',
      isUrban: false,
    },
    {
      name: 'BOBICESTI',
      zip: '125882',
      isUrban: false,
    },
    {
      name: 'BECHET',
      zip: '125891',
      isUrban: false,
    },
    {
      name: 'BELGUN',
      zip: '125908',
      isUrban: false,
    },
    {
      name: 'CHINTESTI',
      zip: '125917',
      isUrban: false,
    },
    {
      name: 'BONDREA',
      zip: '126120',
      isUrban: false,
    },
    {
      name: 'CORLATESTI',
      zip: '126139',
      isUrban: false,
    },
    {
      name: 'CILIENI',
      zip: '126157',
      isUrban: false,
    },
    {
      name: 'COLONESTI',
      zip: '126237',
      isUrban: false,
    },
    {
      name: 'BARASTI',
      zip: '126246',
      isUrban: false,
    },
    {
      name: 'BATARENI',
      zip: '126255',
      isUrban: false,
    },
    {
      name: 'CARSTANI',
      zip: '126273',
      isUrban: false,
    },
    {
      name: 'CHELBESTI',
      zip: '126264',
      isUrban: false,
    },
    {
      name: 'GUESTI',
      zip: '126282',
      isUrban: false,
    },
    {
      name: 'MARUNTEI',
      zip: '126291',
      isUrban: false,
    },
    {
      name: 'NAVARGENI',
      zip: '126308',
      isUrban: false,
    },
    {
      name: 'VLAICI',
      zip: '126317',
      isUrban: false,
    },
    {
      name: 'CORBU',
      zip: '126335',
      isUrban: false,
    },
    {
      name: 'BURDULESTI',
      zip: '126344',
      isUrban: false,
    },
    {
      name: 'BUZESTI',
      zip: '126353',
      isUrban: false,
    },
    {
      name: 'CIURESTI',
      zip: '126362',
      isUrban: false,
    },
    {
      name: 'MILCOVENI',
      zip: '126371',
      isUrban: false,
    },
    {
      name: 'COTEANA',
      zip: '126399',
      isUrban: false,
    },
    {
      name: 'CRAMPOIA',
      zip: '126415',
      isUrban: false,
    },
    {
      name: 'BUTA',
      zip: '126424',
      isUrban: false,
    },
    {
      name: 'CUNGREA',
      zip: '126512',
      isUrban: false,
    },
    {
      name: 'CEPESTI',
      zip: '126521',
      isUrban: false,
    },
    {
      name: 'IBANESTI',
      zip: '126530',
      isUrban: false,
    },
    {
      name: 'MIESTI',
      zip: '126549',
      isUrban: false,
    },
    {
      name: 'OTESTII DE JOS',
      zip: '126558',
      isUrban: false,
    },
    {
      name: 'OTESTII DE SUS',
      zip: '126567',
      isUrban: false,
    },
    {
      name: 'SPATARU',
      zip: '126576',
      isUrban: false,
    },
    {
      name: 'CURTISOARA',
      zip: '126442',
      isUrban: false,
    },
    {
      name: 'DOBROTINET',
      zip: '126451',
      isUrban: false,
    },
    {
      name: 'LINIA DIN VALE',
      zip: '126460',
      isUrban: false,
    },
    {
      name: 'PIETRISU',
      zip: '126479',
      isUrban: false,
    },
    {
      name: 'PROASPETI',
      zip: '126488',
      isUrban: false,
    },
    {
      name: 'RAITIU',
      zip: '126497',
      isUrban: false,
    },
    {
      name: 'DANEASA',
      zip: '126594',
      isUrban: false,
    },
    {
      name: 'BERINDEI',
      zip: '126601',
      isUrban: false,
    },
    {
      name: 'CIOFLANU',
      zip: '126610',
      isUrban: false,
    },
    {
      name: 'PESTRA',
      zip: '126629',
      isUrban: false,
    },
    {
      name: 'ZANOAGA',
      zip: '126638',
      isUrban: false,
    },
    {
      name: 'DEVESELU',
      zip: '126656',
      isUrban: false,
    },
    {
      name: 'COMANCA',
      zip: '126665',
      isUrban: false,
    },
    {
      name: 'DOBRETU',
      zip: '126683',
      isUrban: false,
    },
    {
      name: 'CURTISOARA',
      zip: '126692',
      isUrban: false,
    },
    {
      name: 'HOREZU',
      zip: '126709',
      isUrban: false,
    },
    {
      name: 'DOBROSLOVENI',
      zip: '126727',
      isUrban: false,
    },
    {
      name: 'FRASINETU',
      zip: '126736',
      isUrban: false,
    },
    {
      name: 'POTOPINU',
      zip: '126745',
      isUrban: false,
    },
    {
      name: 'RESCA',
      zip: '126754',
      isUrban: false,
    },
    {
      name: 'RESCUTA',
      zip: '126763',
      isUrban: false,
    },
    {
      name: 'DOBROTEASA',
      zip: '126781',
      isUrban: false,
    },
    {
      name: 'BATIA',
      zip: '126790',
      isUrban: false,
    },
    {
      name: 'CAMPU MARE',
      zip: '126807',
      isUrban: false,
    },
    {
      name: 'VULPESTI',
      zip: '126816',
      isUrban: false,
    },
    {
      name: 'OSICA DE JOS',
      zip: '126834',
      isUrban: false,
    },
    {
      name: 'BOBU',
      zip: '126843',
      isUrban: false,
    },
    {
      name: 'CHILII',
      zip: '126852',
      isUrban: false,
    },
    {
      name: 'DOBRUN',
      zip: '126861',
      isUrban: false,
    },
    {
      name: 'ROSIENII MARI',
      zip: '126870',
      isUrban: false,
    },
    {
      name: 'ROSIENII MICI',
      zip: '126889',
      isUrban: false,
    },
    {
      name: 'ULMET',
      zip: '126898',
      isUrban: false,
    },
    {
      name: 'DRAGHICENI',
      zip: '125506',
      isUrban: false,
    },
    {
      name: 'GROZAVESTI',
      zip: '125515',
      isUrban: false,
    },
    {
      name: 'LIICENI',
      zip: '125524',
      isUrban: false,
    },
    {
      name: 'FAGETELU',
      zip: '126914',
      isUrban: false,
    },
    {
      name: 'BAGESTI',
      zip: '126923',
      isUrban: false,
    },
    {
      name: 'GRUIU',
      zip: '126941',
      isUrban: false,
    },
    {
      name: 'ISACI',
      zip: '126950',
      isUrban: false,
    },
    {
      name: 'PIELCANI',
      zip: '126969',
      isUrban: false,
    },
    {
      name: 'FALCOIU',
      zip: '126987',
      isUrban: false,
    },
    {
      name: 'CIOROIASU',
      zip: '126996',
      isUrban: false,
    },
    {
      name: 'CIOROIU',
      zip: '127000',
      isUrban: false,
    },
    {
      name: 'FARCASELE',
      zip: '127028',
      isUrban: false,
    },
    {
      name: 'FARCASU DE JOS',
      zip: '127037',
      isUrban: false,
    },
    {
      name: 'GHIMPATI',
      zip: '127046',
      isUrban: false,
    },
    {
      name: 'HOTARANI',
      zip: '127055',
      isUrban: false,
    },
    {
      name: 'GANEASA',
      zip: '127073',
      isUrban: false,
    },
    {
      name: 'DRANOVATU',
      zip: '127082',
      isUrban: false,
    },
    {
      name: 'GRADISTEA',
      zip: '127091',
      isUrban: false,
    },
    {
      name: 'IZVORU',
      zip: '127108',
      isUrban: false,
    },
    {
      name: 'OLTISORU',
      zip: '127117',
      isUrban: false,
    },
    {
      name: 'GARCOV',
      zip: '125597',
      isUrban: false,
    },
    {
      name: 'URSA',
      zip: '125604',
      isUrban: false,
    },
    {
      name: 'GIUVARASTI',
      zip: '127135',
      isUrban: false,
    },
    {
      name: 'GOSTAVATU',
      zip: '127153',
      isUrban: false,
    },
    {
      name: 'SLAVENI',
      zip: '127162',
      isUrban: false,
    },
    {
      name: 'GRADINARI',
      zip: '127180',
      isUrban: false,
    },
    {
      name: 'PETCULESTI',
      zip: '127199',
      isUrban: false,
    },
    {
      name: 'RUNCU MARE',
      zip: '127206',
      isUrban: false,
    },
    {
      name: 'SATU NOU',
      zip: '127215',
      isUrban: false,
    },
    {
      name: 'GROJDIBODU',
      zip: '127233',
      isUrban: false,
    },
    {
      name: 'HOTARU',
      zip: '127242',
      isUrban: false,
    },
    {
      name: 'IANCA',
      zip: '127260',
      isUrban: false,
    },
    {
      name: 'POTELU',
      zip: '127279',
      isUrban: false,
    },
    {
      name: 'IANCU JIANU',
      zip: '127297',
      isUrban: false,
    },
    {
      name: 'DOBRICENI',
      zip: '127304',
      isUrban: false,
    },
    {
      name: 'PREOTESTI',
      zip: '127313',
      isUrban: false,
    },
    {
      name: 'ICOANA',
      zip: '127331',
      isUrban: false,
    },
    {
      name: 'FLORU',
      zip: '127340',
      isUrban: false,
    },
    {
      name: 'URSOAIA',
      zip: '127359',
      isUrban: false,
    },
    {
      name: 'IZBICENI',
      zip: '127377',
      isUrban: false,
    },
    {
      name: 'IZVOARELE',
      zip: '127395',
      isUrban: false,
    },
    {
      name: 'ALIMANESTI',
      zip: '127402',
      isUrban: false,
    },
    {
      name: 'LELEASCA',
      zip: '127420',
      isUrban: false,
    },
    {
      name: 'AFUMATI',
      zip: '127439',
      isUrban: false,
    },
    {
      name: 'GREERESTI',
      zip: '127448',
      isUrban: false,
    },
    {
      name: 'MIERLICESTI',
      zip: '127457',
      isUrban: false,
    },
    {
      name: 'TONESTI',
      zip: '127466',
      isUrban: false,
    },
    {
      name: 'TUFARU',
      zip: '127475',
      isUrban: false,
    },
    {
      name: 'URSI',
      zip: '127484',
      isUrban: false,
    },
    {
      name: 'MARUNTEI',
      zip: '127509',
      isUrban: false,
    },
    {
      name: 'BALANESTI',
      zip: '127518',
      isUrban: false,
    },
    {
      name: 'MALU ROSU',
      zip: '127527',
      isUrban: false,
    },
    {
      name: 'MIHAESTI',
      zip: '127545',
      isUrban: false,
    },
    {
      name: 'BUSCA',
      zip: '127554',
      isUrban: false,
    },
    {
      name: 'ULMI',
      zip: '127572',
      isUrban: false,
    },
    {
      name: 'IPOTESTI',
      zip: '127581',
      isUrban: false,
    },
    {
      name: 'MILCOVU DIN DEAL',
      zip: '127590',
      isUrban: false,
    },
    {
      name: 'MILCOVU DIN VALE',
      zip: '127607',
      isUrban: false,
    },
    {
      name: 'STEJARU',
      zip: '127616',
      isUrban: false,
    },
    {
      name: 'MORUNGLAV',
      zip: '127634',
      isUrban: false,
    },
    {
      name: 'BARASTI',
      zip: '127643',
      isUrban: false,
    },
    {
      name: 'GHIOSANI',
      zip: '127652',
      isUrban: false,
    },
    {
      name: 'MORUNESTI',
      zip: '127661',
      isUrban: false,
    },
    {
      name: 'POIANA MARE',
      zip: '127670',
      isUrban: false,
    },
    {
      name: 'MOVILENI',
      zip: '127698',
      isUrban: false,
    },
    {
      name: 'BACEA',
      zip: '127705',
      isUrban: false,
    },
    {
      name: 'NICOLAE TITULESCU',
      zip: '127723',
      isUrban: false,
    },
    {
      name: 'GHIMPETENI',
      zip: '127732',
      isUrban: false,
    },
    {
      name: 'GHIMPETENII NOI',
      zip: '127741',
      isUrban: false,
    },
    {
      name: 'OBARSIA',
      zip: '127769',
      isUrban: false,
    },
    {
      name: 'CAMPU PARULUI',
      zip: '127778',
      isUrban: false,
    },
    {
      name: 'COTENI',
      zip: '127787',
      isUrban: false,
    },
    {
      name: 'OBARSIA NOUA',
      zip: '127796',
      isUrban: false,
    },
    {
      name: 'TABONU',
      zip: '127803',
      isUrban: false,
    },
    {
      name: 'OBOGA',
      zip: '127821',
      isUrban: false,
    },
    {
      name: 'CALUI',
      zip: '127830',
      isUrban: false,
    },
    {
      name: 'GURA CALUIU',
      zip: '127849',
      isUrban: false,
    },
    {
      name: 'OPORELU',
      zip: '127867',
      isUrban: false,
    },
    {
      name: 'BERIA DE JOS',
      zip: '127876',
      isUrban: false,
    },
    {
      name: 'BERIA DE SUS',
      zip: '127885',
      isUrban: false,
    },
    {
      name: 'RADESTI',
      zip: '127894',
      isUrban: false,
    },
    {
      name: 'OPTASI',
      zip: '127910',
      isUrban: false,
    },
    {
      name: 'VITANESTI',
      zip: '127929',
      isUrban: false,
    },
    {
      name: 'ORLEA',
      zip: '127947',
      isUrban: false,
    },
    {
      name: 'GURA PADINII',
      zip: '127956',
      isUrban: false,
    },
    {
      name: 'ORLEA NOUA',
      zip: '127965',
      isUrban: false,
    },
    {
      name: 'SATU NOU',
      zip: '127974',
      isUrban: false,
    },
    {
      name: 'VLADULENI',
      zip: '127992',
      isUrban: false,
    },
    {
      name: 'GRECI',
      zip: '128007',
      isUrban: false,
    },
    {
      name: 'OSICA DE SUS',
      zip: '128016',
      isUrban: false,
    },
    {
      name: 'OSTROV',
      zip: '128025',
      isUrban: false,
    },
    {
      name: 'PERETU',
      zip: '128034',
      isUrban: false,
    },
    {
      name: 'TOMENI',
      zip: '128043',
      isUrban: false,
    },
    {
      name: 'PARSCOVENI',
      zip: '128187',
      isUrban: false,
    },
    {
      name: 'BUTOI',
      zip: '128196',
      isUrban: false,
    },
    {
      name: 'OLARI',
      zip: '128203',
      isUrban: false,
    },
    {
      name: 'SOPARLITA',
      zip: '128212',
      isUrban: false,
    },
    {
      name: 'PERIETI',
      zip: '128061',
      isUrban: false,
    },
    {
      name: 'BALTENI',
      zip: '128070',
      isUrban: false,
    },
    {
      name: 'MAGURA',
      zip: '128089',
      isUrban: false,
    },
    {
      name: 'MIERLESTII DE SUS',
      zip: '128098',
      isUrban: false,
    },
    {
      name: 'PLESOIU',
      zip: '128230',
      isUrban: false,
    },
    {
      name: 'ARCESTI',
      zip: '128249',
      isUrban: false,
    },
    {
      name: 'ARCESTI COT',
      zip: '128258',
      isUrban: false,
    },
    {
      name: 'COCORASTI',
      zip: '128267',
      isUrban: false,
    },
    {
      name: 'DOBA',
      zip: '128276',
      isUrban: false,
    },
    {
      name: 'SCHITU DIN DEAL',
      zip: '128285',
      isUrban: false,
    },
    {
      name: 'SCHITU DIN VALE',
      zip: '128294',
      isUrban: false,
    },
    {
      name: 'POBORU',
      zip: '128310',
      isUrban: false,
    },
    {
      name: 'ALBESTI',
      zip: '128329',
      isUrban: false,
    },
    {
      name: 'CORNATELU',
      zip: '128338',
      isUrban: false,
    },
    {
      name: 'CRETI',
      zip: '128347',
      isUrban: false,
    },
    {
      name: 'SEACA',
      zip: '128356',
      isUrban: false,
    },
    {
      name: 'SURPENI',
      zip: '128365',
      isUrban: false,
    },
    {
      name: 'POTCOAVA',
      zip: '128383',
      isUrban: true,
    },
    {
      name: 'POTCOAVA FALCOENI',
      zip: '128392',
      isUrban: true,
    },
    {
      name: 'SINESTI',
      zip: '128409',
      isUrban: true,
    },
    {
      name: 'TRUFINESTI',
      zip: '128418',
      isUrban: true,
    },
    {
      name: 'VALEA MERILOR',
      zip: '128427',
      isUrban: true,
    },
    {
      name: 'PRISEACA',
      zip: '128445',
      isUrban: false,
    },
    {
      name: 'BUICESTI',
      zip: '128454',
      isUrban: false,
    },
    {
      name: 'SALTANESTI',
      zip: '128463',
      isUrban: false,
    },
    {
      name: 'RADOMIRESTI',
      zip: '128481',
      isUrban: false,
    },
    {
      name: 'CALINESTI',
      zip: '128490',
      isUrban: false,
    },
    {
      name: 'CRACIUNEI',
      zip: '128506',
      isUrban: false,
    },
    {
      name: 'POIANA',
      zip: '128515',
      isUrban: false,
    },
    {
      name: 'REDEA',
      zip: '128533',
      isUrban: false,
    },
    {
      name: 'REDISOARA',
      zip: '128542',
      isUrban: false,
    },
    {
      name: 'VALEA SOARELUI',
      zip: '128551',
      isUrban: false,
    },
    {
      name: 'ROTUNDA',
      zip: '128579',
      isUrban: false,
    },
    {
      name: 'RUSANESTI',
      zip: '128597',
      isUrban: false,
    },
    {
      name: 'JIENI',
      zip: '128604',
      isUrban: false,
    },
    {
      name: 'SAMBURESTI',
      zip: '128891',
      isUrban: false,
    },
    {
      name: 'CERBENI',
      zip: '128908',
      isUrban: false,
    },
    {
      name: 'IONICESTI',
      zip: '128917',
      isUrban: false,
    },
    {
      name: 'LAUNELE',
      zip: '128926',
      isUrban: false,
    },
    {
      name: 'MANULESTI',
      zip: '128935',
      isUrban: false,
    },
    {
      name: 'STANULEASA',
      zip: '128944',
      isUrban: false,
    },
    {
      name: 'TONESTI',
      zip: '128953',
      isUrban: false,
    },
    {
      name: 'SCARISOARA',
      zip: '128622',
      isUrban: false,
    },
    {
      name: 'PLAVICENI',
      zip: '128631',
      isUrban: false,
    },
    {
      name: 'RUDARI',
      zip: '128640',
      isUrban: false,
    },
    {
      name: 'SCHITU',
      zip: '128668',
      isUrban: false,
    },
    {
      name: 'CATANELE',
      zip: '128677',
      isUrban: false,
    },
    {
      name: 'GRECI',
      zip: '128686',
      isUrban: false,
    },
    {
      name: 'LISA',
      zip: '128695',
      isUrban: false,
    },
    {
      name: 'MOSTENI',
      zip: '128702',
      isUrban: false,
    },
    {
      name: 'SEACA',
      zip: '128873',
      isUrban: false,
    },
    {
      name: 'SERBANESTI',
      zip: '129255',
      isUrban: false,
    },
    {
      name: 'SERBANESTII DE SUS',
      zip: '129273',
      isUrban: false,
    },
    {
      name: 'STRUGURELU',
      zip: '129264',
      isUrban: false,
    },
    {
      name: 'SLATIOARA',
      zip: '125383',
      isUrban: false,
    },
    {
      name: 'SALCIA',
      zip: '125392',
      isUrban: false,
    },
    {
      name: 'ALUNISU',
      zip: '128971',
      isUrban: false,
    },
    {
      name: 'CUZA VODA',
      zip: '128980',
      isUrban: false,
    },
    {
      name: 'DAVIDESTI',
      zip: '128999',
      isUrban: false,
    },
    {
      name: 'OPTASANI',
      zip: '129004',
      isUrban: false,
    },
    {
      name: 'PROFA',
      zip: '129013',
      isUrban: false,
    },
    {
      name: 'SPINENI',
      zip: '129022',
      isUrban: false,
    },
    {
      name: 'VINETI',
      zip: '129031',
      isUrban: false,
    },
    {
      name: 'SPRANCENATA',
      zip: '129059',
      isUrban: false,
    },
    {
      name: 'BARSESTII DE SUS',
      zip: '129068',
      isUrban: false,
    },
    {
      name: 'FRUNZARU',
      zip: '129077',
      isUrban: false,
    },
    {
      name: 'URIA',
      zip: '129086',
      isUrban: false,
    },
    {
      name: 'STEFAN CEL MARE',
      zip: '129291',
      isUrban: false,
    },
    {
      name: 'IANCA NOUA',
      zip: '129308',
      isUrban: false,
    },
    {
      name: 'STOENESTI',
      zip: '129102',
      isUrban: false,
    },
    {
      name: 'STOICANESTI',
      zip: '129120',
      isUrban: false,
    },
    {
      name: 'STREJESTI',
      zip: '129148',
      isUrban: false,
    },
    {
      name: 'COLIBASI',
      zip: '129157',
      isUrban: false,
    },
    {
      name: 'MAMURA',
      zip: '129166',
      isUrban: false,
    },
    {
      name: 'STREJESTII DE SUS',
      zip: '129175',
      isUrban: false,
    },
    {
      name: 'STUDINA',
      zip: '129193',
      isUrban: false,
    },
    {
      name: 'ARVATEASCA',
      zip: '129200',
      isUrban: false,
    },
    {
      name: 'GRADINILE',
      zip: '129219',
      isUrban: false,
    },
    {
      name: 'PLAVICEANCA',
      zip: '129228',
      isUrban: false,
    },
    {
      name: 'POTLOGENI',
      zip: '129497',
      isUrban: false,
    },
    {
      name: 'TOPANA',
      zip: '129512',
      isUrban: false,
    },
    {
      name: 'CANDELESTI',
      zip: '129530',
      isUrban: false,
    },
    {
      name: 'CIORACA',
      zip: '129521',
      isUrban: false,
    },
    {
      name: 'COJGAREI',
      zip: '129549',
      isUrban: false,
    },
    {
      name: 'UNGURENI',
      zip: '129558',
      isUrban: false,
    },
    {
      name: 'TRAIAN',
      zip: '129576',
      isUrban: false,
    },
    {
      name: 'TUFENI',
      zip: '129594',
      isUrban: false,
    },
    {
      name: 'BARZA',
      zip: '129601',
      isUrban: false,
    },
    {
      name: 'STOBORASTI',
      zip: '129610',
      isUrban: false,
    },
    {
      name: 'URZICA',
      zip: '129638',
      isUrban: false,
    },
    {
      name: 'STAVARU',
      zip: '129647',
      isUrban: false,
    },
    {
      name: 'VISINA NOUA',
      zip: '129727',
      isUrban: false,
    },
    {
      name: 'VADASTRA',
      zip: '129736',
      isUrban: false,
    },
    {
      name: 'VADASTRITA',
      zip: '129754',
      isUrban: false,
    },
    {
      name: 'VALCELE',
      zip: '129996',
      isUrban: false,
    },
    {
      name: 'BARCANESTI',
      zip: '130008',
      isUrban: false,
    },
    {
      name: 'VALCELELE DE SUS',
      zip: '130017',
      isUrban: false,
    },
    {
      name: 'VALEA MARE',
      zip: '129665',
      isUrban: false,
    },
    {
      name: 'BARCA',
      zip: '129674',
      isUrban: false,
    },
    {
      name: 'RECEA',
      zip: '129683',
      isUrban: false,
    },
    {
      name: 'TURIA',
      zip: '129692',
      isUrban: false,
    },
    {
      name: 'ZORLEASCA',
      zip: '129709',
      isUrban: false,
    },
    {
      name: 'VALENI',
      zip: '129772',
      isUrban: false,
    },
    {
      name: 'MANDRA',
      zip: '129781',
      isUrban: false,
    },
    {
      name: 'POPESTI',
      zip: '129790',
      isUrban: false,
    },
    {
      name: 'TIRISNEAG',
      zip: '129807',
      isUrban: false,
    },
    {
      name: 'VERGULEASA',
      zip: '129825',
      isUrban: false,
    },
    {
      name: 'CAZANESTI',
      zip: '129834',
      isUrban: false,
    },
    {
      name: 'CUCUETI',
      zip: '129843',
      isUrban: false,
    },
    {
      name: 'DUMITRESTI',
      zip: '129852',
      isUrban: false,
    },
    {
      name: 'POGANU',
      zip: '129861',
      isUrban: false,
    },
    {
      name: 'VALEA FETEI',
      zip: '129870',
      isUrban: false,
    },
    {
      name: 'VANESTI',
      zip: '129889',
      isUrban: false,
    },
    {
      name: 'VISINA',
      zip: '129905',
      isUrban: false,
    },
    {
      name: 'VITOMIRESTI',
      zip: '129923',
      isUrban: false,
    },
    {
      name: 'BULIMANU',
      zip: '129932',
      isUrban: false,
    },
    {
      name: 'DEJESTI',
      zip: '129941',
      isUrban: false,
    },
    {
      name: 'DONESTI',
      zip: '129950',
      isUrban: false,
    },
    {
      name: 'STANULEASA',
      zip: '129969',
      isUrban: false,
    },
    {
      name: 'TREPTENI',
      zip: '129978',
      isUrban: false,
    },
    {
      name: 'VLADILA',
      zip: '130035',
      isUrban: false,
    },
    {
      name: 'FRASINET GARA',
      zip: '130044',
      isUrban: false,
    },
    {
      name: 'VLADILA NOUA',
      zip: '130053',
      isUrban: false,
    },
    {
      name: 'VOINEASA',
      zip: '130071',
      isUrban: false,
    },
    {
      name: 'BLAJ',
      zip: '130080',
      isUrban: false,
    },
    {
      name: 'MARGARITESTI',
      zip: '130099',
      isUrban: false,
    },
    {
      name: 'RACOVITA',
      zip: '130106',
      isUrban: false,
    },
    {
      name: 'RUSANESTII DE SUS',
      zip: '130115',
      isUrban: false,
    },
    {
      name: 'VULPENI',
      zip: '130133',
      isUrban: false,
    },
    {
      name: 'COTORBESTI',
      zip: '130142',
      isUrban: false,
    },
    {
      name: 'GROPSANI',
      zip: '130151',
      isUrban: false,
    },
    {
      name: 'MARDALE',
      zip: '130160',
      isUrban: false,
    },
    {
      name: 'PESCARESTI',
      zip: '130179',
      isUrban: false,
    },
    {
      name: 'PLOPSORELU',
      zip: '130188',
      isUrban: false,
    },
    {
      name: 'PRISACA',
      zip: '130197',
      isUrban: false,
    },
    {
      name: 'SIMNICENI',
      zip: '130204',
      isUrban: false,
    },
    {
      name: 'TABACI',
      zip: '130213',
      isUrban: false,
    },
    {
      name: 'VALEA SATULUI',
      zip: '130222',
      isUrban: false,
    },
    {
      name: 'VULTURESTI',
      zip: '130240',
      isUrban: false,
    },
    {
      name: 'DIENCI',
      zip: '130259',
      isUrban: false,
    },
    {
      name: 'VALEA LUI ALB',
      zip: '130268',
      isUrban: false,
    },
    {
      name: 'VLANGARESTI',
      zip: '130277',
      isUrban: false,
    },
    {
      name: 'COMANESTI',
      zip: '125926',
      isUrban: false,
    },
    {
      name: 'GOVORA',
      zip: '125935',
      isUrban: false,
    },
    {
      name: 'LEOTESTI',
      zip: '125944',
      isUrban: false,
    },
    {
      name: 'MIRILA',
      zip: '125953',
      isUrban: false,
    },
    {
      name: 'BRANCOVENI',
      zip: '126031',
      isUrban: false,
    },
    {
      name: 'MARGHENI',
      zip: '126040',
      isUrban: false,
    },
    {
      name: 'OCIOGI',
      zip: '126059',
      isUrban: false,
    },
    {
      name: 'VALENI',
      zip: '126068',
      isUrban: false,
    },
    {
      name: 'BRASTAVATU',
      zip: '125971',
      isUrban: false,
    },
    {
      name: 'CRUSOVU',
      zip: '125980',
      isUrban: false,
    },
    {
      name: 'BREBENI',
      zip: '126004',
      isUrban: false,
    },
    {
      name: 'TEIUSU',
      zip: '126013',
      isUrban: false,
    },
    {
      name: 'BUCINISU',
      zip: '126086',
      isUrban: false,
    },
    {
      name: 'BUCINISU MIC',
      zip: '126095',
      isUrban: false,
    },
    {
      name: 'CARLOGANI',
      zip: '126175',
      isUrban: false,
    },
    {
      name: 'BECULESTI',
      zip: '126184',
      isUrban: false,
    },
    {
      name: 'CEPARI',
      zip: '126193',
      isUrban: false,
    },
    {
      name: 'SCORBURA',
      zip: '126200',
      isUrban: false,
    },
    {
      name: 'STUPINA',
      zip: '126219',
      isUrban: false,
    },
    {
      name: 'CEZIENI',
      zip: '126111',
      isUrban: false,
    },
    {
      name: 'CHILIA',
      zip: '126932',
      isUrban: false,
    },
    {
      name: 'STUDINITA',
      zip: '129237',
      isUrban: false,
    },
    {
      name: 'TATULESTI',
      zip: '129326',
      isUrban: false,
    },
    {
      name: 'BARBALAI',
      zip: '129335',
      isUrban: false,
    },
    {
      name: 'LUNCA',
      zip: '129344',
      isUrban: false,
    },
    {
      name: 'MAGURA',
      zip: '129353',
      isUrban: false,
    },
    {
      name: 'MIRCESTI',
      zip: '129362',
      isUrban: false,
    },
    {
      name: 'MOMAIU',
      zip: '129371',
      isUrban: false,
    },
    {
      name: 'TESLUI',
      zip: '129399',
      isUrban: false,
    },
    {
      name: 'CHERLESTII DIN DEAL',
      zip: '129406',
      isUrban: false,
    },
    {
      name: 'CHERLESTII MOSTENI',
      zip: '129415',
      isUrban: false,
    },
    {
      name: 'COMANITA',
      zip: '129424',
      isUrban: false,
    },
    {
      name: 'CORBU',
      zip: '129433',
      isUrban: false,
    },
    {
      name: 'DELENI',
      zip: '129442',
      isUrban: false,
    },
    {
      name: 'SCHITU DELENI',
      zip: '129451',
      isUrban: false,
    },
    {
      name: 'TIA MARE',
      zip: '129479',
      isUrban: false,
    },
    {
      name: 'DOANCA',
      zip: '129488',
      isUrban: false,
    }
  ],
  PH: [
    {
      name: 'PLOIESTI',
      zip: '130543',
      isUrban: true,
    },
    {
      name: 'CAMPINA',
      zip: '131265',
      isUrban: true,
    },
    {
      name: 'AZUGA',
      zip: '130963',
      isUrban: true,
    },
    {
      name: 'BAICOI',
      zip: '130990',
      isUrban: true,
    },
    {
      name: 'DIMBU',
      zip: '131005',
      isUrban: true,
    },
    {
      name: 'LILIESTI',
      zip: '131014',
      isUrban: true,
    },
    {
      name: 'SCHELA',
      zip: '131023',
      isUrban: true,
    },
    {
      name: 'TINTEA',
      zip: '131041',
      isUrban: true,
    },
    {
      name: 'TUFENI',
      zip: '131032',
      isUrban: true,
    },
    {
      name: 'BOLDESTI-SCAENI',
      zip: '131078',
      isUrban: true,
    },
    {
      name: 'SECIU',
      zip: '131087',
      isUrban: true,
    },
    {
      name: 'BREAZA DE JOS',
      zip: '131112',
      isUrban: true,
    },
    {
      name: 'BREAZA DE SUS',
      zip: '131121',
      isUrban: true,
    },
    {
      name: 'FRASINET',
      zip: '131130',
      isUrban: true,
    },
    {
      name: 'GURA BELIEI',
      zip: '136232',
      isUrban: true,
    },
    {
      name: 'IRIMESTI',
      zip: '131149',
      isUrban: true,
    },
    {
      name: 'NISTORESTI',
      zip: '131158',
      isUrban: true,
    },
    {
      name: 'PODU CORBULUI',
      zip: '131167',
      isUrban: true,
    },
    {
      name: 'PODU VADULUI',
      zip: '131176',
      isUrban: true,
    },
    {
      name: 'SURDESTI',
      zip: '131185',
      isUrban: true,
    },
    {
      name: 'VALEA TARSEI',
      zip: '131194',
      isUrban: true,
    },
    {
      name: 'BUSTENI',
      zip: '131229',
      isUrban: true,
    },
    {
      name: 'POIANA TAPULUI',
      zip: '131238',
      isUrban: true,
    },
    {
      name: 'COMARNIC',
      zip: '131345',
      isUrban: true,
    },
    {
      name: 'GHIOSESTI',
      zip: '131354',
      isUrban: true,
    },
    {
      name: 'PODU LUNG',
      zip: '131363',
      isUrban: true,
    },
    {
      name: 'POIANA',
      zip: '131372',
      isUrban: true,
    },
    {
      name: 'POSADA',
      zip: '131381',
      isUrban: true,
    },
    {
      name: 'MIZIL',
      zip: '131416',
      isUrban: true,
    },
    {
      name: 'FEFELEI',
      zip: '131425',
      isUrban: true,
    },
    {
      name: 'PLOPENI',
      zip: '131452',
      isUrban: true,
    },
    {
      name: 'SINAIA',
      zip: '131559',
      isUrban: true,
    },
    {
      name: 'SLANIC',
      zip: '131586',
      isUrban: true,
    },
    {
      name: 'GROSANI',
      zip: '131595',
      isUrban: true,
    },
    {
      name: 'PRAJANI',
      zip: '131602',
      isUrban: true,
    },
    {
      name: 'URLATI',
      zip: '131639',
      isUrban: true,
    },
    {
      name: 'ARIONESTII NOI',
      zip: '131648',
      isUrban: true,
    },
    {
      name: 'ARIONESTII VECHI',
      zip: '131657',
      isUrban: true,
    },
    {
      name: 'CHERBA',
      zip: '131666',
      isUrban: true,
    },
    {
      name: 'JERCALAI',
      zip: '131675',
      isUrban: true,
    },
    {
      name: 'MARUNTIS',
      zip: '131684',
      isUrban: true,
    },
    {
      name: 'ORZOAIA DE JOS',
      zip: '131693',
      isUrban: true,
    },
    {
      name: 'ORZOAIA DE SUS',
      zip: '131700',
      isUrban: true,
    },
    {
      name: 'SCHIAU',
      zip: '131719',
      isUrban: true,
    },
    {
      name: 'ULMI',
      zip: '131728',
      isUrban: true,
    },
    {
      name: 'VALEA BOBULUI',
      zip: '131737',
      isUrban: true,
    },
    {
      name: 'VALEA CRANGULUI',
      zip: '131746',
      isUrban: true,
    },
    {
      name: 'VALEA MIEILOR',
      zip: '131755',
      isUrban: true,
    },
    {
      name: 'VALEA NUCETULUI',
      zip: '131764',
      isUrban: true,
    },
    {
      name: 'VALEA PIETREI',
      zip: '131773',
      isUrban: true,
    },
    {
      name: 'VALEA SEMAN',
      zip: '131782',
      isUrban: true,
    },
    {
      name: 'VALEA URLOII',
      zip: '131791',
      isUrban: true,
    },
    {
      name: 'VALENII DE MUNTE',
      zip: '131826',
      isUrban: true,
    },
    {
      name: 'ADUNATI',
      zip: '131906',
      isUrban: false,
    },
    {
      name: 'OCINA DE JOS',
      zip: '131915',
      isUrban: false,
    },
    {
      name: 'OCINA DE SUS',
      zip: '131924',
      isUrban: false,
    },
    {
      name: 'ALBESTI-PALEOLOGU',
      zip: '131942',
      isUrban: false,
    },
    {
      name: 'ALBESTI-MURU',
      zip: '131951',
      isUrban: false,
    },
    {
      name: 'CIOCENI',
      zip: '131960',
      isUrban: false,
    },
    {
      name: 'VADU PARULUI',
      zip: '131979',
      isUrban: false,
    },
    {
      name: 'OSTROVU',
      zip: '132002',
      isUrban: false,
    },
    {
      name: 'APOSTOLACHE',
      zip: '132020',
      isUrban: false,
    },
    {
      name: 'BUZOTA',
      zip: '132039',
      isUrban: false,
    },
    {
      name: 'MARLOGEA',
      zip: '132048',
      isUrban: false,
    },
    {
      name: 'UDRESTI',
      zip: '132057',
      isUrban: false,
    },
    {
      name: 'VALEA CRICOVULUI',
      zip: '132066',
      isUrban: false,
    },
    {
      name: 'ARICESTII RAHTIVANI',
      zip: '132084',
      isUrban: false,
    },
    {
      name: 'BUDA',
      zip: '132093',
      isUrban: false,
    },
    {
      name: 'NEDELEA',
      zip: '132100',
      isUrban: false,
    },
    {
      name: 'STOENESTI',
      zip: '132119',
      isUrban: false,
    },
    {
      name: 'TARGSORU NOU',
      zip: '132128',
      isUrban: false,
    },
    {
      name: 'ARICESTII ZELETIN',
      zip: '132146',
      isUrban: false,
    },
    {
      name: 'ALBINARI',
      zip: '132155',
      isUrban: false,
    },
    {
      name: 'BABA ANA',
      zip: '132173',
      isUrban: false,
    },
    {
      name: 'CIRESANU',
      zip: '132182',
      isUrban: false,
    },
    {
      name: 'CONDURATU',
      zip: '132191',
      isUrban: false,
    },
    {
      name: 'CRANGURILE',
      zip: '132208',
      isUrban: false,
    },
    {
      name: 'SATU NOU',
      zip: '132217',
      isUrban: false,
    },
    {
      name: 'BALTA DOAMNEI',
      zip: '132235',
      isUrban: false,
    },
    {
      name: 'BARA',
      zip: '132244',
      isUrban: false,
    },
    {
      name: 'CURCUBEU',
      zip: '132253',
      isUrban: false,
    },
    {
      name: 'LACU TURCULUI',
      zip: '132262',
      isUrban: false,
    },
    {
      name: 'BALTESTI',
      zip: '132280',
      isUrban: false,
    },
    {
      name: 'IZESTI',
      zip: '132299',
      isUrban: false,
    },
    {
      name: 'PODENII VECHI',
      zip: '132306',
      isUrban: false,
    },
    {
      name: 'BANESTI',
      zip: '132324',
      isUrban: false,
    },
    {
      name: 'URLETA',
      zip: '132333',
      isUrban: false,
    },
    {
      name: 'BARCANESTI',
      zip: '130561',
      isUrban: false,
    },
    {
      name: 'GHIGHIU',
      zip: '130570',
      isUrban: false,
    },
    {
      name: 'PUSCASI',
      zip: '130589',
      isUrban: false,
    },
    {
      name: 'ROMANESTI',
      zip: '130598',
      isUrban: false,
    },
    {
      name: 'TATARANI',
      zip: '130605',
      isUrban: false,
    },
    {
      name: 'BERCENI',
      zip: '130623',
      isUrban: false,
    },
    {
      name: 'CARTIERUL DAMBU',
      zip: '130632',
      isUrban: false,
    },
    {
      name: 'CATUNU',
      zip: '130641',
      isUrban: false,
    },
    {
      name: 'CORLATESTI',
      zip: '130650',
      isUrban: false,
    },
    {
      name: 'MOARA NOUA',
      zip: '130669',
      isUrban: false,
    },
    {
      name: 'BERTEA',
      zip: '132351',
      isUrban: false,
    },
    {
      name: 'LUTU ROSU',
      zip: '132360',
      isUrban: false,
    },
    {
      name: 'BLEJOI',
      zip: '130687',
      isUrban: false,
    },
    {
      name: 'PLOIESTIORI',
      zip: '130696',
      isUrban: false,
    },
    {
      name: 'TANTARENI',
      zip: '130703',
      isUrban: false,
    },
    {
      name: 'BOLDESTI',
      zip: '132388',
      isUrban: false,
    },
    {
      name: 'GRADISTEA',
      zip: '132397',
      isUrban: false,
    },
    {
      name: 'BRAZII DE SUS',
      zip: '130721',
      isUrban: false,
    },
    {
      name: 'BATESTI',
      zip: '130730',
      isUrban: false,
    },
    {
      name: 'BRAZII DE JOS',
      zip: '130749',
      isUrban: false,
    },
    {
      name: 'NEGOIESTI',
      zip: '130758',
      isUrban: false,
    },
    {
      name: 'POPESTI',
      zip: '130767',
      isUrban: false,
    },
    {
      name: 'STEJARU',
      zip: '130776',
      isUrban: false,
    },
    {
      name: 'BREBU MANASTIREI',
      zip: '132413',
      isUrban: false,
    },
    {
      name: 'BREBU MEGIESESC',
      zip: '132422',
      isUrban: false,
    },
    {
      name: 'PIETRICEAUA',
      zip: '132431',
      isUrban: false,
    },
    {
      name: 'PODU CHEII',
      zip: '132440',
      isUrban: false,
    },
    {
      name: 'BUCOV',
      zip: '130794',
      isUrban: false,
    },
    {
      name: 'BIGHILIN',
      zip: '130801',
      isUrban: false,
    },
    {
      name: 'CHITORANI',
      zip: '130810',
      isUrban: false,
    },
    {
      name: 'PLEASA',
      zip: '130829',
      isUrban: false,
    },
    {
      name: 'VALEA ORLEI',
      zip: '130838',
      isUrban: false,
    },
    {
      name: 'CALUGARENI',
      zip: '132468',
      isUrban: false,
    },
    {
      name: 'VALEA SCHEILOR',
      zip: '132477',
      isUrban: false,
    },
    {
      name: 'CARBUNESTI',
      zip: '132495',
      isUrban: false,
    },
    {
      name: 'GOGEASCA',
      zip: '132501',
      isUrban: false,
    },
    {
      name: 'CEPTURA DE JOS',
      zip: '132529',
      isUrban: false,
    },
    {
      name: 'CEPTURA DE SUS',
      zip: '132538',
      isUrban: false,
    },
    {
      name: 'MALU ROSU',
      zip: '132547',
      isUrban: false,
    },
    {
      name: 'ROTARI',
      zip: '132556',
      isUrban: false,
    },
    {
      name: 'SOIMESTI',
      zip: '132565',
      isUrban: false,
    },
    {
      name: 'CERASU',
      zip: '132583',
      isUrban: false,
    },
    {
      name: 'SLON',
      zip: '132592',
      isUrban: false,
    },
    {
      name: 'VALEA BORULUI',
      zip: '132609',
      isUrban: false,
    },
    {
      name: 'VALEA BRADETULUI',
      zip: '132618',
      isUrban: false,
    },
    {
      name: 'VALEA LESPEZII',
      zip: '132627',
      isUrban: false,
    },
    {
      name: 'VALEA TOCII',
      zip: '132636',
      isUrban: false,
    },
    {
      name: 'CHIOJDEANCA',
      zip: '132654',
      isUrban: false,
    },
    {
      name: 'NUCET',
      zip: '132663',
      isUrban: false,
    },
    {
      name: 'TRENU',
      zip: '132672',
      isUrban: false,
    },
    {
      name: 'CIORANII DE JOS',
      zip: '132690',
      isUrban: false,
    },
    {
      name: 'CIORANII DE SUS',
      zip: '132707',
      isUrban: false,
    },
    {
      name: 'COCORASTII MISLII',
      zip: '132725',
      isUrban: false,
    },
    {
      name: 'GORUNA',
      zip: '132734',
      isUrban: false,
    },
    {
      name: 'TIPARESTI',
      zip: '132743',
      isUrban: false,
    },
    {
      name: 'COLCEAG',
      zip: '132761',
      isUrban: false,
    },
    {
      name: 'INOTESTI',
      zip: '132770',
      isUrban: false,
    },
    {
      name: 'PAREPA-RUSANI',
      zip: '132789',
      isUrban: false,
    },
    {
      name: 'VALCELELE',
      zip: '132798',
      isUrban: false,
    },
    {
      name: 'CORNU DE JOS',
      zip: '132814',
      isUrban: false,
    },
    {
      name: 'CORNU DE SUS',
      zip: '132823',
      isUrban: false,
    },
    {
      name: 'VALEA OPRII',
      zip: '132832',
      isUrban: false,
    },
    {
      name: 'COSMINA DE JOS',
      zip: '132850',
      isUrban: false,
    },
    {
      name: 'COSMINA DE SUS',
      zip: '132869',
      isUrban: false,
    },
    {
      name: 'DRAGHICESTI',
      zip: '132878',
      isUrban: false,
    },
    {
      name: 'POIANA TRESTIEI',
      zip: '132887',
      isUrban: false,
    },
    {
      name: 'DRAGANESTI',
      zip: '133027',
      isUrban: false,
    },
    {
      name: 'BARAITARU',
      zip: '133036',
      isUrban: false,
    },
    {
      name: 'BELCIUG',
      zip: '133045',
      isUrban: false,
    },
    {
      name: 'CORNU DE JOS',
      zip: '133054',
      isUrban: false,
    },
    {
      name: 'HATCARAU',
      zip: '133063',
      isUrban: false,
    },
    {
      name: 'MERI',
      zip: '133072',
      isUrban: false,
    },
    {
      name: 'TUFANI',
      zip: '133081',
      isUrban: false,
    },
    {
      name: 'DRAJNA DE SUS',
      zip: '132903',
      isUrban: false,
    },
    {
      name: 'CATUNU',
      zip: '132912',
      isUrban: false,
    },
    {
      name: 'CIOCRAC',
      zip: '132921',
      isUrban: false,
    },
    {
      name: 'DRAJNA DE JOS',
      zip: '132930',
      isUrban: false,
    },
    {
      name: 'FAGET',
      zip: '132949',
      isUrban: false,
    },
    {
      name: 'OGRETIN',
      zip: '132958',
      isUrban: false,
    },
    {
      name: 'PIATRA',
      zip: '132967',
      isUrban: false,
    },
    {
      name: 'PITIGOI',
      zip: '132976',
      isUrban: false,
    },
    {
      name: 'PLAI',
      zip: '132985',
      isUrban: false,
    },
    {
      name: 'PODURILE',
      zip: '132994',
      isUrban: false,
    },
    {
      name: 'POIANA MIERLEI',
      zip: '133009',
      isUrban: false,
    },
    {
      name: 'DUMBRAVA',
      zip: '133107',
      isUrban: false,
    },
    {
      name: 'CIUPELNITA',
      zip: '133116',
      isUrban: false,
    },
    {
      name: 'CORNU DE SUS',
      zip: '133125',
      isUrban: false,
    },
    {
      name: 'TRESTIENII DE JOS',
      zip: '133134',
      isUrban: false,
    },
    {
      name: 'TRESTIENII DE SUS',
      zip: '133143',
      isUrban: false,
    },
    {
      name: 'MINIERI',
      zip: '133198',
      isUrban: false,
    },
    {
      name: 'SILISTEA DEALULUI',
      zip: '133205',
      isUrban: false,
    },
    {
      name: 'FILIPESTII DE TARG',
      zip: '133223',
      isUrban: false,
    },
    {
      name: 'BRATASANCA',
      zip: '133232',
      isUrban: false,
    },
    {
      name: 'EZENI',
      zip: '133241',
      isUrban: false,
    },
    {
      name: 'MARGINENII DE JOS',
      zip: '133250',
      isUrban: false,
    },
    {
      name: 'UNGURENI',
      zip: '133269',
      isUrban: false,
    },
    {
      name: 'FLORESTI',
      zip: '133349',
      isUrban: false,
    },
    {
      name: 'CALINESTI',
      zip: '133367',
      isUrban: false,
    },
    {
      name: 'CAP ROSU',
      zip: '133358',
      isUrban: false,
    },
    {
      name: 'CATINA',
      zip: '133376',
      isUrban: false,
    },
    {
      name: 'NOVACESTI',
      zip: '133385',
      isUrban: false,
    },
    {
      name: 'FULGA DE JOS',
      zip: '133401',
      isUrban: false,
    },
    {
      name: 'FULGA DE SUS',
      zip: '133410',
      isUrban: false,
    },
    {
      name: 'GHERGHITA',
      zip: '133438',
      isUrban: false,
    },
    {
      name: 'FANARI',
      zip: '133447',
      isUrban: false,
    },
    {
      name: 'INDEPENDENTA',
      zip: '133456',
      isUrban: false,
    },
    {
      name: 'MALAMUC',
      zip: '133465',
      isUrban: false,
    },
    {
      name: 'OLARI',
      zip: '133474',
      isUrban: false,
    },
    {
      name: 'OLARII VECHI',
      zip: '133483',
      isUrban: false,
    },
    {
      name: 'UNGURENI',
      zip: '133492',
      isUrban: false,
    },
    {
      name: 'GORGOTA',
      zip: '133517',
      isUrban: false,
    },
    {
      name: 'CRIVINA',
      zip: '133526',
      isUrban: false,
    },
    {
      name: 'FANARI',
      zip: '133535',
      isUrban: false,
    },
    {
      name: 'POIENARII APOSTOLI',
      zip: '133544',
      isUrban: false,
    },
    {
      name: 'POTIGRAFU',
      zip: '133553',
      isUrban: false,
    },
    {
      name: 'GORNET',
      zip: '133571',
      isUrban: false,
    },
    {
      name: 'BOGDANESTI',
      zip: '133580',
      isUrban: false,
    },
    {
      name: 'CUIB',
      zip: '133599',
      isUrban: false,
    },
    {
      name: 'NUCET',
      zip: '133606',
      isUrban: false,
    },
    {
      name: 'GORNET-CRICOV',
      zip: '133624',
      isUrban: false,
    },
    {
      name: 'COSERELE',
      zip: '133633',
      isUrban: false,
    },
    {
      name: 'DOBROTA',
      zip: '133642',
      isUrban: false,
    },
    {
      name: 'PRISEACA',
      zip: '133651',
      isUrban: false,
    },
    {
      name: 'TARCULESTI',
      zip: '133660',
      isUrban: false,
    },
    {
      name: 'VALEA SEACA',
      zip: '133679',
      isUrban: false,
    },
    {
      name: 'GURA VADULUI',
      zip: '133697',
      isUrban: false,
    },
    {
      name: 'PERSUNARI',
      zip: '133704',
      isUrban: false,
    },
    {
      name: 'TOHANI',
      zip: '133713',
      isUrban: false,
    },
    {
      name: 'GURA VITIOAREI',
      zip: '131844',
      isUrban: false,
    },
    {
      name: 'BUGHEA DE JOS',
      zip: '131853',
      isUrban: false,
    },
    {
      name: 'FAGETU',
      zip: '131862',
      isUrban: false,
    },
    {
      name: 'FUNDENI',
      zip: '131871',
      isUrban: false,
    },
    {
      name: 'POIANA COPACENI',
      zip: '131880',
      isUrban: false,
    },
    {
      name: 'IORDACHEANU',
      zip: '133731',
      isUrban: false,
    },
    {
      name: 'MOCESTI',
      zip: '133740',
      isUrban: false,
    },
    {
      name: 'PLAVIA',
      zip: '133759',
      isUrban: false,
    },
    {
      name: 'STRAOSTI',
      zip: '133768',
      isUrban: false,
    },
    {
      name: 'VALEA CUCULUI',
      zip: '133777',
      isUrban: false,
    },
    {
      name: 'VARBILA',
      zip: '133786',
      isUrban: false,
    },
    {
      name: 'IZVOARELE',
      zip: '133802',
      isUrban: false,
    },
    {
      name: 'CERNESTI',
      zip: '133811',
      isUrban: false,
    },
    {
      name: 'CHIRITESTI',
      zip: '133820',
      isUrban: false,
    },
    {
      name: 'HOMORACIU',
      zip: '133839',
      isUrban: false,
    },
    {
      name: 'MALU VANAT',
      zip: '133848',
      isUrban: false,
    },
    {
      name: 'SCHIULESTI',
      zip: '133857',
      isUrban: false,
    },
    {
      name: 'JUGURENI',
      zip: '133875',
      isUrban: false,
    },
    {
      name: 'BOBOCI',
      zip: '133884',
      isUrban: false,
    },
    {
      name: 'MARGINEA PADURII',
      zip: '133893',
      isUrban: false,
    },
    {
      name: 'VALEA UNGHIULUI',
      zip: '133900',
      isUrban: false,
    },
    {
      name: 'LAPOS',
      zip: '133928',
      isUrban: false,
    },
    {
      name: 'GLOD',
      zip: '133937',
      isUrban: false,
    },
    {
      name: 'LAPOSEL',
      zip: '133946',
      isUrban: false,
    },
    {
      name: 'PIETRICICA',
      zip: '133955',
      isUrban: false,
    },
    {
      name: 'LIPANESTI',
      zip: '133973',
      isUrban: false,
    },
    {
      name: 'SATU NOU',
      zip: '133982',
      isUrban: false,
    },
    {
      name: 'SIPOTU',
      zip: '133991',
      isUrban: false,
    },
    {
      name: 'ZAMFIRA',
      zip: '134005',
      isUrban: false,
    },
    {
      name: 'MAGURELE',
      zip: '134023',
      isUrban: false,
    },
    {
      name: 'COADA MALULUI',
      zip: '134032',
      isUrban: false,
    },
    {
      name: 'IAZU',
      zip: '134041',
      isUrban: false,
    },
    {
      name: 'MAGURENI',
      zip: '134069',
      isUrban: false,
    },
    {
      name: 'COCORASTII CAPLII',
      zip: '134078',
      isUrban: false,
    },
    {
      name: 'LUNCA PRAHOVEI',
      zip: '134087',
      isUrban: false,
    },
    {
      name: 'MANECIU-UNGURENI',
      zip: '134103',
      isUrban: false,
    },
    {
      name: 'CHEIA',
      zip: '134112',
      isUrban: false,
    },
    {
      name: 'CHICIURENI',
      zip: '134121',
      isUrban: false,
    },
    {
      name: 'COSTENI',
      zip: '134130',
      isUrban: false,
    },
    {
      name: 'FACAIENI',
      zip: '134149',
      isUrban: false,
    },
    {
      name: 'GHEABA',
      zip: '134158',
      isUrban: false,
    },
    {
      name: 'MANASTIREA SUZANA',
      zip: '134167',
      isUrban: false,
    },
    {
      name: 'MANECIU-PAMANTENI',
      zip: '134176',
      isUrban: false,
    },
    {
      name: 'PLAIETU',
      zip: '134185',
      isUrban: false,
    },
    {
      name: 'MANESTI',
      zip: '134201',
      isUrban: false,
    },
    {
      name: 'BALTITA',
      zip: '134210',
      isUrban: false,
    },
    {
      name: 'CHESNOIU',
      zip: '134229',
      isUrban: false,
    },
    {
      name: 'COADA IZVORULUI',
      zip: '134238',
      isUrban: false,
    },
    {
      name: 'COCORASTII COLT',
      zip: '134247',
      isUrban: false,
    },
    {
      name: 'COCORASTII GRIND',
      zip: '134256',
      isUrban: false,
    },
    {
      name: 'COLTU DE JOS',
      zip: '134265',
      isUrban: false,
    },
    {
      name: 'GHIOLDUM',
      zip: '134274',
      isUrban: false,
    },
    {
      name: 'GURA CRIVATULUI',
      zip: '134283',
      isUrban: false,
    },
    {
      name: 'PERSUNARI',
      zip: '134292',
      isUrban: false,
    },
    {
      name: 'PIATRA',
      zip: '134309',
      isUrban: false,
    },
    {
      name: 'SATU DE SUS',
      zip: '134318',
      isUrban: false,
    },
    {
      name: 'ZALHANAUA',
      zip: '134327',
      isUrban: false,
    },
    {
      name: 'PACURETI',
      zip: '134345',
      isUrban: false,
    },
    {
      name: 'BARZILA',
      zip: '134354',
      isUrban: false,
    },
    {
      name: 'CURMATURA',
      zip: '134363',
      isUrban: false,
    },
    {
      name: 'MATITA',
      zip: '134372',
      isUrban: false,
    },
    {
      name: 'SLAVU',
      zip: '134381',
      isUrban: false,
    },
    {
      name: 'PAULESTI',
      zip: '130856',
      isUrban: false,
    },
    {
      name: 'COCOSESTI',
      zip: '130865',
      isUrban: false,
    },
    {
      name: 'GAGENI',
      zip: '130874',
      isUrban: false,
    },
    {
      name: 'PAULESTII NOI',
      zip: '130883',
      isUrban: false,
    },
    {
      name: 'PLOPU',
      zip: '134407',
      isUrban: false,
    },
    {
      name: 'GALMEIA',
      zip: '134416',
      isUrban: false,
    },
    {
      name: 'HARSA',
      zip: '134425',
      isUrban: false,
    },
    {
      name: 'NISIPOASA',
      zip: '134434',
      isUrban: false,
    },
    {
      name: 'PODENII NOI',
      zip: '134452',
      isUrban: false,
    },
    {
      name: 'GHIOCEL',
      zip: '134461',
      isUrban: false,
    },
    {
      name: 'MEHEDINTA',
      zip: '134470',
      isUrban: false,
    },
    {
      name: 'NEVESTEASCA',
      zip: '134489',
      isUrban: false,
    },
    {
      name: 'PODU LUI GALBEN',
      zip: '134498',
      isUrban: false,
    },
    {
      name: 'POPESTI',
      zip: '134504',
      isUrban: false,
    },
    {
      name: 'RAHOVA',
      zip: '134513',
      isUrban: false,
    },
    {
      name: 'SALCIOARA',
      zip: '134522',
      isUrban: false,
    },
    {
      name: 'SFACARU',
      zip: '134531',
      isUrban: false,
    },
    {
      name: 'VALEA DULCE',
      zip: '134540',
      isUrban: false,
    },
    {
      name: 'POIANA CAMPINA',
      zip: '131283',
      isUrban: false,
    },
    {
      name: 'BOBOLIA',
      zip: '131292',
      isUrban: false,
    },
    {
      name: 'PIETRISU',
      zip: '131309',
      isUrban: false,
    },
    {
      name: 'RAGMAN',
      zip: '131318',
      isUrban: false,
    },
    {
      name: 'POIENARII BURCHII',
      zip: '134568',
      isUrban: false,
    },
    {
      name: 'CARBUNARI',
      zip: '134577',
      isUrban: false,
    },
    {
      name: 'OLOGENI',
      zip: '134586',
      isUrban: false,
    },
    {
      name: 'PIORESTI',
      zip: '134595',
      isUrban: false,
    },
    {
      name: 'PODU VALENI',
      zip: '134602',
      isUrban: false,
    },
    {
      name: 'POIENARII RALI',
      zip: '134611',
      isUrban: false,
    },
    {
      name: 'POIENARII VECHI',
      zip: '134620',
      isUrban: false,
    },
    {
      name: 'TATARAI',
      zip: '134639',
      isUrban: false,
    },
    {
      name: 'POSESTII-PAMANTENI',
      zip: '134657',
      isUrban: false,
    },
    {
      name: 'BODESTI',
      zip: '134666',
      isUrban: false,
    },
    {
      name: 'MERDEALA',
      zip: '134675',
      isUrban: false,
    },
    {
      name: 'NUCSOARA DE JOS',
      zip: '134693',
      isUrban: false,
    },
    {
      name: 'NUCSOARA DE SUS',
      zip: '134684',
      isUrban: false,
    },
    {
      name: 'POSESTII UNGURENI',
      zip: '134700',
      isUrban: false,
    },
    {
      name: 'TARLESTI',
      zip: '134719',
      isUrban: false,
    },
    {
      name: 'VALEA PLOPULUI',
      zip: '134728',
      isUrban: false,
    },
    {
      name: 'VALEA SCREZII',
      zip: '134737',
      isUrban: false,
    },
    {
      name: 'VALEA STUPINII',
      zip: '134746',
      isUrban: false,
    },
    {
      name: 'PREDEAL',
      zip: '134764',
      isUrban: false,
    },
    {
      name: 'BOBICESTI',
      zip: '134773',
      isUrban: false,
    },
    {
      name: 'POIENILE',
      zip: '134782',
      isUrban: false,
    },
    {
      name: 'SARARI',
      zip: '134791',
      isUrban: false,
    },
    {
      name: 'SARATEL',
      zip: '134808',
      isUrban: false,
    },
    {
      name: 'TULBUREA',
      zip: '134817',
      isUrban: false,
    },
    {
      name: 'TULBUREA VALENI',
      zip: '134826',
      isUrban: false,
    },
    {
      name: 'VITIOARA DE SUS',
      zip: '134835',
      isUrban: false,
    },
    {
      name: 'ZAMBROAIA',
      zip: '134844',
      isUrban: false,
    },
    {
      name: 'PROVITA DE JOS',
      zip: '134862',
      isUrban: false,
    },
    {
      name: 'DRAGANEASA',
      zip: '134871',
      isUrban: false,
    },
    {
      name: 'PIATRA',
      zip: '134880',
      isUrban: false,
    },
    {
      name: 'PROVITA DE SUS',
      zip: '134906',
      isUrban: false,
    },
    {
      name: 'IZVORU',
      zip: '134915',
      isUrban: false,
    },
    {
      name: 'PLAIU',
      zip: '134924',
      isUrban: false,
    },
    {
      name: 'VALEA BRADULUI',
      zip: '134933',
      isUrban: false,
    },
    {
      name: 'PUCHENII MARI',
      zip: '134951',
      isUrban: false,
    },
    {
      name: 'MIROSLAVESTI',
      zip: '134960',
      isUrban: false,
    },
    {
      name: 'MOARA',
      zip: '134979',
      isUrban: false,
    },
    {
      name: 'ODAILE',
      zip: '134988',
      isUrban: false,
    },
    {
      name: 'PIETROSANI',
      zip: '134997',
      isUrban: false,
    },
    {
      name: 'PUCHENII MICI',
      zip: '135002',
      isUrban: false,
    },
    {
      name: 'PUCHENII MOSNENI',
      zip: '135011',
      isUrban: false,
    },
    {
      name: 'RAFOV',
      zip: '135039',
      isUrban: false,
    },
    {
      name: 'ANTOFILOAIA',
      zip: '135048',
      isUrban: false,
    },
    {
      name: 'BUCHILASI',
      zip: '135057',
      isUrban: false,
    },
    {
      name: 'BUDA',
      zip: '135066',
      isUrban: false,
    },
    {
      name: 'GOGA',
      zip: '135075',
      isUrban: false,
    },
    {
      name: 'MALAIESTI',
      zip: '135084',
      isUrban: false,
    },
    {
      name: 'MOARA DOMNEASCA',
      zip: '135093',
      isUrban: false,
    },
    {
      name: 'PALANCA',
      zip: '135100',
      isUrban: false,
    },
    {
      name: 'SICRITA',
      zip: '135119',
      isUrban: false,
    },
    {
      name: 'SALCIA',
      zip: '135137',
      isUrban: false,
    },
    {
      name: 'SALCIILE',
      zip: '135155',
      isUrban: false,
    },
    {
      name: 'SANGERU',
      zip: '135253',
      isUrban: false,
    },
    {
      name: 'BUTUCI',
      zip: '135262',
      isUrban: false,
    },
    {
      name: 'MIRESU MARE',
      zip: '135271',
      isUrban: false,
    },
    {
      name: 'MIRESU MIC',
      zip: '135280',
      isUrban: false,
    },
    {
      name: 'PIATRA MICA',
      zip: '135299',
      isUrban: false,
    },
    {
      name: 'TISA',
      zip: '135306',
      isUrban: false,
    },
    {
      name: 'SCORTENI',
      zip: '135173',
      isUrban: false,
    },
    {
      name: 'BORDENII MARI',
      zip: '135182',
      isUrban: false,
    },
    {
      name: 'BORDENII MICI',
      zip: '135191',
      isUrban: false,
    },
    {
      name: 'MISLEA',
      zip: '135208',
      isUrban: false,
    },
    {
      name: 'SARCA',
      zip: '135217',
      isUrban: false,
    },
    {
      name: 'SECARIA',
      zip: '135235',
      isUrban: false,
    },
    {
      name: 'SIRNA',
      zip: '135440',
      isUrban: false,
    },
    {
      name: 'BRATESTI',
      zip: '135459',
      isUrban: false,
    },
    {
      name: 'COCEANA',
      zip: '135468',
      isUrban: false,
    },
    {
      name: 'HABUD',
      zip: '135477',
      isUrban: false,
    },
    {
      name: 'TARICENI',
      zip: '135486',
      isUrban: false,
    },
    {
      name: 'VARNITA',
      zip: '135495',
      isUrban: false,
    },
    {
      name: 'SOIMARI',
      zip: '135510',
      isUrban: false,
    },
    {
      name: 'LOPATNITA',
      zip: '135529',
      isUrban: false,
    },
    {
      name: 'MAGURA',
      zip: '135538',
      isUrban: false,
    },
    {
      name: 'SOTRILE',
      zip: '135556',
      isUrban: false,
    },
    {
      name: 'LUNCA MARE',
      zip: '135565',
      isUrban: false,
    },
    {
      name: 'PLAIU CIMPINEI',
      zip: '135574',
      isUrban: false,
    },
    {
      name: 'PLAIU CORNULUI',
      zip: '135583',
      isUrban: false,
    },
    {
      name: 'SECIURI',
      zip: '135592',
      isUrban: false,
    },
    {
      name: 'VISTIERU',
      zip: '135609',
      isUrban: false,
    },
    {
      name: 'STARCHIOJD',
      zip: '135324',
      isUrban: false,
    },
    {
      name: 'BATRANI',
      zip: '135333',
      isUrban: false,
    },
    {
      name: 'BRADET',
      zip: '135342',
      isUrban: false,
    },
    {
      name: 'GRESIA',
      zip: '135351',
      isUrban: false,
    },
    {
      name: 'POIANA MARE',
      zip: '135360',
      isUrban: false,
    },
    {
      name: 'ROTAREA',
      zip: '135379',
      isUrban: false,
    },
    {
      name: 'VALEA ANEI',
      zip: '135388',
      isUrban: false,
    },
    {
      name: 'ZMEURET',
      zip: '135397',
      isUrban: false,
    },
    {
      name: 'STEFESTI',
      zip: '135627',
      isUrban: false,
    },
    {
      name: 'SCURTESTI',
      zip: '135636',
      isUrban: false,
    },
    {
      name: 'TARSORENI',
      zip: '135645',
      isUrban: false,
    },
    {
      name: 'SURANI',
      zip: '135413',
      isUrban: false,
    },
    {
      name: 'PACURI',
      zip: '135422',
      isUrban: false,
    },
    {
      name: 'TALEA',
      zip: '135663',
      isUrban: false,
    },
    {
      name: 'PLAIU',
      zip: '135672',
      isUrban: false,
    },
    {
      name: 'STREJNICU',
      zip: '130909',
      isUrban: false,
    },
    {
      name: 'STANCESTI',
      zip: '130918',
      isUrban: false,
    },
    {
      name: 'TARGSORU VECHI',
      zip: '130927',
      isUrban: false,
    },
    {
      name: 'ZAHANAUA',
      zip: '130936',
      isUrban: false,
    },
    {
      name: 'TATARU',
      zip: '135690',
      isUrban: false,
    },
    {
      name: 'PODGORIA',
      zip: '135707',
      isUrban: false,
    },
    {
      name: 'SILISTEA',
      zip: '135716',
      isUrban: false,
    },
    {
      name: 'TEISANI',
      zip: '135734',
      isUrban: false,
    },
    {
      name: 'BUGNEA DE SUS',
      zip: '135743',
      isUrban: false,
    },
    {
      name: 'OLTENI',
      zip: '135752',
      isUrban: false,
    },
    {
      name: 'STUBEIU',
      zip: '135761',
      isUrban: false,
    },
    {
      name: 'VALEA STALPULUI',
      zip: '135770',
      isUrban: false,
    },
    {
      name: 'TELEGA',
      zip: '135798',
      isUrban: false,
    },
    {
      name: 'BOSILCESTI',
      zip: '135805',
      isUrban: false,
    },
    {
      name: 'BUSTENARI',
      zip: '135814',
      isUrban: false,
    },
    {
      name: 'DOFTANA',
      zip: '135823',
      isUrban: false,
    },
    {
      name: 'MELICESTI',
      zip: '135832',
      isUrban: false,
    },
    {
      name: 'TONTESTI',
      zip: '135841',
      isUrban: false,
    },
    {
      name: 'TINOSU',
      zip: '135869',
      isUrban: false,
    },
    {
      name: 'PISCULESTI',
      zip: '135887',
      isUrban: false,
    },
    {
      name: 'PREDESTI',
      zip: '135878',
      isUrban: false,
    },
    {
      name: 'TOMSANI',
      zip: '135903',
      isUrban: false,
    },
    {
      name: 'LOLOIASCA',
      zip: '135912',
      isUrban: false,
    },
    {
      name: 'MAGULA',
      zip: '135921',
      isUrban: false,
    },
    {
      name: 'SATUCU',
      zip: '135930',
      isUrban: false,
    },
    {
      name: 'VALEA POIENII',
      zip: '136063',
      isUrban: false,
    },
    {
      name: 'VALEA POPII',
      zip: '136072',
      isUrban: false,
    },
    {
      name: 'VALEA URSOII',
      zip: '136081',
      isUrban: false,
    },
    {
      name: 'VARFURILE',
      zip: '136090',
      isUrban: false,
    },
    {
      name: 'TESILA',
      zip: '136116',
      isUrban: false,
    },
    {
      name: 'TRAISTENI',
      zip: '136125',
      isUrban: false,
    },
    {
      name: 'VARBILAU',
      zip: '136143',
      isUrban: false,
    },
    {
      name: 'COTOFENESTI',
      zip: '136152',
      isUrban: false,
    },
    {
      name: 'LIVADEA',
      zip: '136161',
      isUrban: false,
    },
    {
      name: 'PODU URSULUI',
      zip: '136170',
      isUrban: false,
    },
    {
      name: 'POIANA VARBILAU',
      zip: '136189',
      isUrban: false,
    },
    {
      name: 'ALUNIS',
      zip: '131997',
      isUrban: false,
    },
    {
      name: 'ZANOAGA',
      zip: '133152',
      isUrban: false,
    },
    {
      name: 'DUMBRAVESTI',
      zip: '131470',
      isUrban: false,
    },
    {
      name: 'GAVANEL',
      zip: '131489',
      isUrban: false,
    },
    {
      name: 'MALAESTII DE JOS',
      zip: '131498',
      isUrban: false,
    },
    {
      name: 'MALAESTII DE SUS',
      zip: '131504',
      isUrban: false,
    },
    {
      name: 'PLOPENI',
      zip: '131513',
      isUrban: false,
    },
    {
      name: 'SFARLEANCA',
      zip: '131522',
      isUrban: false,
    },
    {
      name: 'FANTANELE',
      zip: '133287',
      isUrban: false,
    },
    {
      name: 'BOZIENI',
      zip: '133296',
      isUrban: false,
    },
    {
      name: 'GHINOAICA',
      zip: '133303',
      isUrban: false,
    },
    {
      name: 'UNGURENI',
      zip: '133312',
      isUrban: false,
    },
    {
      name: 'VADU SAPAT',
      zip: '133321',
      isUrban: false,
    },
    {
      name: 'FILIPESTII DE PADURE',
      zip: '133170',
      isUrban: false,
    },
    {
      name: 'DITESTI',
      zip: '133189',
      isUrban: false,
    },
    {
      name: 'VALCANESTI',
      zip: '136205',
      isUrban: false,
    },
    {
      name: 'CARJARI',
      zip: '136214',
      isUrban: false,
    },
    {
      name: 'TRESTIOARA',
      zip: '136223',
      isUrban: false,
    },
    {
      name: 'VALEA CALUGAREASCA',
      zip: '135958',
      isUrban: false,
    },
    {
      name: 'ARVA',
      zip: '135967',
      isUrban: false,
    },
    {
      name: 'COSLEGI',
      zip: '135976',
      isUrban: false,
    },
    {
      name: 'DARVARI',
      zip: '135985',
      isUrban: false,
    },
    {
      name: 'PANTAZI',
      zip: '135994',
      isUrban: false,
    },
    {
      name: 'RACHIERI',
      zip: '136009',
      isUrban: false,
    },
    {
      name: 'RADILA',
      zip: '136018',
      isUrban: false,
    },
    {
      name: 'SCHIAU',
      zip: '136027',
      isUrban: false,
    },
    {
      name: 'VALEA LARGA',
      zip: '136036',
      isUrban: false,
    },
    {
      name: 'VALEA MANTEI',
      zip: '136045',
      isUrban: false,
    },
    {
      name: 'VALEA NICOVANI',
      zip: '136054',
      isUrban: false,
    }
  ],
  NT: [
    {
      name: 'PIATRA NEAMT',
      zip: '120735',
      isUrban: true,
    },
    {
      name: 'CIRITEI',
      zip: '120744',
      isUrban: true,
    },
    {
      name: 'DOAMNA',
      zip: '120753',
      isUrban: true,
    },
    {
      name: 'VALENI',
      zip: '120762',
      isUrban: true,
    },
    {
      name: 'ROMAN',
      zip: '120879',
      isUrban: true,
    },
    {
      name: 'BICAZ',
      zip: '120977',
      isUrban: true,
    },
    {
      name: 'CAPSA',
      zip: '120986',
      isUrban: true,
    },
    {
      name: 'DODENI',
      zip: '120995',
      isUrban: true,
    },
    {
      name: 'IZVORU MUNTELUI',
      zip: '121000',
      isUrban: true,
    },
    {
      name: 'IZVORUL ALB',
      zip: '121019',
      isUrban: true,
    },
    {
      name: 'POTOCI',
      zip: '121028',
      isUrban: true,
    },
    {
      name: 'SECU',
      zip: '121037',
      isUrban: true,
    },
    {
      name: 'TARGU NEAMT',
      zip: '121064',
      isUrban: true,
    },
    {
      name: 'BLEBEA',
      zip: '121073',
      isUrban: true,
    },
    {
      name: 'HUMULESTI',
      zip: '121082',
      isUrban: true,
    },
    {
      name: 'HUMULESTII NOI',
      zip: '121091',
      isUrban: true,
    },
    {
      name: 'AGAPIA',
      zip: '121117',
      isUrban: false,
    },
    {
      name: 'FILIOARA',
      zip: '121126',
      isUrban: false,
    },
    {
      name: 'SACALUSESTI',
      zip: '121135',
      isUrban: false,
    },
    {
      name: 'VARATEC',
      zip: '121144',
      isUrban: false,
    },
    {
      name: 'BAHNA',
      zip: '121162',
      isUrban: false,
    },
    {
      name: 'ARAMESTI',
      zip: '121171',
      isUrban: false,
    },
    {
      name: 'BAHNISOARA',
      zip: '121180',
      isUrban: false,
    },
    {
      name: 'BROSTENI',
      zip: '121199',
      isUrban: false,
    },
    {
      name: 'IZVOARE',
      zip: '121206',
      isUrban: false,
    },
    {
      name: 'LILIAC',
      zip: '121215',
      isUrban: false,
    },
    {
      name: 'TUTCANII DIN DEAL',
      zip: '121224',
      isUrban: false,
    },
    {
      name: 'TUTCANII DIN VALE',
      zip: '121233',
      isUrban: false,
    },
    {
      name: 'BALTATESTI',
      zip: '121251',
      isUrban: false,
    },
    {
      name: 'GHINDAOANI',
      zip: '121260',
      isUrban: false,
    },
    {
      name: 'VALEA ARINI',
      zip: '121279',
      isUrban: false,
    },
    {
      name: 'VALEA SEACA',
      zip: '121288',
      isUrban: false,
    },
    {
      name: 'BARGAUANI',
      zip: '121475',
      isUrban: false,
    },
    {
      name: 'BAHNA MARE',
      zip: '121484',
      isUrban: false,
    },
    {
      name: 'BALANESTI',
      zip: '121509',
      isUrban: false,
    },
    {
      name: 'BARATCA',
      zip: '121493',
      isUrban: false,
    },
    {
      name: 'BREAZA',
      zip: '121518',
      isUrban: false,
    },
    {
      name: 'CERTIENI',
      zip: '121527',
      isUrban: false,
    },
    {
      name: 'CHILIA',
      zip: '121536',
      isUrban: false,
    },
    {
      name: 'DARLOAIA',
      zip: '121545',
      isUrban: false,
    },
    {
      name: 'GHELAIESTI',
      zip: '121554',
      isUrban: false,
    },
    {
      name: 'HARTOP',
      zip: '121563',
      isUrban: false,
    },
    {
      name: 'HOMICENI',
      zip: '121572',
      isUrban: false,
    },
    {
      name: 'TALPA',
      zip: '121581',
      isUrban: false,
    },
    {
      name: 'VLADICENI',
      zip: '121590',
      isUrban: false,
    },
    {
      name: 'BICAZ-CHEI',
      zip: '121304',
      isUrban: false,
    },
    {
      name: 'BARNADU',
      zip: '121313',
      isUrban: false,
    },
    {
      name: 'GHERMAN',
      zip: '121322',
      isUrban: false,
    },
    {
      name: 'IVANES',
      zip: '121331',
      isUrban: false,
    },
    {
      name: 'BICAZU ARDELEAN',
      zip: '121359',
      isUrban: false,
    },
    {
      name: 'TELEC',
      zip: '121368',
      isUrban: false,
    },
    {
      name: 'TICOS',
      zip: '121377',
      isUrban: false,
    },
    {
      name: 'BIRA',
      zip: '121395',
      isUrban: false,
    },
    {
      name: 'BOGHICEA',
      zip: '121402',
      isUrban: false,
    },
    {
      name: 'CAUSENI',
      zip: '121411',
      isUrban: false,
    },
    {
      name: 'NEGRESTI',
      zip: '121420',
      isUrban: false,
    },
    {
      name: 'NISTRIA',
      zip: '121439',
      isUrban: false,
    },
    {
      name: 'REDIU',
      zip: '121448',
      isUrban: false,
    },
    {
      name: 'SLOBOZIA',
      zip: '121457',
      isUrban: false,
    },
    {
      name: 'BODESTI',
      zip: '121616',
      isUrban: false,
    },
    {
      name: 'BODESTII DE JOS',
      zip: '121625',
      isUrban: false,
    },
    {
      name: 'CORNI',
      zip: '121634',
      isUrban: false,
    },
    {
      name: 'OSLOBENI',
      zip: '121643',
      isUrban: false,
    },
    {
      name: 'BORCA',
      zip: '121661',
      isUrban: false,
    },
    {
      name: 'LUNCA',
      zip: '121670',
      isUrban: false,
    },
    {
      name: 'MADEI',
      zip: '121689',
      isUrban: false,
    },
    {
      name: 'PARAUL CARJEI',
      zip: '121698',
      isUrban: false,
    },
    {
      name: 'PARAUL PANTEI',
      zip: '121705',
      isUrban: false,
    },
    {
      name: 'SABASA',
      zip: '121714',
      isUrban: false,
    },
    {
      name: 'SOCI',
      zip: '121723',
      isUrban: false,
    },
    {
      name: 'BORLESTI',
      zip: '121741',
      isUrban: false,
    },
    {
      name: 'MASTACAN',
      zip: '121769',
      isUrban: false,
    },
    {
      name: 'NECHIT',
      zip: '121750',
      isUrban: false,
    },
    {
      name: 'RUSENI',
      zip: '121778',
      isUrban: false,
    },
    {
      name: 'SOVOAIA',
      zip: '121787',
      isUrban: false,
    },
    {
      name: 'BOTESTI',
      zip: '121803',
      isUrban: false,
    },
    {
      name: 'BARTICESTI',
      zip: '121812',
      isUrban: false,
    },
    {
      name: 'DAVID',
      zip: '121821',
      isUrban: false,
    },
    {
      name: 'MORENI',
      zip: '121830',
      isUrban: false,
    },
    {
      name: 'MUNTENI',
      zip: '121849',
      isUrban: false,
    },
    {
      name: 'NISIPORESTI',
      zip: '121858',
      isUrban: false,
    },
    {
      name: 'VALENI',
      zip: '121867',
      isUrban: false,
    },
    {
      name: 'BOZIENI',
      zip: '121885',
      isUrban: false,
    },
    {
      name: 'BANEASA',
      zip: '121894',
      isUrban: false,
    },
    {
      name: 'CRAIESTI',
      zip: '121901',
      isUrban: false,
    },
    {
      name: 'CUCI',
      zip: '121910',
      isUrban: false,
    },
    {
      name: 'IUCSA',
      zip: '121929',
      isUrban: false,
    },
    {
      name: 'BRUSTURI',
      zip: '121947',
      isUrban: false,
    },
    {
      name: 'DRAGANESTI',
      zip: '121956',
      isUrban: false,
    },
    {
      name: 'GROSI',
      zip: '121965',
      isUrban: false,
    },
    {
      name: 'ORTASTI',
      zip: '121974',
      isUrban: false,
    },
    {
      name: 'POIANA',
      zip: '121983',
      isUrban: false,
    },
    {
      name: 'RASCA',
      zip: '121992',
      isUrban: false,
    },
    {
      name: 'SOIMARESTI',
      zip: '122007',
      isUrban: false,
    },
    {
      name: 'TARZIA',
      zip: '122016',
      isUrban: false,
    },
    {
      name: 'CANDESTI',
      zip: '122070',
      isUrban: false,
    },
    {
      name: 'BARCANESTI',
      zip: '122089',
      isUrban: false,
    },
    {
      name: 'DRAGOVA',
      zip: '122098',
      isUrban: false,
    },
    {
      name: 'PADURENI',
      zip: '122105',
      isUrban: false,
    },
    {
      name: 'TARDENII MICI',
      zip: '122114',
      isUrban: false,
    },
    {
      name: 'VADURELE',
      zip: '122123',
      isUrban: false,
    },
    {
      name: 'CEAHLAU',
      zip: '122034',
      isUrban: false,
    },
    {
      name: 'BISTRICIOARA',
      zip: '122043',
      isUrban: false,
    },
    {
      name: 'PARAUL MARE',
      zip: '122052',
      isUrban: false,
    },
    {
      name: 'CORDUN',
      zip: '120897',
      isUrban: false,
    },
    {
      name: 'PILDESTI',
      zip: '120904',
      isUrban: false,
    },
    {
      name: 'SIMIONESTI',
      zip: '120913',
      isUrban: false,
    },
    {
      name: 'COSTISA',
      zip: '122141',
      isUrban: false,
    },
    {
      name: 'DORNESTI',
      zip: '122150',
      isUrban: false,
    },
    {
      name: 'FRUNZENI',
      zip: '122169',
      isUrban: false,
    },
    {
      name: 'MANOAIA',
      zip: '122178',
      isUrban: false,
    },
    {
      name: 'CRACAOANI',
      zip: '122196',
      isUrban: false,
    },
    {
      name: 'CRACAUL NEGRU',
      zip: '122203',
      isUrban: false,
    },
    {
      name: 'MAGAZIA',
      zip: '122212',
      isUrban: false,
    },
    {
      name: 'MITOCU BALAN',
      zip: '122221',
      isUrban: false,
    },
    {
      name: 'POIANA CRACAOANI',
      zip: '122230',
      isUrban: false,
    },
    {
      name: 'DAMUC',
      zip: '122258',
      isUrban: false,
    },
    {
      name: 'HUISUREZ',
      zip: '122267',
      isUrban: false,
    },
    {
      name: 'TREI FANTANI',
      zip: '122276',
      isUrban: false,
    },
    {
      name: 'DOBRENI',
      zip: '122294',
      isUrban: false,
    },
    {
      name: 'CASARIA',
      zip: '122301',
      isUrban: false,
    },
    {
      name: 'NEGRESTI',
      zip: '122310',
      isUrban: false,
    },
    {
      name: 'POIANA',
      zip: '122329',
      isUrban: false,
    },
    {
      name: 'SARATA',
      zip: '122338',
      isUrban: false,
    },
    {
      name: 'DOLJESTI',
      zip: '122356',
      isUrban: false,
    },
    {
      name: 'BUHONCA',
      zip: '122365',
      isUrban: false,
    },
    {
      name: 'BURUIENESTI',
      zip: '122374',
      isUrban: false,
    },
    {
      name: 'ROTUNDA',
      zip: '122383',
      isUrban: false,
    },
    {
      name: 'DRAGOMIRESTI',
      zip: '122409',
      isUrban: false,
    },
    {
      name: 'BORNIS',
      zip: '122418',
      isUrban: false,
    },
    {
      name: 'HLAPESTI',
      zip: '122427',
      isUrban: false,
    },
    {
      name: 'MASTACAN',
      zip: '122436',
      isUrban: false,
    },
    {
      name: 'UNGHI',
      zip: '122445',
      isUrban: false,
    },
    {
      name: 'VAD',
      zip: '122454',
      isUrban: false,
    },
    {
      name: 'DULCESTI',
      zip: '122472',
      isUrban: false,
    },
    {
      name: 'BOZIENII DE SUS',
      zip: '122481',
      isUrban: false,
    },
    {
      name: 'BRITCANI',
      zip: '122490',
      isUrban: false,
    },
    {
      name: 'CARLIG',
      zip: '122506',
      isUrban: false,
    },
    {
      name: 'CORHANA',
      zip: '122515',
      isUrban: false,
    },
    {
      name: 'POIANA',
      zip: '122524',
      isUrban: false,
    },
    {
      name: 'ROSIORI',
      zip: '122533',
      isUrban: false,
    },
    {
      name: 'RUGINOASA',
      zip: '122542',
      isUrban: false,
    },
    {
      name: 'DUMBRAVA ROSIE',
      zip: '120780',
      isUrban: false,
    },
    {
      name: 'BRASAUTI',
      zip: '120799',
      isUrban: false,
    },
    {
      name: 'CUT',
      zip: '120806',
      isUrban: false,
    },
    {
      name: 'IZVOARE',
      zip: '120815',
      isUrban: false,
    },
    {
      name: 'FARCASA',
      zip: '122560',
      isUrban: false,
    },
    {
      name: 'BUSMEI',
      zip: '122579',
      isUrban: false,
    },
    {
      name: 'FRUMOSU',
      zip: '122588',
      isUrban: false,
    },
    {
      name: 'POPESTI',
      zip: '122597',
      isUrban: false,
    },
    {
      name: 'STEJARU',
      zip: '122604',
      isUrban: false,
    },
    {
      name: 'FAUREI',
      zip: '122622',
      isUrban: false,
    },
    {
      name: 'BUDESTI',
      zip: '122631',
      isUrban: false,
    },
    {
      name: 'CLIMESTI',
      zip: '122640',
      isUrban: false,
    },
    {
      name: 'MICSUNESTI',
      zip: '122659',
      isUrban: false,
    },
    {
      name: 'GARCINA',
      zip: '122837',
      isUrban: false,
    },
    {
      name: 'ALMAS',
      zip: '122846',
      isUrban: false,
    },
    {
      name: 'CUEJDIU',
      zip: '122855',
      isUrban: false,
    },
    {
      name: 'GROZAVESTI',
      zip: '122999',
      isUrban: false,
    },
    {
      name: 'RUGINESTI',
      zip: '123004',
      isUrban: false,
    },
    {
      name: 'HORIA',
      zip: '120931',
      isUrban: false,
    },
    {
      name: 'COTU VAMES',
      zip: '120940',
      isUrban: false,
    },
    {
      name: 'ICUSESTI',
      zip: '123022',
      isUrban: false,
    },
    {
      name: 'BALUSESTI',
      zip: '123031',
      isUrban: false,
    },
    {
      name: 'BATRANESTI',
      zip: '123040',
      isUrban: false,
    },
    {
      name: 'MESTEACAN',
      zip: '123068',
      isUrban: false,
    },
    {
      name: 'ROCNA',
      zip: '123077',
      isUrban: false,
    },
    {
      name: 'SPIRIDONESTI',
      zip: '123086',
      isUrban: false,
    },
    {
      name: 'TABARA',
      zip: '123095',
      isUrban: false,
    },
    {
      name: 'ION CREANGA',
      zip: '123111',
      isUrban: false,
    },
    {
      name: 'AVERESTI',
      zip: '123120',
      isUrban: false,
    },
    {
      name: 'IZVORU',
      zip: '123139',
      isUrban: false,
    },
    {
      name: 'MUNCELU',
      zip: '123148',
      isUrban: false,
    },
    {
      name: 'RECEA',
      zip: '123157',
      isUrban: false,
    },
    {
      name: 'STEJARU',
      zip: '123166',
      isUrban: false,
    },
    {
      name: 'MARGINENI',
      zip: '123184',
      isUrban: false,
    },
    {
      name: 'HARTESTI',
      zip: '123193',
      isUrban: false,
    },
    {
      name: 'HOISESTI',
      zip: '123200',
      isUrban: false,
    },
    {
      name: 'ITRINESTI',
      zip: '123219',
      isUrban: false,
    },
    {
      name: 'MOLDOVENI',
      zip: '123237',
      isUrban: false,
    },
    {
      name: 'HOCIUNGI',
      zip: '123246',
      isUrban: false,
    },
    {
      name: 'ONICENI',
      zip: '123264',
      isUrban: false,
    },
    {
      name: 'CIORNEI',
      zip: '123273',
      isUrban: false,
    },
    {
      name: 'GORUN',
      zip: '123282',
      isUrban: false,
    },
    {
      name: 'LINSESTI',
      zip: '123291',
      isUrban: false,
    },
    {
      name: 'LUNCA',
      zip: '123308',
      isUrban: false,
    },
    {
      name: 'MARMURENI',
      zip: '123317',
      isUrban: false,
    },
    {
      name: 'PIETROSU',
      zip: '123326',
      isUrban: false,
    },
    {
      name: 'POIANA HUMEI',
      zip: '123335',
      isUrban: false,
    },
    {
      name: 'PUSTIETA',
      zip: '123344',
      isUrban: false,
    },
    {
      name: 'SOLCA',
      zip: '123353',
      isUrban: false,
    },
    {
      name: 'VALEA ENEI',
      zip: '123362',
      isUrban: false,
    },
    {
      name: 'PANGARATI',
      zip: '123610',
      isUrban: false,
    },
    {
      name: 'OANTU',
      zip: '123629',
      isUrban: false,
    },
    {
      name: 'PANGARACIOR',
      zip: '123638',
      isUrban: false,
    },
    {
      name: 'POIANA',
      zip: '123647',
      isUrban: false,
    },
    {
      name: 'PRELUCA',
      zip: '123656',
      isUrban: false,
    },
    {
      name: 'STEJARU',
      zip: '123665',
      isUrban: false,
    },
    {
      name: 'PASTRAVENI',
      zip: '123380',
      isUrban: false,
    },
    {
      name: 'LUNCA MOLDOVEI',
      zip: '123399',
      isUrban: false,
    },
    {
      name: 'RADENI',
      zip: '123406',
      isUrban: false,
    },
    {
      name: 'SPIESTI',
      zip: '123415',
      isUrban: false,
    },
    {
      name: 'PETRICANI',
      zip: '123433',
      isUrban: false,
    },
    {
      name: 'BOISTEA',
      zip: '123442',
      isUrban: false,
    },
    {
      name: 'TARPESTI',
      zip: '123451',
      isUrban: false,
    },
    {
      name: 'TOLICI',
      zip: '123460',
      isUrban: false,
    },
    {
      name: 'PIATRA SOIMULUI',
      zip: '123488',
      isUrban: false,
    },
    {
      name: 'LUMINIS',
      zip: '123497',
      isUrban: false,
    },
    {
      name: 'NEGULESTI',
      zip: '123503',
      isUrban: false,
    },
    {
      name: 'POIENI',
      zip: '123512',
      isUrban: false,
    },
    {
      name: 'PIPIRIG',
      zip: '123530',
      isUrban: false,
    },
    {
      name: 'BOBOIESTI',
      zip: '123549',
      isUrban: false,
    },
    {
      name: 'DOLHESTI',
      zip: '123558',
      isUrban: false,
    },
    {
      name: 'LEGHIN',
      zip: '123567',
      isUrban: false,
    },
    {
      name: 'PATALIGENI',
      zip: '123576',
      isUrban: false,
    },
    {
      name: 'PLUTON',
      zip: '123585',
      isUrban: false,
    },
    {
      name: 'STANCA',
      zip: '123594',
      isUrban: false,
    },
    {
      name: 'PODOLENI',
      zip: '123683',
      isUrban: false,
    },
    {
      name: 'NEGRITESTI',
      zip: '123692',
      isUrban: false,
    },
    {
      name: 'POIANA TEIULUI',
      zip: '123807',
      isUrban: false,
    },
    {
      name: 'CALUGARENI',
      zip: '123816',
      isUrban: false,
    },
    {
      name: 'DREPTU',
      zip: '123825',
      isUrban: false,
    },
    {
      name: 'GALU',
      zip: '123834',
      isUrban: false,
    },
    {
      name: 'PARAUL FAGULUI',
      zip: '123852',
      isUrban: false,
    },
    {
      name: 'PETRU VODA',
      zip: '123843',
      isUrban: false,
    },
    {
      name: 'POIANA LARGULUI',
      zip: '123861',
      isUrban: false,
    },
    {
      name: 'ROSENI',
      zip: '123870',
      isUrban: false,
    },
    {
      name: 'RUSENI',
      zip: '123889',
      isUrban: false,
    },
    {
      name: 'SAVINESTI',
      zip: '123898',
      isUrban: false,
    },
    {
      name: 'TOPOLICENI',
      zip: '123905',
      isUrban: false,
    },
    {
      name: 'POIENARI',
      zip: '123718',
      isUrban: false,
    },
    {
      name: 'BUNGHI',
      zip: '123727',
      isUrban: false,
    },
    {
      name: 'CIUREA',
      zip: '123736',
      isUrban: false,
    },
    {
      name: 'HOLM',
      zip: '123745',
      isUrban: false,
    },
    {
      name: 'PANCESTI',
      zip: '123763',
      isUrban: false,
    },
    {
      name: 'PATRICHENI',
      zip: '123754',
      isUrban: false,
    },
    {
      name: 'SACALENI',
      zip: '123772',
      isUrban: false,
    },
    {
      name: 'TALPALAI',
      zip: '123781',
      isUrban: false,
    },
    {
      name: 'RAUCESTI',
      zip: '123923',
      isUrban: false,
    },
    {
      name: 'OGLINZI',
      zip: '123932',
      isUrban: false,
    },
    {
      name: 'SAVESTI',
      zip: '123941',
      isUrban: false,
    },
    {
      name: 'UNGHENI',
      zip: '123950',
      isUrban: false,
    },
    {
      name: 'RAZBOIENII DE JOS',
      zip: '123978',
      isUrban: false,
    },
    {
      name: 'BORSENI',
      zip: '123987',
      isUrban: false,
    },
    {
      name: 'RAZBOIENI',
      zip: '123996',
      isUrban: false,
    },
    {
      name: 'VALEA ALBA',
      zip: '124000',
      isUrban: false,
    },
    {
      name: 'VALEA MARE',
      zip: '124019',
      isUrban: false,
    },
    {
      name: 'REDIU',
      zip: '124037',
      isUrban: false,
    },
    {
      name: 'BETESTI',
      zip: '124046',
      isUrban: false,
    },
    {
      name: 'POLOBOC',
      zip: '124055',
      isUrban: false,
    },
    {
      name: 'SOCEA',
      zip: '124064',
      isUrban: false,
    },
    {
      name: 'ROMANI',
      zip: '124082',
      isUrban: false,
    },
    {
      name: 'GOSMANI',
      zip: '124091',
      isUrban: false,
    },
    {
      name: 'SILISTEA',
      zip: '124108',
      isUrban: false,
    },
    {
      name: 'ROZNOV',
      zip: '124126',
      isUrban: true,
    },
    {
      name: 'CHINTINICI',
      zip: '124135',
      isUrban: true,
    },
    {
      name: 'SLOBOZIA',
      zip: '124144',
      isUrban: true,
    },
    {
      name: 'SABAOANI',
      zip: '124215',
      isUrban: false,
    },
    {
      name: 'TRAIAN',
      zip: '124224',
      isUrban: false,
    },
    {
      name: 'SAGNA',
      zip: '124162',
      isUrban: false,
    },
    {
      name: 'GADINTI',
      zip: '124171',
      isUrban: false,
    },
    {
      name: 'LUTCA',
      zip: '124180',
      isUrban: false,
    },
    {
      name: 'VULPASESTI',
      zip: '124199',
      isUrban: false,
    },
    {
      name: 'SAVINESTI',
      zip: '120833',
      isUrban: false,
    },
    {
      name: 'DUMBRAVA-DEAL',
      zip: '120842',
      isUrban: false,
    },
    {
      name: 'SECUIENI',
      zip: '124242',
      isUrban: false,
    },
    {
      name: 'BARJOVENI',
      zip: '124260',
      isUrban: false,
    },
    {
      name: 'BASTA',
      zip: '124251',
      isUrban: false,
    },
    {
      name: 'BOGZESTI',
      zip: '124279',
      isUrban: false,
    },
    {
      name: 'BUTNARESTI',
      zip: '124288',
      isUrban: false,
    },
    {
      name: 'GIULESTI',
      zip: '124297',
      isUrban: false,
    },
    {
      name: 'PRAJESTI',
      zip: '124304',
      isUrban: false,
    },
    {
      name: 'SECUIENII NOI',
      zip: '124313',
      isUrban: false,
    },
    {
      name: 'UNCESTI',
      zip: '124322',
      isUrban: false,
    },
    {
      name: 'STANITA',
      zip: '124340',
      isUrban: false,
    },
    {
      name: 'CHICEREA',
      zip: '124359',
      isUrban: false,
    },
    {
      name: 'GHIDION',
      zip: '124368',
      isUrban: false,
    },
    {
      name: 'POIENILE OANCEI',
      zip: '124377',
      isUrban: false,
    },
    {
      name: 'TODIRENI',
      zip: '124386',
      isUrban: false,
    },
    {
      name: 'VEJA',
      zip: '124395',
      isUrban: false,
    },
    {
      name: 'VLADNICELE',
      zip: '124402',
      isUrban: false,
    },
    {
      name: 'STEFAN CEL MARE',
      zip: '124420',
      isUrban: false,
    },
    {
      name: 'BORDEA',
      zip: '124439',
      isUrban: false,
    },
    {
      name: 'CARLIGI',
      zip: '124448',
      isUrban: false,
    },
    {
      name: 'DELENI',
      zip: '124457',
      isUrban: false,
    },
    {
      name: 'DUSESTI',
      zip: '124466',
      isUrban: false,
    },
    {
      name: 'GHIGOIESTI',
      zip: '124475',
      isUrban: false,
    },
    {
      name: 'SOCI',
      zip: '124484',
      isUrban: false,
    },
    {
      name: 'TAMASENI',
      zip: '124643',
      isUrban: false,
    },
    {
      name: 'ADJUDENI',
      zip: '124652',
      isUrban: false,
    },
    {
      name: 'TARCAU',
      zip: '124509',
      isUrban: false,
    },
    {
      name: 'ARDELUTA',
      zip: '124518',
      isUrban: false,
    },
    {
      name: 'BRATES',
      zip: '124527',
      isUrban: false,
    },
    {
      name: 'CAZACI',
      zip: '124536',
      isUrban: false,
    },
    {
      name: 'SCHITU TARCAU',
      zip: '124545',
      isUrban: false,
    },
    {
      name: 'STRAJA',
      zip: '124554',
      isUrban: false,
    },
    {
      name: 'TASCA',
      zip: '124572',
      isUrban: false,
    },
    {
      name: 'HAMZOAIA',
      zip: '124581',
      isUrban: false,
    },
    {
      name: 'NEAGRA',
      zip: '124590',
      isUrban: false,
    },
    {
      name: 'TICOS-FLOAREA',
      zip: '124607',
      isUrban: false,
    },
    {
      name: 'TAZLAU',
      zip: '124625',
      isUrban: false,
    },
    {
      name: 'TIBUCANI',
      zip: '124812',
      isUrban: false,
    },
    {
      name: 'DAVIDENI',
      zip: '124821',
      isUrban: false,
    },
    {
      name: 'TIBUCANII DE JOS',
      zip: '124830',
      isUrban: false,
    },
    {
      name: 'TIMISESTI',
      zip: '124670',
      isUrban: false,
    },
    {
      name: 'DUMBRAVA',
      zip: '124689',
      isUrban: false,
    },
    {
      name: 'PLAIESU',
      zip: '124698',
      isUrban: false,
    },
    {
      name: 'PREUTESTI',
      zip: '124705',
      isUrban: false,
    },
    {
      name: 'ZVORANESTI',
      zip: '124714',
      isUrban: false,
    },
    {
      name: 'TRIFESTI',
      zip: '124732',
      isUrban: false,
    },
    {
      name: 'MIRON COSTIN',
      zip: '124741',
      isUrban: false,
    },
    {
      name: 'TUPILATI',
      zip: '124769',
      isUrban: false,
    },
    {
      name: 'ARAMOAIA',
      zip: '124778',
      isUrban: false,
    },
    {
      name: 'HANUL ANCUTEI',
      zip: '124787',
      isUrban: false,
    },
    {
      name: 'TOTOIESTI',
      zip: '124796',
      isUrban: false,
    },
    {
      name: 'URECHENI',
      zip: '124858',
      isUrban: false,
    },
    {
      name: 'INGARESTI',
      zip: '124867',
      isUrban: false,
    },
    {
      name: 'PLUGARI',
      zip: '124876',
      isUrban: false,
    },
    {
      name: 'VALEA URSULUI',
      zip: '124894',
      isUrban: false,
    },
    {
      name: 'BUCIUM',
      zip: '124901',
      isUrban: false,
    },
    {
      name: 'CHILII',
      zip: '123059',
      isUrban: false,
    },
    {
      name: 'GIURGENI',
      zip: '124910',
      isUrban: false,
    },
    {
      name: 'MUNCELU DE JOS',
      zip: '124929',
      isUrban: false,
    },
    {
      name: 'VANATORI-NEAMT',
      zip: '125025',
      isUrban: false,
    },
    {
      name: 'LUNCA',
      zip: '125034',
      isUrban: false,
    },
    {
      name: 'MANASTIREA NEAMT',
      zip: '125043',
      isUrban: false,
    },
    {
      name: 'NEMTISOR',
      zip: '125052',
      isUrban: false,
    },
    {
      name: 'VIISOARA',
      zip: '124947',
      isUrban: false,
    },
    {
      name: 'AGARCIA',
      zip: '124956',
      isUrban: false,
    },
    {
      name: 'BISERICANI',
      zip: '124965',
      isUrban: false,
    },
    {
      name: 'BISTRITA',
      zip: '124974',
      isUrban: false,
    },
    {
      name: 'SCARICICA',
      zip: '124983',
      isUrban: false,
    },
    {
      name: 'VADURELE',
      zip: '125007',
      isUrban: false,
    },
    {
      name: 'VADURI',
      zip: '124992',
      isUrban: false,
    },
    {
      name: 'ZANESTI',
      zip: '125070',
      isUrban: false,
    },
    {
      name: 'TRAIAN',
      zip: '125089',
      isUrban: false,
    },
    {
      name: 'GHERAESTI',
      zip: '122677',
      isUrban: false,
    },
    {
      name: 'GHERAESTII NOI',
      zip: '122686',
      isUrban: false,
    },
    {
      name: 'TETCANI',
      zip: '122695',
      isUrban: false,
    },
    {
      name: 'GIROV',
      zip: '122711',
      isUrban: false,
    },
    {
      name: 'BALUSESTI',
      zip: '122720',
      isUrban: false,
    },
    {
      name: 'BOTESTI',
      zip: '122739',
      isUrban: false,
    },
    {
      name: 'CACIULESTI',
      zip: '122748',
      isUrban: false,
    },
    {
      name: 'DANESTI',
      zip: '122757',
      isUrban: false,
    },
    {
      name: 'DOCHIA',
      zip: '122766',
      isUrban: false,
    },
    {
      name: 'DOINA',
      zip: '122775',
      isUrban: false,
    },
    {
      name: 'GURA VAII',
      zip: '122784',
      isUrban: false,
    },
    {
      name: 'POPESTI',
      zip: '122793',
      isUrban: false,
    },
    {
      name: 'TURTURESTI',
      zip: '122800',
      isUrban: false,
    },
    {
      name: 'VERSESTI',
      zip: '122819',
      isUrban: false,
    },
    {
      name: 'GRINTIES',
      zip: '122873',
      isUrban: false,
    },
    {
      name: 'BRADU',
      zip: '122882',
      isUrban: false,
    },
    {
      name: 'POIANA',
      zip: '122891',
      isUrban: false,
    },
    {
      name: 'GRUMAZESTI',
      zip: '122917',
      isUrban: false,
    },
    {
      name: 'CURECHISTEA',
      zip: '122926',
      isUrban: false,
    },
    {
      name: 'NETEZI',
      zip: '122935',
      isUrban: false,
    },
    {
      name: 'TOPOLITA',
      zip: '122944',
      isUrban: false,
    },
    {
      name: 'HANGU',
      zip: '122962',
      isUrban: false,
    },
    {
      name: 'BUHALNITA',
      zip: '122971',
      isUrban: false,
    },
    {
      name: 'CHIRITENI',
      zip: '122980',
      isUrban: false,
    }
  ],
  SB: [
    {
      name: 'SIBIU',
      zip: '143469',
      isUrban: true,
    },
    {
      name: 'PALTINIS',
      zip: '143478',
      isUrban: true,
    },
    {
      name: 'MEDIAS',
      zip: '143628',
      isUrban: true,
    },
    {
      name: 'IGHISU NOU',
      zip: '143637',
      isUrban: true,
    },
    {
      name: 'AGNITA',
      zip: '143691',
      isUrban: true,
    },
    {
      name: 'COVES',
      zip: '143708',
      isUrban: true,
    },
    {
      name: 'RUJA',
      zip: '143717',
      isUrban: true,
    },
    {
      name: 'AVRIG',
      zip: '144063',
      isUrban: true,
    },
    {
      name: 'BRADU',
      zip: '144072',
      isUrban: true,
    },
    {
      name: 'GLAMBOACA',
      zip: '144081',
      isUrban: true,
    },
    {
      name: 'MARSA',
      zip: '144090',
      isUrban: true,
    },
    {
      name: 'SACADATE',
      zip: '144107',
      isUrban: true,
    },
    {
      name: 'CISNADIE',
      zip: '143744',
      isUrban: true,
    },
    {
      name: 'CISNADIOARA',
      zip: '143753',
      isUrban: true,
    },
    {
      name: 'COPSA MICA',
      zip: '143780',
      isUrban: true,
    },
    {
      name: 'DUMBRAVENI',
      zip: '143815',
      isUrban: true,
    },
    {
      name: 'ERNEA',
      zip: '143824',
      isUrban: true,
    },
    {
      name: 'SAROS PE TARNAVE',
      zip: '143833',
      isUrban: true,
    },
    {
      name: 'OCNA SIBIULUI',
      zip: '143860',
      isUrban: true,
    },
    {
      name: 'TOPARCEA',
      zip: '143879',
      isUrban: true,
    },
    {
      name: 'TALMACIU',
      zip: '145836',
      isUrban: true,
    },
    {
      name: 'BOITA',
      zip: '145845',
      isUrban: false,
    },
    {
      name: 'COLONIA TALMACIU',
      zip: '145854',
      isUrban: true,
    },
    {
      name: 'LAZARET',
      zip: '145863',
      isUrban: false,
    },
    {
      name: 'LOTRIOARA',
      zip: '145872',
      isUrban: false,
    },
    {
      name: 'PALTIN',
      zip: '145881',
      isUrban: false,
    },
    {
      name: 'TALMACEL',
      zip: '145890',
      isUrban: true,
    },
    {
      name: 'ALTINA',
      zip: '143897',
      isUrban: false,
    },
    {
      name: 'BENESTI',
      zip: '143904',
      isUrban: false,
    },
    {
      name: 'GHIJASA DE SUS',
      zip: '143913',
      isUrban: false,
    },
    {
      name: 'APOLDU DE JOS',
      zip: '143931',
      isUrban: false,
    },
    {
      name: 'SANGATIN',
      zip: '143940',
      isUrban: false,
    },
    {
      name: 'ARPASU DE JOS',
      zip: '143968',
      isUrban: false,
    },
    {
      name: 'ARPASU DE SUS',
      zip: '143977',
      isUrban: false,
    },
    {
      name: 'NOUL ROMAN',
      zip: '143986',
      isUrban: false,
    },
    {
      name: 'ATEL',
      zip: '144009',
      isUrban: false,
    },
    {
      name: 'ALMA',
      zip: '144018',
      isUrban: false,
    },
    {
      name: 'DUPUS',
      zip: '144027',
      isUrban: false,
    },
    {
      name: 'GIACAS',
      zip: '144036',
      isUrban: false,
    },
    {
      name: 'SMIG',
      zip: '144045',
      isUrban: false,
    },
    {
      name: 'AXENTE SEVER',
      zip: '144125',
      isUrban: false,
    },
    {
      name: 'AGARBICIU',
      zip: '144134',
      isUrban: false,
    },
    {
      name: 'SOALA',
      zip: '144143',
      isUrban: false,
    },
    {
      name: 'BAZNA',
      zip: '144161',
      isUrban: false,
    },
    {
      name: 'BOIAN',
      zip: '144170',
      isUrban: false,
    },
    {
      name: 'VELT',
      zip: '144189',
      isUrban: false,
    },
    {
      name: 'BIERTAN',
      zip: '144205',
      isUrban: false,
    },
    {
      name: 'COPSA MARE',
      zip: '144214',
      isUrban: false,
    },
    {
      name: 'RICHIS',
      zip: '144223',
      isUrban: false,
    },
    {
      name: 'BIRGHIS',
      zip: '144241',
      isUrban: false,
    },
    {
      name: 'APOS',
      zip: '144250',
      isUrban: false,
    },
    {
      name: 'IGHISU VECHI',
      zip: '144269',
      isUrban: false,
    },
    {
      name: 'PELISOR',
      zip: '144278',
      isUrban: false,
    },
    {
      name: 'VECERD',
      zip: '144287',
      isUrban: false,
    },
    {
      name: 'ZLAGNA',
      zip: '144296',
      isUrban: false,
    },
    {
      name: 'BLAJEL',
      zip: '144312',
      isUrban: false,
    },
    {
      name: 'PAUCEA',
      zip: '144321',
      isUrban: false,
    },
    {
      name: 'ROMANESTI',
      zip: '144330',
      isUrban: false,
    },
    {
      name: 'BRADENI',
      zip: '144385',
      isUrban: false,
    },
    {
      name: 'RETIS',
      zip: '144394',
      isUrban: false,
    },
    {
      name: 'TELINE',
      zip: '144401',
      isUrban: false,
    },
    {
      name: 'BRATEIU',
      zip: '144358',
      isUrban: false,
    },
    {
      name: 'BUZD',
      zip: '144367',
      isUrban: false,
    },
    {
      name: 'BRUIU',
      zip: '144429',
      isUrban: false,
    },
    {
      name: 'GHERDEAL',
      zip: '144438',
      isUrban: false,
    },
    {
      name: 'SOMARTIN',
      zip: '144447',
      isUrban: false,
    },
    {
      name: 'CARTA',
      zip: '144517',
      isUrban: false,
    },
    {
      name: 'POIENITA',
      zip: '144526',
      isUrban: false,
    },
    {
      name: 'CARTISOARA',
      zip: '144544',
      isUrban: false,
    },
    {
      name: 'CHIRPAR',
      zip: '144465',
      isUrban: false,
    },
    {
      name: 'SASAUS',
      zip: '144474',
      isUrban: false,
    },
    {
      name: 'VARD',
      zip: '144483',
      isUrban: false,
    },
    {
      name: 'VESEUD',
      zip: '144492',
      isUrban: false,
    },
    {
      name: 'CRISTIAN',
      zip: '143496',
      isUrban: false,
    },
    {
      name: 'DARLOS',
      zip: '144562',
      isUrban: false,
    },
    {
      name: 'CURCIU',
      zip: '144571',
      isUrban: false,
    },
    {
      name: 'VALEA LUNGA',
      zip: '144580',
      isUrban: false,
    },
    {
      name: 'GURA RAULUI',
      zip: '144606',
      isUrban: false,
    },
    {
      name: 'HOGHILAG',
      zip: '144624',
      isUrban: false,
    },
    {
      name: 'PROD',
      zip: '144633',
      isUrban: false,
    },
    {
      name: 'VALCHID',
      zip: '144642',
      isUrban: false,
    },
    {
      name: 'IACOBENI',
      zip: '144660',
      isUrban: false,
    },
    {
      name: 'MOVILE',
      zip: '144679',
      isUrban: false,
    },
    {
      name: 'NETUS',
      zip: '144688',
      isUrban: false,
    },
    {
      name: 'NOISTAT',
      zip: '144697',
      isUrban: false,
    },
    {
      name: 'STEJARISU',
      zip: '144704',
      isUrban: false,
    },
    {
      name: 'LASLEA',
      zip: '144740',
      isUrban: false,
    },
    {
      name: 'FLORESTI',
      zip: '144759',
      isUrban: false,
    },
    {
      name: 'MALANCRAV',
      zip: '144768',
      isUrban: false,
    },
    {
      name: 'NOU SASESC',
      zip: '144777',
      isUrban: false,
    },
    {
      name: 'ROANDOLA',
      zip: '144786',
      isUrban: false,
    },
    {
      name: 'LOAMNES',
      zip: '144802',
      isUrban: false,
    },
    {
      name: 'ALAMOR',
      zip: '144811',
      isUrban: false,
    },
    {
      name: 'ARMENI',
      zip: '144820',
      isUrban: false,
    },
    {
      name: 'HASAG',
      zip: '144839',
      isUrban: false,
    },
    {
      name: 'MANDRA',
      zip: '144848',
      isUrban: false,
    },
    {
      name: 'SADINCA',
      zip: '144857',
      isUrban: false,
    },
    {
      name: 'LUDOS',
      zip: '144875',
      isUrban: false,
    },
    {
      name: 'GUSU',
      zip: '144884',
      isUrban: false,
    },
    {
      name: 'MARPOD',
      zip: '144900',
      isUrban: false,
    },
    {
      name: 'ILIMBAV',
      zip: '144919',
      isUrban: false,
    },
    {
      name: 'MERGHINDEAL',
      zip: '144973',
      isUrban: false,
    },
    {
      name: 'DEALU FRUMOS',
      zip: '144982',
      isUrban: false,
    },
    {
      name: 'MICASASA',
      zip: '145006',
      isUrban: false,
    },
    {
      name: 'CHESLER',
      zip: '145015',
      isUrban: false,
    },
    {
      name: 'TAPU',
      zip: '145024',
      isUrban: false,
    },
    {
      name: 'VALENI',
      zip: '145033',
      isUrban: false,
    },
    {
      name: 'MIERCUREA SIBIULUI',
      zip: '144937',
      isUrban: true,
    },
    {
      name: 'APOLDU DE SUS',
      zip: '144946',
      isUrban: true,
    },
    {
      name: 'DOBARCA',
      zip: '144955',
      isUrban: true,
    },
    {
      name: 'MIHAILENI',
      zip: '145051',
      isUrban: false,
    },
    {
      name: 'METIS',
      zip: '145060',
      isUrban: false,
    },
    {
      name: 'MOARDAS',
      zip: '145079',
      isUrban: false,
    },
    {
      name: 'RAVASEL',
      zip: '145088',
      isUrban: false,
    },
    {
      name: 'SALCAU',
      zip: '145097',
      isUrban: false,
    },
    {
      name: 'MOSNA',
      zip: '145113',
      isUrban: false,
    },
    {
      name: 'ALMA VII',
      zip: '145122',
      isUrban: false,
    },
    {
      name: 'NEMSA',
      zip: '145131',
      isUrban: false,
    },
    {
      name: 'NOCRICH',
      zip: '145159',
      isUrban: false,
    },
    {
      name: 'FOFELDEA',
      zip: '145168',
      isUrban: false,
    },
    {
      name: 'GHIJASA DE JOS',
      zip: '145177',
      isUrban: false,
    },
    {
      name: 'HOSMAN',
      zip: '145186',
      isUrban: false,
    },
    {
      name: 'TICHINDEAL',
      zip: '145195',
      isUrban: false,
    },
    {
      name: 'ORLAT',
      zip: '145211',
      isUrban: false,
    },
    {
      name: 'PAUCA',
      zip: '145239',
      isUrban: false,
    },
    {
      name: 'BOGATU ROMAN',
      zip: '145248',
      isUrban: false,
    },
    {
      name: 'BROSTENI',
      zip: '145257',
      isUrban: false,
    },
    {
      name: 'PRESACA',
      zip: '145266',
      isUrban: false,
    },
    {
      name: 'POIANA SIBIULUI',
      zip: '145284',
      isUrban: false,
    },
    {
      name: 'POPLACA',
      zip: '143511',
      isUrban: false,
    },
    {
      name: 'PORUMBACU DE JOS',
      zip: '145300',
      isUrban: false,
    },
    {
      name: 'COLUN',
      zip: '145319',
      isUrban: false,
    },
    {
      name: 'PORUMBACU DE SUS',
      zip: '145328',
      isUrban: false,
    },
    {
      name: 'SARATA',
      zip: '145337',
      isUrban: false,
    },
    {
      name: 'SCOREIU',
      zip: '145346',
      isUrban: false,
    },
    {
      name: 'RACOVITA',
      zip: '145364',
      isUrban: false,
    },
    {
      name: 'SEBESU DE SUS',
      zip: '145373',
      isUrban: false,
    },
    {
      name: 'RASINARI',
      zip: '143539',
      isUrban: false,
    },
    {
      name: 'PRISLOP',
      zip: '143548',
      isUrban: false,
    },
    {
      name: 'RAU SADULUI',
      zip: '145391',
      isUrban: false,
    },
    {
      name: 'ROSIA',
      zip: '145417',
      isUrban: false,
    },
    {
      name: 'CASOLT',
      zip: '145426',
      isUrban: false,
    },
    {
      name: 'CORNATEL',
      zip: '145435',
      isUrban: false,
    },
    {
      name: 'NUCET',
      zip: '145462',
      isUrban: false,
    },
    {
      name: 'SADU',
      zip: '145480',
      isUrban: false,
    },
    {
      name: 'SALISTE',
      zip: '145505',
      isUrban: true,
    },
    {
      name: 'ACILIU',
      zip: '145514',
      isUrban: true,
    },
    {
      name: 'AMNAS',
      zip: '145523',
      isUrban: true,
    },
    {
      name: 'CRINT',
      zip: '145532',
      isUrban: true,
    },
    {
      name: 'FANTANELE',
      zip: '145550',
      isUrban: true,
    },
    {
      name: 'GALES',
      zip: '145541',
      isUrban: true,
    },
    {
      name: 'MAG',
      zip: '145569',
      isUrban: true,
    },
    {
      name: 'SACEL',
      zip: '145578',
      isUrban: true,
    },
    {
      name: 'SIBIEL',
      zip: '145587',
      isUrban: true,
    },
    {
      name: 'VALE',
      zip: '145596',
      isUrban: true,
    },
    {
      name: 'SEICA MARE',
      zip: '145676',
      isUrban: false,
    },
    {
      name: 'BOARTA',
      zip: '145685',
      isUrban: false,
    },
    {
      name: 'BUIA',
      zip: '145694',
      isUrban: false,
    },
    {
      name: 'MIGHINDOALA',
      zip: '145701',
      isUrban: false,
    },
    {
      name: 'PETIS',
      zip: '145710',
      isUrban: false,
    },
    {
      name: 'STENEA',
      zip: '145729',
      isUrban: false,
    },
    {
      name: 'SEICA MICA',
      zip: '145747',
      isUrban: false,
    },
    {
      name: 'SOROSTIN',
      zip: '145756',
      isUrban: false,
    },
    {
      name: 'SELIMBAR',
      zip: '143566',
      isUrban: false,
    },
    {
      name: 'BUNGARD',
      zip: '143575',
      isUrban: false,
    },
    {
      name: 'MOHU',
      zip: '143584',
      isUrban: false,
    },
    {
      name: 'VESTEM',
      zip: '143593',
      isUrban: false,
    },
    {
      name: 'SLIMNIC',
      zip: '145612',
      isUrban: false,
    },
    {
      name: 'ALBI',
      zip: '145621',
      isUrban: false,
    },
    {
      name: 'PADURENI',
      zip: '145630',
      isUrban: false,
    },
    {
      name: 'RUSI',
      zip: '145649',
      isUrban: false,
    },
    {
      name: 'VESEUD',
      zip: '145658',
      isUrban: false,
    },
    {
      name: 'SURA MARE',
      zip: '145774',
      isUrban: false,
    },
    {
      name: 'HAMBA',
      zip: '145783',
      isUrban: false,
    },
    {
      name: 'SURA MICA',
      zip: '145809',
      isUrban: false,
    },
    {
      name: 'RUSCIORI',
      zip: '145818',
      isUrban: false,
    },
    {
      name: 'TARNAVA',
      zip: '143655',
      isUrban: false,
    },
    {
      name: 'COLONIA TARNAVA',
      zip: '143664',
      isUrban: false,
    },
    {
      name: 'TILISCA',
      zip: '145916',
      isUrban: false,
    },
    {
      name: 'ROD',
      zip: '145925',
      isUrban: false,
    },
    {
      name: 'TURNU ROSU',
      zip: '145943',
      isUrban: false,
    },
    {
      name: 'SEBESU DE JOS',
      zip: '145952',
      isUrban: false,
    },
    {
      name: 'VALEA VIILOR',
      zip: '145970',
      isUrban: false,
    },
    {
      name: 'MOTIS',
      zip: '145989',
      isUrban: false,
    },
    {
      name: 'VURPAR',
      zip: '146003',
      isUrban: false,
    },
    {
      name: 'JINA',
      zip: '144722',
      isUrban: false,
    },
    {
      name: 'DAIA',
      zip: '145444',
      isUrban: false,
    },
    {
      name: 'NOU',
      zip: '145453',
      isUrban: false,
    }
  ],
  SJ: [
    {
      name: 'ZALAU',
      zip: '139713',
      isUrban: true,
    },
    {
      name: 'STANA',
      zip: '139722',
      isUrban: true,
    },
    {
      name: 'CEHU SILVANIEI',
      zip: '139759',
      isUrban: true,
    },
    {
      name: 'HOROATU CEHULUI',
      zip: '139768',
      isUrban: true,
    },
    {
      name: 'MOTIS',
      zip: '139777',
      isUrban: true,
    },
    {
      name: 'NADIS',
      zip: '139786',
      isUrban: true,
    },
    {
      name: 'ULCIUG',
      zip: '139795',
      isUrban: true,
    },
    {
      name: 'TARMURE',
      zip: '142943',
      isUrban: false,
    },
    {
      name: 'SOMES-ODORHEI',
      zip: '142729',
      isUrban: false,
    },
    {
      name: 'BARSA',
      zip: '142738',
      isUrban: false,
    },
    {
      name: 'DOMNIN',
      zip: '142747',
      isUrban: false,
    },
    {
      name: 'JIBOU',
      zip: '139820',
      isUrban: true,
    },
    {
      name: 'CUCEU',
      zip: '139839',
      isUrban: true,
    },
    {
      name: 'HUSIA',
      zip: '139848',
      isUrban: true,
    },
    {
      name: 'RONA',
      zip: '139857',
      isUrban: true,
    },
    {
      name: 'VAR',
      zip: '139866',
      isUrban: true,
    },
    {
      name: 'SIMLEU SILVANIEI',
      zip: '139893',
      isUrban: true,
    },
    {
      name: 'BIC',
      zip: '139900',
      isUrban: true,
    },
    {
      name: 'CEHEI',
      zip: '139919',
      isUrban: true,
    },
    {
      name: 'PUSTA',
      zip: '139928',
      isUrban: true,
    },
    {
      name: 'AGRIJ',
      zip: '139946',
      isUrban: false,
    },
    {
      name: 'RASTOLTU DESERT',
      zip: '139964',
      isUrban: false,
    },
    {
      name: 'ALMASU',
      zip: '139991',
      isUrban: false,
    },
    {
      name: 'BABIU',
      zip: '140002',
      isUrban: false,
    },
    {
      name: 'CUTIS',
      zip: '140011',
      isUrban: false,
    },
    {
      name: 'JEBUCU',
      zip: '140020',
      isUrban: false,
    },
    {
      name: 'MESTEACANU',
      zip: '140039',
      isUrban: false,
    },
    {
      name: 'PETRINZEL',
      zip: '140048',
      isUrban: false,
    },
    {
      name: 'SFARAS',
      zip: '140057',
      isUrban: false,
    },
    {
      name: 'STANA',
      zip: '140066',
      isUrban: false,
    },
    {
      name: 'TAUDU',
      zip: '140075',
      isUrban: false,
    },
    {
      name: 'BABENI',
      zip: '140093',
      isUrban: false,
    },
    {
      name: 'CIOCMANI',
      zip: '140100',
      isUrban: false,
    },
    {
      name: 'CLIT',
      zip: '140119',
      isUrban: false,
    },
    {
      name: 'PIROSA',
      zip: '140128',
      isUrban: false,
    },
    {
      name: 'POIENITA',
      zip: '140137',
      isUrban: false,
    },
    {
      name: 'BALAN',
      zip: '140155',
      isUrban: false,
    },
    {
      name: 'CHECHIS',
      zip: '140164',
      isUrban: false,
    },
    {
      name: 'CHENDREA',
      zip: '140173',
      isUrban: false,
    },
    {
      name: 'GALGAU ALMASULUI',
      zip: '140191',
      isUrban: false,
    },
    {
      name: 'GALPAIA',
      zip: '140182',
      isUrban: false,
    },
    {
      name: 'BANISOR',
      zip: '140217',
      isUrban: false,
    },
    {
      name: 'BAN',
      zip: '140226',
      isUrban: false,
    },
    {
      name: 'PECEIU',
      zip: '140235',
      isUrban: false,
    },
    {
      name: 'BENESAT',
      zip: '140253',
      isUrban: false,
    },
    {
      name: 'ALUNIS',
      zip: '140262',
      isUrban: false,
    },
    {
      name: 'BIUSA',
      zip: '140271',
      isUrban: false,
    },
    {
      name: 'BOBOTA',
      zip: '140299',
      isUrban: false,
    },
    {
      name: 'DERSIDA',
      zip: '140306',
      isUrban: false,
    },
    {
      name: 'ZALNOC',
      zip: '140315',
      isUrban: false,
    },
    {
      name: 'BOCSA',
      zip: '140333',
      isUrban: false,
    },
    {
      name: 'BORLA',
      zip: '140342',
      isUrban: false,
    },
    {
      name: 'CAMPIA',
      zip: '140351',
      isUrban: false,
    },
    {
      name: 'SALAJENI',
      zip: '140360',
      isUrban: false,
    },
    {
      name: 'BUCIUMI',
      zip: '140388',
      isUrban: false,
    },
    {
      name: 'BODIA',
      zip: '140397',
      isUrban: false,
    },
    {
      name: 'BOGDANA',
      zip: '140404',
      isUrban: false,
    },
    {
      name: 'HUTA',
      zip: '140413',
      isUrban: false,
    },
    {
      name: 'RASTOLT',
      zip: '140422',
      isUrban: false,
    },
    {
      name: 'SANGEORGIU DE MESES',
      zip: '140431',
      isUrban: false,
    },
    {
      name: 'CAMAR',
      zip: '140459',
      isUrban: false,
    },
    {
      name: 'PADURENI',
      zip: '140468',
      isUrban: false,
    },
    {
      name: 'CARASTELEC',
      zip: '140486',
      isUrban: false,
    },
    {
      name: 'DUMUSLAU',
      zip: '140495',
      isUrban: false,
    },
    {
      name: 'CHIESD',
      zip: '140510',
      isUrban: false,
    },
    {
      name: 'COLONIA SIGHETU SILVANIEI',
      zip: '140529',
      isUrban: false,
    },
    {
      name: 'SIGHETU SILVANIEI',
      zip: '140538',
      isUrban: false,
    },
    {
      name: 'CIZER',
      zip: '140556',
      isUrban: false,
    },
    {
      name: 'PLESCA',
      zip: '140565',
      isUrban: false,
    },
    {
      name: 'PRIA',
      zip: '140574',
      isUrban: false,
    },
    {
      name: 'COSEIU',
      zip: '140592',
      isUrban: false,
    },
    {
      name: 'ARCHID',
      zip: '140609',
      isUrban: false,
    },
    {
      name: 'CHILIOARA',
      zip: '140618',
      isUrban: false,
    },
    {
      name: 'CRASNA',
      zip: '140636',
      isUrban: false,
    },
    {
      name: 'HUSENI',
      zip: '140645',
      isUrban: false,
    },
    {
      name: 'MARIN',
      zip: '140654',
      isUrban: false,
    },
    {
      name: 'RATIN',
      zip: '140663',
      isUrban: false,
    },
    {
      name: 'CREACA',
      zip: '140681',
      isUrban: false,
    },
    {
      name: 'BORZA',
      zip: '140690',
      isUrban: false,
    },
    {
      name: 'BREBI',
      zip: '140707',
      isUrban: false,
    },
    {
      name: 'BRUSTURI',
      zip: '140716',
      isUrban: false,
    },
    {
      name: 'CIGLEAN',
      zip: '140725',
      isUrban: false,
    },
    {
      name: 'JAC',
      zip: '140734',
      isUrban: false,
    },
    {
      name: 'LUPOAIA',
      zip: '140743',
      isUrban: false,
    },
    {
      name: 'PRODANESTI',
      zip: '140752',
      isUrban: false,
    },
    {
      name: 'VIILE JACULUI',
      zip: '140761',
      isUrban: false,
    },
    {
      name: 'CRISENI',
      zip: '140832',
      isUrban: false,
    },
    {
      name: 'CRISTUR CRISENI',
      zip: '140841',
      isUrban: false,
    },
    {
      name: 'GARCEIU',
      zip: '140850',
      isUrban: false,
    },
    {
      name: 'CRISTOLT',
      zip: '140789',
      isUrban: false,
    },
    {
      name: 'MUNCEL',
      zip: '140798',
      isUrban: false,
    },
    {
      name: 'POIANA ONTII',
      zip: '140805',
      isUrban: false,
    },
    {
      name: 'VALENI',
      zip: '140814',
      isUrban: false,
    },
    {
      name: 'CUZAPLAC',
      zip: '140878',
      isUrban: false,
    },
    {
      name: 'CUBLESU',
      zip: '140887',
      isUrban: false,
    },
    {
      name: 'GALASENI',
      zip: '140896',
      isUrban: false,
    },
    {
      name: 'MIERTA',
      zip: '140903',
      isUrban: false,
    },
    {
      name: 'PETRINDU',
      zip: '140912',
      isUrban: false,
    },
    {
      name: 'RUGINOASA',
      zip: '140921',
      isUrban: false,
    },
    {
      name: 'STOBORU',
      zip: '140930',
      isUrban: false,
    },
    {
      name: 'TAMASA',
      zip: '140949',
      isUrban: false,
    },
    {
      name: 'DOBRIN',
      zip: '140967',
      isUrban: false,
    },
    {
      name: 'DELENI',
      zip: '140976',
      isUrban: false,
    },
    {
      name: 'DOBA',
      zip: '140985',
      isUrban: false,
    },
    {
      name: 'NAIMON',
      zip: '140994',
      isUrban: false,
    },
    {
      name: 'SANCRAIU SILVANIEI',
      zip: '141009',
      isUrban: false,
    },
    {
      name: 'VERVEGHIU',
      zip: '141018',
      isUrban: false,
    },
    {
      name: 'DRAGU',
      zip: '141036',
      isUrban: false,
    },
    {
      name: 'ADALIN',
      zip: '141045',
      isUrban: false,
    },
    {
      name: 'FANTANELE',
      zip: '141054',
      isUrban: false,
    },
    {
      name: 'UGRUTIU',
      zip: '141063',
      isUrban: false,
    },
    {
      name: 'VOIVODENI',
      zip: '141072',
      isUrban: false,
    },
    {
      name: 'FILDU DE JOS',
      zip: '141090',
      isUrban: false,
    },
    {
      name: 'FILDU DE MIJLOC',
      zip: '141107',
      isUrban: false,
    },
    {
      name: 'FILDU DE SUS',
      zip: '141116',
      isUrban: false,
    },
    {
      name: 'TETISU',
      zip: '141125',
      isUrban: false,
    },
    {
      name: 'GALGAU',
      zip: '141143',
      isUrban: false,
    },
    {
      name: 'BARSAU MARE',
      zip: '141152',
      isUrban: false,
    },
    {
      name: 'CAPALNA',
      zip: '141161',
      isUrban: false,
    },
    {
      name: 'CHIZENI',
      zip: '141170',
      isUrban: false,
    },
    {
      name: 'DOBROCINA',
      zip: '141189',
      isUrban: false,
    },
    {
      name: 'FODORA',
      zip: '141198',
      isUrban: false,
    },
    {
      name: 'FRANCENII DE PIATRA',
      zip: '141205',
      isUrban: false,
    },
    {
      name: 'GLOD',
      zip: '141214',
      isUrban: false,
    },
    {
      name: 'GURA VLADESEI',
      zip: '141223',
      isUrban: false,
    },
    {
      name: 'GARBOU',
      zip: '141241',
      isUrban: false,
    },
    {
      name: 'BEZDED',
      zip: '141250',
      isUrban: false,
    },
    {
      name: 'CALACEA',
      zip: '141269',
      isUrban: false,
    },
    {
      name: 'CERNUC',
      zip: '141278',
      isUrban: false,
    },
    {
      name: 'FABRICA',
      zip: '141287',
      isUrban: false,
    },
    {
      name: 'POPTELEAC',
      zip: '141296',
      isUrban: false,
    },
    {
      name: 'SOLOMON',
      zip: '141303',
      isUrban: false,
    },
    {
      name: 'HALMASD',
      zip: '141321',
      isUrban: false,
    },
    {
      name: 'ALEUS',
      zip: '141330',
      isUrban: false,
    },
    {
      name: 'CERISA',
      zip: '141349',
      isUrban: false,
    },
    {
      name: 'DRIGHIU',
      zip: '141358',
      isUrban: false,
    },
    {
      name: 'FUFEZ',
      zip: '141367',
      isUrban: false,
    },
    {
      name: 'HERECLEAN',
      zip: '141385',
      isUrban: false,
    },
    {
      name: 'BADON',
      zip: '141394',
      isUrban: false,
    },
    {
      name: 'BOCSITA',
      zip: '141401',
      isUrban: false,
    },
    {
      name: 'DIOSOD',
      zip: '141410',
      isUrban: false,
    },
    {
      name: 'GURUSLAU',
      zip: '141429',
      isUrban: false,
    },
    {
      name: 'PANIC',
      zip: '141438',
      isUrban: false,
    },
    {
      name: 'HIDA',
      zip: '141456',
      isUrban: false,
    },
    {
      name: 'BAICA',
      zip: '141465',
      isUrban: false,
    },
    {
      name: 'MILUANI',
      zip: '141474',
      isUrban: false,
    },
    {
      name: 'PADURIS',
      zip: '141483',
      isUrban: false,
    },
    {
      name: 'RACAS',
      zip: '141492',
      isUrban: false,
    },
    {
      name: 'SANPETRU ALMASULUI',
      zip: '141508',
      isUrban: false,
    },
    {
      name: 'STUPINI',
      zip: '141517',
      isUrban: false,
    },
    {
      name: 'TRESTIA',
      zip: '141526',
      isUrban: false,
    },
    {
      name: 'HOROATU CRASNEI',
      zip: '141544',
      isUrban: false,
    },
    {
      name: 'HUREZ',
      zip: '141553',
      isUrban: false,
    },
    {
      name: 'SEREDEIU',
      zip: '141571',
      isUrban: false,
    },
    {
      name: 'STARCIU',
      zip: '141562',
      isUrban: false,
    },
    {
      name: 'ILEANDA',
      zip: '141599',
      isUrban: false,
    },
    {
      name: 'BIRSAUTA',
      zip: '141615',
      isUrban: false,
    },
    {
      name: 'BIZUSA BAI',
      zip: '141606',
      isUrban: false,
    },
    {
      name: 'DABACENI',
      zip: '141624',
      isUrban: false,
    },
    {
      name: 'DOLHENI',
      zip: '141633',
      isUrban: false,
    },
    {
      name: 'LUMINISU',
      zip: '141642',
      isUrban: false,
    },
    {
      name: 'MALENI',
      zip: '141651',
      isUrban: false,
    },
    {
      name: 'NEGRENI',
      zip: '141660',
      isUrban: false,
    },
    {
      name: 'PERII VADULUI',
      zip: '141679',
      isUrban: false,
    },
    {
      name: 'PODISU',
      zip: '141688',
      isUrban: false,
    },
    {
      name: 'RASTOCI',
      zip: '141697',
      isUrban: false,
    },
    {
      name: 'ROGNA',
      zip: '141704',
      isUrban: false,
    },
    {
      name: 'SASA',
      zip: '141713',
      isUrban: false,
    },
    {
      name: 'IP',
      zip: '141731',
      isUrban: false,
    },
    {
      name: 'COSNICIU DE JOS',
      zip: '141740',
      isUrban: false,
    },
    {
      name: 'COSNICIU DE SUS',
      zip: '141759',
      isUrban: false,
    },
    {
      name: 'ZAUAN',
      zip: '141768',
      isUrban: false,
    },
    {
      name: 'ZAUAN BAI',
      zip: '141777',
      isUrban: false,
    },
    {
      name: 'LETCA',
      zip: '141795',
      isUrban: false,
    },
    {
      name: 'CIULA',
      zip: '141802',
      isUrban: false,
    },
    {
      name: 'COZLA',
      zip: '141811',
      isUrban: false,
    },
    {
      name: 'CUCIULAT',
      zip: '141820',
      isUrban: false,
    },
    {
      name: 'LEMNIU',
      zip: '141839',
      isUrban: false,
    },
    {
      name: 'PURCARET',
      zip: '141848',
      isUrban: false,
    },
    {
      name: 'SOIMUSENI',
      zip: '141857',
      isUrban: false,
    },
    {
      name: 'TOPLITA',
      zip: '141866',
      isUrban: false,
    },
    {
      name: 'VALISOARA',
      zip: '141875',
      isUrban: false,
    },
    {
      name: 'LOZNA',
      zip: '141893',
      isUrban: false,
    },
    {
      name: 'CORMENIS',
      zip: '141900',
      isUrban: false,
    },
    {
      name: 'PRELUCI',
      zip: '141919',
      isUrban: false,
    },
    {
      name: 'VALEA LESULUI',
      zip: '141928',
      isUrban: false,
    },
    {
      name: 'VALEA LOZNEI',
      zip: '141937',
      isUrban: false,
    },
    {
      name: 'MAERISTE',
      zip: '142015',
      isUrban: false,
    },
    {
      name: 'CRISTELEC',
      zip: '142024',
      isUrban: false,
    },
    {
      name: 'DOH',
      zip: '142033',
      isUrban: false,
    },
    {
      name: 'GIURTELECU SIMLEULUI',
      zip: '142042',
      isUrban: false,
    },
    {
      name: 'MALADIA',
      zip: '142051',
      isUrban: false,
    },
    {
      name: 'UILEACU SIMLEULUI',
      zip: '142060',
      isUrban: false,
    },
    {
      name: 'MARCA',
      zip: '141955',
      isUrban: false,
    },
    {
      name: 'LESMIR',
      zip: '141964',
      isUrban: false,
    },
    {
      name: 'MARCA HUTA',
      zip: '141973',
      isUrban: false,
    },
    {
      name: 'PORT',
      zip: '141982',
      isUrban: false,
    },
    {
      name: 'SUMAL',
      zip: '141991',
      isUrban: false,
    },
    {
      name: 'MESESENII DE JOS',
      zip: '142088',
      isUrban: false,
    },
    {
      name: 'AGHIRES',
      zip: '142097',
      isUrban: false,
    },
    {
      name: 'FETINDIA',
      zip: '142104',
      isUrban: false,
    },
    {
      name: 'MESESENII DE SUS',
      zip: '142113',
      isUrban: false,
    },
    {
      name: 'MIRSID',
      zip: '142131',
      isUrban: false,
    },
    {
      name: 'FIRMINIS',
      zip: '142140',
      isUrban: false,
    },
    {
      name: 'MOIGRAD-POROLISSUM',
      zip: '142159',
      isUrban: false,
    },
    {
      name: 'POPENI',
      zip: '142168',
      isUrban: false,
    },
    {
      name: 'NAPRADEA',
      zip: '142186',
      isUrban: false,
    },
    {
      name: 'CHEUD',
      zip: '142195',
      isUrban: false,
    },
    {
      name: 'SOMES GURUSLAU',
      zip: '142202',
      isUrban: false,
    },
    {
      name: 'TRANIS',
      zip: '142211',
      isUrban: false,
    },
    {
      name: 'VADURELE',
      zip: '142220',
      isUrban: false,
    },
    {
      name: 'NUSFALAU',
      zip: '142248',
      isUrban: false,
    },
    {
      name: 'BILGHEZ',
      zip: '142257',
      isUrban: false,
    },
    {
      name: 'BOGHIS',
      zip: '142266',
      isUrban: false,
    },
    {
      name: 'BOZIES',
      zip: '142275',
      isUrban: false,
    },
    {
      name: 'PERICEI',
      zip: '142293',
      isUrban: false,
    },
    {
      name: 'BADACIN',
      zip: '142300',
      isUrban: false,
    },
    {
      name: 'PERICEIU MIC',
      zip: '142319',
      isUrban: false,
    },
    {
      name: 'SICI',
      zip: '142328',
      isUrban: false,
    },
    {
      name: 'PLOPIS',
      zip: '142346',
      isUrban: false,
    },
    {
      name: 'FAGETU',
      zip: '142355',
      isUrban: false,
    },
    {
      name: 'IAZ',
      zip: '142364',
      isUrban: false,
    },
    {
      name: 'POIANA BLENCHII',
      zip: '142382',
      isUrban: false,
    },
    {
      name: 'FALCUSA',
      zip: '142391',
      isUrban: false,
    },
    {
      name: 'GOSTILA',
      zip: '142408',
      isUrban: false,
    },
    {
      name: 'MAGURA',
      zip: '142417',
      isUrban: false,
    },
    {
      name: 'ROMANASI',
      zip: '142435',
      isUrban: false,
    },
    {
      name: 'CHICHISA',
      zip: '142444',
      isUrban: false,
    },
    {
      name: 'CIUMARNA',
      zip: '142453',
      isUrban: false,
    },
    {
      name: 'PAUSA',
      zip: '142462',
      isUrban: false,
    },
    {
      name: 'POARTA SALAJULUI',
      zip: '142471',
      isUrban: false,
    },
    {
      name: 'ROMITA',
      zip: '142480',
      isUrban: false,
    },
    {
      name: 'RUS',
      zip: '142505',
      isUrban: false,
    },
    {
      name: 'BUZAS',
      zip: '142514',
      isUrban: false,
    },
    {
      name: 'FANTANELE RUS',
      zip: '142523',
      isUrban: false,
    },
    {
      name: 'HASMAS',
      zip: '142532',
      isUrban: false,
    },
    {
      name: 'SIMISNA',
      zip: '142541',
      isUrban: false,
    },
    {
      name: 'SAG',
      zip: '142621',
      isUrban: false,
    },
    {
      name: 'FIZES',
      zip: '142630',
      isUrban: false,
    },
    {
      name: 'MAL',
      zip: '142649',
      isUrban: false,
    },
    {
      name: 'SARBI',
      zip: '142658',
      isUrban: false,
    },
    {
      name: 'TUSA',
      zip: '142667',
      isUrban: false,
    },
    {
      name: 'SALATIG',
      zip: '142569',
      isUrban: false,
    },
    {
      name: 'BULGARI',
      zip: '142578',
      isUrban: false,
    },
    {
      name: 'DEJA',
      zip: '142587',
      isUrban: false,
    },
    {
      name: 'MINEU',
      zip: '142596',
      isUrban: false,
    },
    {
      name: 'NOTIG',
      zip: '142603',
      isUrban: false,
    },
    {
      name: 'SAMSUD',
      zip: '142863',
      isUrban: false,
    },
    {
      name: 'VALEA POMILOR',
      zip: '142872',
      isUrban: false,
    },
    {
      name: 'SANMIHAIU ALMASULUI',
      zip: '142685',
      isUrban: false,
    },
    {
      name: 'BERCEA',
      zip: '142694',
      isUrban: false,
    },
    {
      name: 'SANTA MARIA',
      zip: '142701',
      isUrban: false,
    },
    {
      name: 'SARMASAG',
      zip: '142890',
      isUrban: false,
    },
    {
      name: 'ILISUA',
      zip: '142907',
      isUrban: false,
    },
    {
      name: 'LOMPIRT',
      zip: '142916',
      isUrban: false,
    },
    {
      name: 'MOIAD',
      zip: '142925',
      isUrban: false,
    },
    {
      name: 'POIANA MAGURA',
      zip: '142934',
      isUrban: false,
    },
    {
      name: 'INAU',
      zip: '142756',
      isUrban: false,
    },
    {
      name: 'SOIMUS',
      zip: '142765',
      isUrban: false,
    },
    {
      name: 'SURDUC',
      zip: '142783',
      isUrban: false,
    },
    {
      name: 'BRAGLEZ',
      zip: '142792',
      isUrban: false,
    },
    {
      name: 'CRISTOLTEL',
      zip: '142809',
      isUrban: false,
    },
    {
      name: 'SOLONA',
      zip: '142818',
      isUrban: false,
    },
    {
      name: 'TESTIOARA',
      zip: '142827',
      isUrban: false,
    },
    {
      name: 'TIHAU',
      zip: '142836',
      isUrban: false,
    },
    {
      name: 'TURBUTA',
      zip: '142845',
      isUrban: false,
    },
    {
      name: 'TREZNEA',
      zip: '139973',
      isUrban: false,
    },
    {
      name: 'BOZNA',
      zip: '139955',
      isUrban: false,
    },
    {
      name: 'VALCAU DE JOS',
      zip: '142961',
      isUrban: false,
    },
    {
      name: 'LAZURI',
      zip: '142970',
      isUrban: false,
    },
    {
      name: 'PREOTEASA',
      zip: '142989',
      isUrban: false,
    },
    {
      name: 'RATOVEI',
      zip: '142998',
      isUrban: false,
    },
    {
      name: 'SUB CETATE',
      zip: '143003',
      isUrban: false,
    },
    {
      name: 'VALCAU DE SUS',
      zip: '143012',
      isUrban: false,
    },
    {
      name: 'VARSOLT',
      zip: '143030',
      isUrban: false,
    },
    {
      name: 'RECEA',
      zip: '143049',
      isUrban: false,
    },
    {
      name: 'RECEA MICA',
      zip: '143058',
      isUrban: false,
    },
    {
      name: 'ZALHA',
      zip: '143076',
      isUrban: false,
    },
    {
      name: 'CEACA',
      zip: '143085',
      isUrban: false,
    },
    {
      name: 'CIURENI',
      zip: '143094',
      isUrban: false,
    },
    {
      name: 'VALEA CIURENILOR',
      zip: '143101',
      isUrban: false,
    },
    {
      name: 'VALEA HRANEI',
      zip: '143110',
      isUrban: false,
    },
    {
      name: 'VALEA LUNGA',
      zip: '143129',
      isUrban: false,
    },
    {
      name: 'VARTESCA',
      zip: '143138',
      isUrban: false,
    },
    {
      name: 'ZIMBOR',
      zip: '143156',
      isUrban: false,
    },
    {
      name: 'CHENDREMAL',
      zip: '143165',
      isUrban: false,
    },
    {
      name: 'DOLU',
      zip: '143174',
      isUrban: false,
    },
    {
      name: 'SANCRAIU ALMASULUI',
      zip: '143192',
      isUrban: false,
    },
    {
      name: 'SUTORU',
      zip: '143183',
      isUrban: false,
    }
  ],
  SM: [
    {
      name: 'SATU-MARE',
      zip: '136492',
      isUrban: true,
    },
    {
      name: 'SATMAREL',
      zip: '136508',
      isUrban: true,
    },
    {
      name: 'CAREI',
      zip: '136535',
      isUrban: true,
    },
    {
      name: 'IANCULESTI',
      zip: '136544',
      isUrban: true,
    },
    {
      name: 'NEGRESTI-OAS',
      zip: '136606',
      isUrban: true,
    },
    {
      name: 'LUNA',
      zip: '136615',
      isUrban: true,
    },
    {
      name: 'TUR',
      zip: '136624',
      isUrban: true,
    },
    {
      name: 'TASNAD',
      zip: '136651',
      isUrban: true,
    },
    {
      name: 'BLAJA',
      zip: '136660',
      isUrban: true,
    },
    {
      name: 'CIG',
      zip: '136679',
      isUrban: true,
    },
    {
      name: 'RATIU',
      zip: '136688',
      isUrban: true,
    },
    {
      name: 'SARAUAD',
      zip: '136697',
      isUrban: true,
    },
    {
      name: 'VALEA MORII',
      zip: '136704',
      isUrban: true,
    },
    {
      name: 'ACAS',
      zip: '136722',
      isUrban: false,
    },
    {
      name: 'GANAS',
      zip: '136731',
      isUrban: false,
    },
    {
      name: 'MIHAIENI',
      zip: '136740',
      isUrban: false,
    },
    {
      name: 'UNIMAT',
      zip: '136759',
      isUrban: false,
    },
    {
      name: 'ANDRID',
      zip: '136777',
      isUrban: false,
    },
    {
      name: 'DINDESTI',
      zip: '136786',
      isUrban: false,
    },
    {
      name: 'IRINA',
      zip: '136795',
      isUrban: false,
    },
    {
      name: 'APA',
      zip: '136811',
      isUrban: false,
    },
    {
      name: 'LUNCA APEI',
      zip: '136820',
      isUrban: false,
    },
    {
      name: 'SOMESENI',
      zip: '136839',
      isUrban: false,
    },
    {
      name: 'ARDUD',
      zip: '136857',
      isUrban: true,
    },
    {
      name: 'ARDUD-VII',
      zip: '136866',
      isUrban: true,
    },
    {
      name: 'BABA NOVAC',
      zip: '136875',
      isUrban: true,
    },
    {
      name: 'GERAUSA',
      zip: '136884',
      isUrban: true,
    },
    {
      name: 'MADARAS',
      zip: '136893',
      isUrban: true,
    },
    {
      name: 'SARATURA',
      zip: '136900',
      isUrban: true,
    },
    {
      name: 'BARSAU DE SUS',
      zip: '137112',
      isUrban: false,
    },
    {
      name: 'BARSAU DE JOS',
      zip: '137121',
      isUrban: false,
    },
    {
      name: 'BATARCI',
      zip: '136928',
      isUrban: false,
    },
    {
      name: 'COMLAUSA',
      zip: '136937',
      isUrban: false,
    },
    {
      name: 'SIRLAU',
      zip: '136946',
      isUrban: false,
    },
    {
      name: 'TAMASENI',
      zip: '136955',
      isUrban: false,
    },
    {
      name: 'BELTIUG',
      zip: '136973',
      isUrban: false,
    },
    {
      name: 'BOLDA',
      zip: '136982',
      isUrban: false,
    },
    {
      name: 'GHIRISA',
      zip: '136991',
      isUrban: false,
    },
    {
      name: 'GIUNGI',
      zip: '137005',
      isUrban: false,
    },
    {
      name: 'RATESTI',
      zip: '137014',
      isUrban: false,
    },
    {
      name: 'SANDRA',
      zip: '137023',
      isUrban: false,
    },
    {
      name: 'BERVENI',
      zip: '137041',
      isUrban: false,
    },
    {
      name: 'LUCACENI',
      zip: '137050',
      isUrban: false,
    },
    {
      name: 'BIXAD',
      zip: '137078',
      isUrban: false,
    },
    {
      name: 'BOINESTI',
      zip: '137087',
      isUrban: false,
    },
    {
      name: 'TRIP',
      zip: '137096',
      isUrban: false,
    },
    {
      name: 'BOGDAND',
      zip: '137149',
      isUrban: false,
    },
    {
      name: 'BABTA',
      zip: '137158',
      isUrban: false,
    },
    {
      name: 'CORUND',
      zip: '137167',
      isUrban: false,
    },
    {
      name: 'SER',
      zip: '137176',
      isUrban: false,
    },
    {
      name: 'BOTIZ',
      zip: '137194',
      isUrban: false,
    },
    {
      name: 'AGRIS',
      zip: '137201',
      isUrban: false,
    },
    {
      name: 'CIUPERCENI',
      zip: '137210',
      isUrban: false,
    },
    {
      name: 'CALINESTI-OAS',
      zip: '137238',
      isUrban: false,
    },
    {
      name: 'COCA',
      zip: '137247',
      isUrban: false,
    },
    {
      name: 'LECHINTA',
      zip: '137256',
      isUrban: false,
    },
    {
      name: 'PASUNEA MARE',
      zip: '137265',
      isUrban: false,
    },
    {
      name: 'CAMARZANA',
      zip: '137283',
      isUrban: false,
    },
    {
      name: 'CAPLENI',
      zip: '136562',
      isUrban: false,
    },
    {
      name: 'CAMIN',
      zip: '136571',
      isUrban: false,
    },
    {
      name: 'CAUAS',
      zip: '137309',
      isUrban: false,
    },
    {
      name: 'ADY ENDRE',
      zip: '137318',
      isUrban: false,
    },
    {
      name: 'GHENCI',
      zip: '137327',
      isUrban: false,
    },
    {
      name: 'GHILESTI',
      zip: '137336',
      isUrban: false,
    },
    {
      name: 'HOTOAN',
      zip: '137345',
      isUrban: false,
    },
    {
      name: 'RADULESTI',
      zip: '137354',
      isUrban: false,
    },
    {
      name: 'CEHALUT',
      zip: '137372',
      isUrban: false,
    },
    {
      name: 'CEHAL',
      zip: '137381',
      isUrban: false,
    },
    {
      name: 'ORBAU',
      zip: '137390',
      isUrban: false,
    },
    {
      name: 'CERTEZE',
      zip: '137416',
      isUrban: false,
    },
    {
      name: 'HUTA CERTEZE',
      zip: '137425',
      isUrban: false,
    },
    {
      name: 'MOISENI',
      zip: '137434',
      isUrban: false,
    },
    {
      name: 'CRAIDOROLT',
      zip: '137452',
      isUrban: false,
    },
    {
      name: 'CRISENI',
      zip: '137461',
      isUrban: false,
    },
    {
      name: 'ERIU SANCRAI',
      zip: '137470',
      isUrban: false,
    },
    {
      name: 'SATU MIC',
      zip: '137489',
      isUrban: false,
    },
    {
      name: 'TEGHEA',
      zip: '137498',
      isUrban: false,
    },
    {
      name: 'CRUCISOR',
      zip: '137513',
      isUrban: false,
    },
    {
      name: 'IEGHERISTE',
      zip: '137522',
      isUrban: false,
    },
    {
      name: 'POIANA CODRULUI',
      zip: '137531',
      isUrban: false,
    },
    {
      name: 'CULCIU MARE',
      zip: '137559',
      isUrban: false,
    },
    {
      name: 'APATEU',
      zip: '137568',
      isUrban: false,
    },
    {
      name: 'CARASEU',
      zip: '137577',
      isUrban: false,
    },
    {
      name: 'COROD',
      zip: '137586',
      isUrban: false,
    },
    {
      name: 'CULCIU MIC',
      zip: '137595',
      isUrban: false,
    },
    {
      name: 'LIPAU',
      zip: '137602',
      isUrban: false,
    },
    {
      name: 'DOBA',
      zip: '137620',
      isUrban: false,
    },
    {
      name: 'BOGHIS',
      zip: '137639',
      isUrban: false,
    },
    {
      name: 'DACIA',
      zip: '137648',
      isUrban: false,
    },
    {
      name: 'PAULIAN',
      zip: '137657',
      isUrban: false,
    },
    {
      name: 'TRAIAN',
      zip: '137666',
      isUrban: false,
    },
    {
      name: 'DOROLT',
      zip: '137684',
      isUrban: false,
    },
    {
      name: 'ATEA',
      zip: '137693',
      isUrban: false,
    },
    {
      name: 'DARA',
      zip: '137700',
      isUrban: false,
    },
    {
      name: 'PETEA',
      zip: '137719',
      isUrban: false,
    },
    {
      name: 'FOIENI',
      zip: '137737',
      isUrban: false,
    },
    {
      name: 'GHERTA MICA',
      zip: '137755',
      isUrban: false,
    },
    {
      name: 'HALMEU',
      zip: '137773',
      isUrban: false,
    },
    {
      name: 'BABESTI',
      zip: '137782',
      isUrban: false,
    },
    {
      name: 'CIDREAG',
      zip: '137791',
      isUrban: false,
    },
    {
      name: 'DOBOLT',
      zip: '137808',
      isUrban: false,
    },
    {
      name: 'HALMEU VII',
      zip: '137817',
      isUrban: false,
    },
    {
      name: 'MESTEACAN',
      zip: '137826',
      isUrban: false,
    },
    {
      name: 'PORUMBESTI',
      zip: '137835',
      isUrban: false,
    },
    {
      name: 'HODOD',
      zip: '137853',
      isUrban: false,
    },
    {
      name: 'GIURTELECU HODODULUI',
      zip: '137862',
      isUrban: false,
    },
    {
      name: 'LELEI',
      zip: '137871',
      isUrban: false,
    },
    {
      name: 'NADISU HODODULUI',
      zip: '137880',
      isUrban: false,
    },
    {
      name: 'HOMORODU DE MIJLOC',
      zip: '137906',
      isUrban: false,
    },
    {
      name: 'CHILIA',
      zip: '137915',
      isUrban: false,
    },
    {
      name: 'HOMORODU DE JOS',
      zip: '137924',
      isUrban: false,
    },
    {
      name: 'HOMORODU DE SUS',
      zip: '137933',
      isUrban: false,
    },
    {
      name: 'NECOPOI',
      zip: '137942',
      isUrban: false,
    },
    {
      name: 'SOLDUBA',
      zip: '137951',
      isUrban: false,
    },
    {
      name: 'LAZURI',
      zip: '137979',
      isUrban: false,
    },
    {
      name: 'BERCU',
      zip: '137988',
      isUrban: false,
    },
    {
      name: 'NISIPENI',
      zip: '137997',
      isUrban: false,
    },
    {
      name: 'NOROIENI',
      zip: '138002',
      isUrban: false,
    },
    {
      name: 'PELES',
      zip: '138011',
      isUrban: false,
    },
    {
      name: 'PELISOR',
      zip: '138020',
      isUrban: false,
    },
    {
      name: 'LIVADA',
      zip: '138048',
      isUrban: false,
    },
    {
      name: 'ADRIAN',
      zip: '138057',
      isUrban: false,
    },
    {
      name: 'DUMBRAVA',
      zip: '138066',
      isUrban: false,
    },
    {
      name: 'LIVADA MICA',
      zip: '138075',
      isUrban: false,
    },
    {
      name: 'MEDIESU AURIT',
      zip: '138093',
      isUrban: false,
    },
    {
      name: 'BABASESTI',
      zip: '138100',
      isUrban: false,
    },
    {
      name: 'IOJIB',
      zip: '138119',
      isUrban: false,
    },
    {
      name: 'MEDIES RATURI',
      zip: '138128',
      isUrban: false,
    },
    {
      name: 'MEDIES VII',
      zip: '138137',
      isUrban: false,
    },
    {
      name: 'POTAU',
      zip: '138146',
      isUrban: false,
    },
    {
      name: 'ROMANESTI',
      zip: '138155',
      isUrban: false,
    },
    {
      name: 'MICULA',
      zip: '138173',
      isUrban: false,
    },
    {
      name: 'BERCU NOU',
      zip: '138182',
      isUrban: false,
    },
    {
      name: 'MICULA NOUA',
      zip: '138191',
      isUrban: false,
    },
    {
      name: 'MOFTINU MIC',
      zip: '138217',
      isUrban: false,
    },
    {
      name: 'DOMANESTI',
      zip: '138226',
      isUrban: false,
    },
    {
      name: 'GHILVACI',
      zip: '138235',
      isUrban: false,
    },
    {
      name: 'GHIROLT',
      zip: '138244',
      isUrban: false,
    },
    {
      name: 'ISTRAU',
      zip: '138253',
      isUrban: false,
    },
    {
      name: 'MOFTINU MARE',
      zip: '138262',
      isUrban: false,
    },
    {
      name: 'SANMICLAUS',
      zip: '138271',
      isUrban: false,
    },
    {
      name: 'ODOREU',
      zip: '138299',
      isUrban: false,
    },
    {
      name: 'BERINDAN',
      zip: '138306',
      isUrban: false,
    },
    {
      name: 'CUCU',
      zip: '138315',
      isUrban: false,
    },
    {
      name: 'ETENI',
      zip: '138324',
      isUrban: false,
    },
    {
      name: 'MARTINESTI',
      zip: '138333',
      isUrban: false,
    },
    {
      name: 'VANATORESTI',
      zip: '138342',
      isUrban: false,
    },
    {
      name: 'ORASU NOU',
      zip: '138360',
      isUrban: false,
    },
    {
      name: 'ORASU NOU-VII',
      zip: '138379',
      isUrban: false,
    },
    {
      name: 'PRILOG',
      zip: '138388',
      isUrban: false,
    },
    {
      name: 'PRILOG VII',
      zip: '138397',
      isUrban: false,
    },
    {
      name: 'RACSA',
      zip: '138404',
      isUrban: false,
    },
    {
      name: 'RACSA VII',
      zip: '138413',
      isUrban: false,
    },
    {
      name: 'REMETEA OASULUI',
      zip: '138422',
      isUrban: false,
    },
    {
      name: 'PAULESTI',
      zip: '138440',
      isUrban: false,
    },
    {
      name: 'AMATI',
      zip: '138459',
      isUrban: false,
    },
    {
      name: 'AMBUD',
      zip: '138468',
      isUrban: false,
    },
    {
      name: 'HRIP',
      zip: '138477',
      isUrban: false,
    },
    {
      name: 'PETIN',
      zip: '138486',
      isUrban: false,
    },
    {
      name: 'RUSENI',
      zip: '138495',
      isUrban: false,
    },
    {
      name: 'PETRESTI',
      zip: '138510',
      isUrban: false,
    },
    {
      name: 'DANDESTIU MIC',
      zip: '138529',
      isUrban: false,
    },
    {
      name: 'PIR',
      zip: '138547',
      isUrban: false,
    },
    {
      name: 'PIRU NOU',
      zip: '138556',
      isUrban: false,
    },
    {
      name: 'SARVAZEL',
      zip: '138565',
      isUrban: false,
    },
    {
      name: 'PISCOLT',
      zip: '138583',
      isUrban: false,
    },
    {
      name: 'RESIGHEA',
      zip: '138592',
      isUrban: false,
    },
    {
      name: 'SCARISOARA NOUA',
      zip: '138609',
      isUrban: false,
    },
    {
      name: 'POMI',
      zip: '138627',
      isUrban: false,
    },
    {
      name: 'BICAU',
      zip: '138645',
      isUrban: false,
    },
    {
      name: 'BORLESTI',
      zip: '138654',
      isUrban: false,
    },
    {
      name: 'SACASENI',
      zip: '138789',
      isUrban: false,
    },
    {
      name: 'CHEGEA',
      zip: '138798',
      isUrban: false,
    },
    {
      name: 'SANISLAU',
      zip: '138672',
      isUrban: false,
    },
    {
      name: 'BEREA',
      zip: '138681',
      isUrban: false,
    },
    {
      name: 'CIUMESTI',
      zip: '138690',
      isUrban: false,
    },
    {
      name: 'HOREA',
      zip: '138707',
      isUrban: false,
    },
    {
      name: 'MARNA NOUA',
      zip: '138716',
      isUrban: false,
    },
    {
      name: 'VIISOARA',
      zip: '138725',
      isUrban: false,
    },
    {
      name: 'SANTAU',
      zip: '138743',
      isUrban: false,
    },
    {
      name: 'CHEREUSA',
      zip: '138752',
      isUrban: false,
    },
    {
      name: 'SUDURAU',
      zip: '138761',
      isUrban: false,
    },
    {
      name: 'SAUCA',
      zip: '138814',
      isUrban: false,
    },
    {
      name: 'BECHENI',
      zip: '138823',
      isUrban: false,
    },
    {
      name: 'CEAN',
      zip: '138832',
      isUrban: false,
    },
    {
      name: 'CHISAU',
      zip: '138841',
      isUrban: false,
    },
    {
      name: 'SILVAS',
      zip: '138850',
      isUrban: false,
    },
    {
      name: 'SOCOND',
      zip: '138878',
      isUrban: false,
    },
    {
      name: 'CUTA',
      zip: '138887',
      isUrban: false,
    },
    {
      name: 'HODISA',
      zip: '138896',
      isUrban: false,
    },
    {
      name: 'SOCONZEL',
      zip: '138903',
      isUrban: false,
    },
    {
      name: 'STANA',
      zip: '138912',
      isUrban: false,
    },
    {
      name: 'SUPURU DE JOS',
      zip: '138930',
      isUrban: false,
    },
    {
      name: 'DOBRA',
      zip: '138949',
      isUrban: false,
    },
    {
      name: 'GIOROCUTA',
      zip: '138958',
      isUrban: false,
    },
    {
      name: 'HUREZU MARE',
      zip: '138967',
      isUrban: false,
    },
    {
      name: 'RACOVA',
      zip: '138976',
      isUrban: false,
    },
    {
      name: 'SECHERESA',
      zip: '138985',
      isUrban: false,
    },
    {
      name: 'SUPURU DE SUS',
      zip: '138994',
      isUrban: false,
    },
    {
      name: 'TARNA MARE',
      zip: '139018',
      isUrban: false,
    },
    {
      name: 'BOCICAU',
      zip: '139027',
      isUrban: false,
    },
    {
      name: 'VAGAS',
      zip: '139045',
      isUrban: false,
    },
    {
      name: 'VALEA SEACA',
      zip: '139036',
      isUrban: false,
    },
    {
      name: 'TARSOLT',
      zip: '139152',
      isUrban: false,
    },
    {
      name: 'ALICENI',
      zip: '139161',
      isUrban: false,
    },
    {
      name: 'TEREBESTI',
      zip: '139063',
      isUrban: false,
    },
    {
      name: 'ALIZA',
      zip: '139072',
      isUrban: false,
    },
    {
      name: 'GELU',
      zip: '139081',
      isUrban: false,
    },
    {
      name: 'PISCARI',
      zip: '139090',
      isUrban: false,
    },
    {
      name: 'TIREAM',
      zip: '139116',
      isUrban: false,
    },
    {
      name: 'PORTITA',
      zip: '139125',
      isUrban: false,
    },
    {
      name: 'VEZENDIU',
      zip: '139134',
      isUrban: false,
    },
    {
      name: 'TURT',
      zip: '139189',
      isUrban: false,
    },
    {
      name: 'GHERTA MARE',
      zip: '139198',
      isUrban: false,
    },
    {
      name: 'TURT BAI',
      zip: '139205',
      isUrban: false,
    },
    {
      name: 'TURULUNG',
      zip: '139223',
      isUrban: false,
    },
    {
      name: 'DRAGUSENI',
      zip: '139232',
      isUrban: false,
    },
    {
      name: 'TURULUNG VII',
      zip: '139241',
      isUrban: false,
    },
    {
      name: 'URZICENI',
      zip: '139269',
      isUrban: false,
    },
    {
      name: 'URZICENI PADURE',
      zip: '139278',
      isUrban: false,
    },
    {
      name: 'VALEA VINULUI',
      zip: '139296',
      isUrban: false,
    },
    {
      name: 'MARIUS',
      zip: '139303',
      isUrban: false,
    },
    {
      name: 'ROSIORI',
      zip: '139312',
      isUrban: false,
    },
    {
      name: 'SAI',
      zip: '139321',
      isUrban: false,
    },
    {
      name: 'VAMA',
      zip: '139349',
      isUrban: false,
    },
    {
      name: 'VETIS',
      zip: '139367',
      isUrban: false,
    },
    {
      name: 'DECEBAL',
      zip: '139376',
      isUrban: false,
    },
    {
      name: 'OAR',
      zip: '139385',
      isUrban: false,
    },
    {
      name: 'VIILE SATU MARE',
      zip: '139401',
      isUrban: false,
    },
    {
      name: 'CIONCHESTI',
      zip: '139410',
      isUrban: false,
    },
    {
      name: 'ACIUA',
      zip: '138636',
      isUrban: false,
    },
    {
      name: 'MEDISA',
      zip: '139429',
      isUrban: false,
    },
    {
      name: 'TATARESTI',
      zip: '139438',
      isUrban: false,
    },
    {
      name: 'TIREAC',
      zip: '139447',
      isUrban: false,
    }
  ],
  SV: [
    {
      name: 'SUCEAVA',
      zip: '146272',
      isUrban: true,
    },
    {
      name: 'CAMPULUNG MOLDOVENESC',
      zip: '146511',
      isUrban: true,
    },
    {
      name: 'FALTICENI',
      zip: '146548',
      isUrban: true,
    },
    {
      name: 'SOLDANESTI',
      zip: '146557',
      isUrban: true,
    },
    {
      name: 'TARNA MARE',
      zip: '146566',
      isUrban: true,
    },
    {
      name: 'RADAUTI',
      zip: '146637',
      isUrban: true,
    },
    {
      name: 'GURA HUMORULUI',
      zip: '146593',
      isUrban: true,
    },
    {
      name: 'VORONET',
      zip: '146600',
      isUrban: true,
    },
    {
      name: 'SIRET',
      zip: '146664',
      isUrban: true,
    },
    {
      name: 'MANASTIOARA',
      zip: '146673',
      isUrban: true,
    },
    {
      name: 'PADURENI',
      zip: '146682',
      isUrban: true,
    },
    {
      name: 'SOLCA',
      zip: '146717',
      isUrban: true,
    },
    {
      name: 'POIENI-SOLCA',
      zip: '146726',
      isUrban: true,
    },
    {
      name: 'VATRA DORNEI',
      zip: '146753',
      isUrban: true,
    },
    {
      name: 'ARGESTRU',
      zip: '146762',
      isUrban: true,
    },
    {
      name: 'ROSU',
      zip: '146771',
      isUrban: true,
    },
    {
      name: 'TODIRENI',
      zip: '146780',
      isUrban: true,
    },
    {
      name: 'ADANCATA',
      zip: '146806',
      isUrban: false,
    },
    {
      name: 'ARTARI',
      zip: '146815',
      isUrban: false,
    },
    {
      name: 'BERESTI',
      zip: '146824',
      isUrban: false,
    },
    {
      name: 'CALUGARENI',
      zip: '146833',
      isUrban: false,
    },
    {
      name: 'FETESTI',
      zip: '146842',
      isUrban: false,
    },
    {
      name: 'HANTESTI',
      zip: '146851',
      isUrban: false,
    },
    {
      name: 'ARBORE',
      zip: '146879',
      isUrban: false,
    },
    {
      name: 'BODNARENI',
      zip: '146888',
      isUrban: false,
    },
    {
      name: 'CLIT',
      zip: '146897',
      isUrban: false,
    },
    {
      name: 'BAIA',
      zip: '146913',
      isUrban: false,
    },
    {
      name: 'BOGATA',
      zip: '146922',
      isUrban: false,
    },
    {
      name: 'BALCAUTI',
      zip: '147009',
      isUrban: false,
    },
    {
      name: 'GROPENI',
      zip: '147018',
      isUrban: false,
    },
    {
      name: 'NEGOSTINA',
      zip: '147027',
      isUrban: false,
    },
    {
      name: 'BILCA',
      zip: '147045',
      isUrban: false,
    },
    {
      name: 'BOSANCI',
      zip: '147143',
      isUrban: false,
    },
    {
      name: 'CUMPARATURA',
      zip: '147152',
      isUrban: false,
    },
    {
      name: 'BOTOSANA',
      zip: '147170',
      isUrban: false,
    },
    {
      name: 'COMANESTI',
      zip: '147189',
      isUrban: false,
    },
    {
      name: 'HUMORENI',
      zip: '147198',
      isUrban: false,
    },
    {
      name: 'BREAZA',
      zip: '147214',
      isUrban: false,
    },
    {
      name: 'BREAZA DE SUS',
      zip: '147223',
      isUrban: false,
    },
    {
      name: 'PARAU NEGREI',
      zip: '147232',
      isUrban: false,
    },
    {
      name: 'BRODINA',
      zip: '147250',
      isUrban: false,
    },
    {
      name: 'BRODINA DE JOS',
      zip: '147269',
      isUrban: false,
    },
    {
      name: 'CUNUNSCHI',
      zip: '147278',
      isUrban: false,
    },
    {
      name: 'DUBIUSCA',
      zip: '147287',
      isUrban: false,
    },
    {
      name: 'EHRESTE',
      zip: '147296',
      isUrban: false,
    },
    {
      name: 'FALCAU',
      zip: '147303',
      isUrban: false,
    },
    {
      name: 'NOROCU',
      zip: '147312',
      isUrban: false,
    },
    {
      name: 'PALTIN',
      zip: '147321',
      isUrban: false,
    },
    {
      name: 'SADAU',
      zip: '147330',
      isUrban: false,
    },
    {
      name: 'ZALOMESTRA',
      zip: '147349',
      isUrban: false,
    },
    {
      name: 'BROSTENI',
      zip: '147367',
      isUrban: true,
    },
    {
      name: 'COTARGASI',
      zip: '147376',
      isUrban: true,
    },
    {
      name: 'DARMOXA',
      zip: '147385',
      isUrban: true,
    },
    {
      name: 'FRASIN',
      zip: '147394',
      isUrban: true,
    },
    {
      name: 'HALEASA',
      zip: '147401',
      isUrban: true,
    },
    {
      name: 'HOLDA',
      zip: '147410',
      isUrban: true,
    },
    {
      name: 'HOLDITA',
      zip: '147429',
      isUrban: true,
    },
    {
      name: 'LUNGENI',
      zip: '147438',
      isUrban: true,
    },
    {
      name: 'NEAGRA',
      zip: '147447',
      isUrban: true,
    },
    {
      name: 'PIETROASA',
      zip: '147456',
      isUrban: true,
    },
    {
      name: 'BUNESTI',
      zip: '147474',
      isUrban: false,
    },
    {
      name: 'PETIA',
      zip: '147483',
      isUrban: false,
    },
    {
      name: 'PODENI',
      zip: '147492',
      isUrban: false,
    },
    {
      name: 'SES',
      zip: '147508',
      isUrban: false,
    },
    {
      name: 'UNCESTI',
      zip: '147517',
      isUrban: false,
    },
    {
      name: 'PARTESTII DE SUS',
      zip: '147535',
      isUrban: false,
    },
    {
      name: 'CACICA',
      zip: '147544',
      isUrban: false,
    },
    {
      name: 'MAIDAN',
      zip: '147553',
      isUrban: false,
    },
    {
      name: 'RUNCU',
      zip: '147562',
      isUrban: false,
    },
    {
      name: 'SOLONETU NOU',
      zip: '147571',
      isUrban: false,
    },
    {
      name: 'CAJVANA',
      zip: '147642',
      isUrban: true,
    },
    {
      name: 'CODRU',
      zip: '147651',
      isUrban: true,
    },
    {
      name: 'CALAFINDESTI',
      zip: '147599',
      isUrban: false,
    },
    {
      name: 'BOTOSANITA MARE',
      zip: '147606',
      isUrban: false,
    },
    {
      name: 'CALINESTI',
      zip: '147615',
      isUrban: false,
    },
    {
      name: 'SERBAUTI',
      zip: '147624',
      isUrban: false,
    },
    {
      name: 'CARLIBABA',
      zip: '147722',
      isUrban: false,
    },
    {
      name: 'CARLIBABA NOUA',
      zip: '147731',
      isUrban: false,
    },
    {
      name: 'IEDU',
      zip: '147740',
      isUrban: false,
    },
    {
      name: 'SESURI',
      zip: '147759',
      isUrban: false,
    },
    {
      name: 'TIBAU',
      zip: '147768',
      isUrban: false,
    },
    {
      name: 'VALEA STANEI',
      zip: '147777',
      isUrban: false,
    },
    {
      name: 'ILISESTI',
      zip: '147679',
      isUrban: false,
    },
    {
      name: 'BALACEANA',
      zip: '147688',
      isUrban: false,
    },
    {
      name: 'CIPRIAN PORUMBESCU',
      zip: '147704',
      isUrban: false,
    },
    {
      name: 'CORNU LUNCII',
      zip: '147795',
      isUrban: false,
    },
    {
      name: 'BAISESTI',
      zip: '147802',
      isUrban: false,
    },
    {
      name: 'BRAIESTI',
      zip: '147811',
      isUrban: false,
    },
    {
      name: 'DUMBRAVA',
      zip: '147820',
      isUrban: false,
    },
    {
      name: 'PAISENI',
      zip: '147839',
      isUrban: false,
    },
    {
      name: 'SASCA MARE',
      zip: '147848',
      isUrban: false,
    },
    {
      name: 'SASCA MICA',
      zip: '147857',
      isUrban: false,
    },
    {
      name: 'SASCA NOUA',
      zip: '147866',
      isUrban: false,
    },
    {
      name: 'SINCA',
      zip: '147875',
      isUrban: false,
    },
    {
      name: 'CRUCEA',
      zip: '147893',
      isUrban: false,
    },
    {
      name: 'CHIRIL',
      zip: '147900',
      isUrban: false,
    },
    {
      name: 'COJOCI',
      zip: '147919',
      isUrban: false,
    },
    {
      name: 'SATU MARE',
      zip: '147928',
      isUrban: false,
    },
    {
      name: 'MARITEI',
      zip: '147946',
      isUrban: false,
    },
    {
      name: 'CALINESTI',
      zip: '147973',
      isUrban: false,
    },
    {
      name: 'CALINESTI-VASILACHE',
      zip: '147982',
      isUrban: false,
    },
    {
      name: 'DANILA',
      zip: '147955',
      isUrban: false,
    },
    {
      name: 'DARMANESTI',
      zip: '147964',
      isUrban: false,
    },
    {
      name: 'MARITEIA MICA',
      zip: '147991',
      isUrban: false,
    },
    {
      name: 'DOLHASCA',
      zip: '148015',
      isUrban: true,
    },
    {
      name: 'BUDENI',
      zip: '148024',
      isUrban: true,
    },
    {
      name: 'GULIA',
      zip: '148033',
      isUrban: true,
    },
    {
      name: 'POIANA',
      zip: '148042',
      isUrban: true,
    },
    {
      name: 'POIENARI',
      zip: '148051',
      isUrban: true,
    },
    {
      name: 'PROBOTA',
      zip: '148060',
      isUrban: true,
    },
    {
      name: 'SILISTEA NOUA',
      zip: '148079',
      isUrban: true,
    },
    {
      name: 'VALEA POIENEI',
      zip: '148088',
      isUrban: true,
    },
    {
      name: 'DOLHESTII-MARI',
      zip: '148104',
      isUrban: false,
    },
    {
      name: 'DOLHESTII MICI',
      zip: '148113',
      isUrban: false,
    },
    {
      name: 'VALEA BOUREI',
      zip: '148122',
      isUrban: false,
    },
    {
      name: 'DORNA CANDRENILOR',
      zip: '148211',
      isUrban: false,
    },
    {
      name: 'COSNA',
      zip: '148220',
      isUrban: false,
    },
    {
      name: 'DEALU FLORENI',
      zip: '148239',
      isUrban: false,
    },
    {
      name: 'PODU COSNEI',
      zip: '148257',
      isUrban: false,
    },
    {
      name: 'POIANA NEGRII',
      zip: '148248',
      isUrban: false,
    },
    {
      name: 'ROMANESTI',
      zip: '148266',
      isUrban: false,
    },
    {
      name: 'TESNA',
      zip: '148275',
      isUrban: false,
    },
    {
      name: 'VALEA BANCULUI',
      zip: '148284',
      isUrban: false,
    },
    {
      name: 'COZANESTI',
      zip: '148140',
      isUrban: false,
    },
    {
      name: 'DORNA-ARINI',
      zip: '148159',
      isUrban: false,
    },
    {
      name: 'GHEORGHITENI',
      zip: '148168',
      isUrban: false,
    },
    {
      name: 'ORTOAIA',
      zip: '148177',
      isUrban: false,
    },
    {
      name: 'RUSCA',
      zip: '148186',
      isUrban: false,
    },
    {
      name: 'SUNATORI',
      zip: '148195',
      isUrban: false,
    },
    {
      name: 'DORNESTI',
      zip: '148300',
      isUrban: false,
    },
    {
      name: 'IAZ',
      zip: '148319',
      isUrban: false,
    },
    {
      name: 'MAZANAESTI',
      zip: '148337',
      isUrban: false,
    },
    {
      name: 'BERCHISESTI',
      zip: '148346',
      isUrban: false,
    },
    {
      name: 'CORLATA',
      zip: '148355',
      isUrban: false,
    },
    {
      name: 'DRAGOIESTI',
      zip: '148364',
      isUrban: false,
    },
    {
      name: 'LUCACESTI',
      zip: '148373',
      isUrban: false,
    },
    {
      name: 'DRAGUSENI',
      zip: '148391',
      isUrban: false,
    },
    {
      name: 'BROSTENI',
      zip: '148408',
      isUrban: false,
    },
    {
      name: 'GARA LEU',
      zip: '148417',
      isUrban: false,
    },
    {
      name: 'DUMBRAVENI',
      zip: '148435',
      isUrban: false,
    },
    {
      name: 'SALAGENI',
      zip: '148444',
      isUrban: false,
    },
    {
      name: 'FANTANELE',
      zip: '148462',
      isUrban: false,
    },
    {
      name: 'BANESTI',
      zip: '148471',
      isUrban: false,
    },
    {
      name: 'COTU DOBEI',
      zip: '148480',
      isUrban: false,
    },
    {
      name: 'SLOBOZIA',
      zip: '148499',
      isUrban: false,
    },
    {
      name: 'STAMATE',
      zip: '148505',
      isUrban: false,
    },
    {
      name: 'FORASTI',
      zip: '148523',
      isUrban: false,
    },
    {
      name: 'ANTOCENI',
      zip: '148532',
      isUrban: false,
    },
    {
      name: 'BOURA',
      zip: '148541',
      isUrban: false,
    },
    {
      name: 'MANOLEA',
      zip: '148550',
      isUrban: false,
    },
    {
      name: 'ONICENI',
      zip: '148569',
      isUrban: false,
    },
    {
      name: 'ROSIORI',
      zip: '148578',
      isUrban: false,
    },
    {
      name: 'RUSI',
      zip: '148587',
      isUrban: false,
    },
    {
      name: 'TOLESTI',
      zip: '148596',
      isUrban: false,
    },
    {
      name: 'UIDESTI',
      zip: '148603',
      isUrban: false,
    },
    {
      name: 'FRASIN',
      zip: '148621',
      isUrban: true,
    },
    {
      name: 'BUCSOAIA',
      zip: '148630',
      isUrban: true,
    },
    {
      name: 'DOROTEIA',
      zip: '148649',
      isUrban: true,
    },
    {
      name: 'PLUTONITA',
      zip: '148658',
      isUrban: true,
    },
    {
      name: 'FRATAUTII NOI',
      zip: '148676',
      isUrban: false,
    },
    {
      name: 'COSTISA',
      zip: '148685',
      isUrban: false,
    },
    {
      name: 'FRATAUTII VECHI',
      zip: '148701',
      isUrban: false,
    },
    {
      name: 'MANEUTI',
      zip: '148710',
      isUrban: false,
    },
    {
      name: 'FRUMOSU',
      zip: '148738',
      isUrban: false,
    },
    {
      name: 'DEIA',
      zip: '148747',
      isUrban: false,
    },
    {
      name: 'DRAGOSA',
      zip: '148756',
      isUrban: false,
    },
    {
      name: 'FUNDU MOLDOVEI',
      zip: '148774',
      isUrban: false,
    },
    {
      name: 'BOTUS',
      zip: '148792',
      isUrban: false,
    },
    {
      name: 'BOTUSEL',
      zip: '148783',
      isUrban: false,
    },
    {
      name: 'BRANISTEA',
      zip: '148809',
      isUrban: false,
    },
    {
      name: 'COLACU',
      zip: '148818',
      isUrban: false,
    },
    {
      name: 'DELNITA',
      zip: '148827',
      isUrban: false,
    },
    {
      name: 'DELUT',
      zip: '148836',
      isUrban: false,
    },
    {
      name: 'OBCINA',
      zip: '148845',
      isUrban: false,
    },
    {
      name: 'PLAI',
      zip: '148854',
      isUrban: false,
    },
    {
      name: 'SMIDA UNGURENILOR',
      zip: '148863',
      isUrban: false,
    },
    {
      name: 'GALANESTI',
      zip: '148881',
      isUrban: false,
    },
    {
      name: 'HURJUIENI',
      zip: '148890',
      isUrban: false,
    },
    {
      name: 'VOITINEL',
      zip: '148907',
      isUrban: false,
    },
    {
      name: 'GRAMESTI',
      zip: '148925',
      isUrban: false,
    },
    {
      name: 'BALINESTI',
      zip: '148934',
      isUrban: false,
    },
    {
      name: 'BOTOSANITA MICA',
      zip: '148943',
      isUrban: false,
    },
    {
      name: 'RUDESTI',
      zip: '148952',
      isUrban: false,
    },
    {
      name: 'VERBIA',
      zip: '148961',
      isUrban: false,
    },
    {
      name: 'GRANICESTI',
      zip: '148989',
      isUrban: false,
    },
    {
      name: 'DUMBRAVA',
      zip: '148998',
      isUrban: false,
    },
    {
      name: 'GURA SOLCII',
      zip: '149003',
      isUrban: false,
    },
    {
      name: 'IACOBESTI',
      zip: '149012',
      isUrban: false,
    },
    {
      name: 'ROMANESTI',
      zip: '149021',
      isUrban: false,
    },
    {
      name: 'SLOBOZIA SUCEVEI',
      zip: '149030',
      isUrban: false,
    },
    {
      name: 'HORODNIC DE JOS',
      zip: '149110',
      isUrban: false,
    },
    {
      name: 'HORODNIC DE SUS',
      zip: '149129',
      isUrban: false,
    },
    {
      name: 'HORODNICENI',
      zip: '149058',
      isUrban: false,
    },
    {
      name: 'BOTESTI',
      zip: '149067',
      isUrban: false,
    },
    {
      name: 'BRADATEL',
      zip: '149076',
      isUrban: false,
    },
    {
      name: 'MIHAIESTI',
      zip: '149085',
      isUrban: false,
    },
    {
      name: 'ROTOPANESTI',
      zip: '149094',
      isUrban: false,
    },
    {
      name: 'IACOBENI',
      zip: '149147',
      isUrban: false,
    },
    {
      name: 'BOTOS',
      zip: '149156',
      isUrban: false,
    },
    {
      name: 'CIOCANESTI',
      zip: '149165',
      isUrban: false,
    },
    {
      name: 'MESTECANIS',
      zip: '149174',
      isUrban: false,
    },
    {
      name: 'IPOTESTI',
      zip: '146290',
      isUrban: false,
    },
    {
      name: 'LISAURA',
      zip: '146307',
      isUrban: false,
    },
    {
      name: 'TISAUTI',
      zip: '146316',
      isUrban: false,
    },
    {
      name: 'IZVOARELE SUCEVEI',
      zip: '149192',
      isUrban: false,
    },
    {
      name: 'BOBEICA',
      zip: '149209',
      isUrban: false,
    },
    {
      name: 'BRODINA',
      zip: '149218',
      isUrban: false,
    },
    {
      name: 'LITENI',
      zip: '149236',
      isUrban: true,
    },
    {
      name: 'CORNI',
      zip: '149245',
      isUrban: true,
    },
    {
      name: 'ROSCANI',
      zip: '149254',
      isUrban: true,
    },
    {
      name: 'ROTUNDA',
      zip: '149263',
      isUrban: true,
    },
    {
      name: 'SILISTEA',
      zip: '149272',
      isUrban: true,
    },
    {
      name: 'VERCICANI',
      zip: '149281',
      isUrban: true,
    },
    {
      name: 'MALINI',
      zip: '149325',
      isUrban: false,
    },
    {
      name: 'IESLE',
      zip: '149334',
      isUrban: false,
    },
    {
      name: 'PARAIE',
      zip: '149343',
      isUrban: false,
    },
    {
      name: 'POIANA MARULUI',
      zip: '149352',
      isUrban: false,
    },
    {
      name: 'VALENI-STANISOARA',
      zip: '149361',
      isUrban: false,
    },
    {
      name: 'MANASTIREA HUMORULUI',
      zip: '149389',
      isUrban: false,
    },
    {
      name: 'PLESA',
      zip: '149398',
      isUrban: false,
    },
    {
      name: 'POIANA MICULUI',
      zip: '149405',
      isUrban: false,
    },
    {
      name: 'MARGINEA',
      zip: '149307',
      isUrban: false,
    },
    {
      name: 'BADEUTI',
      zip: '146940',
      isUrban: true,
    },
    {
      name: 'GARA',
      zip: '146959',
      isUrban: true,
    },
    {
      name: 'IASLOVAT',
      zip: '146968',
      isUrban: false,
    },
    {
      name: 'LUNCA',
      zip: '146977',
      isUrban: true,
    },
    {
      name: 'MILISAUTI',
      zip: '146986',
      isUrban: true,
    },
    {
      name: 'MITOCU DRAGOMIRNEI',
      zip: '146334',
      isUrban: false,
    },
    {
      name: 'DRAGOMIRNA',
      zip: '146343',
      isUrban: false,
    },
    {
      name: 'LIPOVENI',
      zip: '146352',
      isUrban: false,
    },
    {
      name: 'MITOCASI',
      zip: '146361',
      isUrban: false,
    },
    {
      name: 'MOARA NICA',
      zip: '149423',
      isUrban: false,
    },
    {
      name: 'BULAI',
      zip: '149432',
      isUrban: false,
    },
    {
      name: 'FRUMOASA',
      zip: '149441',
      isUrban: false,
    },
    {
      name: 'GROAPA VLADICHII',
      zip: '149450',
      isUrban: false,
    },
    {
      name: 'LITENI',
      zip: '149469',
      isUrban: false,
    },
    {
      name: 'MOARA CARP',
      zip: '149478',
      isUrban: false,
    },
    {
      name: 'VORNICENII MARI',
      zip: '149487',
      isUrban: false,
    },
    {
      name: 'VORNICENII MICI',
      zip: '149496',
      isUrban: false,
    },
    {
      name: 'MOLDOVA-SULITA',
      zip: '149511',
      isUrban: false,
    },
    {
      name: 'BENIA',
      zip: '149520',
      isUrban: false,
    },
    {
      name: 'MOLDOVITA',
      zip: '149548',
      isUrban: false,
    },
    {
      name: 'ARGEL',
      zip: '149557',
      isUrban: false,
    },
    {
      name: 'DEMACUSA',
      zip: '149566',
      isUrban: false,
    },
    {
      name: 'RASCA',
      zip: '149575',
      isUrban: false,
    },
    {
      name: 'BAINET',
      zip: '149593',
      isUrban: false,
    },
    {
      name: 'BANCESTI',
      zip: '149600',
      isUrban: false,
    },
    {
      name: 'CLIMAUTI',
      zip: '149619',
      isUrban: false,
    },
    {
      name: 'MUSENITA',
      zip: '149628',
      isUrban: false,
    },
    {
      name: 'VASCAUTI',
      zip: '149637',
      isUrban: false,
    },
    {
      name: 'VICSANI',
      zip: '149646',
      isUrban: false,
    },
    {
      name: 'OSTRA',
      zip: '149664',
      isUrban: false,
    },
    {
      name: 'TARNICIOARA',
      zip: '149673',
      isUrban: false,
    },
    {
      name: 'PALTINOASA',
      zip: '149762',
      isUrban: false,
    },
    {
      name: 'CAPU CODRULUI',
      zip: '149771',
      isUrban: false,
    },
    {
      name: 'PANACI',
      zip: '149691',
      isUrban: false,
    },
    {
      name: 'CATRINARI',
      zip: '149708',
      isUrban: false,
    },
    {
      name: 'COVERCA',
      zip: '149717',
      isUrban: false,
    },
    {
      name: 'DRAGOIASA',
      zip: '149726',
      isUrban: false,
    },
    {
      name: 'GLODU',
      zip: '149735',
      isUrban: false,
    },
    {
      name: 'PALTINIS',
      zip: '149744',
      isUrban: false,
    },
    {
      name: 'PARTESTII DE JOS',
      zip: '149799',
      isUrban: false,
    },
    {
      name: 'DELENI',
      zip: '149806',
      isUrban: false,
    },
    {
      name: 'VARFU DEALULUI',
      zip: '149824',
      isUrban: false,
    },
    {
      name: 'VARVATA',
      zip: '149815',
      isUrban: false,
    },
    {
      name: 'PATRAUTI',
      zip: '149842',
      isUrban: false,
    },
    {
      name: 'POIANA STAMPEI',
      zip: '149860',
      isUrban: false,
    },
    {
      name: 'CASOI',
      zip: '149879',
      isUrban: false,
    },
    {
      name: 'DORNISOARA',
      zip: '149888',
      isUrban: false,
    },
    {
      name: 'PILUGANI',
      zip: '149897',
      isUrban: false,
    },
    {
      name: 'PRALENI',
      zip: '149904',
      isUrban: false,
    },
    {
      name: 'TATARU',
      zip: '149913',
      isUrban: false,
    },
    {
      name: 'TESNA',
      zip: '149922',
      isUrban: false,
    },
    {
      name: 'POJORATA',
      zip: '149940',
      isUrban: false,
    },
    {
      name: 'VALEA PUTNEI',
      zip: '149959',
      isUrban: false,
    },
    {
      name: 'PREUTESTI',
      zip: '149977',
      isUrban: false,
    },
    {
      name: 'ARGHIRA',
      zip: '149986',
      isUrban: false,
    },
    {
      name: 'BAHNA ARIN',
      zip: '150007',
      isUrban: false,
    },
    {
      name: 'BASARABI',
      zip: '149995',
      isUrban: false,
    },
    {
      name: 'HARTOP',
      zip: '150016',
      isUrban: false,
    },
    {
      name: 'HUSI',
      zip: '150025',
      isUrban: false,
    },
    {
      name: 'DUMBRAVENI',
      zip: '150141',
      isUrban: false,
    },
    {
      name: 'JAHALIA',
      zip: '150150',
      isUrban: false,
    },
    {
      name: 'SLATIOARA',
      zip: '150169',
      isUrban: false,
    },
    {
      name: 'SADOVA',
      zip: '150187',
      isUrban: false,
    },
    {
      name: 'SALCEA',
      zip: '146389',
      isUrban: true,
    },
    {
      name: 'MERENI',
      zip: '146398',
      isUrban: true,
    },
    {
      name: 'PLOPENI',
      zip: '146405',
      isUrban: true,
    },
    {
      name: 'PRELIPCA',
      zip: '146414',
      isUrban: true,
    },
    {
      name: 'VARATEC',
      zip: '146423',
      isUrban: true,
    },
    {
      name: 'NEAGRA SARULUI',
      zip: '150454',
      isUrban: false,
    },
    {
      name: 'GURA HAITII',
      zip: '150463',
      isUrban: false,
    },
    {
      name: 'PLAIU SARULUI',
      zip: '150472',
      isUrban: false,
    },
    {
      name: 'SARISOR',
      zip: '150481',
      isUrban: false,
    },
    {
      name: 'SARISORU MARE',
      zip: '150490',
      isUrban: false,
    },
    {
      name: 'SARU BUCOVINEI',
      zip: '150506',
      isUrban: false,
    },
    {
      name: 'SARUL DORNEI',
      zip: '150515',
      isUrban: false,
    },
    {
      name: 'SATU MARE',
      zip: '150203',
      isUrban: false,
    },
    {
      name: 'TIBENI',
      zip: '150212',
      isUrban: false,
    },
    {
      name: 'SCHEIA',
      zip: '146441',
      isUrban: false,
    },
    {
      name: 'FLORINTA',
      zip: '146450',
      isUrban: false,
    },
    {
      name: 'MIHOVENI',
      zip: '146469',
      isUrban: false,
    },
    {
      name: 'SFANTU ILIE',
      zip: '146478',
      isUrban: false,
    },
    {
      name: 'TREI MOVILE',
      zip: '146487',
      isUrban: false,
    },
    {
      name: 'SIMINICEA',
      zip: '150230',
      isUrban: false,
    },
    {
      name: 'GRIGORESTI',
      zip: '150249',
      isUrban: false,
    },
    {
      name: 'SLATINA',
      zip: '150267',
      isUrban: false,
    },
    {
      name: 'GAINESTI',
      zip: '150276',
      isUrban: false,
    },
    {
      name: 'HERLA',
      zip: '150285',
      isUrban: false,
    },
    {
      name: 'STRAJA',
      zip: '150301',
      isUrban: false,
    },
    {
      name: 'STROIESTI',
      zip: '150329',
      isUrban: false,
    },
    {
      name: 'VALCELELE',
      zip: '150338',
      isUrban: false,
    },
    {
      name: 'ZAHARESTI',
      zip: '150347',
      isUrban: false,
    },
    {
      name: 'STULPICANI',
      zip: '150365',
      isUrban: false,
    },
    {
      name: 'GEMENEA',
      zip: '150374',
      isUrban: false,
    },
    {
      name: 'NEGRILEASA',
      zip: '150383',
      isUrban: false,
    },
    {
      name: 'SLATIOARA',
      zip: '150392',
      isUrban: false,
    },
    {
      name: 'VADU NEGRILESEI',
      zip: '150409',
      isUrban: false,
    },
    {
      name: 'SUCEVITA',
      zip: '150427',
      isUrban: false,
    },
    {
      name: 'VOIEVODEASA',
      zip: '150436',
      isUrban: false,
    },
    {
      name: 'TODIRESTI',
      zip: '150533',
      isUrban: false,
    },
    {
      name: 'COSTANA',
      zip: '150542',
      isUrban: false,
    },
    {
      name: 'PARHAUTI',
      zip: '150551',
      isUrban: false,
    },
    {
      name: 'SARGHIESTI',
      zip: '150560',
      isUrban: false,
    },
    {
      name: 'SOLONET',
      zip: '150579',
      isUrban: false,
    },
    {
      name: 'UDESTI',
      zip: '150597',
      isUrban: false,
    },
    {
      name: 'CHILISENI',
      zip: '150604',
      isUrban: false,
    },
    {
      name: 'LUNCUSOARA',
      zip: '150613',
      isUrban: false,
    },
    {
      name: 'MANASTIOARA',
      zip: '150622',
      isUrban: false,
    },
    {
      name: 'PLAVALARI',
      zip: '150631',
      isUrban: false,
    },
    {
      name: 'POIENI-SUCEAVA',
      zip: '150640',
      isUrban: false,
    },
    {
      name: 'RACOVA',
      zip: '150659',
      isUrban: false,
    },
    {
      name: 'REUSENI',
      zip: '150668',
      isUrban: false,
    },
    {
      name: 'RUSII-MANASTIOARA',
      zip: '150677',
      isUrban: false,
    },
    {
      name: 'SECURICENI',
      zip: '150686',
      isUrban: false,
    },
    {
      name: 'STIRBAT',
      zip: '150695',
      isUrban: false,
    },
    {
      name: 'ULMA',
      zip: '150711',
      isUrban: false,
    },
    {
      name: 'COSTILEVA',
      zip: '150720',
      isUrban: false,
    },
    {
      name: 'LUPCINA',
      zip: '150739',
      isUrban: false,
    },
    {
      name: 'MAGURA',
      zip: '150748',
      isUrban: false,
    },
    {
      name: 'NISIPITU',
      zip: '150757',
      isUrban: false,
    },
    {
      name: 'VADU MOLDOVEI',
      zip: '150775',
      isUrban: false,
    },
    {
      name: 'CAMARZANI',
      zip: '150784',
      isUrban: false,
    },
    {
      name: 'CIUMULESTI',
      zip: '150793',
      isUrban: false,
    },
    {
      name: 'COTU BAII',
      zip: '150800',
      isUrban: false,
    },
    {
      name: 'DUMBRAVITA',
      zip: '150819',
      isUrban: false,
    },
    {
      name: 'FANTANA MARE',
      zip: '150828',
      isUrban: false,
    },
    {
      name: 'IONEASA',
      zip: '150837',
      isUrban: false,
    },
    {
      name: 'MESTECENI',
      zip: '150846',
      isUrban: false,
    },
    {
      name: 'MOVILENI',
      zip: '150855',
      isUrban: false,
    },
    {
      name: 'NIGOTESTI',
      zip: '150864',
      isUrban: false,
    },
    {
      name: 'PRAXIA',
      zip: '150873',
      isUrban: false,
    },
    {
      name: 'SPATARESTI',
      zip: '150882',
      isUrban: false,
    },
    {
      name: 'VALEA MOLDOVEI',
      zip: '150908',
      isUrban: false,
    },
    {
      name: 'CAPU CAMPULUI',
      zip: '150917',
      isUrban: false,
    },
    {
      name: 'MIRONU',
      zip: '150926',
      isUrban: false,
    },
    {
      name: 'VAMA',
      zip: '150944',
      isUrban: false,
    },
    {
      name: 'MOLID',
      zip: '150953',
      isUrban: false,
    },
    {
      name: 'PRISACA DORNEI',
      zip: '150962',
      isUrban: false,
    },
    {
      name: 'STRAMTURA',
      zip: '150971',
      isUrban: false,
    },
    {
      name: 'VATRA MOLDOVITEI',
      zip: '150999',
      isUrban: false,
    },
    {
      name: 'CIUMARNA',
      zip: '151004',
      isUrban: false,
    },
    {
      name: 'PALTINU',
      zip: '151013',
      isUrban: false,
    },
    {
      name: 'VERESTI',
      zip: '151031',
      isUrban: false,
    },
    {
      name: 'BURSUCENI',
      zip: '151040',
      isUrban: false,
    },
    {
      name: 'COROCAIESTI',
      zip: '151059',
      isUrban: false,
    },
    {
      name: 'HANCEA',
      zip: '151068',
      isUrban: false,
    },
    {
      name: 'VICOVU DE JOS',
      zip: '151086',
      isUrban: false,
    },
    {
      name: 'VICOVU DE SUS',
      zip: '151102',
      isUrban: true,
    },
    {
      name: 'BIVOLARIA',
      zip: '151111',
      isUrban: true,
    },
    {
      name: 'VOLOVAT',
      zip: '151139',
      isUrban: false,
    },
    {
      name: 'BURLA',
      zip: '151148',
      isUrban: false,
    },
    {
      name: 'PLESESTI',
      zip: '151166',
      isUrban: false,
    },
    {
      name: 'GIURGESTI',
      zip: '151175',
      isUrban: false,
    },
    {
      name: 'HREATCA',
      zip: '151184',
      isUrban: false,
    },
    {
      name: 'JACOTA',
      zip: '151193',
      isUrban: false,
    },
    {
      name: 'MERESTI',
      zip: '151200',
      isUrban: false,
    },
    {
      name: 'OSOI',
      zip: '151219',
      isUrban: false,
    },
    {
      name: 'VALEA GLODULUI',
      zip: '151228',
      isUrban: false,
    },
    {
      name: 'VULTURESTI',
      zip: '151237',
      isUrban: false,
    },
    {
      name: 'ZAMOSTEA',
      zip: '151255',
      isUrban: false,
    },
    {
      name: 'BADRAGI',
      zip: '151264',
      isUrban: false,
    },
    {
      name: 'CIOMARTAN',
      zip: '151273',
      isUrban: false,
    },
    {
      name: 'COJOCARENI',
      zip: '151282',
      isUrban: false,
    },
    {
      name: 'CORPACI',
      zip: '151291',
      isUrban: false,
    },
    {
      name: 'NICANI',
      zip: '151317',
      isUrban: false,
    },
    {
      name: 'RAUTENI',
      zip: '151326',
      isUrban: false,
    },
    {
      name: 'TAUTESTI',
      zip: '151335',
      isUrban: false,
    },
    {
      name: 'ZVORISTEA',
      zip: '151353',
      isUrban: false,
    },
    {
      name: 'BUDA',
      zip: '151362',
      isUrban: false,
    },
    {
      name: 'DEALU',
      zip: '151371',
      isUrban: false,
    },
    {
      name: 'POIANA',
      zip: '151380',
      isUrban: false,
    },
    {
      name: 'SERBANESTI',
      zip: '151399',
      isUrban: false,
    },
    {
      name: 'SLOBOZIA',
      zip: '151406',
      isUrban: false,
    },
    {
      name: 'STANCA',
      zip: '151415',
      isUrban: false,
    },
    {
      name: 'STANCUTA',
      zip: '151424',
      isUrban: false,
    },
    {
      name: 'BOGDANESTI',
      zip: '147063',
      isUrban: false,
    },
    {
      name: 'BOROAIA',
      zip: '147081',
      isUrban: false,
    },
    {
      name: 'BARASTI',
      zip: '147090',
      isUrban: false,
    },
    {
      name: 'GIULESTI',
      zip: '147107',
      isUrban: false,
    },
    {
      name: 'MOISA',
      zip: '147116',
      isUrban: false,
    },
    {
      name: 'SACUTA',
      zip: '147125',
      isUrban: false,
    },
    {
      name: 'BRASCA',
      zip: '147697',
      isUrban: false,
    },
    {
      name: 'LEUCUSESTI',
      zip: '150034',
      isUrban: false,
    },
    {
      name: 'PUTNA',
      zip: '150052',
      isUrban: false,
    },
    {
      name: 'GURA PUTNEI',
      zip: '150061',
      isUrban: false,
    },
    {
      name: 'RADASENI',
      zip: '150089',
      isUrban: false,
    },
    {
      name: 'LAMASENI',
      zip: '150098',
      isUrban: false,
    },
    {
      name: 'POCOLENI',
      zip: '150105',
      isUrban: false,
    },
    {
      name: 'RASCA',
      zip: '150123',
      isUrban: false,
    },
    {
      name: 'BUDA',
      zip: '150132',
      isUrban: false,
    },
    {
      name: 'LUNCA',
      zip: '151308',
      isUrban: false,
    }
  ],
  TR: [
    {
      name: 'ALEXANDRIA',
      zip: '151807',
      isUrban: true,
    },
    {
      name: 'ROSIORI DE VEDE',
      zip: '151889',
      isUrban: true,
    },
    {
      name: 'TURNU MAGURELE',
      zip: '151692',
      isUrban: true,
    },
    {
      name: 'VIDELE',
      zip: '151914',
      isUrban: true,
    },
    {
      name: 'COSOAIA',
      zip: '151923',
      isUrban: true,
    },
    {
      name: 'ZIMNICEA',
      zip: '151987',
      isUrban: true,
    },
    {
      name: 'BABAITA',
      zip: '152047',
      isUrban: false,
    },
    {
      name: 'CLANITA',
      zip: '152056',
      isUrban: false,
    },
    {
      name: 'FRASINET',
      zip: '152065',
      isUrban: false,
    },
    {
      name: 'MERISANI',
      zip: '152074',
      isUrban: false,
    },
    {
      name: 'BALACI',
      zip: '152001',
      isUrban: false,
    },
    {
      name: 'BURDENI',
      zip: '152010',
      isUrban: false,
    },
    {
      name: 'TECUCI',
      zip: '152029',
      isUrban: false,
    },
    {
      name: 'BLEJESTI',
      zip: '152092',
      isUrban: false,
    },
    {
      name: 'BACIU',
      zip: '152109',
      isUrban: false,
    },
    {
      name: 'SERICU',
      zip: '152118',
      isUrban: false,
    },
    {
      name: 'BOGDANA',
      zip: '152136',
      isUrban: false,
    },
    {
      name: 'BROSTEANCA',
      zip: '152145',
      isUrban: false,
    },
    {
      name: 'ULMENI',
      zip: '152154',
      isUrban: false,
    },
    {
      name: 'URLUIU',
      zip: '152163',
      isUrban: false,
    },
    {
      name: 'BOTOROAGA',
      zip: '152181',
      isUrban: false,
    },
    {
      name: 'CALUGARU',
      zip: '152190',
      isUrban: false,
    },
    {
      name: 'TARNAVA',
      zip: '152207',
      isUrban: false,
    },
    {
      name: 'TUNARI',
      zip: '152216',
      isUrban: false,
    },
    {
      name: 'VALEA CIRESULUI',
      zip: '152225',
      isUrban: false,
    },
    {
      name: 'BRAGADIRU',
      zip: '152243',
      isUrban: false,
    },
    {
      name: 'BRANCENI',
      zip: '152261',
      isUrban: false,
    },
    {
      name: 'BUJORENI',
      zip: '152289',
      isUrban: false,
    },
    {
      name: 'DARVAS',
      zip: '152298',
      isUrban: false,
    },
    {
      name: 'PRUNARU',
      zip: '152305',
      isUrban: false,
    },
    {
      name: 'BUJORU',
      zip: '152323',
      isUrban: false,
    },
    {
      name: 'BUZESCU',
      zip: '152341',
      isUrban: false,
    },
    {
      name: 'CALINESTI',
      zip: '152369',
      isUrban: false,
    },
    {
      name: 'ANTONESTI',
      zip: '152378',
      isUrban: false,
    },
    {
      name: 'COPACEANCA',
      zip: '152387',
      isUrban: false,
    },
    {
      name: 'LICURICIU',
      zip: '152396',
      isUrban: false,
    },
    {
      name: 'MARITA',
      zip: '152403',
      isUrban: false,
    },
    {
      name: 'CALMATUIU',
      zip: '152421',
      isUrban: false,
    },
    {
      name: 'BUJORU',
      zip: '152430',
      isUrban: false,
    },
    {
      name: 'CARAVANETI',
      zip: '152449',
      isUrban: false,
    },
    {
      name: 'NICOLAE BALCESCU',
      zip: '152458',
      isUrban: false,
    },
    {
      name: 'CALMATUIU DE SUS',
      zip: '152476',
      isUrban: false,
    },
    {
      name: 'BACALESTI',
      zip: '152485',
      isUrban: false,
    },
    {
      name: 'IONASCU',
      zip: '152494',
      isUrban: false,
    },
    {
      name: 'CERVENIA',
      zip: '152519',
      isUrban: false,
    },
    {
      name: 'CIOLANESTII DIN DEAL',
      zip: '152537',
      isUrban: false,
    },
    {
      name: 'BALDOVINESTI',
      zip: '152546',
      isUrban: false,
    },
    {
      name: 'CIOLANESTII DIN VALE',
      zip: '152555',
      isUrban: false,
    },
    {
      name: 'CIUPERCENI',
      zip: '151718',
      isUrban: false,
    },
    {
      name: 'POIANA',
      zip: '151727',
      isUrban: false,
    },
    {
      name: 'CONTESTI',
      zip: '152573',
      isUrban: false,
    },
    {
      name: 'COSMESTI',
      zip: '152591',
      isUrban: false,
    },
    {
      name: 'CIUPERCENI',
      zip: '152608',
      isUrban: false,
    },
    {
      name: 'CRANGENI',
      zip: '152626',
      isUrban: false,
    },
    {
      name: 'BALTA SARATA',
      zip: '152635',
      isUrban: false,
    },
    {
      name: 'DOROBANTU',
      zip: '152644',
      isUrban: false,
    },
    {
      name: 'STEJARU',
      zip: '152653',
      isUrban: false,
    },
    {
      name: 'CRANGU',
      zip: '152671',
      isUrban: false,
    },
    {
      name: 'DRACEA',
      zip: '152680',
      isUrban: false,
    },
    {
      name: 'FLORICA',
      zip: '152699',
      isUrban: false,
    },
    {
      name: 'SECARA',
      zip: '152706',
      isUrban: false,
    },
    {
      name: 'ZLATA',
      zip: '152715',
      isUrban: false,
    },
    {
      name: 'CREVENICU',
      zip: '151941',
      isUrban: false,
    },
    {
      name: 'RADULESTI',
      zip: '151950',
      isUrban: false,
    },
    {
      name: 'DIDESTI',
      zip: '152733',
      isUrban: false,
    },
    {
      name: 'INSURATEI',
      zip: '152742',
      isUrban: false,
    },
    {
      name: 'SATU NOU',
      zip: '152751',
      isUrban: false,
    },
    {
      name: 'DOBROTESTI',
      zip: '152779',
      isUrban: false,
    },
    {
      name: 'MERISANI',
      zip: '152788',
      isUrban: false,
    },
    {
      name: 'DRACSENEI',
      zip: '152804',
      isUrban: false,
    },
    {
      name: 'BEUCA',
      zip: '152813',
      isUrban: false,
    },
    {
      name: 'DRACSANI',
      zip: '152822',
      isUrban: false,
    },
    {
      name: 'ODOBEASCA',
      zip: '152831',
      isUrban: false,
    },
    {
      name: 'PLOPI',
      zip: '152840',
      isUrban: false,
    },
    {
      name: 'SATUL VECHI',
      zip: '152859',
      isUrban: false,
    },
    {
      name: 'DRAGANESTI DE VEDE',
      zip: '152877',
      isUrban: false,
    },
    {
      name: 'MAGURA CU LILIAC',
      zip: '152886',
      isUrban: false,
    },
    {
      name: 'VACARESTI',
      zip: '152895',
      isUrban: false,
    },
    {
      name: 'DRAGANESTI-VLASCA',
      zip: '152911',
      isUrban: false,
    },
    {
      name: 'COMOARA',
      zip: '152920',
      isUrban: false,
    },
    {
      name: 'VACENI',
      zip: '152939',
      isUrban: false,
    },
    {
      name: 'FRUMOASA',
      zip: '153008',
      isUrban: false,
    },
    {
      name: 'PAULEASCA',
      zip: '153017',
      isUrban: false,
    },
    {
      name: 'FURCULESTI',
      zip: '152957',
      isUrban: false,
    },
    {
      name: 'MOSTENI',
      zip: '152966',
      isUrban: false,
    },
    {
      name: 'SPATAREI',
      zip: '152975',
      isUrban: false,
    },
    {
      name: 'VOIEVODA',
      zip: '152984',
      isUrban: false,
    },
    {
      name: 'GALATENI',
      zip: '153035',
      isUrban: false,
    },
    {
      name: 'BASCOVENI',
      zip: '153044',
      isUrban: false,
    },
    {
      name: 'GRADISTEANCA',
      zip: '153053',
      isUrban: false,
    },
    {
      name: 'GRATIA',
      zip: '153071',
      isUrban: false,
    },
    {
      name: 'CIURARI-DEAL',
      zip: '153080',
      isUrban: false,
    },
    {
      name: 'DRAGHINESTI',
      zip: '153099',
      isUrban: false,
    },
    {
      name: 'ISLAZ',
      zip: '151745',
      isUrban: false,
    },
    {
      name: 'MOLDOVENI',
      zip: '151754',
      isUrban: false,
    },
    {
      name: 'IZVOARELE',
      zip: '153115',
      isUrban: false,
    },
    {
      name: 'LISA',
      zip: '153133',
      isUrban: false,
    },
    {
      name: 'VANATORI',
      zip: '153142',
      isUrban: false,
    },
    {
      name: 'LITA',
      zip: '151772',
      isUrban: false,
    },
    {
      name: 'LUNCA',
      zip: '153160',
      isUrban: false,
    },
    {
      name: 'PLEASOV',
      zip: '153179',
      isUrban: false,
    },
    {
      name: 'PRUNDU',
      zip: '153188',
      isUrban: false,
    },
    {
      name: 'SAELELE',
      zip: '153197',
      isUrban: false,
    },
    {
      name: 'MAGURA',
      zip: '153259',
      isUrban: false,
    },
    {
      name: 'GURUIENI',
      zip: '153268',
      isUrban: false,
    },
    {
      name: 'MALDAENI',
      zip: '153286',
      isUrban: false,
    },
    {
      name: 'MARZANESTI',
      zip: '153348',
      isUrban: false,
    },
    {
      name: 'CERNETU',
      zip: '153357',
      isUrban: false,
    },
    {
      name: 'TELEORMANU',
      zip: '153366',
      isUrban: false,
    },
    {
      name: 'VALEA PARULUI',
      zip: '153375',
      isUrban: false,
    },
    {
      name: 'MAVRODIN',
      zip: '153213',
      isUrban: false,
    },
    {
      name: 'NENCIULESTI',
      zip: '153222',
      isUrban: false,
    },
    {
      name: 'PARU ROTUND',
      zip: '153231',
      isUrban: false,
    },
    {
      name: 'MERENII DE JOS',
      zip: '153302',
      isUrban: false,
    },
    {
      name: 'MERENII DE SUS',
      zip: '153311',
      isUrban: false,
    },
    {
      name: 'STEFENI',
      zip: '153320',
      isUrban: false,
    },
    {
      name: 'MOSTENI',
      zip: '153393',
      isUrban: false,
    },
    {
      name: 'NANOV',
      zip: '151825',
      isUrban: false,
    },
    {
      name: 'NASTURELU',
      zip: '153419',
      isUrban: false,
    },
    {
      name: 'ZIMNICELE',
      zip: '153428',
      isUrban: false,
    },
    {
      name: 'NECSESTI',
      zip: '153446',
      isUrban: false,
    },
    {
      name: 'BELCIUG',
      zip: '153455',
      isUrban: false,
    },
    {
      name: 'GARDESTI',
      zip: '153464',
      isUrban: false,
    },
    {
      name: 'OLTENI',
      zip: '153482',
      isUrban: false,
    },
    {
      name: 'PERII BROSTENI',
      zip: '153491',
      isUrban: false,
    },
    {
      name: 'ORBEASCA DE JOS',
      zip: '153516',
      isUrban: false,
    },
    {
      name: 'LACENI',
      zip: '153525',
      isUrban: false,
    },
    {
      name: 'ORBEASCA DE SUS',
      zip: '153534',
      isUrban: false,
    },
    {
      name: 'PERETU',
      zip: '153552',
      isUrban: false,
    },
    {
      name: 'PIATRA',
      zip: '153570',
      isUrban: false,
    },
    {
      name: 'PIETROSANI',
      zip: '153598',
      isUrban: false,
    },
    {
      name: 'PLOPII-SLAVITESTI',
      zip: '153632',
      isUrban: false,
    },
    {
      name: 'TAVARLAU',
      zip: '153758',
      isUrban: false,
    },
    {
      name: 'VATASI',
      zip: '153767',
      isUrban: false,
    },
    {
      name: 'POROSCHIA',
      zip: '151843',
      isUrban: false,
    },
    {
      name: 'CALOMFIRESTI',
      zip: '151852',
      isUrban: false,
    },
    {
      name: 'PUTINEIU',
      zip: '153838',
      isUrban: false,
    },
    {
      name: 'BADULEASA',
      zip: '153847',
      isUrban: false,
    },
    {
      name: 'CARLOMANU',
      zip: '153856',
      isUrban: false,
    },
    {
      name: 'RADOIESTI-VALE',
      zip: '153874',
      isUrban: false,
    },
    {
      name: 'CETATEA',
      zip: '153883',
      isUrban: false,
    },
    {
      name: 'RADOIESTI-DEAL',
      zip: '153892',
      isUrban: false,
    },
    {
      name: 'RASMIRESTI',
      zip: '153918',
      isUrban: false,
    },
    {
      name: 'LUDANEASCA',
      zip: '153927',
      isUrban: false,
    },
    {
      name: 'SACENI',
      zip: '153981',
      isUrban: false,
    },
    {
      name: 'BUTCULESTI',
      zip: '153990',
      isUrban: false,
    },
    {
      name: 'CIURARI',
      zip: '154004',
      isUrban: false,
    },
    {
      name: 'SALCIA',
      zip: '153945',
      isUrban: false,
    },
    {
      name: 'BANEASA',
      zip: '153954',
      isUrban: false,
    },
    {
      name: 'TUDOR VLADIMIRESCU',
      zip: '153963',
      isUrban: false,
    },
    {
      name: 'SARBENII DE JOS',
      zip: '154317',
      isUrban: false,
    },
    {
      name: 'SARBENI',
      zip: '154326',
      isUrban: false,
    },
    {
      name: 'UDENI',
      zip: '154335',
      isUrban: false,
    },
    {
      name: 'SCRIOASTEA',
      zip: '154022',
      isUrban: false,
    },
    {
      name: 'BREBINA',
      zip: '154031',
      isUrban: false,
    },
    {
      name: 'CUCUETI',
      zip: '154040',
      isUrban: false,
    },
    {
      name: 'VIILE',
      zip: '154059',
      isUrban: false,
    },
    {
      name: 'SCURTU MARE',
      zip: '154077',
      isUrban: false,
    },
    {
      name: 'ALBENI',
      zip: '154086',
      isUrban: false,
    },
    {
      name: 'DRACESTI',
      zip: '154095',
      isUrban: false,
    },
    {
      name: 'NEGRILESTI',
      zip: '154102',
      isUrban: false,
    },
    {
      name: 'SCURTU-SLAVESTI',
      zip: '154111',
      isUrban: false,
    },
    {
      name: 'VALEA POSTEI',
      zip: '154120',
      isUrban: false,
    },
    {
      name: 'SEACA',
      zip: '154148',
      isUrban: false,
    },
    {
      name: 'NAVODARI',
      zip: '154157',
      isUrban: false,
    },
    {
      name: 'SEGARCEA-VALE',
      zip: '154193',
      isUrban: false,
    },
    {
      name: 'OLTEANCA',
      zip: '154200',
      isUrban: false,
    },
    {
      name: 'SEGARCEA-DEAL',
      zip: '154219',
      isUrban: false,
    },
    {
      name: 'SFINTESTI',
      zip: '154175',
      isUrban: false,
    },
    {
      name: 'SILISTEA',
      zip: '154237',
      isUrban: false,
    },
    {
      name: 'BUTESTI',
      zip: '154246',
      isUrban: false,
    },
    {
      name: 'PURANI',
      zip: '154255',
      isUrban: false,
    },
    {
      name: 'PURANII DE SUS',
      zip: '154264',
      isUrban: false,
    },
    {
      name: 'SILISTEA MICA',
      zip: '154273',
      isUrban: false,
    },
    {
      name: 'SILISTEA-GUMESTI',
      zip: '154291',
      isUrban: false,
    },
    {
      name: 'SLOBOZIA MANDRA',
      zip: '154353',
      isUrban: false,
    },
    {
      name: 'UDA-CLOCOCIOV',
      zip: '154362',
      isUrban: false,
    },
    {
      name: 'UDA-PACIUREA',
      zip: '154371',
      isUrban: false,
    },
    {
      name: 'SMARDIOASA',
      zip: '154399',
      isUrban: false,
    },
    {
      name: 'SOIMU',
      zip: '154406',
      isUrban: false,
    },
    {
      name: 'STEJARU',
      zip: '154424',
      isUrban: false,
    },
    {
      name: 'BRATCOVU',
      zip: '154433',
      isUrban: false,
    },
    {
      name: 'GRESIA',
      zip: '154442',
      isUrban: false,
    },
    {
      name: 'SOCETU',
      zip: '154451',
      isUrban: false,
    },
    {
      name: 'STOROBANEASA',
      zip: '154503',
      isUrban: false,
    },
    {
      name: 'BEIU',
      zip: '154512',
      isUrban: false,
    },
    {
      name: 'SUHAIA',
      zip: '154479',
      isUrban: false,
    },
    {
      name: 'FANTANELE',
      zip: '154488',
      isUrban: false,
    },
    {
      name: 'TALPA-OGRAZILE',
      zip: '154530',
      isUrban: false,
    },
    {
      name: 'LINIA COSTII',
      zip: '154549',
      isUrban: false,
    },
    {
      name: 'ROTARESTI',
      zip: '154558',
      isUrban: false,
    },
    {
      name: 'TALPA POSTEI',
      zip: '154576',
      isUrban: false,
    },
    {
      name: 'TALPA-BASCOVENI',
      zip: '154567',
      isUrban: false,
    },
    {
      name: 'TATARASTII DE JOS',
      zip: '154594',
      isUrban: false,
    },
    {
      name: 'LADA',
      zip: '154601',
      isUrban: false,
    },
    {
      name: 'NEGRENI',
      zip: '154610',
      isUrban: false,
    },
    {
      name: 'NEGRENII DE SUS',
      zip: '154629',
      isUrban: false,
    },
    {
      name: 'NEGRENII-OSEBITI',
      zip: '154638',
      isUrban: false,
    },
    {
      name: 'OBARTU',
      zip: '154647',
      isUrban: false,
    },
    {
      name: 'SLAVESTI',
      zip: '154656',
      isUrban: false,
    },
    {
      name: 'TATARASTII DE SUS',
      zip: '154674',
      isUrban: false,
    },
    {
      name: 'DOBRENI',
      zip: '154683',
      isUrban: false,
    },
    {
      name: 'UDUPU',
      zip: '154692',
      isUrban: false,
    },
    {
      name: 'TIGANESTI',
      zip: '154843',
      isUrban: false,
    },
    {
      name: 'TRAIAN',
      zip: '154745',
      isUrban: false,
    },
    {
      name: 'TRIVALEA-MOSTENI',
      zip: '154763',
      isUrban: false,
    },
    {
      name: 'BRATASANI',
      zip: '154772',
      isUrban: false,
    },
    {
      name: 'DEPARATI',
      zip: '154781',
      isUrban: false,
    },
    {
      name: 'TROIANUL',
      zip: '154807',
      isUrban: false,
    },
    {
      name: 'DULCENI',
      zip: '154816',
      isUrban: false,
    },
    {
      name: 'VATRA',
      zip: '154825',
      isUrban: false,
    },
    {
      name: 'VARTOAPELE DE SUS',
      zip: '154941',
      isUrban: false,
    },
    {
      name: 'GARAGAU',
      zip: '154950',
      isUrban: false,
    },
    {
      name: 'VARTOAPELE DE JOS',
      zip: '154969',
      isUrban: false,
    },
    {
      name: 'VEDEA',
      zip: '154861',
      isUrban: false,
    },
    {
      name: 'ALBESTI',
      zip: '154870',
      isUrban: false,
    },
    {
      name: 'COSOTENI',
      zip: '154889',
      isUrban: false,
    },
    {
      name: 'DULCEANCA',
      zip: '154898',
      isUrban: false,
    },
    {
      name: 'MERI',
      zip: '154905',
      isUrban: false,
    },
    {
      name: 'VIISOARA',
      zip: '154923',
      isUrban: false,
    },
    {
      name: 'VITANESTI',
      zip: '153785',
      isUrban: false,
    },
    {
      name: 'SCHITU POIENARI',
      zip: '153794',
      isUrban: false,
    },
    {
      name: 'SILISTEA',
      zip: '153801',
      isUrban: false,
    },
    {
      name: 'PURANI',
      zip: '153810',
      isUrban: false,
    },
    {
      name: 'ZAMBREASCA',
      zip: '154987',
      isUrban: false,
    },
    {
      name: 'BARSESTII DE JOS',
      zip: '153650',
      isUrban: false,
    },
    {
      name: 'BECIU',
      zip: '153641',
      isUrban: false,
    },
    {
      name: 'BRANCOVEANCA',
      zip: '153669',
      isUrban: false,
    },
    {
      name: 'DUDU',
      zip: '153678',
      isUrban: false,
    },
    {
      name: 'SMARDAN',
      zip: '153687',
      isUrban: false,
    },
    {
      name: 'PLOSCA',
      zip: '153614',
      isUrban: false,
    },
    {
      name: 'POENI',
      zip: '153703',
      isUrban: false,
    },
    {
      name: 'BANOV',
      zip: '153712',
      isUrban: false,
    },
    {
      name: 'BRATESTI',
      zip: '153721',
      isUrban: false,
    },
    {
      name: 'CATUNU',
      zip: '153730',
      isUrban: false,
    },
    {
      name: 'PREAJBA',
      zip: '153749',
      isUrban: false,
    }
  ],
  TM: [
    {
      name: 'TIMISOARA',
      zip: '155252',
      isUrban: true,
    },
    {
      name: 'LUGOJ',
      zip: '155369',
      isUrban: true,
    },
    {
      name: 'MAGURI',
      zip: '155378',
      isUrban: true,
    },
    {
      name: 'TAPIA',
      zip: '155387',
      isUrban: true,
    },
    {
      name: 'BUZIAS',
      zip: '155412',
      isUrban: true,
    },
    {
      name: 'BACOVA',
      zip: '155430',
      isUrban: true,
    },
    {
      name: 'SILAGIU',
      zip: '155421',
      isUrban: true,
    },
    {
      name: 'DETA',
      zip: '155467',
      isUrban: true,
    },
    {
      name: 'OPATITA',
      zip: '155476',
      isUrban: true,
    },
    {
      name: 'FAGET',
      zip: '156810',
      isUrban: true,
    },
    {
      name: 'BATESTI',
      zip: '156829',
      isUrban: true,
    },
    {
      name: 'BEGHEIU MIC',
      zip: '156838',
      isUrban: true,
    },
    {
      name: 'BICHIGI',
      zip: '156847',
      isUrban: true,
    },
    {
      name: 'BRANESTI',
      zip: '156856',
      isUrban: true,
    },
    {
      name: 'BUNEA MARE',
      zip: '156865',
      isUrban: true,
    },
    {
      name: 'BUNEA MICA',
      zip: '156874',
      isUrban: true,
    },
    {
      name: 'COLONIA MICA',
      zip: '156883',
      isUrban: true,
    },
    {
      name: 'JUPANESTI',
      zip: '156892',
      isUrban: true,
    },
    {
      name: 'POVARGINA',
      zip: '156909',
      isUrban: true,
    },
    {
      name: 'TEMERESTI',
      zip: '156918',
      isUrban: true,
    },
    {
      name: 'JIMBOLIA',
      zip: '155500',
      isUrban: true,
    },
    {
      name: 'SANNICOLAU MARE',
      zip: '155537',
      isUrban: true,
    },
    {
      name: 'BALINT',
      zip: '155555',
      isUrban: false,
    },
    {
      name: 'BODO',
      zip: '155564',
      isUrban: false,
    },
    {
      name: 'FADIMAC',
      zip: '155573',
      isUrban: false,
    },
    {
      name: 'TARGOVISTE',
      zip: '155582',
      isUrban: false,
    },
    {
      name: 'BANLOC',
      zip: '155608',
      isUrban: false,
    },
    {
      name: 'DOLAT',
      zip: '155617',
      isUrban: false,
    },
    {
      name: 'LIVEZILE',
      zip: '155626',
      isUrban: false,
    },
    {
      name: 'OFSENITA',
      zip: '155635',
      isUrban: false,
    },
    {
      name: 'PARTOS',
      zip: '155644',
      isUrban: false,
    },
    {
      name: 'SOCA',
      zip: '155653',
      isUrban: false,
    },
    {
      name: 'BARA',
      zip: '155671',
      isUrban: false,
    },
    {
      name: 'DOBRESTI',
      zip: '155680',
      isUrban: false,
    },
    {
      name: 'LAPUSNIC',
      zip: '155699',
      isUrban: false,
    },
    {
      name: 'RADMANESTI',
      zip: '155706',
      isUrban: false,
    },
    {
      name: 'SPATA',
      zip: '155715',
      isUrban: false,
    },
    {
      name: 'BARNA',
      zip: '155966',
      isUrban: false,
    },
    {
      name: 'BOTESTI',
      zip: '155975',
      isUrban: false,
    },
    {
      name: 'BOTINESTI',
      zip: '155984',
      isUrban: false,
    },
    {
      name: 'DRINOVA',
      zip: '155993',
      isUrban: false,
    },
    {
      name: 'JURESTI',
      zip: '156008',
      isUrban: false,
    },
    {
      name: 'POGANESTI',
      zip: '156017',
      isUrban: false,
    },
    {
      name: 'SARAZANI',
      zip: '156026',
      isUrban: false,
    },
    {
      name: 'BEBA VECHE',
      zip: '155733',
      isUrban: false,
    },
    {
      name: 'CHERESTUR',
      zip: '155742',
      isUrban: false,
    },
    {
      name: 'PORDEANU',
      zip: '155751',
      isUrban: false,
    },
    {
      name: 'BECICHERECU MIC',
      zip: '155779',
      isUrban: false,
    },
    {
      name: 'DUDESTII NOI',
      zip: '155788',
      isUrban: false,
    },
    {
      name: 'BELINT',
      zip: '155804',
      isUrban: false,
    },
    {
      name: 'BABSA',
      zip: '155813',
      isUrban: false,
    },
    {
      name: 'CHIZATAU',
      zip: '155822',
      isUrban: false,
    },
    {
      name: 'GRUNI',
      zip: '155831',
      isUrban: false,
    },
    {
      name: 'BETHAUSEN',
      zip: '155859',
      isUrban: false,
    },
    {
      name: 'CLADOVA',
      zip: '155868',
      isUrban: false,
    },
    {
      name: 'CLICIOVA',
      zip: '155877',
      isUrban: false,
    },
    {
      name: 'CUTINA',
      zip: '155886',
      isUrban: false,
    },
    {
      name: 'LEUCUSESTI',
      zip: '155895',
      isUrban: false,
    },
    {
      name: 'NEVRINCEA',
      zip: '155902',
      isUrban: false,
    },
    {
      name: 'BILED',
      zip: '155920',
      isUrban: false,
    },
    {
      name: 'SANDRA',
      zip: '155939',
      isUrban: false,
    },
    {
      name: 'UIHEI',
      zip: '155948',
      isUrban: false,
    },
    {
      name: 'BOGDA',
      zip: '156044',
      isUrban: false,
    },
    {
      name: 'ALTRINGEN',
      zip: '156053',
      isUrban: false,
    },
    {
      name: 'BUZAD',
      zip: '156062',
      isUrban: false,
    },
    {
      name: 'CHARLOTENBURG',
      zip: '156071',
      isUrban: false,
    },
    {
      name: 'COMEAT',
      zip: '156080',
      isUrban: false,
    },
    {
      name: 'SINTAR',
      zip: '156099',
      isUrban: false,
    },
    {
      name: 'BOLDUR',
      zip: '156115',
      isUrban: false,
    },
    {
      name: 'JABAR',
      zip: '156124',
      isUrban: false,
    },
    {
      name: 'OHABA-FORGACI',
      zip: '156133',
      isUrban: false,
    },
    {
      name: 'SINERSIG',
      zip: '156142',
      isUrban: false,
    },
    {
      name: 'BRESTOVAT',
      zip: '156160',
      isUrban: false,
    },
    {
      name: 'COSARII',
      zip: '156179',
      isUrban: false,
    },
    {
      name: 'HODOS',
      zip: '156188',
      isUrban: false,
    },
    {
      name: 'LUCARET',
      zip: '156197',
      isUrban: false,
    },
    {
      name: 'TES',
      zip: '156204',
      isUrban: false,
    },
    {
      name: 'CARPINIS',
      zip: '156222',
      isUrban: false,
    },
    {
      name: 'IECEA MARE',
      zip: '156231',
      isUrban: false,
    },
    {
      name: 'IECEA MICA',
      zip: '156240',
      isUrban: false,
    },
    {
      name: 'CENAD',
      zip: '156268',
      isUrban: false,
    },
    {
      name: 'CENEI',
      zip: '156286',
      isUrban: false,
    },
    {
      name: 'BOBDA',
      zip: '156295',
      isUrban: false,
    },
    {
      name: 'CHECEA',
      zip: '156302',
      isUrban: false,
    },
    {
      name: 'CHEVERESU MARE',
      zip: '156320',
      isUrban: false,
    },
    {
      name: 'DRAGSINA',
      zip: '156339',
      isUrban: false,
    },
    {
      name: 'VUCOVA',
      zip: '156348',
      isUrban: false,
    },
    {
      name: 'CIACOVA',
      zip: '156366',
      isUrban: true,
    },
    {
      name: 'CEBZA',
      zip: '156375',
      isUrban: true,
    },
    {
      name: 'GAD',
      zip: '156384',
      isUrban: false,
    },
    {
      name: 'GHILAD',
      zip: '156393',
      isUrban: false,
    },
    {
      name: 'MACEDONIA',
      zip: '156400',
      isUrban: true,
    },
    {
      name: 'OBAD',
      zip: '156419',
      isUrban: true,
    },
    {
      name: 'PETROMAN',
      zip: '156428',
      isUrban: true,
    },
    {
      name: 'COMLOSU MARE',
      zip: '156446',
      isUrban: false,
    },
    {
      name: 'COMLOSU MIC',
      zip: '156455',
      isUrban: false,
    },
    {
      name: 'LUNGA',
      zip: '156464',
      isUrban: false,
    },
    {
      name: 'COSTEIU',
      zip: '156482',
      isUrban: false,
    },
    {
      name: 'HEZERIS',
      zip: '156491',
      isUrban: false,
    },
    {
      name: 'PARU',
      zip: '156507',
      isUrban: false,
    },
    {
      name: 'TIPARI',
      zip: '156516',
      isUrban: false,
    },
    {
      name: 'VALEA LUNGA ROMANA',
      zip: '156525',
      isUrban: false,
    },
    {
      name: 'CRICIOVA',
      zip: '156543',
      isUrban: false,
    },
    {
      name: 'CIRESU',
      zip: '156552',
      isUrban: false,
    },
    {
      name: 'CIRESU MIC',
      zip: '156561',
      isUrban: false,
    },
    {
      name: 'JDIOARA',
      zip: '156570',
      isUrban: false,
    },
    {
      name: 'CURTEA',
      zip: '156598',
      isUrban: false,
    },
    {
      name: 'COSAVA',
      zip: '156605',
      isUrban: false,
    },
    {
      name: 'HOMOJDIA',
      zip: '156614',
      isUrban: false,
    },
    {
      name: 'DAROVA',
      zip: '156632',
      isUrban: false,
    },
    {
      name: 'HODOS',
      zip: '156641',
      isUrban: false,
    },
    {
      name: 'SACOSU MARE',
      zip: '156650',
      isUrban: false,
    },
    {
      name: 'DENTA',
      zip: '156678',
      isUrban: false,
    },
    {
      name: 'BRESTEA',
      zip: '156687',
      isUrban: false,
    },
    {
      name: 'ROVINITA MARE',
      zip: '156696',
      isUrban: false,
    },
    {
      name: 'ROVINITA MICA',
      zip: '156703',
      isUrban: false,
    },
    {
      name: 'DUDESTII VECHI',
      zip: '156721',
      isUrban: false,
    },
    {
      name: 'CHEGLEVICI',
      zip: '156730',
      isUrban: false,
    },
    {
      name: 'COLONIA BULGARA',
      zip: '156749',
      isUrban: false,
    },
    {
      name: 'VALCANI',
      zip: '156758',
      isUrban: false,
    },
    {
      name: 'DUMBRAVA',
      zip: '156776',
      isUrban: false,
    },
    {
      name: 'BUCOVAT',
      zip: '156785',
      isUrban: false,
    },
    {
      name: 'RACHITA',
      zip: '156794',
      isUrban: false,
    },
    {
      name: 'DUMBRAVITA',
      zip: '155270',
      isUrban: false,
    },
    {
      name: 'FARDEA',
      zip: '156936',
      isUrban: false,
    },
    {
      name: 'DRAGSINESTI',
      zip: '156945',
      isUrban: false,
    },
    {
      name: 'GLADNA MONTANA',
      zip: '156954',
      isUrban: false,
    },
    {
      name: 'GLADNA ROMANA',
      zip: '156963',
      isUrban: false,
    },
    {
      name: 'HAUZESTI',
      zip: '156972',
      isUrban: false,
    },
    {
      name: 'MATNICU MIC',
      zip: '156981',
      isUrban: false,
    },
    {
      name: 'ZOLT',
      zip: '156990',
      isUrban: false,
    },
    {
      name: 'FOENI',
      zip: '157013',
      isUrban: false,
    },
    {
      name: 'CRUCENI',
      zip: '157022',
      isUrban: false,
    },
    {
      name: 'GATAIA',
      zip: '157095',
      isUrban: true,
    },
    {
      name: 'BERECUTA',
      zip: '157102',
      isUrban: false,
    },
    {
      name: 'BIRDA',
      zip: '157111',
      isUrban: false,
    },
    {
      name: 'BUTIN',
      zip: '157120',
      isUrban: true,
    },
    {
      name: 'MANASTIRE',
      zip: '157139',
      isUrban: false,
    },
    {
      name: 'PERCOSOVA',
      zip: '157148',
      isUrban: true,
    },
    {
      name: 'SANGEORGE',
      zip: '157166',
      isUrban: false,
    },
    {
      name: 'SCULIA',
      zip: '157157',
      isUrban: true,
    },
    {
      name: 'SEMLACU MARE',
      zip: '157175',
      isUrban: true,
    },
    {
      name: 'SEMLACU MIC',
      zip: '157184',
      isUrban: true,
    },
    {
      name: 'GAVOJDIA',
      zip: '157040',
      isUrban: false,
    },
    {
      name: 'JENA',
      zip: '157059',
      isUrban: false,
    },
    {
      name: 'LUGOJEL',
      zip: '157068',
      isUrban: false,
    },
    {
      name: 'SALBAGEL',
      zip: '157077',
      isUrban: false,
    },
    {
      name: 'GHIRODA',
      zip: '155298',
      isUrban: false,
    },
    {
      name: 'GIARMATA-VII',
      zip: '155305',
      isUrban: false,
    },
    {
      name: 'GHIZELA',
      zip: '157200',
      isUrban: false,
    },
    {
      name: 'HISIAS',
      zip: '157219',
      isUrban: false,
    },
    {
      name: 'PANIOVA',
      zip: '157228',
      isUrban: false,
    },
    {
      name: 'GHERMAN',
      zip: '157406',
      isUrban: false,
    },
    {
      name: 'LATUNAS',
      zip: '157415',
      isUrban: false,
    },
    {
      name: 'JEBEL',
      zip: '157433',
      isUrban: false,
    },
    {
      name: 'PADURENI',
      zip: '157442',
      isUrban: false,
    },
    {
      name: 'LENAUHEIM',
      zip: '157460',
      isUrban: false,
    },
    {
      name: 'BULGARUS',
      zip: '157479',
      isUrban: false,
    },
    {
      name: 'GRABAT',
      zip: '157488',
      isUrban: false,
    },
    {
      name: 'LIEBLING',
      zip: '157503',
      isUrban: false,
    },
    {
      name: 'CERNA',
      zip: '157512',
      isUrban: false,
    },
    {
      name: 'IOSIF',
      zip: '157521',
      isUrban: false,
    },
    {
      name: 'LOVRIN',
      zip: '157549',
      isUrban: false,
    },
    {
      name: 'GOTTLOB',
      zip: '157558',
      isUrban: false,
    },
    {
      name: 'TOMNATIC',
      zip: '157567',
      isUrban: false,
    },
    {
      name: 'VIZEJDIA',
      zip: '157576',
      isUrban: false,
    },
    {
      name: 'MANASTIUR',
      zip: '157745',
      isUrban: false,
    },
    {
      name: 'PADURANI',
      zip: '157754',
      isUrban: false,
    },
    {
      name: 'REMETEA-LUNCA',
      zip: '157763',
      isUrban: false,
    },
    {
      name: 'TOPLA',
      zip: '157772',
      isUrban: false,
    },
    {
      name: 'MARGINA',
      zip: '157594',
      isUrban: false,
    },
    {
      name: 'BREAZOVA',
      zip: '157601',
      isUrban: false,
    },
    {
      name: 'BULZA',
      zip: '157610',
      isUrban: false,
    },
    {
      name: 'COSEVITA',
      zip: '157629',
      isUrban: false,
    },
    {
      name: 'COSTEIU DE SUS',
      zip: '157638',
      isUrban: false,
    },
    {
      name: 'GROSI',
      zip: '157647',
      isUrban: false,
    },
    {
      name: 'NEMESESTI',
      zip: '157656',
      isUrban: false,
    },
    {
      name: 'SINTESTI',
      zip: '157665',
      isUrban: false,
    },
    {
      name: 'ZORANI',
      zip: '157674',
      isUrban: false,
    },
    {
      name: 'MASLOC',
      zip: '157692',
      isUrban: false,
    },
    {
      name: 'ALIOS',
      zip: '157709',
      isUrban: false,
    },
    {
      name: 'FIBIS',
      zip: '157718',
      isUrban: false,
    },
    {
      name: 'REMETEA MICA',
      zip: '157727',
      isUrban: false,
    },
    {
      name: 'MORAVITA',
      zip: '157790',
      isUrban: false,
    },
    {
      name: 'DEJAN',
      zip: '157807',
      isUrban: false,
    },
    {
      name: 'GAIU MIC',
      zip: '157816',
      isUrban: false,
    },
    {
      name: 'STAMORA GERMANA',
      zip: '157825',
      isUrban: false,
    },
    {
      name: 'MOSNITA NOUA',
      zip: '157843',
      isUrban: false,
    },
    {
      name: 'ALBINA',
      zip: '157852',
      isUrban: false,
    },
    {
      name: 'MOSNITA VECHE',
      zip: '157861',
      isUrban: false,
    },
    {
      name: 'RUDICICA',
      zip: '157870',
      isUrban: false,
    },
    {
      name: 'URSENI',
      zip: '157889',
      isUrban: false,
    },
    {
      name: 'NADRAG',
      zip: '157905',
      isUrban: false,
    },
    {
      name: 'CRIVINA',
      zip: '157914',
      isUrban: false,
    },
    {
      name: 'NITCHIDORF',
      zip: '157932',
      isUrban: false,
    },
    {
      name: 'BLAJOVA',
      zip: '157941',
      isUrban: false,
    },
    {
      name: 'DUBOZ',
      zip: '157950',
      isUrban: false,
    },
    {
      name: 'OHABA LUNGA',
      zip: '157978',
      isUrban: false,
    },
    {
      name: 'DUBESTI',
      zip: '157987',
      isUrban: false,
    },
    {
      name: 'IERSNIC',
      zip: '157996',
      isUrban: false,
    },
    {
      name: 'OHABA ROMANA',
      zip: '158001',
      isUrban: false,
    },
    {
      name: 'ORTISOARA',
      zip: '158029',
      isUrban: false,
    },
    {
      name: 'CALACEA',
      zip: '158038',
      isUrban: false,
    },
    {
      name: 'CORNESTI',
      zip: '158047',
      isUrban: false,
    },
    {
      name: 'SECEANI',
      zip: '158056',
      isUrban: false,
    },
    {
      name: 'PECIU NOU',
      zip: '158074',
      isUrban: false,
    },
    {
      name: 'DINIAS',
      zip: '158083',
      isUrban: false,
    },
    {
      name: 'SANMARTINU SARBESC',
      zip: '158092',
      isUrban: false,
    },
    {
      name: 'PERIAM',
      zip: '158118',
      isUrban: false,
    },
    {
      name: 'PESAC',
      zip: '158127',
      isUrban: false,
    },
    {
      name: 'PIETROASA',
      zip: '158145',
      isUrban: false,
    },
    {
      name: 'CRIVINA DE SUS',
      zip: '158154',
      isUrban: false,
    },
    {
      name: 'FARASESTI',
      zip: '158163',
      isUrban: false,
    },
    {
      name: 'POIENI',
      zip: '158172',
      isUrban: false,
    },
    {
      name: 'PISCHIA',
      zip: '158190',
      isUrban: false,
    },
    {
      name: 'BENCECU DE JOS',
      zip: '158207',
      isUrban: false,
    },
    {
      name: 'BENCECU DE SUS',
      zip: '158216',
      isUrban: false,
    },
    {
      name: 'MURANI',
      zip: '158225',
      isUrban: false,
    },
    {
      name: 'SALCIUA NOUA',
      zip: '158234',
      isUrban: false,
    },
    {
      name: 'RACOVITA',
      zip: '158252',
      isUrban: false,
    },
    {
      name: 'CAPAT',
      zip: '158261',
      isUrban: false,
    },
    {
      name: 'DRAGOIESTI',
      zip: '158270',
      isUrban: false,
    },
    {
      name: 'FICATAR',
      zip: '158289',
      isUrban: false,
    },
    {
      name: 'HITIAS',
      zip: '158298',
      isUrban: false,
    },
    {
      name: 'SARBOVA',
      zip: '158305',
      isUrban: false,
    },
    {
      name: 'RECAS',
      zip: '158323',
      isUrban: true,
    },
    {
      name: 'BAZOS',
      zip: '158332',
      isUrban: true,
    },
    {
      name: 'HERNEACOVA',
      zip: '158341',
      isUrban: true,
    },
    {
      name: 'IZVIN',
      zip: '158350',
      isUrban: true,
    },
    {
      name: 'NADAS',
      zip: '158369',
      isUrban: true,
    },
    {
      name: 'PETROVASELO',
      zip: '158378',
      isUrban: true,
    },
    {
      name: 'STANCIOVA',
      zip: '158387',
      isUrban: true,
    },
    {
      name: 'REMETEA MARE',
      zip: '158403',
      isUrban: false,
    },
    {
      name: 'BAZOSU NOU',
      zip: '158412',
      isUrban: false,
    },
    {
      name: 'BUCOVAT',
      zip: '158421',
      isUrban: false,
    },
    {
      name: 'IANOVA',
      zip: '158430',
      isUrban: false,
    },
    {
      name: 'SACALAZ',
      zip: '158573',
      isUrban: false,
    },
    {
      name: 'BEREGSAU MARE',
      zip: '158582',
      isUrban: false,
    },
    {
      name: 'BEREGSAU MIC',
      zip: '158591',
      isUrban: false,
    },
    {
      name: 'SACOSU TURCESC',
      zip: '158458',
      isUrban: false,
    },
    {
      name: 'BERINI',
      zip: '158467',
      isUrban: false,
    },
    {
      name: 'ICLODA',
      zip: '158476',
      isUrban: false,
    },
    {
      name: 'OTVESTI',
      zip: '158485',
      isUrban: false,
    },
    {
      name: 'STAMORA ROMANA',
      zip: '158494',
      isUrban: false,
    },
    {
      name: 'ULIUC',
      zip: '158500',
      isUrban: false,
    },
    {
      name: 'UNIP',
      zip: '158519',
      isUrban: false,
    },
    {
      name: 'SAG',
      zip: '158788',
      isUrban: false,
    },
    {
      name: 'PARTA',
      zip: '158797',
      isUrban: false,
    },
    {
      name: 'SANANDREI',
      zip: '158662',
      isUrban: false,
    },
    {
      name: 'CARANI',
      zip: '158671',
      isUrban: false,
    },
    {
      name: 'COVACI',
      zip: '158680',
      isUrban: false,
    },
    {
      name: 'SANMIHAIU ROMAN',
      zip: '158706',
      isUrban: false,
    },
    {
      name: 'SANMIHAIU GERMAN',
      zip: '158715',
      isUrban: false,
    },
    {
      name: 'UTVIN',
      zip: '158724',
      isUrban: false,
    },
    {
      name: 'SANPETRU MARE',
      zip: '158742',
      isUrban: false,
    },
    {
      name: 'IGRIS',
      zip: '158751',
      isUrban: false,
    },
    {
      name: 'SARAVALE',
      zip: '158760',
      isUrban: false,
    },
    {
      name: 'SATCHINEZ',
      zip: '158537',
      isUrban: false,
    },
    {
      name: 'BARATEAZ',
      zip: '158546',
      isUrban: false,
    },
    {
      name: 'HODONI',
      zip: '158555',
      isUrban: false,
    },
    {
      name: 'SECAS',
      zip: '158617',
      isUrban: false,
    },
    {
      name: 'CHECHES',
      zip: '158626',
      isUrban: false,
    },
    {
      name: 'CRIVOBARA',
      zip: '158635',
      isUrban: false,
    },
    {
      name: 'VIZMA',
      zip: '158644',
      isUrban: false,
    },
    {
      name: 'STIUCA',
      zip: '158813',
      isUrban: false,
    },
    {
      name: 'DRAGOMIRESTI',
      zip: '158822',
      isUrban: false,
    },
    {
      name: 'OLOSAG',
      zip: '158831',
      isUrban: false,
    },
    {
      name: 'ZGRIBESTI',
      zip: '158840',
      isUrban: false,
    },
    {
      name: 'TEREMIA MARE',
      zip: '158868',
      isUrban: false,
    },
    {
      name: 'NERAU',
      zip: '158877',
      isUrban: false,
    },
    {
      name: 'TEREMIA MICA',
      zip: '158886',
      isUrban: false,
    },
    {
      name: 'TOMESTI',
      zip: '158902',
      isUrban: false,
    },
    {
      name: 'BALOSESTI',
      zip: '158911',
      isUrban: false,
    },
    {
      name: 'COLONIA FABRICII',
      zip: '158920',
      isUrban: false,
    },
    {
      name: 'LUNCANII DE JOS',
      zip: '158939',
      isUrban: false,
    },
    {
      name: 'LUNCANII DE SUS',
      zip: '158948',
      isUrban: false,
    },
    {
      name: 'ROMANESTI',
      zip: '158957',
      isUrban: false,
    },
    {
      name: 'TOPOLOVATU MARE',
      zip: '158975',
      isUrban: false,
    },
    {
      name: 'CRALOVAT',
      zip: '158984',
      isUrban: false,
    },
    {
      name: 'ICTAR-BUDINTI',
      zip: '158993',
      isUrban: false,
    },
    {
      name: 'IOSIFALAU',
      zip: '159008',
      isUrban: false,
    },
    {
      name: 'SUSTRA',
      zip: '159017',
      isUrban: false,
    },
    {
      name: 'TOPOLOVATU MIC',
      zip: '159026',
      isUrban: false,
    },
    {
      name: 'TORMAC',
      zip: '159044',
      isUrban: false,
    },
    {
      name: 'CADAR',
      zip: '159053',
      isUrban: false,
    },
    {
      name: 'SIPET',
      zip: '159062',
      isUrban: false,
    },
    {
      name: 'SUDRIAS',
      zip: '159080',
      isUrban: false,
    },
    {
      name: 'JUPANI',
      zip: '159099',
      isUrban: false,
    },
    {
      name: 'SACENI',
      zip: '159106',
      isUrban: false,
    },
    {
      name: 'SURDUCU MIC',
      zip: '159115',
      isUrban: false,
    },
    {
      name: 'SUSANI',
      zip: '159124',
      isUrban: false,
    },
    {
      name: 'TRAIAN VUIA',
      zip: '159133',
      isUrban: false,
    },
    {
      name: 'UIVAR',
      zip: '159151',
      isUrban: false,
    },
    {
      name: 'IOHANISFELD',
      zip: '159160',
      isUrban: false,
    },
    {
      name: 'OTELEC',
      zip: '159179',
      isUrban: false,
    },
    {
      name: 'PUSTINIS',
      zip: '159188',
      isUrban: false,
    },
    {
      name: 'RAUTI',
      zip: '159197',
      isUrban: false,
    },
    {
      name: 'SANMARTINU MAGHIAR',
      zip: '159204',
      isUrban: false,
    },
    {
      name: 'VARIAS',
      zip: '159222',
      isUrban: false,
    },
    {
      name: 'GELU',
      zip: '159231',
      isUrban: false,
    },
    {
      name: 'SANPETRU MIC',
      zip: '159240',
      isUrban: false,
    },
    {
      name: 'VICTOR VLAD DELAMARINA',
      zip: '159268',
      isUrban: false,
    },
    {
      name: 'HERENDESTI',
      zip: '159277',
      isUrban: false,
    },
    {
      name: 'HONORICI',
      zip: '159286',
      isUrban: false,
    },
    {
      name: 'PADURENI',
      zip: '159295',
      isUrban: false,
    },
    {
      name: 'PETROASA MARE',
      zip: '159302',
      isUrban: false,
    },
    {
      name: 'PINI',
      zip: '159311',
      isUrban: false,
    },
    {
      name: 'VISAG',
      zip: '159320',
      isUrban: false,
    },
    {
      name: 'VOITEG',
      zip: '159348',
      isUrban: false,
    },
    {
      name: 'FOLEA',
      zip: '159357',
      isUrban: false,
    },
    {
      name: 'SANOVITA',
      zip: '157237',
      isUrban: false,
    },
    {
      name: 'GIARMATA',
      zip: '157255',
      isUrban: false,
    },
    {
      name: 'CERNETEAZ',
      zip: '157264',
      isUrban: false,
    },
    {
      name: 'GIERA',
      zip: '157282',
      isUrban: false,
    },
    {
      name: 'GRANICERII',
      zip: '157291',
      isUrban: false,
    },
    {
      name: 'TOAGER',
      zip: '157308',
      isUrban: false,
    },
    {
      name: 'GIROC',
      zip: '155323',
      isUrban: false,
    },
    {
      name: 'CHISODA',
      zip: '155332',
      isUrban: false,
    },
    {
      name: 'GIULVAZ',
      zip: '157326',
      isUrban: false,
    },
    {
      name: 'CRAI NOU',
      zip: '157335',
      isUrban: false,
    },
    {
      name: 'IVANDA',
      zip: '157344',
      isUrban: false,
    },
    {
      name: 'RUDNA',
      zip: '157353',
      isUrban: false,
    },
    {
      name: 'JAMU MARE',
      zip: '157371',
      isUrban: false,
    },
    {
      name: 'CLOPODIA',
      zip: '157380',
      isUrban: false,
    },
    {
      name: 'FERENDIA',
      zip: '157399',
      isUrban: false,
    }
  ],
  TL: [
    {
      name: 'TULCEA',
      zip: '159623',
      isUrban: true,
    },
    {
      name: 'TUDOR VLADIMIRESCU',
      zip: '159632',
      isUrban: true,
    },
    {
      name: 'BABADAG',
      zip: '159669',
      isUrban: true,
    },
    {
      name: 'ISACCEA',
      zip: '159696',
      isUrban: true,
    },
    {
      name: 'REVARSAREA',
      zip: '159712',
      isUrban: true,
    },
    {
      name: 'TICHILESTI',
      zip: '159703',
      isUrban: true,
    },
    {
      name: 'MACIN',
      zip: '159749',
      isUrban: true,
    },
    {
      name: 'SULINA',
      zip: '159776',
      isUrban: true,
    },
    {
      name: 'BAIA',
      zip: '159794',
      isUrban: false,
    },
    {
      name: 'CAMENA',
      zip: '159801',
      isUrban: false,
    },
    {
      name: 'CAUGAGIA',
      zip: '159810',
      isUrban: false,
    },
    {
      name: 'CEAMURLIA DE SUS',
      zip: '159829',
      isUrban: false,
    },
    {
      name: 'PANDURU',
      zip: '159838',
      isUrban: false,
    },
    {
      name: 'BEIDAUD',
      zip: '159856',
      isUrban: false,
    },
    {
      name: 'NEATARNAREA',
      zip: '159865',
      isUrban: false,
    },
    {
      name: 'SARIGHIOL DE DEAL',
      zip: '159874',
      isUrban: false,
    },
    {
      name: 'C.A. ROSETTI',
      zip: '159892',
      isUrban: false,
    },
    {
      name: 'CARDON',
      zip: '159909',
      isUrban: false,
    },
    {
      name: 'LETEA',
      zip: '159918',
      isUrban: false,
    },
    {
      name: 'PERIPRAVA',
      zip: '159927',
      isUrban: false,
    },
    {
      name: 'SFISTOFCA',
      zip: '159936',
      isUrban: false,
    },
    {
      name: 'CARCALIU',
      zip: '159954',
      isUrban: false,
    },
    {
      name: 'CASIMCEA',
      zip: '159972',
      isUrban: false,
    },
    {
      name: 'CISMEAUA NOUA',
      zip: '159981',
      isUrban: false,
    },
    {
      name: 'CORUGEA',
      zip: '159990',
      isUrban: false,
    },
    {
      name: 'HAIDAR',
      zip: '160001',
      isUrban: false,
    },
    {
      name: 'RAHMAN',
      zip: '160010',
      isUrban: false,
    },
    {
      name: 'RAZBOIENI',
      zip: '160029',
      isUrban: false,
    },
    {
      name: 'STANCA',
      zip: '160038',
      isUrban: false,
    },
    {
      name: 'CEAMURLIA DE JOS',
      zip: '160109',
      isUrban: false,
    },
    {
      name: 'LUNCA',
      zip: '160118',
      isUrban: false,
    },
    {
      name: 'CEATALCHIOI',
      zip: '160056',
      isUrban: false,
    },
    {
      name: 'PATLAGEANCA',
      zip: '160065',
      isUrban: false,
    },
    {
      name: 'PLAURU',
      zip: '160074',
      isUrban: false,
    },
    {
      name: 'SALCENI',
      zip: '160083',
      isUrban: false,
    },
    {
      name: 'CERNA',
      zip: '160136',
      isUrban: false,
    },
    {
      name: 'GENERAL PRAPORGESCU',
      zip: '160145',
      isUrban: false,
    },
    {
      name: 'MIRCEA VODA',
      zip: '160154',
      isUrban: false,
    },
    {
      name: 'TRAIAN',
      zip: '160163',
      isUrban: false,
    },
    {
      name: 'CHILIA VECHE',
      zip: '160181',
      isUrban: false,
    },
    {
      name: 'CASLITA',
      zip: '160190',
      isUrban: false,
    },
    {
      name: 'OSTROVU TATARU',
      zip: '160207',
      isUrban: false,
    },
    {
      name: 'TATANIR',
      zip: '160216',
      isUrban: false,
    },
    {
      name: 'CIUCUROVA',
      zip: '160234',
      isUrban: false,
    },
    {
      name: 'ATMAGEA',
      zip: '160243',
      isUrban: false,
    },
    {
      name: 'FANTANA MARE',
      zip: '160252',
      isUrban: false,
    },
    {
      name: 'CRISAN',
      zip: '160270',
      isUrban: false,
    },
    {
      name: 'CARAORMAN',
      zip: '160289',
      isUrban: false,
    },
    {
      name: 'MILA 23',
      zip: '160298',
      isUrban: false,
    },
    {
      name: 'DAENI',
      zip: '160314',
      isUrban: false,
    },
    {
      name: 'DOROBANTU',
      zip: '160332',
      isUrban: false,
    },
    {
      name: 'ARDEALU',
      zip: '160341',
      isUrban: false,
    },
    {
      name: 'CARJELARI',
      zip: '160350',
      isUrban: false,
    },
    {
      name: 'FANTANA OILOR',
      zip: '160369',
      isUrban: false,
    },
    {
      name: 'MESTERU',
      zip: '160378',
      isUrban: false,
    },
    {
      name: 'FRECATEI',
      zip: '160396',
      isUrban: false,
    },
    {
      name: 'CATALOI',
      zip: '160403',
      isUrban: false,
    },
    {
      name: 'POSTA',
      zip: '160412',
      isUrban: false,
    },
    {
      name: 'TELITA',
      zip: '160421',
      isUrban: false,
    },
    {
      name: 'GRECI',
      zip: '160449',
      isUrban: false,
    },
    {
      name: 'GRINDU',
      zip: '160467',
      isUrban: false,
    },
    {
      name: 'HAMCEARCA',
      zip: '160485',
      isUrban: false,
    },
    {
      name: 'BALABANCEA',
      zip: '160494',
      isUrban: false,
    },
    {
      name: 'CAPRIOARA',
      zip: '160500',
      isUrban: false,
    },
    {
      name: 'NIFON',
      zip: '160519',
      isUrban: false,
    },
    {
      name: 'HORIA',
      zip: '160537',
      isUrban: false,
    },
    {
      name: 'CLOSCA',
      zip: '160546',
      isUrban: false,
    },
    {
      name: 'FLORESTI',
      zip: '160555',
      isUrban: false,
    },
    {
      name: 'I.C.BRATIANU',
      zip: '161534',
      isUrban: false,
    },
    {
      name: 'IZVOARELE',
      zip: '160573',
      isUrban: false,
    },
    {
      name: 'ALBA',
      zip: '160582',
      isUrban: false,
    },
    {
      name: 'IULIA',
      zip: '160591',
      isUrban: false,
    },
    {
      name: 'VALEA TEILOR',
      zip: '160608',
      isUrban: false,
    },
    {
      name: 'JIJILA',
      zip: '160626',
      isUrban: false,
    },
    {
      name: 'GARVAN',
      zip: '160635',
      isUrban: false,
    },
    {
      name: 'JURILOVCA',
      zip: '160653',
      isUrban: false,
    },
    {
      name: 'SALCIOARA',
      zip: '160671',
      isUrban: false,
    },
    {
      name: 'VISINA',
      zip: '160662',
      isUrban: false,
    },
    {
      name: 'LUNCAVITA',
      zip: '160699',
      isUrban: false,
    },
    {
      name: 'RACHELU',
      zip: '160706',
      isUrban: false,
    },
    {
      name: 'VACARENI',
      zip: '160715',
      isUrban: false,
    },
    {
      name: 'MAHMUDIA',
      zip: '160733',
      isUrban: false,
    },
    {
      name: 'BALTENII DE JOS',
      zip: '160742',
      isUrban: false,
    },
    {
      name: 'UZLINA',
      zip: '160984',
      isUrban: false,
    },
    {
      name: 'NALBANT',
      zip: '161008',
      isUrban: false,
    },
    {
      name: 'NICOLAE BALCESCU',
      zip: '161017',
      isUrban: false,
    },
    {
      name: 'TRESTENIC',
      zip: '161026',
      isUrban: false,
    },
    {
      name: 'NICULITEL',
      zip: '161044',
      isUrban: false,
    },
    {
      name: 'NUFARU',
      zip: '161062',
      isUrban: false,
    },
    {
      name: 'ILGANII DE JOS',
      zip: '161071',
      isUrban: false,
    },
    {
      name: 'MALCOCI',
      zip: '161080',
      isUrban: false,
    },
    {
      name: 'VICTORIA',
      zip: '161099',
      isUrban: false,
    },
    {
      name: 'OSTROV',
      zip: '161115',
      isUrban: false,
    },
    {
      name: 'PIATRA',
      zip: '161124',
      isUrban: false,
    },
    {
      name: 'PARDINA',
      zip: '161142',
      isUrban: false,
    },
    {
      name: 'PECENEAGA',
      zip: '161160',
      isUrban: false,
    },
    {
      name: 'SARICHIOI',
      zip: '161188',
      isUrban: false,
    },
    {
      name: 'ENISALA',
      zip: '161197',
      isUrban: false,
    },
    {
      name: 'SABANGIA',
      zip: '161204',
      isUrban: false,
    },
    {
      name: 'VISTERNA',
      zip: '161213',
      isUrban: false,
    },
    {
      name: 'ZEBIL',
      zip: '161222',
      isUrban: false,
    },
    {
      name: 'SFANTU GHEORGHE',
      zip: '161240',
      isUrban: false,
    },
    {
      name: 'SLAVA CERCHEZA',
      zip: '161268',
      isUrban: false,
    },
    {
      name: 'SLAVA RUSA',
      zip: '161277',
      isUrban: false,
    },
    {
      name: 'SMARDAN',
      zip: '161295',
      isUrban: false,
    },
    {
      name: 'SOMOVA',
      zip: '161311',
      isUrban: false,
    },
    {
      name: 'MINERI',
      zip: '161320',
      isUrban: false,
    },
    {
      name: 'PARCHES',
      zip: '161339',
      isUrban: false,
    },
    {
      name: 'STEJARU',
      zip: '161357',
      isUrban: false,
    },
    {
      name: 'MINA ALTAN TEPE',
      zip: '161366',
      isUrban: false,
    },
    {
      name: 'VASILE ALECSANDRI',
      zip: '161375',
      isUrban: false,
    },
    {
      name: 'TOPOLOG',
      zip: '161393',
      isUrban: false,
    },
    {
      name: 'CALFA',
      zip: '161400',
      isUrban: false,
    },
    {
      name: 'CERBU',
      zip: '161419',
      isUrban: false,
    },
    {
      name: 'FAGARASU NOU',
      zip: '161428',
      isUrban: false,
    },
    {
      name: 'LUMINITA',
      zip: '161437',
      isUrban: false,
    },
    {
      name: 'MAGURELE',
      zip: '161446',
      isUrban: false,
    },
    {
      name: 'SAMBATA NOUA',
      zip: '161455',
      isUrban: false,
    },
    {
      name: 'TURCOAIA',
      zip: '161473',
      isUrban: false,
    },
    {
      name: 'VALEA NUCARILOR',
      zip: '161491',
      isUrban: false,
    },
    {
      name: 'AGIGHIOL',
      zip: '161507',
      isUrban: false,
    },
    {
      name: 'IAZURILE',
      zip: '161516',
      isUrban: false,
    },
    {
      name: 'BALTENII DE SUS',
      zip: '160751',
      isUrban: false,
    },
    {
      name: 'BESTEPE',
      zip: '160760',
      isUrban: false,
    },
    {
      name: 'MALIUC',
      zip: '160788',
      isUrban: false,
    },
    {
      name: 'GORGOVA',
      zip: '160804',
      isUrban: false,
    },
    {
      name: 'ILGANII DE SUS',
      zip: '160797',
      isUrban: false,
    },
    {
      name: 'PARTIZANI',
      zip: '160813',
      isUrban: false,
    },
    {
      name: 'VULTURU',
      zip: '160822',
      isUrban: false,
    },
    {
      name: 'MIHAI BRAVU',
      zip: '160840',
      isUrban: false,
    },
    {
      name: 'SATU NOU',
      zip: '160859',
      isUrban: false,
    },
    {
      name: 'TURDA',
      zip: '160868',
      isUrban: false,
    },
    {
      name: 'MIHAIL KOGALNICEANU',
      zip: '160886',
      isUrban: false,
    },
    {
      name: 'LASTUNI',
      zip: '160895',
      isUrban: false,
    },
    {
      name: 'RANDUNICA',
      zip: '160902',
      isUrban: false,
    },
    {
      name: 'MURIGHIOL',
      zip: '160920',
      isUrban: false,
    },
    {
      name: 'COLINA',
      zip: '160939',
      isUrban: false,
    },
    {
      name: 'DUNAVATU DE JOS',
      zip: '160948',
      isUrban: false,
    },
    {
      name: 'DUNAVATU DE SUS',
      zip: '160957',
      isUrban: false,
    },
    {
      name: 'PLOPUL',
      zip: '160966',
      isUrban: false,
    },
    {
      name: 'SARINASUF',
      zip: '160975',
      isUrban: false,
    }
  ],
  VS: [
    {
      name: 'VASLUI',
      zip: '161954',
      isUrban: true,
    },
    {
      name: 'BAHNARI',
      zip: '161963',
      isUrban: true,
    },
    {
      name: 'BRODOC',
      zip: '161972',
      isUrban: true,
    },
    {
      name: 'MOARA GRECILOR',
      zip: '161981',
      isUrban: true,
    },
    {
      name: 'REDIU',
      zip: '161990',
      isUrban: true,
    },
    {
      name: 'VIISOARA',
      zip: '162005',
      isUrban: true,
    },
    {
      name: 'BARLAD',
      zip: '161801',
      isUrban: true,
    },
    {
      name: 'HUSI',
      zip: '161838',
      isUrban: true,
    },
    {
      name: 'NEGRESTI',
      zip: '161865',
      isUrban: true,
    },
    {
      name: 'CAZANESTI',
      zip: '161874',
      isUrban: true,
    },
    {
      name: 'CIOATELE',
      zip: '161883',
      isUrban: true,
    },
    {
      name: 'GLODENI',
      zip: '161892',
      isUrban: true,
    },
    {
      name: 'PARPANITA',
      zip: '161909',
      isUrban: true,
    },
    {
      name: 'POIANA',
      zip: '161918',
      isUrban: true,
    },
    {
      name: 'VALEA MARE',
      zip: '161927',
      isUrban: true,
    },
    {
      name: 'ALBESTI',
      zip: '162023',
      isUrban: false,
    },
    {
      name: 'CORNI-ALBESTI',
      zip: '162032',
      isUrban: false,
    },
    {
      name: 'CRASNA',
      zip: '162041',
      isUrban: false,
    },
    {
      name: 'GURA ALBESTI',
      zip: '162050',
      isUrban: false,
    },
    {
      name: 'ALEXANDRU VLAHUTA',
      zip: '162078',
      isUrban: false,
    },
    {
      name: 'BUDA',
      zip: '162087',
      isUrban: false,
    },
    {
      name: 'GHICANI',
      zip: '162096',
      isUrban: false,
    },
    {
      name: 'IBANESTI',
      zip: '162103',
      isUrban: false,
    },
    {
      name: 'MANZATI',
      zip: '162112',
      isUrban: false,
    },
    {
      name: 'MORARENI',
      zip: '162121',
      isUrban: false,
    },
    {
      name: 'PUTU OLARULUI',
      zip: '162130',
      isUrban: false,
    },
    {
      name: 'ARSURA',
      zip: '162158',
      isUrban: false,
    },
    {
      name: 'FUNDATURA',
      zip: '162167',
      isUrban: false,
    },
    {
      name: 'MIHAIL KOGALNICEANU',
      zip: '162176',
      isUrban: false,
    },
    {
      name: 'PAHNESTI',
      zip: '162185',
      isUrban: false,
    },
    {
      name: 'BACANI',
      zip: '162336',
      isUrban: false,
    },
    {
      name: 'BALTATENI',
      zip: '162345',
      isUrban: false,
    },
    {
      name: 'DRUJESTI',
      zip: '162354',
      isUrban: false,
    },
    {
      name: 'SUSENI',
      zip: '162363',
      isUrban: false,
    },
    {
      name: 'VULPASENI',
      zip: '162372',
      isUrban: false,
    },
    {
      name: 'BACESTI',
      zip: '162390',
      isUrban: false,
    },
    {
      name: 'ARMASENI',
      zip: '162407',
      isUrban: false,
    },
    {
      name: 'BABUSA',
      zip: '162416',
      isUrban: false,
    },
    {
      name: 'PALTINIS',
      zip: '162425',
      isUrban: false,
    },
    {
      name: 'TIBANESTII BUHLII',
      zip: '162434',
      isUrban: false,
    },
    {
      name: 'VOVRIESTI',
      zip: '162443',
      isUrban: false,
    },
    {
      name: 'BALTENI',
      zip: '162461',
      isUrban: false,
    },
    {
      name: 'BALTENI-DEAL',
      zip: '162470',
      isUrban: false,
    },
    {
      name: 'CHETRESTI',
      zip: '162489',
      isUrban: false,
    },
    {
      name: 'GARA BANCA',
      zip: '162201',
      isUrban: false,
    },
    {
      name: '1 DECEMBRIE',
      zip: '162318',
      isUrban: false,
    },
    {
      name: 'BANCA',
      zip: '162210',
      isUrban: false,
    },
    {
      name: 'GHERMANESTI',
      zip: '162229',
      isUrban: false,
    },
    {
      name: 'MICLESTI',
      zip: '162238',
      isUrban: false,
    },
    {
      name: 'MITOC',
      zip: '162247',
      isUrban: false,
    },
    {
      name: 'SALCIOARA',
      zip: '162265',
      isUrban: false,
    },
    {
      name: 'SARBI',
      zip: '162274',
      isUrban: false,
    },
    {
      name: 'SATU NOU',
      zip: '162256',
      isUrban: false,
    },
    {
      name: 'STOISESTI',
      zip: '162283',
      isUrban: false,
    },
    {
      name: 'STRAMTURA-MITOC',
      zip: '162292',
      isUrban: false,
    },
    {
      name: 'TIFU',
      zip: '162309',
      isUrban: false,
    },
    {
      name: 'BEREZENI',
      zip: '162504',
      isUrban: false,
    },
    {
      name: 'MUSATA',
      zip: '162513',
      isUrban: false,
    },
    {
      name: 'RANCENI',
      zip: '162522',
      isUrban: false,
    },
    {
      name: 'SATU NOU',
      zip: '162531',
      isUrban: false,
    },
    {
      name: 'STUHULET',
      zip: '162540',
      isUrban: false,
    },
    {
      name: 'BLAGESTI',
      zip: '162568',
      isUrban: false,
    },
    {
      name: 'IGESTI',
      zip: '162577',
      isUrban: false,
    },
    {
      name: 'SIPENI',
      zip: '162586',
      isUrban: false,
    },
    {
      name: 'BOGDANA',
      zip: '162602',
      isUrban: false,
    },
    {
      name: 'ARSITA',
      zip: '162611',
      isUrban: false,
    },
    {
      name: 'FANTANA BLANARULUI',
      zip: '162620',
      isUrban: false,
    },
    {
      name: 'GAVANU',
      zip: '162639',
      isUrban: false,
    },
    {
      name: 'LACU BABEI',
      zip: '162648',
      isUrban: false,
    },
    {
      name: 'PLOPENI',
      zip: '162657',
      isUrban: false,
    },
    {
      name: 'SIMILISOARA',
      zip: '162666',
      isUrban: false,
    },
    {
      name: 'SUCEVENI',
      zip: '162675',
      isUrban: false,
    },
    {
      name: 'VERDES',
      zip: '162684',
      isUrban: false,
    },
    {
      name: 'BOGDANESTI',
      zip: '162700',
      isUrban: false,
    },
    {
      name: 'BUDA',
      zip: '162719',
      isUrban: false,
    },
    {
      name: 'HOROIATA',
      zip: '162728',
      isUrban: false,
    },
    {
      name: 'HUPCA',
      zip: '162737',
      isUrban: false,
    },
    {
      name: 'ORGOIESTI',
      zip: '162746',
      isUrban: false,
    },
    {
      name: 'ULEA',
      zip: '162755',
      isUrban: false,
    },
    {
      name: 'UNTESTI',
      zip: '162764',
      isUrban: false,
    },
    {
      name: 'VISINARI',
      zip: '162773',
      isUrban: false,
    },
    {
      name: 'VLADESTI',
      zip: '162782',
      isUrban: false,
    },
    {
      name: 'BOGDANITA',
      zip: '162808',
      isUrban: false,
    },
    {
      name: 'CARTIBASI',
      zip: '162826',
      isUrban: false,
    },
    {
      name: 'CEPESTI',
      zip: '162817',
      isUrban: false,
    },
    {
      name: 'COROIESTI',
      zip: '162835',
      isUrban: false,
    },
    {
      name: 'RADAESTI',
      zip: '162844',
      isUrban: false,
    },
    {
      name: 'SCHITU',
      zip: '162853',
      isUrban: false,
    },
    {
      name: 'TUNSESTI',
      zip: '162862',
      isUrban: false,
    },
    {
      name: 'BOTESTI',
      zip: '162880',
      isUrban: false,
    },
    {
      name: 'GANESTI',
      zip: '162899',
      isUrban: false,
    },
    {
      name: 'GUGESTI',
      zip: '162906',
      isUrban: false,
    },
    {
      name: 'TALPIGENI',
      zip: '162915',
      isUrban: false,
    },
    {
      name: 'AVERESTI',
      zip: '162933',
      isUrban: false,
    },
    {
      name: 'ARMASENI',
      zip: '162942',
      isUrban: false,
    },
    {
      name: 'BUNESTI',
      zip: '162951',
      isUrban: false,
    },
    {
      name: 'PLOPI',
      zip: '162960',
      isUrban: false,
    },
    {
      name: 'PODU OPRII',
      zip: '162979',
      isUrban: false,
    },
    {
      name: 'ROSIORI',
      zip: '162988',
      isUrban: false,
    },
    {
      name: 'TABALAESTI',
      zip: '162997',
      isUrban: false,
    },
    {
      name: 'CODAESTI',
      zip: '163011',
      isUrban: false,
    },
    {
      name: 'GHERGHELEU',
      zip: '163020',
      isUrban: false,
    },
    {
      name: 'PRIBESTI',
      zip: '163039',
      isUrban: false,
    },
    {
      name: 'REDIU GALIAN',
      zip: '163048',
      isUrban: false,
    },
    {
      name: 'COROIESTI',
      zip: '163066',
      isUrban: false,
    },
    {
      name: 'CHILIENI',
      zip: '163075',
      isUrban: false,
    },
    {
      name: 'COROIESTII DE SUS',
      zip: '163084',
      isUrban: false,
    },
    {
      name: 'HREASCA',
      zip: '163093',
      isUrban: false,
    },
    {
      name: 'MIRENI',
      zip: '163100',
      isUrban: false,
    },
    {
      name: 'MOVILENI',
      zip: '163119',
      isUrban: false,
    },
    {
      name: 'PACURARESTI',
      zip: '163128',
      isUrban: false,
    },
    {
      name: 'COSTESTI',
      zip: '163146',
      isUrban: false,
    },
    {
      name: 'CHITCANI',
      zip: '163155',
      isUrban: false,
    },
    {
      name: 'DINGA',
      zip: '163164',
      isUrban: false,
    },
    {
      name: 'PARVESTI',
      zip: '163173',
      isUrban: false,
    },
    {
      name: 'PUNTISENI',
      zip: '163182',
      isUrban: false,
    },
    {
      name: 'RADESTI',
      zip: '163191',
      isUrban: false,
    },
    {
      name: 'CRETESTI',
      zip: '163217',
      isUrban: false,
    },
    {
      name: 'BUDESTI',
      zip: '163226',
      isUrban: false,
    },
    {
      name: 'CRETESTII DE SUS',
      zip: '163235',
      isUrban: false,
    },
    {
      name: 'SATU NOU',
      zip: '163244',
      isUrban: false,
    },
    {
      name: 'DANESTI',
      zip: '163262',
      isUrban: false,
    },
    {
      name: 'BEREASA',
      zip: '163271',
      isUrban: false,
    },
    {
      name: 'BOTOAIA',
      zip: '163280',
      isUrban: false,
    },
    {
      name: 'EMIL RACOVITA',
      zip: '163299',
      isUrban: false,
    },
    {
      name: 'RASCANI',
      zip: '163306',
      isUrban: false,
    },
    {
      name: 'TATARANI',
      zip: '163315',
      isUrban: false,
    },
    {
      name: 'DELENI',
      zip: '163333',
      isUrban: false,
    },
    {
      name: 'BULBOACA',
      zip: '163342',
      isUrban: false,
    },
    {
      name: 'MORENI',
      zip: '163351',
      isUrban: false,
    },
    {
      name: 'ZIZINCA',
      zip: '163360',
      isUrban: false,
    },
    {
      name: 'DELESTI',
      zip: '163388',
      isUrban: false,
    },
    {
      name: 'ALBESTI',
      zip: '163397',
      isUrban: false,
    },
    {
      name: 'BALESTI',
      zip: '163404',
      isUrban: false,
    },
    {
      name: 'COZMESTI',
      zip: '163413',
      isUrban: false,
    },
    {
      name: 'FASTACI',
      zip: '163422',
      isUrban: false,
    },
    {
      name: 'FUNDATURA',
      zip: '163431',
      isUrban: false,
    },
    {
      name: 'HARSOVA',
      zip: '163440',
      isUrban: false,
    },
    {
      name: 'HORDILESTI',
      zip: '163459',
      isUrban: false,
    },
    {
      name: 'MANASTIREA',
      zip: '163468',
      isUrban: false,
    },
    {
      name: 'RADUIESTI',
      zip: '163477',
      isUrban: false,
    },
    {
      name: 'HURDUGI',
      zip: '164286',
      isUrban: false,
    },
    {
      name: 'GRUMEZOAIA',
      zip: '164295',
      isUrban: false,
    },
    {
      name: 'GUSITEI',
      zip: '164302',
      isUrban: false,
    },
    {
      name: 'PLOTONESTI',
      zip: '164311',
      isUrban: false,
    },
    {
      name: 'URLATI',
      zip: '164320',
      isUrban: false,
    },
    {
      name: 'DRAGOMIRESTI',
      zip: '163495',
      isUrban: false,
    },
    {
      name: 'BABUTA',
      zip: '163501',
      isUrban: false,
    },
    {
      name: 'BELZENI',
      zip: '163510',
      isUrban: false,
    },
    {
      name: 'BOTOI',
      zip: '163529',
      isUrban: false,
    },
    {
      name: 'CIUPERCA',
      zip: '163538',
      isUrban: false,
    },
    {
      name: 'DOAGELE',
      zip: '163547',
      isUrban: false,
    },
    {
      name: 'POIANA PIETREI',
      zip: '163556',
      isUrban: false,
    },
    {
      name: 'POPESTI',
      zip: '163565',
      isUrban: false,
    },
    {
      name: 'RADENI',
      zip: '163574',
      isUrban: false,
    },
    {
      name: 'SEMENEA',
      zip: '163583',
      isUrban: false,
    },
    {
      name: 'TULESTI',
      zip: '163592',
      isUrban: false,
    },
    {
      name: 'VLADIA',
      zip: '163609',
      isUrban: false,
    },
    {
      name: 'GHERMANESTI',
      zip: '163654',
      isUrban: false,
    },
    {
      name: 'ALBITA',
      zip: '163636',
      isUrban: false,
    },
    {
      name: 'BAILE DRANCENI',
      zip: '163645',
      isUrban: false,
    },
    {
      name: 'DRANCENI',
      zip: '163627',
      isUrban: false,
    },
    {
      name: 'RASESTI',
      zip: '163663',
      isUrban: false,
    },
    {
      name: 'SOPARLENI',
      zip: '163672',
      isUrban: false,
    },
    {
      name: 'EPURENI',
      zip: '163690',
      isUrban: false,
    },
    {
      name: 'BOBESTI',
      zip: '163707',
      isUrban: false,
    },
    {
      name: 'DUDA',
      zip: '163716',
      isUrban: false,
    },
    {
      name: 'VALEA GRECULUI',
      zip: '163725',
      isUrban: false,
    },
    {
      name: 'DUMESTI',
      zip: '163743',
      isUrban: false,
    },
    {
      name: 'DUMESTII VECHI',
      zip: '163752',
      isUrban: false,
    },
    {
      name: 'SCHINETEA',
      zip: '163761',
      isUrban: false,
    },
    {
      name: 'VALEA MARE',
      zip: '163770',
      isUrban: false,
    },
    {
      name: 'EPURENI',
      zip: '163798',
      isUrban: false,
    },
    {
      name: 'BARLALESTI',
      zip: '163805',
      isUrban: false,
    },
    {
      name: 'BURSUCI',
      zip: '163814',
      isUrban: false,
    },
    {
      name: 'HORGA',
      zip: '163823',
      isUrban: false,
    },
    {
      name: 'FALCIU',
      zip: '163841',
      isUrban: false,
    },
    {
      name: 'BOGDANESTI',
      zip: '163850',
      isUrban: false,
    },
    {
      name: 'BOZIA',
      zip: '163869',
      isUrban: false,
    },
    {
      name: 'COPACEANA',
      zip: '163878',
      isUrban: false,
    },
    {
      name: 'ODAIA BOGDANA',
      zip: '163887',
      isUrban: false,
    },
    {
      name: 'RANZESTI',
      zip: '163896',
      isUrban: false,
    },
    {
      name: 'GAGESTI',
      zip: '163912',
      isUrban: false,
    },
    {
      name: 'GIURCANI',
      zip: '163921',
      isUrban: false,
    },
    {
      name: 'PEICANI',
      zip: '163930',
      isUrban: false,
    },
    {
      name: 'POPENI',
      zip: '163949',
      isUrban: false,
    },
    {
      name: 'TUPILATI',
      zip: '163958',
      isUrban: false,
    },
    {
      name: 'GARCENI',
      zip: '164071',
      isUrban: false,
    },
    {
      name: 'DUMBRAVENI',
      zip: '164080',
      isUrban: false,
    },
    {
      name: 'RACOVA',
      zip: '164099',
      isUrban: false,
    },
    {
      name: 'RACOVITA',
      zip: '164106',
      isUrban: false,
    },
    {
      name: 'SLOBOZIA',
      zip: '164115',
      isUrban: false,
    },
    {
      name: 'TROHAN',
      zip: '164124',
      isUrban: false,
    },
    {
      name: 'GHERGHESTI',
      zip: '163976',
      isUrban: false,
    },
    {
      name: 'CHETROSU',
      zip: '163985',
      isUrban: false,
    },
    {
      name: 'CORODESTI',
      zip: '163994',
      isUrban: false,
    },
    {
      name: 'DRAGOMANESTI',
      zip: '164008',
      isUrban: false,
    },
    {
      name: 'DRAXENI',
      zip: '164017',
      isUrban: false,
    },
    {
      name: 'BLESCA',
      zip: '164428',
      isUrban: false,
    },
    {
      name: 'BROSTENI',
      zip: '164437',
      isUrban: false,
    },
    {
      name: 'BUSCATA',
      zip: '164446',
      isUrban: false,
    },
    {
      name: 'COSCA',
      zip: '164455',
      isUrban: false,
    },
    {
      name: 'COSESTI',
      zip: '164464',
      isUrban: false,
    },
    {
      name: 'FUNDATURA MARE',
      zip: '164473',
      isUrban: false,
    },
    {
      name: 'FUNDATURA MICA',
      zip: '164482',
      isUrban: false,
    },
    {
      name: 'HARSOVENI',
      zip: '164491',
      isUrban: false,
    },
    {
      name: 'IEZEREL',
      zip: '164507',
      isUrban: false,
    },
    {
      name: 'URSOAIA',
      zip: '164516',
      isUrban: false,
    },
    {
      name: 'VALEA MARE',
      zip: '164525',
      isUrban: false,
    },
    {
      name: 'VALEA OANEI',
      zip: '164534',
      isUrban: false,
    },
    {
      name: 'IVESTI',
      zip: '164552',
      isUrban: false,
    },
    {
      name: 'BELCESTI',
      zip: '164561',
      isUrban: false,
    },
    {
      name: 'POGONESTI',
      zip: '164570',
      isUrban: false,
    },
    {
      name: 'POLOCIN',
      zip: '164589',
      isUrban: false,
    },
    {
      name: 'LAZA',
      zip: '164605',
      isUrban: false,
    },
    {
      name: 'BEJENESTI',
      zip: '164614',
      isUrban: false,
    },
    {
      name: 'POIANA LUI ALEXA',
      zip: '164623',
      isUrban: false,
    },
    {
      name: 'PUSCASI',
      zip: '164632',
      isUrban: false,
    },
    {
      name: 'RASNITA',
      zip: '164641',
      isUrban: false,
    },
    {
      name: 'SAUCA',
      zip: '164650',
      isUrban: false,
    },
    {
      name: 'TEISORU',
      zip: '164669',
      isUrban: false,
    },
    {
      name: 'VALEA TARGULUI',
      zip: '164678',
      isUrban: false,
    },
    {
      name: 'LIPOVAT',
      zip: '164696',
      isUrban: false,
    },
    {
      name: 'CAPUSNENI',
      zip: '164703',
      isUrban: false,
    },
    {
      name: 'CHITOC',
      zip: '164712',
      isUrban: false,
    },
    {
      name: 'CORBU',
      zip: '164721',
      isUrban: false,
    },
    {
      name: 'FUNDU VAII',
      zip: '164730',
      isUrban: false,
    },
    {
      name: 'LUNCA BANULUI',
      zip: '164758',
      isUrban: false,
    },
    {
      name: 'BROSCOSESTI',
      zip: '164767',
      isUrban: false,
    },
    {
      name: 'CONDREA',
      zip: '164776',
      isUrban: false,
    },
    {
      name: 'FOCSA',
      zip: '164785',
      isUrban: false,
    },
    {
      name: 'LUNCA VECHE',
      zip: '164794',
      isUrban: false,
    },
    {
      name: 'OTETOAIA',
      zip: '164801',
      isUrban: false,
    },
    {
      name: 'RADUCANI',
      zip: '164810',
      isUrban: false,
    },
    {
      name: 'MALUSTENI',
      zip: '164838',
      isUrban: false,
    },
    {
      name: 'GHIREASCA',
      zip: '164847',
      isUrban: false,
    },
    {
      name: 'LUPESTI',
      zip: '164856',
      isUrban: false,
    },
    {
      name: 'MANASTIREA',
      zip: '164865',
      isUrban: false,
    },
    {
      name: 'MANZATESTI',
      zip: '164874',
      isUrban: false,
    },
    {
      name: 'TUTCANI',
      zip: '164883',
      isUrban: false,
    },
    {
      name: 'MICLESTI',
      zip: '164909',
      isUrban: false,
    },
    {
      name: 'CHIRCESTI',
      zip: '164918',
      isUrban: false,
    },
    {
      name: 'POPESTI',
      zip: '164927',
      isUrban: false,
    },
    {
      name: 'MUNTENII DE JOS',
      zip: '164945',
      isUrban: false,
    },
    {
      name: 'BACAOANI',
      zip: '164954',
      isUrban: false,
    },
    {
      name: 'MANJESTI',
      zip: '164963',
      isUrban: false,
    },
    {
      name: 'SECUIA',
      zip: '164972',
      isUrban: false,
    },
    {
      name: 'MURGENI',
      zip: '164990',
      isUrban: true,
    },
    {
      name: 'CARJA',
      zip: '165005',
      isUrban: true,
    },
    {
      name: 'FLORENI',
      zip: '165050',
      isUrban: true,
    },
    {
      name: 'LATESTI',
      zip: '165014',
      isUrban: true,
    },
    {
      name: 'RAIU',
      zip: '165041',
      isUrban: true,
    },
    {
      name: 'SARATENI',
      zip: '165023',
      isUrban: true,
    },
    {
      name: 'SCHINENI',
      zip: '165032',
      isUrban: true,
    },
    {
      name: 'OLTENESTI',
      zip: '165078',
      isUrban: false,
    },
    {
      name: 'CURTENI',
      zip: '165087',
      isUrban: false,
    },
    {
      name: 'PIHNA',
      zip: '165096',
      isUrban: false,
    },
    {
      name: 'TARZII',
      zip: '165103',
      isUrban: false,
    },
    {
      name: 'VINETESTI',
      zip: '165112',
      isUrban: false,
    },
    {
      name: 'ZGURA',
      zip: '165121',
      isUrban: false,
    },
    {
      name: 'OSESTI',
      zip: '165149',
      isUrban: false,
    },
    {
      name: 'BUDA',
      zip: '165158',
      isUrban: false,
    },
    {
      name: 'PADURENI',
      zip: '165167',
      isUrban: false,
    },
    {
      name: 'VALCELE',
      zip: '165176',
      isUrban: false,
    },
    {
      name: 'PADURENI',
      zip: '165194',
      isUrban: false,
    },
    {
      name: 'CAPOTESTI',
      zip: '165201',
      isUrban: false,
    },
    {
      name: 'DAVIDESTI',
      zip: '165210',
      isUrban: false,
    },
    {
      name: 'IVANESTI',
      zip: '165229',
      isUrban: false,
    },
    {
      name: 'LEOSTI',
      zip: '165238',
      isUrban: false,
    },
    {
      name: 'RUSCA',
      zip: '165247',
      isUrban: false,
    },
    {
      name: 'TODIRENI',
      zip: '165256',
      isUrban: false,
    },
    {
      name: 'VALENI',
      zip: '165265',
      isUrban: false,
    },
    {
      name: 'PERIENI',
      zip: '165283',
      isUrban: false,
    },
    {
      name: 'CIOCANI',
      zip: '165292',
      isUrban: false,
    },
    {
      name: 'CRANG',
      zip: '165309',
      isUrban: false,
    },
    {
      name: 'CRANGU NOU',
      zip: '165318',
      isUrban: false,
    },
    {
      name: 'PODU PETRIS',
      zip: '165327',
      isUrban: false,
    },
    {
      name: 'POGANA',
      zip: '165425',
      isUrban: false,
    },
    {
      name: 'BOGESTI',
      zip: '165434',
      isUrban: false,
    },
    {
      name: 'CARJOANI',
      zip: '165443',
      isUrban: false,
    },
    {
      name: 'MASCUREI',
      zip: '165452',
      isUrban: false,
    },
    {
      name: 'TOMESTI',
      zip: '165461',
      isUrban: false,
    },
    {
      name: 'POIENESTI',
      zip: '165345',
      isUrban: false,
    },
    {
      name: 'DEALU SECARII',
      zip: '165354',
      isUrban: false,
    },
    {
      name: 'FLORESTI',
      zip: '165363',
      isUrban: false,
    },
    {
      name: 'FRASINU',
      zip: '165372',
      isUrban: false,
    },
    {
      name: 'FUNDU VAII',
      zip: '165381',
      isUrban: false,
    },
    {
      name: 'OPRISITA',
      zip: '165390',
      isUrban: false,
    },
    {
      name: 'POIENESTI-DEAL',
      zip: '165407',
      isUrban: false,
    },
    {
      name: 'PUIESTI',
      zip: '165489',
      isUrban: false,
    },
    {
      name: 'BARTALUS-MOCANI',
      zip: '165498',
      isUrban: false,
    },
    {
      name: 'BARTALUS-RAZESI',
      zip: '165504',
      isUrban: false,
    },
    {
      name: 'CALIMANESTI',
      zip: '165513',
      isUrban: false,
    },
    {
      name: 'CETATUIA',
      zip: '165522',
      isUrban: false,
    },
    {
      name: 'CRISTESTI',
      zip: '165531',
      isUrban: false,
    },
    {
      name: 'FANTANELE',
      zip: '165540',
      isUrban: false,
    },
    {
      name: 'FULGU',
      zip: '165559',
      isUrban: false,
    },
    {
      name: 'GALTESTI',
      zip: '165568',
      isUrban: false,
    },
    {
      name: 'IEZER',
      zip: '165577',
      isUrban: false,
    },
    {
      name: 'LALESTI',
      zip: '165586',
      isUrban: false,
    },
    {
      name: 'ROTARI',
      zip: '165595',
      isUrban: false,
    },
    {
      name: 'RUSI',
      zip: '165602',
      isUrban: false,
    },
    {
      name: 'PUNGESTI',
      zip: '165620',
      isUrban: false,
    },
    {
      name: 'ARMASOAIA',
      zip: '165639',
      isUrban: false,
    },
    {
      name: 'CURSESTI-DEAL',
      zip: '165648',
      isUrban: false,
    },
    {
      name: 'CURSESTI-VALE',
      zip: '165657',
      isUrban: false,
    },
    {
      name: 'HORDILA',
      zip: '165666',
      isUrban: false,
    },
    {
      name: 'RAPSA',
      zip: '165675',
      isUrban: false,
    },
    {
      name: 'SILISTEA',
      zip: '165684',
      isUrban: false,
    },
    {
      name: 'STEJARU',
      zip: '165693',
      isUrban: false,
    },
    {
      name: 'TOPORASTI',
      zip: '165700',
      isUrban: false,
    },
    {
      name: 'REBRICEA',
      zip: '165728',
      isUrban: false,
    },
    {
      name: 'BOLATI',
      zip: '165737',
      isUrban: false,
    },
    {
      name: 'CRACIUNESTI',
      zip: '165746',
      isUrban: false,
    },
    {
      name: 'DRAXENI',
      zip: '165755',
      isUrban: false,
    },
    {
      name: 'MACRESTI',
      zip: '165764',
      isUrban: false,
    },
    {
      name: 'RATESU CUZEI',
      zip: '165773',
      isUrban: false,
    },
    {
      name: 'SASOVA',
      zip: '165782',
      isUrban: false,
    },
    {
      name: 'TATOMIRESTI',
      zip: '165791',
      isUrban: false,
    },
    {
      name: 'TUFESTII DE JOS',
      zip: '165808',
      isUrban: false,
    },
    {
      name: 'ROSIESTI',
      zip: '165826',
      isUrban: false,
    },
    {
      name: 'CODRENI',
      zip: '165835',
      isUrban: false,
    },
    {
      name: 'GARA ROSIESTI',
      zip: '165844',
      isUrban: false,
    },
    {
      name: 'GURA IDRICI',
      zip: '165853',
      isUrban: false,
    },
    {
      name: 'IDRICI',
      zip: '165862',
      isUrban: false,
    },
    {
      name: 'REDIU',
      zip: '165871',
      isUrban: false,
    },
    {
      name: 'VALEA LUI DARIE',
      zip: '165880',
      isUrban: false,
    },
    {
      name: 'SOLESTI',
      zip: '165906',
      isUrban: false,
    },
    {
      name: 'BOUSORI',
      zip: '165915',
      isUrban: false,
    },
    {
      name: 'IAZ',
      zip: '165924',
      isUrban: false,
    },
    {
      name: 'SATU NOU',
      zip: '165933',
      isUrban: false,
    },
    {
      name: 'SERBOTESTI',
      zip: '165942',
      isUrban: false,
    },
    {
      name: 'STIOBORANI',
      zip: '165951',
      isUrban: false,
    },
    {
      name: 'VALEA SILISTEI',
      zip: '165960',
      isUrban: false,
    },
    {
      name: 'STANILESTI',
      zip: '165988',
      isUrban: false,
    },
    {
      name: 'BOGDANA-VOLOSENI',
      zip: '165997',
      isUrban: false,
    },
    {
      name: 'BUDU CANTEMIR',
      zip: '166002',
      isUrban: false,
    },
    {
      name: 'CHERSACOSU',
      zip: '166011',
      isUrban: false,
    },
    {
      name: 'GURA VAII',
      zip: '166020',
      isUrban: false,
    },
    {
      name: 'POGANESTI',
      zip: '166039',
      isUrban: false,
    },
    {
      name: 'SARATU',
      zip: '166048',
      isUrban: false,
    },
    {
      name: 'STEFAN CEL MARE',
      zip: '166066',
      isUrban: false,
    },
    {
      name: 'BARZESTI',
      zip: '166075',
      isUrban: false,
    },
    {
      name: 'BRAHASOAIA',
      zip: '166084',
      isUrban: false,
    },
    {
      name: 'CALUGARENI',
      zip: '166093',
      isUrban: false,
    },
    {
      name: 'CANTALARESTI',
      zip: '166100',
      isUrban: false,
    },
    {
      name: 'MARASENI',
      zip: '166119',
      isUrban: false,
    },
    {
      name: 'MUNTENESTI',
      zip: '166128',
      isUrban: false,
    },
    {
      name: 'SULETEA',
      zip: '166146',
      isUrban: false,
    },
    {
      name: 'FEDESTI',
      zip: '166155',
      isUrban: false,
    },
    {
      name: 'JIGALIA',
      zip: '166164',
      isUrban: false,
    },
    {
      name: 'RASCANI',
      zip: '166173',
      isUrban: false,
    },
    {
      name: 'TACUTA',
      zip: '166244',
      isUrban: false,
    },
    {
      name: 'CUJBA',
      zip: '166253',
      isUrban: false,
    },
    {
      name: 'DUMASCA',
      zip: '166262',
      isUrban: false,
    },
    {
      name: 'FOCSEASCA',
      zip: '166271',
      isUrban: false,
    },
    {
      name: 'MIRCESTI',
      zip: '166280',
      isUrban: false,
    },
    {
      name: 'PROTOPOPESTI',
      zip: '166299',
      isUrban: false,
    },
    {
      name: 'SOFIENI',
      zip: '166306',
      isUrban: false,
    },
    {
      name: 'TANACU',
      zip: '166191',
      isUrban: false,
    },
    {
      name: 'BENESTI',
      zip: '166208',
      isUrban: false,
    },
    {
      name: 'MUNTENII DE SUS',
      zip: '166217',
      isUrban: false,
    },
    {
      name: 'SATU NOU',
      zip: '166226',
      isUrban: false,
    },
    {
      name: 'TATARANI',
      zip: '166324',
      isUrban: false,
    },
    {
      name: 'BALTATI',
      zip: '166333',
      isUrban: false,
    },
    {
      name: 'CRASNASENI',
      zip: '166342',
      isUrban: false,
    },
    {
      name: 'GIURGESTI',
      zip: '166351',
      isUrban: false,
    },
    {
      name: 'LEOSTI',
      zip: '166360',
      isUrban: false,
    },
    {
      name: 'MANTU',
      zip: '166379',
      isUrban: false,
    },
    {
      name: 'STROIESTI',
      zip: '166388',
      isUrban: false,
    },
    {
      name: 'VALEA LUI BOSIE',
      zip: '166397',
      isUrban: false,
    },
    {
      name: 'VALEA SEACA',
      zip: '166404',
      isUrban: false,
    },
    {
      name: 'TODIRESTI',
      zip: '166422',
      isUrban: false,
    },
    {
      name: 'COTIC',
      zip: '166431',
      isUrban: false,
    },
    {
      name: 'DRAGESTI',
      zip: '166440',
      isUrban: false,
    },
    {
      name: 'HUC',
      zip: '166459',
      isUrban: false,
    },
    {
      name: 'PLOPOASA',
      zip: '166468',
      isUrban: false,
    },
    {
      name: 'RAFAILA',
      zip: '166477',
      isUrban: false,
    },
    {
      name: 'SILISTEA',
      zip: '166486',
      isUrban: false,
    },
    {
      name: 'SOFRONESTI',
      zip: '166495',
      isUrban: false,
    },
    {
      name: 'VALEA POPII',
      zip: '166501',
      isUrban: false,
    },
    {
      name: 'VIISOARA',
      zip: '166510',
      isUrban: false,
    },
    {
      name: 'TUTOVA',
      zip: '166538',
      isUrban: false,
    },
    {
      name: 'BADEANA',
      zip: '166547',
      isUrban: false,
    },
    {
      name: 'BORODESTI',
      zip: '166556',
      isUrban: false,
    },
    {
      name: 'CIORTOLOM',
      zip: '166565',
      isUrban: false,
    },
    {
      name: 'COROIU',
      zip: '166574',
      isUrban: false,
    },
    {
      name: 'CRIVESTI',
      zip: '166583',
      isUrban: false,
    },
    {
      name: 'POCHIDIA',
      zip: '166592',
      isUrban: false,
    },
    {
      name: 'SALCENI',
      zip: '166618',
      isUrban: false,
    },
    {
      name: 'SATU NOU',
      zip: '166609',
      isUrban: false,
    },
    {
      name: 'VIZURENI',
      zip: '166627',
      isUrban: false,
    },
    {
      name: 'VALENI',
      zip: '166645',
      isUrban: false,
    },
    {
      name: 'FERESTI',
      zip: '166654',
      isUrban: false,
    },
    {
      name: 'MOARA DOMNEASCA',
      zip: '166663',
      isUrban: false,
    },
    {
      name: 'VETRISOAIA',
      zip: '166681',
      isUrban: false,
    },
    {
      name: 'BUMBATA',
      zip: '166690',
      isUrban: false,
    },
    {
      name: 'VIISOARA',
      zip: '166716',
      isUrban: false,
    },
    {
      name: 'DODESTI',
      zip: '166725',
      isUrban: false,
    },
    {
      name: 'HALTA DODESTI',
      zip: '166734',
      isUrban: false,
    },
    {
      name: 'URDESTI',
      zip: '166743',
      isUrban: false,
    },
    {
      name: 'VALENI',
      zip: '166752',
      isUrban: false,
    },
    {
      name: 'VILTOTESTI',
      zip: '166761',
      isUrban: false,
    },
    {
      name: 'VINDEREI',
      zip: '166789',
      isUrban: false,
    },
    {
      name: 'BRADESTI',
      zip: '166798',
      isUrban: false,
    },
    {
      name: 'DOCANEASA',
      zip: '166814',
      isUrban: false,
    },
    {
      name: 'DOCANI',
      zip: '166805',
      isUrban: false,
    },
    {
      name: 'GARA DOCANEASA',
      zip: '166823',
      isUrban: false,
    },
    {
      name: 'GARA TALASMAN',
      zip: '166832',
      isUrban: false,
    },
    {
      name: 'OBARSENI',
      zip: '166841',
      isUrban: false,
    },
    {
      name: 'VALEA LUNGA',
      zip: '166850',
      isUrban: false,
    },
    {
      name: 'VOINESTI',
      zip: '166878',
      isUrban: false,
    },
    {
      name: 'AVRAMESTI',
      zip: '166887',
      isUrban: false,
    },
    {
      name: 'BANCESTI',
      zip: '166896',
      isUrban: false,
    },
    {
      name: 'COROBANESTI',
      zip: '166903',
      isUrban: false,
    },
    {
      name: 'GARDESTI',
      zip: '166912',
      isUrban: false,
    },
    {
      name: 'MARASESTI',
      zip: '166921',
      isUrban: false,
    },
    {
      name: 'OBARSENI',
      zip: '166930',
      isUrban: false,
    },
    {
      name: 'OBARSENII LINGURARI',
      zip: '166949',
      isUrban: false,
    },
    {
      name: 'RUGARIA',
      zip: '166958',
      isUrban: false,
    },
    {
      name: 'STANCASENI',
      zip: '166967',
      isUrban: false,
    },
    {
      name: 'URICARI',
      zip: '166976',
      isUrban: false,
    },
    {
      name: 'VULTURESTI',
      zip: '166994',
      isUrban: false,
    },
    {
      name: 'BUHAIESTI',
      zip: '167008',
      isUrban: false,
    },
    {
      name: 'PODENI',
      zip: '167017',
      isUrban: false,
    },
    {
      name: 'VOINESTI',
      zip: '167026',
      isUrban: false,
    },
    {
      name: 'VUTCANI',
      zip: '167044',
      isUrban: false,
    },
    {
      name: 'MALAIESTI',
      zip: '167053',
      isUrban: false,
    },
    {
      name: 'POSTA ELAN',
      zip: '167062',
      isUrban: false,
    },
    {
      name: 'ZAPODENI',
      zip: '167080',
      isUrban: false,
    },
    {
      name: 'BUTUCARIA',
      zip: '167099',
      isUrban: false,
    },
    {
      name: 'LAZU',
      zip: '164026',
      isUrban: false,
    },
    {
      name: 'LUNCA',
      zip: '164035',
      isUrban: false,
    },
    {
      name: 'SOCI',
      zip: '164044',
      isUrban: false,
    },
    {
      name: 'VALEA LUPULUI',
      zip: '164053',
      isUrban: false,
    },
    {
      name: 'GRIVITA',
      zip: '164142',
      isUrban: false,
    },
    {
      name: 'FRUNTISENI',
      zip: '164151',
      isUrban: false,
    },
    {
      name: 'GRAJDENI',
      zip: '164160',
      isUrban: false,
    },
    {
      name: 'ODAIA BURSUCANI',
      zip: '164179',
      isUrban: false,
    },
    {
      name: 'TRESTIANA',
      zip: '164188',
      isUrban: false,
    },
    {
      name: 'HOCENI',
      zip: '164204',
      isUrban: false,
    },
    {
      name: 'BARBOSI',
      zip: '164213',
      isUrban: false,
    },
    {
      name: 'DELENI',
      zip: '164222',
      isUrban: false,
    },
    {
      name: 'OTELENI',
      zip: '164231',
      isUrban: false,
    },
    {
      name: 'REDIU',
      zip: '164240',
      isUrban: false,
    },
    {
      name: 'SISCANI',
      zip: '164259',
      isUrban: false,
    },
    {
      name: 'TOMSA',
      zip: '164268',
      isUrban: false,
    },
    {
      name: 'IANA',
      zip: '164348',
      isUrban: false,
    },
    {
      name: 'HALARESTI',
      zip: '164357',
      isUrban: false,
    },
    {
      name: 'RECEA',
      zip: '164366',
      isUrban: false,
    },
    {
      name: 'SILISTEA',
      zip: '164375',
      isUrban: false,
    },
    {
      name: 'VADURILE',
      zip: '164384',
      isUrban: false,
    },
    {
      name: 'IVANESTI',
      zip: '164400',
      isUrban: false,
    },
    {
      name: 'ALBINA',
      zip: '164419',
      isUrban: false,
    },
    {
      name: 'CIOFENI',
      zip: '167106',
      isUrban: false,
    },
    {
      name: 'DELEA',
      zip: '167115',
      isUrban: false,
    },
    {
      name: 'DOBROSLOVESTI',
      zip: '167124',
      isUrban: false,
    },
    {
      name: 'MACRESTI',
      zip: '167133',
      isUrban: false,
    },
    {
      name: 'PORTARI',
      zip: '167142',
      isUrban: false,
    },
    {
      name: 'TELEJNA',
      zip: '167151',
      isUrban: false,
    },
    {
      name: 'UNCESTI',
      zip: '167160',
      isUrban: false,
    },
    {
      name: 'ZORLENI',
      zip: '167188',
      isUrban: false,
    },
    {
      name: 'DEALU MARE',
      zip: '167197',
      isUrban: false,
    },
    {
      name: 'POPENI',
      zip: '167204',
      isUrban: false,
    },
    {
      name: 'SIMILA',
      zip: '167213',
      isUrban: false,
    }
  ],
  VL: [
    {
      name: 'RAMNICU VALCEA',
      zip: '167482',
      isUrban: true,
    },
    {
      name: 'ARANGHEL',
      zip: '167491',
      isUrban: true,
    },
    {
      name: 'CAZANESTI',
      zip: '167507',
      isUrban: true,
    },
    {
      name: 'COPACELU',
      zip: '167516',
      isUrban: true,
    },
    {
      name: 'DEALU MALULUI',
      zip: '167525',
      isUrban: true,
    },
    {
      name: 'FETENI',
      zip: '167605',
      isUrban: true,
    },
    {
      name: 'GORANU',
      zip: '167598',
      isUrban: true,
    },
    {
      name: 'LESPEZI',
      zip: '167614',
      isUrban: true,
    },
    {
      name: 'POENARI',
      zip: '167534',
      isUrban: true,
    },
    {
      name: 'PRIBA',
      zip: '167543',
      isUrban: true,
    },
    {
      name: 'RAURENI',
      zip: '167552',
      isUrban: true,
    },
    {
      name: 'SALISTEA',
      zip: '167623',
      isUrban: true,
    },
    {
      name: 'STOLNICENI',
      zip: '167561',
      isUrban: true,
    },
    {
      name: 'TROIAN',
      zip: '167570',
      isUrban: true,
    },
    {
      name: 'DRAGASANI',
      zip: '167990',
      isUrban: true,
    },
    {
      name: 'GRADISTEA',
      zip: '170355',
      isUrban: false,
    },
    {
      name: 'DIACONESTI',
      zip: '170364',
      isUrban: false,
    },
    {
      name: 'DOBRICEA',
      zip: '170373',
      isUrban: false,
    },
    {
      name: 'LINIA',
      zip: '170382',
      isUrban: false,
    },
    {
      name: 'OBISLAVU',
      zip: '170391',
      isUrban: false,
    },
    {
      name: 'STRACHINESTI',
      zip: '170408',
      isUrban: false,
    },
    {
      name: 'TURBUREA',
      zip: '170417',
      isUrban: false,
    },
    {
      name: 'TUTURU',
      zip: '170426',
      isUrban: false,
    },
    {
      name: 'VALEA GRADISTEI',
      zip: '170435',
      isUrban: false,
    },
    {
      name: 'GUSOENI',
      zip: '170453',
      isUrban: false,
    },
    {
      name: 'BURDALESTI',
      zip: '170462',
      isUrban: false,
    },
    {
      name: 'DEALU MARE',
      zip: '170471',
      isUrban: false,
    },
    {
      name: 'GUSOIANCA',
      zip: '170480',
      isUrban: false,
    },
    {
      name: 'MAGURENI',
      zip: '170499',
      isUrban: false,
    },
    {
      name: 'SPARLENI',
      zip: '170505',
      isUrban: false,
    },
    {
      name: 'IONESTI',
      zip: '170523',
      isUrban: false,
    },
    {
      name: 'BUCSANI',
      zip: '170532',
      isUrban: false,
    },
    {
      name: 'DEALU MARE',
      zip: '170541',
      isUrban: false,
    },
    {
      name: 'DELURENI',
      zip: '170550',
      isUrban: false,
    },
    {
      name: 'FISCALIA',
      zip: '170569',
      isUrban: false,
    },
    {
      name: 'FOTESTI',
      zip: '170578',
      isUrban: false,
    },
    {
      name: 'VALEA LUNGA',
      zip: '173212',
      isUrban: false,
    },
    {
      name: 'VARLENI',
      zip: '173221',
      isUrban: false,
    },
    {
      name: 'STEFANESTI',
      zip: '173757',
      isUrban: false,
    },
    {
      name: 'CONDOIESTI',
      zip: '173766',
      isUrban: false,
    },
    {
      name: 'DOBRUSA',
      zip: '173775',
      isUrban: false,
    },
    {
      name: 'SERBANESTI',
      zip: '173784',
      isUrban: false,
    },
    {
      name: 'STOENESTI',
      zip: '173249',
      isUrban: false,
    },
    {
      name: 'BARLOGU',
      zip: '173258',
      isUrban: false,
    },
    {
      name: 'BUDURASTI',
      zip: '173267',
      isUrban: false,
    },
    {
      name: 'DELENI',
      zip: '173276',
      isUrban: false,
    },
    {
      name: 'DOBRICENI',
      zip: '173285',
      isUrban: false,
    },
    {
      name: 'GRUIERI',
      zip: '173301',
      isUrban: false,
    },
    {
      name: 'GRUIU',
      zip: '173294',
      isUrban: false,
    },
    {
      name: 'MOGOSESTI',
      zip: '173310',
      isUrban: false,
    },
    {
      name: 'NEGHINESTI',
      zip: '173329',
      isUrban: false,
    },
    {
      name: 'PISCU MARE',
      zip: '173338',
      isUrban: false,
    },
    {
      name: 'POPESTI',
      zip: '173347',
      isUrban: false,
    },
    {
      name: 'SUSENI',
      zip: '173356',
      isUrban: false,
    },
    {
      name: 'VALEA CASELOR',
      zip: '168005',
      isUrban: true,
    },
    {
      name: 'ZARNENI',
      zip: '168014',
      isUrban: true,
    },
    {
      name: 'ZLATAREI',
      zip: '168023',
      isUrban: true,
    },
    {
      name: 'PRAJILA',
      zip: '167678',
      isUrban: true,
    },
    {
      name: 'CURATURILE',
      zip: '167650',
      isUrban: true,
    },
    {
      name: 'GATEJESTI',
      zip: '167669',
      isUrban: true,
    },
    {
      name: 'LIVADIA',
      zip: '167730',
      isUrban: true,
    },
    {
      name: 'CHEIA',
      zip: '167703',
      isUrban: true,
    },
    {
      name: 'COMANCA',
      zip: '167712',
      isUrban: true,
    },
    {
      name: 'GURGUIATA',
      zip: '167721',
      isUrban: true,
    },
    {
      name: 'MOSOROASA',
      zip: '167749',
      isUrban: true,
    },
    {
      name: 'OLANESTI',
      zip: '167758',
      isUrban: true,
    },
    {
      name: 'PIETRISU',
      zip: '167767',
      isUrban: true,
    },
    {
      name: 'TISA',
      zip: '167776',
      isUrban: true,
    },
    {
      name: 'BREZOI',
      zip: '167801',
      isUrban: true,
    },
    {
      name: 'CALINESTI',
      zip: '167810',
      isUrban: true,
    },
    {
      name: 'CORBU',
      zip: '167829',
      isUrban: true,
    },
    {
      name: 'DRAGANESTI',
      zip: '167838',
      isUrban: true,
    },
    {
      name: 'GOLOTRENI',
      zip: '167847',
      isUrban: true,
    },
    {
      name: 'PASCOAIA',
      zip: '167856',
      isUrban: true,
    },
    {
      name: 'PROIENI',
      zip: '167865',
      isUrban: true,
    },
    {
      name: 'VALEA LUI STAN',
      zip: '167874',
      isUrban: true,
    },
    {
      name: 'VARATICA',
      zip: '167883',
      isUrban: true,
    },
    {
      name: 'CALIMANESTI',
      zip: '167918',
      isUrban: true,
    },
    {
      name: 'CACIULATA',
      zip: '167927',
      isUrban: true,
    },
    {
      name: 'JIBLEA NOUA',
      zip: '167936',
      isUrban: true,
    },
    {
      name: 'JIBLEA VECHE',
      zip: '167945',
      isUrban: true,
    },
    {
      name: 'PAUSA',
      zip: '167954',
      isUrban: true,
    },
    {
      name: 'SEACA',
      zip: '167963',
      isUrban: true,
    },
    {
      name: 'HOREZU',
      zip: '168050',
      isUrban: true,
    },
    {
      name: 'IFRIMESTI',
      zip: '168069',
      isUrban: true,
    },
    {
      name: 'RAMESTI',
      zip: '168078',
      isUrban: true,
    },
    {
      name: 'ROMANII DE JOS',
      zip: '168087',
      isUrban: true,
    },
    {
      name: 'ROMANII DE SUS',
      zip: '168096',
      isUrban: true,
    },
    {
      name: 'TANASESTI',
      zip: '168103',
      isUrban: true,
    },
    {
      name: 'URSANI',
      zip: '168112',
      isUrban: true,
    },
    {
      name: 'GURA SUHASULUI',
      zip: '168176',
      isUrban: true,
    },
    {
      name: 'BUDA',
      zip: '168149',
      isUrban: true,
    },
    {
      name: 'COSOTA',
      zip: '168158',
      isUrban: true,
    },
    {
      name: 'FACAI',
      zip: '168167',
      isUrban: true,
    },
    {
      name: 'LUNCA',
      zip: '168185',
      isUrban: true,
    },
    {
      name: 'OCNITA',
      zip: '168194',
      isUrban: true,
    },
    {
      name: 'SLATIOARELE',
      zip: '168201',
      isUrban: true,
    },
    {
      name: 'TEICA',
      zip: '168210',
      isUrban: true,
    },
    {
      name: 'ALUNU',
      zip: '168238',
      isUrban: false,
    },
    {
      name: 'BODESTI',
      zip: '168247',
      isUrban: false,
    },
    {
      name: 'COLTESTI',
      zip: '168256',
      isUrban: false,
    },
    {
      name: 'IGOIU',
      zip: '168265',
      isUrban: false,
    },
    {
      name: 'ILACIU',
      zip: '168274',
      isUrban: false,
    },
    {
      name: 'OCRACU',
      zip: '168283',
      isUrban: false,
    },
    {
      name: 'ROSIA',
      zip: '168292',
      isUrban: false,
    },
    {
      name: 'AMARASTI',
      zip: '168318',
      isUrban: false,
    },
    {
      name: 'MERESESTI',
      zip: '168327',
      isUrban: false,
    },
    {
      name: 'NEMOIU',
      zip: '168336',
      isUrban: false,
    },
    {
      name: 'PADINA',
      zip: '168345',
      isUrban: false,
    },
    {
      name: 'PALANGA',
      zip: '168354',
      isUrban: false,
    },
    {
      name: 'TEIUL',
      zip: '168363',
      isUrban: false,
    },
    {
      name: 'BABENI',
      zip: '168381',
      isUrban: true,
    },
    {
      name: 'BONCIU',
      zip: '168390',
      isUrban: true,
    },
    {
      name: 'CAPU DEALULUI',
      zip: '168407',
      isUrban: true,
    },
    {
      name: 'PADURETU',
      zip: '168416',
      isUrban: true,
    },
    {
      name: 'ROMANI',
      zip: '168425',
      isUrban: true,
    },
    {
      name: 'TATARANI',
      zip: '168434',
      isUrban: true,
    },
    {
      name: 'VALEA MARE',
      zip: '168443',
      isUrban: true,
    },
    {
      name: 'BALCESTI',
      zip: '168461',
      isUrban: true,
    },
    {
      name: 'BENESTI',
      zip: '168470',
      isUrban: true,
    },
    {
      name: 'CARLOGANI',
      zip: '168498',
      isUrban: true,
    },
    {
      name: 'CHIRCULESTI',
      zip: '168489',
      isUrban: true,
    },
    {
      name: 'GORUNESTI',
      zip: '168504',
      isUrban: true,
    },
    {
      name: 'IRIMESTI',
      zip: '168513',
      isUrban: true,
    },
    {
      name: 'OTETELISU',
      zip: '168522',
      isUrban: true,
    },
    {
      name: 'PREOTESTI',
      zip: '168531',
      isUrban: true,
    },
    {
      name: 'SATU POIENI',
      zip: '168540',
      isUrban: true,
    },
    {
      name: 'BODESTI',
      zip: '168568',
      isUrban: false,
    },
    {
      name: 'BARBATESTI',
      zip: '168577',
      isUrban: false,
    },
    {
      name: 'BARZESTI',
      zip: '168586',
      isUrban: false,
    },
    {
      name: 'NEGRULESTI',
      zip: '168595',
      isUrban: false,
    },
    {
      name: 'BERBESTI',
      zip: '168611',
      isUrban: true,
    },
    {
      name: 'DAMTENI',
      zip: '168620',
      isUrban: true,
    },
    {
      name: 'DEALU ALUNIS',
      zip: '168639',
      isUrban: true,
    },
    {
      name: 'ROSIOARA',
      zip: '168648',
      isUrban: true,
    },
    {
      name: 'TARGU GANGULESTI',
      zip: '168657',
      isUrban: true,
    },
    {
      name: 'VALEA MARE',
      zip: '168666',
      isUrban: true,
    },
    {
      name: 'STOENESTI',
      zip: '168684',
      isUrban: false,
    },
    {
      name: 'BERISLAVESTI',
      zip: '168693',
      isUrban: false,
    },
    {
      name: 'BRADISOR',
      zip: '168700',
      isUrban: false,
    },
    {
      name: 'DANGESTI',
      zip: '168719',
      isUrban: false,
    },
    {
      name: 'RADACINESTI',
      zip: '168728',
      isUrban: false,
    },
    {
      name: 'ROBAIA',
      zip: '168737',
      isUrban: false,
    },
    {
      name: 'SCAUENI',
      zip: '168746',
      isUrban: false,
    },
    {
      name: 'BOISOARA',
      zip: '168764',
      isUrban: false,
    },
    {
      name: 'BUMBUESTI',
      zip: '168773',
      isUrban: false,
    },
    {
      name: 'GAUJANI',
      zip: '168782',
      isUrban: false,
    },
    {
      name: 'BUDESTI',
      zip: '168808',
      isUrban: false,
    },
    {
      name: 'BARSESTI',
      zip: '168835',
      isUrban: false,
    },
    {
      name: 'BARZA',
      zip: '168817',
      isUrban: false,
    },
    {
      name: 'BERCIOIU',
      zip: '168826',
      isUrban: false,
    },
    {
      name: 'LINIA',
      zip: '168844',
      isUrban: false,
    },
    {
      name: 'PISCU PIETREI',
      zip: '168853',
      isUrban: false,
    },
    {
      name: 'RACOVITA',
      zip: '168862',
      isUrban: false,
    },
    {
      name: 'RUDA',
      zip: '168871',
      isUrban: false,
    },
    {
      name: 'OLTENI',
      zip: '168899',
      isUrban: false,
    },
    {
      name: 'BOGDANESTI',
      zip: '168906',
      isUrban: false,
    },
    {
      name: 'BUJORENI',
      zip: '168915',
      isUrban: false,
    },
    {
      name: 'GURA VAII',
      zip: '168924',
      isUrban: false,
    },
    {
      name: 'LUNCA',
      zip: '168933',
      isUrban: false,
    },
    {
      name: 'MALU ALB',
      zip: '168942',
      isUrban: false,
    },
    {
      name: 'MALU VIRTOP',
      zip: '168951',
      isUrban: false,
    },
    {
      name: 'BUNESTI',
      zip: '168979',
      isUrban: false,
    },
    {
      name: 'COASTA MARE',
      zip: '168988',
      isUrban: false,
    },
    {
      name: 'FIRESTI',
      zip: '168997',
      isUrban: false,
    },
    {
      name: 'RAPANESTI',
      zip: '169002',
      isUrban: false,
    },
    {
      name: 'TEIUSU',
      zip: '169011',
      isUrban: false,
    },
    {
      name: 'TITIRECI',
      zip: '169020',
      isUrban: false,
    },
    {
      name: 'CAINENII MICI',
      zip: '169128',
      isUrban: false,
    },
    {
      name: 'CAINENII MARI',
      zip: '169137',
      isUrban: false,
    },
    {
      name: 'GREBLESTI',
      zip: '169146',
      isUrban: false,
    },
    {
      name: 'PRILOAGE',
      zip: '169155',
      isUrban: false,
    },
    {
      name: 'RAU VADULUI',
      zip: '169164',
      isUrban: false,
    },
    {
      name: 'ROBESTI',
      zip: '169173',
      isUrban: false,
    },
    {
      name: 'ARMASESTI',
      zip: '169048',
      isUrban: false,
    },
    {
      name: 'ARMASESTI',
      zip: '169057',
      isUrban: false,
    },
    {
      name: 'GROSI',
      zip: '169066',
      isUrban: false,
    },
    {
      name: 'MADULARI',
      zip: '169075',
      isUrban: false,
    },
    {
      name: 'MODOIA',
      zip: '169084',
      isUrban: false,
    },
    {
      name: 'OBARSIA',
      zip: '169093',
      isUrban: false,
    },
    {
      name: 'SARSANESTI',
      zip: '169100',
      isUrban: false,
    },
    {
      name: 'ULMETU',
      zip: '169191',
      isUrban: false,
    },
    {
      name: 'BALTENI',
      zip: '169208',
      isUrban: false,
    },
    {
      name: 'BONDOCI',
      zip: '169217',
      isUrban: false,
    },
    {
      name: 'COPACENI',
      zip: '169226',
      isUrban: false,
    },
    {
      name: 'HOTARASA',
      zip: '169235',
      isUrban: false,
    },
    {
      name: 'VETELU',
      zip: '169244',
      isUrban: false,
    },
    {
      name: 'COSTESTI',
      zip: '169262',
      isUrban: false,
    },
    {
      name: 'BISTRITA',
      zip: '169271',
      isUrban: false,
    },
    {
      name: 'PIETRENI',
      zip: '169280',
      isUrban: false,
    },
    {
      name: 'VARATICI',
      zip: '169299',
      isUrban: false,
    },
    {
      name: 'CRETENI',
      zip: '169315',
      isUrban: false,
    },
    {
      name: 'IZVORU',
      zip: '169324',
      isUrban: false,
    },
    {
      name: 'MRENESTI',
      zip: '169333',
      isUrban: false,
    },
    {
      name: 'STREMINOASA',
      zip: '169342',
      isUrban: false,
    },
    {
      name: 'DAESTI',
      zip: '169360',
      isUrban: false,
    },
    {
      name: 'BABUESTI',
      zip: '169379',
      isUrban: false,
    },
    {
      name: 'FEDELESOIU',
      zip: '169388',
      isUrban: false,
    },
    {
      name: 'SANBOTIN',
      zip: '169397',
      isUrban: false,
    },
    {
      name: 'DEALU LAUNELE',
      zip: '169413',
      isUrban: false,
    },
    {
      name: 'BADENI',
      zip: '169422',
      isUrban: false,
    },
    {
      name: 'CERETU',
      zip: '169431',
      isUrban: false,
    },
    {
      name: 'CIRESUL',
      zip: '169440',
      isUrban: false,
    },
    {
      name: 'DEALU SCHEIULUI',
      zip: '169459',
      isUrban: false,
    },
    {
      name: 'DOBRESTI',
      zip: '169468',
      isUrban: false,
    },
    {
      name: 'DRAGULESTI',
      zip: '169477',
      isUrban: false,
    },
    {
      name: 'GLODU',
      zip: '169486',
      isUrban: false,
    },
    {
      name: 'GURA CRUCILOR',
      zip: '169495',
      isUrban: false,
    },
    {
      name: 'LAUNELE DE JOS',
      zip: '169501',
      isUrban: false,
    },
    {
      name: 'LINIA PE VALE',
      zip: '169510',
      isUrban: false,
    },
    {
      name: 'UDRESTI',
      zip: '169529',
      isUrban: false,
    },
    {
      name: 'VALEA SCHEIULUI',
      zip: '169538',
      isUrban: false,
    },
    {
      name: 'DRAGOESTI',
      zip: '169556',
      isUrban: false,
    },
    {
      name: 'BUCIUMENI',
      zip: '169565',
      isUrban: false,
    },
    {
      name: 'GEAMANA',
      zip: '169574',
      isUrban: false,
    },
    {
      name: 'FARTATESTI',
      zip: '169690',
      isUrban: false,
    },
    {
      name: 'AFANATA',
      zip: '169707',
      isUrban: false,
    },
    {
      name: 'BECSANI',
      zip: '169716',
      isUrban: false,
    },
    {
      name: 'CATETU',
      zip: '169725',
      isUrban: false,
    },
    {
      name: 'CUCI',
      zip: '169734',
      isUrban: false,
    },
    {
      name: 'DANCAI',
      zip: '169743',
      isUrban: false,
    },
    {
      name: 'DEJOI',
      zip: '169752',
      isUrban: false,
    },
    {
      name: 'DOZESTI',
      zip: '169761',
      isUrban: false,
    },
    {
      name: 'GARNICET',
      zip: '169798',
      isUrban: false,
    },
    {
      name: 'GIULESTI',
      zip: '169770',
      isUrban: false,
    },
    {
      name: 'GIULESTII DE SUS',
      zip: '169789',
      isUrban: false,
    },
    {
      name: 'MARICESTI',
      zip: '169805',
      isUrban: false,
    },
    {
      name: 'NISIPI',
      zip: '169814',
      isUrban: false,
    },
    {
      name: 'POPESTI',
      zip: '169823',
      isUrban: false,
    },
    {
      name: 'RUSANESTI',
      zip: '169832',
      isUrban: false,
    },
    {
      name: 'SECIU',
      zip: '169841',
      isUrban: false,
    },
    {
      name: 'SOTANI',
      zip: '169869',
      isUrban: false,
    },
    {
      name: 'STANCULESTI',
      zip: '169850',
      isUrban: false,
    },
    {
      name: 'TANISLAVI',
      zip: '169878',
      isUrban: false,
    },
    {
      name: 'VALEA URSULUI',
      zip: '169887',
      isUrban: false,
    },
    {
      name: 'MILESTI',
      zip: '169592',
      isUrban: false,
    },
    {
      name: 'BABENI-OLTETU',
      zip: '169609',
      isUrban: false,
    },
    {
      name: 'BUDESTI',
      zip: '169618',
      isUrban: false,
    },
    {
      name: 'BUNGETANI',
      zip: '169627',
      isUrban: false,
    },
    {
      name: 'COLELIA',
      zip: '169636',
      isUrban: false,
    },
    {
      name: 'DICULESTI',
      zip: '169645',
      isUrban: false,
    },
    {
      name: 'FAURESTI',
      zip: '169654',
      isUrban: false,
    },
    {
      name: 'GAINESTI',
      zip: '169663',
      isUrban: false,
    },
    {
      name: 'MARCUSU',
      zip: '169672',
      isUrban: false,
    },
    {
      name: 'FRANCESTI',
      zip: '169903',
      isUrban: false,
    },
    {
      name: 'BALUTOAIA',
      zip: '169912',
      isUrban: false,
    },
    {
      name: 'COSANI',
      zip: '169921',
      isUrban: false,
    },
    {
      name: 'DEZROBITI',
      zip: '169930',
      isUrban: false,
    },
    {
      name: 'GENUNENI',
      zip: '169949',
      isUrban: false,
    },
    {
      name: 'MANAILESTI',
      zip: '169958',
      isUrban: false,
    },
    {
      name: 'MOSTENI',
      zip: '169967',
      isUrban: false,
    },
    {
      name: 'SURPATELE',
      zip: '169976',
      isUrban: false,
    },
    {
      name: 'VIISOARA',
      zip: '169985',
      isUrban: false,
    },
    {
      name: 'GALICEA',
      zip: '170006',
      isUrban: false,
    },
    {
      name: 'BRATIA DIN DEAL',
      zip: '170015',
      isUrban: false,
    },
    {
      name: 'BRATIA DIN VALE',
      zip: '170024',
      isUrban: false,
    },
    {
      name: 'COCORU',
      zip: '170033',
      isUrban: false,
    },
    {
      name: 'CREMENARI',
      zip: '170042',
      isUrban: false,
    },
    {
      name: 'DEALU MARE',
      zip: '170051',
      isUrban: false,
    },
    {
      name: 'OSTROVENI',
      zip: '170060',
      isUrban: false,
    },
    {
      name: 'TEIU',
      zip: '170079',
      isUrban: false,
    },
    {
      name: 'VALEA RAULUI',
      zip: '170088',
      isUrban: false,
    },
    {
      name: 'GHIOROIU',
      zip: '170104',
      isUrban: false,
    },
    {
      name: 'CAZANESTI',
      zip: '170113',
      isUrban: false,
    },
    {
      name: 'HERASTI',
      zip: '170122',
      isUrban: false,
    },
    {
      name: 'MIEREA',
      zip: '170131',
      isUrban: false,
    },
    {
      name: 'POIENARI',
      zip: '170140',
      isUrban: false,
    },
    {
      name: 'STIRBESTI',
      zip: '170159',
      isUrban: false,
    },
    {
      name: 'GLAVILE',
      zip: '170177',
      isUrban: false,
    },
    {
      name: 'ANINOASA',
      zip: '170186',
      isUrban: false,
    },
    {
      name: 'JAROSTEA',
      zip: '170195',
      isUrban: false,
    },
    {
      name: 'OLTEANCA',
      zip: '170202',
      isUrban: false,
    },
    {
      name: 'VOICULEASA',
      zip: '170211',
      isUrban: false,
    },
    {
      name: 'POPESTI',
      zip: '170239',
      isUrban: false,
    },
    {
      name: 'ALDESTI',
      zip: '170248',
      isUrban: false,
    },
    {
      name: 'BLIDARI',
      zip: '170257',
      isUrban: false,
    },
    {
      name: 'COASTA',
      zip: '170266',
      isUrban: false,
    },
    {
      name: 'DRAGANESTI',
      zip: '170275',
      isUrban: false,
    },
    {
      name: 'GIBESTI',
      zip: '170284',
      isUrban: false,
    },
    {
      name: 'GIURGIUVENI',
      zip: '170293',
      isUrban: false,
    },
    {
      name: 'OPATESTI',
      zip: '170300',
      isUrban: false,
    },
    {
      name: 'POENITA',
      zip: '170319',
      isUrban: false,
    },
    {
      name: 'TULEI-CAMPENI',
      zip: '170328',
      isUrban: false,
    },
    {
      name: 'VATASESTI',
      zip: '170337',
      isUrban: false,
    },
    {
      name: 'GUGUIANCA',
      zip: '170587',
      isUrban: false,
    },
    {
      name: 'MARCEA',
      zip: '170596',
      isUrban: false,
    },
    {
      name: 'PRODANESTI',
      zip: '170603',
      isUrban: false,
    },
    {
      name: 'LADESTI',
      zip: '170694',
      isUrban: false,
    },
    {
      name: 'CERMEGESTI',
      zip: '170701',
      isUrban: false,
    },
    {
      name: 'CHIRICESTI',
      zip: '170710',
      isUrban: false,
    },
    {
      name: 'CIUMAGI',
      zip: '170729',
      isUrban: false,
    },
    {
      name: 'DEALU CORNI',
      zip: '170738',
      isUrban: false,
    },
    {
      name: 'GAGENI',
      zip: '170747',
      isUrban: false,
    },
    {
      name: 'MALDARESTI',
      zip: '170756',
      isUrban: false,
    },
    {
      name: 'OLTEANCA',
      zip: '170765',
      isUrban: false,
    },
    {
      name: 'PASCULESTI',
      zip: '170774',
      isUrban: false,
    },
    {
      name: 'POPESTI',
      zip: '170783',
      isUrban: false,
    },
    {
      name: 'LALOSU',
      zip: '170621',
      isUrban: false,
    },
    {
      name: 'BERBESTI',
      zip: '170630',
      isUrban: false,
    },
    {
      name: 'GHINDARI',
      zip: '170649',
      isUrban: false,
    },
    {
      name: 'MOLOGESTI',
      zip: '170658',
      isUrban: false,
    },
    {
      name: 'OLTETANI',
      zip: '170667',
      isUrban: false,
    },
    {
      name: 'PORTARESTI',
      zip: '170676',
      isUrban: false,
    },
    {
      name: 'SARULESTI',
      zip: '170809',
      isUrban: false,
    },
    {
      name: 'BERESTI',
      zip: '170818',
      isUrban: false,
    },
    {
      name: 'BROSTENI',
      zip: '170827',
      isUrban: false,
    },
    {
      name: 'MIJATI',
      zip: '170836',
      isUrban: false,
    },
    {
      name: 'SCORUSU',
      zip: '170845',
      isUrban: false,
    },
    {
      name: 'SERBANESTI',
      zip: '170854',
      isUrban: false,
    },
    {
      name: 'ZARNESTI',
      zip: '170863',
      isUrban: false,
    },
    {
      name: 'LIVEZI',
      zip: '170881',
      isUrban: false,
    },
    {
      name: 'PARAIENII DE JOS',
      zip: '170907',
      isUrban: false,
    },
    {
      name: 'PARAIENII DE MIJLOC',
      zip: '170916',
      isUrban: false,
    },
    {
      name: 'PARAIENII DE SUS',
      zip: '170925',
      isUrban: false,
    },
    {
      name: 'PARAUSANI',
      zip: '170890',
      isUrban: false,
    },
    {
      name: 'PLESOIU',
      zip: '170934',
      isUrban: false,
    },
    {
      name: 'TINA',
      zip: '170943',
      isUrban: false,
    },
    {
      name: 'LUNGESTI',
      zip: '170961',
      isUrban: false,
    },
    {
      name: 'CARCADIESTI',
      zip: '170970',
      isUrban: false,
    },
    {
      name: 'DUMBRAVA',
      zip: '170989',
      isUrban: false,
    },
    {
      name: 'FUMURENI',
      zip: '170998',
      isUrban: false,
    },
    {
      name: 'GANTULEI',
      zip: '171003',
      isUrban: false,
    },
    {
      name: 'STANESTI-LUNCA',
      zip: '171012',
      isUrban: false,
    },
    {
      name: 'OVESELU',
      zip: '171110',
      isUrban: false,
    },
    {
      name: 'BOCSA',
      zip: '171129',
      isUrban: false,
    },
    {
      name: 'BOTORANI',
      zip: '171138',
      isUrban: false,
    },
    {
      name: 'CIOCANARI',
      zip: '171147',
      isUrban: false,
    },
    {
      name: 'MACIUCENI',
      zip: '171156',
      isUrban: false,
    },
    {
      name: 'MALDARESTI',
      zip: '171165',
      isUrban: false,
    },
    {
      name: 'POPESTI',
      zip: '171174',
      isUrban: false,
    },
    {
      name: 'STEFANESTI',
      zip: '171183',
      isUrban: false,
    },
    {
      name: 'ZAVOIENI',
      zip: '171192',
      isUrban: false,
    },
    {
      name: 'MADULARI',
      zip: '171218',
      isUrban: false,
    },
    {
      name: 'BALSOARA',
      zip: '171227',
      isUrban: false,
    },
    {
      name: 'BANTESTI',
      zip: '171236',
      isUrban: false,
    },
    {
      name: 'DIMULESTI',
      zip: '171245',
      isUrban: false,
    },
    {
      name: 'IACOVILE',
      zip: '171254',
      isUrban: false,
    },
    {
      name: 'MAMU',
      zip: '171263',
      isUrban: false,
    },
    {
      name: 'MALAIA',
      zip: '171030',
      isUrban: false,
    },
    {
      name: 'CIUNGETU',
      zip: '171049',
      isUrban: false,
    },
    {
      name: 'SALISTEA',
      zip: '171058',
      isUrban: false,
    },
    {
      name: 'MALDARESTI',
      zip: '171281',
      isUrban: false,
    },
    {
      name: 'MALDARESTII DE JOS',
      zip: '171290',
      isUrban: false,
    },
    {
      name: 'ROSOVENI',
      zip: '171307',
      isUrban: false,
    },
    {
      name: 'TELECHESTI',
      zip: '171316',
      isUrban: false,
    },
    {
      name: 'MATEESTI',
      zip: '171076',
      isUrban: false,
    },
    {
      name: 'GRECI',
      zip: '171085',
      isUrban: false,
    },
    {
      name: 'TURCESTI',
      zip: '171094',
      isUrban: false,
    },
    {
      name: 'BULETA',
      zip: '171334',
      isUrban: false,
    },
    {
      name: 'ARSANCA',
      zip: '171343',
      isUrban: false,
    },
    {
      name: 'BARSESTI',
      zip: '171352',
      isUrban: false,
    },
    {
      name: 'GOVORA',
      zip: '171361',
      isUrban: false,
    },
    {
      name: 'GURISOARA',
      zip: '171370',
      isUrban: false,
    },
    {
      name: 'MAGURA',
      zip: '171389',
      isUrban: false,
    },
    {
      name: 'MIHAESTI',
      zip: '171398',
      isUrban: false,
    },
    {
      name: 'MUNTENI',
      zip: '171405',
      isUrban: false,
    },
    {
      name: 'NEGRENI',
      zip: '171414',
      isUrban: false,
    },
    {
      name: 'RUGETU',
      zip: '171423',
      isUrban: false,
    },
    {
      name: 'SCARISOARA',
      zip: '171432',
      isUrban: false,
    },
    {
      name: 'STUPAREI',
      zip: '171441',
      isUrban: false,
    },
    {
      name: 'VULPUESTI',
      zip: '171450',
      isUrban: false,
    },
    {
      name: 'MILCOIU',
      zip: '171478',
      isUrban: false,
    },
    {
      name: 'CAZANESTI',
      zip: '171487',
      isUrban: false,
    },
    {
      name: 'CIUTESTI',
      zip: '171496',
      isUrban: false,
    },
    {
      name: 'IZBASESTI',
      zip: '171502',
      isUrban: false,
    },
    {
      name: 'SURICARU',
      zip: '171511',
      isUrban: false,
    },
    {
      name: 'TEPSENARI',
      zip: '171520',
      isUrban: false,
    },
    {
      name: 'MUEREASCA',
      zip: '171548',
      isUrban: false,
    },
    {
      name: 'ANDREIESTI',
      zip: '171557',
      isUrban: false,
    },
    {
      name: 'FRANCESTI-COASTA',
      zip: '171566',
      isUrban: false,
    },
    {
      name: 'GAVANESTI',
      zip: '171575',
      isUrban: false,
    },
    {
      name: 'HOTARELE',
      zip: '171584',
      isUrban: false,
    },
    {
      name: 'MUEREASCA DE SUS',
      zip: '171593',
      isUrban: false,
    },
    {
      name: 'PRIPOARA',
      zip: '171600',
      isUrban: false,
    },
    {
      name: 'SUTA',
      zip: '171619',
      isUrban: false,
    },
    {
      name: 'ROTARASTI',
      zip: '171637',
      isUrban: false,
    },
    {
      name: 'BANESTI',
      zip: '171646',
      isUrban: false,
    },
    {
      name: 'CORBII DIN VALE',
      zip: '171655',
      isUrban: false,
    },
    {
      name: 'DOSU RAULUI',
      zip: '171664',
      isUrban: false,
    },
    {
      name: 'GALTOFANI',
      zip: '171682',
      isUrban: false,
    },
    {
      name: 'GINERICA',
      zip: '171673',
      isUrban: false,
    },
    {
      name: 'LINIA HANULUI',
      zip: '171691',
      isUrban: false,
    },
    {
      name: 'MANGURENI',
      zip: '171717',
      isUrban: false,
    },
    {
      name: 'MAZARARU',
      zip: '171708',
      isUrban: false,
    },
    {
      name: 'PLESOIU',
      zip: '171726',
      isUrban: false,
    },
    {
      name: 'POPESTI',
      zip: '171735',
      isUrban: false,
    },
    {
      name: 'PREDESTI',
      zip: '171744',
      isUrban: false,
    },
    {
      name: 'SCHITU',
      zip: '171753',
      isUrban: false,
    },
    {
      name: 'SERBANEASA',
      zip: '171762',
      isUrban: false,
    },
    {
      name: 'TUFANII',
      zip: '171771',
      isUrban: false,
    },
    {
      name: 'VALEA BALCEASCA',
      zip: '171780',
      isUrban: false,
    },
    {
      name: 'VALEA VIEI',
      zip: '171799',
      isUrban: false,
    },
    {
      name: 'OLANU',
      zip: '171815',
      isUrban: false,
    },
    {
      name: 'CASA VECHE',
      zip: '171824',
      isUrban: false,
    },
    {
      name: 'CIOBOTI',
      zip: '171833',
      isUrban: false,
    },
    {
      name: 'DRAGIOIU',
      zip: '171842',
      isUrban: false,
    },
    {
      name: 'NICOLESTI',
      zip: '171851',
      isUrban: false,
    },
    {
      name: 'STOICANESTI',
      zip: '171860',
      isUrban: false,
    },
    {
      name: 'ORLESTI',
      zip: '171888',
      isUrban: false,
    },
    {
      name: 'AURESTI',
      zip: '171897',
      isUrban: false,
    },
    {
      name: 'PROCOPOAIA',
      zip: '171904',
      isUrban: false,
    },
    {
      name: 'SCAIOSI',
      zip: '171913',
      isUrban: false,
    },
    {
      name: 'SILEA',
      zip: '171922',
      isUrban: false,
    },
    {
      name: 'OTESANI',
      zip: '171940',
      isUrban: false,
    },
    {
      name: 'BOGDANESTI',
      zip: '171959',
      isUrban: false,
    },
    {
      name: 'CARSTANESTI',
      zip: '171968',
      isUrban: false,
    },
    {
      name: 'CUCESTI',
      zip: '171977',
      isUrban: false,
    },
    {
      name: 'SUB DEAL',
      zip: '171986',
      isUrban: false,
    },
    {
      name: 'PAUSESTI',
      zip: '172000',
      isUrban: false,
    },
    {
      name: 'BARCANELE',
      zip: '172019',
      isUrban: false,
    },
    {
      name: 'BUZDUGAN',
      zip: '172028',
      isUrban: false,
    },
    {
      name: 'CERNELELE',
      zip: '172037',
      isUrban: false,
    },
    {
      name: 'PAUSESTI-OTASAU',
      zip: '172046',
      isUrban: false,
    },
    {
      name: 'SERBANESTI',
      zip: '172055',
      isUrban: false,
    },
    {
      name: 'SOLICESTI',
      zip: '172064',
      isUrban: false,
    },
    {
      name: 'VALENI',
      zip: '172073',
      isUrban: false,
    },
    {
      name: 'PAUSESTI-MAGLASI',
      zip: '172091',
      isUrban: false,
    },
    {
      name: 'COASTA',
      zip: '172108',
      isUrban: false,
    },
    {
      name: 'PIETRARI',
      zip: '172117',
      isUrban: false,
    },
    {
      name: 'ULMETEL',
      zip: '172126',
      isUrban: false,
    },
    {
      name: 'VALEA CHEII',
      zip: '172135',
      isUrban: false,
    },
    {
      name: 'VLADUCENI',
      zip: '172144',
      isUrban: false,
    },
    {
      name: 'PERISANI',
      zip: '172162',
      isUrban: false,
    },
    {
      name: 'BAIASU',
      zip: '172171',
      isUrban: false,
    },
    {
      name: 'BRATOVESTI',
      zip: '172180',
      isUrban: false,
    },
    {
      name: 'CUCOIU',
      zip: '172199',
      isUrban: false,
    },
    {
      name: 'MLACENI',
      zip: '172206',
      isUrban: false,
    },
    {
      name: 'PODENI',
      zip: '172215',
      isUrban: false,
    },
    {
      name: 'POIANA',
      zip: '172224',
      isUrban: false,
    },
    {
      name: 'PRIPOARE',
      zip: '172233',
      isUrban: false,
    },
    {
      name: 'SPINU',
      zip: '172242',
      isUrban: false,
    },
    {
      name: 'SURDOIU',
      zip: '172251',
      isUrban: false,
    },
    {
      name: 'TITESTI',
      zip: '172260',
      isUrban: false,
    },
    {
      name: 'PESCEANA',
      zip: '172288',
      isUrban: false,
    },
    {
      name: 'CERMEGESTI',
      zip: '172297',
      isUrban: false,
    },
    {
      name: 'LUPOAIA',
      zip: '172304',
      isUrban: false,
    },
    {
      name: 'NEGRAIA',
      zip: '172313',
      isUrban: false,
    },
    {
      name: 'ROESTI',
      zip: '172322',
      isUrban: false,
    },
    {
      name: 'URSOAIA',
      zip: '172331',
      isUrban: false,
    },
    {
      name: 'PIETRARI',
      zip: '172359',
      isUrban: false,
    },
    {
      name: 'PIETRARII DE SUS',
      zip: '172368',
      isUrban: false,
    },
    {
      name: 'POPESTI',
      zip: '172386',
      isUrban: false,
    },
    {
      name: 'CURTEA',
      zip: '172395',
      isUrban: false,
    },
    {
      name: 'DAESTI',
      zip: '172402',
      isUrban: false,
    },
    {
      name: 'FIRIJBA',
      zip: '172411',
      isUrban: false,
    },
    {
      name: 'MEIENI',
      zip: '172420',
      isUrban: false,
    },
    {
      name: 'URSI',
      zip: '172439',
      isUrban: false,
    },
    {
      name: 'VALEA CASELOR',
      zip: '172448',
      isUrban: false,
    },
    {
      name: 'PRUNDENI',
      zip: '172466',
      isUrban: false,
    },
    {
      name: 'BARBUCENI',
      zip: '172475',
      isUrban: false,
    },
    {
      name: 'CALINA',
      zip: '172484',
      isUrban: false,
    },
    {
      name: 'ZAVIDENI',
      zip: '172493',
      isUrban: false,
    },
    {
      name: 'RACOVITA',
      zip: '172518',
      isUrban: false,
    },
    {
      name: 'BALOTA',
      zip: '172527',
      isUrban: false,
    },
    {
      name: 'BLANOIU',
      zip: '172536',
      isUrban: false,
    },
    {
      name: 'BRADU-CLOCOTICI',
      zip: '172545',
      isUrban: false,
    },
    {
      name: 'COPACENI',
      zip: '172554',
      isUrban: false,
    },
    {
      name: 'GRUIU LUPULUI',
      zip: '172563',
      isUrban: false,
    },
    {
      name: 'TUTULESTI',
      zip: '172572',
      isUrban: false,
    },
    {
      name: 'ROESTI',
      zip: '172590',
      isUrban: false,
    },
    {
      name: 'BAIASA',
      zip: '172607',
      isUrban: false,
    },
    {
      name: 'BAJENARI',
      zip: '172616',
      isUrban: false,
    },
    {
      name: 'BARBARIGENI',
      zip: '172625',
      isUrban: false,
    },
    {
      name: 'CIOCALTEI',
      zip: '172634',
      isUrban: false,
    },
    {
      name: 'CUENI',
      zip: '172643',
      isUrban: false,
    },
    {
      name: 'FRASINA',
      zip: '172652',
      isUrban: false,
    },
    {
      name: 'PISCU SCOARTEI',
      zip: '172661',
      isUrban: false,
    },
    {
      name: 'RAPA CARAMIZII',
      zip: '172670',
      isUrban: false,
    },
    {
      name: 'SAIOCI',
      zip: '172689',
      isUrban: false,
    },
    {
      name: 'ROSIILE',
      zip: '172705',
      isUrban: false,
    },
    {
      name: 'BALACIU',
      zip: '172714',
      isUrban: false,
    },
    {
      name: 'CHERASTI',
      zip: '172723',
      isUrban: false,
    },
    {
      name: 'HOTAROAIA',
      zip: '172732',
      isUrban: false,
    },
    {
      name: 'LUPUIESTI',
      zip: '172741',
      isUrban: false,
    },
    {
      name: 'PASAREI',
      zip: '172750',
      isUrban: false,
    },
    {
      name: 'PERTESTI',
      zip: '172769',
      isUrban: false,
    },
    {
      name: 'PLESESTI',
      zip: '172778',
      isUrban: false,
    },
    {
      name: 'RATALESTI',
      zip: '172787',
      isUrban: false,
    },
    {
      name: 'ROMANESTI',
      zip: '172796',
      isUrban: false,
    },
    {
      name: 'ZGUBEA',
      zip: '172803',
      isUrban: false,
    },
    {
      name: 'RUNCU',
      zip: '172821',
      isUrban: false,
    },
    {
      name: 'CALIGI',
      zip: '172830',
      isUrban: false,
    },
    {
      name: 'GROPENI',
      zip: '172849',
      isUrban: false,
    },
    {
      name: 'SNAMANA',
      zip: '172858',
      isUrban: false,
    },
    {
      name: 'SURPATI',
      zip: '172867',
      isUrban: false,
    },
    {
      name: 'VALEA BABEI',
      zip: '172876',
      isUrban: false,
    },
    {
      name: 'VARATECI',
      zip: '172885',
      isUrban: false,
    },
    {
      name: 'SALATRUCEL',
      zip: '172901',
      isUrban: false,
    },
    {
      name: 'PATESTI',
      zip: '172910',
      isUrban: false,
    },
    {
      name: 'SEACA',
      zip: '172929',
      isUrban: false,
    },
    {
      name: 'SERBANESTI',
      zip: '172938',
      isUrban: false,
    },
    {
      name: 'SCUNDU',
      zip: '172956',
      isUrban: false,
    },
    {
      name: 'AVRAMESTI',
      zip: '172965',
      isUrban: false,
    },
    {
      name: 'BLEJANI',
      zip: '172974',
      isUrban: false,
    },
    {
      name: 'CRANGU',
      zip: '172983',
      isUrban: false,
    },
    {
      name: 'SINESTI',
      zip: '173007',
      isUrban: false,
    },
    {
      name: 'CIUCHETI',
      zip: '173016',
      isUrban: false,
    },
    {
      name: 'DEALU BISERICII',
      zip: '173025',
      isUrban: false,
    },
    {
      name: 'MIJLOCU',
      zip: '173034',
      isUrban: false,
    },
    {
      name: 'POPESTI',
      zip: '173043',
      isUrban: false,
    },
    {
      name: 'URZICA',
      zip: '173052',
      isUrban: false,
    },
    {
      name: 'SIRINEASA',
      zip: '173695',
      isUrban: false,
    },
    {
      name: 'ARICIOAIA',
      zip: '173702',
      isUrban: false,
    },
    {
      name: 'CIORASTI',
      zip: '173711',
      isUrban: false,
    },
    {
      name: 'SLAVITESTI',
      zip: '173720',
      isUrban: false,
    },
    {
      name: 'VALEA ALUNISULUI',
      zip: '173739',
      isUrban: false,
    },
    {
      name: 'MOGESTI',
      zip: '173114',
      isUrban: false,
    },
    {
      name: 'COASTA CERBULUI',
      zip: '173089',
      isUrban: false,
    },
    {
      name: 'GORUNESTI',
      zip: '173098',
      isUrban: false,
    },
    {
      name: 'MILOSTEA',
      zip: '173105',
      isUrban: false,
    },
    {
      name: 'RUGETU',
      zip: '173123',
      isUrban: false,
    },
    {
      name: 'SLATIOARA',
      zip: '173070',
      isUrban: false,
    },
    {
      name: 'STANESTI',
      zip: '173141',
      isUrban: false,
    },
    {
      name: 'BARCANESTI',
      zip: '173150',
      isUrban: false,
    },
    {
      name: 'CIOPONESTI',
      zip: '173169',
      isUrban: false,
    },
    {
      name: 'CUCULESTI',
      zip: '173178',
      isUrban: false,
    },
    {
      name: 'GARNICETU',
      zip: '173187',
      isUrban: false,
    },
    {
      name: 'LINIA DEALULUI',
      zip: '173196',
      isUrban: false,
    },
    {
      name: 'SUIESTI',
      zip: '173203',
      isUrban: false,
    },
    {
      name: 'ZMEURATU',
      zip: '173365',
      isUrban: false,
    },
    {
      name: 'STOILESTI',
      zip: '173383',
      isUrban: false,
    },
    {
      name: 'BALOMIREASA',
      zip: '173392',
      isUrban: false,
    },
    {
      name: 'BARSOIU',
      zip: '173409',
      isUrban: false,
    },
    {
      name: 'BULAGEI',
      zip: '173418',
      isUrban: false,
    },
    {
      name: 'DELURENI',
      zip: '173427',
      isUrban: false,
    },
    {
      name: 'GEAMANA',
      zip: '173436',
      isUrban: false,
    },
    {
      name: 'GHIOBESTI',
      zip: '173445',
      isUrban: false,
    },
    {
      name: 'GIUROIU',
      zip: '173454',
      isUrban: false,
    },
    {
      name: 'IZVORU RECE',
      zip: '173463',
      isUrban: false,
    },
    {
      name: 'MALU',
      zip: '173472',
      isUrban: false,
    },
    {
      name: 'NETESTI',
      zip: '173481',
      isUrban: false,
    },
    {
      name: 'OBOGENI',
      zip: '173490',
      isUrban: false,
    },
    {
      name: 'STANESTI',
      zip: '173506',
      isUrban: false,
    },
    {
      name: 'URSI',
      zip: '173515',
      isUrban: false,
    },
    {
      name: 'VLADULESTI',
      zip: '173524',
      isUrban: false,
    },
    {
      name: 'STROESTI',
      zip: '173542',
      isUrban: false,
    },
    {
      name: 'CIRESU',
      zip: '173551',
      isUrban: false,
    },
    {
      name: 'DIANU',
      zip: '173560',
      isUrban: false,
    },
    {
      name: 'OBROCESTI',
      zip: '173579',
      isUrban: false,
    },
    {
      name: 'POJOGI-CERNA',
      zip: '173588',
      isUrban: false,
    },
    {
      name: 'SUSANI',
      zip: '173800',
      isUrban: false,
    },
    {
      name: 'RAMESTI',
      zip: '173819',
      isUrban: false,
    },
    {
      name: 'SARBI',
      zip: '173828',
      isUrban: false,
    },
    {
      name: 'STOICULESTI',
      zip: '173837',
      isUrban: false,
    },
    {
      name: 'USUREI',
      zip: '173846',
      isUrban: false,
    },
    {
      name: 'SUTESTI',
      zip: '173604',
      isUrban: false,
    },
    {
      name: 'BOROSESTI',
      zip: '173613',
      isUrban: false,
    },
    {
      name: 'CETATEAUA',
      zip: '173622',
      isUrban: false,
    },
    {
      name: 'IZVORASU',
      zip: '173631',
      isUrban: false,
    },
    {
      name: 'MAZILI',
      zip: '173640',
      isUrban: false,
    },
    {
      name: 'MITROFANI',
      zip: '173659',
      isUrban: false,
    },
    {
      name: 'RACU',
      zip: '173668',
      isUrban: false,
    },
    {
      name: 'VERDEA',
      zip: '173677',
      isUrban: false,
    },
    {
      name: 'TETOIU',
      zip: '173864',
      isUrban: false,
    },
    {
      name: 'BAROIU',
      zip: '173873',
      isUrban: false,
    },
    {
      name: 'BUDELE',
      zip: '173882',
      isUrban: false,
    },
    {
      name: 'MANEASA',
      zip: '173891',
      isUrban: false,
    },
    {
      name: 'NENCIULESTI',
      zip: '173908',
      isUrban: false,
    },
    {
      name: 'POPESTI',
      zip: '173917',
      isUrban: false,
    },
    {
      name: 'TEPESTI',
      zip: '173926',
      isUrban: false,
    },
    {
      name: 'TOMSANI',
      zip: '173944',
      isUrban: false,
    },
    {
      name: 'BALTATENI',
      zip: '173953',
      isUrban: false,
    },
    {
      name: 'BOGDANESTI',
      zip: '173962',
      isUrban: false,
    },
    {
      name: 'CHICENI',
      zip: '173971',
      isUrban: false,
    },
    {
      name: 'DUMBRAVESTI',
      zip: '173980',
      isUrban: false,
    },
    {
      name: 'FOLESTII DE JOS',
      zip: '173999',
      isUrban: false,
    },
    {
      name: 'FOLESTII DE SUS',
      zip: '174003',
      isUrban: false,
    },
    {
      name: 'MIRESTI',
      zip: '174012',
      isUrban: false,
    },
    {
      name: 'VAIDEENI',
      zip: '174030',
      isUrban: false,
    },
    {
      name: 'CERNA',
      zip: '174049',
      isUrban: false,
    },
    {
      name: 'CORNET',
      zip: '174058',
      isUrban: false,
    },
    {
      name: 'IZVORU RECE',
      zip: '174067',
      isUrban: false,
    },
    {
      name: 'MARITA',
      zip: '174076',
      isUrban: false,
    },
    {
      name: 'VALEA MARE',
      zip: '174094',
      isUrban: false,
    },
    {
      name: 'BATASANI',
      zip: '174101',
      isUrban: false,
    },
    {
      name: 'DELURENI',
      zip: '174110',
      isUrban: false,
    },
    {
      name: 'DRAGANU',
      zip: '174129',
      isUrban: false,
    },
    {
      name: 'MARGINENI',
      zip: '174138',
      isUrban: false,
    },
    {
      name: 'PIETROASA',
      zip: '174147',
      isUrban: false,
    },
    {
      name: 'VLADESTI',
      zip: '174165',
      isUrban: false,
    },
    {
      name: 'FUNDATURA',
      zip: '174174',
      isUrban: false,
    },
    {
      name: 'PLEASA',
      zip: '174183',
      isUrban: false,
    },
    {
      name: 'PRIPORU',
      zip: '174192',
      isUrban: false,
    },
    {
      name: 'TRUNDIN',
      zip: '174209',
      isUrban: false,
    },
    {
      name: 'VOICESTI',
      zip: '174227',
      isUrban: false,
    },
    {
      name: 'TIGHINA',
      zip: '174236',
      isUrban: false,
    },
    {
      name: 'VOICESTII DIN VALE',
      zip: '174245',
      isUrban: false,
    },
    {
      name: 'VOINEASA',
      zip: '174263',
      isUrban: false,
    },
    {
      name: 'VALEA MACESULUI',
      zip: '174272',
      isUrban: false,
    },
    {
      name: 'VOINESITA',
      zip: '174281',
      isUrban: false,
    },
    {
      name: 'ZATRENI',
      zip: '174307',
      isUrban: false,
    },
    {
      name: 'BUTANU',
      zip: '174316',
      isUrban: false,
    },
    {
      name: 'CIORTESTI',
      zip: '174325',
      isUrban: false,
    },
    {
      name: 'CONTEA',
      zip: '174334',
      isUrban: false,
    },
    {
      name: 'DEALU GLAMEIA',
      zip: '174343',
      isUrban: false,
    },
    {
      name: 'DEALU VALENI',
      zip: '174352',
      isUrban: false,
    },
    {
      name: 'FAURESTI',
      zip: '174361',
      isUrban: false,
    },
    {
      name: 'GANESTI',
      zip: '174370',
      isUrban: false,
    },
    {
      name: 'LACUSTENI',
      zip: '174389',
      isUrban: false,
    },
    {
      name: 'LACUSTENII DE JOS',
      zip: '174398',
      isUrban: false,
    },
    {
      name: 'LACUSTENII DE SUS',
      zip: '174405',
      isUrban: false,
    },
    {
      name: 'MANICEA',
      zip: '174414',
      isUrban: false,
    },
    {
      name: 'MECEA',
      zip: '174423',
      isUrban: false,
    },
    {
      name: 'OLTETU',
      zip: '174432',
      isUrban: false,
    },
    {
      name: 'SASCIOARA',
      zip: '174441',
      isUrban: false,
    },
    {
      name: 'STANOMIRU',
      zip: '174450',
      isUrban: false,
    },
    {
      name: 'VALEA VALENI',
      zip: '174469',
      isUrban: false,
    },
    {
      name: 'VALENI',
      zip: '174478',
      isUrban: false,
    },
    {
      name: 'ZATRENII DE SUS',
      zip: '174487',
      isUrban: false,
    }
  ],
  VN: [
    {
      name: 'FOCSANI',
      zip: '174753',
      isUrban: true,
    },
    {
      name: 'MANDRESTI-MOLDOVA',
      zip: '174762',
      isUrban: true,
    },
    {
      name: 'MANDRESTI-MUNTENI',
      zip: '174771',
      isUrban: true,
    },
    {
      name: 'ADJUD',
      zip: '174879',
      isUrban: true,
    },
    {
      name: 'ADJUDU VECHI',
      zip: '174897',
      isUrban: true,
    },
    {
      name: 'BURCIOAIA',
      zip: '174888',
      isUrban: true,
    },
    {
      name: 'SISCANI',
      zip: '174904',
      isUrban: true,
    },
    {
      name: 'MARASESTI',
      zip: '174931',
      isUrban: true,
    },
    {
      name: 'CALIMANESTI',
      zip: '174977',
      isUrban: true,
    },
    {
      name: 'HARET',
      zip: '174968',
      isUrban: true,
    },
    {
      name: 'MODRUZENI',
      zip: '174986',
      isUrban: true,
    },
    {
      name: 'PADURENI',
      zip: '174995',
      isUrban: true,
    },
    {
      name: 'SIRETU',
      zip: '174940',
      isUrban: true,
    },
    {
      name: 'TISITA',
      zip: '174959',
      isUrban: true,
    },
    {
      name: 'ODOBESTI',
      zip: '175028',
      isUrban: true,
    },
    {
      name: 'UNIREA',
      zip: '175037',
      isUrban: true,
    },
    {
      name: 'PANCIU',
      zip: '175064',
      isUrban: true,
    },
    {
      name: 'CRUCEA DE JOS',
      zip: '175073',
      isUrban: true,
    },
    {
      name: 'CRUCEA DE SUS',
      zip: '175082',
      isUrban: true,
    },
    {
      name: 'DUMBRAVA',
      zip: '175091',
      isUrban: true,
    },
    {
      name: 'NEICU',
      zip: '175108',
      isUrban: true,
    },
    {
      name: 'SATU NOU',
      zip: '175117',
      isUrban: true,
    },
    {
      name: 'ANDREIASU DE JOS',
      zip: '175135',
      isUrban: false,
    },
    {
      name: 'ANDREIASU DE SUS',
      zip: '175144',
      isUrban: false,
    },
    {
      name: 'ARSITA',
      zip: '175153',
      isUrban: false,
    },
    {
      name: 'FETIG',
      zip: '175162',
      isUrban: false,
    },
    {
      name: 'HOTARU',
      zip: '175171',
      isUrban: false,
    },
    {
      name: 'RACHITASU',
      zip: '175180',
      isUrban: false,
    },
    {
      name: 'TITILA',
      zip: '175199',
      isUrban: false,
    },
    {
      name: 'BALESTI',
      zip: '175215',
      isUrban: false,
    },
    {
      name: 'BARSESTI',
      zip: '175233',
      isUrban: false,
    },
    {
      name: 'NEGRILESTI',
      zip: '175242',
      isUrban: false,
    },
    {
      name: 'TOPESTI',
      zip: '175251',
      isUrban: false,
    },
    {
      name: 'BOGHESTI',
      zip: '175279',
      isUrban: false,
    },
    {
      name: 'BICHESTI',
      zip: '175288',
      isUrban: false,
    },
    {
      name: 'BOGHESTII DE SUS',
      zip: '175297',
      isUrban: false,
    },
    {
      name: 'CHITCANI',
      zip: '175304',
      isUrban: false,
    },
    {
      name: 'IUGANI',
      zip: '175313',
      isUrban: false,
    },
    {
      name: 'PLACINTENI',
      zip: '175322',
      isUrban: false,
    },
    {
      name: 'PLESESTI',
      zip: '175331',
      isUrban: false,
    },
    {
      name: 'PRISECANI',
      zip: '175340',
      isUrban: false,
    },
    {
      name: 'TABUCESTI',
      zip: '175359',
      isUrban: false,
    },
    {
      name: 'BOLOTESTI',
      zip: '175377',
      isUrban: false,
    },
    {
      name: 'GAGESTI',
      zip: '175386',
      isUrban: false,
    },
    {
      name: 'IVANCESTI',
      zip: '175395',
      isUrban: false,
    },
    {
      name: 'PIETROASA',
      zip: '175402',
      isUrban: false,
    },
    {
      name: 'PUTNA',
      zip: '175411',
      isUrban: false,
    },
    {
      name: 'VITANESTII DE SUB MAGURA',
      zip: '175420',
      isUrban: false,
    },
    {
      name: 'BORDESTI',
      zip: '175448',
      isUrban: false,
    },
    {
      name: 'BORDESTII DE JOS',
      zip: '175457',
      isUrban: false,
    },
    {
      name: 'BROSTENI',
      zip: '175475',
      isUrban: false,
    },
    {
      name: 'ARVA',
      zip: '175484',
      isUrban: false,
    },
    {
      name: 'PITULUSA',
      zip: '175493',
      isUrban: false,
    },
    {
      name: 'CAMPINEANCA',
      zip: '174799',
      isUrban: false,
    },
    {
      name: 'PIETROASA',
      zip: '174806',
      isUrban: false,
    },
    {
      name: 'VALCELE',
      zip: '174815',
      isUrban: false,
    },
    {
      name: 'CAMPURI',
      zip: '175689',
      isUrban: false,
    },
    {
      name: 'FETESTI',
      zip: '175698',
      isUrban: false,
    },
    {
      name: 'GURA VAII',
      zip: '175705',
      isUrban: false,
    },
    {
      name: 'ROTILESTII MARI',
      zip: '175714',
      isUrban: false,
    },
    {
      name: 'ROTILESTII MICI',
      zip: '175723',
      isUrban: false,
    },
    {
      name: 'CARLIGELE',
      zip: '175741',
      isUrban: false,
    },
    {
      name: 'BLIDARI',
      zip: '175750',
      isUrban: false,
    },
    {
      name: 'BONTESTI',
      zip: '175769',
      isUrban: false,
    },
    {
      name: 'DALHAUTI',
      zip: '175778',
      isUrban: false,
    },
    {
      name: 'CHIOJDENI',
      zip: '175518',
      isUrban: false,
    },
    {
      name: 'CATAUTI',
      zip: '175527',
      isUrban: false,
    },
    {
      name: 'LOJNITA',
      zip: '175536',
      isUrban: false,
    },
    {
      name: 'LUNCILE',
      zip: '175545',
      isUrban: false,
    },
    {
      name: 'MARACINI',
      zip: '175554',
      isUrban: false,
    },
    {
      name: 'PODURILE',
      zip: '175563',
      isUrban: false,
    },
    {
      name: 'SECIU',
      zip: '175572',
      isUrban: false,
    },
    {
      name: 'TULBUREA',
      zip: '175581',
      isUrban: false,
    },
    {
      name: 'CIORASTI',
      zip: '175607',
      isUrban: false,
    },
    {
      name: 'CODRESTI',
      zip: '175616',
      isUrban: false,
    },
    {
      name: 'MIHALCENI',
      zip: '175625',
      isUrban: false,
    },
    {
      name: 'SALCIA NOUA',
      zip: '175634',
      isUrban: false,
    },
    {
      name: 'SALCIA VECHE',
      zip: '175643',
      isUrban: false,
    },
    {
      name: 'SATU NOU',
      zip: '175652',
      isUrban: false,
    },
    {
      name: 'SPATAREASA',
      zip: '175661',
      isUrban: false,
    },
    {
      name: 'SERBANESTI',
      zip: '175796',
      isUrban: false,
    },
    {
      name: 'BUDA',
      zip: '175803',
      isUrban: false,
    },
    {
      name: 'CORBITA',
      zip: '175812',
      isUrban: false,
    },
    {
      name: 'IZVOARELE',
      zip: '175821',
      isUrban: false,
    },
    {
      name: 'LARGASENI',
      zip: '175830',
      isUrban: false,
    },
    {
      name: 'OCHESESTI',
      zip: '175849',
      isUrban: false,
    },
    {
      name: 'RADACINESTI',
      zip: '175858',
      isUrban: false,
    },
    {
      name: 'TUTU',
      zip: '175867',
      isUrban: false,
    },
    {
      name: 'VALCELELE',
      zip: '175876',
      isUrban: false,
    },
    {
      name: 'COTESTI',
      zip: '175894',
      isUrban: false,
    },
    {
      name: 'BUDESTI',
      zip: '175901',
      isUrban: false,
    },
    {
      name: 'GOLESTII DE SUS',
      zip: '175910',
      isUrban: false,
    },
    {
      name: 'VALEA COTESTI',
      zip: '175929',
      isUrban: false,
    },
    {
      name: 'DUMBRAVENI',
      zip: '175947',
      isUrban: false,
    },
    {
      name: 'ALEXANDRU VLAHUTA',
      zip: '175956',
      isUrban: false,
    },
    {
      name: 'CANDESTI',
      zip: '175965',
      isUrban: false,
    },
    {
      name: 'DRAGOSLOVENI',
      zip: '175974',
      isUrban: false,
    },
    {
      name: 'DUMITRESTI',
      zip: '175992',
      isUrban: false,
    },
    {
      name: 'BICESTII DE JOS',
      zip: '176007',
      isUrban: false,
    },
    {
      name: 'BICESTII DE SUS',
      zip: '176016',
      isUrban: false,
    },
    {
      name: 'BLIDARI',
      zip: '176025',
      isUrban: false,
    },
    {
      name: 'DUMITRESTII DE SUS',
      zip: '176043',
      isUrban: false,
    },
    {
      name: 'DUMITRESTII-FATA',
      zip: '176034',
      isUrban: false,
    },
    {
      name: 'GALOIESTI',
      zip: '176052',
      isUrban: false,
    },
    {
      name: 'LASTUNI',
      zip: '176061',
      isUrban: false,
    },
    {
      name: 'LUPOAIA',
      zip: '176070',
      isUrban: false,
    },
    {
      name: 'MOTNAU',
      zip: '176089',
      isUrban: false,
    },
    {
      name: 'POIENITA',
      zip: '176098',
      isUrban: false,
    },
    {
      name: 'ROSCARI',
      zip: '176105',
      isUrban: false,
    },
    {
      name: 'SIMINOC',
      zip: '176114',
      isUrban: false,
    },
    {
      name: 'TINOASA',
      zip: '176123',
      isUrban: false,
    },
    {
      name: 'TRESTIA',
      zip: '176132',
      isUrban: false,
    },
    {
      name: 'VALEA MICA',
      zip: '176141',
      isUrban: false,
    },
    {
      name: 'FITIONESTI',
      zip: '176169',
      isUrban: false,
    },
    {
      name: 'CIOLANESTI',
      zip: '176178',
      isUrban: false,
    },
    {
      name: 'GHIMICESTI',
      zip: '176187',
      isUrban: false,
    },
    {
      name: 'HOLBANESTI',
      zip: '176196',
      isUrban: false,
    },
    {
      name: 'MANASTIOARA',
      zip: '176203',
      isUrban: false,
    },
    {
      name: 'GAROAFA',
      zip: '176221',
      isUrban: false,
    },
    {
      name: 'BIZIGHESTI',
      zip: '176230',
      isUrban: false,
    },
    {
      name: 'CIUSLEA',
      zip: '176249',
      isUrban: false,
    },
    {
      name: 'DOAGA',
      zip: '176258',
      isUrban: false,
    },
    {
      name: 'FAUREI',
      zip: '176267',
      isUrban: false,
    },
    {
      name: 'PRECISTANU',
      zip: '176276',
      isUrban: false,
    },
    {
      name: 'RACHITOSU',
      zip: '176285',
      isUrban: false,
    },
    {
      name: 'STRAJESCU',
      zip: '176294',
      isUrban: false,
    },
    {
      name: 'GOLESTI',
      zip: '174833',
      isUrban: false,
    },
    {
      name: 'CEARDAC',
      zip: '174842',
      isUrban: false,
    },
    {
      name: 'GUGESTI',
      zip: '176310',
      isUrban: false,
    },
    {
      name: 'OREAVU',
      zip: '176329',
      isUrban: false,
    },
    {
      name: 'GURA CALITEI',
      zip: '176347',
      isUrban: false,
    },
    {
      name: 'BALANESTI',
      zip: '176356',
      isUrban: false,
    },
    {
      name: 'COCOSARI',
      zip: '176365',
      isUrban: false,
    },
    {
      name: 'DEALUL LUNG',
      zip: '176374',
      isUrban: false,
    },
    {
      name: 'GROAPA TUFEI',
      zip: '176383',
      isUrban: false,
    },
    {
      name: 'LACU LUI BABAN',
      zip: '176392',
      isUrban: false,
    },
    {
      name: 'PLOPU',
      zip: '176409',
      isUrban: false,
    },
    {
      name: 'POENILE',
      zip: '176418',
      isUrban: false,
    },
    {
      name: 'RASCA',
      zip: '176427',
      isUrban: false,
    },
    {
      name: 'SOTARCARI',
      zip: '176436',
      isUrban: false,
    },
    {
      name: 'HOMOCEA',
      zip: '176454',
      isUrban: false,
    },
    {
      name: 'ARGEA',
      zip: '176463',
      isUrban: false,
    },
    {
      name: 'COSTISA',
      zip: '176472',
      isUrban: false,
    },
    {
      name: 'LESPEZI',
      zip: '176481',
      isUrban: false,
    },
    {
      name: 'PLOSCUTENI',
      zip: '176490',
      isUrban: false,
    },
    {
      name: 'JARISTEA',
      zip: '176515',
      isUrban: false,
    },
    {
      name: 'PADURENI',
      zip: '176524',
      isUrban: false,
    },
    {
      name: 'SCANTEIA',
      zip: '176533',
      isUrban: false,
    },
    {
      name: 'VULCANEASA',
      zip: '176739',
      isUrban: false,
    },
    {
      name: 'MILCOVUL',
      zip: '176757',
      isUrban: false,
    },
    {
      name: 'GOLOGANU',
      zip: '176766',
      isUrban: false,
    },
    {
      name: 'LAMOTESTI',
      zip: '176775',
      isUrban: false,
    },
    {
      name: 'RASTOACA',
      zip: '176784',
      isUrban: false,
    },
    {
      name: 'MOVILITA',
      zip: '176800',
      isUrban: false,
    },
    {
      name: 'DIOCHETI-REDIU',
      zip: '176819',
      isUrban: false,
    },
    {
      name: 'FRECATEI',
      zip: '176828',
      isUrban: false,
    },
    {
      name: 'TROTUSANU',
      zip: '176837',
      isUrban: false,
    },
    {
      name: 'VALENI',
      zip: '176846',
      isUrban: false,
    },
    {
      name: 'NANESTI',
      zip: '176864',
      isUrban: false,
    },
    {
      name: 'CALIENII NOI',
      zip: '176873',
      isUrban: false,
    },
    {
      name: 'CALIENII VECHI',
      zip: '176882',
      isUrban: false,
    },
    {
      name: 'NARUJA',
      zip: '176908',
      isUrban: false,
    },
    {
      name: 'PODU NARUJEI',
      zip: '176917',
      isUrban: false,
    },
    {
      name: 'PODU STOICA',
      zip: '176926',
      isUrban: false,
    },
    {
      name: 'REBEGARI',
      zip: '176935',
      isUrban: false,
    },
    {
      name: 'NEREJU',
      zip: '176953',
      isUrban: false,
    },
    {
      name: 'BRADACESTI',
      zip: '176962',
      isUrban: false,
    },
    {
      name: 'CHIRICANI',
      zip: '176971',
      isUrban: false,
    },
    {
      name: 'NEREJU MIC',
      zip: '176980',
      isUrban: false,
    },
    {
      name: 'SAHASTRU',
      zip: '176999',
      isUrban: false,
    },
    {
      name: 'NISTORESTI',
      zip: '177012',
      isUrban: false,
    },
    {
      name: 'BATCARI',
      zip: '177021',
      isUrban: false,
    },
    {
      name: 'BRADETU',
      zip: '177030',
      isUrban: false,
    },
    {
      name: 'PODU SCHIOPULUI',
      zip: '177058',
      isUrban: false,
    },
    {
      name: 'ROMANESTI',
      zip: '177067',
      isUrban: false,
    },
    {
      name: 'UNGURENI',
      zip: '177076',
      isUrban: false,
    },
    {
      name: 'VALEA NEAGRA',
      zip: '177085',
      isUrban: false,
    },
    {
      name: 'VETRESTI-HERASTRAU',
      zip: '177094',
      isUrban: false,
    },
    {
      name: 'PALTIN',
      zip: '177110',
      isUrban: false,
    },
    {
      name: 'CARSOCHESTI-CORABITA',
      zip: '177129',
      isUrban: false,
    },
    {
      name: 'GHEBARI',
      zip: '177138',
      isUrban: false,
    },
    {
      name: 'MORARESTI',
      zip: '177147',
      isUrban: false,
    },
    {
      name: 'PAVALARI',
      zip: '177156',
      isUrban: false,
    },
    {
      name: 'PRAHUDA',
      zip: '177165',
      isUrban: false,
    },
    {
      name: 'SPULBER',
      zip: '177174',
      isUrban: false,
    },
    {
      name: 'TEPA',
      zip: '177209',
      isUrban: false,
    },
    {
      name: 'TIPAU',
      zip: '177218',
      isUrban: false,
    },
    {
      name: 'TOJANII DE JOS',
      zip: '177183',
      isUrban: false,
    },
    {
      name: 'TOJANII DE SUS',
      zip: '177192',
      isUrban: false,
    },
    {
      name: 'VALCANI',
      zip: '177227',
      isUrban: false,
    },
    {
      name: 'PAUNESTI',
      zip: '177245',
      isUrban: false,
    },
    {
      name: 'VIISOARA',
      zip: '177254',
      isUrban: false,
    },
    {
      name: 'POIANA CRISTEI',
      zip: '177272',
      isUrban: false,
    },
    {
      name: 'DEALU CUCULUI',
      zip: '177281',
      isUrban: false,
    },
    {
      name: 'DUMBRAVA',
      zip: '177290',
      isUrban: false,
    },
    {
      name: 'MAHRIU',
      zip: '177307',
      isUrban: false,
    },
    {
      name: 'ODOBASCA',
      zip: '177316',
      isUrban: false,
    },
    {
      name: 'PETREANU',
      zip: '177325',
      isUrban: false,
    },
    {
      name: 'PODU LACULUI',
      zip: '177334',
      isUrban: false,
    },
    {
      name: 'TARATU',
      zip: '177343',
      isUrban: false,
    },
    {
      name: 'PUFESTI',
      zip: '177361',
      isUrban: false,
    },
    {
      name: 'CIORANI',
      zip: '177370',
      isUrban: false,
    },
    {
      name: 'DOMNESTI-SAT',
      zip: '177389',
      isUrban: false,
    },
    {
      name: 'DOMNESTI-TARG',
      zip: '177398',
      isUrban: false,
    },
    {
      name: 'RACOASA',
      zip: '177414',
      isUrban: false,
    },
    {
      name: 'GOGOIU',
      zip: '177423',
      isUrban: false,
    },
    {
      name: 'MARASTI',
      zip: '177432',
      isUrban: false,
    },
    {
      name: 'VARNITA',
      zip: '177441',
      isUrban: false,
    },
    {
      name: 'VERDEA',
      zip: '177450',
      isUrban: false,
    },
    {
      name: 'REGHIU',
      zip: '177478',
      isUrban: false,
    },
    {
      name: 'FARCAS',
      zip: '177487',
      isUrban: false,
    },
    {
      name: 'JGHEABURI',
      zip: '177496',
      isUrban: false,
    },
    {
      name: 'PISCU REGHIULUI',
      zip: '177502',
      isUrban: false,
    },
    {
      name: 'RAIUTI',
      zip: '177511',
      isUrban: false,
    },
    {
      name: 'SINDRILARI',
      zip: '177520',
      isUrban: false,
    },
    {
      name: 'URSOAIA',
      zip: '177539',
      isUrban: false,
    },
    {
      name: 'VALEA MILCOVULUI',
      zip: '177548',
      isUrban: false,
    },
    {
      name: 'RUGINESTI',
      zip: '177566',
      isUrban: false,
    },
    {
      name: 'ANGHELESTI',
      zip: '177575',
      isUrban: false,
    },
    {
      name: 'COPACESTI',
      zip: '177584',
      isUrban: false,
    },
    {
      name: 'VALENI',
      zip: '177593',
      isUrban: false,
    },
    {
      name: 'SIHLEA',
      zip: '177619',
      isUrban: false,
    },
    {
      name: 'BOGZA',
      zip: '177628',
      isUrban: false,
    },
    {
      name: 'CAIATA',
      zip: '177637',
      isUrban: false,
    },
    {
      name: 'VOETIN',
      zip: '177646',
      isUrban: false,
    },
    {
      name: 'SLOBOZIA BRADULUI',
      zip: '177664',
      isUrban: false,
    },
    {
      name: 'CORNETU',
      zip: '177673',
      isUrban: false,
    },
    {
      name: 'COROTENI',
      zip: '177682',
      isUrban: false,
    },
    {
      name: 'LIESTI',
      zip: '177691',
      isUrban: false,
    },
    {
      name: 'OLARENI',
      zip: '177708',
      isUrban: false,
    },
    {
      name: 'VALEA BECIULUI',
      zip: '177717',
      isUrban: false,
    },
    {
      name: 'SLOBOZIA CIORASTI',
      zip: '177735',
      isUrban: false,
    },
    {
      name: 'ARMENI',
      zip: '177744',
      isUrban: false,
    },
    {
      name: 'JILISTE',
      zip: '177753',
      isUrban: false,
    },
    {
      name: 'DRAGOSLOVENI',
      zip: '177771',
      isUrban: false,
    },
    {
      name: 'RUCARENI',
      zip: '177780',
      isUrban: false,
    },
    {
      name: 'STRAOANE',
      zip: '177806',
      isUrban: false,
    },
    {
      name: 'MUNCELU',
      zip: '177815',
      isUrban: false,
    },
    {
      name: 'REPEDEA',
      zip: '177824',
      isUrban: false,
    },
    {
      name: 'VALENI',
      zip: '177833',
      isUrban: false,
    },
    {
      name: 'SURAIA',
      zip: '177851',
      isUrban: false,
    },
    {
      name: 'BILIESTI',
      zip: '177860',
      isUrban: false,
    },
    {
      name: 'TAMBOESTI',
      zip: '178055',
      isUrban: false,
    },
    {
      name: 'OBREJITA',
      zip: '178064',
      isUrban: false,
    },
    {
      name: 'PADURENI',
      zip: '178073',
      isUrban: false,
    },
    {
      name: 'PIETROASA',
      zip: '178082',
      isUrban: false,
    },
    {
      name: 'SLIMNIC',
      zip: '178091',
      isUrban: false,
    },
    {
      name: 'TRESTIENI',
      zip: '178108',
      isUrban: false,
    },
    {
      name: 'TANASOAIA',
      zip: '177888',
      isUrban: false,
    },
    {
      name: 'CALIMANEASA',
      zip: '177897',
      isUrban: false,
    },
    {
      name: 'COSTISA',
      zip: '177904',
      isUrban: false,
    },
    {
      name: 'COSTISA DE SUS',
      zip: '177913',
      isUrban: false,
    },
    {
      name: 'COVRAG',
      zip: '177922',
      isUrban: false,
    },
    {
      name: 'FELDIOARA',
      zip: '177931',
      isUrban: false,
    },
    {
      name: 'GALBENI',
      zip: '177940',
      isUrban: false,
    },
    {
      name: 'NANESTI',
      zip: '177959',
      isUrban: false,
    },
    {
      name: 'VLADNICU DE JOS',
      zip: '177968',
      isUrban: false,
    },
    {
      name: 'VLADNICU DE SUS',
      zip: '177977',
      isUrban: false,
    },
    {
      name: 'TATARANU',
      zip: '177995',
      isUrban: false,
    },
    {
      name: 'BORDEASCA NOUA',
      zip: '178000',
      isUrban: false,
    },
    {
      name: 'BORDEASCA VECHE',
      zip: '178019',
      isUrban: false,
    },
    {
      name: 'MARTINESTI',
      zip: '178028',
      isUrban: false,
    },
    {
      name: 'VAJAITOAREA',
      zip: '178037',
      isUrban: false,
    },
    {
      name: 'TIFESTI',
      zip: '178199',
      isUrban: false,
    },
    {
      name: 'BATINESTI',
      zip: '178206',
      isUrban: false,
    },
    {
      name: 'CLIPICESTI',
      zip: '178215',
      isUrban: false,
    },
    {
      name: 'IGESTI',
      zip: '178224',
      isUrban: false,
    },
    {
      name: 'OLESESTI',
      zip: '178233',
      isUrban: false,
    },
    {
      name: 'PATRASCANI',
      zip: '178242',
      isUrban: false,
    },
    {
      name: 'SARBI',
      zip: '178251',
      isUrban: false,
    },
    {
      name: 'VITANESTI',
      zip: '178260',
      isUrban: false,
    },
    {
      name: 'TULNICI',
      zip: '178126',
      isUrban: false,
    },
    {
      name: 'COZA',
      zip: '178135',
      isUrban: false,
    },
    {
      name: 'GRESU',
      zip: '178144',
      isUrban: false,
    },
    {
      name: 'HAULISCA',
      zip: '178153',
      isUrban: false,
    },
    {
      name: 'LEPSA',
      zip: '178162',
      isUrban: false,
    },
    {
      name: 'PAULESTI',
      zip: '178171',
      isUrban: false,
    },
    {
      name: 'URECHESTI',
      zip: '178288',
      isUrban: false,
    },
    {
      name: 'POPESTI',
      zip: '178297',
      isUrban: false,
    },
    {
      name: 'TERCHESTI',
      zip: '178304',
      isUrban: false,
    },
    {
      name: 'VALEA SARII',
      zip: '178322',
      isUrban: false,
    },
    {
      name: 'COLACU',
      zip: '178331',
      isUrban: false,
    },
    {
      name: 'MATACINA',
      zip: '178340',
      isUrban: false,
    },
    {
      name: 'PODURI',
      zip: '178359',
      isUrban: false,
    },
    {
      name: 'PRISACA',
      zip: '178368',
      isUrban: false,
    },
    {
      name: 'VANATORI',
      zip: '178616',
      isUrban: false,
    },
    {
      name: 'BALTA RATEI',
      zip: '178625',
      isUrban: false,
    },
    {
      name: 'JORASTI',
      zip: '178634',
      isUrban: false,
    },
    {
      name: 'MIRCESTII NOI',
      zip: '178643',
      isUrban: false,
    },
    {
      name: 'MIRCESTII VECHI',
      zip: '178652',
      isUrban: false,
    },
    {
      name: 'PETRESTI',
      zip: '178661',
      isUrban: false,
    },
    {
      name: 'RADULESTI',
      zip: '178670',
      isUrban: false,
    },
    {
      name: 'VARTESCOIU',
      zip: '178698',
      isUrban: false,
    },
    {
      name: 'BECIU',
      zip: '178705',
      isUrban: false,
    },
    {
      name: 'FARAOANELE',
      zip: '178714',
      isUrban: false,
    },
    {
      name: 'OLTENI',
      zip: '178723',
      isUrban: false,
    },
    {
      name: 'PIETROASA',
      zip: '178732',
      isUrban: false,
    },
    {
      name: 'RAMNICEANCA',
      zip: '178741',
      isUrban: false,
    },
    {
      name: 'VIDRA',
      zip: '178386',
      isUrban: false,
    },
    {
      name: 'BURCA',
      zip: '178395',
      isUrban: false,
    },
    {
      name: 'IRESTI',
      zip: '178402',
      isUrban: false,
    },
    {
      name: 'RUGET',
      zip: '178411',
      isUrban: false,
    },
    {
      name: 'SCAFARI',
      zip: '178420',
      isUrban: false,
    },
    {
      name: 'SERBESTI',
      zip: '178439',
      isUrban: false,
    },
    {
      name: 'TICHIRIS',
      zip: '178448',
      isUrban: false,
    },
    {
      name: 'VIISOARA',
      zip: '178457',
      isUrban: false,
    },
    {
      name: 'VOLOSCANI',
      zip: '178466',
      isUrban: false,
    },
    {
      name: 'VINTILEASCA',
      zip: '178484',
      isUrban: false,
    },
    {
      name: 'BAHNELE',
      zip: '178493',
      isUrban: false,
    },
    {
      name: 'DUPA MAGURA',
      zip: '178509',
      isUrban: false,
    },
    {
      name: 'NECULELE',
      zip: '178518',
      isUrban: false,
    },
    {
      name: 'POIANA STOICHII',
      zip: '178527',
      isUrban: false,
    },
    {
      name: 'TANASARI',
      zip: '178536',
      isUrban: false,
    },
    {
      name: 'LIVEZILE',
      zip: '178554',
      isUrban: false,
    },
    {
      name: 'MESTEACANU',
      zip: '178563',
      isUrban: false,
    },
    {
      name: 'PISCU RADULUI',
      zip: '178572',
      isUrban: false,
    },
    {
      name: 'VIZANTEA MANASTIREASCA',
      zip: '178581',
      isUrban: false,
    },
    {
      name: 'VIZANTEA RAZASEASCA',
      zip: '178590',
      isUrban: false,
    },
    {
      name: 'VRANCIOAIA',
      zip: '178769',
      isUrban: false,
    },
    {
      name: 'BODESTI',
      zip: '178778',
      isUrban: false,
    },
    {
      name: 'MUNCEI',
      zip: '178787',
      isUrban: false,
    },
    {
      name: 'PLOSTINA',
      zip: '178796',
      isUrban: false,
    },
    {
      name: 'POIANA',
      zip: '178803',
      isUrban: false,
    },
    {
      name: 'SPINESTI',
      zip: '178812',
      isUrban: false,
    },
    {
      name: 'VULTURU',
      zip: '178830',
      isUrban: false,
    },
    {
      name: 'BOTARLAU',
      zip: '178849',
      isUrban: false,
    },
    {
      name: 'HANGULESTI',
      zip: '178858',
      isUrban: false,
    },
    {
      name: 'MALURI',
      zip: '178867',
      isUrban: false,
    },
    {
      name: 'VADU ROSCA',
      zip: '178876',
      isUrban: false,
    },
    {
      name: 'VARSATURA',
      zip: '176542',
      isUrban: false,
    },
    {
      name: 'JITIA',
      zip: '176560',
      isUrban: false,
    },
    {
      name: 'CERBU',
      zip: '176579',
      isUrban: false,
    },
    {
      name: 'DEALU SARII',
      zip: '176588',
      isUrban: false,
    },
    {
      name: 'JITIA DE JOS',
      zip: '176597',
      isUrban: false,
    },
    {
      name: 'MAGURA',
      zip: '176604',
      isUrban: false,
    },
    {
      name: 'MAICANESTI',
      zip: '176622',
      isUrban: false,
    },
    {
      name: 'BELCIUGELE',
      zip: '176631',
      isUrban: false,
    },
    {
      name: 'RAMNICENI',
      zip: '176640',
      isUrban: false,
    },
    {
      name: 'SLOBOZIA BOTESTI',
      zip: '176659',
      isUrban: false,
    },
    {
      name: 'STUPINA',
      zip: '176668',
      isUrban: false,
    },
    {
      name: 'TATARU',
      zip: '176677',
      isUrban: false,
    },
    {
      name: 'MERA',
      zip: '176695',
      isUrban: false,
    },
    {
      name: 'LIVADA',
      zip: '176702',
      isUrban: false,
    },
    {
      name: 'MILCOVEL',
      zip: '176711',
      isUrban: false,
    },
    {
      name: 'ROSIOARA',
      zip: '176720',
      isUrban: false,
    },
    {
      name: 'FAGETU',
      zip: '177049',
      isUrban: false,
    }
  ],
};
