const GET_CASEFILES = 'GET_CASEFILES';
const GET_CASEFILES_COMPLETED = 'GET_CASEFILES_COMPLETED';
const GET_CASEFILES_FAILED = 'GET_CASEFILES_FAILED';
const GET_SINGLE_CASEFILE = 'GET_SINGLE_CASEFILE';
const GET_SINGLE_CASEFILE_COMPLETED = 'GET_SINGLE_CASEFILE_COMPLETED';
const GET_SINGLE_CASEFILE_FAILED = 'GET_SINGLE_CASEFILE_FAILED';
const CREATE_CASEFILE = 'CREATE_CASEFILE';
const CREATE_CASEFILE_COMPLETED = 'CREATE_CASEFILE_COMPLETED';
const CREATE_CASEFILE_FAILED = 'CREATE_CASEFILE_FAILED';
const UPDATE_CASEFILE = 'UPDATE_CASEFILE';
const UPDATE_CASEFILE_COMPLETED = 'UPDATE_CASEFILE_COMPLETED';
const UPDATE_CASEFILE_FAILED = 'UPDATE_CASEFILE_FAILED';
const GET_SINGLE_FILE = 'GET_SINGLE_FILE';
const GET_SINGLE_FILE_COMPLETED = 'GET_SINGLE_FILE_COMPLETED';
const GET_SINGLE_FILE_FAILED = 'GET_SINGLE_FILE_FAILED';
const CREATE_FILE = 'CREATE_FILE';
const CREATE_FILE_COMPLETED = 'CREATE_FILE_COMPLETED';
const CREATE_FILE_FAILED = 'CREATE_FILE_FAILED';
const UPDATE_FILE = 'UPDATE_FILE';
const UPDATE_FILE_COMPLETED = 'UPDATE_FILE_COMPLETED';
const UPDATE_FILE_FAILED = 'UPDATE_FILE_FAILED';
const DOWNLOAD_FILE = 'DOWNLOAD_FILE';
const DOWNLOAD_FILE_COMPLETED = 'DOWNLOAD_FILE_COMPLETED';
const DOWNLOAD_FILE_FAILED = 'DOWNLOAD_FILE_FAILED';
const UPDATE_TSF_ENTITY_DATES = 'UPDATE_TSF_ENTITY_DATES';
const UPDATE_TSF_ENTITY_COMPLETED = 'UPDATE_TSF_ENTITY_COMPLETED';
const UPDATE_TSF_ENTITY_FAILED = 'UPDATE_TSF_ENTITY_FAILED';

export default {
  GET_CASEFILES,
  GET_CASEFILES_COMPLETED,
  GET_CASEFILES_FAILED,
  GET_SINGLE_CASEFILE,
  GET_SINGLE_CASEFILE_COMPLETED,
  GET_SINGLE_CASEFILE_FAILED,
  CREATE_CASEFILE,
  CREATE_CASEFILE_COMPLETED,
  CREATE_CASEFILE_FAILED,
  UPDATE_CASEFILE,
  UPDATE_CASEFILE_COMPLETED,
  UPDATE_CASEFILE_FAILED,
  GET_SINGLE_FILE,
  GET_SINGLE_FILE_COMPLETED,
  GET_SINGLE_FILE_FAILED,
  CREATE_FILE,
  CREATE_FILE_COMPLETED,
  CREATE_FILE_FAILED,
  UPDATE_FILE,
  UPDATE_FILE_COMPLETED,
  UPDATE_FILE_FAILED,
  DOWNLOAD_FILE,
  DOWNLOAD_FILE_COMPLETED,
  DOWNLOAD_FILE_FAILED,
  UPDATE_TSF_ENTITY_DATES,
  UPDATE_TSF_ENTITY_COMPLETED,
  UPDATE_TSF_ENTITY_FAILED,
};
