import React from 'react';
import {
  Button,
  Modal, ModalBody, ModalFooter,
  ModalHeader
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faTimes } from '@fortawesome/free-solid-svg-icons';

function DeleteDoctorAddressModal({ close, confirm, address, }) {
  const { city, county, region, } = address;
  return (
    <Modal size="xl" isOpen>
      <ModalHeader className="p-4" toggle={close}>Confirmați operația de ștergere</ModalHeader>
      <ModalBody className="p-4">
        <p>
          {`Sunteți sigur că doriți să inactivați adresa ${city ? city.label : ''}${county ? `, ${county.label}` : ''}${region ? `, ${region.label}` : ''}?`}
        </p>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={close}>
          <FontAwesomeIcon icon={faBan} />
          <span className="ml-2">Anulare</span>
        </Button>
        <Button color="danger" onClick={() => confirm(address)}>
          <FontAwesomeIcon icon={faTimes} />
          <span className="ml-2">Ștergere</span>
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default DeleteDoctorAddressModal;
