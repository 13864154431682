import { combineReducers } from 'redux';
import types from './types';

const initialState = {
  users: null,
  selectedUser: null,
};

const users = (state = initialState.users, action) => {
  switch (action.type) {
    case types.GET_USERS_COMPLETED:
      return action.payload.data.data;
    default:
      return state;
  }
};

export default combineReducers({ users, });
