import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, CardHeader, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import moment from 'moment';
import Select from 'react-select';
import DatePicker, { registerLocale } from 'react-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import ro from 'date-fns/locale/ro'; // the locale you want

import 'react-datepicker/dist/react-datepicker.css';

registerLocale('ro', ro);

// minDate={new Date('01-01-2021')}
// maxDate={new Date()}

const histerectomieOptions = [
  { id: 'E09A923D-0EB7-EB11-8C22-000C2901B5F1', value: 'afecțiune benignă', },
  { id: 'E19A923D-0EB7-EB11-8C22-000C2901B5F1', value: 'afecțiune malignă', }
];

function InformareEdit({ data, saveData, changeTab, auth, error, }) {
  const [inputs, setInputs] = useState({});
  const [doctors, setDoctors] = useState([]);

  // TODO - validare data mobilizarii

  useEffect(() => {
    const inputs = data.attributes;
    setInputs(inputs);
    setDoctors(data.consilierSec1FS1 || []);
    if (Object.keys(inputs).length === 0) return;
    inputs.absentaCongenitalaSec1FS1.value = inputs.absentaCongenitalaSec1FS1.value.toString();
    inputs.histerectomieSec1FS1.value = inputs.histerectomieSec1FS1.value.toString();
    const selectedConsilier = (data.consilierSec1FS1 || []).find(elem => auth?.doctorId === elem.id);
    if (selectedConsilier) {
      inputs.consilierSec1FS1 = selectedConsilier;
    }
  }, []);

  const {
    serieFormular,
    beneficiarSec1FS1,
    dataAnamnezeiSec1FS1,
    cabinetMedicalSec1FS1,
    medicDeFamilieSec1FS1,
    consilierSec1FS1,
    absentaCongenitalaSec1FS1,
    histerectomieSec1FS1,
    tipAfectiuneHisterectomieTotala,
  } = inputs;

  const handleChange = e => {
    const curentVal = { ...inputs[e.target.name], value: e.target.value, };
    setInputs(prevState => ({ ...prevState, [e.target.name]: curentVal, }));
  };

  const handleSelect = (selected, details) => {
    const curentVal = {
      ...inputs[details.name],
      id: selected?.id || '',
      value: selected?.value || '',
    };
    setInputs(prevState => ({ ...prevState, [details.name]: curentVal, }));
  };

  const selectDate = (val, name) => {
    const dataSubmit = moment(val).utc().format();
    const curentVal = { ...inputs[name], value: dataSubmit, };
    setInputs(prevState => ({ ...prevState, [name]: curentVal, }));
  };

  const handleCheck = e => {
    const newVal = e.target.value === 'false' ? 'true' : 'false';
    const curentVal = { ...inputs[e.target.name], value: newVal, };
    setInputs(prevState => ({ ...prevState, [e.target.name]: curentVal, }));
  };

  const save = e => {
    const saveStatus = e.target.id;
    inputs.absentaCongenitalaSec1FS1.value = inputs.absentaCongenitalaSec1FS1.value === 'true';
    inputs.histerectomieSec1FS1.value = inputs.histerectomieSec1FS1.value === 'true';
    const payload = {
      serieFormular,
      beneficiarSec1FS1,
      dataAnamnezeiSec1FS1,
      cabinetMedicalSec1FS1,
      medicDeFamilieSec1FS1,
      consilierSec1FS1,
      absentaCongenitalaSec1FS1,
      histerectomieSec1FS1,
      tipAfectiuneHisterectomieTotala,
    };
    saveData(payload, saveStatus);
  };

  const calcShowSaveBtn = () => {
    if (typeof dataAnamnezeiSec1FS1 === 'undefined' || dataAnamnezeiSec1FS1?.value === '') {
      return false;
    }
    if (typeof consilierSec1FS1 === 'undefined' || consilierSec1FS1?.value === '') {
      return false;
    }
    return true;
  };

  const setTab = e => { changeTab(e.target.id); };

  const showBtns = calcShowSaveBtn();

  return (
    <Card>
      <CardHeader>
        <h3 className="mb-0">
          <div className="d-flex">
            <div>
              <span className="mr-3">1. Informare şi consiliere</span>
            </div>
            {showBtns === true && (
              <div>
                <Button id="draft" size="sm" color="primary" className="btn-rounded" onClick={save}>Draft</Button>
                <Button id="final" size="sm" color="success" className="ml-2 btn-rounded" onClick={save}>Salvează</Button>
              </div>
            )}
            <div className="ml-auto">
              {/* <Button id="-" size="sm" color="secondary" className="ml-auto btn-pill" onClick={setTab}>{'<'}</Button> */}
              <Button id="+" size="sm" color="primary" className="ml-1 btn-pill" onClick={setTab}>{'>'}</Button>
            </div>
          </div>
        </h3>
      </CardHeader>
      <CardBody>
        <Row>
          <Col md={12}>
            <FormGroup>
              <Label>Număr/Serie formular </Label>
              <Input
                className={`${error && error.field === 'serieFormular' ? 'invalid' : ''}`}
                type="text"
                name="serieFormular"
                onChange={handleChange}
                value={serieFormular?.value || ''}
              />
              { error && error.field === 'serieFormular' && <p className="error text-danger mt-1 mb-1">{error.message}</p>}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>Beneficiar</Label>
              <Input
                required
                type="text"
                name="beneficiarSec1FS1"
                disabled
                value={beneficiarSec1FS1?.value || ''}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label>
                Data informare-consiliere *
              </Label>
              <FormGroup row className="align-items-center">
                <Col sm={10} className="pr-0">
                  <Input
                    value={dataAnamnezeiSec1FS1?.value !== '' ? moment(dataAnamnezeiSec1FS1?.value).format('DD-MM-YYYY') : ''}
                    type="text"
                    className={`form-control bg-white ${dataAnamnezeiSec1FS1?.value !== '' ? '' : 'invalid'} ${error && error.field === 'dataAnamnezeiSec1FS1' ? 'invalid' : ''}`}
                    id=""
                    disabled
                    placeholder=""
                  />
                </Col>
                <Col sm={2} className="pl-0">
                  <DatePicker
                    minDate={new Date('01-01-2021')}
                    maxDate={new Date()}
                    name={dataAnamnezeiSec1FS1}
                    locale="ro"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    selected={dataAnamnezeiSec1FS1?.value !== '' ? moment(dataAnamnezeiSec1FS1?.value).toDate() : false}
                    onChange={val => selectDate(val, 'dataAnamnezeiSec1FS1')}
                    customInput={<Button className="form-control"><FontAwesomeIcon icon={faCalendarAlt} /></Button>}
                  />
                </Col>
                { error && error.field === 'dataAnamnezeiSec1FS1' && <p className="error text-danger mt-1 mb-1">{error.message}</p>}
              </FormGroup>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>Centru de informare-consiliere</Label>
              <Input
                required
                type="text"
                name="cabinetMedicalSec1FS1"
                disabled
                value={cabinetMedicalSec1FS1?.value || ''}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label>
                Medic de familie
              </Label>
              <Input
                className={`${error && error.field === 'medicDeFamilieSec1FS1' ? 'invalid' : ''}`}
                type="text"
                name="medicDeFamilieSec1FS1"
                onChange={handleChange}
                value={medicDeFamilieSec1FS1?.value || ''}
              />
              { error && error.field === 'medicDeFamilieSec1FS1' && <p className="error text-danger mt-1 mb-1">{error.message}</p>}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>Personal informare-consiliere *</Label>
              <Select
                value={consilierSec1FS1 || ''}
                onChange={handleSelect}
                getOptionValue={option => option.id}
                getOptionLabel={option => option.value}
                className={(typeof consilierSec1FS1 !== 'undefined' && consilierSec1FS1.value !== '') ? '' : 'invalid'}
                classNamePrefix="roles"
                placeholder=""
                name="consilierSec1FS1"
                options={doctors}
                isClearable
              />
            </FormGroup>
          </Col>
          <Col md={6} />
        </Row>
        <Row>
          <Col>
            <FormGroup check>
              <Input
                type="checkbox"
                checked={absentaCongenitalaSec1FS1?.value === 'true'}
                value={absentaCongenitalaSec1FS1?.value === 'true'}
                name="absentaCongenitalaSec1FS1"
                onChange={handleCheck}
              />
              <Label>Absența congenitală a colului uterin </Label>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup check>

              <Input
                type="checkbox"
                checked={histerectomieSec1FS1?.value === 'true'}
                value={histerectomieSec1FS1?.value === 'true'}
                name="histerectomieSec1FS1"
                onChange={handleCheck}
              />

              <Label>Histerectomie totală </Label>
            </FormGroup>
          </Col>
          {
            histerectomieSec1FS1?.value === 'true' && (
              <Col md={6}>
                <FormGroup>
                  <Label>Tip afecțiune histerectomie totală</Label>
                  <Select
                    isClearable
                    value={tipAfectiuneHisterectomieTotala || ''}
                    onChange={handleSelect}
                    getOptionValue={option => option.id}
                    getOptionLabel={option => option.value}
                    className="basic-single"
                    classNamePrefix="select"
                    placeholder=""
                    name="tipAfectiuneHisterectomieTotala"
                    menuPlacement="top"
                    options={histerectomieOptions}
                  />
                </FormGroup>
              </Col>
            )
          }
        </Row>
      </CardBody>
    </Card>
  );
}

export default InformareEdit;
