import React, { useState, useEffect } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Container, FormGroup, Input, Label, Row, Spinner } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { connect } from 'react-redux';
import { doctorsOperations } from '../../../../redux/ducks/doctors';

function DoctorView({ location, navigate, getSingleDoctor, auth, }) {
  const [doctor, setDoctor] = useState(null);

  useEffect(() => {
    if (!auth?.view.includes('DOCTOR_LIST')) {
      setTimeout(() => { navigate('/'); }, 10);
    } else {
      const splitUrl = location.pathname.split('/doctor/')[1];
      if (splitUrl !== 'new') {
        getDoctorData(splitUrl);
      }
    }
  }, []);

  const getDoctorData = url => {
    const id = url.split('/')[0];
    getSingleDoctor(id)
      .then((res => {
        // this.prefillFields(res.data.data);
        setDoctor(res.data.data);
      }))
      .catch((err => {
        console.log('err: ', err);
      }));
  };

  const goBack = () => {
    const { state, } = location;
    if (state) {
      const { state: { from: { search, pathname, }, }, } = location;
      navigate(`${pathname}${search}`);
    } else {
      navigate('/doctor');
    }
  };

  const goEdit = () => {
    const { id, } = doctor;
    navigate(`/doctor/${id}/edit`);
  };

  const calcAddress = address => {
    if (address === null) return '';
    const { city, county, region, } = address;
    return `${city ? city.label : ''}${county ? `, ${county.label}` : ''}${region ? `, ${region.label}` : ''}`;
  };

  const calcContact = contact => {
    if (contact === null) return '';
    const { label, contactType, } = contact;
    return `${label || ''}${contactType.label ? `, ${contactType.label}` : ''}`;
  };

  if (doctor === null) {
    return (
      <Container fluid className="d-flex">
        <Row className="pt-5 mt-5 justify-content-center w-100">
          <Spinner />
        </Row>
      </Container>
    );
  }

  const { person, organization, active, seal, casContract, specializationType, services = [], } = doctor;
  const { addresses = [], contacts = [], } = person;

  const servicesDom = services.map(a => a.label).join(', ');
  const addressesDom = addresses.map((elem, i) => <div key={i}>{calcAddress(elem)}</div>);
  const contactsDom = contacts.map((elem, i) => <div key={i}>{calcContact(elem)}</div>);
  return (
    <Container fluid className="d-flex">
      <Row className="justify-content-center w-100">
        <Col sm={8}>
          <Card className="mt-3">
            <CardHeader>
              <h3 className="mb-0">Persoană Furnizor</h3>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md={4} className="mb-3">
                  <Label className="mb-0 font-weight-bold">Nume</Label>
                  <div>{person?.lastName || '-'}</div>
                </Col>
                <Col md={4} className="mb-3">
                  <Label className="mb-0 font-weight-bold">Prenume</Label>
                  <div>{person?.firstName || '-'}</div>
                </Col>
                <Col md={4} className="mb-3">
                  <Label className="mb-0 font-weight-bold">Stare</Label>
                  <div>{active ? 'Activ' : 'Inactiv' }</div>
                </Col>
              </Row>
              <Row>
                <Col md={4} className="mb-3">
                  <Label className="mb-0 font-weight-bold">CNP</Label>
                  <div>{person?.cnp || '-'}</div>
                </Col>
                <Col md={4} className="mb-3">
                  <Label className="mb-0 font-weight-bold">Gen</Label>
                  <div>{person?.gender || ''}</div>
                </Col>
                <Col md={4} className="mb-3">
                  <Label className="mb-0 font-weight-bold">Parafă</Label>
                  <div>{seal || ''}</div>
                </Col>
              </Row>
              <Row>
                <Col md={4} className="mb-3">
                  <Label className="mb-0 font-weight-bold">Data nașterii</Label>
                  <div>{person?.birthDate ? moment(person.birthDate).format('DD-MM-YYYY') : ''}</div>
                </Col>
                <Col md={4} className="mb-3">
                  <Label className="mb-0 font-weight-bold">Mediu</Label>
                  <div>{person?.habitat || ''}</div>
                </Col>
                <Col md={4} className="mb-3">
                  <Label className="mb-0 font-weight-bold">CAS Contract</Label>
                  <div>{casContract || ''}</div>
                </Col>
              </Row>
              <Row>
                <Col md={4} className="mb-3">
                  <Label className="mb-0 font-weight-bold">Furnizor </Label>
                  <div>{organization?.label || ''}</div>
                </Col>
                <Col md={4} className="mb-3">
                  <Label className="mb-0 font-weight-bold">Servicii</Label>
                  <div>{servicesDom}</div>
                </Col>
                <Col md={4} className="mb-3">
                  <Label className="mb-0 font-weight-bold">Tip</Label>
                  <div>{specializationType?.label || ''}</div>
                </Col>
              </Row>

              <Row>
                <Col md={4} className="mb-3">
                  <Label className="mb-0 font-weight-bold">Adrese </Label>
                  <div>{addressesDom}</div>
                </Col>
                <Col md={4} className="mb-3">
                  <Label className="mb-0 font-weight-bold">Contacte</Label>
                  <div>{contactsDom}</div>
                </Col>
              </Row>

              <Row className="mt-3">
                <Col md={6} />
                <Col md={6} className="text-right">
                  <Button
                    onClick={goBack}
                    color="secondary"
                  >
                    <FontAwesomeIcon icon={faArrowLeft} />
                    <span className="ml-1">Înapoi</span>
                  </Button>
                  {
                    auth?.update.includes('DOCTOR_UPDATE') && (
                      <Button
                        onClick={goEdit}
                        color="primary"
                        className="ml-1"
                      >
                        <FontAwesomeIcon icon={faPencilAlt} />
                        <span className="ml-1">Editare</span>
                      </Button>
                    )
                  }
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
const mapStateToProps = state => ({
  auth: state.auth,
  doctors: state.doctors.doctors,
  selectedDoctor: state.organizations.selectedDoctor,
});

const mapDispatchToProps = dispatch => ({
  getSingleDoctor: id => dispatch(doctorsOperations.getSingleDoctor(id)),
  selectDoctor: data => dispatch(doctorsOperations.selectDoctor(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DoctorView);
