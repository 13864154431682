import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faCalendarAlt, faEye, faPencilAlt, faSave, faSort, faTimes } from '@fortawesome/free-solid-svg-icons';
import {
  Button,
  CardBody,
  Col,
  FormGroup,
  Input,
  Label,
  Row
} from 'reactstrap';
import moment from 'moment';
import CasefilesForPatientTableRow from './CasefilesForPatientTableRow';
import TsfEntityDatesModal from './TsfEntityDatesModal';

function TableHeadEntities() {
  return (
    <thead>
      <tr>
        <th scope="col">
          <span>Detalii</span>
        </th>
        <th scope="col">
          <span>Creat la data</span>
        </th>
        <th scope="col">
          <span>Modificat la data</span>
        </th>
        <th className="text-center" scope="col">
          <span>Validat OIR</span>
        </th>
        <th className="text-center" scope="col">
          <span>Stare</span>
        </th>
        <th scope="col">
          <span>Status</span>
        </th>
        <th scope="col" />
      </tr>
    </thead>
  );
}

function TsfEntityRow({ data, openDatesModal, openModal, auth, casefile, }) {
  const {
    d0InviteDate,
    d0InviteDoctor,
    d2InformDate,
    d2InformDoctor,
    d1ScheduleDate = '',
    d3InviteDate = '',
    d4ScheduleDate = '',
    d5InviteDate = '',
    d6ScheduleDate = '',
  } = data;
  return (
    <div className=" border border-dark mb-5">
      <table aria-describedby="page-heading" className="table ">
        <TableHeadEntities />
        <tbody>
          <CasefilesForPatientTableRow
            casefile={casefile}
            canActivate={auth?.activate.includes('TSFENTITY_ACTIVATE') || false}
            canEdit={auth?.update.includes('TSFENTITY_UPDATE') || false}
            openModal={openModal}
            key={data.id}
            data={data}
          />
        </tbody>
      </table>
      <div className="w-100 border border-dark border-top-0" />
      <div className="p-2">
        <div className="text-danger text-right">
          <button onClick={() => openDatesModal(data)} className="btn btn-info btn-sm btn-pill mr-1">
            <FontAwesomeIcon icon={faPencilAlt} />
          </button>
        </div>
        <div className="">
          <Row>
            <Col md={6}>
              {
            d0InviteDate && d0InviteDate !== '' && (
              <FormGroup row className="mb-0">
                <Label className="font-weight-bold" sm={3}>Data Invitare Screening:</Label>
                <Label sm={9}>{moment(d0InviteDate).format('DD-MM-YYYY')}</Label>
              </FormGroup>
            )
          }
              {
            d0InviteDoctor && (
              <FormGroup row className="mb-0">
                <Label className="font-weight-bold" sm={3}>Personal Invitare Screening:</Label>
                <Label sm={9}>
                  { d0InviteDoctor?.person?.fullName || '' }
                </Label>
              </FormGroup>
            )
          }
            </Col>

            <Col md={6}>
              {
                d1ScheduleDate && d1ScheduleDate !== '' && (
                  <FormGroup row className="mb-0">
                    <Label className="font-weight-bold" sm={3}>Data Programare Screening:</Label>
                    <Label sm={9}>{moment(d1ScheduleDate).format('DD-MM-YYYY')}</Label>
                  </FormGroup>
                )
              }

              {
                d2InformDate && d2InformDate !== '' && (
                  <FormGroup row className="mb-0">
                    <Label className="font-weight-bold" sm={3}>Data Instiintare Rezultat:</Label>
                    <Label sm={9}>{moment(d2InformDate).format('DD-MM-YYYY')}</Label>
                  </FormGroup>
                )
              }
              {d2InformDoctor && (
                <FormGroup row className="mb-0">
                  <Label className="font-weight-bold" sm={3}>Personal Instiintare Rezultat:</Label>
                  <Label sm={9}>
                    { d2InformDoctor?.person?.fullName || '' }
                  </Label>
                </FormGroup>
              )}
            </Col>
          </Row>

          <Row className="mt-4">
            <Col md={6}>
              {
                d3InviteDate && d3InviteDate !== '' && (
                  <FormGroup row className="mb-0">
                    <Label className="font-weight-bold" sm={3}>Data Invitare Triaj:</Label>
                    <Label sm={9}>{moment(d3InviteDate).format('DD-MM-YYYY')}</Label>
                  </FormGroup>
                )
              }

              {
                d4ScheduleDate && d4ScheduleDate !== '' && (
                  <FormGroup row className="mb-0">
                    <Label className="font-weight-bold" sm={3}>Data Programare Triaj:</Label>
                    <Label sm={9}>{moment(d4ScheduleDate).format('DD-MM-YYYY')}</Label>
                  </FormGroup>
                )
              }

            </Col>
            <Col md={6}>

              {
                d5InviteDate && d5InviteDate !== '' && (
                  <FormGroup row className="mb-0">
                    <Label className="font-weight-bold" sm={3}>Data Invitare Colposcopie/Biopsie:</Label>
                    <Label sm={9}>{moment(d5InviteDate).format('DD-MM-YYYY')}</Label>
                  </FormGroup>
                )
              }
              {
                d6ScheduleDate && d6ScheduleDate !== '' && (
                  <FormGroup row className="mb-0 d-flex">
                    <Label className="font-weight-bold" sm={3}>Data Programare Colposcopie/Biopsie:</Label>
                    <Label sm={9}>{moment(d6ScheduleDate).format('DD-MM-YYYY')}</Label>
                  </FormGroup>
                )
              }
            </Col>
          </Row>
        </div>
      </div>

    </div>
  );
}

function CasefilesForPatientTable({ casefile, tableData, openModal, auth, closeModalAndRefresh, }) {
  const [dateModal, setDateModal] = useState(null);
  const [error, setError] = useState(null);

  const openDatesModal = data => {
    setDateModal(data);
  };

  const close = () => {
    setDateModal(null);
  };

  const confirm = () => {
    // get data
    // close modal
    closeModalAndRefresh();
    setTimeout(() => {
      close();
    }, 300);
  };

  const domPatientFiles = tableData.map(elem => (
    <TsfEntityRow
      casefile={casefile}
      auth={auth}
      openModal={openModal}
      openDatesModal={openDatesModal}
      key={elem.id}
      data={elem}
    />
  ));

  return (
    <div id="entities" className="table-responsive-xl">
      {dateModal && (
        <TsfEntityDatesModal
          casefile={casefile}
          data={dateModal}
          confirm={confirm}
          close={close}
        />
      )}
      { domPatientFiles}
    </div>
  );
}

export default CasefilesForPatientTable;
