import React, { Component } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Spinner } from 'reactstrap';
import { connect } from 'react-redux';
import { RaportareOirFiltersExtended } from './components/index';

import './index.scss';
import { doctorsOperations } from '../../../redux/ducks/doctors';
import { organizationsOperations } from '../../../redux/ducks/organizations';

const defaultFilters = {
  county: '',
  label: '',
  region: '',
  report: '',
  year: '',
  month: '',
};
class RaportareOir extends Component {
  constructor() {
    super();

    this.setFilters = this.setFilters.bind(this);
    this.addFilterAndSearch = this.addFilterAndSearch.bind(this);
    this.resetFilters = this.resetFilters.bind(this);
    this.parseUrlAndSetFilters = this.parseUrlAndSetFilters.bind(this);
    this.getDoctors = this.getDoctors.bind(this);
    this.getDoctorsData = this.getDoctorsData.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.calcDisabled = this.calcDisabled.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      filters: defaultFilters,
      doctors: [],
      key: 0,
      loading: false,
      organizationsExt: [],
    };
  }

  componentDidMount() {
    const { auth, navigate, getOrganizationsExt, } = this.props;
    if (auth) {
      const { view, } = auth;
      if (view && !view.includes('ORGANIZATION_LIST')) {
        setTimeout(() => {
          navigate('/');
        }, 10);
      }
    }

    getOrganizationsExt()
      .then((res => {
        this.setState({ organizationsExt: res.data.data, });
      }))
      .catch((err => {
        console.log('err: ', err);
      }));
  }

  parseUrlAndSetFilters() {
    const { searchParams, } = this.props;
    const parsedFilters = Object.fromEntries([...searchParams]);
    if (Object.keys(parsedFilters).length > 0) {
      this.setFilters(parsedFilters);
    } else {
      this.resetFilters();
    }
  }

  handleChange(selected, details) {
    const { name, } = details;

    if (selected === null) {
      this.addFilterAndSearch({ [name]: '', }, false, name);
    } else {
      const { value, } = selected;
      this.addFilterAndSearch({ [name]: value, });
    }
  }

  getDoctors() {
    const { location: { pathname, }, navigate, } = this.props;
    const { filters, } = this.state;

    // const formatFilters = Object.entries(filters).filter(elem => elem[1] !== '').map(elem => elem.join('=')).join('&');
    // navigate(`${pathname}?${formatFilters}`);
    // this.getDoctorsData(`${pathname}s?${formatFilters}`);
  }

  getDoctorsData(url) {
    const { getDoctors, } = this.props;
    this.setState({ loading: true, });

    getDoctors(url)
      .then((res => {
        const { data, } = res.data;
        const { filters, } = this.state;
        this.setState({
          doctors: data,
          loading: false,
        });
      }))
      .catch((err => {
        console.log('err: ', err);
        this.setState({ loading: false, });
      }));
  }

  setFilters(data) {
    this.setState({ filters: data, }, () => {
      this.getDoctors();
    });
  }

  addFilterAndSearch(data, pageSelect, removeKey) {
    const { filters, } = this.state;
    if (removeKey) delete filters[removeKey];
    const newFilters = {
      ...filters,
      ...data,
    };
    this.setFilters(newFilters);
  }

  resetFilters() {
    this.setState({ filters: defaultFilters, }, () => {
      this.getDoctors();
    });
  }

  calcDisabled() {
    const { filters, } = this.state;
    const required = [];
    Object.keys(filters).forEach(key => {
      if (key !== 'county') {
        required.push(filters[key]);
      }
    });
    // if (Object.values(filters).includes('')) {
    if (required.includes('')) {
      return true;
    }
    return false;
  }

  handleSubmit() {
    const { getReportOir, } = this.props;
    this.setState({ loading: true, });
    const { filters, } = this.state;

    getReportOir({ ...filters, })
      .then(response => {
        this.setState({ loading: false, });
        const timestamp = new Date().valueOf();
        const fileName = `${timestamp}.pdf`;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
      })
      .catch(err => {
        console.log('err: ', err);
      })

      .catch((err => {
        console.log('err: ', err);
        this.setState({ loading: false, });
      }));
  }

  render() {
    const { filters, key, loading, organizationsExt, } = this.state;
    const { location, auth, } = this.props;

    const isDisabled = this.calcDisabled();
    return (
      <>
        {
          loading && (
            <div
              className="loading-container"
            >
              <Spinner />
            </div>
          )
        }
        <div className={`container-fluid position-relative ${loading ? 'no-events loading-data' : ''}`}>
          <Card className="mt-4">
            <CardHeader className="position-relative">
              <div className="d-inline-block">
                <h3><span>Raportare Oir</span></h3>
              </div>
            </CardHeader>
            <CardBody>
              <RaportareOirFiltersExtended
                organizationsExt={organizationsExt}
                auth={auth}
                key={key}
                addFilterAndSearch={this.addFilterAndSearch}
                resetFilters={this.resetFilters}
                filters={filters}
                handleChange={this.handleChange}
              />
              <Col className="text-center">
                <Button
                  onClick={isDisabled ? () => {} : this.handleSubmit}
                  className="btn-primary w-25 mb-4"
                  style={{ marginTop: 32, }}
                  disabled={isDisabled}
                >
                  Download
                </Button>
              </Col>
            </CardBody>
          </Card>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  doctors: state.doctors.doctors,
  selectedDoctor: state.doctors.selectedDoctor,
});

const mapDispatchToProps = dispatch => ({
  getDoctors: url => dispatch(doctorsOperations.getDoctors(url)),
  updateDoctor: data => dispatch(doctorsOperations.updateDoctor(data)),
  getOrganizationsExt: () => dispatch(organizationsOperations.getOrganizationsExt()),
  getReportOir: data => dispatch(organizationsOperations.getReportOir(data)),
  // selectOrganization: data => dispatch(organizationsOperations.selectOrganization(data)),
  // updateOrganization: data => dispatch(organizationsOperations.updateOrganization(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RaportareOir);
