import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, CardHeader, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import Select from 'react-select';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';

const statusCol = [
  { id: 'EF9A923D-0EB7-EB11-8C22-000C2901B5F1', value: 'cu leziuni vizibile', code: 'optiuni_aspectCol_cu_leziuni_vizibile', },
  { id: 'F09A923D-0EB7-EB11-8C22-000C2901B5F1', value: 'fără leziuni vizibile', code: 'optiuni_aspectCol_fara_leziuni_vizibile', }
];

const citologieData = [
  { id: 'F59A923D-0EB7-EB11-8C22-000C2901B5F1', value: 'convențională ', code: 'optiuni_citologie_conventionala', },
  { id: 'F69A923D-0EB7-EB11-8C22-000C2901B5F1', value: 'în mediul lichid', code: 'optiuni_mediu_lichid', }
];

const hpvData = [
  { id: 'FA9A923D-0EB7-EB11-8C22-000C2901B5F1', value: 'Care HPV', code: 'hpv_CARE', },
  { id: 'F89A923D-0EB7-EB11-8C22-000C2901B5F1', value: 'HC2', code: 'hpv_HC2', },
  { id: 'F99A923D-0EB7-EB11-8C22-000C2901B5F1', value: 'PCR', code: 'hpv_PCR', }
];

const sangerariData = [
  { id: 'EC9A923D-0EB7-EB11-8C22-000C2901B5F1', value: 'la contact', code: 'optiuni_sangerari_la_contact', },
  { id: 'ED9A923D-0EB7-EB11-8C22-000C2901B5F1', value: 'spontane', code: 'optiuni_sangerari_spontane', }
];

const statusHormonalData = [
  { id: 'E79A923D-0EB7-EB11-8C22-000C2901B5F1', value: 'Alăptare', code: 'status_hormonal_alaptare', },
  { id: 'E39A923D-0EB7-EB11-8C22-000C2901B5F1', value: 'Ciclică', code: 'status_hormonal_ciclica', },
  { id: 'E69A923D-0EB7-EB11-8C22-000C2901B5F1', value: 'Lăuzie (12 sapt.)', code: 'status_hormonal_lauzie', },
  { id: 'E59A923D-0EB7-EB11-8C22-000C2901B5F1', value: 'Menopauză', code: 'status_hormonal_menopauza', },
  { id: '689B923D-0EB7-EB11-8C22-000C2901B5F1', value: 'Preparate hormonale', code: 'status_hormonal_preparate', },
  { id: 'E49A923D-0EB7-EB11-8C22-000C2901B5F1', value: 'Sarcină', code: 'status_hormonal_sarcina', },
  { id: '679B923D-0EB7-EB11-8C22-000C2901B5F1', value: 'Tratament anticoncepționale', code: 'status_hormonal_anticonceptionale', }
];

const statusPostData = [
  { id: 'EA9A923D-0EB7-EB11-8C22-000C2901B5F1', value: 'post-chimioterapie', code: 'status_postterapeutic_postchimioterapie', },
  { id: 'E99A923D-0EB7-EB11-8C22-000C2901B5F1', value: 'post-radioterapie', code: 'status_postterapeutic_postradioterapie', }
];

function ConsultatieSiRecoltareEdit({ data, changeTab, saveData, auth, error, }) {
  const [inputs, setInputs] = useState({});
  const [doctors, setDoctors] = useState([]);
  const [centruRecoltareSec2FS1, setCentruRecoltareSec2FS1] = useState('');

  const checkboxInputs = [
    'indrumarePrelevareSec2FS1',
    'statusPostvaccinareHPV',
    'purtatoareIUDSec2FS1',
    'leucoreeSec2FS1',
    'cauterizariColSec2FS1',
    'biopsiiDeColSec2FS1',
    'cervicalaSec2FS1'
  ];

  useEffect(() => {
    const inputs = data.attributes;
    setInputs(inputs);
    setDoctors(data.medicRecoltorSec2FS1 || []);
    setCentruRecoltareSec2FS1(data?.centruRecoltareSec2FS1 || '');
    if (Object.keys(inputs).length === 0) return;
    checkboxInputs.forEach(elem => {
      inputs[elem].value = inputs[elem].value.toString();
    });
    const selectedMedic = (data.medicRecoltorSec2FS1 || []).find(elem => auth?.doctorId === elem.id);
    if (selectedMedic) {
      inputs.medicRecoltorSec2FS1 = selectedMedic;
    }
  }, []);

  const {
    recoltareFlaconLabelSec2FS1,
    indrumarePrelevareSec2FS1,
    medicRecoltorSec2FS1,
    numarFlaconSauLama,
    dataRecoltareSec2FS1,
    dataUltimeiMenstruatiiSec2FS1,
    statusPostvaccinareHPV,
    statusHormonalSec2FS1,
    aspectColOptiuniSec2FS1,
    statusPostTerapeuticListSec2FS1 = [],
    antecedenteColSec2FS1,
    optiuniSangerariSec2FS1 = [],
    purtatoareIUDSec2FS1,
    leucoreeSec2FS1,
    cauterizariColSec2FS1,
    biopsiiDeColSec2FS1,
    cervicalaSec2FS1,
    HPVSec2FS1,
    citologieSec2FS1,
    diagnosticBiopsieColSec2FS1,
  } = inputs;

  const handleChange = e => {
    const curentVal = { ...inputs[e.target.name], value: e.target.value, };
    setInputs(prevState => ({ ...prevState, [e.target.name]: curentVal, }));
  };

  // const handleSelect = (selected, details) => {
  //   const curentVal = { ...inputs[details.name], id: selected?.value || '', value: selected?.label || '', };
  //   setInputs(prevState => ({ ...prevState, [details.name]: curentVal, }));
  // };

  const handleSelect = (selected, details) => {
    const curentVal = {
      ...inputs[details.name],
      id: selected?.id || '',
      value: selected?.value || '',
    };
    setInputs(prevState => ({ ...prevState, [details.name]: curentVal, }));
  };

  const handleMultipleSelect = (value, name) => {
    setInputs(prevState => ({ ...prevState, [name]: { value, }, }));
  };

  const selectDate = (val, name) => {
    const dataSubmit = moment(val).utc().format();
    const curentVal = { ...inputs[name], value: dataSubmit, };
    setInputs(prevState => ({ ...prevState, [name]: curentVal, }));
  };

  const handleCheck = e => {
    const newVal = e.target.value === 'false' ? 'true' : 'false';
    const curentVal = { ...inputs[e.target.name], value: newVal, };
    setInputs(prevState => ({ ...prevState, [e.target.name]: curentVal, }));
  };

  const calcShowSaveBtn = () => {
    if (typeof dataRecoltareSec2FS1 === 'undefined' || dataRecoltareSec2FS1?.value === '') {
      return false;
    }
    if (typeof medicRecoltorSec2FS1 === 'undefined' || medicRecoltorSec2FS1?.value === '') {
      return false;
    }
    return true;
  };

  const save = e => {
    const saveStatus = e.target.id;
    checkboxInputs.forEach(elem => {
      inputs[elem].value = inputs[elem].value === 'true';
    });
    const payload = {
      recoltareFlaconLabelSec2FS1,
      // centruRecoltareSec2FS1,
      indrumarePrelevareSec2FS1,
      medicRecoltorSec2FS1,
      numarFlaconSauLama,
      dataRecoltareSec2FS1,
      dataUltimeiMenstruatiiSec2FS1,
      statusPostvaccinareHPV,
      statusHormonalSec2FS1,
      aspectColOptiuniSec2FS1,
      statusPostTerapeuticListSec2FS1,
      antecedenteColSec2FS1,
      optiuniSangerariSec2FS1,
      purtatoareIUDSec2FS1,
      leucoreeSec2FS1,
      cauterizariColSec2FS1,
      biopsiiDeColSec2FS1,
      cervicalaSec2FS1,
      HPVSec2FS1,
      citologieSec2FS1,
      diagnosticBiopsieColSec2FS1,
    };
    saveData(payload, saveStatus);
  };

  const setTab = e => { changeTab(e.target.id); };

  const showBtns = calcShowSaveBtn();

  return (
    <Card>
      <CardHeader>
        <h3 className="mb-0">
          <div className="d-flex">
            <div>
              <span className="mr-3">2. Consultație şi recoltare</span>
            </div>
            {showBtns === true && (
              <div>
                <Button id="draft" size="sm" color="primary" className="btn-rounded" onClick={save}>Draft</Button>
                <Button id="final" size="sm" color="success" className="ml-2 btn-rounded" onClick={save}>Salvează</Button>
              </div>
            )}
            <div className="ml-auto">
              <Button id="-" size="sm" color="secondary" className="ml-auto btn-pill" onClick={setTab}>{'<'}</Button>
              <Button id="+" size="sm" color="primary" className="ml-1 btn-pill" onClick={setTab}>{'>'}</Button>
            </div>
          </div>
        </h3>
      </CardHeader>
      <CardBody className="pb-5">
        <Col
          className="underline-hover form-group dont-display ng-untouched ng-pristine"
          style={{ backgroundColor: 'rgba(233, 236, 239, 0.3)', }}
        >
          <Label>
            Recoltarea flaconului/lamei
            {' '}
            <span>{recoltareFlaconLabelSec2FS1?.value || ''}</span>
          </Label>
        </Col>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>Centru recoltare</Label>
              <Input
                required
                type="text"
                name="centruRecoltareSec2FS1"
                disabled
                value={centruRecoltareSec2FS1?.value || ''}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup check>
              <Input
                type="checkbox"
                checked={indrumarePrelevareSec2FS1?.value === 'true'}
                value={indrumarePrelevareSec2FS1?.value === 'true'}
                name="indrumarePrelevareSec2FS1"
                onChange={handleCheck}
              />

              <Label>Auto-prelevare sub îndrumare medic </Label>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>Medic/AMC/Recoltor *</Label>
              <Select
                isClearable
                value={medicRecoltorSec2FS1 || ''}
                onChange={handleSelect}
                getOptionValue={option => option.id}
                getOptionLabel={option => option.value}
                className={(typeof medicRecoltorSec2FS1 !== 'undefined' && medicRecoltorSec2FS1.value !== '') ? '' : 'invalid'}
                classNamePrefix="roles"
                placeholder=""
                name="medicRecoltorSec2FS1"
                options={doctors}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label>
                Numărul flaconului/lamei (autogenerat de sistem după salvare)
              </Label>
              <Input
                type="text"
                name="numarFlaconSauLama"
                disabled
                value={numarFlaconSauLama?.value || ''}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>Data recoltării flaconului/lamei *</Label>
              <FormGroup row className="align-items-center">
                <Col sm={10} className="pr-0">
                  <Input
                    defaultValue={dataRecoltareSec2FS1 ? moment(dataRecoltareSec2FS1?.value).format('DD-MM-YYYY') : ''}
                    type="text"
                    className={`form-control bg-white ${error && error.field === 'dataRecoltareSec2FS1' ? 'invalid' : ''} ${dataRecoltareSec2FS1?.value === '' ? 'invalid' : ''} ${typeof dataRecoltareSec2FS1 === 'undefined' ? 'invalid' : ''}`}
                    placeholder=""
                    disabled
                  />
                </Col>
                <Col sm={2} className="pl-0">
                  <DatePicker
                    minDate={new Date('01-01-2021')}
                    maxDate={new Date()}
                    name={dataRecoltareSec2FS1}
                    locale="ro"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    selected={moment(dataRecoltareSec2FS1?.value).toDate()}
                    onChange={val => selectDate(val, 'dataRecoltareSec2FS1')}
                    customInput={<Button className="form-control"><FontAwesomeIcon icon={faCalendarAlt} /></Button>}
                  />
                </Col>
                { error && error.field === 'dataRecoltareSec2FS1' && <p className="error text-danger mt-1 mb-1 pl-3">{error.message}</p>}
              </FormGroup>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label>
                Data ultimei menstruații
              </Label>
              <FormGroup row className="align-items-center">
                <Col sm={10} className="pr-0">
                  <Input
                    defaultValue={dataUltimeiMenstruatiiSec2FS1 ? moment(dataUltimeiMenstruatiiSec2FS1?.value).format('DD-MM-YYYY') : ''}
                    type="text"
                    className="form-control bg-white"
                    id=""
                    disabled
                    placeholder=""
                  />
                </Col>
                <Col sm={2} className="pl-0">
                  <DatePicker
                    name={dataUltimeiMenstruatiiSec2FS1}
                    locale="ro"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    selected={moment(dataUltimeiMenstruatiiSec2FS1?.value).toDate()}
                    onChange={val => selectDate(val, 'dataUltimeiMenstruatiiSec2FS1')}
                    customInput={<Button className="form-control"><FontAwesomeIcon icon={faCalendarAlt} /></Button>}
                  />
                </Col>
              </FormGroup>
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <FormGroup check>
              <Input
                type="checkbox"
                checked={statusPostvaccinareHPV?.value === 'true'}
                value={statusPostvaccinareHPV?.value === 'true'}
                name="statusPostvaccinareHPV"
                onChange={handleCheck}
              />
              <Label>Status postvaccinare HPV </Label>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label>
                Status hormonal
              </Label>
              <Select
                isClearable
                value={statusHormonalSec2FS1 || ''}
                onChange={handleSelect}
                getOptionValue={option => option.id}
                getOptionLabel={option => option.value}
                className="basic-single"
                classNamePrefix="select"
                placeholder=""
                name="statusHormonalSec2FS1"
                options={statusHormonalData}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>
                Status post-terapeutic
              </Label>
              <Select
                isMulti
                isClearable
                getOptionValue={option => option.id}
                getOptionLabel={option => option.value}
                value={statusPostTerapeuticListSec2FS1?.value || []}
                onChange={val => { handleMultipleSelect(val, 'statusPostTerapeuticListSec2FS1'); }}
                className="basic-single"
                classNamePrefix="select"
                placeholder=""
                name="statusPostTerapeuticListSec2FS1"
                options={statusPostData}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label>
                Aspectul colului
              </Label>
              <Select
                isClearable
                value={aspectColOptiuniSec2FS1 || ''}
                onChange={handleSelect}
                getOptionValue={option => option.id}
                getOptionLabel={option => option.value}
                className="basic-single"
                classNamePrefix="select"
                placeholder=""
                name="aspectColOptiuniSec2FS1"
                options={statusCol}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>
                Sângerari
              </Label>
              <Select
                isMulti
                getOptionValue={option => option.id}
                getOptionLabel={option => option.value}
                isClearable
                value={optiuniSangerariSec2FS1?.value || []}
                onChange={val => { handleMultipleSelect(val, 'optiuniSangerariSec2FS1'); }}
                className="basic-single"
                classNamePrefix="select"
                placeholder=""
                name="optiuniSangerariSec2FS1"
                options={sangerariData}
              />
            </FormGroup>
          </Col>
        </Row>
        <Col
          className="underline-hover form-group dont-display ng-untouched ng-pristine"
          style={{ backgroundColor: 'rgba(233, 236, 239, 0.3)', }}
        >
          <Label>În antecedente</Label>
        </Col>

        <Row>
          <Col md={6}>
            <FormGroup check>
              <Input
                type="checkbox"
                checked={purtatoareIUDSec2FS1?.value === 'true'}
                value={purtatoareIUDSec2FS1?.value === 'true'}
                name="purtatoareIUDSec2FS1"
                onChange={handleCheck}
              />
              <Label>Purtătoare de IUD </Label>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup check>
              <Input
                type="checkbox"
                checked={leucoreeSec2FS1?.value === 'true'}
                value={leucoreeSec2FS1?.value === 'true'}
                name="leucoreeSec2FS1"
                onChange={handleCheck}
              />
              <Label>Leucoree </Label>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup check>
              <Input
                type="checkbox"
                checked={cauterizariColSec2FS1?.value === 'true'}
                value={cauterizariColSec2FS1?.value === 'true'}
                name="cauterizariColSec2FS1"
                onChange={handleCheck}
              />

              <Label> Cauterizări ale colului </Label>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup check>
              <Input
                type="checkbox"
                checked={biopsiiDeColSec2FS1?.value === 'true'}
                value={biopsiiDeColSec2FS1?.value === 'true'}
                name="biopsiiDeColSec2FS1"
                onChange={handleCheck}
              />
              <Label>Biopsii de col  </Label>
            </FormGroup>
          </Col>
          {
            biopsiiDeColSec2FS1?.value === 'true' && (
              <Col>
                <FormGroup>
                  <Label>Diagnostic/Descriere</Label>
                  <Input
                    type="textarea"
                    rows={3}
                    name="diagnosticBiopsieColSec2FS1"
                    onChange={handleChange}
                    value={diagnosticBiopsieColSec2FS1?.value || ''}
                  />
                </FormGroup>
              </Col>
            )
          }
        </Row>
        <Col
          className="underline-hover form-group dont-display ng-untouched ng-pristine"
          style={{ backgroundColor: 'rgba(233, 236, 239, 0.3)', }}
        >
          <Label>Origine eșantion </Label>
        </Col>
        <Row>
          <Col md={12}>
            <FormGroup check>
              <Input
                type="checkbox"
                checked={cervicalaSec2FS1?.value === 'true'}
                value={cervicalaSec2FS1?.value === 'true'}
                name="cervicalaSec2FS1"
                onChange={handleCheck}
              />
              <Label> Cervicala </Label>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>Citologie</Label>
              <Select
                value={citologieSec2FS1 || ''}
                onChange={handleSelect}
                getOptionValue={option => option.id}
                getOptionLabel={option => option.value}
                className="basic-single"
                classNamePrefix="select"
                placeholder=""
                name="citologieSec2FS1"
                menuPlacement="top"
                isClearable
                options={citologieData}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup check>
              <Label>HPV</Label>
              <Select
                value={HPVSec2FS1 || ''}
                onChange={handleSelect}
                getOptionValue={option => option.id}
                getOptionLabel={option => option.value}
                className="basic-single"
                classNamePrefix="select"
                placeholder=""
                menuPlacement="top"
                isClearable
                name="HPVSec2FS1"
                options={hpvData}
              />
            </FormGroup>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}

export default ConsultatieSiRecoltareEdit;
