import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
// import RavenMiddleware from "redux-raven-middleware";
// import LogRocket from "logrocket";

import * as reducers from './ducks';
import { loadState, saveState } from './localStorage';
import Helpers from '../support/helpers/helpers';
import api from './middlewares/api';

export default function configureStore() {
  const enhancers = [];
  const loggerMiddleware = createLogger({
    predicate: (getState, action) => {
      if (action.type !== undefined) {
        return action;
      }
    },
  });

  let middleware = [];
  if (process.env.REACT_APP_NODE_ENV !== 'production') {
    middleware = [
      api,
      // loggerMiddleware,
      thunk
    ];
  } else {
    middleware = [
      api,
      thunk
    ];
  }
  const persistedState = loadState();

  const rootReducer = combineReducers({ ...reducers, });

  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-undef
    const { devToolsExtension, } = window;

    if (typeof devToolsExtension === 'function') {
      enhancers.push(devToolsExtension());
    }
  }

  const composedEnhancers = compose(
    applyMiddleware(...middleware),
    // applyMiddleware( ...middleware, LogRocket.reduxMiddleware() ),
    ...enhancers
  );

  const store = createStore(
    rootReducer,
    persistedState,
    composedEnhancers
  );

  store.subscribe(() => {
    Helpers.throttle(saveState({
      auth: store.getState().auth,
      // users: store.getState().users.users,
    }), 1000);
  });

  return store;
}
