import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortAsc, faSortDesc } from '@fortawesome/free-solid-svg-icons';
import CasefilesTableRow from './CasefilesTableRow';
import UserActiveModal from '../../Utilizatori/components/UserActiveModal';

function CasefilesTable({ tableData, filters, setSortFilter, setActiveStatus, auth, }) {
  const [modal, setModal] = useState(false);
  const [selected, setSelected] = useState(null);
  const handleActiveModal = data => {
    setModal(modal !== true);
    setSelected(data);
  };

  const domPatients = tableData.map(elem => (
    <CasefilesTableRow
      canEdit={auth?.update.includes('CASEFILE_UPDATE') || false}
      handleActiveModal={handleActiveModal}
      key={elem.id}
      data={elem}
    />
  ));

  const { sortBy, sortDir, } = filters;

  const calcIcon = field => {
    if (field !== sortBy) {
      return faSort;
    }
    return sortDir === 'asc' ? faSortAsc : faSortDesc;
  };

  return (
    <>
      { modal && (
        <UserActiveModal
          dosar
          selected={selected}
          close={handleActiveModal}
          setActive={setActiveStatus}
        />
      )}
      <div id="entities" className="table-responsive-xl mt-5">
        <table aria-describedby="page-heading" className="table">
          <thead>
            <tr>
              <th style={{ width: 300, }} onClick={setSortFilter} role="button" id="patient" scope="col">
                <span className="events-none">Beneficiar</span>
                <FontAwesomeIcon
                  className="ml-1 events-none"
                  icon={calcIcon('patient')}
                />
              </th>
              <th role="button" scope="col">
                <span className="events-none">Detalii ultima fisa</span>
              </th>
              <th role="button" scope="col">
                <span className="events-none">Stare ultima fisa</span>
              </th>
              <th role="button" id="active" scope="col">
                <span className="events-none">Stare dosar</span>
              </th>
              <th scope="col" />
            </tr>
          </thead>
          <tbody>
            { domPatients}
          </tbody>
        </table>
      </div>
    </>

  );
}

export default CasefilesTable;
