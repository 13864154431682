import React from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

function NavigationWrapper({ component: Component, }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  return (
    <Component
      location={location}
      navigate={navigate}
      searchParams={searchParams}
      setSearchParams={setSearchParams}
    />
  );
}

export default NavigationWrapper;
