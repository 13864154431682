import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Button,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row, Spinner
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router';

import { connect } from 'react-redux';
import ConsultatieSiRecoltareView from './CasefileSections/ConsultatieSiRecoltareView';
import FileHeader from './CasefileSections/FileHeader';
import { casefilesOperations } from '../../../../redux/ducks/casefiles';
import InformareView from './CasefileSections/InformareView';
import TestareHPVView from './CasefileSections/TestareHPVView';
import TestareBabesView from './CasefileSections/TestareBabesView';
import ColposcopieView from './CasefileSections/ColposcopieView';
import BiopsieView from './CasefileSections/BiopsieView';
import TratamentView from './CasefileSections/TratamentView';

const fileSections = ['s1', 's2', 's3A', 's3B', 's4', 's42', 's5'];

function SingleFileView({ getSingleFile, updateFile, downloadFile, auth, }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [sections, setSections] = useState({});
  const [activeTab, setActiveTab] = useState('');
  const [file, setFile] = useState(null);
  const [key, setKey] = useState(0);

  useEffect(() => {
    const splitUrl = location.pathname.split('/file/')[1];
    if (splitUrl !== 'new') {
      getFileData(splitUrl);
    }
  }, []);

  const calcInitialTabsState = sections => {
    setSections(sections);
    const active = [];
    Object.keys(sections).forEach(k => {
      if (sections[k] === 'EDIT') {
        active.push(k);
      }
    });
    if (active.length > 0) {
      setActiveTab(active.sort()[0]);
    } else {
      setActiveTab('s1');
    }
  };

  const getFileData = url => {
    const id = url.split('/')[0];
    getSingleFile(id)
      .then((res => {
        const { sections, } = res.data.data;
        setFile(res.data.data);
        calcInitialTabsState(sections);
        setKey(Math.random());
      }))
      .catch((err => {
        console.log('err: ', err);
      }));
  };

  const selectActiveTab = e => {
    setActiveTab(e.target.id);
  };

  const goBack = () => {
    const { state, } = location;
    if (state) {
      const { state: { from: { search, pathname, }, }, } = location;
      navigate(`${pathname}${search}`);
    } else {
      navigate('/casefile');
    }
  };

  const changeTab = val => {
    const index = fileSections.indexOf(activeTab);
    const newIndex = val === '+' ? index + 1 : index - 1;
    const newActiveTab = fileSections[newIndex];
    setActiveTab(newActiveTab);
  };

  const downloadPatientFile = () => {
    downloadFile(file.id)
      .then(response => {
        // this.setState({ loading: false, });
        const timestamp = new Date().valueOf();
        const fileName = `${timestamp}.pdf`;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
      })
      .catch(err => {
        console.log('err: ', err);
      });
  };

  if (file == null) return <Spinner />;

  return (
    <Container id="casefiles" fluid>
      <Row className="mt-4 mb-4">
        <Col sm={2}>
          <Button onClick={goBack} color="secondary">
            <FontAwesomeIcon icon={faArrowLeft} />
            <span className="ml-2">Înapoi</span>
          </Button>
        </Col>
        <Col sm={10}>
          <FileHeader data={file} />
        </Col>
      </Row>
      <Row>
        <Col sm={2}>
          <Nav vertical>
            <NavItem className={activeTab === 's1' ? 'active' : ''}>
              <NavLink id="s1" onClick={selectActiveTab} href="#">
                1. Informare şi consiliere
                { sections.s1 === 'EDIT' && <span className="ml-1 badge badge-pill badge-light">1</span>}
              </NavLink>
            </NavItem>
            <NavItem className={activeTab === 's2' ? 'active' : ''}>
              <NavLink id="s2" onClick={selectActiveTab} href="#">
                2. Consultație şi recoltare
                { sections.s2 === 'EDIT' && <span className="ml-1 badge badge-pill badge-light">2</span>}
              </NavLink>
            </NavItem>
            <NavItem className={activeTab === 's3A' ? 'active' : ''}>
              <NavLink id="s3A" onClick={selectActiveTab} href="#">
                3A. Testare HPV
                { sections.s3A === 'EDIT' && <span className="ml-1 badge badge-pill badge-light">3</span>}
              </NavLink>
            </NavItem>
            <NavItem className={activeTab === 's3B' ? 'active' : ''}>
              <NavLink id="s3B" onClick={selectActiveTab} href="#">
                3B. Testare Babeş-Papanicolau
                { sections.s3B === 'EDIT' && <span className="ml-1 badge badge-pill badge-light">3</span>}
              </NavLink>
            </NavItem>
            <div className="nav-separator" />
            <NavItem className={activeTab === 's4' ? 'active' : ''}>
              <NavLink id="s4" onClick={selectActiveTab}>
                Colposcopie
              </NavLink>
            </NavItem>
            <NavItem className={activeTab === 's42' ? 'active' : ''}>
              <NavLink id="s42" onClick={selectActiveTab}> Biopsie </NavLink>
            </NavItem>
            <NavItem className={activeTab === 's5' ? 'active' : ''}>
              <NavLink id="s5" onClick={selectActiveTab}> Tratament </NavLink>
            </NavItem>

            {auth?.view.includes('TSFENTITY_LIST') && (
              <>
                <div className="nav-separator" />
                <NavItem className="mt-5">
                  <NavLink className="text-primary text-center" onClick={downloadPatientFile}>Descarca FS1</NavLink>
                </NavItem>
              </>
            )}
          </Nav>
        </Col>
        <Col sm={10}>
          { activeTab === 's1' && (
            <InformareView
              changeTab={changeTab}
              data={file}
              activeTab={activeTab}
            />
          ) }
          { activeTab === 's2' && (
            <ConsultatieSiRecoltareView
              changeTab={changeTab}
              data={file}
              activeTab={activeTab}
            />
          ) }
          { activeTab === 's3A' && (
            <TestareHPVView
              changeTab={changeTab}
              data={file}
              activeTab={activeTab}
            />
          ) }
          { activeTab === 's3B' && (
            <TestareBabesView
              readOnly
              changeTab={changeTab}
              data={file}
              activeTab={activeTab}
            />
          ) }
          { activeTab === 's4' && (
            <ColposcopieView
              changeTab={changeTab}
              data={file}
              activeTab={activeTab}
            />
          ) }
          { activeTab === 's42' && (
            <BiopsieView
              changeTab={changeTab}
              data={file}
              activeTab={activeTab}
            />
          ) }
          { activeTab === 's5' && (
            <TratamentView
              changeTab={changeTab}
              data={file}
              activeTab={activeTab}
            />
          ) }
        </Col>
      </Row>
    </Container>
  );
}

const mapStateToProps = state => ({
  auth: state.auth,
  casefiles: state.casefiles.casefiles,
  selectedCasefile: state.casefiles.selectedCasefile,
});

const mapDispatchToProps = dispatch => ({
  getSingleFile: id => dispatch(casefilesOperations.getSingleFile(id)),
  selectCasefile: data => dispatch(casefilesOperations.selectCasefile(data)),
  updateFile: data => dispatch(casefilesOperations.updateFile(data)),
  downloadFile: id => dispatch(casefilesOperations.downloadFile(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SingleFileView);
