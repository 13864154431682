import React from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
  UncontrolledAlert
} from 'reactstrap';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faSave } from '@fortawesome/free-solid-svg-icons';
import * as yup from 'yup';
import { connect } from 'react-redux';
import { userRolesAdminOptions, userPermissionOptions } from '../users.helper';
import { usersOperations } from '../../../../redux/ducks/users';
import { organizationsOperations } from '../../../../redux/ducks/organizations';
import { doctorsOperations } from '../../../../redux/ducks/doctors';

const phoneRegExp = /^\d{10}$/;

const validations = {
  email: yup.object().shape({ email: yup.string().email().required(), }),
  username: yup.object().shape({ username: yup.string().required(), }),
  firstName: yup.object().shape({ firstName: yup.string().required(), }),
  lastName: yup.object().shape({ lastName: yup.string().required(), }),
  // cnp: yup.object().shape({ cnp: yup.string().required(), }),
  phone: yup.object().shape({ phone: yup.string().matches(phoneRegExp, ''), }),
};

class UserForm extends React.Component {
  constructor() {
    super();
    this.state = {
      id: null,
      email: '',
      emailError: false,
      username: '',
      phone: '',
      selectedRole: null,
      selectedRoleSimple: '',
      permissions: [],
      permissionsSimple: [],
      usernameError: false,
      phoneError: false,
      lastName: '',
      lastNameError: false,
      firstName: '',
      firstNameError: false,
      cnp: '',
      cnpError: false,
      suppliers: [],
      selectedSupplier: null,
      doctors: [],
      selectedDoctor: null,
      mainRoles: [],
      apiNotification: null,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleRoleSelect = this.handleRoleSelect.bind(this);
    this.handlePermissionSelect = this.handlePermissionSelect.bind(this);
    this.handleSupplierSelect = this.handleSupplierSelect.bind(this);
    this.cancel = this.cancel.bind(this);
    this.getSingleUser = this.getSingleUser.bind(this);
    this.prefillFields = this.prefillFields.bind(this);
    this.calcDisabled = this.calcDisabled.bind(this);
    this.handleDoctorSelect = this.handleDoctorSelect.bind(this);
    this.validateCnp = this.validateCnp.bind(this);
    this.prefillDoctor = this.prefillDoctor.bind(this);
    this.clearNotification = this.clearNotification.bind(this);
  }

  componentDidMount() {
    const { location, getOrganizationsSimple, auth, navigate, getProfiles, } = this.props;
    const splitUrl = location.pathname.split('/user/')[1];
    if (!auth?.create.includes('USER_CREATE')) {
      setTimeout(() => {
        navigate('/');
      }, 10);
    } else if (splitUrl !== 'new') {
      this.getSingleUser(splitUrl);
    } else {
      getProfiles()
        .then((res => {
          this.setState({ profilesSelection: res.data.data, }, () => {
            const mainRolesOptions = userRolesAdminOptions.filter(a => res.data.data.includes(a.value));
            const secondaryRoles = userPermissionOptions.filter(a => res.data.data.includes(a.value));
            this.setState({
              mainRoles: [...mainRolesOptions, { value: 'ROLE_FURNIZOR', label: 'Rol furnizor', }],
              secondaryRoles,
            });
          });
        }))
        .catch((err => {
          console.log('err: ', err);
        }));
      getOrganizationsSimple()
        .then((res => {
          const { organizations, regions, } = res.data.data;
          this.setState({
            organizations,
            regions,
          });
        }))
        .catch((err => {
          console.log('err: ', err);
        }));
    }
  }

  clearNotification() {
    setTimeout(() => {
      this.setState({ apiNotification: null, });
    }, 2000);
  }

  getSingleUser(url) {
    const { navigate, getSingleUser, getOrganizationsSimple, getProfiles, } = this.props;
    const id = url.split('/')[0];
    getProfiles()
      .then((res => {
        this.setState({ profilesSelection: res.data.data, }, () => {
          const mainRolesOptions = userRolesAdminOptions.filter(a => res.data.data.includes(a.value));
          const secondaryRoles = userPermissionOptions.filter(a => res.data.data.includes(a.value));
          this.setState({
            mainRoles: [...mainRolesOptions, { value: 'ROLE_FURNIZOR', label: 'Rol furnizor', }],
            secondaryRoles,
          });
        });
      }))
      .catch((err => {
        console.log('err: ', err);
      }));
    getOrganizationsSimple()
      .then((res => {
        const { organizations, regions, } = res.data.data;
        this.setState({
          organizations,
          regions,
        });
        getSingleUser(id)
          .then((res => {
            this.prefillFields(res.data.data);
          }))
          .catch((err => {
            console.log('err: ', err);
            setTimeout(() => { navigate('/user'); }, 100);
          }));
      }))
      .catch((err => {
        console.log('err: ', err);
      }));
  }

  prefillFields(data) {
    const {
      id, email, username, phone, profiles = [],
      auxiliarPerson,
      doctor,
    } = data;

    let mainRole;
    const permissions = [];
    profiles.forEach(elem => {
      if (userRolesAdminOptions.find(a => a.value === elem.value)) {
        mainRole = userRolesAdminOptions.find(a => a.value === elem.value);
      }
      if (userPermissionOptions.find(a => a.value === elem.value)) {
        permissions.push(userPermissionOptions.find(a => a.value === elem.value));
      }
    });
    if (permissions.length > 0) {
      if (typeof auxiliarPerson !== 'undefined' && typeof mainRole === 'undefined') {
        mainRole = {
          value: 'ROLE_FURNIZOR',
          label: 'Rol furnizo',
        };
      }
      if (typeof doctor !== 'undefined' && typeof mainRole === 'undefined') {
        mainRole = {
          value: 'ROLE_FURNIZOR',
          label: 'Rol furnizor',
        };
      }
    }

    this.setState({
      email,
      username,
      phone,
      id,
      selectedRole: mainRole,
      selectedRoleSimple: mainRole.value,
      permissions,
    }, () => {
      const { selectedRoleSimple, } = this.state;
      if (selectedRoleSimple === 'ROLE_ADMIN') {
        const { person, } = auxiliarPerson;
        const { firstName = '', lastName = '', cnp = '', } = person;
        this.setState({ firstName, lastName, cnp, });
      }
      if (selectedRoleSimple === 'ROLE_REG_ADMIN') {
        const { person, } = auxiliarPerson;
        const { organization, } = data;
        const { firstName = '', lastName = '', cnp = '', } = person;
        this.setState({ firstName, lastName, cnp, });
        const { regions = [], } = this.state;
        const selectedRegion = regions.find(a => a.value === organization.label);
        if (selectedRegion) {
          this.setState({ selectedSupplier: selectedRegion, suppliers: regions, });
        }
      }
      if (selectedRoleSimple === 'ROLE_FURNIZOR') {
        const persData = doctor || auxiliarPerson;
        const { person, organization = null, id = null, } = persData;
        const { firstName = '', lastName = '', cnp = '', } = person;
        const { organizations = [], } = this.state;
        const selectedOrg = organizations.find(a => a.value === organization.label);
        this.setState({
          firstName,
          lastName,
          cnp,
          suppliers: organizations,
          selectedSupplier: organization ? selectedOrg : null,
        }, () => {
          if (selectedOrg) {
            this.prefillDoctor(selectedOrg, data.doctor);
          }
        });
      }
    });
  }

  handleSubmit() {
    const { createUser, updateUser, } = this.props;

    const {
      id,
      email,
      username,
      phone,
      selectedSupplier,
      selectedRoleSimple,
      selectedRole,
      lastName,
      firstName,
      cnp,
      permissions,
      selectedDoctor,
    } = this.state;

    let payload;

    if (selectedRoleSimple === 'ROLE_SUPER_ADMIN') {
      payload = {
        id,
        email,
        username,
        phone,
        profiles: [selectedRole],
      };
    }

    if (selectedRoleSimple === 'ROLE_REG_ADMIN') {
      payload = {
        id,
        email,
        username,
        phone,
        profiles: [selectedRole],
        organization: selectedSupplier,
        auxiliarPerson: {
          person: {
            lastName,
            firstName,
            cnp,
          },
          organization: selectedSupplier,
        },
      };
    }

    if (selectedRoleSimple === 'ROLE_FURNIZOR') {
      payload = {
        id,
        email,
        username,
        phone,
        organization: selectedSupplier,
        profiles: [...permissions],
        doctor: { id: selectedDoctor.id, },
      };
    }

    const { navigate, } = this.props;
    if (id === null) {
      delete payload.id;
      createUser(payload).then(res => {
        setTimeout(() => {
          navigate('/user');
        }, 100);
      }).catch(error => {
        console.log('err: ', error?.data?.message);
        this.setState({
          apiNotification: {
            color: 'danger',
            message: 'Operatiunea nu poate fi executata!',
          },
        }, () => {
          this.clearNotification();
        });
        // if (error && error.data && error.data.message) {
        //   if (error.data.message.indexOf('CNP invalid') !== -1) {
        //     this.setState({ cnpError: ['CNP-ul nu este valid'], });
        //   }
        // }
      });
    } else {
      updateUser(payload).then(() => {
        setTimeout(() => {
          navigate(`/user/${payload.id}/view`);
        }, 100);
      }).catch(error => {
        console.log('err: ', error);
        this.setState({
          apiNotification: {
            color: 'danger',
            message: 'Operatiunea nu poate fi executata!',
          },
        }, () => {
          this.clearNotification();
        });
      });
    }
  }

  handleInputChange(e) {
    const { name, value, required, } = e.target;
    this.setState({ [name]: value, }, () => {
      if (required) {
        validations[name].validate({ [name]: value, }).then(res => {
          // console.log('valid: ', res);
          this.setState({ [`${name}Error`]: false, });
        }).catch(err => {
          // console.log('not valid: ', err);
          this.setState({ [`${name}Error`]: true, });
        });
      }
      if (name === 'cnp') {
        this.validateCnp(value);
      }
    });
  }

  validateCnp(cnpValue) {
    if (cnpValue !== '') {
      const mask = [2, 7, 9, 1, 4, 6, 3, 5, 8, 2, 7, 9];
      let sum = 0;
      for (let i = 0; i < cnpValue.length - 1; i++) {
        sum += parseInt(cnpValue[i]) * mask[i];
      }

      const mod = (sum % 11 == 10) ? 1 : sum % 11;
      const isValid = (mod == cnpValue[mask.length]);
      this.setState({ cnpError: !isValid, });
    } else {
      this.setState({ cnpError: false, });
    }
  }

  handleDoctorSelect(val) {
    this.setState({ selectedDoctor: val || null, });
  }

  handleRoleSelect(val) {
    this.setState({
      selectedRole: val,
      selectedRoleSimple: val ? val.value : '',
    }, () => {
      if (val === null) {
        this.setState({ suppliers: [], doctors: [], });
      } else {
        if (val.value === 'ROLE_REG_ADMIN') {
          const { regions, } = this.state;
          this.setState({ suppliers: regions, });
        }
        if (val.value === 'ROLE_FURNIZOR') {
          const { organizations, } = this.state;
          this.setState({
            suppliers: organizations,
            selectedSupplier: null,
          });
        }
      }
    });
  }

  handlePermissionSelect(val) {
    this.setState({
      permissions: [...val],
      permissionsSimple: [...val].map(a => a.value),
    });
  }

  prefillDoctor(val, doctorData) {
    const { getDoctorsSimple, } = this.props;
    if (val?.id) {
      getDoctorsSimple(val.id)
        .then((res => {
          this.setState({ doctors: res.data.data, }, () => {
            const selectedDoctor = this.state.doctors.find(a => a.id === doctorData.id);
            if (selectedDoctor) {
              this.setState({ selectedDoctor, });
            }
          });
        }))
        .catch((err => {
          console.log('err: ', err);
        }));
    }
  }

  handleSupplierSelect(val) {
    const { getDoctorsSimple, } = this.props;
    if (val?.id) {
      getDoctorsSimple(val.id)
        .then((res => {
          this.setState({ doctors: res.data.data, });
        }))
        .catch((err => {
          console.log('err: ', err);
        }));
    }
    this.setState({ selectedSupplier: val || null, });
  }

  cancel() {
    const { navigate, } = this.props;
    navigate(-1);
  }

  calcDisabled() {
    const {
      email, emailError, username, usernameError,
      phone, phoneError, lastName, lastNameError,
      firstName, firstNameError, selectedSupplier,
      selectedRole, selectedRoleSimple, selectedDoctor,
      cnp, cnpError, roles, permissions,
    } = this.state;
    let disabled = false;
    if (email === '' || emailError === true) disabled = true;
    if (username === '' || usernameError === true) disabled = true;
    if (phone === '' || phoneError === true) disabled = true;
    if (selectedRole === null) disabled = true;
    if (selectedRoleSimple === 'ROLE_ADMIN' || selectedRoleSimple === 'ROLE_REG_ADMIN') {
      if (firstName === '' || firstNameError === true) disabled = true;
      if (lastName === '' || lastNameError === true) disabled = true;
      if (cnpError === true) disabled = true;
    }
    if (selectedRoleSimple === 'ROLE_REG_ADMIN') {
      if (selectedSupplier === null) disabled = true;
    }
    if (selectedRoleSimple === 'ROLE_DOCTOR') {
      if (selectedSupplier === null || selectedDoctor === null) disabled = true;
    }

    return disabled;
  }

  render() {
    const {
      id,
      email, emailError,
      username, usernameError,
      phone, phoneError, suppliers,
      selectedSupplier, selectedRole, selectedRoleSimple,
      permissions, permissionsSimple,
      lastName, lastNameError,
      firstName, firstNameError,
      cnp, cnpError,
      doctors, selectedDoctor,
      mainRoles,
      secondaryRoles,
      apiNotification,
    } = this.state;

    const saveDisabled = this.calcDisabled();

    return (
      <Container fluid className="d-flex">
        {
          apiNotification && (
            <UncontrolledAlert className="login-alert" color={apiNotification.color}>
              {apiNotification.message}
            </UncontrolledAlert>
          )
        }
        <Row className="justify-content-center w-100">
          <Col sm={8}>
            <Card className="mt-3">
              <CardHeader>
                <h3 className="font-weight-light mb-0">Creare sau editare Utilizator</h3>
              </CardHeader>
              <CardBody>
                <FormGroup>
                  <Label>Email</Label>
                  <Input
                    required
                    type="text"
                    name="email"
                    className={`${(email === '' || emailError) && 'invalid'} `}
                    onChange={this.handleInputChange}
                    onBlur={this.handleInputChange}
                    value={email}
                  />
                  { email === '' && emailError && <p className="error text-danger mt-1 mb-1">Acest câmp este necesar.</p>}
                </FormGroup>
                <FormGroup>
                  <Label>Nume utilizator</Label>
                  <Input
                    required
                    type="text"
                    name="username"
                    className={`${(username === '' || usernameError) && 'invalid'} `}
                    onChange={this.handleInputChange}
                    onBlur={this.handleInputChange}
                    value={username}
                  />
                  { usernameError && <p className="error text-danger mt-1 mb-1">Acest câmp este necesar.</p>}
                </FormGroup>
                <FormGroup>
                  <Label>Telefon</Label>
                  <Input
                    required
                    type="text"
                    name="phone"
                    className={`${(phone === '' || phoneError) && 'invalid'} `}
                    onChange={this.handleInputChange}
                    onBlur={this.handleInputChange}
                    value={phone}
                  />
                  { phone === '' && phoneError && <p className="error text-danger mt-1 mb-1">Acest câmp este necesar.</p>}
                </FormGroup>

                <FormGroup>
                  <Label>Roluri</Label>
                  <Select
                    value={selectedRole}
                    className={selectedRole ? 'select-valid' : 'select-invalid'}
                    classNamePrefix="rolesSelect"
                    placeholder=""
                    isClearable
                    name="roles"
                    options={mainRoles}
                    onChange={this.handleRoleSelect}
                  />
                </FormGroup>
                {
                  selectedRole && (selectedRole.value === 'ROLE_FURNIZOR') && (
                    <FormGroup>
                      <Label>Permisiuni</Label>
                      <Select
                        value={permissions}
                        isMulti
                        className={permissions.length === 0 ? 'select-invalid' : 'select-valid'}
                        classNamePrefix="rolesSelect"
                        placeholder=""
                        isClearable
                        name="permissions"
                        options={secondaryRoles}
                        onChange={this.handlePermissionSelect}
                      />
                    </FormGroup>
                  )
                }

                { (selectedRoleSimple === 'ROLE_ADMIN'
                  || selectedRoleSimple === 'ROLE_ORG_ADMIN'
                  || selectedRoleSimple === 'ROLE_REG_ADMIN'
                  || selectedRoleSimple === 'ROLE_FINANCIAR'
                )
                && (
                  <>
                    <FormGroup>
                      <Label>Nume</Label>
                      <Input
                        required
                        type="text"
                        name="lastName"
                        className={`${(lastName === '' || lastNameError) && 'invalid'} `}
                        onChange={this.handleInputChange}
                        onBlur={this.handleInputChange}
                        value={lastName}
                      />
                      { lastNameError && <p className="error text-danger mt-1 mb-1">Acest câmp este necesar.</p>}
                    </FormGroup>
                    <FormGroup>
                      <Label>Prenume</Label>
                      <Input
                        required
                        type="text"
                        name="firstName"
                        className={`${(firstName === '' || firstNameError) && 'invalid'} `}
                        onChange={this.handleInputChange}
                        onBlur={this.handleInputChange}
                        value={firstName}
                      />
                      { firstNameError && <p className="error text-danger mt-1 mb-1">Acest câmp este necesar.</p>}
                    </FormGroup>
                    <FormGroup>
                      <Label>CNP</Label>
                      <Input
                        // required
                        type="text"
                        name="cnp"
                        className={`${(cnpError) && 'invalid'} } `}
                        onChange={this.handleInputChange}
                        onBlur={this.handleInputChange}
                        value={cnp}
                      />
                      { cnp !== '' && cnpError && <p className="error text-danger mt-1 mb-1">Cnp-ul nu este valid.</p>}
                    </FormGroup>

                  </>
                )}
                { selectedRoleSimple === 'ROLE_REG_ADMIN' && (
                  <FormGroup>
                    <Label>Furnizor</Label>
                    <Select
                      getOptionValue={option => option.id}
                      getOptionLabel={option => option.value}
                      value={selectedSupplier}
                      className={selectedSupplier ? 'select-valid' : 'select-invalid'}
                      classNamePrefix="rolesSelect"
                      placeholder=""
                      isClearable
                      name="suppliers"
                      options={suppliers}
                      onChange={this.handleSupplierSelect}
                    />
                  </FormGroup>
                )}
                { selectedRoleSimple === 'ROLE_FURNIZOR' && (
                  <FormGroup>
                    <Label>Furnizor</Label>
                    <Select
                      getOptionValue={option => option.id}
                      getOptionLabel={option => option.value}
                      value={selectedSupplier}
                      className={selectedSupplier ? 'select-valid' : 'select-invalid'}
                      classNamePrefix="rolesSelect"
                      placeholder=""
                      isClearable
                      name="suppliers"
                      options={suppliers}
                      onChange={this.handleSupplierSelect}
                    />
                  </FormGroup>
                )}

                {
                  (selectedRoleSimple === 'ROLE_FURNIZOR' && selectedSupplier !== null) && (
                    <FormGroup>
                      <Label>Personal Furnizor</Label>
                      <Select
                        getOptionValue={option => option.id}
                        getOptionLabel={option => option.value}
                        value={selectedDoctor}
                        className={selectedDoctor ? 'select-valid' : 'select-invalid'}
                        classNamePrefix="rolesSelect"
                        placeholder=""
                        isClearable
                        name="doctors"
                        options={doctors}
                        onChange={this.handleDoctorSelect}
                      />
                    </FormGroup>
                  )
                }

                <Row className="mt-3">
                  <Col md={12} className="text-right">
                    <Button
                      onClick={this.cancel}
                      id="cancel-save"
                      color="secondary"
                    >
                      <FontAwesomeIcon icon={faBan} />
                      <span className="ml-1">Anulare</span>
                    </Button>
                    <Button
                      disabled={saveDisabled}
                      onClick={saveDisabled ? () => {} : this.handleSubmit}
                      id="save"
                      color="primary"
                      className="ml-1"
                    >
                      <FontAwesomeIcon icon={faSave} />
                      <span className="ml-1">Salvare</span>
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}
const mapStateToProps = state => ({
  auth: state.auth,
  users: state.users.users,
  selectedUser: state.users.selectedUser,
});

const mapDispatchToProps = dispatch => ({
  createUser: data => dispatch(usersOperations.createUser(data)),
  updateUser: data => dispatch(usersOperations.updateUser(data)),
  getSingleUser: id => dispatch(usersOperations.getSingleUser(id)),
  getOrganizationsSimple: () => dispatch(organizationsOperations.getOrganizationsSimple()),
  getDoctorsSimple: orgId => dispatch(doctorsOperations.getDoctorsSimple(orgId)),
  getProfiles: () => dispatch(usersOperations.getProfiles()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserForm);
