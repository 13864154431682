import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, CardHeader, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import Select from 'react-select';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';

const methodData = [
  { id: 'B3233085-3CAB-4397-9275-1D2C7C2640FD', value: 'digene HC2 High-Risk HPV DNA Test', code: 'HC2 HR HPV DNA Test', },
  { id: 'C228CD50-EFB6-4824-A3DD-128CC3167D2F', value: 'Real Time PCR', code: 'Real Time PCR', }
];

const rezultatHpvData = [
  { id: '4F9B923D-0EB7-EB11-8C22-000C2901B5F1', value: 'HPV-HR(16, 18, 26, 31, 33, 35, 39, 45, 51, 52, 53, 56, 58, 59, 66, 73, 82)', code: 'rez_hpv_HR', },
  { id: '509B923D-0EB7-EB11-8C22-000C2901B5F1', value: 'HPV-LR(6, 11, 40, 42, 43, 44, 54, 55, 61, 62, 67, 69, 70, 71, 72, 81, 84)', code: 'rez_hpv_LR', },
  { id: '519B923D-0EB7-EB11-8C22-000C2901B5F1', value: 'HPV-NA (alte tipuri HPV)', code: 'rez_hpv_NA', },
  { id: 'C98F5097-13B7-EB11-8C22-000C2901B5F1', value: 'Negativ', code: 'NEGATIV', },
  { id: '7C9F0E59-A6B0-4B8C-9C96-DE43DA713EB5', value: 'Nesatisfacator', code: 'NESATISFACATOR', }];

const repetareHpvData = [
  { id: '14B8872C-81D3-4D54-916A-7083A25078F4', value: 'în 12 luni', code: 'repetare_hpv_12_luni', },
  { id: '99FC0FF5-231D-43AC-A372-403E3FAA0756', value: 'în 5 ani', code: 'repetare_hpv_5_ani', }
];

const equipmentData = [
  { id: '6AF58420-33BD-EB11-8C24-000C2901B5F1', value: 'APTIMA (Hologic)', code: 'APTIMA_EQUIPMENT', },
  { id: '6BF58420-33BD-EB11-8C24-000C2901B5F1', value: 'Cobas (Roche)', code: 'Cobas_EQUIPMENT', },
  { id: '69F58420-33BD-EB11-8C24-000C2901B5F1', value: 'HC2 (Qiagen)', code: 'HC2_EQUIPMENT', },
  { id: '6DF58420-33BD-EB11-8C24-000C2901B5F1', value: 'Onclarity (BD)', code: 'Onclarity_EQUIPMENT', },
  { id: 'EAE03245-1BFB-4D04-8313-F5ADF05335D4', value: 'RCS (Qiagen)', code: 'RCS_EQUIPMENT', },
  { id: '6CF58420-33BD-EB11-8C24-000C2901B5F1', value: 'RealTime (Abbott)', code: 'RealTime_EQUIPMENT', },
  { id: '6EF58420-33BD-EB11-8C24-000C2901B5F1', value: 'Xpert (Cepheid)', code: 'Xpert_EQUIPMENT', }];

// TODO validari
// data interpretarii
// rezultat HPv

function TestareHPVEdit({ data, changeTab, saveData, auth, error, }) {
  const [inputs, setInputs] = useState({});
  const [doctors, setDoctors] = useState([]);
  const [technicians, setTechnicians] = useState([]);
  const [labSpecFlaconSec31FS1, setLabSpecFlaconSec31FS1] = useState([]);
  const [triajEditable, setTriajEditable] = useState(true);

  useEffect(() => {
    const inputs = data.attributes;
    setInputs(inputs);
    setDoctors(data.medicAnatoPatoLamaSec31FS1 || []);
    setTechnicians(data.tehnicianSec31FS1 || []);

    setLabSpecFlaconSec31FS1(data.labSpecFlaconSec31FS1 || '');
    if (Object.keys(inputs).length === 0) return;
    inputs.triajBpnSec34FS1.value = inputs.triajBpnSec34FS1.value.toString();
    if (inputs?.rezHpvSec34FS1?.value === 'HPV-HR(16, 18, 26, 31, 33, 35, 39, 45, 51, 52, 53, 56, 58, 59, 66, 73, 82)') {
      setTriajEditable(false);
    } else if (inputs?.rezHpvSec34FS1?.value === 'Negativ') {
      setTriajEditable(false);
    } else {
      setTriajEditable(true);
    }
    const selectedMedic = (data.medicAnatoPatoLamaSec31FS1 || []).find(elem => auth?.doctorId === elem.id);
    const selectedTech = (data.tehnicianSec31FS1 || []).find(elem => auth?.doctorId === elem.id);
    if (selectedMedic) {
      inputs.medicAnatoPatoLamaSec31FS1 = selectedMedic;
    }
    if (selectedTech) {
      inputs.tehnicianSec31FS1 = selectedMedic;
    }

    if (inputs?.rezHpvSec34FS1?.value === 'HPV-HR(16, 18, 26, 31, 33, 35, 39, 45, 51, 52, 53, 56, 58, 59, 66, 73, 82)') {
      inputs.triajBpnSec34FS1.value = 'true';
    }
    if (inputs?.rezHpvSec34FS1?.value === 'Negativ') {
      inputs.triajBpnSec34FS1.value = 'false';
    }
  }, []);

  const {
    medicAnatoPatoLamaSec31FS1,
    nrFlaconSec31FS1,
    dataPrimireFlaconSec31FS1,
    dataInterSec31FS1,
    echipamentSec31FS1,
    metodaSec31FS11,
    tehnicianSec31FS1,
    rezHpvSec34FS1,
    repetareHpvList,
    triajBpnSec34FS1,
    obs2Sec34FS1,
    dataElbRezHpvSec34FS1,
  } = inputs;

  const handleChange = e => {
    const curentVal = { ...inputs[e.target.name], value: e.target.value, };
    setInputs(prevState => ({ ...prevState, [e.target.name]: curentVal, }));
  };

  const handleSelect = (selected, details) => {
    const curentVal = {
      ...inputs[details.name],
      id: selected?.id || '',
      value: selected?.value || '',
    };
    setInputs(prevState => ({ ...prevState, [details.name]: curentVal, }));
    if (details.name === 'rezHpvSec34FS1') {
      if (selected !== null) {
        if (selected.value === 'Negativ') {
          setInputs(prevState => ({ ...prevState, triajBpnSec34FS1: { value: 'fasle', }, }));
          setTriajEditable(false);
        } else if (selected.value === 'HPV-HR(16, 18, 26, 31, 33, 35, 39, 45, 51, 52, 53, 56, 58, 59, 66, 73, 82)') {
          setInputs(prevState => ({ ...prevState, triajBpnSec34FS1: { value: 'true', }, }));
          setTriajEditable(false);
        } else {
          setTriajEditable(true);
        }
      }
    }
  };

  const selectDate = (val, name) => {
    const dataSubmit = moment(val).utc().format();
    const curentVal = { ...inputs[name], value: dataSubmit, };
    setInputs(prevState => ({ ...prevState, [name]: curentVal, }));
  };

  const handleCheck = e => {
    const newVal = e.target.value === 'false' ? 'true' : 'false';
    const curentVal = { ...inputs[e.target.name], value: newVal, };
    setInputs(prevState => ({ ...prevState, [e.target.name]: curentVal, }));
  };

  const save = e => {
    const saveStatus = e.target.id;
    inputs.triajBpnSec34FS1.value = inputs.triajBpnSec34FS1.value === 'true';
    const payload = {
      // labSpecFlaconSec31FS1,
      medicAnatoPatoLamaSec31FS1,
      nrFlaconSec31FS1,
      dataPrimireFlaconSec31FS1,
      dataInterSec31FS1,
      echipamentSec31FS1,
      metodaSec31FS11,
      tehnicianSec31FS1,
      rezHpvSec34FS1,
      repetareHpvList,
      triajBpnSec34FS1,
      obs2Sec34FS1,
      dataElbRezHpvSec34FS1,
    };

    saveData(payload, saveStatus);
  };

  const calcShowSaveBtn = () => {
    if (typeof dataInterSec31FS1 === 'undefined' || dataInterSec31FS1?.value === '') {
      return false;
    }
    if (typeof dataElbRezHpvSec34FS1 === 'undefined' || dataElbRezHpvSec34FS1?.value === '') {
      return false;
    }
    if (typeof rezHpvSec34FS1 === 'undefined'
      || rezHpvSec34FS1?.value === ''
      || rezHpvSec34FS1?.value === 'Nesatisfacator') {
      return false;
    }
    if (typeof medicAnatoPatoLamaSec31FS1 === 'undefined' || medicAnatoPatoLamaSec31FS1?.value === '') {
      return false;
    }
    return true;
  };

  const setTab = e => { changeTab(e.target.id); };

  const showBtns = calcShowSaveBtn();

  return (
    <Card>
      <CardHeader>
        <h3 className="mb-0">
          <div className="d-flex">
            <div>
              <span className="mr-3">3A. Testare HPV</span>
            </div>
            {showBtns === true && (
              <div>
                <Button id="draft" size="sm" color="primary" className="btn-rounded" onClick={save}>Draft</Button>
                {
                  rezHpvSec34FS1?.value !== 'Nesatisfacator' && (
                    <Button id="final" size="sm" color="success" className="ml-2 btn-rounded" onClick={save}>Finalizeaza</Button>
                  )
                }
              </div>
            )}
            <div className="ml-auto">
              <Button id="-" size="sm" color="secondary" className="ml-auto btn-pill" onClick={setTab}>{'<'}</Button>
              <Button id="+" size="sm" color="primary" className="ml-1 btn-pill" onClick={setTab}>{'>'}</Button>
            </div>
          </div>
        </h3>

      </CardHeader>
      <CardBody className="pb-5">
        <Col
          className="underline-hover form-group dont-display ng-untouched ng-pristine"
          style={{ backgroundColor: 'rgba(233, 236, 239, 0.3)', }}
        >
          <Label>
            Datele generale ale analizei
          </Label>
        </Col>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>Laborator HPV</Label>
              <Input
                required
                type="text"
                name="labSpecFlaconSec31FS1"
                disabled
                value={labSpecFlaconSec31FS1?.value || ''}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label>Personal de specialitate care certifică rezultatele</Label>
              <Select
                value={medicAnatoPatoLamaSec31FS1 || ''}
                onChange={handleSelect}
                getOptionValue={option => option.id}
                getOptionLabel={option => option.value}
                className={(typeof medicAnatoPatoLamaSec31FS1 !== 'undefined' && medicAnatoPatoLamaSec31FS1.value !== '') ? '' : 'invalid'}
                classNamePrefix="select"
                placeholder=""
                name="medicAnatoPatoLamaSec31FS1"
                isClearable
                options={doctors}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>Numărul probei HPV</Label>
              <Input
                type="text"
                name="nrFlaconSec31FS1"
                onChange={handleChange}
                value={nrFlaconSec31FS1?.value || ''}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>Data primirii probei</Label>
              <FormGroup row className="align-items-center">
                <Col sm={10} className="pr-0">
                  <Input
                    defaultValue={dataPrimireFlaconSec31FS1 ? moment(dataPrimireFlaconSec31FS1?.value).format('DD-MM-YYYY') : ''}
                    type="text"
                    className={`form-control bg-white ${error && error.field === 'dataPrimireFlaconSec31FS1' ? 'invalid' : ''}`}
                    disabled
                    placeholder=""
                  />
                </Col>
                <Col sm={2} className="pl-0">
                  <DatePicker
                    minDate={new Date('01-01-2021')}
                    maxDate={new Date()}
                    name={dataPrimireFlaconSec31FS1}
                    locale="ro"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    selected={moment(dataPrimireFlaconSec31FS1?.value).toDate()}
                    onChange={val => selectDate(val, 'dataPrimireFlaconSec31FS1')}
                    customInput={<Button className="form-control"><FontAwesomeIcon icon={faCalendarAlt} /></Button>}
                  />
                </Col>
                { error && error.field === 'dataPrimireFlaconSec31FS1' && <p className="error text-danger mt-1 mb-1 pl-3">{error.message}</p>}
              </FormGroup>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label>
                Data interpretării *
              </Label>
              <FormGroup row className="align-items-center">
                <Col sm={10} className="pr-0">
                  <Input
                    defaultValue={dataInterSec31FS1 ? moment(dataInterSec31FS1?.value).format('DD-MM-YYYY') : ''}
                    type="text"
                    className={`form-control bg-white ${error && error.field === 'dataInterSec31FS1' ? 'invalid' : ''} ${dataInterSec31FS1?.value === '' ? 'invalid' : ''} ${typeof dataInterSec31FS1 === 'undefined' ? 'invalid' : ''}`}
                    disabled
                    placeholder=""
                  />
                </Col>
                <Col sm={2} className="pl-0">
                  <DatePicker
                    minDate={new Date('01-01-2021')}
                    maxDate={new Date()}
                    name={dataInterSec31FS1}
                    locale="ro"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    selected={moment(dataInterSec31FS1?.value).toDate()}
                    onChange={val => selectDate(val, 'dataInterSec31FS1')}
                    customInput={<Button className="form-control"><FontAwesomeIcon icon={faCalendarAlt} /></Button>}
                  />
                </Col>
                { error && error.field === 'dataInterSec31FS1' && <p className="error text-danger mt-1 mb-1 pl-3">{error.message}</p>}
              </FormGroup>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>Echipament</Label>
              <Select
                value={echipamentSec31FS1 || ''}
                onChange={handleSelect}
                getOptionValue={option => option.id}
                getOptionLabel={option => option.value}
                className="basic-single"
                classNamePrefix="select"
                placeholder=""
                isClearable
                name="echipamentSec31FS1"
                options={equipmentData}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label>Metodă</Label>
              <Select
                value={metodaSec31FS11 || ''}
                onChange={handleSelect}
                getOptionValue={option => option.id}
                getOptionLabel={option => option.value}
                className="basic-single"
                classNamePrefix="select"
                placeholder=""
                isClearable
                name="metodaSec31FS11"
                options={methodData}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>Tehnician/asistent</Label>
              <Select
                value={tehnicianSec31FS1 || ''}
                onChange={handleSelect}
                getOptionValue={option => option.id}
                getOptionLabel={option => option.value}
                className="basic-single"
                classNamePrefix="select"
                placeholder=""
                isClearable
                name="tehnicianSec31FS1"
                options={technicians}
              />
            </FormGroup>
          </Col>
        </Row>

        <Col
          className="underline-hover form-group dont-display ng-untouched ng-pristine"
          style={{ backgroundColor: 'rgba(233, 236, 239, 0.3)', }}
        >
          <Label>
            Date specifice pentru examenul HPV
          </Label>
        </Col>

        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>Rezultat HPV *</Label>
              <Select
                value={rezHpvSec34FS1 || ''}
                onChange={handleSelect}
                getOptionValue={option => option.id}
                getOptionLabel={option => option.value}
                classNamePrefix="select"
                placeholder=""
                name="rezHpvSec34FS1"
                isClearable
                options={rezultatHpvData}
                className={(typeof rezHpvSec34FS1 !== 'undefined' && rezHpvSec34FS1.value !== '') ? '' : 'invalid'}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label>Repetare HPV</Label>
              <Select
                value={repetareHpvList || ''}
                onChange={handleSelect}
                getOptionValue={option => option.id}
                getOptionLabel={option => option.value}
                className="basic-single"
                classNamePrefix="select"
                placeholder=""
                isClearable
                name="repetareHpvList"
                options={repetareHpvData}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup check>
              <Input
                type="checkbox"
                checked={triajBpnSec34FS1?.value === 'true'}
                value={triajBpnSec34FS1?.value === 'true'}
                name="triajBpnSec34FS1"
                onChange={triajEditable ? handleCheck : () => {}}
              />
              <Label>Triaj BPN</Label>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Label>Observații</Label>
              <Input
                type="textarea"
                rows={3}
                name="obs2Sec34FS1"
                onChange={handleChange}
                value={obs2Sec34FS1?.value || ''}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>Data eliberării rezultatului *</Label>
              <FormGroup row className="align-items-center">
                <Col sm={10} className="pr-0">
                  <Input
                    defaultValue={dataElbRezHpvSec34FS1 ? moment(dataElbRezHpvSec34FS1?.value).format('DD-MM-YYYY') : ''}
                    type="text"
                    className={`form-control bg-white ${error && error.field === 'dataElbRezHpvSec34FS1' ? 'invalid' : ''} ${dataElbRezHpvSec34FS1?.value === '' ? 'invalid' : ''} ${typeof dataElbRezHpvSec34FS1 === 'undefined' ? 'invalid' : ''}`}
                    disabled
                    placeholder=""
                  />
                </Col>
                <Col sm={2} className="pl-0">
                  <DatePicker
                    minDate={new Date('01-01-2021')}
                    maxDate={new Date()}
                    name={dataElbRezHpvSec34FS1}
                    locale="ro"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    selected={moment(dataElbRezHpvSec34FS1?.value).toDate()}
                    onChange={val => selectDate(val, 'dataElbRezHpvSec34FS1')}
                    customInput={<Button className="form-control"><FontAwesomeIcon icon={faCalendarAlt} /></Button>}
                  />
                </Col>
                { error && error.field === 'dataElbRezHpvSec34FS1' && <p className="error text-danger mt-1 mb-1 pl-3">{error.message}</p>}
              </FormGroup>
            </FormGroup>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}

export default TestareHPVEdit;
