import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, CardHeader, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import moment from 'moment';

const checkboxInputs = [
  'leepSec5FS1',
  'ablatieLaserSec5FS1',
  'ablatieAlteMijSec5FS1',
  'conizatieSec5FS1',
  'thermocoagulareSec5FS1',
  'crioterapieSec5FS1',
  'histerectomieSec5FS1'
];

function TratamentView({ data, changeTab, }) {
  const [inputs, setInputs] = useState({});

  useEffect(() => {
    const inputs = data.attributes;
    setInputs(inputs);
    if (Object.keys(inputs).length === 0) return;
    checkboxInputs.forEach(elem => {
      inputs[elem].value = inputs[elem].value.toString();
    });
  }, []);

  const {
    cabinetInterventiiSec5FS1,
    medicInterventieSec5FS1,
    dataInterventieSec5FS1,
    leepSec5FS1,
    ablatieLaserSec5FS1,
    ablatieAlteMijSec5FS1,
    conizatieSec5FS1,
    crioterapieSec5FS1,
    thermocoagulareSec5FS1,
    histerectomieSec5FS1,
    alteleSec5FS1,
    laboratorSec5FS1,
    personalAnatomoPatoMedicalSec5FS1,
    numarProbaSec5FS1,
    dataPimireProbaSec5FS1,
    dataInterpretareSec5FS1,
    rezultatHistoSec5FS1,
  } = inputs;

  const setTab = e => { changeTab(e.target.id); };

  return (
    <Card>
      <CardHeader>
        <h3 className="mb-0">
          <div className="d-flex">
            <div>
              <span className="mr-3">Tratament</span>
            </div>
            <div className="ml-auto">
              <Button id="-" size="sm" color="secondary" className="ml-auto btn-pill" onClick={setTab}>{'<'}</Button>
            </div>
          </div>
        </h3>
      </CardHeader>
      <CardBody className="pb-5">
        <Col
          className="underline-hover form-group dont-display ng-untouched ng-pristine"
          style={{ backgroundColor: 'rgba(233, 236, 239, 0.3)', }}
        >
          <Label>
            Datele specifice tratamentului
          </Label>
        </Col>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>Centrul de servicii medicale specializate unde se efectuează intervenţiile</Label>
              <Input
                required
                type="text"
                name="cabinetInterventiiSec5FS1"
                disabled
                value={cabinetInterventiiSec5FS1?.value || ''}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label>Medicul de specialitate obstetrică ginecologie care efectuează intervenţia</Label>
              <Input
                required
                type="text"
                name="medicInterventieSec5FS1"
                disabled
                value={medicInterventieSec5FS1?.value || ''}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>Data intervenţiei</Label>
              <Input
                required
                type="text"
                name="dataInterventieSec5FS1"
                disabled
                value={dataInterventieSec5FS1?.value ? moment(dataInterventieSec5FS1.value).format('DD-MM-YYYY') : ''}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup check>

              <Input
                type="checkbox"
                value={leepSec5FS1?.value || false}
                checked={leepSec5FS1?.value === 'true'}
                name="leepSec5FS1"
                onChange={() => {}}
              />
              <Label>Procedură excizională LEEP</Label>
            </FormGroup>
          </Col>
        </Row>

        <Row className="mt-2 mb-2">
          <Col>
            <FormGroup check>
              <Input
                type="checkbox"
                value={ablatieLaserSec5FS1?.value || false}
                checked={ablatieLaserSec5FS1?.value === 'true'}
                name="ablatieLaserSec5FS1"
                onChange={() => {}}
              />
              <Label>Ablaţie laser </Label>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup check>
              <Input
                type="checkbox"
                value={ablatieAlteMijSec5FS1?.value || false}
                checked={ablatieAlteMijSec5FS1?.value === 'true'}
                name="ablatieAlteMijSec5FS1"
                onChange={() => {}}
              />
              <Label>Ablaţie cu alte mijloace în afară de laser  </Label>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup check>
              <Input
                type="checkbox"
                value={conizatieSec5FS1?.value || false}
                checked={conizatieSec5FS1?.value === 'true'}
                name="conizatieSec5FS1"
                onChange={() => {}}
              />
              <Label>Conizaţie cu bisturiul rece  </Label>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup check>
              <Input
                type="checkbox"
                value={crioterapieSec5FS1?.value || false}
                checked={crioterapieSec5FS1?.value === 'true'}
                name="crioterapieSec5FS1"
                onChange={() => {}}
              />
              <Label>Crioterapie</Label>
            </FormGroup>
          </Col>
        </Row>

        <Row className="mt-2 mb-2">
          <Col>
            <FormGroup check>
              <Input
                type="checkbox"
                value={thermocoagulareSec5FS1?.value || false}
                checked={thermocoagulareSec5FS1?.value === 'true'}
                name="thermocoagulareSec5FS1"
                onChange={() => {}}
              />
              <Label>Thermocoagulare</Label>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup check>
              <Input
                type="checkbox"
                value={histerectomieSec5FS1?.value || false}
                checked={histerectomieSec5FS1?.value === 'true'}
                name="histerectomieSec5FS1"
                onChange={() => {}}
              />
              <Label>Histerectomie totală </Label>
            </FormGroup>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            <FormGroup check>
              <Input
                type="checkbox"
                value={alteleSec5FS1?.value || false}
                checked={alteleSec5FS1?.value === 'true'}
                name="alteleSec5FS1"
                onChange={() => {}}
              />
              <Label>Altele</Label>
            </FormGroup>
          </Col>
        </Row>

        <Col
          className="underline-hover form-group dont-display ng-untouched ng-pristine"
          style={{ backgroundColor: 'rgba(233, 236, 239, 0.3)', }}
        >
          <Label>
            Examen histopatologic – (post tratament)
          </Label>
        </Col>

        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>Laborator</Label>
              <Input
                required
                type="text"
                name="laboratorSec5FS1"
                disabled
                value={laboratorSec5FS1?.value || ''}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label>Personalul medical care realizează diagnosticul histologic</Label>
              <Input
                required
                type="text"
                name="personalAnatomoPatoMedicalSec5FS1"
                disabled
                value={personalAnatomoPatoMedicalSec5FS1?.value || ''}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>Numărul probei</Label>
              <Input
                required
                type="text"
                name="numarProbaSec5FS1"
                disabled
                value={numarProbaSec5FS1?.value || ''}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>Data primirii probei</Label>
              <Input
                required
                type="text"
                name="dataPimireProbaSec5FS1"
                disabled
                value={dataPimireProbaSec5FS1?.value ? moment(dataPimireProbaSec5FS1.value).format('DD-MM-YYYY') : ''}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label>
                Data interpretării
              </Label>
              <Input
                required
                type="text"
                name="dataInterpretareSec5FS1"
                disabled
                value={dataInterpretareSec5FS1?.value ? moment(dataInterpretareSec5FS1.value).format('DD-MM-YYYY') : ''}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>Rezultatul histopatologic</Label>
              <Input
                required
                type="text"
                name="rezultatHistoSec5FS1"
                disabled
                value={rezultatHistoSec5FS1?.value || ''}
              />
            </FormGroup>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}

export default TratamentView;
