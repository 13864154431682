import React, { useState } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Label, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faRemove } from '@fortawesome/free-solid-svg-icons';

function DoctorAddressEntry({ showAddressModal, showDeleteModal, address, readOnly, }) {
  const [showBody, setShowBody] = useState(false);
  const toggleBody = () => {
    setShowBody(!showBody);
  };

  const editAddress = () => {
    showAddressModal(address);
  };

  const deleteAddress = () => {
    showDeleteModal(address);
  };

  // Strada
  // Numărul
  // Bloc
  // Etaj
  // Apartament

  // apartmentNo: "26"
  // buildingNo: "A6"
  // city: {value: "13631", label: "CURTEA DE ARGES", isUrban: true}
  // county: {value: "AG", label: "Argeş"}
  // region: {value: "sud_muntenia", label: "Sud-Muntenia"}
  // street: "STEFAN CEL MARE"
  // streetNo: "-"

  const { city, county, region, apartmentNo = '', buildingNo = '', street = '', streetNo = '', } = address;

  return (
    <Card className="mb-2">
      <CardHeader className="border-bottom-0">
        <div className="d-flex align-items-center justify-content-between">
          <Button
            onClick={toggleBody}
            color="link"
            className="w-100 text-left pl-0 collapsed"
          >
            <div>
              {`${(street !== null && street !== '') ? `str. ${street}` : ''}${(streetNo !== null && streetNo !== '') ? `, nr. ${streetNo}` : ''}${(buildingNo !== null && buildingNo !== '') ? `, bl. ${buildingNo}` : ''}${(apartmentNo !== null && apartmentNo !== '') ? `, ap. ${apartmentNo}` : ''}`}
              {(street !== null & street !== '') || (streetNo !== null && streetNo !== '') || (buildingNo !== null && buildingNo !== '') || (apartmentNo !== null && apartmentNo !== '') ? ', ' : ''}
              {`${city ? city.label : ''}${county ? `, ${county.label}` : ''}${region ? `, ${region.label}` : ''}`}
            </div>
          </Button>

          {
            readOnly ? null : (
              <>
                <Button
                  onClick={editAddress}
                  color="primary"
                  className="btn-pill p-0"
                >
                  <FontAwesomeIcon icon={faPencilAlt} />
                </Button>
                <Button
                  onClick={deleteAddress}
                  color="danger"
                  className="btn-pill ml-1 p-0"
                >
                  <FontAwesomeIcon icon={faRemove} />
                </Button>
              </>
            )
          }

        </div>
      </CardHeader>
      { showBody && (
      <CardBody className="border-top">
        <Row>
          <Col sm={6}>
            <Label className="font-weight-bold mb-0">
              Strada
            </Label>
            <div className="mb-3">
              {street || ''}
            </div>
          </Col>
          <Col sm={6}>
            <Label className="font-weight-bold mb-0">
              Numar
            </Label>
            <div className="mb-3">
              {streetNo || ''}
            </div>
          </Col>
          <Col sm={6}>
            <Label className="font-weight-bold mb-0">
              Bloc
            </Label>
            <div className="mb-3">
              {buildingNo || ''}
            </div>
          </Col>
          <Col sm={6}>
            <Label className="font-weight-bold mb-0">
              Apartament
            </Label>
            <div className="mb-3">
              {apartmentNo || ''}
            </div>
          </Col>
          <Col sm={6}>
            <Label className="font-weight-bold mb-0">
              Localitate
            </Label>
            <div className="mb-3">
              {city.label || ''}
            </div>
          </Col>
          <Col sm={6}>
            <Label className="font-weight-bold mb-0">
              Județ
            </Label>
            <div className="mb-3">
              {county.label || ''}
            </div>
          </Col>
          <Col sm={6}>
            <Label className="font-weight-bold mb-0">
              Regiune
            </Label>
            <div className="mb-3">
              {region.label || ''}
            </div>
          </Col>
        </Row>
      </CardBody>
      )}
    </Card>

  );
}

export default DoctorAddressEntry;
