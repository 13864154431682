// import Cookies from 'universal-cookie';
import types from './types';

const auth = (state = {}, action) => {
  switch (action.type) {
    case types.GET_USER_SETTINGS_COMPLETED: {
      const { privileges = [], ...rest } = action.payload.data.data;
      const view = privileges.filter(elem => elem.includes('LIST'));
      const create = privileges.filter(elem => elem.includes('CREATE'));
      const update = privileges.filter(elem => elem.includes('UPDATE'));
      const remove = privileges.filter(elem => elem.includes('REMOVE'));
      const activate = privileges.filter(elem => elem.includes('ACTIVATE'));
      return {
        ...rest,
        activate,
        view,
        create,
        update,
        remove,
      };
    }

    case types.LOGOUT_COMPLETED: {
      localStorage.removeItem('token');
      localStorage.removeItem('refresh');
      localStorage.removeItem('expiry');
      return null;
    }

    case types.LOGOUT_FAILED: {
      localStorage.removeItem('token');
      localStorage.removeItem('refresh');
      localStorage.removeItem('expiry');
      return null;
    }

    case types.LOGIN_COMPLETED: {
      const user = JSON.parse(action.payload.config.data);
      return { user: user.email, };
    }

    case types.REFRESH_USER_TOKEN_COMPLETED: {
      const { refreshToken, expiryDuration, } = action.payload.data;
      localStorage.setItem('expiry', Date.now() + expiryDuration);
      localStorage.setItem('refresh', refreshToken);
      return state;
    }

    default: return state;
  }
};

export default auth;
