import React from 'react';
import IndicatorTableRow from './IndicatorTableRow';

function IndicatorTable({ tableData = [], }) {
  const domOrganizations = tableData.map(elem => (
    <IndicatorTableRow
      key={elem.name}
      data={elem}
    />
  ));

  return (
    <div id="entities" className="table-responsive-xl mt-5">
      <table aria-describedby="page-heading" className="table">
        <thead>
          <tr>
            <th id="name" scope="col">
              <span className="events-none">Denumire</span>
            </th>
            <th id="value" scope="col">
              <span className="events-none">Valoare</span>
            </th>
          </tr>
        </thead>
        <tbody>
          { domOrganizations}
        </tbody>
      </table>
    </div>
  );
}

export default IndicatorTable;
