/* eslint-disable no-undef */
import actions from './actions';

const getUsers = url => dispatch => new Promise(((resolve, reject) => {
  dispatch(actions.getUsers(url))
    .then(res => {
      resolve(res);
    })
    .catch(err => {
      reject(err);
    });
}));

const getProfiles = () => dispatch => new Promise(((resolve, reject) => {
  dispatch(actions.getProfiles())
    .then(res => {
      resolve(res);
    })
    .catch(err => {
      reject(err);
    });
}));

const getSingleUser = id => dispatch => new Promise(((resolve, reject) => {
  dispatch(actions.getSingleUser(id))
    .then(res => {
      resolve(res);
    })
    .catch(err => {
      reject(err);
    });
}));

const createUser = data => dispatch => new Promise(((resolve, reject) => {
  dispatch(actions.createUser(data))
    .then(res => {
      resolve(res);
    })
    .catch(err => {
      reject(err);
    });
}));

const updateUser = data => dispatch => new Promise(((resolve, reject) => {
  dispatch(actions.updateUser(data))
    .then(res => {
      resolve(res);
    })
    .catch(err => {
      reject(err);
    });
}));

export default {
  createUser,
  updateUser,
  getUsers,
  getSingleUser,
  getProfiles,
};
