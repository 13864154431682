import React from 'react';
import PropTypes from 'prop-types';

import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem, NavLink
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function NavbarMenu({ menuTitle, menuIcon, menuData, location, callback, }) {
  const menuDom = [];
  menuData.forEach(elem => menuDom.push(
    <DropdownItem onClick={elem.withCallback && callback} key={elem.path} className={`dropdown-item-iocn ${location.pathname === elem.path ? 'active' : ''}`}>
      <NavLink className="" to={elem.path} state={{ from: location, }} tag={Link}>
        <FontAwesomeIcon className="pr-1" icon={elem.icon} />
        <span className="pl-1">{elem.title}</span>
      </NavLink>
    </DropdownItem>
  ));

  return (
    <UncontrolledDropdown>
      <DropdownToggle nav caret className="pl-0 pr-3">
        <FontAwesomeIcon className="pr-1" icon={menuIcon} />
        {menuTitle}
      </DropdownToggle>
      <DropdownMenu right>
        { menuDom }
      </DropdownMenu>
    </UncontrolledDropdown>

  );
}

export default NavbarMenu;
