import { combineReducers } from 'redux';
import types from './types';

const initialState = {
  doctors: [],
  selectedDoctor: null,
};

const doctors = (state = initialState.doctors, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const selectedDoctor = (state = initialState.selectedDoctor, action) => {
  switch (action.type) {
    case types.SELECT_DOCTOR:
      return action.payload;
    default:
      return state;
  }
};

export default combineReducers({
  doctors,
  selectedDoctor,
});
