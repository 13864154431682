import React from 'react';
import {
  CardBody,
  Col,
  FormGroup,
  Input,
  Label,
  Row
} from 'reactstrap';
import moment from 'moment';
import { connect } from 'react-redux';
import PatientAddresses from '../../Doctors/components/DoctorAddresses';
import PatientContacts from '../../Doctors/components/DoctorContacts';
import { patientsOperations } from '../../../../redux/ducks/patients';

class PatientFormView extends React.Component {
  constructor() {
    super();
    this.state = {
      lastName: '',
      firstName: '',
      cnp: '',
      birthDate: '',
      identityCardSeries: '',
      identityCardNumber: '',
      gender: '',
      habitat: null,
      populationCategorySelected: null,
      educationSelected: null,
      disadvantagedSelected: null,
      vulnerableSelected: null,
      mobilizationDate: '',
      disadvantaged: '',
      vulnerability: '',
      eligible: '',
      files: [],
      addresses: [],
      contacts: [],
      organization: '',
      disease: 'Screening cancer de col uterin ', // TODO - din casefiles
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.cancel = this.cancel.bind(this);
    this.prefillFields = this.prefillFields.bind(this);
  }

  componentDidMount() {
    const { data, } = this.props;
    if (data) {
      this.prefillFields(data);
    }
  }

  prefillFields(data) {
    const {
      id, active, mobilizationDate: mobilizationDateSaved, disadvantaged, vulnerability, eligible, organization,
      populationCategory, disadvantages, vulnerabilities, createdBy,
      person: {
        lastName, firstName, cnp, birthDate,
        gender, habitat, addresses, contacts, education,
        identityCardNumber,
        identityCardSeries,
      },
    } = data;

    this.setState({
      active,
      id,
      createdBy,
      disadvantaged,
      vulnerability,
      eligible,
      lastName,
      firstName,
      cnp,
      birthDate: birthDate ? moment(birthDate, 'YYYY-MM-DD') : '',
      gender,
      habitat,
      addresses,
      contacts,
      identityCardNumber,
      identityCardSeries,
      mobilizationDate: mobilizationDateSaved,
      educationSelected: education ? { label: education.label, value: education.id, } : null,
      populationCategorySelected: populationCategory ? { label: populationCategory.label, value: populationCategory.id, } : null,
      disadvantagedSelected: disadvantages ? disadvantages.map(a => ({ ...a, value: a.id, })) : null,
      vulnerableSelected: vulnerabilities ? vulnerabilities.map(a => ({ ...a, value: a.id, })) : null,
      organization,
    });
  }

  handleSubmit() {
    const {
      firstName, lastName, cnp, birthDate, gender, createdBy,
      habitat, populationCategorySelected, educationSelected, disadvantagedSelected, vulnerableSelected,
    } = this.state;
    const payload = {
      firstName,
      lastName,
      createdBy,
      cnp,
      birthDate,
      gender,
      habitat,
      populationCategory: populationCategorySelected,
      education: educationSelected,
      disadvantages: disadvantagedSelected,
      vulnerabilities: vulnerableSelected,
    };
  }

  cancel() {
    const { navigate, } = this.props;
    navigate(-1);
  }

  render() {
    const {
      id,
      active,
      lastName,
      firstName,
      cnp,
      identityCardSeries,
      identityCardNumber,
      populationCategorySelected,
      educationSelected,
      disadvantagedSelected,
      vulnerableSelected,
      mobilizationDate,
      birthDate,
      gender,
      habitat,
      disadvantaged,
      vulnerability,
      eligible,
      addresses,
      contacts,
      organization, disease,
      createdBy,
    } = this.state;

    const { simple, } = this.props;
    if (simple) {
      return (
        <CardBody className="p-5">
          <Row>
            <Col md={6} className="mb-3">
              <FormGroup row>
                <Label className="font-weight-bold" sm={3}>Creat la data:</Label>
                <Label sm={9}>{mobilizationDate ? moment(mobilizationDate).format('DD-MM-YYYY') : ''}</Label>

              </FormGroup>
            </Col>
            <Col md={6} className="mb-3">
              <FormGroup row>
                <Label className="font-weight-bold" sm={3}>Stare:</Label>
                <Label sm={9}>{active ? 'Activ' : 'Inactiv'}</Label>
              </FormGroup>
            </Col>

            <Col md={6} className="mb-3">
              <FormGroup row>
                <Label className="font-weight-bold" sm={3}>Personal furnizor:</Label>
                <Label sm={9}>{createdBy || ''}</Label>
              </FormGroup>
            </Col>

            <Col md={6} className="mb-3">
              <FormGroup row>
                <Label className="font-weight-bold" sm={3}>Beneficiar:</Label>
                <Label className="font-weight-bold" sm={9}>{`${lastName} ${firstName}`}</Label>
              </FormGroup>
            </Col>

            {/* <Col md={6} className="mb-3"> */}
            {/*  <FormGroup row> */}
            {/*    <Label className="font-weight-bold" sm={3}>Furnizor:</Label> */}
            {/*    <Label sm={9}>{organization}</Label> */}
            {/*  </FormGroup> */}
            {/* </Col> */}
          </Row>
        </CardBody>
      );
    }

    return (
      <CardBody className="p-5">
        <Row>
          <Col md={4}>
            <FormGroup row>
              <Label sm={3}>Nume</Label>
              <Col sm={9}>
                <Input
                  disabled
                  type="text"
                  value={lastName}
                />
              </Col>
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup row>
              <Label sm={3}>Prenume</Label>
              <Col sm={9}>
                <Input
                  disabled
                  type="text"
                  value={firstName}
                />
              </Col>
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup row>
              <Label sm={4}>Stare</Label>
              <Col sm={8}>
                <Input
                  disabled
                  type="text"
                  value={active ? 'Activ' : 'Inactiv'}
                />
              </Col>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <FormGroup row>
              <Label sm={3}>CNP</Label>
              <Col sm={9}>
                <Input
                  disabled
                  type="text"
                  value={cnp}
                />
              </Col>
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup row>
              <Label sm={3}>Gen</Label>
              <Col sm={9}>
                <Input
                  disabled
                  type="text"
                  value={gender}
                />
              </Col>
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup row>
              <Label sm={4}>Data nașterii</Label>
              <Col sm={8}>
                <Input
                  disabled
                  type="text"
                  value={birthDate ? moment(birthDate).format('DD-MM-YYYY') : ''}
                />
              </Col>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={2}>
            <FormGroup row>
              <Label sm={6}>Seria</Label>
              <Col sm={6}>
                <Input
                  disabled
                  type="text"
                  value={identityCardSeries || ''}
                />
              </Col>
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup row>
              <Label sm={3}>Nr</Label>
              <Col sm={9}>
                <Input
                  disabled
                  type="text"
                  value={identityCardNumber || ''}
                />
              </Col>
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup row>
              <Label sm={3}>Mediu</Label>
              <Col sm={9}>
                <Input
                  disabled
                  type="text"
                  value={habitat || ''}
                />
              </Col>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            {
            addresses && addresses.length > 0 && (
              <PatientAddresses
                readOnly
                addresses={addresses}
              />
            )
          }
          </Col>
          <Col md={4}>
            {
              contacts && contacts.length > 0 && (
                <PatientContacts
                  readOnly
                  contacts={contacts}
                />
              )
            }
          </Col>
        </Row>

        <Row>
          <Col md={8}>
            <FormGroup row>
              <Label sm={3}>Situația pe piața forței de muncă</Label>
              <Col sm={9}>
                <Input
                  disabled
                  type="textarea"
                  value={populationCategorySelected?.label || ''}
                />
              </Col>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={8}>
            <FormGroup row>
              <Label sm={3}>Nivel de educație</Label>
              <Col sm={9}>
                <Input
                  disabled
                  type="textarea"
                  value={educationSelected?.label || ''}
                />
              </Col>
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup row>
              <Label sm={4}>Eligibil</Label>
              <Col sm={8}>
                <Input
                  disabled
                  value={eligible ? 'DA' : 'NU'}
                />
              </Col>
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col md={8}>
            <FormGroup row>
              <Label sm={3}>Persoană dezavantajată</Label>
              <Col sm={9}>
                <Input
                  disabled
                  type="textarea"
                  value={disadvantagedSelected && disadvantagedSelected.length > 0 ? disadvantagedSelected.map(a => a.label).join(',') : ''}
                />
              </Col>
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup row>
              <Label sm={4}>Persoană dezavantajată</Label>
              <Col sm={8}>
                <Input
                  disabled
                  value={disadvantaged ? 'DA' : 'NU'}
                />
              </Col>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={8}>
            <FormGroup row>
              <Label sm={3}>Grupuri vulnerabile</Label>
              <Col sm={9}>
                <Input
                  disabled
                  type="textarea"
                  value={vulnerableSelected && vulnerableSelected.length > 0 ? vulnerableSelected.map(a => a.label).join(', ') : ''}
                />
              </Col>
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup row>
              <Label sm={4}>Persoană vulnerabilă</Label>
              <Col sm={8}>
                <Input
                  disabled
                  value={vulnerability ? 'DA' : 'NU'}
                />
              </Col>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={8}>
            <FormGroup row>
              <Label sm={3}>Data introducere beneficiar</Label>
              <Col sm={9}>
                <Input
                  defaultValue={mobilizationDate ? moment(mobilizationDate).format('DD-MM-YYYY') : ''}
                  type="text"
                  className="form-control"
                  id=""
                  disabled
                  placeholder="dd-mm-yyyy"
                />
              </Col>
            </FormGroup>
          </Col>
        </Row>
      </CardBody>
    );
  }
}

const mapStateToProps = state => ({
  patients: state.patients.patients,
  selectedPatient: state.patients.selectedPatient,
});

const mapDispatchToProps = dispatch => ({
  getSinglePatient: id => dispatch(patientsOperations.getSinglePatient(id)),
  selectPatient: data => dispatch(patientsOperations.selectPatient(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PatientFormView);
