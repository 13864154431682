import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, CardHeader, Spinner } from 'reactstrap';

import './index.scss';
import { CasefileFilterExtended, CasefileFilterSimple, CasefilesTable } from './components';
import { casefilesOperations } from '../../../redux/ducks/casefiles';
import Pagination from '../../Pagination';
import { organizationsOperations } from '../../../redux/ducks/organizations';
import { doctorsOperations } from '../../../redux/ducks/doctors';

const defaultFilters = {
  page: 1,
  size: 10,
  sortBy: 'createTs',
  sortDir: 'desc',
};

class Casefiles extends Component {
  constructor() {
    super();
    this.state = {
      filters: defaultFilters,
      stateCasefiles: [],
      filterType: 'simple',
      loading: true,
      currentPage: 0,
      firstElem: 0,
      lastElem: 0,
      totalNo: 0,
      totalPages: 0,
      organizations: [],
      regions: [],
    };

    this.getCasefiles = this.getCasefiles.bind(this);
    this.onBrowserNav = this.onBrowserNav.bind(this);
    this.parseUrlAndSetFilters = this.parseUrlAndSetFilters.bind(this);
    this.setFilters = this.setFilters.bind(this);
    this.addFilterAndSearch = this.addFilterAndSearch.bind(this);
    this.setSortFilter = this.setSortFilter.bind(this);
    this.setActiveStatus = this.setActiveStatus.bind(this);
    this.selectFilterType = this.selectFilterType.bind(this);
    this.resetFilters = this.resetFilters.bind(this);
  }

  componentDidMount() {
    const { auth, navigate, getOrganizationsSimple, } = this.props;
    const { view, } = auth;
    if (view && !view.includes('CASEFILE_LIST')) {
      setTimeout(() => {
        navigate('/organization');
      }, 10);
    }
    window.addEventListener('popstate', this.onBrowserNav);

    setTimeout(() => {
      this.parseUrlAndSetFilters();
    }, 50);

    getOrganizationsSimple()
      .then((res => {
        const { organizations, regions, } = res.data.data;
        this.setState({
          organizations,
          regions,
        });
      }))
      .catch((err => {
        console.log('err: ', err);
      }));
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.onBrowserNav);
  }

  onBrowserNav() {
    this.setState({ filters: defaultFilters, key: Math.random(), }, () => {
      this.parseUrlAndSetFilters();
    });
  }

  parseUrlAndSetFilters() {
    const { searchParams, } = this.props;
    const parsedFilters = Object.fromEntries([...searchParams]);
    if (Object.keys(parsedFilters).length > 0) {
      this.setFilters(parsedFilters);
    } else {
      this.resetFilters();
    }
  }

  setFilters(data) {
    this.setState({ filters: data, }, () => {
      this.getCasefiles();
    });
  }

  resetFilters() {
    this.setState({ filters: defaultFilters, key: Math.random(), }, () => {
      this.getCasefiles();
    });
  }

  getCasefilesData(url) {
    const { getCasefiles, } = this.props;
    this.setState({ loading: true, });
    getCasefiles(url)
      .then((res => {
        const { totalElements, totalPages, data, } = res.data;
        const { filters, } = this.state;
        this.setState({
          stateCasefiles: data,
          totalNo: totalElements,
          firstElem: (filters.page - 1) * filters.size + 1,
          lastElem: filters.page * filters.size > totalElements ? totalElements : filters.page * filters.size,
          totalPages,
          loading: false,
        });
      }))
      .catch((err => {
        console.log('err: ', err);
        this.setState({ loading: false, });
      }));
  }

  getCasefiles() {
    const { location: { pathname, }, navigate, } = this.props;
    const { filters, } = this.state;
    const formatFilters = Object.entries(filters).filter(elem => elem[1] !== '').map(elem => elem.join('=')).join('&');
    navigate(`${pathname}?${formatFilters}`);
    this.getCasefilesData(`${pathname}s?${formatFilters}`);
  }

  addFilterAndSearch(data, pageSelect, removeKey) {
    const { filters, } = this.state;
    if (removeKey) delete filters[removeKey];
    const newFilters = {
      ...filters,
      ...data,
      ...(!pageSelect && { page: 1, }),
    };
    this.setFilters(newFilters);
  }

  setSortFilter(e) {
    let newSortDir;
    const newSortBy = e.target.id;
    const { filters: { sortBy, sortDir, }, } = this.state;
    if (e.target.id === sortBy) {
      newSortDir = sortDir === 'asc' ? 'desc' : 'asc';
    } else {
      newSortDir = 'asc';
    }
    this.addFilterAndSearch({ sortBy: newSortBy, sortDir: newSortDir, }, true);
  }

  selectFilterType() {
    const { filterType, } = this.state;
    const newType = filterType === 'simple' ? 'extended' : 'simple';
    this.setState({ filterType: newType, });
  }

  setActiveStatus(data) {
    const { active, id, } = data;
    const { updateCasefile, } = this.props;
    updateCasefile({ active, id, })
      .then(() => {
        this.getCasefiles();
      })
      .catch(err => {
        console.log('err: ', err);
      });
  }

  render() {
    const {
      stateCasefiles, filterType, totalPages,
      firstElem, lastElem, totalNo, filters, key, loading,
      regions, organizations,
    } = this.state;
    const { auth, getDoctorsSimple, } = this.props;
    return (
      <>
        {
          loading && (
            <div
              className="loading-container"
            >
              <Spinner />
            </div>
          )
        }
        <div className={`container-fluid position-relative ${loading ? 'no-events loading-data' : ''}`}>
          <Card className="mt-4">
            <CardHeader className="position-relative">
              <div className="d-inline-block">
                <h3><span>Dosare</span></h3>
                <div className="info jhi-item-count">
                  {`Se afiseaza ${firstElem} - ${lastElem} din ${totalNo} elemente.`}
                </div>
              </div>
            </CardHeader>
            <CardBody>
              {
                filterType === 'simple'
                  ? (
                    <CasefileFilterSimple
                      key={key}
                      addFilterAndSearch={this.addFilterAndSearch}
                      setFilterType={this.selectFilterType}
                      resetFilters={this.resetFilters}
                      filters={filters}
                    />
                  )
                  : (
                    <CasefileFilterExtended
                      key={key}
                      getDoctorsSimple={getDoctorsSimple}
                      organizations={organizations}
                      regions={regions}
                      addFilterAndSearch={this.addFilterAndSearch}
                      setFilterType={this.selectFilterType}
                      resetFilters={this.resetFilters}
                      filters={filters}
                    />
                  )
              }
              <CasefilesTable
                auth={auth}
                tableData={stateCasefiles}
                setActiveStatus={this.setActiveStatus}
                setSortFilter={this.setSortFilter}
                filters={filters}
              />
              <Pagination
                totalPages={totalPages}
                totalNo={totalNo}
                filters={filters}
                addFilterAndSearch={this.addFilterAndSearch}
              />
            </CardBody>
          </Card>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  casefiles: state.casefiles.casefiles,
  selectedCasefile: state.casefiles.selectedCasefile,
});

const mapDispatchToProps = dispatch => ({
  getCasefiles: url => dispatch(casefilesOperations.getCasefiles(url)),
  updateCasefile: data => dispatch(casefilesOperations.updateCasefile(data)),
  getOrganizationsSimple: () => dispatch(organizationsOperations.getOrganizationsSimple()),
  getDoctorsSimple: orgId => dispatch(doctorsOperations.getDoctorsSimple(orgId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Casefiles);
