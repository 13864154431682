import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faRedoAlt, faSearch } from '@fortawesome/free-solid-svg-icons';
import { Input } from 'reactstrap';

function PatientFilterSimple({ type, setFilterType, addFilterAndSearch, resetFilters, filters, }) {
  const [inputs, setInputs] = useState({});

  useEffect(
    () => {
      const { firstName = '', lastName = '', cnp = '', doctor = '', } = filters;
      setInputs({
        firstName,
        lastName,
        cnp,
        doctor,
      });
    },
    [filters.firstName, filters.lastName, filters.cnp, filters.doctor]
  );

  const handleFilterType = () => {
    setFilterType(type);
  };

  const handleSearch = () => {
    addFilterAndSearch({ lastName, firstName, cnp, doctor, });
  };

  const handleChange = e => {
    setInputs(prevState => ({ ...prevState, [e.target.name]: e.target.value, }));
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const handleResetFilters = () => {
    resetFilters();
  };

  const { firstName, lastName, cnp, doctor, } = inputs;

  return (
    <div className="row mt-4">
      <div className="col-sm-12">
        <div className="input-group flex-nowrap">
          <div className="input-group-prepend">
            <span id="addon-wrapping" className="input-group-text">Nume:</span>
            <Input
              name="lastName"
              value={lastName || ''}
              onKeyDown={handleKeyDown}
              onChange={handleChange}
              type="text"
              className="form-control principalSearchField"
              placeholder="Căutare"
            />
          </div>
          <div className="input-group-prepend">
            <span id="addon-wrapping" className="input-group-text">Prenume:</span>
            <Input
              name="firstName"
              value={firstName || ''}
              onKeyDown={handleKeyDown}
              onChange={handleChange}
              type="text"
              className="form-control principalSearchField"
              placeholder="Căutare"
            />
          </div>
          <div className="input-group-prepend">
            <span id="addon-wrapping" className="input-group-text">CNP:</span>
            <Input
              name="cnp"
              value={cnp || ''}
              onKeyDown={handleKeyDown}
              onChange={handleChange}
              type="text"
              className="form-control principalSearchField"
              placeholder="Căutare"
            />
          </div>
          <div className="input-group-prepend">
            <span id="addon-wrapping" className="input-group-text">Personal Furnizor:</span>
            <Input
              name="doctor"
              value={doctor || ''}
              onKeyDown={handleKeyDown}
              onChange={handleChange}
              type="text"
              className="form-control principalSearchField"
              placeholder="Căutare"
            />
          </div>

          <div className="input-group-append">
            <div
              onClick={handleResetFilters}
              className="input-group-text bg-transparent border-left-0 border cursor-pointer"
            >
              <FontAwesomeIcon icon={faRedoAlt} />
            </div>
            <div
              onClick={handleSearch}
              className="input-group-text bg-transparent border-left-0 border cursor-pointer"
            >
              <FontAwesomeIcon icon={faSearch} />
            </div>
            <div
              onClick={handleFilterType}
              id="search-expanded"
              className="input-group-text bg-transparent border-left-0 border cursor-pointer"
            >
              <FontAwesomeIcon icon={faChevronDown} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PatientFilterSimple;
