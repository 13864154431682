// read about Object.prototype.toString.call at https://toddmotto.com/understanding-javascript-types-and-reliable-type-checking/#true-object-types
import axios from 'axios';
import Cookies from 'universal-cookie';

const baseURL = process.env.REACT_APP_BASE_URL;

function addHeaders() {
  if (typeof document !== 'undefined') {
    const token = localStorage.getItem('token');
    if (typeof token !== 'undefined') {
      return {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      };
    }
  }
  return {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
}

function requestHeaders(extraHeaders) {
  return {
    ...addHeaders(),
    ...extraHeaders,
  };
}

function calcResponseType(responseType) {
  if (responseType) return responseType;
  return 'json';
}

const fetchHelper = (url, method, extraHeaders, body, responseType) => {
  const details = {
    baseURL,
    method,
    url,
    data: body,
    headers: requestHeaders(extraHeaders),
    responseType: calcResponseType(responseType),
    // responseType: 'json', // options are 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream',
  };
  return axios(details);
};

export default fetchHelper;
