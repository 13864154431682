import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, CardHeader, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import Select from 'react-select';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';

const rezultatHistoData = [
  { id: '529B923D-0EB7-EB11-8C22-000C2901B5F1', value: 'Adenocarcinom', code: 'histopatologic_adenocarcinom', },
  { id: '549B923D-0EB7-EB11-8C22-000C2901B5F1', value: 'Adenocarcinom in situ (AIS)', code: 'histopatologic_AIS', },
  { id: '589B923D-0EB7-EB11-8C22-000C2901B5F1', value: 'Alte tumori epiteliale', code: 'histopatologic_alte_tumori', },
  { id: '579B923D-0EB7-EB11-8C22-000C2901B5F1', value: 'Carcinom scuamos', code: 'histopatologic_carcinom', },
  { id: '599B923D-0EB7-EB11-8C22-000C2901B5F1', value: 'HSIL', code: 'histopatologic_HSIL', },
  { id: '569B923D-0EB7-EB11-8C22-000C2901B5F1', value: 'LSIL', code: 'histopatologic_LSIL', },
  { id: '539B923D-0EB7-EB11-8C22-000C2901B5F1', value: 'Negativ (normal)', code: 'histopatologic_negativ', },
  { id: '5A9B923D-0EB7-EB11-8C22-000C2901B5F1', value: 'Nereprezentativ / neconcludent', code: 'histopatologic_neconcludent', }];

const checkboxInputs = [
  'biopsieCiupireSec42FS1',
  'leepSec42FS1',
  'chiuretajCervSec42FS1',
  'chiuretajMetrSec42FS1',
  'repetareColp2Sec42FS1',
  'atitudineHistoSec42FS1',
  'gradRiscHpvReintraresec42FS1'
];

function BiopsieEdit({ data, saveData, changeTab, }) {
  const [inputs, setInputs] = useState({});
  const [centruRecoltareBiopsieSecBiopsieFS1, setCentruRecoltareBiopsieSecBiopsieFS1] = useState([]);
  const [labAnatoPatoSec42FS1, setLabAnatoPatoSec42FS1] = useState([]);
  const [doctors, setDoctors] = useState([]);
  const [patologi, setPatologi] = useState([]);

  useEffect(() => {
    const inputs = data.attributes;
    setInputs(inputs);
    setDoctors(data?.medicRecoltareBiopsieSecBiopsieFS1 || []);
    setPatologi(data?.medicAnatoPatoSec42FS1 || []);
    setCentruRecoltareBiopsieSecBiopsieFS1(data.centruRecoltareBiopsieSecBiopsieFS1);
    setLabAnatoPatoSec42FS1(data.labAnatoPatoSec42FS1);
    if (Object.keys(inputs).length === 0) return;
    checkboxInputs.forEach(elem => {
      inputs[elem].value = inputs[elem].value.toString();
    });
  }, []);

  const {
    biopsieCiupireSec42FS1,
    dataRecoltareBiopsieSecBiopsieFS1,
    // centruRecoltareBiopsieSecBiopsieFS1,
    medicRecoltareBiopsieSecBiopsieFS1,
    leepSec42FS1,
    chiuretajCervSec42FS1,
    chiuretajMetrSec42FS1,
    repetareColp2Sec42FS1,
    atitudineHistoSec42FS1,
    gradRiscHpvReintraresec42FS1,
    // labAnatoPatoSec42FS1,
    medicAnatoPatoSec42FS1,
    nrBiopsieSec42FS1,
    dataPrimireBiopsieSec42FS1,
    dataInterBiopsieSec42FS1,
    rezHistoPatoSec42FS1,
  } = inputs;

  const handleChange = e => {
    const curentVal = { ...inputs[e.target.name], value: e.target.value, };
    setInputs(prevState => ({ ...prevState, [e.target.name]: curentVal, }));
  };

  const handleSelect = (selected, details) => {
    const curentVal = {
      ...inputs[details.name],
      id: selected?.id || '',
      value: selected?.value || '',
    };
    setInputs(prevState => ({ ...prevState, [details.name]: curentVal, }));
  };

  const selectDate = (val, name) => {
    const dataSubmit = moment(val).utc().format();
    const curentVal = { ...inputs[name], value: dataSubmit, };
    setInputs(prevState => ({ ...prevState, [name]: curentVal, }));
  };

  const handleCheck = e => {
    const newVal = e.target.value === 'false' ? 'true' : 'false';
    const curentVal = { ...inputs[e.target.name], value: newVal, };
    setInputs(prevState => ({ ...prevState, [e.target.name]: curentVal, }));
  };

  const save = e => {
    const saveStatus = e.target.id;
    checkboxInputs.forEach(elem => {
      inputs[elem].value = inputs[elem].value === 'true';
    });
    saveData(inputs, saveStatus);
  };

  const setTab = e => { changeTab(e.target.id); };

  const calcShowSaveBtn = () =>
  // if (typeof rezFinalSec44FS1 === 'undefined' || rezFinalSec44FS1?.value === '') {
  //   return false;
  // }
  // if (typeof medicAnatoPatoCertificareSec3BFS1 === 'undefined' || medicAnatoPatoCertificareSec3BFS1?.value === '') {
  //   return false;
  // }
    true
    // className={(typeof rezHpvSec34FS1 !== 'undefined' && rezHpvSec34FS1.value !== '') ? '' : 'invalid'}
  ;

  const showBtns = calcShowSaveBtn();

  return (
    <Card>
      <CardHeader>
        <h3 className="mb-0">
          <div className="d-flex">
            <div>
              <span className="mr-3">Biopsie</span>
            </div>
            {showBtns === true && (
              <div>
                <Button id="draft" size="sm" color="primary" className="btn-rounded" onClick={save}>Draft</Button>
                <Button id="final" size="sm" color="success" className="ml-2 btn-rounded" onClick={save}>Finalizare follow-up</Button>
              </div>
            )}
            <div className="ml-auto">
              <Button id="-" size="sm" color="secondary" className="ml-auto btn-pill" onClick={setTab}>{'<'}</Button>
              <Button id="+" size="sm" color="primary" className="ml-1 btn-pill" onClick={setTab}>{'>'}</Button>
            </div>
          </div>
        </h3>
      </CardHeader>
      <CardBody className="pb-5">
        <Col
          className="underline-hover form-group dont-display ng-untouched ng-pristine"
          style={{ backgroundColor: 'rgba(233, 236, 239, 0.3)', }}
        >
          <Label>
            Date specifice recomandărilor ulterior colposcopiei
          </Label>
        </Col>

        <Row>
          <Col md={6}>
            <FormGroup check>
              <Input
                type="checkbox"
                checked={biopsieCiupireSec42FS1?.value === 'true'}
                value={biopsieCiupireSec42FS1?.value === 'true'}
                name="biopsieCiupireSec42FS1"
                onChange={handleCheck}
              />
              <Label>Biopsie prin ciupire (punch)</Label>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label>
                Data recoltării biopsiei
              </Label>
              <FormGroup row className="align-items-center">
                <Col sm={10} className="pr-0">
                  <Input
                    defaultValue={dataRecoltareBiopsieSecBiopsieFS1 ? moment(dataRecoltareBiopsieSecBiopsieFS1?.value).format('DD-MM-YYYY') : ''}
                    type="text"
                    className="form-control bg-white"
                    id=""
                    disabled
                    placeholder=""
                  />
                </Col>
                <Col sm={2} className="pl-0">
                  <DatePicker
                    name={dataRecoltareBiopsieSecBiopsieFS1}
                    locale="ro"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    selected={moment(dataRecoltareBiopsieSecBiopsieFS1?.value).toDate()}
                    onChange={val => selectDate(val, 'dataRecoltareBiopsieSecBiopsieFS1')}
                    customInput={<Button className="form-control"><FontAwesomeIcon icon={faCalendarAlt} /></Button>}
                  />
                </Col>
              </FormGroup>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>Centrul recoltării biopsiei</Label>
              <Input
                required
                type="text"
                name="centruRecoltareBiopsieSecBiopsieFS1"
                disabled
                value={centruRecoltareBiopsieSecBiopsieFS1?.value || ''}
              />
              {/* <Select */}
              {/*  value={centruRecoltareBiopsieSecBiopsieFS1 || ''} */}
              {/*  onChange={handleSelect} */}
              {/*  getOptionValue={option => option.id} */}
              {/*  getOptionLabel={option => option.value} */}
              {/*  className="basic-single" */}
              {/*  classNamePrefix="select" */}
              {/*  placeholder="" */}
              {/*  name="centruRecoltareBiopsieSecBiopsieFS1" */}
              {/*  options={mockOrganizations} */}
              {/* /> */}
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label>Medicul care a realizat biopsia </Label>
              <Select
                value={medicRecoltareBiopsieSecBiopsieFS1 || ''}
                onChange={handleSelect}
                getOptionValue={option => option.id}
                getOptionLabel={option => option.value}
                className="basic-single"
                classNamePrefix="select"
                placeholder=""
                name="medicRecoltareBiopsieSecBiopsieFS1"
                options={doctors}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row className="mt-2 mb-2">
          <Col>
            <FormGroup check>
              <Input
                type="checkbox"
                checked={leepSec42FS1?.value === 'true'}
                value={leepSec42FS1?.value === 'true'}
                name="leepSec42FS1"
                onChange={handleCheck}
              />
              <Label>Procedură excizională LEEP </Label>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup check>
              <Input
                type="checkbox"
                checked={chiuretajCervSec42FS1?.value === 'true'}
                value={chiuretajCervSec42FS1?.value === 'true'}
                name="chiuretajCervSec42FS1"
                onChange={handleCheck}
              />
              <Label>Chiuretaj endocervical </Label>
            </FormGroup>
          </Col>
        </Row>

        <Row className="mt-2 mb-2">
          <Col>
            <FormGroup check>
              <Input
                type="checkbox"
                checked={chiuretajMetrSec42FS1?.value === 'true'}
                value={chiuretajMetrSec42FS1?.value === 'true'}
                name="chiuretajMetrSec42FS1"
                onChange={handleCheck}
              />
              <Label>Chiuretaj endometrial </Label>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup check>
              <Input
                type="checkbox"
                checked={repetareColp2Sec42FS1?.value === 'true'}
                value={repetareColp2Sec42FS1?.value === 'true'}
                name="repetareColp2Sec42FS1"
                onChange={handleCheck}
              />
              <Label>Repetarea colposcopiei </Label>
            </FormGroup>
          </Col>
        </Row>

        <Row className="mt-2 mb-2">
          <Col>
            <FormGroup check>
              <Input
                type="checkbox"
                checked={atitudineHistoSec42FS1?.value === 'true'}
                value={atitudineHistoSec42FS1?.value === 'true'}
                name="atitudineHistoSec42FS1"
                onChange={handleCheck}
              />
              <Label>Atitudine în funcţie de rezultatul histopatologic  </Label>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup check>
              <Input
                type="checkbox"
                checked={gradRiscHpvReintraresec42FS1?.value === 'true'}
                value={gradRiscHpvReintraresec42FS1?.value === 'true'}
                name="gradRiscHpvReintraresec42FS1"
                onChange={handleCheck}
              />
              <Label>Reintrare în program în funcţie de gradul de risc dat de tipul de HPV (exemplu: 6 luni, 12 luni, 5 ani)  </Label>
            </FormGroup>
          </Col>
        </Row>

        <Col
          className="underline-hover form-group dont-display ng-untouched ng-pristine"
          style={{ backgroundColor: 'rgba(233, 236, 239, 0.3)', }}
        >
          <Label>
            Datele specifice rezultatelor biopsiei
          </Label>
        </Col>

        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>Centrul de servicii medicale specializate (laborator de anatomie patologică) unde s-a efectuat citirea biopsiei</Label>
              {/* <Select */}
              {/*  value={labAnatoPatoSec42FS1 || ''} */}
              {/*  onChange={handleSelect} */}
              {/*  getOptionValue={option => option.id} */}
              {/*  getOptionLabel={option => option.value} */}
              {/*  className="basic-single" */}
              {/*  classNamePrefix="select" */}
              {/*  placeholder="" */}
              {/*  name="labAnatoPatoSec42FS1" */}
              {/*  options={labs} */}
              {/* /> */}
              <Input
                required
                type="text"
                name="labAnatoPatoSec42FS1"
                disabled
                value={labAnatoPatoSec42FS1?.value || ''}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label>Medicul de specialitate anatomo-patolog care a efectuat citirea biopsiei </Label>
              <Select
                value={medicAnatoPatoSec42FS1 || ''}
                onChange={handleSelect}
                getOptionValue={option => option.id}
                getOptionLabel={option => option.value}
                className="basic-single"
                classNamePrefix="select"
                placeholder=""
                name="medicAnatoPatoSec42FS1"
                options={patologi}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>Numărul biopsiei (autogenerat de sistem după salvare) </Label>
              <Input
                required
                type="text"
                name="nrBiopsieSec42FS1"
                disabled
                value={nrBiopsieSec42FS1?.value || ''}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>Data primirii biopsiei</Label>

              <FormGroup row className="align-items-center">
                <Col sm={10} className="pr-0">
                  <Input
                    defaultValue={dataPrimireBiopsieSec42FS1 ? moment(dataPrimireBiopsieSec42FS1?.value).format('DD-MM-YYYY') : ''}
                    type="text"
                    className="form-control bg-white"
                    id=""
                    disabled
                    placeholder=""
                  />
                </Col>
                <Col sm={2} className="pl-0">
                  <DatePicker
                    name={dataPrimireBiopsieSec42FS1}
                    locale="ro"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    selected={moment(dataPrimireBiopsieSec42FS1?.value).toDate()}
                    onChange={val => selectDate(val, 'dataPrimireBiopsieSec42FS1')}
                    customInput={<Button className="form-control"><FontAwesomeIcon icon={faCalendarAlt} /></Button>}
                  />
                </Col>
              </FormGroup>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label>Data interpretării</Label>
              <FormGroup row className="align-items-center">
                <Col sm={10} className="pr-0">
                  <Input
                    defaultValue={dataInterBiopsieSec42FS1 ? moment(dataInterBiopsieSec42FS1?.value).format('DD-MM-YYYY') : ''}
                    type="text"
                    className="form-control bg-white"
                    id=""
                    disabled
                    placeholder=""
                  />
                </Col>
                <Col sm={2} className="pl-0">
                  <DatePicker
                    name={dataInterBiopsieSec42FS1}
                    locale="ro"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    selected={moment(dataInterBiopsieSec42FS1?.value).toDate()}
                    onChange={val => selectDate(val, 'dataInterBiopsieSec42FS1')}
                    customInput={<Button className="form-control"><FontAwesomeIcon icon={faCalendarAlt} /></Button>}
                  />
                </Col>
              </FormGroup>
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>Rezultatul histopatologic </Label>
              <Select
                value={rezHistoPatoSec42FS1 || ''}
                onChange={handleSelect}
                getOptionValue={option => option.id}
                getOptionLabel={option => option.value}
                className="basic-single"
                classNamePrefix="select"
                placeholder=""
                name="rezHistoPatoSec42FS1"
                menuPlacement="top"
                options={rezultatHistoData}
              />
            </FormGroup>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}

export default BiopsieEdit;
