import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { authOperations } from '../../../redux/ducks/auth';

import './index.scss';
import { Form, Input, UncontrolledAlert } from 'reactstrap';

function PasswordReset({ setNewPassword, checkResetPasswordToken, }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [displayForm, setDisplayForm] = useState(false);
  const [error, setError] = useState(null);
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [token, setToken] = useState('');

  useEffect(() => {
    const resetToken = `${location.pathname.split('passwordreset/')[1]}`;
    checkResetPasswordToken(resetToken)
      .then(() => {
        setDisplayForm(true);
        setToken(resetToken);
      })
      .catch(() => {
        setError('The reset token is expired');
      });
  }, []);

  const handleLogin = () => {
    if (confirmPassword === '' || password === '') return;
    if (confirmPassword !== password) return;
    setNewPassword({ password, }, token)
      .then(() => {
        setTimeout(() => {
          navigate('/check');
        }, 100);
      })
      .catch(err => {
        const error = 'Parola nu a putut fi salvata.';
        setError(error);
      });
  };

  const handlePasswordChange = e => {
    setPassword(e.target.value);
    checkPasswordError(e.target.value);
  };

  const handleConfirmPasswordChange = e => {
    setConfirmPassword(e.target.value);
    checkConfirmPasswordError(e.target.value);
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      handleLogin();
    }
  };

  const checkPasswordError = value => {
    const errorsArr = [];
    if (!/^(?=.*[A-Z])/.test(value)) {
      errorsArr.push('Parola trebuie să conțină cel puțin o literă mare!');
    }
    if (!/^(?=.*[a-z])/.test(value)) {
      errorsArr.push('Parola trebuie să conțină cel puțin o literă mică!');
    }
    if (!/^(?=.*[0-9])/.test(value)) {
      errorsArr.push('Parola trebuie să conțină cel puțin o cifră!');
    }
    if (!/^(?=.*[!@#\$%\^&\*])/.test(value)) {
      errorsArr.push('Parola trebuie să conțină cel puțin un caracter special!');
    }
    if (value.length < 8) {
      errorsArr.push('Parola dumneavoastră trebuie să conțină cel puțin 8 caractere!');
    }
    setPasswordError(errorsArr.length > 0 ? errorsArr : null);
  };

  const checkConfirmPasswordError = val => {
    if (passwordError) return;
    if (password !== val) {
      setConfirmPasswordError('Parola și confirmarea parolei nu se potrivesc!');
    } else {
      setConfirmPasswordError(null);
    }
  };

  // const resetPassword = () => {
  //   navigate('/check/send-reset-mail');
  // };

  const passwordErrorDom = [];
  if (passwordError) {
    passwordError.forEach((mess, idx) => passwordErrorDom.push(
      <p className="text-danger mb-0 password-error-message" key={idx}><small>{mess}</small></p>
    ));
  }

  return (
    <div className="container d-flex justify-content-center pt-5">
      {
        error && (
          <UncontrolledAlert className="login-alert" color="danger">
            {error}
          </UncontrolledAlert>
        )
      }

      <div className="mt-5" />
      {displayForm ? (
        <div className="login-form mt-5">
          <div className="login-form-body">
            <div className="form-group">
              <input
                tabIndex={1}
                onChange={handlePasswordChange}
                type="password"
                id="password"
                name="password"
                className={`form-control ${(password === '' || passwordError) ? 'invalid' : 'valid'}`}
                placeholder="Parolă nouă"
              />
              {passwordErrorDom}
            </div>
            <div className="form-group">
              <input
                tabIndex={2}
                onChange={handleConfirmPasswordChange}
                type="password"
                id="confirmPassword"
                name="confirmPassword"
                className={`form-control ${(confirmPassword === '' || confirmPassword !== password) ? 'invalid' : 'valid'}`}
                placeholder="Confirmă noua parolă"
              />
              {
                confirmPasswordError && (
                  <p className="text-danger mb-0 password-error-message">
                    <small>{confirmPasswordError}</small>
                  </p>
                )
              }
            </div>
            <div className="form-group">
              <button
                onKeyDown={handleKeyDown}
                onClick={handleLogin}
                tabIndex={3}
                className={`btn btn-primary ${(confirmPassword === '' || password === '' || (confirmPassword !== password)) && 'disabled-btn'}`}
              >
                Salvează parola
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

const mapStateToProps = state => ({
  auth: state.auth,
  users: state.users.users,
  selectedUser: state.users.selectedUser,
});

const mapDispatchToProps = dispatch => ({
  checkResetPasswordToken: data => dispatch(authOperations.checkResetPasswordToken(data)),
  setNewPassword: (data, token) => dispatch(authOperations.setNewPassword(data, token)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PasswordReset);
