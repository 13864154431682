import React from 'react';
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  Col
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faTimes } from '@fortawesome/free-solid-svg-icons';

function DeleteAddressModal({ close, address, setAddress, }) {
  const confirmDelete = () => {
    setAddress(null);
    close();
  };
  return (
    <Modal size="xl" isOpen>
      <ModalHeader className="p-4" toggle={close}>Confirmați operația de ștergere</ModalHeader>
      <ModalBody className="p-4">
        <p className="mb-0">{`Sunteți sigur că doriți să ștergeți adresa ${address}?`}</p>
      </ModalBody>
      <ModalFooter>
        <Col md={6} className="text-right">
          <Button
            onClick={close}
            color="secondary"
          >
            <FontAwesomeIcon icon={faBan} />
            <span className="ml-1">Anulare</span>
          </Button>
          <Button
            color="danger"
            className="ml-1"
            onClick={confirmDelete}
          >
            <FontAwesomeIcon icon={faTimes} />
            <span className="ml-1">Stergere</span>
          </Button>
        </Col>
      </ModalFooter>
    </Modal>
  );
}

export default DeleteAddressModal;
