import React, { useState, useEffect } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Container, Label, Row, Spinner } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import { usersOperations } from '../../../../redux/ducks/users';
import { userRolesOptions } from '../users.helper';

function UserView({ location, navigate, getSingleUser, auth, }) {
  const [user, setUser] = useState(null);

  useEffect(() => {
    if (!auth?.view.includes('USER_LIST')) {
      setTimeout(() => { navigate('/'); }, 10);
    } else {
      const splitUrl = location.pathname.split('/user/')[1];

      if (splitUrl !== 'new') {
        getUserData(splitUrl);
      }
    }
  }, []);

  const getUserData = url => {
    const id = url.split('/')[0];
    getSingleUser(id)
      .then((res => {
        // this.prefillFields(res.data.data);
        setUser(res.data.data);
      }))
      .catch((err => {
        console.log('err: ', err);
      }));
  };

  const goBack = () => {
    const { state, } = location;
    if (state) {
      const { state: { from: { search, pathname, }, }, } = location;
      navigate(`${pathname}${search}`);
    } else {
      navigate('/user');
    }
  };

  const goEdit = () => {
    const { id, } = user;
    navigate(`/user/${id}/edit`);
  };

  if (user === null) {
    return (
      <Container fluid className="d-flex">
        <Row className="pt-5 mt-5 justify-content-center w-100">
          <Spinner />
        </Row>
      </Container>
    );
  }

  const { email, username, auxiliarPerson, doctor, patient, phone, profiles = [], active, } = user;
  const personData = auxiliarPerson ? { label: 'Personal Auxiliar', data: auxiliarPerson, }
    : doctor ? { label: 'Personal Furnizor', data: doctor, }
      : patient ? { label: 'Pacient', data: patient, }
        : null;

  const rolesDom = [];
  profiles.forEach(a => {
    const selectedRole = userRolesOptions.find(elem => elem.value === a.value);
    if (selectedRole) {
      rolesDom.push(selectedRole.label);
    }
  });

  return (
    <Container fluid className="d-flex">
      <Row className="justify-content-center w-100">
        <Col sm={8}>
          <Card className="mt-3">
            <CardHeader>
              <h3 className="mb-0">Utilizator</h3>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md={6} className="mb-3">
                  <Label className="mb-0 font-weight-bold">Email</Label>
                  <div>{email}</div>
                </Col>
                <Col md={6} className="mb-3">
                  <Label className="mb-0 font-weight-bold">Telefon</Label>
                  <div>{phone}</div>
                </Col>
                <Col md={6} className="mb-3">
                  <Label className="mb-0 font-weight-bold">Nume utilizator</Label>
                  <div>{username}</div>
                </Col>
                <Col md={6} className="mb-3">
                  <Label className="mb-0 font-weight-bold">Stare</Label>
                  <div>{active ? 'activ' : 'inactiv'}</div>
                </Col>
                { personData && (
                  <>
                    <Col md={6} className="mb-3">
                      <Label className="mb-0 font-weight-bold">{personData.label}</Label>
                      <div>{`${personData.data.person.lastName} ${personData.data.person.firstName}`}</div>
                    </Col>
                    <Col md={6} className="mb-3">
                      <Label className="mb-0 font-weight-bold">Roluri</Label>
                      <div>{rolesDom.join(', ')}</div>
                    </Col>
                    <Col md={6} className="mb-3">
                      <Label className="mb-0 font-weight-bold">Furnizor</Label>
                      <div>{personData.data?.organization?.label || ''}</div>
                    </Col>
                  </>

                )}
              </Row>

              <Row className="mt-3">
                <Col md={6} />
                <Col md={6} className="text-right">
                  <Button
                    onClick={goBack}
                    color="secondary"
                  >
                    <FontAwesomeIcon icon={faArrowLeft} />
                    <span className="ml-1">Înapoi</span>
                  </Button>
                  {
                    auth?.update.includes('USER_UPDATE') && (
                      <Button
                        onClick={goEdit}
                        color="primary"
                        className="ml-1"
                      >
                        <FontAwesomeIcon icon={faPencilAlt} />
                        <span className="ml-1">Editare</span>
                      </Button>
                    )
                  }
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

const mapStateToProps = state => ({
  auth: state.auth,
  users: state.users.users,
  selectedUser: state.users.selectedUser,
});

const mapDispatchToProps = dispatch => ({
  getSingleUser: id => dispatch(usersOperations.getSingleUser(id)),
  selectUser: data => dispatch(usersOperations.selectUser(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserView);
