import React, { Component } from 'react';
import { connect } from 'react-redux';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, CardBody, CardHeader, Spinner } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { OrganizationTable, OrganizationFiltersSimple, OrganizationFiltersExtended } from './components/index';

import { organizationsOperations } from '../../../redux/ducks/organizations';
import Pagination from '../../Pagination';

import './index.scss';

const defaultFilters = {
  page: 1,
  size: 10,
  sortBy: 'code',
  sortDir: 'asc',
};
class Organization extends Component {
  constructor() {
    super();

    this.setFilters = this.setFilters.bind(this);
    this.addFilterAndSearch = this.addFilterAndSearch.bind(this);
    this.selectFilterType = this.selectFilterType.bind(this);
    this.setSortFilter = this.setSortFilter.bind(this);
    this.resetFilters = this.resetFilters.bind(this);
    this.parseUrlAndSetFilters = this.parseUrlAndSetFilters.bind(this);
    this.setActiveStatus = this.setActiveStatus.bind(this);
    this.getOrganizations = this.getOrganizations.bind(this);

    this.state = {
      filters: defaultFilters,
      filterType: 'simple',
      organizations: [],
      firstElem: 0,
      lastElem: 0,
      totalNo: 0,
      totalPages: 0,
      loading: false,
    };
  }

  componentDidMount() {
    const { location: { search, pathname, }, auth, navigate, } = this.props;
    if (auth) {
      const { view, } = auth;
      if (view && !view.includes('ORGANIZATION_LIST')) {
        setTimeout(() => {
          navigate('/patient');
        }, 10);
      }
    }
    if (search !== '') this.getOrganizations(`${pathname}s${search}`);
    this.parseUrlAndSetFilters();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.location.search !== this.props.location.search) {
      const { searchParams, } = this.props;
      if (this.props.location.search !== '') {
        this.getOrganizations(`${this.props.location.pathname}s?${searchParams.toString()}`);
      }
    }
  }

  parseUrlAndSetFilters() {
    const { searchParams, } = this.props;
    const parsedFilters = Object.fromEntries([...searchParams]);
    if (Object.keys(parsedFilters).length > 0) {
      this.setFilters(parsedFilters);
    } else {
      this.resetFilters();
    }
  }

  getOrganizations(url) {
    const { getOrganizations, } = this.props;
    this.setState({ loading: true, });
    getOrganizations(url)
      .then((res => {
        const { totalElements, totalPages, data, } = res.data;
        const { filters, } = this.state;
        this.setState({
          organizations: data,
          totalNo: totalElements,
          firstElem: (filters.page - 1) * filters.size + 1,
          lastElem: filters.page * filters.size > totalElements ? totalElements : filters.page * filters.size,
          totalPages,
          loading: false,
        });
      }))
      .catch((err => {
        console.log('err: ', err);
        this.setState({ loading: false, });
      }));
  }

  setFilters(data) {
    // console.log('setfilters:', data);
    this.setState({ filters: data, }, () => {
      const { setSearchParams, } = this.props;
      const { filters, } = this.state;
      setSearchParams(filters);
    });
  }

  addFilterAndSearch(data, pageSelect, removeKey) {
    Object.keys(data).forEach(k => data[k] === '' && delete data[k]);

    const { filters, } = this.state;
    if (removeKey) delete filters[removeKey];
    const newFilters = {
      ...filters,
      ...data,
      ...(!pageSelect && { page: 1, }),
    };
    this.setFilters(newFilters);
  }

  setSortFilter(e) {
    let newSortDir;
    const newSortBy = e.target.id;
    const { filters: { sortBy, sortDir, }, } = this.state;
    if (e.target.id === sortBy) {
      newSortDir = sortDir === 'asc' ? 'desc' : 'asc';
    } else {
      newSortDir = 'asc';
    }
    this.addFilterAndSearch({ sortBy: newSortBy, sortDir: newSortDir, }, true);
  }

  selectFilterType() {
    const { filterType, } = this.state;
    const newType = filterType === 'simple' ? 'extended' : 'simple';
    this.setState({ filterType: newType, });
  }

  resetFilters() {
    const { searchParams, setSearchParams, } = this.props;
    this.setState({ filters: defaultFilters, }, () => {
      const { filters, } = this.state;
      setSearchParams(filters);
    });
  }

  setActiveStatus(data) {
    const { active, id, } = data;
    const { updateOrganization, } = this.props;
    updateOrganization({ active, id, })
      .then(() => {
        const { location: { search, pathname, }, } = this.props;
        this.getOrganizations(`${pathname}s${search}`);
      })
      .catch(err => {
        console.log('err: ', err);
      });
  }

  render() {
    const { organizations, firstElem, lastElem, totalNo, filters, filterType, totalPages, loading, } = this.state;
    const { location, auth, } = this.props;

    return (
      <>
        {
          loading && (
            <div
              className="loading-container"
            >
              <Spinner />
            </div>
          )
        }
        <div className={`container-fluid position-relative ${loading ? 'no-events loading-data' : ''}`}>
          <Card className="mt-4">
            <CardHeader className="position-relative">
              <div className="d-inline-block">
                <h3><span>Furnizori</span></h3>
                <div className="info">
                  {`Se afiseaza ${firstElem} - ${lastElem} din ${totalNo} elemente.`}
                </div>
              </div>
              {
                auth?.create.includes('ORGANIZATION_CREATE') && (
                  <div className="position-absolute create-organization-btn-wrapper">
                    <NavLink
                      to="/organization/new"
                      state={{ from: location, }}
                    >
                      <Button
                        color="success"
                        className="btn btn-pill p-0"
                      >
                        <FontAwesomeIcon icon={faPlus} />
                      </Button>
                    </NavLink>
                  </div>
                )
}
            </CardHeader>
            <CardBody>
              {
                filterType === 'simple'
                  ? (
                    <OrganizationFiltersSimple
                      addFilterAndSearch={this.addFilterAndSearch}
                      setFilterType={this.selectFilterType}
                      resetFilters={this.resetFilters}
                      filters={filters}
                    />
                  )
                  : (
                    <OrganizationFiltersExtended
                      auth={auth}
                      addFilterAndSearch={this.addFilterAndSearch}
                      setFilterType={this.selectFilterType}
                      resetFilters={this.resetFilters}
                      filters={filters}
                    />
                  )
              }
              <OrganizationTable
                auth={auth}
                setActiveStatus={this.setActiveStatus}
                setSortFilter={this.setSortFilter}
                tableData={organizations}
                filters={filters}
              />
              <Pagination
                totalPages={totalPages}
                totalNo={totalNo}
                filters={filters}
                addFilterAndSearch={this.addFilterAndSearch}
              />
            </CardBody>
          </Card>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  organizations: state.organizations.organizations,
  selectedOrganization: state.organizations.selectedOrganization,
});

const mapDispatchToProps = dispatch => ({
  getOrganizations: url => dispatch(organizationsOperations.getOrganizations(url)),
  selectOrganization: data => dispatch(organizationsOperations.selectOrganization(data)),
  updateOrganization: data => dispatch(organizationsOperations.updateOrganization(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Organization);
