import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Container, Alert } from 'reactstrap';
import { useNavigate, useLocation } from 'react-router-dom';

function NoPermissions({ errorMessage, }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [time, setTime] = useState(5000);

  const countTime = () => {
    setTime(time - 1000);
  };

  const resetCounter = () => { setTime(5000); };

  const handleRedirect = () => {
    const { state, } = location;
    // navigate('/patient');
    if (state) {
      navigate(state.from.pathname);
    } else {
      navigate('/patient');
    }
    if (errorMessage) {
      window.location.reload();
    }
  };

  useEffect(() => {
    resetCounter();
  }, [location.pathname]);

  useEffect(() => {
    if (time === 0) handleRedirect();

    setTimeout(() => {
      countTime();
    }, 1000);
  }, [time]);

  useEffect(() => {
    setTimeout(() => {
      countTime();
    }, 1000);
  }, []);

  return (
    <Container>
      <Row className="justify-content-center pt-5">
        <Col md={6}>
          <Alert color="danger" className="m-0 text-center">
            { errorMessage ? (
              <span style={{ fontSize: '16px', }}>
                <strong>EROARE</strong>
                <br />
                Această operațiune nu poate fi executată!
              </span>
            ) : (
              <span>Nu aveți permisiunea să accesați această pagină.</span>
            )}
            <br />
            <span>
              Veți fi redirecționat în
              {' '}
              <strong>{time / 1000}</strong>
              {' '}
              secunde.
            </span>
          </Alert>
        </Col>
      </Row>
    </Container>
  );
}

export default NoPermissions;
