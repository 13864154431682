import './sugarify';

const Helpers = {
  serverSideRender() {
    return typeof document === 'undefined';
  },
  debounce(action, debounceTimeOut = 500) {
    let timeout = null;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        action(...args);
      }, debounceTimeOut);
    };
  },
  throttle(action, throttleTimeOut = 500) {
    let throttled = false;
    return (...args) => {
      if (!throttled) {
        throttled = true;
        action(...args);
        setTimeout(() => {
          throttled = false;
        }, throttleTimeOut);
      }
    };
  },
  upperToCamel(string) {
    return string.charAt(0).toLowerCase() + string.substring(1);
  },

  camelToUpper(string) {
    return string.charAt(0).toUpperCase() + string.substring(1);
  },

  getParams(url) {
    const params = {};
    const parser = document.createElement('a');
    parser.href = url;
    const query = parser.search.substring(1);
    const vars = query.split('&');
    for (let i = 0; i < vars.length; i++) {
      const pair = vars[i].split('=');
      params[pair[0]] = decodeURIComponent(pair[1]);
    }
    return params;
  },
};

export default Helpers;
