/* eslint-disable prefer-rest-params */
import fetchHelper from '../../support/helpers/fetchHelper';

const apiService = () => next => action => {
  const result = next(action);
  if (!action.async) {
    return result;
  }
  const { path, method = 'GET', body, forwardedIp, responseType, } = action.payload;

  if (!path) {
    throw new Error(`'path' not specified for async action ${action.type}`);
  }
  let url = `${path}`;
  const headers = action.extraHeaders;
  if (action.external) {
    url = path;
  }

  if (forwardedIp) {
    headers['x-real-ip'] = forwardedIp;
  }

  return fetchHelper(url, method, headers, body, responseType).then(
    res => handleResponse(res, action, next),
    err => handleErrors(err, action, next)
  );
};

export default apiService;

function handleErrors(err, action, next) {
// eslint-disable-next-line prefer-rest-params
  if (typeof arguments[0].response !== 'undefined' && err.response.data) {
    // eslint-disable-next-line no-undef
    if (err.response.data.status_code >= 500) {
      next(new Error('there was a problem', err));
    }
    // if ( err.response.data.status_code == 401 || err.response.data.status_code == 403 ) {
    //     window.location.href = "/login";
    // }
  } else {
    // eslint-disable-next-line no-undef
    console.error('failed api call');
  }
  next({
    type: `${action.type}_FAILED`,
    async: true,
    generalFetching: action.generalFetching,
    payload: err,
  });

  return Promise.reject({ err, data: err.response.data, type: action.type, payload: action.payload, });
}

function handleResponse(res, action, next) {
  const redirectUrl = res.redirectUrl || (res.contentNode && res.contentNode.redirectUrl);
  if (redirectUrl) {
    next({
      type: `${action.type}_REDIRECTED`,
      payload: redirectUrl,
    });
    return res;
  }

  next({
    type: `${action.type}_COMPLETED`,
    async: true,
    generalFetching: action.generalFetching,
    additionalData: action.additionalData,
    payload: res,
  });

  return res;
}
