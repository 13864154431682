const GET_USERS = 'GET_USERS';
const GET_USERS_COMPLETED = 'GET_USERS_COMPLETED';
const GET_USERS_FAILED = 'GET_USERS_FAILED';
const GET_SINGLE_USER = 'GET_SINGLE_USER';
const GET_SINGLE_USER_COMPLETED = 'GET_SINGLE_USER_COMPLETED';
const GET_SINGLE_USER_FAILED = 'GET_SINGLE_USER_FAILED';
const CREATE_USER = 'CREATE_USER';
const CREATE_USER_COMPLETED = 'CREATE_USER_COMPLETED';
const CREATE_USER_FAILED = 'CREATE_USER_FAILED';
const UPDATE_USER = 'UPDATE_USER';
const UPDATE_USER_COMPLETED = 'UPDATE_USER_COMPLETED';
const UPDATE_USER_FAILED = 'UPDATE_USER_FAILED';
const GET_PROFILES = 'GET_PROFILES';
const GET_PROFILES_COMPLETED = 'GET_PROFILES_COMPLETED';
const GET_PROFILES_FAILED = 'GET_PROFILES_FAILED';

export default {
  GET_USERS,
  GET_USERS_COMPLETED,
  GET_USERS_FAILED,
  GET_SINGLE_USER,
  GET_SINGLE_USER_COMPLETED,
  GET_SINGLE_USER_FAILED,
  CREATE_USER,
  CREATE_USER_COMPLETED,
  CREATE_USER_FAILED,
  UPDATE_USER,
  UPDATE_USER_COMPLETED,
  UPDATE_USER_FAILED,
  GET_PROFILES,
  GET_PROFILES_COMPLETED,
  GET_PROFILES_FAILED,
};
