import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faCheckCircle,
  faEye,
  faPencilAlt,
  faTimes, faTimesCircle
} from '@fortawesome/free-solid-svg-icons';
import { Link, NavLink, useLocation } from 'react-router-dom';
import moment from 'moment';
import { Button } from 'reactstrap';
import PatientCasefileTable from './PatientCasefileTable';

const calcStatus = (active, isFinalized) => {
  if (active === true && isFinalized === true) { return 'Finalizata'; }
  if (active === true && isFinalized === false) { return 'In lucru'; }
  if (active === false) return 'Inactiva';
};

function CasefilesForPatientTableRow({ casefile, data, openModal, canEdit, canActivate, }) {
  const { id, createTs, updateTs, isFinalized, isOirValidated, active, sections = {}, } = data;

  const location = useLocation();

  return (
    <tr>
      <td>
        <PatientCasefileTable
          casefile={casefile}
        />
      </td>
      <td>{moment(createTs).format('DD-MM-YYYY')}</td>
      <td>{moment(updateTs).format('DD-MM-YYYY')}</td>
      <td className="text-center" style={{ minWidth: 80, }}>
        { isOirValidated
          ? <FontAwesomeIcon fontSize={24} icon={faCheck} />
          : <FontAwesomeIcon fontSize={24} icon={faTimes} />}
      </td>
      <td className="text-center" style={{ minWidth: 80, }}>
        { active
          ? <FontAwesomeIcon fontSize={24} icon={faCheck} />
          : <FontAwesomeIcon fontSize={24} icon={faTimes} />}
      </td>
      <td style={{ minWidth: 80, }}>
        {calcStatus(active, isFinalized)}
      </td>
      <td className="text-right" style={{ minWidth: 150, }}>
        <div className="actions">
          {
            active && canEdit && Object.values(sections).includes('EDIT') && (
            <NavLink
              to={`/file/${id}/edit`}
              state={{ from: location, }}
              className="btn btn-primary btn-sm btn-pill mr-1"
            >
              <FontAwesomeIcon icon={faPencilAlt} />
            </NavLink>
            )
          }
          <NavLink
            to={`/file/${id}/view`}
            state={{ from: location, }}
            className="btn btn-info btn-sm btn-pill mr-1"
          >
            <FontAwesomeIcon icon={faEye} />
          </NavLink>
          {
            canActivate && (
              <>
                { active ? (
                  <div
                    onClick={() => { openModal(id); }}
                    style={{ width: '4rem', borderRadius: '20rem', }}
                    className="btn btn-outline-success btn-sm text-right p-0"
                  >
                    <div className="actions">
                      <Button
                        style={{ boxShadow: 'none', }}
                        color="success"
                        size="sm"
                        className=" btn-pill"
                      >
                        <FontAwesomeIcon icon={faCheckCircle} />
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div
                    style={{ width: '4rem', borderRadius: '20rem', }}
                    className="btn btn-outline-danger text-left p-0 row-btn-wrapper"
                  >
                    <Button
                      color="danger"
                      className="btn-pill p-0"
                    >
                      <FontAwesomeIcon icon={faTimesCircle} />
                    </Button>
                  </div>
                ) }
              </>
            )
          }

        </div>
      </td>
    </tr>
  );
}

export default CasefilesForPatientTableRow;
