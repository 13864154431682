import types from './types';

const login = data => ({
  type: types.LOGIN,
  async: true,
  generalFetching: true,
  payload: {
    path: '/auth/login',
    method: 'POST',
    body: {
      ...data,
      deviceInfo: { deviceType: 'DESKTOP', },
    },
  },
});

const logout = () => ({
  type: types.LOGOUT,
  async: true,
  generalFetching: true,
  payload: {
    path: '/settings/logout',
    method: 'POST',
  },
});

const getUserSettings = () => ({
  type: types.GET_USER_SETTINGS,
  async: true,
  generalFetching: true,
  payload: {
    path: '/settings',
    method: 'GET',
  },
});

const register = (data, lang) => ({
  type: types.REGISTER,
  async: true,
  generalFetching: true,
  payload: {
    path: '/auth/register',
    method: 'POST',
    body: data,
  },
  extraHeaders: { 'Accept-Language': lang, },
});

const requestPasswordReset = data => ({
  type: types.REQUEST_PASSWORD_RESET,
  async: true,
  generalFetching: true,
  payload: {
    path: '/auth/requestpasswordreset',
    method: 'POST',
    body: data,
  },
});

const activateAccount = data => ({
  type: types.ACTIVATE_ACCOUNT,
  async: true,
  generalFetching: true,
  payload: {
    path: `/auth/activate/${data}`,
    method: 'GET',
  },
});

const verifyCompleteAccount = token => ({
  type: types.VERIFY_COMPLETE_ACCOUNT,
  async: true,
  generalFetching: true,
  payload: {
    path: `/auth/complete/${token}`,
    method: 'GET',
  },
});

const completeAccount = (data, token) => ({
  type: types.COMPLETE_ACCOUNT,
  async: true,
  generalFetching: true,
  payload: {
    path: `/auth/complete/${token}`,
    method: 'POST',
    body: data,
  },
});

const checkResetPasswordToken = data => ({
  type: types.CHECK_RESET_PASSWORD_TOKEN,
  async: true,
  generalFetching: true,
  payload: {
    path: `/auth/passwordreset/${data}`,
    method: 'GET',
  },
});

const setNewPassword = (data, token) => ({
  type: types.SET_NEW_PASSWORD,
  async: true,
  generalFetching: true,
  payload: {
    path: `/auth/passwordreset/${token}`,
    method: 'POST',
    body: data,
  },
});

const changePassword = data => ({
  type: types.CHANGE_PASSWORD,
  async: true,
  generalFetching: true,
  payload: {
    path: '/settings/password',
    method: 'POST',
    body: data,
  },
});

const changeUserProfile = data => ({
  type: types.CHANGE_USER_PROFILE,
  async: true,
  generalFetching: true,
  payload: {
    path: '/settings/basic',
    method: 'POST',
    body: data,
  },
});

const registerTech = (data, lang) => ({
  type: types.REGISTER_TECH,
  async: true,
  generalFetching: true,
  payload: {
    path: '/tech/auth/register',
    method: 'POST',
    body: data,
  },
  extraHeaders: { 'Accept-Language': lang, },
});

const refreshUserToken = data => ({
  type: types.REFRESH_USER_TOKEN,
  async: true,
  payload: {
    path: '/auth/refresh',
    method: 'POST',
    body: data,
  },
});

const switchCompany = uuid => ({
  type: types.SWITCH_COMPANY,
  async: true,
  generalFetching: true,
  payload: {
    path: `/organizations/switch/${uuid}`,
    method: 'GET',
  },
});

export default {
  login,
  logout,
  register,
  registerTech,
  requestPasswordReset,
  activateAccount,
  verifyCompleteAccount,
  completeAccount,
  checkResetPasswordToken,
  setNewPassword,
  changePassword,
  changeUserProfile,
  getUserSettings,
  refreshUserToken,
  switchCompany,
};
