import React, { useEffect, useState } from 'react';
import { faChevronUp, faRedoAlt, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import Select from 'react-select';
import { regionOptions, countyOptions, countyOptionsAll } from '../organization.helper';

function OrganizationFiltersExtended({ auth, type, setFilterType, addFilterAndSearch, resetFilters, filters, }) {
  const [region, setRegion] = useState('');
  const [county, setCounty] = useState('');

  useEffect(() => {
    if (auth.region) {
      const userRegion = { value: auth.region.id, label: auth.region.value, };
      setRegion(userRegion);
    } else {
      const { region: regionFilter, } = filters;
      if (regionFilter && regionFilter !== '') {
        const findRegion = regionOptions.find(elem => elem.value === regionFilter);
        setRegion(findRegion);
      }
    }
  }, [filters.region]);

  useEffect(() => {
    const { county: countyFilter, } = filters;
    if (countyFilter && countyFilter !== '') {
      const findCounty = countyOptions.find(elem => elem.value === countyFilter);
      setCounty(findCounty);
    }
  }, [filters.county]);

  const handleFilterType = () => {
    setFilterType(type);
  };

  const handleSearch = () => {
    addFilterAndSearch({ region, });
  };

  const handleRegionChange = selected => {
    if (selected === null) {
      setRegion('');
      addFilterAndSearch({ county: filters?.county || '', }, false, 'region');
    } else {
      setRegion(selected);
      addFilterAndSearch({ county: filters?.county || '', region: selected.value, });
    }
  };

  const handleCountyChange = selected => {
    if (selected === null) {
      setCounty('');
      addFilterAndSearch({ region: filters?.region || '', }, false, 'county');
    } else {
      setCounty(selected);
      addFilterAndSearch({ region: filters?.region || '', county: selected.value, });
    }
  };

  const handleResetFilters = () => {
    if (auth.region) {
      setCounty('');
      resetFilters();
    } else {
      setRegion('');
      setCounty('');
      resetFilters();
    }
  };

  return (
    <div className="searchFormFields mt-4">
      <Row>
        <Col className="text-right">
          <Button
            onClick={handleResetFilters}
            className="btn btn-light btn-sm btn-pill"
          >
            <FontAwesomeIcon icon={faRedoAlt} />
          </Button>
          <Button
            onClick={handleSearch}
            className="btn btn-light btn-sm btn-pill"
          >
            <FontAwesomeIcon icon={faSearch} />
          </Button>
          <Button
            onClick={handleFilterType}
            className="btn btn-light btn-sm btn-pill"
          >
            <FontAwesomeIcon icon={faChevronUp} />
          </Button>
        </Col>
      </Row>

      <Row>
        <Col>
          <FormGroup>
            <Label>
              <small className="ml-sm-2 font-weight-bold">Regiune</small>
            </Label>
            {
              auth.region ? (
                <Input
                  value={region?.label || ''}
                  type="text"
                  className="form-control"
                  id="region"
                  placeholder="Regiune"
                  disabled
                />
              ) : (
                <Select
                  isClearable
                  value={region}
                  onChange={handleRegionChange}
                  className="basic-single"
                  classNamePrefix="select"
                  placeholder="Regiune"
                  name="region"
                  options={regionOptions}
                />
              )
            }

          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label>
              <small className="ml-sm-2 font-weight-bold">Judet</small>
            </Label>
            <Select
              isClearable
              value={county}
              onChange={handleCountyChange}
              className="basic-single"
              classNamePrefix="select"
              placeholder="Judet"
              name="county"
              options={auth.region ? countyOptionsAll[region.value]
                : region !== '' ? countyOptionsAll[region.value] : countyOptions}
            />
          </FormGroup>
        </Col>
      </Row>
    </div>
  );
}

export default OrganizationFiltersExtended;
