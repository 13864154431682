import React, { useEffect, useState } from 'react';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';

const checkboxInputs = [
  'inflamatoriiSec33FS1', // inflamatorii
  'radioChimioSec33FS1', // post - radioterapie / chimioterapie
  'purtatoareSteriletSec33FS1', // la purtatoare sterile
  'celEndomFemSec33FS1', // celule endometriale la femei > 40 ani
  'ceGlandHisteroSec33FS1', // celule glandulare post-histerectomie
  'atrofieSec33FS1', // atrofie
  'alteleModifNonSec33FS1' // altele
];

function ModifNonNeoplaziceCheckboxes({ data, editable, setCheckboxValue, handleChange, readOnly, }) {
  const [inputs, setInputs] = useState({});

  useEffect(() => {
    const dataProps = {
      descriereAlteleModifNonSec33FS1: data?.descriereAlteleModifNonSec33FS1 || { value: '', },
      inflamatoriiSec33FS1: { value: data?.inflamatoriiSec33FS1?.value.toString() || 'false', },
      radioChimioSec33FS1: { value: data?.radioChimioSec33FS1?.value.toString() || 'false', },
      purtatoareSteriletSec33FS1: { value: data?.purtatoareSteriletSec33FS1?.value.toString() || 'false', },
      celEndomFemSec33FS1: { value: data?.celEndomFemSec33FS1?.value.toString() || 'false', },
      ceGlandHisteroSec33FS1: { value: data?.ceGlandHisteroSec33FS1?.value.toString() || 'false', },
      atrofieSec33FS1: { value: data?.atrofieSec33FS1?.value.toString() || 'false', },
      alteleModifNonSec33FS1: { value: data?.alteleModifNonSec33FS1?.value.toString() || 'false', },
    };

    setInputs(dataProps);
    if (Object.keys(inputs).length === 0) return;
    checkboxInputs.forEach(elem => {
      inputs[elem].value = inputs[elem].value.toString();
    });
  }, [data]);

  const {
    inflamatoriiSec33FS1,
    radioChimioSec33FS1,
    purtatoareSteriletSec33FS1,
    celEndomFemSec33FS1,
    ceGlandHisteroSec33FS1,
    atrofieSec33FS1,
    alteleModifNonSec33FS1,
    descriereAlteleModifNonSec33FS1,
  } = inputs;

  const handleCheck = e => {
    setCheckboxValue(e);
  };

  //   'atrofieSec33FS1', // atrofie
  //   'alteleModifNonSec33FS1' // altele

  return (
    <Row className="mt-3">
      <Col md={6}>
        <FormGroup check>
          {
            editable ? (
              <Input
                type="checkbox"
                checked={inflamatoriiSec33FS1?.value === 'true'}
                value={inflamatoriiSec33FS1?.value === 'true'}
                name="inflamatoriiSec33FS1"
                onChange={handleCheck}
              />
            ) : (
              <Input
                type="checkbox"
                value={inflamatoriiSec33FS1?.value || false}
                checked={inflamatoriiSec33FS1?.value === 'true'}
                name="inflamatoriiSec33FS1"
                onChange={() => {}}
              />
            )
          }
          <Label>Inflamatorii</Label>
        </FormGroup>
      </Col>
      <Col md={6}>
        <FormGroup check>
          {
            editable ? (
              <Input
                type="checkbox"
                checked={radioChimioSec33FS1?.value === 'true'}
                value={radioChimioSec33FS1?.value === 'true'}
                name="radioChimioSec33FS1"
                onChange={handleCheck}
              />
            ) : (
              <Input
                type="checkbox"
                value={radioChimioSec33FS1?.value || false}
                checked={radioChimioSec33FS1?.value === 'true'}
                name="radioChimioSec33FS1"
                onChange={() => {}}
              />
            )
          }
          <Label>post - radioterapie / chimioterapie</Label>
        </FormGroup>
      </Col>
      <Col md={6}>
        <FormGroup check>
          {
            editable ? (
              <Input
                type="checkbox"
                checked={purtatoareSteriletSec33FS1?.value === 'true'}
                value={purtatoareSteriletSec33FS1?.value === 'true'}
                name="purtatoareSteriletSec33FS1"
                onChange={handleCheck}
              />
            ) : (
              <Input
                type="checkbox"
                value={purtatoareSteriletSec33FS1?.value || false}
                checked={purtatoareSteriletSec33FS1?.value === 'true'}
                name="purtatoareSteriletSec33FS1"
                onChange={() => {}}
              />
            )
          }
          <Label>la purtatoare sterilet</Label>
        </FormGroup>
      </Col>
      <Col md={6}>
        <FormGroup check>
          {
            editable ? (
              <Input
                type="checkbox"
                checked={celEndomFemSec33FS1?.value === 'true'}
                value={celEndomFemSec33FS1?.value === 'true'}
                name="celEndomFemSec33FS1"
                onChange={handleCheck}
              />
            ) : (
              <Input
                type="checkbox"
                value={celEndomFemSec33FS1?.value || false}
                checked={celEndomFemSec33FS1?.value === 'true'}
                name="celEndomFemSec33FS1"
                onChange={() => {}}
              />
            )
          }
          <Label>celule endometriale la femei > 40 ani</Label>
        </FormGroup>
      </Col>
      <Col md={6}>
        <FormGroup check>
          {
            editable ? (
              <Input
                type="checkbox"
                checked={ceGlandHisteroSec33FS1?.value === 'true'}
                value={ceGlandHisteroSec33FS1?.value === 'true'}
                name="ceGlandHisteroSec33FS1"
                onChange={handleCheck}
              />
            ) : (
              <Input
                type="checkbox"
                value={ceGlandHisteroSec33FS1?.value || false}
                checked={ceGlandHisteroSec33FS1?.value === 'true'}
                name="ceGlandHisteroSec33FS1"
                onChange={() => {}}
              />
            )
          }
          <Label>celule glandulare post-histerectomie</Label>
        </FormGroup>
      </Col>
      <Col md={6}>
        <FormGroup check>
          {
            editable ? (
              <Input
                type="checkbox"
                checked={atrofieSec33FS1?.value === 'true'}
                value={atrofieSec33FS1?.value === 'true'}
                name="atrofieSec33FS1"
                onChange={handleCheck}
              />
            ) : (
              <Input
                type="checkbox"
                value={atrofieSec33FS1?.value || false}
                checked={atrofieSec33FS1?.value === 'true'}
                name="atrofieSec33FS1"
                onChange={() => {}}
              />
            )
          }
          <Label>atrofie</Label>
        </FormGroup>
      </Col>
      <Col md={6}>
        <FormGroup check>
          {
            editable ? (
              <Input
                type="checkbox"
                checked={alteleModifNonSec33FS1?.value === 'true'}
                value={alteleModifNonSec33FS1?.value === 'true'}
                name="alteleModifNonSec33FS1"
                onChange={handleCheck}
              />
            ) : (
              <Input
                type="checkbox"
                value={alteleModifNonSec33FS1?.value || false}
                checked={alteleModifNonSec33FS1?.value === 'true'}
                name="alteleModifNonSec33FS1"
                onChange={() => {}}
              />
            )
          }
          <Label>Altele</Label>
        </FormGroup>
      </Col>
      {
        alteleModifNonSec33FS1?.value === 'true' && (
          <Col md={12}>
            <FormGroup>
              <Label>Descriere alte modificări celulare non-neoplazice</Label>
              <Input
                disabled={readOnly}
                type="textarea"
                rows={3}
                name="descriereAlteleModifNonSec33FS1"
                onChange={readOnly ? () => {} : handleChange}
                value={descriereAlteleModifNonSec33FS1?.value || ''}
              />
            </FormGroup>
          </Col>
        )
      }
    </Row>
  );
}

export default ModifNonNeoplaziceCheckboxes;
