import { connect } from 'react-redux';
import {
  useLocation,
  Navigate
} from 'react-router-dom';
import Cookies from 'universal-cookie';
import { useState, useEffect } from 'react';
import { Spinner } from 'reactstrap';
import { authOperations } from '../redux/ducks/auth';

function RequireAuth({ children, auth, checkToken, }) {
  const location = useLocation();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    checkToken();
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  if (auth === null || Object.keys(auth).length === 0) {
    return <Navigate to="/check" state={{ from: location, }} replace />;
  }

  if (loading) {
    return (
      <div className="text-center mt-5 pt-5">
        <Spinner />
      </div>
    );
  }

  return children;
}

const mapStateToProps = state => ({ auth: state.auth, });

const mapDispatchToProps = dispatch => ({
  checkToken: () => dispatch(authOperations.checkToken()),
  refreshUserToken: data => dispatch(authOperations.refreshUserToken(data)),

});

export default connect(mapStateToProps, mapDispatchToProps)(RequireAuth);
