import React from 'react';
import PropTypes from 'prop-types';

import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem, NavLink
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBuilding,
  faClinicMedical,
  faClipboard,
  faFile,
  faFilePdf, faUser,
  faUsers,
  faUserTag
} from '@fortawesome/free-solid-svg-icons';

const administrationMenuData = [
  { path: '/nomen', icon: faFile, title: 'Nomen', },
  { path: '/template', icon: faFilePdf, title: 'Șablon', },
  { path: '/role', icon: faUserTag, title: 'Permisiune', },
  { path: '/group', icon: faUsers, title: 'Rol', },
  { path: '/office', icon: faClinicMedical, title: 'Birou', },
  // { path: '/organization', icon: faBuilding, title: 'Furnizor', },
  { path: '/form', icon: faClipboard, title: 'Formulare', }
  // { path: '/user', icon: faUser, title: 'Utilizator', }
];

function NavbarMenu({ auth, location, menuTitle, menuIcon, }) {
  const menuData = auth?.organizationName === 'SUPER-ORG' ? [...administrationMenuData] : [];
  if (auth?.view.includes('ORGANIZATION_LIST')) {
    menuData.push({ path: '/organization', icon: faBuilding, title: 'Furnizor', });
  }
  if (auth?.view.includes('USER_LIST')) {
    menuData.push({ path: '/user', icon: faUser, title: 'Utilizator', });
  }
  const menuDom = [];
  menuData.forEach(elem => menuDom.push(
    <DropdownItem key={elem.path} className={`dropdown-item-iocn ${location.pathname === elem.path ? 'active' : ''}`}>
      <NavLink className="" to={elem.path} state={{ from: location, }} tag={Link}>
        <FontAwesomeIcon className="pr-1" icon={elem.icon} />
        <span className="pl-1">{elem.title}</span>
      </NavLink>
    </DropdownItem>
  ));

  return (
    <UncontrolledDropdown>
      <DropdownToggle nav caret className="pl-0 pr-3">
        <FontAwesomeIcon className="pr-1" icon={menuIcon} />
        {menuTitle}
      </DropdownToggle>
      <DropdownMenu right>
        { menuDom }
      </DropdownMenu>
    </UncontrolledDropdown>

  );
}

export default NavbarMenu;
