import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faRedoAlt, faSearch } from '@fortawesome/free-solid-svg-icons';
import { Input } from 'reactstrap';

function DoctorFilterSimple({ type, setFilterType, addFilterAndSearch, resetFilters, filters, }) {
  const [lastName, setLastName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [cnp, setCnp] = useState('');

  useEffect(() => {
    const { firstName: propsFirstName, } = filters;
    if (typeof propsFirstName !== 'undefined') setFirstName(propsFirstName);
  }, [filters.firstName]);

  useEffect(() => {
    const { lastName: propsLastName, } = filters;
    if (typeof propsLastName !== 'undefined') setLastName(propsLastName);
  }, [filters.lastName]);

  useEffect(() => {
    const { cnp: propsCnp, } = filters;
    if (typeof propsCnp !== 'undefined') setCnp(propsCnp);
  }, [filters.cnp]);

  const handleFilterType = () => {
    setFilterType(type);
  };

  const handleSearch = () => {
    addFilterAndSearch({ lastName, firstName, cnp, });
  };

  const handleLastNameChange = e => {
    setLastName(e.target.value);
  };

  const handleCnpChange = e => {
    setCnp(e.target.value);
  };

  const handleFirstNameChange = e => {
    setFirstName(e.target.value);
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const handleResetFilters = () => {
    setLastName('');
    setFirstName('');
    setCnp('');
    resetFilters();
  };

  return (
    <div className="row mt-4">
      <div className="col-sm-12">
        <div className="input-group flex-nowrap">
          <div className="input-group-prepend">
            <span id="addon-wrapping" className="input-group-text">Nume:</span>
            <Input
              value={lastName}
              onKeyDown={handleKeyDown}
              onChange={handleLastNameChange}
              type="text"
              className="form-control principalSearchField"
              placeholder="Căutare"
            />
          </div>
          <div className="input-group-prepend">
            <span id="addon-wrapping" className="input-group-text">Prenume:</span>
            <Input
              value={firstName}
              onKeyDown={handleKeyDown}
              onChange={handleFirstNameChange}
              type="text"
              className="form-control principalSearchField"
              placeholder="Căutare"
            />
          </div>
          <div className="input-group-prepend">
            <span id="addon-wrapping" className="input-group-text">CNP:</span>
            <Input
              value={cnp}
              onKeyDown={handleKeyDown}
              onChange={handleCnpChange}
              type="text"
              className="form-control principalSearchField"
              placeholder="Căutare"
            />
          </div>
          <div className="input-group-append">
            <div
              onClick={handleResetFilters}
              className="input-group-text bg-transparent border-left-0 border cursor-pointer"
            >
              <FontAwesomeIcon icon={faRedoAlt} />
            </div>
            <div
              onClick={handleSearch}
              className="input-group-text bg-transparent border-left-0 border cursor-pointer"
            >
              <FontAwesomeIcon icon={faSearch} />
            </div>
            <div
              onClick={handleFilterType}
              id="search-expanded"
              className="input-group-text bg-transparent border-left-0 border cursor-pointer"
            >
              <FontAwesomeIcon icon={faChevronDown} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DoctorFilterSimple;
