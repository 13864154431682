import types from './types';

const getDoctors = url => ({
  type: types.GET_DOCTORS,
  async: true,
  generalFetching: true,
  payload: {
    path: url,
    method: 'GET',
  },
});

const getDoctorsSimple = organizationId => ({
  type: types.GET_DOCTORS_SIMPLE,
  async: true,
  generalFetching: true,
  payload: {
    path: `/doctors_lite/${organizationId}`,
    // path: '/doctors_lite/0F22D7F6-FA2B-EC11-8C2A-000C2901B5F1',
    method: 'GET',
  },
});

const getSingleDoctor = id => ({
  type: types.GET_SINGLE_DOCTOR,
  async: true,
  generalFetching: true,
  payload: {
    path: `/doctors/${id}`,
    method: 'GET',
  },
});

const createDoctor = data => ({
  type: types.CREATE_DOCTOR,
  async: true,
  generalFetching: true,
  payload: {
    path: '/doctors',
    method: 'POST',
    body: data,
  },
});

const updateDoctor = data => ({
  type: types.UPDATE_DOCTOR,
  async: true,
  generalFetching: true,
  payload: {
    path: `/doctors/${data.id}`,
    method: 'PUT',
    body: data,
  },
});

export default {
  getDoctors,
  createDoctor,
  getSingleDoctor,
  updateDoctor,
  getDoctorsSimple,
};
