export const mockOrganizations = [
  {
    id: '9A99443E-F36B-1410-8B05-00C52C496AEF',
    code: 'IOCN_nord_vest',
    label: 'Nord-Vest',
    description: 'Nord-Vest',
    active: true,
    representative: null,
    servicePrice: '{"MOBILIZATOR":45,"RECOLTOR":32}',
    organizationType:
      {
        id: 'CC47433E-F36B-1410-8D33-00DFB91F5CDC',
        code: 'FURNIZOR_REGIONAL',
        groupCode: 'ORG_TYPE',
        label: 'FURNIZOR REGIONAL',
        description: 'FURNIZOR REGIONAL',
        active: true,
        parentId: null,
      },
    address:
      {
        id: '8299443E-F36B-1410-8B05-00C52C496AEF',
        street: null,
        zipCode: null,
        country: null,
        buildingNo: null,
        floor: null,
        apartmentNo: null,
        streetNo: null,
        location: null,
        city: {
          id: '11F9663E-F36B-1410-8D37-00DFB91F5CDC',
          code: '54984',
          groupCode: 'CITY',
          label: 'CLUJ-NAPOCA',
          description: 'CLUJ-NAPOCA',
          active: true,
          parentId: 'D904443E-F36B-1410-8D2C-00DFB91F5CDC',
        },
        county: {
          id: 'D904443E-F36B-1410-8D2C-00DFB91F5CDC',
          code: 'CJ',
          groupCode: 'COUNTY',
          label: 'Cluj',
          description: 'Cluj',
          active: true,
          parentId: '9647433E-F36B-1410-8D33-00DFB91F5CDC',
        },
      },
    services: [
      {
        id: 'AB47433E-F36B-1410-8D33-00DFB91F5CDC',
        code: 'MOBILIZATOR',
        groupCode: 'SPECIALIZATION_TYPE',
        label: 'Mobilizator',
        description: 'Mobilizator',
        active: true,
        parentId: null,
      }, {
        id: 'B147433E-F36B-1410-8D33-00DFB91F5CDC',
        code: 'RECOLTOR',
        groupCode: 'SPECIALIZATION_TYPE',
        label: 'Recoltor',
        description: 'Recoltor',
        active: true,
        parentId: null,
      }, {
        id: 'B747433E-F36B-1410-8D33-00DFB91F5CDC',
        code: 'LABORATOR_HPV',
        groupCode: 'SPECIALIZATION_TYPE',
        label: 'Laborator HPV',
        description: 'Laborator HPV',
        active: true,
        parentId: null,
      }, {
        id: '7B51433E-F36B-1410-8D33-00DFB91F5CDC',
        code: 'FOLLOWUP',
        groupCode: 'SPECIALIZATION_TYPE',
        label: 'Follow-up',
        description: 'Follow-up',
        active: true,
        parentId: null,
      }, {
        id: '6A5D433E-F36B-1410-8D33-00DFB91F5CDC',
        code: 'LABORATOR_PAP',
        groupCode: 'SPECIALIZATION_TYPE',
        label: 'Laborator PAP',
        description: 'Laborator PAP',
        active: true,
        parentId: null,
      }],
  },
  {
    id: '9F99443E-F36B-1410-8B05-00C52C496AEF',
    code: 'IOCN_nord_est',
    label: 'Nord-Est',
    description: 'Nord-Est',
    active: false,
    representative: null,
    servicePrice: '{}',
    organizationType: {
      id: 'CC47433E-F36B-1410-8D33-00DFB91F5CDC',
      code: 'FURNIZOR_REGIONAL',
      groupCode: 'ORG_TYPE',
      label: 'FURNIZOR REGIONAL',
      description: 'FURNIZOR REGIONAL',
      active: true,
      parentId: null,
    },
    address: {
      id: '8799443E-F36B-1410-8B05-00C52C496AEF',
      street: null,
      zipCode: null,
      country: null,
      buildingNo: null,
      floor: null,
      apartmentNo: null,
      streetNo: null,
      location: null,
      city: {
        id: '435E673E-F36B-1410-8D37-00DFB91F5CDC',
        code: '146272',
        groupCode: 'CITY',
        label: 'SUCEAVA',
        description: 'SUCEAVA',
        active: true,
        parentId: '4705443E-F36B-1410-8D2C-00DFB91F5CDC',
      },
      county: {
        id: '4705443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'SV',
        groupCode: 'COUNTY',
        label: 'Suceava',
        description: 'Suceava',
        active: true,
        parentId: '9B47433E-F36B-1410-8D33-00DFB91F5CDC',
      },
    },
    services: [
      {
        id: 'AB47433E-F36B-1410-8D33-00DFB91F5CDC',
        code: 'MOBILIZATOR',
        groupCode: 'SPECIALIZATION_TYPE',
        label: 'Mobilizator',
        description: 'Mobilizator',
        active: true,
        parentId: null,
      },
      {
        id: 'B147433E-F36B-1410-8D33-00DFB91F5CDC',
        code: 'RECOLTOR',
        groupCode: 'SPECIALIZATION_TYPE',
        label: 'Recoltor',
        description: 'Recoltor',
        active: true,
        parentId: null,
      },
      {
        id: 'B747433E-F36B-1410-8D33-00DFB91F5CDC',
        code: 'LABORATOR_HPV',
        groupCode: 'SPECIALIZATION_TYPE',
        label: 'Laborator HPV',
        description: 'Laborator HPV',
        active: true,
        parentId: null,
      },
      {
        id: '7B51433E-F36B-1410-8D33-00DFB91F5CDC',
        code: 'FOLLOWUP',
        groupCode: 'SPECIALIZATION_TYPE',
        label: 'Follow-up',
        description: 'Follow-up',
        active: true,
        parentId: null,
      },
      {
        id: '6A5D433E-F36B-1410-8D33-00DFB91F5CDC',
        code: 'LABORATOR_PAP',
        groupCode: 'SPECIALIZATION_TYPE',
        label: 'Laborator PAP',
        description: 'Laborator PAP',
        active: true,
        parentId: null,
      }],
  },
  {
    id: 'A499443E-F36B-1410-8B05-00C52C496AEF',
    code: 'IOCN_centru',
    label: 'Centru',
    description: 'Centru',
    active: true,
    representative: null,
    servicePrice: '{}',
    organizationType: {
      id: 'CC47433E-F36B-1410-8D33-00DFB91F5CDC',
      code: 'FURNIZOR_REGIONAL',
      groupCode: 'ORG_TYPE',
      label: 'FURNIZOR REGIONAL',
      description: 'FURNIZOR REGIONAL',
      active: true,
      parentId: null,
    },
    address: {
      id: '8D99443E-F36B-1410-8B05-00C52C496AEF',
      street: null,
      zipCode: null,
      country: null,
      buildingNo: null,
      floor: null,
      apartmentNo: null,
      streetNo: null,
      location: null,
      city: {
        id: '855D673E-F36B-1410-8D37-00DFB91F5CDC',
        code: '143469',
        groupCode: 'CITY',
        label: 'SIBIU',
        description: 'SIBIU',
        active: true,
        parentId: '4205443E-F36B-1410-8D2C-00DFB91F5CDC',
      },
      county: {
        id: '4205443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'SB',
        groupCode: 'COUNTY',
        label: 'Sibiu',
        description: 'Sibiu',
        active: true,
        parentId: 'A047433E-F36B-1410-8D33-00DFB91F5CDC',
      },
    },
    services: [{
      id: 'AB47433E-F36B-1410-8D33-00DFB91F5CDC',
      code: 'MOBILIZATOR',
      groupCode: 'SPECIALIZATION_TYPE',
      label: 'Mobilizator',
      description: 'Mobilizator',
      active: true,
      parentId: null,
    }, {
      id: 'B147433E-F36B-1410-8D33-00DFB91F5CDC',
      code: 'RECOLTOR',
      groupCode: 'SPECIALIZATION_TYPE',
      label: 'Recoltor',
      description: 'Recoltor',
      active: true,
      parentId: null,
    }, {
      id: 'B747433E-F36B-1410-8D33-00DFB91F5CDC',
      code: 'LABORATOR_HPV',
      groupCode: 'SPECIALIZATION_TYPE',
      label: 'Laborator HPV',
      description: 'Laborator HPV',
      active: true,
      parentId: null,
    }, {
      id: '7B51433E-F36B-1410-8D33-00DFB91F5CDC',
      code: 'FOLLOWUP',
      groupCode: 'SPECIALIZATION_TYPE',
      label: 'Follow-up',
      description: 'Follow-up',
      active: true,
      parentId: null,
    }, {
      id: '6A5D433E-F36B-1410-8D33-00DFB91F5CDC',
      code: 'LABORATOR_PAP',
      groupCode: 'SPECIALIZATION_TYPE',
      label: 'Laborator PAP',
      description: 'Laborator PAP',
      active: true,
      parentId: null,
    }],
  },
  {
    id: 'A999443E-F36B-1410-8B05-00C52C496AEF',
    code: 'IOCN_sud_muntenia',
    label: 'Sud-Muntenia',
    description: 'Sud-Muntenia',
    active: true,
    representative: null,
    servicePrice: '{}',
    organizationType: {
      id: 'CC47433E-F36B-1410-8D33-00DFB91F5CDC',
      code: 'FURNIZOR_REGIONAL',
      groupCode: 'ORG_TYPE',
      label: 'FURNIZOR REGIONAL',
      description: 'FURNIZOR REGIONAL',
      active: true,
      parentId: null,
    },
    address: {
      id: '9399443E-F36B-1410-8B05-00C52C496AEF',
      street: null,
      zipCode: null,
      country: null,
      buildingNo: null,
      floor: null,
      apartmentNo: null,
      streetNo: null,
      location: null,
      city: {
        id: 'CF16673E-F36B-1410-8D37-00DFB91F5CDC',
        code: '100530',
        groupCode: 'CITY',
        label: 'GIURGIU',
        description: 'GIURGIU',
        active: true,
        parentId: 'F704443E-F36B-1410-8D2C-00DFB91F5CDC',
      },
      county: {
        id: 'F704443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'GR',
        groupCode: 'COUNTY',
        label: 'Giurgiu',
        description: 'Giurgiu',
        active: true,
        parentId: 'A547433E-F36B-1410-8D33-00DFB91F5CDC',
      },
    },
    services: [{
      id: 'AB47433E-F36B-1410-8D33-00DFB91F5CDC',
      code: 'MOBILIZATOR',
      groupCode: 'SPECIALIZATION_TYPE',
      label: 'Mobilizator',
      description: 'Mobilizator',
      active: true,
      parentId: null,
    }, {
      id: 'B147433E-F36B-1410-8D33-00DFB91F5CDC',
      code: 'RECOLTOR',
      groupCode: 'SPECIALIZATION_TYPE',
      label: 'Recoltor',
      description: 'Recoltor',
      active: true,
      parentId: null,
    }, {
      id: 'B747433E-F36B-1410-8D33-00DFB91F5CDC',
      code: 'LABORATOR_HPV',
      groupCode: 'SPECIALIZATION_TYPE',
      label: 'Laborator HPV',
      description: 'Laborator HPV',
      active: true,
      parentId: null,
    }, {
      id: '7B51433E-F36B-1410-8D33-00DFB91F5CDC',
      code: 'FOLLOWUP',
      groupCode: 'SPECIALIZATION_TYPE',
      label: 'Follow-up',
      description: 'Follow-up',
      active: true,
      parentId: null,
    }, {
      id: '6A5D433E-F36B-1410-8D33-00DFB91F5CDC',
      code: 'LABORATOR_PAP',
      groupCode: 'SPECIALIZATION_TYPE',
      label: 'Laborator PAP',
      description: 'Laborator PAP',
      active: true,
      parentId: null,
    }],
  },
  {
    id: 'FABE443E-F36B-1410-8D2C-00DFB91F5CDC',
    code: 'Spitalul Clinic Județean de Urgența, Cluj',
    label: 'Spitalul Clinic Județean de Urgența, Cluj',
    description: 'Spitalul Clinic Județean de Urgența, Cluj',
    active: true,
    representative: null,
    servicePrice: '{"MOBILIZATOR":45,"RECOLTOR":32}',
    organizationType: {
      id: 'F6BE443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'SPITAL',
      groupCode: 'ORG_TYPE',
      label: 'SPITAL',
      description: 'ORGANZIATION',
      active: true,
      parentId: null,
    },
    address: {
      id: '49C2443E-F36B-1410-8D2C-00DFB91F5CDC',
      street: null,
      zipCode: null,
      country: null,
      buildingNo: null,
      floor: null,
      apartmentNo: null,
      streetNo: null,
      location: null,
      city: {
        id: '3D31673E-F36B-1410-8D37-00DFB91F5CDC',
        code: '106327',
        groupCode: 'CITY',
        label: 'BAIA MARE',
        description: 'BAIA MARE',
        active: true,
        parentId: '1A05443E-F36B-1410-8D2C-00DFB91F5CDC',
      },
      county: {
        id: '1A05443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'MM',
        groupCode: 'COUNTY',
        label: 'Maramureş',
        description: 'Maramures',
        active: true,
        parentId: '9647433E-F36B-1410-8D33-00DFB91F5CDC',
      },
    },
    services: [{
      id: 'AB47433E-F36B-1410-8D33-00DFB91F5CDC',
      code: 'MOBILIZATOR',
      groupCode: 'SPECIALIZATION_TYPE',
      label: 'Mobilizator',
      description: 'Mobilizator',
      active: true,
      parentId: null,
    }, {
      id: 'B147433E-F36B-1410-8D33-00DFB91F5CDC',
      code: 'RECOLTOR',
      groupCode: 'SPECIALIZATION_TYPE',
      label: 'Recoltor',
      description: 'Recoltor',
      active: true,
      parentId: null,
    }, {
      id: 'B747433E-F36B-1410-8D33-00DFB91F5CDC',
      code: 'LABORATOR_HPV',
      groupCode: 'SPECIALIZATION_TYPE',
      label: 'Laborator HPV',
      description: 'Laborator HPV',
      active: true,
      parentId: null,
    }, {
      id: '7B51433E-F36B-1410-8D33-00DFB91F5CDC',
      code: 'FOLLOWUP',
      groupCode: 'SPECIALIZATION_TYPE',
      label: 'Follow-up',
      description: 'Follow-up',
      active: true,
      parentId: null,
    }, {
      id: '6A5D433E-F36B-1410-8D33-00DFB91F5CDC',
      code: 'LABORATOR_PAP',
      groupCode: 'SPECIALIZATION_TYPE',
      label: 'Laborator PAP',
      description: 'Laborator PAP',
      active: true,
      parentId: null,
    }],
  },
  {
    id: 'FDBE443E-F36B-1410-8D2C-00DFB91F5CDC',
    code: 'Spitalul “Sf. Spiridon”, Iași',
    label: 'Spitalul “Sf. Spiridon”, Iași',
    description: 'Spitalul “Sf. Spiridon”, Iași',
    active: true,
    representative: null,
    servicePrice: null,
    organizationType: {
      id: 'F6BE443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'SPITAL',
      groupCode: 'ORG_TYPE',
      label: 'SPITAL',
      description: 'ORGANZIATION',
      active: true,
      parentId: null,
    },
    address: null,
    services: [],
  },
  {
    id: '00BF443E-F36B-1410-8D2C-00DFB91F5CDC',
    code: 'Spitalul clinic județean, Mureș',
    label: 'Spitalul clinic județean, Mureș',
    description: 'Spitalul clinic județean, Mureș',
    active: true,
    representative: null,
    servicePrice: null,
    organizationType: {
      id: 'F6BE443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'SPITAL',
      groupCode: 'ORG_TYPE',
      label: 'SPITAL',
      description: 'ORGANZIATION',
      active: true,
      parentId: null,
    },
    address: null,
    services: [],
  },
  {
    id: '03BF443E-F36B-1410-8D2C-00DFB91F5CDC',
    code: 'Spitalul clinic județean de urgență “Pius Brânzeu”, Timișoara',
    label: 'Spitalul clinic județean de urgență “Pius Brânzeu”, Timișoara',
    description: 'Spitalul clinic județean de urgență “Pius Brânzeu”, Timișoara',
    active: true,
    representative: null,
    servicePrice: null,
    organizationType: {
      id: 'F6BE443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'SPITAL',
      groupCode: 'ORG_TYPE',
      label: 'SPITAL',
      description: 'ORGANZIATION',
      active: true,
      parentId: null,
    },
    address: null,
    services: [],
  },
  {
    id: '06BF443E-F36B-1410-8D2C-00DFB91F5CDC',
    code: 'Spitalul clinic județean, Sibiu',
    label: 'Spitalul clinic județean, Sibiu',
    description: 'Spitalul clinic județean, Sibiu',
    active: true,
    representative: null,
    servicePrice: null,
    organizationType: {
      id: 'F6BE443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'SPITAL',
      groupCode: 'ORG_TYPE',
      label: 'SPITAL',
      description: 'ORGANZIATION',
      active: true,
      parentId: null,
    },
    address: null,
    services: [],
  },
  {
    id: '09BF443E-F36B-1410-8D2C-00DFB91F5CDC',
    code: 'Spitalul Clinic Județean de Urgență “Sf. Apostol Andrei”, cluj',
    label: 'Spitalul Clinic Județean de Urgență “Sf. Apostol Andrei”, cluj',
    description: 'Spitalul Clinic Județean de Urgență “Sf. Apostol Andrei”, cluj',
    active: true,
    representative: null,
    servicePrice: null,
    organizationType: {
      id: 'F6BE443E-F36B-1410-8D2C-00DFB91F5CDC',
      code: 'SPITAL',
      groupCode: 'ORG_TYPE',
      label: 'SPITAL',
      description: 'ORGANZIATION',
      active: true,
      parentId: null,
    },
    address: {
      id: '94D3443E-F36B-1410-8D2C-00DFB91F5CDC',
      street: null,
      zipCode: null,
      country: null,
      buildingNo: null,
      floor: null,
      apartmentNo: null,
      streetNo: null,
      location: null,
      city: {
        id: '11F9663E-F36B-1410-8D37-00DFB91F5CDC',
        code: '54984',
        groupCode: 'CITY',
        label: 'CLUJ-NAPOCA',
        description: 'CLUJ-NAPOCA',
        active: true,
        parentId: 'D904443E-F36B-1410-8D2C-00DFB91F5CDC',
      },
      county: {
        id: 'D904443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'CJ',
        groupCode: 'COUNTY',
        label: 'Cluj',
        description: 'Cluj',
        active: true,
        parentId: '9647433E-F36B-1410-8D33-00DFB91F5CDC',
      },
    },
    services: [],
  }
];

export const testOrg = {
  id: '9A99443E-F36B-1410-8B05-00C52C496AEF',
  code: 'IOCN_nord_vest',
  label: 'Nord-Vest',
  description: 'Nord-Vest',
  active: true,
  representative: null,
  servicePrice: '{}',
  organizationType:
    {
      id: 'CC47433E-F36B-1410-8D33-00DFB91F5CDC',
      code: 'FURNIZOR_REGIONAL',
      groupCode: 'ORG_TYPE',
      label: 'FURNIZOR REGIONAL',
      description: 'FURNIZOR REGIONAL',
      active: true,
      parentId: null,
    },
  address:
    {
      id: '8299443E-F36B-1410-8B05-00C52C496AEF',
      street: null,
      zipCode: null,
      country: null,
      buildingNo: null,
      floor: null,
      apartmentNo: null,
      streetNo: null,
      location: null,
      city: {
        id: '11F9663E-F36B-1410-8D37-00DFB91F5CDC',
        code: '54984',
        groupCode: 'CITY',
        label: 'CLUJ-NAPOCA',
        description: 'CLUJ-NAPOCA',
        active: true,
        parentId: 'D904443E-F36B-1410-8D2C-00DFB91F5CDC',
      },
      county: {
        id: 'D904443E-F36B-1410-8D2C-00DFB91F5CDC',
        code: 'CJ',
        groupCode: 'COUNTY',
        label: 'Cluj',
        description: 'Cluj',
        active: true,
        parentId: '9647433E-F36B-1410-8D33-00DFB91F5CDC',
      },
    },
  services: [
    {
      id: 'AB47433E-F36B-1410-8D33-00DFB91F5CDC',
      code: 'MOBILIZATOR',
      groupCode: 'SPECIALIZATION_TYPE',
      label: 'Mobilizator',
      description: 'Mobilizator',
      active: true,
      parentId: null,
    }, {
      id: 'B147433E-F36B-1410-8D33-00DFB91F5CDC',
      code: 'RECOLTOR',
      groupCode: 'SPECIALIZATION_TYPE',
      label: 'Recoltor',
      description: 'Recoltor',
      active: true,
      parentId: null,
    }, {
      id: 'B747433E-F36B-1410-8D33-00DFB91F5CDC',
      code: 'LABORATOR_HPV',
      groupCode: 'SPECIALIZATION_TYPE',
      label: 'Laborator HPV',
      description: 'Laborator HPV',
      active: true,
      parentId: null,
    }, {
      id: '7B51433E-F36B-1410-8D33-00DFB91F5CDC',
      code: 'FOLLOWUP',
      groupCode: 'SPECIALIZATION_TYPE',
      label: 'Follow-up',
      description: 'Follow-up',
      active: true,
      parentId: null,
    }, {
      id: '6A5D433E-F36B-1410-8D33-00DFB91F5CDC',
      code: 'LABORATOR_PAP',
      groupCode: 'SPECIALIZATION_TYPE',
      label: 'Laborator PAP',
      description: 'Laborator PAP',
      active: true,
      parentId: null,
    }],
};

export const organizationServicesMock = [
  {
    id: 'AB47433E-F36B-1410-8D33-00DFB91F5CDC',
    code: 'MOBILIZATOR',
    value: 'MOBILIZATOR',
    groupCode: 'SPECIALIZATION_TYPE',
    label: 'Mobilizator',
    description: 'Mobilizator',
    active: true,
    parentId: null,
  },
  {
    value: 'RECOLTOR',
    label: 'Recoltor',
  },
  {
    id: 'B747433E-F36B-1410-8D33-00DFB91F5CDC',
    code: 'LABORATOR_HPV',
    value: 'LABORATOR_HPV',
    groupCode: 'SPECIALIZATION_TYPE',
    label: 'Laborator HPV',
    description: 'Laborator HPV',
    active: true,
    parentId: null,
  },
  {
    id: '7B51433E-F36B-1410-8D33-00DFB91F5CDC',
    code: 'FOLLOWUP',
    value: 'FOLLOWUP',
    groupCode: 'SPECIALIZATION_TYPE',
    label: 'Follow-up',
    description: 'Follow-up',
    active: true,
    parentId: null,
  },
  {
    id: '6A5D433E-F36B-1410-8D33-00DFB91F5CDC',
    code: 'LABORATOR_PAP',
    value: 'LABORATOR_PAP',
    groupCode: 'SPECIALIZATION_TYPE',
    label: 'Laborator PAP',
    description: 'Laborator PAP',
    active: true,
    parentId: null,
  }
];

export const organizationTypeOptions = [
  { value: 'ORG', label: 'ORG', },
  { value: 'SPITAL', label: 'SPITAL', },
  { value: 'FURNIZOR', label: 'FURNIZOR', },
  { value: 'FURNIZOR_REGIONAL', label: 'FURNIZOR_REGIONAL', }
];

export const regionOptions = [
  { value: 'nord_vest', label: 'Nord-Vest', },
  { value: 'nord_est', label: 'Nord-Est', },
  { value: 'centru', label: 'Centru', },
  { value: 'sud_muntenia', label: 'Sud-Muntenia', }
];

export const countyOptionsAll = {
  nord_vest: [
    { value: 'BH', label: 'Bihor', },
    { value: 'BN', label: 'Bistriţa-Năsăud', },
    { value: 'CJ', label: 'Cluj', },
    { value: 'MM', label: 'Maramureş', },
    { value: 'SM', label: 'Satu Mare', },
    { value: 'SJ', label: 'Sălaj', }
  ],
  nord_est: [
    { value: 'BC', label: 'Bacău', },
    { value: 'BT', label: 'Botoşani', },
    { value: 'IS', label: 'Iaşi', },
    { value: 'NT', label: 'Neamţ', },
    { value: 'SV', label: 'Suceava', },
    { value: 'VS', label: 'Vaslui', }
  ],
  centru: [
    { value: 'AB', label: 'Alba', },
    { value: 'BV', label: 'Braşov', },
    { value: 'CV', label: 'Covasna', },
    { value: 'HR', label: 'Harghita', },
    { value: 'MS', label: 'Mureş', },
    { value: 'SB', label: 'Sibiu', }
  ],
  sud_muntenia: [
    { value: 'AG', label: 'Argeş', },
    { value: 'B', label: 'Bucureşti', },
    { value: 'CL', label: 'Călăraşi', },
    { value: 'DB', label: 'Dâmboviţa', },
    { value: 'GR', label: 'Giurgiu', },
    { value: 'IL', label: 'Ialomiţa', },
    { value: 'PH', label: 'Prahova', },
    { value: 'TR', label: 'Teleorman', }
  ],
};

export const organizationServicesOptions = [
  { value: 'MOBILIZATOR', label: 'Mobilizator', },
  { value: 'RECOLTOR', label: 'Recoltor', },
  { value: 'LABORATOR_HPV', label: 'Laborator HPV', },
  { value: 'FOLLOWUP', label: 'Follow-up', },
  { value: 'LABORATOR_PAP', label: 'Laborator PAP', }
];

export const countyOptions = [
  { value: 'AB', label: 'Alba', },
  { value: 'AG', label: 'Argeş', },
  { value: 'B', label: 'Bucureşti', },
  { value: 'BC', label: 'Bacău', },
  { value: 'BH', label: 'Bihor', },
  { value: 'BN', label: 'Bistriţa-Năsăud', },
  { value: 'BT', label: 'Botoşani', },
  { value: 'BV', label: 'Braşov', },
  { value: 'CJ', label: 'Cluj', },
  { value: 'CL', label: 'Călăraşi', },
  { value: 'CV', label: 'Covasna', },
  { value: 'DB', label: 'Dâmboviţa', },
  { value: 'GR', label: 'Giurgiu', },
  { value: 'HR', label: 'Harghita', },
  { value: 'IL', label: 'Ialomiţa', },
  { value: 'IS', label: 'Iaşi', },
  { value: 'MS', label: 'Mureş', },
  { value: 'MM', label: 'Maramureş', },
  { value: 'NT', label: 'Neamţ', },
  { value: 'PH', label: 'Prahova', },
  { value: 'SB', label: 'Sibiu', },
  { value: 'SJ', label: 'Sălaj', },
  { value: 'SM', label: 'Satu Mare', },
  { value: 'SV', label: 'Suceava', },
  { value: 'TR', label: 'Teleorman', },
  { value: 'VS', label: 'Vaslui', }
];
