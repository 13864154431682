const GET_DOCTORS = 'GET_DOCTORS';
const GET_DOCTORS_COMPLETED = 'GET_DOCTORS_COMPLETED';
const GET_DOCTORS_FAILED = 'GET_DOCTORS_FAILED';
const GET_SINGLE_DOCTOR = 'GET_SINGLE_DOCTOR';
const GET_SINGLE_DOCTOR_COMPLETED = 'GET_SINGLE_DOCTOR_COMPLETED';
const GET_SINGLE_DOCTOR_FAILED = 'GET_SINGLE_DOCTOR_FAILED';
const CREATE_DOCTOR = 'CREATE_DOCTOR';
const CREATE_DOCTOR_COMPLETED = 'CREATE_DOCTOR_COMPLETED';
const CREATE_DOCTOR_FAILED = 'CREATE_DOCTOR_FAILED';
const UPDATE_DOCTOR = 'UPDATE_DOCTOR';
const UPDATE_DOCTOR_COMPLETED = 'UPDATE_DOCTOR_COMPLETED';
const UPDATE_DOCTOR_FAILED = 'UPDATE_DOCTOR_FAILED';
const GET_DOCTORS_SIMPLE = 'GET_DOCTORS_SIMPLE';
const GET_DOCTORS_SIMPLE_COMPLETED = 'GET_DOCTORS_SIMPLE_COMPLETED';
const GET_DOCTORS_SIMPLE_FAILED = 'GET_DOCTORS_SIMPLE_FAILED';

export default {
  GET_DOCTORS,
  GET_DOCTORS_COMPLETED,
  GET_DOCTORS_FAILED,
  GET_DOCTORS_SIMPLE,
  GET_DOCTORS_SIMPLE_COMPLETED,
  GET_DOCTORS_SIMPLE_FAILED,
  GET_SINGLE_DOCTOR,
  GET_SINGLE_DOCTOR_COMPLETED,
  GET_SINGLE_DOCTOR_FAILED,
  CREATE_DOCTOR,
  CREATE_DOCTOR_COMPLETED,
  CREATE_DOCTOR_FAILED,
  UPDATE_DOCTOR,
  UPDATE_DOCTOR_COMPLETED,
  UPDATE_DOCTOR_FAILED,
};
