import React from 'react';
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  Col
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

function PatientActiveModal({ close, selected, setActive, }) {
  const confirm = () => {
    setActive({
      id: selected.id,
      active: !selected.active,
    });
    close();
  };

  return (
    <Modal size="xl" isOpen>
      <ModalHeader className="p-4" toggle={close}>
        {`Confirmați operația de ${selected.active ? 'inactivare' : 'reactivare'}`}
      </ModalHeader>
      <ModalBody className="p-4">
        <p className="mb-0">{`Sunteți sigur că doriți să ${selected.active ? 'inactivați' : 'reactivați'} beneficiarul ${selected.person.fullName}?`}</p>
      </ModalBody>
      <ModalFooter>
        <Col md={6} className="text-right">
          <Button
            onClick={close}
            color="secondary"
          >
            <FontAwesomeIcon icon={faBan} />
            <span className="ml-1">Anulare</span>
          </Button>
          <Button
            color={selected.active ? 'danger' : 'success'}
            className="ml-1"
            onClick={confirm}
          >
            <FontAwesomeIcon
              icon={selected.active ? faTimes : faCheck}

            />
            <span className="ml-1">{selected.active ? 'Inactivare' : 'Reactivare'}</span>
          </Button>
        </Col>
      </ModalFooter>
    </Modal>
  );
}

export default PatientActiveModal;
