import React, { Component } from 'react';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, CardBody, CardHeader, Spinner } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { UsersTable, UserFilterSimple, UserFilterExtended } from './components/index';
import Pagination from '../../Pagination';
import { usersOperations } from '../../../redux/ducks/users';

import './index.scss';
import { organizationsOperations } from '../../../redux/ducks/organizations';

const defaultFilters = {
  page: 1,
  size: 10,
  sortBy: 'createTs',
  sortDir: 'asc',
};

class Users extends Component {
  constructor() {
    super();

    this.setFilters = this.setFilters.bind(this);
    this.addFilterAndSearch = this.addFilterAndSearch.bind(this);
    this.selectFilterType = this.selectFilterType.bind(this);
    this.setSortFilter = this.setSortFilter.bind(this);
    this.resetFilters = this.resetFilters.bind(this);
    this.parseUrlAndSetFilters = this.parseUrlAndSetFilters.bind(this);
    this.setActiveStatus = this.setActiveStatus.bind(this);
    this.getUsers = this.getUsers.bind(this);
    this.getUsersData = this.getUsersData.bind(this);
    this.onBrowserNav = this.onBrowserNav.bind(this);

    this.state = {
      filters: defaultFilters,
      filterType: 'simple',
      users: [],
      firstElem: 0,
      lastElem: 0,
      totalNo: 0,
      key: 0,
      loading: false,
      organizationsExt: [],
    };
  }

  componentDidMount() {
    window.addEventListener('popstate', this.onBrowserNav);

    const { auth, navigate, getOrganizationsExt, } = this.props;
    if (auth) {
      const { view, } = auth;
      if (view && !view.includes('USER_LIST')) {
        setTimeout(() => {
          navigate('/');
        }, 10);
      }
    }
    setTimeout(() => {
      this.parseUrlAndSetFilters();
    }, 50);
    getOrganizationsExt()
      .then((res => {
        this.setState({ organizationsExt: res.data.data, });
      }))
      .catch((err => {
        console.log('err: ', err);
      }));
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.onBrowserNav);
  }

  onBrowserNav() {
    this.setState({ filters: defaultFilters, key: Math.random(), }, () => {
      this.parseUrlAndSetFilters();
    });
  }

  parseUrlAndSetFilters() {
    const { searchParams, } = this.props;
    const parsedFilters = Object.fromEntries([...searchParams]);
    if (Object.keys(parsedFilters).length > 0) {
      this.setFilters(parsedFilters);
    } else {
      this.resetFilters();
    }
  }

  getUsers() {
    const { location: { pathname, }, navigate, } = this.props;
    const { filters, } = this.state;
    const formatFilters = Object.entries(filters).filter(elem => elem[1] !== '').map(elem => elem.join('=')).join('&');
    navigate(`${pathname}?${formatFilters}`);
    this.getUsersData(`${pathname}s?${formatFilters}`);
  }

  getUsersData(url) {
    const { getUsers, } = this.props;
    this.setState({ loading: true, });

    getUsers(url)
      .then((res => {
        const { totalElements, totalPages, data, } = res.data;
        const { filters, } = this.state;
        this.setState({
          users: data,
          totalNo: totalElements,
          firstElem: (filters.page - 1) * filters.size + 1,
          lastElem: filters.page * filters.size > totalElements ? totalElements : filters.page * filters.size,
          totalPages: totalPages - 1,
          loading: false,
        });
      }))
      .catch((err => {
        console.log('err: ', err);
        this.setState({ loading: false, });
      }));
  }

  setFilters(data) {
    this.setState({ filters: data, }, () => {
      this.getUsers();
    });
  }

  addFilterAndSearch(data, pageSelect, removeKey) {
    const { filters, } = this.state;
    if (removeKey) delete filters[removeKey];
    const newFilters = {
      ...filters,
      ...data,
      ...(!pageSelect && { page: 1, }),
    };
    this.setFilters(newFilters);
  }

  setSortFilter(e) {
    let newSortDir;
    const newSortBy = e.target.id;
    const { filters: { sortBy, sortDir, }, } = this.state;
    if (e.target.id === sortBy) {
      newSortDir = sortDir === 'asc' ? 'desc' : 'asc';
    } else {
      newSortDir = 'asc';
    }
    this.addFilterAndSearch({ sortBy: newSortBy, sortDir: newSortDir, }, true);
  }

  selectFilterType() {
    const { filterType, } = this.state;
    const newType = filterType === 'simple' ? 'extended' : 'simple';
    this.setState({ filterType: newType, });
  }

  resetFilters() {
    this.setState({ filters: defaultFilters, }, () => {
      this.getUsers();
    });
  }

  setActiveStatus(data) {
    const { active, id, } = data;
    const { updateUser, } = this.props;
    updateUser({ active, id, })
      .then(() => {
        this.getUsers();
      })
      .catch(err => {
        console.log('err: ', err);
      });
  }

  render() {
    const { users, firstElem, lastElem, totalNo, filters, filterType, key, totalPages, loading, organizationsExt, } = this.state;
    const { location, auth, } = this.props;

    return (
      <>
        {
          loading && (
            <div
              className="loading-container"
            >
              <Spinner />
            </div>
          )
        }
        <div className={`container-fluid position-relative ${loading ? 'no-events loading-data' : ''}`}>
          <Card className="mt-4">
            <CardHeader className="position-relative">
              <div className="d-inline-block">
                <h3><span>Utilizatori</span></h3>
                <div className="info">
                  {`Se afiseaza ${firstElem} - ${lastElem} din ${totalNo} elemente.`}
                </div>
              </div>
              <div className="position-absolute create-organization-btn-wrapper">
                <NavLink
                  to="/user/new"
                  state={{ from: location, }}
                >
                  <Button
                    color="success"
                    className="btn btn-pill p-0"
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </Button>
                </NavLink>
              </div>
            </CardHeader>
            <CardBody>
              {
                filterType === 'simple'
                  ? (
                    <UserFilterSimple
                      key={key}
                      addFilterAndSearch={this.addFilterAndSearch}
                      setFilterType={this.selectFilterType}
                      resetFilters={this.resetFilters}
                      filters={filters}
                    />
                  )
                  : (
                    <UserFilterExtended
                      auth={auth}
                      organizationsExt={organizationsExt}
                      key={key}
                      addFilterAndSearch={this.addFilterAndSearch}
                      setFilterType={this.selectFilterType}
                      resetFilters={this.resetFilters}
                      filters={filters}
                    />
                  )
              }
              <UsersTable
                auth={auth}
                setActiveStatus={this.setActiveStatus}
                setSortFilter={this.setSortFilter}
                tableData={users}
                filters={filters}
              />
              <Pagination
                totalPages={totalPages}
                totalNo={totalNo}
                filters={filters}
                addFilterAndSearch={this.addFilterAndSearch}
              />
            </CardBody>
          </Card>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  doctors: state.doctors.doctors,
  selectedDoctor: state.doctors.selectedDoctor,
});

const mapDispatchToProps = dispatch => ({
  getUsers: url => dispatch(usersOperations.getUsers(url)),
  updateUser: data => dispatch(usersOperations.updateUser(data)),
  getOrganizationsExt: () => dispatch(organizationsOperations.getOrganizationsExt()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Users);
