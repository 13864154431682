import React from 'react';
import { Button, Card, CardHeader, Col, FormGroup, Input, Label, Row, Spinner } from 'reactstrap';

class FileHeader extends React.Component {
  constructor() {
    super();
    this.state = {};
    this.prefillFields = this.prefillFields.bind(this);
  }

  componentDidMount() {
    this.prefillFields();
  }

  componentDidUpdate(prevProps) {
    const { data, } = this.props;
    if (prevProps.data === null && data !== null) {
      this.prefillFields();
    }
  }

  prefillFields() {
    const { data, } = this.props;

    if (typeof data !== 'undefined' && data !== null) {
      const { patient, attributes, flaconNumberAuto, } = data;
      const { customId, person: { firstName, lastName, cnp, }, } = patient;
      const { serieFormular, } = attributes;
      this.setState({ customId, firstName, lastName, flaconNumberAuto, cnp, serieFormular, });
    }
  }

  render() {
    const { data, } = this.props;
    if (data === null) return <Spinner />;

    const { lastName, firstName, cnp, customId, flaconNumberAuto, serieFormular, } = this.state;

    return (
      <Card>
        <CardHeader className="border-0">
          <Row>
            <Col sm={6} md={4} xl={2}>
              <FormGroup>
                <Label>Nume beneficiar</Label>
                <Input
                  required
                  type="text"
                  disabled
                  value={lastName || ''}
                />
              </FormGroup>
            </Col>
            <Col sm={6} md={4} xl={2}>
              <FormGroup>
                <Label>Prenume beneficiar</Label>
                <Input
                  required
                  type="text"
                  disabled
                  value={firstName || ''}
                />
              </FormGroup>
            </Col>
            <Col sm={6} md={4} xl={2}>
              <FormGroup>
                <Label>CNP beneficiar</Label>
                <Input
                  required
                  type="text"
                  disabled
                  value={cnp || ''}
                />
              </FormGroup>
            </Col>
            <Col sm={6} md={4} xl={2}>
              <FormGroup>
                <Label>ID</Label>
                <Input
                  required
                  type="text"
                  disabled
                  value={customId || ''}
                />
              </FormGroup>
            </Col>
            <Col sm={6} md={4} xl={2}>
              <FormGroup>
                <Label>Număr/Serie formular</Label>
                <Input
                  required
                  type="text"
                  disabled
                  value={serieFormular?.value || ''}
                />
              </FormGroup>
            </Col>
            <Col sm={6} md={4} xl={2}>
              <FormGroup>
                <Label>Număr flacon extern</Label>
                <Input
                  required
                  type="text"
                  disabled
                  value={flaconNumberAuto || ''}
                />
              </FormGroup>
            </Col>
          </Row>
        </CardHeader>
      </Card>
    );
  }
}

export default FileHeader;
