import React, { Component } from 'react';
import { connect } from 'react-redux';
import usersOperations from '../../../redux/ducks/users/operations';
import NoPermissions from '../../NoPermissions';

class Indicatori extends Component {
  render() {
    return (
      <NoPermissions />
    );
  }
}

const mapStateToProps = state => ({
  users: state.users.users,
  selectedUser: state.users.selectedUser,
});

const mapDispatchToProps = dispatch => ({ getUsers: () => dispatch(usersOperations.getUsers()), });

export default connect(mapStateToProps, mapDispatchToProps)(Indicatori);
