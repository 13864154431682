import React, { useEffect, useState } from 'react';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';

function InfectiiCheckboxes({ data, editable, setCheckboxValue, readOnly, handleChange, }) {
  const [inputs, setInputs] = useState({});

  const checkboxInputs = [
    'trichomonasSec32FS1',
    'candidaSec32FS1',
    'vaginozaSec33FS1',
    'actinomycesSec33FS1',
    'herpesSec33FS1',
    'alteleMicroSec33FS1'
  ];

  useEffect(() => {
    const dataProps = {
      descriereAlteleMicroSec33FS1: data?.descriereAlteleMicroSec33FS1 || { value: '', },
      trichomonasSec32FS1: { value: data?.trichomonasSec32FS1?.value.toString() || 'false', },
      candidaSec32FS1: { value: data?.candidaSec32FS1?.value.toString() || 'false', },
      vaginozaSec33FS1: { value: data?.vaginozaSec33FS1?.value.toString() || 'false', },
      actinomycesSec33FS1: { value: data?.actinomycesSec33FS1?.value.toString() || 'false', },
      herpesSec33FS1: { value: data?.herpesSec33FS1?.value.toString() || 'false', },
      alteleMicroSec33FS1: { value: data?.alteleMicroSec33FS1?.value.toString() || 'false', },
    };
    setInputs(dataProps);
    if (Object.keys(inputs).length === 0) return;
    checkboxInputs.forEach(elem => {
      inputs[elem].value = inputs[elem].value.toString();
    });
  }, [data]);

  const {
    trichomonasSec32FS1,
    candidaSec32FS1,
    vaginozaSec33FS1,
    actinomycesSec33FS1,
    herpesSec33FS1,
    alteleMicroSec33FS1,
    descriereAlteleMicroSec33FS1,
  } = inputs;

  const handleCheck = e => {
    setCheckboxValue(e);
  };

  // const handleInputChange = e => {
  //   console.log('here: ', e.target.name);
  //   console.log('here: ', e.target.value);
  //   handleChange(e);
  // };

  return (
    <Row className="mt-3">
      <Col md={6}>
        <FormGroup check>
          {
            editable ? (
              <Input
                type="checkbox"
                checked={trichomonasSec32FS1?.value === 'true'}
                value={trichomonasSec32FS1?.value === 'true'}
                name="trichomonasSec32FS1"
                onChange={handleCheck}
              />
            ) : (
              <Input
                type="checkbox"
                value={trichomonasSec32FS1?.value || false}
                checked={trichomonasSec32FS1?.value === 'true'}
                name="trichomonasSec32FS1"
                onChange={() => {}}
              />
            )
          }
          <Label>Thricomonas</Label>
        </FormGroup>
      </Col>
      <Col md={6}>
        <FormGroup check>
          {
            editable ? (
              <Input
                type="checkbox"
                checked={candidaSec32FS1?.value === 'true'}
                value={candidaSec32FS1?.value === 'true'}
                name="candidaSec32FS1"
                onChange={handleCheck}
              />
            ) : (
              <Input
                type="checkbox"
                value={candidaSec32FS1?.value || false}
                checked={candidaSec32FS1?.value === 'true'}
                name="candidaSec32FS1"
                onChange={() => {}}
              />
            )
          }
          <Label>Candida</Label>
        </FormGroup>
      </Col>
      <Col md={6}>
        <FormGroup check>
          {
            editable ? (
              <Input
                type="checkbox"
                checked={vaginozaSec33FS1?.value === 'true'}
                value={vaginozaSec33FS1?.value === 'true'}
                name="vaginozaSec33FS1"
                onChange={handleCheck}
              />
            ) : (
              <Input
                type="checkbox"
                value={vaginozaSec33FS1?.value || false}
                checked={vaginozaSec33FS1?.value === 'true'}
                name="vaginozaSec33FS1"
                onChange={() => {}}
              />
            )
          }
          <Label>Gardnerella vaginalis</Label>
        </FormGroup>
      </Col>
      <Col md={6}>
        <FormGroup check>
          {
            editable ? (
              <Input
                type="checkbox"
                checked={actinomycesSec33FS1?.value === 'true'}
                value={actinomycesSec33FS1?.value === 'true'}
                name="actinomycesSec33FS1"
                onChange={handleCheck}
              />
            ) : (
              <Input
                type="checkbox"
                value={actinomycesSec33FS1?.value || false}
                checked={actinomycesSec33FS1?.value === 'true'}
                name="actinomycesSec33FS1"
                onChange={() => {}}
              />
            )
          }
          <Label>Actinomyces</Label>
        </FormGroup>
      </Col>
      <Col md={6}>
        <FormGroup check>
          {
            editable ? (
              <Input
                type="checkbox"
                checked={herpesSec33FS1?.value === 'true'}
                value={herpesSec33FS1?.value === 'true'}
                name="herpesSec33FS1"
                onChange={handleCheck}
              />
            ) : (
              <Input
                type="checkbox"
                value={herpesSec33FS1?.value || false}
                checked={herpesSec33FS1?.value === 'true'}
                name="herpesSec33FS1"
                onChange={() => {}}
              />
            )
          }
          <Label>Herpes virus simplex</Label>
        </FormGroup>
      </Col>
      <Col md={12}>
        <FormGroup check>
          {
            editable ? (
              <Input
                type="checkbox"
                checked={alteleMicroSec33FS1?.value === 'true'}
                value={alteleMicroSec33FS1?.value === 'true'}
                name="alteleMicroSec33FS1"
                onChange={handleCheck}
              />
            ) : (
              <Input
                type="checkbox"
                value={alteleMicroSec33FS1?.value || false}
                checked={alteleMicroSec33FS1?.value === 'true'}
                name="alteleMicroSec33FS1"
                onChange={() => {}}
              />
            )
          }
          <Label>Altele</Label>
        </FormGroup>
      </Col>
      {
        alteleMicroSec33FS1?.value === 'true' && (
        <Col sm={12}>
          <FormGroup>
            <Label>Descriere alte infectii</Label>
            <Input
              disabled={readOnly}
              type="textarea"
              rows={3}
              name="descriereAlteleMicroSec33FS1"
              onChange={handleChange}
              value={descriereAlteleMicroSec33FS1?.value || ''}
            />
          </FormGroup>
        </Col>
        )
      }

    </Row>
  );
}

export default InfectiiCheckboxes;
