import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router';
import { authOperations } from '../../../redux/ducks/auth';
import Cookies from 'universal-cookie';

import './index.scss';
import { Form, Input, UncontrolledAlert } from 'reactstrap';

function Login({ login, checkToken, getUserSettings, }) {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);
  const [password, setPassword] = useState('');

  const handleLogin = () => {
    const cookies = new Cookies();
    if (email === '' || password === '') return;
    login({ email, password, })
      .then(res => {
        setTimeout(() => {
          // cookies.set('access', res.data.accessToken);
          localStorage.setItem('token', res.data.accessToken);
          localStorage.setItem('refresh', res.data.refreshToken);
          localStorage.setItem('expiry', Date.now() + Number(res.data.expiryDuration));
          getUserSettings()
            .catch(() => {
              setError('Something went wrong. Please try again.');
            });
          navigate('/patient');
        }, 100);
        setTimeout(() => {
          checkToken();
        }, 2000);
      })
      .catch(err => {
        setError('Date incorecte');
      });
  };

  const handleEmailChange = e => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = e => {
    setPassword(e.target.value);
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      handleLogin();
    }
  };

  const handleKeyDownReset = event => {
    if (event.key === 'Enter') {
      resetPassword();
    }
  };

  const resetPassword = () => {
    navigate('/check/send-reset-mail');
  };

  return (
    <div className="container d-flex justify-content-center pt-5">
      {
        error && (
          <UncontrolledAlert className="login-alert" color="danger">
            {error}
          </UncontrolledAlert>
        )
      }

      <div className="mt-5" />
      <div onSubmit={handleLogin} className="login-form mt-5">
        <div className="login-form-body">
          <div className="form-group">
            <Input
              tabIndex={1}
              onKeyDown={handleKeyDown}
              onChange={handleEmailChange}
              type="text"
              id="email"
              name="email"
              className={`form-control ${email === '' ? 'invalid' : 'valid'}`}
              placeholder="Nume utilizator"
              value={email}
              autoComplete="off"
            />
          </div>
          <div className="form-group">
            <Input
              tabIndex={2}
              onKeyDown={handleKeyDown}
              onChange={handlePasswordChange}
              type="password"
              id="password"
              name="password"
              className={`form-control ${password === '' ? 'invalid' : 'valid'}`}
              placeholder="Parolă"
              autoComplete="off"
            />
          </div>
          <div className="form-group">
            <button
              onKeyDown={handleKeyDown}
              onClick={handleLogin}
              tabIndex={3}
              className={`btn btn-primary ${(email === '' || password === '') && 'disabled-btn'}`}
            >
              Autentificare
            </button>
          </div>
        </div>
        <div className="login-form-footer">
          <div tabIndex={3} onKeyDown={handleKeyDownReset} onClick={resetPassword} className="login-form-footer-message">Ai uitat parola?</div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  auth: state.auth,
  users: state.users.users,
  selectedUser: state.users.selectedUser,
});

const mapDispatchToProps = dispatch => ({
  login: data => dispatch(authOperations.login(data)),
  getUserSettings: data => dispatch(authOperations.getUserSettings(data)),
  checkToken: () => dispatch(authOperations.checkToken()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
