import { Button, Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faCalendarAlt, faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import { NavLink } from 'react-router-dom';
import { casefilesOperations } from '../../../../redux/ducks/casefiles';
import { organizationsOperations } from '../../../../redux/ducks/organizations';
import { doctorsOperations } from '../../../../redux/ducks/doctors';

function TsfEntityDatesModal({ casefile, data, confirm, close, getDoctorsSimple, updateTsfEntityDates, }) {
  const { doctor, } = casefile;
  const {
    d1ScheduleDate,
    d3InviteDate,
    d4ScheduleDate,
    d5InviteDate,
    d6ScheduleDate,
    d0IsFirstInvite = false,
  } = data;

  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [inviteDate, setInviteDate] = useState('');
  const [triajDate, setTriajDate] = useState('');
  const [colpoDate, setColpoDate] = useState('');
  const [colpoScheduleDate, setColpoScheduleDate] = useState('');
  const [colpoScheduleCheck, setColpoScheduleCheck] = useState(false);
  const [triajScheduleDate, setTriajScheduleDate] = useState('');
  const [scheduleDate, setScheduleDate] = useState('');
  const [informDate, setInformDate] = useState('');
  const [selectedInviteDoctor, setSelectedInviteDoctor] = useState(null);
  const [selectedInformDoctor, setSelectedInformDoctor] = useState(null);
  const [organizations, setOrganizations] = useState([]);
  const [regions, setRegions] = useState([]);
  const [doctors, setDoctors] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState('');
  const [informCheck, setInformCheck] = useState(false);
  const [inviteCheck, setInviteCheck] = useState(false);
  const [firstInviteCheck, setFirstInviteCheck] = useState(true);
  const [error, setError] = useState(null);

  const prefillInviteData = (doctorsData, tsfEntity) => {
    if (tsfEntity.d0InviteDate && tsfEntity.d0InviteDoctor) {
      setSelectedInviteDoctor({
        id: tsfEntity?.d0InviteDoctor?.id || '',
        value: tsfEntity.d0InviteDoctor?.person?.fullName || '',
      });
      setInviteDate(moment(tsfEntity.d0InviteDate).toDate());
      setInviteCheck(true);
    }
  };

  const prefillInformData = (doctorsData, tsfEntity) => {
    if (tsfEntity.d2InformDate && tsfEntity.d2InformDoctor) {
      setSelectedInformDoctor({
        id: tsfEntity?.d2InformDoctor?.id || '',
        value: tsfEntity.d2InformDoctor?.person?.fullName || '',
      });
      setInformDate(moment(tsfEntity.d2InformDate).toDate());
      setInformCheck(true);
    }
  };

  // DID MOUNT
  useEffect(() => {
    if (d1ScheduleDate) setScheduleDate(moment(d1ScheduleDate).toDate());
    if (d3InviteDate) setTriajDate(moment(d3InviteDate).toDate());
    if (d4ScheduleDate) setTriajScheduleDate(moment(d4ScheduleDate).toDate());
    if (d5InviteDate) setColpoDate(moment(d5InviteDate).toDate());
    if (d6ScheduleDate) setColpoScheduleDate(moment(d6ScheduleDate).toDate());

    setFirstInviteCheck(d0IsFirstInvite);

    if (doctor && doctor.organization) {
      getDoctorsSimple(doctor.organization.id)
        .then((res => {
          setDoctors(res.data.data);
          prefillInviteData(res.data.data, data);
          prefillInformData(res.data.data, data);
        }))
        .catch((err => {
          console.log('err: ', err);
        }));
    }
  }, []);

  const inviteDateChange = data => {
    setInviteDate(data);
  };

  const triajDateChange = data => {
    setTriajDate(data);
  };

  const triajScheduleDateChange = data => {
    setTriajScheduleDate(data);
  };

  const colpoDateChange = data => {
    setColpoDate(data);
  };

  const colpoScheduleDateChange = data => {
    setColpoScheduleDate(data);
  };

  const scheduleDateChange = data => {
    setScheduleDate(data);
  };

  const informDateChange = data => {
    setInformDate(data);
  };

  const selectInviteDoctor = selected => {
    setSelectedInviteDoctor(selected || '');
  };

  const selectInformDoctor = selected => {
    setSelectedInformDoctor(selected || '');
  };

  const saveChanges = () => {
    const payload = { d0IsFirstInvite: firstInviteCheck, };

    if (inviteCheck) {
      payload.d0InviteDoctor = { id: selectedInviteDoctor.id, };
      payload.d0InviteDate = moment(inviteDate).format('YYYY-MM-DD');
    }

    if (informCheck) {
      payload.d2InformDoctor = { id: selectedInformDoctor.id, };
      payload.d2InformDate = moment(informDate).format('YYYY-MM-DD');
    }

    if (scheduleDate && scheduleDate !== '') {
      payload.d1ScheduleDate = moment(scheduleDate).format('YYYY-MM-DD');
    }

    if (triajDate && triajDate !== '') {
      payload.d3InviteDate = moment(triajDate).format('YYYY-MM-DD');
    }
    if (triajScheduleDate && triajScheduleDate !== '') {
      payload.d4ScheduleDate = moment(triajScheduleDate).format('YYYY-MM-DD');
    }
    if (colpoDate && colpoDate !== '') {
      payload.d5InviteDate = moment(colpoDate).format('YYYY-MM-DD');
    }
    if (colpoScheduleDate && colpoScheduleDate !== '') {
      payload.d6ScheduleDate = moment(colpoScheduleDate).format('YYYY-MM-DD');
    }

    updateTsfEntityDates({
      id: data.id,
      body: payload,
    }).then(res => {
      // close Modal and refresh
      confirm();
    }).catch(err => {
      // console.log(err?.data?.message || "Operatiunea nu fost efectuata");
      setError(err?.data?.message || 'Operatiunea nu fost efectuata');
      setTimeout(() => {
        setError(null);
      }, 5000);
    });
  };

  const handleInviteCheck = e => {
    const newVal = e.target.value === 'false';
    setInviteCheck(newVal);
  };

  const handleFirstInviteCheck = e => {
    const newVal = e.target.value === 'false';
    setFirstInviteCheck(newVal);
  };

  const handleInformCheck = e => {
    const newVal = e.target.value === 'false';
    setInformCheck(newVal);
  };

  const calcDisabled = () => {
    let disabled = false;
    if (inviteCheck) {
      if (inviteDate === '' || inviteDate === null) { disabled = true; }
      if (selectedInviteDoctor === '' || selectedInviteDoctor === null) { disabled = true; }
    }
    if (informCheck) {
      if (informDate === '' || informDate === null) { disabled = true; }
      if (selectedInformDoctor === '' || selectedInformDoctor === null) { disabled = true; }
    }
    return disabled;
  };

  const saveDisabled = calcDisabled();

  return (
    <Modal size="xl" isOpen>
      <ModalHeader className="p-4" toggle={close}>Editare data</ModalHeader>
      <ModalBody className="p-4">
        <Row>
          <Col md={4}>
            <Row>
              <Col md={5}>
                <FormGroup check>
                  <Input
                    type="checkbox"
                    checked={inviteCheck}
                    value={inviteCheck}
                    name="inviteCheck"
                    onChange={handleInviteCheck}
                  />
                  <Label className="font-weight-bold">Invitare screening</Label>
                </FormGroup>
              </Col>
              <Col md={5}>
                <FormGroup check>
                  <Input
                    type="checkbox"
                    checked={firstInviteCheck}
                    value={firstInviteCheck}
                    name="firstInviteCheck"
                    onChange={handleFirstInviteCheck}
                  />
                  <Label className="font-weight-bold">Prima invitatie</Label>
                </FormGroup>
              </Col>
            </Row>
          </Col>
          <Col md={4} />
          <Col md={4}>
            <Row>
              <Col md={10}>
                <FormGroup check>
                  <Input
                    type="checkbox"
                    checked={informCheck}
                    value={informCheck}
                    name="informCheck"
                    onChange={handleInformCheck}
                  />
                  <Label className="font-weight-bold">Instiintare Rezultat</Label>
                </FormGroup>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col md={4}>
            <Row>
              <Col
                style={
                  inviteCheck ? {} : {
                    opacity: 0.7,
                    pointerEvents: 'none',
                  }
                }
                md={12}
              >
                <Label className="font-weight-bold">Data invitare screening:</Label>
                <FormGroup row className="d-flex align-items-center m-0">
                  <Col sm={9} className="pl-0 pr-0">
                    <Input
                      value={inviteDate ? moment(inviteDate).format('DD-MM-YYYY') : ''}
                      type="text"
                      className={`form-control bg-white ${(inviteCheck && inviteDate === '') && 'invalid'} `}
                      id="inviteDate"
                      disabled
                      placeholder="zz-ll-aaaa"
                    />
                  </Col>
                  <Col sm={3} className="pl-0">
                    <DatePicker
                      minDate={new Date('01-01-2021')}
                      maxDate={new Date()}
                      locale="ro"
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      selected={inviteDate}
                      onChange={inviteDateChange}
                      customInput={<Button className="form-control"><FontAwesomeIcon icon={faCalendarAlt} /></Button>}
                    />
                  </Col>
                </FormGroup>
              </Col>
            </Row>
          </Col>
          <Col md={4}>
            <Row>
              <Col md={12}>
                <Label className="font-weight-bold">Data programare screening:</Label>
                <FormGroup row className="d-flex align-items-center m-0">
                  <Col sm={9} className="pl-0 pr-0">
                    <Input
                      value={scheduleDate ? moment(scheduleDate).format('DD-MM-YYYY') : ''}
                      type="text"
                      className="form-control bg-white"
                      id="scheduleDate"
                      disabled
                      placeholder="zz-ll-aaaa"
                    />
                  </Col>
                  <Col sm={3} className="pl-0">
                    <DatePicker
                      minDate={new Date('01-01-2021')}
                      maxDate={new Date()}
                      locale="ro"
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      selected={scheduleDate}
                      onChange={scheduleDateChange}
                      customInput={<Button className="form-control"><FontAwesomeIcon icon={faCalendarAlt} /></Button>}
                    />
                  </Col>
                </FormGroup>
              </Col>
            </Row>
          </Col>
          <Col md={4}>
            <Row>
              <Col
                style={
                  informCheck ? {} : {
                    opacity: 0.7,
                    pointerEvents: 'none',
                  }
                }
                md={12}
              >
                <Label className="font-weight-bold">Data instiintare rezultat:</Label>
                <FormGroup row className="d-flex align-items-center m-0">
                  <Col sm={9} className="pl-0 pr-0">
                    <Input
                      value={informDate ? moment(informDate).format('DD-MM-YYYY') : ''}
                      type="text"
                      className={`form-control bg-white ${(informCheck && informDate === '') && 'invalid'} `}
                      id="informDate"
                      disabled
                      placeholder="zz-ll-aaaa"
                    />
                  </Col>
                  <Col sm={3} className="pl-0 pr-0">
                    <DatePicker
                      minDate={new Date('01-01-2021')}
                      maxDate={new Date()}
                      locale="ro"
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      selected={informDate}
                      onChange={informDateChange}
                      customInput={<Button className="form-control"><FontAwesomeIcon icon={faCalendarAlt} /></Button>}
                    />
                  </Col>
                </FormGroup>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col md={4}>
            <Row>
              <Col
                style={
                  inviteCheck ? {} : {
                    opacity: 0.7,
                    pointerEvents: 'none',
                  }
                }
                md={12}
              >
                <Label className="font-weight-bold mt-4">Personal invitare screening:</Label>
                <FormGroup>
                  <Col className="pl-0">
                    <Select
                      value={selectedInviteDoctor}
                      getOptionValue={option => option.id}
                      getOptionLabel={option => option.value}
                      className={!inviteCheck ? 'select-valid' : selectedInviteDoctor ? 'select-valid' : 'select-invalid'}
                      classNamePrefix="rolesSelect"
                      placeholder=""
                      isClearable
                      name="selectedInviteDoctor"
                      options={doctors}
                      onChange={selectInviteDoctor}
                    />
                  </Col>

                </FormGroup>
              </Col>
            </Row>
          </Col>
          <Col md={4} />
          <Col md={4}>

            <Row>
              <Col
                style={informCheck ? {} : {
                  opacity: 0.7,
                  pointerEvents: 'none',
                }}
                md={12}
              >
                <Label className="font-weight-bold mt-4">Personal instiintare rezultat:</Label>
                <FormGroup>
                  <Col className="pl-0 pr-0">
                    <Select
                      value={selectedInformDoctor}
                      getOptionValue={option => option.id}
                      getOptionLabel={option => option.value}
                      className={!informCheck ? 'select-valid' : selectedInformDoctor ? 'select-valid' : 'select-invalid'}
                      classNamePrefix="rolesSelect"
                      placeholder=""
                      isClearable
                      name="selectedInformDoctor"
                      options={doctors}
                      onChange={selectInformDoctor}
                    />
                  </Col>
                </FormGroup>
              </Col>
            </Row>

          </Col>
        </Row>

        <Row className="mt-2 mb-4">
          <Col md={6}>
            <Row>
              <Col md={12}>
                <Label className="font-weight-bold">Data invitare triaj:</Label>
                <FormGroup row className="d-flex align-items-center m-0">
                  <Col sm={9} className="pl-0 pr-0">
                    <Input
                      value={triajDate ? moment(triajDate).format('DD-MM-YYYY') : ''}
                      type="text"
                      className="form-control bg-white"
                      id="triajDate"
                      disabled
                      placeholder="zz-ll-aaaa"
                    />
                  </Col>
                  <Col sm={3} className="pl-0">
                    <DatePicker
                      minDate={new Date('01-01-2021')}
                      maxDate={new Date()}
                      locale="ro"
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      selected={triajDate}
                      onChange={triajDateChange}
                      customInput={<Button className="form-control"><FontAwesomeIcon icon={faCalendarAlt} /></Button>}
                    />
                  </Col>
                </FormGroup>
              </Col>
            </Row>
          </Col>
          <Col md={6}>
            <Row>
              <Col md={12}>
                <Label className="font-weight-bold">Data programare triaj:</Label>
                <FormGroup row className="d-flex align-items-center m-0">
                  <Col sm={9} className="pl-0 pr-0">
                    <Input
                      value={triajScheduleDate ? moment(triajScheduleDate).format('DD-MM-YYYY') : ''}
                      type="text"
                      className="form-control bg-white"
                      id="triajScheduleDate"
                      disabled
                      placeholder="zz-ll-aaaa"
                    />
                  </Col>
                  <Col sm={3} className="pl-0">
                    <DatePicker
                      minDate={new Date('01-01-2021')}
                      maxDate={new Date()}
                      locale="ro"
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      selected={triajScheduleDate}
                      onChange={triajScheduleDateChange}
                      customInput={<Button className="form-control"><FontAwesomeIcon icon={faCalendarAlt} /></Button>}
                    />
                  </Col>
                </FormGroup>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="">
          <Col md={6}>
            <Row>
              <Col md={12}>
                <Label className="font-weight-bold">Data invitare colposcopie/biopsie:</Label>
                <FormGroup row className="d-flex align-items-center m-0">
                  <Col sm={9} className="pl-0 pr-0">
                    <Input
                      value={colpoDate ? moment(colpoDate).format('DD-MM-YYYY') : ''}
                      type="text"
                      className="form-control bg-white"
                      id="colpoDate"
                      disabled
                      placeholder="zz-ll-aaaa"
                    />
                  </Col>
                  <Col sm={3} className="pl-0">
                    <DatePicker
                      minDate={new Date('01-01-2021')}
                      maxDate={new Date()}
                      locale="ro"
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      selected={colpoDate}
                      onChange={colpoDateChange}
                      customInput={<Button className="form-control"><FontAwesomeIcon icon={faCalendarAlt} /></Button>}
                    />
                  </Col>
                </FormGroup>
              </Col>
            </Row>
          </Col>
          <Col md={6}>
            <Row>
              <Col md={12}>
                <Label className="font-weight-bold">Data programare colposcopie/biopsie:</Label>
                <FormGroup row className="d-flex align-items-center m-0">
                  <Col sm={9} className="pl-0 pr-0">
                    <Input
                      value={colpoScheduleDate ? moment(colpoScheduleDate).format('DD-MM-YYYY') : ''}
                      type="text"
                      className="form-control bg-white"
                      id="colpoDate"
                      disabled
                      placeholder="zz-ll-aaaa"
                    />
                  </Col>
                  <Col sm={3} className="pl-0">
                    <DatePicker
                      minDate={new Date('01-01-2021')}
                      maxDate={new Date()}
                      locale="ro"
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      selected={colpoScheduleDate}
                      onChange={colpoScheduleDateChange}
                      customInput={<Button className="form-control"><FontAwesomeIcon icon={faCalendarAlt} /></Button>}
                    />
                  </Col>
                </FormGroup>
              </Col>
            </Row>
          </Col>
        </Row>

        { error && <p className="text-danger mb-0">{error}</p>}

        <Row className="mt-5">
          <Col md={6} />
          <Col md={6} className="text-right">
            <Button
              onClick={close}
              color="secondary"
            >
              <FontAwesomeIcon icon={faBan} />
              <span className="ml-1">Anulare</span>
            </Button>
            <Button
              disabled={saveDisabled}
              onClick={saveDisabled ? () => {} : saveChanges}
              color="primary"
              className="ml-1"
            >
              <FontAwesomeIcon icon={faSave} />
              <span className="ml-1">Salvare</span>
            </Button>
          </Col>
        </Row>

      </ModalBody>
    </Modal>
  );
}

const mapStateToProps = state => ({
  auth: state.auth,
  casefiles: state.casefiles.casefiles,
  selectedCasefile: state.casefiles.selectedCasefile,
});

const mapDispatchToProps = dispatch => ({
  getSingleCasefile: id => dispatch(casefilesOperations.getSingleCasefile(id)),
  updateCasefile: data => dispatch(casefilesOperations.updateCasefile(data)),
  updateTsfEntityDates: data => dispatch(casefilesOperations.updateTsfEntityDates(data)),
  getOrganizationsSimple: () => dispatch(organizationsOperations.getOrganizationsSimple()),
  getDoctorsSimple: orgId => dispatch(doctorsOperations.getDoctorsSimple(orgId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TsfEntityDatesModal);
