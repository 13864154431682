import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faRedoAlt, faSearch } from '@fortawesome/free-solid-svg-icons';
import { Input } from 'reactstrap';

function UserFilterSimple({ type, setFilterType, addFilterAndSearch, resetFilters, filters, }) {
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [role, setRole] = useState('');

  useEffect(() => {
    const { username: propsUsername, } = filters;
    if (typeof propsUsername !== 'undefined') setUsername(propsUsername);
  }, [filters.username]);

  useEffect(() => {
    const { email: propsEmail, } = filters;
    if (typeof propsEmail !== 'undefined') setEmail(propsEmail);
  }, [filters.email]);

  useEffect(() => {
    const { role: propsRole, } = filters;
    if (typeof propsRole !== 'undefined') setRole(propsRole);
  }, [filters.role]);

  const handleFilterType = () => {
    setFilterType(type);
  };

  const handleSearch = () => {
    addFilterAndSearch({ email, username, role, });
  };

  const handleEmailChange = e => {
    setEmail(e.target.value);
  };

  const handleRoleChange = e => {
    setRole(e.target.value);
  };

  const handleUsernameChange = e => {
    setUsername(e.target.value);
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const handleResetFilters = () => {
    setEmail('');
    setUsername('');
    setRole('');
    resetFilters();
  };

  return (
    <div className="row mt-4">
      <div className="col-sm-12">
        <div className="input-group flex-nowrap">
          <div className="input-group-prepend">
            <span id="addon-wrapping" className="input-group-text">Email:</span>
            <Input
              value={email}
              onKeyDown={handleKeyDown}
              onChange={handleEmailChange}
              type="text"
              className="form-control principalSearchField"
              placeholder="Căutare"
            />
          </div>
          <div className="input-group-prepend">
            <span id="addon-wrapping" className="input-group-text">Nume Utilizator:</span>
            <Input
              value={username}
              onKeyDown={handleKeyDown}
              onChange={handleUsernameChange}
              type="text"
              className="form-control principalSearchField"
              placeholder="Căutare"
            />
          </div>
          <div className="input-group-prepend">
            <span id="addon-wrapping" className="input-group-text">Rol:</span>
            <Input
              value={role}
              onKeyDown={handleKeyDown}
              onChange={handleRoleChange}
              type="text"
              className="form-control principalSearchField"
              placeholder="Căutare"
            />
          </div>
          <div className="input-group-append">
            <div
              onClick={handleResetFilters}
              className="input-group-text bg-transparent border-left-0 border cursor-pointer"
            >
              <FontAwesomeIcon icon={faRedoAlt} />
            </div>
            <div
              onClick={handleSearch}
              className="input-group-text bg-transparent border-left-0 border cursor-pointer"
            >
              <FontAwesomeIcon icon={faSearch} />
            </div>
            <div
              onClick={handleFilterType}
              id="search-expanded"
              className="input-group-text bg-transparent border-left-0 border cursor-pointer"
            >
              <FontAwesomeIcon icon={faChevronDown} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserFilterSimple;
