import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Collapse, Navbar, NavbarToggler, Nav, NavbarBrand } from 'reactstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChartLine,
  faQuestionCircle,
  faList,
  faUser,
  faBriefcaseMedical,
  faUserInjured, faUserMd
} from '@fortawesome/free-solid-svg-icons';
import { statisticsMenuData, routes, administrationMenuData, userMenuData } from './headerHelper';
import { authOperations } from '../../redux/ducks/auth';
import NavbarMenu from './NavbarMenu';
import NavbarItem from './NavbarItem';

import './index.scss';
import AdministrationMenu from './AdministrationMenu';

function Header({ auth, logout, }) {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const location = useLocation();
  const navigate = useNavigate();
  if (auth === null || Object.keys(auth).length === 0 || location.pathname === '/check') return <div />;

  const logoutUser = () => {
    logout();
    navigate('/check');
  };

  const { view, } = auth;

  return (
    <div id="appHeader" className="w-100">
      <Navbar color="light" light expand="md" className="bg-white">
        <div className="mr-2">
          <small
            className="d-flex align-items-center justify-content-center"
          >
            <span className="pl-2 pr-2 bg-warning text-white">V2</span>
          </small>
        </div>
        <div>
          {auth?.organizationName !== 'SUPER-ORG' ? auth?.organizationName : ''}
        </div>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="ml-auto" navbar>
            { view && view.includes('CASEFILE_LIST') && (
            <NavbarItem
              icon={<FontAwesomeIcon icon={faBriefcaseMedical} />}
              isActive={location.pathname}
              entry={{ id: 'dosar', path: '/casefile', name: 'Dosar', }}
            />
            )}

            { view && view.includes('PATIENT_LIST') && (
            <NavbarItem
              icon={<FontAwesomeIcon icon={faUserInjured} />}
              isActive={location.pathname}
              entry={{ id: 'beneficiar', path: '/patient', name: 'Beneficiar', }}
            />
            )}

            { view && view.includes('DOCTOR_LIST') && (
            <NavbarItem
              icon={<FontAwesomeIcon icon={faUserMd} />}
              isActive={location.pathname}
              entry={{ id: 'doctor', path: '/doctor', name: 'Personal Furnizor', }}
            />
            )}

            {/* { navbarDom } */}
            { view && (view.includes('REPORT_LIST') || view.includes('USER_LIST')) && (
              <NavbarMenu
                menuTitle="Statistici"
                menuIcon={faChartLine}
                menuData={statisticsMenuData}
                location={location}
              />
            )}

            { view && (view.includes('ORGANIZATION_LIST') || view.includes('USER_LIST')) && (
            <AdministrationMenu
              auth={auth}
              menuTitle="Administrare"
              menuIcon={faList}
              location={location}
            />
            )}

            {/* <NavItem className={`d-flex align-items-center navbar-item-iocn ${location.pathname === '/help' ? 'is-active' : ''}`}> */}
            {/*  <NavLink className="pl-1 pr-3" to="" tag={Link}> */}
            {/*    <FontAwesomeIcon icon={faQuestionCircle} /> */}
            {/*    <span className="pl-1">Help</span> */}
            {/*  </NavLink> */}
            {/* </NavItem> */}
            <NavbarMenu
              menuTitle={auth.username || ''}
              menuIcon={faUser}
              menuData={userMenuData}
              location={location}
              callback={logoutUser}
            />
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
}

const mapStateToProps = state => ({ auth: state.auth, });

const mapDispatchToProps = dispatch => ({ logout: () => dispatch(authOperations.logout()), });

export default connect(mapStateToProps, mapDispatchToProps)(Header);

Header.propTypes = {
  auth: PropTypes.instanceOf(Object),
  logout: PropTypes.func.isRequired,
};

Header.defaultProps = { auth: null, };
