import { combineReducers } from 'redux';
import types from './types';

const initialState = {
  patients: [],
  selectedPatient: null,
};

const patients = (state = initialState.patients, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const selectedPatient = (state = initialState.selectedPatient, action) => {
  switch (action.type) {
    case types.SELECT_PATIENT:
      return action.payload;
    default:
      return state;
  }
};

export default combineReducers({
  patients,
  selectedPatient,
});
