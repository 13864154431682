import React, { useState, useEffect } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Container, FormGroup, Input, Label, Row, Spinner } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faCalendarAlt, faSave } from '@fortawesome/free-solid-svg-icons';
import { NavLink, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { connect } from 'react-redux';
import moment from 'moment';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import { casefilesOperations } from '../../../../redux/ducks/casefiles';
import { organizationsOperations } from '../../../../redux/ducks/organizations';
import { doctorsOperations } from '../../../../redux/ducks/doctors';
import IDCardScanner from './IdCardScan';

function CasefileView({ getDoctorsSimple, getOrganizationsSimple, getSingleCasefile, updateCasefile, auth, }) {
  const location = useLocation();
  const navigate = useNavigate();

  const [casefile, setCasefile] = useState(null);
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [inviteDate, setInviteDate] = useState('');
  const [scheduleDate, setScheduleDate] = useState('');
  const [selectedInviteDoctor, setSelectedInviteDoctor] = useState(null);
  const [organizations, setOrganizations] = useState([]);
  const [regions, setRegions] = useState([]);
  const [doctors, setDoctors] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState('');
  const [inviteCheck, setInviteCheck] = useState(false);
  const [firstInviteCheck, setFirstInviteCheck] = useState(true);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  useEffect(() => {
    if (!auth?.update.includes('CASEFILE_UPDATE')) {
      setTimeout(() => {
        navigate('/');
      }, 10);
    } else {
      const splitUrl = location.pathname.split('/casefile/')[1];
      if (splitUrl !== 'new') {
        getCasefileData(splitUrl);
      }
    }
    getOrganizationsSimple()
      .then((res => {
        const { organizations, regions, } = res.data.data;
        setOrganizations(organizations);
        setRegions(regions);
      }))
      .catch((err => {
        console.log('err: ', err);
      }));
  }, []);

  const prefillInviteData = (doctorsData, casefileData) => {
    if (casefileData.d0InviteDate && casefileData.d0InviteDoctor) {
      setSelectedInviteDoctor({
        id: casefileData?.d0InviteDoctor?.id || '',
        value: casefileData.d0InviteDoctor?.person?.fullName || '',
      });
      setInviteDate(moment(casefileData.d0InviteDate).toDate());
      setInviteCheck(true);
    }
  };

  const getCasefileData = url => {
    const id = url.split('/')[0];
    getSingleCasefile(id)
      .then((res => {
        const casefileData = res.data.data;
        setCasefile(casefileData);
        const {
          doctor,
          d1ScheduleDate,
          d0IsFirstInvite = false,
        } = res.data.data;
        if (doctor && doctor.person) {
          const { id, } = doctor;
          const { firstName, lastName, } = doctor.person;
          setSelectedDoctor({
            id,
            value: `${lastName} ${firstName}`,
          });
        }

        if (d1ScheduleDate) setScheduleDate(moment(d1ScheduleDate).toDate());

        setFirstInviteCheck(d0IsFirstInvite);

        if (doctor && doctor.organization) {
          setSelectedSupplier(doctor.organization);
          getDoctorsSimple(doctor.organization.id)
            .then((res => {
              setDoctors(res.data.data);
              prefillInviteData(res.data.data, casefileData);
            }))
            .catch((err => {
              console.log('err: ', err);
            }));
        }
      }))
      .catch((err => {
        console.log('err: ', err);
      }));
  };

  const goBack = () => {
    const { state, } = location;
    if (state) {
      const { state: { from: { search, pathname, }, }, } = location;
      navigate(`${pathname}${search}`);
    } else {
      navigate('/casefile');
    }
  };

  const inviteDateChange = data => {
    setInviteDate(data);
  };

  const scheduleDateChange = data => {
    setScheduleDate(data);
  };

  const handleDateError = () => {
    setError('Data instiintarii trebuie sa fie inainte de data informarii!');
    setTimeout(() => {
      setError(null);
    }, 3000);
  };

  const handleSuccess = () => {
    setSuccess('Modificarile au fost salvate!');
    setTimeout(() => {
      setSuccess(null);
    }, 3000);
  };

  if (casefile === null) return <Spinner />;

  const {
    id = '',
    createTs = '',
    active = false,
    doctor: {
      person: {
        firstName: firstNameDoctor = '',
        lastName: lastNameDoctor = '',
      },
    },
    patient: {
      person: {
        firstName: firstNamePatient = '',
        lastName: lastNamePatient = '',
      },
    },
    disease: { label = '', },
    tsfEntities: patientFiles = [],
  } = casefile;

  const selectDoctor = selected => {
    setSelectedDoctor(selected || '');
  };

  const selectInviteDoctor = selected => {
    setSelectedInviteDoctor(selected || '');
  };

  const handleSupplierSelect = selected => {
    setSelectedSupplier(selected || '');
    if (selected) {
      if (selected?.id) {
        getDoctorsSimple(selected.id)
          .then((res => {
            setDoctors(res.data.data);
            setSelectedDoctor('');
            setSelectedInviteDoctor('');
          }))
          .catch((err => {
            console.log('err: ', err);
          }));
      }
    }
  };

  const saveChanges = () => {
    const payload = {
      id: casefile.id,
      doctor: { id: selectedDoctor ? selectedDoctor.id : '', },
      d0IsFirstInvite: firstInviteCheck,
    };

    if (inviteCheck) {
      payload.d0InviteDoctor = { id: selectedInviteDoctor.id, };
      payload.d0InviteDate = moment(inviteDate).format('YYYY-MM-DD');
    }

    if (scheduleDate && scheduleDate !== '') {
      payload.d1ScheduleDate = moment(scheduleDate).format('YYYY-MM-DD');
    }

    updateCasefile(payload).then(res => {
      handleSuccess();
    }).catch(err => {
      // console.log(err?.data?.message || "Operatiunea nu fost efectuata");
      setError(err?.data?.message || 'Operatiunea nu fost efectuata');
      setTimeout(() => {
        setError(null);
      }, 5000);
    });
  };

  const handleInviteCheck = e => {
    const newVal = e.target.value === 'false';
    setInviteCheck(newVal);
  };

  const handleFirstInviteCheck = e => {
    const newVal = e.target.value === 'false';
    setFirstInviteCheck(newVal);
  };

  const calcDisabled = () => {
    let disabled = false;

    if (selectedSupplier === '' || selectedSupplier === null) { disabled = true; }
    if (selectedDoctor === '' || selectedDoctor === null) { disabled = true; }
    if (inviteCheck) {
      if (inviteDate === '' || inviteDate === null) { disabled = true; }
      if (selectedInviteDoctor === '' || selectedInviteDoctor === null) { disabled = true; }
    }
    return disabled;
  };

  const saveDisabled = calcDisabled();

  return (
    <Container fluid className="d-flex ">
      {/* <Row> */}
      {/* <IDCardScanner /> */}
      {/* </Row> */}
      <Row className="w-100 m-0 justify-content-center">
        <Col lg={10}>
          <Card className="mt-3">
            <CardHeader>
              <h3 className="mb-0">Editare Dosar</h3>
            </CardHeader>
            <CardBody className="p-5">
              <Row>
                <Col md={12} className="mb-3">
                  <FormGroup>
                    <Label className="font-weight-bold">Creat la data:</Label>
                    <Input
                      disabled
                      type="text"
                      name="createTs"
                      value={moment(createTs).format('DD-MM-YYYY  HH:mm:ss')}
                    />
                  </FormGroup>
                </Col>
                <Col md={12} className="mb-3">
                  <FormGroup>
                    <Label className="font-weight-bold">Beneficiar:</Label>
                    <Input
                      disabled
                      type="text"
                      name="patientName"
                      value={`${firstNamePatient} ${lastNamePatient}`}
                    />
                  </FormGroup>
                </Col>

                <Col md={12} className="mb-3">
                  <FormGroup>
                    <Label className="font-weight-bold">Program:</Label>
                    <Input
                      disabled
                      type="text"
                      name="label"
                      value={label}
                    />
                  </FormGroup>
                </Col>
                <Col md={12} className="mb-3">
                  <Label className="font-weight-bold">Furnizor:</Label>
                  <FormGroup>
                    <Select
                      getOptionValue={option => option.id}
                      getOptionLabel={option => option.value || option.label}
                      value={selectedSupplier}
                      className={selectedSupplier ? 'select-valid' : 'select-invalid'}
                      classNamePrefix="rolesSelect"
                      placeholder=""
                      isClearable
                      name="suppliers"
                      options={organizations}
                      onChange={handleSupplierSelect}
                    />
                  </FormGroup>
                </Col>
                <Col md={12} className="mb-3">
                  <Label className="font-weight-bold">Personal furnizor:</Label>
                  <FormGroup>
                    <Select
                      value={selectedDoctor}
                      getOptionValue={option => option.id}
                      getOptionLabel={option => option.value}
                      className={selectedDoctor ? 'select-valid' : 'select-invalid'}
                      classNamePrefix="rolesSelect"
                      placeholder=""
                      isClearable
                      name="selectedDoctor"
                      options={doctors}
                      onChange={selectDoctor}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <Row>
                    <Col md={5}>
                      <FormGroup check>
                        <Input
                          type="checkbox"
                          checked={inviteCheck}
                          value={inviteCheck}
                          name="inviteCheck"
                          onChange={handleInviteCheck}
                        />
                        <Label className="font-weight-bold">Invitare screening</Label>
                      </FormGroup>
                    </Col>
                    <Col md={5}>
                      <FormGroup check>
                        <Input
                          type="checkbox"
                          checked={firstInviteCheck}
                          value={firstInviteCheck}
                          name="firstInviteCheck"
                          onChange={handleFirstInviteCheck}
                        />
                        <Label className="font-weight-bold">Prima invitatie</Label>
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row>
                <Col m6={6}>
                  <Row>
                    <Col
                      style={
                        inviteCheck ? {} : {
                          opacity: 0.7,
                          pointerEvents: 'none',
                        }
                      }
                      md={12}
                    >
                      <Label className="font-weight-bold">Data invitare screening:</Label>
                      <FormGroup row className="d-flex align-items-center m-0">
                        <Col sm={9} className="pl-0 pr-0">
                          <Input
                            value={inviteDate ? moment(inviteDate).format('DD-MM-YYYY') : ''}
                            type="text"
                            className={`form-control bg-white ${(inviteCheck && inviteDate === '') && 'invalid'} `}
                            id="inviteDate"
                            disabled
                            placeholder="zz-ll-aaaa"
                          />
                        </Col>
                        <Col sm={3} className="pl-0">
                          <DatePicker
                            minDate={new Date('01-01-2021')}
                            maxDate={new Date()}
                            locale="ro"
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            selected={inviteDate}
                            onChange={inviteDateChange}
                            customInput={<Button className="form-control"><FontAwesomeIcon icon={faCalendarAlt} /></Button>}
                          />
                        </Col>
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
                <Col m6={6}>
                  <Row>
                    <Col md={12}>
                      <Label className="font-weight-bold">Data programare screening:</Label>
                      <FormGroup row className="d-flex align-items-center m-0">
                        <Col sm={9} className="pl-0 pr-0">
                          <Input
                            value={scheduleDate ? moment(scheduleDate).format('DD-MM-YYYY') : ''}
                            type="text"
                            className="form-control bg-white"
                            id="scheduleDate"
                            disabled
                            placeholder="zz-ll-aaaa"
                          />
                        </Col>
                        <Col sm={3} className="pl-0">
                          <DatePicker
                            minDate={new Date('01-01-2021')}
                            maxDate={new Date()}
                            locale="ro"
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            selected={scheduleDate}
                            onChange={scheduleDateChange}
                            customInput={<Button className="form-control"><FontAwesomeIcon icon={faCalendarAlt} /></Button>}
                          />
                        </Col>
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row>
                <Col m6={6}>
                  <Row>
                    <Col
                      style={
                      inviteCheck ? {} : {
                        opacity: 0.7,
                        pointerEvents: 'none',
                      }
                    }
                      md={12}
                    >
                      <Label className="font-weight-bold mt-4">Personal invitare screening:</Label>
                      <FormGroup>
                        <Col className="pl-0">
                          <Select
                            value={selectedInviteDoctor}
                            getOptionValue={option => option.id}
                            getOptionLabel={option => option.value}
                            className={!inviteCheck ? 'select-valid' : selectedInviteDoctor ? 'select-valid' : 'select-invalid'}
                            classNamePrefix="rolesSelect"
                            placeholder=""
                            isClearable
                            name="selectedInviteDoctor"
                            options={doctors}
                            onChange={selectInviteDoctor}
                          />
                        </Col>
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
                <Col m6={6} />
              </Row>

              { error && <p className="text-danger font-weight-bold mb-0">{error}</p>}
              { success && <p className="text-success font-weight-bold mb-0">{success}</p>}

              <Row className="mt-5">
                <Col md={6} />
                <Col md={6} className="text-right">
                  <Button
                    onClick={goBack}
                    color="secondary"
                  >
                    <FontAwesomeIcon icon={faBan} />
                    <span className="ml-1">Anulare</span>
                  </Button>
                  <NavLink to={`/casefile/${id}/edit`}>
                    <Button
                      disabled={saveDisabled}
                      onClick={saveDisabled ? () => {} : saveChanges}
                      color="primary"
                      className="ml-1"
                    >
                      <FontAwesomeIcon icon={faSave} />
                      <span className="ml-1">Salvare</span>
                    </Button>
                  </NavLink>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
const mapStateToProps = state => ({
  auth: state.auth,
  casefiles: state.casefiles.casefiles,
  selectedCasefile: state.casefiles.selectedCasefile,
});

const mapDispatchToProps = dispatch => ({
  getSingleCasefile: id => dispatch(casefilesOperations.getSingleCasefile(id)),
  updateCasefile: data => dispatch(casefilesOperations.updateCasefile(data)),
  getOrganizationsSimple: () => dispatch(organizationsOperations.getOrganizationsSimple()),
  getDoctorsSimple: orgId => dispatch(doctorsOperations.getDoctorsSimple(orgId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CasefileView);
