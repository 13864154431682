import React, { Component } from 'react';
import { connect } from 'react-redux';
import usersOperations from '../../../redux/ducks/users/operations';

class Dosar extends Component {
  render() {
    const dom = [];
    for (let i = 0; i < 100; i++) {
      dom.push(<h1 key={i}>Dosar</h1>);
    }
    return (
      <div style={{ paddingTop: 60, height: '100vh', }} className="container-fluid border">
        <h1>first</h1>
        { dom}

      </div>
    );
  }
}

const mapStateToProps = state => ({
  users: state.users.users,
  selectedUser: state.users.selectedUser,
});

const mapDispatchToProps = dispatch => ({ getUsers: () => dispatch(usersOperations.getUsers()), });

export default connect(mapStateToProps, mapDispatchToProps)(Dosar);
