import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCheckCircle, faEye, faPencilAlt, faTimes, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { Button } from 'reactstrap';

function DoctorsTableRow({ data, handleActiveModal, canEdit, canActivate, }) {
  const {
    id, seal,
    organization: { label = '', },
    casContract, specializationType, person: { firstName = '', lastName = '', }, active,
  } = data;
  const location = useLocation();

  return (
    <tr>
      <td>{seal}</td>
      <td>{casContract}</td>
      <td>{specializationType?.label ?? ''}</td>
      <td>
        {lastName}
        {' '}
        {firstName}
      </td>
      <td style={{ maxWidth: 250, }}>
        {label}
      </td>
      <td style={{ minWidth: 80, }}>
        { active
          ? <FontAwesomeIcon fontSize={24} icon={faCheck} />
          : <FontAwesomeIcon fontSize={24} icon={faTimes} />}
      </td>
      <td className="text-right" style={{ minWidth: 180, }}>
        <div className="actions">
          <NavLink
            className="btn btn-info btn-sm btn-pill mr-1"
            to={`/doctor/${id}/view`}
            state={{ from: location, }}
          >
            <FontAwesomeIcon icon={faEye} />
          </NavLink>
          {canEdit && (
          <NavLink
            to={`/doctor/${id}/edit`}
            state={{ from: location, }}
            className="btn btn-primary btn-sm btn-pill mr-1"
          >
            <FontAwesomeIcon icon={faPencilAlt} />
          </NavLink>
          )}

          {canActivate && (
            <>
              { active ? (
                <div
                  style={{ width: '4rem', borderRadius: '20rem', }}
                  className="btn btn-outline-success text-right p-0 row-btn-wrapper"
                >
                  <Button
                    onClick={() => handleActiveModal(data)}
                    color="success"
                    className="btn-pill p-0"
                  >
                    <FontAwesomeIcon icon={faCheckCircle} />
                  </Button>
                </div>
              ) : (
                <div
                  style={{ width: '4rem', borderRadius: '20rem', }}
                  className="btn btn-outline-danger text-left p-0 row-btn-wrapper"
                >
                  <Button
                    onClick={() => handleActiveModal(data)}
                    color="danger"
                    className="btn-pill p-0"
                  >
                    <FontAwesomeIcon icon={faTimesCircle} />
                  </Button>
                </div>
              )}
            </>
          )}

        </div>
      </td>
    </tr>
  );
}

export default DoctorsTableRow;
