import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faRedoAlt, faSearch } from '@fortawesome/free-solid-svg-icons';
import { Input } from 'reactstrap';

function CasefileFilterSimple({ type, setFilterType, addFilterAndSearch, resetFilters, filters, }) {
  const [inputs, setInputs] = useState({});

  useEffect(
    () => {
      const { firstName = '', lastName = '', cnp = '', serieFormular = '', } = filters;
      setInputs({
        firstName,
        lastName,
        cnp,
        serieFormular,
      });
    },
    [filters.firstName, filters.lastName, filters.cnp, filters.serieFormular]
  );

  const handleFilterType = () => {
    setFilterType(type);
  };

  const handleSearch = () => {
    addFilterAndSearch({ lastName, firstName, cnp, serieFormular, });
  };

  const handleChange = e => {
    setInputs(prevState => ({ ...prevState, [e.target.name]: e.target.value, }));
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const handleResetFilters = () => {
    resetFilters();
  };

  const { firstName, lastName, cnp, serieFormular, } = inputs;

  return (
    <div className="row mt-4">
      <div className="col-sm-12">
        <div className="input-group flex-nowrap">
          <div className="input-group-prepend">
            <span id="addon-wrapping" className="input-group-text">Nume beneficiar:</span>
            <Input
              name="lastName"
              value={lastName || ''}
              onKeyDown={handleKeyDown}
              onChange={handleChange}
              type="text"
              className="form-control principalSearchField"
              placeholder="Căutare"
            />
          </div>
          <div className="input-group-prepend">
            <span id="addon-wrapping" className="input-group-text">Prenume beneficiar:</span>
            <Input
              name="firstName"
              value={firstName || ''}
              onKeyDown={handleKeyDown}
              onChange={handleChange}
              type="text"
              className="form-control principalSearchField"
              placeholder="Căutare"
            />
          </div>
          <div className="input-group-prepend">
            <span id="addon-wrapping" className="input-group-text">CNP Beneficiar:</span>
            <Input
              name="cnp"
              value={cnp || ''}
              onKeyDown={handleKeyDown}
              onChange={handleChange}
              type="text"
              className="form-control principalSearchField"
              placeholder="Căutare"
            />
          </div>
          <div className="input-group-prepend">
            <span id="addon-wrapping" className="input-group-text">Serie formular:</span>
            <Input
              name="serieFormular"
              value={serieFormular || ''}
              onKeyDown={handleKeyDown}
              onChange={handleChange}
              type="text"
              className="form-control principalSearchField"
              placeholder="Căutare"
            />
          </div>
          {/* <div className="input-group-prepend"> */}
          {/*  <span id="addon-wrapping" className="input-group-text">Personal Furnizor:</span> */}
          {/*  <input */}
          {/*    id="search-default" */}
          {/*    type="text" */}
          {/*    className="form-control principalSearchField" */}
          {/*    placeholder="Căutare" */}
          {/*  /> */}
          {/* </div> */}
          <div className="input-group-append">
            <div
              onClick={handleResetFilters}
              className="input-group-text bg-transparent border-left-0 border cursor-pointer"
            >
              <FontAwesomeIcon icon={faRedoAlt} />
            </div>
            <div
              onClick={handleSearch}
              className="input-group-text bg-transparent border-left-0 border cursor-pointer"
            >
              <FontAwesomeIcon icon={faSearch} />
            </div>
            <div
              onClick={handleFilterType}
              id="search-expanded"
              className="input-group-text bg-transparent border-left-0 border cursor-pointer"
            >
              <FontAwesomeIcon icon={faChevronDown} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CasefileFilterSimple;
