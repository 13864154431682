import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { authOperations } from '../../../redux/ducks/auth';

import './index.scss';

function Reset({ auth, changePassword, }) {
  const navigate = useNavigate();
  const location = useLocation();

  const [error, setError] = useState(true);
  const [oldPassword, setOldPassword] = useState('');
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');

  const handleSubmit = () => {
    changePassword({ newPassword: password, oldPassword, })
      .then(res => {
      })
      .catch(err => {
        console.log(err);
      });
  };

  const handlePasswordChange = e => {
    setPassword(e.target.value);
    checkPasswordError(e.target.value);
  };

  const handleOldPasswordChange = e => {
    setOldPassword(e.target.value);
  };

  const checkPasswordError = value => {
    const errorsArr = [];
    if (!/^(?=.*[A-Z])/.test(value)) {
      errorsArr.push('Parola trebuie să conțină cel puțin o literă mare!');
    }
    if (!/^(?=.*[a-z])/.test(value)) {
      errorsArr.push('Parola trebuie să conțină cel puțin o literă mică!');
    }
    if (!/^(?=.*[0-9])/.test(value)) {
      errorsArr.push('Parola trebuie să conțină cel puțin o cifră!');
    }
    if (!/^(?=.*[!@#\$%\^&\*])/.test(value)) {
      errorsArr.push('Parola trebuie să conțină cel puțin un caracter special!');
    }
    if (value.length < 8) {
      errorsArr.push('Parola dumneavoastră trebuie să conțină cel puțin 8 caractere!');
    }
    setPasswordError(errorsArr.length > 0 ? errorsArr : null);
  };

  const checkConfirmPasswordError = val => {
    if (passwordError) return;
    if (password !== val) {
      setConfirmPasswordError('Parola și confirmarea parolei nu se potrivesc!');
    } else {
      setConfirmPasswordError(null);
    }
  };

  const handleConfirmPasswordChange = e => {
    setConfirmPassword(e.target.value);
    checkConfirmPasswordError(e.target.value);
  };

  const handleRedirect = () => {
    const { state, } = location;
    if (state) {
      navigate(state.from.pathname);
    }
  };

  const passwordErrorDom = [];
  if (passwordError) {
    passwordError.forEach((mess, idx) => passwordErrorDom.push(
      <p className="text-danger mb-0 password-error-message" key={idx}><small>{mess}</small></p>
    ));
  }

  return (
    <div className="container d-flex justify-content-center pt-5">
      <div className="mt-5" />
      <div className="login-form mt-5">
        <div className="login-form-body">
          <div className="form-group">
            <input
              type="text"
              id="email"
              name="email"
              className="form-control valid disabled readonly"
              placeholder="Nume utilizator"
              defaultValue={auth.user}
            />
          </div>
          <div className="form-group">
            <input
              onChange={handleOldPasswordChange}
              type="password"
              id="oldPassword"
              name="oldPassword"
              className={`form-control ${oldPassword === '' ? 'invalid' : 'valid'}`}
              placeholder="Parolă veche"
            />
          </div>
          <div className="form-group">
            <input
              onChange={handlePasswordChange}
              type="password"
              id="password"
              name="password"
              className={`form-control ${(password === '' || passwordError) ? 'invalid' : 'valid'}`}
              placeholder="Parolă nouă"
            />
            {passwordErrorDom}
          </div>
          <div className="form-group">
            <input
              onChange={handleConfirmPasswordChange}
              type="password"
              id="confirmPassword"
              name="confirmPassword"
              className={`form-control ${(confirmPassword === '' || confirmPassword !== password) ? 'invalid' : 'valid'}`}
              placeholder="Confirmă noua parolă"
            />
            {
              confirmPasswordError && (
                <p className="text-danger mb-0 password-error-message">
                  <small>{confirmPasswordError}</small>
                </p>
              )
            }
          </div>
          <div className="form-group">
            <button
              onClick={handleRedirect}
              type="submit"
              className="btn btn-secondary mr-1"
            >
              Renunță
            </button>
            <button
              onClick={handleSubmit}
              type="submit"
              className={`btn btn-primary ${(passwordError || confirmPasswordError) && 'disabled-btn'}`}
            >
              Schimbă parola
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  auth: state.auth,
  users: state.users.users,
  selectedUser: state.users.selectedUser,
});

const mapDispatchToProps = dispatch => ({
  login: data => dispatch(authOperations.login(data)),
  checkToken: () => dispatch(authOperations.checkToken()),
  changePassword: data => dispatch(authOperations.changePassword(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Reset);
